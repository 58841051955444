<template>
  <div :class="{ 'lock-scroll': exporting }">
    <div class="data-loading-fixed" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <section class="section-analytics">
      <Menu :showSubMenu="false"></Menu>
      <SubMenu cloudwords="true" style="height:130px;"></SubMenu>
      <div v-if="canShowThePage">
        <SearchTerm @typeCloud="changeTypeCloud($event)" :statsUpdated="statsUpdated" @export-cloud-word="generatePDF()" />
        <div v-if="!loading" class="content-text-bubbles" id="pdf-cloud-word">
          <CloudWords
            v-if="displayType === 'bubbles'"
            class="cloud_col1"
            :display="display"
            :words="words"
            :qty="qty"
            :origin="origin"
            :renderBubbles="renderBubbles"
            :class="{
              'row': exporting,
              'center-lg': exporting,
              'center-md': exporting,
              'center-sm': exporting,
              'bubbles-width': exporting
            }"
          />
          <List class="cloud_col1" v-if="displayType === 'list'" :list="words" :origin="origin" :renderList="renderList"/>
          <TextAnalytics v-if="words.length > 0" class="cloud_col2" data-html2canvas-ignore="true" :empty="empty" :responses="responses" :searchedTerm="words.length === 1 ? words[0].word : undefined" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import CloudWords from '../components/analytics/CloudWords.vue'
import List from '../components/analytics/List.vue'
import SubMenu from '../components/dashboard/menu/Grouper.vue'
import SearchTerm from '../components/analytics/SearchTerm.vue'
import TextAnalytics from '../components/analytics/TextAnalytics.vue'
import html2pdf from 'html2pdf.js'
import { analyticsService, dashboardsService } from '@/_services'
import { formatters } from '@/_helpers'
export default {
  name: 'Analytics',
  data () {
    return {
      displayType: 'bubbles',
      exporting: false,
      canShowThePage: false,
      loading: false,
      empty: '',
      responses: '',
      origin: '',
      qty: '',
      display: '',
      words: [],
      renderList: '',
      renderBubbles: 0,
      statsUpdated: {},
      currentQuestion: ''
    }
  },
  components: {
    Menu,
    CloudWords,
    SubMenu,
    TextAnalytics,
    SearchTerm,
    List,
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.path !== '/') {
        vm.$store.commit('filters/resetAllFilters')
        vm.$router.push({ path: to.path, query: { q: JSON.stringify({ $and: [] }) } }).catch(() => {})
      }
    })
  },
  beforeDestroy () {
    this.$root.$off('filter-updated')
    this.$root.$off('question-analytics')
    this.$root.$off('searchCloud')
    this.$root.$off('search-menu-is-done')
  },
  mounted () {
    this.$root.$on('filter-updated', async () => {
      await Promise.all([this.updateStats(),
        this.getResponses('create', ''),
        this.getWords(this.formatQuery3hrs(), 'getWords')])
    })

    this.$root.$on('question-analytics', async (id) => {
      this.currentQuestion = id
      await Promise.all([this.updateStats(), this.getResponses('group', id), this.getWords(id, 'grouperQuestion')])
    })

    this.$root.$on('searchCloud', (id) => {
      this.getWords(id, 'searchTerms')
    })

    this.$root.$on('search-menu-is-done', () => {
      this.canShowThePage = true
    })
  },
  methods: {
    formatQuery3hrs () {
      return formatters.formatDateByAddTimezone(this.$route.query.q, 3, 'hours')
    },
    async updateStats () {
      const surveyId = this.$store.getters['dashboard/getCurrentSurvey']._id
      this.statsUpdated = await dashboardsService.getSurveyStats(surveyId, this.formatQuery3hrs())
    },
    async getWords (q, type) {
      let words = []
      this.loading = true
      if (type === 'getWords') {
        words = await analyticsService.getWords(q)
      }
      if (type === 'searchTerms') {
        words = await analyticsService.searchTerms(this.formatQuery3hrs(), q, this.currentQuestion)
        this.getResponsesWithTerm(q, this.currentQuestion)
      }
      if (type === 'grouperQuestion') {
        words = await analyticsService.grouperQuestion(this.formatQuery3hrs(), q)
      }
      if (words && words.length > 0) {
        words.forEach(o => {
          o.openTooltip = false
        })
        this.origin = words
        this.makeClouds(words)
        this.qty = this.words.map(item => {
          return item.qty
        })
        this.renderBubbles += 1
        this.renderList = true
        this.display = true
      } else {
        this.origin = []
        this.display = false
        this.renderList = false
      }
      this.loading = false
    },
    makeClouds (words) {
      this.words = words.sort(() => Math.random() - 0.5)
    },
    async getResponsesWithTerm (term, currentQuestion) {
      const getResponses = await analyticsService.getResponsesWithTerm(this.formatQuery3hrs(), term, currentQuestion)
      if (getResponses && getResponses.length > 0) {
        this.empty = false
        this.responses = getResponses
      } else {
        this.empty = true
        this.responses = []
      }
    },
    async getResponses (where, id) {
      let getResponses
      if (where === 'group') {
        getResponses = await analyticsService.grouperQuestionResponses(this.formatQuery3hrs(), id)
      } else {
        getResponses = await analyticsService.getResponses(this.formatQuery3hrs())
      }
      if (getResponses && getResponses.length > 0) {
        this.empty = false
        this.responses = getResponses
      } else {
        this.empty = true
        this.responses = []
      }
    },
    changeTypeCloud (e) {
      this.displayType = e
    },
    generatePDF () {
      this.loading = true
      this.exporting = true
      this.$root.$emit('export-cloud', true)
      const element = document.getElementById('pdf-cloud-word')
      var opt = {
        filename: 'cloud-graphic.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { orientation: 'landscape' },
        pagebreak: { mode: 'avoid-all' }
      }

      html2pdf()
        .set(opt)
        .from(element)
        .save()
        .then(() => {
          this.exporting = false
          this.loading = false
          this.$root.$emit('export-cloud', false)
        })
    }
  }
}
</script>

<style lang="scss">
.section-analytics {
  .mobileFilter {
    display: none;
  }
}
.content-text-bubbles {
  max-width: 1360px;
  padding: 0 30px;
  margin: 0 auto;
  display: flex;
  .cloud_col1 {
    width: 70%;
    margin-right: 3%;
  }
  .cloud_col2 {
    width: 25%;
    margin-top: 30px;
  }

}
#pdf-cloud-word {
  background-color: #eff3f8;
}
.breakAfterList {
  height: auto !important;
  overflow-y: none !important;
}
.bubbles-width {
  width: 1400px !important;
}
@media screen and (max-width: 1215px) {
  .content-text-bubbles {
    display: block;
    .cloud_col1, .cloud_col2 {
      width: 100%;
    }
  }
}
</style>
