import { callApiService } from '@/_services'
import { formatters } from '@/_helpers'

const API_URL = process.env.VUE_APP_URL

export const healthService = {
  getSyntheticOverview,
  getOvertimeOverview,
  getOverallOverview,
  getOvertimeChurn,
  getOverallChurn
}

function queryWith3Hours (query) {
  return formatters.formatDateByAddTimezone(query, 3, 'hours')
}

async function getSyntheticOverview (query) {
  const url = `${API_URL}health-score/synthetic?q=${queryWith3Hours(query)}`
  return callApiService.callApi(url, 'get')
}

async function getOvertimeOverview (query) {
  const url = `${API_URL}health-score/overtime?q=${queryWith3Hours(query)}`
  return callApiService.callApi(url, 'get')
}

async function getOverallOverview (query) {
  const url = `${API_URL}health-score/overall?q=${queryWith3Hours(query)}`
  return callApiService.callApi(url, 'get')
}

async function getOvertimeChurn (query) {
  const url = `${API_URL}health-churn/overtime?q=${queryWith3Hours(query)}`
  return callApiService.callApi(url, 'get')
}

async function getOverallChurn (query) {
  const url = `${API_URL}health-churn/overall?q=${queryWith3Hours(query)}`
  return callApiService.callApi(url, 'get')
}
