<template>
  <div class="annotation">
    <div class="content-loading-annotation-sidebar" v-show="loading"></div>
    <div class="annotation-header-content">
      <button class="create-note" @click="openNewNote = true" v-if="!openNewNote" v-title="$t('annotation.create')">
        <i class="material-icons notes">add</i>
      </button>
      <div class="new-note-text" v-show="openNewNote">
        <app-form-textarea-limited
          :inputMaxHeight="500"
          maxlength="5000"
          identify="new-note"
          v-model="annotation"
          colorText="#424242"
          name="new-note"
        ></app-form-textarea-limited>
        <div class="details-text-note">
          <div class="btn-notes">
            <button @click="saveAnnotation">{{ $t('profile.save') }}</button>
            <button @click="openNewNote = false">{{ $t('tickets.cancel') }}</button>
          </div>
          <p>{{ $t('annotation.max_length') }}: <span :style="{'color': textRemaining.remainingCount === 0 ? 'red' : '#424242'}">{{ textRemaining.remainingCount }}</span></p>
        </div>
      </div>
    </div>
    <div v-for="(item, index) in list" :key="index" class="item-annotation" :class="{'delete-ittem-effect' : active}">
      <div class="card-note">
        <p class="my-note">{{ item.content }}</p>
        <div class="details-note">
          <div class="author-and-date">
            <p class="author-note">
              <img src="https://res.cloudinary.com/binds-co/image/upload/v1508526527/placeholder_je0010.png" alt="image-user">
              {{ item.author.name }}
            </p>
            <p class="date-note">{{ item.createdAt }}</p>
          </div>
          <div class="delete-note">
            <button class="delete-note-btn" @click="openModalDelete(item._id)" v-title="$t('annotation.delete')" title-placement="top">
              <i class="material-icons delete">delete</i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-delete-annotation">
      <app-modal
        v-model="showModalDelete"
        :title="$t('annotation.delete_annotation')"
        lightbox
        close
        class="modal-default-primary"
        :primary-button="$t('annotation.yes')"
        @primaryButtonClick="deleteItem(isToDelete)"
        :secondary-button="$t('annotation.no')"
        @secondaryButtonClick="showModalDelete = false">
        </app-modal>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import { annotationsService } from '@/_services'
import { formatters } from '@/_helpers'

export default {
  name: 'Annotation',
  props: ['id'],
  components: {
    'app-form-textarea-limited': () => import('@binds-tech/binds-design-system/src/components/Form/TextareaLimited'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal')
  },
  data () {
    return {
      loading: true,
      list: [],
      isToDelete: '',
      showModalDelete: false,
      openNewNote: false,
      annotation: '',
      active: false,
      textRemaining: {
        maxCount: 5000,
        remainingCount: 5000
      }
    }
  },
  async mounted () {
    const annotationList = await annotationsService.getAnnotation(this.id)
    this.list = annotationList.reverse()
    this.list.map(item => {
      item.createdAt = formatters.formatDateByLangAndFixTimeZone(item.createdAt, this.lang, 'lll')
    })

    this.setLoading(false)
  },
  watch: {
    annotation: function () {
      this.textRemaining.remainingCount = this.textRemaining.maxCount - this.annotation.length
    }
  },
  computed: {
    account () { return this.$store.getters['account/getUser'] },
    lang () { return this.$store.getters['account/getLang'] }
  },
  methods: {
    setLoading (e) {
      if (e) { this.loading = e }

      const opacity = e ? 1 : 0
      const duration = 0.5
      const divLoading = document.querySelector('.content-loading-annotation-sidebar')
      gsap.to(divLoading, {
        opacity,
        duration,
        onComplete: () => {
          if (!e) { this.loading = e }
        }
      })
    },
    async saveAnnotation () {
      if (!this.annotation) {
        this.showAlert(this.$t('surveyList.error_go_to'))
        return
      }

      this.setLoading(true)

      const createdAnnotation = await annotationsService.createAnnotation(this.id, this.annotation)

      const authorDetails = {
        name: this.account.name,
        _id: this.account._id
      }

      const newItem = {
        content: createdAnnotation.content,
        _id: createdAnnotation._id,
        createdAt: formatters.formatDateByLangAndFixTimeZone(createdAnnotation.createdAt, this.lang, 'lll'),
        author: authorDetails
      }

      this.list.unshift(newItem)
      this.annotation = ''
      this.openNewNote = false
      this.setLoading(false)
    },
    showAlert (message) {
      this.$store.commit('alerts/alert', {
        message: message,
        position: 'bottomRight',
        showAlert: true
      })
    },
    openModalDelete (id) {
      if (id) {
        this.isToDelete = id
        this.showModalDelete = true
      }
    },
    async deleteItem (itemId) {
      this.setLoading(true)

      this.active = true
      const deleteItem = await annotationsService.deleteAnnotation(this.id, itemId)
      if (deleteItem._id) {
        this.list = this.list.filter(function (i) {
          return i._id !== itemId
        })
        this.isToDelete = ''
        this.showModalDelete = false
      } else {
        this.$store.commit('alerts/alert', {
          message: this.$t('surveyList.error_go_to'),
          position: 'bottomRight',
          showAlert: true
        })
        this.showModalDelete = false
      }
      this.active = false
      this.setLoading(false)
    }
  }
}
</script>

<style lang="scss">
.annotation {
  position: relative;
  width: 100%;
  height: 100%;
  .content-loading-annotation-sidebar {
    position: absolute;
    z-index: 51;
    opacity: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f6f4f7;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .annotation-header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px 0;
    .create-note {
      width: 1.7vw;
      height: 1.7vw;
      border-radius: 50%;
      font-size: 0.7vw;
      border: 1px solid #e1e1e1;
      background: #ffffff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      color: #5e5e5e;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .new-note-text {
      width: 98%;
      .input-wrapper {
        .input {
          background: #ffffff;
          border-radius: 5px;
          font-size: 0.7vw;
          color: #454548;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        }
      }
      .input-wrapper:after {
        display: none;
      }
      .details-text-note {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-notes {
          margin-top: 0.5vw;
          display: flex;
          gap: 0.5vw;
          button {
            width: 5vw;
            height: 1.7vw;
            font-size: 0.7vw;
            border-radius: 5px;
            border: 1px solid #e1e1e1;
            background: #ffffff;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
            color: #5e5e5e;
          }
        }
        p {
          font-size: 13px;
          color: #424242;
        }
      }
    }
  }
  .item-annotation {
    .card-note {
      padding: 10px;
      margin: 10px 0;
      background: #ffffff;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      border: 1px solid #e1e1e1;
      .my-note {
        color: #454548;
        font-size: 0.9vw;
        margin: 10px 0;
      }
      .details-note {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #424242;
        .delete-note {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .delete {
            color: #7a7a7a;
            font-size: 20px;
          }
          .delete:hover {
            color: #424242;
            transform: scale(1.1);
          }
        }
        .author-and-date {
          height: 50px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .author-note {
            font-size: 0.9vw;
            display: flex;
            align-items: center;
            img {
              margin-right: 7px;
              width: 1.1vw;
              height: 1.1vw;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .date-note {
            font-size: 0.8vw;
            padding-left: 5px;
          }
        }
      }
    }
  }
}
.modal-delete-annotation {
  .modal__wrapper {
    background: #F6F4F7;
    padding: 15px 15px 15px 75px;
    border-radius: 3px;
    overflow: hidden;
    .modal__wrapper__title {
      margin: 15px 0 15px 0 !important;
      font-size: 25px;
    }
    .modal__wrapper__actions {
      padding: 0;
      margin: 40px 0 10px 0;
      .modal__wrapper__actions__button {
        padding: 5px;
        min-width: 150px !important;
        div {
          padding: 0;
          width: 150px !important;
        }
      }
    }
  }
  .modal__wrapper:before {
    border-radius: 0;
  }
}
</style>
