<template>
  <div class="container-individual-list">
    <div class="content-loading-individual-list" v-show="loadIndividualList">
      <DoubleBounce class="data-loading-spinner animated fadeIn" />
    </div>
    <NoDataToShow v-if="showMessageNoResponses" title="dashboard.no_responses" />
    <IndividualCard
      v-for="(card, idx) in listOfPageWithItems[currentPage]"
      :key="`${idx}${key}`" :cardPayload="{...card, accountTags}"
      :hasSendingSidebar="sendingSelected"
      @open-details-sending="openSendingDetail($event)"
    />
    <SidebarSendings
      :key="sendingSelected"
      v-if="showDetailsSending"
      :showSidebarSending="showDetailsSending"
      :id="sendingSelected"
      @closeSendings="closeSidebarSending()"
    />
    <div class="container-paginate-individual-list" v-if="Object.keys(listOfPageWithItems).length > 0">
      <app-paginate :initValue="currentPage" :prevText="'<'" :nextText="'>'" @input="changePage" :pageRange="10" :pageCount="dynamicPageCount" />
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import { tagsService, accountService, dashboardsService } from '@/_services'
import { formatters, responseFormat } from '@/_helpers'
import IndividualCard from './IndividualCard.vue'
import SidebarSendings from '../../SideBarConfig/SidebarSendings.vue'
import NoDataToShow from '../../alerts/NoDataToShow.vue'

export default {
  name: 'IndividualList',
  components: {
    IndividualCard,
    SidebarSendings,
    NoDataToShow,
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-paginate': () => import('@binds-tech/binds-design-system/src/components/Paginate/Paginate')
  },
  data () {
    return {
      key: 0,
      listOfPageWithItems: {},
      dynamicPageCount: 0,
      rangeEnd: 20,
      contentLength: undefined,
      currentPage: 1,
      accountTags: [],
      showDetailsSending: false,
      sendingSelected: null,
      loadIndividualList: true,
      showMessageNoResponses: false
    }
  },
  beforeDestroy () {
    this.$root.$off('update-ticket')
    this.$root.$off('individual-is-solved')
    this.$root.$off('filter-updated')
  },
  async mounted () {
    this.$root.$on('filter-updated', () => { this.getIndividualData(); this.setLoading(true) })

    this.$root.$on('close-details-event', () => { this.showDetails = false })

    this.$root.$on('individual-is-solved', payload => {
      const pageItems = this.listOfPageWithItems[this.currentPage]
      const index = pageItems.findIndex(item => item.sendingId === payload._id)

      if (index !== -1) {
        pageItems[index].userInfo.isSolved = payload.isSolved
      }
    })

    this.$root.$on('update-ticket', (payload, id) => {
      const index = this.listOfPageWithItems[this.currentPage].findIndex(item => item.sendingId === id)

      if (index !== -1) {
        this.listOfPageWithItems[this.currentPage][index].ticket = payload
        this.key++
      }
    })

    this.getTagsAndUsers()
    if (this.sendingFromNotification) this.openSendingDetail({ sendingId: this.sendingFromNotification })
  },
  computed: {
    currentSurvey () { return this.$store.getters['dashboard/getCurrentSurvey'] },
    lang () { return this.$store.getters['account/getLang'] },
    user () { return this.$store.getters['account/getUser'] },
    sendingFromNotification () { return this.$store.getters['providers/getSendingOnQuery'] }
  },
  methods: {
    async getTagsAndUsers () {
      const [tags, users] = await Promise.all([tagsService.getTags(), accountService.getUsers()])
      this.accountTags = tags

      if (users) { this.users = users }
    },
    setLoading (e) {
      if (e) { this.loadIndividualList = e }
      const opacity = e ? 1 : 0

      const divLoading = document.querySelector('.content-loading-individual-list')
      gsap.to(divLoading, {
        opacity,
        duration: 0.5,
        onComplete: () => {
          if (!e) { this.loadIndividualList = e }
        }
      })
    },
    closeSidebarSending () {
      this.showDetailsSending = false
      this.sendingSelected = null
      this.resetFocusCards()
    },
    resetFocusCards () {
      const allContainerCards = document.querySelectorAll('.container-individual-card')

      allContainerCards.forEach(container => {
        gsap.to(container, {
          opacity: 1,
          borderColor: '#e1e1e1',
          duration: 0.3
        })
      })
    },
    openSendingDetail (payload) {
      if (!payload?.sendingId) return
      let handlerSending = payload.sendingId

      if (typeof payload === 'string') { handlerSending = payload }

      this.sendingSelected = handlerSending
      this.showDetailsSending = true
    },
    async getIndividualData () {
      if (this.$route.name === 'Individual') {
        this.rangeEnd = 20
        this.contentLength = undefined
        this.listOfPageWithItems = {}
        this.dynamicPageCount = 1
        this.currentPage = 1
        this.$nextTick(async function () {
          await this.changePage(1)
          this.showDetails = false
        })
      }
    },
    async changePage (page) {
      const alreadyHasItems = this.listOfPageWithItems[page]

      if (!alreadyHasItems || alreadyHasItems.length <= 0) {
        this.setLoading(true)
        await this.loadMore(page)
      }

      this.currentPage = page

      setTimeout(() => { this.setLoading(false) }, 200)
      this.key++
    },
    async loadMore (currentPageChanged = this.currentPage) {
      this.rangeEnd = currentPageChanged * 20
      await this.getResults(this.rangeEnd, currentPageChanged)
    },
    async getResults (rangeEnd, currentPageChange = this.currentPage) {
      this.$root.$emit('hide-stats', true)
      this.loading = true

      const dateFilter = this.$route.query.q
      const formatRangeEnd = this.contentLength !== undefined && rangeEnd - 1 > this.contentLength ? this.contentLength : rangeEnd - 1
      let query = formatters.formatDateByAddTimezone(dateFilter, 3, 'hours')
      if (this.sendingFromNotification) {
        query = formatters.insertSendingIdOnQuery(query, this.sendingFromNotification)
      }
      const response = await dashboardsService.getIndividualResponses(query, formatRangeEnd, rangeEnd - 20)
      let result = []
      if (response && response.data) {
        result = await response.data
      }

      const enableResendButton = result.find(e => ['email', 'sms', 'whatsapp'].includes(e.type))

      if (enableResendButton) { this.$emit('show-btn-resend-individual') }

      this.cardInfos = []
      if (result.length > 0) {
        const range = response.range
        const splitedLength = range.split('/')
        this.dynamicPageCount = range && splitedLength.length > 0 && splitedLength[1] ? Math.ceil((range.split('/')[1] / 20)) : 1
        const newTotalOfSendings = range.split('/')[1] || 0
        const formatedResult = this.formatResponses(result)
        if (newTotalOfSendings !== this.contentLength) {
          this.listOfPageWithItems = {}
        }
        this.contentLength = newTotalOfSendings
        this.listOfPageWithItems[currentPageChange] = formatedResult
        this.currentPage = currentPageChange
        this.showMessageNoResponses = false
        this.loading = false
        this.$root.$emit('hide-stats', false)

        const divTop = document.querySelector('.container-main-menu')
        divTop.scrollIntoView({ behavior: 'smooth', block: 'start' })
      } else {
        this.$root.$emit('hide-stats', true)
        this.rangeEnd = 20
        this.contentLength = undefined
        this.listOfPageWithItems = {}
        this.dynamicPageCount = 1
        this.currentPage = 1
        this.loading = false
        this.showMessageNoResponses = true
      }
    },
    formatResponses (result) {
      const listOfResponses = []
      result.map(item => {
        const cardToPush = {
          sendingId: item._id,
          survey: this.currentSurvey,
          seedData: item?.seedData || {},
          userInfo: {
            isSolved: item.isSolved,
            name: item?.contact && item?.contact?.name && item?.contact?.name.length ? item?.contact?.name : this.$t('dashboard.no_name'),
            email: item?.contact && item?.contact?.email && item?.contact?.email.length > 0 ? item?.contact?.email : this.$t('dashboard.no_email'),
            phone: item.seedData?.from?.phone || this.$t('dashboard.no_phone'),
            _id: item._id
          },
          sendInfo: {
            type: item.type,
            createdAt: formatters.formatDateByLangAndFixTimeZone(item.createdAt, this.lang, 'lll') || this.$t('dashboard.mail_not_send'),
            respondedAt: item.respondedAt ? formatters.formatDateByLangAndFixTimeZone(item.respondedAt, this.lang, 'lll') : this.$t('dashboard.no_response'),
            openedAt: item.openedAt ? formatters.formatDateByLangAndFixTimeZone(item.openedAt, this.lang, 'lll') : this.$t('dashboard.mail_not_opened'),
            doneAt: item.doneAt ? formatters.formatDateByLangAndFixTimeZone(item.doneAt, this.lang, 'lll') : this.$t('dashboard.not_finished'),
            deletedAt: this.currentSurvey.deletedAt ? formatters.formatDateByLangAndFixTimeZone(this.currentSurvey.deletedAt, this.lang, 'lll') : ''
          },
          tags: item.tags ? item.tags : '',
          insights: [],
          health: item.health
        }

        if (item.ticket) {
          const lastStatusUpdate = item.ticket.lastStatusUpdate
          const formattedTicket = {
            date: lastStatusUpdate.date,
            status: lastStatusUpdate.label,
            label: this.$t(`tickets.status.${lastStatusUpdate.label}`),
            category: item.ticket.category ? item.ticket.category.title : undefined
          }

          switch (lastStatusUpdate.label) {
            case 'unassigned':
              formattedTicket.by = this.$t('tickets.no_attendant')
              formattedTicket.label = this.$t('tickets.opened')
              break

            case 'assigned':
              formattedTicket.by = item.ticket.attendant && item.ticket.attendant.name ? item.ticket.attendant.name : this.$t('dashboard.no_attendant')
              formattedTicket.label = this.$t('tickets.opened')
              break

            case 'closed':
              if (lastStatusUpdate.author && lastStatusUpdate.author.name) {
                formattedTicket.by = lastStatusUpdate.author.name
              }
              formattedTicket.label = this.$t('tickets.closed')
              break

            default:
              break
          }

          cardToPush.ticket = formattedTicket
        }

        cardToPush.responses = responseFormat.responses(this.currentSurvey, item.responses)

        cardToPush.survey.questions.forEach(e => {
          if (e.type === 'text' && e.artificialAnalysis) {
            cardToPush.insights = {
              seedData: { from: { name: cardToPush.userInfo.name, email: cardToPush.userInfo.email } },
              insight: { artificialAnalysis: { response: e.response.text, ...e.artificialAnalysis } }
            }
          }
        })

        listOfResponses.push(cardToPush)
      })
      return listOfResponses
    }
  }
}
</script>

<style lang="scss" scoped>
.container-individual-list {
  position: relative;
  width: 100%;
  min-height: 55vh;
  padding: 1vw 3.5vw;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  .container-paginate-individual-list {
    display: flex;
    align-items: center;
    justify-content: center;
    .pagination {
      transform: scale(0.8)
    }
  }
  .content-loading-individual-list {
    z-index: 51;
    opacity: 1;
    position: absolute;
    border-radius: 15px;
    top: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #EFF3F8;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner-loading-individual-list {
      width: 2.1vw !important;
      height: 2.1vw !important;
      border-width: 3px !important;
      border-radius: 35px;
      border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
    }
  }
}
</style>
