<template>
  <div>
    <div v-html="embededCode"></div>
  </div>
</template>
<script>
import { embedGenerator } from '@/_helpers'

export default {
  props: ['question'],
  data () {
    return {
      embededCode: []
    }
  },

  mounted () {
    this.embededCode = embedGenerator.returnEmbedSingleAndSingleOther(this.question)
    this.$emit('embedQuestion', this.embededCode)
  }
}
</script>
