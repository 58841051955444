<template>
  <transition name="modal">
    <div class="modal-mask-tags">
      <div class="modal-wrapper-tags">
        <div class="modal-container" style="top: 65px !important;">
          <div class="modal-header-tags">
            <slot name="header">
              <div class="modal-close-button close-config col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button @click="updateTagManager()">
                  <app-textstyle size="size-l">×</app-textstyle>
                </button>
              </div>
            </slot>
          </div>
          <div class="modal-body-tags">
            <slot name="body">
              <div class="row">
                <div class="card-tag" style="border-top: 2px solid;" :style="{'border-top-color': newTag.color }">
                  <div>
                    <app-heading level="h6">{{$t('tags.new_tag_name')}}:</app-heading>
                    <app-form-input
                      class="messageLabel"
                      v-model="newTag.name"
                      border-bottom
                    ></app-form-input>
                  </div>
                  <div style="margin-top: 20px;">
                    <app-heading level="h6">{{$t('tags.new_tag_color')}}:</app-heading>
                    <div class="row" style="display: flex;">
                      <div class="input-color-container">
                        <input class="input-color" type="color" v-model="newTag.color">
                      </div>
                      <app-button class="update-tag-btn" variation="primary" outline @click="createTag(newTag)">{{$t('tags.create')}}</app-button>
                    </div>
                  </div>
                </div>
                <div v-for="(tag, index) in tags" :key="index">
                  <div class="card-tag" style="border-top: 2px solid;" :style="{'border-top-color': tag.color }">
                    <div class="col-lg-12 col-md-12 col-sm-12" style="padding: 0px !important;">
                      <i class="material-icons delete-tag" @click="deleteTag(tag._id)">delete</i>
                    </div>
                    <div>
                      <app-heading level="h6">{{$t('tags.tag_name')}}:</app-heading>
                      <app-form-input
                        class="messageLabel"
                        v-model="tag.name"
                        border-bottom
                      ></app-form-input>
                    </div>
                    <div style="margin-top: 20px;">
                      <app-heading level="h6">{{$t('tags.tag_color')}}:</app-heading>
                      <div class="row" style="display: flex;">
                        <div class="input-color-container">
                          <input class="input-color" type="color" v-model="tag.color">
                        </div>
                        <app-button class="update-tag-btn" variation="primary" outline @click="updateTag(tag._id, tag)">{{$t('tags.update_tag')}}</app-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
      <!-- loading effect -->
      <div class="data-loading" v-show="loadingTags">
        <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
      </div>
    </div>
  </transition>
</template>

<script>
import { tagsService } from '@/_services'

export default {
  name: 'tagManager',
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  data () {
    return {
      tags: [],
      newTag: { name: '', color: '#18161675' },
      showTagManager: false,
      loadingTags: false
    }
  },
  mounted () {
    this.getTags()
  },
  methods: {
    updateTagManager () {
      this.$root.$emit('update-tags-sending', this.tags)
      this.$emit('close-tag-manager', this.tags)
    },
    async getTags () {
      this.tags = []
      this.loadingTags = true

      const tags = await tagsService.getTags()
      this.tags = tags

      this.loadingTags = false
    },
    async createTag () {
      this.newTag.name = this.newTag.name.trim()
      const create = await tagsService.createTag(this.newTag)
      if (create) {
        this.$store.commit('alerts/alert', {
          message: this.$t('activity.tag_created'),
          position: 'bottomRight',
          showAlert: true
        })
      }

      this.newTag = { name: '', color: '#555555' }
      this.getTags()
    },
    async updateTag (id, tag) {
      const update = await tagsService.updateTag(id, tag)
      if (update) {
        this.$store.commit('alerts/alert', {
          message: this.$t('activity.tag_updated'),
          position: 'bottomRight',
          showAlert: true
        })
      }
    },
    async deleteTag (tagId) {
      const del = await tagsService.deleteTag(tagId)
      if (del) {
        this.$store.commit('alerts/alert', {
          message: this.$t('activity.tag_deleted'),
          position: 'bottomRight',
          showAlert: true
        })
        this.$emit('tag-manager-removed', tagId)
      }

      this.getTags()
    }
  }
}
</script>

<style lang="scss">
.card-tag {
  width: 318px;
  height: 170px;
  padding: 1rem;
  margin-right: 37px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 5px 7px 0 rgba(48,48,48,.16), 0 5px 4px 0 rgba(0,0,0,.01);
  -webkit-box-sizing: border-box;
  -webkit-box-shadow: 0 5px 7px 0 rgba(48,48,48,.16), 0 5px 4px 0 rgba(0,0,0,.01);
  & h6 {
    font-size: 14px !important;
    color: #080808 !important;
  }
  & .text-field__input > input {
    font-size: 14px !important;
    font-weight: normal !important;
    color: #4d4d4d !important;
  }
}
.modal-body-tags {
  width: 99% !important;
  margin-top: 25px;
  margin-left: 20px;
  max-height: 443px;
  overflow-y: auto;
}
.modal-mask-tags {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.17);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper-tags {
  display: table-cell;
  vertical-align: middle;
  width: 880px !important;
  min-height: 300px !important;
  max-height: 450px !important;
  overflow-x: hidden;
}
.modal-header-tags h3 {
  margin-top: 0;
  color: #42b983;
}
.delete-tag {
  cursor: pointer;
  float: right;
  color: #878787;
  font-size: 18px !important;
  margin-right: -10px !important;
  margin-top: -10px !important;
}
.input-color-container {
  position: relative;
  overflow: hidden;
  width: 32px;
  height: 32px;
  border: solid 2px #ddd;
  border-radius: 40px;
  margin-top: 3px;
}
.input-color {
  position: absolute;
  right: -8px;
  top: -8px;
  width: 56px;
  height: 56px;
  border: none;
}
.update-tag-btn {
  margin-left: 185px !important;
  width: 100px !important;
  height: 35px;
  font-size: 12px !important;
  margin-top: -37px !important;
  color: #555555 !important;
  border-color: #555555 !important;
  box-shadow: inset 0 0 0 1px #555555 !important;
  transform: none !important;
}
</style>
