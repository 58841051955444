<template>
  <div class="container-modal-alert-health">
    <div class="section-modal-alert-health">
      <div class="content-loading-modal-alert-health" v-if="loadingAlertHealth">
        <Circle2 class="spinner-social-alert-health"/>
      </div>
      <header class="header-modal-alert-health">
        <h3 class="title-header-modal-alert-health">{{ $t('notifications.notifications') }}</h3>
        <i class="material-icons icon-close-modal-alert-health" @click="openOrCloseModal(true)" v-title="'Fechar'">close</i>
      </header>
      <section class="content-modal-alert-health">
        <div class="options-modal-alert-health">
          <div class="content-initial-tabs">
            <ChangeTab :renderTabs="initialTabs" @tab-active="changeAlertTab($event)" :isLoading="'asas'" />
          </div>
          <div class="items-to-chose-modal-alert-health">
            <div class="item-classification-alert-health"
              :class="`item-label-alert-${item.id}${tabActive.name}`"
              v-for="item in tabActive.classifications"
              :key="item.id"
              @click="selectLabelAlert(item)">
              <p class="title-item-class-alert-health">{{ item.label }}</p>
              <div class="has-notifications-alert-health" v-if="item.notifications">
                <p class="title-item-notification-alert-health">{{ item.notifications }}</p>
                <i class="material-icons-outlined icon-notifications-active">notifications_active</i>
              </div>
            </div>
          </div>
        </div>
        <div class="items-modal-alert-health" v-if="tabActive.classificationActive" :key="key">
          <ChangeTab :renderTabs="tabActive.tabs" @tab-active="changeItemTab($event)" :isLoading="tabActive.classificationActive.id" :showContent="true">
            <template v-slot:default>
              <SelectAlertItem v-if="tabActive.currentTab === 'users'" :dataAlertItems="tabActive.classificationActive" keyItem="users" />
              <SelectAlertItem v-if="tabActive.currentTab === 'groups'" :dataAlertItems="tabActive.classificationActive" keyItem="groups" />
            </template>
          </ChangeTab>
        </div>
      </section>
      <div class="footer-modal-alert-health">
        <button class="btn-modal-alert-health" @click="saveItems()">Salvar</button>
        <button class="btn-modal-alert-health btn-cancel" @click="openOrCloseModal(true)">Fechar</button>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import { accountService, surveyService } from '@/_services'
import ChangeTab from '../inputs/ChangeTab.vue'
import SelectAlertItem from '../inputs/SelectAlertItem.vue'

export default {
  name: 'ModalAlertHealth',
  props: ['survey'],
  components: {
    ChangeTab,
    SelectAlertItem,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      key: 0,
      loadingAlertHealth: true,
      tabActive: null,
      users: [],
      groups: [],
      optionScore: {
        name: 'score',
        currentTab: 'users',
        tabs: [
          { render: true, active: true, title: this.$t('notifications.users'), value: 'users', icon: 'person' },
          { render: true, active: false, title: this.$t('notifications.roles'), value: 'groups', icon: 'group' }
        ],
        classifications: [],
        classificationActive: null
      },
      optionChurn: {
        name: 'churn',
        currentTab: 'users',
        tabs: [
          { render: true, active: true, title: this.$t('notifications.users'), value: 'users', icon: 'person' },
          { render: true, active: false, title: this.$t('notifications.roles'), value: 'groups', icon: 'group' }
        ],
        classifications: [],
        classificationActive: null
      },
      initialTabs: [
        { render: true, active: true, title: this.$t('health_score.title_card_score'), value: 'optionScore', icon: 'ecg_heart' },
        { render: true, active: false, title: this.$t('health_score.title_card_churn'), value: 'optionChurn', icon: 'autorenew' }
      ]
    }
  },
  created () {
    this.tabActive = this.optionScore
  },
  mounted () {
    this.openOrCloseModal()
  },
  computed: {
    user () { return this.$store.getters['account/getUser'] },
    account () { return this.$store.getters['account/getAccount'] }
  },
  methods: {
    updateTriggersHealth (triggers) {
      const updateRecipients = (trigger, classifications) => {
        const findClassification = classifications.find(e => e.value === trigger.value)
        let notificationsCount = 0

        trigger.recipients.forEach(recipient => {
          const typeRecipient = Object.keys(recipient)[0] === 'role' ? 'groups' : 'users'
          const findRecipient = findClassification[typeRecipient].find(r => r._id === recipient[Object.keys(recipient)[0]])
          findRecipient.email = recipient.email
          findRecipient.sms = recipient.sms

          if (recipient.email) notificationsCount++
          if (recipient.sms) notificationsCount++
        })

        findClassification.notifications = notificationsCount
      }

      triggers.forEach(trigger => {
        if (trigger.type === 'score') {
          return updateRecipients(trigger, this.optionScore.classifications)
        }

        updateRecipients(trigger, this.optionChurn.classifications)
      })
    },
    async getDataAlertHealth () {
      try {
        const [users, groups] = await Promise.all([
          accountService.getUsers(),
          accountService.getRoles()
        ])

        const mappingGroups = key => key.map(item => ({ ...item, email: false, sms: false }))

        const mappingUsers = key => key.map(item => ({ ...item, emailUser: item.email, email: false, sms: false }))

        if (groups) { this.groups = mappingGroups(groups) }

        if (users) { this.users = mappingUsers(users) }

        const tabsToPush = ['optionScore', 'optionChurn']

        const classifications = [
          { id: 1, value: 'superHigh', label: this.$t('health_score.churn.churn_very_high'), notifications: 0 },
          { id: 2, value: 'high', label: this.$t('health_score.churn.churn_high'), notifications: 0 },
          { id: 3, value: 'medium', label: this.$t('health_score.churn.churn_mid'), notifications: 0 },
          { id: 4, value: 'low', label: this.$t('health_score.churn.churn_low'), notifications: 0 },
          { id: 5, value: 'superLow', label: this.$t('health_score.churn.churn_very_low'), notifications: 0 }
        ]

        tabsToPush.forEach(nameTab => {
          const clonedClassifications = classifications.map(classification => ({
            ...classification,
            users: structuredClone(this.users),
            groups: structuredClone(this.groups)
          }))

          this[nameTab].classifications = clonedClassifications
        })

        if (this.survey.health.triggers.length) {
          this.updateTriggersHealth(this.survey.health.triggers)
        }

        setTimeout(() => {
          this.selectLabelAlert(this.tabActive.classifications[0])
        })
      } catch (error) {
        console.error('error getting data:', error)
        this.openOrCloseModal(true)
      } finally {
        this.setLoading()
      }
    },
    saveItems () {
      const itemsGenerated = []

      const generateItems = (classifications, type) => {
        classifications.forEach(classification => {
          if (classification.notifications > 0) {
            const objEmpty = {
              value: classification.value,
              type: type,
              recipients: []
            }

            objEmpty.recipients.push(...this.mappingItems(classification.groups, 'role'))
            objEmpty.recipients.push(...this.mappingItems(classification.users, 'user'))

            itemsGenerated.push(objEmpty)
          }
        })
      }

      generateItems(this.optionScore.classifications, 'score')
      generateItems(this.optionChurn.classifications, 'churn')

      if (!itemsGenerated.length) return

      this.survey.health.triggers = itemsGenerated

      this.saveSurvey()
    },
    mappingItems (payload, type) {
      return payload
        .filter(item => item.email || item.sms)
        .map(item => ({
          [type]: item._id,
          email: item.email,
          sms: item.sms
        }))
    },
    async saveSurvey () {
      this.loadingAlertHealth = true

      try {
        const oldSurvey = structuredClone(this.survey)
        const colorArray = []

        if (!Array.isArray(oldSurvey.colors)) {
          Object.keys(oldSurvey.colors).forEach(item => {
            if (item && oldSurvey.colors[item]) {
              colorArray.push({ alias: item, value: oldSurvey.colors[item] })
            }
          })
          oldSurvey.colors = colorArray
        }

        oldSurvey.message = oldSurvey.message ? oldSurvey.message._id : undefined

        const result = await surveyService.updateSurvey(oldSurvey, true)

        if (result && !result.errors) { this.formatColorsFromReq(result) }
      } catch (error) {
        console.error('Error updating survey:', error)
      }
    },
    formatColorsFromReq (survey) {
      const getColors = survey.colors.reduce((acc, cur) => ({ ...acc, [cur.alias]: cur.value }), {})
      survey.colors = getColors
      this.$store.commit('survey/survey', survey)
      this.openOrCloseModal(true)
    },
    changeItemTab (tab) {
      this.tabActive.currentTab = tab
    },
    changeAlertTab (tab) {
      this.tabActive = this[tab]

      setTimeout(() => { this.selectLabelAlert(this.tabActive.classifications[0]) })
    },
    openOrCloseModal (isClose) {
      const opacity = isClose ? 0 : 1
      const container = document.querySelector('.container-modal-alert-health')
      const section = document.querySelector('.section-modal-alert-health')

      gsap.timeline()
        .to([container, section], {
          opacity: opacity,
          duration: 0.5,
          onComplete: () => {
            if (isClose) {
              this.$emit('close-modal-alert-health')
            } else {
              this.getDataAlertHealth()
            }
          }
        })
    },
    setLoading () {
      const contentLoading = document.querySelector('.content-loading-modal-alert-health')

      gsap.to(contentLoading, {
        opacity: 0,
        duration: 0.5,
        onComplete: () => { this.loadingAlertHealth = false }
      })
    },
    selectLabelAlert (item) {
      const allItems = document.querySelectorAll('.item-classification-alert-health')

      allItems.forEach(item => { item.classList.remove('item-classification-selected') })

      const getItem = document.querySelector(`.item-label-alert-${item.id}${this.tabActive.name}`)
      getItem.classList.add('item-classification-selected')

      this.tabActive.classificationActive = item
      this.key++
    }
  }
}
</script>

<style lang="scss" scoped>
.container-modal-alert-health {
  user-select: none;
  background: #00000035;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  .section-modal-alert-health {
    position: relative;
    width: 70vw;
    height: 75vh;
    opacity: 0;
    background: #ffffff;
    border-radius: 0.3rem;
    border: 1px solid #cacaca;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
    .content-loading-modal-alert-health {
      position: absolute;
      z-index: 101;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ffffff;
      border-radius: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .spinner-social-alert-health {
        width: 2vw !important;
        height: 2vw !important;
        border-width: 3px !important;
        border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
      }
    }
    .header-modal-alert-health {
      background: #f9f9f9;
      position: relative;
      width: 100%;
      height: 8%;
      border: 1px solid #f1f1f1;
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 3vw;
      padding: 0 0.5vw;
      .title-header-modal-alert-health {
        font-size: 1.2vw;
        color: #454548;
        text-shadow: 1px 0px 4px #e1e1e1;
      }
      .icon-close-modal-alert-health {
        background: #9696A0;
        width: 1.3vw;
        height: 1.3vw;
        border-radius: 50%;
        font-size: 1vw;
        color: var(--default-text-color);
        filter: drop-shadow(0px 0 1px #9696A0);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .content-modal-alert-health {
      width: 100%;
      height: 84%;
      background: #f9f9f9;
      border: 1px solid #f1f1f1;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.08);
      border-radius: 0.3rem;
      overflow: hidden;
      display: flex;
      .options-modal-alert-health {
        width: 30%;
        height: 100%;
        padding: 0.5vw;
        border-right: 1px solid #e1e1e1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.5vw;
        .items-to-chose-modal-alert-health {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 0.7vw;
          .item-classification-alert-health {
            position: relative;
            width: 100%;
            background: #ffffff;
            height: calc(100% / 5);
            border: 1px solid #e1e1e1;
            box-shadow: 0 4px  #e1e1e1;
            border-radius: 0.3vw;
            cursor: pointer;
            transition: border-color 0.4s;
            display: flex;
            align-items: center;
            justify-content: center;
            .title-item-class-alert-health {
              font-size: 0.85vw;
              font-weight: 500;
              color: #808080;
              transition: color 0.4s, font-weight 0.4s;
            }
            .has-notifications-alert-health {
              position: absolute;
              right: 0.2vw;
              top: 0.2vw;
              display: flex;
              align-items: center;
              gap: 0.2vw;
              .icon-notifications-active {
                font-size: 1vw;
                color: #ff450099;
              }
              .title-item-notification-alert-health {
                font-size: 0.8vw;
                font-weight: 500;
                color: #ff450099;
              }
            }
          }
          .item-classification-selected {
            background: #f1f1f1;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.08);
            transform: translateY(2px);
            .title-item-class-alert-health {
              color: #454548;
              font-weight: 600;
            }
          }
        }
      }
      .items-modal-alert-health {
        width: 70%;
        height: 100%;
        padding: 0.5vw;
        display: flex;
        flex-direction: column;
      }
    }
    .footer-modal-alert-health {
      width: 100%;
      height: 8%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1vw;
      .btn-modal-alert-health {
        width: fit-content;
        padding: 0.3vw 1vw;
        border-radius: 0.4vw;
        font-size: 0.8vw;
        color: var(--default-text-color);
        background: var(--accent-color);
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
      }
      .btn-cancel {
        background: #ffffff;
        border: 1px solid var(--accent-color);
        color: var(--accent-color);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>
