<template>
  <div class="container-approaches-card" :class="`container-approaches-card-${myData.id}`" @click="openDetails(myData)">
    <div class="content-approaches-card">
      <div class="infos-approaches-card">
        <div class="info-approaches-card">
          <i class="material-icons icon-approaches-list">person</i>
          <p class="label-info-approaches title-approach ellipsis-approach">{{ myData.name }}</p>
        </div>
        <div class="info-approaches-card">
          <i class="material-icons icon-approaches-list">mail</i>
          <p class="label-info-approaches">{{ myData.email || $t('dashboard.no_email') }}</p>
        </div>
        <div class="info-approaches-card">
          <i class="material-icons icon-approaches-list">smartphone</i>
          <p class="label-info-approaches ellipsis-approach">{{ myData.phone || $t('dashboard.no_phone') }}</p>
        </div>
        <div class="info-approaches-card">
          <i class="material-symbols-outlined icon-approaches-list">article</i>
          <p class="label-info-approaches ellipsis-approach">{{ myData.survey }}</p>
        </div>
      </div>
      <div class="status-approaches-card">
        <p class="label-stat-approaches title-approach ellipsis-approach"><span class="span-label-approaches">{{ $t('dashboard.send_type') }}</span>: {{ formatLabel(myData.type) }}</p>
        <p class="label-stat-approaches"><span class="span-label-approaches">{{ $t("dashboard.send") }}:</span> {{ myData.send }}</p>
        <p class="label-stat-approaches"><span class="span-label-approaches">{{ $t("dashboard.opening") }}</span>: {{ myData.openedAt }}</p>
        <p class="label-stat-approaches"><span class="span-label-approaches">{{ $t("dashboard.answer") }}</span>: {{ myData.respondedAt }}</p>
      </div>
      <div class="buttons-approaches-card" @click.stop>
        <div class="button-approaches-card" :class="{'disabled': myData.archivedAt || !userSurveyLink}" @click="getSurveyLink()">
          <i class="material-icons icon-button-approaches-list">link</i>
          <p class="label-button-approaches-card">Link</p>
        </div>
        <div class="button-approaches-card" :class="{'disabled': myData.archivedAt }" @click="openDetails(myData)">
          <i class="material-icons-outlined icon-button-approaches-list">forum</i>
          <p class="label-button-approaches-card">{{ $t("sendings.responses") }}</p>
        </div>
        <div class="button-approaches-card" v-if="!myData.archivedAt && isAdminOrSuperAdmin" @click="archiveApproach(true)">
          <i class="material-symbols-outlined icon-button-approaches-list">article_shortcut</i>
          <p class="label-button-approaches-card">{{ $t("approaches.archive") }}</p>
        </div>
        <div class="button-approaches-card" v-if="myData.archivedAt && isAdminOrSuperAdmin" @click="archiveApproach()">
          <i class="material-symbols-outlined icon-button-approaches-list">source_notes</i>
          <p class="label-button-approaches-card">{{ $t("approaches.unarchive") }}</p>
        </div>
        <div class="button-approaches-card" @click="confirmDelete()" v-if="user.type === 'superadmin'">
          <i class="material-icons-outlined icon-button-approaches-list">delete</i>
          <p class="label-button-approaches-card">{{ $t("dashboard_card.delete") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import { approachesService } from '@/_services'

export default {
  name: 'ApproachesCard',
  props: {
    dataCard: {
      type: Object,
      require: true
    }
  },
  components: {
  },
  data () {
    return {
      loadApproachesCard: true,
      myData: null,
      modalToDelete: false,
      loadingDelete: false
    }
  },
  created () {
    this.myData = structuredClone(this.dataCard)
  },
  computed: {
    user () { return this.$store.getters['account/getUser'] },
    userSurveyLink () { return this.user.features.sendingsSurveyLink },
    isAdminOrSuperAdmin () { return ['admin', 'superadmin'].includes(this.user.type) }
  },
  methods: {
    confirmDelete () {
      this.$emit('delete-approach', this.myData.id)
    },
    async archiveApproach (isToArchive) {
      let result = null

      try {
        if (isToArchive) {
          result = await approachesService.archiveApproach(this.myData.id)
        } else {
          await approachesService.unarchiveApproach(this.myData.id)
        }

        this.myData.archivedAt = isToArchive ? result.archivedAt : false
      } catch (error) {
        console.error('Error', error)
      }
    },
    async getSurveyLink () {
      if (!this.userSurveyLink || this.myData.archivedAt) return

      try {
        const url = process.env.VUE_APP_SURVEY + this.myData.id

        await navigator.clipboard.writeText(url)

        this.$store.commit('alerts/alert', {
          message: this.$t('approaches.copied'),
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      } catch (error) {
        console.error('Failed to copy link: ', error)

        this.$store.commit('alerts/alert', {
          message: this.$t('sendConfig.error'),
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      }
    },
    formatLabel (label) { return label.charAt(0).toUpperCase() + label.slice(1) },
    openDetails (card) {
      if (card.archivedAt) return

      this.$emit('open-details-sending', card)

      this.setFocusCard(card)
    },
    setFocusCard (toSend) {
      const idSending = toSend.id

      const removeFocusCard = () => {
        const allContainerCards = document.querySelectorAll('.container-approaches-card')

        allContainerCards.forEach(container => {
          gsap.to(container, {
            opacity: 0.5,
            borderColor: '#e1e1e1',
            duration: 0.3
          })
        })
      }

      removeFocusCard()

      const containerCard = document.querySelector(`.container-approaches-card-${idSending}`)

      if (!containerCard) { return this.removeFocusCard() }

      gsap.to(containerCard, {
        opacity: 1,
        borderColor: '#919191',
        duration: 0.3
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-approaches-card {
  position: relative;
  height: 8.5vw;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #e1e1e1;
  border-radius: 15px;
  box-shadow: 0 5px 7px 0 #30303029;
  padding: 0.7vw 0.5vw;
  display: flex;
  margin-bottom: 1vw;
  .content-approaches-card {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5vw;
    .infos-approaches-card {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .info-approaches-card {
        width: 100%;
        color: #454548;
        display: flex;
        align-items: center;
        gap: 0.3vw;
        .icon-approaches-list {
          font-size: 1vw;
        }
        .label-info-approaches {
          font-size: 0.8vw;
          font-weight: 500;
          line-height: 0.85vw;
        }
        .ellipsis-approach {
          width: 100%;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .title-approach {
          font-weight: 600;
          font-size: 1.05vw;
          line-height: 1.3vw;
        }
      }
    }
    .status-approaches-card {
      width: 40%;
      height: 100%;
      color: #454548;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .label-stat-approaches {
        font-size: 0.8vw;
        font-weight: 400;
        line-height: 0.85vw;
        .span-label-approaches {
          font-weight: 600;
        }
      }
    }
    .buttons-approaches-card {
      width: 25%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .button-approaches-card {
        user-select: none;
        cursor: pointer;
        color: #454548;
        min-width: 4.5vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1vw;
        .icon-button-approaches-list {
          font-size: 1.5vw;
        }
      }
      .button-approaches-card:hover {
        color: var(--accent-color);
        transform: scale(1.01);
      }
      .label-button-approaches-card {
        font-size: 0.8vw;
      }
    }
  }
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.3;
}

.disabled:hover {
  color: #454548 !important;
  transform: scale(1) !important;
}
</style>
