<template>
  <div class="sendhub-page">
    <Menu :showSubMenu="false"></Menu>
    <Breadcrumb/>
    <div class="row">
      <OneByOne class="col-lg-9 col-md-9 col-sm-9" :activeImport="selectedImportType" style="padding: 0px !important;" v-show="selectedImportType === 'onebyone'"/>
      <Csv class="col-lg-9 col-md-9 col-sm-9" :activeImport="selectedImportType" v-show="selectedImportType === 'csv'"/>
      <div class="col-lg-3 col-md-3 col-sm-3" style="padding: 0px !important;">
        <SendConfig/>
      </div>
    </div>
</div>
</template>

<script>
import Menu from '../components/Menu.vue'
import Breadcrumb from '../components/survey/BreadcrumbSurveys.vue'
import OneByOne from '../components/sendhub/preSend/OneByOne.vue'
import Csv from '../components/sendhub/preSend/Csv.vue'
import SendConfig from '../components/SideBarConfig/SendConfig.vue'
import { sendhubService, surveyService } from '@/_services'

export default {
  name: 'Sendhub',
  data () {
    return {
      selectedImportType: 'onebyone'
    }
  },
  async mounted () {
    this.$root.$on('update-active-import', (selected) => {
      this.selectedImportType = selected
    })
    let surveyId = this.$route.params.id
    const hasSurveyOnStore = this.$store.getters['survey/getSurvey']._id
    if (hasSurveyOnStore && hasSurveyOnStore < 2) {
      const survey = await surveyService.getSurvey(this.$route.params.id)
      if (survey) {
        this.$store.commit('survey/survey', survey)
        surveyId = survey._id
      }
    }
    const collectors = await sendhubService.collectors()
    let selected = {}
    collectors.map(function (i) {
      if (surveyId === i.survey) {
        selected = i
      }
    })
    this.$store.commit('sendhub/sendhub', { collection: selected._id })

    this.$root.$emit('exceeded')
  },
  components: {
    Breadcrumb,
    Menu,
    OneByOne,
    Csv,
    SendConfig
  }
}

</script>

<style lang="scss">
  .sendhub-page {
    .config {
      top: 174px !important;
      .sidebar-config {
        height: calc(100vh - 220px);
      }
    }
  }
  .content-send{
    position: relative;
  }

  .spreadsheet-contacts .jexcel>thead>tr>td{
    background-color: #eff3f8;
    padding: 10px 0;
    border-bottom: 2px solid $color-gray-light;
    font-size: 18px;
  }
  .spreadsheet-contacts .jexcel>tbody>tr>td:first-child{
    background-color: #fff;
  }
  .spreadsheet-contacts .jexcel>tbody>tr:nth-child(even){
    background-color: #eff3f8;
  }
    .jexcel_pagination{display: none;}
    .rename-column{
      background-color: #fff;
      border-radius:6px;
      padding:10px;
      width: 330px;
      display: block;
      box-sizing: border-box;
          -webkit-box-shadow: 0px 10px 64px -31px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 64px -31px rgba(0,0,0,0.75);
      box-shadow: 0px 10px 64px -31px rgba(0,0,0,0.75);
    }
  .loadingModal {
    .modal__wrapper {
      text-align: center;
    }
    .spinner--double-bounce {
        margin: 0 auto !important;
        margin-top: 6% !important;
        padding: 3% !important;
      }
    .modal__overlay {
      background: hsla(0, 0%, 0%, 0.58) !important;
    }
  }
  .content-send .modal__overlay{
    z-index: 10000;
  }
  .content-send .modal__wrapper{
    z-index: 100000 !important;
  }
  .failure-message{
    color: $color-red-light;
    display: block
  }
  .failure-message-count{
    color:$color-gray;
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }
</style>
