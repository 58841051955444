import i18n from '../../translations/i18n'
import { find, has, isEmpty } from 'lodash-es'
import { alfabetical } from '@/_helpers'

const moment = require('moment')
const textOperator = () => alfabetical.changeToAlfabeticalOrder([
  {
    key: '$like',
    label: i18n.t('advanced_filters.fields.contains'),
    toQuery: '$like',
    input: true
  },
  {
    key: '$nlike',
    label: i18n.t('advanced_filters.fields.does_not_contain'),
    toQuery: '$like',
    input: true
  },
  {
    key: '$eq',
    label: i18n.t('advanced_filters.fields.is_equal_to'),
    toQuery: '$eq',
    input: true
  },
  {
    key: '$ne',
    label: i18n.t('advanced_filters.fields.is_diffrent_from'),
    toQuery: '$ne',
    input: true
  },
  {
    key: '$exists',
    label: i18n.t('advanced_filters.fields.is_define'),
    toQuery: true,
    input: false
  },
  {
    key: '$exists',
    label: i18n.t('advanced_filters.fields.is_not_define'),
    toQuery: false,
    input: false
  }
], 'label')

const questions = () => ({
  single: alfabetical.changeToAlfabeticalOrder([
    {
      key: '$elemMatch',
      label: i18n.t('advanced_filters.fields.is_equal_to'),
      operator: '$eq',
      input: true,
      toQuery: {
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('advanced_filters.fields.is_diffrent_from'),
      operator: '$ne',
      input: true,
      toQuery: {
      }
    }
  ], 'label'),
  nps: alfabetical.changeToAlfabeticalOrder([
    {
      key: '$elemMatch',
      label: i18n.t('advanced_filters.fields.promoters'),
      toQuery: {
        rating: { $gte: 90 }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('advanced_filters.fields.neutrals'),
      toQuery: {
        rating: {
          $lte: 80,
          $gte: 70
        }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('advanced_filters.fields.detractors'),
      toQuery: {
        rating: {
          $lte: 60
        }
      }
    }
  ], 'label'),
  csat: alfabetical.changeToAlfabeticalOrder([
    {
      key: '$elemMatch',
      label: i18n.t('advanced_filters.fields.satisfied'),
      toQuery: {
        rating: {
          $gte: 70
        }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('advanced_filters.fields.unsatisfied'),
      toQuery: {
        rating: {
          $lt: 70
        }
      }
    }
  ], 'label'),
  nes: alfabetical.changeToAlfabeticalOrder([
    {
      key: '$elemMatch',
      label: i18n.t('advanced_filters.fields.less_effort'),
      toQuery: {
        rating: {
          $lte: 25
        }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('advanced_filters.fields.expected_effort'),
      toQuery: {
        rating: {
          $gt: 49,
          $lt: 51
        }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('advanced_filters.fields.much_effort'),
      toQuery: {
        rating: {
          $gte: 75
        }
      }
    }
  ], 'label'),
  ces: alfabetical.changeToAlfabeticalOrder([
    {
      key: '$elemMatch',
      label: i18n.t('components.ces.totally_disagree'),
      toQuery: {
        rating: {
          $lte: 1
        }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('components.ces.disagree'),
      toQuery: {
        rating: {
          $gte: 14,
          $lte: 16
        }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('components.ces.partially_disagree'),
      toQuery: {
        rating: {
          $gte: 29,
          $lte: 31
        }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('components.ces.neutral'),
      toQuery: {
        rating: {
          $gte: 49,
          $lte: 51
        }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('components.ces.partially_agree'),
      toQuery: {
        rating: {
          $gte: 64,
          $lte: 66
        }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('components.ces.agree'),
      toQuery: {
        rating: {
          $gte: 79,
          $lte: 81
        }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('components.ces.totally_agree'),
      toQuery: {
        rating: {
          $gte: 99
        }
      }
    }
  ], 'label'),
  nvs: alfabetical.changeToAlfabeticalOrder([
    {
      key: '$elemMatch',
      label: i18n.t('dashboard.graphics.nvs_op1'),
      toQuery: {
        rating: {
          $gte: 99
        }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('dashboard.graphics.nvs_op2'),
      toQuery: {
        rating: {
          $gte: 74,
          $lte: 76
        }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('dashboard.graphics.nvs_op3'),
      toQuery: {
        rating: {
          $gte: 49,
          $lte: 51
        }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('dashboard.graphics.nvs_op4'),
      toQuery: {
        rating: {
          $gte: 24,
          $lte: 26
        }
      }
    },
    {
      key: '$elemMatch',
      label: i18n.t('dashboard.graphics.nvs_op5'),
      toQuery: {
        rating: {
          $lte: 1
        }
      }
    }
  ], 'label')
})

const equalOrNot = () => alfabetical.changeToAlfabeticalOrder([
  {
    key: '$eq',
    label: i18n.t('advanced_filters.fields.is_equal_to'),
    toQuery: '$eq',
    options: true
  },
  {
    key: '$ne',
    label: i18n.t('advanced_filters.fields.is_diffrent_from'),
    toQuery: '$ne',
    options: true
  }
], 'label')

const boolean = () => alfabetical.changeToAlfabeticalOrder([
  {
    key: '$eq',
    label: i18n.t('advanced_filters.fields.yes'),
    toQuery: true
  },
  {
    key: '$eq',
    label: i18n.t('advanced_filters.fields.no'),
    toQuery: false
  }
], 'label')

const exists = () => alfabetical.changeToAlfabeticalOrder([
  {
    key: '$exists',
    label: i18n.t('advanced_filters.fields.yes'),
    toQuery: true
  },
  {
    key: '$exists',
    label: i18n.t('advanced_filters.fields.no'),
    toQuery: false
  }
], 'label')

const contains = () => alfabetical.changeToAlfabeticalOrder([
  { key: '$in', label: i18n.t('advanced_filters.fields.contains') },
  { key: '$nin', label: i18n.t('advanced_filters.fields.does_not_contain') }
], 'label')

// COMENTADO POIS PODE SER USADO FUTURAMENTE

// const conversionHealth = () => [
//   {
//     key: '$eq',
//     label: i18n.t('health_score.lh_to_lh'),
//     toQuery: 'LH_TO_LH'
//   },
//   {
//     key: '$eq',
//     label: i18n.t('health_score.lh_to_mh'),
//     toQuery: 'LH_TO_MH'
//   },
//   {
//     key: '$eq',
//     label: i18n.t('health_score.lh_to_hh'),
//     toQuery: 'LH_TO_HH'
//   },
//   {
//     key: '$eq',
//     label: i18n.t('health_score.mh_to_lh'),
//     toQuery: 'MH_TO_LH'
//   },
//   {
//     key: '$eq',
//     label: i18n.t('health_score.mh_to_mh'),
//     toQuery: 'MH_TO_MH'
//   },
//   {
//     key: '$eq',
//     label: i18n.t('health_score.mh_to_hh'),
//     toQuery: 'MH_TO_HH'
//   },
//   {
//     key: '$eq',
//     label: i18n.t('health_score.hh_to_lh'),
//     toQuery: 'HH_TO_LH'
//   },
//   {
//     key: '$eq',
//     label: i18n.t('health_score.hh_to_mh'),
//     toQuery: 'HH_TO_MH'
//   },
//   {
//     key: '$eq',
//     label: i18n.t('health_score.hh_to_hh'),
//     toQuery: 'HH_TO_HH'
//   }
// ]

const averageScore = () => [
  {
    key: { $gte: 80 },
    label: i18n.t('health_score.health.health_very_high')
  },
  {
    key: { $gte: 60, $lte: 79 },
    label: i18n.t('health_score.health.health_high')
  },
  {
    key: { $gte: 40, $lte: 59 },
    label: i18n.t('health_score.health.health_mid')
  },
  {
    key: { $gte: 20, $lte: 39 },
    label: i18n.t('health_score.health.health_low')
  },
  {
    key: { $lte: 19 },
    label: i18n.t('health_score.health.health_very_low')
  }
]

const averageChurn = () => [
  {
    key: { $gte: 80 },
    label: i18n.t('health_score.churn.churn_very_high')
  },
  {
    key: { $gte: 60, $lte: 79 },
    label: i18n.t('health_score.churn.churn_high')
  },
  {
    key: { $gte: 40, $lte: 59 },
    label: i18n.t('health_score.churn.churn_mid')
  },
  {
    key: { $gte: 20, $lte: 39 },
    label: i18n.t('health_score.churn.churn_low')
  },
  {
    key: { $lte: 19 },
    label: i18n.t('health_score.churn.churn_very_low')
  }
]

function initialState () {
  return {
    currentDateMetric: 'respondedAt', // default, mas pode alterar para 'createdAt', por exemplo.
    currentEntity: 'dashboard', // default, mas ao precisar do filtro em outra tela basta passar a nova current entity (approaches)
    dashboard: {
      counted: 0,
      query: {
        options: alfabetical.changeToAlfabeticalOrder(getDashboardOptions(), 'label'),
        respondedAt: {
          $gte: '',
          $lte: ''
        },
        $and: [],
        categories: alfabetical.changeToAlfabeticalOrder(getCategories(), 'label')
      }
    },
    individual: {
      counted: 0,
      query: {
        options: alfabetical.changeToAlfabeticalOrder(getDashboardOptions(), 'label'),
        respondedAt: {
          $gte: '',
          $lte: ''
        },
        $and: [],
        categories: alfabetical.changeToAlfabeticalOrder(getCategories(), 'label')
      }
    },
    referrals: {
      counted: 0,
      query: {
        options: alfabetical.changeToAlfabeticalOrder(getDashboardOptions(), 'label'),
        respondedAt: {
          $gte: '',
          $lte: ''
        },
        $and: [],
        categories: alfabetical.changeToAlfabeticalOrder(getCategories(), 'label')
      }
    },
    ranking: {
      counted: 0,
      query: {
        options: alfabetical.changeToAlfabeticalOrder(getRankingOptions(), 'label'),
        respondedAt: {
          $gte: '',
          $lte: ''
        },
        $and: [],
        categories: alfabetical.changeToAlfabeticalOrder(getCategories(), 'label')
      }
    },
    sentimentals: {
      counted: 0,
      query: {
        options: alfabetical.changeToAlfabeticalOrder(getRankingOptions(), 'label'),
        respondedAt: {
          $gte: '',
          $lte: ''
        },
        $and: [],
        categories: alfabetical.changeToAlfabeticalOrder(getCategories(), 'label')
      }
    },
    approaches: {
      counted: 0,
      query: {
        options: alfabetical.changeToAlfabeticalOrder(getApproachesOptions(), 'label'),
        createdAt: {
          $gte: '',
          $lte: ''
        },
        $and: [],
        categories: alfabetical.changeToAlfabeticalOrder(getCategories(), 'label')
      }
    },
    insights: {
      counted: 0,
      query: {
        options: alfabetical.changeToAlfabeticalOrder(getDashboardOptions(), 'label'),
        createdAt: {
          $gte: '',
          $lte: ''
        },
        $and: [],
        categories: alfabetical.changeToAlfabeticalOrder(getCategoriesInsights(), 'label')
      }
    },
    customDashboard: {
      counted: 0,
      query: {
        options: alfabetical.changeToAlfabeticalOrder(getDashboardOptions(), 'label'),
        createdAt: {
          $gte: '',
          $lte: ''
        },
        $and: [],
        categories: alfabetical.changeToAlfabeticalOrder(getCategoriesCustomDashboard(), 'label')
      }
    }
  }
}
const responses = { $elemMatch: { 'question.type': 'text', text: { $exists: true, $ne: '' } } }
const state = initialState

function getDashboardOptions () {
  return [
    { code: 'health.churn', category: 'health', label: i18n.t('health_score.filter_churn'), select: averageChurn() },
    { code: 'health.score', category: 'health', label: i18n.t('health_score.filter_score'), select: averageScore() },
    { code: 'tags', category: 'tags', label: i18n.t('advanced_filters.filter_by_tag'), select: contains(), values: [] },
    { code: 'isSolved', category: 'responses', label: i18n.t('advanced_filters.mark_as_solved'), select: boolean() },
    { code: 'doneAt', category: 'responses', label: i18n.t('advanced_filters.done_at'), select: exists() },
    { code: 'responses.text', category: 'responses', label: i18n.t('advanced_filters.response_type_text'), select: textOperator() },
    { code: 'responses', category: 'responses', label: i18n.t('advanced_filters.response_text_true'), toQuery: responses },
    { code: 'hasNotes', category: 'attendance', label: i18n.t('advanced_filters.annotations'), select: boolean() },
    { code: 'contact.name', category: 'contact', label: i18n.t('advanced_filters.contact_name'), select: textOperator() },
    { code: 'contact.email', category: 'contact', label: i18n.t('advanced_filters.contact_email'), select: textOperator() },
    { code: 'contact.phone', category: 'contact', label: i18n.t('advanced_filters.contact_phone'), select: textOperator() },
    { code: 'contact.cid', category: 'contact', label: i18n.t('advanced_filters.contact_code'), select: textOperator() },
    { code: 'contact.cpf', category: 'contact', label: i18n.t('advanced_filters.contact_cpf'), select: textOperator() },
    { code: 'ticket', category: 'attendance', label: i18n.t('advanced_filters.attendances_created'), select: exists() },
    { code: 'ticket.attendant', category: 'attendance', label: i18n.t('advanced_filters.attendant_user') },
    { code: 'ticket.category', category: 'attendance', label: i18n.t('advanced_filters.filter_category') },
    { code: 'ticket.parentCategory', category: 'attendance', label: i18n.t('advanced_filters.filter_subcategory') },
    { code: 'date.$gt', category: 'date.send', label: i18n.t('advanced_filters.fields.gt') },
    { code: 'date.$lt', category: 'date.send', label: i18n.t('advanced_filters.fields.lt') },
    { code: 'date.$gte', category: 'date.send', label: i18n.t('advanced_filters.fields.gte') },
    { code: 'date.$lte', category: 'date.send', label: i18n.t('advanced_filters.fields.lte') },
    { code: 'type', category: 'channel', label: i18n.t('advanced_filters.categories.channel'), select: equalOrNot() },
    {
      code: 'ticket.lastStatusUpdate.label',
      category: 'attendance',
      label: i18n.t('advanced_filters.ticket_status'),
      select: equalOrNot(),
      options: [
        { name: i18n.t('tickets.status.assigned'), value: 'assigned' },
        { name: i18n.t('tickets.status.unassigned'), value: 'unassigned' },
        { name: i18n.t('tickets.status.closed'), value: 'closed' }
      ]
    },
    { code: 'positivo', category: 'artificialAnalysis.sentiment', label: 'Positivo' },
    { code: 'neutro', category: 'artificialAnalysis.sentiment', label: 'Neutro' },
    { code: 'negativo', category: 'artificialAnalysis.sentiment', label: 'Negativo' },
    { code: 'elogio', category: 'artificialAnalysis.classification', label: 'Elogio' },
    { code: 'critica', category: 'artificialAnalysis.classification', label: 'Crítica' },
    { code: 'sugestao', category: 'artificialAnalysis.classification', label: 'Sugestão' },
    { code: 'raiva', category: 'artificialAnalysis.emotion', label: 'Raiva' },
    { code: 'hostilidade', category: 'artificialAnalysis.emotion', label: 'Hostilidade' },
    { code: 'tristeza', category: 'artificialAnalysis.emotion', label: 'Tristeza' },
    { code: 'medo', category: 'artificialAnalysis.emotion', label: 'Medo' },
    { code: 'frustracao', category: 'artificialAnalysis.emotion', label: 'Frustração' },
    { code: 'aversao', category: 'artificialAnalysis.emotion', label: 'Aversão' },
    { code: 'alegria', category: 'artificialAnalysis.emotion', label: 'Alegria' },
    { code: 'confianca', category: 'artificialAnalysis.emotion', label: 'Confiança' },
    { code: 'empatia', category: 'artificialAnalysis.emotion', label: 'Empatia' },
    { code: 'surpresa', category: 'artificialAnalysis.emotion', label: 'Surpresa' }
  ]
}

function getRankingOptions () {
  return [
    { code: 'health.churn', category: 'health', label: i18n.t('health_score.filter_churn'), select: averageChurn() },
    { code: 'health.score', category: 'health', label: i18n.t('health_score.filter_score'), select: averageScore() },
    { code: 'tags', category: 'tags', label: i18n.t('advanced_filters.filter_by_tag'), select: contains(), values: [] },
    { code: 'isSolved', category: 'responses', label: i18n.t('advanced_filters.mark_as_solved'), select: boolean() },
    { code: 'doneAt', category: 'responses', label: i18n.t('advanced_filters.done_at'), select: exists() },
    { code: 'responses.text', category: 'responses', label: i18n.t('advanced_filters.response_type_text'), select: textOperator() },
    { code: 'responses', category: 'responses', label: i18n.t('advanced_filters.response_text_true'), toQuery: responses },
    { code: 'hasNotes', category: 'attendance', label: i18n.t('advanced_filters.annotations'), select: boolean() },
    { code: 'contact.name', category: 'contact', label: i18n.t('advanced_filters.contact_name'), select: textOperator() },
    { code: 'contact.email', category: 'contact', label: i18n.t('advanced_filters.contact_email'), select: textOperator() },
    { code: 'contact.phone', category: 'contact', label: i18n.t('advanced_filters.contact_phone'), select: textOperator() },
    { code: 'contact.cid', category: 'contact', label: i18n.t('advanced_filters.contact_code'), select: textOperator() },
    { code: 'contact.cpf', category: 'contact', label: i18n.t('advanced_filters.contact_cpf'), select: textOperator() },
    { code: 'ticket', category: 'attendance', label: i18n.t('advanced_filters.attendances_created'), select: exists() },
    { code: 'type', category: 'channel', label: i18n.t('advanced_filters.categories.channel'), select: equalOrNot() }
  ]
}

function getApproachesOptions () {
  return [
    { code: 'respondedAt', category: 'responses', label: i18n.t('advanced_filters.responded_at'), select: exists() },
    { code: 'doneAt', category: 'responses', label: i18n.t('advanced_filters.done_at'), select: exists() },
    { code: 'state', category: 'status', label: 'Status', select: equalOrNot() },
    { code: 'contact.name', category: 'contact', label: i18n.t('advanced_filters.contact_name'), select: textOperator() },
    { code: 'contact.email', category: 'contact', label: i18n.t('advanced_filters.contact_email'), select: textOperator() },
    { code: 'contact.phone', category: 'contact', label: i18n.t('advanced_filters.contact_phone'), select: textOperator() },
    { code: 'contact.cid', category: 'contact', label: i18n.t('advanced_filters.contact_code'), select: textOperator() },
    { code: 'contact.cpf', category: 'contact', label: i18n.t('advanced_filters.contact_cpf'), select: textOperator() },
    { code: 'type', category: 'channel', label: i18n.t('advanced_filters.categories.channel'), select: equalOrNot() },
    { code: 'ticket', category: 'attendance', label: i18n.t('advanced_filters.attendances_created'), select: exists() },
    { code: 'seed.attendant.name', category: 'attendance', label: i18n.t('advanced_filters.attendant_name'), select: textOperator() }
  ]
}

function getCategoriesCustomDashboard () {
  return [
    { key: 'contact', label: i18n.t('advanced_filters.categories.contact') },
    { key: 'metadata', label: i18n.t('advanced_filters.categories.metadata') },
    { key: 'channel', label: i18n.t('advanced_filters.categories.channel') },
    { key: 'tags', label: i18n.t('advanced_filters.categories.tags') },
    { key: 'attendance', label: i18n.t('advanced_filters.categories.attendance') },
    { key: 'artificialAnalysis.sentiment', label: 'Sentimento' },
    { key: 'artificialAnalysis.classification', label: 'Classificação' },
    { key: 'artificialAnalysis.emotion', label: 'Emoção' }
  ]
}

/**
 * retorna as categorias iniciais de cada campo do filtro
 * a "entity" serve para bloquear certos tipos de categorias em telas que n utilizam a mesma
 */
function getCategories () {
  return [
    { key: 'tags', label: i18n.t('advanced_filters.categories.tags'), entity: 'sending-responses' },
    { key: 'responses', label: i18n.t('advanced_filters.categories.responses') },
    { key: 'attendance', label: i18n.t('advanced_filters.categories.attendance'), entity: 'sending-responses' },
    { key: 'contact', label: i18n.t('advanced_filters.categories.contact') },
    { key: 'metadata', label: i18n.t('advanced_filters.categories.metadata') },
    { key: 'questions', label: i18n.t('advanced_filters.categories.questions'), entity: 'sending-responses' },
    { key: 'survey', label: i18n.t('advanced_filters.categories.survey'), entity: 'sendings' },
    { key: 'channel', label: i18n.t('advanced_filters.categories.channel') },
    { key: 'status', label: 'Status', entity: 'sendings' },
    { key: 'health', label: i18n.t('health_score.title_card_score') },
    { key: 'date.send', label: i18n.t('advanced_filters.categories.date_send'), entity: 'sending-responses' }
  ]
}

function getCategoriesInsights () {
  return [
    { key: 'health', label: i18n.t('health_score.title_card_score') },
    { key: 'contact', label: i18n.t('advanced_filters.categories.contact') },
    { key: 'metadata', label: i18n.t('advanced_filters.categories.metadata') },
    { key: 'channel', label: i18n.t('advanced_filters.categories.channel') },
    { key: 'tags', label: i18n.t('advanced_filters.categories.tags') },
    { key: 'attendance', label: i18n.t('advanced_filters.categories.attendance') },
    { key: 'artificialAnalysis.sentiment', label: 'Sentimento' },
    { key: 'artificialAnalysis.classification', label: 'Classificação' },
    { key: 'artificialAnalysis.emotion', label: 'Emoção' }
  ]
}

const mutations = {
  resetToInitialState (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  updateEntity (state, entity) {
    state.currentEntity = entity
  },
  updateDateMetric (state, metric) {
    state.currentDateMetric = metric
  },
  updateDashFilter (state, entityToUpdate) {
    state[state.currentEntity].query[state.currentDateMetric] = entityToUpdate.query[state.currentDateMetric]
  },
  updateCreatedAtFrom (state, gte) {
    state[state.currentEntity].query[state.currentDateMetric].$gte = gte
  },
  updateCreatedAtTo (state, lte) {
    state[state.currentEntity].query[state.currentDateMetric].$lte = lte
  },
  /**
   * insert an metadata to 'start' input field of the filter
   * @param {Object} state current state of filters
   * @param {Object} field metadata to insert inside options select (start field)
   */
  insertMetadataField (state, field) {
    const checkIfKeyExist = find(state[state.currentEntity].query.options, { code: `seed.metadata.${field.key}` })
    if (!checkIfKeyExist) {
      const option = {
        code: `seed.metadata.${field.key}`,
        label: field.label,
        input: true,
        category: 'metadata',
        select: textOperator()
      }

      if (field.key === 'RESEND') {
        option.select = exists()
      }

      state[state.currentEntity].query.options.push(option)

      state[state.currentEntity].query.options = alfabetical.changeToAlfabeticalOrder(state[state.currentEntity].query.options, 'label')
    }
  },
  resetOptionsToReload (state) {
    const entityOptionsMap = {
      dashboard: getDashboardOptions(),
      individual: getDashboardOptions(), // Assuming 'individual' uses the same options as 'dashboard'
      referrals: getDashboardOptions(), // Assuming 'referrals' uses the same options as 'dashboard'
      ranking: getRankingOptions(),
      customDashboard: getDashboardOptions()
    }
    const defaultOptions = getApproachesOptions()
    state[state.currentEntity].query.options =
      entityOptionsMap[state.currentEntity] || defaultOptions
  },
  resetAllFilters (state) {
    const entityOptionsMap = {
      dashboard: getDashboardOptions(),
      individual: getDashboardOptions(), // Assuming 'individual' uses the same options as 'dashboard'
      referrals: getDashboardOptions(), // Assuming 'referrals' uses the same options as 'dashboard'
      ranking: getRankingOptions(),
      customDashboard: getDashboardOptions()
    }

    const defaultOptions = getApproachesOptions()
    const currentEntity = state.currentEntity
    const currentDateMetric = state.currentDateMetric
    state[currentEntity].query.$and = []
    state[currentEntity].query[currentDateMetric] = { $gte: '', $lte: '' }
    state[currentEntity].counted = 0
    state[currentEntity].query.options =
      entityOptionsMap[currentEntity] || defaultOptions
  },
  insertQuestionField (state, question) {
    const checkIfKeyExist = find(state[state.currentEntity].query.options, { code: 'responses', category: 'questions', _id: question._id })
    if (!checkIfKeyExist) {
      // js trabalha por referencia, então se select for diretamente igual a questions,
      // vai alterar o valor de questions quando entrar no map
      const selectOptions = JSON.parse(JSON.stringify(questions()[question.type]))
      selectOptions.map(function (option) {
        if (option.toQuery && typeof option.toQuery === 'object') {
          option.toQuery['question._id'] = question._id
        }
      })
      state[state.currentEntity].query.options.push({ code: 'responses', category: 'questions', label: question?.question || question?.title, _id: question._id, select: selectOptions })
    }
  },
  insertSurveyField (state, element) {
    const checkIfKeyExist = find(state[state.currentEntity].query.options, { code: 'survey', _id: element._id })
    if (!checkIfKeyExist) {
      const toQuery = { $eq: element._id }
      state[state.currentEntity].query.options.push({ code: 'survey', category: 'survey', label: element.title, _id: element._id, toQuery, isExpired: element.isExpired })
    }
  },
  /**
   * Update or create a key inside $and query
   * @param {Object} state  with current state of this store
   * @param {Object} filterField with field that you want to create/update, must be like { respondedAt: { $gte: 'something' } }
   */
  createOrUpdateSpecificKey (state, filterField) {
    let hasTheKey = false
    Object
      .keys(filterField)
      .forEach(key => {
        Object
          .keys(state[state.currentEntity].query.$and)
          .forEach((subKey, index) => {
            if (state[state.currentEntity].query.$and[index] && state[state.currentEntity].query.$and[index][key]) {
              if (key === state.currentDateMetric) {
                if (has(filterField[key], '$gte') && has(state[state.currentEntity].query.$and[index][key], '$gte')) {
                  hasTheKey = true
                  state[state.currentEntity].query.$and[index][key].$gte = filterField[key].$gte
                  if (isEmpty(filterField[key].$gte)) {
                    state[state.currentEntity].query.$and.splice(index, 1)
                  }
                }
                if (has(filterField[key], '$lte') && has(state[state.currentEntity].query.$and[index][key], '$lte')) {
                  hasTheKey = true
                  state[state.currentEntity].query.$and[index][key].$lte = filterField[key].$lte
                  if (isEmpty(filterField[key].$lte)) {
                    state[state.currentEntity].query.$and.splice(index, 1)
                  }
                }
              } else {
                hasTheKey = true
                state[state.currentEntity].query.$and[index][key] = filterField[key]
              }
            }
          })
      })
    if (!hasTheKey) {
      if (has(filterField, state.currentDateMetric)) {
        // check to not create an empty respondedAt key
        const value = has(filterField[state.currentDateMetric], '$gte') ? filterField[state.currentDateMetric].$gte : filterField[state.currentDateMetric].$lte
        if (isEmpty(value)) {
          return false
        }
      }
      state[state.currentEntity].query.$and.push(filterField)
    }
  },
  /**
   * Update the options of some field you want
   * @param {Object} state with current state of this store
   * @param {Object} options constains the key and value of the field you want to update, like: { tags: [] }
   */
  updateOptionValues (state, options) {
    Object.keys(options).forEach(key => {
      state[state.currentEntity].query.options.forEach((item, index) => {
        if (item.code === key) {
          state[state.currentEntity].query.options[index].values = options[key]
        }
      })
    })
  },
  /**
   * update intire $and filter query
   * @param {Object} state with current state of this store
   * @param {Object} values new value that you want to set inside $and filter
   */
  updateFilterAnd (state, values) {
    const advancedFiltersCount = []
    if (!values && typeof values !== 'object') {
      return
    }
    if (state.currentEntity === 'approaches') {
      values.map(i => {
        if (!i.createdAt && !i.archivedAt && !i.realTimeDate) {
          if (!advancedFiltersCount.includes(i)) {
            advancedFiltersCount.push(i)
          }
        }
      })
    } else {
      values.map(i => {
        if (!i.respondedAt && !i.survey && !i.realTimeDate) {
          if (!advancedFiltersCount.includes(i)) {
            advancedFiltersCount.push(i)
          }
        }
      })
    }
    state[state.currentEntity].counted = advancedFiltersCount.length
    state[state.currentEntity].query.$and = values
  },
  /**
   * update specific key of $and filter query
   * @param {Object} state with current state of this store
   * @param {*} filterField update specific key of $and filter, this value can be: { survey: { $eq: surveyId } }
   */
  updateSpecificKey (state, filterField) {
    Object
      .keys(filterField)
      .forEach(key => {
        Object
          .keys(state[state.currentEntity].query.$and)
          .forEach((subKey, index) => {
            if (state[state.currentEntity].query.$and[index][key]) {
              state[state.currentEntity].query.$and[index][key] = filterField[key]
            }
          })
      })
  },
  /**
   * delete all specific keys that match with the given field
   * @param {Object} state with current state of this store
   * @param {*} filterField delete specific key of $and filter, this value can be: { survey: { $eq: surveyId } }
   */
  deleteSpecificKey (state, fieldToRemove) {
    Object
      .keys(state[state.currentEntity].query.$and)
      .forEach((subKey, index) => {
        if (state[state.currentEntity].query.$and[index] && state[state.currentEntity].query.$and[index][fieldToRemove]) {
          state[state.currentEntity].query.$and.splice(index, 1)
        }
      })
  }
}

const getters = {
  getCurrentEntity: state => state.currentEntity,
  getDashFilter: state => state[state.currentEntity],
  getQueryFromDash (state) {
    const objectFilter = {
      query: {
        [state.currentDateMetric]: {
          $gte: '',
          $lte: ''
        }
      }
    }
    const currentLocale = moment.locale()
    if (state[state.currentEntity].query[state.currentDateMetric]?.$gte) {
      const to = moment(state[state.currentEntity].query[state.currentDateMetric].$gte).locale(currentLocale).utc()
      objectFilter.query[state.currentDateMetric].$gte = to.locale(currentLocale).format('DD/MM/YYYY')
    }
    if (state[state.currentEntity].query[state.currentDateMetric]?.$lte) {
      const to = moment(state[state.currentEntity].query[state.currentDateMetric].$lte).locale(currentLocale).utc()
      objectFilter.query[state.currentDateMetric].$lte = to.locale(currentLocale).format('DD/MM/YYYY')
    }
    return objectFilter
  },
  getCountedFilter (state) {
    const advancedFiltersCount = []
    if (state.currentEntity === 'approaches') {
      state[state.currentEntity].query.$and.map(i => {
        if (!i.createdAt && !i.archivedAt && !i.realTimeDate) {
          if (!advancedFiltersCount.includes(i)) {
            advancedFiltersCount.push(i)
          }
        }
      })
    } else {
      state[state.currentEntity].query.$and.map(i => {
        if (!i.respondedAt && !i.survey && !i.realTimeDate) {
          if (!advancedFiltersCount.includes(i)) {
            advancedFiltersCount.push(i)
          }
        }
      })
    }
    return advancedFiltersCount.length
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
