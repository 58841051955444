<template>
  <div>
    <div class="act" style="height:100vh;">
      <div class="col-lg-12 col-md-12 link-config">
        <app-toggle-input
          v-model="linkSettings.allowMoreThanOne"
          v-on:change.native="updateLinkSettings()"
        ></app-toggle-input>
        <app-heading level="h5">{{$t('sendhub.linkHub.allowMoreThanOne.title')}}</app-heading>
        <span>{{$t('sendhub.linkHub.allowMoreThanOne.description')}}</span>
      </div>
      <div class="col-lg-12 col-md-12 link-config">
        <app-toggle-input
          v-model="linkSettings.showEndSurveyButton"
          v-on:change.native="updateLinkSettings()"
        >
        </app-toggle-input>
        <app-heading level="h5">{{$t('sendhub.linkHub.showEndSurveyButton.title')}}</app-heading>
        <span>{{$t('sendhub.linkHub.showEndSurveyButton.description')}}</span>
      </div>
      <div class="col-lg-12 col-md-12 link-config">
        <app-toggle-input
          v-model="linkSettings.endSurveyByInactivity"
          v-on:change.native="updateLinkSettings()"
        >
        </app-toggle-input>
        <app-heading level="h5">
            {{$t('sendhub.linkHub.endSurveyByInactivity.title')}}
          </app-heading>
          <span>{{$t('sendhub.linkHub.endSurveyByInactivity.description')}}</span>
           <div v-if="linkSettings.endSurveyByInactivity">
          <span class="smaller-span">{{ $t('sendhub.totenHub.shut_in') }}</span>
          <div style="display: flex;">
            <app-form-input
              type="number"
              class="seconds-input"
              v-model="linkSettings.seconds"
              v-on:change.native="updateLinkSettings()"
            ></app-form-input>
            <span class="smaller-span seconds">{{ $t('sendhub.totenHub.seconds') }}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'LinkConfig',
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input')
  },
  data () {
    return {
      linkSettings: ''
    }
  },
  mounted () {
    const settings = this.$store.getters['sendhub/getLinkSettings']
    this.linkSettings = settings
  },
  methods: {
    async updateLinkSettings () {
      this.$store.commit('sendhub/sendhub', { linkSettings: this.linkSettings })
      this.$root.$emit('updated-link')
    }
  }
}
</script>
<style lang="scss">
.link-config {
  h5 {
    font-size: 14px !important;
    margin-top: 2px !important;
  }
  span {
    font-size: 14px;
    display: block;
    margin-top: 20px;
    line-height: 20px;
  }
  .heading {
    margin-left: 45px !important;
  }
  .seconds {
    font-size: 13px;
    margin-top: 30px;
    margin-left: 7px;
  }
  .seconds-input {
    & .text-field__input > input {
      width: 80px;
      margin-top: 10px;
    }
  }
}
</style>
