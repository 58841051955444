<template>
  <div class="container-like-graphic" :style="{
      '--firstColor': cardData?.colors[0] ? cardData.colors[0] : '#51ad59',
      '--firstColorOpacity': cardData?.colors[0] ? `${cardData.colors[0]}50` : '#73E83140',
      '--secondColor': cardData?.colors[1] ? cardData.colors[1] : '#ea4922',
      '--secondColorOpacity': cardData?.colors[1] ? `${cardData.colors[1]}50` : '#ea492240',
      }">
    <div class="content-like-graphic" v-for="(item, idx) in items" :key="idx">
      <div class="content-label">
        <app-heading level="h6"
        :style="{'white-space': item.label.length > 134 ? 'nowrap' : 'wrap'}"
        v-title="item.label" title-max-width="320">
        {{ item.label }}
        </app-heading>
      </div>
      <div class="percentage-bar">
        <div class="icons">
         <div class="negative">
            <i class="material-icons down">thumb_down</i>
            <app-heading class="label-down" level="h6">{{ parseFloat(item.percentageDislike.toFixed(1)) }}<span>%</span></app-heading>
          </div>
          <div class="content-details">
            <div class="details-items">
              <app-heading class="dislike" level="h8">Dislikes</app-heading>
              <app-heading class="dislike" level="h8">{{ item.qtyDislike }}</app-heading>
            </div>
            <div class="details-items">
              <app-heading class="like" level="h8">Likes</app-heading>
              <app-heading class="like" level="h8">{{ item.qtyLike }}</app-heading>
            </div>
          </div>
          <div class="positive">
            <i class="material-icons up">thumb_up</i>
            <app-heading class="label-up" level="h6">{{ parseFloat(item.percentageLike.toFixed(1)) }}<span>%</span></app-heading>
          </div>
        </div>
        <div class="progressive-bar">
          <div class="bar-down" :style="{
          'width': item.percentageDislike + '%',
          'border-radius': item.percentageLike == 100 ? '35px' : '35px 0 0 35px',
          'display': item.percentageLike == 100 ? 'none' : 'block'}"></div>
          <div class="bar-up" :style="{
          'width': item.percentageLike + '%',
          'border-radius': item.percentageDislike == 100 ? '35px' : '0 35px 35px 0',
          'display': item.percentageDislike == 100 ? 'none' : 'block'}">
          <div class="ball"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'likeOverall',
  props: ['data', 'question', 'cardData'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      items: []
    }
  },
  mounted () {
    if (this.data[0] && this.data[0].questions) {
      const found = this.data[0].questions.find(dataQuestion => {
        return dataQuestion.question && dataQuestion.question._id === this.question._id
      })
      if (found && found.data) {
        this.question.subjects.forEach(sub => {
          this.items.push({
            label: sub.label,
            qtyLike: 0,
            percentageLike: 0,
            qtyDislike: 0,
            percentageDislike: 0
          })
        })
        found.data.forEach((item, i) => {
          if (typeof found.data[i][2] !== 'string') {
            this.items.forEach(subject => {
              if (subject.label === item[0]) {
                if (item[1] === 100) {
                  subject.qtyLike = item[2]
                  subject.percentageLike = item[3]
                }

                if (item[1] === 0) {
                  subject.qtyDislike = item[2]
                  subject.percentageDislike = item[3]
                }
              }
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.container-like-graphic {
  width: 100%;
  height: 100%;
  padding: 5px;
  overflow-y: auto;
  .content-like-graphic {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
    padding: 3px;
    border-radius: 4px;
    border: 1px dashed rgb(219, 219, 219);
    .content-label {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      h6 {
        font-size: 14px;
        color: #424242 !important;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 18px;
      }
    }
    .percentage-bar {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .icons {
        width: 92%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .positive, .negative {
          height: 100%;
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          h6 {
            margin: 0 !important;
            font-weight: 500;
            font-size: 20px !important;
            span {
              font-size: 15px;
            }
          }
          .up, .label-up {
            margin: 0 5px;
            color: var(--firstColor);
          }
          .down, .label-down {
            margin: 0 5px;
            color: var(--secondColor);
          }
        }
        .positive {
          justify-content: flex-end;
        }
        .content-details {
          width: 40%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .details-items {
            width: 33.3%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            .h8 {
              margin: 0 !important;
            }
            .like {
              color: var(--firstColor);
            }
            .dislike {
              color: var(--secondColor);
            }
          }
        }
      }
      .progressive-bar {
        width: 95%;
        height: 30%;
        border-radius: 35px;
        overflow: hidden;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.23);
        padding: 1px;
        display: flex;
        .bar-up {
          position: relative;
          opacity: 0;
          height: 100%;
          background: var(--firstColorOpacity);
          position: relative;
          border-radius: 35px 0 0 35px;
          border: 1px solid var(--firstColor);
          border-left: 1px solid transparent;
          animation: fadeBar 1.5s linear forwards;
          .ball {
            position: absolute;
            height: 101%;
            width: 1px;
            background: var(--firstColor);
            animation: loadingBar 1s linear forwards;
          }
        }
        .bar-down {
          opacity: 0;
          height: 100%;
          background: var(--secondColorOpacity);
          position: relative;
          border-radius: 0 35px 35px 0;
          border: 1px solid var(--secondColor);
          border-right: 1px solid transparent;
          animation: fadeBar 1.5s linear forwards;
        }
      }
    }
  }
}

@keyframes loadingBar {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
    opacity: 0.3;
  }
}

@keyframes fadeBar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 820px) {
  .content-label {
    width: 50% !important;
    h6 {
      font-size: 12px !important;
    }
  }
  .percentage-bar {
    width: 50% !important;
    .icons {
      height: 100% !important;
      justify-content: space-around !important;
    .positive, .negative {
      justify-content: center !important;
      flex-direction: column;
      margin: 0 15px !important;
      h6 {
        margin: 5px 0 !important;
      }
    }
      .content-details {
        display: none !important;
      }
    }
    .progressive-bar {
      display: none !important;
    }
  }
}
</style>
