import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

export const templatesService = {
  createFromTemplate,
  getAllTemplates,
  addTemplate,
  updateTemplate
}

async function createFromTemplate (templateId, title) {
  const createFromTemplateUrl = API_URL + 'templates/' + templateId + '/survey'
  const result = await callApiService.callApi(createFromTemplateUrl, 'post', title)
  return result
}

async function addTemplate (id, body) {
  const templatesUrl = API_URL + 'surveys/' + id + '/template'
  const result = await callApiService.callApi(templatesUrl, 'post', body)
  return result
}

async function updateTemplate (body) {
  const templatesUrl = API_URL + 'templates/' + body._id
  const result = await callApiService.callApi(templatesUrl, 'put', body)
  return result
}

async function getAllTemplates () {
  const templatesUrl = API_URL + 'templates?isDraft=true'
  const result = await callApiService.callApi(templatesUrl, 'get')
  return result
}
