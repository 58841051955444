<template>
  <div class="survey-settings">
    <!-- Pergunta -->
    <div id="question-settings" class="settings-section" v-show="survey.hiddenAnsweredMessage && survey.hiddenStartMessage">
      <div class="row center-xl center-lg center-md center-sm center-xs accordion-section-title">
        <app-heading level="h6" class="title-acc">{{ $t('survey.settings.set_question') }}</app-heading>
      </div>
      <div class="row center-xl center-lg center-md center-sm center-xs">
        <questionConfig/>
      </div>
    </div>
    <!-- Mensagens -->
    <div id="messages-settings" class="settings-section">
      <div class="row center-lg center-md center-sm accordion-section-title">
        <app-heading level="h6" class="title-acc">{{ $t('survey.settings.set_messages') }}</app-heading>
      </div>
      <div class="row center-lg">
        <messages/>
      </div>
    </div>
    <!-- Tema -->
    <div id="theme-settings" class="settings-section">
      <div class="row center-lg center-md center-sm accordion-section-title">
        <app-heading level="h6" class="title-acc">{{ $t('survey.settings.set_theme') }}</app-heading>
      </div>
      <div class="row center-lg">
        <theme/>
      </div>
    </div>
    <!-- Respostas -->
    <div id="response-settings" class="settings-section">
      <div class="row center-lg center-md center-sm accordion-section-title">
        <app-heading level="h6" class="title-acc">{{ $t('survey.settings.set_responses') }}</app-heading>
      </div>
      <div class="row center-lg">
        <responses/>
      </div>
    </div>
  </div>
</template>
<script>
import theme from './theme/Theme.vue'
import messages from './messages/Messages.vue'
import responses from './responses/Responses.vue'
import questionConfig from './question/QuestionConfig.vue'
export default {
  name: 'SurveySettings',
  components: {
    theme,
    messages,
    responses,
    questionConfig,
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  computed: {
    survey () {
      return this.$store.getters['survey/getSurvey']
    }
  }
}
</script>
<style lang="scss">
.survey-settings {
  background-color: #fff;
  box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
  height: 100%;
  max-height: calc(100vh + 220px);
  min-height: 100%;
  overflow-y: auto;
  .accordion-section {
    min-height: 48px;
    margin-bottom: 7px;
  }
  .accordion-section-title {
    font-size: 14px !important;
    margin-bottom: 20px;;
  }
  .condition {
    height: 48px;
    margin-bottom: 7px;
  }
  .title-acc {
    font-size: 14px !important;
    font-weight: 600;
  }
  .settings-section {
    margin-top: 30px;
  }
}
</style>
