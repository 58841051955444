<template>
  <div class="container-modal-resend">
    <div class="section-modal-resend">
      <div class="is-loading-resend" ref="isLoading" v-show="loading">
        <Circle2 class="spinner-social" v-if="!sendDone"/>
        <div class="section-success-resend" v-else>
          <i class="material-icons icon-check-resend">check_circle</i>
          <p class="title-check-resend">{{ $t('resend.success_resend_title') }}</p>
        </div>
      </div>
      <div class="container-settings-modal" ref="containerSettings" v-if="!loading">
        <div class="container-to-progress-bar">
          <VerticalProgressBar :currentStep="currentStep"/>
        </div>
        <div class="section-steps-modal">
          <div class="section-lock-resend" v-show="lock" ref="lockChange">
            <i class="material-icons icon-lock-resend">lock</i>
          </div>
          <div class="config-step-modal">
            <h3 class="header-title-step">{{ $t('resend.select_resend') }} <i class="material-icons-outlined icon-info-resend" v-title="$t('resend.tooltip_info')" title-placement="right">info</i></h3>
            <div class="section-surveys-to-resend" ref="configStepSurveys">
              <CardDetailsResend
                v-for="(survey, index) in listSurveys"
                :key="index"
                :id="index"
                :item="survey"
                :list="surveysSelected"
                @update-list-surveys="updateSurveys($event)"
              />
            </div>
          </div>
          <div class="config-step-modal config-step" ref="configStepSettings">
            <h3 class="header-title-step">{{ $t('resend.config_resend') }}</h3>
            <div class="content-all-config">
              <div class="section-to-config-name-and-date">
                <div class="change-config-to-resend">
                  <p class="label-chhange-title">{{ $t('resend.title_resend') }}</p>
                  <input type="text" class="input-change-title-resend" v-model="resendTitle" :placeholder="$t('resend.write_here')">
                </div>
                <div class="change-config-to-resend">
                  <p class="label-chhange-title">{{ $t('resend.title_date') }}</p>
                  <div class="select-date-input" :key="keyDate">
                    <v-date-picker
                      class="input-date-to-send"
                      :min-date="new Date()"
                      :locale="$i18n.locale"
                      :masks='{inputDateTime24hr: "D MMMM YYYY, H:mm"}'
                      v-model="resendDate"
                      @input="verifyDate($event)"
                      color="gray"
                      mode="dateTime"
                      is24hr
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="section-date-selected">
                          <input v-on="inputEvents"
                          class="input-date-selected"
                          inputValue readonly :placeholder="resendDate" :value="inputValue"
                          />
                          <i class="material-icons icon-date-resend">event</i>
                        </div>
                      </template>
                    </v-date-picker>
                  </div>
                </div>
              </div>
              <CardSelectMetadata :metadatasResend="metadatasResend" :currentStep="currentStep" />
            </div>
          </div>
        </div>
      </div>
      <div class="container-resume-resend-modal" :class="{'active-resume': currentStep === 'done'}" ref="containerResume" v-if="!loading">
        <ResumeResend
          :key="key"
          :data="surveysSelected"
          :metadatas="metadatasResend"
          @is-to-resend="isToResend()"
          @change-step="changeStep($event)"
          @cancel="closeModalResend()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { resendService } from '@/_services'
import { formatters } from '@/_helpers'
import CardDetailsResend from '../resend/CardDetailsResend.vue'
import ResumeResend from '../resend/ResumeResend.vue'
import CardSelectMetadata from './CardSelectMetadata.vue'
import VerticalProgressBar from '../inputs/VerticalProgressBar.vue'

export default {
  name: 'ModalResend',
  props: [],
  components: {
    CardDetailsResend,
    ResumeResend,
    CardSelectMetadata,
    VerticalProgressBar,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      key: 0,
      keyDate: 0,
      lock: false,
      loading: true,
      sendDone: false,
      resendTitle: '',
      currentStep: 'surveys',
      resendDate: this.$t('resend.select_date'),
      listSurveys: [],
      surveysSelected: [],
      metadatasResend: []
    }
  },

  async mounted () {
    const modal = document.querySelector('.container-modal-resend')
    gsap.to(modal, { opacity: 1, duration: 0.5 })

    this.getDataSurveys()
  },
  watch: {
    resendDate: {
      immediate: true,
      handler (newVal) { if (!newVal) { this.resendDate = this.$t('resend.select_date') } }
    }
  },
  computed: {
  },
  methods: {
    async isToResend () {
      const queryUpdated = formatters.formatDateByAddTimezone(this.$route.query.q, 3, 'hours')

      const toSend = {
        title: this.resendTitle.trim(),
        query: JSON.parse(queryUpdated),
        metadata: {}
      }

      if (this.resendDate !== this.$t('resend.select_date')) { toSend.schedulingDate = this.resendDate }

      if (this.metadatasResend.length) {
        this.metadatasResend.forEach(meta => {
          if (meta.value.length) { toSend.metadata[meta.key] = meta.value }
        })
      }

      const surveysClone = structuredClone(this.surveysSelected)

      surveysClone.forEach(s => {
        const myTypes = {
          email: { enabled: false },
          sms: { enabled: true },
          whatsapp: { enabled: false }
        }

        s.deliveryTypes.forEach(types => {
          myTypes[types.key].enabled = types.enabled
        })

        s.deliveryTypes = myTypes
      })

      toSend.surveys = surveysClone

      const result = await resendService.sendResend(toSend)

      if (result) {
        gsap.to([this.$refs.containerSettings, this.$refs.containerResume], {
          opacity: 0,
          duration: 0.8,
          onComplete: () => {
            this.loading = true
            this.sendDone = true
            setTimeout(() => {
              this.closeModalResend()
              this.loading = false
              this.sendDone = false
            }, 2000)
          }
        })
      }
    },
    changeStep (step) {
      this.currentStep = step
      const configStepSettings = this.$refs.configStepSettings
      const configStepSurveys = this.$refs.configStepSurveys
      const lockChanges = this.$refs.lockChange
      const duration = 1

      if (this.currentStep === 'surveys') {
        gsap.to(configStepSurveys, { height: 'auto', opacity: 1, overflow: 'hidden', duration })
        gsap.to(configStepSettings, { height: 0, opacity: 0, overflow: 'visible', pointerEvents: 'all', duration })
      } else if (this.currentStep === 'settings') {
        gsap.to(lockChanges, { opacity: 0, duration, onComplete: () => { this.lock = false } })
        gsap.to(configStepSurveys, { height: 0, opacity: 0, overflow: 'hidden', duration })
        gsap.to(configStepSettings, { height: 'auto', opacity: 1, overflow: 'visible', pointerEvents: 'all', duration })
      } else {
        this.lock = true
        gsap.to(lockChanges, { opacity: 0.8, duration })
      }
    },
    updateSurveys (e) {
      const surveyIdx = this.surveysSelected.findIndex(s => s.survey === e.survey)

      if (e.totalToSend <= 0) {
        if (surveyIdx !== -1) { this.surveysSelected.splice(surveyIdx, 1) }
      } else {
        const objSurvey = {
          title: e.title,
          survey: e.survey,
          deliveryTypes: e.deliveryTypes,
          total: e.totalToSend
        }

        if (surveyIdx !== -1) {
          this.surveysSelected[surveyIdx] = objSurvey
        } else {
          this.surveysSelected.push(objSurvey)
        }
      }

      this.key++
    },
    async getDataSurveys () {
      const queryQ = formatters.formatDateByAddTimezone(this.$route.query.q, 3, 'hours')

      try {
        const list = await resendService.getSurveysResend(queryQ)

        if (list && list.detailedCount.length) { list.detailedCount.forEach(e => { this.formatObjSurvey(e); this.loading = false }) }
      } catch (error) {
        console.error('Error loading survey details', error)
        this.closeModalResend()
      }
    },
    formatObjSurvey (e) {
      const typesDelivery = [
        { key: 'email', count: 0, enabled: false },
        { key: 'sms', count: 0, enabled: false },
        { key: 'whatsapp', count: 0, enabled: false }
      ]

      e.deliveryTypes.forEach(type => {
        const index = typesDelivery.findIndex(i => i.key === type.key)
        typesDelivery[index].count = type.doc_count
        typesDelivery[index].enabled = true
      })

      return this.listSurveys.push({
        title: e.survey.title,
        survey: e.survey._id,
        deliveryTypes: typesDelivery,
        totalToSend: 0
      })
    },
    closeModalResend () {
      this.$emit('close-modal-resend')
    },
    verifyDate (selectedDateTime) {
      if (selectedDateTime instanceof Date) {
        if (selectedDateTime.toDateString() === new Date().toDateString()) {
          const minTime = new Date(new Date())
          minTime.setMinutes(minTime.getMinutes())

          if (selectedDateTime < minTime) {
            selectedDateTime.setHours(minTime.getHours(), minTime.getMinutes() + 10)
            this.keyDate++
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container-modal-resend {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  background: #00000035;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  .section-modal-resend {
    user-select: none;
    position: relative;
    transition: opacity 0.3s;
    width: 75vw;
    height: 90vh;
    background: #ffffff;
    border-radius: 0.3rem;
    border: 1px solid #e1e1e1;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    .is-loading-resend {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ffffff;
      border-radius: 0.3rem;
      border: 1px solid #e1e1e1;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      .spinner-social {
        width: 40px !important;
        height: 40px !important;
        border-width: 4px !important;
        border-color: var(--accent-color) #cacaca #cacaca !important;
      }
      .section-success-resend {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        .icon-check-resend {
          font-size: 3vw;
          color: #69db63;
        }
        .title-check-resend {
          font-size: 0.8vw;
          color: #808080;
        }
      }
    }
    .container-settings-modal {
      position: relative;
      width: 75%;
      height: 100%;
      padding: 0 0.2rem 0.2rem 0.2rem;
      overflow-y: auto;
      display: flex;
      justify-content: flex-end;
      .container-to-progress-bar {
        position: fixed;
        left: 13.2%;
        width: 1%;
        height: 87.5%;
      }
      .section-steps-modal {
        position: relative;
        width: 97%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .section-lock-resend {
          pointer-events: none;
          background: #ffffff;
          position: absolute;
          opacity: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon-lock-resend {
            font-size: 1.8vw;
            background: var(--accent-color);
            color: var(--default-text-color);
            padding: 15px;
            border-radius: 50%;
            opacity: 0.5;
          }
        }
        .config-step-modal {
          height: auto;
          background: #ffffff;
          border-radius: 0.3rem;
          padding: 0.2rem;
          border: 1px solid #cacaca;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: column;
          gap: 5px;
          .header-title-step {
            width: 100%;
            height: 35px;
            font-size: 0.9vw;
            background: #f5f5f5;
            color: #454548;
            border-radius: 0.3rem;
            border: 1px solid #cacaca;
            padding: 0.2rem 0.8rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            .icon-info-resend {
              font-size: 0.9vw;
              opacity: 0.6;
              color: var(--accent-color);
              cursor: help;
            }
          }
          .content-all-config {
            display: flex;
            flex-direction: column;
            gap: 5px;
            .section-to-config-name-and-date {
              width: 100%;
              height: 10vh;
              padding: 0.2rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 5px;
              .change-config-to-resend {
                width: 50%;
                height: 100%;
                border: 1px solid #e1e1e1;
                border-radius: 0.3rem;
                padding: 0.2rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .label-chhange-title {
                  width: 100%;
                  height: 35%;
                  background: #f5f5f5;
                  font-size: 0.7vw;
                  font-weight: bold;
                  color: #454548;
                  border-radius: 0.3rem;
                  border: 1px solid #e1e1e1;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .input-change-title-resend {
                  background: transparent;
                  height: 60%;
                  width: 100%;
                  font-size: 0.8vw;
                  text-align: center;
                  border: none;
                  color: var(--accent-color);
                }
                .input-change-title-resend::placeholder {
                  color: #cacaca;
                }
                .select-date-input {
                  width: 100%;
                  height: 100%;
                  .input-date-to-send {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    .section-date-selected {
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      gap: 0.5rem;
                      .input-date-selected {
                        width: 90%;
                        height: 100%;
                        background: transparent;
                        border: none;
                        text-align: center;
                        font-weight: 500;
                        cursor: pointer;
                        color: var(--accent-color);
                        font-size: 1.5vh;
                      }
                      .input-date-selected::placeholder {
                        color: #cacaca;
                        font-weight: 400;
                      }
                      .icon-date-resend {
                        width: 10%;
                        font-size: 2.5vh;
                        color: var(--accent-color);
                      }
                    }
                  }
                }
              }
            }
          }
          .section-surveys-to-resend {
            display: flex;
            flex-wrap: wrap;
            overflow: auto;
          }
        }
        .config-step {
          opacity: 0;
          overflow: hidden;
        }
      }
    }
    .container-resume-resend-modal {
      width: 25%;
      border-radius: 0.3rem;
      border: 1px solid #cacaca;
      transition: box-shadow 1s;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
    .active-resume {
      box-shadow: 0 1px 11px #808080;
    }
  }
}
</style>
