<template>
  <div class="container-individual-response-card">
    <div class="content-individual-response-card" v-for="response in responsesCard" :key="response._id" v-title="titleResponseCard(response)" title-max-width="320">
      <div class="titles-individual-response-card">
        <i class="material-icons icon-title-individual-response-card" :style="{'color': iconColor(response.question.type)}">{{ iconType(response.question.type) }}</i>
        <p class="title-individual-response-card">{{ titleResponseCard(response) }}</p>
      </div>
      <div v-if="response.formattedValue === $t('dashboard.no_response')" class="option-individual-response-card">
        <div class="option-text-individual-response-card">{{ response.formattedValue }}</div>
      </div>
      <component
        v-else-if="['1to7button', '5emo', 'yn', 'ynm', 'ynd'].includes(response.question.ui)"
        class="component-individual-response-card"
        :is="'app' + response.question.ui.toLowerCase()"
        :response="response.formattedValue"
        :question="response.question"
        :colors="colorSurvey"
      />
      <div v-else-if="!enumSingle(response) && !['matrix', 'referral', 'nvs'].includes(response.question.type) " class="option-individual-response-card">
        <div class="option-ball-individual-response-card" :style="{'--background': colorBall(response.question.type, response.question.ui, response.formattedValue) }">{{ response.formattedValue }}</div>
      </div>
      <div v-else-if="response.question.type === 'matrix'" class="option-individual-response-card">
        <i class="material-icons option-text-individual-response-card only-matrix" v-title="$t('dashboard.matrix_component')">format_list_numbered</i>
      </div>
      <div v-else-if="response.question.type === 'referral'" class="option-individual-response-card">
        <div class="option-text-individual-response-card">{{ response.referralEntriesQty > 1 ? `${response.referralEntriesQty} ${$t("dashboard.referrals")}` : `${response.referralEntriesQty} ${$t('dashboard.referral')}`}}</div>
      </div>
      <div v-else-if="enumSingle(response)" class="option-individual-response-card">
        <div class="option-text-individual-response-card">{{ response.formattedValue }}</div>
      </div>
      <div v-else class="option-individual-response-card">
        <div class="option-text-individual-response-card only-nvs">{{ response.formattedValue }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { metadataManipulator, gradientMaker } from '@/_helpers'
import app10num from '../../survey/answersType/10num.vue'
import app5num from '../../survey/answersType/5num.vue'
import app5nes from '../../survey/answersType/5nes.vue'
import appyn from '../../survey/answersType/kpi-yn.vue'
import appynm from '../../survey/answersType/kpi-ynm.vue'
import appynd from '../../survey/answersType/kpi-ynd.vue'
import app5emo from '../../survey/answersType/5emo.vue'
import apptext from '../../survey/answersType/text.vue'
import appinputext from '../../survey/answersType/input-text.vue'
import appreferral from '../../survey/answersType/referral.vue'
import appsingle from '../../survey/answersType/single.vue'
import appmultiple from '../../survey/answersType/multiple.vue'
import app0to5 from '../../survey/answersType/0to5.vue'
import app1to5 from '../../survey/answersType/1to5.vue'
import app1to7button from '../../survey/answersType/1to7button.vue'
import app0to10 from '../../survey/answersType/0to10.vue'
import app1to10 from '../../survey/answersType/1to10.vue'
import app5radio from '../../survey/answersType/nvs.vue'

export default {
  name: 'IndividualResponseCard',
  props: ['responseData'],
  components: {
    app10num,
    app5num,
    app5nes,
    appyn,
    appynm,
    appynd,
    app5emo,
    apptext,
    appinputext,
    appreferral,
    appsingle,
    appmultiple,
    app0to5,
    app1to5,
    app1to7button,
    app0to10,
    app1to10,
    app5radio
  },
  data () {
    return {
      colorSurvey: { splashColor: '#3f3356' },
      questionTypes: [
        { type: 'csat', name: 'CSAT', value: 'mood', color: '#2ed976cc' },
        { type: 'nps', name: 'NPS', value: 'speed', color: '#382ad6cc' },
        { type: 'kpi', name: 'KPI', value: 'insert_chart', color: '#ff4bd7cc' },
        { type: 'nes', name: 'CES', value: 'fitness_center', color: '#20be2bcc' },
        { type: 'nvs', name: 'NVS', value: 'volunteer_activism', color: '#DDD042cc' },
        { type: 'matrix', name: 'MATRIZ', value: 'format_list_numbered', color: '#0014d6cc' },
        { type: 'ces', name: 'CES 2.0', value: 'fitness_center', color: '#eb7024cc' },
        { type: 'text', name: 'TEXTO', value: 'text_fields', color: '#f0062acc' },
        { type: 'referral', name: 'INDICAÇÃO', value: 'share', color: '#adaf41cc' },
        { type: 'enum', name: 'ENUM', value: '123', color: '#454548' }
      ]
    }
  },
  computed: {
    responsesCard () { return this.responseData.responses.filter(e => e.question.type !== 'anonymous' && e.question.ui !== 'text').slice(0, 3) }
  },
  methods: {
    colorBall (type, ui, value) { return gradientMaker.getColorByValue(type, ui, value) },
    iconColor (type) { return this.questionTypes.find(item => item.type === type).color },
    iconType (type) { return this.questionTypes.find(item => item.type === type).value },
    formatText (title, question, sending) {
      let string = title

      if (!string) { string = question }

      return metadataManipulator.insertMetaOnString(string, sending)
    },
    titleResponseCard (response) { return this.formatText(response.question.title, response.question.question, this.responseData) },
    enumSingle (response) { return response.question.type === 'enum' && (response.question.ui === 'single' || response.question.ui === 'singleOther' || response.question.ui === 'multiple' || response.question.ui === 'multipleOther') }
  }
}
</script>

<style lang="scss">
.container-individual-response-card {
  height: 100%;
  display: flex;
  gap: 0.3vw;
  .content-individual-response-card {
    background: #f6f6f668;
    width: 5.5vw;
    height: 100%;
    border-radius: 8px;
    padding: 0.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    .titles-individual-response-card {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.4vw;
      .title-individual-response-card {
        color: #5e5e5e;
        font-size: 0.9vw;
        max-width: 85%;
        line-height: 0.9vw;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .icon-title-individual-response-card {
        font-size: 0.8vw;
      }
    }
    .component-individual-response-card {
      width: 100%;
      height: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        font-size: 1.3vw;
        line-height: 1.2vw;
      }
      div, button, label {
        width: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(0.7);
        cursor: default;
        span {
          font-size: 1.3vw;
          line-height: 1.2vw;
        }
      }
      label:hover {
        transform: scale(0.7) !important;
      }
    }
    .option-individual-response-card {
      padding-top: 0.2vw;
      width: 100%;
      height: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      .option-ball-individual-response-card {
        background: var(--background);
        color: #ffffff;
        width: 1.75vw;
        height: 1.75vw;
        border-radius: 35px;
        font-weight: bold;
        font-size: 0.8vw;
        transition: transform 0.3s ease;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .option-text-individual-response-card {
        max-width: 100%;
        max-height: 90%;
        background: #ffffff;
        color: #5e5e5e;
        padding: 0.2vw;
        font-size: 0.75vw;
        text-align: center;
        border-radius: 8px;
        border: 1px solid #e1e1e1;
        transition: transform 0.3s ease;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .only-matrix {
        font-size: 1.2vw;
        transition: transform 0.3s ease;
      }
      .only-nvs {
        font-size: 0.45vw;
      }
      .option-ball-individual-response-card:hover, .option-text-individual-response-card:hover {
        transform: scale(1.05);
      }
    }
  }
}
</style>
