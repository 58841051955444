import { findIndex } from 'lodash-es'
import i18n from '../../translations/i18n'

const state = emptySurvey()
function emptyColors () {
  return {
    bodyColor: '#eff3f8',
    buttonBg: '#ff607d',
    buttonColor: '#ffff',
    footerBg: '#fff',
    headerBg: '#fff',
    actionColor: '#64469d',
    actionText: '#ffff',
    headerColor: '#3f3356',
    splashBg: '#fff',
    splashColor: '#3f3356',
    buttonHover: '#ed4d7f'
  }
}

function emptySurvey () {
  return {
    selectedType: 'kpi',
    isTemplate: false,
    version: 0,
    _id: 1,
    title: i18n.t('questionsNav.survey'),
    account: 1,
    animation: {
      isActive: false,
      name: ''
    },
    mergedFields: [],
    ticket: { triggers: [] },
    ticketSettings: [],
    hiddenStartMessage: true,
    hiddenEndMessage: true,
    hiddenAnsweredMessage: true,
    hidePreviousButton: false,
    showSelector: false,
    colors: emptyColors(),
    questionSelected: 0,
    alreadySent: false,
    sendInterval: '',
    responseInterval: '',
    responseIntervalKey: '',
    expireInterval: '',
    sms: `Bindsv2 ${i18n.t('questionsNav.sms_default')}`,
    whatsapp: '',
    showProgressBar: true,
    images: [],
    startMessage: {
      isEnable: false,
      logo: {
        isHidden: false
      },
      imageUrl: '',
      uuid: '',
      title: {
        value: i18n.t('start.survey_title'),
        isHidden: false
      }
    },
    selectedEndMessageIndex: 0,
    endMessages: [
      {
        message: i18n.t('questionsNav.message_title'),
        logo: { isHidden: false },
        ifMetadata: [],
        showIfAny: [],
        showIfAll: []
      }
    ],
    answeredMessage: i18n.t('questionsNav.answered_message_value'),
    answeredMessageDetails: {
      value: '',
      link: {
        label: '',
        value: ''
      },
      redirectUrl: '',
      redirectSec: 5
    },
    defaultDetails: {
      value: i18n.t('questionsNav.details.value'),
      link: {
        label: i18n.t('questionsNav.details.link.label'),
        value: i18n.t('questionsNav.details.link.value')
      }
    },
    questions: [
      {
        id: 0,
        label: '',
        question: '',
        type: 'blank',
        ui: 'blank',
        title: '',
        _id: '',
        gradient: true,
        stars: {
          active: false,
          numbers: false
        },
        notificate: [],
        images: [],
        showIfAny: [],
        showIfAll: [],
        ifMetadata: [],
        message: [],
        then: [],
        nextLabel: i18n.t('questionsNav.nextLabel'),
        randomOptions: false,
        options: [],
        minLength: 0,
        maxLength: 0,
        required: true,
        customTitle: false,
        tooltipOpen: false,
        order: 0,
        icon: 'insert_chart'
      }
    ]
  }
}

const mutations = {
  resetToInitialState (state) {
    Object.assign(state, emptySurvey())
  },
  survey (state, survey) {
    Object.keys(survey).forEach(key => {
      const value = survey[key]
      state[key] = value
    })

    /*
      Nosso store não limpa campos que não existem na survey, ele só dá update nos que mudaram,
      os que não mudam, permanecem como estão. Se em algum momento, é salvo no store a data de expiração
      de uma pesquisa, quando acessamos outra pesquisa, o store mantém o campo "expiredAt".
      Com isso, fica salvo no store uma data de expiração para uma pesquisa que NÃO ESTÁ expirada.
      Checar se o expiredAt existe no state e não na survey do state. Caso exista: nós o excluímos.
    */
    const surveyHasExpiredAt = Object.keys(survey).includes('expiredAt')
    const stateHasExpiredAt = Object.keys(state).includes('expiredAt')
    if (!surveyHasExpiredAt && stateHasExpiredAt) {
      delete state.expiredAt
    }
  },
  setLogo (state, payload) {
    state.images[0] = payload
  },
  clearTheme (state) {
    state.colors = emptyColors()
  },
  saveTheme (state, payload) {
    state.colors = payload
  },
  updateQuestionSettings (state, question) {
    state.questions.map(q => {
      if (q.id === question.id) {
        Object.keys(question).forEach(key => {
          const value = question[key]
          state[key] = value
        })
      }
    })
  },
  updateMessageProps (state, payload) {
    const position = findIndex(state.images, function (item) {
      return item.where === payload.where
    })

    if (position < 0) {
      state.images.push(payload)
    } else {
      state.images[position] = payload
    }
  },
  updateEndMessageProps (state, payload) {
    if (state.selectedEndMessageIndex >= 0) {
      state.endMessages[state.selectedEndMessageIndex].logo.url = payload.value
    }
  },
  updateEndMessageByIndex (state, payload) {
    state.endMessages[payload.idx] = payload.message
  },
  updateImageSmallLogo (state, image) {
    const position = findIndex(state.images, image => {
      return image.alias === 'logoSmall'
    })
    if (position >= 0 && state.images[position]) {
      state.images[position].value = image.logo
    }
  },
  updateImagesLogo (state, image) {
    const position = findIndex(state.images, image => {
      return image.alias === 'logo'
    })
    if (position >= 0 && state.images[position]) {
      state.images[position].value = image.logo
      state.endMessages[0].logo.url = image.logo
    }
  },
  /**
   * update or create one question inside current state of survey
   * OBS: this function may cause problem with the performance if you call then to update many questions
   * because you will do a foreach inside all of the questions, just to update one question
   * imagine this function inside a foreach before you call then
   * @param {Object} state current state of survey
   * @param {Object} updatedQuestion question to update inside current state
   */
  upsertQuestion (state, updatedQuestion) {
    const position = findIndex(state.questions, function (item) {
      if (item._id) {
        return item._id === updatedQuestion._id
      } else {
        return item.order === updatedQuestion.order
      }
    })
    if (position > -1) {
      state.questions.splice(position, 1, updatedQuestion)
    }
  },
  startEmptySurvey (state, params) {
    params.survey = emptySurvey()
  },
  updateAnimation (state, payload) {
    state.animation = payload
  }
}

const getters = {
  getSurvey: state => state,
  getQuestions: state => state.questions,
  getQuestionSelected: state => state.questions[state.questionSelected],
  getSurveySettings: state => state.surveySettings,
  getQuestionSettings: state => state.questions[state.questionSelected].settings,
  getStartMessage: state => state.startMessage,
  getColors: state => state.colors,
  getAnimation: state => state.animation
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
