<template>
  <app-modal
    lightbox
    v-model="isToShow"
    close
    @close="$emit('close')"
    width="98%"
    class="modal-default-primary checkbox-sendhub-link"
  >
    <div class="container-modal" style="max-height: 85vh">
      <div style="display: flex; justify-content: center">
        <div style="width: 44%">
          <app-heading level="h5" style="padding: 20px">
            Pré-visualização
          </app-heading>
          <iframe
            class="iframe-survey-preview-link"
            :key="keyCustomLinkPreview"
            type="text/html"
            :src="link2"
          ></iframe>
        </div>
        <div class="container-custom-fields-link">
          <app-heading level="h5">
            Adicione os campos personalizados e visualize ao lado
          </app-heading>
          <div
            class="container-add-field col-lg-12 col-md-12 col-sm-12 col-xs-12"
          >
            <v-select
              class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-color-input"
              v-model="newFieldType"
              label="label"
              :clearable="false"
              :reduce="(item) => item.value"
              placeholder="Selecione o tipo do campo"
              :options="[
                { label: 'Nome', value: 'name' },
                { label: 'Email', value: 'email' },
                { label: 'Telefone', value: 'phone' },
                { label: 'Campo personalizado', value: 'custom_field_binds' },
              ]"
            ></v-select>
            <v-select
              v-if="newFieldType === 'custom_field_binds'"
              class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-color-input"
              placeholder="Selecione o campo personalizado"
              v-model="newField"
              :clearable="false"
              label="type"
              :options="selects"
            ></v-select>
            <app-button
              class="btn-outline-primary col-lg-3 col-md-3 col-sm-3 col-xs-3"
              style="font-size: 14px; padding: 0px; height: 40px"
              @click="add()"
            >
              Adicionar campo
            </app-button>
          </div>
          <!-- seletor, input text, esconder, mostrar, obrigatório, se já vai vir preenchido, placeholder, label -->
          <div style="max-height: 58vh; overflow: scroll">
            <div
              :style="{ 'z-index': field.zindex }"
              class="container-fields row ml15 mt10 filterModalCheckboxMini"
              v-for="(field, idx) in fields"
              :key="idx"
            >
              <div class="field-key-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <span>
                  {{field.type}}
                </span>
              </div>
              <div
                @click="remove(idx)"
                v-title="'excluir'"
                style="position: absolute; right: 5px; top: 5px"
              >
                <i class="material-icons mobileFilter" style="font-size: 1.3em"
                  >delete</i
                >
              </div>
              <div
                class="col-lg-4 col-md-4 col-sm-4 col-xs-12"
                style="padding-top: 0; position: relative"
              >
                <div
                  title-max-width="300"
                  v-title="'Legenda para descrever o nome do campo'"
                  style="position: absolute; right: 15px; top: 0px; z-index: 2"
                >
                  <i
                    class="material-icons mobileFilter"
                    style="font-size: 1.3em"
                    >info</i
                  >
                </div>
                <app-form-input
                  class="filterInputText"
                  placeholder="Legenda do campo"
                  secondary
                  v-model="field.label"
                >
                </app-form-input>
              </div>
              <div
                class="col-lg-4 col-md-4 col-sm-4 col-xs-12"
                style="padding-top: 0; position: relative"
              >
                <div
                  title-max-width="300"
                  v-title="
                    'Valor inicial do campo, deixe vazio caso não queira que ele já venha preenchido.'
                  "
                  style="position: absolute; right: 15px; top: 0px; z-index: 2"
                >
                  <i
                    class="material-icons mobileFilter"
                    style="font-size: 1.3em"
                    >info</i
                  >
                </div>
                <app-form-input
                  class="filterInputText"
                  placeholder="Valor do campo"
                  secondary
                  v-model="field.value"
                >
                </app-form-input>
              </div>
              <div
                class="col-lg-4 col-md-4 col-sm-4 col-xs-12"
                style="padding-top: 0; position: relative"
              >
                <div
                  title-max-width="300"
                  v-title="
                    'Auxilia no preenchimento do campo, como um exemplo do que escrever.'
                  "
                  title-placement="left"
                  style="position: absolute; right: 15px; top: 0px; z-index: 2"
                >
                  <i
                    class="material-icons mobileFilter"
                    style="font-size: 1.3em"
                    >info</i
                  >
                </div>
                <app-form-input
                  class="filterInputText"
                  placeholder="Descrição do campo"
                  secondary
                  v-model="field.placeholder"
                >
                </app-form-input>
              </div>
              <!-- Digite aqui e crie as opções caso queira um campo do tipo seletor -->
              <div
                class="tags-select col-lg-7 col-md-7 col-sm-7 col-xs-7"
                style="position: relative"
              >
                <div
                  title-max-width="295"
                  v-title="
                    'Digite para começar a criar um campo. Se desejar um valor interno diferente, siga o exemplo: São Paulo**sp'
                  "
                  style="position: absolute; left: -10px; top: 8px; z-index: 2"
                >
                  <i
                    class="material-icons mobileFilter"
                    style="font-size: 1.3em"
                    >info</i
                  >
                </div>
                <v-select
                  label="name"
                  @option:selected="update"
                  @open="field.zindex = '10000'"
                  @close="field.zindex = '0'"
                  :create-option="
                    (book) => ({
                      name: book.split('**')[0],
                      value:
                        book.split('**').length > 1
                          ? book.split('**')[1]
                          : book.split('**')[0],
                    })
                  "
                  placeholder="Opções do seletor"
                  class="survey-selector"
                  v-model="field.options"
                  taggable
                  multiple
                  push-tags
                >
                  <template
                    #option="{ name, value }"
                    style="font-family: Open Sans, Helvetica, Arial, sans-serif"
                  >
                    <h3 style="margin: 0">nome: {{ name }}</h3>
                    <em>valor: {{ value }}</em>
                  </template>
                </v-select>
              </div>
              <div class="checkbox-container">
                <app-form-checkbox-mini
                  :initial-value="field.showThisField"
                  :label="'Exibir na pesquisa'"
                  @valueChanged="field.showThisField = $event"
                  :labelColor="user.account.theme.colors.primary"
                />
                <app-form-checkbox-mini
                  :initial-value="field.required"
                  :label="'Preenchimento obrigatório'"
                  @valueChanged="field.required = $event"
                  :labelColor="user.account.theme.colors.primary"
                />
              </div>
            </div>
          </div>

          <app-button
            class="btn-outline-primary col-lg-12 col-md-12 col-sm-12 col-xs-12"
            style="font-size: 14px; height: 40px"
            @click="update"
            v-if="fields.length > 0"
          >
            Atualizar visualização
          </app-button>
          <app-button
            class="btn-outline-primary col-lg-12 col-md-12 col-sm-12 col-xs-12"
            style="font-size: 14px; height: 40px"
            @click="copyCustomLink"
            v-if="fields.length > 0"
          >
            Copiar link personalizado
          </app-button>
        </div>
      </div>
    </div>
  </app-modal>
</template>

<script>
import { sendhubService } from '@/_services'
import { mapGetters } from 'vuex'

export default {
  name: 'CustomLink', // esse componente é uma feature interna, por isso a estrutura sem tradução etc
  props: ['openModal'],
  components: {
    'app-button': () =>
      import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-heading': () =>
      import(
        '@binds-tech/binds-design-system/src/components/Typography/Heading'
      ),
    'app-form-input': () =>
      import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-modal': () =>
      import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-form-checkbox-mini': () =>
      import(
        '@binds-tech/binds-design-system/src/components/Form/CheckboxMini'
      ),
    vSelect: () => import('vue-select')
  },
  data () {
    return {
      isToShow: true,
      surveyHashId: '',
      mykey: 0,
      customlink: '',
      newFieldType: '',
      newField: '',
      keyCustomLinkPreview: 0,
      selects: [],
      fields: []
    }
  },
  async mounted () {
    const survey = this.$store.getters['survey/getSurvey']
    this.surveyHashId = survey.hashId
    this.user.account.fields.forEach((metadata) => {
      this.selects.push({
        type: metadata.key,
        placeholder: '',
        showThisField: true,
        label: metadata.label,
        value: '',
        required: true,
        zindex: 0
      })
    })
    this.update()
  },
  computed: {
    link () {
      return this.mountLink(false, false)
    },
    link2 () {
      return this.mountLink(true, true)
    },
    ...mapGetters({
      user: 'account/getUser',
      linkSettings: 'sendhub/getLinkSettings'
    })
  },
  methods: {
    dropDownShouldOpen (field) {
      if (this.country !== null) {
        field.zindex = 1
        return false
      }
      field.zindex = 0
      return true
    },
    add () {
      if (!this.newFieldType) {
        return
      }
      if (this.newFieldType !== 'custom_field_binds') {
        this.newField = {
          type: this.newFieldType,
          placeholder: 'Digite aqui',
          showThisField: true,
          label: 'Legenda do campo',
          value: '',
          required: true,
          zindex: 0
        }
      }
      if (!this.newField) {
        return
      }
      const hasAnother = this.fields.filter(
        (item) => item.type === this.newField.type
      )
      if (hasAnother.length > 0) {
        return this.$store.commit('alerts/alert', {
          message: 'Já existe um campo como este (com a mesma chave interna)',
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      }
      this.fields.push(this.newField)
      this.update()
      this.newField = ''
    },
    remove (idx) {
      this.fields.splice(idx, 1)
      this.update()
    },
    mountLink (isCustom, isPreview) {
      let linkUrl = 'https://surveyv2.binds.co/#/link/' + this.surveyHashId
      if (this.linkSettings) {
        linkUrl = linkUrl + '/?'
        if (this.linkSettings.allowMoreThanOne) {
          linkUrl = linkUrl + 'loop=true&'
        }
        if (this.linkSettings.showEndSurveyButton) {
          linkUrl = linkUrl + 'close=true&'
        }
        if (this.linkSettings.endSurveyByInactivity && !isPreview) {
          linkUrl = linkUrl + 'timeout=' + this.linkSettings.seconds + '&'
        }
      }
      if (isCustom) {
        return linkUrl + 'fields=' + JSON.stringify(this.fields)
      }
      return linkUrl
    },
    update () {
      this.keyCustomLinkPreview++
    },
    copyCustomLink () {
      try {
        navigator.clipboard.writeText(this.mountLink(true, false))

        this.$store.commit('alerts/alert', {
          message: 'URL da Pesquisa copiado para a área de transferência.',
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      } catch (err) {
        this.$store.commit('alerts/alert', {
          message: 'Não foi possível copiar',
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      }
    },
    async updateLink () {
      const surveyHashId = this.$store.getters['survey/getSurvey'].hashId
      const totenSettings = this.$store.getters['sendhub/getTotenSettings']
      this.link = await sendhubService.mountLink(surveyHashId, totenSettings)
    },
    copyToClipboard () {
      const testingCodeToCopy = document.querySelector('#addToClipboard')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        document.execCommand('copy')

        this.$store.commit('alerts/alert', {
          message: 'URL da Pesquisa copiado para a área de transferência.',
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      } catch (err) {
        this.$store.commit('alerts/alert', {
          message: 'Não foi possível copiar',
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      }
    }
  }
}
</script>

<style lang="scss">
.checkbox-sendhub-link {
  .input-wrapper:after {
    display: none;
  }

  .filterInputText > .text-field__input > input {
    color: #7f7f7f !important;
  }
  .filterInputText > .text-field__input {
    box-shadow: none!important;
    border-radius: 7px;
    background-color: #fee !important;
  }
  .survey-selector .vs__dropdown-toggle {
    height: auto;
    box-shadow: none !important;
  }
  .tags-select .vs__dropdown-toggle {
    height: auto !important;
    min-height: 45px !important;
    background: #f1f1f1 !important;
  }
  .checkbox-container {
    text-align: left;
    .input-wrapper {
      display: flex;
      margin-bottom: 10px;
      padding-top: 0px;
    }
  }
}
.container-custom-fields-link {
  width: 60%;
  height: 75vh;
  border-left: 2px solid #f1f1f1;
  padding: 20px;
  .container-fields {
    position: relative;
    z-index: 0;
    filter: grayscale(1);
    border-radius: 17px;
    margin: 10 auto !important;
    padding: 10px !important;
    width: 97%;
    margin-bottom: 20px !important;
    box-shadow: rgba(48, 48, 48, 0.14) 0px 0px 10px 0px,
      rgba(0, 0, 0, 0.01) 0px 5px 4px 0px !important;
    justify-content: center;
  }
}
.container-add-field {
  align-items: center;
  padding: 7px;
  text-align: center;
  justify-content: center;
  display: flex;
}
.iframe-survey-preview-link {
  width: 100%;
  height: 100vh;
  margin-top: -82px !important;
  transform: scale(0.8);
  box-shadow: none !important;
}
.field-key-container {
  text-align: center;
  background: #f1f1f1;
  margin: 20px 15px;
  border-radius: 10px;
  color: #898989;
}
</style>
