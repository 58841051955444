<template>
  <div style="widht: 100vw; height: 100vh; overflow: hidden">
    <div class="loading-survey-list" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <Menu :showSubMenu="false"></Menu>
    <attendant-modal
      :sending="ticketId"
      v-if="showAttendantModal"
      :previousAttendant="currentTicket.attendant"
      @close-manual-tickets="showAttendantModal = false"
      @update-manual-tickets="updateAttendantModal($event)"
    ></attendant-modal>
    <app-modal
      close
      lightbox
      :title="$t('tickets.confirm_action')"
      v-model="showDeleteModal"
      :primary-button="$t('tickets.close_ticket')"
      @primaryButtonClick="closeTicket()"
      :secondary-button="$t('tickets.cancel')"
      @secondaryButtonClick="showDeleteModal = false"
      class="modal-default-primary modal-ticket-chat"
    >
      <app-heading level="h6">{{ $t('tickets.confirm_action_text') }}</app-heading>
    </app-modal>
    <div class="container-tickets" v-if="canShowThePage">
      <div class="content-chat">
        <div class="list-called">
          <div class="filters-category">
            <div class="choose-category">
              <treeselect
                v-model="selectedCategory"
                :options="categories"
                :placeholder="$t('tickets.category.head_title')"
                :normalizer="normalizerCategory"
                @input="getTickets()"/>
            </div>
            <div class="status-category">
              <treeselect
                v-model="selectedStatus"
                :options="status"
                :normalizer="normalizerStatus"
                @input="getTickets()"/>
            </div>
          </div>
          <div class="row" style="padding: 0px 5px;">
            <div class="status-category" v-if="selectedStatus === 'assigned' || selectedStatus === 'closed'">
              <treeselect
                v-model="selectedAttendant"
                :options="users"
                :placeholder="$t('tickets.any_attendant')"
                :normalizer="normalizerAttendant"
                @input="getTickets()"/>
            </div>
          </div>
          <div class="row search-tickets-row">
            <app-form-input
              secondary
              v-model="search"
              class="search-tickets"
              :placeholder="$t('tickets.search')"
              @keyup.enter="getFilteredTickets()"
            />
          </div>
          <div class="chat-list" v-if="tickets.length === 0">
            <div class="row list">
              <div class="row center-lg center-md center-sm no">
                <app-heading level="h6" class="no-items">{{ $t('tickets.no_more') }}</app-heading>
              </div>
            </div>
          </div>
          <div class="chat-list" ref="chatList" v-if="tickets.length > 0">
            <div class="row list">
              <div class="ticket-item" :ref="`ticket-${ticket._id}`" v-for="(ticket, index) in tickets" :class="{'ticket-selected': ticket.selected}" :key="index">
                <div class="ticket-card" @click="seeTicket(ticket)" >
                  <div class="row">
                    <div class="col-lg-7 col-md-7 col-sm-7 infos">
                      <div>
                        <app-heading level="h6" class="contact-name">{{ ticket.contact.name }}</app-heading>
                        <app-heading level="h7" class="contact-email">{{ ticket.contact.email }}</app-heading>
                        <app-heading v-if="!ticket.attendant" level="h7" class="ellipsis-sending dark-text no-attendant">{{ $t('tickets.no_attendant') }}</app-heading>
                        <app-heading v-if="ticket.attendant" level="h7" class="ellipsis-sending dark-text attendant-title">{{ $t('tickets.attendant') }}: {{ ticket.attendant.name }}</app-heading>
                        <app-heading level="h7" class="ellipsis-sending dark-text ticket-date">{{ ticket.date }}</app-heading>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row center-lg center-md center-sm easy-loading">
                <i class="material-icons more-button" v-if="!loadingMore && !noMorePages" v-title="$t('tickets.load_more')" @click="getTickets(true)">more_horiz</i>
                <Circle2 class="circle-ticket" v-if="loadingMore && !noMorePages"></Circle2>
                <app-heading level="h6" class="no-items" v-if="noMorePages">{{ $t('tickets.no_more') }}</app-heading>
              </div>
            </div>
          </div>
        </div>
        <div class="container-chat">
          <div class="chat-title-row">
            <div class="transfer-ticket" style="padding: 0px;">
              <app-heading class="chat-title" level="h5">Chat</app-heading>
            </div>
            <div class="transfer-ticket" v-if="ticketId">
              <div class="hvr-border" v-title="$t('tickets.change_attendant')" title-placement="left" @click="showAttendantModal = true">
                <i class="material-icons cat-icon">redo</i>
                <app-heading v-if="currentTicket.attendant" level="h6" class="at-name">{{ currentTicket.attendant.name }}</app-heading>
                <app-heading v-else level="h6" class="at-name">{{ $t('tickets.no_attendant') }}</app-heading>
              </div>
            </div>
            <div class="transfer-ticket" v-on:click="openCategories = true" v-if="ticketId">
              <div class="hvr-border" v-title="$t('tickets.change_category')" title-placement="left">
                <i class="material-icons cat-icon">local_offer</i>
                <app-heading v-if="currentTicket.ticketCategory" level="h6" class="at-name">{{ currentTicket.ticketCategory.title }}</app-heading>
                <app-heading v-else level="h6" class="at-name">{{ $t('tickets.select_category') }}</app-heading>
              </div>
            </div>
            <div class="transfer-ticket" v-if="ticketId">
              <div class="hvr-border" v-on:click="showDetails = true">
                <i class="material-icons cat-icon">info</i>
                <app-heading level="h6" class="at-name">{{ $t('survey.details') }}</app-heading>
              </div>
            </div>
            <div class="transfer-ticket btn-close-ticket" v-if="currentTicket.lastStatusUpdate && ticketId">
              <app-button v-if="currentTicket.lastStatusUpdate.label !== 'closed'" @click="showDeleteModal = true">{{ $t('tickets.close_ticket') }}</app-button>
              <app-button v-else @click="reopenTicket()">{{ $t('tickets.open_ticket') }}</app-button>
            </div>
          </div>
          <Chat v-if="ticketId" class="chat-box" :id="ticketId" :ticketStatus="currentTicket.lastStatusUpdate"/>
          <div v-else-if="!loading" class="row center-lg center-md center-sm no-ticket-msg">
            <app-heading level="h4">{{ $t('tickets.no_ticket') }}</app-heading>
          </div>
        </div>
      </div>
    </div>
     <!-- <Categories>-->
    <app-modal
      v-model="openCategories"
      class="modal-default-primary modal-categories"
      lightbox
      close
    >
      <div>
        <categories class="chat-box" :ticketId="ticketId" :currentTicket="currentTicket.ticketCategory" @updateCategory="setNewCAtegory($event)"/>
      </div>
    </app-modal>
    <!-- END <Categories>-->
    <!-- Details -->
    <Details
      v-if="showDetails"
      :id="ticketId"
      @closeSendings="closeSidebar"
      :class="{'expand-details': showDetails}"
    ></Details>
    <!-- END Details -->
    <theme-style>
      .container-tickets > .filters-category {
        background-color: {{user.account.theme.colors.primary  || '#ed4d7f'}};
      }
      .container-tickets > .content-chat > .container-chat > .chat-title-row > .btn-close-ticket > button {
        background: {{user.account.theme.colors.accent || '#673ab7'}} !important;
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      }
      .container-tickets > .content-chat > .container-chat > .chat-title-row > .transfer-ticket > h5 {
        color: {{user.account.theme.colors.accent  || '#ed4d7f'}} !important;
      }
      .container-tickets > .content-chat > .container-chat > .chat-title-row > .transfer-ticket > div > .cat-icon {
        color: {{user.account.theme.colors.accent  || '#ed4d7f'}} !important;
      }
      .container-tickets > .content-chat > .container-chat > .chat-title-row > .transfer-ticket > div > h6 {
        color: {{user.account.theme.colors.accent  || '#ed4d7f'}} !important;
      }
      .container-tickets > .content-chat > .container-chat > .chat-box > .send-spot > .send > .send-btn {
        color: {{user.account.theme.colors.accent  || '#ed4d7f'}} !important;
      }
      .container-tickets > .content-chat > .container-chat > .chat-box > .messages-height > div > .start-sm > .received-message {
        background-color: {{user.account.theme.colors.accent  || '#ed4d7f'}} !important;
        border-color: {{user.account.theme.colors.accent  || '#ed4d7f'}} !important;
      }
      .container-tickets > .content-chat > .list-called > .chat-list > .list > .ticket-selected {
        background-color: {{ user.account.theme.colors.accent  || '#ed4d7f20'}} !important;
      }
      .vue-treeselect__control-arrow {
        color: {{ user.account.theme.colors.accent  || '#ed4d7f20'}} !important;
      }
    </theme-style>
  </div>
</template>
<script>
import { ticketsService, surveyService } from '@/_services'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Menu from '../components/Menu.vue'
import Chat from '../components/tickets/Chat.vue'
import Categories from '../components/tickets/Categories.vue'
import AttendantModal from '../components/dashboard/individual/ManualTicketsModal.vue'
import Store from '@/store/'

export default {
  name: 'Tickets',
  data () {
    return {
      status: [
        // Não trocar a ordem dos objetos
        { label: this.$i18n.t('tickets.status.all'), value: 'all' },
        { label: this.$i18n.t('tickets.status.opened'), value: 'assigned,unassigned' },
        { label: this.$i18n.t('tickets.status.assigned'), value: 'assigned' },
        { label: this.$i18n.t('tickets.status.unassigned'), value: 'unassigned' },
        { label: this.$i18n.t('tickets.status.closed'), value: 'closed' }
      ],
      selectedCategory: undefined,
      showAttendantModal: false,
      selectedStatus: 'assigned,unassigned',
      selectedAttendant: undefined,
      showDeleteModal: false,
      currentUser: undefined,
      ticketId: undefined,
      loadingMore: false,
      noMorePages: false,
      currentTicket: {},
      categories: [],
      loading: false,
      prevRoute: '',
      tickets: [],
      search: '',
      users: [],
      start: 0,
      end: 19,
      openCategories: false,
      showDetails: false,
      canShowThePage: false,
      statusHasChanged: false
    }
  },
  beforeRouteEnter (to, from, next) {
    const user = Store.getters['account/getUser']
    if (!user.features.tickets || user.features.hiddenSendingContacts) {
      return next({ name: 'YouCant' })
    }
    next(vm => {
      vm.prevRoute = from
    })
  },
  components: {
    Treeselect,
    Menu,
    Chat,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    Details: () => import('../components/SideBarConfig/SidebarSendings.vue'),
    Categories,
    AttendantModal
  },
  beforeDestroy () {
    this.$root.$off('chat-ready')
  },
  watch: {
    selectedStatus (newV, oldV) {
      if (oldV !== newV) this.statusHasChanged = true
      else this.statusHasChanged = false
    }
  },
  async mounted () {
    this.canShowThePage = false
    this.loading = true
    this.currentTicket = this.$route.query

    const validateIfHasSurveysOnAccount = await surveyService.getAllSurveys() // n usamos as surveys nessa tela, mas precisamos checar se o usuário tem pesquisas criadas
    if (validateIfHasSurveysOnAccount.length <= 0) {
      return this.$router.push({ name: 'Start' })
    }
    const categories = await ticketsService.getTicketCategories()
    if (categories) {
      this.categories = categories
      this.categories.unshift({ title: this.$i18n.t('tickets.all_categories'), _id: 'all' })
    }

    const users = await ticketsService.getUsersWithSuperadminParam()
    if (users) {
      this.users = users
    }

    this.getTickets()

    this.$root.$on('chat-ready', () => {
      this.loading = false
    })

    this.canShowThePage = true
  },
  computed: {
    user () {
      const user = this.$store.getters['account/getUser']
      return user
    },
    lang () {
      return this.$store.getters['account/getLang']
    }
  },
  methods: {
    normalizerCategory (node) {
      return {
        id: node._id,
        label: node.title,
        children: node.subcategories
      }
    },
    normalizerStatus (node) {
      return {
        id: node.value,
        label: node.label
      }
    },
    normalizerAttendant (node) {
      return {
        id: node._id,
        label: node.name
      }
    },
    goTo (ref) {
      const refName = `ticket-${ref}`
      const element = this.$refs[refName]
      const top = element[0].offsetTop
      this.$refs.chatList.scrollTo(0, top)
    },
    closeSidebar () {
      this.showDetails = false
    },
    async setNewCAtegory (e) {
      if (e.title !== null) {
        const newCategory = await ticketsService.categorizeTicket({ category: e.id }, this.ticketId)
        if (newCategory) {
          this.currentTicket.ticketCategory = e
          this.openCategories = false
        }
      } else {
        // Without Cateogry
        const newCategory = await ticketsService.categorizeTicket({ category: null }, this.ticketId)
        if (newCategory) {
          this.currentTicket.ticketCategory = { title: this.$i18n.t('tickets.category.no_category'), _id: null }
          this.openCategories = false
        }
      }
    },
    async getFilteredTickets () {
      const category = this.selectedCategory !== 'all' ? this.selectedCategory : undefined
      if (this.search.length > 0) {
        this.loading = true
        const result = await ticketsService.getTicketsByStatus(this.search, category, this.selectedStatus, this.selectedAttendant)
        this.loading = false
        if (result) {
          this.tickets = result
          if (this.tickets.length > 0) this.seeTicket(this.tickets[0])
          else this.ticketId = null
        }
      } else {
        this.getTickets(false, true) // first parameter: without loadmore, second: came from search
      }
    },
    updateTicketsUrl (ticketId) {
      // Só adiciona um atributo, se existir o valor a ser adicionado. Checa o valor: se ele existir, adiciona chave e valor.
      const objToLoad = {
        _id: ticketId,
        ...(this.selectedStatus && { status: this.selectedStatus }),
        ...(this.selectedCategory && { category: this.selectedCategory }),
        ...((this.selectedAttendant && ['assigned', 'closed'].indexOf(this.selectedStatus) >= 0) && { attendant: this.selectedAttendant })
      }
      this.$router.push({ name: 'Tickets', query: objToLoad }).catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error when updateing url with filters', err) })
      this.$forceUpdate()
    },
    async getTickets (loadMore, search) {
      if (loadMore) {
        this.loadingMore = true
        this.end += 20
      } else {
        this.loading = true
        this.end = 19
        this.tickets = []
      }
      const category = this.selectedCategory !== 'all' ? this.selectedCategory : undefined
      const tickets = await ticketsService.getTicketsByStatus(null, category, this.selectedStatus, this.selectedAttendant, this.start, this.end)
      if (tickets && tickets.length > 0) {
        this.tickets = tickets

        if (search) this.seeTicket(this.tickets[0]) // sets the first chat when search bar is cleared

        if (tickets.length < this.end) {
          this.noMorePages = true
        }

        this.getTicketFromId()

        this.loadingMore = false
      } else {
        this.currentTicket = {}
        this.ticketId = ''
        this.loading = false
      }
    },
    async getTicketFromId () {
      let selectedTicket = this.tickets[0]
      if (this.currentTicket._id) {
        const ticketById = await ticketsService.getTicketById(this.currentTicket._id)
        if (!ticketById) return false
        selectedTicket = ticketById[0]
        selectedTicket.date = this.$moment(selectedTicket.lastInteraction).locale(this.lang).fromNow()
        selectedTicket.selected = true
      }

      if (this.statusHasChanged) this.seeTicket(this.tickets[0])
      else this.seeTicket(selectedTicket)

      this.statusHasChanged = false
    },
    seeTicket (ticket) {
      this.currentTicket = ticket
      const findTicketInList = this.tickets.find(e => e._id === ticket._id)

      if (!findTicketInList) { this.tickets.unshift(ticket) }

      this.tickets.map(i => {
        i.date = this.$moment(i.lastInteraction).locale(this.lang).fromNow()
        i.selected = false
        if (i._id === ticket._id) {
          i.selected = true
          this.ticketId = i._id
        }
      })

      this.$root.$emit('chat-changed')
      this.updateTicketsUrl(this.currentTicket._id)
      this.$forceUpdate()
    },
    async closeTicket () {
      this.showDeleteModal = false
      this.loading = true
      const responseTicket = await ticketsService.closeTicket(this.currentTicket._id)
      if (!responseTicket) {
        this.$store.commit('alerts/alert', {
          message: this.$t('surveyList.error_go_to'),
          position: 'bottomRight',
          showAlert: true
        })
      } else {
        const indexToDelete = this.tickets.findIndex(i => i._id === this.currentTicket._id)

        if (indexToDelete >= 0) {
          this.tickets.splice(indexToDelete, 1)
          this.currentTicket = {}
          this.ticketId = ''

          this.$store.commit('alerts/alert', {
            message: this.$t('tickets.closed'),
            position: 'bottomRight',
            showAlert: true
          })
        }
      }
      this.loading = false
    },
    async reopenTicket () {
      this.loading = true
      const responseTicket = await ticketsService.reopenTicket(this.currentTicket._id)

      if (!responseTicket) {
        this.$store.commit('alerts/alert', {
          message: this.$t('surveyList.error_go_to'),
          position: 'bottomRight',
          showAlert: true
        })
      } else {
        const indexToReopen = this.tickets.findIndex(i => i._id === this.currentTicket._id)

        if (indexToReopen >= 0) {
          this.tickets.splice(indexToReopen, 1)
          this.currentTicket = {}
          this.ticketId = ''

          this.$store.commit('alerts/alert', {
            message: this.$t('tickets.opened'),
            position: 'bottomRight',
            showAlert: true
          })
        }
      }
      this.loading = false
    },
    updateAttendantModal (e) {
      this.currentTicket.attendant = e
      this.loading = true
      this.showAttendantModal = false
      this.seeTicket(this.currentTicket)
    }
  }
}
</script>
<style lang="scss">
.loading-survey-list {
  background: #EFF3F840;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner-loading-survey-list {
    width: 2.1vw !important;
    height: 2.1vw !important;
    border-width: 3px !important;
    border-radius: 35px;
    border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
  }
}
.container-tickets {
  height: 91vh;
  width: 100vw;
  .content-chat {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .list-called {
      width: 25%;
      height: 100%;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
      .filters-category {
        width: 100%;
        height: 8%;
        display: flex;
        .status-category {
          padding: 0 5px;
          min-width: 50%;
          display: flex;
          align-items: center;
        }
        .choose-category {
          padding: 0 5px;
          min-width: 50%;
          display: flex;
          align-items: center;
        }
        .style-chooser {
          min-width: 100%;
          background: #FFFFFF;
          border-radius: 4px;
        }
        .vue-treeselect__control {
          .vue-treeselect__x-container {
            display: none;
          }
          .vue-treeselect__single-value {
            color: #a5a5a2 !important;
          }
        }
      }
      .search-tickets-row {
        background: #FFFFFF;
        width: 100%;
        height: 8%;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 0.5px solid #cacaca;
        input {
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.26);
          border-radius: 35px;
        }
      }
      .chat-list {
        width: 100%;
        height: 85%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.26);
        border-right: 0.5px solid #cacaca;
        .list {
          min-height: 20%;
          max-height: 100%;
          overflow-x: hidden;
          overflow-y: auto;
        }
        .list::-webkit-scrollbar-thumb {
          background: gray;
        }
        .easy-loading {
          height: 100px;
          .no-items {
            font-size: 15px;
            font-style: italic;
          }
          .more-button {
            color: #424242 !important;
          }
        }
      }
    }
    .container-chat {
      width: 75%;
      height: 100%;
      .chat-title-row {
        background: #FFFFFF;
        width: 100%;
        height: 8%;
        box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.26);
        display: flex;
        justify-content: space-around;
        align-items: center;
        .transfer-ticket {
          height: 100%;
          width: 20%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .btn-close-ticket {
          & .binds-button.medium {
            font-size: 12px;
            width: 150px;
          }
          & .binds-button.default.outline {
            color: #fff;
          }
        }
        .btn-close-ticket:nth-child(5) {
          display: flex;
          justify-content: flex-end;
          padding-right: 15px;
        }
      }
      .chat-box {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
  }
}
.ticket-card {
  margin: 12px auto;
  max-height: 80px !important;
  min-height: 80px !important;
  width: 100%;
  & .infos {
    padding-top: 0px !important;
    padding-left: 20px !important;
    .heading {
      color: #000000 !important;
    }
  }
  & .ellipsis {
    width: 75%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & .ellipsis-sending {
    width: 230px;
    height: 32px;
    overflow: hidden;
    white-space: nowrap;
  }
}
.change-button {
  color: gray;
  cursor: pointer;
  font-size: 25px !important;
}
.more-button {
  color: gray;
  cursor: pointer;
  font-size: 35px !important;
}
.circle-ticket {
  margin: 20px;
  width: 20px !important;
  height: 20px !important;
  border-color: rgb(77, 1, 90) rgb(161, 106, 206) rgb(161, 106, 206) !important;
}
.search-tickets {
  width: 100% !important;
  & .text-field__input {
    border: 1px solid #b5b5b5;
    border-radius: 20px !important;
    width: 100% !important;
    height: 35px !important;
  }
  & input {
    padding: 0px 15px !important;
    font-style: italic !important;
  }
}
.no {
  background: #FFFFFF;
  display: flex;
  align-items: center;
  .no-items {
    font-size: 14px !important;
    font-style: italic;
    margin-top: 10px !important;
    margin-bottom:20px !important;
  }
}
.filter-tickets {
  height: 59px;
}
.filters-row {
  border-right: 1px solid gray;
  padding: 0px !important;
}
.ticket-item {
  width: 100%;
  height: 110px;
  background-color: #fff;
  border-bottom: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
  border-left: 1px solid #cacaca;
}
.no-ticket-msg {
  margin-top: 20% !important;
  h6 {
    color: #696868 !important;
  }
}
.no-attendant {
  font-size: 12px !important;
  margin-top: 20px !important;
  font-weight: 600 !important;
}
.attendant-title {
  font-size: 12px !important;
  margin-top: 15px !important;
  font-weight: 600 !important;
}
.ticket-date {
  margin-top: -20px !important;
  font-style: italic !important;
  font-size: 12px !important;
}
.contact-name {
  font-weight: 600 !important;
  min-width: 400px !important;
}
.contact-email {
  margin-top: 5px !important;
  font-size: 12px !important;
}
.chat-title {
  color: #fff !important;
  padding: 20px 15px !important;
}
.p0 {
  padding: 0px !important;
}
.ticket-selected {
  .ticket-card {
    .heading {
      color: #ffffff !important;
    }
  }
  background-color: #a7a7a7;
}
.chat-list::-webkit-scrollbar-thumb {
  background-color: #979797;
}
.transfer-ticket {
  & .hvr-border {
    cursor: pointer;
    display: flex;
  }
  & i {
    color: #fff;
  }
}
.at-name {
  margin: 6px 0px 0px 10px !important;
  color: #fff !important;
  font-size: 12px !important;
}
.cat-icon {
  font-size: 20px !important;
  margin-top: 2px;
}
.modal-categories {
  .modal__wrapper {
    width: 50% !important;
    padding: 0 !important;
    &:before {
      background-color: #696868 !important;
    }
  }
}
.modal__wrapper__content {padding: 0 !important;}
  .modal-ticket-chat {
    & .modal__wrapper {
      h4 {
        font-size: 20px;
        font-weight: 600;
        margin-top: -5px !important;
      }
      h6 {
        margin-top: 20px;
      }
      .binds-button.secondary {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
</style>
