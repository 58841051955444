<template>
  <DefaultStructure>
    <div class="fadeInIt" id="form">
      <div v-if="currentActive < 2">
        <span
          @click="currentActive = index"
          v-for="(tab, index) in tabs"
          :key="tab.label"
          class="loginTabs"
          :class="{
            activeLoginTab: currentActive === index,
          }"
          >{{ $t("login." + tab.label) }}</span
        >
      </div>
      <div v-else>
        <span v-if="currentActive === 2" class="loginTabs activeLoginTab"
          >Single Sign-On</span
        >
        <span v-if="currentActive === 3" class="loginTabs activeLoginTab">{{
          $t("login.reset")
        }}</span>
      </div>
      <login-form v-show="currentActive === 0"></login-form>
      <sign-up-form v-show="currentActive === 1" @go-to-login="currentActive = 0"></sign-up-form>
      <single-sign-form v-show="currentActive === 2"></single-sign-form>
      <reset-pass-form v-show="currentActive === 3"></reset-pass-form>
    </div>
  </DefaultStructure>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import DefaultStructure from '@/components/loginForms/DefaultStructure.vue'
import LoginForm from '@/components/loginForms/Login.vue'
import SignUpForm from '@/components/loginForms/SignUp.vue'
import SingleSignForm from '@/components/loginForms/SingleSignOn.vue'
import ResetPassForm from '@/components/loginForms/ResetPass.vue'

export default {
  name: 'Login',
  data () {
    return {
      currentActive: 0,
      tabs: [{ label: 'welcome' }, { label: 'new_account' }]
    }
  },
  components: {
    DefaultStructure,
    SignUpForm,
    LoginForm,
    SingleSignForm,
    ResetPassForm
  },
  beforeDestroy () {
    this.$root.$off('update-current-active')
  },
  created () {
    if (this.$route.name === 'SignUp' || this.$route.name === 'LoginFromSite') {
      const initial = {
        'pt-br': 'pt-BR',
        'en-us': 'en-US',
        'es-cl': 'es-CL'
      }
      this.$i18n.locale = initial[this.$route.params.lang]
      this.currentActive = this.$route.name === 'LoginFromSite' ? 0 : 1
    }
  },
  mounted () {
    this.$root.$on('update-current-active', (value) => {
      this.currentActive = value
    })
  },
  computed: {
    ...mapState('account', ['status'])
  },
  methods: {
    ...mapActions('account', ['login']),
    modalRedirect () {
      const token = window.localStorage.getItem('bindsUser')
        ? JSON.parse(window.localStorage.getItem('bindsUser'))
        : ''
      const url = `${process.env.VUE_APP_V1}login/authenticationWithSSO/setToken?token=${token}`
      window.location.href = url
    }
  }
}
</script>
<style lang="scss">
</style>
