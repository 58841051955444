<template>
  <div class="single single-create" :class="{ 'widget-preview-single': isWidget && question.ui === 'singleOther' }">
    <div v-if="render" class="options-box">
      <div v-for="(item, index) in question.options" :key="index">
        <div class="row middle-xl middle-lg middle-md middle-sm middle-xs">
          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1">
            <app-form-radio class="single-radio"/>
          </div>
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <edit-title
              :class="{ 'special-pad': item.label.length <= 40 }"
              class="text-space"
              v-if="!preview"
              :title="item.label"
              :color="colors.splashColor"
              @updateSurveyTitle="updateQuestionDescription($event,index)"
              :identify="`single-${index}`"
              :minHeight="15"
              :maxHeight="90"
              type="textarea"
            />
            <div class="subject-options" v-else>
              <app-heading level="h6" :style="{'color': colors.splashColor }">{{ item.label }}</app-heading>
            </div>
          </div>
          <div v-if="!preview && question.options.length > 1" class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 p0">
            <button v-on:click="deleteItem(index)">
              <i class="material-icons del-single-op">delete</i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row single-ui" v-if="!preview" :class="{ 'add-single-other': question.ui === 'singleOther' }">
      <button v-on:click="addItem()">
        <i class="material-icons">add</i>
      </button>
    </div>
    <div v-if="question.ui === 'singleOther'" class="row middle-xl middle-gl middle-md middle-sm middle-xs widget">
      <div class="r-other col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 p0">
        <app-form-radio class="single-radio"/>
      </div>
      <div class="i-other col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10">
        <!-- edit-title ficará desabilitado quando for a "other", a edição é pra ser feita só na barra lateral de configurações -->
        <edit-title
          class="text-space-single"
          v-if="!preview"
          style="pointer-events: none;"
          :title="question.otherLabel"
          :color="colors.splashColor"
          @updateSurveyTitle="updateOtherLabel($event)"
          :identify="`other-${question._id}`"
          :minHeight="15"
          :maxHeight="90"
          type="textarea"
        />
        <div v-else>
          <app-heading level="h6" :style="{'color': colors.splashColor }">{{ question.otherLabel }}</app-heading>
        </div>
      </div>
    </div>
    <div class="row" v-if="question.ui === 'singleOther' && !preview" style='margin-left: 20px'>
      <app-form-input
        :placeholder="question.placeholder ? question.placeholder : `${$t('survey.placeholder')}`"
        border-bottom
        class="other-input-single col-lg-10 col-md-10 col-sm-10"
        value=""
      ></app-form-input>
    </div>
    <theme-style>
      .single-ui > button {
        background-color: {{user.account.theme.colors.accent  || '#ed4d7f'}} !important;
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      }
    </theme-style>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditTitle from '../../EditTitle.vue'
export default {
  name: 'appsingle',
  props: ['colors', 'question', 'preview', 'isWidget'],
  components: {
    EditTitle,
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-form-radio': () => import('@binds-tech/binds-design-system/src/components/Form/Radio'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () { return { render: true } },
  created () {
    if (this.question.options.length === 0) {
      const options = [
        { label: this.$i18n.t('survey.option_1'), value: this.$i18n.t('survey.option_1') },
        { label: this.$i18n.t('survey.option_2'), value: this.$i18n.t('survey.option_2') },
        { label: this.$i18n.t('survey.option_3'), value: this.$i18n.t('survey.option_3') }
      ]
      this.question.options = options
    }
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser',
      currentSurvey: 'survey/getSurvey'
    })
  },
  methods: {
    updateQuestionDescription (e, idx) {
      this.question.options[idx].label = e
      this.question.options[idx].value = e
      this.$store.commit('survey/upsertQuestion', this.question)

      const hasTrigger = this.currentSurvey.ticket.triggers.length
      const hasTriggerInQuestion = this.currentSurvey.ticket.triggers.find(trigger => trigger.questionId === this.question._id && trigger.responseIndex === idx)

      if (hasTrigger && hasTriggerInQuestion) {
        hasTriggerInQuestion.responseContents = e
      }
    },
    updateOtherLabel (e) {
      this.question.otherLabel = e
      this.$store.commit('survey/upsertQuestion', this.question)
    },
    addItem () {
      const count = this.question.options.length + 1
      this.question.options.push({ label: this.$i18n.t('survey.option_added') + count, value: this.$i18n.t('survey.option_added') + count })
      this.$store.commit('survey/upsertQuestion', this.question)
    },
    deleteItem (idx) {
      this.render = false
      this.question.options.splice(idx, 1)
      setTimeout(function () { this.render = true }.bind(this), 200)
      this.$store.commit('survey/upsertQuestion', this.question)
    }
  }
}
</script>
<style lang="scss">
.text-space-single {
  margin-top: 25px;
  & textarea {
    height: 50px !important;
  }
  & .input-wrapper .input {
    padding-top: 12px !important;
    font-size: 14px !important;
    text-align: left;
  }
}

.r-other {
  margin: 20px 0 0 20px;
}

.i-other {
  padding-left: 0px !important;
}

.single {
  position: relative;
  padding-top: 35px;
  width: 550px;
  margin: 0 auto;

  button.binds-button.medium.primary.floating {
    position: absolute;
    bottom: -15px;
    left: 26px;
    width: 35px;
    height: 35px;
    line-height: 14px;
    i {
      font-size: 14px !important;
      margin-top: 0px !important;
    }
  }
  .input-wrapper:after { display: none; }
}
.single-create {
  padding: 20px;
  width: 80%;
}
.single-radio {
  & .checkmarkv2 {
    height: 12px !important;
    width: 12px !important;
  }
}
.del-single-op {
  font-size: 14px !important;
  color: gray;
}
.text-space {
  margin-top: 5px;
  & .input-wrapper .input {
    padding: 5px !important;
    font-size: 14px !important;
    text-align: left;
  }
}
.special-pad {
  margin-top: 7px;
  & .input-wrapper .input {
    padding-top: 20px !important;
  }
}
.other-input {
  padding: 0px 0px 0px 1rem !important;
  & .text-field__input > input {
    font-size: 14px !important;
  }
}
.options-box {
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.single-ui {
  button {
    position: absolute;
    left: -25px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.widget-preview-single {
  display: block !important;
  .widget {
    margin: -20px 0px 30px 30px !important;
    h6 {
      text-align: left !important;
    }
  }
}
</style>
