<template>
  <div class="container-sidebar-sending">
    <div class="content-loading-sidebar-sending" v-show="loadSidebarSending">
      <Circle2 class="spinner-social-sidebar-sending"/>
    </div>
    <div class="header-sidebar-sending" :key="`${myData?.sendingId}${key}`">
      <i class="material-icons icon-close-sidebar-sending" @click="expandSidebar(false)">cancel</i>
      <div class="content-info-user-sidebar-sending">
        <InfoUserSidebar :dataInfo="sendingInfo" />
      </div>
    </div>
    <div class="content-sidebar-sending" v-if="myData" :key="`${myData?.sendingId}${key+1}`">
      <ChangeTab :renderTabs="tabs" @tab-active="tabActive = $event" :initialTab="true" :showContent="true">
        <template v-slot:default>
          <ResponsesSidebar v-if="tabActive === 'responses'" :dataResponses="myData" />
          <MetadataSidebar v-if="tabActive === 'metadata'" :dataMetadata="myData" />
          <HealthChartSidebar v-if="tabActive === 'health'" :dataHealth="myData" />
          <NotesSidebar v-if="tabActive === 'notes'" :dataNotes="myData" />
          <TicketSidebar v-if="tabActive === 'tickets'" :dataTicket="myData" />
          <MiniCardInsight v-if="tabActive === 'text-analysis'" :dataInsight="myData.insights" />
        </template>
      </ChangeTab>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import { dashboardsService } from '@/_services'
import ChangeTab from '../inputs/ChangeTab.vue'
import { formatters } from '@/_helpers'

import InfoUserSidebar from './sidebarComponents/InfoUserSidebar.vue'
import MiniCardInsight from '../insights/components/MiniCardInsight.vue'
import ResponsesSidebar from './sidebarComponents/ResponsesSidebar.vue'
import NotesSidebar from './sidebarComponents/NotesSidebar.vue'
import MetadataSidebar from './sidebarComponents/MetadataSidebar.vue'
import TicketSidebar from './sidebarComponents/TicketSidebar.vue'
import HealthChartSidebar from './sidebarComponents/HealthChartSidebar.vue'

export default {
  name: 'SidebarSendings',
  props: ['id', 'showSidebarSending'],
  components: {
    ChangeTab,
    NotesSidebar,
    TicketSidebar,
    MetadataSidebar,
    MiniCardInsight,
    InfoUserSidebar,
    ResponsesSidebar,
    HealthChartSidebar,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      key: 0,
      mySending: null,
      myData: null,
      loadSidebarSending: true,
      tabs: [
        { render: true, active: true, title: this.$t('dashboard_card.anwsers_link'), value: 'responses', icon: 'forum' },
        { render: true, active: false, title: this.$t('sendingsBar.metadata'), value: 'metadata', icon: 'data_object' },
        { render: false, active: false, title: this.$t('health_score.title_card_score'), value: 'health', icon: 'ecg_heart' },
        { render: true, active: false, title: this.$t('annotation.notes'), value: 'notes', icon: 'speaker_notes' },
        { render: true, active: false, title: this.$t('tickets.title'), value: 'tickets', icon: 'mail' },
        { render: false, active: false, title: 'Insight', value: 'text-analysis', icon: 'format_size' }
      ],
      detailsTicket: {},
      buttonToTop: false,
      tabOptions: {
        general: true,
        responses: false,
        metadataOpt: false,
        notes: false,
        attendance: false,
        insight: false
      },
      itemInsight: [],
      tags: [],
      accountMetadata: '',
      openCategories: false,
      tabActive: null
    }
  },
  beforeDestroy () {
    this.$root.$off('open-sending-in-time')
  },
  created () {
    this.mySending = this.id
  },
  mounted () {
    this.expandSidebar(true)

    this.$route.name === 'Tickets' ? this.showMarkAsResolved = false : this.showMarkAsResolved = true

    this.$root.$on('open-sending-in-time', payload => {
      this.setLoading(true)
      this.mySending = payload
      this.getTicketData()
    })
  },
  computed: {
    lang () { return this.$store.getters['account/getLang'] },
    user () { return this.$store.getters['account/getUser'] },
    metadataImage () { return this.myData?.seedData?.metadata?.imageUrl },
    sendingInfo () { return { ...this.myData?.userInfo, ...this.myData?.sendInfo, metadataImage: this.metadataImage } }
  },
  methods: {
    expandSidebar (e) {
      const width = e ? '37vw' : '0vw'
      setTimeout(() => {
        const containerSidebar = document.querySelector('.container-sidebar-sending')
        gsap.to(containerSidebar, {
          width,
          duration: 0.3,
          onComplete: () => {
            if (e) { return this.getTicketData() }
            this.$store.commit('providers/UPDATE_PROVIDER', { key: 'sendingIdOnQuery', value: null })
            this.$emit('closeSendings')
          }
        })
      }, 10)
    },
    setLoading (e) {
      if (e) { this.loadSidebarSending = e }

      const opacity = e ? 1 : 0
      setTimeout(() => {
        const divLoading = document.querySelector('.content-loading-sidebar-sending')
        gsap.to(divLoading, {
          opacity,
          duration: 0.2,
          onComplete: () => {
            if (!e) { this.loadSidebarSending = e }
          }
        })
      }, 10)
    },
    async getTicketData () {
      try {
        const data = await dashboardsService.getSendingData(this.mySending)

        this.formatData(data)
      } catch (error) {
        console.error('error:', error)
        return this.expandSidebar(false)
      } finally {
        this.setLoading(false)
      }
    },
    formatData (data) {
      const formatItem = {
        sendingId: data._id,
        survey: data.survey,
        seedData: data.seedData || {},
        userInfo: {
          isSolved: data.isSolved,
          name: data.contact?.name || this.$t('dashboard.no_name'),
          email: data.contact?.email || this.$t('dashboard.no_email'),
          phone: data.seedData?.from?.phone || this.$t('dashboard.no_phone'),
          _id: data._id
        },
        sendInfo: {
          type: data.type || '',
          createdAt: formatters.formatDateByLangAndFixTimeZone(data.createdAt, this.lang, 'LLL') || this.$t('dashboard.mail_not_send'),
          respondedAt: data.respondedAt ? formatters.formatDateByLangAndFixTimeZone(data.respondedAt, this.lang, 'LLL') : this.$t('dashboard.no_response'),
          lastResponseAt: '',
          openedAt: data.openedAt ? formatters.formatDateByLangAndFixTimeZone(data.openedAt, this.lang, 'LLL') : this.$t('dashboard.mail_not_opened'),
          doneAt: data.doneAt ? formatters.formatDateByLangAndFixTimeZone(data.doneAt, this.lang, 'LLL') : this.$t('dashboard.not_finished'),
          deletedAt: ''
        },
        tags: data.tags ? data.tags : '',
        ticket: {},
        insights: [],
        health: data.health
      }

      if (data.survey.deletedAt) {
        formatItem.sendInfo.deletedAt = formatters.formatDateByLangAndFixTimeZone(data.survey.deletedAt, this.lang, 'LLL')

        this.$store.commit('alerts/alert', {
          message: this.$t('dashboard.survey_deleted_alert'),
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      }

      formatItem.survey.questions.forEach(e => {
        if (e?.response?.createdAt) {
          formatItem.sendInfo.lastResponseAt = formatters.formatDateByLangAndFixTimeZone(e.response.createdAt, this.lang, 'LLL')
        }
        if (e.type === 'text' && e.artificialAnalysis) {
          formatItem.insights = {
            seedData: { from: { name: formatItem.userInfo.name, email: formatItem.userInfo.email } },
            insight: { artificialAnalysis: { response: e.response.text, ...e.artificialAnalysis } }
          }
        }
      })

      if (Object.keys(formatItem.insights).length) { this.tabs[5].render = true }
      if (formatItem.health) { this.tabs[2].render = true }

      if (data.ticket) {
        const lastStatusUpdate = data.ticket.lastStatusUpdate
        const formattedTicket = {
          date: lastStatusUpdate.date,
          status: lastStatusUpdate.label,
          label: this.$t(`tickets.status.${lastStatusUpdate.label}`),
          category: data.ticket.category ? data.ticket.category.title : undefined
        }

        switch (lastStatusUpdate.label) {
          case 'unassigned':
            formattedTicket.by = this.$t('tickets.no_attendant')
            formattedTicket.label = this.$t('tickets.opened')
            break

          case 'assigned':
            formattedTicket.by = data.ticket.attendant && data.ticket.attendant.name ? data.ticket.attendant.name : this.$t('dashboard.no_attendant')
            formattedTicket.email = data.ticket.attendant && data.ticket.attendant.email ? data.ticket.attendant.email : this.$t('dashboard.no_email')
            formattedTicket.label = this.$t('tickets.opened')
            break

          case 'closed':
            if (lastStatusUpdate.author && lastStatusUpdate.author.name) {
              formattedTicket.by = lastStatusUpdate.author.name
              formattedTicket.email = lastStatusUpdate.author.email
            }
            formattedTicket.label = this.$t('tickets.closed')
            break

          default:
            break
        }

        formatItem.ticket = formattedTicket
      }

      const listSeeds = this.user.account.fields
      const keyList = data.seedData.metadata

      if (keyList) {
        listSeeds.forEach((item) => {
          item.value = keyList[item.key]
          item.displayInput = false
          !this.user.features.metadataEditing
            ? item.value ? item.hiddenItem = true : item.hiddenItem = false : item.hiddenItem = true
        })
      }

      formatItem.allMetadata = listSeeds
      formatItem.accountMetadata = data.seedData.metadata

      this.myData = formatItem
    }
  }
}
</script>

<style lang="scss">
.container-sidebar-sending {
  background: #ffffff;
  width: 0vw;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 60;
  border-radius: 10px 0 0 15px;
  border-left: 1px solid #e1e1e1;
  box-shadow: 0 0 15px 10px #00000030;
  display: flex;
  flex-direction: column;
  .content-loading-sidebar-sending {
    z-index: 61;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner-social-sidebar-sending {
      width: 2.1vw !important;
      height: 2.1vw !important;
      border-width: 3px !important;
      border-radius: 35px;
      border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
    }
  }
  .header-sidebar-sending {
    position: relative;
    width: 100%;
    height: 35%;
    padding: 0.5vw;
    .icon-close-sidebar-sending {
      position: absolute;
      top: 0.3vw;
      right: 0.3vw;
      color: #5e5e5e;
      font-size: 1.4vw;
      cursor: pointer;
    }
    .content-info-user-sidebar-sending {
      width: 100%;
      height: 100%;
    }
  }
  .content-sidebar-sending {
    width: 100%;
    height: 70%;
    padding: 0.5vw;
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
  }
}
</style>
