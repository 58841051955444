<template>
  <div class="container-file-social-media">
    <div class="section-file-social-media" v-if="step === 1">
      <div class="header-warning">
        <div class="titles-warning">
          <app-heading level="h7" class="title-modal-document">{{ $t('bvs.upload.title') }}</app-heading>
          <app-heading level="h7" class="warning-modal-document">{{ $t('bvs.upload.title_date') }} <span style="font-weight: bold;">1995-07-27 16:20:00</span></app-heading>
          <app-heading level="h7" class="warning-modal-document">{{ $t('seeds.file_size') }}: <span style="font-weight: bold;">10MB</span></app-heading>
          <app-heading level="h7" class="warning-modal-document">({{ $t('bvs.upload.title_format._1') }} <span style="font-weight: bold;">{{ $t('bvs.upload.title_format._2') }}</span> {{ $t('bvs.upload.title_format._3') }} <span style="font-weight: bold;">{{ $t('bvs.upload.title_format._4') }}</span>)</app-heading>
        </div>
        <label for="import-file-sm" class="import-spreadsheet">
          <input type="file" id="import-file-sm" multiple="false" ref="filesm" @change.prevent="validFileAndLoadPreview">
          <p>{{ $t('bvs.upload.import_file') }}</p>
        </label>
      </div>
      <div class="infos-spreadsheet" v-if="showTotalOfLines && step === 1">
        <div class="header-infos">
          <div class="details-spreadsheet">
            <app-heading level="h7" class="name-spreadsheet-document">{{ $t("seeds.count_lines") }} <span>{{ formatNumber(showTotalOfLines - 1) }}</span></app-heading>
            <app-heading level="h7" class="name-spreadsheet-document">{{ $t("seeds.file") }}: <span>{{ $refs.filesm.files[0].name }}</span></app-heading>
            <app-heading level="h7" class="name-spreadsheet-document">{{ $t("seeds.current_file_size") }}: <span>{{ convertBytes($refs.filesm.files[0].size) }}</span></app-heading>
          </div>
          <app-heading level="h6" style="margin: 5px 0 0 0 !important; text-align: center; color: #454548">{{ $t('seeds.preview') }}</app-heading>
        </div>
        <div class="preview-spreadsheet">
          <div id="spreadsheet" ref="import-spreadsheet-sm"></div>
        </div>
      </div>
    </div>
    <div class="section-fileprev-social-media" v-if="step === 2">
      <div class="header-mapping">
        <div class="info-brand-mapping">
          <app-heading level="h5" class="title-header-mapping">{{ $t('bvs.history.title_list_brand') }}:</app-heading>
          <app-heading level="h5" class="name-header-mapping">{{ brand.customBrand.name }}</app-heading>
        </div>
        <app-heading level="h6" class="title-header-mapping" style="width: 100%; text-align: left">{{ $t('seeds.preview') }}</app-heading>
        <div class="prev-card-mapping">
          <div class="card-prev">
            <div class="image-card-prev">
              <img class="image-card" :src="cardPrev.imageUrl">
              <div class="line"></div>
            </div>
            <div class="infos-card-prev">
              <div class="name-and-text">
                <div class="header-info">
                  <p><i class="material-icons icon-shared">public</i> <span>PostFromUpload</span></p>
                  <p><i class="material-icons icon-shared">schedule</i> <span>{{ $moment(cardPrev.postedAt).startOf('hour').fromNow() }}</span></p>
                  <p><i class="material-icons icon-shared">apartment</i> <span>@{{ cardPrev.username }}</span></p>
                </div>
                <div class="info-user">
                  <p class="name-user">{{ cardPrev.name }}</p>
                  <p class="user-name">@{{cardPrev.username }}</p>
                </div>
                <div class="text-card">
                  <p class="text-prev" v-html="returnParsedHashtagText(cardPrev.text)"></p>
                </div>
              </div>
              <div class="metrics-card-prev">
                <div class="options-metrics">
                  <div class="reply" v-title="$t('bvs.share.comments')" title-max-width="320">
                    <p><i class="material-icons icon-mention">mode_comment</i> {{ cardPrev.quote_count }}</p>
                  </div>
                  <div class="retweet" v-title="$t('bvs.share.retweets')" title-max-width="320">
                    <p><i class="material-icons icon-mention">repeat</i> {{ cardPrev.share_count }}</p>
                  </div>
                  <div class="like" v-title="$t('bvs.share.likes')" title-max-width="320">
                    <p><i class="material-icons icon-mention">favorite</i> {{ cardPrev.like_count }}</p>
                  </div>
                  <div class="redirect-link" v-title="$t('bvs.share.view_on_page')" title-max-width="320">
                    <i class="material-icons icon-mention">turn_right</i>
                  </div>
                </div>
                <div class="nps-mention">
                  <i class="material-icons icon-mention-nps" style="color: #57AD2699">mood</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-options-mapping">
        <div class="title-header">
          <app-heading level="h6" class="title-item-header">{{ $t('seeds.your_file') }}</app-heading>
          <app-heading level="h6" class="title-item-header">{{ $t('seeds.your_match') }}</app-heading>
        </div>
        <div class="header-options">
          <div class="item-header" v-for="(header, idx) in headers[0]" :key="idx">
            <div class="title-items">
              <app-heading level="h6" class="title-item">{{ header }}</app-heading>
              <app-heading level="h7" class="name-item">{{ headers[1][idx] }}</app-heading>
            </div>
            <div class="select-mapping">
              <select
                class="v-select-mapping"
                placeholder="Selecione"
                v-model="selectedHeaders[idx]"
                v-on:change="checkMapping(idx, selectedHeaders[idx], headers[1][idx])">
                <option value="">{{ $t('advanced_filters.select_option') }}</option>
                <option
                  :class="option.selected ? 'hide-option' : ''"
                  v-for="(option, optionKey) in optionsMapping" :key="optionKey"
                  :value="option.key">
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="save-file-social-media">
      <button class="btn-save-file cancel" @click="backStep()" v-if="step === 2">{{ $t('bvs.upload.back') }}</button>
      <button class="btn-save-file" @click="goMappingHeaders()" v-if="step === 1 && file">{{ $t('bvs.upload.next') }}</button>
      <button class="btn-save-file" @click="saveFile()" v-if="step === 2">{{ $t('bvs.upload.save') }}</button>
    </div>
    <div class="data-loading-fixed" v-show="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
  </div>
</template>

<script>
import { socialMediaService } from '@/_services'
import Papa from 'papaparse'
import mimeTypes from 'mime-types'
import 'jspreadsheet-ce/dist/jspreadsheet.css'
import 'jsuites/dist/jsuites.css'
import { read, utils } from 'xlsx'
const jspreadsheet = require('jspreadsheet-ce')
import('lodash-es').then((lodashEs) => { window._ = lodashEs.default })

export default {
  name: 'ImportFileSocialMedia',
  props: ['brand'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  data () {
    return {
      loading: false,
      file: '',
      step: 1,
      selectedHeaders: [],
      sendhubTable: [],
      showTotalOfLines: 0,
      isValidFileMimeType: false,
      showNewMetadataModal: false,
      newMetadataWhere: undefined,
      headers: [],
      optionsMapping: [
        { key: 'name', label: 'Nome' },
        { key: 'username', label: 'Username' },
        { key: 'email', label: 'E-mail' },
        { key: 'phone', label: 'Telefone' },
        { key: 'text', label: 'Texto' },
        { key: 'postedAt', label: 'Data da postagem' },
        { key: 'link', label: 'Link da postagem' },
        { key: 'imageUrl', label: 'Link da imagem' },
        { key: 'like_count', label: 'Quantidade de Likes' },
        { key: 'dislike_count', label: 'Quantidade de Deslikes' },
        { key: 'share_count', label: 'Quantidade de Compartilhamentos' },
        { key: 'quote_count', label: 'Quantidade de Comentários' }
      ],
      delimiter: ',',
      options: {
        data: [],
        columns: []
      },
      fileMimeTypes: [
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel'
      ],
      cardPrev: {
        name: 'Nome',
        username: 'Username',
        email: 'email@email.com',
        phone: '1140028922',
        text: 'Todas estas questões, devidamente ponderadas, levantam dúvidas sobre se a adoção de políticas descentralizadoras obstaculiza a apreciação da importância do retorno esperado a longo #prazo.',
        postedAt: '1995-07-27 16:20:00',
        link: '',
        imageUrl: 'https://donutssugaresugar.com.br/wp-content/uploads/2022/07/donuts_sugar_e_sugar_slider_donuts_1200px.png',
        like_count: '27',
        dislike_count: '2',
        share_count: '9',
        quote_count: '16'
      }
    }
  },
  mounted () {
    this.$root.$on('close-new-metadata', () => {
      this.showNewMetadataModal = false
      if (this.newMetadataWhere) {
        this.selectedHeaders[this.newMetadataWhere.index] = this.optionsMapping[this.optionsMapping.length - 2].key
      }
      this.newMetadataWhere = undefined
      this.selectedHeaders.forEach((o, index) => {
        if (o !== undefined) {
          this.checkMapping(index, o)
        }
      })
    })
  },
  computed: {
  },
  methods: {
    formatNumber (n) {
      return new Intl.NumberFormat('pt-BR').format(n)
    },
    convertBytes (x) {
      const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      let l = 0; let n = parseInt(x, 10) || 0

      while (n >= 1024 && ++l) { n = n / 1024 }

      return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + '' + units[l])
    },
    returnParsedHashtagText (text) {
      const spliteds = text.split(' ')
      let regexToUse = /@.*/g
      const parsedStrings = spliteds.map((item) => {
        let color = '#454548' // black
        if (item.includes('#')) {
          regexToUse = /#.*/g
          color = '#3a9eea' // blue
        }
        return item.replace(regexToUse, (match, f) => {
          return `<b class="clickable-text" style="color: ${color};">${match.replaceAll(',', ' ')}</b>`
        })
      })
      let newText = ''
      parsedStrings.forEach((element) => {
        newText += ` ${element}`
      })

      return newText
    },
    backStep () {
      this.file = ''
      this.showTotalOfLines = 0
      this.step--
    },
    async saveFile () {
      const findTextField = this.selectedHeaders.find(e => e === 'text')
      if (!findTextField) {
        return this.$store.commit('alerts/alert', {
          message: 'O campo de texto é obrigatório',
          position: 'bottomRight',
          showAlert: true
        })
      }
      this.loading = true
      const jsonToSend = []
      this.selectedHeaders.map((item, index) => {
        if (item !== undefined && item !== 'undefined' && item.trim().length > 0) {
          jsonToSend.push({ key: item, value: this.headers[0][index] })
        }
      })

      // prepara a query para o backend
      const toQuery = {
        delimiter: this.delimiter,
        headers: [encodeURI(JSON.stringify(jsonToSend))],
        _id: this.brand._id
      }

      // prepara o arquivo para o envio
      const formdata = new FormData()
      formdata.append('csv', this.file)
      const result = await socialMediaService.uploadFileCustomBrand(formdata, toQuery)

      if (result) {
        this.$store.commit('alerts/alert', {
          message: 'Upload realizado com sucesso',
          position: 'bottomRight',
          showAlert: true
        })
        this.$root.$emit('upload-success')
      }

      if (!result || !result.user) {
        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('sendConfig.error'),
          position: 'bottomRight',
          showAlert: true
        })
      }
      this.loading = false
    },
    checkMapping (idx, value, item) {
      this.optionsMapping.map((option) => {
        if (option.selected) {
          if (option.index === idx) {
            option.selected = false
            option.index = null
          }
        }
      })

      this.cardPrev[value] = item

      if (value !== 'undefined' && value !== undefined) {
        const hasThisHeader = window._.findIndex(this.optionsMapping, { key: value })
        if (hasThisHeader >= 0) {
          this.optionsMapping[hasThisHeader].index = idx
          this.optionsMapping[hasThisHeader].selected = true
        }
      }
    },
    goMappingHeaders () {
      this.step++
      this.cardPrev = {
        name: 'Nome',
        username: 'Username',
        email: 'email@email.com',
        phone: '1140028922',
        text: 'Todas estas questões, devidamente ponderadas, levantam dúvidas sobre se a adoção de políticas descentralizadoras obstaculiza a apreciação da importância do retorno esperado a longo #prazo.',
        postedAt: '1995-07-27 16:20:00',
        link: '',
        imageUrl: 'https://donutssugaresugar.com.br/wp-content/uploads/2022/07/donuts_sugar_e_sugar_slider_donuts_1200px.png',
        like_count: '27',
        dislike_count: '2',
        share_count: '9',
        quote_count: '16'
      }
    },
    async validFileAndLoadPreview () {
      if (!this.$refs.filesm.files[0]) { return false }
      if (this.showTotalOfLines > 0) {
        document.getElementById('spreadsheet').innerHTML = ''
        this.headers = []
        this.options.data = []
        this.options.columns = []
      }

      this.file = this.$refs.filesm.files[0]
      const mimeType = this.file.type === '' ? mimeTypes.lookup(this.file.name) : this.file.type
      if (this.file) {
        this.isValidFileMimeType = this.fileMimeTypes.indexOf(mimeType)
        const convertToMb = (this.file.size / 1024 / 1024) // value is a byte
        if (convertToMb > 10) {
          this.$store.commit('alerts/alert', {
            message: this.$i18n.t('seeds.file_size') + '10 Megabytes.',
            position: 'bottomRight',
            showAlert: true
          })
          return false
        }
      }
      if (this.isValidFileMimeType < 0) {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('seeds.file_type'),
          position: 'bottomRight',
          showAlert: true
        })
        return false
      }
      this.load()
    },
    load () {
      const self = this
      // inicia a leitura do csv informado
      this.readFile((output, countXlsx) => {
        if (!this.isUTF8(output)) {
          this.isLoading = false
          this.$store.commit('alerts/alert', {
            message: this.$i18n.t('seeds.encode_error'),
            position: 'bottomRight',
            showAlert: true
          })
          return
        }
        const previewData = Papa.parse(output, {
          skipEmptyLines: 'greedy'
        })
        this.delimiter = previewData.meta.delimiter
        this.headers = window._.get(previewData, 'data').slice(0, 12)
        this.headers[0] = this.headers[0].map((col, idx) => {
          return col.trim().replace(/[^A-zÀ-ú 0-9]/gi, '_') // converte o header
        })
        const dataLength = window._.get(previewData, 'data')?.length
        // informa a quantidade de linhas do csv
        this.showTotalOfLines = countXlsx || dataLength

        // validator and formatter of headers (columns)
        const notPermitedHeaders = []
        this.headers[0].forEach(function (element, index) {
          if (element.trim().length > 0) {
            // preenche os headers da tabela
            self.options.columns.push({
              type: 'text',
              width: '250',
              title: element,
              readOnly: true
            })
          } else {
            notPermitedHeaders.push(index) // informa quem sãos os headers vazios
          }
        }, self)
        // preenche os dados para as linhas da tabela
        const rowsToTablePreview = this.headers.filter((item, index) => {
          if (index > 0) {
            if (notPermitedHeaders.length > 0) {
              notPermitedHeaders.forEach((idx) => {
                if (item[idx] !== undefined) {
                  item.splice(idx, 1) // remove o valor da célula que estiver na coluna que foi ignorada por estar vazia (sem nome)
                }
              })
            }
          }
          return item
        })
        this.options.data = window._.tail(rowsToTablePreview)

        // renderiza a tabela de preview
        setTimeout(() => {
          this.sendhubTable = jspreadsheet(this.$refs['import-spreadsheet-sm'], this.options)
        }, 10)

        // alerta para informar erros
        if (this.showErrorMessage) {
          this.$store.commit('alerts/alert', {
            message: this.$i18n.t(this.showErrorMessage),
            position: 'bottomRight',
            showAlert: true
          })
        }
      })
    },
    readFile (callback) {
      const file = this.$refs.filesm.files[0]
      if (file) {
        const reader = new FileReader()
        if (file.type === 'text/csv') {
          reader.readAsText(file, 'UTF-8')
        }
        reader.onload = function (evt) {
          // lê arquivos xlsx / xls
          if (file.type !== 'text/csv') {
            let countRows = null
            var data = new Uint8Array(evt.target.result)
            var workbook = read(data, {
              type: 'array',
              blankrows: false,
              cellText: false,
              cellDates: true
            })
            try {
              const fullRange = workbook?.Sheets[workbook?.SheetNames[0]]['!fullref']
              countRows = fullRange ? fullRange.split(':')[1].slice(1) : null
            } catch (error) {
              console.log('erro ao tentar encontrar o total de linhas')
            }
            callback(
              utils.sheet_to_csv(
                workbook.Sheets[workbook.SheetNames[0]],
                {
                  blankrows: false,
                  rawNumbers: true,
                  raw: false,
                  dateNF: 'dd-mm-yyyy'
                }
              ),
              countRows
            )
          } else {
            callback(evt.target.result)
          }
        }
        if (file.type !== 'text/csv') {
          reader.readAsArrayBuffer(file)
        }
        reader.onerror = function (e) {
          console.log('console error', e)
        }
        this.file = file
      }
    },
    isUTF8 (content) {
      for (let b = 0; b < content.length; b++) {
        if (content[b] === '�') {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="scss">
.hide-option {
  display: none;
}
.container-file-social-media {
  width: 100%;
  height: 100%;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .section-file-social-media {
    width: 100%;
    height: 100%;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .header-warning {
      width: 100%;
      height: 17%;
      padding-right: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .titles-warning {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .title-modal-document {
          color: #454548;
          width: 100%;
          text-align: left;
        }
        .warning-modal-document {
          width: 100%;
          color: #454548;
          text-align: left;
          span {
            color: var(--accent-color) !important;;
          }
        }
        .field-required-modal-document {
          color: #454548 !important;
          width: 100%;
          text-align: left;
          span {
            font-weight: bold;
            color: var(--accent-color);
          }
        }
      }
    }
    .infos-spreadsheet {
      width: 100%;
      height: 83%;
      padding: 5px 0;
      .header-infos {
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: column;
        .details-spreadsheet {
          height: 65%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title-spreadsheet-document {
            font-weight: 500;
            color: #454548;
            text-align: center;
          }
          .total-spreadsheet-document {
            color: #454548;
            font-weight: 400 !important;
            margin-bottom: 7px !important;
            span {
              font-weight: bold;
              color: var(--accent-color);
            }
          }
          .name-spreadsheet-document {
            color: #454548 !important;
            margin: 0 !important;
            span {
              font-weight: bold;
              color: var(--accent-color);
            }
          }
        }
      }
      .preview-spreadsheet {
        width: 100%;
        max-height: 80%;
        overflow-x: auto;
        border-bottom: 1px solid #cacaca;
        border-right: 1px solid #cacaca;
        .jexcel_container {
          pointer-events: none;
          width: 100%;
          height: 100%;
          padding: 0 !important;
          .jexcel_content {
            padding: 0 !important;
            .jexcel {
              tbody {
                tr {
                  td {
                    border-top: 1px solid #cacaca !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .import-spreadsheet {
      width: 150px;
      height: 35px;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background: var(--accent-color);
      #import-file-sm {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      p {
        font-size: 15px;
        color: var(--default-text-color);
      }
    }
  }
  .section-fileprev-social-media {
    position: relative;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cacaca;
    .header-mapping {
      width: 30%;
      height: 100%;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      .info-brand-mapping {
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .name-header-mapping {
          color: var(--accent-color);
          font-size: 20px;
        }
      }
      .title-header-mapping {
        color: #454548;
        font-weight: 500 !important;
        margin-bottom: 10px !important;
      }
      .prev-card-mapping {
        width: 100%;
        height: 65%;
        background: #FFFFFF;
        border: 1px solid #cacaca;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        .card-prev {
          width: 100%;
          height: 100%;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .image-card-prev {
            width: 20%;
            height: 100%;
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .image-card {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              object-fit: cover;
              padding: 1px;
              border: 1px solid #cacaca;
            }
            .line {
              width: 1px;
              border-radius: 35px;
              height: 75%;
              margin-top: 10px;
              background: #cacaca;
            }
          }
          .infos-card-prev {
            width: 80%;
            height: 100%;
            padding: 5px;
            .name-and-text {
              height: 90%;
              .header-info {
                width: 100%;
                height: 5%;
                display: flex;
                justify-content: space-between;
                p {
                  font-size: 10px;
                  color: gray;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  padding-right: 5px;
                  .icon-shared {
                    color: gray;
                    font-size: 17px;
                    margin-right: 3px;
                  }
                }
              }
              .info-user {
                width: 100%;
                height: 25%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                .name-user {
                  font-size: 20px;
                  font-weight: bold;
                  margin-right: 5px;
                }
                .user-name {
                  color: gray;
                  font-size: 15px;
                  margin-top: 5px;
                }
              }
              .text-card {
                width: 100%;
                height: 70%;
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                .text-prev {
                  font-size: 15px;
                  line-height: 15px;
                  color: #454548;
                }
              }
            }
            .metrics-card-prev {
              width: 100%;
              height: 10%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .options-metrics {
                height: 35px;
                width: 80%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .reply, .retweet, .like, .redirect-link {
                  color: #a7a7a7;
                  p {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 15px;
                    .icon-mention {
                      width: 30px;
                      height: 30px;
                      font-size: 17px;
                      margin-right: 5px;
                      border-radius: 50%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }
                }
                .redirect-link {
                  cursor: pointer;
                }
                .reply:hover {
                  color: #3a9eea;
                  .icon-mention {
                    background: #3a9eea1c;
                  }
                }
                .retweet:hover {
                  color: #3bc4c7;
                  .icon-mention {
                    background: #3bc4c71c;
                  }
                }
                .like:hover {
                  color: #ff4e69;
                  .icon-mention {
                    background: #ff4e691c;
                  }
                }
                .redirect-link:hover {
                  color: #3a9eea;
                  .icon-mention {
                    border-radius: 50%;
                    background: #3a9eea1c;
                  }
                }
              }
              .nps-mention {
                width: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                .icon-mention-nps {
                  font-size: 25px;
                }
              }
            }
          }
        }
      }
    }
    .section-options-mapping {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .title-header {
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .title-item-header {
          font-weight: 500 !important;
          color: #454548 !important;
        }
      }
      .header-options {
        width: 100%;
        height: 90%;
        border: 1px solid #cacaca;
        border-bottom: none;
        border-radius: 2px;
        overflow-y: auto;
        background: #FFFFFF;
        .item-header {
          min-height: 55px;
          margin-bottom: 2px;
          border-bottom: 1px solid #cacaca;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title-items {
            width: 70%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding-left: 5px;
            .title-item {
              font-weight: 500 !important;
              color: #454548 !important;
            }
            .name-item {
              font-weight: 400 !important;
              color: #858585;
              line-height: 16px;
            }
          }
          .select-mapping {
            width: 30%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .v-select-mapping {
              height: 35px;
              width: 98%;
              outline: none;
              border-radius: 2px;
              padding-left: 5px;
              background: #F0F2F5;
              color: var(--accent-color);
              border: 1px solid #cacaca;
            }
          }
        }
      }
    }
  }
  .save-file-social-media {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-save-file {
      width: 90px;
      height: 30px;
      margin-right: 10px;
      font-size: 15px;
      background: var(--accent-color);
      color: var(--default-text-color);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cancel {
      background: #ffffff;
      border: 1px solid #cacaca;
      color: #454548;
      font-weight: bold;
    }
  }
  .data-loading-fixed {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner {
      margin: 0 !important;
    }
  }
}
</style>
