<template>
  <div  style="height: 100%;">
    <canvas
      :id="makeSpecialId()"
      width="100%"
    ></canvas>
  </div>
</template>

<script>
import i18n from '../../../translations/i18n'
import { canvasMaker, responseOptions, translator } from '@/_helpers'
import('chart.js').then((chartjs) => {
  window.Chart = chartjs.default
})

export default {
  name: 'Bar',
  props: ['data', 'question', 'cardData'],
  data () {
    return {
      colors: ['#a87df5', '#fc90a4', '#00aaa7', '#673ab7', '#9f95f5', '#FFB500', '#f55bed', '#CA1E54', '#4D5359', '#79C99E', '#97DB4F', '#27B573', '#FF7663', '#1B9AAA', '#FFC43D']
    }
  },
  methods: {
    scaleBar (value) {
      return value[0] + 5
    },
    returnPercentage (value, total) {
      return Number(Math.round((value * 100) / total + 'e' + 2) + 'e-' + 2)
    },
    makeSpecialId () {
      this.question.canvasId = canvasMaker.makeCanvasId(this.question)
      return this.question.canvasId
    },
    emitClick (objToRedirect) {
      if (this.$route.name === 'RealTime') {
        this.$root.$emit('realtime-redirect-to-responses', objToRedirect)
      } else {
        this.$root.$emit('redirect-to-responses', objToRedirect)
      }
    }
  },
  async mounted () {
    let fontSize = 18
    let fontFamily = "Avenir', Helvetica, Arial, sans-serif;"
    let backgroundColor = this.question.type === 'enum' ? this.colors : this.cardData.colors
    let total = 0
    const labels = []
    const data = []
    const self = this
    const auxLabels = []
    const keyOptions = responseOptions.getOptions(this.question)

    this.data[0].questions.map(item => {
      if (item.question._id === this.question._id) {
        total = item.total
        item.data.map((point, index) => {
          if (index > 0) {
            data.push(point[1])
            keyOptions.map(op => {
              if ([op.value, op.rating].indexOf(point[0].toString()) > -1) {
                if (['10num', '5num'].indexOf(this.question.ui) > -1) {
                  labels.push(i18n.t('dashboard.graphics.op') + ' ' + op.label)
                } else if (this.question.ui === '5emo') {
                  fontSize = 24
                  fontFamily = 'icomoon'
                  backgroundColor = [
                    this.cardData.colors[0],
                    this.cardData.colors[1],
                    this.cardData.colors[2],
                    this.cardData.colors[3],
                    this.cardData.colors[4]
                  ]
                  if (point[0].toString() === op.value) {
                    labels.push(op.icon)
                    auxLabels.push(op)
                  }
                } else if (this.question.type === 'kpi') {
                  backgroundColor = []
                  labels.push(op.label)
                  labels.forEach((e, idx) => {
                    if (e === translator.translateThisText(item.question.language, 'dashboard.y')) {
                      backgroundColor[idx] = '#57ad26'
                    }
                    if (e === translator.translateThisText(item.question.language, 'dashboard.n')) {
                      backgroundColor[idx] = '#ea484d'
                    }
                    if (e === (translator.translateThisText(item.question.language, 'dashboard.mb') && translator.translateThisText(item.question.language, 'dashboard.graphics.op3'))) {
                      backgroundColor[idx] = '#f8c43d'
                    }
                  })
                } else {
                  labels.push(op.label)
                }
              }
            })
          }
        })
      }
    })

    const planetChartData = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColor
          }
        ]
      },
      options: {
        tooltips: {
          titleFontFamily: fontFamily,
          titleFontSize: fontSize,
          callbacks: {
            label: function (context) {
              return `${context.value} - ${self.returnPercentage(context.value, total)}%`
            },
            footer: function (tooltipItems, data) {
              return [`${i18n.t('real_time.see_at_responses')}`]
            }
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              color: 'rgba(0, 0, 0, 0)'
            },
            ticks: {
              fontFamily: fontFamily,
              fontSize: fontSize,
              fontColor: '#939393'
            }
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              color: 'rgba(0, 0, 0, 0)'
            },
            ticks: {
              beginAtZero: true,
              padding: 25,
              suggestedMax: self.scaleBar(data)
            }
          }]
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        onClick: function (event) {
          const item = this.getElementAtEvent(event)
          let itemLabel = ''
          if (item.length > 0) {
            itemLabel = labels[item[0]._index]
            if (self.question.ui === '5emo') {
              const labelIndex = auxLabels.findIndex(i => i.icon === itemLabel)
              if (labelIndex >= 0) {
                itemLabel = auxLabels[labelIndex].label
              }
            }
            const clickedItem = { label: itemLabel }
            const objToRedirect = { clickedOption: clickedItem, question: self.question }
            self.emitClick(objToRedirect)
          }
        }
      }
    }

    const ctx = document.getElementById(this.question.canvasId)
    const chart = await new window.Chart(ctx, {
      type: planetChartData.type,
      data: planetChartData.data,
      options: planetChartData.options,
      plugins: [{
        afterDatasetsDraw: function (chart) {
          const ctx = chart.ctx
          ctx.font = '10' + 'px Arial'
          ctx.textAlign = 'left'
          ctx.textBaseline = 'bottom'

          chart.config.data.datasets.forEach(dataset => {
            const data = dataset
            labels.forEach((o, i) => {
              const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model
              ctx.fillStyle = model.backgroundColor
              const label = `${self.returnPercentage(data.data[i], total).toFixed(1)}%`
              ctx.fillText(label, model.x - 13, model.y - 5)
            })
          })

          chart.config.data.datasets.forEach(dataset => {
            const data = dataset
            labels.forEach((o, i) => {
              const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model
              ctx.fillStyle = model.backgroundColor
              const label = `${data.data[i]}`
              ctx.fillText(label, model.x - 13, model.y - 17)
            })
          })
        }
      }]
    })
    return chart
  }
}
</script>
