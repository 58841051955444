<template>
  <div class="content-startend">
    <div class="content-question-title-start" :style="{'background-color': survey.colors.headerBg}">
    </div>
    <div class="content-main-message answered" :style="{'background-color': survey.colors.splashBg}">
      <SetImg :title="$t('sendhub.set_image_text')" :imageProps="imagePropsEnd" context="messages"/>
        <div class="answered-title" v-click-outside="hideOptions">
          <edit-title
            :title="survey.answeredMessage"
            @updateSurveyTitle="updateAnsweredMessage"
            @openOptions="displayOptions = true"
            :optionsDisplay="displayOptions"
            :editStyle="true"
            :color="survey.colors.splashColor"
            :identify="`answered-${survey._id}`"
            :minHeight="50"
            :maxHeight="40000"
            type="textarea"
            :metadata.sync="metadataGroup"
          />
        </div>
      <div class="row center-lg center-md center-sm end-message-description mt45" v-if="survey.answeredMessageDetails !== {}">
        <app-heading level="h6">{{ survey.answeredMessageDetails.value }}</app-heading>
      </div>
      <div class="content-question-title-start" v-if="survey.answeredMessageDetails.link">
        <app-heading level="h6">
          <a :href="survey.answeredMessageDetails.link.value" target="_blank">
            {{ survey.answeredMessageDetails.link.label }}
          </a>
        </app-heading>
      </div>
    </div>
    <div class="content-footer-answer" :style="{'background-color': survey.colors.footerBg}"></div>
  </div>
</template>

<script>
import SetImg from '../../image/SetImg.vue'
import EditTitle from '../../EditTitle.vue'
import { imgProps } from '@/_helpers'
import { userService } from '@/_services'

export default {
  name: 'AnsweredMessages',
  components: {
    SetImg,
    EditTitle,
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      editTitle: false,
      imagePropsEnd: undefined,
      displayOptions: false,
      metadataGroup: [
        { key: '_name', label: this.$i18n.t('sendhub.name'), type: String },
        { key: '_email', label: this.$i18n.t('sendhub.email'), type: String },
        { key: '_phone', label: this.$i18n.t('sendhub.phone'), type: String }
      ]
    }
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    },
    imagePropsDefault () {
      const survey = this.$store.getters['survey/getSurvey']
      const result = Object.assign({}, window._.find(this.survey.images, o => o.alias === 'logo'))
      result.value = survey.images && this.survey.images.length > 0 ? this.survey.images[0].value : ''
      result.where = 'answeredMessage'
      result.success = !!result.value
      result.crop = 'free'
      result.imageShrink = '800x600 100%'
      result.imageOnly = true
      return result
    }
  },
  created () {
    this.imageProps = imgProps.setImgMessage(this.survey.images, 'respondedMessage')
  },
  async mounted () {
    const survey = this.$store.getters['survey/getSurvey']

    if (survey.endMessages[0].logo.url) {
      const props = Object.assign({}, survey.images[1])
      props.value = survey.endMessages[0].logo.url
      props.where = 'answeredMessage'
      props.success = !!props.value
      props.crop = 'free'
      props.imageShrink = '800x600 100%'
      props.imageOnly = true
      this.imagePropsEnd = props
    } else {
      this.imagePropsEnd = this.imagePropsDefault
    }

    const user = await userService.getMe()
    if (user.account.fields.length > 0) {
      const data = user.account.fields
      data.map(item => this.metadataGroup.push(item))
    }

    this.$root.$on('update-merged-fields', (fields) => {
      fields.map(item => {
        if (this.survey.mergedFields.length > 0) {
          if (!this.survey.mergedFields.includes(item)) {
            this.survey.mergedFields.push(item)
          }
        } else {
          this.survey.mergedFields.push(item)
        }
      })
    })
  },
  methods: {
    hideOptions () {
      this.displayOptions = false
    },
    updateAnsweredMessage (e) {
      this.$store.commit('survey/survey', { answeredMessage: e })
      this.editTitle = false
    }
  }
}
</script>

<style lang="scss">
.content-startend {
  .content-question-title {
    margin: 0 auto;
    margin-top: 80px;
    width: 80%;
    .heading{
      text-align: center;
    }
  }
}
.answered-title {
  margin-top: 20px;
  textarea {
    font-weight: 600;
  }
  .content-edit-title {
    width: 90%;
    margin: 0 auto;
  }
}
</style>
