<template>
  <button class="btn-animate" @click="handleClick" :class="{ 'loading': isLoading, 'disabled': disabled }" :disabled="isLoading || disabled">
      <span class="is-loading" v-if="isLoading">
        <div class="stripes"></div>
        {{ messages.loading }}
      </span>
      <span class="is-send" v-else>
        {{ messages.initial }}
        <i class="material-icons icon-send-resend">send</i>
      </span>
    </button>
</template>

<script>
export default {
  name: 'ButtonAnimate',
  props: ['messages', 'isLoading', 'disabled'],
  components: {},
  data () {
    return {
    }
  },
  methods: {
    handleClick () { this.$emit('has-click') }
  }
}
</script>

<style lang="scss" scoped>
.btn-animate {
  width: 28vh;
  height: 2vw;
  user-select: none;
  position: relative;
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: var(--default-text-color);
  border: none;
  border-radius: 0.3rem;
  font-size: 1.5vh;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  transition: opacity 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  .is-loading {
    color: var(--default-text-color);
    .stripes {
      background-image: linear-gradient(45deg, transparent 25%, var(--default-text-color) 25%, var(--default-text-color) 50%, transparent 50%, transparent 75%, var(--default-text-color) 75%, var(--default-text-color));
      background-size: 20px 20px;
      height: 100%;
      left: 0;
      opacity: 0.1;
      position: absolute;
      top: 0px;
      width: 100%;
      animation: moveStripes 2s linear infinite;
    }
  }
  .disabled {
    pointer-events: none;
  }
  .is-send {
    display: flex;
    align-items: center;
    gap: 1rem;
    .icon-send-resend {
      font-size: 1.7vh;
      padding-top: 0.2rem;
    }
  }
}

.btn-animate:hover {
  transform: scale(1.001);
  box-shadow: 0 0 4px var(--accent-color);
}

.loading, .loading:hover {
  cursor: not-allowed;
  color: var(--primary-color);
  border: none;
}

@keyframes moveStripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 0;
  }
}
</style>
