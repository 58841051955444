<template>
  <div class="side-settings send-config">
    <div class="survey-config-heading">
      <app-heading level="h4">{{ $t('sendhub.import_contacts') }}</app-heading>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <app-card
          class="type-card"
          v-on:click.native="changeSelected('onebyone')"
          :class="{'active-type-send': active === 'onebyone', 'inactive-card': active !== 'onebyone'}"
        >
          <img :src="importImage.keyboard" style="width:48px; height: auto;">
          <app-heading level="h6">{{ $t('sendhub.type_one_by_one') }}</app-heading>
        </app-card>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <app-card
          class="type-card"
          v-on:click.native="changeSelected('csv')"
          :class="{'active-type-send': active !== 'onebyone', 'inactive-card': active === 'onebyone'}"
        >
          <img :src="importImage.csv" style="width: 48px; height: auto;">
          <app-heading level="h6">{{ $t('sendhub.file_types') }}</app-heading>
        </app-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SendConfig',
  components: {
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      type: '',
      isModalOpen: false,
      active: 'onebyone'
    }
  },
  mounted () {
    this.type = this.$route.query.type
  },
  computed: {
    importImage () {
      return {
        csv: require('@/assets/img/csv.svg'),
        keyboard: require('@/assets/img/keyboard.svg')
      }
    }
  },
  methods: {
    changeSelected (selected) {
      this.active = selected
      this.$root.$emit('update-active-import', selected)
    },
    useTemplate () { }
  }
}
</script>

<style lang="scss">
.type-card {
  padding: 10px;
  width: 90% !important;
  border-radius: 9px !important;
  cursor: pointer;
}
.inactive-card {
  background-color: rgb(237, 234, 234) !important;
}
.active-type-send {
  background: var(--accent-color) !important;
  h6 {
    color: #eff3f8 !important;
  }
}
.send-config {
  max-height: 100% !important;
  min-height: 100% !important;
}
</style>
