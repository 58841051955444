<template>
  <div class="filter-role-query" v-if="query">
    <div class="title-role-query">
      <app-heading level="h5" class="title">{{ $t("role.filter_title") }}</app-heading>
      <app-heading level="h8" style="font-style: italic">{{ $t("role.filter_description") }}</app-heading>
    </div>
    <div v-for="(item, idx) in query" :key="idx" class="role-query">
      <div class="delete-field">
        <button @click="deleteRow(idx)">
          <app-icon name="trash" size="sm" color="white" v-title="$t('role.delete_field')" title-placement="top" />
        </button>
      </div>
      <div class="item-role-query" :key="`meta-${key}`">
        <v-select
          :clearable="false"
          :options="metadataOptions"
          v-model="item.model"
          @input="selectMetadata(idx)"
        ></v-select>
      </div>
      <div class="item-role-query" :key="`port-${key}`">
        <v-select
          :clearable="false"
          :options="operatorPort"
          v-model="item.operator"
          @input="selectOperator(idx)"
        ></v-select>
      </div>
      <app-form-input v-if="item.operator.value !== '$exists'"
      border-bottom
      :placeholder="$t('role.value')"
      v-model="item.value"
      v-on:change="textFilter(idx)"
      />
    </div>
    <div class="add-field" @click="addRow()">
        <app-icon name="duplicate" size="sm" color="white" v-title="$t('role.add_field')" title-placement="top" />
    </div>
  </div>
</template>

<script>
import { userService } from '@/_services'

export default {
  name: 'CardFilterRole',
  props: ['query'],
  components: {
    vSelect: () => import('vue-select'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-icon': () => import('@binds-tech/binds-design-system/src/components/Icon/Icon'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input')
  },
  data () {
    return {
      key: 0,
      fieldsMeta: [],
      metadataOptions: [],
      operatorPort: [
        { value: '$eq', label: this.$i18n.t('advanced_filters.fields.is_equal') },
        { value: '$ne', label: this.$i18n.t('advanced_filters.fields.is_different') },
        { value: '$exists', label: this.$i18n.t('advanced_filters.fields.is_define'), boolean: true },
        { value: '$exists', label: this.$i18n.t('advanced_filters.fields.is_not_define'), boolean: false }
      ]
    }
  },
  mounted () {
    this.updateOptionsMeta()
  },
  computed: {
    account () { return this.$store.getters['account/getAccount'] }
  },
  methods: {
    async updateOptionsMeta () {
      const me = await userService.getMe()
      this.metadataOptions = me.account.fields.map(item => {
        return { value: item.key, label: item.label }
      })
    },
    selectMetadata (idx) {
      const filter = this.query
      const selected = filter[idx]
      let seedMeta = ''
      const newKey = 'seed.metadata.' + selected.model.value
      Object.keys(selected).forEach(item => {
        if (item.startsWith('seed.metadata')) { seedMeta = item }
      })
      selected[newKey] = selected[seedMeta]
      delete selected[seedMeta]
      this.query[idx] = selected
      this.key++
    },
    textFilter (idx) {
      const filter = this.query
      const selected = filter[idx]
      let seedMeta = ''
      Object.keys(selected).forEach(item => {
        if (item.startsWith('seed.metadata')) { seedMeta = item }
      })

      let operator = ''
      Object.keys(selected[seedMeta]).forEach(item => { operator = item })
      selected[seedMeta][operator] = selected.value
      this.query[idx] = selected
    },
    deleteRow (idx) {
      this.query.splice(idx, 1)
      this.key++
    },
    addRow () {
      const newField = {
        model: {
          value: 'metadata',
          label: 'Metadata'
        },
        operator: {
          value: '$eq',
          label: this.$i18n.t('advanced_filters.fields.is_equal')
        },
        'seed.metadata.metadata': { $eq: '' }
      }
      this.$emit('add-field-meta', newField)
    },
    selectOperator (idx) {
      const filter = this.query
      const selected = filter[idx]
      let seedMeta = ''
      Object.keys(selected).forEach(item => {
        if (item.startsWith('seed.metadata')) { seedMeta = item }
      })

      let oldOperator = ''
      const operator = selected.operator

      Object.keys(selected[seedMeta]).forEach(item => { oldOperator = item })

      if (operator.value === '$exists') {
        selected[seedMeta].$exists = selected[seedMeta][oldOperator]
        delete selected[seedMeta][oldOperator]
        selected[seedMeta].$exists = operator.boolean
        selected.value = ''
      } else {
        selected[seedMeta][operator.value] = selected.value
        delete selected[seedMeta][oldOperator]
        selected.value = ''
      }

      this.query[idx] = selected
      this.key++
    }
  }
}
</script>

<style lang="scss">
.filter-role-query {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #cacaca;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title-role-query {
    text-align: center;
    margin-bottom: 10px;
    .title {
      padding: 10px 0;
      border-bottom: 0.5px solid #e6e6e6;
    }
    .h8 {
      margin: 10px 0;
    }
  }
  .role-query {
    width: 95%;
    min-height: 200px;
    padding: 5px;
    margin: 20px 0;
    border-radius: 5px;
    border: 0.5px solid #e6e6e6;
    .text-field__input {
      width: 100%;
      height: 30px;
      margin: 10px 0 5px 0;
      border-bottom: 0.5px solid #e6e6e6;
      padding-left: 5px;
      background: transparent !important;
      input {
        height: 90%;
        padding: 0;
        font-size: 17px;
        font-weight: 400;
      }
    }
    .delete-field {
      width: 100%;
      text-align: right;
      margin: 5px 0;
      .b-icon {
        cursor: pointer;
      }
    }
    .item-role-query {
      margin: 15px 0;
      .v-select {
        .vs__dropdown-toggle {
          border: 0.5px solid #e6e6e6;
        }
      }
    }
  }
  .add-field {
    cursor: pointer;
  }
}
</style>
