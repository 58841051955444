<template>
  <div>
    <app-heading level=h5 class="step-one">{{ $t('sendhub.emailSmsHub.step_one') }}</app-heading>
    <emailHub></emailHub>
    <app-heading level=h5 class="step-two">{{ $t('sendhub.emailSmsHub.step_two') }}</app-heading>
    <smsHub></smsHub>
  </div>
</template>

<script>
import emailHub from './emailHub'
import smsHub from './smsHub'

export default {
  name: 'EmailSmsHub',
  data () {
    return {}
  },
  components: {
    emailHub,
    smsHub,
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  mounted () {

  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
.step-one {
  margin-top: 25px !important;
  margin-left: 5px !important;
}
.step-two {
  margin-top: -45px !important;
  margin-left: 5px !important;
}
</style>
