<template>
  <div>
    <div class="act" style="height:100vh;" :class="{'static-side': fixed}">
      <div class="col-lg-12 col-md-12" v-if="canShowEmbeded">
        <app-toggle-input
          v-model="emailSettings.hasEmbeddedQuestion"
          v-on:change.native="updateEmailSettings()"
        ></app-toggle-input>
        <app-heading level="h6">{{$t('sendhub.toggle_embedded_question')}}</app-heading>
      </div>
      <div v-if="colors" class="email-colors">
        <app-heading class="col-lg-12 col-md-12 col-sm-12 mt50" level="h5">{{ $t('sendhub.email_colors') }}:</app-heading>
        <div class="items-email-colors ">
          <div class="theme-item sw-colors">
            <input type="color" class="palette" @input="updateEmailSettings()" v-model="colors.c1"/>
          </div>
          <div class="theme-item sw-colors">
            <input type="color" class="palette" @input="updateEmailSettings()" v-model="colors.c2"/>
          </div>
          <div class="theme-item sw-colors">
            <input type="color" class="palette" @input="updateEmailSettings()" v-model="colors.c3"/>
          </div>
          <div class="theme-item sw-colors">
            <input type="color" class="palette" @input="updateEmailSettings()" v-model="colors.c4"/>
          </div>
        </div>
      </div>
      <div class="config-image-email">
        <app-heading level="h5" class="col-lg-12 col-md-12 col-sm-12 mt50">{{$t('sendhub.images')}}:</app-heading>
        <SetImg :imageProps="imageProps" :title="$t('sendhub.set_image_text')" @toEmailImage="updateEmailImage()" context="email"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailConfig',
  components: {
    SetImg: () => import('../image/SetImg.vue'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput')
  },
  data () {
    return {
      fixed: false
    }
  },
  computed: {
    emailSettings () {
      const settings = this.$store.getters['sendhub/getEmailSettings']
      return settings
    },
    colors () {
      const settings = this.$store.getters['sendhub/getEmailSettings']
      return settings.colors
    },
    firstQuestion () {
      const questions = this.$store.getters['survey/getQuestions']
      const firstQuestion = questions[0]
      return firstQuestion
    },
    canShowEmbeded () {
      let result
      ['5emo', '5num', '10num', 'yn', 'ynm', 'ynd', '1to7button', '1to7', '5radio', 'single', 'singleOther'].indexOf(this.firstQuestion.ui) > -1 ? result = true : result = false
      if (this.firstQuestion.type === 'matrix') {
        result = false
      }
      return result
    },
    imageProps () {
      const email = this.$store.getters['sendhub/getEmailSettings']
      const result = {}
      if (email.images && email.images.length > 0) {
        result.value = email.images[0].value
        result.where = 'emailSettings'
        result.success = !!email.images[0].value
        result.crop = 'free'
        result.imageShrink = '800x600 100%'
        result.imageOnly = true
      }
      return result
    }
  },
  mounted () {
    window.addEventListener('scroll', this.staticSide)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.staticSide)
  },
  methods: {
    staticSide () {
      window.scrollY > 250 ? this.fixed = true : this.fixed = false
    },
    updateEmailSettings () {
      this.$root.$emit('EmbededQuestion', this.emailSettings.hasEmbeddedQuestion)
      this.$store.commit('sendhub/sendhub', { emailSettings: this.emailSettings })
    },
    updateEmailImage () {
      this.$root.$emit('colorTemplateEmail', this.colors)
    }
  },
  watch: {
    colors: {
      deep: true,
      handler (newValue) {
        this.$root.$emit('colorTemplateEmail', newValue)
      }
    }
  }
}
</script>
<style lang="scss">
.labelColors {
  width: 20%;
  padding-left: 2%;
}
.labelText {
  margin-left: 20px;
  margin-top: 20px;
  & h6 {
    font-size: 14px !important;
    margin-left: 0 !important;
  }
  & .text-field__input {
    & input {
      font-size: 16px !important;
    }
  }
}
.email-colors {
  margin-top: 50px;
  padding-left: 20px;
  .items-email-colors {
    display: flex;
    .palette {
      height: 2.1vw;
      width: 2.1vw;
      display: flex;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
}
.sw-colors {
  margin-right: 15px;
  margin-top: 20px;
  display: flex !important;
}
.emailHr {
  height: 1px;
  width: 103%;
  color: red;
  background-color: rgb(221, 221, 221);
  border: none;
  margin-left: 8%;
  margin-top: 5%;
}
.colorPicker {
  height: 70px;
  display: flex;
  padding-bottom: 10px;
  .heading{
    padding-left: 23px !important;
  }
}
.config-image-email {
  margin-top: 120px;
  padding-left: 20px;
  .content-upload-setImage {
    margin-top: 40px;
  }
  .set-image {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70px;
    margin-left: 0;
  }
}
.static-side {
  position: fixed;
  width: 342px;
  top:0;
}
</style>
