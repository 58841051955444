<template>
  <div>
    <div class="content-selector">
      <app-modal
        v-model="showModal"
        :title="$t('survey.conditions_msg')"
        lightbox
        class="modal-default-primary modal-conditions"
        :primary-button="$t('survey.continue')"
        @primaryButtonClick="clearConditions()"
        :secondary-button="$t('survey.cancel')"
        @secondaryButtonClick="cancel()"
        v-on:keyup.esc="cancel()"
      >
        <app-heading level="h6">{{ $t('survey.conditions_msg2') }}</app-heading>
      </app-modal>
      <div class="nav-selector">
        <carousel
          style="padding: 9px;width: 84%;margin: 0 auto;"
          :navigationEnabled="true"
          :paginationEnabled="false"
          :perPageCustom="[[768, 3], [1024, 9]]"
          navigationNextLabel="<i class='material-icons'>keyboard_arrow_right</i>"
          navigationPrevLabel="<i class='material-icons'>keyboard_arrow_left</i>"
        >
          <slide v-for="item in shared" v-bind:key="item.type">
            <div>
              <app-button
                v-title="$t(item.tooltip)"
                style="display: grid;font-size: 18px;color: #3f3356;font-weight: 600;margin: 0 auto;"
                v-on:click="selectType(item, shared)"
                :class="{ 'navSelectorIsActive': item.type === selectedAnswerType }"
              >
                <app-icon v-if="item.type === 'nps' ||  item.type === 'enum'" :name="item.icon" size="md"/>
                <i v-else class="material-icons">{{item.icon}}</i>
                <span v-if="['csat', 'nps', 'kpi', 'nvs', 'ces', 'nes'].indexOf(item.type) > -1">
                  {{ item.type === 'ces' ? 'ces 2.0' : item.type === 'nes' ? 'ces' : item.type }}
                </span>
                <span v-if="item.type === 'matrix'">{{ $t('survey.matrix') }}</span>
                <span v-if="item.type === 'referral'">{{ $t('survey.referral') }}</span>
                <span v-if="item.type === 'text'">{{ $t('survey.text') }}</span>
                <span v-if="item.type === 'enum'">enum</span>
                <!-- <span v-if="item.type === 'enum'">{{ $t('survey.enum') }}</span> -->
              </app-button>
            </div>
          </slide>
        </carousel>
      </div>
      <div class="selector-ui">
        <template v-for="(item, idx) in shared">
          <ul :key="idx" v-if="selectedAnswerType === item.type">
            <template v-for="(ui, uiIndex) in item.ui">
              <li :key="uiIndex" v-on:click="selectUi(item, ui)">
                <div>
                  <img
                    :src="ui.image"
                    class="img-size setFilterGrey"
                    :class="{ 'stars-select': ui.isStar }"
                    :width="ui.imgWidth"
                  >
                </div>
              </li>
            </template>
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { iconFormatter, responseOptions, referralSettings } from '@/_helpers'
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'Selector',
  components: {
    Carousel,
    Slide,
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-icon': () => import('@binds-tech/binds-design-system/src/components/Icon/Icon'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal')
  },
  data () {
    return {
      editTitle: false,
      editDescription: false,
      shared: [],
      conditionsToDelete: [],
      showModal: false,
      icon: '',
      type: '',
      ui: '',
      currentQuestionToUpdateAfterRemoveConditions: {}
    }
  },
  mounted () {
    const acc = this.$store.getters['account/getUser']
    this.shared = iconFormatter.getSharedIcons(acc.language)
    this.$root.$on('check-conditional-modal', questionChanged => {
      this.checkIfHaveConditionsWithQuestion(questionChanged, false)
    })
  },
  beforeDestroy () {
    this.$root.$off('check-conditional-modal')
  },
  computed: {
    question () {
      const qs = this.$store.getters['survey/getQuestionSelected']
      return qs
    },
    questions () {
      const questions = this.$store.getters['survey/getQuestions']
      return questions
    },
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    },
    selectedAnswerType () {
      let selected
      if (this.survey.selectedType !== 'blank') {
        selected = this.survey.selectedType
      } else {
        selected = 'kpi'
      }
      return selected
    }
  },
  methods: {
    selectType (item, shared) {
      this.survey.selectedType = item.type
      this.question.notificate = []
      this.question.endLabel = ''
      this.question.startLabel = ''
      this.$store.commit('survey/upsertQuestion', this.question)
      this.$store.commit('survey/survey', this.survey)
      this.$root.$emit('update-survey-settings-key')
    },
    selectUi (widgetSelector, ui) {
      this.survey.ticket.triggers = this.survey.ticket.triggers.filter(trigger => {
        return trigger.questionId !== this.question._id
      })

      const auxQuestion = Object.assign({}, this.question)
      this.icon = widgetSelector.icon
      this.type = widgetSelector.type
      this.ui = ui.name

      auxQuestion.ui = this.ui
      auxQuestion.gradient = true
      auxQuestion.type = this.type
      auxQuestion.notificate = []
      auxQuestion.options = []
      auxQuestion.startLabel = ''
      auxQuestion.endLabel = ''
      delete auxQuestion.subjects
      delete auxQuestion.mask

      if (this.type === 'text') {
        auxQuestion.required = false
      }

      if (auxQuestion.type === 'csat') {
        if (ui.isStar) {
          auxQuestion.stars = {
            active: true,
            numbers: false
          }
          auxQuestion.gradient = false
        } else {
          auxQuestion.stars = {
            active: false,
            numbers: false
          }
        }
      }

      if (auxQuestion.ui === '5num' || auxQuestion.ui === '5nes' || auxQuestion.ui === '10num') {
        auxQuestion.options = responseOptions.getOptions(auxQuestion)
        if (auxQuestion.ui === '5nes') {
          auxQuestion.ui = '10num'
          this.ui = '10num'
        }
      }

      if (auxQuestion.ui === 'referralDynamic') {
        auxQuestion.referral = referralSettings.getDynamicFields()
      }

      if (auxQuestion.ui === 'referral') {
        auxQuestion.referral = referralSettings.getOptions()
      }

      if ((['0to5', '1to5', '0to10', '1to10', '10num'].indexOf(auxQuestion.ui) > -1) && auxQuestion.type === 'enum') {
        auxQuestion.options = responseOptions.createOptionsToEnumUi(auxQuestion.ui)
        auxQuestion.ui = '10num'
        this.ui = '10num'
        auxQuestion.startLabel = ''
        auxQuestion.endLabel = ''
      } else if (auxQuestion.ui === 'text') {
        auxQuestion.mask = ui.mask
        if (auxQuestion.mask === 'email') {
          auxQuestion.placeholder = 'hello@binds.co'
        }
        if (auxQuestion.mask === 'tel') {
          auxQuestion.placeholder = '+55 11 xxxx-xxxx'
        }
        if (auxQuestion.mask === 'text') {
          auxQuestion.placeholder = ''
        }
      }

      if (auxQuestion.type === 'ces') {
        auxQuestion.options = responseOptions.getOptions(auxQuestion)
      }

      if (this.question.ui !== this.ui || this.question.type !== this.type) {
        this.checkIfHaveConditionsWithQuestion(auxQuestion)
        return true
      }

      this.$store.commit('survey/upsertQuestion', auxQuestion)
      this.$store.commit('survey/survey', { showSelector: false })
    },
    checkIfHaveConditionsWithQuestion (questionChanged) {
      this.conditionsToDelete = []

      this.questions.map((question, questionIndex) => {
        question.showIfAll.map((condition, index) => {
          if (condition.question === questionChanged.id) {
            this.conditionsToDelete.push({
              where: 'questions',
              conditionIndex: condition.question,
              questionIndex: questionIndex,
              type: 'showIfAll'
            })
          }
        })

        question.showIfAny.map((condition, index) => {
          if (condition.question === questionChanged.id) {
            this.conditionsToDelete.push({
              where: 'questions',
              conditionIndex: condition.question,
              questionIndex: questionIndex,
              type: 'showIfAny'
            })
          }
        })
      })

      this.survey.endMessages.map((endMessage, questionIndex) => {
        endMessage.showIfAll.map((condition, index) => {
          if (condition.question === questionChanged.id) {
            this.conditionsToDelete.push({
              where: 'endMessage',
              conditionIndex: condition.question,
              questionIndex: questionIndex,
              type: 'showIfAll'
            })
          }
        })

        endMessage.showIfAny.map((condition, index) => {
          if (condition.question === questionChanged.id) {
            this.conditionsToDelete.push({
              where: 'endMessage',
              conditionIndex: condition.question,
              questionIndex: questionIndex,
              type: 'showIfAny'
            })
          }
        })
      })

      if (this.conditionsToDelete.length > 0) {
        this.showModal = true
        this.currentQuestionToUpdateAfterRemoveConditions = questionChanged
      } else {
        this.changeUi(questionChanged)
      }
    },
    changeUi (questionChanged) {
      const objectToUpdate = questionChanged

      objectToUpdate.icon = this.icon
      objectToUpdate.type = this.type
      objectToUpdate.ui = this.ui
      if (objectToUpdate.ui === 'text') {
        objectToUpdate.type = 'text'
        objectToUpdate.icon = 'text_fields'
      }

      if (this.survey.ticket.triggers) {
        const index = this.survey.ticket.triggers.findIndex(t => t.questionId === questionChanged._id)
        if (index >= 0) {
          this.survey.ticket.triggers.splice(index, 1)
        }
      }

      this.$store.commit('survey/upsertQuestion', objectToUpdate)
      this.$store.commit('survey/survey', { showSelector: false })
    },
    clearConditions () {
      const endMessages = [...new Set(this.survey.endMessages)]
      this.conditionsToDelete.map(item => {
        if (item.type === 'showIfAll') {
          if (item.where === 'questions') {
            this.questions[item.questionIndex].showIfAll = this.questions[item.questionIndex].showIfAll.filter(condition => condition.question !== item.conditionIndex)
          }
          if (item.where === 'endMessage') {
            endMessages[item.questionIndex].showIfAll = endMessages[item.questionIndex].showIfAll.filter(condition => condition.question !== item.conditionIndex)
          }
        }

        if (item.type === 'showIfAny') {
          if (item.where === 'questions') {
            this.questions[item.questionIndex].showIfAny = this.questions[item.questionIndex].showIfAny.filter(condition => condition.question !== item.conditionIndex)
          }
          if (item.where === 'endMessage') {
            endMessages[item.questionIndex].showIfAny = endMessages[item.questionIndex].showIfAny.filter(condition => condition.question !== item.conditionIndex)
          }
        }

        if (item.where === 'questions') {
          this.$store.commit('survey/upsertQuestion', this.questions[item.questionIndex])
        }
        this.$store.commit('survey/survey', { endMessages })
      })

      this.showModal = false
      this.changeUi(this.currentQuestionToUpdateAfterRemoveConditions)
      this.conditionsToDelete = []
      this.currentQuestionToUpdateAfterRemoveConditions = {}
    },
    cancel () {
      this.showModal = false
      this.conditionsToDelete = []
      this.currentQuestionToUpdateAfterRemoveConditions = {}
    }
  }
}

</script>

<style lang="scss">
.modal-conditions {
  h6 {
    line-height: 1.5 !important;
  }
}
.setFilterGrey {
  filter: grayscale(100%);
}
.content-selector {
  width: 100%;
  display: block;
  margin: 0 auto;
  height: 400px;
  overflow: auto;
  // background-color: #eff3f8;
  text-align: center;
}

.selector-ui {
  background-color: #fff;
  padding: 18px;
  height: auto;
  box-sizing: border-box;
  text-align: left;
  margin-top: 13%;
  @extend %animated-faster;
  @extend %fadeIn;
  ul {
    li {
      overflow: hidden;
      width: 40%;
      height: 147px;
      font-weight: bold;
      text-align: center;
      border-radius: 6px;
      color: grey;
      padding: 10px;
      display: inline-block;
      vertical-align: top;
      margin: 15px;
      background-color: #fff;
      cursor: pointer;
      border:1px solid #b5b5b5;
      line-height: 172px;
      &:hover {
        border:1px solid $color-purple-dark;
      }
    }
  }
}
.navSelectorIsActive {
  i, span {
    // color: $color-purple !important;
    opacity: 1 !important;
  }
}
.nav-selector {
  position: absolute;
  background-color: #f0f4f8;
  z-index: 1;
  width: 100%;
  // padding: 12px 0;
  text-align: center;
  div {
    // display: inline-block;
    // // margin: 0 14px;
    // margin: 0px 14px 20px 14px;
    // min-width: 60px;
    .binds-button.default {
      text-align: center;
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding:0;
      i {
        font-size: 25px;
        color: $color-purple-darker;

      }
      span {
        display: block;
        font-size: 14px;
        color: $color-purple-darker;
        font-weight: 600;

      }

    }
  }
}
.stars-select {
  transform: scale(0.7) !important;
  -webkit-transform: scale(0.7) !important;
  margin-top: 10px !important;
  margin-left: -40px !important;
}
</style>
