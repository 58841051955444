<template>
  <div>
    <Menu :showSubMenu="false" :key="key"></Menu>
    <div id="container" style="display: block;text-align: center;">
      <div style="margin-bottom: 20px;  border-radius: 0px 0px 7px 7px; box-shadow: rgba(0, 0, 0, 0.49) 2px 2px 4px -3px;">
        <input
          style="border: medium none; width: 90%;  padding: 16px; border-radius: 4px;display: flex;margin-left: 24px;"
          @input="isTyping = true"
          v-model="searchQuery"
          :placeholder="$t('account_details.type_id_or_name')"
        />
      </div>
      <div class="cardsContainer">
        <div
          @click="openDetails(account._id)"
          class="accountCard"
          v-for="(account, index) in accounts"
          :key="index"
        >
          <div
            class="accountLogo"
            :style="{'background-color': !account.theme || !account.theme.smallLogo || !account.logoUrl ? '#D8D8D8' : '#FFFFFF',
            'background-image': account.theme && account.theme.smallLogo ? `url(${account.theme.smallLogo})` :
              account.logoUrl ? `url(${account.logoUrl})` : 'url(https://res.cloudinary.com/binds-co/image/upload/v1508526527/placeholder_je0010.png)'}"
          >
          <!-- <img :src="account.theme && account.theme.smallLogo ? `${account.theme.smallLogo}` :
              account.logoUrl ? `${account.logoUrl}` : 'https://res.cloudinary.com/binds-co/image/upload/v1508526527/placeholder_je0010.png'" alt="logo"> -->
          </div>
          <div class="changeToThisAccount alignRight">
            <div>
              <i
                @click="changeToThisAccount(account)"
                v-title="$t('account_details.enter_account')"
                class="changeCircle btn-icons material-icons"
              >change_circle</i>
            </div>
          </div>
          <div class="changeToThisAccount alignLeft">
            <div>
              <i :class="{'btn-icons': account.haveEverSent}" v-title="account.haveEverSent ? `${$t('account_details.already_sent')}` : `${$t('account_details.didnt_send_yet')}`" class="notHaveEverSent material-icons">check_circle</i>
            </div>
          </div>
          <div class="accountInfo">
            <div class="detail-container">
              <p>
                <span class="useWeightText">criado:</span>
                <span class="notUseWeightText accountHour">{{
                  moment(account.createdAt)
                }}</span>
              </p>
              <p>
                <span class="useWeightText">atualizado:</span>
                <span class="notUseWeightText accountHour">{{
                  moment(account.updatedAt)
                }}</span>
              </p>
            </div>
            <div v-if="account.owner"
              class="detail-container"
              style="
                padding-bottom: 5px;
                height: 130px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;"
              >
              <p style="justify-content: center">
                <span class="useWeightText accountHour"  style="width: 98%">{{ account.name }}</span>
              </p>
              <p style="justify-content: center">
                <span class="notUseWeightText accountHour" style="width: 98%; font-size:14px;">{{ account._id }}</span>
              </p>
              <p style="justify-content: center">
                <span class="notUseWeightText accountHour" style="width: 98%">{{ account.owner.name }}</span>
              </p>
              <p style="justify-content: center">
                <span class="notUseWeightText accountHour" style="width: 98%">{{ account.owner.email }}</span>
              </p>
              <p style="justify-content: center">
                <span class="notUseWeightText">{{ account.owner.tel }}</span>
              </p>
            </div>
            <div v-else class="detail-container" style="padding-bottom: 10px">
              <p style="justify-content: center">
                <span class="useWeightText accountHour"  style="width: 98%">{{ $t('account_details.no_name') }}</span>
              </p>
              <p style="justify-content: center">
                <span class="notUseWeightText accountHour" style="width: 98%">{{ $t('account_details.no_email') }}</span>
              </p>
              <p style="justify-content: center">
                <span class="notUseWeightText">{{ $t('account_details.no_phone') }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AccountDetails @closeAccountSideBar="openSideBar = false" v-if="openSideBar" :accountId="currentAccountId"></AccountDetails>
    <div class="data-loading-account-fixed" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <!-- <side-bar-user v-if="openSideBar" :user="activeUser" :roles="roles" @closeSideBarUser="openSideBar = false" @newUser="addNewUser($event)"/> -->
  </div>
</template>

<script>
import { accountService, userService } from '@/_services'

import Menu from '../components/Menu.vue'
import AccountDetails from '../components/SideBarConfig/AccountDetails.vue'
export default {
  name: 'AccountsList',
  components: {
    Menu,
    AccountDetails,
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  data () {
    return {
      key: 0,
      openSideBar: false,
      accounts: [],
      loading: false,
      isTyping: false,
      searchQuery: '',
      currentAccountId: ''
    }
  },
  beforeCreate: () => {
    document.body.className = 'whiteAccoutListBg'
  },
  beforeDestroy () {
    document.body.classList.remove('whiteAccoutListBg')
  },
  async mounted () {
    this.loading = true
    this.accounts = await accountService.getAccounts()
    this.loading = false

    this.$root.$on('closeSideBarAccount', () => {
      setTimeout(() => {
        this.openSideBar = false
      }, 1000)
    })
  },
  watch: {
    searchQuery () {
      setTimeout(() => {
        this.isTyping = false
      }, 2000)
    },
    isTyping (value) {
      if (!value) {
        this.searchAccount(this.searchQuery)
      }
    }
  },
  computed: {
    lang () {
      return this.$store.getters['account/getLang']
    }
  },
  methods: {
    moment (date) {
      this.$moment.locale(this.lang)
      return this.$moment(date).format('l')
    },
    openDetails (accountId) {
      if (!this.loading) {
        this.openSideBar = true
        this.currentAccountId = accountId
      }
    },
    async searchAccount (searchQuery) {
      this.loading = true
      var valueEscapded = searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      this.accounts = await accountService.searchAccount(valueEscapded)
      this.loading = false
    },
    async changeToThisAccount (account) {
      this.loading = true
      const user = this.$store.getters['account/getUser']
      if (user._id && account._id) {
        this.loading = true
        const payload = JSON.parse(JSON.stringify(user))
        payload.account = account._id
        const fetchAll = [userService.updateUser(payload._id, payload), accountService.getAccount(payload.account)]
        const [hasUpdatedUser, getAccount] = await Promise.all(fetchAll)
        this.loading = false
        if (hasUpdatedUser._id && getAccount._id) {
          this.$store.commit('account/setAccount', getAccount)
          this.$store.commit('dashboard/resetToInitialState')
          this.$store.commit('filtersSocialMedia/resetToInitialState')
          this.$store.commit('filters/resetToInitialState')
          this.$store.commit('survey/resetToInitialState')
          this.$store.commit('sendhub/resetToInitialState')
          this.$store.commit('seeds/resetToInitialState')
          this.$store.commit('templates/resetToInitialState')
          this.$store.commit('widget/resetToInitialState')
          this.$store.commit('questionSettings/resetToInitialState')
          this.$store.commit('filtersInsight/resetToInitialState')
        } else {
          this.$store.commit('alerts/alert', {
            message: 'error on account updated',
            showAlert: true
          })
        }
      }
      this.loading = false
      this.key++
    }
  }
}
</script>

<style lang="scss">
.data-loading-account-fixed {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .data-loading-spinner {
    margin-top: 24% !important;
    margin-left: 48% !important;
    & .spinner-inner {
      background-color: #fff !important;
    }
  }
}
.whiteAccoutListBg {
  background-color: #ffffff;
}
.notHaveEverSent {
  color:#e5e5e5;
}
.accountHour {
  width: 48%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1px;
}
.accountInfo {
  padding-top: 5px;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}
.accountLogo {
  margin: 0 auto;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 70%;
    height: 80%;
  }
}
.alignLeft {
  justify-content: left;
  margin-top: -46px;
}
.alignRight {
  justify-content: right;
  margin-top: -12%;
}
.changeToThisAccount {
  width: 100%;
  display: grid;
  & div {
    border-radius: 2px;
    padding: 10px;
    width: 27px;
  }
}
.cardsContainer {
  max-height: 84vh;
  overflow: scroll;
}
.accountCard {
  cursor: pointer;
  display: inline-block;
  width: 14%;
  border-radius: 11px;
  padding: 15px;
  margin: 9px;
  box-shadow: rgba(0, 0, 0, 0.5) 3px 4px 7px -5px;
  .accountLogo {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    border: 1px solid rgb(209, 205, 205);
  }
}
.useWeightText {
  font-weight: bold;
  color: #717171;
}
.notUseWeightText {
  color: #717171;
}
.detail-container > p {
  -webkit-box-pack: justify;
  justify-content: space-between;
  display: flex;
  padding: 4px;
}
</style>
