<template>
    <div v-html="embededCode"></div>
</template>
<script>
export default {
  props: ['question'],
  data () {
    return {
      embededCode: [
        `<!--[if mso]>
          <style type="text/css">
          .buttonLink {
            font-size: 20px !important;
          }
          .hideSpace {
            display: block !important;
          }
          </style>
        <![endif]-->`,
        '<div class="email-cta" style="width:500px;margin:0 auto;"> \n ',
        '<br>',
        '<br>',
        '<h2 style="display:block; margin-bottom:20px;text-align:center;color:{{color.c2}}">' + this.question.question + '</h2> \n ',
        '<table width="500" cellspacing="0" cellpadding="0" border="0" align="center" style="text-align:center"> \n',
        '<tbody> \n',
        '<tr> \n',
        '<td valign="top"> \n',
        '<a class="buttonLink" href="*|SRV:100|*" style="line-height:40px;width:200px;height:40px;border-radius:24px;font-size: 16px; font-weight: bold; background-color:#57ad26;color:#fff;display:block;text-align:center;margin:0 auto;text-decoration: none;"> &nbsp;&nbsp;&nbsp;&nbsp; Sim\n',
        '&nbsp;&nbsp;&nbsp;&nbsp;</a> \n',
        '</td> \n',
        '</tr> \n',
        '<tr class="hideSpace" style="display:none;"><td>&nbsp;&nbsp;</td></tr>',
        '<tr> \n',
        '<td valign="top"> \n',
        '<a class="buttonLink" href="*|SRV:0|*" style="line-height:40px;width:200px;height:40px;border-radius:24px;font-size: 16px; font-weight: bold; background-color:#ea484d;color:#fff;display:block;text-align:center;margin:0 auto;margin-top:12px;text-decoration: none;"> &nbsp;&nbsp; Não\n',
        '&nbsp;&nbsp;</a> \n',
        '</td> \n',
        '</tr> \n',
        '<tr class="hideSpace" style="display:none;"><td>&nbsp;&nbsp;</td></tr>',
        '<tr> \n',
        '<td valign="top"> \n',
        '<a class="buttonLink" href="*|SRV:50|*" style="line-height:40px;width:200px;height:40px;border-radius:24px;font-size: 16px; font-weight: bold; background-color:#f8c43d;color:#fff;display:block;text-align:center;margin:0 auto;margin-top:12px;text-decoration: none;"> &nbsp;&nbsp; Não Sei\n',
        '&nbsp;&nbsp;</a> \n',
        '</td> \n',
        '</tr> \n',
        '</tbody> \n',
        '</table> \n',
        '</div>'
      ].join('')
    }
  },
  mounted () {
    this.$emit('embedQuestion', this.embededCode)
  }
}
</script>
