export const embedGenerator = { returnEmbedCsatOrNps, returnEmbedEnum, returnEmbedCes, returnDefaultEmailTemplate, returnEmbedCsatStars, returnEmbedSingleAndSingleOther }
function returnHeader (question) {
  return `<div class="email-cta" style="width:450px;margin:0 auto;text-align: center;"> \n
    <br>
    <br>
    <h2 style="display:block; margin-bottom:20px;text-align:center;color:{{color.c2}}"> ${question.question} </h2> \n
    <table width="450" cellspacing="4" cellpadding="0" border="0"  align="center"> \n
    <tbody style="display: inline-block"> \n
    <tr> \n
    <td style="text-align: center;" align="center" valign="top">`
}

function footerHtml () {
  return `<td style="color:#333"> \n
    </td> \n
    </tr> \n
    </td> \n
    </tbody> \n
    </table> \n
    </div>`
}

function returnDefaultEmailTemplate () {
  return `<div class="email-cta"><table width="450px" cellspacing="0" cellpadding="0"  align="center">
  <tbody>
    <tr>
      <td style="margin: 0 auto;-webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; color: {{color.c2}}; display: block;" width="300" height="70" bgcolor="{{color.c3}}" align="center">
        <a href="*|SRVYES|*" style="font-size:24px; font-family:Arial,sans-serif; text-decoration: none; line-height:70px; width:100%; display:inline-block"> <span style="color: {{color.c1}}" contenteditable=""> Avaliar</span> </a>
      </td>
    </tr>
  </tbody>
</table>
</div>`
}

function returnEmbedCsatOrNps (question) {
  const parsedObjectQuestion = { ...question }
  let embedHtml = returnHeader(parsedObjectQuestion)

  let options = []
  if (parsedObjectQuestion.options.length <= 0) {
    if (parsedObjectQuestion.ui === '10num') {
      options = [...Array(11).keys()].map(item => { return { label: item, rating: (item * 10).toString() } })
    }
    if (parsedObjectQuestion.ui === '5num') {
      options = [...Array(5).keys()].map(item => { return { label: item + 1, rating: (item * 25).toString() } })
    }
    parsedObjectQuestion.options = options
  }

  embedHtml += numberButtons(parsedObjectQuestion)
  const width = parsedObjectQuestion.ui === '10num' ? 450 : 200
  embedHtml += returnStardAndEndLabels(parsedObjectQuestion, width, 'left', 'right')
  embedHtml += footerHtml()
  return embedHtml
}

function returnEmbedCsatStars (question) {
  const parsedObjectQuestion = { ...question }
  const contentWidth = 250
  let embedHtml = returnHeader(parsedObjectQuestion)
  if (question.stars.numbers) {
    embedHtml += ['<div class="email-cta" style="width:450px;margin:0 auto;"> \n ',
      '<table width="' + contentWidth + 'px" cellspacing="0" cellpadding="0" border="0" class="ng-scope" style="margin: 0 auto;"> \n',
      '<tbody> \n',
      '<tr> \n',
      '<td valign="top" align="center"> \n',
      '<div style="background:#fff;color:#666;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;font-size:20px;">\n',
      '<table width="98%" cellspacing="0" cellpadding="0" border="0" style="display:flex;justify-content:center">\n',
      '<tbody>\n',
      '<tr style="display: flex;">\n',
      '<td valign="top" align="center;" style="position:relative !important;">\n',
      '<a href="*|SRV:0|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n',
      '<img style="margin-top:10px;margin-right:10px;" src="https://ucarecdn.com/6718d1bb-fb7a-4f20-b4dc-f412bfdf31ad/star1.png" alt="1" height="50" width="50"> \n',
      '</a>\n',
      '</td>\n',
      '<td valign="top" align="center;" style="position:relative !important;">\n',
      '<a href="*|SRV:25|*" style="url("https://ucarecdn.com/8f2e1687-38cb-45a4-ba4d-bfd64b604090/starprincipal7.png");width:50px;height:50px;display:inline-block">\n',
      '<img style="margin-top:10px;margin-right:10px;" src="https://ucarecdn.com/a3ca368b-f370-45b2-9835-42d1093a34cf/star2.png" alt="2" height="50" width="50"> \n',
      '</a>\n',
      '</td>\n',
      '<td valign="top" align="center;" style="position:relative !important;">\n',
      '<a href="*|SRV:50|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n',
      '<img style="margin-top:10px;margin-right:10px;" src="https://ucarecdn.com/bcde562a-356b-4a37-b54c-ba2b03b480ee/star3.png" alt="3" height="50" width="50"> \n',
      '</a>\n',
      '</td>\n',
      '<td valign="top" align="center;" style="position:relative !important;">\n',
      '<a href="*|SRV:75|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n',
      '<img style="margin-top:10px;margin-right:10px;" src="https://ucarecdn.com/aec9a7a4-a568-4874-9139-a43a42c9c8d0/star4.png" alt="4" height="50" width="50"> \n',
      '</a>\n',
      '</td>\n',
      '<td valign="top" align="center;" style="position:relative !important;">\n',
      '<a href="*|SRV:100|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n',
      '<img style="margin-top:10px;margin-right:10px;" src="https://ucarecdn.com/1482bd5d-9a17-472f-9c38-236824d59a27/star5.png" alt="5" height="50" width="50"> \n',
      '</a>\n',
      '</td>\n',
      '</tr>\n',
      '</tbody>\n',
      '</table>\n',
      '</div>\n',
      '<br> \n',
      '<br> \n',
      '<br> \n',
      '</td> \n',
      '</tr> \n',
      '</tbody> \n',
      '</table> \n',
      '</div>'
    ].join('')
  } else {
    embedHtml += ['<div class="email-cta" style="width:450px;margin:0 auto;"> \n ',
      '<table width="' + contentWidth + 'px" cellspacing="0" cellpadding="0" border="0" class="ng-scope" style="margin: 0 auto;"> \n',
      '<tbody> \n',
      '<tr> \n',
      '<td valign="top" align="center"> \n',
      '<div style="background:#fff;color:#666;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;font-size:20px;">\n',
      '<table width="98%" cellspacing="0" cellpadding="0" border="0" style="display:flex;justify-content:center">\n',
      '<tbody>\n',
      '<tr style="display: flex;">\n',
      '<td valign="top" align="center;">\n',
      '<a href="*|SRV:0|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n',
      '<img style="margin-top:10px;margin-right:10px;" src="https://ucarecdn.com/8f2e1687-38cb-45a4-ba4d-bfd64b604090/starprincipal7.png" alt="1" height="50" width="50"> \n',
      '</a>\n',
      '</td>\n',
      '<td valign="top" align="center;">\n',
      '<a href="*|SRV:25|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n',
      '<img style="margin-top:10px;margin-right:10px;" src="https://ucarecdn.com/8f2e1687-38cb-45a4-ba4d-bfd64b604090/starprincipal7.png" alt="1" height="50" width="50"> \n',
      '</a>\n',
      '</td>\n',
      '<td valign="top" align="center;">\n',
      '<a href="*|SRV:50|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n',
      '<img style="margin-top:10px;margin-right:10px;" src="https://ucarecdn.com/8f2e1687-38cb-45a4-ba4d-bfd64b604090/starprincipal7.png" alt="1" height="50" width="50"> \n',
      '</a>\n',
      '</td>\n',
      '<td valign="top" align="center;">\n',
      '<a href="*|SRV:75|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n',
      '<img style="margin-top:10px;margin-right:10px;" src="https://ucarecdn.com/8f2e1687-38cb-45a4-ba4d-bfd64b604090/starprincipal7.png" alt="1" height="50" width="50"> \n',
      '</a>\n',
      '</td>\n',
      '<td valign="top" align="center;">\n',
      '<a href="*|SRV:100|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n',
      '<img style="margin-top:10px;margin-right:10px;" src="https://ucarecdn.com/8f2e1687-38cb-45a4-ba4d-bfd64b604090/starprincipal7.png" alt="1" height="50" width="50"> \n',
      '</a>\n',
      '</td>\n',
      '</tr>\n',
      '</tbody>\n',
      '</table>\n',
      '</div>\n',
      '<br> \n',
      '<br> \n',
      '<br> \n',
      '</td> \n',
      '</tr> \n',
      '</tbody> \n',
      '</table> \n',
      '</div>'
    ].join('')
  }
  embedHtml += returnStardAndEndLabels(parsedObjectQuestion, 280, 'left', 'right')
  embedHtml += footerHtml()
  return embedHtml
}

function returnEmbedSingleAndSingleOther (question) {
  let embedHtml = returnHeader(question)
  question.options.forEach((item, idx) => {
    embedHtml += `
      <tr style="display:flex;justify-content:left; min-width: 40%">\n
      <td style="margin-right: 10px">\n
      <a href="*|SRV:${item.label}|*" style="font-size:16px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n
      <img src="https://res.cloudinary.com/binds-co/image/upload/v1530200860/faces/icon-radio.png" alt="radio" height="40" width="40"> \n
      </td>\n
      <td style="display: flex; align-items: center; margin-bottom: 15px;">\n
      <a href="*|SRV:${item.label}|*" style="font-size:16px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n
      </a><a href="*|SRV:${item.label}|*" style="font-size:16px; font-family:Arial,sans-serif; text-decoration:none; line-height:40px; width:100%; display:inline-block;margin: auto;color: #333;">${item.label}</a>\n
      </td>\n
      </tr>\n`
  })
  function otherLabel (question) {
    return `<tr style="display:flex;justify-content:left; min-width: 40%">\n
    <td style="margin-right: 10px">\n
    <a href="*|SRV:${question.otherLabel}|*" style="font-size:16px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n
    <img src="https://res.cloudinary.com/binds-co/image/upload/v1530200860/faces/icon-radio.png" alt="radio" height="40" width="40"> \n
    </td>\n
    <td style="display: flex; align-items: center; margin-bottom: 15px;">\n
    <a href="*|SRV:${question.otherLabel}|*" style="font-size:16px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n
    </a><a href="*|SRV:${question.otherLabel}|*" style="font-size:16px; font-family:Arial,sans-serif; text-decoration:none; line-height:40px; width:100%; display:inline-block;margin: auto;color: #333;">${question.otherLabel}</a>\n
    </td>\n
    </tr>\n`
  }
  if (question.otherLabel) {
    embedHtml += otherLabel(question)
  }
  embedHtml += footerHtml()
  return embedHtml
}

function returnEmbedCes (question) {
  let embedHtml = returnHeader(question)
  question.options.forEach((item, idx) => {
    const tagForColor = question.gradient ? idx : '.c3'
    const rating = getKeyByCheckExistence(item, ['label', 'value', 'rating'])
    embedHtml += `
    <!--[if mso]>
      <style type="text/css">
      a, td {
        line-height: 1.2 !important;
        padding: 6px 15px !important;
        text-decoration: none !important;
        margin: 2px !important;
        width:  150px !important;
      }
      </style>
    <![endif]-->
      <tr>\n
      <td style="background-color:{{color${tagForColor}}};color:#fff;width: 250px;display: flex;border-radius: 25px;margin: 4px auto;text-align: center;" valign="top" align="center">\n
      <a href="*|SRV:${rating}|*" style="background-color:{{color${tagForColor}}};color:#fff;font-size:20px; font-family:Arial,sans-serif; text-decoration:none;text-align: center; line-height:40px; width: 250px; display:inline-block;margin: auto;border-radius: 25px;"> &nbsp;&nbsp;${item.label}</a>\n
      </td> \n
      </tr> \n`
  })
  embedHtml += footerHtml()
  return embedHtml
}

function returnStardAndEndLabels (question, width, alignLeft = 'center', alignRight = 'center') {
  const halfWidth = width / 2

  if (question.startLabel || question.endLabel) {
    return `
    <table cellspacing="4" cellpadding="0" border="0"  align="center" width="${width}" style="margin: 0 auto;">
    <tbody>
    <td colspan="0" align="${alignLeft}" width="${halfWidth}">
    <span style="font-weight:normal;font-family:arial,sans-serif;font-size:16px;line-height:16px;color:#9EA3A9;">
    ${question.startLabel}</span></td>
    <td colspan="0" align="${alignRight}" width="${halfWidth}">
    <span style="font-weight:normal;font-family:arial,sans-serif;font-size:16px;line-height:16px;color:#9EA3A9;">
    ${question.endLabel}</span></td>
    </tbody>
    </table>`
  }
  return `
  <table cellspacing="4" cellpadding="0" border="0"  align="center" width="${width}" style="margin: 0 auto;">
  <tbody>
  </tbody>
</table>`
}

function numberButtons (question) {
  const width = 450

  let embedHtml = `<!--[if mso]>
  <style type="text/css">
  a {
    line-height: 1.2 !important;
    padding: 6px 15px !important;
    text-decoration: none !important;
    margin: 2px !important;
  }
  .buttonLink {
    font-size: 30px !important;
  }
  </style>
  <![endif]-->
  <table min-width="${width}" style="margin: 0 auto;" role="presentation" cellspacing="0" cellpadding="0" border="0" align="center"> \n
  <tbody> \n
  <tr style="display:inline-block"> \n
  <td style="width: 2px;"></td> \n
  `
  question.options.forEach((item, idx) => {
    const tagForColor = question.gradient ? idx : '.c3'
    const rating = getKeyByCheckExistence(item, ['label', 'value', 'rating'])
    embedHtml += `
    <td valign="top" align="center" nowrap>
    <div style="width:35px;height:35px;border-radius:50%;background-color:{{color${tagForColor}}};color:#fff;text-align:center;margin: 0 2px">
    <a class="buttonLink" href="*|SRV:${rating}|*" style=";font-size:18px;font-family:Arial,sans-serif;text-decoration:none;line-height:35px;display:inline-block"> \n
    <span style="color:#fff;">${item.label}</span> \n
    </a>
    </div>
    <td style="width: 2px;"></td>`
  })
  embedHtml += '</tr></tbody></table></td>'

  return embedHtml
}

function getKeyByCheckExistence (objToObserve, keysToFind) {
  let response
  keysToFind.forEach(key => {
    if (objToObserve[key] !== undefined) {
      response = objToObserve[key]
    }
  })
  return response
}

function returnEmbedEnum (question) {
  const parsedObjectQuestion = { ...question }
  let embedHtml = returnHeader(parsedObjectQuestion)
  embedHtml += numberButtons(parsedObjectQuestion)
  if (parsedObjectQuestion.type === 'nes') {
    embedHtml += returnStardAndEndLabels(parsedObjectQuestion, 350)
    return embedHtml
  }
  if (parsedObjectQuestion.type === 'enum') {
    const width = parsedObjectQuestion.options.length > 9 ? 450 : parsedObjectQuestion.options.length > 5 ? 350 : 250
    embedHtml += returnStardAndEndLabels(parsedObjectQuestion, width, 'left', 'right')
    return embedHtml
  }
  if (parsedObjectQuestion.type === 'ces') {
    embedHtml += returnStardAndEndLabels(parsedObjectQuestion, 350)
    return embedHtml
  }
  embedHtml += footerHtml()
  return embedHtml
}
