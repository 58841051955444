import { callApiService } from '@/_services'
// import { formatters } from '@/_helpers'

const API_URL = process.env.VUE_APP_URL

export const approachesService = {
  getApproaches,
  getApproachesStats,
  archiveApproach,
  deleteApproach,
  unarchiveApproach
}

async function getApproaches (query, rangeEnd, rangeStart) {
  const formattedApproachesUrl = `${API_URL}sendings/?q=${query}`
  const result = await callApiService.callApi(formattedApproachesUrl, 'get', null, rangeEnd, false, rangeStart)
  return result
}

async function getApproachesStats (query) {
  const formattedApproachesStatsUrl = `${API_URL}sendingStats/?q=${query}`
  const result = await callApiService.callApi(formattedApproachesStatsUrl, 'get', null)
  return result
}

async function archiveApproach (id) {
  const archiveUrl = API_URL + 'sendings/' + id + '/archive'
  const result = await callApiService.callApi(archiveUrl, 'get')
  return result
}
async function unarchiveApproach (id) {
  const archiveUrl = API_URL + 'sendings/' + id + '/unarchive'
  const result = await callApiService.callApi(archiveUrl, 'get')
  return result
}

async function deleteApproach (id) {
  const archiveUrl = API_URL + 'sendings/' + id
  const result = await callApiService.callApi(archiveUrl, 'delete')
  return result
}
