<template>
  <div class="container-change-tab">
    <div class="section-change-tab" :style="{'--length': renderTabs.length}">
      <div class="content-change-tab"
        v-for="tab in renderTabs"
        :key="tab.value"
        :class="[`tab-${tab.value}`, {'tab-active': tab.active}]"
        :style="{'display': tab.render ? 'flex' : 'none'}"
        @click="goTab(tab)">
        <i class="material-symbols-outlined icon-change-tab">{{ tab.icon }}</i>
        <p class="title-change-tab">{{ tab.title }}</p>
      </div>
    </div>
    <div class="content-to-tab" v-if="showContent">
      <div class="content-loading-tab-sidebar" v-show="tabLoading"></div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'ChangeTab',
  props: ['renderTabs', 'isLoading', 'initialTab', 'showContent'],
  components: {},
  data () {
    return {
      tabActive: '',
      tabLoading: true
    }
  },
  mounted () {
    if (!this.renderTabs.length) return

    const tabToActive = this.renderTabs.find(e => e.active)

    if (!tabToActive) return

    this.tabActive = tabToActive.value

    if (this.initialTab) { this.$emit('tab-active', this.tabActive) }

    this.setLoading(false)
  },
  watch: {
    isLoading: {
      handler (newVal, oldVal) {
        if (oldVal !== newVal) {
          this.setLoading(true)
          setTimeout(() => { this.setLoading(false) }, 200)
        }
      }
    }
  },
  methods: {
    goTab (tab) {
      if (tab.active) return

      this.setLoading(true)

      this.renderTabs.forEach(tab => { tab.active = false })

      tab.active = true
      this.tabActive = tab.value
      this.$emit('tab-active', this.tabActive)

      setTimeout(() => { this.setLoading(false) }, 200)
    },
    setLoading (e) {
      if (e) { this.tabLoading = e }

      const opacity = e ? 1 : 0
      const duration = e ? 0 : 0.5
      const divLoading = document.querySelector('.content-loading-tab-sidebar')
      gsap.to(divLoading, {
        opacity,
        duration,
        onComplete: () => {
          if (!e) { this.tabLoading = e }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-change-tab {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5vw;
  .section-change-tab {
    width: 100%;
    height: 4vw;
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    padding: 0.2vw;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
    background: #f6f4f7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.2vw;
    .content-change-tab {
      width: calc(100% / var(--length));
      height: 100%;
      color: #9e9e9e;
      border-radius: 3px;
      padding: 0.3vw;
      cursor: pointer;
      transition: box-shadow 0.3s, color 0.3s, background 0.3s;
      user-select: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.2vw;
      .title-change-tab {
        font-size: 0.8vw;
      }
      .icon-change-tab {
        font-size: 1vw;
      }
    }
    .tab-active {
      background: #ffffff;
      color: var(--accent-color);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      border: 1px solid #e1e1e1;
    }
  }
  .content-to-tab {
    position: relative;
    flex: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    background: #f6f4f7;
    border-radius: 5px;
    padding: 0.3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    .content-loading-tab-sidebar {
      position: absolute;
      z-index: 51;
      opacity: 1;
      border-radius: 15px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #f6f4f7;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
