<template>
  <div class="container-analysis">
    <ShimmerLoading :isHeight="'18.4vh'" :quantity="4" v-if="loading" />
    <div class="content-analysis" v-if="insights.length && !loading">
      <CardInsight v-for="insight in insights" :key="insight._id" :item="insight" :highlightedText="highlight"/>
    </div>
    <NoData v-if="!insights.length && !loading" />
    <ExportSending v-if="openExportInsights" pageToExport="insights" @close-export-sidebar="openExportInsights = false" />
  </div>
</template>

<script>
import ShimmerLoading from '../../customLoading/ShimmerLoading.vue'
import CardInsight from './CardInsight.vue'
import NoData from './NoData.vue'
import ExportSending from '../../SideBarConfig/ExportSending.vue'

export default {
  name: 'AnalysisInsight',
  props: ['dataBase', 'highText', 'loading'],
  components: {
    ExportSending,
    CardInsight,
    ShimmerLoading,
    NoData
  },
  data () {
    return {
      highlight: '',
      openExportInsights: false
    }
  },
  mounted () {
    this.$root.$on('open-export-insights', () => { this.openExportInsights = true })
  },
  watch: {
    'filters.textSearch': {
      immediate: true,
      handler (newVal) {
        this.highlight = newVal
      }
    }
  },
  computed: {
    filters () { return this.$store.getters['filtersInsight/getFilters'] },
    insights () {
      const insights = []
      this.dataBase.forEach(e => {
        e.insights.forEach(i => {
          const objInsight = {
            insight: i,
            respondedAt: e.respondedAt,
            seedData: e.seedData,
            _id: `${e._id}-${i._id}`
          }

          insights.push(objInsight)
        })
      })
      return insights
    }
  }
}
</script>

<style lang="scss">
.container-analysis {
  width: 100%;
  height: 94%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content-analysis {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>
