<template>
  <div class="container-text-modal-list" ref="container-text-modal-list">
    <div class="content-text-modal">
      <div class="content-loading-text-modal-list" ref="loading-text-modal-list" v-if="loadingTextModalList">
        <Circle2 class="spinner-social-text-modal-list"/>
        <p class="title-loading-text-modal-list">{{ $t('dashboard.loading_data') }}</p>
      </div>
      <div class="header-text-modal">
        <div class="header-title-text-modal">
          <p class="title-text-modal-list">{{ $t('dashboard.individual_answers') }}</p>
          <i class="material-icons-outlined icon-close-text-modal-list" @click="displayAnimation(true)">close</i>
        </div>
        <div class="header-title-text-modal">
          <i class="material-icons icon-responses-text-modal" v-title="$t('dashboard.individual_responses')">ads_click</i>
          <p class="label-text-modal-list">{{ formattedNumber }} {{ $t('dashboard.individual_responses') }}</p>
        </div>
        <div class="header-title-text-modal">
          <div class="search-text-modal-list">
            <input type="text" class="input-search-text-modal-list" v-model="searchText" @input="filteredText()" :placeholder="$t('bvs.search')">
          </div>
        </div>
      </div>
      <div class="content-list-text-modal" ref="content-list-text-modal">
        <CardText v-for="(item, idx) in filteredTextList" :key="`${item._id}${idx}`" :textData="item" :notShowTags="notShowTags" :tags="tags" />
      </div>
      <div class="paginate-text-items">
        <app-paginate class="paginate-text-list" :initValue="1" :prevText="'<'" :nextText="'>'" @input="changePage" :pageRange="itemsPerPage" :pageCount="dynamicPageCount" :marginPages="1"></app-paginate>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import { tagsService, dashboardsService } from '@/_services'
import { formatters, constants } from '@/_helpers'
import CardText from '../../inputs/CardText.vue'

export default {
  name: 'TextModalList',
  props: ['question', 'notShowTags'],
  components: {
    CardText,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2'),
    'app-paginate': () => import('@binds-tech/binds-design-system/src/components/Paginate/Paginate')
  },
  data () {
    return {
      tags: [],
      textList: [],
      endRange: '',
      startRange: '',
      searchText: '',
      currentPage: 1,
      itemsPerPage: constants.itemsPerPageOnTextList,
      originalTextList: null,
      totalContentRange: null,
      loadingTextModalList: true
    }
  },
  mounted () {
    this.displayAnimation()
  },
  computed: {
    formattedNumber () { return parseFloat(this.totalContentRange).toLocaleString('pt-BR') },
    filteredTextList () {
      if (!this.originalTextList || !this.originalTextList[this.currentPage]) {
        return []
      }

      if (!this.searchText) {
        return this.originalTextList[this.currentPage]
      }

      return this.originalTextList[this.currentPage].filter(item =>
        item.text.toLowerCase().includes(this.searchText.toLowerCase())
      )
    },
    dynamicPageCount () {
      const itemsTextLength = (this.totalContentRange / this.itemsPerPage)
      if (Number.isInteger(itemsTextLength)) {
        return itemsTextLength
      }
      return Math.trunc(itemsTextLength) + 1
    }
  },
  methods: {
    changePage (page) {
      const alreadyHasItems = this.textList[page]

      this.currentPage = page

      if (!alreadyHasItems || !alreadyHasItems.length) {
        this.loadingTextModalList = true
        this.endRange = this.currentPage * this.itemsPerPage
        this.startRange = this.endRange - this.itemsPerPage
        this.getDataText()
      }

      this.searchText = ''
    },
    displayAnimation (close) {
      const container = this.$refs['container-text-modal-list']
      const handlerOpacity = close ? 0 : 1

      gsap.to(container, {
        duration: 0.5,
        opacity: handlerOpacity,
        onComplete: () => {
          if (close) { return this.$emit('close-modal-text-list') }

          this.getDataText()
        }
      })
    },
    async getDataText () {
      try {
        const query = formatters.formatDateByAddTimezone(this.$route.query.q, 3, 'hours')
        const [textResult, tags] = await Promise.all([
          dashboardsService.getTextDash({ q: query }, this.question._id, this.startRange, this.endRange),
          tagsService.getTags()
        ])

        if (!textResult.data.length) return

        this.textList[this.currentPage] = textResult.data

        if (textResult.range) {
          const totalContent = textResult.range.split('/')[1]
          if (totalContent !== '*') { this.totalContentRange = +(totalContent) }
        }

        this.originalTextList = [...this.textList]

        if (!this.notShowTags) {
          try {
            this.tags = tags
            this.textList[this.currentPage].map(item => { item.tagInput = '' })
          } catch (error) {
            console.error('Error fetching tag data:', error)
          }
        }
      } catch (error) {
        console.error('Error fetching text list data', error)
      } finally {
        this.loadingDone()
      }
    },
    loadingDone () {
      const refLoading = this.$refs['loading-text-modal-list']

      gsap.to(refLoading, {
        opacity: 0,
        duration: 0.3,
        onComplete: () => { this.loadingTextModalList = false }
      })
    }
  }
}
</script>

<style lang="scss">
.container-text-modal-list {
  background: #00000025;
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 40;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-text-modal {
    position: relative;
    background: #ffffff;
    width: 50vw;
    height: 85vh;
    border-radius: 0.5vw;
    padding: 0.5vw;
    border: 1px solid #adadad;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.3vw;
    .header-text-modal {
      width: 100%;
      height: 15%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.4vw;
      .header-title-text-modal {
        position: relative;
        background: #f9f9f9;
        height: 1.7vw;
        border-radius: 35px;
        border: 1px solid #f1f1f1;
        padding-left: 0.5rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.05);
        .icon-close-text-modal-list {
          position: absolute;
          right: 0.3vw;
          background: #9696A0;
          width: 1.3vw;
          height: 1.3vw;
          border-radius: 50%;
          font-size: 1vw;
          color: var(--default-text-color);
          filter: drop-shadow(0px 0 1px #9696A0);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .icon-responses-text-modal {
          font-size: 0.9vw;
          color: var(--accent-color);
          cursor: default;
        }
        .title-text-modal-list {
          flex: 1;
          font-size: 0.95vw;
          line-height: 0.95vw;
          font-weight: 500;
          color: #454548;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .label-text-modal-list {
          color: #454548;
          font-size: 0.75vw;
          font-weight: 400;
        }
        .search-text-modal-list {
          background: #ffffff;
          border-radius: 35px;
          border: 1px solid #e1e1e1;
          width: 50%;
          height: 80%;
          padding-left: 0.2vw;
          display: flex;
          align-items: center;
          gap: 0.3vw;
          .input-search-text-modal-list {
            flex: 1;
            border: none;
            padding-left: 0.3vw;
            font-size: 0.7vw;
            color: #454548;
          }
          .icon-responses-text-modal {
            height: 100%;
            padding: 0 0.3vw;
            border-radius: 3vw;
            color: var(--accent-color);
            transition: background 0.3s, color 0.3s;
            cursor: pointer;
          }
          .icon-responses-text-modal:hover {
            background: var(--accent-color);
            color: var(--default-text-color);
          }
        }
      }
    }
    .content-list-text-modal {
      width: 100%;
      height: 74%;
      border-radius: 0.4vw;
      overflow-y: scroll;
    }
    .paginate-text-items {
      width: 100%;
      height: 8%;
      display: flex;
      align-items: center;
      justify-content: center;
      .paginate-text-list {
        filter: grayscale(1);
        display: flex;
        align-items: center;
        justify-content: center;
        .page-item {
          width: 1.5vw;
          height: 1.5vw;
          padding: 0;
          border: 1px solid #afafaf;
          display: flex;
          align-items: center;
          justify-content: center;
          .page-link-item {
            padding: 0;
            margin: 0 -2px;
            font-size: 0.8vw;
            width: 100%;
            height: 100%;
            box-shadow: none;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
          }
        }
        .next-item, .prev-item {
          .next-link-item, .prev-link-item {
            font-size: 0.8vw;
            padding: 0;
            width: 1.5vw;
            height: 1.5vw;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .prev-link-item {
            border-right: none;
          }
        }
        .active {
          border-radius: 7px;
          margin-top: 3px;
          margin: 0 -1px;
          width: 2vw;
          height: 2.2vw;
        }
      }
    }
    .content-loading-text-modal-list {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      background: #ffffff;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      .spinner-social-text-modal-list {
        width: 2vw !important;
        height: 2vw !important;
        border-width: 3px !important;
        border-color: var(--accent-color) #cacaca #cacaca !important;
      }
      .title-loading-text-modal-list {
        font-size: 0.65vw;
        color: #cacaca;
        font-weight: 500;
      }
    }
  }
}
</style>
