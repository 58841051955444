<template>
  <div class="container-graphics-social-media">
    <div class="small-graphics">
      <OvertimeLine :key="'overtimeLine' + key"></OvertimeLine>
      <div class="word-and-average">
        <WordCloud :key="'wordCloud' + key"></WordCloud>
        <AverageByBrand :key="'averageBrand' + key"></AverageByBrand>
      </div>
    </div>
    <Mentions :key="'mentions' + key"></Mentions>
  </div>
</template>

<script>
import Mentions from '../charts/Mentions.vue'
import AverageByBrand from '../charts/AverageByBrand.vue'
import WordCloud from '../charts/WordCloud.vue'
import OvertimeLine from '../charts/OvertimeLine.vue'

export default {
  name: 'socialGraphics',
  props: [],
  components: {
    Mentions,
    AverageByBrand,
    WordCloud,
    OvertimeLine
  },
  data () {
    return {
      key: 0
    }
  },
  created () {
    this.$root.$on('update-graphics', async () => {
      this.key++
    })
  },
  mounted () {
  },
  computed: {
  },
  methods: {}
}
</script>

<style lang="scss">
.container-graphics-social-media {
  display: flex;
  .small-graphics {
    width: 75%;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .word-and-average {
      width: 100%;
      height: 60%;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
