<template>
  <div>
    <notifications :question="question" v-if="openNotificationsModal" @close-notifications-modal="openNotificationsModal = false"></notifications>
    <notifications-for-text :question="question" v-if="openNotificationsForText" @close-notifications-modal="openNotificationsForText = false"></notifications-for-text>
    <div class="content-question-title" :style="{'background-color': survey.colors.headerBg}">
      <div>
        <app-heading class="ballon-place">
          <i class="material-icons icon-alert-health" @click="selectModalToOpen('showModalAlertHealth')" v-title="$t('notifications.notify_settings_health')">favorite</i>
        </app-heading>
        <app-heading class="question-order" v-if="question.type !== 'matrix' && question.ui !== 'multipleOther' && question.ui !== 'singleOther'">
          <i
            v-title="$t('notifications.notify_settings')"
            v-if="question.type !== 'text' && question.type !== 'referral'"
            class="material-icons bell"
            :class="{'purple-bell': question.notificate.length > 0}"
            @click="openNotificationsModal = true"
          >notifications</i>
          <i
            v-if="question.type === 'text' || question.type === 'referral'"
            class="material-icons bell"
            :class="{'purple-bell': question.notificate.length > 0}"
            @click="openNotificationsForText = true"
          >notifications</i>
        </app-heading>
        <app-heading class="ballon-place" v-if="question.type !== 'matrix' && question.type !== 'referral' && question.ui !== 'singleOther' && question.ui !== 'multipleOther'">
          <i
            v-title="$t('tickets.open_onsurvey')"
            v-if="account.features.tickets"
            class="material-icons ballon"
            :class="{'purple-bell': survey.ticket.triggers.length > 0}"
            @click="selectModalToOpen('openTicketsModal')"
          >chat_bubble</i>
        </app-heading>
      </div>
      <div v-click-outside="closeOptionsDisplay">
      <div class="question-title mt20">
        <edit-title
          @updateSurveyTitle="updateQuestionTitle"
          @openOptions="optionsDisplayTitle = true"
          :editStyle="true"
          :center="true"
          :optionsDisplay="optionsDisplayTitle"
          :identify="`title-${question._id}`"
          :minHeight="50"
          :maxHeight="40000"
          :title="question.label"
          :color="survey.colors.headerColor || '#000000'"
          :metadata.sync="metadataGroup"
          type="textarea"
        />
      </div>
      <div class="question-description">
        <edit-title
          @updateSurveyTitle="updateQuestionDescription"
          @openOptions="optionsDisplayDescription = true"
          :editStyle="true"
          :optionsDisplay="optionsDisplayDescription"
          :center="true"
          :identify="`description-${question._id}`"
          :minHeight="50"
          :maxHeight="40000"
          :title="question.question"
          :color="survey.colors.headerColor || '#000000'"
          :metadata.sync="metadataGroup"
          type="textarea"
        />
      </div>
      </div>
    </div>
    <div class="content-type-answer" :style="{'background-color': survey.colors.splashBg}">
      <div class="content-button-selector">
        <button
          v-on:click="openSelector()"
          @mouseover="activeBorderSelector = true"
          @mouseleave="activeBorderSelector = false"
          class="btn-circle-accent button-edit-survey"
          v-if="survey.showSelector === false"
        ><i class="material-icons">edit</i>
        </button>
        <span class="edit-label">{{ $t('survey.question_error') }}</span>
      </div>
      <div class="transparentBorderSelector" :class="{'border-selector' : activeBorderSelector}">
        <Selector v-if="survey.showSelector"/>
        <div class="content-answer-component"
          v-if="!survey.showSelector"
          :class="{'mt5emo': this.helperAnswer === 'app5emo', 'mtEnum': this.helperAnswer === 'appsingle' || this.helperAnswer === 'apptext'}"
          @mouseover="activeBorderSelector = false"
        >
          <helperAnswer :key="wasUpdated" v-bind:is="this.helperAnswer" :colors="survey.colors" :question="question"></helperAnswer>
        </div>
      </div>
    </div>
    <actions-prev-next v-if="survey.images.length >= 1"></actions-prev-next>
    <theme-style>
      .content-edit-title > div > label > .text-field__input input{
        border: 1.3px dashed {{ survey.colors.headerBg || 'transparent' }};
      }
      .text-field__input input:hover,
      .text-field__input input:focus{
        border-color: {{ user.account.theme.colors.secondary || '#64469d' }} !important;
      }
    </theme-style>
    <app-modal
      class="modal-default-primary confirm-open-modal"
      v-model="openModalAndSaveSurvey"
      :title="$t('survey.save_and_open_ticket')"
      lightbox
      close
      :primary-button="$t('resetpass.save')"
      @primaryButtonClick="saveSurveyAndOpenModal()"
      :secondary-button="$t('tickets.cancel')"
      @secondaryButtonClick="openModalAndSaveSurvey = false; whatModal = null"
    >
      <div class="content-loading-modal-save-survey" v-if="loadingSaveSurveyToModal">
        <Circle2 class="spinner-social"/>
      </div>
    </app-modal>
    <app-modal
      v-model="showModalAutoMessageToDelete"
      :title="$t('survey.auto_messages_title')"
      lightbox
      class="modal-default-primary modal-conditions"
      :primary-button="$t('survey.cancel')"
      @secondaryButtonClick="updateQuestion()"
      :secondary-button="$t('survey.continue')"
      @primaryButtonClick="showModalAutoMessageToDelete = false"
      v-on:keyup.esc="showModalAutoMessageToDelete = false"
    >
      <app-heading level="h6">{{ $t('survey.auto_messages_msg') }}</app-heading>
    </app-modal>
    <TicketsModal v-if="openTicketsModal" :survey="survey" @close-modal-ticket="openTicketsModal = false"/>
    <ModalAlertHealth v-if="showModalAlertHealth" :survey="survey" @close-modal-alert-health="showModalAlertHealth = false" />
  </div>
</template>

<script>
import Selector from './Selector.vue'
import EditTitle from '../EditTitle.vue'
import notifications from './NotificationsModal.vue'
import notificationsForText from './NotificationsForTextAndReferral.vue'
import TicketsModal from '../../components/survey/TicketsModal.vue'
import ModalAlertHealth from './ModalAlertHealth.vue'
import { userService, surveyService } from '@/_services'
import { mapGetters } from 'vuex'

/* export answers components */
import appblank from './answersType/blank.vue'
import app10num from './answersType/10num.vue'
import app5num from './answersType/5num.vue'
import app5nes from './answersType/5nes.vue'
import appyn from './answersType/kpi-yn.vue'
import appynd from './answersType/kpi-ynd.vue'
import appynm from './answersType/kpi-ynm.vue'
import app5emo from './answersType/5emo.vue'
import apptext from './answersType/text.vue'
import appreferral from './answersType/referral.vue'
import appreferraldynamic from './answersType/referraldynamic.vue'
import appsingle from './answersType/single.vue'
import appmultiple from './answersType/multiple.vue'
import app0to5 from './answersType/0to5.vue'
import app1to5 from './answersType/1to5.vue'
import app1to7 from './answersType/1to7.vue'
import app1to7button from './answersType/1to7button.vue'
import app0to10 from './answersType/0to10.vue'
import app1to10 from './answersType/1to10.vue'
import appmatrix1to5 from './answersType/matrix1to5.vue'
import appmatrix10num from './answersType/matrix10num.vue'
import applike from './answersType/matrixlike.vue'
import app5radio from './answersType/nvs.vue'
import ActionsPrevNext from './ActionsPrevNext.vue'
import Vue from 'vue'
import('lodash-es').then((lodashEs) => {
  window._ = lodashEs.default
})
// diretiva para fechar as opçoes para editar o titulo
Vue.directive('click-outside', {
  bind (el, { value }) {
    if (typeof value !== 'function') { return }
    document.addEventListener('click', e => el.contains(e.target) || value())
  }
})
export default {
  name: 'SurveyCreate',
  components: {
    ModalAlertHealth,
    Selector,
    EditTitle,
    appblank,
    app10num,
    app5num,
    app5nes,
    appyn,
    appynd,
    appynm,
    app5emo,
    apptext,
    appreferral,
    appreferraldynamic,
    appsingle,
    appmultiple,
    app0to5,
    app1to5,
    app1to7,
    app1to7button,
    app0to10,
    app1to10,
    appmatrix1to5,
    appmatrix10num,
    app5radio,
    applike,
    ActionsPrevNext,
    notifications,
    notificationsForText,
    TicketsModal,
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      whatModal: null,
      loadingSaveSurveyToModal: false,
      activeBorderSelector: false,
      metadataGroup: [
        { key: '_name', label: this.$i18n.t('sendhub.name'), type: String },
        { key: '_email', label: this.$i18n.t('sendhub.email'), type: String },
        { key: '_phone', label: this.$i18n.t('sendhub.phone'), type: String }
      ],
      wasUpdated: 0,
      openNotificationsModal: false,
      openNotificationsForText: false,
      openTicketsModal: false,
      optionsDisplayTitle: false,
      optionsDisplayDescription: false,
      openModalAndSaveSurvey: false,
      showModalAutoMessageToDelete: false,
      showModalAlertHealth: false
    }
  },
  async mounted () {
    const user = await userService.getMe()
    if (user.account.fields.length > 0) {
      const data = user.account.fields
      data.map(item => this.metadataGroup.push(item))
    }

    this.$root.$on('update-merged-fields', (fields) => {
      fields.map(item => {
        if (this.survey.mergedFields.length > 0) {
          if (!this.survey.mergedFields.includes(item)) {
            this.survey.mergedFields.push(item)
          }
        } else {
          this.survey.mergedFields.push(item)
        }
      })
    })
  },
  watch: {
    question: function () {
      this.wasUpdated += 1
    }
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser',
      account: 'account/getAccount'
    }),
    isEditingAllowed () {
      const { alreadySent } = this.survey
      const { allowEditSurveyWithResponses } = this.account.features
      return !(alreadySent && !allowEditSurveyWithResponses)
    },
    question () { return this.$store.getters['survey/getQuestionSelected'] },
    survey () { return this.$store.getters['survey/getSurvey'] },
    account () { return this.$store.getters['account/getAccount'] },
    helperAnswer () {
      let appUi = 'app' + this.question.ui.toLowerCase()
      if (this.question.ui === '10num' && ['nps', 'csat'].indexOf(this.question.type) < 0) {
        if (this.question.type === 'nes') {
          appUi = 'app5nes'
        }
        if (this.question.options.length === 6) {
          appUi = 'app0to5'
        }
        if (this.question.options.length === 5) {
          appUi = 'app1to5'
        }
        if (this.question.options.length === 11) {
          appUi = 'app0to10'
        }
        if (this.question.options.length === 10) {
          appUi = 'app1to10'
        }
      }
      if (this.question.type === 'matrix' && this.question.ui === '10num') {
        appUi = 'appmatrix10num'
      }

      if (this.question.ui === 'multipleOther') {
        appUi = 'appmultiple'
      }

      if (this.question.ui === 'singleOther') {
        appUi = 'appsingle'
      }
      return appUi
    }
  },
  methods: {
    selectModalToOpen (name) {
      this.whatModal = name
      this.openModalAndSaveSurvey = true
    },
    async saveSurveyAndOpenModal () {
      this.loadingSaveSurveyToModal = true
      const oldSurvey = structuredClone(this.survey)
      const colorArray = []

      if (!Array.isArray(oldSurvey.colors)) {
        Object.keys(oldSurvey.colors).forEach(item => {
          if (item && oldSurvey.colors[item]) {
            colorArray.push({ alias: item, value: oldSurvey.colors[item] })
          }
        })
        oldSurvey.colors = colorArray
      }
      oldSurvey.message = oldSurvey.message ? oldSurvey.message._id : undefined

      const result = await surveyService.updateSurvey(oldSurvey, true)

      if (result && !result.errors) {
        this.formatColorsFromReq(result)
        this.openModalAndSaveSurvey = false

        this[this.whatModal] = true

        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('survey.draft_saved'),
          position: 'bottomRight',
          showAlert: true
        })
      } else {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('survey.draft_not_saved'),
          position: 'bottomRight',
          showAlert: true
        })
      }
      this.loadingSaveSurveyToModal = false
      this.whatModal = null
    },
    formatColorsFromReq (survey) {
      const getColors = survey.colors.reduce((acc, cur) => ({ ...acc, [cur.alias]: cur.value }), {})
      survey.colors = getColors
      this.$store.commit('survey/survey', survey)
    },
    closeOptionsDisplay () {
      this.optionsDisplayTitle = false
      this.optionsDisplayDescription = false
    },
    updateQuestionTitle (e) {
      this.question.label = e
      if (!this.question.customTitle) {
        this.question.title = e
      }
      this.$store.commit('survey/upsertQuestion', this.question)
    },
    updateQuestionDescription (e) {
      this.question.question = e
      this.$store.commit('survey/upsertQuestion', this.question)
    },
    openSelector () {
      const hasTrigger = this.survey.ticket.triggers.length
      const hasTriggerInQuestion = this.survey.ticket.triggers.find(trigger => trigger.questionId === this.question._id)

      if (hasTrigger && hasTriggerInQuestion) {
        this.showModalAutoMessageToDelete = true
        return
      }

      this.updateQuestion()
    },
    updateQuestion () {
      if (!this.isEditingAllowed) {
        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('survey.survey_already_sent'),
          position: 'bottomRight',
          showAlert: true
        })
      }

      this.showModalAutoMessageToDelete = false
      this.survey.showSelector = true
      this.survey.selectedType = this.question.type
      this.$store.commit('survey/survey', this.survey)
    }
  }
}
</script>

<style lang="scss">
.confirm-open-modal {
  .modal__wrapper {
    .content-loading-modal-save-survey {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ffffff;
      border-radius: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .spinner-social {
        width: 2vw !important;
        height: 2vw !important;
        border-width: 3px !important;
        border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
      }
    }
  }
}
.purple-bell {
  color: #9c8cca !important;
}
.bell {
  font-size: 18px;
  margin-right: 10px !important;
  margin-top: 0px;
  cursor: pointer;
  color: gray;
}
.ballon {
  font-size: 18px !important;
  color: gray;
}
.ballon-place {
  cursor: pointer;
  margin-top: -49px !important;
  float: right !important;
  margin-right: 7px !important;
  .icon-alert-health {
    color: gray;
    font-size: 22px;
  }
}
.content-question-title {
  width: 100%;
  border-radius: 10px 10px 0 0;
  padding: 15px 0;
  .text-field--secondary .text-field__input {
    background-color: transparent !important;
  }
}
.question-title {
  .content-edit-title {
    width: 90%;
    margin: 0 auto;
    .heading {
      text-align: left;
      font-weight: 700;
      margin: 0 !important;
    }
    .text-field--secondary {
      .text-field__input {
        input {
          text-align: left;
          padding: 5px !important;
          font-size: 28px !important;
          font-weight: 700 !important;
        }
      }
    }
  }
}
.question-description {
  width: 90%;
  margin: 0 auto;
  & .input-wrapper {
    & .input {
      font-size: 16px !important;
      padding: 6px !important;
    }
  }
  .content-edit-title {
    .heading {
      padding: 5px !important;
      font-size: 16px !important;
      margin: 0 !important;
    }
    .text-field--secondary .text-field__input{
      input{
        padding: 5px !important;
        font-size: 16px !important;
        font-weight: 600;
      }
    }
  }

}
.content-type-answer {
  width: 100%;
  min-height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  & button.binds-button.medium.primary.floating {
    margin-top: 0px;
    margin-right: 25px;
    i {
      font-size: 28px;
      margin-top: 5px;
    }
  }
  .content-answer-component {
    min-height: 415px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.survey-description {
  width: 100%;
  & .input-wrapper {
    .input {
      height: auto;
    }
  }
  & textarea {
    font-weight: 600 !important;
    padding: 2px !important;
    font-size: 36px !important;
    background-color: none !important;
  }
}

.mt5emo {
  margin-top: 50px !important;
}
.mtEnum {
  margin-top: 20px !important;
  width: 100%;
}
.border-selector {
  border: 1.3px dashed $color-purple-darker !important;
  border-radius: 6px;
  width: fit-content;
}
.transparentBorderSelector {
  border: 1.3px dashed #f000;
  width: 100%;
}
.content-button-selector {
  .edit-label {
    right: -57px;
    display: none;
    animation: none;
    top: 90px;
  }
  .button-edit-survey {
    width: 55px;
    height: 55px;
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 30px;
    border-radius: 50%;
    i {
      font-size: 30px;
      color: #fff !important;
    }
  }
  &:hover {
    .edit-label {
      display: block;
    }
  }
}

</style>
