
<template>
  <div class="all-colors">
    <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
      <div class="col-lg-8 col-md-8 col-sm-8 color-group">
        <app-heading level="h6">1. {{ $t('theme.background') }}</app-heading>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="color-box">
          <input class="survey-palette" type="color" v-model="colors.bodyColor" @input="saveColors()"/>
          <i class="material-icons-outlined">arrow_drop_down</i>
        </div>
      </div>
    </div>
    <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
      <div class="col-lg-8 col-md-8 col-sm-8 color-group">
        <app-heading level="h6">2. {{ $t('theme.header') }}</app-heading>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="color-box">
          <input class="survey-palette" type="color" v-model="colors.headerBg" @input="saveColors()"/>
          <i class="material-icons-outlined">arrow_drop_down</i>
        </div>
      </div>
    </div>
    <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
      <div class="col-lg-8 col-md-8 col-sm-8 color-group">
        <app-heading level="h6">3. {{ $t('theme.header_text') }}</app-heading>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="color-box">
          <input class="survey-palette" type="color" v-model="colors.headerColor" @input="saveColors()"/>
          <i class="material-icons-outlined">arrow_drop_down</i>
        </div>
      </div>
    </div>
    <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
      <div class="col-lg-8 col-md-8 col-sm-8 color-group">
        <app-heading level="h6">4. {{ $t('theme.survey_background') }}</app-heading>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="color-box">
          <input class="survey-palette" type="color" v-model="colors.splashBg" @input="saveColors()"/>
          <i class="material-icons-outlined">arrow_drop_down</i>
        </div>
      </div>
    </div>
    <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
      <div class="col-lg-8 col-md-8 col-sm-8 color-group">
        <app-heading level="h6">5. {{ $t('theme.survey_text') }}</app-heading>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="color-box">
          <input class="survey-palette" type="color" v-model="colors.splashColor" @input="saveColors()"/>
          <i class="material-icons-outlined">arrow_drop_down</i>
        </div>
      </div>
    </div>
    <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
      <div class="col-lg-8 col-md-8 col-sm-8 color-group">
        <app-heading level="h6">6. {{ $t('theme.buttons') }}</app-heading>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="color-box">
          <input class="survey-palette" type="color" v-model="colors.actionColor" @input="saveColors()"/>
          <i class="material-icons-outlined">arrow_drop_down</i>
        </div>
      </div>
    </div>
    <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
      <div class="col-lg-8 col-md-8 col-sm-8 color-group">
        <app-heading level="h6">7. {{ $t('theme.buttons_text') }}</app-heading>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="color-box">
          <input class="survey-palette" type="color" v-model="colors.actionText" @input="saveColors()"/>
          <i class="material-icons-outlined">arrow_drop_down</i>
        </div>
      </div>
    </div>
    <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
      <div class="col-lg-8 col-md-8 col-sm-8 color-group">
        <app-heading level="h6">8. {{ $t('theme.footer') }}</app-heading>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="color-box">
          <input class="survey-palette" type="color" v-model="colors.footerBg" @input="saveColors()"/>
          <i class="material-icons-outlined">arrow_drop_down</i>
        </div>
      </div>
    </div>
    <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
      <div class="col-lg-8 col-md-8 col-sm-8 color-group">
        <app-heading level="h6">9. {{ $t('theme.footer_buttons') }}</app-heading>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="color-box">
          <input class="survey-palette" type="color" v-model="colors.buttonBg" @input="saveColors()"/>
          <i class="material-icons-outlined">arrow_drop_down</i>
        </div>
      </div>
    </div>
    <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
      <div class="col-lg-8 col-md-8 col-sm-8 color-group">
        <app-heading level="h6">10. {{ $t('theme.footer_text') }}</app-heading>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="color-box">
          <input class="survey-palette" type="color" v-model="colors.buttonColor" @input="saveColors()"/>
          <i class="material-icons-outlined">arrow_drop_down</i>
        </div>
      </div>
    </div>
    <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
      <div class="col-lg-8 col-md-8 col-sm-8 color-group">
        <app-heading level="h6">11. {{ $t('theme.hover_buttons') }}</app-heading>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <div class="color-box">
          <input class="survey-palette" type="color" v-model="colors.buttonHover" @input="saveColors()"/>
          <i class="material-icons-outlined">arrow_drop_down</i>
        </div>
      </div>
    </div>
    <div class="row center-lg center-md start-sm clear-theme-btn hvr-grow">
      <app-button variation="primary" outline v-on:click="clearTheme()">{{ $t('theme.clear_theme') }}</app-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SurveyColors',
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  computed: {
    colors () {
      const req = this.$store.getters['survey/getSurvey']
      return req.colors
    }
  },
  methods: {
    clearTheme () {
      this.$store.commit('survey/clearTheme')
    },
    async saveColors () {
      this.$store.commit('survey/saveTheme', this.colors)
    }
  }
}
</script>
<style lang="scss">
.all-colors {
  h6 {
    margin-top: 5px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}
.color-group {
  padding-left: 5px !important;
}
.clear-theme-btn {
  margin: 20px 0px !important;
  & .binds-button.medium {
    font-size: 12px !important;
    font-weight: 500;
  }
}
.align-colors {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.color-box {
  cursor: pointer !important;
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 64px;
  padding-left: 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.survey-palette {
  height: 34px;
  width: 40px;
  display: flex;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer !important;
  input {
    border-radius: 10px !important;
  }
}
</style>
