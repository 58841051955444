<template>
  <div class="questions-grid-view" style="padding-top: 0px">
    <div
      :key="cardItem.key"
      class="question-card-wrapp"
      v-for="cardItem in filterQuestionsToRemoveNonPermittedUis"
      :style="{display: cardItem.total > 0 ? 'initial' : 'none'}"
      :class="{
        'question-text-card': cardItem.question.type === 'text',
        'question-like-card': cardItem.question.type === 'matrix' }"
    >
      <app-card id="card" v-if="cardItem.question.type !== 'referral' && cardItem.total > 0">
        <div class="total-answered">
          <app-heading v-if="cardItem.question.type !== 'text'" level=h8 class="answered">{{ cardItem.total.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }} {{ $t('dashboard.individual_responses') }}</app-heading>
        </div>
        <div class="cube-transition">
          <app-heading class="cube-flippety" level="h5">{{ cardItem.question.title || cardItem.question.question }}</app-heading>
          <app-heading level="h6" style="padding-bottom:5px;margin-top: 15px !important;width:230px;height: 50px;overflow: hidden;text-overflow: ellipsis;" class="cube-flop">{{ cardItem.question.question }}</app-heading>
        </div>
        <question-card
          :realTimeQuestions="realTimeQuestions"
          :realTimeTexts="realTimeTexts"
          :cardData="cardItem"
          :matrixData="matrixData"
          :defaultCardsQuestions="defaultCardsQuestions"
        ></question-card>
      </app-card>
    </div>
  </div>
</template>
<script>
import QuestionCard from './QuestionCard'
export default {
  name: 'Graphics',
  props: ['loading', 'realTimeQuestions', 'realTimeTexts', 'defaultCardsQuestions', 'matrixData'],
  components: {
    QuestionCard,
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card')
  },
  computed: {
    filterQuestionsToRemoveNonPermittedUis () {
      const getTotalForQuestion = (item) => {
        let total = null
        if (this.realTimeQuestions.length > 0) {
          const questionSet = this.realTimeQuestions[0]?.questions || this.realTimeQuestions
          const idx = questionSet.findIndex(q => q?.question?._id === item.question._id || q?.question === item.question._id)

          if (idx >= 0) {
            total = questionSet[idx].total || questionSet[idx].overall?.qty || null
          }
        }

        if (item.type === 'text') {
          this.realTimeTexts.forEach(response => {
            if (response.length > 0 && response[0]?.question?._id === item.question._id) {
              total = response.length // force total for text questions
            }
          })
        }
        return total
      }

      return this.defaultCardsQuestions
        .filter(item => !['referral', 'anonymous'].includes(item.type))
        .map(item => ({ ...item, total: getTotalForQuestion(item) }))
    }
  },
  methods: {
    returnTotal (currentCard) {
      if (this.realTimeQuestions.length > 0 && this.realTimeQuestions[0].questions) {
        let countOfTexts = 0
        this.realTimeTexts.forEach(response => {
          if (countOfTexts > 0) {
            return false
          }
          if (response.length > 0 && response[0].question && response[0].question._id === currentCard.question._id) {
            countOfTexts = response.length
          }
        })
        return countOfTexts
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.questions-grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  grid-gap: 0px;
  grid-auto-rows: minmax(20px, auto);
  grid-auto-flow: dense;
  padding: 0px;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 30px;
  margin-left: -5px;
}
.questions-grid-view > .question-card-wrapp {
  height: auto;
  & div {
    .title-question-card {
      margin-bottom: 20px;
      & h6 {
        width: 214px;
      }
    }
  }
  .total-answered {
    width: 50%;
    height: 5%;
    box-sizing: border-box;
    margin-top: 5px;
    margin-left: 5.5%;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    .answered {
      color: #3f3356;
    }
  }
}
</style>
