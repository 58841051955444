<template>
  <div class="container-user">
    <div style="height: 100vh; width: 100vw; position: fixed; background: transparent;" @click="closeSidebarUser()"></div>
    <div class="sidebar-user">
      <div class="user-side-header">
        <app-heading level="h5">{{ user.name ? user.name : $t('pageUsers.new_user') }}</app-heading>
        <button v-on:click="closeSidebarUser()">
          <app-textstyle  size="size-xl">×</app-textstyle>
        </button>
      </div>
      <div class="user-config">
        <Expander :title="user._id ? $t('pageUsers.account_info') : $t('pageUsers.new_account_info')" :showOnStartup="true">
          <div class="user-data mt10">
            <app-heading level="h7">{{$t('account_config.name')}} *</app-heading>
            <app-form-input
              v-model="user.name"
              :placeholder="$t('account_config.name')"
            ></app-form-input>
          </div>
          <div class="user-data">
            <app-heading level="h7">{{$t('account_config.email')}} *</app-heading>
            <app-form-input
              v-model="user.email"
              :placeholder="$t('account_config.email')"
            ></app-form-input>
          </div>
          <div class="user-data">
            <app-heading level="h7" style="margin-bottom: 10px !important">{{$t('account_config.phone')}}</app-heading>
            <vue-phone-number-input
              ref="phoneOutput"
              v-model="user.tel"
              :preferred-countries="countries"
              :show-code-on-list="true"
              :fetch-country="true"
              :translations="{
                countrySelectorLabel: $t('account_config.phone_input.country_code'),
                countrySelectorError: $t('account_config.phone_input.choose_country'),
                phoneNumberLabel: $t('account_config.phone_input.phone_number'),
                example: $t('account_config.phone_input.example')
              }"
            ></vue-phone-number-input>
          </div>
          <div class="user-roles">
            <app-heading level="h7">{{$t('account_config.language')}}:</app-heading>
              <app-form-radio-group
                class="roles-options"
                :items="listLanguage"
                :initialValue="user.language"
                @valueGroup="ValueChanged($event, user)"
              />
          </div>
        </Expander>
        <hr style="width: 100%; left: 0; color: rgba(0, 0, 0, 0.4);">
        <Expander class="row" :title="$t('pageUsers.profile_config')+':'" :show-on-startup="false">
          <div class="user-roles">
            <app-heading level="h7">{{$t('account_config.type_user')}}:</app-heading>
              <app-form-radio-group
              class="roles-options"
                :items="roles"
                :initialValue="user.type"
                @valueGroup="ValueChanged($event, user)"
              />
          </div>
          <div class="user-features" v-if="account.type === 'admin' || account.type === 'superadmin'">
            <div class="content-features">
            <app-heading level="h6" class="functionnality-title">{{ $t('pageUsers.user_features') }}:</app-heading>
              <div class="list-features" v-for="(feature, idx) in features" :key="idx">
                <div class="user-feature" v-if="feature.name !== 'questionsMetadata'">
                  <input
                    type="checkbox"
                    v-model="feature.active"
                    class="func-checkbox"
                    @click="handleToggleFeature(feature)"
                    >
                  <app-heading level="h7" style="margin-left: 10px; display: flex; align-items: center;">
                    {{feature.label}}
                    <i
                      v-title="feature.name === 'hiddenSendingMetadata' ? $t('pageUsers.tooltip_metadata') : $t('pageUsers.tooltip_contact')"
                      title-max-width="350"
                      style="font-size: 15px; margin-left: 5px;"
                      class="material-icons"
                      v-if="['hiddenSendingMetadata', 'hiddenSendingContacts']
                        .indexOf(feature.name) !== -1"
                    >info</i>
                  </app-heading>
                </div>
              </div>
            </div>
          </div>
          <app-heading level="h6" class="functionnality-title">{{ $t('pageUsers.group_config') }}:</app-heading>
          <CardWithPagination
            v-if="groups.length > 0 && showCard"
            style="margin-top: 30px"
            :contents="{
            type: 'groups',
            title: 'menu.users_group',
            subtitle: 'pageUsers.message_group',
            itemList: groups,
            objectsId: groupsId,
            user: user }"
          />
        </Expander>
        <hr v-if="!isEmptyUser" style="width: 100%; left: 0; color: rgba(0, 0, 0, 0.4);">
        <Expander :title="$t('account_config.change_password')+':'" :showOnStartup="false" v-if="!isEmptyUser">
          <app-heading level="h7" style="padding: 1rem 0; line-height: 1.4;">
            {{ $t('pageUsers.tip_password') }}
          </app-heading>
          <div class="row center-lg center-md start-sm clear-theme-btn hvr-grow">
            <app-button variation="primary" outline v-on:click="changePassword()">{{ $t('pageUsers.request_password') }}</app-button>
          </div>
        </Expander>
        <hr v-if="!isEmptyUser" style="width: 100%; left: 0; color: rgba(0, 0, 0, 0.4);">
        <div class="footer-sidebar-user">
          <app-button v-on:click="setUser()">{{$t('sendhub.save')}}</app-button>
        </div>
      </div>
      <theme-style>
        .footer-sidebar-user > button {
          background: {{account.account.theme.colors.accent || '#673ab7'}} !important;
          color:{{ (account.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
        }
        .pagination > li > a {
          border: 1px solid {{'#cacaca'}} !important;
        }
        .user-side-header {
          background: {{account.account.theme.colors.primary || '#673ab7'}} !important;
          color:{{ (account.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
        }
        input.func-checkbox {
          accent-color: {{ account.account.theme.colors.accent || '#673ab7'}};
        }
      </theme-style>
    </div>
    <app-modal
      v-model="showCloseModal"
      :title="$t('survey.exit_whithout_save')"
      lightbox
      close
      class="modal-default-primary"
      :primary-button="this.$t('account_config.delete_confirm.yes')"
      @primaryButtonClick="canBeClosed = true; closeSidebarUser()"
      :secondary-button="this.$t('account_config.delete_confirm.no')"
      @secondaryButtonClick="canBeClosed = false; showCloseModal = false"
    >
      <app-heading level="h7">{{ $t('survey.exit_message') }}</app-heading>
    </app-modal>
 </div>
</template>

<script>
import { accountService } from '@/_services'
import CardWithPagination from '../SideBarConfig/CardWithPagination.vue'
import Expander from './Expander.vue'

export default {
  name: 'SideBarUser',
  props: ['user', 'roles', 'groups'],
  components: {
    CardWithPagination,
    'vue-phone-number-input': () => import('vue-phone-number-input'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-form-radio-group': () => import('@binds-tech/binds-design-system/src/components/Form/RadioGroup'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    Expander
  },
  data () {
    return {
      groupsId: [],
      showCard: false,
      isEmptyUser: false,
      languageList: '',
      features: [
        { name: 'sendingsSurveyLink', label: this.$i18n.t('userFeatures.sendingsSurveyLink'), active: false },
        { name: 'metadataEditing', label: this.$i18n.t('userFeatures.metadataEditing'), active: false },
        { name: 'seedBatches', label: this.$i18n.t('userFeatures.seedBatches'), active: false },
        { name: 'sendings', label: this.$i18n.t('userFeatures.sendings'), active: false },
        { name: 'surveyLink', label: this.$i18n.t('userFeatures.surveyLink'), active: false },
        { name: 'tickets', label: this.$i18n.t('userFeatures.tickets'), active: false },
        { name: 'bindsSupport', label: this.$i18n.t('userFeatures.bindsSupport'), active: false },
        { name: 'loginWithAzure', label: this.$i18n.t('userFeatures.loginWithAzure'), active: false },
        { name: 'surveyEdit', label: this.$i18n.t('userFeatures.surveyEdit'), active: false },
        { name: 'trackTerms', label: this.$i18n.t('userFeatures.trackTerms'), active: false },
        { name: 'hiddenSendingContacts', label: this.$i18n.t('userFeatures.hiddenSendingContacts'), active: false },
        { name: 'hiddenSendingMetadata', label: this.$i18n.t('userFeatures.hiddenSendingMetadata'), active: false },
        { name: 'hideExports', label: this.$i18n.t('userFeatures.disableExports'), active: false },
        { name: 'ranking', label: this.$i18n.t('pageTitles.ranking'), active: false },
        { name: 'insights', label: 'insights', active: false }
      ],
      canBeClosed: false,
      showCloseModal: false,
      countries: ['BR', 'ES', 'US']
    }
  },
  created () {
    const onEscape = (e) => {
      if (e.keyCode === 27) {
        this.showCloseModal = true
      }
    }

    document.addEventListener('keydown', onEscape)

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape)
    })
  },
  async mounted () {
    // give active model true or false depending
    if (this.user.features) {
      const userFeatures = this.user.features
      this.features.forEach((i) => { userFeatures[i.name] ? i.active = true : i.active = false })
    }
    if (!this.user._id) {
      this.isEmptyUser = true
    } else {
      this.isEmptyUser = false
    }
    this.groupsId = this.user.roles
    this.showCard = true
  },
  computed: {
    listLanguage () {
      const roles = [
        { type: 'pt-BR', label: this.$t('pageUsers.portuguese'), value: false },
        { type: 'en-US', label: this.$t('pageUsers.english'), value: false },
        { type: 'es-CL', label: this.$t('pageUsers.spanish'), value: false }
      ]
      return roles
    },
    theme () { return this.$store.getters['account/getUser'].account.theme },
    account () { return this.$store.getters['account/getUser'] }
  },
  methods: {
    handleToggleFeature (feature) {
      const contact = this.features.find(val => val.name === 'hiddenSendingContacts')
      const metadata = this.features.find(val => val.name === 'hiddenSendingMetadata')
      const tickets = this.features.find(val => val.name === 'tickets')
      const insights = this.features.find(val => val.name === 'insights')
      const metadataEditing = this.features.find(val => val.name === 'metadataEditing')

      if (feature.name === 'hiddenSendingContacts' && !feature.active && tickets.active) tickets.active = false
      if (feature.name === 'insights' && !feature.active && insights.active) insights.active = false
      if (feature.name === 'hiddenSendingMetadata' && !feature.active && metadataEditing.active) metadataEditing.active = false
      if (feature.name === 'tickets' && !feature.active && contact.active) contact.active = false
      if (feature.name === 'metadataEditing' && !feature.active && metadata.active) metadata.active = false
    },
    async setUser () {
      // create object features
      const features = {}
      this.features.map(o => { features[o.name] = o.active })
      this.user.features = features
      this.user.name = this.user.name?.trim()
      this.user.tel = this.$refs.phoneOutput.results.formattedNumber?.trim()
      this.user.email = this.user.email?.trim()

      if (this.isEmptyUser) {
        const account = this.$store.getters['account/getUser'].account._id

        this.user.account = account
        this.user.opt = true

        if (this.user.name?.length > 0 && this.user.email?.length > 0) {
          const sendNewUser = await accountService.setUser(this.user)

          if (sendNewUser) {
            this.$store.commit('alerts/alert', {
              message: this.$t('dashboard.success_save_config'),
              position: 'bottomRight',
              showAlert: true
            })
            this.canBeClosed = true
            this.$emit('newUser', sendNewUser)
            this.$root.$emit('update-users-page')
            this.closeSidebarUser()
          } else {
            this.$store.commit('alerts/alert', {
              message: this.$t('account_config.validate_email'),
              position: 'bottomRight',
              showAlert: true
            })
          }
        } else {
          this.$store.commit('alerts/alert', {
            message: this.$t('account_config.error_create_account'),
            position: 'bottomRight',
            showAlert: true
          })
        }
      } else {
        await accountService.updateUser(this.user)
        this.$store.commit('account/updateUserFeatures', this.user.features)
        this.$store.commit('alerts/alert', {
          message: this.$t('dashboard.success_save_config'),
          position: 'bottomRight',
          showAlert: true
        })
        this.canBeClosed = true
        this.$root.$emit('update-users-page')
        this.closeSidebarUser()
      }
    },
    closeSidebarUser () {
      if (this.canBeClosed) this.$emit('closeSideBarRoles', false)
      else if (this.isEmptyUser) this.$emit('closeSideBarRoles', false)
      else if (!this.canBeClosed) this.showCloseModal = true
    },
    async changePassword () {
      const req = await accountService.changePassword(this.user)
      let message = ''
      if (req) {
        message = this.$t('account_config.reset_password')
      } else {
        message = this.$t('global.unknown_error')
      }
      this.$store.commit('alerts/alert', {
        message: message,
        position: 'bottomRight',
        showAlert: true
      })
    },
    async ValueChanged (e, user) {
      if (['user', 'admin', 'superadmin'].indexOf(e) !== -1) {
        user.type = e
      } else {
        user.language = e
      }
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
.modal__wrapper__actions {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}
.container-user {
  width: 100vw;
  height: 100vh;
  position:fixed;
  top: 0;
  display: flex;
  justify-content: right;
  align-items: center;
  z-index: 999;
  .sidebar-user {
    width: 28rem;
    height: 100vh;
    background: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
    border-radius: 5px 0 0 5px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .expander > div.row.pointer {
      & + div {
        width: 100%;
      }
      display: flex;
      justify-content: space-between;
    }
    .user-side-header {
      background: #000;
      height: fit-content;
      min-height: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      .text-style {
        color: #fff;
      }
      h5 {
        color: #fff;
      }
    }
    .side-user-header {
      width: 99%;
      min-height: 15%;
      border-bottom: 0.5px solid #cacaca;
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;
      .details-user {
        height: 100%;
        width: 70%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          margin-bottom: 5px;
        }
        h4 {
          font-size: 19px;
        }
      }
      .delete-user {
        width: 15%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding: 0 0 5px 3px;
        .b-icon {
          font-size: 20px;
        }
      }
      .sidebar-close {
        width: 15%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        button {
          position: absolute;
          top: -10px;
          right: 5px;
        }
      }
    }
    .user-config {
      width: 100%;
      padding: 0 5px;
      .user-data {
        margin: 0 0 10px 8px;
        .text-field__input {
          margin: 15px 0px;
          border: none;
          border-bottom: 1px solid rgba(0, 0, 0, 0.4);
          height: 32px;
          width: 100%;
          input {
            color: rgb(63, 51, 86) !important;
            padding-left: 5px;
            background: rgba(217, 217, 217, 0.2);
            height: 100%;
            font-size: 17px;
            font-weight: 400;
          }
        }
      }
      .user-roles {
        border-radius: 5px;
        margin: 10px 0;
        padding-top: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .roles-options {
          width: 100%;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .input-wrapper {
            .label {
              margin: 0;
              .input:checked ~ .checkmark {
                border: 4px solid;
              }
              .checkmark {
                margin-top: 6px;
                width: 20px;
                height: 20px;
                cursor: pointer;
              }
            }
          }
          .input-wrapper::after {
            display: none;
          }
          .content-radio-group {
            margin: 10px;
          }
        }
      }
      .user-group, .user-features {
        width: 100%;
        padding-top: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .content-groups {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 5px;
          width: 100%;
          min-height: 200px;
          .h8 {
            height: 10%;
            text-align: center;
            padding-bottom: 5px;
            border-bottom: 0.5px solid #e6e6e6;
          }
          .search-group {
            width: 100%;
            height: 40px;
            margin: 5px 0 25px 10px;
            display: flex;
            .select-groups {
              margin: 7px 0;
              width: 97%;
              height: 50px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .v-select {
                margin-left: 30px;
                background: transparent;
                padding: 0;
                .vs__dropdown-toggle {
                  width: 85% !important;
                }
                .vs__actions {
                  display: none;
                  color: var(--primary-color) !important;
                }
                .vs__search {
                  color: rgb(168, 168, 168);
                }
              }
              .text-field__input {
                width: 100%;
                height: 30px;
                margin: 5px 0;
                border-bottom: 0.5px solid #e6e6e6;
                padding-left: 5px;
                background: transparent !important;
                input {
                  height: 90%;
                  padding: 0;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }
          .list-groups {
            width: 100%;
            padding: 5px;
            height: 230px;
            .groups {
              padding: 4px;
              width: 100%;
              margin: 5px 0;
              .input-wrapper::after {
                display: none;
              }
            }
          }
        }
        .groups-paginate {
          width: 100%;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          .pagination {
            max-width: 95%;
            .prev-link-item, .next-link-item {
              font-size: 12px !important;
            }
            .page-link-item {
              font-size: 12px !important;
            }
          }
        }
      }
      .content-features {
        width: 100%;
        margin: 30px 0 25px;
        min-height: 350px;
        .functionnality-title {
          width: 100%;
          text-align: start;
          height: 25px;
          margin-left: 10px;
        }
        .list-features {
          padding-left: 5px;
          margin: 15px 0 0 5px;
          display: flex;
          .heading {
            margin-top: 2px;
            font-size: 15px;
          }
          .user-feature {
            display: flex;
            > input {
              height: 17px;
              width: 17px;
            }
            .ui-switch-track {
              background: #d5d5d5;
            }
          }
        }
      }
    }
    .clear-theme-btn {
      button.binds-button {
        width: 230px;
        padding: 9px;
      }
    }
    .footer-sidebar-user {
      width: 100%;
      height: 10%;
      margin: 15px 0;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        margin: 0;
        padding: 9px 10px;
        font-size: 16px;
        width: 230px;
      }
      span {
        text-decoration: underline;
        font-size: medium;
        cursor: pointer;
      }
    }
  }
  .func-expander-title {
    padding-top: 40px;
    h5 {
      font-weight: 400;
      font-size: 16px;
    }
    .row.pointer {
      justify-content: space-between;
    }
  }
}
</style>
