<template>
  <div class="container-no-data">
    <i class="material-icons icon-no-data" style="margin: 0 10px 2px 0">error</i>
    <p class="label-no-data">{{ message ? message : $t('bvs.no_data') }}</p>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: ['message'],
  components: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">
  .container-no-data {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: #808080;
    .icon-no-data {
      font-size: 3rem;
    }
    .label-no-data {
      font-size: 0.9rem;
    }
  }
</style>
