/**
 * Provider Data Store Module
 *
 * This Vuex module is used to store the provider data.
 * In Vue 3, we have the logic to share data between components without considering the parent-child relationship.
 * However, in this module, we are using Vuex store to store the provider data to facilitate sharing between components
 * and ensure data consistency.
 *
 * Note:
 *   Before using this module, ensure that you update the data only from this module and only from one place.
 */

const state = {
  sendingIdOnQuery: ''
}

const mutations = {
  /**
     * Updates the provider data with the given object.
     * @param {*} state
     * @param {{key: '', value: ''}} objectToStore
     */
  UPDATE_PROVIDER (state, objectToStore) {
    state[objectToStore.key] = objectToStore.value
  },
  /**
   * Resets the provider data for the specified key.
   * @param {*} state
   * @param {string} key
   */
  RESET_PROVIDER (state, key) {
    state[key] = ''
  }
}

const getters = {
  getSendingOnQuery: state => state.sendingIdOnQuery
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
