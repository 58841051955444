<template>
  <div class="container-mentions">
    <app-heading level="h4" class="title-mentions">{{ $t('bvs.sentiment_mentions_title') }}</app-heading>
    <div class="mentions">
      <CardMention v-for="(mention, idx) in texts" :key="idx" :data="mention" />
    </div>
    <div class="container-loading" v-if="isLoading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <div class="alert-data" v-if="!isLoading && dataAlert">
      <i class="material-icons" style="margin: 0 10px 2px 0">error</i>
      <app-heading level="h5">{{ $t('bvs.no_data') }}</app-heading>
    </div>
  </div>
</template>

<script>
import { socialMediaService } from '@/_services'
import CardMention from '../components/CardMention.vue'

export default {
  name: 'Mentions',
  components: {
    CardMention,
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      isLoading: false,
      texts: null,
      dataMentions: null,
      dataAlert: false
    }
  },
  mounted () {
    this.dataMentions = this.$route.query
    this.updateMentions()
  },
  computed: {
    lang () {
      return this.$store.getters['account/getLang']
    },
    colors () {
      const colors = this.$store.getters['account/getAccount'].theme.colors
      return colors
    }
  },
  methods: {
    timeout (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async updateMentions () {
      this.isLoading = true
      const res = await socialMediaService.searchTextMentions({
        brandId: this.dataMentions.terms || '',
        startDate: this.dataMentions.startDate,
        endDate: this.dataMentions.endDate,
        start: 0,
        end: 10, // 100 mentions de cada marca
        text: this.dataMentions.text || '',
        sentiment: this.dataMentions.sentiment || ''
      })

      const allTexts = res.map(mention => {
        return {
          text: mention.text,
          socialMedia: mention.socialMedia,
          score: mention.score,
          magnitude: mention.magnitude,
          publicMetrics: mention.publicMetrics || {},
          sentiment: mention.sentiment,
          userInfos: mention.userInfos || {},
          brandsTracking: mention.brandTracking,
          link: mention.link,
          image: mention.userInfos ? mention.userInfos.imageUrl : '',
          createdAt: mention.postedAt
        }
      })

      this.dataAlert = false

      if (res.length <= 0) {
        this.dataAlert = true
      }

      this.texts = allTexts
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss">
.container-mentions {
  position: relative;
  overflow: hidden;
  width: 25%;
  height: 100%;
  border-radius: 10px;
  background: #ffffff70;
  border: 1px solid #dbdbdb;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  .title-mentions {
    text-align: center;
    height: 30px;
    margin: 10px 0 !important;
  }
  .mentions {
    height: 775px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .container-loading, .alert-data {
    background: #ffffff;
    color: #151515;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
