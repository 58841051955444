export const metadataManipulator = {
  insertMetaOnString,
  levenshteinDistance,
  removeFieldsFromAccounts
}

/**
 * Formata o texto recebido e retorna com os metadados encontrados com o regex
 */
function insertMetaOnString (text, sending) {
  const metadata = sending.seedData && sending.seedData.metadata ? sending.seedData.metadata : {}
  let newString = ''
  try {
    newString = text.replace(/\*\|((?:(?!\|\*).)+)\|\*/g, (match, f) => {
      return metadata[f] || ''
    })
  } catch (error) {
    newString = text
  }
  return newString
}

/**
 * Comparar strings (metadata) para retorna a probabilidade de match entre as stringa (metodo  Levenshtein)
 */
function levenshteinDistance (str1, str2) {
  const track = Array(str2.length + 1).fill(null).map(() =>
    Array(str1.length + 1).fill(null))
  for (let i = 0; i <= str1.length; i += 1) {
    track[0][i] = i
  }
  for (let j = 0; j <= str2.length; j += 1) {
    track[j][0] = j
  }
  for (let j = 1; j <= str2.length; j += 1) {
    for (let i = 1; i <= str1.length; i += 1) {
      const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1
      track[j][i] = Math.min(
        track[j][i - 1] + 1, // deletion
        track[j - 1][i] + 1, // insertion
        track[j - 1][i - 1] + indicator // substitution
      )
    }
  }
  return track[str2.length][str1.length]
}

function removeFieldsFromAccounts (account) {
  const accountToSave = Object.assign({}, account)
  delete accountToSave.fields
  return accountToSave
}
