<template>
  <div class="container-like-graphic">
    <div class="content-like-graphic" v-for="(item, idx) in items" :key="idx">
      <div class="content-label">
        <app-heading level="h6"
        :style="{'white-space': item.label.length > 134 ? 'nowrap' : 'wrap'}"
        v-title="item.label" title-max-width="320">
        {{ item.label }}
        </app-heading>
      </div>
      <div class="porcentage-bar-matrix" v-if="item.bars.length">
        <div class="progress-bar">
          <div class="bar"
           v-for="(bar, idx) in item.bars" :key="`bar-${idx}-${bar.percentage}`"
           v-title="`${$t( 'dashboard.option' )}: ${bar.option} | Qtd: ${bar.qty} | ${bar.percentage.toFixed(1)}%`" title-max-width="320"
           :style="{'background': bar.backgroundColor, 'width': `${bar.percentage}` + '%'}">
            <div class="details"
            :style="{'background': bar.backgroundColor, 'display': item.bars.length > 15 ? 'none' : 'flex' }">
              <p>{{ bar.option }}<span :style="{ 'display': item.bars.length > 15 ? 'none' : bar.percentage > 15 ? 'flex' : 'none' }">- {{ bar.percentage.toFixed(1) }}%</span></p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="content-label">
        <app-heading level="h6" title-max-width="320">
        {{$t('dashboard.no_response')}}
        </app-heading>
      </div>
    </div>
  </div>
</template>

<script>
import('color-scheme').then((ColorScheme) => { window.ColorScheme = ColorScheme.default })

import('color').then((Color) => { window.Color = Color.default })

export default {
  name: 'stackedBar',
  props: ['data', 'question', 'cardData', 'custom'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      items: [],
      colors10num: [
        '#E94212c7',
        '#ED5E0Ec7',
        '#F27A0Bc7',
        '#F79507c7',
        '#FBB003c7',
        '#FDC900c7',
        '#D4C30Dc7',
        '#AABA19c7',
        '#80B326c7',
        '#57AB32c7',
        '#29A340c7'
      ],
      colors1to5: [
        '#E94212c7',
        '#ED5E0Ec7',
        '#FBB003c7',
        '#AABA19c7',
        '#29A340c7'
      ]
    }
  },
  async mounted () {
    if (this.data[0] && this.data[0].questions) {
      const found = this.data[0].questions.find(dataQuestion => {
        return dataQuestion.question && dataQuestion.question._id === this.question._id
      })

      if (found && found.data) {
        this.question.subjects.forEach(sub => {
          this.items.push({
            label: sub.label,
            bars: []
          })
        })

        found.data.forEach((item, i) => {
          if (typeof found.data[i][2] !== 'string') {
            this.items.forEach((subject, idx) => {
              if (subject.label === item[0]) {
                const optionKey = this.question.ui === 'matrix1to5' ? item[1] / 25 + 1 : item[1] / 10
                const percentage = parseFloat((item[3]).toFixed(2))
                subject.bars.push({
                  option: optionKey,
                  qty: item[2],
                  percentage: percentage < 8 ? 10 : percentage,
                  backgroundColor: this.question.ui === 'matrix1to5' ? this.hasColor1to5[optionKey - 1] : this.hasColor10num[optionKey]
                })
                subject.bars = subject.bars.sort((a, b) => {
                  if (a.option < b.option) {
                    return -1
                  }
                })
              }
            })
          }
        })
      }
    }
  },
  computed: {
    hasColor1to5 () {
      if (this.cardData.colors.length === 5) { return this.cardData.colors }

      return this.colors1to5
    },
    hasColor10num () {
      if (this.cardData.colors.length === 11) { return this.cardData.colors }

      return this.colors10num
    }
  }
}
</script>

<style lang="scss">
.container-like-graphic {
  width: 100%;
  height: 100%;
  padding: 5px;
  overflow-y: auto;
  .content-like-graphic {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
    padding: 3px;
    border-radius: 4px;
    border: 1px dashed rgb(219, 219, 219);
    .content-label {
      width: 35%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 2px;
      h6 {
        font-size: 14px;
        color: #424242 !important;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 18px;
      }
    }
    .porcentage-bar-matrix {
      width: 65%;
      height: 55%;
      display: flex;
      align-items: center;
      .progress-bar {
        width: 98%;
        height: 75%;
        display: flex;
        border-radius: 35px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.23);
        padding: 1px;
        border-radius: 35px;
        overflow: hidden;
        .bar {
          opacity: 0;
          height: 100%;
          min-width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          animation: loadingBar 1s linear forwards;
          .details {
            position: relative;
            border-radius: 35px;
            margin-right: 5px;
            border: 1px solid rgba(255, 255, 255, 0.23);
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.10);
            min-width: 20px;
            max-width: 65px;
            padding: 0 3px;
            height: 70%;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
              color: #ffffff;
              font-weight: bold;
              font-size: 10px;
              opacity: 0;
              display: flex;
              animation: fadeBar 2s linear forwards;
              span {
                margin-left: 2px;
              }
            }
          }
        }
      }
      .bar:first-child {
        border-radius: 35px 0 0 35px;
      }
      .bar:last-child {
        border-radius: 0 35px 35px 0;
      }
    }
  }
}

@keyframes loadingBar {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes fadeBar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 820px) {
  .content-label {
    width: 50% !important;
    h6 {
      font-size: 12px !important;
    }
  }
}
</style>
