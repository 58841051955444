import { render, staticRenderFns } from "./OneByOne.vue?vue&type=template&id=36defb22"
import script from "./OneByOne.vue?vue&type=script&lang=js"
export * from "./OneByOne.vue?vue&type=script&lang=js"
import style0 from "./OneByOne.vue?vue&type=style&index=0&id=36defb22&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports