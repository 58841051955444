<template>
  <div class="row expander-title" style="margin-top: 15px !important;" v-if="!showingStartOrAnsweredMessage">
    <app-modal
      close
      v-model="showModal"
      lightbox
      class="modal-default-primary modal-conditions"
      v-on:keyup.esc="cancel()"
    >
      <div class="row" v-if="!showingEndMessage">
        <div v-for="(question, index) in questionsListForCondition" :key="index">
          <div class="nav-card-conditions" @click="selectQuestionForCondition(index)">
            <div>
              <app-heading class="question-order">
                {{ index + 1 }}
              </app-heading>
              <div class="head-questions-prev">
                <app-heading level="h6" class="question-nav-title">
                  {{ question.label || $t('questionsNav.question_title') }}
                </app-heading>
                <app-heading level="h6" class="question-nav-text">
                  {{ question.question }}
                </app-heading>
              </div>
            </div>
            <component
              class="transform-ui-c"
              v-bind:is="renderUi(question.ui, question.type, question.options)"
              :question="question"
              :colors="componentsColors"
              :preview="true"
              :class="{
                't-5emo-c': question.ui.toLowerCase() === '5emo',
                't-stars-c': question.ui.toLowerCase() === '5num' && (question.stars && question.stars.active),
                't-5num-c': question.ui.toLowerCase() === '5num' && (!question.stars || !question.stars.active),
                't-5nes-c': (question.ui.toLowerCase() === '5nes' || question.ui.toLowerCase() === '10num') && question.type === 'nes',
                't-yn-c': question.ui.toLowerCase() === 'yn',
                't-ynm-c': question.ui === 'ynm',
                't-ynd-c': question.ui.toLowerCase() === 'ynd',
                't-text-c': question.ui.toLowerCase() === 'text',
                't-inputext-c': question.ui.toLowerCase() === 'inputext',
                't-referral-c': question.ui.toLowerCase() === 'referral',
                't-referraldynamic-c': question.ui.toLowerCase() === 'referraldynamic',
                't-single-c': question.ui.toLowerCase() === 'single' || question.ui === 'singleOther',
                't-multiple-c': question.ui.toLowerCase() === 'multiple' || question.ui === 'multipleOther',
                't-0to5-c': question.options.length === 6 && question.ui === '10num',
                't-1to5-c': question.options.length === 5 && question.ui === '10num' && question.type !== 'nes',
                't-0to10-c': ((question.options.length === 11 && question.ui === '10num') || (question.options.length === 14 && question.ui === '10num')) && question.type !== 'matrix',
                't-nps-c' : question.type === 'nps' && question.ui === '10num',
                't-1to10-c': question.options.length === 10 && question.ui === '10num',
                't-1to7-c': question.type === 'ces' && question.ui === '1to7',
                't-1to7button-c': question.type === 'ces' && question.ui === '1to7button',
                't-matrix1to5-c': question.ui.toLowerCase() === 'matrix1to5',
                't-matrix10num-c': question.ui.toLowerCase() === '10num' && question.type === 'matrix',
                't-5radio-c': question.ui.toLowerCase() === '5radio'
              }"
            >
            </component>
          </div>
        </div>
      </div>
      <div class="row" v-if="showingEndMessage">
        <div v-for="(question, index) in questionsListForConditionForEnd" :key="index">
          <div class="nav-card-conditions" @click="selectQuestionForCondition(index)">
            <div>
              <app-heading class="question-order">
                {{ index + 1 }}
              </app-heading>
              <div class="head-questions-prev">
                <app-heading level="h6" class="question-nav-title">
                  {{ question.label || $t('questionsNav.question_title') }}
                </app-heading>
                <app-heading level="h6" class="question-nav-text">
                  {{ question.question }}
                </app-heading>
              </div>
            </div>
            <component
              class="transform-ui-c"
              v-bind:is="renderUi(question.ui, question.type, question.options)"
              :question="question"
              :colors="componentsColors"
              :preview="true"
              :class="{
              't-5emo-c': question.ui.toLowerCase() === '5emo',
              't-5num-c': question.ui.toLowerCase() === '5num',
              't-5nes-c': (question.ui.toLowerCase() === '5nes' || question.ui.toLowerCase() === '10num') && question.type === 'nes',
              't-yn-c': question.ui.toLowerCase() === 'yn',
              't-ynm-c': question.ui.toLowerCase() === 'ynm',
              't-ynd-c': question.ui.toLowerCase() === 'ynd',
              't-text-c': question.ui.toLowerCase() === 'text',
              't-inputext-c': question.ui.toLowerCase() === 'inputext',
              't-referral-c': question.ui.toLowerCase() === 'referral',
              't-referraldynamic-c': question.ui.toLowerCase() === 'referraldynamic',
              't-single-c': question.ui.toLowerCase() === 'single' || question.ui === 'singleOther',
              't-multiple-c': question.ui.toLowerCase() === 'multiple' || question.ui === 'multipleOther',
              't-0to5-c': question.options.length === 6 && question.ui === '10num',
              't-1to5-c': question.options.length === 5 && question.ui === '10num' && question.type !== 'nes',
              't-0to10-c': ((question.options.length === 11 && question.ui === '10num') || (question.options.length === 14 && question.ui === '10num')) && question.type !== 'matrix',
              't-nps-c' : question.type === 'nps' && question.ui === '10num',
              't-1to10-c': question.options.length === 10 && question.ui === '10num',
              't-1to7-c': question.type === 'ces' && question.ui === '1to7',
              't-1to7button-c': question.type === 'ces' && question.ui === '1to7button',
              't-matrix1to5-c': question.ui.toLowerCase() === 'matrix1to5',
              't-matrix10num-c': question.ui.toLowerCase() === '10num' && question.type === 'matrix',
              't-5radio-c': question.ui.toLowerCase() === '5radio'
              }"
            >
            </component>
          </div>
        </div>
      </div>
    </app-modal>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <!-- Conditions for questions -->
      <DoubleBounce v-if="isLoading" style="left: 50%"/>
      <div class="condition-content" v-if="!isFirstQuestion && !showingEndMessage && !isLoading">
        <div class="row">
          <app-heading level="h4">{{ $t('survey.for_responses') }}:</app-heading>
        </div>
        <div class="row selects-condition" v-if="responseConditionsList.length > 1">
          <app-heading
            level="h6"
            class="col-lg-12 col-md-12 col-sm-12"
            style="margin-left: 0px !important;"
          >{{ $t('survey.display_rules') }}:</app-heading>
          <v-select
            class="col-lg-12 col-md-12 col-sm-12 rule-select"
            label="label"
            :options="displayRules"
            v-model="selectedDisplayRule"
            :clearable="false"
            :searchable="false"
            @input="changeDisplayRule()"
          ></v-select>
        </div>
        <!-- Lista de condições já cadastradas -->
        <div v-for="(condition, index) in responseConditionsList" :key="index">
          <hr v-if="index != 0" style="width: 100%; position: absolute; left: 0; color: rgba(0, 0, 0, 0.3)">
          <div class="card-condition card-response" v-if="thereIsConditionForResponse">
            <div class="row" :style="{ 'padding-top': index !== 0 ? '15px' : '0'}">
              <div class="col-lg-offset-11 col-lg-1 col-md-offset-11 col-md-1 col-sm-offset-11 col-sm-1 clear-button">
                <button v-on:click="deleteConditionForResponse(index)">
                  <app-textstyle size="size-xl" v-title="$t('dashboard_card.delete')">×</app-textstyle>
                </button>
              </div>
              <div class="parent-new-condition">
                <div class="child-new-condition">
                  <app-heading level="h6">{{ $t('survey.settings.question') }}:</app-heading>
                  <button class="btn-select-question" @click="showModalForUpdating(index)">
                    {{ condition.question.label || condition.question.question || condition.question.title }}
                  </button>
                </div>
                <div class="child-new-condition">
                  <app-heading level="h6">{{ $t('survey.settings.conditions') }}:</app-heading>
                  <v-select
                    class="col-lg-12 col-md-12 col-sm-12 col-xs-12 fix-select"
                    style="padding: 0"
                    label="label"
                    :options="operators"
                    v-model="condition.operator"
                    :clearable="false"
                    @input="updateConditionForResponse(condition, index)"
                  ></v-select>
              </div>
              </div>
              <div v-if="condition.question && condition.question.options" class="row selects-condition" style="margin-top: -5px;">
                <app-heading level="h6" style="margin-left: 1rem !important">{{ $t('survey.metadata_value') }}:</app-heading>
                <v-select
                  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 fix-select"
                  style="padding-top: 10px"
                  label="label"
                  :options="condition.question.options"
                  v-model="condition.value"
                  :clearable="false"
                  @input="updateConditionForResponse(condition, index)"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
        <!-- Adicionando nova condição por resposta -->
        <div v-if="addingCondition">
          <hr style="width: 100%; position: absolute; left: 0; color: rgba(0, 0, 0, 0.3)">
          <div class="row ml5neg" style="padding-top: 15px">
            <app-heading level="h6">{{ $t('survey.new_condition') }}:</app-heading>
          </div>
          <div class="card-condition card-response card-adding mt15">
            <div class="row mt15">
              <div class="parent-new-condition">
                <div class="child-new-condition">
                  <app-heading level="h6">{{ $t('survey.settings.question') }}:</app-heading>
                  <button class="btn-select-question" @click="showModal = true">
                    {{ newCondition.question.label || newCondition.question.question || newCondition.question.title }}
                  </button>
                </div>
                <div class="child-new-condition">
                  <app-heading level="h6">{{ $t('survey.settings.conditions') }}:</app-heading>
                  <v-select
                    class="col-lg-12 col-md-12 col-sm-12 col-xs-12 fix-select"
                    style="padding: 0;"
                    label="label"
                    :options="operators"
                    v-model="newCondition.operator"
                    :clearable="false"
                  ></v-select>
                </div>
              </div>
              <div class="row selects-condition selects-condition-response" style="margin-top: -5px;">
                <app-heading level="h6" style="margin-left: 1rem !important">{{ $t('survey.metadata_value') }}:</app-heading>
                <v-select
                  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 fix-select"
                  style="padding-top: 10px !important"
                  label="label"
                  :options="newCondition.question.options"
                  v-model="newCondition.value"
                  :clearable="false"
                ></v-select>
              </div>
              <div class="col-lg-offset-11 col-lg-1 col-md-offset-11 col-md-1 col-sm-offset-11 col-sm-1 confirm-button">
                <app-button inline v-title="$t('survey.confirm')" v-on:click="addNewConditionForResponse()">
                  <i class="material-icons check-icon">check_circle</i>
                </app-button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!addingCondition" class="row add-condition-response">
          <app-button variation="primary" size="small" floating @click="showAddConditionFieldsForResponse()">
            <i class="material-icons plus-icon">add</i>
          </app-button>
          <app-heading level="h6">{{ $t('survey.add_condition') }}</app-heading>
        </div>
      </div>
      <hr v-if="(!isFirstQuestion && !showingEndMessage ) && !isLoading" style="width: 100%; position: absolute; left: 0; color: rgba(0, 0, 0, 0.3)">
      <div class="condition-content" v-if="!showingEndMessage && !isLoading" style="margin-top: 30px">
        <div class="row">
          <app-heading level="h4">{{ $t('survey.for_metadata') }}:</app-heading>
        </div>
        <div class="row add-condition" v-if="!thereIsMetadata && !showAddConditionFields">
          <app-button class="btn-circle-accent" variation="primary" size="small" floating @click="addConditionForMetadata()">
            <i class="material-icons plus-icon">add</i>
          </app-button>
          <app-heading level="h6">{{ $t('survey.add_condition') }}</app-heading>
        </div>
        <div class="card-condition card-condition-meta" v-if="(metadataGroup && thereIsMetadata) || showAddConditionFields">
          <div class="row">
            <div class="col-lg-offset-11 col-lg-1 col-md-offset-11 col-md-1 col-sm-offset-11 col-sm-1 clear-button" v-if="metadataGroup">
              <button v-on:click="clearConditionForMetadata()">
                <app-textstyle size="size-xl" v-title="$t('dashboard_card.delete')">×</app-textstyle>
              </button>
            </div>
             <div class="parent-new-condition">
              <div class="child-new-condition">
                <app-heading level="h6">{{ $t('survey.settings.question') }}:</app-heading>
                <v-select
                  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 fix-select"
                  style="padding: 0; margin-right: 10px"
                  label="label"
                  :options="metadataGroup"
                  v-model="selectedMetadada"
                  :clearable="false"
                  :searchable="false"
                  @input="changeMetadata()"
                ></v-select>
              </div>

              <div class="child-new-condition">
                <app-heading level="h6">{{ $t('survey.settings.question') }}:</app-heading>
                <v-select
                  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 fix-select"
                  style="padding: 0"
                  label="label"
                  :options="operators"
                  v-model="selectedOperator"
                  :clearable="false"
                  :searchable="false"
                  @input="changeOperator()"
                ></v-select>
              </div>
             </div>
            <div class="row selects-condition selects-condition-meta">

            </div>
            <div class="row" style="margin-top: -5px;">
              <app-heading level="h6" style="margin-left: 17px !important; margin-bottom: -5px !important;">{{ $t('survey.metadata_value') }}:</app-heading>
              <app-form-input
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12 condition-value messageLabel"
                v-model="metadataCondition"
                secondary
                @input="changeConditionValue()"
              ></app-form-input>
            </div>
          </div>
        </div>
      </div>
      <!-- Conditions for questions -END -->
      <!-- End message conditions -->
      <div class="condition-content" v-if="showingEndMessage && endMessageIndex !== 0">
        <div class="row">
          <app-heading level="h4" v-if="!isLoading">{{ $t('survey.for_metadata') }}:</app-heading>
        </div>
        <div class="row add-condition" v-if="!thereIsMetadataForEnd && !conditionFieldsForEnd && !isLoading">
          <app-button variation="primary" size="small" floating @click="addConditionForMetadata(true)">
            <i class="material-icons plus-icon">add</i>
          </app-button>
          <app-heading level="h6">{{ $t('survey.add_condition') }}</app-heading>
        </div>
        <div class="card-condition" v-if="(metadataGroup && thereIsMetadataForEnd) || conditionFieldsForEnd">
          <div class="row">
            <div class="col-lg-offset-11 col-lg-1 col-md-offset-11 col-md-1 col-sm-offset-11 col-sm-1 clear-button" v-if="metadataGroup && thereIsMetadataForEnd">
              <button v-on:click="clearConditionForMetadata(true)">
                <app-textstyle size="size-xl" v-title="$t('dashboard_card.delete')">×</app-textstyle>
              </button>
            </div>
            <div class="row selects-condition" :class="{ 'selects-top': conditionFieldsForEnd && !thereIsMetadataForEnd }">
              <v-select
                class="col-lg-6 col-md-6 col-sm-6 col-xs-6"
                label="label"
                :options="metadataGroup"
                v-model="selectedMetadataForEnd"
                :clearable="false"
                :searchable="false"
                @input="changeMetadata(true)"
              ></v-select>
              <v-select
                class="col-lg-6 col-md-6 col-sm-6 col-xs-6"
                label="label"
                :options="operators"
                v-model="selectedOperatorForEnd"
                :clearable="false"
                :searchable="false"
                @input="changeOperator(true)"
              ></v-select>
            </div>
            <div class="row" style="margin-top: -5px;">
              <app-heading level="h6" style="margin-left: 17px !important; margin-bottom: -5px !important;">{{ $t('survey.metadata_value') }}:</app-heading>
              <app-form-input
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12 condition-value messageLabel"
                v-model="metadataConditionForEnd"
                secondary
                @input="changeConditionValue(true)"
              ></app-form-input>
            </div>
          </div>
        </div>
        <hr v-if="(endMessageIndex !== 0 && showingEndMessage) && !isLoading" style="width: 100%; position: absolute; left: 0; color: rgba(0, 0, 0, 0.3)">
        <div class="condition-content" v-if="endMessageIndex > 0 && showingEndMessage">
          <div class="row">
            <app-heading level="h4">{{ $t('survey.for_responses') }}:</app-heading>
          </div>
          <div class="row selects-condition" v-if="responseConditionsListForEnd.length > 1">
            <app-heading
              level="h6"
              class="col-lg-12 col-md-12 col-sm-12"
              style="margin-left: 0px !important;"
            >{{ $t('survey.display_rules') }}:</app-heading>
            <v-select
              class="col-lg-12 col-md-12 col-sm-12 rule-select"
              label="label"
              :options="displayRules"
              v-model="selectedDisplayRuleForEnd"
              :clearable="false"
              :searchable="false"
              @input="changeDisplayRule(true)"
            ></v-select>
          </div>
          <div v-for="(condition, index) in responseConditionsListForEnd" :key="index">
            <div class="card-condition card-response" v-if="thereIsResponseConditionForEnd">
              <div class="row">
                <div class="col-lg-offset-11 col-lg-1 col-md-offset-11 col-md-1 col-sm-offset-11 col-sm-1 clear-button">
                  <button v-on:click="deleteConditionForResponse(index, true)">
                    <app-textstyle size="size-xl" v-title="$t('dashboard_card.delete')">×</app-textstyle>
                  </button>
                </div>
                <div class="row selects-condition">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <button class="btn-select-question" @click="showModalForUpdating(index, true)">
                        {{ condition.question.label || condition.question.question || condition.question.title }}
                    </button>
                  </div>
                  <v-select
                    class="col-lg-6 col-md-6 col-sm-6 col-xs-6 fix-select"
                    label="label"
                    :options="operators"
                    v-model="condition.operator"
                    :clearable="false"
                    @input="updateConditionForResponse(condition, index, true)"
                  ></v-select>
                </div>
                <div class="row selects-condition" style="margin-top: -5px;">
                  <v-select
                    class="col-lg-12 col-md-12 col-sm-12 col-xs-12 fix-select"
                    label="label"
                    :options="condition.question.options"
                    v-model="condition.value"
                    :clearable="false"
                    @input="updateConditionForResponse(condition, index, true)"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
          <div v-if="addingConditionForEnd">
            <div class="row ml5neg" style="">
              <app-heading level="h6">{{ $t('survey.new_condition') }}:</app-heading>
            </div>
            <div class="card-condition card-response card-adding mt15">
              <div class="row mt15">
                <div class="row selects-condition">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <button class="btn-select-question" @click="showModal = true">
                        {{ newConditionForEnd.question.label || newConditionForEnd.question.question || newConditionForEnd.question.title }}
                    </button>
                  </div>
                  <v-select
                    class="col-lg-6 col-md-6 col-sm-6 col-xs-6 fix-select"
                    label="label"
                    :options="operators"
                    v-model="newConditionForEnd.operator"
                    :clearable="false"
                  ></v-select>
                </div>
                <div class="row selects-condition" style="margin-top: -5px;">
                  <v-select
                    class="col-lg-12 col-md-12 col-sm-12 col-xs-12 fix-select"
                    label="label"
                    :options="newConditionForEnd.question.options"
                    v-model="newConditionForEnd.value"
                    :clearable="false"
                  ></v-select>
                </div>
                <div class="col-lg-offset-11 col-lg-1 col-md-offset-11 col-md-1 col-sm-offset-11 col-sm-1 confirm-button">
                  <app-button inline v-title="$t('survey.confirm')" v-on:click="addNewConditionForResponse(true)">
                    <i class="material-icons check-icon">check_circle</i>
                  </app-button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!addingConditionForEnd" class="row add-condition-response">
            <app-button variation="primary" size="small" floating @click="showAddConditionFieldsForResponse(true)">
              <i class="material-icons plus-icon">add</i>
            </app-button>
            <app-heading level="h6">{{ $t('survey.add_condition') }}</app-heading>
          </div>
      </div>
      </div>
      <div class="container-no-condition" v-if="showingEndMessage && endMessageIndex === 0">
        <p class="label-no-condition">{{ $t('survey.settings.no_condition_end_message') }}</p>
      </div>
      <!-- End message conditions - END -->
    </div>
  </div>
</template>
<script>
import { responseOptions } from '@/_helpers'
// import { userService } from '@/_services'
import app10num from '../../../survey/answersType/10num.vue'
import app5num from '../../../survey/answersType/5num.vue'
import app5nes from '../../../survey/answersType/5nes.vue'
import appyn from '../../../survey/answersType/kpi-yn.vue'
import appynm from '../../../survey/answersType/kpi-ynm.vue'
import appynd from '../../../survey/answersType/kpi-ynd.vue'
import app5emo from '../../../survey/answersType/5emo.vue'
import apptext from '../../../survey/answersType/text.vue'
import appinputext from '../../../survey/answersType/input-text.vue'
import appreferral from '../../../survey/answersType/referral.vue'
import appsingle from '../../../survey/answersType/single.vue'
import appmultiple from '../../../survey/answersType/multiple.vue'
import app0to5 from '../../../survey/answersType/0to5.vue'
import app1to5 from '../../../survey/answersType/1to5.vue'
import app1to7 from '../../../survey/answersType/1to7.vue'
import app1to7button from '../../../survey/answersType/1to7button.vue'
import app0to10 from '../../../survey/answersType/0to10.vue'
import app1to10 from '../../../survey/answersType/1to10.vue'
import app5radio from '../../../survey/answersType/nvs.vue'
import isAnonymous from '../responses/IsAnonymousConfig.vue'
import ReferralDynamicOptions from '../../ReferralDynamicOptions.vue'

export default {
  name: 'Conditions',
  components: {
    app10num,
    app5num,
    app5nes,
    appyn,
    appynm,
    appynd,
    app5emo,
    apptext,
    appinputext,
    appreferral,
    appsingle,
    appmultiple,
    app0to5,
    app1to5,
    app1to7,
    app1to7button,
    app0to10,
    app1to10,
    app5radio,
    isAnonymous,
    ReferralDynamicOptions,
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    vSelect: () => import('vue-select')
  },
  props: ['showingEndMessage'],
  data () {
    return {
      isLoading: true,
      endMessageIndex: '',
      showingStartOrAnsweredMessage: false,
      // End message conditions - START
      selectedMetadataForEnd: '',
      selectedOperatorForEnd: '',
      metadataConditionForEnd: '',
      conditionFieldsForEnd: false,
      thereIsMetadataForEnd: false,
      // End message conditions - END
      // Condition for metadata - START
      metadataGroup: [],
      selectedMetadada: '',
      selectedOperator: '',
      metadataCondition: '',
      operators: [
        { label: this.$i18n.t('survey.is_op'), value: '$eq' },
        { label: this.$i18n.t('survey.is_not_op'), value: '$ne' }
      ],
      showAddConditionFields: false,
      thereIsMetadata: false,
      isFirstQuestion: false,
      // Condition for metadata - END
      // Condition for responses - START
      questionsListForCondition: [],
      questionsListForConditionForEnd: [],
      responseConditionsList: [],
      responseConditionsListForEnd: [],
      showResponseConditionFields: false,
      showConditionFieldsForEnd: false,
      thereIsConditionForResponse: false,
      thereIsResponseConditionForEnd: false,
      displayRules: [
        { label: this.$i18n.t('survey.all_rule'), value: 'showIfAll' },
        { label: this.$i18n.t('survey.any_rule'), value: 'showIfAny' }
      ],
      selectedDisplayRule: '',
      selectedDisplayRuleForEnd: '',
      newCondition: {},
      newConditionForEnd: {},
      addingCondition: false,
      addingConditionForEnd: false,
      showModal: false,
      updatingCondition: false,
      updatingConditionForEnd: false,
      conditionToUpdateIndex: undefined,
      conditionToUpdateIndexForEnd: undefined,
      otherLabel: false,
      multipleOther: false,
      errorRedirectUrl: false,
      endMessageRedirect: false,
      titleOnReports: '',
      // Condition for responses - END
      // Condition for blocking - START
      isToBlock: false,
      isToBlockSurveySendByInterval: false,
      isToBlockSurveyExpireByInterval: false,
      sendLockTime: '',
      expireLockTime: '',
      lockTime: '',
      intervalKey: '',
      interval: '',
      sendInterval: '',
      sendIntervalValue: '',
      expireInterval: '',
      expireIntervalValue: '',
      intervalValue: 'h',
      fieldsMetadata: [
        { label: this.$i18n.t('survey.email_or_phone'), value: null }
      ],
      optionsForBlocking: [
        { label: this.$i18n.t('survey.hours'), value: 'h' },
        { label: this.$i18n.t('survey.days'), value: 'd' },
        { label: this.$i18n.t('survey.months'), value: 'M' }
      ],
      optionsSendBlock: [
        { label: this.$i18n.t('survey.seconds'), value: 'seconds' },
        { label: this.$i18n.t('survey.minutes'), value: 'minutes' },
        { label: this.$i18n.t('survey.hours'), value: 'hours' },
        { label: this.$i18n.t('survey.days'), value: 'days' },
        { label: this.$i18n.t('survey.weeks'), value: 'weeks' },
        { label: this.$i18n.t('survey.months'), value: 'months' }
      ],
      isOther: false,
      currentEndMessage: ''
      // Condition for blocking - END
    }
  },
  watch: {
    question: function () {
      this.addingCondition = false
      this.newCondition = {}
      this.updatingCondition = false
      this.setTitleOnReportsValue()
      this.setConditionsInitialValues()
      this.createQuestionsListForCondition()
      this.mountResponseConditionsList()
    }
  },
  beforeDestroy () {
    this.$root.$off('end-message-updated')
  },
  async mounted () {
    if (this.user.account.fields.length > 0) {
      const data = this.user.account.fields
      data.map(item => this.metadataGroup.push(item))
    }
    this.$store.commit('survey/survey', { isTemplate: false })

    const survey = this.$store.getters['survey/getSurvey']

    if (survey.responseInterval) {
      const parseInterval = survey.responseInterval.split('')
      const findInterval = parseInterval[parseInterval.length - 1]
      parseInterval.splice(parseInterval.length - 1, 1)
      this.lockTime = parseInterval.join('')
      this.intervalValue = findInterval
      this.optionsForBlocking.find(item => {
        if (item.value === findInterval) {
          this.interval = item.label
        }
      })

      this.isToBlock = true
    } else {
      this.interval = this.optionsForBlocking[0].label
    }

    if (survey.responseIntervalKey) {
      const parseIntervalKey = survey.responseIntervalKey.split('.')

      this.metadataGroup.find(item => {
        if (item.key === parseIntervalKey[1] && survey.responseIntervalKey !== null) {
          this.intervalKey = item.label
        }
      })
    }

    if (survey.sendInterval) {
      const findInterval = survey.sendInterval.match(/[a-zA-Z]+/g)
      const hasSendInterval = survey.sendInterval.match(/\d+/g)
      if (hasSendInterval) {
        this.sendLockTime = hasSendInterval[0]
      }

      if (findInterval && findInterval[0]) {
        this.sendIntervalValue = findInterval[0]
        this.optionsSendBlock.find(item => {
          if (item.value === findInterval[0]) {
            this.sendInterval = item.label
          }
        })
      }
      this.isToBlockSurveySendByInterval = true
    } else {
      this.sendInterval = this.optionsSendBlock[0]
      this.sendIntervalValue = this.optionsSendBlock[0].value
    }

    if (survey.expireInterval) {
      const findInterval = survey.expireInterval.match(/[a-zA-Z]+/g)
      const hasExpireInterval = survey.expireInterval.match(/\d+/g)
      if (hasExpireInterval) {
        this.expireLockTime = hasExpireInterval[0]
      }

      if (findInterval && findInterval[0]) {
        this.expireIntervalValue = findInterval[0]
        this.optionsSendBlock.find(item => {
          if (item.value === findInterval[0]) {
            this.expireInterval = item.label
          }
        })
      }
      this.isToBlockSurveyExpireByInterval = true
    } else {
      this.expireInterval = this.optionsSendBlock[0]
      this.expireIntervalValue = this.optionsSendBlock[0].value
    }

    if (survey.responseIntervalKey === null) {
      this.intervalKey = this.fieldsMetadata[0].label
    }

    this.createQuestionsListForCondition()

    this.mountResponseConditionsList()
    this.setConditionsInitialValues()

    // Init Single Other
    if (this.question.ui === 'singleOther') {
      this.isOther = true
    }
    // Init multiple Other
    if (this.question.ui === 'multipleOther') {
      this.isOther = true
    }
    // Set initial fields for referral
    if (this.question.ui === 'referral') {
      const activeFields = []
      if (this.question.referral.fields.name.active) {
        activeFields.push('n')
      }
      if (this.question.referral.fields.email.active) {
        activeFields.push('e')
      }
      if (this.question.referral.fields.phone.active) {
        activeFields.push('p')
      }
      const referralOp = activeFields.join('')
      this.updateReferralOptions(referralOp)
    }
    this.endMessageIndex = survey.selectedEndMessageIndex || 0
    this.currentEndMessage = survey.endMessages[this.endMessageIndex]
    if (this.currentEndMessage && this.currentEndMessage.details !== undefined) {
      this.endMessageDetails = true
      if (this.currentEndMessage.details.link !== undefined) {
        this.useLinkEndMessage = true
      }
    }

    if (this.currentEndMessage.ifMetadata.length > 0) {
      if (this.currentEndMessage.ifMetadata.value !== '') {
        this.thereIsMetadataForEnd = true
        this.metadataConditionForEnd = this.currentEndMessage.ifMetadata[0].value

        const op = this.operators.find(item => item.value === this.currentEndMessage.ifMetadata[0].operator)
        if (op) {
          this.selectedOperatorForEnd = op
        }

        const metadata = this.metadataGroup.find(item => item.key === this.currentEndMessage.ifMetadata[0].label)
        if (metadata) {
          this.selectedMetadataForEnd = metadata
        }
      }
    }

    this.$root.$on('end-message-updated', () => {
      this.ifMetadata = []
      this.showingStartOrAnsweredMessage = false
      this.endMessageDetails = false
      this.useLinkEndMessage = false

      const survey = this.$store.getters['survey/getSurvey']
      this.endMessageIndex = survey.selectedEndMessageIndex
      this.currentEndMessage = survey.endMessages[this.endMessageIndex]

      if (this.currentEndMessage.details !== undefined) {
        this.endMessageDetails = true
        if (this.currentEndMessage.details.link !== undefined) {
          this.useLinkEndMessage = true
        }
      }

      if (this.currentEndMessage && this.currentEndMessage.redirectUrl !== '') {
        this.endMessageRedirect = true
      } else {
        this.endMessageRedirect = false
      }
      this.$forceUpdate()

      // Initializing metadata conditions for current endmessage
      this.thereIsMetadataForEnd = false
      this.metadataConditionForEnd = ''
      this.conditionFieldsForEnd = false

      if (this.currentEndMessage.ifMetadata.length > 0) {
        if (this.currentEndMessage.ifMetadata.value !== '') {
          this.thereIsMetadataForEnd = true
          this.metadataConditionForEnd = this.currentEndMessage.ifMetadata[0].value

          const op = this.operators.find(item => item.value === this.currentEndMessage.ifMetadata[0].operator)
          if (op) {
            this.selectedOperatorForEnd = op
          }

          const metadata = this.metadataGroup.find(item => item.label === this.currentEndMessage.ifMetadata[0].label)
          if (metadata) {
            this.selectedMetadataForEnd = metadata
          }
        }
      }
      // Initializing metadata conditions for current endmessage - END
      // Initializing response conditions for current endmessage - START
      this.addingConditionForEnd = false
      this.newConditionForEnd = {}
      this.updatingConditionForEnd = false
      this.createQuestionListConditionForEnd()
      this.mountResponseConditionsListForEnd()
      // Initializing response conditions for current endmessage - END
    })

    this.isLoading = false
  },
  async created () {
    const survey = this.$store.getters['survey/getSurvey']

    if (!survey.responseInterval) {
      survey.responseInterval = null
    }
    if (!survey.sendInterval) {
      survey.sendInterval = null
    }
    if (!survey.expireInterval) {
      survey.expireInterval = null
    }
    if (!survey.responseIntervalKey) {
      survey.responseIntervalKey = null
    }

    this.$root.$on('conditions-updated-drag', () => {
      this.$forceUpdate()
    })

    this.$root.$on('showing-question', () => {
      this.$root.$emit('update-survey-settings-key')
    })

    this.$root.$on('showing-message', () => {
      this.showingStartOrAnsweredMessage = true
      this.$forceUpdate()
    })

    if (survey.answeredMessageDetails) {
      this.answeredMessageDetails = survey.answeredMessageDetails
      if (this.answeredMessageDetails.link) {
        this.useAnsweredDetails = true
      }
    }
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    },
    questions: {
      get () {
        return this.$store.getters['survey/getQuestions']
      },
      set (newList) {
        this.$store.commit('survey/survey', { questions: newList })
      }
    },
    lang () {
      return this.$store.getters['account/getLang']
    },
    question () {
      const survey = this.$store.getters['survey/getSurvey']
      const language = survey.questions[survey.questionSelected].language ? survey.questions[survey.questionSelected].language : this.lang
      survey.questions[survey.questionSelected].language = language
      return survey.questions[survey.questionSelected]
    },
    componentsColors () {
      const themeColors = this.$store.getters['survey/getColors']
      const colors = {
        actionColor: themeColors.actionColor,
        actionText: themeColors.actionText,
        splashColor: themeColors.splashColor
      }
      return colors
    },
    user () {
      const user = this.$store.getters['account/getUser']
      return user
    }
  },
  methods: {
    updateReferralOptions (e) {
      /*
        Precisa sempre inicializar os campos para true quando essa função acontece, porque
        quando eles são colocados para false durante a seleção do input, isso se mantém.
        Isso garante que somente o campo que deve estar false ficará false.
      */
      this.question.referral.fields.name.active = true
      this.question.referral.fields.phone.active = true
      this.question.referral.fields.email.active = true

      if (e === 'np') {
        this.question.referral.fields.email.active = false
      }
      if (e === 'ne') {
        this.question.referral.fields.phone.active = false
      }

      this.question.selectedReferralOption = e
    },
    changeDisplayRule (isEndMessage) {
      if (isEndMessage) {
        if (this.selectedDisplayRuleForEnd.value === 'showIfAll') {
          if (this.currentEndMessage.showIfAny.length > 0) {
            this.currentEndMessage.showIfAll = this.currentEndMessage.showIfAny
            this.currentEndMessage.showIfAny = []
            this.updateCurrentEndMessage()
          }
        }
        if (this.selectedDisplayRuleForEnd.value === 'showIfAny') {
          if (this.currentEndMessage.showIfAll.length > 0) {
            this.currentEndMessage.showIfAny = this.currentEndMessage.showIfAll
            this.currentEndMessage.showIfAll = []
            this.updateCurrentEndMessage()
          }
        }
      } else {
        if (this.selectedDisplayRule.value === 'showIfAll') {
          if (this.question.showIfAny.length > 0) {
            this.question.showIfAll = this.question.showIfAny
            this.question.showIfAny = []
            this.updateCurrentQuestion()
          }
        }
        if (this.selectedDisplayRule.value === 'showIfAny') {
          if (this.question.showIfAll.length > 0) {
            this.question.showIfAny = this.question.showIfAll
            this.question.showIfAll = []
            this.updateCurrentQuestion()
          }
        }
      }
    },
    addNewConditionForResponse (isEndMessage) {
      if (isEndMessage) {
        this.updatingConditionForEnd = false
        const conditionToPush = {
          question: this.newConditionForEnd.question.id,
          operator: this.newConditionForEnd.operator.value,
          value: this.newConditionForEnd.value.value || this.newConditionForEnd.value.rating || this.newConditionForEnd.value.label
        }

        if (this.selectedDisplayRuleForEnd.value === 'showIfAll') {
          this.currentEndMessage.showIfAll.push(conditionToPush)
        }
        if (this.selectedDisplayRuleForEnd.value === 'showIfAny') {
          this.currentEndMessage.showIfAny.push(conditionToPush)
        }

        this.updateCurrentEndMessage()
        this.mountResponseConditionsListForEnd()
        this.addingConditionForEnd = false
      } else {
        this.updatingCondition = false

        const conditionToPush = {
          question: this.newCondition.question.id,
          operator: this.newCondition.operator.value,
          value: this.newCondition.value.value || this.newCondition.value.rating || this.newCondition.value.label
        }

        if (this.selectedDisplayRule.value === 'showIfAll') {
          this.question.showIfAll.push(conditionToPush)
        }
        if (this.selectedDisplayRule.value === 'showIfAny') {
          this.question.showIfAny.push(conditionToPush)
        }

        this.updateCurrentQuestion()
        this.mountResponseConditionsList()
        this.addingCondition = false
      }
    },
    setTitleOnReportsValue () {
      this.titleOnReports = !this.question.customTitle
    },
    deleteConditionForResponse (index, isEndMessage) {
      if (isEndMessage) {
        if (this.responseConditionsListForEnd.length > 0) {
          this.responseConditionsListForEnd.splice(index, 1)
        }
        if (this.selectedDisplayRuleForEnd.value === 'showIfAll') {
          this.currentEndMessage.showIfAll.splice(index, 1)
        }
        if (this.selectedDisplayRuleForEnd.value === 'showIfAny') {
          this.currentEndMessage.showIfAny.splice(index, 1)
        }
        this.updateCurrentEndMessage()
      } else {
        if (this.responseConditionsList.length > 0) {
          this.responseConditionsList.splice(index, 1)
        }
        if (this.selectedDisplayRule.value === 'showIfAll') {
          this.question.showIfAll.splice(index, 1)
        }
        if (this.selectedDisplayRule.value === 'showIfAny') {
          this.question.showIfAny.splice(index, 1)
        }
        this.updateCurrentQuestion()
      }
    },
    renderUi (ui, type, options) {
      let appUi = 'app' + ui.toLowerCase()

      if (ui === '10num' || ui === '10NUM') {
        if (options.length === 6) {
          appUi = 'app0to5'
        }
        if (options.length === 5) {
          appUi = 'app1to5'
        }
        if (options.length === 11) {
          appUi = 'app0to10'
        }
        if (options.length === 10) {
          appUi = 'app1to10'
        }
      }
      if (type === 'matrix' && ui === '10num') {
        appUi = 'appmatrix10num'
      }
      if (ui === 'multipleOther') {
        appUi = 'appmultiple'
      }
      if (ui === 'singleOther') {
        appUi = 'appsingle'
      }

      return appUi
    },
    selectQuestionForCondition (index) {
      if (!this.showingEndMessage) {
        if (!this.updatingCondition) {
          this.newCondition = {
            question: this.questionsListForCondition[index],
            operator: this.operators[0],
            value: this.questionsListForCondition[index].options[0]
          }
        } else {
          const cUpdated = {
            question: this.questionsListForCondition[index].id,
            operator: this.operators[0],
            value: this.questionsListForCondition[index].options[0]
          }

          this.responseConditionsList[this.conditionToUpdateIndex] = { ...cUpdated }
          cUpdated.question = this.questionsListForCondition[index].id
          cUpdated.operator = this.operators[0].value
          cUpdated.value = this.questionsListForCondition[index].options[0].value || this.questionsListForCondition[index].options[0].rating || this.questionsListForCondition[index].options[0].label

          if (this.selectedDisplayRule.value === 'showIfAll') {
            this.question.showIfAll[this.conditionToUpdateIndex] = cUpdated
          }
          if (this.selectedDisplayRule.value === 'showIfAny') {
            this.question.showIfAny[this.conditionToUpdateIndex] = cUpdated
          }

          this.updateCurrentQuestion()
        }
      } else {
        if (!this.updatingConditionForEnd) {
          this.newConditionForEnd = {
            question: this.questionsListForConditionForEnd[index],
            operator: this.operators[0],
            value: this.questionsListForConditionForEnd[index].options[0]
          }
        } else {
          const cUpdated = {
            question: this.questionsListForConditionForEnd[index],
            operator: this.operators[0],
            value: this.questionsListForConditionForEnd[index].options[0]
          }

          this.responseConditionsListForEnd[this.conditionToUpdateIndexForEnd] = { ...cUpdated }
          cUpdated.question = this.questionsListForConditionForEnd[index].id
          cUpdated.operator = this.operators[0].value
          cUpdated.value = this.questionsListForConditionForEnd[index].options[0].value || this.questionsListForConditionForEnd[index].options[0].rating || this.questionsListForConditionForEnd[index].options[0].label

          if (this.selectedDisplayRuleForEnd.value === 'showIfAll') {
            this.currentEndMessage.showIfAll[this.conditionToUpdateIndexForEnd] = cUpdated
          }
          if (this.selectedDisplayRuleForEnd.value === 'showIfAny') {
            this.currentEndMessage.showIfAny[this.conditionToUpdateIndexForEnd] = cUpdated
          }

          this.updateCurrentEndMessage()
        }
      }

      this.showModal = false
    },
    showAddConditionFieldsForResponse (isEndMessage) {
      if (isEndMessage) {
        if (this.questionsListForConditionForEnd.length === 0) this.createQuestionListConditionForEnd()
        this.addingConditionForEnd = true
        this.newConditionForEnd = {
          question: this.questionsListForConditionForEnd[0],
          operator: this.operators[0],
          value: this.questionsListForConditionForEnd[0].options[0]
        }
      } else {
        this.addingCondition = true
        this.newCondition = {
          question: this.questionsListForCondition[0],
          operator: this.operators[0],
          value: this.questionsListForCondition[0].options[0]
        }
      }
    },
    setConditionsInitialValues () {
      /*
        IMPORTANTTE: Setar o valor default é necessário.
        Como estamos trabalhando com store, quando a questionSelected
        é trocada, as variáveis que usamos para regras de exibição devem
        ser resetadas. Se apagar, gerará bug.
      */
      this.isFirstQuestion = false
      this.thereIsMetadata = false
      this.metadataCondition = ''
      this.showAddConditionFields = false
      this.showingStartOrAnsweredMessage = false

      // Se for a primeira question da survey, condições POR RESPOSTA não aparecem para ela.
      if (this.question.id === this.questions[0].id) {
        this.isFirstQuestion = true
      }

      this.selectedMetadada = this.metadataGroup[0]
      this.selectedOperator = this.operators[0]

      if (this.question.ifMetadata.length > 0) {
        if (this.question.ifMetadata[0].value !== '') {
          this.thereIsMetadata = true
          this.metadataCondition = this.question.ifMetadata[0].value
          const op = this.operators.find(item => item.value === this.question.ifMetadata[0].operator)
          if (op) {
            this.selectedOperator = op
          }
          let metadata = this.metadataGroup.find(item => item.key === this.question.ifMetadata[0].label)
          if (metadata) {
            this.selectedMetadada = metadata
          } else {
            /**
              esse else é na verdade uma correção de um bug onde estava sendo salvo o label do metadado na condição, no lugar do campo key que seria o certo
              isso quebrava o fluxo condicional da pesquisa
              então quando carregar uma pesquisa com esse bug, procuramos pelo label caso n seja encontrada a key do metadado
              se encontrado o label substituimos o valor antigo (label) por esse (key)
              Pode remover esse else daqui 1 ano, beijos <3
            */
            metadata = this.metadataGroup.find(item => item.label === this.question.ifMetadata[0].label)
            if (metadata) {
              this.selectedMetadada = metadata
              this.question.ifMetadata[0].label = metadata.key
            }
          }
        }
      }
    },
    changeOperator (isEndMessage) {
      if (isEndMessage) {
        if (!this.thereIsMetadataForEnd) {
          const condition = {
            label: this.selectedMetadataForEnd.key,
            operator: this.selectedOperatorForEnd.value,
            value: this.metadataConditionForEnd
          }
          this.currentEndMessage.ifMetadata.push(condition)
        } else {
          this.currentEndMessage.ifMetadata[0].operator = this.selectedOperatorForEnd.value
        }
        this.updateCurrentEndMessage()
      } else {
        // Caso eu esteja adicionando uma condição a uma question que tinha nenhuma ainda.
        if (!this.thereIsMetadata) {
          this.question.ifMetadata = [{
            label: this.selectedMetadada.key,
            operator: this.selectedOperator.value,
            value: this.metadataCondition
          }]
        } else {
          this.question.ifMetadata[0].operator = this.selectedOperator.value
        }
        // Para garantir que só dê update na question quando o value da condition já estiver preenchido.
        this.updateCurrentQuestion(null, true)
      }
    },
    changeMetadata (isEndMessage) {
      // Se a condição for para uma endMessage
      if (isEndMessage) {
        if (!this.thereIsMetadataForEnd) {
          const condition = {
            label: this.selectedMetadataForEnd.key,
            operator: this.selectedOperatorForEnd.value,
            value: this.metadataConditionForEnd
          }
          this.currentEndMessage.ifMetadata[0] = condition
        } else {
          this.currentEndMessage.ifMetadata[0].label = this.selectedMetadataForEnd.key
        }
        this.updateCurrentEndMessage()
      } else {
        // Caso eu esteja adicionando uma condição a uma question que tinha nenhuma ainda.
        if (!this.thereIsMetadata) {
          const condition = {
            label: this.selectedMetadataForEnd.key,
            operator: this.selectedOperatorForEnd.value,
            value: this.metadataConditionForEnd
          }
          this.question.ifMetadata.push(condition)
        } else {
          this.question.ifMetadata[0].label = this.selectedMetadada.key
        }
        // Para garantir que só dê update na question quando o value da condition já estiver preenchido.
        this.updateCurrentQuestion(null, true)
      }
    },
    changeConditionValue (isEndMessage) {
      if (isEndMessage) {
        if (!this.thereIsMetadataForEnd) {
          this.currentEndMessage.ifMetadata = [{
            label: this.selectedMetadataForEnd.key,
            operator: this.selectedOperatorForEnd.value,
            value: this.metadataConditionForEnd
          }]
        } else {
          this.currentEndMessage.ifMetadata[0].value = this.metadataConditionForEnd
        }
        if (this.metadataConditionForEnd) {
          this.updateCurrentEndMessage()
        }
      } else {
        // Caso eu esteja adicionando uma condição a uma question que tinha nenhuma ainda.
        if (!this.thereIsMetadata) {
          this.question.ifMetadata = [{
            label: this.selectedMetadada.key,
            operator: this.selectedOperator.value,
            value: this.metadataCondition
          }]
        } else {
          this.question.ifMetadata[0].value = this.metadataCondition
        }
        if (this.metadataCondition) {
          this.updateCurrentQuestion()
        }
      }
    },
    clearConditionForMetadata (isEndMessage) {
      if (isEndMessage) {
        this.currentEndMessage.ifMetadata = []
        this.thereIsMetadataForEnd = false
        this.metadataConditionForEnd = ''
        this.conditionFieldsForEnd = false
        this.selectedMetadataForEnd = ''
        this.selectedOperatorForEnd = ''
        this.updateCurrentEndMessage()
      } else {
        this.question.ifMetadata = []
        this.updateCurrentQuestion()
        this.showAddConditionFields = false
        this.setConditionsInitialValues()
      }
    },
    addConditionForMetadata (isEndMessage) {
      if (isEndMessage) {
        this.conditionFieldsForEnd = true
      } else {
        this.showAddConditionFields = true
      }
    },
    createQuestionsListForCondition () {
      this.questionsListForCondition = []

      const forbiddenTypes = ['matrix', 'text', 'referral']
      const currentQuestion = this.question
      let indexLimit = this.questions.length - 1
      let isTheCurrentQuestion = false

      if (!this.isFirstQuestion) {
        const questions = [...new Set(this.questions)]
        questions.map((q, index) => {
          const question = Object.assign({}, q)
          const isForbidden = forbiddenTypes.includes(question.type)
          const alreadyHave = this.questionsListForCondition.includes(question)

          if (question.id === currentQuestion.id) {
            isTheCurrentQuestion = true
            indexLimit = index
          }

          if (!isForbidden && !alreadyHave && !isTheCurrentQuestion && index < indexLimit) {
            const questionOptions = [...new Set(question.options)]
            const options = [...new Set(responseOptions.getOptions(question, false, true))]
            question.options = options.length > 0 ? options : questionOptions
            if (question.options.length > 0 && question.type === 'enum' && ['multipleOther', 'singleOther'].indexOf(question.ui) > -1) {
              if (question.options[question.options.length - 1].label !== question.otherLabel) {
                question.options.push({ label: question.otherLabel })
              }
            }
            this.questionsListForCondition.push(question)
          }
        })

        // New condition starts with this default values.
        if (this.questionsListForCondition.length > 0) {
          this.newCondition = {
            question: this.questionsListForCondition[0],
            operator: this.operators[0],
            value: this.questionsListForCondition[0].options[0]
          }
        }
      }
    },
    createQuestionListConditionForEnd () {
      this.questionsListForConditionForEnd = []
      const questions = [...new Set(this.questions)]
      const forbiddenTypes = ['matrix', 'text', 'referral']
      questions.map((q, index) => {
        const question = Object.assign({}, q)
        const isForbidden = forbiddenTypes.includes(question.type)
        const alreadyHave = this.questionsListForConditionForEnd.includes(question)

        if (!isForbidden && !alreadyHave) {
          const questionOptions = [...new Set(question.options)]
          const options = [...new Set(responseOptions.getOptions(question, false, true))]
          if (options.length > 0 && question.type === 'enum' && ['multipleOther', 'singleOther'].indexOf(question.ui) > -1) {
            if (options[options.length - 1].label !== question.otherLabel) {
              options.push({ label: question.otherLabel })
            }
          }
          question.options = options.length > 0 ? options : questionOptions
          this.questionsListForConditionForEnd.push(question)
        }
      })

      // New condition starts with this default values.
      if (this.questionsListForConditionForEnd.length > 0) {
        this.newConditionForEnd = {
          question: this.questionsListForConditionForEnd[0],
          operator: this.operators[0],
          value: this.questionsListForConditionForEnd[0].options[0]
        }
      }
    },
    mountResponseConditionsListForEnd () {
      this.responseConditionsListForEnd = []
      this.selectedDisplayRuleForEnd = this.displayRules[1]
      let conditionsToMap

      if (this.currentEndMessage.showIfAll.length > 0) {
        this.thereIsResponseConditionForEnd = true
        this.selectedDisplayRuleForEnd = this.displayRules[0]
        conditionsToMap = this.currentEndMessage.showIfAll
      }

      if (this.currentEndMessage.showIfAny.length > 0) {
        this.thereIsResponseConditionForEnd = true
        this.selectedDisplayRuleForEnd = this.displayRules[1]
        conditionsToMap = this.currentEndMessage.showIfAny
      }

      if (conditionsToMap) {
        conditionsToMap.map(condition => {
          const conditionToPush = {}

          // Pega a QUESTION da condição.
          const question = this.questionsListForConditionForEnd.find(item => item.id === condition.question || item._id === condition.question)
          if (question) {
            conditionToPush.question = question
          }

          // Pega o OPERATOR da condição.
          const operator = this.operators.find(item => item.value === condition.operator)
          if (operator) {
            conditionToPush.operator = operator
          }

          // Pega o valor (a opção de resposta) da condição.
          const value = question.options.find(item => item.value === condition.value || item.rating === condition.value || item.label === condition.value)
          if (value) {
            conditionToPush.value = value
          }
          this.responseConditionsListForEnd.push(conditionToPush)
        })
      }
    },
    showModalForUpdating (conditionIndex, isEndMessage) {
      this.showModal = true
      if (isEndMessage) {
        this.updatingConditionForEnd = true
        this.conditionToUpdateIndexForEnd = conditionIndex
      } else {
        this.updatingCondition = true
        this.conditionToUpdateIndex = conditionIndex
      }
    },
    mountResponseConditionsList () {
      this.responseConditionsList = []
      this.selectedDisplayRule = this.displayRules[1]
      let conditionsToMap

      if (this.question && this.question.showIfAll.length > 0) {
        this.thereIsConditionForResponse = true
        this.selectedDisplayRule = this.displayRules[0]
        conditionsToMap = this.question.showIfAll
      }

      if (this.question && this.question.showIfAny.length > 0) {
        this.thereIsConditionForResponse = true
        this.selectedDisplayRule = this.displayRules[1]
        conditionsToMap = this.question.showIfAny
      }

      if (conditionsToMap) {
        conditionsToMap.map(condition => {
          const conditionToPush = {}

          // Pega a QUESTION da condição.
          const question = this.questionsListForCondition.find(item => item.id === condition.question)
          if (question) {
            conditionToPush.question = question
          }

          // Pega o OPERATOR da condição.
          const operator = this.operators.find(item => item.value === condition.operator)
          if (operator) {
            conditionToPush.operator = operator
          }

          // Pega o valor (a opção de resposta) da condição.
          const value = question.options.find(item => item.value === condition.value || item.rating === condition.value || item.label === condition.value)
          if (value) {
            conditionToPush.value = value
          }
          this.responseConditionsList.push(conditionToPush)
        })
      }
    },
    updateConditionForResponse (condition, index, isEndMessage) {
      const conditionToSave = {
        question: condition.question.id,
        operator: condition.operator.value,
        value: condition.value.value || condition.value.rating || condition.value.label
      }

      if (isEndMessage) {
        if (this.selectedDisplayRuleForEnd.value === 'showIfAll') {
          this.currentEndMessage.showIfAll[index] = conditionToSave
        }

        if (this.selectedDisplayRuleForEnd.value === 'showIfAny') {
          this.currentEndMessage.showIfAny[index] = conditionToSave
        }

        this.updateCurrentEndMessage()
      } else {
        if (this.selectedDisplayRule.value === 'showIfAll') {
          this.question.showIfAll[index] = conditionToSave
        }

        if (this.selectedDisplayRule.value === 'showIfAny') {
          this.question.showIfAny[index] = conditionToSave
        }

        this.updateCurrentQuestion()
      }
    },
    updateCurrentQuestion (title, metadata) {
      if (title) {
        if (this.titleOnReports) {
          const label = this.question.label
          this.question.title = label
        }
        this.survey.questions[this.survey.questionSelected].customTitle = !this.titleOnReports
      }
      const updatedValue = this.survey.questions[this.survey.questionSelected]
      if (metadata) {
        if (updatedValue.ifMetadata[0] && updatedValue.ifMetadata[0].value) {
          this.$store.commit('survey/upsertQuestion', updatedValue)
          this.$forceUpdate()
        }
      } else {
        this.$store.commit('survey/upsertQuestion', updatedValue)
        this.$forceUpdate()
      }

      if (this.question.limitAnswer) {
        if (this.question.minLength > this.question.maxLength) {
          this.question.maxLength = this.question.minLength
        }
      }
    },
    updateCurrentEndMessage () {
      this.survey.endMessages[this.endMessageIndex] = this.currentEndMessage
      this.$store.commit('survey/survey', this.survey)
      this.$forceUpdate()
      this.$root.$emit('details-updated')
    }
  }
}
</script>
<style lang="scss">
.container-no-condition {
  // background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  .label-no-condition {
    color: #808080;
    font-size: 0.75vw;
    text-align: center;
  }
}
.pretty-border-accent {
  border-radius: 5px;
  margin: 10px 0px;
  padding: 10px;
  .flexThis {
    margin: 0 !important;
    .select-animation {
      width: 100%;
      display: flex;
      justify-content: center;
      .v-select {
        padding: 0;
      }
    }
  }
}
.toggle-with-flex-d {
  display: flex;
}
.side-settings {
  max-height: calc(100vh + 220px);
  min-height: 100% !important;
  background-color: #fff;
  box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
  overflow: auto !important;
  .ui-switch-track {
    background: #d5d5d5 !important;
  }
  .binds-button.primary.floating {
    box-shadow: inset 0 0 0 2px var(--accent-color) !important;
  }
  .binds-button.primary {
    background: var(--accent-color) !important;;
  }
}
.survey-config-heading {
  & .heading {
    font-size: 18px;
    padding: 15px !important;
  }
}
.expander-title {
  padding-left: 0px !important;
  .expander {
    h5 {
      font-size: 14px;
    }
    .arrow {
      margin-left: 6px;
      margin-top: 3.5px;
      width: 6px;
      height: 6px;
    }
  }
  & > div {
    padding-left: 0px;
    padding-top: 0px;
    margin-left: -3px;
  }
}
.second-act {
  padding-top: 15px;
  & h6 {
    font-size: 13.5px !important;
    margin-left: 50px !important;
    margin-top: 3px !important;
  }
}
.config-headings {
  padding-left: 5px !important;
  h6 {
    width: 135px !important;
  }
  .text-field__input {
    & input {
      font-size: 13.5px !important;
    }
  }
}
.characters {
  & div > h6 {
    font-size: 13.5px !important;
  }
  & div > label > .text-field__input {
    & input {
      font-size: 13.5px !important;
    }
  }
}
.d-flex {
  display: flex !important;
}
.inputext-settings {
  & h6 {
    margin-left: 45px !important;
    font-size: 13.5px !important
  }
}
.field-instruction {
  & h6 {
    margin-left: 0px !important;
  }
  .text-field__input {
    & input {
      font-size: 13.5px !important;
    }
  }
}
.question-language-select {
  & div {
    height: 40px !important;
  }
  & .vs__selected-options {
    min-width: 145px;
    font-size: 14px !important;
  }
}
.question-language-header h6 {
  margin: 0 !important;
}
.messages-expander {
  margin-top: 25px !important;
  margin-left: 2px !important;
  padding: 0px !important;
  & h6 {
    font-size: 13.5px !important;
    margin-left: 50px !important;
    padding-top: 3px !important;
  }
  & h4 {
    margin-left: 0px !important;
    margin-top: 5px !important;
    margin-bottom: 25px !important;
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .flexThis {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .content-animation {
    margin-top: 10px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      filter: grayscale(1);
    }
  }

  .content-block {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    .vs__actions {
      color: var(--primary-color) !important;
      .vs__clear {
        display: none;
      }
    }
    .option-one {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 50%;
      width: 100%;
      input[type=number] {
        height: 30px;
        font-size: 16px;
        padding-left: 10px;
        width: 30%;
        outline: none;
        border: none;
        border-bottom: 1px solid #cacaca;
      }
      .vs__dropdown-toggle {
        width: 140px;
      }
    }
    .option-two {
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-items: left;
      h6 {
        margin: 5px 0 0 0 !important;
      }
      .v-select {
        padding: 0;
        margin-top: 20px;
        .vs__dropdown-toggle {
          min-width: 215px;
        }
      }
    }
  }
}
.start-messages-options {
  div {
    margin-bottom: 35px;
  }
}
.clear-button {
  padding: 0 !important;
  button > span {
    font-size: 20px !important;
  }
}
.rules {
  h6 {
    margin-left: -5px !important;
    font-size: 14px !important;
  }
}
.condition-content {
  margin-left: 0px !important;
  margin-top: 15px;
  & h4 {
    margin-left: 0px !important;
    margin-top: 5px !important;
    margin-bottom: 15px !important;
    font-size: 14px !important;
    font-weight: normal !important;
  }
  & h6 {
    font-size: 13.5px !important;
    margin-left: 5px !important;
    margin-top: 6px !important;
  }
}
.condition-value {
  & .text-field__input {
    border: 1px solid gainsboro !important;
    border-radius: 5px !important;
    height: 35px !important;
    & input {
      padding: 0px 0px 0px 9px !important;
    }
  }
}
.check-icon:hover {
  color: green;
}
.add-condition {
  margin-top: 10px !important;
  & .binds-button.small.floating {
    width: 27px !important;
    height: 27px !important;
  }
}
.add-condition-response {
  margin-top: 10px !important;
  & .binds-button.small.floating {
    width: 27px !important;
    height: 27px !important;
  }
}
.confirm-button {
  padding: 0 !important;
  & .binds-button.medium {
    padding: 0px !important;
  }
  button > span {
    font-size: 20px !important;
  }
}
.divider {
  margin-top: 10px;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}
.act-save-template {
  margin: 20px 0;
  & h6 {
    font-size: 13.5px !important;
    margin-left: 50px !important;
    margin-top: 3px !important;
  }
}
.template-settings {
  & h6 {
    font-size: 13.5px !important;
  }
  .text-field__input {
    & input {
      font-size: 13.5px !important;
    }
  }
}
.mt45 {
  margin-top: 45px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.link-label {
  margin-left: 5px;
  .text-field__input {
    & input {
      font-size: 13.5px !important;
    }
  }
  h6 {
    font-size: 12px !important;
    font-weight: normal !important;
    margin-left: 0px !important;
    margin-top: 30px !important;
  }
}
.card-condition {
  background: #fff;
  display: contents;
  min-height: 150px;
  height: fit-content;
}
.card-response {
  margin-bottom: 10px;
  height: 155px;
}
.selects-condition {
  margin-top: -10px !important;
  &  .vs--open {
    &  .vs__selected  {
      position: relative !important;
    }
  }
  & .vs__selected-options  {
      display: contents;
    & span {
      font-size: 14px !important;
      overflow: hidden !important;
      width: 100% !important;
      height: 20px !important;
    }
  }
}
.selects-top {
  margin-top: 20px !important;
}
.rule-select {
  div.vs__dropdown-toggle {
    width: fit-content;
    margin-left: 1rem;
  }
  .vs__selected-options > input {
    display: contents !important;
  }
  & .vs__selected-options > span {
    font-size: 13px !important;
    overflow: hidden !important;
    width: 230px !important;
    height: 20px !important;
  }
  padding: 10px 0 20px 0px !important;
}
.fix-select {
  .vs__selected-options > input {
    display: contents !important;
  }
}
.btn-select-question {
  height: 31px !important;
  font-size: 13.5px !important;
  text-align: left !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap;
  border-radius: 4px;
  width: 8rem;
  margin-right: 5px;
  padding-left: 7px;
  color: #080808;
  font-family: 'Avenir', Helvetica, Arial, sans-serif !important;
  border: 1px solid rgb(161, 161, 161);
  font-weight: 500px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.modal-conditions {
  & .modal__wrapper {
    width: 680px !important;
    max-height: 450px !important;
    overflow-y: auto;
  }
}
.nav-card-conditions {
  cursor: pointer;
  width: 185px;
  height: 184px;
  padding: 1rem;
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 5px 7px 0 rgba(48,48,48,.16), 0 5px 4px 0 rgba(0,0,0,.01);
  -webkit-box-sizing: border-box;
  -webkit-box-shadow: 0 5px 7px 0 rgba(48,48,48,.16), 0 5px 4px 0 rgba(0,0,0,.01);
}
.answered-redirect{
  margin-top: 20px;
  margin-bottom: 20px;
  h6 {
    margin-left: 0;
  }
  .time-redirect{
    display: flex;
    align-items: baseline;
    .text-field__input{
      width: 38px;
      margin-left: 35px;
      input{text-align: center;}
    }
  }
}
.error-url-redirect {
  margin-top: 7px;
  .heading{
    color: $color-pink !important
  }
}
.headings-nes {
  padding-left: 5px !important;
  display: block !important;
  h6 {
    width: 135px !important;
  }
  .text-field__input {
    & input {
      font-size: 13.5px !important;
    }
  }
}

.sidebar-referral-options input,
.sidebar-referral-select .input-wrapper .dropdown{
  font-size:15px !important;
}
.selects-condition-meta .vs__dropdown-toggle {
  height: 30px;
}
.condition-border .vs__dropdown-toggle {
  border: 1px solid #a1a1a1;
}
.selects-condition-meta .vs__selected-options > span {
  height: auto !important;
  white-space: nowrap;
}
.selects-condition-response .vs__selected-options > span {
  width: 100% !important;
  white-space: nowrap;
}
.selects-condition .btn-select-question {
  box-shadow: none;
}
.flexThis {
  display: flex;
}
// Classes para preview de componentes para condições - START
.parent-new-condition {
  display: flex;
  align-items: center;
  width: 100%;
  padding: .75rem 1rem;
  .child-new-condition {
    flex: 1;
    > h6.heading.dark {
      margin-bottom: 10px !important;
    }
    > button {
      margin-bottom: 2px;
    }
  }
}

.transform-ui-c {
  width: 100% !important;
  pointer-events:none;
}
.t-5emo-c {
  transform: scale(0.5);
  -webkit-transform: scale(0.5);
  margin-top: -62px !important;
  margin-left: -10px;
}
.t-10num-c {
  width: 400% !important;
  transform: scale(0.3);
  -webkit-transform: scale(0.3);
  margin-left: -217px !important;
  margin-top: -55% !important;
  margin-bottom: -30% !important;
}
.t-5num-c {
  min-width: 310px;
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin-left: -57px !important;
  padding-top: 20px;
}
.t-stars-c {
  min-width: 310px;
  transform: scale(0.55);
  -webkit-transform: scale(0.55);
  margin-left: -67px !important;
  padding-top: 20px;
}
.t-5nes-c {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin: 20px 0 0 -113px;
  min-width: 583px ;
}
.t-0to5-c {
  transform: scale(0.55);
  -webkit-transform: scale(0.55);
  margin: 20px 0 0 -135px;
  min-width: 583px ;
}
.t-1to5-c {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin: 25px 0 0 -113px;
  min-width: 583px ;
}
.t-1to7-c {
  transform: scale(0.45);
  -webkit-transform: scale(0.45);
  margin: 20px 0 0 -160px;
  min-width: 583px;
}
.t-1to7button-c {
  transform: scale(0.5) !important;
  -webkit-transform: scale(0.5) !important;
  margin-left: -5% !important;
  margin-top: -100px;
  width: 110% !important;
}
.t-5radio-c {
  transform: scale(0.55);
  min-width: 235px;
  margin: -45px 0 0 -40px;
}
.t-matrix1to5-c {
  transform: scale(0.4);
  -webkit-transform: scale(0.4);
  margin-left: -225px;
  min-width: 602px;
  margin-top: -110px;
}
.t-matrix10num-c {
  margin-left: -247px;
  transform: scale(0.45);
  -webkit-transform: scale(0.45);
  min-width: 672px;
  margin-top: -110px;
}
.t-nps-c,
.t-0to10-c {
  width: 661px !important;
  transform: scale(0.3);
  -webkit-transform: scale(0.3);
  margin-left: -237px;
  min-width: 583px !important;
  padding-top: 20px;
}
.t-1to10-c {
  min-width: 610px;
  transform: scale(0.35);
  -webkit-transform: scale(0.35);
  margin-left: -210px;
  margin-top: 10%;
}
.t-yn-c {
  min-width: 310px;
  transform: scale(0.45);
  -webkit-transform: scale(0.45);
  margin-left: -65px;
  margin-top: 0px;
}
.t-ynd-c, .t-ynm-c {
  min-width: 310px;
  transform: scale(0.5) !important;
  -webkit-transform: scale(0.5) !important;
  margin-left: -50% !important;
  margin-top: -24% !important;
}
.t-text-c > .input-text {
  transform: scale(0.7) !important;
  -webkit-transform: scale(0.7) !important;
  min-width: 225px !important;
  margin-left: -33px !important;
  margin-top: 42px !important;
}
.t-text-c > .comment {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin-top: -57%;
  min-width: 310px;
  margin-left: -70px;
}
.t-inputext-c {
  transform: scale(0.4);
  -webkit-transform: scale(0.4);
  margin-left: -141px !important;
  margin-top: -3% !important;
  margin-bottom: -32% !important;
}
.t-referral-c {
  transform: scale(0.65);
  -webkit-transform: scale(0.65);
  margin-top: -34%;
  margin-bottom: -30%;
  min-width: 375px;
  margin-left: -110px;
}
.t-referraldynamic-c {
  transform: scale(0.85);
  -webkit-transform: scale(0.85);
  min-width: 300px;
  margin-left: -65px;
}
.t-single-c {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin: -35% 0 -20% -35%;
  width: 230% !important;
  height: 190px !important;
  h6 {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.t-single-others-c {
  transform: scale(0.55);
  -webkit-transform: scale(0.55);
  margin-top: -43% !important;
  margin-bottom: -30% !important;
  width: 200% !important;
  margin-left: -50%;
  height: 180px;
  .nav-input-enum {
    padding-left: 0 !important;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    li {
      display: flex;
      align-items: center;
      justify-content: left;
      width: fit-content;
      .op {
        margin: 0 !important;
        display: flex;
        justify-content: flex-start;
        .subject-options {
          min-height: 30px;
          max-height: 70px;
          overflow-y: auto;
          max-width: 60%;
        }
      }
    }
    .other {
      padding-left: 0px !important;
    }
  }
}
.t-multiple-c {
  width: 210% !important;
  height: 220px !important;
  transform: scale(0.63);
  -webkit-transform: scale(0.63);
  margin: -28% 0 15% -20% !important;
  h6 {
    max-width: 200px;
    text-overflow: ellipsis;
    padding-top: 23px !important;
    white-space: nowrap;
    overflow: hidden;
  }
}
.labelButton {
  width: 300px;
}
// Classes para preview de componentes para condições - END
</style>
