import i18n from '../translations/i18n'

// Reusable colors
const colors = {
  red: '#EA484D',
  yellow: '#F8C43D',
  green: '#57AD26',
  pink: '#C53966' // Example color
}

// Define a function to create rating objects
function createRating (label, abbreviatedLabel, color, ratingToQuery = '') {
  return {
    icon: '',
    label: i18n.t(label),
    abbreviatedLabel: i18n.t(abbreviatedLabel),
    color,
    ratingToQuery
  }
}

// Define rating objects for different categories
const ratings = {
  nps10num: {
    dt: createRating('dashboard.graphics.detractors', 'ranking.abbreviation.detractor', colors.red),
    nt: createRating('dashboard.graphics.neutral', 'ranking.abbreviation.neutral', colors.yellow),
    pr: createRating('dashboard.graphics.promoters', 'ranking.abbreviation.promoter', colors.green)
  },
  npsYnm: {
    pr: createRating('dashboard.graphics.op1', 'ranking.abbreviation.yes', colors.green),
    nt: createRating('dashboard.graphics.op2', 'ranking.abbreviation.no', colors.red),
    dt: createRating('dashboard.graphics.op4', 'ranking.abbreviation.maybe', colors.yellow)
  },
  csatGeneral: {
    insat: createRating('dashboard.graphics.un', 'ranking.abbreviation.insatisfied', colors.red),
    sat: createRating('dashboard.graphics.sat', 'ranking.abbreviation.satisfied', colors.green)
  },
  nesGeneral: {
    me: createRating('dashboard.graphics.me', 'ranking.abbreviation.mutch_effort', colors.red),
    ee: createRating('dashboard.graphics.ee', 'ranking.abbreviation.expected_effort', colors.yellow),
    le: createRating('dashboard.graphics.le', 'ranking.abbreviation.less_effort', colors.green)
  },
  kpiYnd: {
    y: createRating('dashboard.graphics.op1', 'ranking.abbreviation.yes', colors.green),
    n: createRating('dashboard.graphics.op2', 'ranking.abbreviation.no', colors.red),
    d: createRating('dashboard.graphics.op3', 'ranking.abbreviation.don_t_know', colors.yellow)
  },
  kpiYn: {
    y: createRating('dashboard.graphics.op1', 'ranking.abbreviation.yes', colors.green),
    n: createRating('dashboard.graphics.op2', 'ranking.abbreviation.no', colors.red)
  },
  kpi5emo: {
    ex: createRating('dashboard.graphics.ex', 'ranking.abbreviation.excellent', colors.green),
    vg: createRating('dashboard.graphics.vg', 'ranking.abbreviation.very_good', colors.green),
    satisf: createRating('dashboard.graphics.satisf', 'ranking.abbreviation.satisfied', colors.green),
    bd: createRating('dashboard.graphics.bd', 'ranking.abbreviation.bad', colors.red),
    vb: createRating('dashboard.graphics.vb', 'ranking.abbreviation.very_bad', colors.red)
  },
  cesGeneral: {
    me: createRating('dashboard.graphics.me', 'ranking.abbreviation.mutch_effort', colors.red),
    ee: createRating('dashboard.graphics.ee', 'ranking.abbreviation.expected_effort', colors.yellow),
    le: createRating('dashboard.graphics.le', 'ranking.abbreviation.less_effort', colors.green)
  },
  nvsGeneral: {
    sb: createRating('dashboard.graphics.nvs_op1', 'ranking.abbreviation.significantly_better', colors.green),
    lb: createRating('dashboard.graphics.nvs_op2', 'ranking.abbreviation.a_little_better', colors.green),
    nb: createRating('dashboard.graphics.nvs_op3', 'ranking.abbreviation.neither_better_nor_worse', colors.green),
    lw: createRating('dashboard.graphics.nvs_op4', 'ranking.abbreviation.a_little_worse', colors.yellow),
    sw: createRating('dashboard.graphics.nvs_op5', 'ranking.abbreviation.significantly_worse', colors.red)
  },
  health: {
    vl: createRating('health_score.churn.churn_very_low', 'health_score.abbreviated.very_low', colors.red),
    lw: createRating('health_score.churn.churn_low', 'health_score.abbreviated.low', colors.red),
    md: createRating('health_score.churn.churn_mid', 'health_score.abbreviated.mid', colors.yellow),
    hh: createRating('health_score.churn.churn_high', 'health_score.abbreviated.high', colors.green),
    vh: createRating('health_score.churn.churn_very_high', 'health_score.abbreviated.very_high', colors.green)
  }
}

export default {
  nvs: {
    '5radio': ratings.nvsGeneral
  },
  csat: {
    '5emo': ratings.csatGeneral,
    '10num': ratings.csatGeneral,
    '5num': ratings.csatGeneral
  },
  nps: {
    '10num': ratings.nps10num,
    ynm: ratings.npsYnm
  },
  kpi: {
    ynd: ratings.kpiYnd,
    yn: ratings.kpiYn,
    '5emo': ratings.kpi5emo
  },
  nes: {
    '10num': ratings.nesGeneral
  },
  ces: {
    '1to7': ratings.cesGeneral,
    '1to7button': ratings.cesGeneral
  },
  health: {
    health: ratings.health
  }
}
