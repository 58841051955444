<template>
  <div class="send-by-csv">
    <new-metadata-modal
      v-if="showNewMetadataModal"
      @close-new-metadata="showNewMetadataModal = false"
    ></new-metadata-modal>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="box">
          <div class="header-contacts">
            <div>
              <div
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                style="text-align: center"
              >
                <app-heading level="h6"
                  ><p>{{ $t("seeds.spreadsheet_import") }}</p></app-heading
                >
                <app-heading level="h4"
                  >{{ $t("seeds.step") }} {{ step.number }} :
                  {{ step.title[step.number - 1] }}</app-heading
                >
              </div>
              <div class="row">
                <div
                  class="col-xs-1 col-sm-1 col-md-1 col-lg-1"
                  style="margin-top: 10px; text-align: start"
                >
                  <div>
                    <app-icon
                      class="b-icon checkbox-fill xs"
                      style="
                        color: var(--accent-color) !important;
                        font-size: 24px !important;
                      "
                    ></app-icon>
                  </div>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <p
                    :class="{
                      progress: step.number > 1,
                      defaultProgress: step.number === 1,
                    }"
                    style="
                      height: 2px;
                      width: 144%;
                      margin-top: 20px;
                      margin-left: -14%;
                      display: flex;
                    "
                  ></p>
                  <p
                    :class="{
                      removeProgress: step.number != 1,
                      insertDefault: step.number === 1,
                    }"
                    style="
                      height: 2px;
                      width: 144%;
                      margin-top: -2px;
                      margin-left: -14%;
                      display: flex;
                    "
                  ></p>
                </div>
                <div
                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2"
                  style="margin-top: 10px; text-align: center"
                >
                  <div>
                    <app-icon
                      :class="{
                        activeColor: step.number >= 2,
                        inactiveColor: step.number < 2,
                      }"
                      class="b-icon checkbox-fill xs"
                      style="color: #eff3f8; font-size: 24px !important"
                    ></app-icon>
                  </div>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <p
                    :class="{
                      progress: step.number > 2,
                      defaultProgress: step.number <= 2,
                    }"
                    style="
                      height: 2px;
                      width: 144%;
                      margin-top: 20px;
                      display: flex;
                      margin-left: -29%;
                    "
                  ></p>
                  <p
                    :class="{
                      removeProgress: step.number === 3,
                      insertDefault: step.number < 3,
                    }"
                    style="
                      height: 2px;
                      width: 144%;
                      display: flex;
                      margin-left: -29%;
                      margin-top: -2px;
                    "
                  ></p>
                </div>
                <div
                  class="col-xs-1 col-sm-1 col-md-1 col-lg-1"
                  style="margin-top: 10px; text-align: end"
                >
                  <div>
                    <app-icon
                      :class="{
                        activeColor: step.number >= 3,
                        inactiveColor: step.number < 3,
                      }"
                      class="b-icon checkbox-fill xs"
                      style="font-size: 24px !important"
                    ></app-icon>
                  </div>
                </div>
              </div>
              <!-- modelo -->
              <div
                v-show="step.number === 1"
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              >
                <p>{{ $t("seeds.inform_1") }}</p>
                <br />
                <app-button class="btn-outline-primary button-csv-import">
                  <a href="csv/file-sample.csv" download class="csvMenu">
                    <div class="makeItClick"></div>
                    <p>{{ $t("seeds.download_model") }}</p>
                  </a>
                </app-button>
              </div>
              <!-- importar -->
              <div
                v-show="step.number === 2"
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              >
                <p>{{ $t("seeds.inform_2") }}</p>
                <br />
                <app-button
                  style="color: #9a838a !important"
                  class="btn-outline-primary button-csv-import"
                >
                  {{ $t("seeds.import_file") }}
                  <input
                    class="inputfile"
                    ref="csv"
                    style="cursor: default"
                    @change.prevent="validFileAndLoadPreview"
                    type="file"
                    multiple="false"
                    name="csv"
                  />
                </app-button>
                <div
                  v-if="showTotalOfLines"
                  class="col-xs-9 col-sm-9 col-md-9 col-lg-9"
                >
                  <div class="vue-csv-uploader-part-one">
                    <app-heading level="h5">{{
                      $t("seeds.preview")
                    }}</app-heading>
                    <p>
                      {{ $t("seeds.count_lines") }} {{ showTotalOfLines - 1 }}
                    </p>
                    <p>{{ $refs.csv.files[0].name }}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="box">
                      <div class="spreadsheet-contacts" id="sendContacts">
                        <div
                          id="import-spreadsheet"
                          ref="import-spreadsheet"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- configurar -->
              <div
                v-show="step.number === 3"
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                style="position: relative"
              >
                <app-heading level="h5" v-if="sendType !== 'whatsapp'">{{
                  $t("seeds.schedule_title")
                }}</app-heading>
                <div class="col-md-6" id="schedule-survey-date-picker" v-if="sendType !== 'whatsapp'">
                  <div class="row">
                    <v-date-picker
                      :min-date="new Date()"
                      :locale="$i18n.locale"
                      :masks="{ inputDateTime24hr: 'D MMMM YYYY, H:mm' }"
                      v-model="yourFormat"
                      color="gray"
                      mode="dateTime"
                      is24hr
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="icon-container">
                          <i class="material-icons">event</i>
                          <input
                            v-on="inputEvents"
                            class="
                              px-2
                              py-1
                              border
                              rounded
                              focus:outline-none focus:border-blue-300
                            "
                            inputValue
                            readonly
                            :placeholder="yourFormat"
                            :value="inputValue"
                          />
                        </div>
                      </template>
                    </v-date-picker>
                  </div>
                </div>
                <app-heading level="h5">{{
                  $i18n.t("seeds.map_headers")
                }}</app-heading>
                <p>{{ $i18n.t("seeds.map_inform_1") }}</p>
                <div
                  class="row center-md"
                  style="background: #f4f4f4; border-radius: 5px"
                >
                  <div class="col-md-6" style="text-align: center">
                    <p class="p-csv">
                      {{ $i18n.t("seeds.your_file").toUpperCase() }}
                    </p>
                  </div>
                  <div class="col-md-6" style="text-align: center">
                    <p class="p-csv">
                      {{ $i18n.t("seeds.your_match").toUpperCase() }}
                    </p>
                  </div>
                </div>
                <div
                  class="col-md-12"
                  style="max-height: 100%; padding: 0px"
                >
                  <div
                    class="row"
                    v-for="(line, index) in headers[0]"
                    v-bind:key="index"
                    :style="{
                      display: line.trim().length <= 0 ? 'none' : 'flex',
                    }"
                  >
                    <div
                      class="col-md-6"
                      style="padding: 0px; border-right: 1px solid #f4f4f4"
                    >
                      <div class="p-container-csv">
                        <p class="p-csv" style="margin-bottom: 4px">
                          {{ line }}
                          <i
                            v-title="
                              $i18n.t('seeds.csv_special_character_info')
                            "
                            v-if="parsedHeaders.indexOf(index) > -1"
                            class="material-icons"
                            style="font-size: 1.3em; margin: 0 3px"
                            >help</i
                          >
                        </p>
                        <p v-if="headers.length > 1">{{ headers[1][index] }}</p>
                      </div>
                    </div>
                    <!-- mapear headers -->
                    <div class="col-md-6" style="padding: 0px">
                      <div class="input-wrapper">
                        <select
                          class="dropdown"
                          placeholder="better"
                          v-on:change="
                            checkMapping(index, selectedHeaders[index])
                          "
                          v-model="selectedHeaders[index]"
                        >
                          <option value="undefined">
                            <p v-if="!changePlaceholder">
                              {{ $i18n.t("seeds.select") }}
                            </p>
                            <p v-if="changePlaceholder">
                              {{ $i18n.t("seeds.ignore_column") }}
                            </p>
                          </option>
                          ""
                          <option
                            :class="{ hideOption: !!option.selected }"
                            v-for="(option, optionKey) in optionsMapping"
                            v-bind:key="optionKey"
                            :value="option.key"
                          >
                            {{ option.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row end-md">
                <div class="col-md-3" v-if="step.number > 1">
                  <app-button
                    style="font-size: 13px !important; width: 153px"
                    class="greyButtons button-csv-import"
                    v-on:click="previousStep()"
                  >
                    <i
                      class="material-icons"
                      style="position: absolute; left: 5px; top: 7px"
                      >navigate_before</i
                    >
                    {{ $t("seeds.previous_step") }}
                  </app-button>
                </div>
                <div
                  class="col-md-3"
                  v-if="
                    step.number === 1 ||
                    (step.number < 3 &&
                      showTotalOfLines > 1 &&
                      canShowNextButton)
                  "
                >
                  <app-button
                    style="font-size: 13px !important; width: 153px"
                    class="greyButtons button-csv-import"
                    v-on:click="nextStep()"
                  >
                    {{ $t("seeds.next_step") }}
                    <i
                      class="material-icons"
                      style="position: absolute; right: 5px; top: 7px"
                      >navigate_next</i
                    >
                  </app-button>
                </div>
                <div class="col-md-3" v-if="step.number === 3">
                  <app-button
                    :class="{
                      activeColorButton: checkIfHasHeader,
                      inactiveColorButton: !checkIfHasHeader,
                    }"
                    style="font-size: 13px !important; width: 153px"
                    v-on:click="sendSeeds()"
                  >
                    {{ $t("seeds.send") }}
                    <i class="material-icons" style="position: absolute; right: 5px; top: 7px">send</i>
                  </app-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-modal
      v-model="isAlreadyRunningUpload"
      :title="$t('sendhub.upload.riskTitle')"
      lightbox
      @primaryButtonClick="acceptedTheRisk = true; sendSeeds()"
      :primary-button="$t('sendhub.upload.riskButton')"
      class="modal-default-primary success_modal"
    >
      <p class="riskMessage"> {{$t('sendhub.upload.riskMessage')}} </p>
    </app-modal>
    <app-modal
      v-model="successSeeds"
      :title="$t('sendhub.sent_processing')"
      lightbox
      :primary-button="$t('sendhub.go_to_dashboard')"
      @primaryButtonClick="goDashboard()"
      class="modal-default-primary success_modal"
    >
    </app-modal>
    <!-- loading spinner -->
    <div class="loadingModal" v-show="isLoading">
      <rotate-square5></rotate-square5>
    </div>
    <theme-style>
      <!-- .button-csv-import { font-size: 13px !important; box-shadow: inset 0 0 0
      2px {{ user.account.theme.colors.accent || "#64469d" }} !important; }
      .import-csv-icon { color:
      {{ user.account.theme.colors.accent || "#64469d" }} !important; } -->
    </theme-style>
  </div>
</template>

<script>
import { uploadFile } from '@/_services'
import mimeTypes from 'mime-types'
import Papa from 'papaparse'
import { metadataManipulator } from '@/_helpers'
import NewMetadataModal from './NewMetadataModal.vue'
import ThemeStyle from '../../theme/ThemeStyle.vue'
import 'jspreadsheet-ce/dist/jspreadsheet.css'
import 'jsuites/dist/jsuites.css'
import { read, utils } from 'xlsx'

const jspreadsheet = require('jspreadsheet-ce')
import('lodash-es').then((lodashEs) => {
  window._ = lodashEs.default
})

export default {
  components: {
    'app-heading': () =>
      import(
        '@binds-tech/binds-design-system/src/components/Typography/Heading'
      ),
    'app-button': () =>
      import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-modal': () =>
      import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-icon': () =>
      import('@binds-tech/binds-design-system/src/components/Icon/Icon'),
    VDatePicker: () => import('v-calendar/lib/components/date-picker.umd'),
    RotateSquare5: () =>
      import('vue-loading-spinner/src/components/RotateSquare5'),
    NewMetadataModal,
    ThemeStyle
  },
  props: ['activeImport'],
  data () {
    return {
      sendhubTable: [],
      showTotalOfLines: 0,
      step: {
        number: 1,
        title: [
          this.$t('seeds.step_1'),
          this.$t('seeds.step_2'),
          this.$t('seeds.step_3')
        ]
      },
      yourFormat: '',
      yourData: {
        hh: '',
        mm: ''
      },
      isAlreadyRunningUpload: false,
      acceptedTheRisk: false,
      canShowNextButton: true,
      successSeeds: false,
      selectedHeaders: [],
      parsedHeaders: [],
      showErrorMessage: '',
      isLoading: false,
      isModalOpen: false,
      headerEmail: '',
      headerPhone: '',
      headerName: '',
      date: '',
      delimiter: ',',
      headers: [],
      isValidFileMimeType: false,
      fileMimeTypes: [
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel'
      ],
      options: this.defaultOptions(),
      checkIfHasHeader: false,
      changePlaceholder: false,
      showNewMetadataModal: false,
      newMetadataWhere: undefined,
      sendType: ''
    }
  },
  computed: {
    user () {
      return this.$store.getters['account/getUser']
    },
    account () {
      return this.$store.getters['account/getAccount']
    },
    optionsMapping () {
      const user = this.$store.getters['account/getUser']
      const options = [
        { key: 'name', label: this.$i18n.t('signup.name') },
        { key: 'email', label: this.$i18n.t('signup.email') },
        { key: 'phone', label: this.$i18n.t('signup.phone') }
      ]

      user.account.fields.map((i) => options.push(i))
      options.push({ key: 'new', label: this.$i18n.t('seeds.new_metadata') })
      return options
    }
  },
  mounted () {
    this.sendType = this.$route.query.type
    const self = this
    this.$root.$on('toSend', function (e) {
      if (self.activeImport === 'csv') {
        self.sendSeeds()
      }
    })
    this.$root.$on('close-new-metadata', () => {
      this.showNewMetadataModal = false
      if (this.newMetadataWhere) {
        this.selectedHeaders[this.newMetadataWhere.index] =
          this.optionsMapping[this.optionsMapping.length - 2].key
      }
      this.newMetadataWhere = undefined
      this.selectedHeaders.forEach((o, index) => {
        if (o !== undefined) {
          this.checkMapping(index, o)
        }
      })
    })
  },
  methods: {
    checkLimitPerDayWpp () {
      const limitSendPerDay = this.account.whatsAppSettings.limitToSendPerDay

      if ((this.showTotalOfLines - 1) > limitSendPerDay) {
        this.canShowNextButton = false
        this.$store.commit('alerts/alert', {
          message: this.$t('whatsapp.limit_exceeded'),
          position: 'bottomRight',
          showAlert: true
        })
      }
    },
    async goDashboard () {
      const surveyId = this.$route.params.id
      const query = {
        $and: [{ survey: { $eq: surveyId } }]
      }
      this.$router.push({
        name: 'RealTime',
        query: { q: JSON.stringify(query) }
      })
    },
    checkMapping (index, value) {
      if (value === 'new') {
        this.showNewMetadataModal = true
        this.newMetadataWhere = { index: index, value: value }
        return
      }

      this.changePlaceholder = false
      let countSelecteds = 1
      this.optionsMapping.map((option) => {
        if (option.selected) {
          countSelecteds++
          if (option.index === index) {
            option.selected = false
            option.index = null
          }
        }
      })

      // trocar o label default do mapeamento de headers
      if (countSelecteds === this.optionsMapping.length) {
        this.changePlaceholder = true
      }

      if (value !== 'undefined' && value !== undefined) {
        const hasThisHeader = window._.findIndex(this.optionsMapping, {
          key: value
        })
        if (hasThisHeader >= 0) {
          this.optionsMapping[hasThisHeader].index = index
          this.optionsMapping[hasThisHeader].selected = true
        }
      }

      this.checkIfHasHeader = this.validHeaderChanges()
    },
    previousStep () {
      this.step.number = this.step.number - 1
    },
    nextStep () {
      this.step.number = this.step.number + 1
    },
    validHeaderChanges () {
      if (
        this.$route.query.type === 'Email' &&
        !this.optionsMapping[1].selected
      ) {
        this.showErrorMessage = 'seeds.map_inform_1'
        return false
      }

      if (
        this.$route.query.type === 'sms' &&
        !this.optionsMapping[2].selected
      ) {
        this.showErrorMessage = 'seeds.map_inform_1'
        return false
      }

      if (
        this.$route.query.type === 'All' &&
        (!this.optionsMapping[1].selected || !this.optionsMapping[2].selected)
      ) {
        this.showErrorMessage = 'seeds.map_inform_1'
        return false
      }

      return true
    },
    openConfigModal () {
      this.isModalOpen = true
    },
    defaultOptions () {
      return {
        data: [],
        onpaste: function () {
          return false
        },
        minDimensions: [10, 1],
        onselection: function () {
          return false
        },
        contextMenu: function () {
          return false
        },
        columns: [],
        allowInsertRow: false
      }
    },
    resetOptions () {
      if (document.getElementById('import-spreadsheet')) {
        document.getElementById('import-spreadsheet').innerHTML = ''
      }
      this.headers = []
      this.options.data = []
      this.options.columns = []
      this.headerEmail = ''
      this.headerName = ''
      this.headerPhone = ''
      this.showErrorMessage = ''
      this.showTotalOfLines = 0
      this.selectedHeaders = []
      this.checkIfHasHeader = false
      this.changePlaceholder = false
      this.optionsMapping.map((option) => {
        option.selected = false
        option.index = null
      })
    },
    checkHeaders () {
      // check if has duplicated headers
      this.showErrorMessage = 'seeds.csv_empty_headers'
      const findDuplicates = (arr) =>
        arr.filter((item, index) => arr.indexOf(item) !== index)
      if (findDuplicates(this.headers[0]).length > 0) {
        this.canShowNextButton = false
        this.showErrorMessage = 'seeds.csv_duplicated_headers'
      }
    },
    isUTF8 (content) {
      for (let b = 0; b < content.length; b++) {
        // If ? is encountered it's definitely not utf8!
        if (content[b] === '�') {
          return false
        }
      }
      return true
    },
    load () {
      this.canShowNextButton = true
      this.isLoading = true
      const self = this
      // refresh memory
      this.resetOptions()

      // inicia a leitura do csv informado
      this.readFile((output, countXlsx) => {
        if (!this.isUTF8(output)) {
          this.isLoading = false
          this.$store.commit('alerts/alert', {
            message: this.$i18n.t('seeds.encode_error'),
            position: 'bottomRight',
            showAlert: true
          })
          return
        }
        const previewData = Papa.parse(output, {
          skipEmptyLines: 'greedy'
        })
        this.delimiter = previewData.meta.delimiter
        this.headers = window._.get(previewData, 'data').slice(0, 4)
        const dataLength = window._.get(previewData, 'data')?.length

        // informa a quantidade de linhas do csv
        this.showTotalOfLines = countXlsx || dataLength

        this.headers[0] = this.headers[0].map((col, idx) => {
          if (col.trim().replace(/[A-zÀ-ú 0-9]/gi, '')) {
            // procura por headers com caractere especial
            this.parsedHeaders.push(idx)
          }
          return col.trim().replace(/[^A-zÀ-ú 0-9]/gi, '_') // converte o header
        })

        // validator and formatter of headers (columns)
        this.checkHeaders()
        const notPermitedHeaders = []
        this.headers[0].forEach(function (element, index) {
          if (element.trim().length > 0) {
            // preenche os headers da tabela
            self.options.columns.push({
              type: 'text',
              width: '125px',
              title: element,
              readOnly: true
            })
          } else {
            notPermitedHeaders.push(index) // informa quem sãos os headers vazios
          }
        }, self)
        // preenche os dados para as linhas da tabela
        const rowsToTablePreview = this.headers.filter((item, index) => {
          if (index > 0) {
            if (notPermitedHeaders.length > 0) {
              notPermitedHeaders.forEach((idx) => {
                if (item[idx] !== undefined) {
                  item.splice(idx, 1) // remove o valor da célula que estiver na coluna que foi ignorada por estar vazia (sem nome)
                }
              })
            }
          }
          return item
        })
        this.options.data = window._.tail(rowsToTablePreview)

        // renderiza a tabela de preview
        this.sendhubTable = jspreadsheet(
          this.$refs['import-spreadsheet'],
          this.options
        )

        // alerta para informar erros
        if (this.showErrorMessage) {
          this.$store.commit('alerts/alert', {
            message: this.$i18n.t(this.showErrorMessage),
            position: 'bottomRight',
            showAlert: true
          })
        }

        // chega se a quantidade de envios da planilha é maior que o limite de envios por dia (apenas para envio via WhatsApp)
        if (this.sendType === 'whatsapp') {
          this.checkLimitPerDayWpp()
        }
        // setando selectHeaders se os headers do arquivo forem dompativeis com os metadados da conta
        this.headers[0].forEach((o, index) => {
          const matched = this.optionsMapping.map((meta) => {
            let matchValue = metadataManipulator.levenshteinDistance(
              o,
              meta.label
            )
            if (matchValue < 3) {
              return meta
            }

            if (meta.label !== this.$i18n.t('seeds.new_metadata')) {
              // tenta achar pela key do metadado e exclui a opção "criar personalizado" da consulta
              matchValue = metadataManipulator.levenshteinDistance(o, meta.key)
            }
            if (matchValue < 3) {
              return meta
            }
          })
          const matchedClear = matched.filter((i) => i !== undefined)
          this.selectedHeaders[index] =
            matchedClear[0] === undefined ? undefined : matchedClear[0].key
        })
        this.selectedHeaders.forEach((o, index) => {
          if (o !== undefined) {
            this.checkMapping(index, o)
          }
        })
        this.isLoading = false
      })
    },
    readFile (callback) {
      const file = this.$refs.csv.files[0]
      if (file) {
        const reader = new FileReader()
        if (file.type === 'text/csv') {
          reader.readAsText(file, 'UTF-8')
        }
        reader.onload = function (evt) {
          // lê arquivos xlsx / xls
          if (file.type !== 'text/csv') {
            let countRows = null
            var data = new Uint8Array(evt.target.result)
            var workbook = read(data, {
              type: 'array',
              blankrows: false,
              cellText: false,
              cellDates: true
            })

            try {
              const fullRange = workbook?.Sheets[workbook?.SheetNames[0]]['!fullref']
              countRows = fullRange ? fullRange.split(':')[1].slice(1) : null
            } catch (error) {
              console.log('erro ao tentar encontrar o total de linhas', error)
            }
            callback(
              utils.sheet_to_csv(
                workbook.Sheets[workbook?.SheetNames[0]],
                {
                  blankrows: false,
                  rawNumbers: true,
                  raw: false,
                  dateNF: 'dd-mm-yyyy'
                }
              ),
              countRows
            )
          } else {
            callback(evt.target.result)
          }
        }
        if (file.type !== 'text/csv') {
          reader.readAsArrayBuffer(file)
        }
        reader.onerror = function (e) {
          console.log('console error', e)
        }
      }
    },
    async validFileAndLoadPreview () {
      this.showErrorMessage = ''
      const file = this.$refs.csv.files[0]
      const mimeType =
        file.type === '' ? mimeTypes.lookup(file.name) : file.type
      if (file) {
        this.isValidFileMimeType = this.fileMimeTypes.indexOf(mimeType)
        const convertToMb = (file.size / 1024 / 1024) // value is a byte
        if (convertToMb > 10) {
          this.$store.commit('alerts/alert', {
            message: this.$i18n.t('seeds.file_size') + '10 Megabytes.',
            position: 'bottomRight',
            showAlert: true
          })
          return false
        }
      }
      if (this.isValidFileMimeType < 0) {
        this.resetOptions()
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('seeds.file_type'),
          position: 'bottomRight',
          showAlert: true
        })
        return false
      }
      // carrega o preview e valida os headers para o atrelamento
      this.load()
    },
    async sendSeeds () {
      this.isAlreadyRunningUpload = false
      this.successSeeds = false
      this.isLoading = true
      this.showErrorMessage = ''
      this.validHeaderChanges()
      if (this.showErrorMessage) {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t(this.showErrorMessage),
          position: 'bottomRight',
          showAlert: true
        })
        this.isLoading = false
        return false
      }

      // constroi a estrutura de metadados e dados campos de email/nome/telefone
      const json = []
      this.selectedHeaders.map((item, index) => {
        if (
          item !== undefined &&
          item !== 'undefined' &&
          item.trim().length > 0
        ) {
          json.push({ key: item, value: this.headers[0][index] })
        }
      })
      // obtém o collector dessa survey
      const surveyIdToCheck = this.$store.getters['survey/getSurvey']._id
      const startOfToday = this.$moment()
        .utc()
        .subtract(30, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      const allPromises = [
        uploadFile.getCollector(),
        uploadFile.isUploadRunning({ survey: surveyIdToCheck, status: 'pending', createdAt: startOfToday }),
        uploadFile.isUploadRunning({ survey: surveyIdToCheck, status: 'processing', createdAt: startOfToday })
      ]
      const [collectors, pending, processing] = await Promise.all(allPromises)
      const runningOtherUpload = pending.concat(processing)
      if (runningOtherUpload.length && !this.acceptedTheRisk) {
        this.isAlreadyRunningUpload = true
        this.isLoading = false
        return
      }
      let findedCollector = ''
      if (collectors) {
        findedCollector = window._.findIndex(collectors, {
          survey: this.$store.getters['survey/getSurvey']._id
        })
      }
      if (findedCollector < 0) {
        this.$store.commit('alerts/alert', {
          message: 'Collector not found',
          position: 'bottomRight',
          showAlert: true
        })
        this.isLoading = false
        return false
      }

      // prepara a query para o backend
      const toQuery = {
        delimiter: this.delimiter,
        collector: collectors[findedCollector]._id,
        headers: encodeURI(JSON.stringify(json)),
        date: this.yourFormat,
        defaultSendingType: this.$route.query.type.toLowerCase()
      }

      // prepara o arquivo para o envio
      const formdata = new FormData()
      formdata.append('csv', this.$refs.csv.files[0])
      const result = await uploadFile.uploadSeeds(formdata, toQuery)
      this.isLoading = false
      if (!result || !result.user) {
        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('sendConfig.error'),
          position: 'bottomRight',
          showAlert: true
        })
      }
      this.successSeeds = true
    }
  }
}
</script>

<style lang="scss">
.button-csv-import {
  font-size: 13px !important;
  box-shadow: none !important;
  // color: var(--primary-color) !important;
  // border: 2px solid var(--primery-color) !important;
}

.hideOption {
  display: none;
}
.p-container-csv {
  margin: 10px 10px 0px 0px;
  height: 53px;
  background-color: #f4f4f4;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}
.p-csv {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  line-height: 1;
  color: #3f3356 !important;
  font-weight: 600;
}
#schedule-survey-date-picker {
  justify-content: center;
  padding-left: 0;
  input {
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 5px;
    background-color: #fff;
    padding: 16px !important;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  }
  .icon-container {
    display: flex;
    align-items: center;
  }
  .material-icons {
    padding: 5px;
    position: absolute;
  }
  .vc-day-content {
    background: none;
  }
}
.input-wrapper {
  position: relative;
  &:after {
    position: absolute;
    display: block;
    content: "";
    width: 6px;
    height: 6px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    border: 2px solid rgb(0, 170, 167);
    border-top: 0;
    border-right: 0;
  }
  .dropdown {
    width: 94%;
    height: 53px;
    padding: 13px;
    box-sizing: border-box;
    border-radius: 10px;
    border: solid 1px $color-gray-light;
    background-color: $color-white;
    -webkit-appearance: none;
    position: relative;
    text-align: left;
    font-family: $font-text;
    font-size: $size-m;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    outline: none;
    margin: 10px;
    text-overflow: ellipsis;
    color: $color-purple-darker;
  }
}
.vue__time-picker .controls > * {
  margin-top: 10px;
}
.vue__time-picker input.display-time {
  border: 1px solid #bbbdc8;
  height: 2.9em;
  width: 15em;
  border-radius: 4px;
  color: #bbbdc8;
}
.agendarEnvio > .datepicker > div > label > div {
  border-color: #bfc1cc !important;
  background-color: #fff;
}
.greyButtons {
  color: #9a838a !important;
}
.greyButtons:hover,
.greyButtons:active,
.greyButtons:focus,
.greyButtons {
  color: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
  background: transparent !important;
}
.csvHeaders {
  margin: 10px;
}
.activeColor {
  animation: activeColor 3s ease-in-out;
  animation-fill-mode: both;
}
.inactiveColor {
  animation: inactiveColor 1s ease-in-out;
  animation-fill-mode: both;
}
.progress {
  background-color: var(--accent-color) !important;
  animation: progressBar 2s ease-in-out;
  animation-fill-mode: both;
}
.removeProgress {
  background-color: #eff3f8;
  animation: noprogressBar 2s ease-in-out;
  animation-fill-mode: both;
}
.defaultProgress {
  background-color: var(--accent-color) !important;
  animation: defaultProgress 2s ease-in-out;
  animation-fill-mode: both;
}
.insertDefault {
  background-color: #eff3f8;
  animation: defaultProgressBar 2s ease-in-out;
  animation-fill-mode: both;
}
.activeColorButton {
  animation: activeColorBt 1s ease-in-out;
  animation-fill-mode: both;
}
.inactiveColorButton {
  animation: inactiveColorBt 1s ease-in-out;
  animation-fill-mode: both;
}
@keyframes activeColor {
  0% {
    color: #eff3f8;
  }
  100% {
    color: var(--accent-color);
  }
}
@keyframes activeColorBt {
  0% {
    color: #eff3f8;
  }
  100% {
    color: #fff;
    background: var(--accent-color);
  }
}
@keyframes inactiveColorBt {
  0% {
    color: #fff;
    background: var(--accent-color);
  }
  100% {
    color: #fff;
    background: #bbbdc8;
  }
}
@keyframes inactiveColor {
  0% {
    color: var(--accent-color);
  }
  100% {
    color: #eff3f8;
  }
}
@keyframes defaultProgress {
  0% {
    width: 144%;
  }
  100% {
    width: 0%;
  }
}
@keyframes defaultProgressBar {
  0% {
    width: 0%;
  }
  100% {
    width: 144%;
  }
}
@keyframes progressBar {
  0% {
    width: 0%;
  }
  100% {
    width: 144%;
  }
}
@keyframes noprogressBar {
  0% {
    width: 144%;
  }
  100% {
    width: 0%;
  }
}
.floatLeftHeaders {
  float: left;
}
.spaceIcons {
  margin-top: 10px;
}
.csvMenu {
  p {
    color: var(--primary-color) !important;
  }
  text-align: -webkit-center;
  text-decoration: none;
  cursor: default;
}
.headerIcons {
  color: rgb(134, 134, 134) !important;
  font-size: 24px !important;
}
.makeItClick {
  height: 80px;
  position: absolute;
  width: 180px;
  margin-top: -15px;
}

.hover-item:hover {
  color: $color-purple;
  & p {
    color: $color-purple;
    font-weight: bold;
  }
  & div i {
    color: $color-purple !important;
  }
}
.header-config {
  margin-left: 20px;
  background-color: #fff;
  padding-bottom: 20px;
  padding-top: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 803px;
  color: #838383;
  line-height: 20px;
}
.binds-button.secondary.outline {
  cursor: default;
}
.inputfile {
  width: 161.1px;
  height: 82.1px;
  margin-top: -30px;
  margin-left: -80px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}
.binds-button {
  margin-bottom: 0px;
  margin-top: 10px;
}
.content-send {
  position: relative;
}
.spreadsheet-contacts {
  width: 100%;
  padding-right: 15px;
  margin-left: 0px !important;
}

.spreadsheet-contacts .jexcel > thead > tr > td {
  background-color: #eff3f8;
  padding: 10px 0;
  border-bottom: 2px solid $color-gray-light;
  font-size: 18px;
}
.spreadsheet-contacts .jexcel > tbody > tr > td:first-child {
  background-color: #fff;
}
.spreadsheet-contacts .jexcel > tbody > tr:nth-child(even) {
  background-color: #eff3f8;
}
.header-contacts {
  box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16),
    0 5px 4px 0 rgba(0, 0, 0, 0.01);
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  max-width: 803px;
  span {
    color: #2c2b2d;
  }
  p {
    color: $color-gray;
  }
}
.send-by-csv {
  .header-contacts {
    margin: 0 auto;
  }
  .datepicker__picker {
    left: auto;
  }
  .datepicker .vdp-datepicker__calendar .cell.selected,
  .datepicker
    .vdp-datepicker__calendar
    .cell:not(.blank):not(.disabled).day:hover,
  .datepicker
    .vdp-datepicker__calendar
    .cell:not(.blank):not(.disabled).month:hover,
  .datepicker
    .vdp-datepicker__calendar
    .cell:not(.blank):not(.disabled).year:hover {
    color: white;
    background: black;
  }
  .datepicker
    .vdp-datepicker__calendar
    header
    .prev:not(.disabled):hover::after,
  .datepicker .vdp-datepicker__calendar header .up:not(.disabled):hover {
    border-right-color: #a9a7ac;
    color: #a9a7ac;
  }
  .datepicker
    .vdp-datepicker__calendar
    header
    .next:not(.disabled):hover::after {
    border-left-color: #a9a7ac;
  }
  // .header-contacts span {
  //   color: #2C2B2D;
  // }
  .vue__time-picker .dropdown ul li:not([disabled]).active,
  .vue__time-picker .dropdown ul li:not([disabled]).active:hover,
  .vue__time-picker .dropdown ul li:not([disabled]).active:focus,
  .vue__time-picker-dropdown ul li:not([disabled]).active,
  .vue__time-picker-dropdown ul li:not([disabled]).active:hover,
  .vue__time-picker-dropdown ul li:not([disabled]).active:focus {
    background: #2c2b2d;
  }
}
.jexcel_pagination {
  display: none;
}
.rename-column {
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;
  width: 330px;
  display: block;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 10px 64px -31px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 64px -31px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 64px -31px rgba(0, 0, 0, 0.75);
}

.failure-message {
  color: $color-red-light;
  display: block;
}

.failure-message-count {
  color: $color-gray;
  display: block;
  margin-top: 10px;
  font-weight: bold;
}

.vue-time-picker-csv input {
  height: 2.8em !important;
}

.riskMessage {
  margin: 0 auto;
  margin-top: 1.3rem;
  text-align: center;
}
</style>
