<template>
  <div class="container-card-filter">
    <app-select-binds
      :placeholder="$t('advanced_filters.select_type')"
      :options="categories(idx)"
      titleItem="label"
      :id="`type-0${idx}`"
      reduce="key"
      :value="field.category"
      @model="field.category = $event"
      @input="changeType"
    />
    <app-select-binds
      v-if="field.category !== '' && field.category !== 'tags'"
      :key="field.category"
      :placeholder="$t('advanced_filters.select_type')"
      :options="dynamicOptions(field.category)"
      titleItem="label"
      :id="`type-1${idx}`"
      :value="field.start"
      @model="field.start = $event"
      @input="returnFirstOption"
    />
    <app-select-binds
      v-if="(field.category === 'channel' || field.category === 'status') && field.start"
      :placeholder="$t('advanced_filters.select_option')"
      :options="field.category === 'channel' ? channels() : statusOption()"
      titleItem="label"
      :id="`type-2${idx}`"
      :value="field.middle"
      @model="field.middle = $event"
    />
    <app-select-binds
      v-if="showOptionsAttendance"
      :placeholder="$t('advanced_filters.select_option')"
      :options="optionsAttendance"
      titleItem="label"
      :id="`type-3${idx}`"
      :value="field.end"
      @model="field.end = $event"
      @input="field.middle = ''"
    />
    <app-select-binds
      v-if="field.start && field.start.select"
      :placeholder="$t('advanced_filters.select_conditional')"
      :options="field.start.select"
      titleItem="label"
      :id="`type-4${idx}`"
      :value="field.middle"
      @model="field.middle = $event"
      @input="field.end = ''"
    />
    <app-select-binds
      v-if="field.middle && field.middle.options"
      :placeholder="$t('advanced_filters.select_conditional')"
      :options="field.start.options"
      titleItem="name"
      :id="`type-5${idx}`"
      :value="field.end"
      @model="field.end = $event"
    />
    <div class="input-multiselect" v-if="field.middle && field.start.values">
      <multiselect
        v-model="field.end"
        :max-height="150"
        :options="field.start.values.map(item => item._id)"
        :multiple="true"
        :hide-selected="true"
        :allow-empty="false"
        :close-on-select="false"
        :clear-on-select="false"
        placeholder="Selecione"
        :custom-label="opt => field.start.values.find(x => x._id == opt).name"
        >
        <template slot="selection"
        slot-scope="{ values, isOpen }">
        <span class="multiselect__single"
        v-if="values.length &amp;&amp; !isOpen">{{ values.length }} <span>{{ values.length > 1 ? 'opções selecionadas' : 'opção selecionada' }}</span></span>
        </template>
      </multiselect>
    </div>
    <SearchText
      :key="field.middle.key"
      v-if="field.middle && field.middle.input"
      :placeholder="'Digite o valor'"
      :value="field.end"
      @search-text-insight="field.end = $event"
    />
    <v-date-picker class="container-date-picker" v-model="date" color="gray" locale="pt-br" mode="date" @input="changeDate" v-if="showDateInput">
      <template v-slot="{ inputValue, inputEvents }">
        <div class="content-date-picker">
          <div class="section-date-picker" v-on="inputEvents">
            <div class="start-date">
              <span>{{inputValue}}</span>
            </div>
            <i  class="material-icons icon-calendar">calendar_month</i>
          </div>
        </div>
      </template>
    </v-date-picker>
    <button class="delete-filter" v-title="'Excluir'" @click="deleteFilter()">
      <i class="material-icons icon-delet-filter">delete</i>
    </button>
  </div>
</template>

<script>
import { dateFormatter, orderFormater } from '@/_helpers'
import SearchText from '../insights/components/SearchText.vue'
import('lodash-es').then((lodashEs) => { window._ = lodashEs.default })

export default {
  name: 'CardFilter',
  props: ['idx', 'field', 'entity', 'optionsCategoryList', 'optionsSubCategoryList', 'listAttendants'],
  components: {
    SearchText,
    Multiselect: () => import('vue-multiselect'),
    'app-select-binds': () => import('@binds-tech/binds-design-system/src/components/Select/SelectBinds')
  },
  data () {
    return {
      date: this.$moment(new Date()).startOf('day').utc().format()
    }
  },
  mounted () {
  },
  computed: {
    user () { return this.$store.getters['account/getUser'] },
    options () { return this.$store.getters['filters/getDashFilter'].query.options },
    showOptionsAttendance () {
      let show
      if (this.field.start.code === 'ticket.category' ||
        this.field.start.code === 'ticket.parentCategory' ||
        this.field.start.code === 'ticket.attendant') {
        show = true
      }
      return show
    },
    optionsAttendance () {
      let options
      if (this.field.start.code === 'ticket.category') { options = this.optionsCategoryList }
      if (this.field.start.code === 'ticket.parentCategory') { options = this.optionsSubCategoryList }
      if (this.field.start.code === 'ticket.attendant') { options = this.listAttendants }
      return options
    },
    showDateInput () {
      let show = false
      if (this.field.start.code === 'date.$gte' ||
        this.field.start.code === 'date.$lte' ||
        this.field.start.code === 'date.$gt' ||
        this.field.start.code === 'date.$lt') {
        show = true
      }
      return show
    }
  },
  methods: {
    deleteFilter () {
      this.$emit('delete-field', this.idx)
    },
    returnFirstOption (e) {
      if (e.key === 'all') return
      this.field.middle = ''
      this.field.end = ''
    },
    dynamicOptions (category) {
      if (category !== 'tags' && category !== 'channel' && category !== 'status') {
        return this.returnCategory(this.field)
      }
      if (category === 'channel' || category === 'status') {
        return this.equalOrNot()
      }
    },
    changeType (e) {
      if (e.key === 'all') return
      this.field.start = ''
      this.field.middle = ''
      this.field.end = ''

      setTimeout(() => {
        if (this.field.category === 'tags') {
          this.field.middle = ''
          this.field.start = this.returnCategory(this.field)[0]
        }
      })
    },
    returnCategory (filter) {
      if (filter.category === 'survey') {
        return orderFormater.expiredAt(window._.filter(this.options, { category: filter.category }))
      }

      return window._.filter(this.options, { category: filter.category })
    },
    categories (idx) {
      let result = window._.filter(this.$store.getters['filters/getDashFilter'].query.categories, o => {
        return (!o.entity || o.entity === this.entity)
      })

      const hasSomeFieldUsingSurveyCategory = window._.findIndex(this.field, item => item.category === 'survey')

      if (hasSomeFieldUsingSurveyCategory >= 0) {
        if (idx !== hasSomeFieldUsingSurveyCategory) {
          result = window._.filter(this.$store.getters['filters/getDashFilter'].query.categories, o => {
            return (!o.entity || o.entity === this.entity) && o.key !== 'survey'
          })
        }
      }

      if (this.$store.getters['dashboard/getCurrentSurvey']?.isAnonymous || this.user.features.hiddenSendingContacts) {
        result = result.filter(op => op.key !== 'contact')
      }
      if (this.user.features.hiddenSendingContacts) { result = result.filter(op => op.key !== 'attendance') }
      if (this.user.features.hiddenSendingMetadata) { result = result.filter(op => op.key !== 'metadata') }

      return result
    },
    equalOrNot () {
      const ad = [
        {
          key: '$eq',
          label: this.$i18n.t('advanced_filters.fields.is_equal_to'),
          toQuery: '$eq',
          options: true
        },
        {
          key: '$ne',
          label: this.$i18n.t('advanced_filters.fields.is_diffrent_from'),
          toQuery: '$ne',
          options: true
        }
      ]
      return ad
    },
    channels () {
      const channel = [
        { label: 'widget' },
        { label: 'email' },
        { label: 'sms' },
        { label: 'link' },
        { label: 'whatsapp' }
      ]
      return channel
    },
    statusOption () {
      const status = [
        {
          label: this.$i18n.t('advanced_filters.fields.sent'),
          value: 'sent'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.rejected'),
          value: 'rejected'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.deferred'),
          value: 'deferred'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.spam'),
          value: 'spam'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.bounced'),
          value: 'bounced'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.soft_bounced'),
          value: 'soft-bounced'
        },
        {
          label: this.$i18n.t('advanced_filters.fields.Unsub'),
          value: { optedOutAt: { $exists: true } }
        }
      ]
      return status
    },
    changeDate (d) {
      let date
      if (typeof d === 'string') {
        date = dateFormatter.stringPeriodToPlainDate(d).formatedStart
      } else {
        date = this.$moment(d, 'YYYY-MM-DD HH:mm ZZ').startOf('day').utc().format()
      }
      this.date = date
      this.field.start.date = this.date
      this.finishFilter()
    }
  }
}
</script>

<style lang="scss">
.container-card-filter {
  position: relative;
  width: 100%;
  height: 5rem;
  border: 0.1rem solid #cacaca;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
  padding: 0.5rem !important;
  display: flex;
  align-items: center;
  gap: 1rem;
  .container-date-picker {
    width: 14rem;
    height: 3.2rem;
    .content-date-picker {
      width: 100%;
      height: 100%;
      .section-date-picker {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border: 0.1rem solid #cacaca;
        font-size: 1.5rem;
        color: #808080;
        border-radius: 0.3rem;
        .start-date, .end-date {
          font-size: 1.4rem;
          display: flex;
          align-items: center;
        }
        .icon-calendar {
          font-size: 1.4rem;
          color: var(--accent-color);
        }
      }
    }
  }
  .input-multiselect {
    height: 2.5rem;
    min-width: 16rem;
    max-width: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3rem;
    border: 0.1rem solid #cacaca;
    .multiselect {
      height: 100%;
      min-height: fit-content;
      .multiselect__select {
        height: 100%;
      }
      .multiselect__tags {
        border: none;
        position: relative;
        height: 100%;
        min-height: fit-content;
        display: flex;
        align-items: center;
        .multiselect__tags-wrap {
          position: absolute;
          background: #ffffff;
          width: 25rem;
          top: 0;
          left: 101%;
          height: 12rem;
          padding: 0.2rem;
          overflow-y: auto;
          border-radius: 0.3rem;
          border: 0.1rem solid #cacaca;
          box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.070);
        }
        .multiselect__placeholder {
          font-size: 0.9rem;
          color: #808080;
        }
      }
    }
  }
}
.delete-filter {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  padding: 0.2rem;
  border: 0.1rem solid #cacaca;
  .icon-delet-filter {
    font-size: 1.2rem;
    color: #cacaca;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.070);
  }
  .icon-delet-filter:hover {
    color: var(--accent-color);
  }
}
.delete-filter:hover {
  border-color: var(--accent-color);
}
</style>
