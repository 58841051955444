<template>
  <div>
    <div :class="{'positionError': !padding, 'position' : padding}">
      <img
        :src="'/img/404'+$i18n.locale+'.png'"
        class="setFilterGrey"
        :class="{'withOtherGraphics': !padding, 'textGraphic' : padding}"
      >
      <p class="errorMessage" v-if="!withoutResponses">{{ $i18n.t('dashboard.not_found') }}</p>
      <p class="errorMessage" v-else>{{ $i18n.t('real_time.not_answered') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['padding', 'withoutResponses']
}
</script>

<style>
.setFilterGrey {
  filter: grayscale(100%);
}
.errorMessage {
  font-family: inherit;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  letter-spacing: normal;
  color: #868585;
}
.positionError {
  padding-bottom: 25%;
  padding-top: 8%;
  text-align: center;
}
.position {
  padding-top: 10%;
  text-align: center;
}
.withOtherGraphics {
  width: 86%;
}
.textGraphic {
  width: 48%;
}
</style>
