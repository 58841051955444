export default {
  Dashboard: 'Dashboard',
  Home: 'Home',
  activity: {
    interface: {
      header: {
        name: 'Nombre',
        email: 'Correo electrónico',
        origin: 'Origen',
        action: 'Acción',
        date: 'Fecha'
      },
      users: 'Usuario',
      select: 'Seleccionar',
      actions_label: 'Acciones',
      actions: {
        created: 'Creó',
        updated: 'Actualizó',
        deleted: 'Eliminó',
        sent: 'Envió',
        expired: 'Expirado',
        unexpired: 'No expirado',
        added: 'Añadió',
        removed: 'Quitó'
      },
      all: 'Todos',
      entities: {
        annotation: 'Anotación',
        account: 'Cuenta',
        user: 'Usuario',
        survey: 'Encuesta',
        message: 'Mensaje',
        scheduled: 'Programación',
        ResendSending: 'Reenvío',
        collector: 'Recolector',
        ticketCategory: 'Categoría de Ticket',
        emailTest: 'Prueba de Correo',
        role: 'Rol',
        token: 'Token'
      }
    },
    interpolate: {
      resend: 'a resending',
      tag: 'a tag',
      annotation: 'a note',
      user: 'the user',
      survey: 'the survey',
      ticket: 'a ticket',
      role: 'a group',
      message: 'a message',
      category: 'the category',
      account: 'the account',
      seedBatch: 'a batch'
    },
    filter_tooltip_today: 'Filter for today',
    action: 'Action',
    displaying: 'Displaying',
    of_today: 'on today',
    from_yesterday: 'from yesterday',
    sending_expired: 'Expired approach',
    sending_unexpired: 'Activated approach',
    sending: 'approach',
    has_expired: 'Expired',
    has_unexpired: 'Activated',
    annotation: 'Annotation',
    has_created: 'Created',
    has_added: 'Added',
    has_removed: 'Removed',
    has_deleted: 'Deleted',
    has_sent: 'Sent',
    has_updated: 'Updated',
    user_updated: 'User updated',
    user_created: 'User created',
    user_deleted: 'User deleted',
    role_updated: 'Role updated',
    role_created: 'Role Created',
    role_deleted: 'Role Deleted',
    tag_updated: 'Tag updated',
    tag_created: 'Tag Created',
    tag_deleted: 'Tag Deleted',
    tag_error_add: 'Error adding tag',
    survey_updated: 'Survey updated',
    survey_created: 'Survey created',
    survey_deleted: 'Survey deleted',
    message_updated: 'Message updated',
    message_created: 'Message created',
    message_deleted: 'Message deleted',
    survey_send: 'Survey sent',
    seedBatch_sent: 'Batch sent',
    title: 'Activity',
    label: 'activity',
    seedBatch: 'Batch',
    no_activity: 'No activities yet!',
    ticket_updated: 'Ticket updated',
    ticket_created: 'Ticket created',
    ticket_deleted: 'Ticket deleted',
    ticketCategory_updated: 'Category updated',
    ticketCategory_created: 'Category created',
    ticketCategory_deleted: 'Category deleted',
    addTag_updated: 'Tag Marked / Unmarked',
    addTag: 'Tags on Responses',
    annotation_updated: 'Annotation updated',
    annotation_created: 'Annotation created',
    annotation_deleted: 'Annotation deleted',
    account_updated: 'Account updated',
    account_created: 'Account created',
    account_deleted: 'Account deleted',
    BrandOnBindsVoiceScore_created: 'Brand created',
    BrandOnBindsVoiceScore_added: 'Brand added',
    BrandOnBindsVoiceScore_removed: 'Brand removed',
    ResendSending_created: 'Resend created',
    Scheduled_deleted: 'Resend canceled',
    yesterday: 'Yesterday',
    today: 'Today',
    tomorrow: 'Tomorrow',
    last: 'Last',
    when: 'When',
    time: 'Time',
    no_logs: 'It looks like your account doesnt have activity logs yet.',
    no_log_day: 'It looks like there are no activities on this day.'
  },
  sendings: {
    pick_older_date: 'Since',
    deliveries: 'deliveries',
    delivery_rate: 'delivery rate',
    openings: 'openings',
    clicks: 'clicks',
    responses: 'responses',
    response_rate: 'response rate',
    softBounces: 'soft bounce',
    bounces: 'bounces',
    spam: 'SPAM returns',
    without_sendings: 'Ops! We don\'t have any approach data to show yet.',
    deleted: 'Approach deleted!',
    error_deleted: 'Error when deleting approach',
    archived: 'Approach archived!',
    resolved: 'Resolved'
  },
  'bar-filter': {
    older_date: 'Since',
    status: 'Status'
  },
  isAnonymous: {
    is_anonymous: 'Anonymize Survey',
    anonymous_auth: 'Request authorization of analytical data',
    anonymous_question: 'Authorize display of responses for data analysis',
    anonymous_title: 'Autorização'
  },
  annotation: {
    annotation: 'Notes',
    description: 'Create notes on the response specifications and annotate information about the attendance.',
    go_top: 'Go to top',
    notes: 'Notes',
    max_length: 'Maximum characters',
    yes: 'Yes',
    no: 'No',
    delete_annotation: 'Do you want to delete this annotation ?',
    create: 'Create notes',
    type_note: 'Write a note',
    delete: 'Delete'
  },
  exceeded: {
    upgrade: 'It\'s time to upgrade.',
    limit: 'You have reached the limit for free submissions. To send more you need to choose a plan that best meets your demand.',
    keep: 'Keep sending',
    notshow: 'don\'t show me again',
    thanks: 'Thank you!',
    process: 'We are processing your request, please wait for one of our attendants to contact you.'
  },
  account_details: {
    title: 'Account configurations',
    subtitle: 'General Settings',
    default: 'Default',
    account_name: 'Account\'s name',
    type_id_or_name: 'Type account\'s id or name',
    type_url: 'Type the URL',
    updated: 'Updated!',
    updating_error: 'Error trying to update',
    enter_account: 'Enter in this account',
    no_name: 'No name',
    no_email: 'No email',
    no_phone: 'No phone',
    already_sent: 'Already sent surveys',
    didnt_send_yet: 'Haven\'t sent surveys yet',
    account: {
      name: {
        value: 'Name',
        description: 'The account name is used as the senders name in e-mail messages'
      },
      url: {
        value: 'Url',
        description: 'The url for the account website'
      },
      dashboardPeriod: {
        value: 'Default date for filters',
        description: 'We will use this field to configure the default time in the date filters through the platform'
      }
    },
    send_preferences: {
      title: 'Shipping Settings',
      name: {
        value: 'Name field',
        description: 'This is the field, in the integration, that contains the name of the recipient'
      },
      email: {
        value: 'Email Field',
        description: 'This is the field, in the integration, that contains the recipients email'
      },
      phone: {
        value: 'Telephone Field',
        description: 'This is the field, in the integration, that contains the recipients phone'
      },
      sending_interval: {
        value: 'Send interval',
        description: 'Prevents sending to the same recipient during the time interval',
        ask: 'Survey break?'
      },
      expiration_time: {
        value: 'Search expiration time',
        description: 'Sets the maximum time limit for a survey to be answered after submission'
      }
    },
    features: {
      sms_enabled: 'SMS approaches',
      sms_metadata: 'Enable SMS sending with Metadata',
      tickets: 'Attendance (close the loop)',
      edit_survey_response: 'Edit answered survey',
      associate_notes: 'Assign notes to the service',
      sheet_export: 'Export files by email',
      has_invoice: 'Paying customer (release shooting lock)',
      require_category: 'Force categorization of care',
      text_analytics: 'Text analysis',
      comparative_dash: 'Performance report',
      tags: 'Tags',
      two_way_auth: 'Two-factor authentication',
      v2: 'Enable Binds system transition (v1 / v2)',
      allow_to_v2: 'Display the transition button for v2',
      allow_to_v2_description: 'The button will only appear for the account owner',
      confirm_optout_title: 'Screen to confirm email unsubscribe',
      confirm_optout_description: 'When clicking to no longer receive emails from the company, a screen will appear to confirm this action.',
      binds_voice_score: 'Enable Binds Voice Score',
      binds_voice_score_description: 'Dashboard that analyzes texts based on data sources such as social networks and spreadsheets.',
      whatsapp: {
        title: 'Enable WhatsApp',
        phone_id: 'Phone Number Identification',
        business_id: 'WhatsApp Business Account Identification',
        token: 'Permanent Access Token',
        limit_per_day: 'Sending Limit per Day',
        limit_per_day_label: 'Number of Sends per Day',
        send_interval_title: 'Sending interval, by phone',
        send_interval_description: 'Prevents sending to the same recipient during the time interval. Using a longer period improves the user experience and increases the chance of response.',
        template_text_simple_title: 'Simple Text Message',
        template_text_simple_description: 'Template for simple text',
        template_text_button_title: 'Message with Button',
        template_text_button_description: 'Template for text + button',
        template_text_button_image_title: 'Message with Button and Image',
        template_text_button_image_description: 'Template for text + button + image'
      },
      sso: {
        title: 'Enable SSO',
        client_id: 'Specifies the client ID of the application that is registered in Azure Active Directory',
        client_secret: 'Secret used to establish customer ID ownership',
        tenant: 'Azure AD tenant identifier'
      }
    }
  },
  date_filter: {
    from: 'From',
    to: 'To',
    empty: 'Select a date',
    periods: {
      day: '24 HOURS',
      week: '7 DAYS',
      month: '30 DAYS',
      semester: '6 MONTHS',
      year: '12 MONTHS'
    }
  },
  login: {
    welcome: 'Welcome',
    password: 'Password',
    enter: 'Enter',
    back: 'Back',
    send: 'Send',
    reset: 'I forgot the password',
    new_at_binds: 'new at Binds.co?',
    new_account: 'Register',
    login_error: 'Login or password are incorrect',
    complete_solution: 'The complete solution for your customer service and surveys',
    custon_indicators: 'Custom indicators',
    beyond_NPS: 'Beyond the NPS',
    managers_notifications: 'Managers notifications',
    in_depth_reports: 'Full reports',
    easy_integrations: 'Easy integration',
    language_select: 'Select language',
    alert: 'Important warning!',
    alert_v1_redirect: 'This account is using our old version of the platform, click on "Redirect" to access the correct location.',
    redirect: 'Redirect',
    ready: 'All set for you to begin. Log in!'
  },
  resetpass: {
    title: 'New password for',
    pass1: 'New password',
    pass2: 'Confirm new password',
    email_sent: 'We send the password to your email',
    fill_in: 'Fill in the fields correctly.',
    code_expired: 'The password reset link has expired. Please request a new one.',
    save: 'Save'
  },
  signup: {
    form_title: 'Create your account in seconds',
    company: 'Company name',
    phone: 'Phone',
    email: 'E-mail',
    password: 'Password',
    name: 'Name',
    enter: 'Enter',
    incorrect_field_value: 'Please fill this field with a valid value',
    create_account: 'Create account',
    have_account: 'Already have an account?',
    agreement: 'By clicking on this button, you agree with our',
    use_terms: 'Terms of use',
    congratulation: 'Congratulations, your account has been created! We sent an email to set your password.'
  },
  metadata: {
    title_resend_metadata: 'Resend',
    manageFields: 'Manage custom fields',
    title: 'Custom fields',
    description: 'Manage your account\'s custom fields',
    text_type: 'Text',
    field: 'Field',
    label: 'Label',
    type: 'Type',
    groupable: 'Make rankable',
    enable_insight: 'Insights',
    error_enable_insight: 'You can only have 3 fields with active insights',
    ranking: 'Ex: By creating a custom field called "city" and making it "rankable" you could see a ranking of responses by "city" if you used this field when triggering your email/phone survey.',
    new: 'New custom field',
    add_field: 'Add field',
    error_add: 'A field with this name already exists'
  },
  tickets: {
    description: 'Close the customer service cycle with negotiations via email.',
    open_onsurvey: 'Automatic ticket opening',
    no_ticket: 'There\'s no ticket selected.',
    first_contact: 'First contact attempt',
    attendants_history: 'Attendants History',
    select_survey: 'Select survey',
    category: {
      send_now: 'Now',
      send_24h: '24 Hours',
      send_7d: '7 Days',
      send_30d: '30 Days',
      head_title: 'Category',
      add: 'Add Category',
      new_category: 'Name',
      created: 'Category created',
      created_error: 'Something Wrong =(',
      deleted: 'Category deleted',
      to_edit: 'Edit',
      select: 'Select',
      add_sub: 'Sub Category',
      edited_success: 'Category edited',
      no_category: 'No category'
    },
    title: 'Ticket',
    questions: 'Questions',
    select_answer: 'Select an answer',
    no_message: 'No message',
    error_create_auto_message: 'Title and body of the message are mandatory.',
    error_delete_msg: 'Unable to delete message.',
    no_categories: 'No category',
    no_attendant: 'No attendant',
    attendant: 'Attendant',
    apply: 'Apply',
    cancel: 'Cancel',
    filled: 'Filled',
    unfilled: 'Unfilled',
    create: 'Create ticket',
    choose_attendant: 'Choose the attendant',
    new_ticket: 'New ticket',
    success_msg: 'Ticket created!',
    error_msg: 'Error creating ticket',
    by: 'By',
    closed: 'Closed ticket',
    opened: 'Opened ticket',
    history: 'Attendant\'s history',
    see_ticket: 'See ticket',
    all_categories: 'Categories',
    no_items: 'There is no items to show',
    no_more: 'No more items',
    load_more: 'Load more',
    status: {
      all: 'All status',
      opened: 'Opened',
      assigned: 'Assigned',
      unassigned: 'Unassigned',
      closed: 'Closed'
    },
    search: 'Search by name or email',
    any_attendant: 'Any attendant',
    close_ticket: 'Close ticket',
    open_ticket: 'Open ticket',
    confirm_action_text: 'Are you sure about closing this ticket?',
    confirm_action: 'Confirm action',
    select_category: 'Select category',
    change_attendant: 'Change attendant',
    change_category: 'Change category',
    transfer: 'Transfer',
    last: 'Last status',
    events: {
      created: 'ticket was created',
      transfered: 'transfered this ticket to',
      opened: 'opened this ticket',
      closed: 'closed this ticket',
      categorized: 'categorized this ticket to',
      no_category: 'categorized this ticket to: no category'
    }
  },
  profile: {
    title: 'My Profile',
    change_pass: 'Change password',
    name: 'Name:',
    email: 'E-mail:',
    phone: 'Phone:',
    password: 'Password:',
    confirm: 'Confirme the password:',
    wrong_pass: 'The passwords do not match.',
    sms: 'I agree to receive alerts by SMS defined by the manager',
    news: 'Notify me about plataform\'s updates',
    save: 'Save',
    success: 'Success!',
    error: 'Something went wrong. Verify your datas.',
    delete_metadata: 'Do you really want to delete this custom field?',
    smsAlert: 'SMS alert not activated. Enter your phone number',
    personalInfo: 'Personal information',
    otherInfo: 'Other information'
  },
  sendConfig: {
    success: 'Success!',
    error: 'Oops! An unexpected error has occurred.',
    seconds: 'Seconds',
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
    interval_title: 'Send settings',
    period: 'Period',
    interval_subtitle: 'Block by send',
    expiration_title: 'Search expiration time',
    expiration_subtitle: 'Sets the maximum time limit for a survey to be answered after submission',
    select: 'Select',
    interval: 'Interval',
    description: 'Prevents sending to the same recipient during the time interval',
    block: 'Block by survey',
    block_tooltip: 'Blocking can be activated by search or by default for all shipments.'
  },
  role: {
    new: 'Create a new group',
    user_title: 'Users',
    groups: 'Groups',
    name_group: 'Name',
    search: 'Search',
    value: 'Value',
    add_field: 'Add field',
    delete_field: 'Delete field',
    group_updatedAt: 'Last update',
    group_createdAt: 'Creation date',
    search_group: 'Search group',
    can_send_title: 'Surveys that this group can send',
    can_send_description: 'leave it blank if you dont want to filter by source',
    can_edit_title: 'Surveys that this group can access',
    can_edit_description: 'leave it blank if you dont want to filter by survey',
    filter_title: 'Content filter',
    filter_description: 'when defined, restricts access to content that matches this filter',
    create: 'Create Group',
    delete: 'Delete Group',
    update: 'Update Group',
    confirm_action: 'Confirm this action',
    delete_item: 'Are you shure?',
    select_users: 'Select Users for this group',
    delete_group_confirm: 'Do you want to delete the group?',
    deleted: 'Deleted Group',
    empty_group: 'You dont have a group yet',
    empty_group_msg: 'Create custom usage rules for specific user groups.',
    error_group: 'Cannot delete a group with related users'
  },
  real_time: {
    graphics: 'Gráficos',
    responses: 'Respostas',
    fullscreen: 'Fullscreen',
    not_answered: 'This question has no answers to show.',
    see_at_responses: 'Click to see at responses',
    auto_scroll: 'Autoscroll',
    see_details: 'Click to see details'
  },
  sentimentals: {
    title: 'Distribution of response feelings',
    data_not_found: 'Data not Found',
    feelings: 'Feelings',
    negative: 'Negative',
    positive: 'Positive',
    neutral: 'Neutral',
    negatives: 'negatives',
    positives: 'positives',
    neutrals: 'neutrals',
    total: 'Total',
    answer_text: 'Text Answers',
    gauge_legend: '% of comments are positive',
    check_date_filters: 'Check the date\'s filters',
    comments: 'comments',
    gauge_chart_title: 'Sentiment score',
    doughnut_chart_title: 'Sentiment overall',
    bar_chart_title: 'Sentiment timeline',
    line_chart_title: 'Sentiment score timeline',
    total_comments: 'Total of comments',
    comments_up: 'Number of comments has gone up compared to the previous period',
    comments_down: 'Number of comments has dropped compared to the previous period'
  },
  menu: {
    panel: 'My panel',
    surveys: 'Surveys',
    user_role: 'Users / Permission',
    reports: 'Reports',
    textual_analysis: 'Text Analysis',
    approaches: 'Approaches',
    my_profile: 'My profile',
    my_account: 'My account',
    send_setting: 'Send settings',
    users_group: 'Users groups',
    metadata: 'Custom fields',
    help: 'Help',
    help_sub: 'Support for the account',
    help_user: 'user',
    exit: 'Exit',
    accounts: 'Account listing',
    change_version: 'Access the old version',
    help_chat: 'Chat Support',
    help_email: 'Chat Email',
    tickets: 'Tickets',
    auto_messages: 'Automatic Messages'
  },
  searchMenu: {
    advanced_filters: 'Advanced filters',
    export_data: 'Export data'
  },
  export: {
    user: 'Export users',
    title: 'Export',
    generate_pdf: 'Generate PDF',
    cancel: 'Cancel',
    subtitle: 'The file transfer may take a few moments, depending on the number of records.',
    time_zone: 'Select the time zone',
    delimiter: 'Column separator',
    download: 'Your file is being processed in the background, you will soon have access to it.',
    download_on_email: 'The export is being processed. You will receive it by email once completed.',
    custom_delimiter: 'Type the custom separator',
    without_fields: 'Export without custom fields',
    select_fields: 'Select one or more fields',
    choose_fields: 'Choose especific custom fields to export',
    show_ticket_info: 'Export with service information',
    choose_field_metadata: 'Select the field that corresponds to the CPF.',
    subtitle_bacen: 'Select month and year to export.',
    mounth: 'Mounth:',
    year: 'Year:',
    dates_utc: 'Dates in time zone (UTC)',
    dates_local: 'Dates in local time zone',
    mounth_selected: {
      jan: 'Jan',
      feb: 'Feb',
      mar: 'Mar',
      apr: 'Apr',
      may: 'May',
      jun: 'Jun',
      jul: 'Jul',
      aug: 'Aug',
      sep: 'Sep',
      oct: 'Oct',
      nov: 'Nov',
      dec: 'Dec'
    },
    separators: {
      comma: 'Comma (default)',
      semicolon: 'Semicolon',
      tab: 'TAB',
      custom: 'Custom separator'
    }
  },
  account_config: {
    company_name: 'Company name',
    from: 'Sender',
    email_signature: 'Email signature',
    colors_settings: 'Customize colors',
    blend_mode: 'Remove white background from main logo',
    text_light: {
      label: 'Text color',
      light: 'Light',
      dark: 'Dark'
    },
    reset_password: 'The password reset email was sent.',
    change_password: 'Change password',
    type_user: 'User Role',
    validate_email: 'Invalid or already registered email',
    error_create_account: 'Invalid name or email',
    email: 'Email',
    name: 'Name',
    phone: 'Phone',
    language: 'Language',
    user_saved: 'User Permissions Updated',
    main_image: 'Main image of the company',
    main_logo: 'Main logo',
    small_logo: 'Small logo',
    created_date: 'Creation date',
    delete_user: 'Do you want to delete the user?',
    delete_confirm: {
      yes: 'Yes',
      no: 'No'
    },
    user_deleted: 'Deleted User',
    error_delete: 'Error deleting user',
    fromSystem: 'From the system',
    fromSurvey: 'From the survey',
    primaryColor: 'Primary color',
    secondaryColor: 'Secondary color',
    myCompanyInfo: 'My company information',
    configImg: 'Configue images',
    colors: {
      superiorMenu: 'Top menu, buttons',
      icons: 'Icons, buttons texts, details',
      surveyMenu: 'Survey top menu'
    },
    phone_input: {
      country_code: 'Country code',
      choose_country: 'Choose a country',
      phone_number: 'Phone number',
      example: 'Example :'
    }
  },
  dashboard: {
    survey_deleted_alert: 'This Survey has been deleted',
    survey_deleted: 'Survey Deleted',
    send_type: 'Send type',
    option: 'Option',
    views: {
      average: 'Average',
      overtime: 'Overtime',
      overall: 'Overall'
    },
    no_elegible_component: 'The survey has no component eligible to be displayed in this report.',
    add_filter: 'Add a filter',
    include_fav: 'Add to favorites',
    save_and_apply: 'Save and apply filter',
    favorites: 'Favorites',
    use_filter: 'Use this filter',
    clear_filters: 'Clear filters',
    apply_filters: 'Apply filters',
    apply_query: 'Add filters',
    type_filter_title: 'Type the filter\'s title',
    type_value: 'Type the value for the field',
    no_referrals: 'The survey selected hasn\'t referrals to show.',
    no_responses: 'No answers were found for the selected period.',
    overview: 'Overview',
    real_time: 'Dashboard',
    individual_responses: 'Responses',
    individual_response: 'Response',
    no_responses_label: 'No reviews',
    has_filter: 'Has a filter',
    config_graphic: 'Config',
    loading_graphic: 'Loading graphic...',
    loading_data: 'Loading ...',
    individual_answers: 'Answers',
    referrals: 'Referrals',
    referral: 'Referral',
    ranking: 'Ranking',
    performance: 'Performance',
    referred_by: 'Referred by',
    word_cloud: 'Word Cloud',
    therm_not_found: 'Therm not found',
    bubbles: 'Bubbles',
    list: 'List',
    period: {
      '24hours': '24 hours',
      '7days': '7 days',
      '30days': '30 days',
      '6months': '6 months',
      '12months': '12 months'
    },
    legends: 'Legends',
    success_update: 'Updated!',
    error_update: 'Error updating',
    update_card: 'Update Chart',
    missing_question_and_graphic: 'You have to choose both question and graphic!',
    error_clone_card_text: 'It is not possible to duplicate text type cards.',
    create_card: 'Create Chart',
    create_new_card: 'New Chart',
    choose_title: 'Title',
    add_filter_advanced: 'add custom filter',
    change_what_display: 'Edit Chart',
    filter_advanced: 'Advanced filter',
    not_edit_question: 'Unable to edit question',
    choose_question: 'Choose a question',
    add_new_card: 'Add new Chart',
    save_card: 'Save card',
    success_delete_card: 'Success deleting card!',
    error_delete_card: 'Error deleting card.',
    success_clone_card: 'Success cloning card!',
    error_clone_card: 'Error cloning card.',
    you_can: 'You can create a customized card from this one!',
    no_customization: 'You have no customized dashboard for this survey yet',
    no_customization_subtitle: 'Customize it and come back here!',
    success_save_config: 'Saved!',
    error_save_config: 'Error saving',
    no_data_to_show: {
      title: 'No answers to show',
      subtitle: 'Your customers opinions will be shown here.',
      unnamed: 'Unnamed'
    },
    graphics: {
      delete_graphic: 'Do you want to delete this chart?',
      clone_graphic: 'Do you want to duplicate this chart?',
      actions: 'Actions',
      showing_responses: 'By answer',
      showing_average: 'By average',
      average: 'Average',
      nvs_op1: 'Significantly better',
      nvs_op2: 'A little better',
      nvs_op3: 'Neither better, nor worse',
      nvs_op4: 'A little worse',
      nvs_op5: 'Significantly worse',
      no_overtime_matrix: 'There is no overtime graphic for matrix component.',
      vu: 'Very unsatisfied',
      un: 'Unsatisfied',
      ind: 'Indifferent',
      sat: 'Satisfied',
      satisf: 'Satisfying',
      vs: 'Very satisfied',
      vb: 'Very bad',
      bd: 'Bad',
      st: 'Good',
      vg: 'Very good',
      ex: 'Excellent',
      le: 'Little effort',
      me: 'Much effort',
      ee: 'Expected effort',
      op1: 'Yes',
      op2: 'No',
      op3: "Don't know",
      op4: 'Maybe',
      op: 'Option',
      detractors: 'Detractors',
      promoters: 'Promoters',
      neutral: 'Neutral',
      detractor: 'Detractor',
      promoter: 'Promoter',
      neutralSing: 'Neutral',
      neutralString: 'Neutral',
      generated_palette: 'Generated color palette:',
      delete_card: 'Delete chart',
      clone_card: 'Clone chart',
      choose: 'Choose a graphic',
      choose_display: 'Choose displaying type',
      choose_display_graphics: 'Choose graphic\'s displaying type',
      by_responses: 'By responses',
      by_metric: 'By metric',
      change_question: 'Choose the base question:',
      options: 'Graphics options:',
      choose_color: 'Choose the main color for your graphic:',
      percentage_distribution: 'Percentage distribution',
      online_distribution: 'Distribution in line',
      gauge: 'Gauge',
      percentage_bar: 'Percentage Bar',
      single_line: 'Single Line',
      lines: 'Lines',
      bar: 'Bars',
      horizontal_bar: 'Horizontal Bar',
      vertical_bar: 'Vertical Bar',
      pie: 'Pie',
      percentage: 'Percentage',
      percentage_pie: 'Percentage Pie',
      bubbles: 'Bubbles',
      stacked: 'Barras apiladas',
      table: 'Tabla',
      text_color: 'Choose text color:',
      customized_dash: 'Custom',
      selected_option: 'Selected option:',
      total: 'total',
      likes_in_time: 'of likes in time'
    },
    textUi: {
      choices: 'Number of choices:',
      option: 'Most chosen option:'
    },
    gauge_analytics: {
      labels: {
        terrible: 'Terrible! We recommend quick actions to change this perception in your customers',
        bad: 'Bad! There are many opportunities for improvement',
        good: 'Good! But there are possibly many opportunities for improvement',
        great: 'Great! Your company already has a great perception with customers',
        excellent: 'Great! You are in a degree of excellence with your customers'
      },
      labels_nvs: {
        bad: 'Bad, needs improvement',
        moderate: 'Moderate, can improve',
        good: 'Good',
        great: 'Great',
        excellent: 'Exceptional'
      },
      metrics: {
        NPS: {
          bad2: '-51 to -100',
          bad1: '-1 to -50',
          good: '0 to 49',
          great: '50 to 74',
          excellent: '75 to 100'
        },
        CSAT: {
          oneTo5: {
            bad: '0 to 29.9',
            good: '30 to 49.9',
            great: '50 to 79.9',
            excellent: '80 to 100'
          },
          zeroTo10: {
            bad: '0 to 29.9',
            good: '30 to 49.9',
            great: '50 to 79.9',
            excellent: '80 to 100'
          }
        }
      }
    },
    survey_overview: 'Surveys Summary',
    recent_surveys: 'Recent surveys',
    see_all: 'Show all surveys',
    see_more: 'See More',
    sendings: 'Sendings',
    openings: 'Openeds',
    drafts: 'Drafts',
    paused: 'Paused | Pauseds',
    surveys: 'Surveys',
    responses: 'Answers',
    unsubscribes: 'Unsubscribed',
    spam: 'Spam',
    clicks: 'Clicks',
    conclusion_rate: 'Conclusion rate',
    average_time: 'Average time',
    title: 'Choose a survey at the menu above',
    subtitle: 'and analyze the answers with our graphs and lists!',
    y: 'Yes',
    n: 'No',
    mb: 'Maybe',
    not_found: 'Chart not found, review filters',
    most_choosen: {
      1: 'This was the most chosen alternative among the options, ',
      2: ' of users opted for it.',
      3: 'This was the most chosen value among the options, with a total of ',
      4: ' choices.'
    },
    no_response: 'No answer.',
    msg_no_response: 'It seems there have been no answers yet',
    no_name: 'No name informed',
    no_attendant: 'Attendant not found',
    no_email: 'No email informed',
    no_phone: 'No phone informed',
    not_finished: 'Not finished',
    mail_not_opened: 'Email not opened',
    mail_not_send: 'Email not send',
    attend: 'Attend',
    send: 'Send',
    answer: 'Answer',
    last_answer: 'Last Answer',
    opening: 'Opening',
    done: 'Finished',
    matrix_component: 'Matrix component'
  },
  dashboard_card: {
    anwsers: 'Answer from | Answers from',
    sendings: '{count} send | {count} sendings',
    average_time: 'Average time: {count} min',
    anwsers_link: 'Answers',
    edit: 'Edit',
    clone: 'Clone',
    tv_dash: 'Dash',
    delete: 'Delete',
    rate: 'Answer rate'
  },
  performance: {
    select: 'Select',
    select_two: 'Select',
    selected: 'Selected',
    to_remove: 'Remove',
    mark_off: 'Mark off',
    max_elements: 'Maximum selections reached. Remove a selected option to select another.',
    no_result: 'No results found. Consider changing the search query.',
    no_options: 'The list is empty.',
    surveys_selected: 'surveys selected',
    choose_period: 'Choose the period',
    choose_param: 'Choose the param',
    ten_surveys: 'Choose until 10 surveys',
    ungroup: 'See ungrouped surveys',
    between_surveys: 'Performance between surveys',
    overtime_between_surveys: 'Overtime performance between surveys',
    sendings: 'Sendings',
    opening_rate: 'Opening Rate',
    answer_rate: 'Answer Rate',
    complete_response_rate: 'Complete Response Rate',
    abandonment_rate: 'Abandonment Rate',
    response_time: 'Average Response Time (in minutes)',
    day: '24 HOURS',
    week: '7 DAYS',
    month: '30 DAYS',
    semester: '6 MONTHS',
    year: '12 MONTHS',
    only_ten: 'You can only select until 10 surveys'
  },
  survey_store: {
    input_add_label: 'Add referral',
    input_name_placeholder: 'Name',
    input_email_placeholder: 'Email',
    input_phone_placeholder: 'Phone number',
    input_url_placeholder: 'URL',
    input_textarea: 'Type here',
    input_name: 'Type your name',
    options: {
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      other: 'Other'
    }
  },
  sendingsBar: {
    metadata: 'Metadata'
  },
  start: {
    from_stratch: 'Start from stratch',
    from_template: 'Start from template',
    survey_title: 'Survey title',
    survey_title_placeholder: 'Survey title',
    title: 'Let\'s start your first survey',
    description: 'You can either start from a stratch and do every detail or start from a market-proven template from our specialists.',
    modal_survey_title: 'Enter survey name',
    confirm_email: 'Please confirm your email in order to send the search',
    confirm_email_first: 'Verify your account via the email we sent to you, you can resend the confirmation by clicking this button below',
    confirm_email_title: 'Confirm your account to continue',
    confirm_email_resend: 'Resend!',
    confirm_email_resended: 'Email resent!',
    create: 'Create',
    error_creating: 'Error creating survey.',
    survey_templates: 'Survey Templates',
    template_title_error: 'Survey\'s title is required.'
  },
  survey: {
    settings: {
      set_question: 'Question settings',
      set_messages: 'Message settings',
      set_theme: 'Theme settings',
      set_responses: 'Response settings',
      question: 'Question',
      conditions: 'Conditions',
      messages: 'Messages',
      theme: 'Theme',
      responses: 'Responses',
      colors: 'Colors',
      logos: 'Logos',
      animations: 'Animations',
      locks: 'Locks',
      anonymity: 'Anonymity',
      start_msg: 'Start message',
      end_msg: 'End message',
      answered_msg: 'Answered message',
      no_condition_end_message: 'The default message cannot be repositioned or conditioned'
    },
    pt: 'Portuguese',
    en: 'English',
    es: 'Spanish',
    matrix: 'matrix',
    text: 'text',
    referral: 'referral',
    enum: 'custom',
    exit_whithout_save: 'Are you sure you want to quit?',
    exit_confirm: 'Continue without save',
    exit_message: 'It is possible that the changes made will not be saved.',
    save_and_open_ticket: 'Save a survey before starting setup.',
    save_and_continue: 'Save and continue',
    bold: 'Bold',
    italic: 'Italic',
    underlined: 'Underlined',
    metadata: 'Metadada',
    title_on_reports: 'Question title in reports',
    custom_title: 'Custom title',
    show_progress_bar: 'Show progress bar',
    hide_button_previous: 'Hide back button',
    details: 'Details',
    metadata_value: 'Field value',
    display_rules: 'Display when',
    all_rule: 'All conditions are true',
    any_rule: 'Any condition is true',
    is_op: 'is',
    is_not_op: 'is not',
    hide_logo: 'Hide logo',
    hide_title: 'Hide title',
    conditions_title: 'Conditions',
    for_metadata: 'For custom fields',
    for_responses: 'For responses',
    add_condition: 'Add condition',
    new_condition: 'New condition',
    confirm: 'Confirm',
    count_of_conditions: 'Total conditions',
    condition_title: 'This question has an answer in the title',
    condition_title2: 'Delete the answer indication to be able to move the question',
    condition_position: 'You have conditions that depend on the position of this question!',
    condition_position2: 'You have set up conditional flows for this survey that involve the position of this question. If you choose to change the position of this question, we\'ll need to delete all conditions that depend on it.',
    conditions_msg: 'You have conditions that depend on this question!',
    conditions_msg2: 'You have set up conditional flows for this survey that involve this question. If you choose to change the question, all conditions that depend on it will be deleted.',
    auto_messages_title: 'When deleting or changing the question component, all settings linked to it will be lost.',
    auto_messages_msg: 'Do you wish to continue?',
    continue: 'Continue',
    cancel: 'Cancel',
    lock_time: 'Response blockin by time',
    send_lock_time: 'Blocking submissions by time',
    send_block_description: 'This sending block per search will always be considered if it is active, even though in the account settings</b> there is already a sending block configured.',
    expire_lock_time: 'Expire submission for a set time',
    expire_block_description: 'This rule configures that the respondent has a period to respond to the survey.',
    config_blocking: 'Configure locks',
    unique_key: 'Single key for lock',
    unique_key_desc: 'Email or phone, or custom field',
    config_anonymous: 'Configure anonymity',
    seconds: 'Seconds',
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
    email_or_phone: 'Email or Phone',
    add_http: 'Add http: // ou https: // to the url',
    url_redirect: 'URL for redirection',
    time_for_redirect: 'Time to redirect (in seconds)',
    redirect: 'Redirect',
    no_animation: 'No animation',
    components: {
      csat: 'Monitor satisfaction at strategic points on the journey',
      nps: 'Monitor recommendation probability and loyalty',
      kpi: 'Monitor the performance of various organizational aspects',
      matrix: 'Evaluate multiple items at once',
      ces: "Monitor the customer's effort and possible difficulties",
      text: 'Text components for your search',
      referral: 'Request referrals from your customers',
      enum: 'Present a list of options with multiple choice components',
      nvs: "See the customer's opinion compared to the competition",
      cev: "Assess the customer's emotional bond with your brand"
    },
    gradient: 'Use colored buttons (gradient)',
    add_question: 'Add question',
    bread_edit: 'Survey Edit',
    bread_setting: 'Send Settings',
    bread_send: 'Survey Send',
    navbar_move: 'Move',
    navbar_clone: 'Clone',
    navbar_delete: 'Delete',
    select_question: 'Choose my question',
    back_button: 'Back',
    draft_saved: 'Draft saved!',
    save_draft: 'Save draft',
    draft_not_saved: 'An error occurred on save',
    question_error: 'Select the type of the question',
    save_and_send: 'Save and send',
    toggle_title: 'Show title',
    toggle_description: 'Show description',
    toggle_action_button: 'Show action buttons',
    toggle_use_start_message: 'Use starting message',
    toggle_image_on_top: 'Image on top',
    toggle_logo_on_top: 'Logo on top',
    toggle_image_in_footer: 'Image in footer',
    toggle_allow_come_back: 'Allow come back after answering',
    toggle_save_as_template: 'Save as template',
    toggle_random: 'Set Random',
    toggle_required: 'Required Answer',
    toggle_others: 'Others Option',
    template_title_input: 'Template\'s title',
    template_description_input: 'Template\'s description',
    select_template_category: 'Template\'s category',
    limit_of_sending: 'Your voice has exceeded the free shipping limit',
    start_button_text: 'Button text',
    embedLink: 'Embed link',
    linkLabel: 'Link label',
    linkUrl: 'URL',
    language: 'Question language',
    general_config: 'General',
    messages: 'Messages',
    start_message_config: 'Start message',
    end_message_config: 'End message',
    current_question_config: 'Question',
    survey_settings: 'Survey settings',
    survey_already_sent: 'This action can not be performed. This survey already has answers',
    field_required: 'Field required',
    min_length: 'Minimum of characters:',
    max_length: 'Maximum of characters:',
    define_placeholder: 'Instruction:',
    min_options: 'Minimum of options:',
    max_options: 'Maximum of options:',
    limit_answer: 'Limit the number of choices',
    previous_question_label: 'Previous question button label:',
    multiple_button: 'Button',
    label_others: 'Label others',
    answered_message_config: 'Already answered',
    referral_options: 'Referral options',
    ne_option: 'Name + Email',
    np_option: 'Name + Phone',
    nep_option: 'Name + Email + Phone',
    nepu_option: 'Name + Email + Phone + URL',
    change_placeholders: 'Change fields instructions',
    left_label: 'Left label',
    right_label: 'Right label',
    choose_survey: 'Choose a survey',
    survey_not_found: 'Survey not found',
    questions_error: 'Error getting questions',
    colors_settings: 'Colors settings',
    logos_settings: 'Logo settings',
    logos_title: 'right at the bottom',
    logos_description: 'This image will be used at the bottom of each question.',
    add_input: 'Add Field',
    show_input: 'Show Field',
    required_field: 'Field Required',
    instruction: 'Instruction',
    mapping_type: 'Select de field type',
    delete_input: 'Delete field',
    copy_of: 'A copy of',
    option_1: 'option 1',
    option_2: 'option 2',
    option_3: 'option 3',
    option_added: 'option added ',
    option_other_added: 'Other option added',
    others: 'Others',
    placeholder: 'Placeholder',
    error_saving: 'Error saving survey.',
    required_question_sing_1: 'The text in question',
    required_question_sing_2: 'is required',
    required_question_plu_1: 'The texts in questions',
    required_question_plu_2: 'are required.'
  },
  questionsNav: {
    message_title: 'Insert the message here',
    start_message: 'Inital message',
    end_message: 'Final message',
    answered_message: 'Already answered',
    answered_message_value: 'This survey was already answered.',
    add_question: 'Add question',
    add_end_message: 'Add final message',
    details: {
      value: 'Add details to the message here.',
      link: {
        label: 'Text for the link',
        value: 'URL'
      }
    },
    sms_default: ': Tell us about your experience and help us improve our services.',
    survey: 'Survey',
    nextLabel: 'Next',
    question_title: 'Question\'s title here',
    question_value: 'Question here'
  },
  theme: {
    background: 'Background',
    header: 'Header',
    header_text: 'Header text',
    survey_background: 'Survey background',
    survey_text: 'Survey text',
    buttons: 'Buttons',
    buttons_text: 'Buttons text',
    footer: 'Footer',
    footer_buttons: 'Footer buttons',
    hover_buttons: 'Sombra dos botões',
    footer_text: 'Footer text',
    clear_theme: 'Clear theme',
    save: 'Tema salvo'
  },
  analytics: {
    send_analytics: 'Analyze answers',
    sendings: 'Sendings',
    answers: 'Answers',
    time: 'Average time',
    anonymous_reply: 'Anonymous reply'
  },
  components: {
    csat_satisfied: 'Satisfied',
    csat_not_satisfied: 'Unsatisfied',
    nes_big_effort: 'Big effort',
    nes_little_effort: 'Little effort',
    nps_very_likely: 'Would indicate',
    nps_unlikely: 'Would not indicate',
    text: 'Comment your answer',
    ces: {
      totally_disagree: 'I Totally disagree',
      disagree: 'I Disagre',
      partially_disagree: 'Partially Disagree',
      neutral: 'Neutral',
      partially_agree: 'Partially agree',
      agree: 'I agree',
      totally_agree: 'I Totally agree'
    }
  },
  sendhub: {
    generate_qrcode: 'Generate QR CODE',
    new_qrcode: 'Generate new QR CODE',
    copy_code: 'Copy',
    success_copy: 'Code copied successfully.',
    error_copy: 'Could not copy.',
    show_code: 'See code',
    hide_code: 'Close code',
    yes: 'Yes',
    no: 'No',
    limit: 'Limit of 20 thousand lines, we suggest sending by spreadsheet in this case.',
    limit_wpp: 'Limit of 5000 lines reached',
    code_to_embed: 'Code to embed in your website',
    column: 'Column',
    site_url: 'Site URL',
    widget_position: 'Widget position',
    widget_closePermanently: 'Close Permanently',
    widget_top_left: 'Top left',
    widget_middle_left: 'Middle left',
    widget_bottom_left: 'Bottom left',
    widget_top_right: 'Top right',
    widget_middle_right: 'Middle right',
    widget_bottom_right: 'Bottom right',
    widget_bottom: 'Bottom',
    widget_popup: 'Popup',
    widget_button: 'Action button (right)',
    widget_buttonB: 'Action button (left)',
    show_close_button: 'Show close button',
    widget_size: 'Size (px)',
    height: 'Height (px)',
    widget_time_to_open: 'Time to open (seconds)',
    widget_colors: 'Colors',
    background_color: 'Background',
    text_buttons_color: 'Button\'s texts',
    buttons_color: 'Buttons',
    texts_color: 'Texts',
    email_colors: 'Email\'s colors',
    type_one_by_one: 'Type one by one',
    import_contacts: 'Import contacts',
    file_types: '.csv , .xls or .xlsx file',
    go_to_dashboard: 'Go to dashboard',
    emails_with_error: 'Emails with error',
    phones_with_error: 'Phones with error',
    validating_survey: 'Sending the information',
    contacts_select: 'Contacts selection',
    sent_processing: 'The file is being processed',
    sent_success: 'Shipping processed!',
    explain_select: 'You can add contacts one by one or import them using one of the options beside. If you prefer, copy and paste your contacts table here. The contacts will be automatically added here.',
    column_select: 'Select a column',
    add_line: 'Add line',
    add_column: 'Add column',
    fix_contacts: 'Insert correct data',
    some_contacts: 'It wasn\'t sent for some contacts.',
    save: 'Save',
    clean: 'Clean all',
    is_to_clean: 'Want to clean everything?',
    copy: 'Copy',
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    from_input: 'From',
    subject_input: 'Subject',
    email_text: 'Click here to insert the email text',
    set_image_text: 'Click here to add an image',
    toggle_embedded_question: 'Embed first question',
    toggle_image_on_top: 'Image on top',
    action_button_text: 'Next button text',
    type_email: 'By customized email',
    type_link: 'Generating a link to send as you want',
    type_sms: 'Sending the survey\'s link by SMS',
    sms_warn: 'Contact the support team to enable this option',
    type_widget: 'Using our widget on your website',
    type_email_and_sms: 'Sending by email and SMS at the same time',
    type_totens: 'Show on totens',
    toten: 'Totem approach',
    toten_hub_text: 'It is really easy to offer the survey to the visitors at your store or event, without any registering. Just let the address below available on a device connect to the Internet.',
    link: 'Link to send',
    link_survey: 'Survey link',
    duplicate: 'Duplicate',
    invalid: 'Invalid',
    send_phone: 'Phone',
    status: 'Status',
    sendings_total: 'Sendings total',
    failures_total: 'Failures total',
    duplicates_total: 'Duplicates total',
    view_more: 'View more',
    view_less: 'View less',
    sendings_precessed: 'Sedings processed',
    images: 'Images',
    upload: {
      riskTitle: 'There is already a shipment being processed',
      riskMessage: 'We are processing a spreadsheet submission for this survey, if a new submission is made, the previous one will still be processed.',
      riskButton: 'Send anyway'
    },
    linkHub: {
      allowMoreThanOne: {
        title: 'Allow more than one respondent',
        description: 'Allows more than one person to answer your survey. It is ideal when you want to publish your survey on a site or on a social network without collecting information from respondents.'
      },
      showEndSurveyButton: {
        title: 'Display terminate search option',
        description: 'Displays the end button, so you can end the search at any point.'
      },
      endSurveyByInactivity: {
        title: 'Automatically quit idle search',
        description: 'Automatically pauses an idle search after a time interval.'
      },
      colors: {
        button: 'Choose button color',
        background: 'Choose background color',
        font: 'Choose font color'
      }
    },
    smsHub: {
      title: 'Edit',
      label: 'Survey Link',
      label2: 'Answer',
      charactersLimit: 'Character limit',
      charactersRemaining: 'Number of characters remaining',
      smsMetadata: 'You are not allowed to sign metadata',
      showEndSurveyButton: {
        title: 'Display terminate search option',
        description: 'Displays the end button, so you can end the search at any point.'
      },
      endSurveyByInactivity: {
        title: 'Automatically quit idle search',
        description: 'Automatically pauses an idle search after a time interval.'
      },
      error_messages: 'Error getting SMS. Try again.'
    },
    emailHub: {
      input_error: 'Please fill in the sender field and email subject',
      input_success: 'Email settings was saved'
    },
    emailSmsHub: {
      step_one: 'Step 1: Configure the e-mail',
      step_two: 'Step 2: Configure the SMS'
    },
    totenHub: {
      shut_in: 'Shut in the survey in:',
      seconds: 'seconds'
    },
    empty_table: 'Type the contacts in the table',
    existing_column: 'Existing Column',
    widget_action_button: {
      startOpen: 'Display on start',
      closeActionButton: 'Close button',
      customizedImage: 'Custom image'
    },
    configLayout: 'Configure layout and size',
    configTitle: 'Configurar título',
    configWidget: 'Set up Widget',
    font: 'Font',
    textProps: {
      bold: 'Bold',
      underline: 'Underline',
      italic: 'Italic'
    },
    ofTitle: 'Title',
    ofMessage: 'Message',
    sendInfo: 'Shipping information',
    borders: 'Borders',
    hints: {
      widgetPosition: 'Position where the widget will appear on the screen',
      startOpen: 'The widget content will appear as soon as the page is loaded',
      closeActionButton: 'Action button close button',
      customizedImage: 'Action button icon',
      customizedImageLink: 'Action Button Icon Link',
      backgroundColorActionButton: 'Action button background color',
      width: 'Widget width',
      height: 'Widget height',
      widgetTimeToOpen: 'Widget open time',
      borders: 'Curvature of edges and close button',
      showCloseButton: 'Close button display',
      closePermanently: 'When clicked the button closes the widget permanently',
      font: 'Font type, size and decoration',
      backgroundColorTitle: 'Title background color',
      textButtonColor: 'Button text color',
      buttonColor: 'Button background color',
      textsColor: 'Title text color',
      backgroundColorMessage: 'Message background color',
      textColorMessage: 'Message text color'
    }
  },
  date_send: {
    when_send: 'When should we send?',
    send_now: 'Send Now',
    select_date: 'Schedule shipping',
    date: 'Date:',
    schedule: 'Time:',
    send: 'Send',
    valid_date: 'Select a valid date',
    date_time: 'Select the date and time'
  },
  seeds: {
    copy_data: 'Copy',
    copy_success: 'Copied successfully.',
    copy_fail: 'Could not copy.',
    schedule_title: 'If you wish, schedule the shipment',
    schedule_placeholder: 'Schedule Shipping',
    success: 'Sending processed successfully.',
    csv_duplicated_headers: 'There are columns with repeated names, please correct and try uploading again.',
    csv_special_character_info: 'Special characters will be converted to underscores if found in column headings',
    csv_null_headers: 'There are unnamed columns, please correct and try to upload again.',
    csv_column_error: 'UNNAMED',
    csv_empty_headers: 'Map the columns in the 3rd step.',
    spreadsheet_import: 'Spreadsheet Import',
    inform_1: 'If you wish, you can proceed to the next step without the need to use our template spreadsheet.',
    inform_2: 'Import your upload spreadsheet, confirm that the data is correct in the display and then proceed to the next step. (We accept only .csv .xls .xlsx files with UTF-8 formatting)',
    encode_error: 'We only accept UTF-8 formatted files',
    download_model: 'Download Template Spreadsheet',
    import_file: 'Import file',
    map_headers: 'Map columns',
    step: 'Step',
    step_1: 'Download model',
    step_2: 'Upload file',
    step_3: 'Set up and send',
    next_step: 'Next step',
    previous_step: 'Previous step',
    send: 'Send',
    preview: 'This view is just a preview',
    count_lines: 'Total lines in file:',
    map_inform_1: 'Configure which fields in your spreadsheet refer to Name, Email and / or Phone.',
    map_email: 'Email column',
    map_phone: 'Phone column',
    map_name: 'Name column',
    ignore_column: 'Ignore column',
    select: 'Select...',
    your_file: 'YOUR FILE',
    your_match: 'MATCH WITH',
    file_size: 'The file size must be less than ',
    file_type: 'Incorrect file format, we only accept .CSV, .xls or .xlsx file',
    new_metadata: '+ Create new custom field',
    fill: 'Fill in the fields'
  },
  templates: {
    error_getting_templates: 'Error on getting templates.',
    error_saving_template: 'Error on saving template.',
    error_using_template: 'Error on using template.',
    survey_title_template: 'Type the survey\'s title',
    use_template: 'Use template',
    edit_template_details: 'Editar template\'s details',
    template_title: 'Template\'s title',
    template_description: 'Template\'s description',
    popular: 'Define as popular',
    draft: 'Draft'
  },
  emptyState: {
    title: 'No replies to show',
    description: 'Customer reviews will be shown here.'
  },
  expired: {
    title: 'Whoops!',
    token_expired: 'Your reset password token expired',
    try_again: 'Try again',
    know_more_binds: 'Meet binds.co',
    signin: 'Login'
  },
  resetPassword: {
    title: 'New Password for',
    action: 'Save',
    newPass: 'New password',
    confirmPass: 'Confirm new password'
  },
  msg: {
    account: 'Account',
    account_description: 'Account description'
  },
  global: {
    pick_date: 'Pick the date',
    link_copied: 'Link copied',
    unknown_error: 'An unknown error ocurred.',
    load_more: 'Load more'
  },
  http: {
    notFound: 'The page you tried to access was not found :(',
    you_cant: 'Sorry, but you have no permission to access this page.',
    unmapped: 'An unknown error just ocurred in the server.'
  },
  surveyList: {
    filter_options: {
      no_filter: 'No Filter',
      title_az: 'Title A-Z',
      title_za: 'Title Z-A',
      recent_date_send: 'Recent Send Date',
      old_date_send: 'Earliest Send Date',
      most_sent: 'Most Sent',
      least_sent: 'Least Sent',
      most_answered: 'Most Answered',
      least_answered: 'Least Answered',
      average_time_ascending: 'Ascending Average Time',
      average_time_descending: 'Descending Average Time',
      expired: 'Expired',
      not_expired: 'Not expired',
      health_ascending: 'Health Ascending',
      health_descending: 'Health Descending'
    },
    health_survey: 'Search health',
    id_copied: 'Copied survey ID.',
    expired: 'This search has expired. Contact support if you want to reactivate it.',
    my_surveys: 'My Surveys',
    search_by_title: 'Search by title',
    active_since: 'Active since',
    last_send: 'Last send',
    copy_id: 'Copy ID',
    not_send: 'Survey not sent',
    created_since: 'Creation date',
    delete_question: 'Are you sure you want to delete this Survey?',
    delete_error: 'Error deleting survey',
    delete_success: 'The survey was deleted!',
    delete_survey: 'Delete Survey',
    filter_author: 'Filter by author',
    filter_category: 'Filter by category',
    filter_type: 'Filter by type',
    new_survey: 'New Survey',
    templates: 'Templates',
    error_go_to: 'Something went wrong. Try again later.',
    you_cant: 'You have no permission for it.',
    survey_cloned: 'Cloned survey',
    survey_cloned_error: 'Error in cloned survey.',
    no_surveys_msg: 'You don\'t have surveys yet.',
    create_first_survey: 'Create your first one!',
    click_add_title: 'Click here to add the title',
    click_add_description: 'Click here to add the description',
    clone_survey: 'Clone survey',
    type_title: 'Type the title',
    go_templates: 'Go to templates',
    modal_templates_title: 'Looking for inspiration',
    modal_templates_msg: 'We have prepared several examples for you. Choose a template to customize and start collecting responses in no time.',
    expire: 'expire',
    activate_capital: 'Activate',
    activate: 'activate',
    expire_in: 'Expires in',
    expire_survey: 'Expire survey',
    survey_expired: 'Expired survey',
    activate_survey: 'Remove search expiration date',
    are_you_sure: 'Are you sure you want to',
    this_survey: 'this survey?',
    success_expire: 'Expiration date entered successfully',
    select_date: 'Select a date to expire the survey',
    error_expire: 'Error expiring survey',
    success_activate: 'Success activating survey',
    error_activate: 'Error activating survey'
  },
  advanced_filters: {
    delete_filter: 'Are you sure you want to delete?',
    select_type: 'Select Type',
    select_option: 'Select Option',
    select_conditional: 'Select Conditional',
    title: 'Advanced filters',
    filter_by_tag: 'Tag filter',
    mark_as_solved: 'Marked as resolved?',
    done_at: 'Answered to the end?',
    response_type_text: 'Text responses',
    response_text_true: 'Text responses that have been completed',
    annotations: 'Contains annotations?',
    contact_name: 'Contact name',
    contact_email: 'Contact email',
    contact_phone: 'Contact phone',
    contact_code: 'Contact code',
    contact_cpf: 'Contact CPF',
    attendances_created: 'Attendances criated?',
    attendant_name: 'Name of the attendant',
    ticket_status: 'Ticket status',
    responded_at: 'Answered?',
    clean_empty_questions: 'Do not display unanswered questions',
    attendant_user: 'Customer Service Representative',
    filter_category: 'Category of the service',
    filter_subcategory: 'Subcategory of the service',
    placeholder_category: 'Select category',
    placeholder_subcategory: 'Select subcategory',
    fields: {
      contains: 'Contains',
      does_not_contain: "Doesn't contain",
      is_equal: "It's the same",
      is_different: "It's different",
      is_define: 'It is defined',
      is_not_define: 'It is not defined',
      is_equal_to: 'Equals',
      is_diffrent_from: "It's different from",
      promoters: 'Promoters',
      neutrals: 'Neutrals',
      detractors: 'Detractors',
      satisfied: 'Satisfied',
      unsatisfied: 'Unsatisfied',
      less_effort: 'Little effort',
      expected_effort: 'Moderate effort',
      much_effort: 'Much effort',
      yes: 'Yes',
      no: 'No',
      sent: 'Sent',
      rejected: 'Rejected',
      deferred: 'Deferred',
      spam: 'Spam',
      bounced: 'Bounced',
      soft_bounced: 'Soft-bounced',
      Unsub: 'Unsub',
      gte: 'Greater than or equal to',
      lte: 'Less than or equal to',
      gt: 'Greater than',
      lt: 'Less than'
    },
    categories: {
      tags: 'Tags',
      responses: 'Answers',
      attendance: 'Service',
      contact: 'Contact',
      metadata: 'Custom field',
      questions: 'Questions',
      survey: 'Research is',
      channel: 'Send Channel',
      date_send: 'Submission Date'
    }
  },
  tags: {
    open_tag_manager: 'Open tag manager',
    add_tag: 'Add tag',
    edit_tag: 'Edit tags',
    exist_tag: 'This tag already exists',
    confirm: 'Confirm',
    remove_tag: 'Remove tag',
    create_new_tag: 'Create new tag',
    create: 'create',
    new_tag_name: 'New tag name',
    new_tag_color: 'New tag color',
    tag_name: 'Tag name',
    tag_color: 'Tag color',
    update_tag: 'update',
    see_all: 'see all',
    see_more: 'see more',
    alert_remove: 'Do you really want to remove this tag?',
    subtitle_alert: 'You are about to remove this tag.',
    see_more_responses: 'See all answers'
  },
  approaches: {
    status: 'Status',
    rejected: 'Rejected',
    deferred: 'Deferred',
    sendings: 'sendings',
    deliveries: 'deliveries',
    delivery_rate: 'delivery rate',
    openings: 'openings',
    clicks: 'clicks',
    answers: 'Answers',
    answer: 'Answer',
    non_existing_data: 'Non-existing data',
    mark_solved: 'Mark as resolved',
    response_rate: 'response rate',
    bounces: 'Bounced',
    softBounces: 'Soft bounced',
    spam: 'Spam report',
    no_results: 'No results found. Check the filters.',
    copy_link: 'Copy survey link',
    archive: 'Archive',
    delete: 'Delete',
    already_archived: 'Already archived.',
    copied: 'Link copied!',
    profile: 'User profile',
    active: 'Active',
    archived: 'Archived',
    all: 'All',
    user_not_found: 'User not found.',
    unarchive: 'Unarchive',
    not_answered: 'Not answered',
    not_opened: 'Not opened',
    confirm_delete: 'Are you sure to delete this sending and all its answers?'
  },
  pageUsers: {
    name: 'Name',
    user: 'User',
    users: 'Users',
    delete_user: 'Delete user',
    admin: 'Administrator',
    new_user: 'New user',
    type_user: 'User type',
    user_features: 'User features',
    email: 'Email',
    phone: 'Phone',
    created_at: 'Creation date',
    activity_at: 'Last activity',
    search_by_name_email: 'Search by name or email',
    search_group: 'Search group',
    message_group: 'The user who is not in any group will not have content restrictions.',
    portuguese: 'Portuguese',
    english: 'English',
    spanish: 'Spanish',
    all_groups: 'All',
    marked_groups: 'Marked',
    unchecked_groups: 'Unchecked',
    new_account_info: 'New account information',
    account_info: 'Account information',
    profile_config: 'Profile settings',
    group_config: 'Group settings',
    request_password: 'Request new password',
    cancel: 'Cancel',
    tip_password: 'A new password will be defined through your registered email',
    editUser: 'Editar usuario',

    tooltip_contact: 'Enabling option "Hide contacts" will automatically disable option "Tickets".',
    tooltip_metadata: 'Enabling option "Hide metadata" will automatically disable option "Metadata editing".'
  },
  ranking: {
    moreAnswered: 'Most answered',
    lessAnswered: 'Less answered',
    highlights: 'Highlights',
    search: 'Search by metadata',

    hint_nps: 'Evaluation by metric',
    hint_enum: 'Number of selected options',

    best: 'best rated',
    worse: 'worst rated',
    movimentations: 'Movements',
    responses: 'Responses',
    comparison: 'Compared to the last period',
    metadata: 'Metadata',
    quantity: 'Qty',
    nothing_to_show: 'We have no items to show here. Check the filters!',
    custom_field: 'Custom field',
    question: 'Question',
    min_sample: 'Minimum sample',
    first_metadata1: "You still don't have custom fields",
    first_metadata2: '(with the "rankable" option active)',
    redirect_to_metadata: 'Create rankable field',
    noQuestions: 'The survey has no questions that can be displayed in the ranking',
    cannotExport: 'Survey cannot be exported, check the filters',

    // export
    positionVariation: 'Position variation',
    scoreVariation: 'Variation',
    score: 'Score',
    position: 'Position',
    average: 'Average',
    beforeResult: 'Previous result',

    // metrics
    abbreviation: {
      detractor: 'D',
      neutral: 'N',
      promoter: 'P',
      yes: 'Y',
      no: 'N',
      maybe: 'MB',
      insatisfied: 'INSAT',
      satisfied: 'SAT',
      mutch_effort: 'ME',
      expected_effort: 'EE',
      less_effort: 'LE',
      don_t_know: 'DN',
      excellent: 'EX',
      very_good: 'VG',
      bad: 'BD',
      very_bad: 'VB',
      significantly_better: 'SB',
      a_little_better: 'AB',
      neither_better_nor_worse: 'NB',
      a_little_worse: 'AW',
      significantly_worse: 'SW'
    }
  },
  notifications: {
    notify_settings: 'Notification Settings',
    notify_settings_health: 'Configuring Health notifications',
    notifications: 'Notifications',
    no_group: 'No registered groups',
    apply: 'Apply',
    cancel: 'Cancel',
    roles: 'Roles',
    users: 'Users',
    smsDisabled: 'SMS sending is disabled for this user'
  },
  redirect: {
    redirect: 'Redirect',
    time_Redirect: 'Time to redirect (in seconds)',
    url_redirection: 'Url for redirection',
    erro_redirect: 'Add http: // or https: // to the url'
  },
  TWO_FACTOR: {
    auth: 'Two-factor authentication',
    verification_code: 'Verification code',
    we_send_a_code: 'We sent a verification code to',
    inside_account: 'registered',
    inform_the_code: ', please enter the code to continue.',
    send_new_code_by: 'Send new code by',
    wait_to_send_again: 'Wait to send again',
    dialog: {
      expired_code_message: 'Your code has expired, we have sent a new one, please wait.',
      fill_in: 'Fill in all fields to continue.',
      success_send: 'Code successfully resent'
    }
  },
  userFeatures: {
    questionsMetadata: 'Advanced Editor',
    seedBatches: 'batches',
    metadataEditing: 'Metadata editing',
    sendingsSurveyLink: 'Get sending link',
    sendings: 'Sendings',
    trackTerms: 'Binds Voice Score',
    bindsSupport: 'Binds Support',
    surveyLink: 'Share survey link',
    tickets: 'Tickets',
    surveyEdit: 'Survey editing',
    loginWithAzure: 'Login with SSO',
    hiddenSendingContacts: 'Hide contacts',
    hiddenSendingMetadata: 'Hide metadata',
    disableExports: 'Disable Export'
  },
  pageTitles: {
    login: 'Login',
    dashboard: 'Create your survey',
    surveys: 'Surveys',
    template: 'Survey templates',
    send: 'Send survey',
    email: 'E-mail - Send survey',
    sms: 'SMS - Send survey',
    widget: 'Widget - Send survey',
    link: 'Link - Send survey',
    totem: 'Totem - Send survey',
    panel: 'My dashboard',
    overview: 'Survey overview',
    individual: 'Survey responses',
    referrals: 'Referrals',
    ranking: 'Ranking',
    performance: 'Performance surveys',
    realtime: 'Realtime results',
    approaches: 'Approaches surveys',
    cloud_words: 'Word cloud',
    sentimental: 'Sentimental',
    ticket: 'Tickets',
    users: 'Users',
    roles: 'User group',
    account_list: 'Account list'
  },
  preview: {
    view: 'View',
    add_fields: 'Add custom fields in order to test the conditional flow',
    field_value: 'Field value',
    field: 'Field',
    clear_all: 'Clear all',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    added_message: 'This field has already been added, but you can change its value here.',
    value_changed: 'The value was updated!',
    add_tooltip: 'Add field',
    select_field: 'Select a field',
    simulate_survey: 'Simulate survey',
    reload_survey: 'Reload survey'
  },
  bvs: {
    name: 'Name',
    deselect_all: 'Deselect all',
    search_on_twitter: 'Search on twitter',
    current_brands: 'Current brands',
    no_data: 'No data to display, check filters.',
    binds_voice_score: 'Binds Voice Score',
    date_to: 'To',
    date_from: 'Select the period',
    filter_sentiment: 'Filter sentiment',
    filter_sentiment_placeholder: 'All',
    search_text: 'Search text',
    search_text_placeholder: 'Search text',
    filter_user: 'Filter by user',
    user_selecteds: 'selected users',
    all_users_selecteds: 'Users',
    confirm: 'Confirm',
    cancel: 'Cancel',
    search: 'Search',
    sentiment_overtime_title: 'Mentions overtime',
    sentiment_synthetic_title: 'Binds Voice Score',
    sentiment_mentions_title: 'Mentions',
    sentiment_word_cloud_title: 'Hashtags',
    sentiment_bvs: 'BVS',
    sentiment_nps: 'NPS',
    sentiment_mentions: 'MENTIONS',
    word_cloud_quote: 'Quote',
    word_cloud_amount: 'Amount',
    word_cloud: 'Word cloud',
    word_cloud_list: 'List',
    modal_title: 'Binds Voice Score',
    modal_step1: 'Searching for your brand...',
    modal_step2: 'Extracting from twitter and other places...',
    modal_step3: 'Analyzing feelings...',
    modal_step4: 'Preparing the first set of mentions...',
    modal_step5: 'Adjusting the graphics...',
    modal_step6: 'Its almost ready, come back in a few minutes...',
    search_not_found: 'User not found or already exists in suggestion list.',
    theme: 'Light',
    type_overtime: 'Quantity',
    delete_term: 'Remove',
    updating_data: 'Searching data...',
    config_users: 'Configure users',
    social_media: 'Social Media',
    document_title: 'My Sources',
    placeholder: 'Enter username',
    not_found: 'Not found',
    user_exist: 'Its already on your list',
    create_custom_brand: 'Create your own custom brand',
    create_success: 'Successfully created',
    verified_user: 'Verified User',
    create_date: 'Creation date',
    public_brand: 'Public brand',
    custom_brand: 'Custom brand',
    has_terms: 'It has terms',
    looking_for_posts: 'We are looking for posts for this brand',
    delete: 'Delete',
    add: 'Add',
    change: 'Change',
    edit: 'Edit',
    want_to_add: 'Do you want to add this brand?',
    subtitle_to_add: 'You are about to add this brand to your account.',
    want_to_remove: 'Do you really want to remove this brand?',
    subtitle_to_remove: 'You are about to remove this brand from your account.',
    limit_reached: 'Account limit reached',
    change_name_title: 'Want to change your brand name?',
    change_name_subtitle: 'You are about to change your brand name.',
    sentiment: {
      all: 'All',
      positive: 'Positive comments',
      neutral: 'Neutral comments',
      negative: 'Negative comments'
    },
    share: {
      comments: 'Comments',
      retweets: 'Retweets',
      likes: 'Likes',
      view_on_page: 'View on page'
    },
    tab_yours_brands: 'Your marks',
    tab_upload: 'Upload',
    tab_history: 'History',
    history: {
      title: 'Upload history',
      total_registers: 'Registers',
      all: 'All',
      total: 'Total',
      done: 'Concluded',
      processing: 'Processing',
      pending: 'Pending',
      error: 'Error',
      title_list_user: 'User',
      title_list_brand: 'Mark',
      title_list_date: 'Date',
      title_list_status: 'Status',
      title_list_rows: 'Number of lines',
      title_list_rows_created: 'Last line created'
    },
    upload: {
      title: 'When importing your spreadsheet for submission, confirm that the data is correct.',
      title_date: 'The date format must be in:',
      title_format: {
        _1: 'We only accept files',
        _2: '.csv .xls .xlsx',
        _3: 'with formatting',
        _4: 'UTF-8'
      },
      import_file: 'Import file',
      max_size_file: 'Maximum file size:',
      fields_required: 'Required fields:',
      text: 'Text',
      save: 'Save',
      back: 'Back',
      next: 'Next'
    }
  },
  whatsapp: {
    hello: 'Hello',
    text_default: 'Tell us about your experience and help us improve our services:',
    text_footer: 'your opinion is very important to us',
    text_footer_2: '- its quick -',
    text_button: 'To respond',
    explain_dynamic_text: 'Mandatory dynamic text, subject to occasional changes according to our META templates.',
    today: 'Today',
    title_template: 'Select your template',
    config_template: 'Configure template',
    config_name: 'Configure display name',
    config_img: 'Configure display image',
    name_account: 'Account name',
    name_user: 'Username',
    tooltip_help_name: 'Name used in message display, character limit: 120.',
    tooltip_help_image: 'Image used in message body display',
    limit_exceeded: 'Send limit higher than allowed',
    alert_title_empty: 'Empty display name. The message has not been updated.'
  },
  resend: {
    tooltip_info: 'Resend only considers active searches and approaches such as E-mail, SMS and Whatsapp.',
    label_resend: 'Resend',
    resend: 'Resend',
    title_schedule: 'Schedules',
    label_schedule: 'schedules',
    search_by_title: 'Search by title',
    select_resend: 'Select Send Type',
    selected_surveys: 'Selected Send',
    config_resend: 'Configure',
    advance: 'Next',
    cancel_resend: 'Cancel send',
    total: 'Total',
    type: {
      email: 'E-mail',
      sms: 'SMS',
      whatsapp: 'Whatsapp'
    },
    survey: 'Survey',
    type_resend: 'Shipping types',
    details_resend: 'Submission Summary',
    total_approaches: 'Total approaches',
    close: 'Close',
    cancel: 'Cancel',
    continue: 'Continue',
    back: 'Back',
    order_by: 'Order by',
    select_field: 'Select the field',
    create: 'Create',
    field: 'Field',
    label: 'Label',
    value: 'Value',
    remove: 'Remove',
    confirm: 'Confirm',
    field_already_exists: 'This field already exists',
    created_success: 'Successfully created',
    select_date: 'Select date',
    write_here: 'Type here',
    title_resend: 'Do you want to add a title for this resend?',
    title_cancel_resend: 'Do you really want to cancel this resend?',
    label_cancel_resend: 'You are about to cancel this resend.',
    no_data_list: 'There are no schedules to display at this time.',
    title_date: 'Do you want to schedule a resend?',
    title_metadata: 'Add custom fields',
    getting_informations: 'Getting information',
    created_at: 'Created in',
    scheduled_to: 'Scheduled to',
    count: 'Amount',
    send_for: 'shipping via',
    success_delete: 'Resend canceled successfully!',
    error_delete: 'Oops! try again.',
    success_resend_title: 'Resend successfully completed/scheduled.',
    filter_options: {
      no_filter: 'No filter',
      title_az: 'Title A-Z',
      title_za: 'Title Z-A',
      created_gt: 'Creation date - ⬆️',
      created_lt: 'Creation date - ⬇️',
      schedule_gt: 'Schedule date - ⬆️',
      schedule_lt: 'Schedule date - ⬇️'
    }
  },
  automatic_message: {
    title_config: 'Settings',
    subtitle: 'Set your automatic messages',
    title_delete_message: 'Do you want to delete this message?',
    confirm: 'Confirm',
    cancel: 'Cancel',
    add: 'Add',
    create: 'Create',
    save: 'Save',
    title_placeholder: 'Enter the message title here',
    delete_tooltip: 'Remove message'
  },
  insights: {
    no_insights: 'It seems like you don\'t have research that can generate insights.'
  },
  health_score: {
    title_graph_average: 'Average Health',
    title_graph_approach: 'Health of the approach',
    title_evolution_health: 'Evolution of health',
    title_approaches_health: 'Health of the addressed',
    see_details_in_approaches: 'See approach details',
    conversion: 'Conversion',
    filter_churn: 'Probability of churn',
    filter_score: 'health',
    lh_to_lh: 'Low to low',
    lh_to_mh: 'Low to medium',
    lh_to_hh: 'Low to high',
    mh_to_lh: 'Medium to low',
    mh_to_mh: 'Medium to medium',
    mh_to_hh: 'Medium to high',
    hh_to_lh: 'High to low',
    hh_to_mh: 'High to medium',
    hh_to_hh: 'High to high',
    health: {
      health_very_high: 'Health very high',
      health_high: 'Health high',
      health_mid: 'Health medium',
      health_low: 'Health low',
      health_very_low: 'Health very low'
    },
    churn: {
      churn_very_high: 'Very high',
      churn_high: 'High',
      churn_mid: 'Medium',
      churn_low: 'Low',
      churn_very_low: 'Very low'
    },
    abbreviated: {
      very_high: 'V. HIGH',
      high: 'HIGH',
      mid: 'MED',
      low: 'LOW',
      very_low: 'V. LOW'
    },
    tooltip_churn: 'This graph represents the average churn probability of the approaches in this survey.',
    tooltip_score: 'This graph represents the average health of the approaches in this survey.',
    title_card_score: 'health',
    title_card_churn: 'Churn probability',
    tooltip_hide_health: 'Hide health from survey',
    tooltip_show_health: 'View survey health',
    tooltip_addressed: 'This value reflects the average of all approaches responded to by this contact, on a scale of 0 to 100. It provides an overview of the health of interactions over time.',
    tooltip_approach: 'This value represents the health of this specific approach on a scale of 0 to 100. It is calculated based on the average of the responses provided during this interaction. ',
    tooltip_probability_churn: 'The probability of churn is an estimate calculated by Binds, which considers all responses given by this contact to predict the chance of cancellation or withdrawal.'
  }
}
