import i18n from '../../translations/i18n'
import { find } from 'lodash-es'

const state = {
  types: [
    { title: i18n.t('approaches.active'), type: 'active', toQuery: { archivedAt: { $exists: false } } },
    { title: i18n.t('approaches.archived'), type: 'archived', toQuery: { archivedAt: { $exists: true } } },
    { title: i18n.t('approaches.all'), type: 'all' }
  ],
  selectedType: { title: i18n.t('approaches.active'), type: 'active', toQuery: { archivedAt: { $exists: false } } }
}

const mutations = {
  updateSelectedType (state, type) {
    const finded = find(state.types, { type: type })
    if (finded) {
      state.selectedType = finded
    }
  }
}

const getters = {
  getTypes: state => state.types,
  getSelectedType: state => state.types.find(item => item.type === state.selectedType.type)
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
