<template>
  <div>
    <div class="sidebar-account-details">
      <div>
        <p id="sidebarTitle">{{ $t('account_details.title') }}</p>
        <div
          style="margin-top: -58px; cursor: pointer;"
          v-on:click="$emit('closeAccountSideBar', false)"
          class="close-side-bar-account-details"
        >
          <i class="material-icons">close</i>
        </div>
      </div>
      <div style="margin-top: 20px">
        <div class="fieldContainer">
          <p class="sidebarSubTitle">{{ $t('account_details.subtitle') }}</p>
          <div>
            <p class="fieldTitle">ID</p>
            <input
              class="softInputField"
              v-model="account._id"
              disabled
              :placeholder="$t('account_details.account_name')"
            />
          </div>
          <div>
            <p class="fieldTitle">{{ $t('account_details.account.name.value') }}</p>
            <p class="fieldDescription">
              {{ $t('account_details.account.name.description') }}
            </p>
            <input
              class="softInputField"
              v-model="account.name"
              :placeholder="$t('account_details.account_name')"
            />
          </div>
          <div>
            <p class="fieldTitle">{{ $t('account_details.account.url.value') }}</p>
            <p class="fieldDescription">{{ $t('account_details.account.url.description') }}</p>
            <input
              class="softInputField"
              v-model="account.url"
              :placeholder="$t('account_details.type_url')"
            />
          </div>
          <div>
            <p class="fieldTitle">{{ $t('account_details.account.dashboardPeriod.value') }}</p>
            <p class="fieldDescription">
              {{ $t('account_details.account.dashboardPeriod.description') }}
            </p>
            <app-form-dropdown
              class="sideBarSelect"
              v-model="account.dashboardDefaultPeriod"
              :items="filterPeriods"
              item-value="value"
              item-text="label"
            />
          </div>
        </div>
        <div class="fieldContainer">
          <p class="sidebarSubTitle">{{ $t('account_details.send_preferences.title') }}</p>
          <div>
            <p class="fieldTitle">{{ $t('account_details.send_preferences.name.value') }}</p>
            <p class="fieldDescription">
              {{ $t('account_details.send_preferences.name.description') }}
            </p>
            <app-form-dropdown
              class="sideBarSelect"
              v-model="account.nameFieldKey"
              :items="accountFields"
              item-value="value"
              item-text="label"
            />
          </div>
          <div>
            <p class="fieldTitle">{{ $t('account_details.send_preferences.email.value') }}</p>
            <p class="fieldDescription">
              {{ $t('account_details.send_preferences.email.description') }}
            </p>
            <app-form-dropdown
              class="sideBarSelect"
              v-model="account.emailFieldKey"
              :items="accountFields"
              item-value="value"
              item-text="label"
            />
          </div>
          <div>
            <p class="fieldTitle">{{ $t('account_details.send_preferences.phone.value') }}</p>
            <p class="fieldDescription">
             {{ $t('account_details.send_preferences.phone.description') }}
            </p>
            <app-form-dropdown
              class="sideBarSelect"
              v-model="account.phoneFieldKey"
              :items="accountFields"
              item-value="value"
              item-text="label"
            />
          </div>
          <div>
            <p class="fieldTitle">{{ $t('account_details.send_preferences.sending_interval.value') }}</p>
            <p class="fieldDescription">
              {{ $t('account_details.send_preferences.sending_interval.description') }}
            </p>
            <div style="display: inline-flex">
              <input
                style="text-align: center"
                type="number"
                min="0"
                class="softInputField"
                v-model="sendingInterval.value"
                @input="concatValuesInterval('interval')"
              />
              <app-form-dropdown
                style="width: 100%; padding-left: 10px"
                class="sideBarSelect"
                v-model="sendingInterval.unit"
                :items="sendingPeriods"
                item-value="value"
                item-text="label"
                @input="concatValuesInterval('interval')"
              />
            </div>
            <div style="margin: 15px 0; display: flex; place-items: center;">
              <app-toggle-input
                v-if="account.features"
                v-model="account.features.surveySendingInterval"
              >
              </app-toggle-input>
              <p class="fieldDescription" style="margin: .2rem 3rem;">
                {{ $t('account_details.send_preferences.sending_interval.ask') }}
              </p>
            </div>
          </div>
          <div>
            <p class="fieldTitle">{{ $t('account_details.send_preferences.expiration_time.value') }}</p>
            <p class="fieldDescription">
             {{ $t('account_details.send_preferences.expiration_time.description') }}
            </p>
            <div style="display: inline-flex">
              <input
                style="text-align: center"
                type="number"
                min="0"
                class="softInputField"
                v-model="expirationTime.value"
                @input="concatValuesInterval('expiration')"
              />
              <app-form-dropdown
                style="width: 100%; padding-left: 10px"
                class="sideBarSelect"
                v-model="expirationTime.unit"
                :items="sendingPeriods"
                item-value="value"
                item-text="label"
                @input="concatValuesInterval('expiration')"
              />
            </div>
          </div>
          <div class="fieldContainer">
            <p class="sidebarSubTitle" style="margin-top: 10px;">Features</p>
            <div class="row" style="display: block;">
              <div class="feature-toggles" style="display: flex;">
                <app-toggle-input v-if="account" v-model="account.smsEnabled"></app-toggle-input>
                <app-heading level="h6">{{ $t('account_details.features.sms_enabled') }}</app-heading>
              </div>
              <!-- <div class="feature-toggles" style="display: flex;">
                <app-toggle-input v-if="account" v-model="account.features.whatsApp"></app-toggle-input>
                <app-heading level="h6">{{ $t('whatsapp.label_send') }}</app-heading>
              </div> -->
              <div class="feature-toggles">
                <app-toggle-input v-if="account.smsSettings" v-model="account.smsSettings.metadata"></app-toggle-input>
                <app-heading level="h6">{{ $t('account_details.features.sms_metadata') }}</app-heading>
              </div>
              <div class="feature-toggles">
                <app-toggle-input v-if="account.features" v-model="account.features.tickets"></app-toggle-input>
                <app-heading level="h6">{{ $t('account_details.features.tickets') }}</app-heading>
              </div>
              <div class="feature-toggles">
                <app-toggle-input v-if="account.features" v-model="account.features.allowEditSurveyWithResponses"></app-toggle-input>
                <app-heading level="h6">{{ $t('account_details.features.edit_survey_response') }}</app-heading>
              </div>
              <div class="feature-toggles">
                <app-toggle-input v-if="account.ticket" v-model="account.ticket.associateNotes"></app-toggle-input>
                <app-heading level="h6">{{ $t('account_details.features.associate_notes') }}</app-heading>
              </div>
              <div class="feature-toggles">
                <app-toggle-input v-if="account.features" v-model="account.features.insights"></app-toggle-input>
                <app-heading level="h6">Insights</app-heading>
              </div>
              <div class="feature-toggles">
                <app-toggle-input v-if="account.billing" v-model="account.billing.hasInvoice"></app-toggle-input>
                <app-heading level="h6">{{ $t('account_details.features.has_invoice') }}</app-heading>
              </div>
              <div class="feature-toggles">
                <app-toggle-input v-if="account.ticket" v-model="account.ticket.requireCategory"></app-toggle-input>
                <app-heading level="h6">{{ $t('account_details.features.require_category') }}</app-heading>
              </div>
              <div class="feature-toggles">
                <app-toggle-input v-if="account.features" v-model="account.features.textAnalytics"></app-toggle-input>
                <app-heading level="h6">{{ $t('account_details.features.text_analytics') }}</app-heading>
              </div>
              <div class="feature-toggles">
                <app-toggle-input v-if="account.features" v-model="account.features.comparativeDash"></app-toggle-input>
                <app-heading level="h6">{{ $t('account_details.features.comparative_dash') }}</app-heading>
              </div>
              <div class="feature-toggles">
                <app-toggle-input v-if="account.features" v-model="account.features.tags"></app-toggle-input>
                <app-heading level="h6">{{ $t('account_details.features.tags') }}</app-heading>
              </div>
              <div class="feature-toggles">
                <app-toggle-input v-if="account.features" v-model="account.features.twoFactorAuth"></app-toggle-input>
                <app-heading level="h6">{{ $t('account_details.features.two_way_auth') }}</app-heading>
              </div>
              <div class="feature-toggles">
                <app-toggle-input v-if="account.features" v-model="account.features.v2"></app-toggle-input>
                <app-heading level="h6">{{ $t('account_details.features.v2') }}</app-heading>
              </div>
              <div class="feature-toggles">
                <app-toggle-input v-if="account.features" v-model="account.features.confirmOptOut"></app-toggle-input>
                <app-heading level="h6">{{ $t('account_details.features.confirm_optout_title') }}</app-heading>
                <app-heading level="h6" class="description">{{ $t('account_details.features.confirm_optout_description') }}</app-heading>
              </div>
              <div class="feature-toggles">
                <app-toggle-input v-if="account.features" v-model="account.termsSettings.enabled"></app-toggle-input>
                <app-heading level="h6">{{ $t('account_details.features.binds_voice_score') }}</app-heading>
                <app-heading level="h6" class="description">{{ $t('account_details.features.binds_voice_score_description') }}</app-heading>
              </div>
            </div>
          </div>
        </div>
        <div class="fieldContainer">
          <p class="sidebarSubTitle">
            {{ $t('account_details.features.whatsapp.title') }}
            <app-toggle-input v-if="account" v-model="account.features.whatsApp" style="display: block; position: relative; float: right; margin-right: 39px;"></app-toggle-input>
          </p>
          <div v-if="account.features && account.features.whatsApp">
            <div>
              <p class="fieldTitle">{{ $t('account_details.features.whatsapp.limit_per_day') }}</p>
              <p class="fieldDescription">{{ $t('account_details.features.whatsapp.limit_per_day_label') }}</p>
              <input
                class="softInputField"
                v-model="account.whatsAppSettings.limitToSendPerDay"
              />
            </div>
            <div>
              <p class="fieldTitle">Phone ID</p>
              <p class="fieldDescription">{{ $t('account_details.features.whatsapp.phone_id') }}</p>
              <input
                class="softInputField"
                v-model="account.whatsAppSettings.phoneId"
                placeholder="108325892262292"
              />
            </div>
            <div>
              <p class="fieldTitle">Business ID</p>
              <p class="fieldDescription">{{ $t('account_details.features.whatsapp.business_id') }}</p>
              <input
                class="softInputField"
                v-model="account.whatsAppSettings.businessId"
                placeholder="118294787824669"
              />
            </div>
            <div>
              <p class="fieldTitle">Token</p>
              <p class="fieldDescription">{{ $t('account_details.features.whatsapp.token') }}</p>
              <input
                class="softInputField"
                v-model="account.whatsAppSettings.token"
                placeholder="GCwWq3IkQ3qPj1ebNcd0mLa45pJztPAccTORgJcrdlyFc86X0jX2FfwjflBsZEAAKHbrmY7wYBAARpshQu4ASQ4EZBH7WwQVJwrpqWrNTkP94AaIyZCiDwRW07V0BClwweTjcFoGaL"
              />
            </div>
            <div>
              <p class="fieldTitle">{{ $t('account_details.features.whatsapp.template_text_simple_title') }}</p>
              <p class="fieldDescription"> {{ $t('account_details.features.whatsapp.template_text_simple_description') }} </p>
              <input
                class="softInputField"
                v-model="account.whatsAppSettings.templates.text"
                placeholder="id_do_template_no_whatsapp_business"
              />
            </div>
            <div>
              <p class="fieldTitle">{{ $t('account_details.features.whatsapp.template_text_button_title') }}</p>
              <p class="fieldDescription"> {{ $t('account_details.features.whatsapp.template_text_button_description') }}</p>
              <input
                class="softInputField"
                v-model="account.whatsAppSettings.templates.button"
                placeholder="id_do_template_no_whatsapp_business"
              />
            </div>
            <div>
              <p class="fieldTitle">{{ $t('account_details.features.whatsapp.template_text_button_image_title') }}</p>
              <p class="fieldDescription"> {{ $t('account_details.features.whatsapp.template_text_button_image_description') }}</p>
              <input
                class="softInputField"
                v-model="account.whatsAppSettings.templates.image"
                placeholder="id_do_template_no_whatsapp_business"
              />
            </div>
            <div>
            <p class="fieldTitle">{{ $t('account_details.features.whatsapp.send_interval_title') }}</p>
            <p class="fieldDescription">
             {{ $t('account_details.features.whatsapp.send_interval_description') }}
            </p>
            <div style="display: inline-flex">
              <input
                style="text-align: center"
                type="number"
                min="0"
                class="softInputField"
                v-model="whatsAppSendInterval.value"
                @input="concatValuesInterval('whatsappInterval')"
              />
              <app-form-dropdown
                style="width: 100%; padding-left: 10px"
                class="sideBarSelect"
                v-model="whatsAppSendInterval.unit"
                :items="sendingPeriods"
                item-value="value"
                item-text="label"
                @input="concatValuesInterval('whatsappInterval')"
              />
            </div>
          </div>
          </div>
        </div>
        <div class="fieldContainer" style="margin-bottom: 55px">
          <p class="sidebarSubTitle">
            {{ $t('account_details.features.sso.title') }}
            <app-toggle-input
              style="display: block; position: relative; float: right; margin-right: 39px;"
              v-if="account.features"
              @input="account.features.azureAuth && !account.azureSettings ? (account.azureSettings = {}) : ''"
              v-model="account.features.azureAuth"
            >
            </app-toggle-input>
          </p>
          <div v-if="account.features && account.features.azureAuth && account.azureSettings">
            <div>
              <p class="fieldTitle">Client ID</p>
              <p class="fieldDescription">
                {{ $t('account_details.features.sso.client_id') }}
              </p>
              <input
                class="softInputField"
                v-model="account.azureSettings.clientID"
                placeholder="223sd2323a1-1196-4a9e-814b-223232dsa213"
              />
            </div>
            <div>
              <p class="fieldTitle">Client Secret</p>
              <p class="fieldDescription">
                {{ $t('account_details.features.sso.client_secret') }}
              </p>
              <input
                class="softInputField"
                v-model="account.azureSettings.clientSecret"
                placeholder="x~3f-dfsf3DSSADAdsa.sS45"
              />
            </div>
            <div>
              <p class="fieldTitle">Tenant</p>
              <p class="fieldDescription">
                {{ $t('account_details.features.sso.tenant') }}
              </p>
              <input
                class="softInputField"
                v-model="account.azureSettings.tenant"
                placeholder="tenanthotmailtenant.onmicrosoft.com"
              />
            </div>
          </div>
        </div>
        <div class="data-loading-templates" v-if="isLoading" style="border-radius: 54px 0px 0px">
          <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
        </div>
      </div>
    </div>
    <div id="saveButton" @click="saveAccount()"><p>{{ $t('profile.save') }}</p></div>
    <theme-style>
      .sidebarSubTitle {
        background-color: {{ user.account.theme.colors.primary || '#673ab7' }} !important;
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      }
      #saveButton {
        background-color: {{ user.account.theme.colors.secondary || '#ff607d' }} !important;
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      }
    </theme-style>
  </div>
</template>

<script>
import { accountService } from '@/_services'
import { metadataManipulator } from '@/_helpers'
import { mapGetters } from 'vuex'
import('lodash-es').then((lodashEs) => {
  window._ = lodashEs.default
})
export default {
  name: 'AccountDetails',
  props: ['accountId'],
  components: {
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput'),
    'app-form-dropdown': () => import('@binds-tech/binds-design-system/src/components/Form/Dropdown'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  data () {
    return {
      account: {},
      isLoading: false,
      accountFields: [],
      sendingInterval: {
        unit: '',
        value: ''
      },
      expirationTime: {
        unit: '',
        value: ''
      },
      whatsAppSendInterval: {
        unit: '',
        value: ''
      },
      sendingPeriods: [
        {
          value: 'seconds',
          label: 'Segundos'
        },
        {
          value: 'minutes',
          label: 'Minutos'
        },
        {
          value: 'hours',
          label: 'Horas'
        },
        {
          value: 'days',
          label: 'Dias'
        },
        {
          value: 'weeks',
          label: 'Semanas'
        },
        {
          value: 'months',
          label: 'Meses'
        }
      ],
      filterPeriods: [
        {
          value: 'day',
          label: this.$t('dashboard.period.24hours')
        },
        {
          value: 'week',
          label: this.$t('dashboard.period.7days')
        },
        {
          value: 'month',
          label: this.$t('dashboard.period.30days')
        },
        {
          value: 'semester',
          label: this.$t('dashboard.period.6months')
        },
        {
          value: 'year',
          label: this.$t('dashboard.period.12months')
        }
      ]
    }
  },
  watch: {
    accountId: function () {
      this.loadNewAccountId()
    }
  },
  beforeDestroy () {
    this.$root.$off('closeSideBarAccount')
  },
  async mounted () {
    this.loadNewAccountId()
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    })
  },
  methods: {
    concatValuesInterval (type) {
      let valueToSend
      if (type === 'interval') {
        valueToSend = `${this.sendingInterval.value}${this.sendingInterval.unit}`
        if (this.sendingInterval.value === '0' || this.sendingInterval.value === '') {
          valueToSend = ''
        }
        this.account.sendingInterval = valueToSend
        return
      }

      if (type === 'expiration') {
        valueToSend = `${this.expirationTime.value}${this.expirationTime.unit}`
        if (this.expirationTime.value === '0' || this.expirationTime.value === '') {
          valueToSend = ''
        }
        this.account.expirationInterval = valueToSend
      }

      if (type === 'whatsappInterval') {
        valueToSend = `${this.whatsAppSendInterval.value}${this.whatsAppSendInterval.unit}`
        if (this.whatsAppSendInterval.value === '0' || this.whatsAppSendInterval.value === '') {
          valueToSend = ''
        }
        this.account.whatsAppSettings.sendInterval = valueToSend
      }
    },
    ui2Interval (obj) {
      var value = window._.get(obj, 'value')
      var unit = window._.get(obj, 'unit')
      return !value || !unit ? '' : value + unit
    },
    interval2Ui (str) {
      return !str
        ? {
          unit: 'hours'
        }
        : {
          value: parseInt(str.match(/\d*/g)[0], 10),
          unit: str.match(/[^\d\W]+/g)[0]
        }
    },
    async loadNewAccountId () {
      if (this.accountId) {
        this.isLoading = true
        this.account = await accountService.getAccount(this.accountId)

        this.accountFields.push({
          label: `${this.$i18n.t('account_details.default')} (from.email)`,
          value: 'from.email'
        })
        this.accountFields.push({
          label: `${this.$i18n.t('account_details.default')} (from.name)`,
          value: 'from.name'
        })
        this.accountFields.push({
          label: `${this.$i18n.t('account_details.default')} (from.phone)`,
          value: 'from.phone'
        })

        // transforma o valor conjunto cagado do backend no valor unitário do front
        this.sendingInterval = this.interval2Ui(this.account.sendingInterval)
        this.expirationTime = this.interval2Ui(this.account.expirationInterval)
        this.whatsAppSendInterval = this.interval2Ui(this.account.whatsAppSettings.sendInterval)

        if (this.account && this.account.fields.length > 0) {
          const data = Object.assign({}, this.account)
          data.fields.map((item) => {
            item.value = item.key
            this.accountFields.push(item)
          })
        }
        this.isLoading = false
      }
    },
    showSomeMessage (message) {
      this.$store.commit('alerts/alert', {
        message: message,
        position: 'bottomRight',
        showAlert: true
      })
    },
    async saveAccount () {
      if (this.account) {
        this.isLoading = true
        const accountToSave = metadataManipulator.removeFieldsFromAccounts(this.account)
        const hasSuccessOnUpdate = await accountService.updateAccount(this.account._id, accountToSave)
        this.isLoading = false
        if (hasSuccessOnUpdate) {
          this.showSomeMessage(this.$i18n.t('account_details.updated'))
          this.$root.$emit('closeSideBarAccount')
        } else {
          this.showSomeMessage(this.$i18n.t('account_details.updating_error'))
        }
      }
    }
  }
}
</script>

<style lang="scss">
#saveButton {
    margin-bottom: 150px;
    position: fixed;
    width: 360px;
    bottom: -140px;
    right: 15px;
    z-index: 999;
    border-radius: 12px;
    & p {
      text-align: center;
      font-size: 2.3vh;
      font-weight: 550;
      padding: 12px 0px;
    }
    cursor: pointer;
}
.sideBarSelect > select {
  height: 32px !important;
  padding: 3px 9px !important;
  margin: 0px !important;
  font-size: 14px !important;
}
#sidebarTitle {
  padding: 10px;
  font-size: 1.5em;
  text-align: center;
  color: #717171;
}
.sidebarSubTitle {
  padding: 10px;
  font-size: 1.2em;
  border-radius: 4px;
}
.fieldTitle {
  padding: 10px 10px 2px 0px;
  font-weight: bold;
  font-size: 0.9em;
  color: #717171;
}
.featureContainer {
  display: flex;
  padding: 2px;
  justify-content: space-between;
  width: 87%;
}
.featureTuggle {
  margin-top: 16px;
  margin-bottom: 13px;
}
.featureTitle {
  padding: 10px 10px 2px 0px;
  font-size: 0.9em;
  color: #717171;
}
.fieldDescription {
  font-size: 0.8em;
  margin-bottom: 11px;
  color: #8f8f8f;
}
.softInputField {
  border: none;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  color: #757171;
}
.fieldContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  & div {
    padding-top: 2px;
  }
}
.close-side-bar-account-details {
  position: absolute;
  right: 0;
  color: var(--accent-color);
}
.sidebar-account-details {
  overflow: auto;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 999;
  right: 0;
  padding: 20px;
  height: calc(100vh);
  width: 390px;
  box-sizing: border-box;
  box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
}
.feature-toggles {
  display: flex;
  padding: 15px 0px !important;
  & h6 {
    color: #717171 !important;
    font-size: 14px !important;
    padding-left: 45px !important;
    line-height: 15px !important;
  }
  & .description {
    font-size: 11px !important;
    color: #8f8f8f !important;
  }
}
</style>
