import { constants, userRoles } from '@/_helpers'

export default {
  computed: {
    getUser () {
      return this.$store.getters['account/getUser']
    },
    canCreateOrCloneSurveys () {
      const { roles, type: userType } = this.getUser

      if (!this.isValidUserType(userType)) return false

      const hasNoContentFilteringRole = this.hasNoContentFilteringRoles(roles)

      return this.isAllowedUserType(userType) || hasNoContentFilteringRole
    },
    canVisualizeDashboards () {
      return userRoles.hasPermission(this.getUser, ['sendings'])
    }
  },
  methods: {
    isValidUserType (userType) {
      const allowedTypes = [constants.user.TYPES.SUPER_ADMIN, constants.user.TYPES.ADMIN, constants.user.TYPES.USER]
      return allowedTypes.includes(userType)
    },
    isAllowedUserType (userType) {
      return [constants.user.TYPES.SUPER_ADMIN, constants.user.TYPES.ADMIN].includes(userType)
    },
    hasNoContentFilteringRoles (roles) {
      if (!roles) return true

      return roles.every(({ filters }) => this.isRoleWithoutContentFiltering(filters))
    },
    isRoleWithoutContentFiltering (filters) {
      if (!filters?.Survey && !filters?.Collector) return true
      // Check if both Survey and Collector filters are either empty or not present
      return [filters?.Collector, filters?.Survey].every(
        (filter) => this.isFilterEmptyOrUndefined(filter)
      )
    },
    isFilterEmptyOrUndefined (filter) {
      const QUERY_EMPTY_STRING = '{"$and":[{"_id":{"$in":[]}}]}'
      return !filter || filter === QUERY_EMPTY_STRING
    }
  }
}
