export default {
  data: () => {
    return {
      scrollInterval: null
    }
  },
  methods: {
    isAtScrollEnd (element) {
      if (element) return ((element.scrollTop + element.clientHeight) >= element.scrollHeight)
      return ((window.scrollY + window.innerHeight + 5) >= document.documentElement.scrollHeight)
    },
    scrollDown (element) {
      if (element) {
        element.scrollBy(0, 1)
      } else {
        window.scrollBy(0, 1)
      }
    },
    scrollToTop (element) {
      if (element) {
        element.scrollTo(0, 0)
      } else {
        window.scrollTo(0, 0)
      }
    },
    toggleAutoScroll (tvDash, isScrollAllowed, scrollElement) {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval)
        this.scrollInterval = null
      }

      if (tvDash && isScrollAllowed) {
        this.scrollInterval = setInterval(() => {
          const isAtBottom = this.isAtScrollEnd(scrollElement)
          if (isAtBottom) {
            this.scrollToTop(scrollElement)
          } else {
            this.scrollDown(scrollElement)
          }
        }, 25) // Adjust interval speed as needed
      }
    }

  }
}
