
const state = {
  showAlert: false,
  message: 'alert',
  position: 'topRight',
  color: 'pink-light',
  close: 'callbackOnClose'
}

const confirmEmail = {
  showAlert: false,
  message: 'alert',
  position: 'top',
  color: 'pink-light',
  close: 'callbackOnClose'
}

const mutations = {
  alert (state, payload) {
    state.persist = false
    payload.persist = false
    Object
      .keys(payload)
      .forEach(key => {
        const value = payload[key]
        state[key] = value
      })
  },
  emailAlert (confirmEmail, payload) {
    Object
      .keys(payload)
      .forEach(key => {
        const value = payload[key]
        confirmEmail[key] = value
      })
  }
}

const getters = {
  getAlertState: state => state
}

export default {
  namespaced: true,
  state,
  confirmEmail,
  mutations,
  getters
}
