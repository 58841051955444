<template>
    <div v-html="embededCode"></div>
</template>
<script>
import { embedGenerator } from '@/_helpers'
export default {
  props: ['question'],
  data () {
    return {
      embededCode: ''
    }
  },
  mounted () {
    if (!this.question.stars.active) {
      this.embededCode = embedGenerator.returnEmbedCsatOrNps(this.question)
    } else {
      this.embededCode = embedGenerator.returnEmbedCsatStars(this.question)
    }

    this.$emit('embedQuestion', this.embededCode)
  }
}
</script>
