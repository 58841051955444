export const gradientMaker = {
  checkGradientAndReturnTheColors,
  returnGradientColorsForThisQuestion,
  getColorByValue
}
const defaultColors = [
  '#E94212',
  '#ED5E0E',
  '#F27A0B',
  '#F79507',
  '#FBB003',
  '#FDC900',
  '#D4C30D',
  '#AABA19',
  '#80B326',
  '#57AB32',
  '#29A340'
]
const defaultColors1to5 = [
  '#E94212',
  '#ED5E0E',
  '#FBB003',
  '#AABA19',
  '#29A340'
]
const colors = {
  nps: {
    '10num': defaultColors,
    ynm: ['#57AD26', '#EA484D', '#F8C43D']
  },
  kpi: {
    ynd: ['#57AD26', '#EA484D', '#F8C43D'],
    yn: ['#57AD26', '#EA484D']
  },
  csat: {
    '5num': defaultColors1to5,
    '10num': defaultColors
  },
  ces: {
    // ainda não existe o ces, depois será feito
    '1to7': [
      '#f22020',
      '#f22020',
      '#f25b20',
      '#f4b622',
      '#c2d924',
      '#26bb07',
      '#26bb07'
    ],
    '1to7button': [
      '#f22020',
      '#f22020',
      '#f25b20',
      '#f4b622',
      '#c2d924',
      '#26bb07',
      '#26bb07'
    ]
  },
  nes: {
    // na v2 ainda não tem o nes de 1 a 7 botões, precisa confirmar com o rafa (provavelmente não terá)
    // mas basicamente o nes inverte as cores dos botões, vem o verde primeiro até ficar vermelho
    // quanto menor a opção escolhida menor é o esforço (nes é uma métrica de esforço)
    '1to7': [
      '#26bb07',
      '#26bb07',
      '#c2d924',
      '#f4b622',
      '#f25b20',
      '#f22020',
      '#f22020'
    ],
    '10num': defaultColors1to5.slice().reverse() // aqui são 5 botões mas o nome é 10num mesmo, a galera que fez a v1 tinha preguiça =)
  },
  enum: {
    11: defaultColors,
    10: defaultColors, // somente 10 cores, mas não fica estranho se usarmos as cores padrões que são 11 opções
    6: ['#E94212', '#ED5E0E', '#FBB003', '#FDC900', '#AABA19', '#29A340'],
    5: defaultColors1to5
  }
}

/**
 * Returns the color based on the provided type, UI, and index.
 *
 * @param {string} type - The type of the color (e.g., 'enum', 'primary', etc.).
 * @param {string} ui - The UI element to get the color for (e.g., 'button', 'background', etc.).
 * @param {number|string} idx - The index to retrieve the color from. Can be a string or number.
 * @returns {string} - The corresponding color as a hex code or default color for 'enum' type.
 */
function getColorByValue (type, ui, idx) {
  if (type === 'enum') {
    return '#7b7b7b'
  }

  const colorIndex = Math.max(0, parseInt(idx) - 1)
  return colors[type]?.[ui]?.[colorIndex]
}

/**
 * Manipula o objeto enviado por parãmetro para validar se necessita de cores especiais, inserindo-as conforme a UI e Tipo da pergunta
 *
 * @param {Object} question contém a questão que será manipulada e verificada a grade de cores
 * @param {*} objectToMutate contém o item a ser modificado, inserindo cores de fundo e hover dos botões
 */
function checkGradientAndReturnTheColors (question, objectToMutate) {
  if (typeof question.gradient === 'undefined') {
    question.gradient = true
  }
  if (question.gradient) {
    const isGradiendMapped = returnGradientColorsForThisQuestion(question)
    if (isGradiendMapped) {
      objectToMutate.forEach((item, index) => {
        try {
          item.order = new Date().getTime()
          item.bgColor = isGradiendMapped[index]
          item.hoverColor = isGradiendMapped[index] + '90' // insere cores mais claras para a cor atual, dando um efeito de "vidro" ou sombra branca na cor atual
        } catch (error) {
          console.log('error', error)
          return []
        }
      })
    }
  }
}

function returnGradientColorsForThisQuestion (question) {
  try {
    if (question.gradient) {
      if (question.type === 'enum') {
        return colors[question.type][question.options.length]
      }
      return colors[question.type][question.ui]
    }
    return []
  } catch (e) {
    return []
  }
}
