function initialState () {
  return {
    displayHealth: true
  }
}

const state = initialState()

const mutations = {
  setDisplayHealth (state, payload) {
    state.displayHealth = payload
  }
}

const getters = {
  displayHealth: state => state.displayHealth
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
