<template>
    <div v-html="embededCode"></div>
</template>
<script>
import { embedGenerator } from '@/_helpers'

export default {
  props: ['question'],
  data () {
    return {
      embededCode: [],
      label1: '',
      label2: '',
      label3: '',
      label4: '',
      label5: '',
      label6: '',
      label7: ''

    }
  },
  mounted () {
    this.label1 = this.$t('components.ces.totally_disagree')
    this.label2 = this.$t('components.ces.disagree')
    this.label3 = this.$t('components.ces.partially_disagree')
    this.label4 = this.$t('components.ces.neutral')
    this.label5 = this.$t('components.ces.partially_agree')
    this.label6 = this.$t('components.ces.agree')
    this.label7 = this.$t('components.ces.totally_agree')

    this.embededCode = ['<div class="email-cta" style="width:400px;margin:0 auto;"> \n ',
      '<br> \n',
      '<br> \n',
      '<br> \n',
      '<br> \n',
      '<h2 style="margin:0 auto;line-height:23px;text-align:center;color:{{color.c2}}">' + this.question.question + '</h2> \n ',
      '<br> \n',
      '<br> \n',
      '<table width="400px" cellspacing="0" cellpadding="0" border="0" class="ng-scope" style="margin: 0 auto;"> \n',
      '<tbody> \n',
      '<tr> \n',
      '<td valign="top" align="center"> \n',
      '<div style="background:#fff;color:#666;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;font-size:20px;">\n',
      '<table width="397" cellspacing="0"  align="center" cellpadding="0" border="0">\n',
      '<tbody>\n',
      '<tr>\n',
      '<td style="text-align: center" valign="top">\n',
      '<a href="*|SRV:0|*" style="background-color:{{color.c3}};color:#fff;font-size:20px; font-family:Arial,sans-serif; text-decoration:none;text-align: center; line-height:40px; width:250px; display:inline-block;margin: auto;border-radius: 25px;margin: 4px;"> &nbsp;&nbsp;' + this.label1 + '</a>\n',
      '</td>\n',
      '</tr>\n',
      '<tr>\n',
      '<td style="text-align: center" valign="top">\n',
      '<a href="*|SRV:15|*" style="background-color:{{color.c3}};color:#fff;font-size:20px; font-family:Arial,sans-serif; text-decoration:none;text-align: center; line-height:40px; width:250px; display:inline-block;margin: auto;border-radius: 25px;margin: 4px;"> &nbsp;&nbsp;' + this.label2 + '</a>\n',
      '</td>\n',
      '</tr>\n',
      '<tr>\n',
      '<td style="text-align: center" valign="top">\n',
      '<a href="*|SRV:30|*" style="background-color:{{color.c3}};color:#fff;font-size:20px; font-family:Arial,sans-serif; text-decoration:none;text-align: center; line-height:40px; width:250px; display:inline-block;margin: auto;border-radius: 25px;margin: 4px;"> &nbsp;&nbsp;' + this.label3 + '</a>\n',
      '</td>\n',
      '</tr>\n',
      '<tr>\n',
      '<td style="text-align: center" valign="top">\n',
      '<a href="*|SRV:50|*" style="background-color:{{color.c3}};color:#fff;font-size:20px; font-family:Arial,sans-serif; text-decoration:none;text-align: center; line-height:40px; width:250px; display:inline-block;margin: auto;border-radius: 25px;margin: 4px;"> &nbsp;&nbsp;' + this.label4 + '</a>\n',
      '</td>\n',
      '</tr>\n',
      '<tr>\n',
      '<td style="text-align: center" valign="top">\n',
      '<a href="*|SRV:65|*" style="background-color:{{color.c3}};color:#fff;font-size:20px; font-family:Arial,sans-serif; text-decoration:none;text-align: center; line-height:40px; width:250px; display:inline-block;margin: auto;border-radius: 25px;margin: 4px;"> &nbsp;&nbsp;' + this.label5 + '</a>\n',
      '</td>\n',
      '</tr>\n',
      '<tr>\n',
      '<td style="text-align: center" valign="top">\n',
      '<a href="*|SRV:80|*" style="background-color:{{color.c3}};color:#fff;font-size:20px; font-family:Arial,sans-serif; text-decoration:none;text-align: center; line-height:40px; width:250px; display:inline-block;margin: auto;border-radius: 25px;margin: 4px;"> &nbsp;&nbsp;' + this.label6 + '</a>\n',
      '</td>\n',
      '</tr>\n',
      '<tr>\n',
      '<td style="text-align: center" valign="top">\n',
      '<a href="*|SRV:100|*" style="background-color:{{color.c3}};color:#fff;font-size:20px; font-family:Arial,sans-serif; text-decoration:none;text-align: center; line-height:40px; width:250px; display:inline-block;margin: auto;border-radius: 25px;margin: 4px;"> &nbsp;&nbsp;' + this.label7 + '</a>\n',
      '</td>\n',
      '</tr>\n',
      '</tbody>\n',
      '</table>\n',
      '</div>\n',
      '</td>\n',
      '</tr>\n',
      '</tbody>\n',
      '</table>\n',
      '</div>'
    ].join('')
    if (this.question.gradient) {
      this.embededCode = embedGenerator.returnEmbedCes(this.question)
    }
    console.log('embededCode', this.embededCode.length)
    this.$emit('embedQuestion', this.embededCode)
  }
}
</script>
