<template>
  <div class="container-media">
    <div class="template-social-media" v-if="user.account.termsSettings.enabled && user.features.trackTerms">
      <Menu :showSubMenu="false" class="menu-top"></Menu>
      <div class="contents-social-media" v-if="!showModal" @click="validateClickOutSide($event)">
        <SearchMenu></SearchMenu>
        <div class="graphics-social-media">
          <TheGraphics></TheGraphics>
        </div>
      </div>
      <ModalSocialMidia v-if="showModal"></ModalSocialMidia>
    </div>
    <div class="you-cant" v-else>
      <YouCant />
    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import SearchMenu from './filters/SearchMenu.vue'
import TheGraphics from './home/Grouper.vue'
import ModalSocialMidia from './components/ModalSocialMedia.vue'
import YouCant from '../../pages/YouCant.vue'

export default {
  name: 'BindsVoiceScore',
  components: {
    Menu,
    TheGraphics,
    SearchMenu,
    ModalSocialMidia,
    YouCant
  },
  data () {
    return {
      isLoading: false,
      showModal: false
    }
  },
  beforeDestroy () {
    this.$root.$off('modal-media')
  },
  mounted () {
    const terms = this.$store.getters['account/getAccount'].brandsTracking
    terms.length === 0 ? this.showModal = true : this.showModal = false

    this.$root.$on('modal-media', () => {
      this.showModal = !this.showModal
    })
  },
  computed: {
    user () {
      const user = this.$store.getters['account/getUser']
      return user
    }
  },
  methods: {
    validateClickOutSide (a) {
      const click = document.querySelector('.section-selecteds').contains(a.target)
      const clickOut = !document.querySelector('.container-select').contains(a.target)
      const clickInside = (document.querySelector('.section-options').contains(a.target) && document.querySelector('.two-items').contains(a.target) && document.querySelector('.total-items').contains(a.target))
      if (click) { this.$root.$emit('open-filter-select') }
      if (clickOut && !clickInside) { this.$root.$emit('open-filter-select', true) }
    }
  }
}
</script>

<style lang="scss">
.template-social-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .content-menu.menu-top {
    width: 100%;
  }
  .contents-social-media {
    width: 98vw;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title-social-media {
      width: 98vw;
      text-align: left;
      margin-top: 20px;
    }
    .graphics-social-media {
      width: 98vw;
    }
  }
}
</style>
