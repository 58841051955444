<template>
  <transition name="modal">
    <div class="modal-mask-notifications">
      <div class="modal-wrapper-notifications">
        <div class="modal-container" style="top: 65px !important;">
          <div class="modal-header-notifications">
            <slot name="header">
              <div class="modal-close-button close-config col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button @click="$emit('close-notifications-modal')">
                  <app-textstyle size="size-l">×</app-textstyle>
                </button>
              </div>
            </slot>
          </div>
          <div class="body-notifications">
            <slot name="body">
              <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-9">
                  <tabs :options="{ useUrlFragment: false }" >
                    <tab :name="$t('notifications.roles')">
                      <div style="display: flex; text-align: center; font-size: 14px !important;">
                        <p class="col-lg-6 col-md-6 col-sm-6"></p>
                        <app-heading class="col-lg-3 col-md-3 col-sm-3" style="font-size: 14px !important; margin-left: -5px;">E-mail</app-heading>
                        <app-heading class="col-lg-3 col-md-3 col-sm-3" style="font-size: 14px !important; margin-left: -5px;">SMS</app-heading>
                      </div>
                      <div v-for="(role, roleIndex) in roles" :key="roleIndex" style="display: flex; border-bottom: 1px solid #dcdcdc; padding: 10px;">
                        <app-heading class="col-lg-6 col-md-6 col-sm-6" level="h6">{{ role.name }}</app-heading>
                        <input class="col-lg-3 col-md-3 col-sm-3" type="checkbox" v-model="roles[roleIndex].email" @change="$forceUpdate()">
                        <input class="col-lg-3 col-md-3 col-sm-3" type="checkbox" v-model="roles[roleIndex].sms" @change="$forceUpdate()">
                      </div>
                    </tab>
                    <tab :name="$t('notifications.users')">
                      <div style="display: flex; text-align: center; font-size: 14px !important;">
                        <p class="col-lg-6 col-md-6 col-sm-6"></p>
                        <app-heading class="col-lg-3 col-md-3 col-sm-3" style="font-size: 14px !important; margin-left: -5px;">E-mail</app-heading>
                        <app-heading class="col-lg-3 col-md-3 col-sm-3" style="font-size: 14px !important; margin-left: -5px;">SMS</app-heading>
                      </div>
                      <div v-for="(user, userIdx) in users" :key="userIdx" style="display: flex; border-bottom: 1px solid #dcdcdc; padding: 10px;">
                        <app-heading level="h6" class="col-lg-6 col-md-6 col-sm-6">{{ user.name }}</app-heading>
                        <div class="col-lg-3 col-md-3 col-sm-3" style="text-align: center !important; padding: 0px !important;">
                          <input type="checkbox" v-model="users[userIdx].email" @change="$forceUpdate()">
                        </div>
                        <div v-if="user.optSMSAlerts" class="col-lg-3 col-md-3 col-sm-3" style="text-align: center !important; padding: 0px !important;">
                          <input type="checkbox" v-model="users[userIdx].sms" @change="$forceUpdate()">
                        </div>
                        <div v-title="$t('notifications.smsDisabled')" v-if="!user.optSMSAlerts" class="col-lg-3 col-md-3 col-sm-3" style="text-align: center !important; padding: 0px !important;">
                          <input disabled type="checkbox">
                        </div>
                      </div>
                    </tab>
                  </tabs>
                </div>
              </div>
              <app-button class="save-account col-lg-offset-8 col-lg-2 col-md-offset-8 col-md-2 col-sm-offset-8 col-sm-2" variation="primary" @click="apply()">{{ $t('notifications.apply') }}</app-button>
              <app-button class="save-account col-lg-offset-8 col-lg-2 col-md-offset-8 col-md-2 col-sm-offset-8 col-sm-2" variation="primary" outline @click="$emit('close-notifications-modal')">{{ $t('notifications.cancel') }}</app-button>
            </slot>
          </div>
        </div>
      </div>
      <!-- loading effect -->
      <div class="data-loading" v-show="loading">
        <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
      </div>
    </div>
  </transition>
</template>

<script>
import { accountService } from '@/_services'

export default {
  name: 'NotificationsForTextAndReferral',
  props: ['question'],
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  data () {
    return {
      loading: false,
      roles: [],
      users: [],
      notificate: []
    }
  },
  async mounted () {
    this.loading = true
    const roles = await accountService.getRoles()
    if (roles) {
      this.roles = roles
      this.roles.map(item => {
        item.email = false
        item.sms = false
      })
    }

    const users = await accountService.getUsers()
    if (users) {
      this.users = users
      this.users.map(item => {
        item.email = false
        item.sms = false
      })
    }

    if (this.question.notificate.length > 0) {
      this.question.notificate.map(item => {
        item.recipients.map(recipient => {
          this.roles.map((role, i) => {
            if (role._id === recipient.role) {
              if (recipient.email) {
                this.roles[i].email = recipient.email
              }
              if (recipient.sms) {
                this.roles[i].sms = recipient.sms
              }
            }
          })
          this.users.map((user, userIdx) => {
            if (user._id === recipient.user) {
              if (recipient.email) {
                this.users[userIdx].email = recipient.email
              }
              if (recipient.sms) {
                this.users[userIdx].sms = recipient.sms
              }
            }
          })
        })
      })
    }
    this.loading = false
  },
  methods: {
    apply () {
      this.loading = true
      this.notificate = []
      const notification = { recipients: [] }
      this.roles.forEach(role => {
        if (role.email || role.sms) {
          const recipient = {
            role: role._id,
            email: role.email,
            sms: role.sms
          }
          notification.recipients.push(recipient)
        }
      })

      this.users.forEach(user => {
        if (user.email || user.sms) {
          const recipient = {
            user: user._id,
            email: user.email,
            sms: user.sms
          }
          notification.recipients.push(recipient)
        }
      })

      if (notification.recipients.length > 0) {
        this.notificate.push(notification)
      }

      this.question.notificate = this.notificate
      this.$store.commit('survey/upsertQuestion', this.question)
      this.loading = false
      this.$emit('close-notifications-modal')
    }
  }
}
</script>

<style lang="scss">
.body-notifications {
  width: 100% !important;
  max-height: 443px;
  min-height: 443px;
}
.modal-mask-notifications {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.17);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper-notifications {
  display: table-cell;
  vertical-align: middle;
  width: 880px !important;
  min-height: 300px !important;
  max-height: 450px !important;
  overflow-x: hidden;
}
.modal-header-notifications h3 {
  margin-top: 0;
  color: #42b983;
}
.option-label {
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 6px;
  padding: 10px !important;
  text-align: center !important;
}
.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}
@media (min-width: 700px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}
.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}
.tabs-component-tab:not(:last-child) {
  border-bottom: dotted 1px #ddd;
}
.tabs-component-tab:hover {
  color: #666;
}
.tabs-component-tab.is-active {
  color: #000;
}
.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}
@media (min-width: 700px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: .5em;
    transform: translateY(2px);
    transition: transform .3s ease;
  }
  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}
.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: .75em 1em;
  text-decoration: none;
}
.tabs-component-panels {
  padding: 2em 2em;
  height: 200px !important;
  overflow: auto;
}
@media (min-width: 700px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    padding: 2em 2em;
  }
}
</style>
