<template>
  <div>
    <div class="surveyBread editable-text">
      <div class="content-breadcrumb">
        <edit-title :title="survey.title" @updateSurveyTitle="updateSurveyTitle" class="survey-edit-title"/>
        <div class="bread-navigation">
          <app-menu-local dark :items="items">
            <template v-slot:item-structure="{item}">
              <router-link
                :to="{ name: item.link, params: { id: survey._id } }"
                :class="{'menu__items_item--active': !!item.active, 'breadcrumb-disabled': !!item.disabled}"
              >{{item.label}}
              </router-link>
            </template>
          </app-menu-local>
        </div>
      </div>
      <div class="actions-save-survey" v-if="showSurveyActionButtons === 'Surveys'">
        <div class="btn mt20" v-if="!survey.isTemplate">
          <app-button
            variation="primary"
            class="test-preview"
            :active="enableButton"
            v-on:click="showSurveyPreview()"
          >{{ $t('preview.simulate_survey') }}</app-button>
          <app-button
            variation="secondary"
            outline
            :active="enableButton"
            class="survey-save-as"
            v-on:click="saveSurvey(false, true)"
          >{{$i18n.t('survey.save_draft')}}</app-button>
          <app-button
            variation="primary"
            class="save-and-send"
            :active="enableButton"
            v-on:click="saveSurvey(true)"
          >{{$i18n.t('survey.save_and_send')}}</app-button>
        </div>
        <div class="btn mt15" v-if="survey.isTemplate">
          <app-button
            variation="primary"
            class="save-and-send"
            v-on:click="saveTemplate()"
          >{{$i18n.t('survey.toggle_save_as_template')}}
          </app-button>
        </div>
      </div>
      <div class="actions-save-survey" v-if="showSurveyActionButtons === 'Sendhub' && sendType !== 'csv'">
        <div class="btn mt20" >
          <app-button
            variation="primary"
            class="save-and-send"
            v-on:click="toSendEvent()"
          >{{$i18n.t('survey.save_and_send')}}</app-button>
        </div>
      </div>

      <div class="actions-save-survey" v-if="showSurveyActionButtons === 'Send' && sendhub === 'email' || sendhub === 'emailSms'">
        <div class="btn mt20">
          <app-button
            variation="secondary"
            outline
            class="survey-save-as"
            v-on:click="saveEmailConfig()"
          >{{$i18n.t('sendhub.save')}}</app-button>
          <app-button
            variation="primary"
            class="save-and-send"
            v-on:click="saveAndSend()"
          >{{$i18n.t('survey.save_and_send')}}</app-button>
        </div>
      </div>
      <div class="actions-save-survey" v-if="showSurveyActionButtons === 'Send' && sendhub === 'sms'">
        <div class="btn mt20">
          <app-button
            variation="primary"
            class="save-and-send"
            v-on:click="saveSms()"
          >{{$i18n.t('survey.save_and_send')}}</app-button>
        </div>
      </div>
      <div class="actions-save-survey" v-if="showSurveyActionButtons === 'Send' && sendhub === 'whatsapp'">
        <div class="btn mt20">
          <app-button
            variation="primary"
            class="save-and-send"
            v-on:click="saveWhats()"
          >{{$i18n.t('survey.save_and_send')}}</app-button>
        </div>
      </div>
    </div>
    <theme-style>
    .surveyBread {
      background-color: {{user.account.theme.colors.secondary || '#64469d' }} !important;
    }
    .survey-edit-title .text-field--secondary .text-field__input input {
      color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      border: 1.3px dashed {{user.account.theme.colors.secondary || '#64469d'}} !important;
      background-color: {{user.account.theme.colors.secondary || '#64469d'}} !important;
    }
    .survey-edit-title .text-field--secondary .text-field__input input:hover,
    .survey-edit-title .text-field--secondary .text-field__input input:focus {
      border-color: {{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
    }
    .survey-save-as {
      margin: 0;
      box-shadow: inset 0 0 0 2px {{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
    }
    .save-and-send {
      background-color: {{user.account.theme.colors.accent || '#ff607d' }} !important;
      box-shadow: inset 0 0 0 2px {{ user.account.theme.colors.accent || '#ff607d' }} !important;
      color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
    }
    .test-preview {
      background-color: {{ user.account.theme.colors.secondary || '#ff607d' }} !important;
      box-shadow: inset 0 0 0 2px {{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
    }
    .menu-local__item > a {
      color: {{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c87' : '#fbfbfb9c' }} !important;
    }
    .menu-local__item > a:hover, .menu-local__item > a:focus {
      color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      border-color:{{user.account.theme.colors.secondary || '#64469d'}} !important;
    }
    .bread-navigation > ul > li > .menu__items_item--active  {
      color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      border-color: {{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
    }
    </theme-style>
    <div class="loadingModal" v-show="isLoading">
      <rotate-square5></rotate-square5>
    </div>
    <app-modal
      class="modal-default-primary"
      v-model="isModalOpen"
      :title="$t('start.confirm_email_title')"
      lightbox
      close
      :primary-button="$t('start.confirm_email_resend')"
      v-on:keyup.esc="isModalOpen === false"
      @primaryButtonClick="resendConfirmEmail"
    >
      <span>{{$t('start.confirm_email_first')}}</span>
    </app-modal>
    <app-modal
      lightbox
      close
      v-model="showPreviewModal"
      v-on:keyup.esc="showPreviewModal === false"
      width="98%"
      :title="$t('preview.simulate_survey')"
      class="modal-default-primary preview-test"
    >
      <SurveyPreview :currentSurvey="survey"/>
    </app-modal>
  </div>
</template>

<script>
import EditTitle from '../EditTitle.vue'
import SurveyPreview from '../sendhub/hubs/SurveyPreview.vue'
import { surveyService, templatesService, sendhubService } from '@/_services'
import { errorMessagesFormatter } from '@/_helpers'
import { mapGetters } from 'vuex'

export default {
  name: 'Breadcrumb',
  components: {
    EditTitle,
    SurveyPreview,
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-menu-local': () => import('@binds-tech/binds-design-system/src/components/Menu/Local'),
    RotateSquare5: () => import('vue-loading-spinner/src/components/RotateSquare5')
  },
  props: ['sendhub'],
  data () {
    return {
      items: [
        { link: 'Surveys', label: this.$i18n.t('survey.bread_edit'), active: false, disabled: false, name: 'Surveys', id: 0 },
        { link: 'Send', label: this.$i18n.t('survey.bread_setting'), active: false, disabled: false, name: 'Send', id: 1 },
        { link: 'Sendhub', label: this.$i18n.t('survey.bread_send'), active: false, disabled: false, name: 'Sendhub', id: 2 }
      ],
      isLoading: false,
      isModalOpen: false,
      sendType: '',
      showPreviewModal: false
    }
  },
  beforeDestroy () {
    this.$root.$off('saveSurveyAndExit')
    this.$root.$off('update-active-import')
  },
  created () {
    let thisBread
    this.items.forEach((item, index) => {
      item.disabled = false
      if (item.name === this.$route.name) {
        item.active = true
        thisBread = index
      }
      if (item.id > thisBread) {
        item.disabled = true
      }
    })

    this.$root.$on('saveSurveyAndExit', async payload => {
      await this.saveSurvey(false, true)
      this.$router.push(payload)
    })

    this.$root.$on('update-active-import', (selected) => {
      this.sendType = selected
    })
  },
  computed: {
    showSurveyActionButtons () {
      return this.$route.name
    },
    emailSettings () {
      const settings = this.$store.getters['sendhub/getEmailSettings']
      return settings
    },
    survey () {
      return this.$store.getters['survey/getSurvey']
    },
    ...mapGetters({
      user: 'account/getUser'
    }),
    enableButton () {
      const active = this.$store.getters['questionSettings/getEnableButtonSave']
      return active
    }
  },
  methods: {
    showSurveyPreview () {
      const saveDraft = this.saveSurvey(false, false)
      if (saveDraft) {
        this.showPreviewModal = true
      }
    },
    toSendEvent () {
      this.$root.$emit('toSend')
    },
    updateSurveyTitle (e) {
      if (!e) {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('start.survey_title_placeholder'),
          position: 'topLeft',
          showAlert: true
        })
      } else {
        this.$store.commit('survey/survey', { title: e })
      }
    },
    async saveEmailConfig () {
      this.$root.$emit('saveEmail')
    },
    async saveSms () {
      const messageSms = this.$store.getters['survey/getSurvey'].sms
      const messageId = this.$store.getters['sendhub/getEmailSettings']._id
      const editSms = await sendhubService.updateSms({ sms: messageSms }, messageId)
      const accountIsTypeUserAndAllowedToSend = this.user.type === 'user' && this.user.features.seedBatches
      if (editSms || accountIsTypeUserAndAllowedToSend) {
        this.$router.push({ name: 'Sendhub', params: { id: this.survey._id }, query: { type: 'sms' } })
      } else {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('sendhub.smsHub.error_messages'),
          position: 'bottomRight',
          showAlert: true
        })
      }
    },
    async saveWhats () {
      this.$root.$emit('save-config-wpp')
      const objectEmailSettings = this.$store.getters['sendhub/getEmailSettings']
      delete objectEmailSettings.templatesWpp
      const editWpp = await sendhubService.updateWhats({ whatsapp: objectEmailSettings.whatsapp, images: objectEmailSettings.images }, objectEmailSettings._id)
      const accountIsTypeUserAndAllowedToSend = this.user.type === 'user' && this.user.features.seedBatches
      if (editWpp || accountIsTypeUserAndAllowedToSend) {
        this.$router.push({ name: 'Sendhub', params: { id: this.survey._id }, query: { type: 'whatsapp' } })
      } else {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('sendhub.smsHub.error_messages'),
          position: 'bottomRight',
          showAlert: true
        })
      }
    },
    saveAndSend () {
      this.saveEmailConfig()
      const survey = this.$store.getters['survey/getSurvey']
      this.$router.push({ name: 'Sendhub', params: { id: survey._id }, query: { type: this.sendhub === 'emailSms' ? 'all' : 'Email' } })
    },
    async saveSurvey (isToRedirect, showMessage) {
      this.isLoading = true
      const sendSurvey = this.$store.getters['survey/getSurvey']
      const colorArray = []
      if (!Array.isArray(sendSurvey.colors)) {
        Object.keys(sendSurvey.colors).forEach(item => {
          if (item && sendSurvey.colors[item]) {
            colorArray.push({ alias: item, value: sendSurvey.colors[item] })
          }
        })
        sendSurvey.colors = colorArray
      }
      sendSurvey.message = this.survey.message ? this.survey.message._id : undefined

      // o segundo parâmetro é o que diz se irá retornar a mensagem de erro da requisição (Boolean returnMessage).
      const resultSurvey = await surveyService.updateSurvey(sendSurvey, true)
      if (resultSurvey && !resultSurvey.errors) {
        this.formatColorsFromReq(resultSurvey)
        this.isLoading = false
        if (isToRedirect) {
          this.$router.push({ name: 'Send', params: { id: this.survey._id } })
        } else if (showMessage) {
          this.$store.commit('alerts/alert', {
            message: this.$i18n.t('survey.draft_saved'),
            position: 'bottomRight',
            showAlert: true
          })
        }
      } else {
        this.formatColorsFromReq(sendSurvey)
        this.isLoading = false
        const questionErrorMsg = errorMessagesFormatter.returnQuestionErrorMessage(resultSurvey.errors)
        this.$store.commit('alerts/alert', {
          message: questionErrorMsg,
          position: 'bottomRight',
          showAlert: true
        })
      }
      if (this.survey.isTemplate) {
        this.$store.commit('alerts/alert', { message: this.$i18n.t(resultSurvey.message), position: 'bottomRight', showAlert: true })
      }
    },
    formatColorsFromReq (survey) {
      const getColors = survey.colors.reduce((acc, cur) => ({ ...acc, [cur.alias]: cur.value }), {})
      survey.colors = getColors
      this.$store.commit('survey/survey', survey)
    },
    async resendConfirmEmail () {
      const result = await surveyService.resendEmail()
      if (result) {
        this.$store.commit('alerts/alert', { message: this.$i18n.t('start.confirm_email_resended'), position: 'bottomRight', showAlert: true, persist: false })
        this.isModalOpen = false
      }
    },
    async saveTemplate () {
      this.saveSurvey(false, true)
      this.templates.account = this.survey.account
      const result = await templatesService.addTemplate(this.survey._id, this.templates)

      if (result.status !== 'ok') {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('templates.error_saving_template'),
          position: 'bottomRight',
          showAlert: true
        })
      }
      this.$router.push({ name: 'Templates', params: { id: this.survey._id } })
    },
    async updateQuestions () {
      const questionsError = []
      this.survey.questions.forEach(async (item, index) => {
        item.order = index
        item.error = false
        if (item.type === 'blank') {
          item.error = true
          this.isLoading = false
          questionsError.push(item)
          this.$store.commit('survey/survey', {
            showSelector: false,
            hiddenEndMessage: true,
            hiddenStartMessage: true,
            questionSelected: index
          })
          return false
        }
        await surveyService.updateQuestion(this.survey._id, item)
      })
      return questionsError
    }
  }
}
</script>

<style lang="scss">
.loadingModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.801);
  z-index: 9999;
}
.spinner--rotate-square-5 {
    margin: 0 auto !important;
    margin-top: 17% !important;
    padding: 3% !important;
  }
.surveyBread {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}
.editable-text {
  .content-edit-title {
    margin-left: 19px;
    width: 75%;
    .heading {
      color: #ddd;
      padding: 5px;
    }
  }
  & .input-wrapper {
    & .input {
      height: 40px !important;
      font-size: 24px !important;
      background-color: #3f3356 !important;
    }
  }
}
.bread-navigation {
  margin-left: 26px !important;
  display: block;
}
.survey-edit-title {
  & .text-field--secondary .text-field__input {
    input {
      border-color: $color-purple-darker !important;
      border-radius: 4px;
      font-weight: 600 !important;
      padding: 5px !important;
      font-size: 22px !important;
    }
  }
}
.menu__items_item--active {
  opacity: 1 !important;
}
.breadcrumb-disabled {
  pointer-events: none !important;
  cursor: default !important;
  opacity: 0.4;
}
.actions-save-survey {
  padding-right: 20px;
}
.survey-preview {
  & .modal__wrapper__title {
    line-height: 34px !important;
    text-align: center;
    margin-bottom: 20px !important;
  }
  & .modal__wrapper__actions {
    text-align: center;
  }
}
</style>
