<template>
  <div class="container-input-fav" @click="expandInputFav(true)">
    <i class="material-icons icon-fav-filter" v-title="'Adicionar aos favoritos'">star</i>
    <input type="text" v-if="opened" class="input-fav-filter" placeholder="Digite o nome" v-model="nameFilter">
    <i class="material-icons icon-fav-filter-check" v-if="opened && nameFilter.length > 0" @click="createNewFilterFavorite()">check</i>
  </div>
</template>

<script>
// import { filtersService } from '@/_services'
import { filterFunctions } from '@/_helpers'

export default {
  name: 'InputNewFavorite',
  props: ['entity', 'fields'],
  components: {},
  data () {
    return {
      opened: false,
      nameFilter: ''
    }
  },
  mounted () {
  },
  computed: {},
  methods: {
    expandInputFav (e) {
      this.opened = true
      setTimeout(() => {
        const input = document.querySelector('.input-fav-filter')
        input.style.width = e ? '15rem' : '0rem'
        if (!e) { setTimeout(() => { this.opened = false }, 1000) }
      })
    },
    async createNewFilterFavorite () {
      // const $andWithRespondedAtremoved = this.$store.getters['filters/getDashFilter'].query.$and.filter(item => !item.respondedAt)

      // const filterToCreate = {
      //   query: {
      //     $and: $andWithRespondedAtremoved
      //   },
      //   entity: this.entity,
      //   name: this.nameFilter
      // }
      // const result = await filtersService.createCustomFilter(filterToCreate)
      const result = await filterFunctions.createFavoriteFilter(this.entity, this.nameFilter, this.$route, this.fields)
      if (result) {
        this.$root.$emit('update-favorite-filters')
        this.expandInputFav(false)
        this.nameFilter = ''
      } else {
        this.$store.commit('alerts/alert', {
          message: 'Erro ao favoritar o filtro',
          position: 'bottomRight',
          showAlert: true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-input-fav {
  height: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .icon-fav-filter, .icon-fav-filter-check {
    cursor: pointer;
    color: #e3e31e;
    font-size: 1.3rem;
    padding: 0.1rem;
    border: 0.1rem solid #e3e31e;
    border-radius: 50%;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.070);
  }
  .icon-fav-filter-check {
    font-size: 1.2rem;
    color: green;
    border-color: green;
  }
  .input-fav-filter {
    width: 0;
    height: 100%;
    transition: width 1.5s;
    padding-left: 0.5rem;
    font-size: 0.9rem;
    border-radius: 0.3rem;
    color: #808080;
    border: 0.1rem solid #cacaca;
  }
  .input-fav-filter::placeholder {
    color: #cacaca;
  }
}
</style>
