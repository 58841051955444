<template>
  <div>
    <canvas :id="makeSpecialId()" width="550" height="400"></canvas>
  </div>
</template>

<script>
import { canvasMaker } from '@/_helpers'
import Chart from 'chart.js'
export default {
  name: 'BarComparativeDash',
  props: ['dataComparative', 'metric'],
  methods: {
    makeSpecialId () {
      this.dataComparative.specialId = canvasMaker.makeCanvasIdWithoutParam()
      return this.dataComparative.specialId
    }
  },
  async mounted () {
    const labels = []
    const data = []
    const fontSize = 18
    const fontFamily = "'Avenir', Helvetica, Arial, sans-serif;"

    if (this.dataComparative) {
      this.dataComparative.map(item => {
        if (this.metric === 'sendings') {
          labels.push(item.survey.title)
          data.push(item.total)
        } else if (this.metric === 'responseTime') {
          labels.push(`${item.survey.title} ${item.metricTotal}`)
          data.push(item.metricTotal)
        } else {
          labels.push(`${item.survey.title} ${item.percentage}%`)
          data.push(item.percentage)
        }
      })
    }

    const planetChartData = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          { // one line graph
            data: data,
            backgroundColor: [
              '#a87df5', '#fc90a4', '#00aaa7', '#673ab7', '#9f95f5',
              '#FFB500', '#f55bed', '#CA1E54', '#4D5359', '#79C99E',
              '#97DB4F', '#27B573', '#FF7663', '#1B9AAA', '#FFC43D'
            ]
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        pointStyle: 'circle',
        responsive: true,
        lineTension: 1,
        scales: {
          xAxes: [{
            gridLines: {
              color: 'rgba(0, 0, 0, 0)'
            },
            ticks: {
              fontFamily: fontFamily,
              fontSize: fontSize,
              fontColor: '#939393'
            }
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              color: 'rgba(0, 0, 0, 0)'
            },
            ticks: {
              beginAtZero: true,
              padding: 25
            }
          }]
        }
      }
    }

    const ctx = document.getElementById(this.dataComparative.specialId)
    const chart = await new Chart(ctx, {
      type: planetChartData.type,
      data: planetChartData.data,
      options: planetChartData.options
    })

    return chart
  }
}
</script>
