<template>
    <div class="content-edit-title">
      <!-- Text input -->
      <!-- Add options -->
      <div v-if="canHaveMetadata">
        <optionsTitle
          v-if="optionsDisplay"
          :view="componentName"
          :metadata="metadata"
          :identify="identify"
          :lastSelection="lastSelection"
          @lastSelectionUpdate="lastSelectionUpdate($event)"
          :cursorPosition="cursorPosition"
          :title="title"
           @updateOptionsTitle="saveEdit($event)"
          />
      </div>
      <!-- Add options - END -->
      <div class="active-edit-title">
        <span class="edit-label">{{ $t('dashboard_card.edit') }}</span>
        <app-form-textarea-limited
          :key="render"
          v-if="type === 'textarea'"
          :id="id"
          secondary
          class="title center-text"
          :class="{'center-text' : center}"
          ref="title"
          :maxlength="maxCharacters ?? '750'"
          :identify="identify"
          :inputMinHeight="minHeight"
          :inputMaxHeight="maxHeight"
          :value="title"
          :colorText="color"
          @highlight="highlightText"
          @change.native="saveEdit($event.target.value)"
          @click="handleClick($event)"
          @input="countLength($event.length)"
        >
        </app-form-textarea-limited>
        <app-form-input
          v-if="type !== 'textarea'"
          :id="id"
          secondary
          class="title"
          ref="title"
          :value="title"
          :style="{ 'height': height, 'font-size': fontSize, 'color': color }"
          :colorText="color"
          @change.native="saveEdit($event.target.value)"
          @click="handleClick($event)"
        >
        </app-form-input>
      </div>
      <!-- Text input - END -->
      <theme-style>
        .edit-label {
          background-color: {{user.account.theme.colors.primary  || '#ed4d7f'}};
          box-shadow: inset 0 0 0 2px {{ user.account.theme.colors.primary || '#fdfbff' }} !important;
          color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }};
        }
        .fields-button > button {
          background-color: {{user.account.theme.colors.accent  || '#ff607d'}} !important;
        }
      </theme-style>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import optionsTitle from './survey/optionsTitle.vue'
export default {
  name: 'EditTitle',
  props: {
    identify: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: false
    },
    fontSize: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false
    },
    metadata: {
      type: Array,
      required: false
    },
    center: {
      type: Boolean,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    minHeight: {
      type: Number,
      required: false
    },
    maxCharacters: {
      type: Number,
      required: false
    },
    maxHeight: {
      type: Number,
      required: false
    },
    optionsDisplay: {
      type: Boolean,
      required: false
    },
    editStyle: {
      type: Boolean,
      required: false
    }
  },
  components: {
    'app-form-textarea-limited': () => import('@binds-tech/binds-design-system/src/components/Form/TextareaLimited'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    optionsTitle
  },
  data () {
    return {
      componentName: '',
      canHaveMetadata: false,
      usedFields: [],
      cursorPosition: null,
      id: null,
      timeout: null,
      lastSelection: {
        selectionStart: null,
        value: null
      },
      render: 0
    }
  },
  async mounted () {
    this.componentName = this.$parent.$vnode.componentOptions.tag
    this.id = this._uid
    const allowedViews = ['SurveyCreate', 'StartMessages', 'EndMessages', 'AnsweredMessage', 'WhatsappConfig', 'ModalAutomaticMessage']
    if (allowedViews.indexOf(this.$parent.$vnode.componentOptions.tag) > -1) {
      this.canHaveMetadata = true
    }
    this.$root.$on('showing-question', () => {
      this.render += 1
    })
    this.$root.$on('end-message-updated', () => {
      this.render += 1
    })
  },
  methods: {
    countLength (currentLength) {
      this.$root.$emit('current-length-wpp', currentLength)
    },
    highlightText (e) {
      this.lastSelection.selectionStart = e.target.selectionStart
      this.lastSelection.value = e.target.value.substring(
        e.target.selectionStart,
        e.target.selectionEnd
      )
    },
    lastSelectionUpdate (obj) {
      this.lastSelection = obj
    },
    saveEdit: function (newTitle) {
      if (this.componentName !== 'WhatsappConfig') {
        return this.$emit('updateSurveyTitle', newTitle)
      }
      this.$emit('updateNameTitle', newTitle)
    },
    handleClick (e) {
      if (this.editStyle) {
        this.$emit('openOptions')
        this.cursorPosition = e.target.selectionStart
        this.saveEdit(e.target.value)
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    }),
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    }
  }
}
</script>

<style lang="scss">
.title {
  width: 100%;
  & textarea {
    border:1.3px dashed white;
  }
  & input, & textarea {
    font-weight: 600 !important;
    font-size: 26px !important;
    padding: 2px !important;
    background-color: none !important;
    border: 1.3px dashed transparent !important;
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &:hover {
      border: 1.3px dashed $color-purple-darker !important;
      border-radius: 6px;
    }
    &:focus {
      border: 1.3px dashed $color-purple-darker !important;
      border-radius: 6px;
    }
  }
}
.content-edit-title {
  .input-wrapper:after {
    display: none;
  }
  position: relative;
  .text-field--secondary .text-field__input {
    background-color: transparent !important
  }
  &:hover {
    > .edit-label {
      display: block;
    }
  }
}
.active-edit-title {
  &:hover {
    > .edit-label {
      display: block;
      max-width: 80px;
      left: -55px !important;
    }
  }
}
.edit-hover {
  &:hover {
    border: 2px dashed $color-purple-darker;
    border-radius: 6px;
    cursor: text;
    position: relative;
  }
}
.edit-label {
  display: none;
  top: 30px;
  right: -53px;
  position: absolute;
  // background-color: #ed4d7f;
  z-index: 1;
  // color: #fff;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  -moz-animation: hiddenLabel 0s ease-in 1s forwards;
  /* Firefox */
  -webkit-animation: hiddenLabel 0s ease-in 1s forwards;
  /* Safari and Chrome */
  -o-animation: hiddenLabel 0s ease-in 1s forwards;
  /* Opera */
  animation: hiddenLabel 0s ease-in 1s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes hiddenLabel {
  to {
    width:0;
    height:0;
    display: none;
    font-size: 0;
    padding: 0;
  }
}
@-webkit-keyframes hiddenLabel {
  to {
    width:0;
    height:0;
    display: none;
    font-size: 0;
    padding: 0;
  }
}
.center-text {
  textarea {
    text-align: center;
  }
}
</style>
