<template>
  <div class="card-tag-insight" :style="{'border-color': returnColorBorderAndIcon(value), 'background': returnColorBackground(value)}">
    <i class="material-icons icon-tag-insight" :style="{'color': returnColorBorderAndIcon(value)}">{{ icon }}</i>
    <p class="card-title-insight">{{ title }}: <span class="card-value-insight">{{ capitalizeFirstLetter(value) }}</span></p>
  </div>
</template>

<script>
export default {
  name: 'TagInsight',
  props: ['icon', 'title', 'value'],
  components: {},
  data () {
    return {
      goods: ['Positivo', 'Elogio', 'Alegria', 'Confiança', 'Empatia'],
      neutral: ['Sugestão', 'Neutro', 'Surpresa'],
      negatives: ['Raiva', 'Hostilidade', 'Tristeza', 'Medo', 'Frustração', 'Aversão', 'Negativo', 'Crítica']
    }
  },
  mounted () {
  },
  methods: {
    capitalizeFirstLetter (str) {
    // WorkAround para colocar acento nas palavras, e colocar a primeira letra em maiúsculo, futuramente quando tiver translate para outras línguias usar o translate
      let convertStr = str && str.length > 0 ? str[0].toUpperCase() + str.slice(1) : str
      if (convertStr === 'Confianca') { convertStr = 'Confiança' }
      if (convertStr === 'Sugestao') { convertStr = 'Sugestão' }
      if (convertStr === 'Aversao') { convertStr = 'Aversão' }
      if (convertStr === 'Frustracao') { convertStr = 'Frustração' }
      if (convertStr === 'Critica') { convertStr = 'Crítica' }
      return convertStr
    },
    returnColorBorderAndIcon (e) {
      const str = this.capitalizeFirstLetter(e)
      if (this.goods.indexOf(str) > -1) { return '#7cb159' }
      if (this.neutral.indexOf(str) > -1) { return '#d5d54c' }
      if (this.negatives.indexOf(str) > -1) { return '#e98480' }
      return '#808080'
    },
    returnColorBackground (e) {
      const str = this.capitalizeFirstLetter(e)
      if (this.goods.indexOf(str) > -1) { return '#7cb15940' }
      if (this.neutral.indexOf(str) > -1) { return '#d5d54c40' }
      if (this.negatives.indexOf(str) > -1) { return '#e9848040' }
      return '#f5f5f5'
    }
  }
}
</script>

<style lang="scss" scoped>
.card-tag-insight {
  height: 1.7vw;
  min-width: 7vw;
  border-radius: 3.5rem;
  padding: 0.2vw 0.3vw;
  border: 0.1rem solid #808080;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .card-title-insight {
    font-size: 0.6vw;
    color: #000000;
    font-weight: bold;
    .card-value-insight {
      color: #454548;
      font-weight: normal;
      text-transform: capitalize;
    }
  }
  .icon-tag-insight {
    font-size: 0.9vw;
    color: #808080;
  }
}
</style>
