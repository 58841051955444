export const alfabetical = {
  changeToAlfabeticalOrder
}

/**
 * Retorna um array ordenado alfabeticamente, baseado na KEY recebida por parametro
 * @param {Array} array contendo o array a ser ordernado
 * @param {String} key contendo a key do array para realizar a comparação
 */
function changeToAlfabeticalOrder (array, key) {
  return array.sort(function (objA, objB) {
    var a = reduceString(objA[key])
    var b = reduceString(objB[key])
    return a < b ? -1 : a > b ? 1 : 0
  })
}

function reduceString (string) {
  if (string) {
    return string.toLowerCase().replace(/[àáâãäå]/, 'a').replace(/[èéêë]/, 'e').replace(/[ìíîï]/, 'i').replace(/[òóôõö]/, 'o').replace(/[ùúûü]/, 'u').replace(/[ç]/, 'c').replace(/[^a-z0-9]/gi, '')
  }
}
