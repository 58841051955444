import 'whatwg-fetch'
import { callApiService } from '@/_services'
import store from '@/store'
const API_URL = process.env.VUE_APP_URL

export const userService = {
  login, signup, resetpass, logout, updateUser, getMe, resendVerificationCode, loginWithSSO
}
const headers = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
}

async function getMe () {
  const userUrl = API_URL + 'users/me'
  const res = await callApiService.callApi(userUrl, 'get')
  if (res && res._id) {
    const previousAccount = store.getters['account/getAccount']
    if (previousAccount && res.account._id !== previousAccount._id) { // previne que dê erro ao entrar em outra conta com pesquisas diferentes...
      store.commit('dashboard/resetToInitialState')
      store.commit('filters/resetToInitialState')
      store.commit('survey/resetToInitialState')
      store.commit('sendhub/resetToInitialState')
      store.commit('seeds/resetToInitialState')
      store.commit('templates/resetToInitialState')
      store.commit('widget/resetToInitialState')
      store.commit('questionSettings/resetToInitialState')
    }

    if (!res.image) {
      res.image = {}
    }
    window.me = res // Feito para o uso do comercial
    store.commit('account/setAccount', res.account)
    store.commit('account/setUser', res)
    store.commit('account/language', res.language)
  }
  return res
}

async function updateUser (userId, settings) {
  const userUrl = API_URL + 'users/' + userId
  return await callApiService.callApi(userUrl, 'put', settings)
}

async function login (body) {
  const jsonResponse = await callApiWithoutAuth(API_URL + 'login', body)
  if ((jsonResponse.user && jsonResponse.account) || jsonResponse.token) {
    localStorage.setItem('bindsUser', JSON.stringify(jsonResponse.token))
  }
  return jsonResponse
}

async function signup (account) {
  return callApiWithoutAuth(API_URL + 'accountOwners', account)
}

function resendVerificationCode (body) {
  return callApiWithoutAuth(API_URL + 'login', body)
}

function loginWithSSO (url) {
  return callApiWithoutAuth(url)
}

function resetpass (body, token) {
  let url = API_URL + 'passwordResetRequests'
  const type = token ? 'put' : 'post'
  url = token ? `${url}/${token}` : url
  return callApiWithoutAuth(url, body, type)
}

function logout () {
  // remove user from local storage to log user out
  localStorage.removeItem('user')
  window.location.reload('/')
}

async function callApiWithoutAuth (url, body, type = 'post') {
  try {
    return await window.fetch(url, {
      method: body ? type : 'get',
      headers: headers,
      body: JSON.stringify(body)
    }, 30000).then(res => {
      return res.json()
    }).then(jsonResponse => {
      console.log('jsonResponse')
      return jsonResponse
    }).catch(error => {
      console.log('Error inside userService', error)
    })
  } catch (error) {
    console.log('Error to call DB on userService', error)
    return []
  }
}
