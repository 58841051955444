<template>
  <div>
    <div class="content-cite-analytics">
       <section>
         <div class="empty-responses" v-if="empty">
           <app-heading level="h4"> Sem Resposta </app-heading>
         </div>
        <div class="list-text-analytics" v-else>
            <div class="box-cite-analytics hvr-float-shadow "
            style="max-height: 100%;"
            v-for="(item, index) in responses"
            :key="index"
            v-on:click="getIndividualResponses(item)"
            :class="[
                { 'border-promotor-kpi' : item.rating > 75 && item.selectedQuestion.ui === '5emo' },
                { 'border-neutral' : item.rating < 75 && item.rating >= 25 && item.selectedQuestion.ui === '5emo' },
                { 'border-detractor' : item.rating < 25 && item.selectedQuestion.ui === '5emo' },
                { 'border-promotor' : item.rating >= 90 && item.selectedQuestion.type === 'nps' },
                { 'border-neutral' : item.rating >= 70 && item.rating < 90 && item.selectedQuestion.type === 'nps' },
                { 'border-detractor' : item.rating < 70 && item.selectedQuestion.type === 'nps' },
                { 'border-promotor' : item.rating <= 25 &&  item.selectedQuestion.type === 'nes' },
                { 'border-neutral' : item.rating === 50 && item.selectedQuestion.type === 'nes' },
                { 'border-detractor' : item.rating >= 75 && item.selectedQuestion.type === 'nes' },
                { 'border-promotor' : item.rating === 100 &&  (item.selectedQuestion.ui === 'yn' || item.selectedQuestion.ui === 'ynd') },
                { 'border-neutral' : item.rating === 50 && (item.selectedQuestion.ui === 'yn' || item.selectedQuestion.ui === 'ynd') },
                { 'border-detractor' : item.rating === 0 && (item.selectedQuestion.ui === 'yn' || item.selectedQuestion.ui === 'ynd') },
                { 'border-promotor' : item.rating === 100 &&  (item.selectedQuestion.ui === 'yn' || item.selectedQuestion.ui === 'ynd') },
                { 'border-promotor' : item.rating >= 70 && (item.selectedQuestion.ui === '10num' || item.selectedQuestion.type === 'csat') },
                { 'border-detractor' : item.rating < 70 && (item.selectedQuestion.ui === '10num' || item.selectedQuestion.type === 'csat') }
            ]"
            >
                <div class="contact-answered">
                  <div style="margin-bottom: 5px;">
                    <app-heading level="h5" style="min-width:auto;font-weight: 600" v-if="item?.seedData?.from?.name">{{item?.seedData?.from?.name}}</app-heading>
                  </div>
                  <div>
                    <app-heading level="h6" class="contact-email" v-if="item?.seedData?.from?.email">{{item?.seedData?.from?.email}}</app-heading>
                  </div>
                </div>
                <div class="response-analytics">
                    <p v-html="highlightSearchedWord(item.text)"></p>
                </div>
                <div id="text-footer" class="to-text-footer">
                    <div v-if="item.selectedQuestion.type === 'nps'">
                      <span style="color:#00aaa7" class="result-response-analytic" v-if="item.rating >= 90" >{{ $t("dashboard.graphics.promoter") }} - {{item.rating / 10}}</span>
                      <span style="color:#ffb500" class="result-response-analytic" v-if="item.rating >= 70 && item.rating < 90" >{{ $t("dashboard.graphics.neutralString") }} - {{item.rating / 10}}</span>
                      <span style="color:#ff607d" class="result-response-analytic" v-if="item.rating < 70" >{{ $t("dashboard.graphics.detractor") }} - {{item.rating / 10}}</span>
                    </div>
                    <div v-if="item.selectedQuestion.ui === 'yn'">
                      <span style="color:#00aaa7" class="result-response-analytic" v-if="item.rating === 100" >{{ $t("dashboard.graphics.op1") }}</span>
                      <span style="color:#ff607d" class="result-response-analytic" v-if="item.rating === 0" >{{ $t("dashboard.graphics.op2") }}</span>
                    </div>
                    <div v-if="item.selectedQuestion.ui === 'ynd'">
                      <span style="color:#00aaa7" class="result-response-analytic" v-if="item.rating === 100" >{{ $t("dashboard.graphics.op1") }}</span>
                      <span style="color:#ffb500" class="result-response-analytic" v-if="item.rating === 50" >{{ $t("dashboard.graphics.op3") }}</span>
                      <span style="color:#ff607d" class="result-response-analytic" v-if="item.rating === 0" >{{ $t("dashboard.graphics.op2") }}</span>
                    </div>
                    <div v-if="item.selectedQuestion.type === 'nes'">
                      <span style="color:#00aaa7" class="result-response-analytic" v-if="item.rating <= 25" >{{ $t("dashboard.graphics.le") }}</span>
                      <span style="color:#ffb500" class="result-response-analytic" v-if="item.rating === 50" >{{ $t("dashboard.graphics.ee") }}</span>
                      <span style="color:#ff607d" class="result-response-analytic" v-if="item.rating >= 75" >{{ $t("dashboard.graphics.me") }}</span>
                    </div>
                    <div v-if="item.selectedQuestion.type === 'csat' && item.selectedQuestion.ui === '10num'">
                      <span style="color:#00aaa7" class="result-response-analytic" v-if="item.rating >= 70" >{{ $t("dashboard.graphics.sat") }}</span>
                      <span style="color:#ff607d" class="result-response-analytic" v-if="item.rating < 70" >{{ $t("dashboard.graphics.un") }}</span>
                    </div>
                    <div v-if="item.selectedQuestion.type === 'csat' && item.selectedQuestion.ui === '5emo'" class="analytics-5emo">
                      <div class="label-5emo" v-if="item.rating === 100">
                        <old-5emo  class="hvr-grow" name="icon-joy-gee" size="30" color="#49BB59"></old-5emo>
                        <span><app-heading level="h6">{{$t('dashboard.graphics.vs')}}</app-heading></span>
                      </div>
                      <div class="label-5emo" v-if="item.rating === 75">
                        <old-5emo  class="hvr-grow" name="icon-happy-gee" size="30" color="#37B0E4"></old-5emo>
                        <span><app-heading level="h6">{{$t('dashboard.graphics.sat')}}</app-heading></span>
                      </div>
                      <div class="label-5emo" v-if="item.rating === 50">
                        <old-5emo class="hvr-grow" name="icon-neutral-sleep" size="30" color="#9E9E9E"></old-5emo>
                        <span><app-heading level="h6">{{$t('dashboard.graphics.ind')}}</app-heading></span>
                      </div>
                      <div class="label-5emo" v-if="item.rating === 25">
                        <old-5emo class="hvr-grow" name="icon-unhappy-sleep" size="30" color="#F26C3C"></old-5emo>
                        <span><app-heading level="h6">{{$t('dashboard.graphics.un')}}</app-heading></span>
                      </div>
                      <div class="label-5emo" v-if="item.rating === 0">
                        <old-5emo class="hvr-grow" name="icon-wow-open" size="30" color="#EE2E35"></old-5emo>
                        <span><app-heading level="h6">{{$t('dashboard.graphics.vu')}}</app-heading></span>
                      </div>
                    </div>
                    <div v-if="item.selectedQuestion.type === 'kpi' && item.selectedQuestion.ui === '5emo'" class="analytics-5emo">
                      <div class="label-5emo" v-if="item.rating === 100">
                        <old-5emo  class="hvr-grow" name="icon-joy-gee" size="30" color="#49BB59"></old-5emo>
                        <span><app-heading level="h6">{{$t('dashboard.graphics.ex')}}</app-heading></span>
                      </div>
                      <div class="label-5emo" v-if="item.rating === 75">
                        <old-5emo  class="hvr-grow" name="icon-happy-gee" size="30" color="#37B0E4"></old-5emo>
                        <span><app-heading level="h6">{{$t('dashboard.graphics.vg')}}</app-heading></span>
                      </div>
                      <div class="label-5emo" v-if="item.rating === 50">
                        <old-5emo class="hvr-grow" name="icon-neutral-sleep" size="30" color="#9E9E9E"></old-5emo>
                        <span><app-heading level="h6">{{$t('dashboard.graphics.satisf')}}</app-heading></span>
                      </div>
                      <div class="label-5emo" v-if="item.rating === 25">
                        <old-5emo class="hvr-grow" name="icon-unhappy-sleep" size="30" color="#F26C3C"></old-5emo>
                        <span><app-heading level="h6">{{$t('dashboard.graphics.bd')}}</app-heading></span>
                      </div>
                      <div class="label-5emo" v-if="item.rating === 0">
                        <old-5emo class="hvr-grow" name="icon-wow-open" size="30" color="#EE2E35"></old-5emo>
                        <span><app-heading level="h6">{{$t('dashboard.graphics.vb')}}</app-heading></span>
                      </div>
                    </div>
                    <div class="text-date">{{item?.seedData?.createdAt | moment("from", "now")}}</div>
                    <!-- <div class="footer-box-textanalytics">
                      <app-button inline v-on:click="getIndividualResponses(item)">{{ $t("dashboard.see_more")}}</app-button>
                    </div> -->
                </div>
            </div>
        </div>
      </section>
      <Details
        v-if="showDetails"
        :id="sendingId"
        @closeSendings="closeSidebar"
        :class="{'expand-details': showDetails}"
      ></Details>
    </div>
    <!-- <theme-style>
      .listMore:hover {
        border-color: {{this.cardData.average.colors[3]}} !important;
        color: {{this.cardData.average.colors[3]}} !important;
      }
    </theme-style> -->
  </div>
</template>

<script>
import { jsPDF } from 'jspdf'
import { formatters } from '@/_helpers'
export default {
  name: 'TextAnalytics',
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'old-5emo': () => import('@binds-tech/binds-design-system/src/components/Icon/Old5emo'),
    Details: () => import('../SideBarConfig/SidebarSendings.vue')
  },
  props: ['empty', 'responses', 'searchedTerm'],
  data () {
    return {
      sendingId: '',
      showDetails: ''
    }
  },
  computed: {
    lang () {
      return this.$store.getters['account/getLang']
    }
  },
  methods: {
    highlightSearchedWord (text) {
      if (!this.searchedTerm) {
        return text
      }

      return text.replace(new RegExp(this.searchedTerm, 'gi'), match => {
        return '<span style="background: #fc0; padding: 0 3px; color: #666;">' + match + '</span>'
      })
    },
    getIndividualResponses (item) {
      this.showDetails = false
      this.sendingId = item.sendingId
      setTimeout(() => { this.showDetails = true }, 100)
    },
    closeSidebar () {
      this.showDetails = false
    },
    exportResponses () {
      // tem que desabilitar o eslint nessa linha porque ele reclama do construtor jsPDF começar com letra minúscula
      /* eslint-disable */
      var doc = new jsPDF('l', 'mm', [100, 280])

      this.responses.map(item => {
        const email = doc.splitTextToSize(item?.seedData?.from.email, 180)
        doc.setFontSize(16)
        doc.setFont('times')
        doc.setTextColor(8, 8, 8)
        doc.text(20, 15, email)

        const timeS = this.$moment(item.respondedAt).fromNow()
        const time = doc.splitTextToSize(timeS, 180)
        doc.setFontSize(14)
        doc.setFont('times')
        doc.setTextColor(150)
        doc.text(20, 22, time)

        const response = doc.splitTextToSize(`"${item.text}"`, 200)
        doc.setFontSize(14)
        doc.setFont('times')
        doc.setTextColor(8, 8, 8)
        doc.text(30, 40, response)

        const footerObj = formatters.footerKeysForExport(item.selectedQuestion.type, item.selectedQuestion.ui, item.rating)
        const footer = doc.splitTextToSize(footerObj.text, 180)
        doc.setFontSize(14)
        doc.setFont('times')
        doc.setTextColor(footerObj.color)
        doc.text(20, 80, footer)

        doc.addPage()
      })

      // Save the PDF
      doc.save('responses.pdf')
    }
  },
  beforeDestroy () {
    this.$root.$off('export-responses-cloud')
  },
  mounted () {
    this.$moment.locale(this.lang)
    this.$root.$on('export-responses-cloud', () => {
      this.exportResponses()
    })
  }
}
</script>
<style lang="scss">
.contact-answered {
  margin-bottom: 20px;
  padding-top: 10px;
  box-sizing: border-box;
}
.content-cite-analytics {
  max-width: 1360px;
  margin:0 auto;
  margin-bottom: 100px;
  section {
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
    border: 20px solid #fff;
    .list-text-analytics {
      max-height: 750px;
      overflow-y: auto !important;
      overflow-x: hidden;
      padding-right: 15px;
    }
    .label-5emo span {
      margin-left: 5px;
      width: auto;
    }
  }
}
.box-cite-analytics {
  background-color: #eff3f8;
  border-radius:6px;
  padding: 15px;
  width: 100%;
  max-height: 290px;
  margin: 6px;
  margin-bottom: 20px;
  box-sizing: border-box;
  vertical-align: top;
  cursor: pointer;
  display: block;
  .to-text-footer {
    margin-top: 30px;
    font-style: italic;
    font-size: 14px;
  }
  .response-analytics {
    overflow:  auto;
    p {
      line-height: 21px;
      font-style: italic;
      color: #838383;
      font-size: 15px;
    }
  }
  .text-date {
    margin-top: 5px;
    font-size: 12px;
  }
  .result-response-analytic {
    font-weight: bold;
    font-size: 16px;
  }
}
.border-promotor {
  border-left: 7px solid #00aaa7
}
.border-neutral {
  border-left: 7px solid #ffb500
}
.border-detractor {
  border-left: 7px solid #ff607d
}
.border-promotor-kpi {
  border-left: 7px solid #9871dd
}
.footer-box-textanalytics{
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.contact-name {
  h5 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 700px) {
  .box-cite-analytics {
    width: 88%;
  }
  .content-cite-analytics {
    .card {
      .list-text-analytics {
        max-height: fit-content;
        overflow: hidden !important;
      }
    }
  }
}
</style>
