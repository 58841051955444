const moment = require('moment')
const periods = {
  day: { unit: 'hour', qty: 24 },
  week: { unit: 'day', qty: 7 },
  month: { unit: 'day', qty: 30 },
  semester: { unit: 'month', qty: 6 },
  year: { unit: 'month', qty: 12 }
}

function stringPeriodToPlainDate (period) {
  const now = moment(new Date()).utc()
  const unit = periods[period].unit
  const qty = periods[period].qty
  let end = now.clone()
  let start = now.clone().subtract(qty, unit)
  if (period !== 'day') {
    end = now.clone().endOf('day').add(3, 'hours')
    start = now.clone().subtract(qty, unit).startOf('day').add(3, 'hours')
  }
  return { formatedStart: start.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), formatedEnd: end.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), start, end }
}

export const dateFormatter = {
  stringPeriodToPlainDate
}
