<template>
  <div>
    <div class="row start-lg start-md start-sm comment" v-if="!this.question.mask" style="width:525px;">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <app-form-textarea :color="survey.colors.splashColor" :placeholder="question.placeholder ? question.placeholder : t('survey_store.input_textarea')" v-model="value"></app-form-textarea>
      </div>
    </div>
    <div class="row start-lg start-md start-sm input-text" :class="{'input-size-prev' : !preview}" v-else>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <app-form-input secondary border-solid v-model="value" :placeholder="question.placeholder ? question.placeholder : t('survey_store.input_textarea')"></app-form-input>
      </div>
    </div>
  </div>
</template>

<script>
import { translator } from '@/_helpers'

export default {
  name: 'apptext',
  props: ['question', 'preview'],
  components: {
    'app-form-textarea': () => import('@binds-tech/binds-design-system/src/components/Form/Textarea'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input')
  },
  data () {
    return {
      value: ''
    }
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    }
  },
  methods: {
    t (text) {
      this.question.placeholder = translator.translateThisText(this.question.language, text)
      return this.question.placeholder
    }
  }
}
</script>
<style lang="scss">
.comment {
  & h6 {
    font-size: 18px !important;
    float: left;
    margin-bottom: -50px !important;
  }
  & textarea {
    margin-top: 75px !important;
    height: 250px;
    width: 92% !important;
  }
  .input-wrapper:after {
    display: none;
  }
}
.input-size-prev {
  width: 550px !important;
}
// .binds-content-widget {
//   .input-wrapper {
//     &:after {display: none;}
//   }
// }
</style>
