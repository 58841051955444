<template>
    <div v-html="embededCode"></div>
</template>
<script>
export default {
  props: ['question'],
  data () {
    return {
      embededCode: [],
      label1: '',
      label2: '',
      label3: '',
      label4: '',
      label5: '',
      contentWidth: ''
    }
  },
  mounted () {
    if (this.question.type === 'csat') {
      this.label1 = this.$t('dashboard.graphics.vs')
      this.label2 = this.$t('dashboard.graphics.sat')
      this.label3 = this.$t('dashboard.graphics.ind')
      this.label4 = this.$t('dashboard.graphics.un')
      this.label5 = this.$t('dashboard.graphics.vu')
      this.contentWidth = '225'
    } else {
      this.label1 = this.$t('dashboard.graphics.ex')
      this.label2 = this.$t('dashboard.graphics.vg')
      this.label3 = this.$t('dashboard.graphics.satisf')
      this.label4 = this.$t('dashboard.graphics.bd')
      this.label5 = this.$t('dashboard.graphics.vb')
      this.contentWidth = '164'
    }
    this.embededCode = ['<div class="email-cta" style="width:400px;margin:0 auto;"> \n ',
      '<h2 style="margin:0 auto;line-height:23px;text-align:center;color:{{color.c2}}">' + this.question.question + '</h2> \n ',
      '<br> \n',
      '<br> \n',
      '<table width="' + this.contentWidth + '" cellspacing="0" cellpadding="0" border="0" class="ng-scope" style="display:flex;justify-content:center"> \n',
      '<tbody> \n',
      '<tr> \n',
      '<td valign="top" align="center"> \n',
      '<div style="background:#fff;color:#666;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;font-size:20px;">\n',
      '<table width="100%" cellspacing="0" cellpadding="0" border="0">\n',
      '<tbody>\n',
      '<tr style="display:flex;align-item:center;margin-bottom:10px">\n',
      '<td>\n',
      '<a href="*|SRV:100|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n',
      '<img  src="https://res.cloudinary.com/binds-co/image/upload/v1523293658/faces/excelente.png" alt="' + this.label1 + '" height="40" width="40"> \n',
      '</td>\n',
      '<td>\n',
      '<a href="*|SRV:100|*" style="font-size:20px; font-family:Arial,sans-serif; text-decoration:none; line-height:40px; width:100%; display:inline-block;margin: auto;color: #333;"> &nbsp;&nbsp;' + this.label1 + '</a>\n',
      '</td>\n',
      '</tr>\n',
      '<tr style="display:flex;align-item:center;margin-bottom:10px">\n',
      '<td>\n',
      '<a href="*|SRV:75|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n',
      '<img  src="https://res.cloudinary.com/binds-co/image/upload/v1523293658/faces/muito-bom.png" alt="' + this.label2 + '" height="40" width="40"> \n',
      '</td>\n',
      '<td>\n',
      '<a href="*|SRV:75|*" style="font-size:20px; font-family:Arial,sans-serif; text-decoration:none; line-height:40px; width:100%; display:inline-block;margin: auto;color: #333;"> &nbsp;&nbsp;' + this.label2 + '</a>\n',
      '</td>\n',
      '</tr>\n',
      '<tr style="display:flex;align-item:center;margin-bottom:10px">\n',
      '<td>\n',
      '<a href="*|SRV:50|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n',
      '<img  src="https://res.cloudinary.com/binds-co/image/upload/v1523293658/faces/satisfatorio.png" alt="' + this.label3 + '" height="40" width="40"> \n',
      '</td>\n',
      '<td>\n',
      '<a href="*|SRV:50|*" style="font-size:20px; font-family:Arial,sans-serif; text-decoration:none; line-height:40px; width:100%; display:inline-block;margin: auto;color: #333;"> &nbsp;&nbsp;' + this.label3 + '</a>\n',
      '</td>\n',
      '</tr>\n',
      '<tr style="display:flex;align-item:center;margin-bottom:10px">\n',
      '<td>\n',
      '<a href="*|SRV:25|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n',
      '<img  src="https://res.cloudinary.com/binds-co/image/upload/v1523293658/faces/ruim.png" alt="' + this.label4 + '" height="40" width="40"> \n',
      '</td>\n',
      '<td>\n',
      '<a href="*|SRV:25|*" style="font-size:20px; font-family:Arial,sans-serif; text-decoration:none; line-height:40px; width:100%; display:inline-block;margin: auto;color: #333;"> &nbsp;&nbsp;' + this.label4 + '</a>\n',
      '</td>\n',
      '</tr>\n',
      '<tr style="display:flex;align-item:center;margin-bottom:10px">\n',
      '<td>\n',
      '<a href="*|SRV:0|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;display:inline-block">\n',
      '<img  src="https://res.cloudinary.com/binds-co/image/upload/v1523293658/faces/muito-ruim.png" alt="' + this.label5 + '" height="40" width="40"> \n',
      '</td>\n',
      '<td>\n',
      '<a href="*|SRV:0|*" style="font-size:20px; font-family:Arial,sans-serif; text-decoration:none; line-height:40px; width:100%; display:inline-block;margin: auto;color: #333;"> &nbsp;&nbsp;' + this.label5 + '</a>\n',
      '</td>\n',
      '</tr>\n',
      '</tbody>\n',
      '</table>\n',
      '</div>\n',
      '<br> \n',
      '<br> \n',
      '<br> \n',
      '</td> \n',
      '</tr> \n',
      '</tbody> \n',
      '</table> \n',
      '</div>'
    ].join('')
    this.$emit('embedQuestion', this.embededCode)
  }
}
</script>
