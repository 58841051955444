<template>
  <div class="container-health-sidebar">
    <div class="content-average-health-sidebar">
      <SquareHealthCard :dataCircle="healthData.health.score" :details="average" />
      <SquareHealthCard :dataCircle="healthData.health.average" :details="addressed" />
      <SquareHealthCard :dataCircle="healthData.health.churn" :details="churn" />
    </div>
    <div class="content-overtime-health-sidebar">
      <IndividualChartHealth :chartData="healthData" from="sidebar" />
    </div>
  </div>
</template>

<script>
import IndividualChartHealth from '../../dashboard/individual/IndividualChartHealth.vue'
import SquareHealthCard from '../../dashboard/charts/SquareHealthCard.vue'

export default {
  name: 'HealthChartSidebar',
  props: ['dataHealth'],
  components: {
    SquareHealthCard,
    IndividualChartHealth
  },
  data () {
    return {
      healthData: null,
      churn: {
        type: 'churn',
        title: 'health_score.title_card_churn',
        tooltip: 'health_score.tooltip_probability_churn',
        icon: 'autorenew',
        color: ''
      },
      average: {
        type: 'score',
        title: 'health_score.title_graph_approach',
        tooltip: 'health_score.tooltip_approach',
        icon: 'send',
        color: ''
      },
      addressed: {
        type: 'score',
        title: 'health_score.title_approaches_health',
        tooltip: 'health_score.tooltip_addressed',
        icon: 'person',
        color: ''
      }
    }
  },
  created () {
    this.healthData = this.dataHealth
  }
}
</script>

<style lang="scss" scoped>
.container-health-sidebar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2vw;
  .content-average-health-sidebar {
    width: 100%;
    height: 55%;
    display: flex;
    justify-content: space-around;
    .content-gauge-sidebar {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2.5vw;
      .label-gauge-sidebar {
        line-height: 0.8vw;
        color: #424242;
        font-weight: 500;
        text-align: center;
        font-size: 0.8vw;
      }
    }
  }
  .content-overtime-health-sidebar {
    background: #ffffff;
    width: 100%;
    height: 45%;
    border: 1px solid #e1e1e1;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 0.3vw;
    padding: 0.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
