<template>
  <div class="content-gauge-health" :style="{'--color': returnColorGraph}">
    <div class="gauge-health">
      <div class="arrow-gauge-health" :ref="`arrow-gauge-${id}`">
        <div class="background-gauge-health"></div>
      </div>
    </div>
    <div class="gauge-center-health" :style="{'background': `#${containerBackground}` || '#ffffff'}"></div>
    <div class="icon-value-gauge-health">
      <i class="material-icons icon-heart-health">favorite</i>
      <p class="value-gauge-health" :ref="`value-gauge-${id}`">{{ dataValue }}</p>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import mixins from '@/_mixins/health'

export default {
  name: 'GaugeHeart',
  mixins: [mixins],
  props: {
    containerBackground: {
      type: String,
      require: false
    },
    id: {
      type: String,
      require: true
    },
    dataValue: {
      type: Number,
      require: true
    }
  },
  components: {},
  data () {
    return {
      currentRotation: 0
    }
  },
  mounted () {
    this.initialAnimation()
  },
  computed: {
    angleHealth () {
      const rangeHealth = 100
      const rangeAngle = 180
      return (this.dataValue * rangeAngle) / rangeHealth
    },
    returnColorGraph () { return this.getColorForHealth(this.dataValue, 'score') }
  },
  methods: {
    animateValue () {
      const myValue = this.$refs[`value-gauge-${this.id}`]

      myValue.innerHTML = 0

      gsap.to(myValue, {
        duration: 1.8,
        innerHTML: this.dataValue,
        snap: 'innerHTML'
      })
    },
    initialAnimation () {
      this.animateValue()

      const arrow = this.$refs[`arrow-gauge-${this.id}`]
      const pulseGif = this.$refs[`gif-pulse-${this.id}`]

      const finalAngle = 180
      const computedAngle = this.angleHealth

      const rotationToFinal = this.currentRotation + (finalAngle - this.currentRotation + 360) % 360
      const rotationToComputed = rotationToFinal - (rotationToFinal - computedAngle + 360) % 360

      gsap.to(arrow, {
        rotation: rotationToFinal,
        duration: 1,
        onComplete: () => {
          this.currentRotation = rotationToFinal % 360
          gsap.to(arrow, {
            rotation: rotationToComputed,
            duration: 1,
            onComplete: () => {
              this.currentRotation = rotationToComputed % 360
              gsap.to(pulseGif, {
                display: 'none',
                opacity: 0,
                duration: 0.5
              })
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .content-gauge-health {
    position: relative;
    width: 15vw;
    height: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .gauge-health {
      z-index: 20;
      position: relative;
      background: #ffffff;
      width: 14vw;
      height: 7vw;
      border-radius: 10vw 10vw 0 0;
      border-bottom-left-radius: 0.5vw;
      border-bottom-right-radius: 0.5vw;
      overflow: hidden;
      box-shadow: 0 -0.5px 4px #e0e0d9;
      .arrow-gauge-health {
        position: relative;
        width: 25.5%;
        height: 0.3vw;
        background: #454548;
        position: absolute;
        bottom: -0.5%;
        left: 25%;
        transform: translateX(-50%);
        transform-origin: 100% 4px;
        transform: rotate(0deg);
        display: flex;
        z-index: 28;
        &::before {
          z-index: 28;
          content: '';
          position: absolute;
          bottom: -100%;
          left: -40%;
          transform-origin: 100% 3px;
          transform: rotate(-90deg);
          width: 0;
          height: 0;
          border-left: 0.6vw solid transparent;
          border-right: 0.6vw solid transparent;
          border-bottom: 1.2vw solid #454548;
        }
        .background-gauge-health {
          position: absolute;
          top: 0.2vw;
          left: -170%;
          width: 20vw;
          height: 15vw;
          background: var(--color);
          z-index: 20;
          transform: rotate(0.2deg);
        }
      }
    }
    .gauge-center-health {
      position: absolute;
      z-index: 26;
      content: '';
      width: 6vw;
      height: 3vw;
      border-radius: 10vw 10vw 0 0;
      border: 1px solid #e1e1e1;
      border-bottom: none;
      bottom: 0.38vw;
      left: 50%;
      transform: translateX(-50%);
    }
    .icon-value-gauge-health {
      z-index: 27;
      position: absolute;
      left: 50%;
      bottom: -30%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-heart-health {
        position: relative;
        font-size: 5vw;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%);
        color: var(--color);
        filter: drop-shadow(0px 0px 4px var(--color));
      }
      .value-gauge-health {
        position: absolute;
        font-size: 1.8vw;
        font-weight: 700;
        z-index: 28;
        color: #ffffff;
        top: 44%;
        left: 48%;
        transform: translate(-50%, -50%);
      }
    }
  }
</style>
