<template>
  <div class="content-10num" :key="render">
    <div class="content-subject-matrix">
      <div>
        <div v-for="(item, index) in question.subjects" v-bind:key="index">
          <div class="subject-matrix">
            <div class="subject-label" v-if="!preview">
              <app-form-textarea-limited
                :inputMaxHeight="500"
                :inputMinHeight="20"
                :identify="index"
                v-model="item.label"
                class="description-area col-xl-12 col-lg-12 col-md-12 col-sm-12 col-lg-12 col-xs-12"
                :colorText="colors.splashColor"
                name="matrix-description"
                @updateSurveyTitle="updateQuestionDescription($event,index)"
              ></app-form-textarea-limited>
            </div>
            <div class="subject-label" v-else>
              <app-heading level="h6" :style="{'color': colors.splashColor }">{{ item.label }}</app-heading>
            </div>
            <div class="subject-rating">
              <app-form-rate-active
                v-model="inputValue"
                item-value="id"
                item-text="id"
                :items="items"
                :hoverColor="colors.buttonHover"
                :textColor="colors.actionText"
                :bgColor="colors.actionColor"
              />
              <div class="delete-subject" v-if="!preview">
                <button class="delete-enum-option" v-on:click="deleteItem(index)"><i class="material-icons" style="color:#b4b4b5;font-size:17px;margin-top:11px;">close</i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="add-item" v-if="!preview">
      <button @click="addItem()"><i class="material-icons add">add</i></button>
    </div>
    <theme-style>
      .add-item > button {
        background-color: {{user.account.theme.colors.accent  || '#ff607d'}} !important;
      }
      .add-item > button > .add {
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }};
      }
    </theme-style>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'matrix10num',
  props: ['colors', 'preview', 'question'],
  components: {
    'app-form-textarea-limited': () => import('@binds-tech/binds-design-system/src/components/Form/TextareaLimited'),
    'app-form-rate-active': () => import('@binds-tech/binds-design-system/src/components/Form/RateActive'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      inputValue: null,
      render: 0,
      items: [
        { id: 0, value: 0 },
        { id: 1, value: 1 },
        { id: 2, value: 2 },
        { id: 3, value: 3 },
        { id: 4, value: 4 },
        { id: 5, value: 5 },
        { id: 6, value: 6 },
        { id: 7, value: 7 },
        { id: 8, value: 8 },
        { id: 9, value: 9 },
        { id: 10, value: 10 }
      ]
    }
  },
  methods: {
    deleteItem (idx) {
      this.render += 1
      this.question.subjects.splice(idx, 1)
      setTimeout(function () { this.render = true }.bind(this), 200)
      this.$store.commit('survey/upsertQuestion', this.question)
    },
    updateQuestionDescription (e, idx) {
      this.question.subjects[idx].label = e
      this.$store.commit('survey/upsertQuestion', this.question)
    },
    addItem () {
      this.render += 1
      const count = this.question.subjects.length + 1
      this.question.subjects.push({ label: this.$i18n.t('survey.option_added') + count })
      this.$store.commit('survey/upsertQuestion', this.question)
      setTimeout(function () { this.render = true }.bind(this), 200)
    }
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    },
    ...mapGetters({
      user: 'account/getUser'
    })
  },
  created () {
    if (!this.question.subjects) {
      const subjects = [
        { label: this.$i18n.t('survey.option_1') },
        { label: this.$i18n.t('survey.option_2') },
        { label: this.$i18n.t('survey.option_3') }
      ]
      const options = [
        {
          label: '0',
          rating: 0
        },
        {
          label: '1',
          rating: 10
        },
        {
          label: '2',
          rating: 20
        },
        {
          label: '3',
          rating: 30
        },
        {
          label: '4',
          rating: 40
        },
        {
          label: '5',
          rating: 50
        },
        {
          label: '6',
          rating: 60
        },
        {
          label: '7',
          rating: 70
        },
        {
          label: '8',
          rating: 80
        },
        {
          label: '9',
          rating: 90
        },
        {
          label: '10',
          rating: 100
        }
      ]
      this.question.subjects = subjects
      this.question.options = options
      this.$store.commit('survey/upsertQuestion', this.question)
    }
  }
}
</script>
<style lang="scss">
.content-10num {
  padding-top: 40px;
  .content-subject-matrix {
    width: 555px;
    .subject-matrix {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 90%;
      margin: 0 auto;
      .subject-rating {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
        .delete-subject {
          padding-top: 12px;
        }
        .rating {
          padding: 0;
          margin: 10px;
          .rating__button{
            width: 34px !important;
            height: 34px !important;
            line-height: 34px !important;
            margin-right: 3px !important;
          }
        }
      }
      .subject-label {
        box-sizing: border-box;
        width: 85%;
        .textarea {
          width: 100% !important;
          .input {
            border: 1px dashed #cacaca !important;
            padding: 10px;
          }
        }
        .description-area {
          padding: 0;
          .input {
            min-height: 50px !important;
            border: 1px dashed rgb(179, 179, 179);
          }
        }
      }
    }
  }
}

</style>
