<template>
  <div>
    <app-card class="cardSendhub">
      <app-card-header class="row headerSendhub headerSms">
        <app-heading level="h4" light>
            <app-icon name="atm"></app-icon>
            {{ $t("sendhub.toten") }}
        </app-heading>
        <div v-title="'Link personalizado'" style="position: absolute;right: 10px !important;" v-if="user.type === 'superadmin'">
          <app-button
          @click="openModal = !openModal"
          style="padding: 0;height: 27px;width: 27px;"
          class="btn-circle-accent"
          variation="primary"
          ><i class="material-icons">add_link</i></app-button>
        </div>
      </app-card-header>
      <div class="row">
        <app-heading class="col-lg-12 col-md-12 col-sm-12 toten-text" level="h6">{{ $t("sendhub.toten_hub_text") }}</app-heading>
      </div>
      <div class="content-send-link">
        <div class="send-link">
          <app-heading level=h6>{{ $t("sendhub.link_survey") }}:</app-heading>
          <app-form-input :value="link" placeholder="link" border-bottom id="addToClipboard"/>
        </div>
        <app-button class="btn-circle-accent" v-on:click="copyToClipboard" variation="primary">{{ $t("sendhub.copy") }}</app-button>
      </div>
    </app-card>
    <CustomLink v-if="openModal" @close="openModal = !openModal"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomLink from './CustomLink'

export default {
  name: 'TotenHub',
  components: {
    'app-icon': () => import('@binds-tech/binds-design-system/src/components/Icon/Icon'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-card-header': () => import('@binds-tech/binds-design-system/src/components/Card/Header'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    CustomLink
  },
  data () {
    return {
      openModal: false
    }
  },
  computed: {
    link () {
      return this.mountLink()
    },
    ...mapGetters({
      user: 'account/getUser',
      totenSettings: 'sendhub/getTotenSettings'
    })
  },
  methods: {
    mountLink () {
      const surveyHashId = this.$store.getters['survey/getSurvey']
      let linkUrl = 'https://surveyv2.binds.co/#/link/' + surveyHashId.hashId
      if (this.totenSettings) {
        linkUrl = linkUrl + '/?'
        if (this.totenSettings.allowMoreThanOne) {
          linkUrl = linkUrl + 'loop=true&'
        }
        if (this.totenSettings.showEndSurveyButton) {
          linkUrl = linkUrl + 'close=true&'
        }
        if (this.totenSettings.endSurveyByInactivity) {
          linkUrl = linkUrl + 'timeout=' + this.totenSettings.seconds + '&'
        }
      }
      return linkUrl
    },
    copyToClipboard () {
      const testingCodeToCopy = document.querySelector('#addToClipboard')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        document.execCommand('copy')

        this.$store.commit('alerts/alert', {
          message: 'URL da Pesquisa copiado para a área de transferência.',
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      } catch (err) {
        alert('Oops, unable to copy')
      }
    }
  }
}
</script>

<style lang="scss">
.content-send-link{
  margin-top: 2rem;
  text-align: center;
}
.send-link{
  width: 100%;
  margin: 0 auto;
  margin-bottom: 3rem;
  text-align: left;
  & .text-field__input {
    & input {
      font-size: 16px !important;
      color: #1d1b22 !important;
    }
  }
}
.content-send-link button{
  width: 200px;
}
.toten-text {
  margin-top: 1rem !important;
  line-height: 20px !important;
}

</style>
