<template>
    <div>
      <header class="container-filter-activity">
        <div class="activity-datepicker content-select">
            <v-date-picker
          class="container-date-picker"
          v-model="date"
          color="gray"
          locale="pt-br"
          model="date"
          @input="changeDate"
          is-range
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div class="content-date-picker">
              <p class="placeholder-date">Data</p>
              <div class="section-date-picker" v-on="inputEvents.start">
                <div class="start-date">
                  <span>{{ inputValue.start }}</span>
                </div>
                <p>-</p>
                <div class="end-date">
                  <span>{{ inputValue.end }}</span>
                </div>
                <i  class="material-icons icon-calendar">calendar_month</i>
              </div>
            </div>
          </template>
          <template #footer>
            <div style="padding: 0.5em;display: flex;">
              <button @click="changeDate(mockDate)" v-for="(mockDate, index) in rangeOfDates" :key="index" style="height: 2.2em; padding: 0.3em; font-size: 1em;margin: 0.4em;">{{ $t(`date_filter.periods.${mockDate}`)}}</button>
            </div>
          </template>
        </v-date-picker>
        <app-select-binds
          :placeholder="$t('activity.interface.users')"
          :options="users"
          :titleItem="'email'"
          id="users"
          :showIndex="true"
          :value="currentUser"
          @model="currentUser = $event"
         @input="updateCurrentFilter('user', $event._id)"
          style="height: 3.2rem; gap: 6px;"
        />
        <app-select-binds
          placeholder="Origem"
          :options="entities"
          :titleItem="'label'"
          id="entity"
          :showIndex="true"
          :value="currentEntity"
          @model="currentEntity = $event"
          @input="updateCurrentFilter('entity', $event.name)"
          style="height: 3.2rem; gap: 6px;"
        />
        <app-select-binds
          :placeholder="$t('activity.interface.actions_label')"
          :options="actions"
          :titleItem="'label'"
          id="actions"
          :showIndex="true"
          :value="currentAction"
          @model="currentAction = $event"
          @input="updateCurrentFilter('action', $event.name)"
          style="height: 3.2rem; gap: 6px;"
        />
        </div>
      </header>
    </div>
  </template>

<script>
import { ticketsService } from '@/_services'
import { dateFormatter, activityHelper } from '@/_helpers'
export default {
  name: 'FilterActivity',
  props: ['getUser'],
  components: {
    'app-select-binds': () => import('@binds-tech/binds-design-system/src/components/Select/SelectBinds')
  },
  data () {
    return {
      users: [],
      rangeOfDates: ['day', 'week', 'month', 'semester', 'year'],
      currentFilter: {
        user: '',
        action: '',
        entity: '',
        startDate: '',
        endDate: ''
      },
      actions: activityHelper.actionsList,
      entities: activityHelper.entityList(this.getUser.type),
      currentUser: '',
      currentAction: '',
      currentEntity: '',
      date: {
        end: this.$moment().subtract(7, 'days').format(),
        start: this.$moment().format()
      }
    }
  },
  async mounted () {
    this.users = await this.getUsers()
  },
  methods: {
    changeDate (dates) {
      let startDate
      let endDate

      if (typeof dates === 'string') {
        startDate = dateFormatter.stringPeriodToPlainDate(dates).formatedStart
        endDate = dateFormatter.stringPeriodToPlainDate(dates).formatedEnd
      } else {
        startDate = this.$moment(dates.start, 'YYYY-MM-DD HH:mm ZZ').startOf('day').utc().format()
        endDate = this.$moment(dates.end, 'YYYY-MM-DD HH:mm ZZ').endOf('day').utc().format()
      }

      this.currentFilter.startDate = startDate
      this.currentFilter.endDate = endDate

      this.date = { start: startDate, end: endDate }

      this.emitFilter(this.currentFilter)
    },
    async getUsers () {
      const users = await ticketsService.getUsersWithSuperadminParam()
      users.unshift({ email: this.$t('activity.interface.all'), _id: '' })
      return users
    },
    updateCurrentFilter (filter, value) {
      this.currentFilter[filter] = value
      this.emitFilter(this.currentFilter)
    },
    stringfyCurrentFilter (filter) {
      return filter ? Object.keys(filter)
        .filter(key => filter[key] !== '')
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`)
        .join('&') : {}
    },
    emitFilter (filter) {
      const filterActivity = this.stringfyCurrentFilter(filter)
      this.$emit('filter-activity', filterActivity)
    }
  }
}
</script>

  <style lang="scss" scoped>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62.5%;
  }
  .search-activity-logs {
    > div {
      width: 100%;
    }
  }
  .container-filter-activity {
    background: #ffffff;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 0.1rem solid #cacaca;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content-select {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 1rem;
  .container-date-picker {
        width: 13rem;
        height: 3.2rem;
        .content-date-picker {
          width: 100%;
          border-radius: 0.3rem;
          border: 0.1rem solid #cacaca;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .placeholder-date {
            height: 30%;
            font-size: 1vh;
            padding-left: 0.3rem;
            font-weight: bold;
            text-transform: uppercase;
            color: #454548;
            font-family: "Open Sans", Helvetica, Arial, sans-serif;
            display: flex;
            align-items: flex-end;
          }
          .section-date-picker {
            width: 100%;
            height: 70%;
            display: flex;
            align-items: center;
            padding-left: 0.5rem;
            gap: 0.5rem;
            font-size: 0.9vw;
            color: #808080;
            .start-date, .end-date {
              font-size: 1.1vw;
              display: flex;
              align-items: center;
            }
            .icon-calendar {
              font-size: 1.1vw;
              color: var(--accent-color);
            }
          }
        }
        .vc-popover-content-wrapper {
          .vc-popover-content {
            .vc-container {
              .vc-pane-container {
                div:nth-child(3) {
                  height: 2rem;
                  border-radius: 0.3rem;
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  button {
                    font-size: 0.7rem !important;
                    border-radius: 0.3rem;
                    height: 1.6rem !important;
                  }
                  button:hover {
                    background: #EDF2F7;
                    color: #4C535D;
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 1300px) {
  .container-date-picker {
    width: 12rem !important;
  }
}
  </style>
