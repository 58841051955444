<template>
  <div class="container-input-text" :class="`container-input-id-${id}`" :style="{'background': disabled ? '#f1f1f1' : '#ffffff', 'border-color': requireValue && !text.length ? '#db6363b7' : '#cacaca' }">
    <p class="placeholder-search" v-if="placeholder">{{ placeholder }}</p>
    <div class="content-search-text">
      <input
        :class="id"
        type="text"
        :placeholder="placeholderInput"
        class="input-text"
        :disabled="disabled"
        v-model="text"
        @input="handleInputTyping"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: ['id', 'disabled', 'value', 'focus', 'placeholder', 'requireValue', 'placeholderInput'],
  components: {},
  data () {
    return {
      text: ''
    }
  },
  mounted () {},
  watch: {
    value: {
      immediate: true,
      handler (newVal) { if (newVal) { this.text = newVal } }
    },
    text: {
      immediate: true,
      handler (newVal) {
        if (this.requireValue) {
          const inputBorder = document.querySelector(`.container-input-id-${this.id}`)
          if (inputBorder) {
            if (newVal.length) {
              inputBorder.style.borderColor = '#cacaca'
            } else {
              inputBorder.style.borderColor = '#db6363b7'
            }
          }
        }
      }
    }
  },
  computed: {},
  methods: {
    handleInputTyping () {
      this.$emit('update-model', this.text.trim())
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
}
.container-input-text {
  height: 2rem;
  border-radius: 0.2rem;
  border: 1px solid #cacaca;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #808080;
  overflow: hidden;
  .placeholder-search {
    font-size: 0.9vh;
    padding-left: 0.3rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #454548;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }
  .content-search-text {
    display: flex;
    .input-text {
      background: transparent;
      padding-left: 0.3rem;
      width: 85%;
      height: 100%;
      outline: none;
      font-size: 0.8vw;
      color: #808080;
      border: none;
    }
    .input-text::placeholder {
      color: #808080;
      font-size: 0.8vw;
    }
    .input-text:disabled {
      background: transparent;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .container-search-text {
    width: 12rem;
  }
}
</style>
