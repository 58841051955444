<template>
  <div class="container-chart-ball">
    <div class="section-chart-ball">
      <div class="bar-chart-ball" v-for="(bar, idx) in data.options" :key="idx" :style="{'width': `${bar.percentage}%`, '--chart-color': cardData.colors[idx]}">
        <div class="content-chart-ball">
          <span class="icon-chart-ball">{{ returnIcon(bar.key) }}</span>
          <span class="label-chart-ball">{{ parseInt(bar.percentage) }}%</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BarWithPercentage',
  props: ['data', 'question', 'cardData'],
  data () {
    return {
      emotes: [
        {
          key: '',
          value: 0
          // 'triste',
        },
        {
          key: '',
          value: 25
          // 'chateado',
        },
        {
          key: '',
          value: 50
          // 'tanto faz',
        },
        {
          key: '',
          value: 75
          // 'sorrindo',
        },
        {
          key: '',
          value: 100
          // 'feliz',
        }
      ]
    }
  },
  methods: {
    returnIcon (findThisEmote) {
      return this.emotes.filter(emote => { return emote.value === findThisEmote })[0].key
    }
  }
}
</script>
<style lang="scss" scoped>
.container-chart-ball {
  width: 100%;
  height: 100%;
  padding: 0 0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .section-chart-ball {
    width: 100%;
    display: flex;
    .bar-chart-ball {
      position: relative;
      height: 1vw;
      background: var(--chart-color);
      box-shadow: 0 0 6px var(--chart-color);
      display: flex;
      align-items: center;
      justify-content: center;
      .content-chart-ball {
        position: absolute;
        top: -0.5vw;
        cursor: default;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5vw;
        .icon-chart-ball {
          font-family: 'Bicons';
          background: var(--chart-color);
          color: #ffffff;
          font-size: 1.6vw;
          border-radius: 50%;
          padding: 0.2vw;
          box-shadow: 0 0 6px var(--chart-color);
        }
        .label-chart-ball {
          color: var(--chart-color);
          font-weight: 700;
          font-size: 0.9vw;
        }
      }
    }
    .bar-chart-ball:last-child {
      border-radius: 0 3vw 3vw 0;
    }
    .bar-chart-ball:first-child {
      border-radius: 3vw 0 0 3vw;
    }
  }
}
</style>
