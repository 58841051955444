<template>
  <div class="container-gauge-sentimentals">
    <canvas id="sentimentals-gauge" class="myCanvas-sentimentals"></canvas>
    <div class="label-gauge-sentimentals">
      <span>{{ valueOfGraphic }}{{ $t('sentimentals.gauge_legend') }} </span>
    </div>
  </div>
</template>
<script>

export default {
  name: 'GaugeSentimentals',
  props: ['data'],
  data () {
    return {
      valueOfGraphic: 0,
      specialId: ''
    }
  },
  mounted () {
    const myCanvas = document.getElementById('sentimentals-gauge')
    myCanvas.width = 350
    myCanvas.height = 350

    // fatias a serem exibidas, sem  label pq serão setados depois
    const piecesOfPie = {
      0: 1,
      1: 2,
      2: 3,
      3: 4
    }

    /**
     * cria os pedaços de pizza do gráfico
     * @param {{ Object }} ctx recebe o contexto atual do que está sendo desenhado em getContext('2d')
     * @param {{ Number }} centerX posição horizontal de onde desejamos realizar o desenho
     * @param {{ Number }} centerY posição vertical de onde desejamos realizar o desenho
     * @param {{ Number }} radius define a proporção do gráfico
     * @param {{ Number }} startAngle define o ponto inicial da fatia num ângulo de 360º
     * @param {{ Number }} endAngle define o ponto final da fatia num ângulo de 360º
     * @param {{ String }} color define a cor da fatia
     */
    function drawPieSlice (ctx, centerX, centerY, radius, startAngle, endAngle, color) {
      ctx.fillStyle = color
      ctx.beginPath()
      ctx.moveTo(centerX, centerY)
      ctx.arc(centerX, centerY, radius, startAngle, endAngle)
      ctx.closePath()
      ctx.fill()
    }

    function drawArrow (fromX, fromy, toX, toY) {
      // variables to be used when creating the arrow
      const c = document.getElementById('sentimentals-gauge')
      const ctx = c.getContext('2d')
      const headlength = 5
      const angle = Math.atan2(toY - fromy, toX - fromX)
      // starting path of the arrow from the start square to the end square and drawing the stroke
      ctx.beginPath()
      ctx.moveTo(fromX, fromy)
      ctx.lineTo(toX, toY)
      ctx.strokeStyle = '#666666'
      ctx.lineWidth = 10
      ctx.stroke()
      // starting a new path from the head of the arrow to one of the sides of the point
      ctx.beginPath()
      ctx.moveTo(toX, toY)
      ctx.lineTo(toX - headlength * Math.cos(angle - Math.PI / 7), toY - headlength * Math.sin(angle - Math.PI / 7))
      // path from the side point of the arrow, to the other side point
      ctx.lineTo(toX - headlength * Math.cos(angle + Math.PI / 7), toY - headlength * Math.sin(angle + Math.PI / 7))
      // path from the side point back to the tip of the arrow, and then again to the opposite side point
      ctx.lineTo(toX, toY)
      ctx.lineTo(toX - headlength * Math.cos(angle - Math.PI / 7), toY - headlength * Math.sin(angle - Math.PI / 7))
      // draws the paths created above
      ctx.strokeStyle = '#666666'
      ctx.lineWidth = 12
      ctx.stroke()
      ctx.fillStyle = '#666666'
      ctx.fill()
    }

    function drawSpecialArrow (options, range) {
      if (options.valueOfGraphic < range[0]) {
        drawArrow(180, 185, 119, 155, options)
        return 0
      } else if (options.valueOfGraphic < range[1] && options.valueOfGraphic >= range[0]) {
        drawArrow(170, 160, 150, 120, options)
        return 1
      } else if (options.valueOfGraphic >= range[1] && options.valueOfGraphic < range[2]) {
        drawArrow(180, 180, 200, 120, options)
        return 2
      } else if (options.valueOfGraphic >= range[2]) {
        drawArrow(160, 180, 231, 155, options)
        return 3
      }
    }

    function drawRange (ctx, labelX, labelY, range, positionX, positionY) {
      ctx.fillText(range[0], positionX[0], positionY[0])
      ctx.fillText(range[1], positionX[1], positionY[1])
      ctx.fillText(range[2], positionX[2], positionY[2])
      ctx.fillText(range[3], positionX[3], positionY[3])
      ctx.fillText(range[4], positionX[4], positionY[4])
    }

    /**
     * responsável por desenhar o gráfico
     * @param {{Object}} options contém os parâmetros para rendereziar o gráfico
     * @param {{Object}} question contém a questão atual que o gráfico será renderizado
     */
    const Piechart = function (options) {
      this.options = options
      this.canvas = options.canvas
      this.ctx = this.canvas.getContext('2d')
      this.colors = options.colors
      this.draw = function () {
        let colorIndex = 0
        let startAngle = 3.140
        let sliceAngle = 0
        for (let i = 0; i < Object.keys(this.options.data).length; i++) {
          sliceAngle = 1 * Math.PI * 25 / 100
          drawPieSlice(
            this.ctx,
            this.canvas.width / 2,
            this.canvas.height / 2,
            Math.min(this.canvas.width / 2.5, this.canvas.height / 2),
            startAngle,
            startAngle + sliceAngle,
            this.colors[colorIndex % this.colors.length]
          )
          startAngle += sliceAngle
          colorIndex++
        }

        if (this.options.doughnutHoleSize) {
          let centerColor = 0
          // draw a small stroke betwen the center of the circle
          drawPieSlice(
            this.ctx,
            this.canvas.width / 2,
            this.canvas.height / 2,
            this.options.doughnutHoleSize * Math.min(this.canvas.width / 3.5, this.canvas.height / 2),
            0,
            2 * Math.PI,
            'white'
          )

          centerColor = drawSpecialArrow(this.options, [30, 50, 80])
          this.options.valueOfGraphic = this.options.valueOfGraphic + '%'

          // draw the main circle, colored based on the current target
          drawPieSlice(
            this.ctx,
            this.canvas.width / 2,
            this.canvas.height / 2,
            this.options.doughnutHoleSize * Math.min(this.canvas.width / 4, this.canvas.height / 2),
            0,
            2 * Math.PI,
            this.options.cardData.colors[centerColor]
          )

          // start the structure of the circle, inserting the value of then
          this.ctx.beginPath()
          this.ctx.fillStyle = this.options.cardData.textColor
          const lengthOfValue = this.options.valueOfGraphic.toString().length
          let xPosition = (this.canvas.width / 2.17)
          if (lengthOfValue <= 1) {
            xPosition = (this.canvas.width / 2.10)
          }
          if (lengthOfValue >= 3) {
            xPosition = lengthOfValue === 3 ? (this.canvas.width / 2.30) : lengthOfValue >= 5 ? (this.canvas.width / 2.60) : (this.canvas.width / 2.45)
          }
          this.ctx.font = 'bold 24px Arial'
          this.ctx.fillText(this.options.valueOfGraphic, xPosition + 8, this.canvas.height / 1.90)
          this.ctx.closePath()
          this.ctx.fill()
        }

        // draw an arc with the ranged values of the gauge (-100 to 100)
        startAngle = 2.75
        sliceAngle = 1 * Math.PI * 25 / 100
        const pieRadius = Math.min(this.canvas.width / 3, this.canvas.height / 2)
        const offset = sliceAngle + 90
        const labelX = this.canvas.width / 2.3 + (offset + pieRadius / 2) * Math.cos(startAngle + sliceAngle / 2)
        const labelY = this.canvas.height / 2 + (offset + pieRadius / 2) * Math.sin(startAngle + sliceAngle / 2)
        this.ctx.fillStyle = '#838383'
        this.ctx.font = 'bold 13px Arial'
        const positionsX = [labelX + 2, labelX + 53, labelX + 168, labelX + 272, labelX + 315]
        const positionsY = [labelY, labelY - 100, labelY - 145, labelY - 100, labelY]
        const range = [0, 30, 50, 80, 100]
        drawRange(this.ctx, labelX, labelY, range, positionsX, positionsY)
      }
    }

    // get the current value and clean then to show at the view
    if (this.data.avg) {
      // validando se o avarage eh um numero inteiro
      if (Number.isInteger(this.data.avg)) {
        this.valueOfGraphic = this.data.avg.toString()
      } else {
        const valueParsed = this.data.avg.toString()
        this.valueOfGraphic = valueParsed.slice(0, (valueParsed.indexOf('.')) + 2)
      }
    }

    // monta o objeto que a função espera para desenhar o gráfico
    const objectToMount = {
      canvas: myCanvas,
      data: piecesOfPie,
      colors: ['#EA484D', '#EF874C', '#F8C43D', '#9FCD35'],
      doughnutHoleSize: 0.5,
      valueOfGraphic: this.valueOfGraphic,
      cardData: {
        colors: ['#EA484D', '#EF874C', '#F8C43D', '#9FCD35'],
        textColor: '#ffffff'
      }
    }
    // instancia o objeto que desenha o gráfico em si
    const myDougnutChart = new Piechart(objectToMount)
    myDougnutChart.draw()
  }
}
</script>
<style lang="scss" scoped>
.container-gauge-sentimentals {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .label-gauge-sentimentals {
    position: absolute;
    bottom: 25%;
    color: #808080;
    font-size: 18px;
  }
}
</style>
