<template>
  <div class="container-tab">
    <button class="btn-tab" :class="tab.value" v-for="tab in tabs" :key="tab.value" @click="selectTab(tab)">
      <i v-if="tab.icon" class="material-icons icon-tab">{{ tab.icon }}</i>
      <p class="title-tab">{{ tab.title }}</p>
    </button>
  </div>
</template>

<script>
export default {
  name: 'TabChange',
  props: ['tabs'],
  components: {},
  data () {
    return {
      tabSelected: ''
    }
  },
  mounted () {
    this.selectTab(this.tabs[0])
  },
  methods: {
    selectTab (e) {
      const tabs = document.querySelectorAll('.btn-tab')
      tabs.forEach(e => { e.classList.remove('selected') })
      const btn = document.querySelector(`.${e.value}`)
      btn.classList.add('selected')
      this.$root.$emit('tab-changed', e)
    }
  }

}
</script>

<style lang="scss" scoped>
.container-tab {
  min-width: 14rem;
  height: 3.2rem;
  border-radius: 0.3rem;
  border: 0.1rem solid #cacaca;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  .btn-tab {
    min-width: 9.5rem;
    height: 100%;
    background: #ffffff;
    color: #808080;
    font-size: 0.8vw;
    padding: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    outline: none;
    .icon-tab {
      font-size: 1.1vw;
    }
  }
  .btn-tab:nth-child(even) {
    border-right: 0.1rem solid #cacaca;
    border-left: 0.1rem solid #cacaca;
  }
  .selected {
    background: var(--accent-color);
    color: var(--default-text-color);
    background-image: linear-gradient(
      -60deg,
      transparent, transparent 40%,
      #ffffff44 40%, #ffffff44 60%,
      transparent 60%, transparent 100%
    );
    background-size: 200% 100%;
    background-repeat: no-repeat;
    background-position-x: 150%;
  }
  .selected:hover {
    background-position-x: -150%;
    transition: background-position-x 1s;
  }
}

@media only screen and (max-width: 1300px) {
  .container-tab {
    .btn-tab {
      min-width: 5rem;
      flex-direction: column;
      gap: 0;
      .icon-tab {
        font-size: 1.1rem;
      }
      .title-tab {
        font-size: 0.8rem;
      }
    }
  }
}
</style>
