<template>
  <div class="container-detail-response">
    <div class=content-dynamic-detail-response v-html="formatDetailResponse()"></div>
  </div>
</template>

<script>
export default {
  name: 'DetailResponse',
  props: ['dataRes'],
  components: {},
  data () {
    return {
    }
  },
  methods: {
    formatDetailResponse () {
      if (this.dataRes.type === 'kpi' && this.dataRes.ui === '5emo') {
        return this.formatKpi5emo(this.dataRes.rating)
      }
      if (this.dataRes.type === 'csat' && (this.dataRes.ui === '10num' || this.dataRes.ui === '5num')) {
        return this.formatCsatNum(this.dataRes.rating)
      }
      if (this.dataRes.type === 'csat' && this.dataRes.ui === '5emo') {
        return this.formatCsat5emo(this.dataRes.rating)
      }
      if (this.dataRes.type === 'enum') {
        return this.formatEnum(this.dataRes)
      }
      if (this.dataRes.type === 'text') {
        return this.formatText(this.dataRes.text)
      }
      if (this.dataRes.type === 'referral') {
        return this.formatReferral(this.dataRes)
      }
      if (this.dataRes.type === 'matrix') {
        return this.formatMatrix(this.dataRes)
      }
      if (this.dataRes.type === 'nvs') {
        return this.formatNvs(this.dataRes.rating)
      }
      if (this.dataRes.type === 'ces') {
        return this.formatCes(this.dataRes)
      }
      if (this.dataRes.type === 'nes') {
        return this.formatNes(this.dataRes.rating)
      }
      if (this.dataRes.type === 'nps' && this.dataRes.ui === '10num') {
        return this.formatNps10Num(this.dataRes.rating)
      }
      if (this.dataRes.type === 'nps' && this.dataRes.ui === 'ynm') {
        return this.formatNpsYnm(this.dataRes.rating)
      }

      return this.formatYnOrYnd(this.dataRes.rating)
    },
    formatYnOrYnd (rating) {
      const options = [
        { rating: 100, label: this.$i18n.t('dashboard.graphics.op1') },
        { rating: 0, label: this.$i18n.t('dashboard.graphics.op2') },
        { rating: 50, label: this.$i18n.t('dashboard.graphics.op3') }
      ]

      const res = options.find(e => e.rating === rating)

      return `<p class="label-detail-response ces-detail">${res.label}</p>`
    },
    formatNpsYnm (rating) {
      const options = [
        { rating: 95, label: this.$i18n.t('dashboard.graphics.op1') },
        { rating: 5, label: this.$i18n.t('dashboard.graphics.op2') },
        { rating: 75, label: this.$i18n.t('dashboard.graphics.op4') }
      ]

      const res = options.find(e => e.rating === rating)

      return `<p class="label-detail-response ces-detail">${res.label}</p>`
    },
    formatNps10Num (rating) {
      const options = [
        { rating: rating / 10, label: this.$i18n.t('dashboard.graphics.neutralString') },
        { rating: rating / 10, label: this.$i18n.t('dashboard.graphics.promoter') },
        { rating: rating / 10, label: this.$i18n.t('dashboard.graphics.detractor') }
      ]

      let res

      if (rating === 70) {
        res = options[0]
      } else if (rating === 80) {
        res = options[0]
      } else if (rating >= 70 && rating < 90) {
        res = options[0]
      } else if (rating >= 90) {
        res = options[1]
      } else if (rating < 70) {
        res = options[2]
      }

      return `<p class="label-detail-response ball-detail">${res.rating}</p>
        <p class="label-detail-response">${res.label}</p>`
    },
    formatNes (rating) {
      let label = this.$i18n.t('dashboard.graphics.le')

      if (rating === 50) {
        label = this.$i18n.t('dashboard.graphics.ee')
      } else if (rating > 50) {
        label = this.$i18n.t('dashboard.graphics.me')
      }

      return `<p class="label-detail-response">${label}</p>`
    },
    formatCes (payload) {
      const options1to7 = [
        { rating: 0, label: '1' },
        { rating: 15, label: '2' },
        { rating: 30, label: '3' },
        { rating: 50, label: '4' },
        { rating: 65, label: '5' },
        { rating: 80, label: '6' },
        { rating: 100, label: '7' }
      ]

      const options1to7button = [
        { rating: 0, label: this.$i18n.t('components.ces.totally_disagree') },
        { rating: 15, label: this.$i18n.t('components.ces.disagree') },
        { rating: 30, label: this.$i18n.t('components.ces.partially_disagree') },
        { rating: 50, label: this.$i18n.t('components.ces.neutral') },
        { rating: 65, label: this.$i18n.t('components.ces.partially_agree') },
        { rating: 80, label: this.$i18n.t('components.ces.agree') },
        { rating: 100, label: this.$i18n.t('components.ces.totally_agree') }
      ]

      const findOne = payload.ui === '1to7' ? options1to7 : options1to7button

      const res = findOne.find(e => e.rating === payload.rating)

      return `<p class="label-detail-response ${payload.ui === '1to7' ? 'ball-detail' : 'ces-detail'}">${res.label}</p>`
    },
    formatNvs (rating) {
      const options = [
        { rating: 100, label: this.$i18n.t('dashboard.graphics.nvs_op1') },
        { rating: 75, label: this.$i18n.t('dashboard.graphics.nvs_op2') },
        { rating: 50, label: this.$i18n.t('dashboard.graphics.nvs_op3') },
        { rating: 25, label: this.$i18n.t('dashboard.graphics.nvs_op4') },
        { rating: 0, label: this.$i18n.t('dashboard.graphics.nvs_op5') }
      ]

      const res = options.find(e => e.rating === rating)

      return `<p class="label-detail-response">${res.label}</p>`
    },
    formatMatrix (payload) {
      let matrixContent = '<div class="content-to-response-detail-item">'
      payload.value.forEach((option, index) => {
        matrixContent += '<div class="content-matrix-detail-item">'
        if (payload.ui === 'matrix1to5' && option.rating !== null) {
          matrixContent += `<span class="opt-matrix-detail-item">${option.rating / 25 + 1}</span>`
          if (payload.question.subjects[index] && payload.question.subjects[index]._id === option._id) {
            matrixContent += `<p class="label-detail-response">${payload.question.subjects[index].label}</p>`
          }
        } else if (payload.ui === '10num' && option.rating !== null) {
          matrixContent += `<span class="opt-matrix-detail-item">${option.rating / 10}</span>`
          if (payload.question.subjects[index] && payload.question.subjects[index]._id === option._id) {
            matrixContent += `<p class="label-detail-response">${payload.question.subjects[index].label}</p>`
          }
        } else if (payload.ui === 'like' && option.rating !== null) {
          matrixContent += '<div class="content-matrix-detail-item">'
          if (option.rating === 0) {
            matrixContent += '<i class="material-icons icon-detail-matrix-like thumb-down">thumb_down</i>'
          } else {
            matrixContent += '<i class="material-icons icon-detail-matrix-like thumb-up">thumb_up</i>'
          }
          matrixContent += `<p class="label-detail-response">${this.extractResponseForUiLike(payload.question.subjects, option._id)}</p>`
          matrixContent += '</div>'
        }
        matrixContent += '</div>'
      })
      matrixContent += '</div>'
      return matrixContent
    },
    formatReferral (payload) {
      let enumContent = '<div class="content-to-response-detail-item">'
      payload.referralEntries.forEach((k, index) => {
        enumContent += '<div class="content-referral-detail" v-for="(o, key, index) in k" :key="index">'
        Object.entries(k).forEach(([key, value]) => {
          if (key === 'name') {
            enumContent += `<p class="label-detail-response"><i class="material-icons-outlined icon-content-response-detail">person</i> ${value}</p>`
          } else if (key === 'email') {
            enumContent += `<p class="label-detail-response"><i class="material-icons-outlined icon-content-response-detail">contact_mail</i> ${value}</p>`
          } else if (key === 'phone') {
            enumContent += `<p class="label-detail-response"><i class="material-icons-outlined icon-content-response-detail">call</i> ${value}</p>`
          } else {
            enumContent += `<p class="label-detail-response"><i class="material-icons-outlined icon-content-response-detail">navigate_next</i> ${value}</p>`
          }
        })
        enumContent += '</div>'
      })
      enumContent += '</div>'
      return enumContent
    },
    formatText (text) {
      return `<p class="label-detail-response">"${text}"</p>`
    },
    formatEnum (payload) {
      let enumContent = '<div class="content-to-response-detail-item">'

      if (payload.ui === 'multipleOther' || payload.ui === 'multiple') {
        payload.value.forEach(item => {
          enumContent += `<p class="label-detail-response">${item}</p>`
        })
      }

      if (payload.ui === 'single' || payload.ui === 'singleOther') {
        enumContent += `<p class="label-detail-response">${payload.value}</p>`
      }

      if (payload.ui === '10num') {
        enumContent += `<p class="label-detail-response ball-detail">${payload.value}</p>`
      }

      if (payload.text) {
        enumContent += `<p class="label-detail-response">"${payload.text}"</p>`
      }

      enumContent += '</div>'

      return enumContent
    },
    formatKpi5emo (rating) {
      const options = [
        { rating: 100, label: this.$i18n.t('dashboard.graphics.ex'), color: '#49BB59', icon: 'sentiment_very_satisfied' },
        { rating: 75, label: this.$i18n.t('dashboard.graphics.vg'), color: '#37B0E4', icon: 'sentiment_satisfied' },
        { rating: 50, label: this.$i18n.t('dashboard.graphics.satisf'), color: '#9E9E9E', icon: 'sentiment_neutral' },
        { rating: 25, label: this.$i18n.t('dashboard.graphics.bd'), color: '#F26C3C', icon: 'sentiment_dissatisfied' },
        { rating: 0, label: this.$i18n.t('dashboard.graphics.vb'), color: '#EE2E35', icon: 'sentiment_very_dissatisfied' }
      ]

      const res = options.find(e => e.rating === rating)

      const html = `
        <div class="content-to-response-detail">
          <i class="material-symbols-outlined icon-content-response-detail" style="color: ${res.color};">${res.icon}</i>
          <p class="label-detail-response">${res.label}</p>
        </div>`

      return html
    },
    formatCsatNum (rating) {
      let label = rating

      if (typeof label !== 'string') {
        label = this.$i18n.t('dashboard.graphics.un')

        if (rating >= 70) { label = this.$i18n.t('dashboard.graphics.sat') }
      }

      return `<p class="label-detail-response">${label}</p>`
    },
    formatCsat5emo (rating) {
      const options = [
        { rating: 100, label: this.$i18n.t('dashboard.graphics.vs'), color: '#49BB59', icon: 'sentiment_very_satisfied' },
        { rating: 75, label: this.$i18n.t('dashboard.graphics.sat'), color: '#37B0E4', icon: 'sentiment_satisfied' },
        { rating: 50, label: this.$i18n.t('dashboard.graphics.ind'), color: '#9E9E9E', icon: 'sentiment_neutral' },
        { rating: 25, label: this.$i18n.t('dashboard.graphics.un'), color: '#F26C3C', icon: 'sentiment_dissatisfied' },
        { rating: 0, label: this.$i18n.t('dashboard.graphics.vu'), color: '#EE2E35', icon: 'sentiment_very_dissatisfied' }
      ]

      const res = options.find(e => e.rating === rating)

      const html = `
        <div class="content-to-response-detail">
          <i class="material-symbols-outlined icon-content-response-detail" style="color: ${res.color};">${res.icon}</i>
          <p class="label-detail-response">${res.label}</p>
        </div>`

      return html
    },
    extractResponseForUiLike (subjects, optionId) {
      return subjects.find(res => res._id === optionId).label
    }
  }
}
</script>

<style lang="scss">
.container-detail-response {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .content-dynamic-detail-response {
    width: 100%;
    height: 100%;
    padding: 0.2vw 0.5vw;
    display: flex;
    align-items: center;
    gap: 0.3vw;
    .label-detail-response {
      color: #454548;
      font-size: 0.9vw;
      display: flex;
      align-items: center;
      gap: 0.3vw;
    }
    .ball-detail, .ces-detail {
      width: 1.3vw;
      height: 1.3vw;
      background: #e5e5e5;
      border-radius: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border: 1px solid #d8d8d8;
      color: #424242;
    }
    .ces-detail {
      width: fit-content;
      padding: 0.7vw 0.8vw;
    }
    .icon-content-response-detail, .icon-detail-matrix-like {
      font-size: 1.2vw;
    }
    .content-to-response-detail {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 0.3vw;
    }
    .content-to-response-detail-item {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      gap: 0.3vw;
      .content-matrix-detail-item {
        display: flex;
        align-items: center;
        gap: 0.3vw;
        .thumb-down {
          font-size: 1vw;
          color: #ea4922;
        }
        .thumb-up {
          font-size: 1vw;
          color: #51ad59;
        }
        .opt-matrix-detail-item {
          width: 1.3vw;
          height: 1.3vw;
          background: #e5e5e5;
          border-radius: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border: 1px solid #d8d8d8;
          color: #424242;
        }
      }
      .content-referral-detail {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 0.3vw;
        .icon-content-response-detail {
          font-size: 0.9vw;
        }
      }
    }
  }
}
</style>
