import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

export const panelService = {
  getPanelStats,
  getQuestionStats
}

async function getPanelStats () {
  return callApiService.callApi(API_URL + 'panel-stats?q=%7B"$and":%5B%7B"archivedAt":%7B"$exists":false%7D%7D%5D%7D')
}

async function getQuestionStats () {
  return callApiService.callApi(API_URL + 'panel-survey-stats?q=%7B"$and":%5B%7B"archivedAt":%7B"$exists":false%7D%7D%5D%7D')
}
