
<template>
  <div id="anime-stage">
    <LetterCube></LetterCube>
  </div>
</template>

<script>
import { accountService, surveyService } from '@/_services'

export default {
  components: {
    LetterCube: () =>
      import('../components/customLoading/LetterCube.vue')
  },
  async mounted () {
    window.localStorage.setItem('bindsUser', JSON.stringify(this.$route.params.token))
    const response = await accountService.loginFromV1(this.$route.params)
    if (response.accountUpdated && response.user) {
      response.user.account = response.accountUpdated
      this.$i18n.locale = response.user.language
      this.$store.commit('account/setUser', response.user)
      this.$store.commit('account/language', response.user.language)
      if (response.user && response.user.account && !response.user.account.theme) {
        this.$store.commit('account/setDefaultTheme')
      }
      const surveys = await surveyService.getAllSurveys()
      if (surveys.length <= 0) {
        this.$router.push({ name: 'Start' })
        return response
      }
      if (response.user &&
        response.user.account &&
        !response.user.account.billing.hasInvoice
      ) {
        this.$router.push({ name: 'SurveysList' })
        return response
      }
      this.$router.push({
        name: 'RealTime',
        query: { q: JSON.stringify({ $and: [] }) }
      })
      return false
    }
    this.$router.push({ name: 'Login' }).catch(err => { if (err.name !== 'NavigationDuplicated')console.log('error when updateing url with filters', err) })
  }
}
</script>

<style>
#anime-stage > .spinner {
  width: 100% !important;
  height: 550px !important;
}
</style>
