<template>
  <div>
    <div class="loading-list" v-if="loading">
      <DoubleBounce />
    </div>
    <section class="section-analytics-list" v-if="renderList">
      <div :class="list.length === 0 ? 'empty-words': ''">
        <section class="content-list-words">
          <app-card v-for="(item, index) in list" v-bind:key="index" style="position:relative">
            <div v-if="item.openTooltip">
              <WordCard :word="item" :tooltipTag="item.openTooltip" @closeTooltipTag="clearDisplayTag()"/>
            </div>
            <div class="content-items-word" v-on:click="handle(index)">
              <div class="word-related"><app-heading level="h4">{{item.word}}</app-heading></div>
              <div class="word-related-qty">
                <span>{{item.qty}}</span>
                <span>Volume</span>
              </div>
              <div class="list-options-word" v-for="(o, index) in item.options" v-bind:key="index">
                <div class="item-list-word" v-if="o.key === 'pr'" style="color:#00aaa7">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.promoter") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'nt'" style="color:#ffb500">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.neutralString") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'dt'" style="color:#ff607d">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.detractor") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'vu'" style="color:#ff607d">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.vu") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'un'" style="color:#ff607d">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.un") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'ind'" style="color:#ffb500">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.ind") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'sat'" style="color:#00aaa7">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.sat") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'vs'" style="color:#00aaa7">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.vs") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'unsatisfied'" style="color:#ff607d">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.un") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'satisfied'" style="color:#00aaa7">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.sat") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'ex'" style="color:#00aaa7">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.ex") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'vg'" style="color:#00aaa7">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.vg") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'st'" style="color:#ffb500">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.st") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'bd'" style="color:#ff607d">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.bd") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'vb'" style="color:#ff607d">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.vb") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'y'" style="color:#00aaa7">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.op1") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'n'" style="color:#ff607d">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.op2") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'mb'" style="color:#ffb500">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.op3") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'le'" style="color:#00aaa7">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.le") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'ee'" style="color:#ffb500">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.ee") }}</span>
                </div>
                <div class="item-list-word" v-if="o.key === 'me'" style="color:#ff607d">
                  <span>{{o.value}}</span>
                  <span>{{ $t("dashboard.graphics.me") }}</span>
                </div>
              </div>
            </div>
          </app-card>
        </section>
      </div>
    </section>
        <div v-else style="text-align:center; margin-top:30px;">
            <app-heading level="h3">{{ $t("dashboard.not_found") }}</app-heading>
        </div>
  </div>
</template>

<script>
import { formatters } from '@/_helpers'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import WordCard from '@/components/analytics/wordCard.vue'
export default {
  name: 'Analytics',
  components: {
    WordCard,
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  props: ['list', 'origin', 'exporting', 'renderList'],
  data () {
    return {
      loading: false
    }
  },
  methods: {
    handle (idx) {
      this.clearDisplayTag()
      this.list[idx].openTooltip = true
    },
    clearDisplayTag () {
      this.list.forEach(item => {
        item.openTooltip = false
      })
    },
    generatePDFlist () {
      // GENERATE TABLE TO PDF
      if (this.list.length > 0) {
        const columns = this.list[0].options.map(item => {
          return formatters.analyticsHeadersKey(item.key)
        })
        columns.unshift('termo', 'volume')
        const rows = []
        this.list.forEach((item, index) => {
          let unit = []
          item.options.forEach(o => {
            unit.push(o.value)
          })
          rows.push(unit)
          rows[index].unshift(item.word, item.qty)
          unit = []
        })

        /* eslint-disable */
        const doc = jsPDF('p', 'pt')
        doc.autoTable(columns, rows)
        doc.save('list.pdf')
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('export-list')
    this.$root.$off('export-list-word')
  },
  mounted () {
    // ordena lista do maior volume para o menor
    this.list.sort((a, b) => {
      return b.qty - a.qty
    })

    this.$root.$on('export-list-word', () => {
      this.generatePDFlist()
    })
  }
}
</script>

<style lang="scss">
.section-analytics-list {
  margin-top: 32px;
}
.content-list-words {
  max-width: 1290px;
  margin: 0 auto;
  margin-bottom: 100px;
  height: 780px;
  overflow-y: auto;
  position: relative;
  padding-right: 30px;
  overflow-x: hidden;
  @extend %animated-faster;
  @extend %fadeInLeft;
  .card {
    width: 100% !important;
    display: block !important;
    margin-bottom: 20px;
    overflow: unset !important;
    .tag-cloud-display {
      top: 0;
      right: 0;
    }
  }
  .content-items-word {
    cursor: pointer;
    display: flex;
    justify-content: space-around;
  }
  .word-related-qty,
  .item-list-word{
    span{
      display: block;
      &:first-child{
        font-size: 30px;
        margin-bottom: 5px;
      }
      &:last-child{
        font-size: 14px;
      }
    }
  }
}
.word-related{
  width: 20%;
  padding-top:10px;
}
  .loading-list {
    width: 69%;
    position: absolute;
    text-align: center;
    z-index: 99;
    height: 100%;
  }
@media screen and (max-width: 800px) {
  .content-list-words {
    margin-top: 80px;
    .content-items-word {
      display: block;
      .word-related{
        display: block;
        margin-bottom: 20px;
        width: 100%;
      }
      .word-related-qty, .list-options-word{
        display: inline-block;
        width: 100px;
        margin-bottom: 30px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .show-in-responses {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>
