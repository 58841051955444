<template>
  <div style="overflow: hidden !important;">
    <div class="loading-survey-list" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <div class="row messages-height" ref="messages">
      <div class="row" v-for="(chat, index) in chatList" :key="index">
        <div class="row center-lg center-md center-sm chat-events" v-if="chat.type === 'TicketCreationEvent'">
          <app-heading level="h6">{{ moment(chat.createdAt) }} ─ {{ $t('tickets.events.created') }}</app-heading>
        </div>
        <div class="row center-lg center-md center-sm chat-events" v-if="chat.type === 'TicketAttendantChangeEvent'">
          <app-heading level="h6" v-if="chat.attendant">
            {{ moment(chat.createdAt) }} ─ {{ chat.author?.name || $t('tickets.no_attendant') }} {{ $t('tickets.events.transfered') }}: {{ chat.attendant.name || $t('tickets.no_attendant') }}
          </app-heading>
          <app-heading level="h6" v-else>
            {{ moment(chat.createdAt) }} ─ {{ chat.author?.name || $t('tickets.no_attendant') }} {{ $t('tickets.events.transfered') }}: {{ $t('tickets.no_attendant') }}
          </app-heading>
        </div>
        <div class="row center-lg center-md center-sm chat-events" v-if="chat.type === 'TicketStatusChangeEvent'">
          <app-heading level="h6">
            {{ moment(chat.createdAt) }} ─ {{ chat.author?.name || $t('tickets.no_attendant') }}
            <span v-if="chat.status === 'closed'">{{ $t('tickets.events.closed') }}.</span>
            <span v-else>{{ $t('tickets.events.opened') }}.</span>
          </app-heading>
        </div>
        <div class="row center-lg center-md center-sm chat-events" v-if="chat.type === 'TicketCategoryChangeEvent'">
          <app-heading v-if="chat.category" level="h6">
            {{ moment(chat.createdAt) }} ─ {{ chat.author?.name || $t('tickets.no_attendant') }} {{ $t('tickets.events.categorized') }}: {{ chat.category.title }}
          </app-heading>
          <app-heading v-else level="h6">
            {{ moment(chat.createdAt) }} ─ {{ chat.author?.name || $t('tickets.no_attendant') }} {{ $t('tickets.events.no_category') }}.
          </app-heading>
        </div>
        <div class="row end-lg end-md end-sm" v-if="chat.type === 'TicketMessageSentEvent'">
          <div class="sent-message">
            <app-heading level="h7">{{ chat.author?.name || $t('tickets.no_attendant') }}</app-heading>
            <app-heading level="h6" class="break-line-sent mt10" v-html="chat.text"></app-heading>
            <app-heading level="h7" class="message-date" style="font-weight: lighter !important;">{{ moment(chat.createdAt) }} </app-heading>
          </div>
        </div>
        <div class="row start-lg start-md start-sm" v-if="chat.type === 'TicketMessageReceivedEvent'">
          <div class="received-message">
            <app-heading level="h7">{{ chat.contact.name }}</app-heading>
            <app-heading level="h6" class="mt10" v-html="chat.text"></app-heading>
            <app-heading level="h7" class="message-date" style="font-weight: lighter !important;">{{ moment(chat.createdAt) }} </app-heading>
          </div>
        </div>
      </div>
    </div>
    <div class="row send-spot" v-if="ticketStatus">
      <div class="col-lg-11 col-md-11 col-sm-11 text">
        <app-form-textarea-limited
          :inputMaxHeight="150"
          :inputMinHeight="41"
          :maxlength="'auto'"
          :identify="'ticket-chat'"
          class="message-area"
          colorText="#fff"
          ref="sendArea"
          v-model="message"
          name="send-message"
          :disabled="ticketStatus.label === 'closed'"
          :class="{ 'disabled-chat': ticketStatus.label === 'closed' }"
        ></app-form-textarea-limited>
      </div>
      <div class="col-lg-1 col-md-1 col-sm-1 send" style="position: relative;">
        <i class="material-icons send-btn" v-if="!sending && ticketStatus.label !== 'closed'" @click="sendMessage()">send</i>
        <div class="loading-circle-msg" v-if="sending">
          <Circle2 class="circle-msg"></Circle2>
        </div>
        <div class="ticket-block-message" v-if="ticketStatus.label === 'closed'">
          <i class="material-icons" title-placement="left" v-title="$t('tickets.closed')">block</i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ticketsService } from '@/_services'
export default {
  name: 'Chat',
  props: ['id', 'ticketStatus'],
  components: {
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-textarea-limited': () => import('@binds-tech/binds-design-system/src/components/Form/TextareaLimited')
  },
  data () {
    return {
      message: '',
      chatList: [],
      loading: false,
      sending: false
    }
  },
  beforeDestroy () {
    this.$root.$off('chat-changed')
  },
  async mounted () {
    this.getChat()
    this.$root.$on('chat-changed', async () => {
      this.loading = true
      await new Promise(resolve => setTimeout(resolve, 650))
      this.chatList = []
      this.getChat()
    })
  },
  computed: {
    lang () {
      return this.$store.getters['account/getLang']
    }
  },
  methods: {
    async getChat () {
      const event = await ticketsService.getEvents(this.id)
      if (event) {
        this.chatList = event.reverse()
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
      this.$root.$emit('chat-ready')
      this.loading = false
    },
    async sendMessage () {
      this.sending = true
      const send = await ticketsService.setMessage(this.id, { text: this.message })
      this.chatList.push(send)
      this.message = ''
      // forçando a altura do componente de textarea para 'auto' apos o envio porque o componente nao volta a sua altura normal caso o texto digitando for > 2 linhas
      // o elemento de textarea esta com indicação $ref porem o elemento que precisa de altura esta no children
      this.$refs.sendArea.$el.children[0].style = 'auto'
      this.$nextTick(() => {
        this.scrollToBottom()
        this.sending = false
      })
    },
    moment (eventDate) {
      this.$moment.locale(this.lang)
      return this.$moment(eventDate).format('LLL')
    },
    scrollToBottom () {
      const message = this.$refs.messages
      if (message) {
        message.scrollTop = message.scrollHeight
      }
    }
  }
}
</script>
<style lang="scss">
.loading-survey-list {
  background: #EFF3F840;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner-loading-survey-list {
    width: 2.1vw !important;
    height: 2.1vw !important;
    border-width: 3px !important;
    border-radius: 35px;
    border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
  }
}
.disabled-chat {
  & textarea {
    cursor: not-allowed !important;
    background-color: rgba(221, 221, 221, 0.473) !important;
  }
}
.input-send {
  .text-field__input {
    border-radius: 25px !important;
    border: 2px solid #666262 !important;
    width: 103% !important;
    background-color: #ddd !important;
    input {
      color: #080808 !important;
      padding: 10px !important;
      font-size: 14px !important;
    }
  }
}
.textarea-send {
  width: 103% !important;
  height: 43px !important;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-box-direction: normal;
  padding-top: 10px;
  padding-left: 10px;
  color: #080808 !important;
  font-size: 14px !important;
  border-radius: 25px !important;
  border: 2px solid #666262 !important;
  background-color: #ddd !important;
  position: relative;
  box-sizing: border-box;
  resize: none !important;
  &:focus {
    outline: none !important;
  }
}
.chat-events {
  overflow-x: hidden;
  margin: 10px 0px 10px 0px !important;
  width: 100%;
  & h6 {
    font-size: 13px !important;
    color: #3e3e3e !important;
  }
}
.sent-message {
  margin: 10px 0px 10px 0px;
  margin-top: 40px;
  max-width: 650px;
  height: fit-content;
  text-align: left;
  border: 1px solid #303030;
  background-color: #3f3f3f;
  border-radius: 6px;
  padding: 10px 10px 5px 10px;
  & h6 {
    color: #fff !important;
    line-height: 18px !important;
    font-size: 14px !important;
  }
  & .h7 {
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }
}
.received-message {
  margin: 10px 0px 10px 0px;
  margin-left: 15px;
  max-width: 650px;
  height: fit-content;
  text-align: left;
  border: 1px solid #532c9b;
  background-color: #532c9b;
  border-radius: 6px;
  padding: 10px 10px 5px 10px;
  & h6 {
    color: #fff !important;
    line-height: 18px !important;
    font-size: 14px !important;
  }
  & .h7 {
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }
}
.message-date {
  font-size: 12px;
  font-style: italic;
  text-align: right;
  margin-top: 15px !important;
}
.send-spot {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
  background-color: #FFFFFF;
  display: flex;
  align-items: flex-start;
  position: fixed;
  width: 75% !important;
  bottom: 0;
  .text {
    background-color: #FFFFFF;
    padding: 15px 8px 8px 8px !important;
    .input-wrapper {
      #ticket-chat {
        border: 1px solid #8b8b8b;
        color: #424242 !important;
      }
    }
  }
  .send {
    background-color: #FFFFFF;
    height: 100%;
    width: 100%;
    padding: 0;
    .send-btn {
      position: absolute;
      top: 45px;
      left: 30px;
      color: #ffffff;
      font-size: 30px !important;
      cursor: pointer;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}
.messages-height::-webkit-scrollbar-thumb {
  background-color: #979797;
}
.break-line-sent {
  white-space: pre-line;
}
.messages-height {
  padding: 0px 10px 30px 10px !important;
  margin-top: 10px !important;
  height: calc(100vh - 240px) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.loading-circle-msg {
  & .circle-msg {
    width: 20px !important;
    height: 20px !important;
    border-color: rgb(92, 92, 92) rgb(223, 222, 223) rgb(223, 222, 223) !important;
    border-width: 2px !important;
  }
  position: absolute;
  top: 60px;
  left: 30px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.message-area {
  &:after {
    display: none !important;
  }
  & textarea {
    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: #e1e1e194;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #fff !important;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #e1e1e105;
    }
  }
}
.ticket-block-message {
  height: 70px;
  margin-top: 10px;
  justify-content: space-around;
  display: flex;
  align-items: center;
  .material-icons {
    font-size: 30px;
  }
}
@supports (-moz-appearance:none) {
  .text { padding: 5px 8px 8px 8px !important; }
  .send-btn {
    left: 30px !important;
    top: 50px !important;
  }
  .messages-height {
    height: calc(100vh - 255px) !important;
  }
}
</style>
