<template>
  <div class="animated fadeIn">
    <button class="hide-when-print" style="display: flex;" @click="changeLegendState(hideOrShow = !hideOrShow)">
      <i class="btn-icons material-icons tag-icon">{{!hideOrShow ? 'visibility_off' : 'visibility'}}</i>
      <span class="legend-button">{{$t('dashboard.legends')}}</span>
    </button>
    <canvas :id="makeSpecialId()"  width="600" height="400" class="pieChart"></canvas>
  </div>
</template>

<script>
/* eslint-disable */

import('chart.js').then((chartjs) => {
  window.Chart = chartjs.default
})
import i18n from '../../../translations/i18n'
import { canvasMaker, responseOptions } from '@/_helpers'

export default {
  name: 'Pie',
  props: [ 'data', 'question', 'cardData' ],
  data () {
    return {
      dataForLegends: [],
      currentChart: {},
      hideOrShow: true,
      colors: ['#a87df5', '#fc90a4', '#00aaa7', '#673ab7', '#9f95f5', '#FFB500', '#f55bed', '#CA1E54', '#4D5359', '#79C99E', '#97DB4F', '#27B573', '#FF7663', '#1B9AAA', '#FFC43D']
    }
  },
  methods: {
    makeSpecialId () {
      this.question.canvasId = canvasMaker.makeCanvasId(this.question)
      return this.question.canvasId
    },
    changeLegendState (bool) {
      if(this.currentChart.options) {
        this.currentChart.options.legend.display = bool
        this.currentChart.update()
      }
    }
  },
  async mounted () {
    this.$root.$on('show-subtitles', (value) => {
      this.hideOrShow = value
      this.changeLegendState(this.hideOrShow)
    })

    let fontSize = 12
    let fontFamily = "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
    let backgroundColor = this.question.type === 'enum' ? this.colors : this.cardData.colors
    const labels = []
    const data = []
    const keyOptions = responseOptions.getOptions(this.question)

    this.data[0].questions.map(item => {
      if (item.question._id === this.question._id) {
        item.data.map((point, index) => {
          let legendObj = { label: '', count: '' }
          if (index > 0) {
            legendObj.count = point[1]
            data.push(point[1])
            if (['multiple', 'single', 'singleOther', 'multipleOther'].indexOf(this.question.ui) > -1) {
              labels.push(point[0])
              legendObj.label = point[0]
              return false
            }
            keyOptions.map(op => {
              if ([op.value, op.rating].indexOf(point[0].toString()) > -1) {
                if (['10num', '5num'].indexOf(this.question.ui) > -1) {
                  const label = i18n.t('dashboard.graphics.op') + ' ' + op.label
                  labels.push(label)
                  legendObj.label = label
                } else if (this.question.ui === '5emo') {
                  fontSize = 20
                  fontFamily = 'icomoon'
                  backgroundColor = [
                    this.cardData.colors[0],
                    this.cardData.colors[1],
                    this.cardData.colors[2],
                    this.cardData.colors[3],
                    this.cardData.colors[4]
                  ]
                  if (point[0].toString() === op.value) {
                    labels.push(op.icon)
                    legendObj.label = op.icon
                  }
                } else {
                  labels.push(op.label)
                  legendObj.label = op.label
                }
              }
            })

            this.dataForLegends.push(legendObj)
          }
        })
      }
    })
    const planetChartData = {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [
          {   
            data: data,
            backgroundColor: backgroundColor
          }
        ]
      },
      options: {
        tooltips: {
          titleFontFamily: fontFamily,
          titleFontSize: fontSize,
          callbacks: {
            title: (tooltipItem, data) => {
              let sumTexts = ''
              const indexOfLabel = tooltipItem[0].index
              labels[indexOfLabel].split(' ').forEach((split, index) => {
                if (index % 4 === 0) {
                  sumTexts += `${split}`
                } else {
                  sumTexts += ` ${split}`
                }
              })
              return sumTexts
            },
            label: function (context) {
              return data[context.index]
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 50,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        legend: {
          display: true,
          position:'bottom',
          align:'center',
          labels: {
            padding: 10,
            fontFamily: fontFamily,
            fontSize: fontSize,
            fontColor: '#939393'
          } 
        }
      }     
    }

    if (planetChartData.data.datasets) {
      const ctx = document.getElementById(this.question.canvasId)
      const loadedChartJs = await window.Chart
  
      loadedChartJs.defaults.global.legend.position = 'bottom';
      loadedChartJs.defaults.global.legend.labels.usePointStyle = true;
      
      this.currentChart = new loadedChartJs(ctx, {
        type: planetChartData.type,
        data: planetChartData.data,
        options: planetChartData.options
      })
  
      ctx.setAttribute('style', 'width: 480px; height: 250px; display:block; padding-left:27px')
      return this.currentChart
    }
  }
}
</script>
<style lang="scss">
.pieChart {
	display: block;
	margin-left: -43px;
}
.pie-legends {
  display: block;
  position: absolute;
  top:330px;
  left: 50px;
  .setLabel {
    margin-top: 20px;
    font-size: 0.7vw;
    span{
      display: block;
      font-size: 16px;
    }
  }
}
.legend-button {
  display: flex;
  font-size: 11px;
  margin-left: 5px;
  color: #b5b5b5;
  align-items: center;
}
</style>