<template>
  <div>
    <div class="loading-approaches" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <div class="row">
      <div class="notToShowMobile">
        <div class="row center-lg center-md center-sm">
          <div class="col-sm-2 col-md-2 col-lg-2">
            <app-heading level="h6" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 input-title">{{ $t('performance.choose_period') }}:</app-heading>
            <div class="submenu_select row">
              <v-select
                label="label"
                :clearable="false"
                :searchable="false"
                :options="periods"
                v-model="selectedPeriod"
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12 survey-selector"
                @input="updateComparativeDash()"
              ></v-select>
            </div>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-4">
            <app-heading level="h6" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 title-multi">{{ $t('performance.ten_surveys') }}:</app-heading>
            <div class="submenu_select row">
              <multiselect
                label="title"
                track-by="title"
                :placeholder="$t('performance.select')"
                :selectLabel="$t('performance.select_two')"
                :selectGroupLabel="$t('performance.select_two')"
                :selectedLabel="$t('performance.selected')"
                :deselectLabel="$t('performance.to_remove')"
                :deselectGroupLabel="$t('performance.mark_off')"
                :maxElements="$t('performance.max_elements')"
                :noResult="$t('performance.no_result')"
                :noOptions="$t('performance.no_options')"
                :multiple="true"
                :max-height="300"
                :options="surveys"
                :allowEmpty="false"
                :preserve-search="true"
                :clear-on-select="false"
                :close-on-select="false"
                v-model="maxTenSurveysArray"
                class="multi-surveys"
                @input="updateComparativeDash(true)"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span class="multiselect__single" v-if="values.length && !isOpen">
                    {{ values.length }} {{ $t('performance.surveys_selected') }}
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <p :class="{'option-expired-multi': props.option.isExpired}">
                  {{ props.option.title }}
                  <i v-if="props.option.isExpired" class="material-icons expired-icon-select" v-title="$t('surveyList.survey_expired')">timer_off</i>
                </p>
                </template>
              </multiselect>
            </div>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-4">
            <app-heading level="h6" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 input-title">{{ $t('performance.choose_param') }}:</app-heading>
            <div class="submenu_select row">
              <v-select
                label="label"
                :clearable="false"
                :options="metrics"
                v-model="selectedMetric"
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12 survey-selector"
                @input="updateComparativeDash()"
              ></v-select>
            </div>
          </div>
          <div class="col-sm-2 col-md-2 col-lg-2">
            <router-link to="#ungrouped">
              <app-heading level="h6" class="ungroup">{{ $t('performance.ungroup') }}</app-heading>
            </router-link>
          </div>
          <div class="row center-lg center-md center-sm">
            <div class="bar-chart">
              <app-card>
                <app-heading level="h5" class="chart-title">{{ $t('performance.between_surveys') }}</app-heading>
                <bar-comparative-dash :dataComparative="barsChartData" :metric="selectedMetric.metric" :key="renderChart"></bar-comparative-dash>
              </app-card>
            </div>
            <div class="bar-chart">
              <app-card>
                <app-heading level="h5" class="chart-title">{{ $t('performance.overtime_between_surveys') }}</app-heading>
                <overtime-comparative-dash
                  :period="selectedPeriod.period"
                  v-if="overtimeChartData.length > 0 && maxTenSurveysArray.length === overtimeChartData.length"
                  :overtimeChartData="overtimeChartData"
                  :metric="selectedMetric.metric"
                  :key="renderMultipleOvertime"
                  :multiple="true"
                ></overtime-comparative-dash>
                <!-- só pra preencher enquanto espera o overtime ficar completo -->
                <div v-else style="width: 550px; height: 400px;"></div>
              </app-card>
            </div>
          </div>
          <div id="ungrouped" class="row center-lg center-md center-sm">
            <div v-for="(item, lineIndex) in overtimeChartData" :key="lineIndex">
              <app-card class="single-line">
                <overtime-comparative-dash
                  :period="selectedPeriod.period"
                  :overtimeChartData="item"
                  :metric="selectedMetric"
                  :key="renderOvertimeChart"
                  :indexForColor="lineIndex"
                  :single="true"
                ></overtime-comparative-dash>
              </app-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { surveyService, comparativeDashService } from '@/_services'
import BarComparativeDash from '../charts/BarComparativeDash.vue'
import OvertimeComparativeDash from '../charts/OvertimeComparativeDash.vue'

export default {
  name: 'ComparativeDash',
  components: {
    BarComparativeDash,
    OvertimeComparativeDash,
    vSelect: () => import('vue-select'),
    Multiselect: () => import('vue-multiselect'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      surveys: [],
      maxTenSurveysArray: [],
      metrics: [
        { label: this.$i18n.t('performance.sendings'), metric: 'sendings' },
        { label: this.$i18n.t('performance.opening_rate'), metric: 'opened' },
        { label: this.$i18n.t('performance.answer_rate'), metric: 'responses' },
        { label: this.$i18n.t('performance.complete_response_rate'), metric: 'finishedResponses' },
        { label: this.$i18n.t('performance.abandonment_rate'), metric: 'abandoned' },
        { label: this.$i18n.t('performance.response_time'), metric: 'responseTime' }
      ],
      periods: [
        { label: this.$i18n.t('performance.day'), period: 'day' },
        { label: this.$i18n.t('performance.week'), period: 'week' },
        { label: this.$i18n.t('performance.month'), period: 'month' },
        { label: this.$i18n.t('performance.semester'), period: 'semester' },
        { label: this.$i18n.t('performance.year'), period: 'year' }
      ],
      selectedPeriod: { label: this.$i18n.t('performance.month'), period: 'month' },
      selectedMetric: { label: this.$i18n.t('performance.sendings'), metric: 'sendings' },
      barsChartData: [],
      overtimeChartData: [],
      renderChart: 0,
      renderOvertimeChart: 0,
      renderMultipleOvertime: 0,
      surveysIds: [],
      loading: false,
      ungroup: false
    }
  },
  async mounted () {
    this.loading = true
    this.surveys = await surveyService.getAllSurveys()
    if (this.surveys.length > 0) {
      this.surveys.map((item, index) => {
        this.$store.commit('filters/insertSurveyField', item)
        if (index <= 1) {
          this.maxTenSurveysArray.push(item)
        }
      })
      this.maxTenSurveysArray.map(item => {
        if (!this.surveysIds.includes(item._id)) {
          this.surveysIds.push(item._id)
        }
      })
    } else {
      return this.$router.push({ name: 'Start' })
    }
    this.getComparativeDash()
  },
  methods: {
    async getComparativeDash () {
      this.loading = true
      if (this.$route.name !== 'Performance') {
        return false
      }
      this.overtimeChartData = []
      const barsData = await comparativeDashService.getComparativeBarsChart({
        metric: this.selectedMetric.metric,
        period: this.selectedPeriod.period,
        surveys: this.surveysIds
      })
      this.barsChartData = barsData

      this.maxTenSurveysArray.map(async (survey) => {
        const overtimeData = await comparativeDashService.getComparativeOvertimeChart({
          metric: this.selectedMetric.metric,
          period: this.selectedPeriod.period,
          survey: survey._id
        })
        this.overtimeChartData.push(overtimeData)
      })
      this.renderChart += 1
      this.renderOvertimeChart += 1
      this.renderMultipleOvertime += 1
      this.loading = false
      this.updateUrl()
    },
    updateComparativeDash (isSurveysArray) {
      this.surveysIds = []
      if (isSurveysArray && this.maxTenSurveysArray.length > 10) {
        this.maxTenSurveysArray.pop()
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('performance.only_ten'),
          position: 'bottomRight',
          showAlert: true
        })
        this.maxTenSurveysArray.map(item => {
          this.surveysIds.push(item._id)
        })
      }
      this.maxTenSurveysArray.map(item => {
        if (!this.surveysIds.includes(item._id)) {
          this.surveysIds.push(item._id)
        }
      })
      this.getComparativeDash()
    },
    updateUrl () {
      const url = []
      let strSurveys = ''
      let urlObj = {}

      this.surveysIds.map(item => {
        url.push(`surveys=${item}&`)
      })

      url.push(`period=${this.selectedPeriod.period}&metric=${this.selectedMetric.metric}`)
      strSurveys = encodeURI(url.join(''))

      this.$route.query.q = { $and: strSurveys }
      urlObj = { q: JSON.stringify(this.$route.query.q) }
      this.$router.push({ path: this.$route.path, query: urlObj })
    }
  }
}
</script>

<style lang="scss">
.multiselect__content {
  padding: 0 5px;
  .multiselect__option {
    padding: 0;
    height: 20px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    .option-expired-multi {
      background: #ebebeb;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}
.overflow-selection > div {
  overflow-x: auto !important;
}
.input-title {
  font-size: 14px !important;
  margin-left: 18px !important;
  margin-bottom: 10px !important;
}
.title-multi {
  font-size: 14px !important;
  margin-left: 2px !important;
  margin-bottom: 10px !important;
}
.ungroup {
  margin-top: 35px !important;
  cursor: pointer;
}
.bar-chart {
  padding: 0px 30px 30px 30px !important;
}
.chart-title {
  text-align: center;
  margin-bottom: 15px !important;
}
.single-line {
  width: 400px !important;
  height: 400px !important;
  margin-right: 30px;
  margin-bottom: 30px;
}
.multi-surveys {
  & .multiselect__tags {
    min-height: 52px !important;
    padding-top: 15px;
  }
  & .multiselect__select {
    top: 5px !important;
  }
}
</style>
