<template>
  <div class="side-settings">
    <EmailConfig v-if="where === 'email' || where === 'emailSms'"></EmailConfig>
    <LinkConfig v-if="where ==='link'"></LinkConfig>
    <TotenConfig v-if="where ==='toten'"></TotenConfig>
    <WidgetConfig v-if="where ==='widget'"></WidgetConfig>
    <WhatsappConfig v-if="where ==='whatsapp'"></WhatsappConfig>
  </div>
</template>

<script>
import EmailConfig from './EmailConfig.vue'
import LinkConfig from './LinkConfig.vue'
import TotenConfig from './TotenConfig.vue'
import WidgetConfig from './WidgetConfig.vue'
import WhatsappConfig from './WhatsappConfig.vue'

export default {
  name: 'SendhubSidebars',
  props: ['where'],
  components: { EmailConfig, LinkConfig, TotenConfig, WidgetConfig, WhatsappConfig }
}
</script>

<style lang="scss">
.act {
  width: 342px;
  padding-top: 35px;
  margin-left: 20px !important;
  & h6 {
    font-size: 13.5px !important;
    margin-left: 50px !important;
    margin-top: 3px !important;
  }
}
.heading {
  margin-bottom: 3px !important;
}
.btn {
  & button {
    font-size: 16px !important;
    height: 48px;
  }
  & button + button {
    margin-left: 10px;
  }
  & .btn-save {
    width: 109px;
  }
  & .btn-send {
    width: 172px;
  }
}
.labelButton {
  & h6 {
    font-size: 14px !important;
    margin-left: 0 !important;
  }
  & .text-field__input {
    input {
      font-size: 16px !important;
    }
  }
}
</style>
