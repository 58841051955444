
export const tooltipMaker = {
  makeCustomTooltip
}

function makeCustomTooltip (tooltipModel, lines, position, color) {
  // Tooltip Element
  var tooltipEl = document.getElementById('bindsChart-tooltip')

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.id = 'bindsChart-tooltip'
    tooltipEl.innerHTML = '<table></table>'
    document.body.appendChild(tooltipEl)
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  }

  // Set Position
  tooltipEl.classList.remove('above', 'below', 'no-transform')
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign)
  } else {
    tooltipEl.classList.add('no-transform')
  }

  // Set Text
  if (tooltipModel.body) {
    var titleLines = tooltipModel.title || []
    var bodyLines = lines[0]

    // head of the tooltip, with default color
    var innerHtml = '<thead style="background-color:' + color + '">'

    // Set the title with the current pointer selected
    titleLines.forEach(function (title) {
      innerHtml += '<tr><th style="color: #fff;padding: 10px;">' + title + '</th></tr>'
    })
    // Set the current value of pointer selected
    innerHtml += '<div style="font-weight: 600;background-color: #737373f2;text-align: center;color: #fff;padding: 5px;">' + tooltipModel.dataPoints[0].value + '</div></thead><tbody>'
    // Set the amount of doc_count, that makes the SUM of values
    bodyLines.valueGroup[tooltipModel.dataPoints[0].index].forEach(function (item, i) {
      innerHtml += '<tr><td style="padding: 4px 0px 5px 5px;background-color: #737373d1;font-weight: 520;color: #fff">' + item.key + ': ' + item.doc_count + '</td></tr>'
    })
    innerHtml += '</tbody>'

    var tableRoot = tooltipEl.querySelector('table')
    tableRoot.innerHTML = innerHtml
  }
  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1
  tooltipEl.style.position = 'absolute'
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX / 1.5 + 'px'
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
  tooltipEl.style.pointerEvents = 'none'
}
