<template>
  <div class="container-widgethub">
    <div class="preview-widgethub">
      <div class="devices-widgethub">
        <button class="btn-device-widgethub" @click="changeDevice('desktop')">Desktop</button>
        <button class="btn-device-widgethub" @click="changeDevice('mobile')">Mobile</button>
      </div>
      <div id="device-only" class="desktop">
        <div class="screen-device">
          <iframe class="iframe-preview-site" :src="widget.sitePreview"></iframe>
          <iframe class="iframe-preview-widget" :src="preview"></iframe>
        </div>
        <div class="ball-device"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'widgetHub',
  components: {
  },
  data () {
    return {
      portable: 'view-port',
      scriptImport: 'https://widget-v3.web.app/js/app.js',
      key: 0,
      scriptWidget: ''
      // linkWidget: ''
    }
  },
  async mounted () {
    this.$nextTick(function () {
      const scriptWidget = document.createElement('script')
      scriptWidget.setAttribute('src', 'https://widget-v3.web.app/js/app.js')
      document.head.appendChild(scriptWidget)
      this.scriptWidget = scriptWidget

      // const linkWidget = document.createElement('link')
      // linkWidget.setAttribute('href', 'https://widget-v3.web.app/css/app.css')
      // linkWidget.setAttribute('rel', 'stylesheet')
      // document.head.appendChild(linkWidget)
      // this.linkWidget = linkWidget

      // comentado para acompanhar, acredito que nem use mais isso para o preview do widget.
    })
  },
  beforeDestroy () {
    document.head.removeChild(this.scriptWidget)
    // document.head.removeChild(this.linkWidget)
  },
  computed: {
    preview () {
      const widget = this.$store.getters['widget/getWidget']

      const getBlobUrl = (code, type) => {
        const blob = new Blob([code], { type })
        return URL.createObjectURL(blob)
      }

      const previewCode = [
        '<!DOCTYPE html>',
        '<head>',
        '<meta charset=utf8>',
        '</head>',
        '<div>',
        '<link href="https://widget-v3.web.app/css/app.css" rel=stylesheet>',
        '<div>',
        '<vue-widget',
        ' widget_position="' + widget.widgetPosition + '"',
        ' widget_title="' + widget.widgetTitle + '"',
        ' buttons="' + widget.buttons + '"',
        ' width="' + widget.width + '"',
        ' height="' + widget.height + '"',
        ' text_buttons="' + widget.textButtons + '"',
        ' background="' + widget.background + '"',
        ' texts="' + widget.texts + '"',
        ' timeout="' + widget.timeout + '"',
        ' width_metric="' + widget.metric + '"',
        ' survey_id="' + this.survey._id + '"',
        ' close_button="' + widget.closeButton + '"',
        ' close_permanently="' + widget.closePermanently + '"',
        ' response_background="' + widget.responseBackground + '"',
        ' hidde_after_answer="' + widget.hiddeAfterAnswer + '"',
        ' hidde_when_click_to_close="' + widget.hiddeWhenClickToClose + '"',
        ' response_font_color="' + widget.responseFontColor + '"',
        ' font_family="' + widget.fontFamily + '"',
        ' font_size="' + widget.fontSize + '"',
        widget.widgetPosition.includes('actionButton') ? `action_config='${widget.actionConfig}'` : '',
        ` text_props='${widget.textProps}'`,
        ' border_radius="' + widget.borderRadius + '"',
        ' from=' + "'{" + '"name": "", "email": "", "phone":""}' + "'",
        ' is_to_post="false"',
        ' metadata=' + "'{}'" + '/>',
        '</div>',
        /* eslint-disable */
        '<script src="https://widget-v3.web.app/js/app.js"><\/script>',
        '</div>'
      ].join('')

      return getBlobUrl(previewCode, 'text/html')
    },
    widget () {
      const get = this.$store.getters['widget/getWidget']
      return get
    },
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    },
    question () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey.questions[0]
    },
    widgetColorsComponents () {
      const wid = this.$store.getters['widget/getWidget']
      const colors = {
        actionColor: wid.buttons,
        actionText: wid.textButtons,
        splashColor: wid.texts
      }
      return colors
    }
  },
  methods: {
    changeDevice (e) {
      const onlyScreen = document.getElementById('device-only')
      onlyScreen.className = e
    }
  },
  watch: {
    widget: {
      deep: true,
      handler (oldVal, newVal) {
        if (oldVal.seconds === newVal.seconds) {
          this.key++
        }
      }
    }
  }
}
</script>

<style lang="scss">
.container-widgethub {
  margin-top: 20px;
  width: 100%;
  height: 780px;
  display: flex;
  align-items: center;
  justify-content: center;
  .preview-widgethub {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .devices-widgethub {
      width: 20%;
      height: 5%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .btn-device-widgethub {
        border-radius: 8px;
        width: 35%;
        height: 100%;
        background: darkgoldenrod;
        font-size: 1.1em;
        border: none;
        color: var(--default-text-color);
        background: var(--accent-color);
      }
      .btn-device-widgethub:hover {
        transform: scale(1.01);
      }
    }
    .desktop {
      background: #424242;
      width: 100%;
      height: 95%;
      border-radius: 10px;
      margin-top: 20px;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      border: 1px solid #b9b9b9;
      align-items: center;
      justify-content: flex-start;
      transition: all 0.5s ease;
      .screen-device {
        position: relative;
        overflow: hidden;
        width: 97%;
        height: 90%;
        border-radius: 10px;
        border: 1px solid #b9b9b9;
      }
      .ball-device {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-top: 15px;
        background: #919191;
      }
    }
    .mobile {
      background: #424242;
      width: 32%;
      height: 85%;
      border-radius: 10px;
      margin-top: 20px;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      border: 1px solid #b9b9b9;
      align-items: center;
      justify-content: flex-start;
      transition: all 0.5s ease;
      .screen-device {
        position: relative;
        overflow: hidden;
        width: 95%;
        height: 90%;
        border-radius: 10px;
        border: 1px solid #b9b9b9;
      }
      .ball-device {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-top: 10px;
        background: #919191;
        border: 1px solid white;
      }
    }
  }
  .iframe-preview-site {
    width: 100%;
    height: 100%;
  }
  .iframe-preview-widget {
    width: 168%;
    height: 168%;
    position: absolute;
    top: -34%;
    left: -34%;
    transform: scale(0.6);
  }
}
</style>
