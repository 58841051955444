<template>
  <div class="container-card-brand">
    <div class="info-card-brand" :style="{'min-height': data.type === 'customBrand' ? '6em' : '5em' }">
      <div class="image-card-brand">
        <img @error="onErrorToLoadImage()" :src="src">
      </div>
      <div class="name-card-brand">
        <app-heading
        v-if="data.name || data[data.type].name"
        level="h5" class="name-brand" style="display: flex; align-items: center;">
          {{ data.name || data[data.type].name }}
          <i v-if="data[data.type] && data[data.type].verified"
           v-title="$t('bvs.verified_user')"
            class="material-icons icon-brand-verified">{{ returnIconOrigin }}</i>
        </app-heading>
        <app-heading level="h7"
        v-if="data.screen_name || data[data.type].screen_name"
        class="screen-name-brand">
        @{{ data.screen_name || data[data.type].screen_name }}
      </app-heading>
      <app-heading level="h7"
        v-if="data.createdAt" v-title="$t('bvs.create_date')"
        class="screen-name-brand" style="display: flex;align-items: center;">
        {{ $moment(data.createdAt).format('LL') }}
      </app-heading>
      <app-heading level="h5" style="display: flex; align-items: center;">
        <i v-if="data.type === 'twitter'"
          v-title="$t('bvs.public_brand')"
          class="material-icons icons-brand">public</i>
          <i v-if="data.hasTerms"
          v-title="$t('bvs.has_terms')"
          class="material-icons icons-brand">article</i>
          <i v-if="data.type === 'customBrand'"
          v-title="$t('bvs.custom_brand')"
          class="material-icons icons-brand">cloud_upload</i>
        </app-heading>
      </div>
      <div v-if="showActionButtons && !isToShowTheFirstAnimation()" class="options-card-brand">
        <div>
          <i class="material-icons remove-brand-icon" v-title="data.type === 'customBrand' ? $t('bvs.delete') : $t('bvs.delete_term')" @click="openModal('remove')">delete</i>
          <i class="material-icons add-brand-icon" v-if="!data._id" @click="openModal('add')" v-title="$t('bvs.add')">add</i>
          <i class="material-icons add-brand-icon" v-if="data.type === 'customBrand'" v-title="$t('bvs.edit')" @click="openModal('edit')">edit</i>
          <i class="material-icons add-brand-icon" v-if="data.type === 'customBrand'" v-title="'Upload'" @click="openUploadTab()">upload_file</i>
        </div>
      </div>
    </div>
    <div v-if="isToShowTheFirstAnimation()"
      v-title="$t('bvs.looking_for_posts')"
      class="loading-card-brand-action">
      <div class="fadeInIt">
        <div v-for="(currentAnimation, animateIdx) in step.animations"
            v-show="step.animation === (animateIdx + 1)" :key="animateIdx"
            class="animateThere">
          <div class="icon-text">
            <i class="material-icons new-brand-icons">{{ currentAnimation.icon }}</i>
            <p>{{ currentAnimation.text }}</p>
          </div>
          <Circle2 class="spinner-social"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardBrand',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    showActionButtons: {
      type: Boolean,
      default: true
    }
  },
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      src: '',
      step: {
        animation: 1,
        animations: [
          { icon: 'travel_explore', text: this.$i18n.t('bvs.modal_step1') },
          { icon: 'tag', text: this.$i18n.t('bvs.modal_step2') },
          { icon: 'insert_emoticon', text: this.$i18n.t('bvs.modal_step3') },
          { icon: 'local_fire_department', text: this.$i18n.t('bvs.modal_step4') },
          { icon: 'moving', text: this.$i18n.t('bvs.modal_step5') },
          { icon: 'pending', text: this.$i18n.t('bvs.modal_step6') }
        ]
      },
      metadatas: [],
      emptyImageOrErrorLoad: 'https://res.cloudinary.com/binds-co/image/upload/v1508526527/placeholder_je0010.png',
      urlImageDefaultCustom: 'https://w7.pngwing.com/pngs/591/439/png-transparent-cloud-upload-export-upload-upload-to-cloud-cute-tech-icon-set-icon.png'
    }
  },
  mounted () {
    this.loadTheImage()
    const interval = setInterval(() => { // just some animation
      if (this.step.animation === 6) {
        return clearInterval(interval)
      }
      this.step.animation++
    }, 10000)
  },
  computed: {
    returnIconOrigin () {
      return this.data.type === 'twitter' || this.data[this.data.type].verified ? 'verified' : ''
    }
  },
  methods: {
    isToShowTheFirstAnimation () {
      if (this.data._id && this.data.type === 'twitter' && !this.data.hasTerms) {
        if (this.data.createdAt) {
          const brandCreateAt = this.$moment(this.data.createdAt)
          const now = this.$moment()
          return now.diff(brandCreateAt, 'minutes') <= 3
        }
      }
      return false
    },
    loadTheImage () {
      if (this.data.customBrand && this.data.customBrand.metadata) {
        for (const [key, value] of Object.entries(this.data.customBrand.metadata)) {
          this.metadatas.push({ label: key, value: value })
        }
      }
      if (this.data.profile_image_url || this.data[this.data.type].profile_image_url_https) {
        this.src = this.data.profile_image_url || this.data[this.data.type].profile_image_url_https
      }

      if (this.data.type === 'customBrand') {
        this.src = this.urlImageDefaultCustom // fix the images of custom brands
      }
    },
    onErrorToLoadImage () {
      this.src = this.emptyImageOrErrorLoad
    },
    openModal (type) {
      this.$root.$emit('open-modal-of-brands-tracking', { data: this.data, type })
    },
    openUploadTab () {
      this.$root.$emit('open-upload', this.data)
    }
  }

}
</script>

<style lang="scss">
.container-card-brand {
  position: relative;
  width: 338px;
  height: 140px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 0.3em;
  margin: 0.1em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .info-card-brand {
    width: 100%;
    height: 75%;
    display: flex;
    align-items: center;
    .image-card-brand {
      width: 3em;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #cacaca;
      padding: 2px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .new-brands-animations {
      width: 50% !important;
      display: flex;
      .new-brand-icons {
        color: var(--accent-color);
      }
    }
    .name-card-brand {
      width: 70%;
      height: 90%;
      padding-left: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .name-brand, .screen-name-brand {
        color: #454548;
      }
      .name-brand {
        font-size: 1em !important;
      }
      .icon-brand-verified, .icons-brand {
          font-size: 16px;
          color: #959595;
        }
      .icon-brand-verified {
        margin-left: 5px;
      }
      .screen-name-brand {
        margin: 0.2em 0px !important;
        font-size: 0.9em !important;
        color: #959595 !important;
      }
      .type-brand-icon {
        color: #b3b3b3;
        font-size: 20px;
      }
    }
    .options-card-brand {
      position: absolute;
      right: 0;
      top: 0;
      width: 15%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .add-brand-icon, .remove-brand-icon {
        width: 27px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #cacaca;
        color: var(--accent-color);
        font-size: 18px;
        cursor: pointer;
        margin: 0.5em;
      }
    }
  }
  .loading-card-brand-action {
    height: 25%;
    display: flex;
    align-items: center;
    .fadeInIt {
      width: 100%;
      height: 100%;
      .animateThere {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon-text {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .new-brand-icons {
            font-size: 20px;
            color: var(--accent-color);
            width: 6%;
            margin-right: 15px;
          }
          p {
            font-size: 12px;
            width: 90%;
            color: #454548;
          }
        }
        .spinner {
          margin-right: 5px;
          width: 20px !important;
          height: 20px !important;
          border-width: 2px !important;
          border-color: var(--default-text-color) var(--accent-color) var(--accent-color) !important;
        }
      }
    }
  }
  .types-card-brand {
    width: 90%;
    padding: 5px 2px;
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
    .type-card-brand {
      width: fit-content;
      font-size: 12px;
      font-weight: 500;
      margin: 0 5px 5px 0;
      padding: 2px 5px;
      border-radius: 35px;
      color: #0B66C3 !important;
      border: 1px solid #0B66C3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
