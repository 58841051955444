<template>
  <div>
    <div class="tag-cloud-display" v-if="tooltipTag">
        <app-card>
          <app-button class="close-tag-cloud-display" inline @click.native="closeDisplayTag()" style="font-size: 20px;position: absolute;top: 0px;right: -7px;"> x </app-button>
            <div class="tag-cloud-details">
                <app-heading level="h4" style="margin-top:10px;">{{word.word}}</app-heading>
                <div class="tag-cloud-options">
                  <ul v-if="word.question.type === 'kpi'  && (word.question.ui === 'ynd'|| word.question.ui === 'yn')">
                    <li class="hvr-grow" v-on:click="toResponse({ $gte: 100 })"><i class="point-key" style="background-color:#00aaa7 ;"></i><span>{{ $t("dashboard.graphics.op1") }}: {{y_score}}</span><i class="material-icons">open_in_new</i></li>
                    <li class="hvr-grow" v-on:click="toResponse({ $gte: 49, $lte: 51 })" v-if="word.question.ui === 'ynd'"><i class="point-key" style="background-color:#ffb500 ;"></i><span>{{ $t("dashboard.graphics.op3") }}: {{mb_score}}</span><i class="material-icons">open_in_new</i></li>
                    <li class="hvr-grow" v-on:click="toResponse({ $lte: 1 })"><i class="point-key" style="background-color:#ff607d ;"></i><span>{{ $t("dashboard.graphics.op2") }}: {{n_score}}</span><i class="material-icons">open_in_new</i></li>
                  </ul>
                  <ul v-if="word.question.type === 'nps'">
                    <li class="hvr-grow" v-on:click="toResponse({ $gte: 90 })"><i class="point-key" style="background-color:#00aaa7;"></i><span>{{ $t("dashboard.graphics.promoter") }}: {{promoter_score}}</span><i class="material-icons">open_in_new</i></li>
                    <li class="hvr-grow" v-on:click="toResponse({ $lte: 80, $gt: 60 })"><i class="point-key" style="background-color:#ffb500;"></i><span>{{ $t("dashboard.graphics.neutralString") }}: {{neutral_score}}</span><i class="material-icons">open_in_new</i></li>
                    <li class="hvr-grow" v-on:click="toResponse({ $lte: 60 })"><i class="point-key" style="background-color:#ff607d;"></i><span>{{ $t("dashboard.graphics.detractor") }}: {{detractor_score}}</span><i class="material-icons">open_in_new</i></li>
                  </ul>
                  <ul v-if="word.question.type === 'csat' && word.question.ui === '5emo'">
                    <li class="hvr-grow"  v-on:click="toResponse({ $lte: 1 })"><i class="point-key" style="background-color:#ff607d;"></i><span>{{ $t("dashboard.graphics.vu") }}: {{vu_score}}</span><i class="material-icons">open_in_new</i></li>
                    <li class="hvr-grow"  v-on:click="toResponse({ $gte: 24, $lte: 26 })"><i class="point-key" style="background-color:#ff607d;"></i><span>{{ $t("dashboard.graphics.un") }}: {{un_score}}</span><i class="material-icons">open_in_new</i></li>
                    <li class="hvr-grow"  v-on:click="toResponse({ $gte: 49, $lte: 51 })"><i class="point-key" style="background-color:#ffb500;"></i><span>{{ $t("dashboard.graphics.ind") }}: {{ind_score}}</span><i class="material-icons">open_in_new</i></li>
                    <li class="hvr-grow"  v-on:click="toResponse({ $gte: 74, $lte: 76 })"><i class="point-key" style="background-color:#00aaa7;"></i><span>{{ $t("dashboard.graphics.sat") }}: {{sat_score}}</span><i class="material-icons">open_in_new</i></li>
                    <li class="hvr-grow"  v-on:click="toResponse({ $gte: 100 })"><i class="point-key" style="background-color:#00aaa7;"></i><span>{{ $t("dashboard.graphics.vs") }}: {{vs_score}}</span><i class="material-icons">open_in_new</i></li>
                  </ul>
                  <ul v-if="word.question.type === 'csat' && (word.question.ui === '10num' || word.question.ui === '5num')">
                    <li class="hvr-grow"  v-on:click="toResponse({ $lt: 70 })"><i class="point-key" style="background-color:#ff607d;"></i><span>{{ $t("dashboard.graphics.un") }}: {{unsatisfied_score}}</span><i class="material-icons">open_in_new</i></li>
                    <li class="hvr-grow"  v-on:click="toResponse({ $gte: 70 })"><i class="point-key" style="background-color:#00aaa7;"></i><span>{{ $t("dashboard.graphics.sat") }}: {{satisfied_score}}</span><i class="material-icons">open_in_new</i></li>
                  </ul>
                  <ul v-if="word.question.type === 'kpi'  &&  word.question.ui === '5emo'">
                    <li class="hvr-grow"  v-on:click="toResponse({ $gte: 100 })"><i class="point-key" style="background-color:#00aaa7 ;"></i><span>{{ $t("dashboard.graphics.ex") }}: {{ex_score}}</span><i class="material-icons">open_in_new</i></li>
                    <li class="hvr-grow"  v-on:click="toResponse({ $gte: 74, $lte: 76 })"><i class="point-key" style="background-color:#00aaa7 ;"></i><span>{{ $t("dashboard.graphics.vg") }}: {{vg_score}}</span><i class="material-icons"></i></li>
                    <li class="hvr-grow"  v-on:click="toResponse({ $gte: 49, $lte: 51 })"><i class="point-key" style="background-color:#ffb500 ;"></i><span>{{ $t("dashboard.graphics.st") }}: {{st_score}}</span><i class="material-icons"></i></li>
                    <li class="hvr-grow"  v-on:click="toResponse({ $gte: 24, $lte: 26 })"><i class="point-key" style="background-color:#ff607d;"></i><span>{{ $t("dashboard.graphics.bd") }}: {{bd_score}}</span><i class="material-icons"></i></li>
                    <li class="hvr-grow"  v-on:click="toResponse({ $lte: 1 })"><i class="point-key" style="background-color:#ff607d;"></i><span>{{ $t("dashboard.graphics.vb") }}: {{vb_score}}</span><i class="material-icons"></i></li>
                  </ul>
                  <ul v-if="word.question.type === 'nes'">
                    <li class="hvr-grow"  v-on:click="toResponse({ $lte: 40 })"><i class="point-key" style="background-color:#00aaa7 ;"></i><span>{{ $t("dashboard.graphics.le") }}: {{le_score}}</span><i class="material-icons"></i></li>
                    <li class="hvr-grow"  v-on:click="toResponse({ $gte: 40, $lte: 60 })"><i class="point-key" style="background-color:#ffb500 ;"></i><span>{{ $t("dashboard.graphics.ee") }}: {{ee_score}}</span><i class="material-icons"></i></li>
                    <li class="hvr-grow"  v-on:click="toResponse({ $gte: 90 })"><i class="point-key" style="background-color:#ff607d ;"></i><span>{{ $t("dashboard.graphics.me") }}: {{me_score}}</span><i class="material-icons"></i></li>
                  </ul>
                </div>
            </div>
        </app-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bubbles',
  data () {
    return {
      // NPS
      promoter_score: '',
      neutral_score: '',
      detractor_score: '',
      // CSAT 5 EMO
      vu_score: '',
      un_score: '',
      ind_score: '',
      sat_score: '',
      vs_score: '',
      // CSAT NUM
      unsatisfied_score: '',
      satisfied_score: '',
      // KPI YND YN
      y_score: '',
      n_score: '',
      mb_score: '',
      // KPI 5EMO
      vb_score: '',
      bd_score: '',
      st_score: '',
      vg_score: '',
      ex_score: '',
      // NES
      le_score: '',
      ee_score: '',
      me_score: ''
    }
  },
  props: ['word', 'tooltipTag'],
  components: {
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button')
  },
  methods: {
    handle () {
      this.$emit('openTooltipTag', true)
    },
    closeDisplayTag () {
      this.$emit('closeTooltipTag', true)
    },
    toResponse (rating) {
      var q = JSON.parse(this.$route.query.q)
      q.$and.push({ 'responses.text': { $like: this.word.word } })
      q.$and.push({ responses: { $elemMatch: { 'question._id': this.word.question._id, rating: rating } } })
      this.$router.push({ path: '/dashboard/individual', query: { q: JSON.stringify(q) } })
    }
  },
  mounted () {
    this.word.options.forEach(element => {
      switch (element.key) {
        // NPS
        case 'pr':
          this.promoter_score = element.value
          break
        case 'nt':
          this.neutral_score = element.value
          break
        case 'dt':
          this.detractor_score = element.value
          break
          // CSAT 5EMO
        case 'vu':
          this.vu_score = element.value
          break
        case 'un':
          this.un_score = element.value
          break
        case 'ind':
          this.ind_score = element.value
          break
        case 'sat':
          this.sat_score = element.value
          break
        case 'vs':
          this.vs_score = element.value
          break
          // CSAT NUM
        case 'unsatisfied':
          this.unsatisfied_score = element.value
          break
        case 'satisfied':
          this.satisfied_score = element.value
          break
          // KPI 5EMO
        case 'vb':
          this.vb_score = element.value
          break
        case 'bd':
          this.bd_score = element.value
          break
        case 'st':
          this.st_score = element.value
          break
        case 'vg':
          this.vg_score = element.value
          break
        case 'ex':
          this.ex_score = element.value
          break
          // KPI YND YN
        case 'y':
          this.y_score = element.value
          break
        case 'mb':
          this.mb_score = element.value
          break
        case 'n':
          this.n_score = element.value
          break
          // NES
        case 'le':
          this.le_score = element.value
          break
        case 'ee':
          this.ee_score = element.value
          break
        case 'me':
          this.me_score = element.value
          break
      }
    })
  }
}
</script>

<style lang="scss">
.tag-cloud-display{
  width: 260px;
  text-align: left;
  position: absolute;
  z-index: 999;
  top: -33px;
  right: -168px;
  .card{
    width: 100% !important;
    height: auto;
    min-height: 200px;
    .redirect_to_responses{
        margin-top: 40px;
        span{
          display: inline-block;
          vertical-align: middle;
        }
        i{
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
          color: #fff;
        }
    }
  }
}
.tag-cloud-options {
  margin-top: 40px;
  ul{
    li{
      position: relative;
      padding-left: 25px;
      width: 168px;
      margin: 0 auto;
      margin-top: 20px;
      text-align: left;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .point-key{
        position: absolute;
        left: 0;
        width: 15px;
        height: 15px;
        border-radius: 100px;
        background-color:#00aaa7;
        content:'',
      }
    }
  }
}
.tag-cloud-empty {
    width: 100%;
    height: 100%;
    span{
        margin-top: 20px;
        display: block;
        font-size: 24px;
        color: $color-gray-light;
    }
}
.close-tag-cloud-display {
  float: right;
}
</style>
