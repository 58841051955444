<template>
  <div class="container-overtime-media">
    <div class="title-overtime-media">
       <app-heading level="h4">{{ $t('bvs.sentiment_overtime_title') }}</app-heading>
    </div>
    <div class="graphic-overtime-media">
      <div class="select-type">
        <v-select
          class="input-type-select"
          v-model="typeSelected"
          label="label"
          :placeholder="$t('bvs.type_overtime')"
          :reduce="label => label.value"
          :options="typeOptions"
          @input="changeTypeOvertime()"
        ></v-select>
      </div>
      <canvas :id="specialId"></canvas>
    </div>
    <div class="container-loading" v-if="isLoading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <div class="alert-data" v-if="!isLoading && dataAlert">
      <i class="material-icons" style="margin: 0 10px 2px 0">error</i>
      <app-heading level="h5">{{ $t('bvs.no_data') }}</app-heading>
    </div>
  </div>
</template>

<script>
import { socialMediaService } from '@/_services'
import { gridMaker, canvasMaker, formatters } from '@/_helpers'
import('chart.js').then((chartjs) => { window.Chart = chartjs.default })

export default {
  name: 'OvertimeMedia',
  props: [],
  components: {
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    vSelect: () => import('vue-select'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      typeSelected: 'quantity',
      typeOptions: [
        { label: this.$i18n.t('bvs.type_overtime'), value: 'quantity' },
        { label: 'BVS', value: 'bvs' }
      ],
      specialId: '',
      isLoading: false,
      colors: [
        '#309FE5', '#FFE303', '#FE0E46', '#673ab7', '#00d21f',
        '#00B3FF', '#00D21F', '#a87df5', '#fc90a4', '#00aaa7',
        '#673ab7', '#9f95f5', '#FF7663', '#1B9AAA', '#FFC43D'
      ],
      dataChart: null,
      currentChart: null,
      dataAlert: false
    }
  },
  async mounted () {
    this.dataChart = this.$route.query
    this.makeSpecialId()
    this.updateGraphics()
  },
  computed: {
    account () {
      const terms = this.$store.getters['account/getAccount']
      return terms
    },
    lang () {
      return this.$store.getters['account/getLang']
    }
  },
  methods: {
    changeTypeOvertime () {
      this.currentChart.destroy()
      this.updateGraphics()
    },
    async updateGraphics () {
      this.isLoading = true
      if (this.dataChart.startDate <= this.dataChart.endDate) {
        const gridRange = gridMaker.getGridWithouAddTimeZone(this.dataChart.startDate, this.dataChart.endDate)
        const res = await socialMediaService.searchOvertimeMentions({
          gridRange,
          brandId: this.dataChart.terms || '',
          text: this.dataChart.text || '',
          sentiment: this.dataChart.sentiment
        })
        this.dataReq = res

        const lengthTermsAccount = this.account.brandsTracking.length
        if (lengthTermsAccount === this.dataReq.length) {
          this.$store.commit('filtersSocialMedia/isUpdating', false)
          this.$root.$emit('update-done')
        }

        if (res.length > 0) {
          this.renderChart(res)
          this.dataAlert = false
        } else {
          this.isLoading = false
          this.dataAlert = true
        }
      } else {
        return false
      }
    },
    makeSpecialId () {
      this.specialId = canvasMaker.makeCanvasIdWithoutParam()
    },
    async renderChart (data) {
      if (!data) {
        return false
      }

      const end = this.$moment(this.dataChart.endDate)
      const newStart = this.$moment(this.dataChart.startDate)
      const dayAdded = newStart.endOf('day').add(1, 'day')
      let formatDate = 'HH:mm' // esse if é para saber se o filtro é por hora ou dias, com isso formatamos melhor os labels de data do gráfico
      if (end > dayAdded) {
        formatDate = 'MMM Do'
      }

      const fontSize = 8
      const fontFamily = "'Avenir', Helvetica, Arial, sans-serif;"
      const labels = []
      const myDataSets = { datasets: [] }
      const legends = []

      data[0].forEach(i => {
        const time = formatters.formatDateByLangAndFixTimeZone(i.from, this.lang, formatDate)
        labels.push(time)
      })

      data.forEach((term, i) => {
        const color = this.colors[i]
        legends.push(term[0].brand.type ? term[0].brand[term[0].brand.type].name : term[0].brand.name)
        myDataSets.datasets.push({
          label: term[0].brand.type ? term[0].brand[term[0].brand.type].name : term[0].brand.name,
          data: [],
          backgroundColor: 'transparent',
          borderColor: color,
          pointBackgroundColor: color,
          pointBorderColor: color,
          pointHoverBorderColor: color,
          borderWidth: 2
        })

        term.forEach((point, index) => {
          const typeQuantity = this.typeSelected === 'quantity' ? point.total : +(point.bvs.toFixed(1))
          myDataSets.datasets[i].data.push(typeQuantity)
        })
      })

      const datas = {
        labels: labels,
        datasets: myDataSets.datasets
      }

      const options = {
        maintainAspectRatio: false,
        tooltips: {
          titleFontFamily: fontFamily,
          titleFontSize: fontSize,
          position: 'average',
          titleMarginTop: 0,
          footerFontStyle: 'normal'
        },
        animation: {
          duration: 1900
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontFamily: fontFamily,
                fontSize: fontSize
              },
              scaleLabel: {
                display: false
              },
              stacked: false
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
                color: '#fff',
                zeroLineColor: '#fff',
                zeroLineWidth: 0
              },
              ticks: {
                fontFamily: fontFamily,
                fontSize: fontSize,
                min: 0
              },
              stacked: false
            }
          ]
        },
        legend: {
          display: true,
          labels: {
            generateLabels: () => {
              return legends.map((legend, i) => {
                const color = this.colors[i]
                return {
                  text: legend,
                  fillStyle: color,
                  strokeStyle: color
                }
              })
            }
          }
        }
      }

      const ctx = document.getElementById(this.specialId).getContext('2d')

      this.currentChart = await new window.Chart(ctx, {
        type: 'line',
        data: datas,
        options: options
      })

      this.isLoading = false
      return this.currentChart
    }
  }
}
</script>

<style lang="scss">
.container-overtime-media {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 39%;
  padding: 15px;
  border-radius: 10px;
  background: #ffffff70;
  border: 1ps solid #e1e1e1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  .title-overtime-media {
    text-align: center;
  }
  .filter-overtime {
    width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .filter-title {
      margin-right: 15px;
      padding-bottom: 10px;
    }
    div {
      .filter-term, .filter-metric {
        margin-right: 10px;
        height: 30px;
        .vs__dropdown-toggle {
          cursor: pointer;
          background: #00000005;
          border: none;
          .vs__selected-options {
            .vs__search {
              cursor: pointer;
              color: gray;
            }
            .vs__selected {
              margin: 0;
              color: gray !important;
              font-size: 15px;
            }
          }
          .vs__clear {
            display: none;
          }
        }
      }
    }
  }
  .graphic-overtime-media {
    height: 250px;
    padding: 10px;
    .select-type {
      width: 15%;
      .vs__dropdown-toggle {
        cursor: pointer;
        background: #00000005;
        border: none;
        border-bottom: 1px solid #dbdbdb;
        .vs__selected-options {
          .vs__search {
            cursor: pointer;
          }
          .vs__search::placeholder {
            color: gray;
            font-size: 15px;
          }
          .vs__selected {
            margin: 0;
            color: gray !important;
            font-size: 15px;
          }
        }
        .vs__clear {
          display: none;
        }
      }
    }
  }
  .container-loading, .alert-data {
    background: #ffffff;
    color: #151515;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
