<template>
  <div class="container-history-upload">
    <div class="header-history-upload">
      <div class="titles-history-upload">
        <app-heading class="title-history" level="h4">{{ $t('bvs.history.title') }}</app-heading>
        <app-heading class="title-history" level="h7"><span style="font-weight: 600;">{{ totalRegisters }}</span> {{ $t('bvs.history.total_registers') }}</app-heading>
      </div>
      <div class="options-status-history-upload" :style="{'background': `${colors.accent}c7` }">
        <div class="op-status selected" @click="selectStatus('', 0)">{{ $t('bvs.history.all') }}</div>
        <div class="op-status" @click="selectStatus('done', 1)">{{ $t('bvs.history.done') }}</div>
        <div class="op-status" @click="selectStatus('processing', 2)">{{ $t('bvs.history.processing') }}</div>
        <div class="op-status" @click="selectStatus('pending', 3)">{{ $t('bvs.history.pending') }}</div>
        <div class="op-status" @click="selectStatus('error', 4)">{{ $t('bvs.history.error') }}</div>
      </div>
    </div>
    <div class="section-list-history-upload">
      <div class="header-list-upload">
        <app-heading class="title-header-list" level="h7">{{ $t('bvs.history.title_list_user') }}</app-heading>
        <app-heading class="title-header-list" level="h7">{{ $t('bvs.history.title_list_brand') }}</app-heading>
        <app-heading class="title-header-list" level="h7">{{ $t('bvs.history.title_list_date') }}</app-heading>
        <app-heading class="title-header-list" level="h7">{{ $t('bvs.history.title_list_status') }}</app-heading>
        <app-heading class="title-header-list" level="h7">{{ $t('bvs.history.title_list_rows') }}</app-heading>
        <app-heading class="title-header-list" level="h7">{{ $t('bvs.history.title_list_rows_created') }}</app-heading>
      </div>
      <div class="list-upload" :key="key">
        <div class="item-list-upload" v-for="upload in currentPageItems" :key="upload._id">
          <app-heading class="item-list" level="h7">{{ upload.user.name }}</app-heading>
          <app-heading class="item-list" level="h7">{{ upload.brandsTracking.customBrand.name }}</app-heading>
          <app-heading class="item-list" level="h7">{{ moment(upload.createdAt) }}</app-heading>
          <app-heading level="h7" class="item-list">
            <p :class="upload.status" level="h7">{{ $t(`bvs.history.${upload.status}`) }}</p>
          </app-heading>
          <app-heading class="item-list" level="h7">
            <span style="margin-right: 5px">{{ $t('bvs.history.total') }}: <span style="font-weight: 600">{{ formatNumber(upload.rowsQty) }}</span> |</span>
            <span style="margin-right: 5px">{{ $t('bvs.history.done') }}: <span style="font-weight: 600">{{ formatNumber(upload.rowsSucess) }}</span> |</span>
            <span>{{ $t('bvs.history.error') }}: <span style="font-weight: 600">{{ formatNumber(upload.rowsError) }}</span></span>
          </app-heading>
          <app-heading
            class="item-list"
            level="h7">
            <i class="material-icons icon-hover" v-if="upload.lastRowCreated" @mouseover="upload.show = true" @mouseleave="upload.show = false">info</i>
            <transition name="bounce">
              <div class="info-card" v-if="upload.show">
                <CardMention v-if="upload.show" :data="{ ...upload.lastRowCreated, brandsTracking: upload.brandsTracking }" :key="upload._id"/>
              </div>
            </transition>
          </app-heading>
        </div>
      </div>
      <div class="pagination-list-upload">
        <app-paginate :initValue="1" :prevText="'<'" :nextText="'>'" @input="changePage" :pageRange="itemPerPage" :pageCount="dynamicPageCount"></app-paginate>
      </div>
    </div>
    <div class="data-loading-fixed" v-show="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
  </div>
</template>

<script>
import { socialMediaService } from '@/_services'
import { mapGetters } from 'vuex'
import CardMention from './CardMention.vue'

export default {
  name: 'UploadHistory',
  props: [],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-paginate': () => import('@binds-tech/binds-design-system/src/components/Paginate/Paginate'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    CardMention
  },
  data () {
    return {
      key: 0,
      loading: false,
      totalRegisters: 0,
      registers: [],
      page: 1,
      itemPerPage: 11,
      dynamicPageCount: 0
    }
  },
  mounted () {
    this.getListUploads()
  },
  computed: {
    colors () {
      const colors = this.$store.getters['account/getAccount'].theme.colors
      return colors
    },
    ...mapGetters({
      user: 'account/getUser'
    }),
    lang () {
      return this.$store.getters['account/getLang']
    },
    currentPageItems () {
      let filterIndex = this.page
      if (this.page > this.registers.length) {
        filterIndex = 1
      }
      return this.registers.slice((filterIndex * this.itemPerPage) - this.itemPerPage, filterIndex * this.itemPerPage)
    }
  },
  methods: {
    formatNumber (n) {
      return new Intl.NumberFormat('pt-BR').format(n)
    },
    async getListUploads (status) {
      this.loading = true

      const result = await socialMediaService.uploadHistory(status)

      if (result) {
        let showItems = []
        if (this.user.type !== 'superadmin') {
          result.forEach(e => {
            if (e.user.type !== 'superadmin') {
              showItems.push(e)
            }
          })
        } else {
          showItems = result
        }

        showItems.forEach(e => { e.show = false })

        this.key++

        this.registers = showItems
        this.totalRegisters = this.registers.length
      }
      this.updatePagination()
      this.loading = false
    },
    updatePagination () {
      const itemsLength = (this.registers.length / 11)
      if (Number.isInteger(itemsLength)) {
        this.dynamicPageCount = itemsLength
      } else {
        this.dynamicPageCount = Math.trunc(itemsLength) + 1
      }
    },
    moment (eventDate) {
      this.$moment.locale(this.lang)
      return this.$moment(eventDate).format('LLL')
    },
    changePage (page) {
      this.page = page
    },
    selectStatus (status, idx) {
      const classTabs = document.querySelectorAll('.op-status')

      for (const tab of classTabs) {
        tab.classList.remove('selected')
      }

      classTabs[idx].classList.add('selected')

      this.getListUploads(status)
    }
  }
}
</script>

<style lang="scss">
.container-history-upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header-history-upload {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titles-history-upload {
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .title-history {
        color: #454548 !important;
      }
    }
    .options-status-history-upload {
      user-select: none;
      width: 45%;
      height: 33%;
      border: 1px solid var(--accent-color);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .op-status {
        height: 100%;
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid var(--accent-color);
        font-size: 13px;
        color: #FFFFFF;
        cursor: pointer;
      }
      .selected {
        color: #FFFFFF;
        font-weight: bold;
        font-size: 14px;
        background: var(--accent-color);
      }
      .op-status:last-child {
        border: none;
      }
    }
  }
  .section-list-history-upload {
    width: 100%;
    height: 85%;
    background: #F0F2F5;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .header-list-upload {
      width: 100%;
      height: 10%;
      padding: 0 10px;
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title-header-list:last-child {
        width: 11%;
      }
      .title-header-list:nth-child(5) {
        width: 24%;
      }
      .title-header-list {
        color: #454548 !important;
        font-weight: 600 !important;
        width: 16.25%;
        text-align: center;
      }
    }
    .list-upload {
      background: #FFFFFF;
      width: 100%;
      height: 80%;
      display: flex;
      flex-direction: column;
      .item-list-upload {
        width: 100%;
        height: 10%;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e3e3e3;
        position: relative;
        .item-list:last-child {
          width: 11%;
        }
        .item-list:nth-child(5) {
          width: 24%;
        }
        .item-list {
          width: 16.25%;
          display: flex;
          justify-content: center;
          font-size: 13px !important;
          .icon-hover {
            cursor: default;
          }
          .info-card {
            width: 400px;
            background: #ffffff;
            box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.35);
            border-radius: 5px;
            overflow: hidden;
            position: absolute;
            top: -130%;
            right: 7%;
            z-index: 1000;
          }
          .done {
            color: #9FCD35;
            border: 1px solid #9FCD35;
            padding: 2px 9px;
            border-radius: 35px;
          }
          .pending {
            color: #F08F59;
            border: 1px solid #F08F59;
            padding: 2px 9px;
            border-radius: 35px;
          }
          .error {
            color: #EA484D;
            border: 1px solid #EA484D;
            padding: 2px 9px;
            border-radius: 35px;
          }
          .processing {
            color: #F8C43D;
            border: 1px solid #F8C43D;
            padding: 2px 9px;
            border-radius: 35px;
          }
        }
      }
    }
    .pagination-list-upload {
      width: 100%;
      height: 10%;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      .pagination {
        height: 25px;
        .page-link-item, .prev-link-item, .next-link-item {
          padding: 2px 10px !important;
          font-size: 13px;
        }
      }
    }
  }
  .data-loading-fixed {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner {
      margin: 0 !important;
    }
  }
}

.bounce-enter-active {
  animation: bounce-in 0.8s;
}
.bounce-leave-active {
  animation: bounce-in 0.8s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
