<template>
  <div class="container-ticket-individual-card">
    <div class="content-ticket-individual-card" v-if="ticketData">
      <div class="label-ticket-individual-card">
        <span class="title-ticket-individual-card">{{ ticketData.label }}</span>
      </div>
      <div class="label-ticket-individual-card">
        <span>{{ $t('tickets.by') }}:</span>
        <span>{{ ticketData.by }}</span>
      </div>
      <div class="label-ticket-individual-card">
        <span>{{ $t('activity.when') }}:</span>
        <span>{{ $moment(ticketData.date).fromNow() }}</span>
      </div>
      <div v-if="ticketData.category" class="label-ticket-individual-card">
        <span>{{ $t('tickets.category.head_title') }}:</span>
        <span>{{ ticketData.category }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndividualTicketCard',
  props: ['ticketData'],
  components: {},
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.container-ticket-individual-card {
  height: 100%;
  .content-ticket-individual-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 0.3vw;
    .label-ticket-individual-card {
      padding: 0 0.5vw;
      display: flex;
      gap: 0.3vw;
      font-size: 0.7vw;
      color: #5e5e5e;
      line-height: 0.8vw;
      span:last-child {
        flex: 1;
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .title-ticket-individual-card {
        font-size: 0.75vw;
        color: #454548;
      }
    }
  }
}
</style>
