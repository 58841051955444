<template>
  <div class="container-shimmer">
    <div v-for="container in qty" :key="container"  class="shimmer-content" :style="{'height': isHeight ? isHeight : '5rem'}">
      <div class="shimmer"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShimmerLoading',
  props: ['isHeight', 'quantity'],
  data () {
    return {
      qty: 1
    }
  },
  mounted () {
    if (this.quantity) { this.qty = this.quantity }
  }
}
</script>

<style lang="scss" scoped>
.container-shimmer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .shimmer-content {
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    .shimmer {
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, transparent 0%, #e9e9e9 50%, transparent 100%);
      animation: shimmer 1.8s infinite;
    }
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
