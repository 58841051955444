import hash from 'js-md5'
import axios from 'axios'
const secretKey = '3037a427e47cf3fec963'
const publicKey = '376317e03aa6b6ccab3d'
const apiUrl = 'https://api.uploadcare.com'
const headers = {
  Authorization: 'Uploadcare.Simple ' + publicKey + ':' + secretKey,
  Accept: 'application/vnd.uploadcare-v0.5+json'
}

export const uploadcareService = {
  generateHash,
  deleteImage,
  setQrCode
}

function generateHash () {
  const expire = new Date().getTime() * 30
  const toSign = secretKey + expire
  const signature = hash(toSign)

  return {
    expire: expire,
    signature: signature,
    publicKey: publicKey,
    secretKey: secretKey
  }
}

async function setQrCode (file) {
  const params = generateHash()
  const formdata = new FormData()
  formdata.append('blob', file, 'qrcode')
  formdata.append('UPLOADCARE_PUB_KEY', params.publicKey)
  formdata.append('UPLOADCARE_STORE', 'true')
  formdata.append('signature', params.signature)
  formdata.append('expire', params.expire)

  const result = await axios.post('https://upload.uploadcare.com/base/?jsonerrors=1', formdata)
  return result
}

async function deleteImage (uuid) {
  const result = await callApi(apiUrl + '/files/' + uuid + '/storage/', 'delete', '')
  return result
}

/**
 * Call UploadCare project
 *
 * @param {String} url contain the url of requisition
 * @param {String} method contain the method of requisition
 * @param {Object | Null} body contain the body of requisition
 * @returns {Object}
 */
async function callApi (url, method, body) {
  const request = {
    method,
    headers
  }

  if (method !== 'get' && body !== '') {
    request.body = JSON.stringify(body)
  }
  try {
    const result = await fetch(
      url, request
    ).then(r => r.json())
    return result
  } catch (error) {
    console.log('error on uploadcare service', error)
  }
}
