var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-hashtags"},[_c('app-heading',{staticClass:"title-hashtag",attrs:{"level":"h4"}},[_vm._v(_vm._s(_vm.$t('bvs.sentiment_word_cloud_title')))]),(_vm.typeWord === 'word')?_c('div',{staticClass:"section-word"},[_c('vue-word-cloud',{attrs:{"animationDuration":5000,"animationEasing":'ease-in-out',"animationOverlap":0.2,"enterAnimation":'animate__animated animate__bounceIn',"leaveAnimation":'animate__animated animate__bounceOut',"spacing":0.25,"font-size-ratio":5,"words":_vm.currentWords,"color":() => {
          return ['#ffd077', '#3bc4c7', '#3a9eea', '#ff4e69', '#461e47'][
            Math.floor(
              Math.random() *
                ['#ffd077', '#3bc4c7', '#3a9eea', '#ff4e69', '#461e47'].length
            )
          ];
        },"font-family":"Finger Paint"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [[_c('div',{directives:[{name:"title",rawName:"v-title",value:(_vm.fulltexts[props.text]),expression:"fulltexts[props.text]"}],staticStyle:{"cursor":"pointer","text-align":"center","font-family":"Finger Paint"},attrs:{"title-class-name":"social-text-class"},on:{"click":function($event){return _vm.$root.$emit('search-by-clicked-text', props.text)}}},[_vm._v(" "+_vm._s(props.text)+" ")])]]}}],null,false,4005972806)})],1):_vm._e(),(_vm.typeWord === 'list')?_c('div',{staticClass:"section-list"},[_c('div',{staticClass:"title-list-word"},[_c('app-heading',{staticClass:"title-list",attrs:{"level":"h6"}},[_vm._v(_vm._s(_vm.$t('bvs.word_cloud_quote')))]),_c('app-heading',{staticClass:"title-list",attrs:{"level":"h6"}},[_vm._v(_vm._s(_vm.$t('bvs.word_cloud_amount')))]),_c('app-heading',{staticClass:"title-list",attrs:{"level":"h6"}},[_vm._v(_vm._s(_vm.$t('bvs.sentiment_bvs')))])],1),_c('div',{staticClass:"list-word"},_vm._l((_vm.currentWords),function(item,idx){return _c('div',{key:idx,staticClass:"item-list-word",style:({'background': `${_vm.colors.accent}21`})},[_c('p',{staticClass:"name-list"},[_vm._v(_vm._s(item[0]))]),_c('p',{staticClass:"qtd-list"},[_vm._v(_vm._s(item[1].qty))]),_c('i',{staticClass:"material-icons icon-bvs",style:({'color':
              item[1].bvs.toFixed(1) <= 19.9 ? '#ea484d':
              item[1].bvs.toFixed(1) <= 39.9 ? '#EF874C':
              item[1].bvs.toFixed(1) <= 59.9 ? '#f8c43d':
              item[1].bvs.toFixed(1) <= 79.9 ? '#9FCD35':
              '#57AD26'})},[_vm._v(_vm._s(_vm.returnEmotion(item[1].bvs.toFixed(1))))])])}),0)]):_vm._e(),_c('div',{staticClass:"options-word"},[_c('div',{staticClass:"type-word",style:({'color': _vm.typeWord === 'word' ? _vm.colors.accent : 'gray' }),on:{"click":function($event){return _vm.changeType('word')}}},[_c('i',{staticClass:"material-icons icon-word"},[_vm._v("cloud")]),_c('p',[_vm._v(_vm._s(_vm.$t('bvs.word_cloud')))])]),_c('div',{staticClass:"type-list",style:({'color': _vm.typeWord === 'list' ? _vm.colors.accent : 'gray' }),on:{"click":function($event){return _vm.changeType('list')}}},[_c('i',{staticClass:"material-icons icon-word"},[_vm._v("list_alt")]),_c('p',[_vm._v(_vm._s(_vm.$t('bvs.word_cloud_list')))])])]),(_vm.isLoading)?_c('div',{staticClass:"container-loading"},[_c('DoubleBounce',{staticClass:"data-loading-spinner animated fadeIn"})],1):_vm._e(),(!_vm.isLoading && _vm.dataAlert)?_c('div',{staticClass:"alert-data"},[_c('i',{staticClass:"material-icons",staticStyle:{"margin":"0 10px 2px 0"}},[_vm._v("error")]),_c('app-heading',{attrs:{"level":"h5"}},[_vm._v(_vm._s(_vm.$t('bvs.no_data')))])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }