<template>
  <div>
    <div class="loading-approaches" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <Menu :showSubMenu="false"></Menu>
    <div class="new-flow">
      <div class="row">
        <div class="col-xs-12 painel-overview">
          <app-heading light style="margin-left: 13px; margin-bottom: 13px !important;" level="h6">{{ $t("dashboard.survey_overview") }}</app-heading>
          <div class="ml60 row" style="display: flex;">
            <div v-for="(card, index) in cards.stats" v-bind:key="index">
              <app-card class="statsCard col-md-12 col-xs-12" v-if="['bounces', 'softBounces'].indexOf(card.name) < 0">
                <div>
                  <app-heading level="h4" class="text-center mb-1" style="font-weight: 700 !important; color: #333333;">{{ card.value.toLocaleString(lang) }}</app-heading>
                  <app-heading level="h6" class="text-center" style="color: #333333;">{{ $t('dashboard.' + card.name) }}</app-heading>
                </div>
              </app-card>
            </div>
          </div>
          <div class="row bottom-md bottom-xs mt10">
            <div class="col-md-6 col-xs-6">
              <app-heading style="color: #333333;" level="h3">{{ $t("dashboard.recent_surveys") }}</app-heading>
            </div>
            <div class="col-md-6 col-xs-6 see-all-surveys">
              <app-heading level="h6" class="pointer" @click.native="goToSurveyList()">
                {{ $t("dashboard.see_all") }}
              </app-heading>
            </div>
          </div>
          <div class="row start-xl start-lg center-md center-sm">
            <composition-card v-for="(item, index) in questions" :key="index" :card="item"></composition-card>
          </div>
        </div>
      </div>
    </div>
    <theme-style>
      .painel-overview {
        background-color: {{user.account.theme.colors.accent || '#3f3356'}} !important;
      }
      .see-all-surveys h6, .see-all-surveys a, .see-all-surveys a:link, .see-all-surveys a:focus, .see-all-surveysa:active {
        color: {{user.account.theme.colors.primary || '#673ab7'}} !important;
      }
      .painel-overview > h6 {
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#333333' : '#fdfbff' }};
      }
    </theme-style>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import CompositionCard from '../components/cards/CompositionCard.vue'
import { mapGetters } from 'vuex'
import { formatQuestionResult } from '@/_helpers'

import { panelService, userService } from '@/_services'
export default {
  name: 'Panel',
  components: {
    Menu,
    CompositionCard,
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  data () {
    return {
      cards: [],
      questions: [],
      loading: false
    }
  },
  async mounted () {
    this.$nextTick(async () => {
      this.$root.$on('remove-this-composition-card', (surveyId) => {
        const index = this.questions.findIndex(item => item.survey._id === surveyId)
        if (index >= 0) {
          this.questions.splice(index, 1)
        }
      })
    })

    this.loading = true
    const fetchAll = [userService.getMe(), panelService.getPanelStats(), panelService.getQuestionStats()]
    const results = await Promise.all(fetchAll)
    const user = results[0] || {}
    const generalStatus = results[1] || []
    this.questions = results[2] || []

    if (user.type === 'admin' || user.type === 'superadmin' || user.type === 'editor') {
      this.allowedProfile = true
    }

    const needFirst = ['sendings', 'responses']
    const aux = []
    if (generalStatus.stats) {
      Object.keys(generalStatus.stats).forEach(el => {
        if (needFirst.indexOf(el) > -1) {
          aux.unshift({ name: el, value: generalStatus.stats[el] })
          return
        }
        aux.splice(2, 0, { name: el, value: generalStatus.stats[el] })
      })
      this.cards.stats = aux
    }
    this.questions.forEach(item => {
      if (['nps', 'csat'].indexOf(item.firstQuestion.type) >= 0) {
        item.compositionCard = formatQuestionResult.panelStatsForFirstQuestion(item)
      }
    })
    this.loading = false
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    }),
    lang () {
      return this.$store.getters['account/getLang']
    }
  },
  methods: {
    goToSurveyList () {
      this.$router.push({ name: 'SurveysList' })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dash { background-color: $color-bg; }
.painel-overview {
  height: 124px;
  .heading {
    margin-left: 72px;
    margin-top: 33px;
  }
  .statsCard {
    margin-left: 10px;
  }
  .card .heading {
    margin: 0;
  }
}
.see-all-surveys h6 {
  float: right;
  margin-right: 72px;
    & a, a:link, a:focus, a:active {
      text-decoration: none;
    }
}

.recent {
  margin-left: 33px;
}
</style>
