<template>
  <div id="pdf-cloud-word">
    <div v-if="!display" style="text-align:center;margin-top:30px;">
      <app-heading level="h3">{{ $t("dashboard.not_found") }}</app-heading>
    </div>
    <div v-else>
      <section class="content-text-analytics" v-if="origin.length > 0">
        <div class="content-tags-cloud">
          <div class="bubble-word" v-for="(item,index) in words" :key="index">
            <Bubbles
              :idx="index"
              :word="item"
              :key="renderBubbles"
              :qty="qty"
              :tooltipTag="item.openTooltip"
              @openTooltipTag="openTooltip(index)"
              @closeTooltipTag="cleanTooltips()"
              style="cursor: pointer;"
            ></Bubbles>
          </div>
        </div>
      </section>
      <div class="row center-lg center-md center-sm" :class="{ 'empty-words': !exporting }">
        {{$t('analytics.sendings')}}: {{currentSurveyStats.totalSendings.toLocaleString(lang)}} /
        {{$t('analytics.answers')}}: {{currentSurveyStats.totalAnswers.toLocaleString(lang)}} /
        {{$t('analytics.time')}}: {{currentSurveyStats.responseAverageTime}} min
      </div>
    </div>
  </div>
</template>

<script>
import Bubbles from '@/components/analytics/Bubbles.vue'
import('lodash-es').then((lodashEs) => {
  window._ = lodashEs.default
})
export default {
  name: 'CloudWords',
  components: {
    Bubbles,
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  props: ['display', 'words', 'qty', 'origin', 'renderBubbles'],
  data () {
    return {
      spacing: 0.5,
      selectedWord: '',
      type_question: '',
      ui_question: '',
      survey: '',
      exporting: false
    }
  },
  beforeDestroy () {
    this.$root.$off('export-cloud')
  },
  mounted () {
    this.$root.$on('export-cloud', (e) => {
      this.exporting = e
    })
  },
  computed: {
    currentSurveyStats () {
      const stats = this.$store.getters['dashboard/getCurrentSurveyStats']
      return stats
    },
    lang () {
      return this.$store.getters['account/getLang']
    }
  },
  methods: {
    openTooltip (idx) {
      this.cleanTooltips()
      this.words[idx].openTooltip = !this.words[idx].openTooltip
    },
    cleanTooltips () {
      this.words.forEach(o => {
        o.openTooltip = false
      })
    }
  }
}
</script>

<style lang="scss">
.content-text-analytics {
    width: 100%;
    margin: 0 auto;
}
// bubbles
.bubble-word {
    display: inline-block;
    position: relative;
    background-color:rgba(63, 51, 86, 0.7);
    border-radius: 100%;
    margin: 1%;
}
.content-tags-cloud {
  -webkit-transform: scale(0.85);
     -moz-transform: scale(0.85);
      -ms-transform: scale(0.85);
       -o-transform: scale(0.85);
          transform: scale(0.85);
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
}
// END bubbles
@media screen and (max-width: 800px) {
    .content-text-analytics {
        display:block;
        position: relative;
    }
    .tag-cloud-display {
        width: 320px;
        position: absolute;
        left: 50%;
        top:100px;
        margin-left: -160px;
        z-index: 999999999;
        .close-tag-cloud-display {
            display: block;
            position: absolute;
            right: 10px;
            top:10px;
        }
    }
}
.empty-words {
  display: none !important;
}
.bubbles-width {
  width: 1400px !important;
}
</style>
