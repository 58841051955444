<template>
  <div>
    <Menu></Menu>
    <SubMenu :referrals="true"></SubMenu>
    <Referrals v-show="selectedSurveyId && canShowThePage"></Referrals>
    <NoDataToShow v-show="!selectedSurveyId && canShowThePage" title="dashboard.no_responses"></NoDataToShow>
  </div>
</template>

<script>

import Menu from '../components/Menu.vue'
import SubMenu from '../components/dashboard/menu/Grouper.vue'
import Referrals from '../components/dashboard/referrals/Grouper.vue'
import NoDataToShow from '../components/alerts/NoDataToShow.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'Panel',
  components: { Menu, SubMenu, Referrals, NoDataToShow },
  data () {
    return {
      canShowThePage: false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.path !== '/') {
        if (from.name !== to.name && (['Performance', 'Approaches'].indexOf(from.name) > -1)) {
          vm.$store.commit('filters/resetAllFilters')
          vm.$router.push({ path: to.path, query: { q: JSON.stringify({ $and: [] }) } }).catch(() => {})
        }
      }
    })
  },
  computed: {
    ...mapGetters({
      currentSurvey: 'dashboard/getCurrentSurvey'
    }),
    selectedSurveyId: {
      get () {
        return this.$store.getters['dashboard/getSelectedSurveyId']
      }
    }
  },
  mounted () {
    this.$root.$on('search-menu-is-done', () => {
      this.canShowThePage = true
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
