<template>
  <div class="nvs-create">
    <ul>
      <li v-for="(option, idx) in options" :key="idx">
        <app-form-radio class="single-radio"/>
        <app-heading level="h6"
        :style="{'color': colors.splashColor}">{{ option.label }}</app-heading>
      </li>
    </ul>
  </div>
</template>

<script>
import { translator } from '@/_helpers'

export default {
  name: 'app5radio',
  props: ['question', 'colors'],
  components: {
    'app-form-radio': () => import('@binds-tech/binds-design-system/src/components/Form/Radio'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
    }
  },
  created () {
    this.question.options = this.options
  },
  computed: {
    options () {
      const options = [
        { label: this.t('dashboard.graphics.nvs_op1'), rating: 100 },
        { label: this.t('dashboard.graphics.nvs_op2'), rating: 75 },
        { label: this.t('dashboard.graphics.nvs_op3'), rating: 50 },
        { label: this.t('dashboard.graphics.nvs_op4'), rating: 25 },
        { label: this.t('dashboard.graphics.nvs_op5'), rating: 0 }
      ]
      return options
    }
  },
  methods: {
    t (text) {
      return translator.translateThisText(this.question.language, text)
    }
  }
}
</script>

<style lang="scss">
.nvs-create {
  width: 40%;
  ul {
    li {
      display: flex;
      align-items: center;
      height: 25px;
      margin-bottom: 20px;
      .single-radio {
        width: 10% !important;
        margin: 0 !important;
      }
      .heading {
        text-align: left;
        margin-left: 15px;
      }
    }
  }
}
.binds-center-widget {
  .nvs-create {
    width: 73% !important;
    margin-top: 10px !important;
    margin-left: 65px !important;
    ul {
      li {
        .heading {
          margin: 4px 0 0 0px !important;
        }
      }
    }
  }

}
.single-radio::after {
  display: none !important;
}
</style>
