function initialState () {
  return {
    metric: '',
    period: '',
    surveyId: '',
    questionId: '',
    textSearch: '',
    q: '',
    date: {
      startDate: '',
      endDate: ''
    }
  }
}
const state = initialState()

const mutations = {
  resetToInitialState (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  insightField (state, insightField) {
    Object
      .keys(insightField)
      .forEach(key => {
        const value = insightField[key]
        state[key] = value
      })
  },
  updateSurveyId (state, payload) {
    state.surveyId = payload
  },
  updateDate (state, payload) {
    state.date = payload
  },
  updateSentiment (state, payload) {
    state.sentiment = payload
  },
  updateEmotion (state, payload) {
    state.emotion = payload
  },
  updateText (state, payload) {
    state.text = payload
  },
  updateCategory (state, payload) {
    state.category = payload
  },
  updateTheme (state, payload) {
    state.theme = payload
  }
}

const getters = {
  getFilters: state => state
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
