<template>
  <div class="anonymous-section">
    <div class="anonymous-toggle">
      <app-toggle-input v-model="survey.isAnonymous" v-on:change.native="activeAnonymous(survey.isAnonymous)"></app-toggle-input>
      <app-heading level="h6">{{$t('isAnonymous.is_anonymous')}}</app-heading>
    </div>
    <div class="anonymous-toggle mt20" v-if="allowUserData">
      <app-toggle-input v-model="isActive"  v-on:change.native="addConfigQuestion(active)"></app-toggle-input>
      <app-heading level="h6">{{$t('isAnonymous.anonymous_auth')}}</app-heading>
    </div>
  </div>
</template>

<script>
export default {
  name: 'isAnonymous',
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput')
  },
  data () {
    return {
      active: false,
      allowUserData: false
    }
  },
  mounted () {
    this.allowUserData = this.survey.isAnonymous
    const hasActive = this.survey.questions.filter(item => {
      return item.type === 'anonymous'
    })
    this.active = hasActive.length > 0
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    },
    isActive: {
      get () {
        const hasActive = this.survey.questions.filter(item => {
          return item.type === 'anonymous'
        })
        return hasActive.length > 0
      },
      set (value) {
        this.$store.commit('survey/survey', { requireAuthorization: value })
        this.active = value
      }
    },
    user () {
      return this.$store.getters['account/getUser']
    }
  },
  methods: {
    activeAnonymous (value) {
      if (value) {
        this.$store.commit('survey/survey', { isAnonymous: value })
        this.allowUserData = true
      } else {
        this.deleteAnonymousQuestion()
        this.allowUserData = false
      }
    },
    addConfigQuestion (active) {
      const questions = this.survey.questions
      if (active) {
        const newQuestion = {
          order: questions.length + 1,
          label: this.$i18n.t('questionsNav.question_title'),
          question: this.$i18n.t('isAnonymous.anonymous_question'),
          type: 'anonymous',
          ui: 'yn',
          language: this.user.language.toLowerCase(),
          title: this.$i18n.t('isAnonymous.anonymous_title'),
          notificate: [],
          showIfAny: [],
          showIfAll: [],
          ifMetadata: [],
          then: [],
          nextLabel: this.$i18n.t('questionsNav.nextLabel'),
          randomOptions: false,
          options: [],
          minLength: 0,
          maxLength: 0,
          required: true,
          customTitle: false,
          icon: 'insert_chart'
        }
        questions.unshift(newQuestion)
        this.$store.commit('survey/survey', { questions: questions })
      } else {
        this.deleteAnonymousQuestion()
      }
    },
    deleteAnonymousQuestion () {
      const questions = this.survey.questions
      const questionsWithoutAnonymous = questions.filter(item => {
        return item.type !== 'anonymous'
      })
      this.$store.commit('survey/survey', { questions: questionsWithoutAnonymous, questionSelected: 0 })
      this.active = false
      this.$root.$emit('showing-question')
    }
  }
}
</script>

<style lang="scss">
.anonymous-section {
  padding: 20px 0;
}
.anonymous-toggle {
  display: flex;
  & h6 {
    font-size: 14px !important;
    margin-top: 2px !important;
    margin-left: 45px !important;
  }
}
</style>
