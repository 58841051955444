<template>
  <div>
    <div v-if="firstBubble.value" style="padding-top: 50px;">
      <div style="text-align: -webkit-center">
        <div class="shadowLeft lastBubble " :style="{'background-color': cardData.colors[1]}"></div>
        <div class="shadowRight topBubble" :style="{'background-color': cardData.colors[2]}"></div>
        <div class="shadowLeft bottomBubble" :style="{'background-color': cardData.colors[3]}"></div>
        <div class="shadowRight middleBubble" :style="{'background-color': cardData.colors[4]}">
        </div>
        <div class="firstBubble" :style="{'background-color': cardData.colors[0]}">
          <p class="shadowRight">
            <app-tooltip hover>
              <span>{{firstBubble.key}}</span>
              <template v-slot:activator>
                {{firstBubble.key}}
              </template>
            </app-tooltip>
          </p>

        </div>

      </div>
    </div>
    <div class="resultMessage" v-if="firstBubble.value">
      {{$i18n.t('dashboard.most_choosen.1')}} {{parseInt(firstBubble.percentage)}}% {{$i18n.t('dashboard.most_choosen.2')}}
    </div>
  </div>
</template>
<script>
/* eslint-disable */

export default {
  name: 'NumUi',
  props: ['data', 'cardData'],
  components: {
    'app-tooltip': () => import('@binds-tech/binds-design-system/src/components/Tooltip/Tooltip')
  },
  data () {
    return {
      firstBubble: {
        value: 0,
        key: '70',
        percentage: 50
      },
      middleBubble: {
        key: '',
        value: 0
      },
      lastBubble: {
        key: '',
        value: 0
      }
    }
  },
  mounted () {
    if (this.data.options) {
      this.data.options.forEach(value => {
        // dança das cadeiras, basicamente
        if (value.doc_count > this.firstBubble.value) {
          this.lastBubble.value = this.middleBubble.value
          this.lastBubble.key = this.middleBubble.key

          this.middleBubble.value = this.firstBubble.value
          this.middleBubble.key = this.firstBubble.key

          this.firstBubble.value = value.doc_count
          this.firstBubble.key = value.key
          this.firstBubble.percentage = value.percentage
        } else if (value.doc_count <= this.firstBubble.value && value.doc_count > this.lastBubble.value && value.doc_count >= this.middleBubble.value) {
          this.lastBubble.value = this.middleBubble.value
          this.lastBubble.key = this.middleBubble.key

          this.middleBubble.value = value.doc_count
          this.middleBubble.key = value.key
        } else {
          this.lastBubble.value = value.doc_count
          this.lastBubble.key = value.key
        }
      });
    }
  }
}
</script>
<style scoped>
.resultMessage {
  margin-top: 213px;
  font-family: inherit;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  letter-spacing: normal;
  color: #080808;
  padding-bottom: 11px;
}
.bottomBubble {
  margin-top: 5%;
  position: absolute;
  border-radius: 87px;
  top: 25%;
  left: 26%;
  height: 160px;
  width: 160px;
  /* background-color: #b7a3cd; */
}
.topBubble {
  margin-top: 5%;
  position: absolute;
  border-radius: 87px;
  top: 16%;
  left: 37%;
  height: 160px;
  width: 160px;
  /* background-color: #b7a3cd; */
}
.lastBubble {
  left: 25%;
  width: 110px;
  margin-top: 5%;
  top: 23%;
  position: absolute;
  height: 110px;
  /* background-color: #a079ce; */
  border-radius: 87px;
  z-index: 9;
}
.middleBubble {
  right: 19%;
  width: 110px;
  margin-top: 5%;
  top: 32%;
  position: absolute;
  height: 110px;
  /* background-color: #a079ce; */
  border-radius: 87px;
}
.firstBubble {
  width: 160px;
  height: 160px;
  right: 27%;
  top: 25%;
  /* background-color: #7f55ae; */
  border-radius: 87px;
  position: absolute;
  z-index: 10;
}
.lastBubble > p {
  position: absolute;
  top: 38%;
  left: 1px;
  font-size: 15px;
  padding: 0;
  margin: 0;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  width: 77px;
}
.middleBubble > p {
  position: absolute;
  font-size: 20px;
  top: 40px;
  left: 1px;
  padding: 0;
  margin: 0;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  width: 97px;
}
.firstBubble > p {
  position: absolute;
  font-size: 2.2em;
  top: 42%;
  left: 14%;
  padding: 0;
  margin: 0;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  width: 115px;
}
.shadowRight {
  box-shadow: 14px 10px 64px -31px rgb(0, 0, 0);
}
.shadowLeft {
  box-shadow: -18px 7px 64px -31px rgb(0, 0, 0);
}
</style>