import i18n from '../../translations/i18n'

function initialState () {
  return {
    surveys: [],
    questions: {},
    selectedSurvey: null,
    currentSurvey: null,
    currentPage: 'average',
    currentSurveyStats: {},
    subMenu: {
      tabSelected: { index: 0, label: 'overview' }
    },
    filter: {
      surveyId: 0,
      surveyTitlte: '',
      dateFrom: '',
      dateTo: ''
    },
    customizationSettings: {
      name: '',
      graphicType: '',
      attribute: '',
      viewType: '',
      colors: '',
      textColor: '',
      question: '',
      survey: ''
    },
    graphicTypes: [
    // CSAT - Start
      {
        type: 'csat',
        ui: '5emo',
        charts: [
          { name: i18n.t('dashboard.graphics.gauge'), graphicType: 'emoteGauge', attribute: 'int', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.percentage_bar'), graphicType: 'percentageBar', attribute: 'percent', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.lines'), graphicType: 'line', attribute: 'multiple', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      {
        type: 'csat',
        ui: '5num',
        charts: [
          { name: i18n.t('dashboard.graphics.gauge'), graphicType: 'rangeGauge', attribute: 'int', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.lines'), graphicType: 'line', attribute: 'multiple', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      {
        type: 'csat',
        ui: '10num',
        charts: [
          { name: i18n.t('dashboard.graphics.gauge'), graphicType: 'rangeGauge', attribute: 'int', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.lines'), graphicType: 'line', attribute: 'multiple', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      // CSAT - End
      // NPS - Start
      {
        type: 'nps',
        ui: '10num',
        charts: [
          { name: i18n.t('dashboard.graphics.gauge'), graphicType: 'rangeGauge', attribute: 'int', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.single_line'), graphicType: 'line', attribute: 'single', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      {
        type: 'nps',
        ui: 'ynm',
        charts: [
          { name: i18n.t('dashboard.graphics.gauge'), graphicType: 'rangeGauge', attribute: 'int', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.bubbles'), graphicType: 'kpiYnd', attribute: 'bubbles', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.single_line'), graphicType: 'line', attribute: 'single', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      // NPS - End
      // KPI - Start
      {
        type: 'kpi',
        ui: 'yn',
        charts: [
          { name: i18n.t('dashboard.graphics.bubbles'), graphicType: 'kpiYn', attribute: 'bubbles', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.percentage_bar'), graphicType: 'percentageBar', attribute: 'percent', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.lines'), graphicType: 'line', attribute: 'multiple', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      {
        type: 'kpi',
        ui: 'ynd',
        charts: [
          { name: i18n.t('dashboard.graphics.bubbles'), graphicType: 'kpiYnd', attribute: 'bubbles', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.percentage_bar'), graphicType: 'percentageBar', attribute: 'percent', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.lines'), graphicType: 'lines', attribute: 'multiple', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      {
        type: 'kpi',
        ui: '5emo',
        charts: [
          { name: i18n.t('dashboard.graphics.gauge'), graphicType: 'emoteGauge', attribute: 'int', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.percentage_bar'), graphicType: 'percentageBar', attribute: 'percent', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.lines'), graphicType: 'line', attribute: 'multiple', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      // KPI - End
      // CES - Start
      {
        type: 'nes',
        ui: '10num',
        charts: [
          { name: i18n.t('dashboard.graphics.gauge'), graphicType: 'rangeGauge', attribute: 'int', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.single_line'), graphicType: 'line', attribute: 'single', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      {
        type: 'ces',
        ui: '1to7',
        charts: [
          { name: i18n.t('dashboard.graphics.gauge'), graphicType: 'rangeGauge', attribute: 'int', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.single_line'), graphicType: 'line', attribute: 'single', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },

      {
        type: 'ces',
        ui: '1to7button',
        charts: [
          { name: i18n.t('dashboard.graphics.gauge'), graphicType: 'rangeGauge', attribute: 'int', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.single_line'), graphicType: 'line', attribute: 'single', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      // CES - End

      // NVS - 5RADIO
      {
        type: 'nvs',
        ui: '5radio',
        charts: [
          { name: i18n.t('dashboard.graphics.gauge'), graphicType: 'rangeGauge', attribute: 'int', viewType: 'average' },
          { name: i18n.t('dashboard.graphics.single_line'), graphicType: 'line', attribute: 'single', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      // NVS - END
      // MATRIX
      {
        type: 'matrix',
        ui: 'matrix1to5',
        charts: [
          { name: i18n.t('dashboard.graphics.stacked'), graphicType: 'stackedBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.online_distribution'), graphicType: 'likeOvertime', attribute: 'int', viewType: 'overtime' }
        ]
      },
      {
        type: 'matrix',
        ui: '10num',
        charts: [
          { name: i18n.t('dashboard.graphics.stacked'), graphicType: 'stackedBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.online_distribution'), graphicType: 'likeOvertime', attribute: 'int', viewType: 'overtime' }
        ]
      },
      {
        type: 'matrix',
        ui: 'like',
        charts: [
          { name: i18n.t('dashboard.graphics.percentage_distribution'), graphicType: 'likeOverall', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.online_distribution'), graphicType: 'likeOvertime', attribute: 'int', viewType: 'overtime' }
        ]
      },
      // MATRIX - end
      // ENUM - Start
      {
        type: 'enum',
        ui: 'single',
        charts: [
          { name: i18n.t('dashboard.graphics.lines'), graphicType: 'line', attribute: 'multiple', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      {
        type: 'enum',
        ui: 'singleother',
        charts: [
          { name: i18n.t('dashboard.graphics.lines'), graphicType: 'line', attribute: 'multiple', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      {
        type: 'enum',
        ui: 'multiple',
        charts: [
          { name: i18n.t('dashboard.graphics.lines'), graphicType: 'line', attribute: 'multiple', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      {
        type: 'enum',
        ui: 'multipleother',
        charts: [
          { name: i18n.t('dashboard.graphics.lines'), graphicType: 'line', attribute: 'multiple', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      {
        type: 'enum',
        ui: '0to5',
        charts: [
          { name: i18n.t('dashboard.graphics.lines'), graphicType: 'line', attribute: 'multiple', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      {
        type: 'enum',
        ui: '5num',
        charts: [
          { name: i18n.t('dashboard.graphics.lines'), graphicType: 'line', attribute: 'multiple', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      {
        type: 'enum',
        ui: '10num',
        charts: [
          { name: i18n.t('dashboard.graphics.lines'), graphicType: 'line', attribute: 'multiple', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      },
      {
        type: 'enum',
        ui: '1to10',
        charts: [
          { name: i18n.t('dashboard.graphics.lines'), graphicType: 'line', attribute: 'multiple', viewType: 'overtime' },
          { name: i18n.t('dashboard.graphics.horizontal_bar'), graphicType: 'horizontalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.vertical_bar'), graphicType: 'verticalBar', icon: 'verticalBar', attribute: 'int', viewType: 'overall' },
          { name: i18n.t('dashboard.graphics.pie'), graphicType: 'pie', attribute: 'int', viewType: 'overall' }
        ]
      }
    // ENUM - End
    ],
    realtime: {
      view: 'average',
      interval: null
    }
  }
}

const state = initialState

const mutations = {
  resetToInitialState (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  updateSelected (state, tabSelected) {
    Object
      .keys(tabSelected)
      .forEach(key => {
        const value = tabSelected[key]
        state.subMenu.tabSelected[key] = value
      })
  },
  updateSelectedSurveyId (state, surveyId) {
    state.selectedSurvey = surveyId
  },
  updateSurveys (state, arrayOfSurveys) {
    state.surveys = arrayOfSurveys
  },
  updateCurrentSurvey (state, survey) {
    state.currentSurvey = survey
  },
  updateCurrentSurveyStats (state, stats) {
    state.currentSurveyStats = stats
  },
  updateCustomizationSettings (state, settings) {
    Object
      .keys(settings)
      .forEach(key => {
        const value = settings[key]
        state[key] = value
      })
  },
  updateRealTimeView (state, view) {
    state.realtime.view = view
  },
  updateRealTimeInterval (state, interval) {
    state.realtime.interval = interval
  },
  updateCurrentPage (state, page) {
    state.currentPage = page
  }
}

const getters = {
  getCurrentPage: state => state.currentPage,
  getSelectedSurveyId: state => state.selectedSurvey,
  getAllSurveys: state => state.surveys,
  getFilter: state => state.filter,
  getQuestions: state => state.questions,
  getCurrentSurvey: state => state.currentSurvey,
  getCurrentSurveyStats: state => state.currentSurveyStats,
  getSubMenu: state => state.subMenu.tabSelected,
  getGraphicTypes: state => state.graphicTypes,
  getCustomizationSettings: state => state.customizationSettings,
  getRealTimeView: state => state.realtime.view,
  getRealTimeInterval: state => state.realtime.interval
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
