<template>
  <div class="container-text-list">
    <div class="section-card-text-list">
      <CardText v-for="item in dataList" :key="item._id" :textData="item" :notShowTags="notShowTags" :tags="tags" />
    </div>
    <i class="material-icons icon-read-more" v-title="$t('tags.see_more')" @click="modalIsOpen = true">read_more</i>
    <TextModalList v-if="modalIsOpen" :cardData="cardData" :question="question" :notShowTags="notShowTags" @close-modal-text-list="modalIsOpen = false" />
  </div>
</template>

<script>
import { tagsService } from '@/_services'
import CardText from '../../inputs/CardText.vue'
import TextModalList from '../charts/TextModalList.vue'

export default {
  name: 'TextList',
  props: ['data', 'cardData', 'question', 'notShowTags'],
  components: {
    CardText,
    TextModalList
  },
  data () {
    return {
      tags: [],
      dataList: [],
      modalIsOpen: false,
      showTagManager: false,
      showCreateOption: false
    }
  },
  mounted () {
    this.formatData()
  },
  computed: {},
  methods: {
    async formatData () {
      this.dataList = this.data.slice(0, 20)

      if (this.notShowTags) return

      try {
        const tags = await tagsService.getTags()
        this.tags = tags
        this.data.map(item => { item.tagInput = '' })
      } catch (error) {
        console.error('Error fetching tag data:', error)
      }
    }
  }
}
</script>

<style lang="scss">
.container-text-list {
  width: 100%;
  height: 100%;
  padding: 0.3vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5vw;
  .section-card-text-list {
    width: 100%;
    height: 90%;
    overflow: scroll;
  }
  .icon-read-more {
    height: 10%;
    cursor: pointer;
    font-size: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--accent-color);
  }
}
</style>
