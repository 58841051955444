<template>
  <div class="container">
    <div class="loading-survey-list" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <Menu :showSubMenu="false"></Menu>
    <div class="users-container">
      <div class="users-content">
        <div class="header">
          <div class="header-title">
            <app-heading level="h3">{{ $t('pageUsers.users') }}</app-heading>
            <app-heading level="h6" class="users-total">{{ users.length}} {{ $t('pageUsers.users') }}</app-heading>
          </div>
          <div class="header-button">
            <app-button class="button-add-user" v-on:click="newUser()" v-if="account.type === 'admin' || account.type ==='superadmin'">+ {{ $t('pageUsers.new_user') }}</app-button>
          </div>
        </div>
        <div class="users-filter">
          <app-form-input v-model="search" @input="updatePagination()" :placeholder="$t('pageUsers.search_by_name_email')"/>
          <i class="material-icons export-user" @click="modalExportShow = !modalExportShow" v-title="$t('export.user')">cloud_download</i>
        </div>
        <div class="users-list">
          <div class="header-list">
            <ul>
              <li></li>
              <li>{{ $t('pageUsers.name') }}</li>
              <li>{{ $t('pageUsers.type_user') }}</li>
              <li>{{ $t('pageUsers.email') }}</li>
              <li>{{ $t('pageUsers.phone') }}</li>
              <li>{{ $t('pageUsers.created_at') }}</li>
              <li>{{ $t('pageUsers.activity_at') }}</li>
              <li></li>
            </ul>
          </div>
          <div class="users" v-for="(user, idx) in currentPageItems" :key="idx">
            <ul>
              <li>
                <img :src="user.image && user.image.value ? user.image.value : userConstants.USER_DEFAULT_IMAGE" alt="user-image">
              </li>
              <li>{{ user.name }}</li>
              <li>{{ user.type === 'user' ? $t('pageUsers.user') : user.type === 'admin' ? $t('pageUsers.admin') : user.type }}</li>
              <li>{{ user.email }}</li>
              <li>{{ user.tel ? user.tel : '-' }}</li>
              <li>{{ moment(user.createdAt) }}</li>
              <li>{{ user.lastActivityAt ? moment(user.lastActivityAt) : '-'}}</li>
              <li>
                <div class="btn-details" v-on:click="sideBarUser(user)">
                  <app-icon name="edit" v-title="$t('pageUsers.editUser')" />
                </div>
                <div class="btn-details" @click="showDeleteModal = true; userToBeDeleted = user">
                  <app-icon name="trash" size="xs" color="white" v-title="$t('pageUsers.delete_user')" />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="users-paginate">
          <app-paginate :initValue="1" :prevText="'<'" :nextText="'>'" @input="changePage" :pageRange="userPerPage" :pageCount="dynamicPageCount"></app-paginate>
        </div>
      </div>
      <div class="modal-export-user" v-if="modalExportShow">
        <div class="section-export">
          <div class="header-export">
            <app-heading level="h4">{{ $t('export.user') }}</app-heading>
          </div>
          <div class="options-export" :style="{'--color': account.account.theme.colors.accent || '#ff607d'}">
            <!-- tipo do arquivo -->
            <div class="file-type-badge" @click="options.format = 'xlsx'" :class="{'file-type-badge-active': options.format === 'xlsx'}">
              <i class="material-icons mobileFilter" style="font-size: 1.8em;">text_snippet</i>
              <span style="font-size: 0.6em;color: #000000;">EXCEL</span>
            </div>
            <div class="file-type-badge" @click="options.format = 'xml'" :class="{'file-type-badge-active': options.format === 'xml'}">
              <i class="material-icons mobileFilter" style="font-size: 1.8em;">text_snippet</i>
              <span style="font-size: 0.6em;color: #000000;">XML</span>
            </div>
            <div class="file-type-badge" @click="options.format = 'csv'" :class="{'file-type-badge-active': options.format === 'csv'}">
              <i class="material-icons mobileFilter" style="font-size: 1.8em;">text_snippet</i>
              <span style="font-size: 0.6em;color: #000000;">CSV</span>
            </div>
          </div>
          <div class="buttons-export">
            <app-button class="button-add-user export" @click="modalExportShow = !modalExportShow">{{ $t('export.cancel') }}</app-button>
            <app-button class="button-add-user export" @click="exportFile()">{{ $t('export.title') }}</app-button>
          </div>
        </div>
      </div>
    </div>
    <side-bar-user v-if="openSideBar" :user="activeUser" :roles="roles" :groups="groups" :showButtonDelete="showButtonDelete" @closeSideBarRoles="openSideBar=false" @newUser="addNewUser($event)"/>
    <app-modal
      v-model="showDeleteModal"
      :title="this.$t('account_config.delete_user')"
      lightbox
      close
      class="modal-default-primary"
      :primary-button="this.$t('account_config.delete_confirm.yes')"
      @primaryButtonClick="deleteUser()"
      :secondary-button="this.$t('account_config.delete_confirm.no')"
      @secondaryButtonClick="showDeleteModal = false"
    ></app-modal>
    <theme-style>
      .header-export h4 {
        color:{{ (account.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      }
      .header-export {
        background: {{account.account.theme.colors.accent || '#673ab7'}} !important;
      }
      .button-add-user, .user-details {
        background: {{account.account.theme.colors.accent || '#673ab7'}} !important;
        color:{{ (account.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      }
      .b-icon {
        color: {{account.account.theme.colors.accent || '#673ab7'}} !important;
      }
    </theme-style>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import SideBarUser from '../components/SideBarConfig/SideBarUser.vue'
import { accountService, roleService, exportService } from '@/_services'
import { constants } from '@/_helpers'
export default {
  name: 'Users',
  components: {
    Menu,
    SideBarUser,
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-icon': () => import('@binds-tech/binds-design-system/src/components/Icon/Icon'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-paginate': () => import('@binds-tech/binds-design-system/src/components/Paginate/Paginate'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal')
  },
  data () {
    return {
      users: [],
      groups: [],
      activeUser: '',
      search: '',
      page: 1,
      userPerPage: 20,
      dynamicPageCount: 0,
      openSideBar: false,
      loading: false,
      showButtonDelete: true,
      modalExportShow: false,
      showDeleteModal: false,
      userToBeDeleted: '',
      options: {
        tz: 'utc',
        format: 'xlsx'
      }
    }
  },
  computed: {
    account () {
      const user = this.$store.getters['account/getUser']
      return user
    },
    lang () {
      return this.$store.getters['account/getLang']
    },
    roles () {
      let roles = [
        { type: 'user', label: this.$t('pageUsers.user'), value: false },
        { type: 'admin', label: this.$t('pageUsers.admin'), value: false }
      ]
      if (this.account.type === 'superadmin') {
        roles.push({ type: 'superadmin', label: 'superadmin', value: false })
      } else if (this.account.type === 'user') {
        roles = []
      }
      return roles
    },
    filteredUser () {
      const filtered = this.users.filter((user) => {
        return user.name.toLowerCase().includes(this.search.toLowerCase()) || user.email.toLowerCase().includes(this.search)
      })
      return filtered
    },
    currentPageItems () {
      let filterIndex = this.page
      if (this.page > this.filteredUser.length) {
        filterIndex = 1
      }
      return this.filteredUser.slice((filterIndex * this.userPerPage) - this.userPerPage, filterIndex * this.userPerPage)
    },
    userConstants () {
      return constants.user
    }
  },
  methods: {
    async deleteUser () {
      const result = await accountService.deleteUser(this.userToBeDeleted?._id)
      let message = ''
      if (result) {
        message = this.$t('account_config.user_deleted')
      } else {
        message = this.$t('account_config.error_delete')
      }
      this.$store.commit('alerts/alert', {
        message: message,
        position: 'bottomRight',
        showAlert: true
      })
      this.showDeleteModal = false
      this.userToBeDeleted = ''
      await this.getUsers()
    },
    async sideBarUser (item) {
      this.loading = true
      const user = await accountService.getUser(item._id)
      this.activeUser = user
      if (['admin', 'superadmin'].indexOf(this.account.type) !== -1) {
        this.account._id === item._id ? this.showButtonDelete = false : this.showButtonDelete = true
      } else {
        this.showButtonDelete = false
      }
      this.openSideBar = true
      this.loading = false
    },
    async ValueChanged (e, item) {
      const user = await accountService.getUser(item._id)
      user.type = e
      await accountService.updateUser(user)
      this.$store.commit('alerts/alert', {
        message: this.$t('account_config.user_saved'),
        position: 'bottomRight',
        showAlert: true
      })
    },
    newUser () {
      this.openSideBar = true
      this.activeUser = {
        language: 'pt-BR',
        type: 'user',
        roles: []
      }
    },
    addNewUser (e) {
      this.users.push(e)
    },
    updatePagination () {
      const usersLength = (this.filteredUser.length / 20)
      if (Number.isInteger(usersLength)) {
        this.dynamicPageCount = usersLength
      } else {
        this.dynamicPageCount = Math.trunc(usersLength) + 1
      }
    },
    changePage (page) {
      this.page = page
      window.scrollTo({ top: 200, behavior: 'smooth' })
    },
    moment (eventDate) {
      this.$moment.locale(this.lang)
      return this.$moment(eventDate).format('L')
    },
    async exportFile () {
      this.loading = true

      const url = `${process.env.VUE_APP_URL}users.${this.options.format}`

      const signedUrl = await exportService.signWithRedirectRoute({ url: url })
      if (signedUrl) {
        window.location.assign(signedUrl.url)
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('export.download'),
          position: 'bottomRight',
          showAlert: true
        })
        this.modalExportShow = false
      }
      this.loading = false
    },
    async getUsers () {
      const usersList = await accountService.getUsers()
      this.users = usersList
    }
  },
  async mounted () {
    this.loading = true
    await this.getUsers()

    if (this.account.type === 'user') {
      const self = this
      this.users = this.users.filter(function (i) {
        return i.name === self.account.name
      })
    }

    this.$root.$on('update-users-page', async () => {
      await this.getUsers()
    })

    this.groups = await roleService.getRoles()
    this.updatePagination()
    this.loading = false
  },
  beforeDestroy () {
    this.$root.$off('update-users-page')
  }
}
</script>
<style lang="scss">
* {
  box-sizing: border-box;
}
.loading-survey-list {
  background: #EFF3F840;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner-loading-survey-list {
    width: 2.1vw !important;
    height: 2.1vw !important;
    border-width: 3px !important;
    border-radius: 35px;
    border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
  }
}
.users-container {
  width: 100vw;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .users-content {
    width: 95%;
    height: 95%;
    .header {
      width: 100%;
      height: 15%;
      border-bottom: 1px solid #cacaca;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .users-total {
        color: #808080 !important;
        font-size: 14px;
        margin: 10px 0 0 5px;
      }
      .button-add-user {
        font-size: 13px;
        padding: 8px 15px;
      }
    }
    .users-filter {
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text-field {
        width: 20%;
      .text-field__input {
        background: transparent;
        border: none !important;
        border-bottom: 1px solid #cacaca !important;
        input {
          font-size: 16px;
          font-weight: 400;
          height: 40px;
          outline: none;
          padding: 0 0 0 5px;
        }
      }
      }
      .export-user {
        font-size: 27px;
        color: gray;
        margin-right: 5px;
        cursor: pointer;
      }
    }
    .users-list {
      width: 100%;
      height: 70%;
      position: relative;
      border-radius: 5px;
      overflow-x: hidden;
      background: #fff;
      border: 1px solid #cacaca;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      .header-list {
        font-size: 18px;
        font-weight: 600;
      }
      .users {
        color: #424242;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
      .header-list, .users {
        width: 100%;
        height: 10%;
        border-bottom: 1px solid #cacaca;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          li {
            width: 13%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: rgb(63, 51, 86);
            .btn-details {
              cursor: pointer;
              padding: 5px;
              .b-icon {
                font-size: 18px;
                margin-right: 25px;
              }
            }
          }
          li:nth-child(1) {
            padding: 0;
            margin: 0 20px 0 20px;
            width: 5%;
            justify-content: center;
          }
          li:nth-child(4) {
            width: 17%;
          }
        }
      }
    }
    .users-paginate {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }
  }
}
.modal-export-user {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #80808069;
  .section-export {
    overflow: hidden;
    width: 400px;
    height: 250px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    .header-export {
      width: 100%;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .options-export {
      width: 100%;
      height: 105px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .file-type-badge {
        border: 4px solid transparent;
        display: inline-grid;
        text-align: center;
        padding: 10px 14px;
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        .mobileFilter {
          color: #000000 !important;
        }
      }
      .file-type-badge:hover, .file-type-badge-active  {
        border-color: var(--color) !important;
        cursor: pointer;
        & .material-icons {
          color: var(--color) !important;
        }
        & span {
          color: var(--color) !important;
        }
      }
    }
    .buttons-export {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .button-add-user.export {
        box-shadow: none;
        margin: 0;
        height: 40px;
        div {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
