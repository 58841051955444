<template>
  <div>
    <app-modal
      close
      v-model="showModal"
      lightbox
      class="modal-default-primary modal-conditions"
      v-on:keyup.esc="cancel()"
    >
      <div class="row">
        <div v-for="(question, index) in survey.questions" :key="index">
          <div
            class="nav-card-conditions"
            @click="selectQuestionForCondition(question)"
            v-if="(index < survey.questionSelected) && (forbiddenTypes.indexOf(question.type) < 0 && forbiddenUis.indexOf(question.ui) < 0)">
            <div>
              <app-heading class="question-order">
                {{ index + 1 }}
              </app-heading>
              <div class="head-questions-prev">
                <app-heading level="h6" class="question-nav-title">
                  {{ question.label || $t('questionsNav.question_title') }}
                </app-heading>
                <app-heading level="h6" class="question-nav-text">
                  {{ question.question }}
                </app-heading>
              </div>
            </div>
            <component
              class="transform-ui-c"
              v-bind:is="renderUi(question.ui, question.type, question.options)"
              :question="question"
              :colors="componentsColors"
              :preview="true"
              :class="{
                't-5emo-c': question.ui.toLowerCase() === '5emo',
                't-stars-c': question.ui.toLowerCase() === '5num' && (question.stars && question.stars.active),
                't-5num-c': question.ui.toLowerCase() === '5num' && (!question.stars || !question.stars.active),
                't-5nes-c': (question.ui.toLowerCase() === '5nes' || question.ui.toLowerCase() === '10num') && question.type === 'nes',
                't-yn-c': question.ui.toLowerCase() === 'yn',
                't-ynm-c': question.ui === 'ynm',
                't-ynd-c': question.ui.toLowerCase() === 'ynd',
                't-text-c': question.ui.toLowerCase() === 'text',
                't-inputext-c': question.ui.toLowerCase() === 'inputext',
                't-referral-c': question.ui.toLowerCase() === 'referral',
                't-referraldynamic-c': question.ui.toLowerCase() === 'referraldynamic',
                't-single-c': question.ui.toLowerCase() === 'single' || question.ui === 'singleOther',
                't-multiple-c': question.ui.toLowerCase() === 'multiple' || question.ui === 'multipleOther',
                't-0to5-c': question.options.length === 6 && question.ui === '10num',
                't-1to5-c': question.options.length === 5 && question.ui === '10num' && question.type !== 'nes',
                't-0to10-c': ((question.options.length === 11 && question.ui === '10num') || (question.options.length === 14 && question.ui === '10num')) && question.type !== 'matrix',
                't-nps-c' : question.type === 'nps' && question.ui === '10num',
                't-1to10-c': question.options.length === 10 && question.ui === '10num',
                't-1to7-c': question.type === 'ces' && question.ui === '1to7',
                't-1to7button-c': question.type === 'ces' && question.ui === '1to7button',
                't-matrix1to5-c': question.ui.toLowerCase() === 'matrix1to5',
                't-matrix10num-c': question.ui.toLowerCase() === '10num' && question.type === 'matrix',
                't-5radio-c': question.ui.toLowerCase() === '5radio'
              }"
            >
            </component>
          </div>
        </div>
      </div>
    </app-modal>
  </div>
</template>

<script>
import app10num from './answersType/10num.vue'
import app5num from './answersType/5num.vue'
import app5nes from './answersType/5nes.vue'
import appyn from './answersType/kpi-yn.vue'
import appynm from './answersType/kpi-ynm.vue'
import appynd from './answersType/kpi-ynd.vue'
import app5emo from './answersType/5emo.vue'
import apptext from './answersType/text.vue'
import appinputext from './answersType/input-text.vue'
import appreferral from './answersType/referral.vue'
import appmultiple from './answersType/multiple.vue'
import app0to5 from './answersType/0to5.vue'
import app1to5 from './answersType/1to5.vue'
import app1to7 from './answersType/1to7.vue'
import app1to7button from './answersType/1to7button.vue'
import app0to10 from './answersType/0to10.vue'
import app1to10 from './answersType/1to10.vue'
import app5radio from './answersType/nvs.vue'
import appmatrix1to5 from './answersType/matrix1to5.vue'
import appmatrix10num from './answersType/matrix10num.vue'
export default {
  name: 'prevSelectQuestions',
  props: ['showModal'],
  components: {
    app10num,
    app5num,
    app5nes,
    appyn,
    appynm,
    appynd,
    app5emo,
    apptext,
    appinputext,
    appreferral,
    appmultiple,
    app0to5,
    app1to5,
    app1to7,
    app1to7button,
    appmatrix1to5,
    appmatrix10num,
    app0to10,
    app1to10,
    app5radio,
    'app-single': () => import('./answersType/single.vue'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal')
  },
  data () {
    return {
      forbiddenTypes: ['matrix', 'referral'],
      forbiddenUis: []
    }
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    }
  },
  methods: {
    componentsColors () {
      const themeColors = this.$store.getters['survey/getColors']
      const colors = {
        actionColor: themeColors.actionColor,
        actionText: themeColors.actionText,
        splashColor: themeColors.splashColor
      }
      return colors
    },
    selectQuestionForCondition (question) {
      this.$emit('submitSelectedQuestion', question.id)
    },
    renderUi (ui, type, options) {
      let appUi = 'app' + ui.toLowerCase()
      if (ui === '10num' || ui === '10NUM') {
        switch (options.length) {
          case 6:
            appUi = 'app0to5'
            break
          case 5:
            appUi = 'app1to5'
            break
          case 11:
            appUi = 'app0to10'
            break
          case 10:
            appUi = 'app1to10'
            break
        }
      }
      if (type === 'matrix' && ui === '10num') {
        appUi = 'appmatrix10num'
      }
      if (ui === 'multipleOther') {
        appUi = 'appmultiple'
      }
      if (ui === 'singleOther' || ui === 'single') {
        appUi = 'app-single'
      }
      return appUi
    }
  }
}
</script>

<style lang="scss">

</style>
