<template>
  <div class="container">
    <div class="loading-survey-list" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <Menu :showSubMenu="false"></Menu>
    <div class="empty-group" v-if="empty_group && list.length === 0">
      <div class="empty-content">
        <app-heading level="h2" style="text-align: center;">{{ $t('role.empty_group') }}</app-heading>
        <app-heading class="empty-msg" level="h5">{{ $t('role.empty_group_msg') }}</app-heading>
        <app-button class="button-add-group" v-on:click="openSideBar(null)">Criar Grupo</app-button>
      </div>
    </div>
    <div v-if="!empty_group && list.length > 0" class="groups-container">
      <div class="groups-content">
        <div class="header">
          <div class="header-title">
            <app-heading level="h3">{{ $t('role.groups') }}</app-heading>
            <app-heading level="h6" class="groups-total">{{ list.length}} {{ $t('role.groups') }}</app-heading>
          </div>
          <div class="header-button">
            <app-button class="button-add-group" v-on:click="openSideBar(null)">+ {{ $t('role.create') }}</app-button>
          </div>
        </div>
        <div class="groups-filter">
          <app-form-input v-model="search" @input="updatePagination()" :placeholder="$t('role.search_group')"/>
        </div>
        <div class="groups-list">
          <div class="header-list">
            <ul>
              <li>{{ $t('role.name_group') }}</li>
              <li>{{ $t('role.group_createdAt') }}</li>
              <li>{{ $t('role.group_updatedAt') }}</li>
              <li></li>
            </ul>
          </div>
          <div class="groups" v-for="group in currentPageItems" :key="group._id">
            <ul>
              <li>{{ group.name }}</li>
              <li>{{ moment(group.createdAt) }}</li>
              <li>{{ moment(group.updatedAt) }}</li>
              <li>
                <div class="btn-details" v-on:click="openSideBar(group)">
                  <app-icon name="edit" />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="groups-paginate">
          <app-paginate :initValue="1" :prevText="'<'" :nextText="'>'" @input="changePage" :pageRange="groupPerPage" :pageCount="dynamicPageCount" ></app-paginate>
        </div>
      </div>
    </div>
    <side-bar-roles v-if="openBar" @close-sidebar-roles="openBar = !openBar" :surveys="listSurveys" :collectors="listCollector" :userList="users" :currentGroup="currentGroup"/>
    <theme-style>
      .empty-msg {
        color: {{account.account.theme.colors.accent || '#673ab7'}} !important;
      }
      .button-add-group {
        background: {{account.account.theme.colors.accent || '#673ab7'}} !important;
        color:{{ (account.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      }
      .b-icon {
        color: {{account.account.theme.colors.accent || '#673ab7'}} !important;
      }
    </theme-style>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import { roleService, surveyService, sendhubService, accountService } from '@/_services'
import SideBarRoles from '../components/SideBarConfig/SideBarRoles.vue'

export default {
  name: 'Roles',
  data () {
    return {
      currentGroup: null,
      openBar: false,
      empty_group: false,
      loading: false,
      list: [],
      listSurveys: [],
      listCollector: [],
      users: [],
      search: '',
      page: 1,
      groupPerPage: 20,
      dynamicPageCount: 0
    }
  },
  components: {
    Menu,
    SideBarRoles,
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-paginate': () => import('@binds-tech/binds-design-system/src/components/Paginate/Paginate'),
    'app-icon': () => import('@binds-tech/binds-design-system/src/components/Icon/Icon'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  methods: {
    openSideBar (group) {
      this.currentGroup = group
      this.loading = true
      this.openBar = true
    },
    moment (eventDate) {
      this.$moment.locale(this.lang)
      return this.$moment(eventDate).format('L')
    },
    updatePagination () {
      const groupsLength = (this.filtereGroup.length / 20)
      if (Number.isInteger(groupsLength)) {
        this.dynamicPageCount = groupsLength
      } else {
        this.dynamicPageCount = Math.trunc(groupsLength) + 1
      }
    },
    changePage (page) {
      this.page = page
      window.scrollTo({ top: 200, behavior: 'smooth' })
    }
  },
  computed: {
    account () {
      const user = this.$store.getters['account/getUser']
      return user
    },
    filtereGroup () {
      const filtered = this.list.filter(group => {
        return group.name.toLowerCase().includes(this.search.toLowerCase())
      })
      return filtered
    },
    currentPageItems () {
      let filterIndex = this.page
      if (this.page > this.filtereGroup.length) {
        filterIndex = 1
      }
      return this.filtereGroup.slice((filterIndex * this.groupPerPage) - this.groupPerPage, filterIndex * this.groupPerPage)
    }
  },
  async mounted () {
    this.loading = true

    const fetchAll = [roleService.getRoles(),
      surveyService.getAllSurveys(),
      sendhubService.collectors(),
      accountService.getUsers()]
    const [roles, surveys, collectors, userList] = await Promise.all(fetchAll)
    this.list = roles
    this.listSurveys = surveys
    this.listCollector = collectors
    this.users = userList

    if (roles.length === 0) {
      this.empty_group = true
    }

    this.$root.$on('groups_update_item', (e) => {
      this.list.filter((item, idx) => {
        if (item._id === e._id) {
          this.list.splice(idx, 1, e)
        }
      })
    })

    this.$root.$on('groups_create', async (e) => {
      this.list.unshift(e)
      this.empty_group = false
    })

    this.$root.$on('groups_delete', async (e) => {
      this.list = this.list.filter(item => {
        return item._id !== e._id
      })
      if (this.list.length === 0) {
        this.empty_group = true
      }
    })

    this.$root.$on('loading-done', () => {
      this.loading = false
    })

    this.updatePagination()

    this.loading = false
  }
}
</script>
<style lang="scss">
* {
  box-sizing: border-box;
}
.loading-survey-list {
  background: #EFF3F840;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner-loading-survey-list {
    width: 2.1vw !important;
    height: 2.1vw !important;
    border-width: 3px !important;
    border-radius: 35px;
    border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
  }
}
.empty-group {
  width: 100vw;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .empty-content {
    width: 35%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
.groups-container {
  width: 100vw;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .groups-content {
    width: 95%;
    height: 95%;
    .header {
      width: 100%;
      height: 15%;
      border-bottom: 1px solid #cacaca;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .groups-total {
        color: #808080 !important;
        font-size: 14px;
        margin: 10px 0 0 5px;
      }
      .button-add-group {
        font-size: 13px;
        padding: 8px 15px;
      }
    }
    .groups-filter {
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;
      .text-field {
        width: 20%;
      .text-field__input {
        background: transparent;
        border: none !important;
        border-bottom: 1px solid #cacaca !important;
        input {
          font-size: 16px;
          font-weight: 400;
          height: 40px;
          outline: none;
          padding: 0 0 0 5px;
        }
      }
      }
    }
    .groups-list {
      width: 100%;
      height: 70%;
      position: relative;
      border-radius: 5px;
      overflow-x: hidden;
      background: #fff;
      border: 1px solid #cacaca;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      .header-list {
        font-size: 18px;
        font-weight: 600;
      }
      .groups {
        color: #424242;
      }
      .header-list, .groups {
        width: 100%;
        height: 10%;
        border-bottom: 1px solid #cacaca;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          li {
            width: 15%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: rgb(63, 51, 86);
            .btn-details {
              cursor: pointer;
              padding: 5px;
              .b-icon {
                font-size: 18px;
              }
            }
          }
          li:nth-child(1) {
            width: 25%;
          }
        }
      }
    }
    .groups-paginate {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
