<template>
    <div class="sentiment-chart-container">
    <div class="sentiment-chart-header">
      <p class="sentiment-chart-title">{{ title }}</p>
      <i class="material-symbols-outlined icon-chart-sentiment" v-title="'Acompanhe variações de sentimentos ao longo do tempo.'" title-max-width="250" title-placement="left">info</i>
    </div>
    <div class="sentiment-chart-section" v-if="data.length">
      <canvas class="canvas-chart-sentiment" width="300" height="500" :id="makeSpecialId()"></canvas>
    </div>
    <div  v-else style='height: 83%'>
      <NoData/>
    </div>
  </div>
</template>
<script>
import { generateColors } from './generateColorsInsights'
import { canvasMaker } from '@/_helpers'
import NoData from '../NoData.vue'
import('chart.js').then((chartjs) => { window.Chart = chartjs.default })

export default {
  name: 'GraphicSentiment',
  props: ['data', 'title'],
  components: {
    NoData
  },
  data () {
    return {
      text: '',
      dataSwot: []
    }
  },
  async mounted () {
    if (!this.data.length) return
    const ree = []
    const timeLabels = this.data.map(item => item.time)
    this.data.forEach(function (item) {
      item.product.forEach(product => {
        ree.push(product)
      })
    })
    const groupedObject = ree.reduce((result, item) => {
      if (!result[item.key]) {
        result[item.key] = []
      }
      result[item.key].push(item)
      return result
    }, {})

    const dataSet = []
    for (const [key, value] of Object.entries(groupedObject)) {
      const sd = {}
      sd.label = this.capitalizeFirstLetter(key)
      sd.data = value.map(obj => obj.doc_count)
      sd.stack = 'Stack 0'
      sd.backgroundColor = generateColors.generateColor(key)
      dataSet.push(sd)
    }
    const ctx = document.getElementById(this.specialId).getContext('2d')

    this.displayGraph = await new window.Chart(ctx, {
      type: 'bar',
      data: {
        labels: timeLabels,
        datasets: dataSet
      },
      options: {
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    })
  },
  methods: {
    capitalizeFirstLetter (label) {
      return label.charAt(0).toUpperCase() + label.slice(1)
    },
    makeSpecialId () {
      this.specialId = canvasMaker.makeCanvasIdWithoutParam()
      return this.specialId
    }
  }
}
</script>
<style lang="scss" scoped>
.sentiment-chart-container {
  width: 49.5%;
  height: 500px;
  border-radius: 12px;
  border: 1px solid #e1e1e1;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.070);
  .sentiment-chart-header {
    height: 17%;
    display: flex;
    justify-content: space-between;
    padding: 25px;
    border-bottom: 1px solid #e4e0eb;
    color: var(--accent-color);
    .sentiment-chart-title {
      font-size: 1.3vw;
      font-weight: bold;
    }
    .icon-chart-sentiment {
      font-size: 1.4vw;
      margin-bottom: 6px;
    }
  }
  .sentiment-chart-section {
    padding: 20px;
    height: 83%;
    margin: 0 !important;
    .canvas-chart-sentiment {
      height: 100%;
    }
  }
}
</style>
