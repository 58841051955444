<template>
  <div class="TFA abstract-bg-purple">
    <img
      style="margin: 43px; width: 166px"
      src="../assets/img/logo-white.svg"
      alt="binds.co"
    />
    <div id="center2FA">
      <div id="auth-container">
        <p id="title">
          {{ $i18n.t("TWO_FACTOR.auth") }}
        </p>
        <p id="sub-title">
          {{ $i18n.t("TWO_FACTOR.we_send_a_code") }}
          {{ codeSendBy }}
          {{ $i18n.t("TWO_FACTOR.inside_account") }}
          {{ codeSendTo }}
          {{ $i18n.t("TWO_FACTOR.inform_the_code") }}
        </p>
      </div>
      <div id="code-container" class="abstract-bg-purple">
        <p
          style="
            padding: 7px;
            color: white;
            text-align: center;
            font-size: 1.6em;
          "
        >
          {{ $i18n.t("TWO_FACTOR.verification_code") }}
        </p>
        <div style="text-align: center; margin-top: 20px">
          <input
            class="name inputCodeField"
            id="digito1"
            type="text"
            maxlength="1"
            v-model="verificationCode.first"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          this.value !== '' ? document.getElementById('digito2').focus(): '';"
          />

          <input
            class="name inputCodeField"
            id="digito2"
            type="text"
            maxlength="1"
            v-model="verificationCode.second"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          this.value !== '' ? document.getElementById('digito3').focus(): '';"
          />

          <input
            class="name inputCodeField"
            id="digito3"
            type="text"
            maxlength="1"
            v-model="verificationCode.third"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          this.value !== '' ? document.getElementById('digito4').focus(): '';"
          />

          <input
            class="name inputCodeField"
            id="digito4"
            type="text"
            maxlength="1"
            v-model="verificationCode.bedroom"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          this.value !== '' ? document.getElementById('digito5').focus(): '';"
          />

          <input
            class="name inputCodeField"
            id="digito5"
            type="text"
            maxlength="1"
            v-model="verificationCode.fifth"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          this.value !== '' ? document.getElementById('digito6').focus(): '';"
          />

          <input
            class="name inputCodeField"
            id="digito6"
            type="text"
            maxlength="1"
            v-model="verificationCode.sixth"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          />
        </div>
        <div style="display: flex; margin-top: 60px">
          <div style="display: flex">
            <div
              id="first-send-button"
              class="sendButton"
              style="border-radius: 10px 0px 0px 10px; color: #706662"
            >
              <span v-show="canResend">
                {{ $i18n.t("TWO_FACTOR.send_new_code_by") }}
              </span>
              <span v-show="!canResend" style="font-size: 0.9em">
                {{ $i18n.t("TWO_FACTOR.wait_to_send_again") }}
              </span>
            </div>
            <div
              v-show="canResend"
              class="sendButton"
              @click="resendMessage('email')"
              style="
                background-color: #9871dd;
                cursor: pointer;
                border-radius: 0px 7px 7px 0px;
                color: white;
                width: 48px;
                text-align: center;
              "
            >
              <span class="iconSize">
                <i style="color: white" class="material-icons">email</i>
              </span>
            </div>
            <div
              v-show="canResend"
              class="sendButton"
              @click="resendMessage('phone')"
              style="
                background-color: #8461c0;
                cursor: pointer;
                border-radius: 0px 10px 10px 0px;
                margin-left: -5px;
                width: 43px;
                color: white;
                text-align: center;
              "
            >
              <span class="iconSize">
                <i style="color: white" class="material-icons">smartphone</i>
              </span>
            </div>
            <span v-show="!canResend" id="timerText">{{ currentTime }}</span>
          </div>
          <div class="enterButton" @click="sendCode">
            {{ $i18n.t("login.enter") }}
            <span class="iconSize" style="margin-left: 10px">
              <i class="material-icons">login</i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { userService, surveyService } from '@/_services'

export default {
  data () {
    return {
      verificationCode: {},
      canResend: true,
      currentTime: '00:00',
      codeSendBy: '',
      codeSendTo: ''
    }
  },
  created () {
    this.codeSendBy = this.$route.params.codeSendBy
    this.codeSendTo = this.$route.params.sendTo
  },
  methods: {
    async resendMessage (location) {
      const bodyToResendCodeRoute = Object.assign(
        this.$route.params.loginData,
        { sendBy: location }
      )
      const condeWasResend = await userService.resendVerificationCode(
        bodyToResendCodeRoute
      )
      if (condeWasResend.twoFactorAuth) {
        this.showMessage(this.$i18n.t('TWO_FACTOR.dialog.success_send'))
        this.codeSendBy = condeWasResend.codeSendBy
        this.codeSendTo = condeWasResend.sendTo
      } else {
        return this.showMessage(condeWasResend.message)
      }
      let sec = 59
      this.canResend = false
      let currentSec = ''
      const interval = setInterval(() => {
        if (sec <= 0) {
          clearInterval(interval)
          this.currentTime = '00:00'
          sec = 59
          this.canResend = true
          return false
        }
        currentSec = sec <= 9 ? '0' + sec : sec
        this.currentTime = '00:' + currentSec
        sec--
      }, 1000)
    },
    showMessage (message) {
      this.$store.commit('alerts/alert', {
        message: message,
        showAlert: true
      })
    },
    async sendCode () {
      let finalcode = ''
      const self = this
      Object.keys(this.verificationCode).forEach(function (key, index) {
        if (!self.verificationCode[key]) {
          finalcode = ''
          return false
        }
        finalcode += self.verificationCode[key]
      })
      if (finalcode && this.$route.params.loginData) {
        delete this.$route.params.loginData.resendCode
        delete this.$route.params.loginData.sendBy
        const loginSuccess = await userService.login(
          Object.assign(this.$route.params.loginData, { code: finalcode })
        )
        if (loginSuccess.token) {
          const user = await userService.getMe()
          if (user.account) {
            this.$i18n.locale = user.language
            this.$store.commit('account/setUser', user)
            this.$store.commit('account/language', user.language)
            if (!user.account.theme) {
              this.$store.commit('account/setDefaultTheme')
            }

            const surveys = await surveyService.getAllSurveys()
            if (surveys.length <= 0) {
              this.$router.push({ name: 'Start' })
              return user
            }
            if (!user.account.billing.hasInvoice) {
              this.$router.push({ name: 'SurveysList' })
              return user
            }
            this.$router.push({
              name: 'RealTime',
              query: { q: JSON.stringify({ $and: [] }) }
            })
          }
        } else if (loginSuccess.codeSendBy) {
          this.showMessage(
            this.$i18n.t('TWO_FACTOR.dialog.expired_code_message')
          )
        } else if (loginSuccess.name === 'AuthError') {
          this.showMessage(this.$i18n.t('expired.try_again'))
        }
      } else {
        this.showMessage(this.$i18n.t('TWO_FACTOR.fill_code'))
      }
    }
  }
}
</script>
<style lang="scss">
.TFA {
  background-color: #b19ad9;

  #timerText {
    font-size: 1.3em;
    width: 85px;
    text-align: center;
    font-weight: 600;
    color: rgb(255, 255, 255);
    margin: auto;
    padding: 9.6px;
    border-radius: 0px 10px 10px 0px;
    background-color: #7948b0;
  }
  #center2FA {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 50%;
  }

  #auth-container {
    background-color: white;
    border-radius: 10px;
    padding: 18px;
    height: 356px;
    color: #62667f;
    box-shadow: 1px 3px 39px -24px black;
  }

  .enterButton {
    margin-left: 10px;
    text-align: center;
    width: auto;
    height: 40px !important;
    border-radius: 10px;
    padding: 10px;
    font-weight: 600;
    background: #fff;
    color: #626662;
    margin: 0 auto;
    height: 38px;
    display: flex;
    cursor: pointer;
  }
  .sendButton {
    background-color: white;
    padding: 10px;
    width: auto;
    height: 40px;
  }

  .inputCodeField {
    text-align: center;
    caret-color: #fff;
    margin: 0px 5px;
    background: transparent;
    border: none;
    border-bottom: 1px solid white;
    width: 60px;
    font-size: 3em;
    color: white;
    font-weight: 600;
  }

  .iconSize {
    font-size: 1.2em;
  }

  #center2FA #auth-container #sub-title {
    padding: 15px 0px;
    font-size: 1.2em;
  }

  #center2FA #auth-container #title {
    font-size: 3vw;
  }

  #first-send-button {
    font-size: 1em;
  }

  #code-container {
    background-color: #b19ad9;
    padding: 45px;
    border-radius: 10px;
    margin-top: -164px;
    border-top-left-radius: 91px;
  }
  @media screen and (max-width: 632px) {
    #center2FA {
      top: 53%;
      width: 80%;
    }
    #center2FA #auth-container #title {
      font-size: 6vw;
    }
    #center2FA #auth-container #sub-title {
      font-size: 3.7vw;
    }
    #first-send-button {
      font-size: 3vw;
    }
    #code-container {
      padding: 19px;
      margin-top: -188px;
    }
    .inputCodeField {
      width: 28px !important;
    }
    .enterButton {
      margin-left: 10px;
    }
  }
}
</style>
