
export const dashboardMaker = {
  makeDefaultDash
}

function makeDefaultDash (questions, canAddId = false) {
  const defaultDash = []
  questions.forEach(question => {
    if (question.type !== 'referral') {
      let defaultCard = {}
      let averageChart = ''
      let overallChart = ''
      let overtimeChart = ''

      const averageColors = [
        '#EA484D', '#EF874C', '#F8C43D', '#9FCD35', '#57AD26',
        '#805ea0', '#966fbc', '#ae74e8', '#b97bf7', '#ce9eff'
      ]

      const defaultColorsKpiYnd = ['#57AD26', '#EA484D', '#F8C43D']

      const overtimeColor = [
        '#805ea0', '#966fbc', '#ae74e8', '#b97bf7', '#ce9eff',
        '#805ea0', '#966fbc', '#ae74e8', '#b97bf7', '#ce9eff'
      ]
      const overallColors = [
        '#a87df5', '#fc90a4', '#00aaa7', '#673ab7', '#9f95f5',
        '#FFB500', '#f55bed', '#CA1E54', '#4D5359', '#79C99E',
        '#97DB4F', '#27B573', '#FF7663', '#1B9AAA', '#FFC43D'
      ]

      if (question.type === 'csat') {
        averageChart = 'rangeGauge'
        overallChart = 'horizontalBar'
        if (question.ui === '5emo') {
          averageChart = 'emoteGauge'
          overallChart = 'verticalBar'
        }
      }

      if (question.type === 'nps') {
        overallChart = 'horizontalBar'
        if (['10num', 'ynm', 'ynd'].indexOf(question.ui) > -1) {
          averageChart = 'rangeGauge'
        }
        if (question.ui === 'text') {
          averageChart = 'textUi'
        }
      }

      if (question.type === 'kpi') {
        overallChart = 'verticalBar'
        if (question.ui === '5emo' || question.ui === '5EMO') {
          averageChart = 'emoteGauge'
        }
        if (question.ui === 'ynm' || question.ui === 'ynd') {
          averageChart = 'kpiYnd'
        }

        if (question.ui === 'yn') {
          averageChart = 'kpiYn'
        }
      }

      if (['ces', 'nes', 'nvs'].indexOf(question.type) > -1) {
        overallChart = 'horizontalBar'
        if (question.ui === '10num') {
          averageChart = 'rangeGauge'
        }

        if (question.ui === 'text') {
          averageChart = 'textUi'
        }
        if (question.ui === '1to7' || question.ui === '1to7button' || question.ui === '5radio') {
          averageChart = 'rangeGauge'
        }
      }

      if (question.type === 'enum') {
        overallChart = 'horizontalBar'
        averageChart = 'textUi'
      }

      if (question.type === 'matrix') {
        overallChart = 'stackedBar'
        averageChart = 'stackedBar'

        if (question.ui === 'like') {
          overallChart = 'likeOverall'
          averageChart = 'likeOverall'
          overtimeChart = 'likeOvertime'
        }
      }

      const isKpiYnd = ['yn', 'ynd'].includes(question.ui)

      defaultCard = {
        id: question.id,
        type: question.type,
        ...(canAddId && { _id: question._id }),
        key: question._id,
        ui: question.ui,
        question: question,
        defaultStart: 'average',
        isExpanded: false,
        average: {
          showTextOnly: false,
          syncQuestion: false,
          textAnalytic: false,
          graphicType: averageChart,
          colors: isKpiYnd ? defaultColorsKpiYnd : !['matrix'].includes(question.type) ? averageColors : [],
          textColor: 'white'
        },
        overtime: {
          showTextOnly: false,
          syncQuestion: false,
          textAnalytic: false,
          graphicType: overtimeChart,
          colors: question.type !== 'matrix' ? overtimeColor : [],
          textColor: 'white'
        },
        overall: {
          showTextOnly: false,
          syncQuestion: false,
          textAnalytic: false,
          graphicType: overallChart,
          colors: question.type !== 'matrix' ? overallColors : [],
          textColor: 'white'
        }
      }

      if (defaultCard) {
        defaultDash.push(defaultCard)
      }
    }
  })

  return defaultDash
}
