<template>
  <div class="container-card-swot-sidebar" v-if="dataSwot">
    <div class="header-card-swot-sidebar">
      <p class="card-label-swot-sidebar">Análise SWOT</p>
    </div>
    <div class="content-swot-sidebar">
      <div class="row-swot-sidebar" v-for="swot in dataSwot" :key="swot.letter">
        <div class="ball-swot-sidebar" :style="{'background': swot.background, 'border-color': swot.borderColor}">{{ swot.letter }}</div>
        <div class="info-swot-sidebar">
          <p class="label-swot-sidebar">{{ swot.title }}</p>
          <p class="label-swot-sidebar label-swot">{{ swot.label || 'N/A' }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwotRow',
  props: ['swot'],
  components: {},
  data () {
    return {
      dataSwot: []
    }
  },
  created () {
    this.formattedSwot()
  },
  watch: {
    swot: {
      immediate: true,
      deep: true,
      handler () { this.formattedSwot() }
    }
  },
  methods: {
    formattedSwot () {
      this.dataSwot = [
        { letter: 'S', title: 'Força', label: this.swot?.s, background: '#6dd978', borderColor: '#008000' },
        { letter: 'W', title: 'Fraqueza', label: this.swot?.w, background: '#b7b7b7', borderColor: '#808080' },
        { letter: 'O', title: 'Oportunidade', label: this.swot?.o, background: '#ddf54c', borderColor: '#808b00' },
        { letter: 'T', title: 'Ameaça', label: this.swot?.t, background: '#fc7676', borderColor: '#be2626' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.container-card-swot-sidebar {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  padding: 0.5vw;
  background: #fbfbfb;
  .header-card-swot-sidebar {
    display: flex;
    justify-content: center;
    .card-label-swot-sidebar {
      font-size: 0.9vw;
      font-weight: 600;
      color: #454548;
    }
  }
  .content-swot-sidebar {
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
    .row-swot-sidebar {
      width: 100%;
      padding: 0.2vw 0.5vw;
      border-radius: 5px;
      border: 1px solid #e1e1e1;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      gap: 0.5vw;
      .ball-swot-sidebar {
        width: 1.5vw;
        height: 1.5vw;
        border: 1px solid #cacaca;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        background: #b7b7b7;
        font-size: 0.9vw;
        color: #ffffff;
        font-weight: bold;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .info-swot-sidebar {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 0.3vw;
        .label-swot-sidebar {
          font-size: 0.75vw;
          color: #454548;
          font-weight: 600;
        }
        .label-swot {
          font-size: 0.7vw;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
