<template>
  <div class="expander">
    <div class="row pointer" v-on:click="toggle()">
      <app-heading class="pointer" level="h5">{{ title }}</app-heading>
      <span :class="{arrow: true, 'arrow-bottom': isVisible, 'arrow-right': !isVisible}"></span>
      <span v-title="$t('survey.count_of_conditions')" class="count-filter" v-if="lengthOfConditions">{{lengthOfConditions}}</span>
    </div>
    <div :class="{'expander-content': beFlex}" v-show="isVisible">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Expander',
  props: {
    title: {
      type: String
    },
    showOnStartup: {
      type: Boolean,
      default: true
    },
    flex: {
      type: Boolean,
      default: false
    },
    lengthOfConditions: {
      type: Number,
      default: null
    }
  },
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      isVisible: false,
      beFlex: false
    }
  },
  mounted () {
    if (this.showOnStartup) {
      this.isVisible = true
    }
    if (this.showOnStartup && this.flex) {
      this.beFlex = true
    }
  },
  methods: {
    toggle () {
      this.isVisible = !this.isVisible
      if (this.flex && this.isVisible) {
        this.beFlex = true
      }
      if (!this.isVisible) {
        this.beFlex = false
      }
      this.$emit('changeExpand', this.isVisible)
    }
  }
}
</script>

<style lang="scss">
.expander {
  padding: 15px;
  background: #fff;
.count-filter {
    width: 20px;
    height: 20px;
    background: var(--accent-color);
    border-radius: 50%;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -4px;
    margin-left: 10px;
    font-size: 0.9em;
  }
}
.expander-content {
  display: flex !important;
}
/* arrow common style */
.arrow {
  margin-left: 10px;
  margin-top: 4.5px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-top: 2px solid $color-purple-dark;
  border-right: 2px solid $color-purple-dark;
}
.arrow-right {
  transition: all 0.3s ease-in-out;
  transform: rotate(45deg);
}
.arrow-bottom {
  margin-top: 2px !important;
  transition: all 0.3s ease-in-out;
  transform: rotate(135deg);
}
.pointer {
  cursor: pointer;
}
@keyframes fadeInDown {
  from {
    opacity:0;
    -webkit-transform: translatey(-10px);
    -moz-transform: translatey(-10px);
    -o-transform: translatey(-10px);
    transform: translatey(-10px);
  }
  to {
    opacity:1;
    -webkit-transform: translatey(0);
    -moz-transform: translatey(0);
    -o-transform: translatey(0);
    transform: translatey(0);
  }
}

@media only screen and (min-width: 1500px) and (max-width: 3000px) {
  .expander {
    width: 100%;
    .referral-dynamic-options {
      min-width: 420px;
      .delete-dynamic-field {
        margin-left: auto;
      }
    }
  }
}
</style>
