<template>
  <div class="container-modal-media">
    <div class="content-modal-media">
      <button class="close-modal-media" @click="closeModalMedia()">
        <i class="material-icons close-modal-media-icon">cancel</i>
      </button>
      <div class="header-modal-media">
        <div class="titles-header-modal-media">
          <app-heading level="h3">{{ $t('bvs.modal_title') }}</app-heading>
        </div>
        <div class="tabs-modal-media">
          <p level="h7" class="title-tab-modal-media tab-brand tab-border" @click="selectTab('brand', '.tab-brand')">{{ $t('bvs.tab_yours_brands') }}</p>
          <p level="h7" class="title-tab-modal-media tab-upload" @click="selectTab('upload', '.tab-upload')">{{ $t('bvs.tab_upload') }}</p>
          <p level="h7" class="title-tab-modal-media tab-upload-activities" @click="selectTab('activities-upload', '.tab-upload-activities')">{{ $t('bvs.tab_history') }}</p>
        </div>
      </div>
      <div class="section-modal-media" v-if="tabSelected === 'brand'">
        <div class="searched-brands">
          <div class="count-brands">
            <CounteBrands :name="'bvs.search_on_twitter'" :hasCount="false" :icon="'person_search'" :color="'#7e7f82'" />
            <InputSearchBrand @new-brands="searchedBrands = $event.newBrands"/>
          </div>
          <div class="content-searched-items">
            <CardBrand :data="card" v-for="card in searchedBrands" :key="card.screen_name.toLowerCase()" />
          </div>
        </div>
        <div class="existing-brands">
          <div class="count-brands">
            <CounteBrands :name="'bvs.current_brands'"
            :hasCount="false"
            :total="account.brandsTracking.length"
            :max="account.termsSettings.limit"
            :icon="'how_to_reg'"
            :color="'#7e7f82'" />
            <InputCreateBrand @scroll-to-brand="scrollIntoView('newBrand')"/>
          </div>
          <div class="content-current-items">
            <CardBrand :ref="'brands-container' + card._id" :data="card" v-for="card in account.brandsTracking" :key="card._id" />
          </div>
        </div>
      </div>
      <div class="section-modal-media-upload" v-if="tabSelected === 'upload'">
        <div class="section-to-upload">
          <ImportFileSocialMedia :brand="brandSelected" />
        </div>
      </div>
      <div class="section-modal-media-activities-upload" v-if="tabSelected === 'activities-upload'">
        <UploadHistory />
      </div>
      <ModalAddBrand @scroll-to-brand="scrollIntoView('newBrand')" v-if="openModalAddBrand" :brand="brandSelected" />
      <ModalEditBrand v-if="openModalEdit" :brand="brandSelected" />
      <ModalRemoveBrand v-if="openModalRemoveBrand" :brand="brandSelected" />
    </div>
  </div>
</template>

<script>
import UploadHistory from './UploadHistory.vue'
import CounteBrands from './CountBrands.vue'
import CardBrand from './CardBrand.vue'
import ImportFileSocialMedia from './ImportFileSocialMedia.vue'
export default {
  name: 'ModalSocialMedia',
  props: [],
  components: {
    UploadHistory,
    CardBrand,
    CounteBrands,
    ImportFileSocialMedia,
    InputSearchBrand: () => import('./InputSearchBrand.vue'),
    InputCreateBrand: () => import('./InputCreateBrand.vue'),
    ModalAddBrand: () => import('./ModalAddBrand.vue'),
    ModalRemoveBrand: () => import('./ModalRemoveBrand.vue'),
    ModalEditBrand: () => import('./ModalEditBrand.vue'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      searchedBrands: [],
      tabSelected: 'brand',
      brandSelected: null,
      loadingNewBrand: false,
      newBrandMessage: '',
      loadingSearch: false,
      openModalEdit: false,
      openModalRemoveBrand: false,
      openModalAddBrand: false
    }
  },
  beforeDestroy () {
    this.$root.$off('close-brand-action-modals')
  },
  async mounted () {
    const modal = document.querySelector('.content-modal-media')
    setTimeout(() => { modal.classList.add('show') }, 100)

    this.$root.$on('new-brands', payload => {
      this.searchedBrands = payload.newBrands
    })

    this.$root.$on('close-brand-action-modals', () => {
      this.openModalEdit = false
      this.openModalRemoveBrand = false
      this.openModalAddBrand = false
    })

    this.$root.$on('open-upload', payload => {
      this.brandSelected = payload
      this.selectTab('upload', '.tab-upload')
    })

    this.$root.$on('open-modal-of-brands-tracking', payload => {
      this.brandSelected = payload.data
      if (payload.type === 'edit') {
        this.openModalEdit = true
      }

      if (payload.type === 'remove') {
        this.openModalRemoveBrand = true
      }

      if (payload.type === 'add') {
        this.openModalAddBrand = true
      }
    })

    this.$root.$on('remove-brand-searched', brand => {
      const findId = this.searchedBrands.findIndex(e => { return e.id === brand.id })
      this.searchedBrands.splice(findId, 1)
    })

    this.$root.$on('upload-success', () => {
      this.selectTab('activities-upload', '.tab-upload-activities')
    })
  },
  computed: {
    colors () {
      const colors = this.$store.getters['account/getAccount'].theme.colors
      return colors
    },
    account () {
      const terms = this.$store.getters['account/getAccount']
      return terms
    }
  },
  methods: {
    scrollIntoView (location) {
      setTimeout(() => {
        let ref
        if (location === 'newBrand') {
          const lastItemOfTheArray = this.account.brandsTracking[this.account.brandsTracking.length - 1]
          ref = this.$refs['brands-container' + lastItemOfTheArray._id]
        }
        if (!ref) return false
        ref[0].$el.scrollIntoView({ behavior: 'smooth' })
      }, 300)
    },
    selectTab (e, tabClass) {
      if (e === 'upload' && !this.brandSelected) { return false }

      this.tabSelected = e
      const currentTab = document.querySelector(tabClass)
      const tabs = document.querySelectorAll('.title-tab-modal-media')

      for (const tab of tabs) {
        tab.classList.remove('tab-border')
        tab.style.color = '#cacaca'
      }

      currentTab.classList.add('tab-border')
      currentTab.style.color = '#454548'
    },
    closeModalMedia () {
      const modal = document.querySelector('.content-modal-media')
      modal.classList.remove('show')
      setTimeout(() => { this.$root.$emit('modal-media') }, 300)
    }
  }
}
</script>

<style lang="scss">
.container-modal-media {
  background: #00000035;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: openModal 0.2s linear forwards;
  .content-modal-media {
    opacity: 0;
    position: relative;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 75%;
    height: 80vh;
    transition: opacity 0.3s;
    display: flex;
    flex-direction: column;
    .close-modal-media {
      position: absolute;
      top: 5px;
      right: 5px;
      .close-modal-media-icon {
        color: var(--accent-color);
      }
    }
    .header-modal-media {
      width: 100%;
      height: 15%;
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .titles-header-modal-media {
        width: 100%;
        height: 75%;
        padding: 0 50px 0 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
          font-weight: 500;
          color: #454548;
        }
      }
      .tabs-modal-media {
        width: 100%;
        height: 25%;
        padding-left: 15px;
        display: flex;
        .title-tab-modal-media {
          position: relative;
          color: #cacaca;
          font-size: 15px;
          font-weight: 600 !important;
          margin-right: 15px !important;
          cursor: pointer;
        }
        .tab-brand {
          color: #454548;
        }
      }
    }
    .section-modal-media {
      width: 100%;
      height: 85%;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      .searched-brands, .existing-brands {
        padding: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .searched-brands {
        width: 25.2%;
        .content-searched-items {
          border: 1px solid #F0F2F5;
          overflow-y: auto;
          width: 100%;
          height: 82%;
          background: #F0F2F5;
          border-radius: 5px;
          padding: 5px;
        }
      }
      .existing-brands {
        width: 74%;
        .content-current-items {
          border: 1px solid #F0F2F5;
          width: 100%;
          height: 82%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-content: flex-start;
          overflow-y: auto;
          background: #F0F2F5;
          border-radius: 5px;
          padding: 5px;
        }
        .count-brands {
          align-items: flex-end;
        }
      }
    }
    .section-modal-media-upload {
      width: 100%;
      height: 85%;
      padding: 15px;
      .section-to-upload {
        background: #F0F2F5;
        border-radius: 5px;
        width: 100%;
        height: 100%;
      }
    }
    .section-modal-media-activities-upload {
      width: 100%;
      height: 85%;
      padding: 15px;
    }
    .count-brands {
      width: 100%;
      height: 17%;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      .btn-new-upload {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        padding: 0 10px;
        border: 1px solid #cacaca;
        background: #F0F2F5;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .title-new-brand-custom {
          font-size: 16px;
          color: #b3b3b3 !important;
          font-weight: 600 !important;
        }
        .upload-brand-icon {
          color: #b3b3b3;
        }
      }
    }
    .start-modal-media {
      width: 100%;
      height: 10%;
      padding: 15px;
      background: #454548;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .data-loading-fixed {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      .spinner {
        margin: 0 !important;
      }
    }
  }
  .show {
    opacity: 1;
  }
  .tab-border {
    border-bottom: 2px solid var(--accent-color);
  }
}

@keyframes openModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
