<template>
  <div class="barMetric" :class="{
      dashBarMetric: this.$route.name === 'RealTime',
      emoDashBarMetric: data.question.ui === '5emo' && this.$route.name === 'RealTime',
      nvsBarMetric: data.question.ui === '5radio' && this.$route.name != 'RealTime',
      emoticonBarMetric: data.question.ui === '5emo'&& this.$route.name != 'RealTime' }">
    <div class="metric-container" v-if="data.valueGroup">
      <div class="options" v-for="(bar, idx) in Object.keys(options[data.question.type])"
      :key="idx"
      @click="openOnResponses(options[data.question.type][bar])">
        <div class="labels-bar">
          <p>{{options[data.question.type][bar].label}}</p>
          <p>{{ returnNumberWithoutRound(data.valueGroup[bar] * 100, data.total) }}%</p>
        </div>
        <div class="individual-bar">
          <div class="progressive-bar">
            <div class="rate-bar" :style="{width: `${returnNumberWithoutRound(data.valueGroup[bar] * 100, data.total)}%`,
            'background': options[data.question.type][bar].color}"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'BarWithMetric',
  data() {
    return {
      colors: [],
      options: {},
      values: []
    }
  },
  props: ['data', 'question', 'cardData'],
  created() {
    if (!this.data) return

    this.colors = this.returnMetricsAndColors()
    this.options = this.returnMetricsAndColors()
  },
  computed: {
    currentSurveyId() {
      const survey = this.$store.getters['dashboard/getCurrentSurvey']
      const id = survey._id
      return id
    }
  },
  methods: {
    returnNumberWithoutRound (number, total) {
      const sum = (((number / total) * 100) / 100).toFixed(1)
      return sum
    },
    returnMetricsAndColors() {
      return {
        nvs: {
          sb: {
            icon: '',
            label: this.$t('dashboard.graphics.nvs_op1'),
            color: this.colors[0] || '#57AD26',
            ratingToQuery: '"$gte":99'
          },
          lb: {
            icon: '',
            label: this.$t('dashboard.graphics.nvs_op2'),
            color: this.colors[2] || '#57AD26',
            ratingToQuery: '"$gte":74,"$lte":76'
          },
          nb: {
            icon: '',
            label: this.$t('dashboard.graphics.nvs_op3'),
            color: this.colors[4] || '#57AD26',
            ratingToQuery: '"$gte":49,"$lte":51'
          },
          lw: {
            icon: '',
            label: this.$t('dashboard.graphics.nvs_op4'),
            color: this.colors[4] || '#F8C43D',
            ratingToQuery: '"$gte":24,"$lte":26'
          },
          sw: {
            icon: '',
            label: this.$t('dashboard.graphics.nvs_op5'),
            color: this.colors[4] || '#EA484D',
            ratingToQuery: '"$lte":1'
          }
        },
        csat: {
          ins: {
            icon: '',
            label: this.$t('advanced_filters.fields.unsatisfied'),
            color: this.colors[0] || '#EA484D',
            ratingToQuery: '"$lt":70'
          },
          sat: {

            icon: '',
            label: this.$t('advanced_filters.fields.satisfied'),
            color: this.colors[4] || '#57AD26',
            ratingToQuery: '"$gte":70'
          }
        },
        nps: {
          dt: {
            icon: '',
            label: this.$t('advanced_filters.fields.detractors'),
            color: this.colors[0] || '#EA484D',
            ratingToQuery: '"$lte":60'
          },
          nt: {
            icon: '',
            label: this.$t('advanced_filters.fields.neutrals'),
            color: this.colors[2] || '#F8C43D',
            ratingToQuery: '"$lte":80,"$gte":70'
          },
          pr: {
            icon: '',
            label: this.$t('advanced_filters.fields.promoters'),
            color: this.colors[4] || '#57AD26',
            ratingToQuery: '"$gte":90'
          }
        },
        nes: {
          me: {
            icon: '',
            label: this.$t('advanced_filters.fields.much_effort'),
            color: this.colors[0] || '#EA484D',
            ratingToQuery: '"$gte":75'
          },
          ee: {
            icon: '',
            label: this.$t('advanced_filters.fields.expected_effort'),
            color: this.colors[2] || '#F8C43D',
            ratingToQuery: '"$gt":49, "$lt":51'
          },
          le: {
            icon: '',
            label: this.$t('advanced_filters.fields.less_effort'),
            color: this.colors[4] || '#57AD26',
            ratingToQuery: '"$lte":25'
          }
        },
        ces: {
          td: {
            icon: '',
            label: this.$t('components.ces.totally_disagree'),
            color: this.colors[0] || '#EA484D',
            ratingToQuery: '"$lte":31'
          },
          nt: {
            icon: '',
            label: this.$t('advanced_filters.fields.neutrals'),
            color: this.colors[2] || '#F8C43D',
            ratingToQuery: '"$lte":51,"$gte":49'
          },
          ta: {
            icon: '',
            label: this.$t('components.ces.totally_agree'),
            color: this.colors[4] || '#57AD26',
            ratingToQuery: '$gte":64'
          }
        }
      }
    },
    returnIcon(findThisEmote) {
      return this.emotes.filter((emote) => {
        return emote.value === findThisEmote
      })[0].key
    },
    convertDateFromRealtime(stringDate) {
      let date = new Date()
      switch (stringDate) {
        case 'day':
          date = this.$moment().subtract(1, 'days').format('DD/MM/YYYY')
          break
        case 'week':
          date = this.$moment().subtract(1, 'weeks').format('DD/MM/YYYY')
          break
        case 'month':
          date = this.$moment().subtract(1, 'months').format('DD/MM/YYYY')
          break
        case 'semester':
          date = this.$moment().subtract(6, 'months').format('DD/MM/YYYY')
          break
        case 'year':
          date = this.$moment().subtract(1, 'years').format('DD/MM/YYYY')
          break
        default:
          date = this.$moment().subtract(1, 'days').format('DD/MM/YYYY')
          break
      }
      date = this.$moment(date, 'DD/MM/YYYY')
        .utc()
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      const dateTo = this.$moment(new Date(), 'DD/MM/YYYY')
        .utc()
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      return {
        from: date,
        to: dateTo
      }
    },
    openOnResponses(metric) {
      const parsedQuery = JSON.parse(this.$route.query.q)
      const hasSurveyOnQuery = window._.find(parsedQuery.$and, 'survey')
      const hasRealTimeDate = window._.find(parsedQuery.$and, 'realTimeDate')
      let hasRespondedAtGteOnQuery = window._.find(
        parsedQuery.$and,
        'respondedAt.$gte'
      )
      let hasRespondedAtLteOnQuery = window._.find(
        parsedQuery.$and,
        'respondedAt.$lte'
      )
      let dateFrom = ''
      let dateTo = ''
      let survey = this.currentSurveyId
      if (hasSurveyOnQuery) {
        survey = hasSurveyOnQuery.survey.$eq
      }
      if (this.$route.name === 'RealTime') {
        if (!hasRealTimeDate) {
          return false
        }
        const converted = this.convertDateFromRealtime(
          hasRealTimeDate.realTimeDate
        )
        if (converted.from) {
          dateFrom = converted.from
        }
        if (converted.to) {
          dateTo = converted.to
        }
      } else {
        if (!hasRespondedAtGteOnQuery) {
          hasRespondedAtGteOnQuery = window._.find(
            this.$store.getters['filters/getDashFilter'].query.$and,
            'respondedAt.$gte'
          )
          if (!hasRespondedAtGteOnQuery) {
            return false
          }
        }
        if (!hasRespondedAtLteOnQuery) {
          hasRespondedAtLteOnQuery = window._.find(
            this.$store.getters['filters/getDashFilter'].query.$and,
            'respondedAt.$lte'
          )
          if (!hasRespondedAtLteOnQuery) {
            return false
          }
        }
        dateFrom = hasRespondedAtGteOnQuery.respondedAt.$gte
        dateTo = hasRespondedAtLteOnQuery.respondedAt.$lte
      }

      const query = JSON.parse(this.$route.query.q)
      const filterToAdd = `{"responses":{"$elemMatch":{"rating":{${metric.ratingToQuery}},"question._id":"${this.data.question._id}"}}}`

      if (this.filterExistsInFilter(query.$and, filterToAdd)) {
        query.$and.push(JSON.parse(filterToAdd))
        this.$router.push({ name: 'Individual', query: { q: query }})
      }
    },
    filterExistsInFilter(array, objToCheck) {
      return array.every((obj) => JSON.stringify(obj) !== objToCheck)
    }
  }
}
</script>

<style lang="scss">
%animated-not-to-slow {
  -webkit-animation-duration: 620ms;
  animation-duration: 620ms;
}

.expand-sub-item-mobile {
  @extend %animated-not-to-slow;
  @extend %fadeIn;
}

.zoomMe {
  @extend %animated-not-to-slow;
  @extend %zoomIn;
}

.dashBarMetric {
  margin-top: -90px !important;
}

.emoticonBarMetric {
  margin-top: -75px !important;
}

.emoDashBarMetric {
  margin-top: -25px !important;
}

.barMetric {
  margin-top: -6vw;
  .metric-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 0.3vw;
    .options {
      display: flex;
      flex-direction: column;
      gap: 0.1vw;
      cursor: pointer;
      .labels-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 10px;
        width: 100%;
        font-size: 0.6vw;
        p:nth-child(1) {
          font-weight: 500;
        }
      }
      .individual-bar {
        width: 100%;
        height: 0.15vw;
        display: flex;
        align-items: center;
        .progressive-bar {
          width: 100%;
          height: 100%;
          background: rgb(230, 228, 228);
          border-radius: 50px;
          overflow: hidden;
          .rate-bar {
            height: 100%;
          }
        }
      }
    }
  }
}

.nvsBarMetric {
  margin-top: -7vw !important;
  height: 5vw;
  .metric-container {
    gap: 0.2vw;
  }
}



</style>
