<template>
  <div class="row center-lg center-md center-sm">
    <app-accordion
      class="col-lg-11 col-md-11 col-sm-11 accordion-section"
      :title="$t('survey.settings.colors')"
      :showOnStartup="false"
    >
      <colors/>
    </app-accordion>
    <app-accordion
      class="col-lg-11 col-md-11 col-sm-11 accordion-section"
      :title="$t('survey.settings.logos')"
      :showOnStartup="false"
    >
      <logos/>
    </app-accordion>
    <app-accordion
      class="col-lg-11 col-md-11 col-sm-11 accordion-section"
      :title="$t('survey.settings.animations')"
      :showOnStartup="false"
    >
      <animations/>
    </app-accordion>
  </div>
</template>
<script>
import logos from './Logos.vue'
import colors from './Colors.vue'
import animations from './Animations.vue'
export default {
  name: 'Theme',
  components: {
    logos,
    colors,
    animations,
    'app-accordion': () => import('@binds-tech/binds-design-system/src/components/Accordion/Accordion')
  },
  data () {
    return {}
  }
}
</script>
