import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

export const resendService = {
  getSurveysResend,
  getSchedulesResend,
  sendResend,
  deleteScheduledResend
}

/** get list of surveys to resend
 *
 * @param {*} queryQ - Objeto "q" da query
 * @returns {Promise<Object>} - Uma promessa que resolve em um objeto contendo os dados da consulta.
 */

async function getSurveysResend (queryQ) {
  const url = `${API_URL}resend-sendings/query-detail?q=${queryQ}`
  return await callApiService.callApi(url, 'get')
}

/**
 * @returns {Promise<Object>} - Uma promessa que resolve em um objeto contendo os dados da consulta.
 */

async function getSchedulesResend () {
  const url = `${API_URL}scheduled`
  return await callApiService.callApi(url, 'get')
}

/**
 *
 * @param {*} body Body com os parâmetros para a query.
 * @param {Object} body.metadata - Metadados para o reenvio.
 * @param {Object} body.q - Parâmetros do filtro avançado.
 * @param {Array} body.surveys - Surveys selecionadas para fazer o reenvio.
 * @param {String} body.title - Título para o reenvio.
 * @param {String} body.schedulingDate - Data de agendamento do reenvio.
 * @returns {Promise<Object>} - Uma promessa que resolve em um objeto contendo os dados da consulta.
 */

async function sendResend (body) {
  const url = `${API_URL}resend-sendings`
  return await callApiService.callApi(url, 'post', body)
}

/**
 *
 * @param {*} body Body com os parâmetros do envio a ser cancelado.
 * @param {Object} body.collector - Id do collector.
 * @param {Object} body.date - Data do agendamento a ser cancelado.
 * @param {Object} body.seedBatch - Id do seedBatch.
 * @returns {Promise<Object>} - Uma promessa que resolve em um objeto contendo os dados da consulta.
 */

async function deleteScheduledResend (payload) {
  const body = {
    collector: payload.collector,
    date: payload.schedulingDate,
    seedBatch: payload.seedBatch
  }

  const url = `${API_URL}scheduled/cancel`
  return await callApiService.callApi(url, 'post', body)
}
