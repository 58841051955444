<template>
  <div>
    <div v-if="question?.type !== 'matrix'">
      <div class="row center-xl center-lg center-md center-sm mt15">
        <button class="hide-when-print" style="display: flex;" @click="toggleLegendOrView('legend')" v-if="question?.type !== 'nps'">
          <i class="btn-icons material-icons tag-icon">{{ !hideOrShow ? 'visibility_off' : 'visibility' }}</i>
          <span class="title-button">{{$t('dashboard.legends')}}</span>
        </button>
        <button class="hide-when-print" style="display: flex; margin-left: 15px !important;" @click="toggleLegendOrView('view')" v-if="question?.type === 'kpi'">
          <i class="btn-icons toggle-view-btn material-icons">{{ !kpiByAverage ? 'toggle_on' : 'toggle_off' }}</i>
          <span class="title-button">{{ !kpiByAverage ? $t('dashboard.graphics.showing_responses') : $t('dashboard.graphics.showing_average') }}</span>
        </button>
      </div>
      <canvas
        v-if="question?.type !== 'matrix'"
        :id="makeSpecialId()"
        :class="{
          'mtOvertimeWithLegends': question?.type !== 'nps',
          'mtOvertime': question?.type === 'nps'
        }"
        :width="widthChart"
        :height="heightChart"
      ></canvas>
    </div>
    <div class="no-graphic-message" v-if="question?.type === 'matrix'">
      <app-heading level="h4">{{ $t('dashboard.graphics.no_overtime_matrix') }}</app-heading>
    </div>
  </div>
</template>
<script>

import { gridMaker, tooltipMaker, canvasMaker, formatters } from '@/_helpers'
import i18n from '../../../translations/i18n'
import('chart.js').then((chartjs) => {
  window.Chart = chartjs.default
})
import('lodash-es').then((lodashEs) => {
  window._ = lodashEs.default
})

export default {
  name: 'OvertimeChart',
  props: ['data', 'question', 'cardData', 'expanded', 'isHealth'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      currentChart: {},
      hideOrShow: false,
      canvasId: null,
      // Binds colour pallette: 16 colors will cover up untill 16 graphic lines
      colors: [
        '#a87df5', '#fc90a4', '#00aaa7', '#673ab7', '#9f95f5',
        '#FFB500', '#f55bed', '#CA1E54', '#4D5359', '#79C99E',
        '#97DB4F', '#27B573', '#FF7663', '#1B9AAA', '#FFC43D'
      ],
      fontSize: 18,
      fontFamily: "'Avenir', Helvetica, Arial, sans-serif;",
      kpiByAverage: true
    }
  },
  computed: {
    lang () { return this.$store.getters['account/getLang'] },
    widthChart () { return this.expanded ? '800' : '600' },
    heightChart () { return this.expanded ? '250' : '400' }
  },
  mounted () {
    this.renderChart()
  },
  watch: {
    // Colocamos async e await para chamar a função renderChart, ao invés de usar setTimeOut como antigamente.
    expanded: async function () {
      this.currentChart.destroy()
      await this.renderChart()
    }
  },
  methods: {
    makeSpecialId () {
      if (this.isHealth) {
        this.canvasId = canvasMaker.makeCanvasIdWithoutParam()
        return this.canvasId
      }

      this.canvasId = canvasMaker.makeCanvasId(this.question)

      return this.canvasId
    },
    toggleLegendOrView (param) {
      this.currentChart.destroy()
      this.makeSpecialId()
      if (param === 'legend' && this.currentChart.options) {
        this.hideOrShow = !this.hideOrShow
      } else {
        this.kpiByAverage = !this.kpiByAverage
      }

      if (this.question?.type === 'enum') {
        this.$root.$emit('expand-enum', { expand: this.hideOrShow, question: this.question?._id })
      }
      const self = this
      setTimeout(() => self.renderChart(), 100)
    },
    async renderChart () {
      if (!this.data) { return false }

      const groups = []
      const labels = []
      let lines = []
      let optionsValues = []
      let graphicValues = []

      // Returns just items that are not null, and we see it by the "qty" attribute
      let dataTime = this.data.overall.qty ? Object.assign({}, this.data) : []

      if (this.isHealth) {
        dataTime = {
          overtime: this.data?.overtime,
          overall: this.data?.overall
        }
      }

      const gridRange = gridMaker.checkIfPeriodIsByDay(this.$route.name, this.$route.query.q)
      let formatDate = 'MMM Do' // esse if é para saber se o filtro é por hora ou dias, com isso formatamos melhor os labels de data do gráfico
      if (gridRange) { formatDate = 'HH:mm' }

      // Para gráfico de mudança de average no tempo. Uma só linha.
      const nesOrNps10num = this.question?.ui === '10num' && (this.question?.type === 'nps' || this.question?.type === 'nes')
      const kpiQuestionAvg = this.question?.type === 'kpi' && this.kpiByAverage
      if (nesOrNps10num || kpiQuestionAvg || this.question?.ui === 'ynm' || this.isHealth) {
        const avgGrades = []
        if (!dataTime.overtime) {
          return false
        }
        dataTime.overtime.forEach((item, i) => {
          // devemos considerar um average vazio sem data certa?
          if (i > 0 && dataTime.overtime[i][2] !== null) {
            const avg = Math.round(dataTime.overtime[i][2] * 100) / 100
            const time = formatters.formatDateByLangAndFixTimeZone(dataTime.overtime[i][0], this.lang, formatDate)
            avgGrades.push(avg)
            labels.push(time)
          }
        })

        lines = [{
          label: i18n.t('dashboard.graphics.average'),
          data: avgGrades,
          borderColor: this.cardData.colors[1],
          pointBorderColor: this.cardData.colors[0],
          pointBackgroundColor: this.cardData.colors[0],
          backgroundColor: ['rgba(255, 255, 255, .2)'],
          borderWidth: 2
        }]

        optionsValues = {
          scales: {
            xAxes: [{
              ticks: {
                display: true // this will remove only the label
              }
            }]
          },
          legend: {
            display: this.hideOrShow,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 20
            }
          }
        }
        if (kpiQuestionAvg) {
          optionsValues.scales.yAxes = [{
            ticks: {
              max: 100,
              beginAtZero: true,
              precision: 0,
              padding: 25
            }
          }]
        }
      } else if ((['ynd', 'yn', 'single', 'singleOther', '1to10', 'multiple', 'multipleOther', '0to5', '1to7', '1to7button', '1to5', '5num', '0to10', '10num', '5emo', '5radio'].indexOf(this.question?.ui) > -1) && this.question?.type !== 'nes') {
        // Separar por grupos - START
        if (!dataTime.overtime) {
          return false
        }
        //  Gráfico de soma, conta a quantidade de QTY
        dataTime.overtime.forEach((item, i) => {
          // remove empty values
          if (i <= 0 || dataTime.overtime[i][0] === null || item[3] === null) {
            return false
          }
          const time = formatters.formatDateByLangAndFixTimeZone(dataTime.overtime[i][0], this.lang, formatDate)
          labels.push(time)
          // const groupName = ''
          if (this.question?.type === 'nes') {
            let totalME = 0
            let totalLE = 0
            let totalEE = 0
            dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
              if (indexOfMe >= 4) {
                if (overtimeHeader < 49) {
                  totalLE += item[indexOfMe]
                  return false
                }
                if (overtimeHeader === 50) {
                  totalEE += item[indexOfMe]
                  return false
                }
                totalME += item[indexOfMe]
              }
            })

            if (!groups[i18n.t('dashboard.graphics.le')]) {
              groups[i18n.t('dashboard.graphics.le')] = []
            }
            if (!groups[i18n.t('dashboard.graphics.ee')]) {
              groups[i18n.t('dashboard.graphics.ee')] = []
            }
            if (!groups[i18n.t('dashboard.graphics.me')]) {
              groups[i18n.t('dashboard.graphics.me')] = []
            }
            groups[i18n.t('dashboard.graphics.le')].push(totalLE)
            groups[i18n.t('dashboard.graphics.ee')].push(totalEE)
            groups[i18n.t('dashboard.graphics.me')].push(totalME)
          }

          if ((['csat', 'ces', 'nvs'].indexOf(this.question?.type) >= 0) && ['5emo', '1to5', '5num', '10num', '1to7', '1to7button', '5radio'].indexOf(this.question?.ui) >= 0) {
            /*
              CSAT, CES 2.0 e NVS devem mostrar somente a média (average) nas labels de cada ponto,
              tudo numa só linha.
            */
            const csatAverageRounded = Math.round(item[2] * 100) / 100
            if (!groups[i18n.t('dashboard.graphics.average')]) {
              groups[i18n.t('dashboard.graphics.average')] = []
            }
            groups[i18n.t('dashboard.graphics.average')].push(csatAverageRounded)
          }

          if (this.question?.type === 'kpi' && this.question?.ui === '5emo') {
            let verygood = 0
            let excelent = 0
            let satisfied = 0
            let verybad = 0
            let bad = 0

            dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
              if (indexOfMe >= 4) {
                switch (overtimeHeader) {
                  case '0': {
                    bad += item[indexOfMe]
                    break
                  }
                  case '25': {
                    verybad += item[indexOfMe]
                    break
                  }
                  case '50': {
                    satisfied += item[indexOfMe]
                    break
                  }
                  case '75': {
                    excelent += item[indexOfMe]
                    break
                  }
                  case '100': {
                    verygood += item[indexOfMe]
                  }
                }
              }
            })

            this.fontSize = 24
            this.fontFamily = 'icomoon'
            const labels = ['\uE608', '\uE607', '\uE606', '\uE600', '\uE603']
            const values = [verygood, excelent, satisfied, verybad, bad]

            labels.forEach((el, index) => {
              if (!groups[el]) {
                groups[el] = []
              }
              groups[el].push(values[index])
            })
          }

          if (this.question?.ui === 'yn') {
            let opt1 = 0
            let opt2 = 0
            dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
              if (indexOfMe >= 4) {
                if (overtimeHeader < 100) {
                  opt1 += item[indexOfMe]
                  return false
                }
                opt2 += item[indexOfMe]
              }
            })

            if (!groups[i18n.t('dashboard.graphics.op1')]) {
              groups[i18n.t('dashboard.graphics.op1')] = []
            }

            if (!groups[i18n.t('dashboard.graphics.op2')]) {
              groups[i18n.t('dashboard.graphics.op2')] = []
            }

            groups[i18n.t('dashboard.graphics.op1')].push(opt1)
            groups[i18n.t('dashboard.graphics.op2')].push(opt2)
          }

          if (this.question?.ui === 'ynd') {
            let opt1 = 0
            let opt2 = 0
            let opt3 = 0
            dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
              if (indexOfMe >= 4) {
                if (overtimeHeader === 0) {
                  opt3 += item[indexOfMe]
                  return false
                }
                if (overtimeHeader === 50) {
                  opt2 += item[indexOfMe]
                  return false
                }
                opt1 += item[indexOfMe]
              }
            })

            if (!groups[i18n.t('dashboard.graphics.op1')]) {
              groups[i18n.t('dashboard.graphics.op1')] = []
            }
            if (!groups[i18n.t('dashboard.graphics.op4')]) {
              groups[i18n.t('dashboard.graphics.op4')] = []
            }
            if (!groups[i18n.t('dashboard.graphics.op2')]) {
              groups[i18n.t('dashboard.graphics.op2')] = []
            }
            groups[i18n.t('dashboard.graphics.op1')].push(opt1)
            groups[i18n.t('dashboard.graphics.op4')].push(opt2)
            groups[i18n.t('dashboard.graphics.op2')].push(opt3)
          }

          if (this.question?.type === 'enum') {
            const results = {}
            dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
              if (indexOfMe >= 4) {
                if (!results[overtimeHeader]) {
                  results[overtimeHeader] = { key: overtimeHeader, doc_count: 0 }
                }
                results[overtimeHeader].doc_count = item[indexOfMe] || 0
                return false
              }

              Object.keys(results).forEach(currentKey => {
                if (!groups[currentKey]) {
                  groups[currentKey] = []
                }
                groups[currentKey].push(results[currentKey].doc_count)
              })
            })
            Object.keys(results).forEach(currentKey => {
              if (!groups[currentKey]) {
                groups[currentKey] = []
              }
              groups[currentKey].push(results[currentKey].doc_count)
            })
          }
        })

        graphicValues = []
        for (const groupName in groups) {
          graphicValues.push({ group: groupName, doc_count: groups[groupName] })
        }

        optionsValues = {
          responsive: true,
          tooltips: {
            backgroundColor: '#227799',
            bodyFontFamily: this.fontFamily,
            callbacks: {
              afterBody: function (tooltipItem) {
                const value = parseFloat(tooltipItem.value)
                return value
              }
            }
          },
          legend: {
            display: this.hideOrShow,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 8,
              boxWidth: 9,
              fontFamily: this.fontFamily,
              fontSize: this.fontSize,
              fontColor: '#939393'
            }
          },
          pointStyle: 'circle',
          lineTension: 1,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                precision: 0,
                padding: 25
              }
            }],
            xAxes: [{
              ticks: {
                display: true
              }
            }]
          }
        }
        lines = graphicValues.map((line, index) => {
          const color = this.colors[index]
          const newLine = {
            label: line.group,
            data: line.doc_count,
            borderColor: color,
            pointBorderColor: color,
            pointBackgroundColor: color,
            backgroundColor: ['rgba(255, 255, 255, .2)'],
            borderWidth: 2
          }
          return newLine
        })
        // Separar por grupos - END
      } else if (this.question?.type === 'matrix') {
        const results = {}
        if (!dataTime.overtime) {
          return false
        }
        dataTime.overtime.forEach((item, i) => {
          if (i <= 0 || dataTime.overtime[i][0] === null || item[3] === null) {
            return false
          }
          const time = formatters.formatDateByLangAndFixTimeZone(dataTime.overtime[i][0], this.lang, formatDate)
          labels.push(time)
          dataTime.overtime[0].forEach(async (overtimeHeader, indexOfMe) => {
            if (indexOfMe >= 3) {
              const findLabel = await window._.find(this.question?.subjects, { _id: overtimeHeader })
              if (findLabel) {
                if (!results[findLabel.label]) {
                  results[findLabel.label] = { key: findLabel.label, doc_count: 0 }
                }
                const value = item[indexOfMe] === 0 ? 0 : item[indexOfMe] / 10
                results[findLabel.label].doc_count = value
                return false
              }
            }
          })

          Object.keys(results).forEach(currentKey => {
            if (!groups[currentKey]) {
              groups[currentKey] = []
            }
            groups[currentKey].push(results[currentKey].doc_count)
          })
        })

        graphicValues = []
        for (const groupName in groups) {
          graphicValues.push({ group: groupName, doc_count: groups[groupName] })
        }

        optionsValues = {
          responsive: true,
          tooltips: {
            backgroundColor: '#227799',
            callbacks: {
              afterBody: function (tooltipItem) {
                const value = parseFloat(tooltipItem.value)
                return value
              }
            }
          },
          legend: {
            display: this.hideOrShow,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 20
            }
          },
          pointStyle: 'circle',
          lineTension: 1,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                precision: 0,
                padding: 25
              }
            }],
            xAxes: [{
              ticks: {
                display: true
              }
            }]
          }
        }
        lines = graphicValues.map((line, index) => {
          const colour = this.cardData.colors ? this.cardData.colors[index] : this.colors[index]
          const newLine = {
            label: line.group,
            data: line.doc_count,
            borderColor: colour,
            pointBorderColor: colour,
            pointBackgroundColor: colour,
            backgroundColor: ['rgba(255, 255, 255, .2)'],
            borderWidth: 2
          }
          return newLine
        })
      } else {
        // gráfico de média dos valores, somando item.average
        const valueGroup = []
        dataTime.forEach((item, index) => {
          const value = Math.round(item.average * 100) / 100
          const time = formatters.formatDateByLangAndFixTimeZone(item.from, this.lang, formatDate)
          graphicValues.push(value)
          labels.push(time)
          valueGroup[index] = item.valueGroup
        })
        lines = [{
          data: graphicValues,
          borderColor: this.cardData.colors[1],
          pointBorderColor: this.cardData.colors[0],
          pointBackgroundColor: this.cardData.colors[0],
          backgroundColor: ['rgba(255, 255, 255, .2)'],
          borderWidth: 2,
          valueGroup: valueGroup
        }]
        optionsValues = {
          tooltips: {
            enabled: false,
            custom: function (tooltipModel) {
              var position = this._chart.canvas.getBoundingClientRect()
              tooltipMaker.makeCustomTooltip(tooltipModel, lines, position, lines[0].pointBackgroundColor)
            }
          },
          responsive: true,
          legend: {
            display: this.hideOrShow,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 20
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                precision: 0
              }
            }],
            xAxes: [{
              ticks: {
                display: true
              }
            }]
          }
        }
      }

      if (this.question?.type === 'enum') {
        optionsValues.legend.position = 'right'
        optionsValues.legend.rtl = true
      }

      const chartData = {
        type: 'line',
        data: {
          labels: labels,
          datasets: lines
        },
        options: optionsValues
      }

      if (chartData.data.datasets) {
        const ctx = document.getElementById(this.canvasId)
        this.currentChart = await new window.Chart(ctx, {
          type: chartData.type,
          data: chartData.data,
          options: chartData.options,
          plugins: [{
            afterLayout: function (chart) {
              chart.legend.legendItems.map(label => {
                const formattedLabel = label.text.length > 40 ? label.text.slice(0, 50) + '...' : label.text
                label.text = formattedLabel
              })
            }
          }]
        })
        if (this.question?.type === 'matrix') {
          this.currentChart.canvas.parentNode.style.margin = '0 auto'
        }
        return this.currentChart
      }
    }
  }
}
</script>

<style lang="scss">
.mtOvertimeWithLegends {
  margin-top: 20px;
}
.mtOvertime {
  margin-top: 50px;
}
.title-button {
  display: flex;
  font-size: 11px;
  margin-left: 5px;
  margin-top: 4px;
  color: #b5b5b5;
  align-items: center;
}
.toggle-view-btn {
  font-size: 26px !important;
}
.matrix-overtime-content{
  max-width: 64%;
  display: block;
  position: absolute;
  top: 20%;
  left: 20%;
}
.no-graphic-message {
  text-align: center;
  margin-top: 20%;
}
</style>
