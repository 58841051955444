function initialState () {
  return {
    cropWidth: undefined,
    sms: '',
    emailSettings: emptyEmailSettings(),
    linkSettings: emptyLinkSettings(),
    totenSettings: emptyTotenSettings(),
    link: '',
    totenLink: '',
    collection: '',
    footerHtml:
    [
      '<table border="0" cellpadding="0" cellspacing="0" width="600" style="font-family:Arial,sans-serif;font-weight:100;background:#ffffff;margin: 0 auto"> \n',
      '<tbody> \n',
      '<tr> \n',
      '<td align="center" valign="top" style="padding-top:40px"> \n ',
      '<br> \n',
      '<table width="600" cellspacing="0" cellpadding="0" border="0"> \n',
      '<tbody> \n',
      '<div class="email-cta">',
      '<table cellspacing="0" cellpadding="0"> \n',
      '<tr> \n',
      '<td align="center" width="300" height="70" bgcolor="{{color.c3}}" style="-webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; color: {{color.c2}}; display: block;"> \n ',
      '<a href="*|SRVYES|*" style="font-size:24px; font-family:Arial,sans-serif; text-decoration: none; line-height:70px; width:100%; display:inline-block"> <span style="color: {{color.c1}}" contenteditable=""> Avaliar</span> </a> \n ',
      '</td> \n',
      '</tr> \n',
      '</table> \n ',
      '</tbody> \n',
      '</table> \n ',
      '</div> \n',
      '</td> \n',
      '</tr> \n',
      '<tr> \n',
      '<td align="center" valign="top"> \n',
      '<br> \n',
      '<br> \n',
      '<br> \n',
      '<br> \n',
      '<div style="font-weight:200;color:{{color.c4}};font-size:12px;">Se não quiser mais receber este tipo de mensagem, <a href="*|OPTOUT|*" style="color:{{color.c2}};text-decoration:underline;" target="_blank">cancele sua inscrição</a>.</div> \n ',
      '<br> \n',
      '<br> \n',
      '<br> \n',
      '</td> \n',
      '</tr> \n',
      '</tbody> \n',
      '</table>'
    ].join('')
  }
}
const state = initialState

/**
 * Empty function to return default values to front and start state
 */
function emptyEmailSettings () {
  return {
    account: '',
    colors: {},
    createdAt: '',
    fonts: [],
    hasSurvey: '',
    emailSettings: {},
    hasEmbeddedQuestion: false,
    html: '',
    images: [],
    sms: '',
    whatsapp: '',
    templatesWpp: '',
    subject: '',
    template: '',
    title: '',
    updatedAt: '',
    _id: ''
  }
}

/**
 * Empty function to return default values to front and start state
 */
function emptyLinkSettings () {
  return {
    allowMoreThanOne: false,
    showEndSurveyButton: false,
    seconds: 30,
    endSurveyByInactivity: false
  }
}

function emptyTotenSettings () {
  return {
    allowMoreThanOne: false,
    showEndSurveyButton: false,
    seconds: 30,
    endSurveyByInactivity: false
  }
}

const mutations = {
  resetToInitialState (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  cropWidth (state, width) {
    state.cropWidth = width
  },
  sendhub (state, sendhub) {
    Object
      .keys(sendhub)
      .forEach(key => {
        const value = sendhub[key]
        state[key] = value
      })
  },
  startEmptyEmail (state) {
    state.emailSettings = emptyEmailSettings()
  },
  updateImageProps (state, image) {
    state.emailSettings.images[0] = { value: image.value, alias: 'logo' }
  },
  updateImageWpp (state, image) {
    state.emailSettings.images[1] = { value: image.value, alias: 'whatsapp' }
  },
  updateWpp (state, payload) {
    state.emailSettings.whatsapp = payload
  },
  updateTemplateWpp (state, payload) {
    state.emailSettings.templatesWpp = payload
  },
  updateEmailSettings (state, settings) {
    state.emailSettings = settings
  },
  resetWpp (state) {
    state.emailSettings.whatsapp = ''
  }
}

const getters = {
  getEmailSettings: state => state.emailSettings,
  getLinkSettings: state => state.linkSettings,
  getTotenSettings: state => state.totenSettings,
  getLink: state => state.link,
  getTotenLink: state => state.totenLink,
  getSms: state => state.sms,
  getCollection: state => state.collection,
  getFooterEmail: state => state.footerHtml,
  getCropWidth: state => state.cropWidth
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
