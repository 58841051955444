<template>
  <div class="container-text-ui">
    <div class="content-text-ui">
      <div class="section-text-ui">
        <div class="most-chosen-option-text-ui">
          <p class="title-section-ui">{{ $t('dashboard.textUi.option') }}</p>
          <p class="label-section-ui">{{ firstBubble.key }}</p>
        </div>
        <button v-if="checkUiEnum && firstBubble.key === 'Outros'" class="see-more-others" @click="showResponsesOthers = true" v-title="$t('tags.see_more_responses')">
          <p class="title-see-all">{{ $t('tags.see_all') }}</p>
          <i class="material-icons-outlined see-more-text-ui">forum</i>
        </button>
        <div class="number-choices-text-ui">
          <p class="title-choices-ui">{{ $t('dashboard.textUi.choices') }} <span ref="my-value-text-ui">{{ firstBubble.count }}</span></p>
        </div>
      </div>
    </div>
    <div class="content-label-ui" v-if="firstBubble.percentage">
      <p class="text-label-ui">{{ $t('dashboard.most_choosen.1') }} <span>{{ parseInt(this.firstBubble.percentage) }}%</span>{{ $t('dashboard.most_choosen.2') }}</p>
    </div>
    <TextModalOthers v-if="showResponsesOthers" :data="data" />
  </div>
</template>
<script>
import gsap from 'gsap'
import TextModalOthers from './TextModalOthers.vue'

export default {
  name: 'TextUi',
  props: ['data', 'cardData'],
  components: {
    TextModalOthers
  },
  data () {
    return {
      showResponsesOthers: false,
      firstBubble: {
        key: '',
        count: 0,
        percentage: 50
      }
    }
  },
  mounted () {
    this.$root.$on('close-modal-others', () => { this.showResponsesOthers = false })

    if (this.data.average) {
      this.data.average.forEach(option => {
        if (option.doc_count > this.firstBubble.count) {
          this.firstBubble.count = option.doc_count
          this.firstBubble.key = option.key
          this.firstBubble.percentage = option.percentage
        }
      })

      this.animateValue()
    }
  },
  computed: {
    checkUiEnum () {
      const uiValuesWithEnum = ['multipleOther', 'singleOther']
      return uiValuesWithEnum.includes(this.data.question.ui)
    }
  },
  methods: {
    animateValue () {
      const myValue = this.$refs['my-value-text-ui']

      myValue.innerHTML = 0

      gsap.to(myValue, {
        duration: 1.5,
        innerHTML: this.firstBubble.count,
        snap: 'innerHTML',
        onUpdate: () => {
          myValue.textContent = Math.ceil(myValue.innerHTML).toLocaleString()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.container-text-ui {
  width: 100%;
  height: 100%;
  min-height: 200px;
  padding: 0.5vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5vw;
  .content-text-ui {
    width: 100%;
    height: 80%;
    border-radius: 0.5vw;
    background: linear-gradient(to right, #EA484D95, #F8C43D95, #9FCD3595);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    padding: 0.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .section-text-ui {
      width: 100%;
      height: 100%;
      background: #ffffff;
      box-shadow: 0 0 4px #ffffff;
      border-radius: 0.4vw;
      padding: 0.5vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 0.5vw;
      .most-chosen-option-text-ui {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.7vw;
        color: #959191;
        .title-section-ui {
          font-size: 0.8vw;
        }
        .label-section-ui {
          color: #454548;
          font-weight: 600;
          font-size: 1.1vw;
          line-height: 1.2vw;
          text-align: center;
        }
      }
      .see-more-others {
        width: fit-content;
        background: var(--accent-color);
        color: var(--default-text-color);
        padding: 0.2vw 0.3vw;
        opacity: 0.8;
        border-radius: 0.2vw;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
        .title-see-all {
          font-size: 0.75vw;
        }
        .see-more-text-ui {
          font-size: 1vw;
        }
      }
      .see-more-others:hover {
        opacity: 1;
      }
      .number-choices-text-ui {
        width: 100%;
        height: 40%;
        color: #959191;
        display: flex;
        align-items: center;
        justify-content: center;
        .title-choices-ui {
          font-size: 0.8vw;
          display: flex;
          align-items: center;
          gap: 0.5vw;
          span {
            color: #454548;
            font-weight: 700;
            font-size: 1.4vw;
          }
        }
      }
    }
  }
  .content-label-ui {
    width: 100%;
    height: 20%;
    border-radius: 0.3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .text-label-ui {
      color: #454548;
      font-size: 0.75vw;
      text-align: center;
      span {
        font-weight: 500;
        color: #000000;
      }
    }
  }
}
</style>
