<template>
  <div>
    <div class="row content-header-wordcloud" >
      <app-card class="overview-card card-header-wordcloud">
        <div class="overview-card-row">
          <div class="dashboard-content-stats">
            <div>
              <app-heading level="h4">{{statsUpdated.totalSendings ? statsUpdated.totalSendings.toLocaleString(lang) : 0}}</app-heading>
              <app-heading level="h6">{{$t('analytics.sendings')}}</app-heading>
            </div>
            <div>
              <app-heading level="h4">{{statsUpdated.totalAnswers ? statsUpdated.totalAnswers.toLocaleString(lang) : 0}}</app-heading>
              <app-heading level="h6">{{$t('analytics.answers')}}</app-heading>
            </div>
            <div>
              <app-heading level="h4">{{statsUpdated.responseAverageTime ? statsUpdated.responseAverageTime : 0.0}} min</app-heading>
              <app-heading level="h6">{{$t('analytics.time')}}</app-heading>
            </div>
          </div>
          <div class="search-tag-analytics">
            <div class="form-search-tag">
              <div style="width: 100%;">
                <app-form-input secondary border-solid v-model="searchTag" placeholder="Buscar"  v-on:keyup.native="clearSearch" v-on:keyup.enter="search()"></app-form-input>
              </div>
              <div class="tag-button-search">
                <app-button inline v-on:click="search()"><i class="material-icons">search</i></app-button>
              </div>
              <div class="empty-term">
                <app-heading level="h5" v-if="foundTerm">{{ $t('dashboard.therm_not_found') }}</app-heading>
              </div>
            </div>
          </div>
          <div class="dashboard-content-options" style="height:72px;" data-html2canvas-ignore="true">
            <div class="options1-dash">
              <div>
                <app-button
                  inline
                  style="width:70px;"
                  :class="{tvDashButtomColorActive: typeCloud === 'bubbles'}"
                  v-on:click="selectTypeClouds('bubbles')"
                >
                  <div style="width: 91px;">
                    <i style="font-size:25px;" class="material-icons">bubble_chart</i>
                    <span
                      :class="{tvDashButtomColorActive: typeCloud === 'bubbles'}"
                      style="font-size: 12px;line-height: 17px;height: 12px;display: block;"
                    >
                      <app-textstyle
                        color="gray"
                        style="font-size:12px;"
                        :class="{tvDashButtomColorActive: !!tvDashIsOpen}"
                      >{{ $t('dashboard.bubbles') }}</app-textstyle>
                    </span>
                  </div>
                </app-button>
              </div>
              <div>
                <app-button
                  inline
                  :class="{tvDashButtomColorActive: typeCloud === 'list'}"
                  v-on:click="selectTypeClouds('list')"
                >
                  <div style="width: 91px;">
                    <i style="font-size:25px;" class="material-icons">sort</i>
                    <span
                      :class="{tvDashButtomColorActive: typeCloud === 'list'}"
                      style="font-size: 12px;line-height: 17px;height: 12px;display: block;"
                    >
                      <app-textstyle
                        color="gray"
                        style="font-size:12px;"
                        :class="{tvDashButtomColorActive: !!tvDashIsOpen}"
                      >{{ $t('dashboard.list') }}</app-textstyle>
                    </span>
                  </div>
                </app-button>
              </div>
            </div>
            <!-- END change types -->
            <div class="options2-dash">
              <div>
                <app-button
                style="width: 100%;padding: 11px 0;"
                  inline
                  icon="fullscreen"
                  icon-color="gray"
                  icon-size="sm"
                  :class="{tvDashButtomColorActive: !!tvDashIsOpen}"
                  v-on:click="tvDashPage()"
                >
                  <app-textstyle
                    color="gray"
                    style="font-size: 12px;"
                    :class="{tvDashButtomColorActive: !!tvDashIsOpen}"
                  >TV Dash</app-textstyle>
                </app-button>
              </div>
            </div>
            <div v-if="typeCloud === 'list' && !hideExports">
              <app-button
                inline
                icon="download"
                icon-color="gray"
                icon-size="sm"
                class="colored"
                v-on:click="exportPDF()"
              >
                <div class="">
                  <app-textstyle color="gray" size="size-xxs" :class="{tvDashButtomColorActive: !!tvDashIsOpen}">{{ $t('export.title') }}</app-textstyle>
                </div>
              </app-button>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchTerm',
  props: ['statsUpdated'],
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle')
  },
  data () {
    return {
      searchTag: '',
      foundTerm: '',
      typeCloud: 'bubbles',
      tvDashIsOpen: false,
      questionSelected: {
        question: 'Escolha a Questao'
      }
    }
  },
  computed: {
    currentSurveyStats () { return this.$store.getters['dashboard/getCurrentSurveyStats'] },
    lang () { return this.$store.getters['account/getLang'] },
    user () { return this.$store.getters['account/getUser'] },
    hideExports () { return this.user.features.hideExports }
  },
  methods: {
    exportPDF () {
      // Vai ficar comentado porque não terá exportação de responses por enquanto.
      // this.typeCloud === 'bubbles' ? this.$emit('export-cloud-word') : this.$root.$emit('export-list-word')
      // this.$root.$emit('export-responses-cloud')
      this.$root.$emit('export-list-word')
    },
    search () {
      this.$root.$emit('searchCloud', this.searchTag)
    },
    selectTypeClouds (u) {
      this.typeCloud = u
      this.$emit('typeCloud', this.typeCloud)
    },
    clearSearch () {
      if (!this.searchTag) {
        this.$root.$emit('searchCloud', '')
      }
    },
    tvDashPage () {
      if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen()
          // mozzila
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        }
        return false
      }

      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }
}
</script>

<style lang="scss">
.search-tag-analytics {
  max-width: 1272px;
  padding-right: 102px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  height: 95px;
  .empty-term{
      .heading {
          margin-top: 20px;
          color: #ff607d !important;
      }
  }
  .form-search-tag{
      position: relative;
      width: 250px;
      .tag-button-search {
          position: absolute;
          top:4px;
          right: 0;
          i{
              color: $color-gray;
              font-weight: bold;
              font-size: 28px;
          }
      }
  }
}
.select-question-analytics {
  .v-select {
    min-width: 300px !important;
    background-color: #fff !important;
  }
  #vs2__combobox {
    width: 600px;
  }
}

.content-header-wordcloud .dashboard-content-options{
  padding-top: 0;
  margin-top: 0;
}
.content-header-wordcloud .dashboard-content-stats,
.content-header-wordcloud .search-tag-analytics{
  padding-top: 7px;
  margin-top: 0;
}
.card-header-wordcloud {
  max-width: 92% !important;
  width: 92% !important;
  padding: 0px !important;
}
@media screen and (max-width: 800px) {
  .search-tag-analytics {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    padding: 0 5%;
    .form-search-tag {
      width: 100%;
    }
  }
  .select-question-analytics {
    margin-bottom: 20px;
    .v-select {
      width: 100% !important;

    }
  }
}
</style>
