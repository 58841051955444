function initialState () {
  return {
    default: {
      others: false,
      minCharacters: 0,
      maxCharacters: 200,
      selectedReferralOption: 'nep',
      randomOptions: false,
      otherLabel: '',
      leftLabel: 'Satisfied',
      rightLabel: 'Unsatisfied'
    },
    enableButtonToSave: true,
    // question referral
    referral: {
      addLabel: '',
      name: {
        type: 'name',
        active: false,
        required: true,
        placeholder: 'Name',
        label: ''
      },
      email: {
        type: 'email',
        active: false,
        required: true,
        placeholder: 'Email',
        label: ''
      },
      phone: {
        type: 'phone',
        active: false,
        required: true,
        placeholder: 'Phone',
        label: ''
      },
      url: {
        type: 'url',
        active: false,
        required: true,
        placeholder: 'URL',
        label: ''
      }
    },
    options: [
      { label: 'opção 1', value: 'opção 1' },
      { label: 'opção 2', value: 'opção 2' },
      { label: 'opção 3', value: 'opção 3' }
    ]
  }
}
const state = initialState

const mutations = {
  resetToInitialState (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  enableButtonSave (state, payload) {
    state.enableButtonToSave = payload
  }
}

const getters = {
  getDefaultSettings: state => state.default,
  getOptions: state => state.options,
  getReferral: state => state.referral,
  getEnableButtonSave: state => state.enableButtonToSave
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
