<template>
  <div class="container-chart-controller">
    <div class="section-chart-controller" v-if="view === 'average' && cardData.average">
      <emote-gauge
        :data="data"
        :question="cardData.question"
        :cardData="cardData"
        v-if="cardData.average.graphicType === 'emoteGauge'"
      ></emote-gauge>
      <bar-with-percentage
        :data="data"
        :question="cardData.question"
        :cardData="cardData.average"
        v-if="cardData.average.graphicType === 'percentageBar'"
      ></bar-with-percentage>
      <bar-horiz-chart
        v-if="cardData.average.graphicType === 'horizontalBar'"
        :data="data"
        :cardData="cardData.average"
        :question="cardData.question"
      ></bar-horiz-chart>
      <Gauge
        v-if="cardData.average.graphicType === 'rangeGauge'"
        :data="data"
        :cardData="cardData.average"
        :question="cardData.question"
      ></Gauge>
      <num-ui
        v-if="cardData.average.graphicType === 'numUi'"
        :data="data"
        :cardData="cardData.average"
      ></num-ui>
      <text-ui
        v-if="cardData.average.graphicType === 'textUi'"
        :data="data"
        :cardData="cardData.average"
      ></text-ui>
      <kpi-ynd
        v-if="cardData.average.graphicType === 'kpiYnd' || cardData.average.graphicType === 'kpiYn'"
        :data="data"
        :cardData="cardData.average"
      ></kpi-ynd>
    </div>
    <div class="section-chart-controller chart-overtime" v-if="view === 'overtime' && cardData.overtime">
      <like-overtime
        v-if="cardData.overtime.graphicType === 'likeOvertime'"
        :data="data"
        :cardData="cardData.overtime"
        :question="cardData.question"
        :expanded="cardData.isExpanded">
      </like-overtime>
      <overtime-chart
        :isHealth="isHealth"
        :data="data"
        :cardData="cardData.overtime"
        :question="cardData.question"
        :expanded="cardData.isExpanded"
        v-if="cardData.overtime.graphicType !== 'likeOvertime'"
      ></overtime-chart>
    </div>
    <div class="section-chart-controller" v-if="view === 'overall' && cardData.overall">
      <overall
        v-if="cardData.overall.graphicType === 'defaultOverall'"
        :data="data"
        :cardData="cardData.overall"
        :question="cardData.question"
      ></overall>
      <vertical-bar
        v-if="cardData.overall.graphicType === 'verticalBar'"
        :data="data"
        :cardData="cardData.overall"
        :question="cardData.question"
      ></vertical-bar>
      <pie-chart
        v-if="cardData.overall.graphicType === 'pie'"
        :data="data"
        :cardData="cardData.overall"
        :question="cardData.question"
      ></pie-chart>
      <bar-horiz-chart
        v-if="cardData.overall.graphicType === 'horizontalBar'"
        :data="data"
        :cardData="cardData.overall"
        :question="cardData.question"
        :expanded="cardData.isExpanded"
      ></bar-horiz-chart>
      <like-overall
        v-if="cardData.overall.graphicType === 'likeOverall'"
        :data="data"
        :cardData="cardData.overall"
        :question="cardData.question"
        :expanded="cardData.isExpanded">
      </like-overall>
    </div>
  </div>
</template>

<script>
import EmoteGauge from './EmoteGauge.vue'
import Gauge from './Gauge.vue'
import NumUi from './NumUi.vue'
import TextUi from './TextUi.vue'
import KpiYnd from './KpiYnd.vue'
import BarWithPercentage from './BarWithPercentage.vue'
import OvertimeChart from './Overtime.vue'
import Overall from './Overall.vue'
import VerticalBar from './Bar.vue'
import PieChart from './Pie.vue'
import BarHorizChart from './BarHorizChart.vue'
import likeOverall from './likeOverall.vue'
import likeOvertime from './likeOvertime.vue'

export default {
  name: 'ChartController',
  props: ['cardData', 'data', 'view', 'isHealth'],
  components: {
    EmoteGauge,
    Gauge,
    NumUi,
    TextUi,
    KpiYnd,
    BarWithPercentage,
    OvertimeChart,
    Overall,
    VerticalBar,
    PieChart,
    BarHorizChart,
    likeOverall,
    likeOvertime
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">
.container-chart-controller {
  width: 100%;
  height: 100%;
  .section-chart-controller {
    width: 100%;
    height: 100%;
  }
}
.chart-overtime {
  height: fit-content !important;
}

.content-view-area {
  text-align: center;
}
.isActive {
  color: $color-purple-dark;
}
.data-loading {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  .data-loading-spinner {
    margin-left: 46%;
    margin-top: 220px !important;
    & .spinner-inner {
      background-color: #fff !important;
    }
  }
}
.setLabel {
  font-family: inherit;
  font-size: 0.7vw;
  height: 2vw;
  color: #000000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
