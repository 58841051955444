<template>
  <div class="container-config-card-sidebar">
    <div class="content-config-card-sidebar">
      <header class="header-config-card-sidebar">
        <i class="material-icons close-sidebar-icon" @click="closeOrOpenSidebar(true)">cancel</i>
        <h5 class="header-title-config-card-sidebar">{{ $t('dashboard.change_what_display') }}</h5>
      </header>
      <div class="section-custom-config-card-sidebar">
        <div class="option-custom-config-card">
          <p class="title-to-options">{{ $t('dashboard.graphics.actions') }}:</p>
          <div class="content-option-config-card">
            <button class="btn-action-config-card btn-clone" v-if="clonedCard.type !== 'text'" @click="showModalAlert('clone')">{{ $t('dashboard.graphics.clone_card') }}</button>
            <button class="btn-action-config-card" @click="showModalAlert('delete')">{{ $t('dashboard.graphics.delete_card') }}</button>
          </div>
        </div>
        <div class="option-custom-config-card">
          <p class="title-to-options">{{ $t('dashboard.choose_title') }}:</p>
          <div class="content-option-config-card">
            <div class="input-text">
              <InputText
                :placeholderInput="$t('survey_store.input_textarea')"
                :value="clonedCard.title"
                @update-model="clonedCard.title = $event"
              />
            </div>
          </div>
        </div>
        <div class="option-custom-config-card">
          <p class="title-to-options">{{ $t('dashboard.filter_advanced') }}:</p>
          <button class="btn-add-filter-card" @click="filterIsOpen = !filterIsOpen" v-title="$t('dashboard.filter_advanced')" title-placement="left">
            <i class="material-icons icon-open-filter-advanced">filter_alt</i>
            <i class="material-icons icon-check-filter-advanced" v-if="clonedCard.query">check</i>
          </button>
        </div>
        <div v-title="$t('dashboard.not_edit_question')" style="width: 100%;">
          <div class="option-custom-config-card disabled-option">
            <p class="title-to-options">{{ $t('dashboard.graphics.change_question') }}</p>
            <app-select-binds
              :options="validQuestions"
              titleItem="question"
              id="current-question-card"
              :value="currentQuestion"
              @model="currentQuestion = $event"
              @input="getCharts()"
              style="width: 100%; min-height: 2rem;"
            />
          </div>
        </div>
        <div class="option-custom-config-card" v-if="currentQuestion.type && currentQuestion.type !== 'text'">
          <p class="title-to-options">{{ $t('dashboard.graphics.options') }}</p>
          <app-select-binds
            :options="charts"
            titleItem="name"
            id="current-chart"
            :value="chart"
            @model="chart = $event"
            @input="createColorPalette()"
            style="width: 100%; min-height: 2rem;"
          />
        </div>
        <div class="option-custom-config-card" v-if="chart.graphicType === 'horizontalBar' && currentQuestion.type.toLowerCase() === 'nps'">
          <p class="title-to-options" v-if="currentQuestion.type !== 'text'">{{ $t('dashboard.graphics.choose_display_graphics') }}</p>
          <app-select-binds
            :options="npsDisplayingTypes"
            titleItem="label"
            id="current-chart"
            :value="selectedNpsType"
            @model="selectedNpsType = $event"
            @input="createColorPalette()"
            style="width: 100%; min-height: 2rem;"
          />
        </div>
        <div class="option-custom-config-card" v-if="currentQuestion.type !== 'text' && colorPalettePreview.length > 0 && currentQuestion.type !== 'enum' && chart.graphicType !== 'likeOvertime'">
          <p class="title-to-options">{{ $t('dashboard.graphics.choose_color') }}</p>
          <input class="main-color" type="color" v-model="mainColor" @input="createColorPalette()"/>
        </div>
        <div class="option-custom-config-card" v-if="currentQuestion.type !== 'enum' && currentQuestion.type !== 'text'">
          <p class="title-to-options">{{ $t('dashboard.graphics.generated_palette') }}</p>
          <div class="palette-container" v-if="clonedCard.colors.length">
            <div class="my-palette" v-for="(color, index) in clonedCard.colors" :key="index">
              <input class="palette" type="color" v-model="clonedCard.colors[index]"/>
            </div>
          </div>
        </div>
        <button class="btn-create-config-card" @click="updateCard()">{{ $t('dashboard.update_card') }}</button>
      </div>
      <div class="content-loading-config-card-custom" v-show="isLoading">
        <Circle2 class="spinner-social-config-card"/>
      </div>
    </div>
    <FilterModal v-if="filterIsOpen" :queryCard="clonedCard.query" @close="filterIsOpen = false" entity="customDashboard" @query-to-custom-card="updateQuery($event)" @remove-filter-dash-card="removeQuery()" />
    <app-modal
      v-model="showModal"
      :title="messageModal"
      lightbox
      close
      class="modal-default-primary"
      :primary-button="$t('annotation.yes')"
      @primaryButtonClick="orderModal()"
      :secondary-button="$t('annotation.no')"
      @secondaryButtonClick="showModal = false">
    </app-modal>
  </div>
</template>

<script>
import { dashboardsService } from '@/_services'
import { responseOptions } from '@/_helpers'
import InputText from '../../inputs/InputText.vue'
import gsap from 'gsap'
import('color-scheme').then((ColorScheme) => { window.ColorScheme = ColorScheme.default })
import('color').then((Color) => { window.Color = Color.default })

export default {
  name: 'ConfigCardCustom',
  props: ['card'],
  components: {
    InputText,
    FilterModal: () => import('@/components/filters/FilterModal.vue'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2'),
    'app-select-binds': () => import('@binds-tech/binds-design-system/src/components/Select/SelectBinds'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal')
  },
  data () {
    return {
      messageModal: '',
      showModal: false,
      clonedCard: structuredClone(this.card),
      isLoading: false,
      currentQuestion: '',
      expandCard: false,
      filterIsOpen: false,
      charts: [],
      chart: this.$i18n.t('dashboard.graphics.choose'),
      mainColor: '',
      colorPalettePreview: [],
      validQuestions: [],
      npsDisplayingTypes: [
        { label: this.$i18n.t('dashboard.graphics.by_metric'), value: 'metric' },
        { label: this.$i18n.t('dashboard.graphics.by_responses'), value: 'responses' }
      ],
      selectedNpsType: '',
      optionsLength: 0
    }
  },
  beforeDestroy () {
    this.$store.commit('filters/updateEntity', 'dashboard')
  },
  created () {
    this.$store.commit('filters/updateEntity', 'customDashboard')
  },
  mounted () {
    this.currentQuestion = this.clonedCard.question
    this.mainColor = this.clonedCard.colors[0] || '#805ea0'
    this.getCharts()

    if (this.clonedCard[this.clonedCard.viewType] && this.clonedCard[this.clonedCard.viewType].colors) {
      this.colorPalettePreview = this.clonedCard[this.clonedCard.viewType].colors
      this.mainColor = this.clonedCard[this.clonedCard.viewType].colors[0]
    }

    this.questions.map(item => {
      if (item.type !== 'referral') { this.validQuestions.push(item) }
    })
  },
  computed: {
    questions () { return this.$store.getters['dashboard/getCurrentSurvey'].questions },
    currentSurveyId () { return this.$store.getters['dashboard/getCurrentSurvey']._id }
  },
  methods: {
    showModalAlert (order) {
      if (order === 'clone') {
        this.messageModal = this.$i18n.t('dashboard.graphics.clone_graphic')
      } else {
        this.messageModal = this.$i18n.t('dashboard.graphics.delete_graphic')
      }

      this.orderExecModal = order
      this.showModal = true
    },
    orderModal () {
      if (this.orderExecModal === 'delete') { return this.deleteCard() }

      this.cloneCard()
    },
    updateQuery (query) {
      if (Array.isArray(query.$and) && query.$and.length > 0) {
        const newQuery = query.$and.map(condition => {
          return Object.keys(condition).length > 0 ? condition : null
        })

        const formattedObject = newQuery.filter(obj => obj !== null)
        this.clonedCard.query = JSON.stringify(formattedObject)
      }
    },
    removeQuery () {
      this.clonedCard.query = null

      return this.$store.commit('alerts/alert', {
        message: 'Filtro removido',
        position: 'bottomRight',
        showAlert: true
      })
    },
    closeOrOpenSidebar (close) {
      const container = document.querySelector('.container-config-card-sidebar')
      const sidebar = document.querySelector('.content-config-card-sidebar')
      const duration = 0.5
      const opacity = close ? 0 : 1
      const width = close ? '0vw' : '30vw'
      gsap.to(container, { opacity, duration })
      gsap.to(sidebar, { width, duration, onComplete: () => { if (close) { this.$emit('close-sidebar-edit'); this.isLoading = false } } })
    },
    async cloneCard () {
      if (this.clonedCard.type === 'text') {
        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('dashboard.error_clone_card_text'),
          position: 'bottomRight',
          showAlert: true
        })
      }

      if (typeof this.chart !== 'object' || !this.chart.attribute) {
        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('dashboard.missing_question_and_graphic'),
          position: 'bottomRight',
          showAlert: true
        })
      }

      this.isLoading = true

      const newCard = {
        graphicType: this.chart.graphicType,
        attribute: this.chart.attribute,
        viewType: this.chart.viewType,
        colors: this.clonedCard.colors,
        textColor: '#ffffff',
        question: this.clonedCard.question._id,
        survey: this.currentSurveyId,
        query: this.clonedCard.query,
        title: this.clonedCard.title
      }

      const clone = await dashboardsService.createCustomizedCard(newCard)

      if (clone) {
        this.$emit('update-dashboard-custom')
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('dashboard.success_clone_card'),
          position: 'bottomRight',
          showAlert: true
        })
        this.closeOrOpenSidebar(true)
      } else {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('dashboard.error_clone_card'),
          position: 'bottomRight',
          showAlert: true
        })
      }
    },
    async deleteCard () {
      this.isLoading = true
      const del = await dashboardsService.deleteCustomizedCard(this.clonedCard._id)
      if (del) {
        this.closeOrOpenSidebar(true)
        this.$emit('update-dashboard-custom')
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('dashboard.success_delete_card'),
          position: 'bottomRight',
          showAlert: true
        })
      } else {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('dashboard.error_delete_card'),
          position: 'bottomRight',
          showAlert: true
        })
      }
    },
    async updateCard () {
      this.isLoading = true

      const updated = {
        graphicType: this.currentQuestion.type === 'text' ? 'text' : this.chart.graphicType ? this.chart.graphicType : this.chart.icon,
        attribute: this.currentQuestion.type === 'text' ? 'text' : this.chart.attribute,
        viewType: this.currentQuestion.type === 'text' ? 'average' : this.chart.viewType,
        colors: this.clonedCard.colors,
        textColor: '#ffffff',
        question: this.currentQuestion._id,
        survey: this.currentSurveyId,
        npsType: this.selectedNpsType.value,
        title: this.clonedCard.title,
        query: this.clonedCard.query
      }

      const update = await dashboardsService.updateCustomizedCard(this.clonedCard._id, updated)

      let message = this.$i18n.t('dashboard.error_update')

      if (update) {
        this.closeOrOpenSidebar(true)
        message = this.$i18n.t('dashboard.success_update')
        this.$emit('update-dashboard-custom')
      }
      this.$store.commit('alerts/alert', {
        message: message,
        position: 'bottomRight',
        showAlert: true
      })
    },
    getCharts () {
      this.charts = []
      this.chart = {}
      this.optionsLength = responseOptions.getOptions(this.currentQuestion).length
      if (this.currentQuestion.type !== 'text') {
        const questionType = this.currentQuestion.type.toLowerCase()
        const questionUi = this.currentQuestion.ui.toLowerCase()
        const graphicsLists = this.$store.getters['dashboard/getGraphicTypes']
        this.charts = graphicsLists.find(list => list.type === questionType && list.ui === questionUi).charts
        if (this.charts.length > 0) {
          const type = this.clonedCard.average ? this.clonedCard.average.graphicType : this.clonedCard.overall ? this.clonedCard.overall.graphicType : this.clonedCard.overtime.graphicType
          const selectedChartIdx = this.charts.findIndex(i => i.graphicType === type)
          if (selectedChartIdx >= 0) {
            this.chart = this.charts[selectedChartIdx]
          }
        }
        this.selectedNpsType = this.clonedCard[this.chart.viewType].npsType === 'metric' ? this.npsDisplayingTypes[0] : this.npsDisplayingTypes[1]
      }

      this.closeOrOpenSidebar()
    },
    async createColorPalette () {
      this.colorPalettePreview = []

      const colorsAux = this.generateColors()

      const limitByGraphic = {
        line: { limit: this.optionsLength },
        emoteGauge: { limit: 5 },
        rangeGauge: { limit: 4 },
        horizontalBar: { limit: (this.selectedNpsType.value === 'metric' && this.currentQuestion.type.toLowerCase() === 'nps') ? 3 : this.optionsLength },
        verticalBar: { limit: this.optionsLength },
        pie: { limit: this.optionsLength },
        percentageBar: { limit: 5 },
        kpiYnd: { limit: 3 },
        kpiYn: { limit: 2 },
        stackedBar: { limit: 11 },
        likeOverall: { limit: 11 },
        likeOvertime: { limit: 2 }
      }

      const key = this.chart.graphicType

      colorsAux.map((color, index) => {
        if (index < limitByGraphic[key].limit) {
          this.colorPalettePreview.push(color)
        }
      })

      if (key === 'stackedBar' && this.clonedCard.ui === 'matrix1to5') { limitByGraphic.stackedBar.limit = 5 }

      if (key === 'likeOvertime') {
        this.colorPalettePreview = [colorsAux[0]]
        this.clonedCard.colors = [colorsAux[0]]
      }

      if (key === 'likeOvertime' && this.card.ui === 'like') {
        this.colorPalettePreview = [colorsAux[0], colorsAux[8]]
      }

      if (key === 'likeOverall' && this.card.ui === 'like') {
        this.colorPalettePreview = ['#ea4922', '#51ad59']
      }

      this.clonedCard.colors = this.colorPalettePreview
    },
    generateColors () {
      const baseColorRgb = this.hexToRgb(this.mainColor)
      const step = 11
      const increment = 1 / step
      const generatedColors = []

      for (let i = 0; i < step; i++) {
        const newColor = this.rgbToHex(
          Math.round(baseColorRgb.r + (255 - baseColorRgb.r) * (i * increment)),
          Math.round(baseColorRgb.g + (255 - baseColorRgb.g) * (i * increment)),
          Math.round(baseColorRgb.b + (255 - baseColorRgb.b) * (i * increment))
        )
        generatedColors.push(newColor)
      }

      return generatedColors
    },
    hexToRgb (hex) {
      hex = hex.replace(/^#/, '')

      const bigint = parseInt(hex, 16)
      const r = (bigint >> 16) & 255
      const g = (bigint >> 8) & 255
      const b = bigint & 255

      return { r, g, b }
    },
    rgbToHex (r, g, b) {
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled-option {
  filter: grayscale(1);
  background: rgb(237, 237, 237);
  pointer-events: none;

}
.container-config-card-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  background: #00000025;
  width: 100vw;
  height: 100vh;
  z-index: 36;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .content-config-card-sidebar {
    position: relative;
    background: #ffffff;
    width: 0vw;
    height: 100vh;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem 0 0 0.5rem;
    border-left: 1px solid #cacaca;
    overflow: hidden;
    .header-config-card-sidebar {
      position: relative;
      width: 100%;
      height: 10%;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .header-title-config-card-sidebar {
        color: #454548;
        font-size: 1.1vw;
      }
      .close-sidebar-icon {
        position: absolute;
        top: 0.2rem;
        right: 0.3rem;
        color: var(--accent-color);
        font-size: 1.4vw;
        cursor: pointer;
      }
    }
    .section-custom-config-card-sidebar {
      width: 100%;
      height: 90%;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 1rem;
      .option-custom-config-card {
        width: 100%;
        border: 1px solid #cacaca;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
        padding: 0.5rem 0.3rem;
        border-radius: 0.3rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .title-to-options {
          font-size: 0.8vw;
          color: var(--accent-color);
          font-weight: 500;
        }
        .main-color {
          height: 2.1vw;
          width: 2.1vw;
          display: flex;
          border: none;
          background-color: transparent;
          cursor: pointer;
        }
        .palette-container {
          display: flex;
          gap: 5px;
          .my-palette {
            .palette {
              height: 2.1vw;
              width: 2.1vw;
              display: flex;
              border: none;
              background-color: transparent;
              cursor: pointer;
            }
          }
        }
        .content-option-config-card {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 0.5rem;
          .btn-action-config-card {
            background: var(--accent-color);
            padding: 0 0.4rem;
            height: 1.8vw;
            font-size: 0.75vw;
            border-radius: 0.3rem;
            color: var(--default-text-color);
            font-weight: bold;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
          }
          .btn-clone {
            background: transparent;
            border: 1px solid var(--accent-color);
            color: var(--accent-color);
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          }
          .input-text {
            width: 100%;
          }
        }
        .btn-add-filter-card {
          width: fit-content;
          height: 2rem;
          border: 1px solid var(--accent-color);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          border-radius: 0.3rem;
          padding: 0 0.2rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          .icon-open-filter-advanced {
            font-size: 1.3vw;
            color: var(--accent-color);
          }
          .icon-check-filter-advanced {
            background: #15c315;
            border-radius: 50%;
            color: #ffffff;
            font-size: 0.9vw;
            padding: 0.1rem;
            font-weight: bold;
          }
        }
      }
      .btn-create-config-card {
        background: var(--accent-color);
        width: fit-content;
        padding: 0 0.5rem;
        height: 2vw;
        font-size: 0.75vw;
        border-radius: 0.3rem;
        color: var(--default-text-color);
        font-weight: bold;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      }
    }
    .content-loading-config-card-custom {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      .spinner-social-config-card {
        width: 2.1vw !important;
        height: 2.1vw !important;
        border-width: 3px !important;
        border-radius: 35px;
        border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
      }
    }
  }
}
</style>
