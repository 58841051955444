<template>
  <div class="row center-lg center-md center-sm">
    <app-accordion
      class="col-lg-11 col-md-11 col-sm-11 accordion-section"
      :title="$t('survey.settings.question')"
      :showOnStartup="true"
      v-if="!showingEndMessage"
    >
      <question :showingEndMessage="showingEndMessage"/>
    </app-accordion>
    <app-accordion
      class="col-lg-11 col-md-11 col-sm-11 accordion-section"
      :title="$t('survey.settings.conditions')"
      :conditionsLength="showingEndMessage ? endMessageConditionsLength : questionConditionsLength"
      :showOnStartup="endMessageConditionsLength > 0 ? true : false"
    >
      <conditions :showingEndMessage="showingEndMessage"/>
    </app-accordion>
  </div>
</template>
<script lang="js">
import question from './Question.vue'
import conditions from './Conditions.vue'
export default {
  name: 'QuestionConfig',
  components: {
    question,
    conditions,
    'app-accordion': () => import('@binds-tech/binds-design-system/src/components/Accordion/Accordion')
  },
  data () {
    return {
      showingEndMessage: false
    }
  },
  computed: {
    question () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey.questions[survey.questionSelected]
    },
    questionConditionsLength () {
      return this.question.showIfAll.length + this.question.showIfAny.length + this.question.ifMetadata.length
    },
    endMessageConditionsLength () {
      const survey = this.$store.getters['survey/getSurvey']
      const endMessageLenght = survey.endMessages[survey.selectedEndMessageIndex]
      return endMessageLenght?.showIfAll.length + endMessageLenght?.showIfAny.length + endMessageLenght?.ifMetadata.length
    }
  },
  mounted () {
    this.$root.$on('end-message-updated', () => {
      this.showingEndMessage = true
    })
    this.$root.$on('showing-message', () => {
      this.showingEndMessage = false
    })
    this.$root.$on('showing-question', () => {
      this.showingEndMessage = false
    })
  }
}
</script>
