<template>
  <div class="row start-lg start-md start-sm input-text">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <app-form-input secondary border-solid v-model="value" :placeholder="question.placeholder"></app-form-input>
    </div>
  </div>
</template>

<script>
export default {
  name: 'appinputext',
  props: ['question'],
  components: {
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input')
  },
  data () {
    return {
      value: '',
      placeholder: ''
    }
  }
}
</script>
<style lang="scss">
  .input-text {
    // margin-left: 30px !important;
    & .text-field__input {
      width: 90% !important;
    }
  }
</style>
