<template>
  <div style="display: block; margin: 20px 0;">
    <div class="second-act">
      <div class="row">
        <!-- Other label -->
        <div class="row" v-if="question.ui === 'single' || question.ui === 'singleOther'">
          <div class="col-xs config-headings toggle-with-flex-d">
            <app-toggle-input v-model="isOther" v-on:change.native="updateQuestionSingleOther(question)" :key="question._id"></app-toggle-input>
            <app-heading level="h6">{{$t('survey.toggle_others')}}</app-heading>
          </div>
        </div>
        <div class="row" v-if="question.ui === 'multiple' || question.ui === 'multipleOther'">
          <div class="col-xs config-headings toggle-with-flex-d">
            <app-toggle-input v-model="isOther" v-on:change.native="updateQuestionMultipleOther(question)" :key="question._id"></app-toggle-input>
            <app-heading level="h6">{{$t('survey.toggle_others')}}</app-heading>
          </div>
        </div>
        <!-- "randomOptions" toggle -->
        <div class="row" v-if="question.ui === 'single' || question.ui === 'multiple' || question.ui === 'singleOther' || question.ui === 'multipleOther'">
          <div class="col-xs config-headings toggle-with-flex-d">
            <app-toggle-input v-model="question.randomOptions" v-on:change.native="updateCurrentQuestion()"></app-toggle-input>
            <app-heading level="h6">{{$t('survey.toggle_random')}}</app-heading>
          </div>
        </div>
      </div>
      <!-- Required question toggle -->
      <div class="row" v-if="question.type !== 'referral'">
        <div class="col-xs config-headings toggle-with-flex-d" style="display: flex;">
          <app-toggle-input v-model="question.required" v-on:change.native="updateCurrentQuestion()"></app-toggle-input>
          <app-heading level="h6">{{$t('survey.toggle_required')}}</app-heading>
        </div>
      </div>
      <!-- Required question toggle - END -->
      <div
        class="row"
        v-if="
          (['nps', 'csat', 'nes', 'enum', 'ces'].indexOf(question.type) > -1) &&
          (['single', 'multiple', 'singleOther', 'multipleOther'].indexOf(question.ui) === -1) && (!question.stars || !question.stars.active)"
        >
        <div class="col-xs config-headings toggle-with-flex-d">
          <app-toggle-input v-model="question.gradient" v-on:change.native="updateQuestionLanguage(question)"></app-toggle-input>
          <app-heading level="h6" style="width: 150px !important; white-space: nowrap;">{{$t('survey.gradient')}}</app-heading>
        </div>
      </div>
      <!-- Custom title toggle -->
      <div class="row mt15">
        <div class="col-xs config-headings" style="display: flex;margin-left: 5px !important;">
          <app-toggle-input v-model="titleOnReports" v-on:change.native="updateCurrentQuestion(true)"></app-toggle-input>
          <app-heading level="h6">{{ $t('survey.title_on_reports') }}</app-heading>
        </div>
        <div class="col-xs config-headings field-instruction" style="margin-top: 30px !important;" v-if="question.customTitle">
          <app-heading level="h6" style="width: 250px !important;margin-left: 0px !important;">{{ $t('survey.custom_title') }}</app-heading>
          <app-form-input
            class="messageLabel"
            v-model="question.title"
            border-bottom
            v-on:change.native="updateCurrentQuestion()"
          ></app-form-input>
        </div>
      </div>
      <div class="row">
        <div class="col-xs config-headings" style="display: flex; align-items: center;">
          <app-toggle-input style="top: -10px;" v-model="survey.showProgressBar" v-on:change.native="updateCurrentQuestion(true)"></app-toggle-input>
          <app-heading level="h6" style="width: 100% !important;">{{ $t('survey.show_progress_bar') }}</app-heading>
        </div>
      </div>
      <div class="row">
        <div class="col-xs config-headings" style="display: flex; align-items: center;">
          <app-toggle-input style="top: -10px;" v-model="survey.hidePreviousButton" v-on:change.native="updateCurrentQuestion(true)"></app-toggle-input>
          <app-heading level="h6" style="width: 100% !important;">{{ $t('survey.hide_button_previous') }}</app-heading>
        </div>
      </div>
      <!-- Tradução da pergunta -->
      <div class="translate-container mt30" v-if="(['csat', 'nps', 'kpi', 'nes', 'text', 'ces', 'nvs', 'anonymous'].indexOf(question.type) > -1)">
        <app-heading level="h6" style="margin: auto 10px !important;">{{ $t('survey.language') }}</app-heading>
        <v-select
          class="question-language-select mt15 ml5"
          label="label"
          :options="languages"
          v-model="question.language"
          @option:selected="updateQuestionLanguage(question)"
          :clearable="false"
          :searchable="false"
          :reduce="location => location.value"
        ></v-select>
      </div>
      <div class="labelButton characters mt30" v-if="!question.required || ['multiple', 'multipleOther'].indexOf(question.ui) >= 0 || question.type === 'text'">
        <div style="margin-left: 5px">
          <app-heading level="h6">{{$t('sendhub.action_button_text')}}</app-heading>
          <app-form-input
            class="messageLabel"
            v-model="question.nextLabel"
            v-on:change.native="updateCurrentQuestion()"
            border-bottom
          ></app-form-input>
        </div>
      </div>
      <!-- CSAT Stars Numbers toggle -->
      <div class="row mt30" v-if="question.ui === '5num' && question.type === 'csat' && (question.stars && question.stars.active)">
        <div clas="col-xs config-headings toggle-with-flex-d" style="margin-left: 5px !important; display: flex">
          <app-toggle-input v-model="question.stars.numbers" v-on:change.native="updateCurrentQuestion(true)"></app-toggle-input>
          <app-heading level="h6">Usar números</app-heading>
        </div>
      </div>
     <!-- CSAT NES CES labels -->
      <div class="row" v-if="(question.type === 'csat' && question.ui !== '5emo') || question.type === 'nes' || (question.type === 'ces' && question.ui !== '1to7button')">
        <div class="col-xs config-headings">
          <app-heading level="h7" style="font-size: 13.5px;">{{$t('survey.left_label')}}</app-heading>
          <app-form-input
            v-model="question.startLabel"
            v-on:change.native="updateCurrentQuestion()"
            border-bottom
            class="fields col-lg-6 col-md-6 col-sm-6"
            :maxlength="'40'"
          ></app-form-input>
        </div>
        <div class="col-xs config-headings">
          <app-heading level="h7" style="font-size: 13.5px;">{{$t('survey.right_label')}}</app-heading>
          <app-form-input
            v-model="question.endLabel"
            v-on:change.native="updateCurrentQuestion()"
            border-bottom
            class="fields col-lg-6 col-md-6 col-sm-6"
            :maxlength="'40'"
          ></app-form-input>
        </div>
      </div>
      <!-- ENUM labels -->
      <div class="row" v-if="question.type === 'enum' && question.ui !== 'single' && question.ui !== 'multiple' && question.ui !== 'singleOther' && question.ui !== 'multipleOther'">
        <div class="col-xs config-headings">
          <app-heading level="h7" style="font-size: 13.5px;">{{$t('survey.left_label')}}</app-heading>
          <app-form-input
            v-model="question.startLabel"
            v-on:change.native="updateCurrentQuestion()"
            border-bottom
            class="fields col-lg-6 col-md-6 col-sm-6"
            :maxlength="'40'"
          ></app-form-input>
        </div>
        <div class="col-xs config-headings">
          <app-heading level="h7" style="font-size: 13.5px;">{{$t('survey.right_label')}}</app-heading>
          <app-form-input
            v-model="question.endLabel"
            v-on:change.native="updateCurrentQuestion()"
            border-bottom
            class="fields col-lg-6 col-md-6 col-sm-6"
            :maxlength="'40'"
          ></app-form-input>
        </div>
      </div>
      <!--NES labels -->
      <div class="row" v-if="(question.type === 'nes' && question.ui !== '10num') || (question.type === 'nps' && question.ui === '10num')">
        <div class="col-lg-6 col-md-6 col-sm-6 headings-nes">
          <app-heading level="h7" style="font-size: 13.5px;">{{$t('survey.left_label')}}</app-heading>
          <app-form-input
            v-model="question.startLabel"
            v-on:change.native="updateCurrentQuestion()"
            border-bottom
            class="fields col-lg-12 col-md-12 col-sm-12"
            :maxlength="'40'"
          ></app-form-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 headings-nes">
          <app-heading level="h7" style="font-size: 13.5px;">{{$t('survey.right_label')}}</app-heading>
          <app-form-input
            v-model="question.endLabel"
            v-on:change.native="updateCurrentQuestion()"
            border-bottom
            class="fields col-lg-12 col-md-12 col-sm-12"
            :maxlength="'40'"
          ></app-form-input>
        </div>
      </div>
      <!-- SINGLE && MULTIPLE -->
      <div class="row" v-if="question.ui === 'singleOther' || question.ui === 'multipleOther'">
        <div class="col-xs config-headings">
          <app-heading level="h7" style="font-size: 13.5px;">{{$t('survey.label_others')}}</app-heading>
          <app-form-input
            v-model="question.otherLabel"
            :placeholder="question.otherLabel ? question.otherLabel : `${$t('survey.others')}`"
            v-on:change.native="updateCurrentQuestion()"
            border-bottom
            class="fields col-lg-6 col-md-6 col-sm-6"
          ></app-form-input>
        </div>
        <div class="col-xs config-headings">
          <app-heading level="h7" style="font-size: 13.5px;">{{$t('survey.instruction')}}</app-heading>
          <app-form-input
            v-model="question.placeholder"
            :placeholder="question.placeholder ? question.placeholder : `${$t('survey.placeholder')}`"
            v-on:change.native="updateCurrentQuestion()"
            border-bottom
            class="fields col-lg-6 col-md-6 col-sm-6"
          ></app-form-input>
        </div>
      </div>
      <!-- Referral Dynamic options-->
      <div class="row" v-if="question.ui === 'referralDynamic'">
        <ReferralDynamicOptions />
      </div>
      <!-- Referral options -->
      <div class="row sidebar-referral-select " v-if="question.ui === 'referral'">
        <div class="col-xs" style="margin-top:25px;">
          <app-heading level="h7">{{$t('survey.referral_options')}}:</app-heading>
          <app-form-dropdown
            v-model="question.selectedReferralOption"
            class="catDropdown mt20"
            label="Selecione uma opção"
            :items="referralOptions"
            item-value="type"
            item-text="name"
            v-on:change="updateReferralOptions(question.selectedReferralOption)"
          />
        </div>
      </div>
      <div class="sidebar-referral-options" v-if="question.ui === 'referral'">
        <div class="col-xs">
          <app-heading level="h7">{{$t('survey.change_placeholders')}}</app-heading>
          <div class="row" v-if="question.referral.fields.name">
            <app-form-input
              v-model="question.referral.fields.name.placeholder"
              v-on:change.native="updateCurrentQuestion()"
              border-bottom
              class="fields col-lg-6 col-md-6 col-sm-6"
            ></app-form-input>
            <div class="referral-input">
              <app-toggle-input
                v-on:change.native="updateCurrentQuestion()"
                v-if="question.ui === 'referral'"
                v-model="question.referral.fields.name.required"
              ></app-toggle-input>
              <app-heading level="h6">{{$t('survey.field_required')}}</app-heading>
            </div>
          </div>
          <div class="row" v-if="questionSettings.selectedReferralOption !== 'np' && question.referral.fields.email.active">
            <app-form-input
              v-model="question.referral.fields.email.placeholder"
              v-on:change.native="updateCurrentQuestion()"
              border-bottom
              class="fields col-lg-6 col-md-6 col-sm-6"
            ></app-form-input>
            <div class="referral-input">
              <app-toggle-input
                v-model="question.referral.fields.email.required"
                v-on:change.native="updateCurrentQuestion()"
              ></app-toggle-input>
              <app-heading level="h6">{{$t('survey.field_required')}}</app-heading>
            </div>
          </div>
          <div class="row" v-if="questionSettings.selectedReferralOption !== 'ne' && question.referral.fields.phone.active">
            <app-form-input
              v-model="question.referral.fields.phone.placeholder"
              v-on:change.native="updateCurrentQuestion()"
              border-bottom
              class="fields col-lg-6 col-md-6 col-sm-6"
            ></app-form-input>
            <div class="referral-input">
              <app-toggle-input
                v-model="question.referral.fields.phone.required"
                v-on:change.native="updateCurrentQuestion()"
              ></app-toggle-input>
              <app-heading level="h6">{{$t('survey.field_required')}}</app-heading>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- If is an input text, it sets the input's placeholder -->
    <div v-if="question.ui === 'text' && question.mask !== null && question.ui !== 'text'" class="inputext-settings">
      <div class="mt30 field-instruction">
        <app-heading level="h6">{{$t('survey.change_placeholders')}}</app-heading>
        <app-form-input
          v-model="question.placeholder"
          v-on:change.native="updateCurrentQuestion()"
          border-bottom
        ></app-form-input>
      </div>
    </div>
    <!-- Says if the textarea is required -->
    <div class="labelButton characters" v-if="question.ui === 'text' || question.ui === 'multiple' || question.ui === 'multipleOther'">
      <div class="text-config" v-if="question.ui === 'text'">
        <div class="define-placeholder" style="margin-top: 35px">
          <app-heading level="h8">{{ $t('survey.define_placeholder') }}</app-heading>
          <app-form-input
            type="text"
            v-model="question.placeholder"
            v-on:change.native="updateCurrentQuestion()"
            border-bottom
          ></app-form-input>
        </div>
        <div class="second-act characters d-flex mt20" v-if="question.required && question.mask !== 'tel'">
          <div>
            <app-heading level="h6">{{ $t('survey.min_length') }}</app-heading>
            <app-form-input
              type="number"
              v-model="question.minLength"
              v-on:change.native="updateCurrentQuestion()"
              border-bottom
            ></app-form-input>
          </div>
          <div style="margin-left: 15px">
            <app-heading level="h6">{{ $t('survey.max_length') }}</app-heading>
            <app-form-input
              type="number"
              v-model="question.maxLength"
              v-on:change.native="updateCurrentQuestion()"
              border-bottom
            ></app-form-input>
          </div>
        </div>
      </div>
      <div class="second-act" v-if="question.ui === 'multiple' || question.ui === 'multipleOther'">
        <div class="row">
          <div class="col-xs config-headings toggle-with-flex-d">
            <app-toggle-input style="width: 50px;" v-model="question.limitAnswer" v-on:change.native="updateCurrentQuestion()"></app-toggle-input>
            <app-heading level="h6" style="width: 100% !important;">{{$t('survey.limit_answer')}}</app-heading>
          </div>
        </div>
        <div v-if="question.limitAnswer" class="second-act characters d-flex">
          <div style="margin-left: 5px; width: 100%">
            <app-heading level="h6">{{ $t('survey.min_options') }}</app-heading>
            <app-form-input
              type="number"
              :min="1"
              :max="question.options.length"
              v-model="question.minLength"
              v-on:change.native="updateCurrentQuestion()"
              border-bottom
            ></app-form-input>
          </div>
          <div style="margin-left: 15px; width: 100%">
            <app-heading level="h6">{{ $t('survey.max_options') }}</app-heading>
            <app-form-input
              type="number"
              :min="question.minLength"
              :max="question.options.length"
              v-model="question.maxLength"
              v-on:change.native="updateCurrentQuestion()"
              border-bottom
            ></app-form-input>
          </div>
        </div>
      </div>
    </div>
    <div v-if="question.type === 'referral'">
      <div class="labelButton characters mt30">
        <div style="margin-left: 5px">
          <app-heading level="h6">{{ $t('survey.previous_question_label') }}</app-heading>
          <app-form-input
            class="messageLabel"
            v-model="question.prevLabel"
            v-on:change.native="updateCurrentQuestion()"
            border-bottom
          ></app-form-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReferralDynamicOptions from '../../ReferralDynamicOptions.vue'

export default {
  name: 'Question',
  components: {
    ReferralDynamicOptions,
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput'),
    'app-form-dropdown': () => import('@binds-tech/binds-design-system/src/components/Form/Dropdown'),
    vSelect: () => import('vue-select')
  },
  props: ['showingEndMessage'],
  data () {
    return {
      titleOnReports: '',
      isOther: '',
      referralOptions: [
        { name: this.$i18n.t('survey.nep_option'), type: 'nep' },
        { name: this.$i18n.t('survey.ne_option'), type: 'ne' },
        { name: this.$i18n.t('survey.np_option'), type: 'np' }
      ],
      languages: [{ label: this.$i18n.t('survey.pt'), value: 'pt-br' }, { label: this.$i18n.t('survey.en'), value: 'en-us' }, { label: this.$i18n.t('survey.es'), value: 'es-cl' }],
      optionsForBlocking: [
        { label: this.$i18n.t('survey.hours'), value: 'h' },
        { label: this.$i18n.t('survey.days'), value: 'd' },
        { label: this.$i18n.t('survey.months'), value: 'M' }
      ],
      optionsSendBlock: [
        { label: this.$i18n.t('survey.seconds'), value: 'seconds' },
        { label: this.$i18n.t('survey.minutes'), value: 'minutes' },
        { label: this.$i18n.t('survey.hours'), value: 'hours' },
        { label: this.$i18n.t('survey.days'), value: 'days' },
        { label: this.$i18n.t('survey.weeks'), value: 'weeks' },
        { label: this.$i18n.t('survey.months'), value: 'months' }
      ],
      questionSettings: []
    }
  },
  async mounted () {
    if (this.question.ui === 'singleOther' || this.question.ui === 'multipleOther') {
      this.isOther = true
    }

    this.$root.$on('showing-question', () => {
      let toggle
      this.question.ui === 'singleOther' || this.question.ui === 'multipleOther' ? toggle = true : toggle = false
      this.isOther = toggle
    })
    this.$store.commit('survey/survey', { isTemplate: false })

    this.setTitleOnReportsValue()

    const survey = this.$store.getters['survey/getSurvey']

    if (survey.responseInterval) {
      const parseInterval = survey.responseInterval.split('')
      const findInterval = parseInterval[parseInterval.length - 1]
      parseInterval.splice(parseInterval.length - 1, 1)
      this.lockTime = parseInterval.join('')
      this.intervalValue = findInterval
      this.optionsForBlocking.find(item => {
        if (item.value === findInterval) {
          this.interval = item.label
        }
      })

      this.isToBlock = true
    } else {
      this.interval = this.optionsForBlocking[0].label
    }

    if (survey.sendInterval) {
      const findInterval = survey.sendInterval.match(/[a-zA-Z]+/g)
      const hasSendInterval = survey.sendInterval.match(/\d+/g)
      if (hasSendInterval) {
        this.sendLockTime = hasSendInterval[0]
      }

      if (findInterval && findInterval[0]) {
        this.sendIntervalValue = findInterval[0]
        this.optionsSendBlock.find(item => {
          if (item.value === findInterval[0]) {
            this.sendInterval = item.label
          }
        })
      }
      this.isToBlockSurveySendByInterval = true
    } else {
      this.sendInterval = this.optionsSendBlock[0]
      this.sendIntervalValue = this.optionsSendBlock[0].value
    }

    if (survey.expireInterval) {
      const findInterval = survey.expireInterval.match(/[a-zA-Z]+/g)
      const hasExpireInterval = survey.expireInterval.match(/\d+/g)
      if (hasExpireInterval) {
        this.expireLockTime = hasExpireInterval[0]
      }

      if (findInterval && findInterval[0]) {
        this.expireIntervalValue = findInterval[0]
        this.optionsSendBlock.find(item => {
          if (item.value === findInterval[0]) {
            this.expireInterval = item.label
          }
        })
      }
      this.isToBlockSurveyExpireByInterval = true
    } else {
      this.expireInterval = this.optionsSendBlock[0]
      this.expireIntervalValue = this.optionsSendBlock[0].value
    }
    // Init Single Other
    if (this.question.ui === 'singleOther') {
      this.isOtherSingle = true
    }
    // Init multiple Other
    if (this.question.ui === 'multipleOther') {
      this.isOtherMultiple = true
    }
    // Set initial fields for referral
    if (this.question.ui === 'referral') {
      const activeFields = []
      if (this.question.referral.fields.name.active) {
        activeFields.push('n')
      }
      if (this.question.referral.fields.email.active) {
        activeFields.push('e')
      }
      if (this.question.referral.fields.phone.active) {
        activeFields.push('p')
      }
      const referralOp = activeFields.join('')
      this.updateReferralOptions(referralOp)
    }
  },
  computed: {
    questions () {
      return this.$store.getters['survey/getQuestions']
    },
    survey () {
      return this.$store.getters['survey/getSurvey']
    },
    question () {
      const survey = this.$store.getters['survey/getSurvey']
      const language = survey.questions[survey.questionSelected].language ? survey.questions[survey.questionSelected].language : this.lang
      survey.questions[survey.questionSelected].language = language
      return survey.questions[survey.questionSelected]
    },
    isValidQuestion () {
      return !!this.survey.questions[this.survey.questionSelected]
    }
  },
  watch: {
    'question.startLabel': function (newValue) {
      if (newValue?.length >= 40) {
        this.$store.commit('alerts/alert', {
          message: `${this.$t('survey.max_length')} 40`,
          position: 'bottomRight',
          showAlert: true
        })
      }
    },
    'question.endLabel': function (newValue) {
      if (newValue?.length >= 40) {
        this.$store.commit('alerts/alert', {
          message: `${this.$t('survey.max_length')} 40`,
          position: 'bottomRight',
          showAlert: true
        })
      }
    }
  },
  methods: {
    setTitleOnReportsValue () {
      this.titleOnReports = !this.question.customTitle
    },
    updateCurrentQuestion (title, metadata) {
      if (title) {
        if (this.titleOnReports) {
          const label = this.question.label
          this.question.title = label
        }
        this.survey.questions[this.survey.questionSelected].customTitle = !this.titleOnReports
      }
      const updatedValue = this.survey.questions[this.survey.questionSelected]
      if (metadata) {
        if (updatedValue.ifMetadata[0] && updatedValue.ifMetadata[0].value) {
          this.$store.commit('survey/upsertQuestion', updatedValue)
          this.$forceUpdate()
        }
      } else {
        this.$store.commit('survey/upsertQuestion', updatedValue)
        this.$forceUpdate()
      }

      if (this.question.limitAnswer) {
        if (this.question.minLength > this.question.maxLength) {
          this.question.maxLength = this.question.minLength
        }
      }
    },
    updateQuestionLanguage (question) {
      this.$root.$emit('language-update', question.language, question.id)
      this.$store.commit('survey/upsertQuestion', question)
    },
    updateQuestionSingleOther (question) {
      if (this.isOther) {
        question.ui = 'singleOther'
        question.otherLabel = this.$i18n.t('survey.others')
      } else {
        question.ui = 'single'
        delete question.otherLabel
      }
      this.$store.commit('survey/upsertQuestion', question)
    },
    updateQuestionMultipleOther (question) {
      if (this.isOther) {
        question.ui = 'multipleOther'
        question.otherLabel = this.$i18n.t('survey.others')
      } else {
        question.ui = 'multiple'
        delete question.otherLabel
      }
      this.$store.commit('survey/upsertQuestion', question)
    },
    updateReferralOptions (e) {
      /*
        Precisa sempre inicializar os campos para true quando essa função acontece, porque
        quando eles são colocados para false durante a seleção do input, isso se mantém.
        Isso garante que somente o campo que deve estar false ficará false.
      */
      this.question.referral.fields.name.active = true
      this.question.referral.fields.phone.active = true
      this.question.referral.fields.email.active = true

      if (e === 'np') {
        this.question.referral.fields.email.active = false
      }
      if (e === 'ne') {
        this.question.referral.fields.phone.active = false
      }

      this.question.selectedReferralOption = e
    }
  }
}
</script>
<style lang="scss">
span.rating__label {
    width: 100px !important;
    word-break: break-word;
  &.rating__label--min {
    text-align: left;
  }
  &.rating__label--max {
    text-align: right;
  }
}
.labelButton.characters {
  width: 100%;
}
.survey-config-heading {
  & .heading {
    font-size: 18px;
    padding: 15px !important;
  }
}
.second-act {
  padding-top: 15px;
  & h6 {
    font-size: 13.5px !important;
    margin-left: 50px !important;
    margin-top: 3px !important;
  }
}
.config-headings {
  padding-left: 5px !important;
  h6 {
    width: 135px !important;
  }
  .text-field__input {
    & input {
      font-size: 13.5px !important;
    }
  }
}
.characters {
  & div > h6 {
    font-size: 13.5px !important;
  }
  & div > label > .text-field__input {
    & input {
      font-size: 13.5px !important;
    }
  }
}
.d-flex {
  display: flex !important;
}
.inputext-settings {
  & h6 {
    margin-left: 45px !important;
    font-size: 13.5px !important
  }
}
.field-instruction {
  & h6 {
    margin-left: 0px !important;
  }
  .text-field__input {
    & input {
      font-size: 13.5px !important;
    }
  }
}
.question-language-select {
  & div {
    height: 40px !important;
  }
  & .vs__selected-options {
    min-width: 145px;
    font-size: 14px !important;
  }
}
.question-language-header h6 {
  margin: 0 !important;
}
.sidebar-referral-select {
  .col-lg-6 {
    background: red;
  }
}
.sidebar-referral-options input,
.sidebar-referral-select .input-wrapper .dropdown{
  font-size:15px !important;
}
.referral-input {
  display: flex;
  margin: auto 10px;
}
.translate-container {
  display: flex;
  width: 100%;
  place-items: center;

  div.v-select {
    flex: 1;
    margin-top: 0 !important;
  }
}
</style>
