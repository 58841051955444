<template>
  <div v-if="canShow">
    <Menu :showSubMenu="false"></Menu>
    <Breadcrumb :sendhub="this.hubSettings"/>
    <div class="row" v-if="emailHubSettings._id">
      <div class="col-lg-9 col-md-9 col-sm-8">
        <SendhubNav :list="this.list" @selectHub="showHub"/>
        <div style="min-height: 780px; display: flex; justify-content: center; align-items: flex-start">
          <div v-if="notPermitEdit">
            <div class="contenthub-block">
              <div>
                <i class="material-icons block-icon">block</i>
              </div>
            </div>
          </div>
          <contentHub v-bind:is="this.contentHub" :class="{ 'opacityDisplay': notPermitEdit }" :key="render"></contentHub>
        </div>
      </div>
      <div v-if="this.hubSettings !== 'sms'" class="col-lg-3 col-md-3 col-sm-3" style="padding: 0px !important;">
        <SendhubSidebars :where="this.hubSettings"  :class="{ 'opacityDisplay': notPermitEdit }"/>
      </div>
    </div>
    <div class="data-loading-fixed" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
/* import HUB */
import emailHub from '../components/sendhub/hubs/emailHub.vue'
import linkHub from '../components/sendhub/hubs/linkHub.vue'
import totenHub from '../components/sendhub/hubs/totenHub.vue'
import emailSmsHub from '../components/sendhub/hubs/emailSmsHub.vue'
import smsHub from '../components/sendhub/hubs/smsHub.vue'
import whatsappHub from '../components/sendhub/hubs/whatsappHub.vue'
import widgetHub from '../components/sendhub/hubs/widgetHub.vue'
import Breadcrumb from '../components/survey/BreadcrumbSurveys.vue'
import SendhubSidebars from '../components/SideBarConfig/SendhubSidebars.vue'
import SendhubNav from '../components/sendhub/SendhubNav.vue'
import { surveyService, sendhubService } from '@/_services'
export default {
  name: 'Send',
  components: {
    linkHub,
    totenHub,
    emailSmsHub,
    smsHub,
    Breadcrumb,
    SendhubSidebars,
    SendhubNav,
    emailHub,
    widgetHub,
    Menu,
    whatsappHub,
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      hub: 'email',
      showConfig: false,
      notPermitEdit: false,
      canShow: false,
      render: 0,
      loading: false,
      list: [
        { iconName: 'email', text: this.$i18n.t('sendhub.type_email'), type: 'email' },
        { iconName: 'sms', text: this.$i18n.t('sendhub.type_sms'), type: 'sms' },
        { iconName: '', text: 'WhatsApp', type: 'whatsapp' },
        { iconName: 'link', text: this.$i18n.t('sendhub.type_link'), type: 'link' },
        { iconName: 'sms-alt', text: this.$i18n.t('sendhub.type_email_and_sms'), type: 'emailSms' },
        { iconName: 'widget', text: this.$i18n.t('sendhub.type_widget'), type: 'widget' },
        { iconName: 'atm', text: this.$i18n.t('sendhub.type_totens'), type: 'toten' }
      ]
    }
  },
  async beforeRouteEnter (to, from, next) {
    next(async vm => {
      if (vm.getUser.type !== 'user') {
        vm.canShow = true
        return vm.$router.push({ path: to.path }).catch(() => { })
      }
      const surveyId = vm.$route.params.id
      const collector = await sendhubService.collectors()
      const isAgreedtoSend = collector.find(o => o.survey === surveyId)
      if (isAgreedtoSend) {
        if (vm.getUser.features.seedBatches) {
          vm.canShow = true
          return vm.$router.push({ path: to.path }).catch(() => { })
        }
      }
      vm.$router.push({ name: 'YouCant' })
    })
  },
  computed: {
    contentHub () {
      return this.hub + 'Hub'
    },
    emailHubSettings () {
      const settings = this.$store.getters['sendhub/getEmailSettings']
      return settings
    },
    hubSettings () {
      return this.hub
    },
    footerHtml () {
      const footer = this.$store.getters['sendhub/getFooterEmail']
      return footer
    },
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    },
    getUser () {
      const user = this.$store.getters['account/getUser']
      return user
    }
  },
  async mounted () {
    this.$root.$on('loadingSelectedHub', (val) => { this.loading = val })
    this.loading = true
    const survey = await surveyService.getSurvey(this.$route.params.id)
    if (!survey._id || survey === {}) {
      this.$router.push({ name: 'NotFound' })
    } else {
      this.$store.commit('survey/survey', survey)
    }
    const message = await sendhubService.getMessages(survey.message._id)
    if (message) {
      this.render += 1
      // Email Colors
      const getColorsEmail = message.colors.reduce((acc, cur) => ({ ...acc, [cur.alias]: cur.value }), {})
      message.colors = getColorsEmail
      this.$store.commit('sendhub/updateEmailSettings', message)
    }
    this.$store.commit('survey/survey', { sms: message.sms })
    this.loading = false
  },
  methods: {
    showHub (e) {
      this.hub = e
    }
  }
}

</script>

<style lang="scss">
.t-align {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contenthub-block {
  position: absolute;
  width: 74%;
  height: 100%;
  text-align: center;
  padding-top: 10%;
  z-index: 999;
  div {
    background-color: transparent;
    height: 85px;
    width: 410px;
    margin: 0 auto;
    padding-left: 20px;
  }
}
.opacityDisplay {
  opacity: 0.3;
  pointer-events: none;
}
.VueCarousel-slide {
  text-align: center;
}
</style>
