<template>
  <transition name="modal">
    <div class="mask-manual-tickets">
      <div class="wrapper-manual-tickets">
        <div class="modal-container modal-select-attendant">
          <div class="header-manual-tickets">
            <slot name="header">
              <div class="modal-close-button close-config col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button @click="$emit('close-manual-tickets')">
                  <app-textstyle size="size-l">×</app-textstyle>
                </button>
              </div>
              <app-heading level="h5" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 modal-title-ticket" style="margin-left: 15px !important;">
                {{ $t('tickets.new_ticket') }}
              </app-heading>
            </slot>
          </div>
          <div class="body-manual-tickets">
            <slot name="body">
              <div class="row center-lg center-md center-sm">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt20" style="padding-bottom: 0px !important;">
                  <app-heading level="h7">{{ $t('tickets.choose_attendant') }}:</app-heading>
                </div>
                <v-select
                  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 attendant-select"
                  label="name"
                  :options="users"
                  :clearable="false"
                  v-model="attendant"
                  @input="$forceUpdate()"
                ></v-select>
              </div>
              <div class="row end-lg end-md end-sm" style="margin-top: 15px; padding-right: 15px;">
                <app-button class="col-lg-3 col-md-3 col-sm-3 cancel-ticket" variation="primary" outline @click="$emit('close-manual-tickets')">{{ $t('tickets.cancel') }}</app-button>
                <app-button v-if="newTicket" class="col-lg-5 col-md-5 col-sm-5 create-ticket-btn" variation="primary" @click="createTicket()">{{ $t('tickets.create') }}</app-button>
                <app-button v-else class="col-lg-5 col-md-5 col-sm-5 create-ticket-btn" variation="primary" @click="updateTicket()">{{ $t('tickets.transfer') }}</app-button>
              </div>
            </slot>
          </div>
        </div>
      </div>
      <!-- loading effect -->
      <div class="data-loading" v-if="loading">
        <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
      </div>
    </div>
  </transition>
</template>

<script>
import { accountService, ticketsService } from '@/_services'
import { mapGetters } from 'vuex'

export default {
  name: 'TicketsModal',
  props: ['sending', 'newTicket', 'previousAttendant'],
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    vSelect: () => import('vue-select'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  data () {
    return {
      loading: false,
      attendant: undefined,
      users: undefined,
      noAttendant: { name: this.$i18n.t('tickets.no_attendant'), value: null }
    }
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    })
  },
  async mounted () {
    this.loading = true

    const users = await accountService.getUsers()
    if (users) {
      this.users = users
      if (!this.previousAttendant) {
        const currentUserIndex = this.users.findIndex(user => user._id === this.user._id)
        this.users.splice(currentUserIndex, 1)
        this.users.unshift(this.user)
        this.users.unshift(this.noAttendant)
      } else {
        const currentUserIndex = this.users.findIndex(user => user._id === this.previousAttendant._id)
        this.users.splice(currentUserIndex, 1)
        this.users.unshift(this.previousAttendant)
        this.users.unshift(this.noAttendant)
      }
    }

    this.attendant = this.users[1]

    this.loading = false
  },
  methods: {
    async createTicket () {
      this.loading = true
      const ticket = { attendant: this.attendant._id, sending: this.sending }
      if (this.attendant === this.noAttendant) {
        ticket.attendant = null
      }

      const create = await ticketsService.createTicket(ticket)
      if (create) {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('tickets.success_msg'),
          position: 'bottomRight',
          showAlert: true
        })
      } else {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('tickets.error_msg'),
          position: 'bottomRight',
          showAlert: true
        })
      }

      this.$emit('close-manual-tickets')
      this.loading = false
    },
    async updateTicket () {
      this.loading = true
      await ticketsService.transferTicket(this.sending, this.attendant._id)
      this.$emit('update-manual-tickets', this.attendant)
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.body-manual-tickets {
  height: 200px;
}
.mask-manual-tickets {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.17);
  display: table;
  transition: opacity 0.3s ease;
  overflow: hidden !important;
}
.wrapper-manual-tickets {
  display: table-cell;
  vertical-align: middle;
  width: 880px !important;
  min-height: 300px !important;
  max-height: 450px !important;
  overflow: hidden !important;
}
.header-manual-tickets h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-title-ticket {
  margin: 10px 0px 0px 10px !important;
}
.cancel-ticket {
  font-size: 14px !important;
}
.create-ticket-btn {
  font-size: 14px !important;
  height: 39px;
}
.attendant-select {
  .vs__dropdown-menu {
    max-height: 300px;
  }
}
.modal-select-attendant {
  top: 150px !important;
  padding: 0px !important;
  width: 400px !important;
  overflow: initial !important;
  overflow-x: initial !important;
}
</style>
