<template>
  <div class="container-card-text" :style="{'--color-text': handlerColor}">
    <TagSelect v-if="!notShowTags && tags.length" :dataTag="textData" :tags="tags" @set-loading="loadingCardText = $event" />
    <div class="section-contact-card-text">
      <div class="contact-card-text">
        <i class="material-icons icon-contact-card-text name-person">person</i>
        <p class="label-contact-card-text name-person">{{ contactName }}</p>
      </div>
      <div class="contact-card-text">
        <i class="material-icons icon-contact-card-text">mail</i>
        <p class="label-contact-card-text">{{ contactEmail }}</p>
      </div>
      <div class="contact-card-text">
        <i class="material-icons icon-contact-card-text">call</i>
        <p class="label-contact-card-text">{{ contactPhone }}</p>
      </div>
    </div>
    <div class="section-text-card">
      <p class="label-text-card">{{ textData.text }}</p>
      <p class="data-text-card">{{ formatDate(textData.createdAt) }}</p>
    </div>
    <i class="material-icons icon-nps-text-card">favorite</i>
  </div>
</template>

<script>
import TagSelect from '../tags/TagSelect.vue'

export default {
  name: 'CardText',
  props: ['textData', 'notShowTags', 'tags'],
  components: {
    TagSelect
  },
  data () {
    return {
      loadingCardText: false
    }
  },
  computed: {
    contactName () { return this.textData.contact?.name || this.$t('dashboard.no_name') },
    contactEmail () { return this.textData.contact?.email || this.$t('dashboard.no_email') },
    contactPhone () { return this.textData.contact?.phone || this.$t('dashboard.no_phone') },
    lang () { return this.$store.getters['account/getLang'] },
    handlerColor () {
      const { score } = this.textData

      if (score >= 0.5 && score <= 1) {
        return '#00aaa7'
      } else if (score >= 0 && score < 0.5) {
        return '#ffb500'
      } else if (score < 0) {
        return '#ff607d'
      } else {
        return '#919191'
      }
    }
  },
  methods: {
    formatDate (eventDate) {
      this.$moment.locale(this.lang)
      return this.$moment(eventDate).format('lll')
    }
  }
}
</script>

<style lang="scss" scoped>
.container-card-text {
  background: #eff3f895;
  width: 100%;
  height: fit-content;
  min-height: 7vw;
  margin-bottom: 0.8vw;
  border-radius: 0.3vw 0.4vw 0.4vw 0.3vw;
  padding: 0.4vw;
  border: 1px solid #e1e1e1;
  border-left: 0.15vw solid var(--color-text);
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 0.8vw;
  .section-contact-card-text {
    width: 100%;
    display: flex;
    gap: 0.3vw;
    flex-wrap: wrap;
    .contact-card-text:first-child {
      flex: 2;
    }
    .contact-card-text {
      display: flex;
      align-items: center;
      gap: 0.3vw;
      flex: 1;
      color: #919191;
      .icon-contact-card-text {
        font-size: 0.9vw;
      }
      .label-contact-card-text {
        font-size: 0.7vw;
      }
      .name-person {
        color: #454548;
        font-weight: 500;
        font-size: 0.75vw;
      }
    }
  }
  .section-text-card {
    min-height: 2.5vw;
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #f1f1f1;
    border-radius: 0.8vw 0.8vw 0.8vw 0;
    padding: 0.4vw;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 0.7vw;
    .label-text-card {
      width: 100%;
      color: #454548;
      font-size: 0.7vw;
    }
    .data-text-card {
      font-size: 0.6vw;
      color: #919191;
    }
  }
  .icon-nps-text-card {
    font-size: 1.2vw;
    color: var(--color-text);
    filter: drop-shadow(0px 0px 1px var(--color-text));
  }
}
</style>
