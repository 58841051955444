
<template>
  <div class="row center-lg center-md center-sm mt20">
    <div class="logos-toggle row">
      <app-toggle-input v-model="usePrimaryLogo" @change.native="updateSurveyImage(usePrimaryLogo)"/>
      <app-heading level="h6" class="tog-text" v-html="$t('survey.logos_title')"/>
      <i v-title="$t('survey.logos_description')" title-max-width="260" title-placement="top" class="material-icons">help</i>
    </div>
    <div class="mb50" id="smallLogoConfig">
      <SetImg :title="$t('sendhub.set_image_text')" :imageProps="imagePropsDefault" context="account"/>
    </div>
  <theme-style>
    .logos-toggle > div > input {
      color: {{user.account.theme.colors.accent || '#ff607d'}} !important;
    }
  </theme-style>
  </div>
</template>

<script>
import SetImg from '@/components/image/SetImg.vue'

export default {
  name: 'Logos',
  components: {
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    SetImg
  },
  data () {
    return {
      usePrimaryLogo: false
    }
  },
  computed: {
    imagePropsDefault () {
      const images = this.images || [{}, { value: this.user.account.theme.smallLogo }]
      const result = Object.assign({}, images[1])
      result.where = 'surveySmallLogo'
      result.success = !!result.value
      result.crop = 'free'
      result.imageShrink = '800x600 100%'
      result.imageOnly = true
      result.hideDelete = true
      return result
    },
    images () {
      const req = this.$store.getters['survey/getSurvey']
      return req.images
    },
    user () {
      const account = this.$store.getters['account/getUser']
      return account
    }
  },
  created () {
    try {
      this.usePrimaryLogo = this.images.filter((image) => {
        return image.alias === 'logoSmall'
      })[0].value
    } catch (error) {
      this.usePrimaryLogo = false
    }
  },
  methods: {
    updateSurveyImage () {
      let arrayOfImages = Object.assign([], this.images)
      if (!this.usePrimaryLogo) {
        try {
          if (arrayOfImages && arrayOfImages.length > 0) {
            arrayOfImages = arrayOfImages.filter((image) => {
              return image.alias !== 'logoSmall'
            })
          }
        } catch (error) {
          arrayOfImages = Object.assign([], this.images)
        }
      } else {
        if (arrayOfImages) {
          const hasSmall = arrayOfImages.filter((image) => {
            return image.alias === 'logoSmall'
          })
          if (hasSmall.length <= 0) {
            const url =
              this.user.account.theme && this.user.account.theme.smallLogo
                ? this.user.account.theme.smallLogo
                : ''
            arrayOfImages.push({ alias: 'logoSmall', value: url })
          }
        }
      }
      this.$store.commit('survey/survey', { images: arrayOfImages })
    }
  }
}
</script>
<style lang="scss">
.all-logos {
  h6 {
    margin-bottom: 10px !important;
    margin-top: 10px;
    text-align: left;
    font-size: 12px !important;
    text-align: center !important;
  }
}
.logos-toggle {
  display: flex;
  margin-top: 15px;
  & .tog-text {
    max-width: 220px;
    font-size: 14px !important;
    margin-left: 45px !important;
    line-height: 20px !important;
  }
  & i {
    font-size: 18px;
    margin-left: 5px;
    margin-top: 1px;
    color: #8E8E8E;
    cursor: pointer;
  }
}
#smallLogoConfig {
  width: inherit !important;
}
#smallLogoConfig > div > div {
  height: 69px;
  & div > div > div > img {
    display: block;
    margin: 30px auto;
    max-height: 3.2em;
    max-width: 90%;
    width: auto !important;
    min-height: 50px;
  }
}
</style>
