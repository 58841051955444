<template>
  <div class="emotion-chart-container">
    <div class="header">
      <p class="title">EMOÇÃO</p>
      <div class="info-icon">
        <i class="material-symbols-outlined" v-title="'Visualize emoções ao longo do tempo. Compreenda momentos emocionais.'">info</i>
      </div>
    </div>
    <div class="chart-container" v-if="data.length">
      <canvas width="300" height="250" id="insightEmotionGraph"></canvas>
    </div>
    <div  v-else style='height: 83%'>
      <NoData/>
    </div>
    <div class="emotion-list">
      <div v-for="(emotion, idx) in emotions" :key="emotion.key + emotion.doc_count" :style="itemBackgroundColor(idx)" class="emotion-item">
        <div class="emotion-info">
          <div :style="{ backgroundColor: emotion.color }" class="color-indicator"></div>
          <span>{{ emotion.key }}</span>
        </div>
        <span class="doc-count">{{ emotion.doc_count }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import NoData from '../NoData.vue'
import { generateColors } from './generateColorsInsights'

export default {
  props: ['data'],
  data () {
    return {
      emotions: [],
      labels: []
    }
  },
  components: {
    NoData
  },
  async mounted () {
    if (!this.data.length) return

    this.emotions = this.data.map((item) => ({
      ...item,
      color: generateColors.generateColor(item.key)
    }))

    this.labels = this.emotions.map((emotion) => this.capitalizeFirstLetter(emotion.key))

    await this.emotionChart()
  },
  methods: {
    capitalizeFirstLetter (label) {
      return label.charAt(0).toUpperCase() + label.slice(1)
    },
    async emotionChart () {
      const ctx = document.getElementById('insightEmotionGraph')
      const chart = await new window.Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.labels,
          datasets: [
            {
              data: this.emotions.map((emotion) => emotion.doc_count),
              backgroundColor: this.emotions.map((emotion) => emotion.color)
            }
          ]
        },
        options: {
          scales: {
            xAxes: [
              {
                ticks: {
                  callback: function (value) {
                    if (value.length > 10) {
                      return value.substring(0, 10) + '...' // Truncate long labels
                    }
                    return value
                  }
                }
              }
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                  padding: 25
                }
              }
            ]
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false
        }
      })

      return chart
    },
    itemBackgroundColor (idx) {
      return { backgroundColor: idx % 2 ? 'white' : '#eff3f8' }
    }
  }
}
</script>

<style lang="scss" scoped>
.emotion-chart-container {
  width: 49.5%;
  max-height: 500px;
  border-radius: 12px;
  border: 1px solid #e1e1e1;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.070);
  .header {
    height: 17%;
    display: flex;
    justify-content: space-between;
    padding: 25px;
    border-bottom: 1px solid #e4e0eb;
    color: var(--accent-color);
    .title {
      font-size: 1.3vw;
      font-weight: bold;
    }
    .info-icon {
      font-size: 30px;
      margin-bottom: 6px;
    }
  }
  .chart-container {
    padding: 20px;
    height: 58%;
    margin: 0 !important;
  }
  .emotion-list {
    height: 25%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.5rem;
    overflow-y: auto;
    .emotion-item {
      height: 30px;
      background: #ffffff !important;
      width: fit-content;
      border-radius: 0.3rem;
      font-size: 0.8vw;
      border: 0.1rem solid #e1e1e1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 0.2rem;
      color: #808080;
      .emotion-info {
        display: flex;
        .color-indicator {
          height: 17px;
          width: 40px;
          margin-right: 5px;
          border: 1px solid #cacaca;
          border-radius: 0.2rem;
        }
        span {
          text-transform: capitalize;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .doc-count {
        margin-left: auto;
      }
    }
  }
}
</style>
