<template>
  <div style="margin: 5vw">
    <div class="input-field">
      <div class="linearGradient">
        <input
          class="login-form-input-field"
          v-model="email"
          type="text"
          id="name"
          required
        />
        <label class="login-form-label-field" for="name">E-mail:</label>
      </div>
    </div>
    <button id="signUpButton" @click="submitLogin()">
      {{ $t("login.send") }}
    </button>
    <div>
      <span
        class="loginActionLinks"
        @click="$root.$emit('update-current-active', 0)"
        >{{ $t("login.back") }}</span
      >
    </div>
  </div>
</template>
<script>
import { userService } from '@/_services'

export default {
  name: 'SingleSignForm',
  data () {
    return {
      email: '',
      password: '',
      isSignOn: false
    }
  },
  methods: {
    showMessage (message) {
      this.$store.commit('alerts/alert', {
        message: message,
        showAlert: true
      })
    },
    async submitLogin () {
      if (!this.email) {
        return false
      }
      this.$root.$emit('set-login-loading', true)
      try {
        const url = `${process.env.VUE_APP_URL}login/azure?email=${encodeURIComponent(this.email)}`
        const result = await userService.loginWithSSO(url)
        if (result && result.message) {
          this.showMessage(result.message)
        } else {
          window.open(url, '_self')
        }
        this.$root.$emit('set-login-loading', false)
        return false
      } catch (e) {
        this.$root.$emit('set-login-loading', false)
        console.log('Error on login request', e)
      }
      this.$root.$emit('set-login-loading', false)
    }
  }
}
</script>

<style>
</style>
