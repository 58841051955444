<template>
  <div class="content-startend">
    <div class="content-question-title-start" :style="{ 'background-color': survey.colors.headerBg }">
    </div>
    <div class="content-main-message" :style="{'background-color': survey.colors.splashBg}">
      <SetImg :title="$t('sendhub.set_image_text')" @doneWithSuccess="$root.$emit('end-message-updated')" :imageProps="imagePropsEnd" context="messages"/>
      <div class="end-title" v-click-outside="hideOptions">
        <edit-title
          :title="messageToShow?.message || ''"
          @updateSurveyTitle="updateMessage"
          @openOptions="displayOptions = true"
          :optionsDisplay="displayOptions"
          :editStyle="true"
          :color="survey.colors.splashColor"
          :identify="`end-${survey._id}`"
          :minHeight="50"
          :maxHeight="40000"
          type="textarea"
          :metadata.sync="metadataGroup"
        />
      </div>
      <div class="row center-lg center-md center-sm end-message-description mt45" v-if="messageToShow?.details !== undefined">
        <app-heading level="h6" style="text-align: center">{{ messageToShow?.details.value }}</app-heading>
      </div>
      <div class="content-question-title-start" v-if="messageToShow?.details !== undefined && messageToShow?.details.link">
        <app-heading level="h6">
          <a :href="messageToShow.details.link.value" target="_blank">
            {{ messageToShow.details.link.label }}
          </a>
        </app-heading>
      </div>
    </div>
    <div class="content-footer-answer" :style="{'background-color': survey.colors.footerBg}"></div>
  </div>
</template>

<script>
import SetImg from '../../image/SetImg.vue'
import EditTitle from '../../EditTitle.vue'
import { userService } from '@/_services'
import('lodash-es').then((lodashEs) => {
  window._ = lodashEs.default
})
export default {
  name: 'EndMessages',
  components: {
    SetImg,
    EditTitle,
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      editTitle: false,
      selectedIndex: 0,
      imagePropsEnd: undefined,
      displayOptions: false,
      metadataGroup: [
        { key: '_name', label: this.$i18n.t('sendhub.name'), type: String },
        { key: '_email', label: this.$i18n.t('sendhub.email'), type: String },
        { key: '_phone', label: this.$i18n.t('sendhub.phone'), type: String }
      ]
    }
  },
  created () {
    this.mountStructure()

    // Quando outra end message é selecionada.
    this.$root.$on('end-message-updated', () => {
      this.mountStructure()
    })

    // Quando outra end message é atualizado nos detalhes.
    this.$root.$on('details-updated', () => {
      this.$forceUpdate()
    })
  },
  async mounted () {
    const user = await userService.getMe()
    if (user.account.fields.length > 0) {
      const data = user.account.fields
      data.map(item => this.metadataGroup.push(item))
    }

    this.$root.$on('update-merged-fields', (fields) => {
      fields.map(item => {
        if (this.survey.mergedFields.length > 0) {
          if (!this.survey.mergedFields.includes(item)) {
            this.survey.mergedFields.push(item)
          }
        } else {
          this.survey.mergedFields.push(item)
        }
      })
    })
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    },
    imagePropsDefault () {
      const result = Object.assign({}, window._.find(this.survey.images, o => o.alias === 'logo'))
      result.value = this.survey.images && this.survey.images.length > 0 ? this.survey.images[0].value : ''
      result.where = 'endMessages'
      result.success = !!result.value
      result.crop = 'free'
      result.imageShrink = '800x600 100%'
      result.imageOnly = true
      return result
    },
    messageToShow: {
      get () {
        const survey = this.$store.getters['survey/getSurvey']
        const index = survey.selectedEndMessageIndex
        const message = survey.endMessages[index]
        return message
      },
      set (newMessage) {
        const messageToSave = {
          idx: this.selectedIndex,
          message: newMessage
        }
        this.$store.commit('survey/updateEndMessageByIndex', messageToSave)
      }
    }
  },
  methods: {
    hideOptions () {
      this.displayOptions = false
    },
    mountStructure () {
      const survey = this.$store.getters['survey/getSurvey']
      this.selectedIndex = survey.selectedEndMessageIndex
      this.messageToShow = survey.endMessages[this.selectedIndex]
      if (this.messageToShow.logo.url || this.selectedIndex > 0) {
        const props = Object.assign({}, survey.images[1])
        props.value = this.messageToShow.logo.url
        props.where = 'endMessages'
        props.success = !!props.value
        props.crop = 'free'
        props.imageShrink = '800x600 100%'
        props.imageOnly = true
        this.imagePropsEnd = props
      } else {
        this.imagePropsEnd = this.imagePropsDefault
      }
    },
    updateMessage (e) {
      this.messageToShow.message = e
      const messageToUpdate = {
        idx: this.selectedIndex,
        message: this.messageToShow
      }
      this.$store.commit('survey/updateEndMessageByIndex', messageToUpdate)
      this.editTitle = false
    }
  }
}
</script>

<style lang="scss">
.content-startend {
  .content-question-title {
    margin: 0 auto;
    margin-top: 80px;
    width: 80%;
    .heading {
      text-align: center;
    }
  }
  .content-question-title-start {
    min-height: 100px;
  }
}
.end-title {
  textarea {
    font-weight: 600;
  }
  .content-edit-title {
    width: 90%;
    margin: 0 auto;
  }
}
.end-message-description {
  width: 100%;
  display: block;
  margin: 20px 0;
  .heading {
    text-align: center;
  }
  .title {
    input {
      text-align: center;
      font-size: 22px !important;
    }
  }
}
</style>
