import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

export const roleService = {
  getRoles,
  getGroup,
  updateGroup,
  createGroup,
  deleteGroup
}

async function getRoles () {
  const linkSettingsUrl = API_URL + 'roles'
  const result = await callApiService.callApi(linkSettingsUrl, 'get')
  return result
}

async function getGroup (id) {
  const linkSettingsUrl = `${API_URL}roles/${id}`
  const result = await callApiService.callApi(linkSettingsUrl, 'get')
  return result
}

async function updateGroup (group) {
  const linkSettingsUrl = `${API_URL}roles/${group._id}`
  const result = await callApiService.callApi(linkSettingsUrl, 'put', group)
  return result
}

async function deleteGroup (group) {
  const linkSettingsUrl = `${API_URL}roles/${group._id}`
  const result = await callApiService.callApi(linkSettingsUrl, 'delete', group)
  return result
}

async function createGroup (group) {
  const linkSettingsUrl = API_URL + 'roles'
  const result = await callApiService.callApi(linkSettingsUrl, 'post', group)
  return result
}
