
export const canvasMaker = {
  makeCanvasId,
  makeCanvasIdWithoutParam
}

function makeCanvasId (question) {
  const min = Math.ceil(2)
  const max = Math.floor(100)
  var date = new Date().getTime()
  const newId = question._id + question.type + Math.floor(Math.random() * (max - min)) + min + date
  return newId
}

function makeCanvasIdWithoutParam () {
  var date = new Date().getTime()

  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (date + Math.random() * 16) % 16 | 0
    date = Math.floor(date / 16)
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })

  return uuid
}
