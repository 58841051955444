<template>
  <div class="container-approaches-status">
    <div class="content-card-status" v-for="(stat, idx) in dataStats" :key="stat.title">
      <p class="title-card-status">{{ formatLabel(stat.title) }}</p>
      <p class="number-card-status" v-if="stat.formula" :class="`number-card-${idx}`">{{ animateNumber(stat.value, idx, true) }}</p>
      <p class="number-card-status" v-else :class="`number-card-${idx}`">{{ animateNumber(stat.value, idx) }}</p>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'ApproachesStatus',
  props: ['dataStats', 'tooltipMsg'],
  components: {},
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    formatLabel (label) { return label.charAt(0).toUpperCase() + label.slice(1) },
    animateNumber (value, idx, decimal) {
      setTimeout(() => {
        const label = document.querySelector(`.number-card-${idx}`)

        label.innerHTML = 0

        gsap.to(label, {
          duration: 1,
          innerHTML: value,
          snap: decimal ? (v) => v.toFixed(2) : 'innerHTML',
          ease: 'power1.inOut',
          onUpdate: () => {
            const fixed = `${Number(label.innerHTML).toFixed(2)}%`

            if (decimal) {
              label.innerHTML = fixed
              return
            }

            label.innerHTML = Number(label.innerHTML).toLocaleString('de-DE')
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-approaches-status {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 0.7vw;
  cursor: default;
  .content-card-status {
    width: 8vw;
    height: 4vw;
    box-shadow: 0 2px 7px 0 #30303029;
    background: #ffffff;
    color: #454548;
    border: 1px solid #e1e1e1;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .title-card-status {
      font-weight: 500;
      font-size: 0.8vw;
    }
    .number-card-status {
      font-weight: 700;
      font-size: 1vw;
    }
  }
}

.content-card-status:hover {
  transform: translateY(-2px);
}
</style>
