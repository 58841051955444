<template>
  <div>
    <Menu :showSubMenu="false"></Menu>
    <div class="cant">
      <div class="heading-cant">
        <app-heading level="h3">{{ $t('http.you_cant') }}</app-heading>
        <i class="material-icons block-icon">block</i>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'

export default {
  name: 'YouCant',
  components: {
    Menu,
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  }
}
</script>
<style lang="scss">
.cant {
  max-width: 920px;
  display: block;
  margin: 0 auto;
  margin-top: 4rem;
  text-align: center;
  .heading-cant {
    h3 {
      line-height: 50px;
    }
  }
}
.block-icon {
  margin-top: 25px;
  color: #a39c9c;
  font-size: 100px !important;
}
</style>
