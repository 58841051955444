<template>
  <div class="container-fav-filter">
    <div class="header-fav-filter" @click="openFavorites()">
      <p class="title-fav-filter">Filtros Favoritos</p>
      <i class="material-icons icon-expand-filter" v-if="!opened">expand_more</i>
      <i class="material-icons icon-expand-filter" v-else>expand_less</i>
    </div>
    <div class="content-fav-filter" v-if="opened && favoriteFilters.length > 0 && !loading">
      <div class="card-fav-filter" v-for="fav in favoriteFilters" :key="fav._id">
        <div class="card-fav-info">
          <p class="card-fav-name"><span class="card-fav-title">Nome:</span> {{ fav.name }}</p>
          <p class="card-fav-name"><span class="card-fav-title">Criado em:</span> {{ $moment(fav.createdAt).format('LL') }}</p>
        </div>
        <div class="card-actions-btn">
          <i class="material-icons icon-select-filter" v-title="'Carregar filtro'" @click="loadFavFilter(fav)">play_arrow</i>
          <i class="material-icons icon-delete-filter" v-title="'Deletar filtro'" @click="isToDeleteFilter(fav)">delete</i>
        </div>
      </div>
    </div>
    <div class="alert-no-fav-filter" v-if="opened && favoriteFilters.length === 0 && !loading">
      <p class="title-alert-fav-filter">Parece que você não tem filtros favoritos</p>
      <i class="material-icons icon-no-filter">sentiment_neutral</i>
    </div>
    <ShimmerLoading v-if="loading"/>
    <ModalDeleteFilter v-if="showModalDelete" :filter="filterToDelete" @cancel-action="showModalDelete = false" />
  </div>
</template>

<script>
import ShimmerLoading from '../customLoading/ShimmerLoading.vue'
import ModalDeleteFilter from './ModalDeleteFilter.vue'
import { filterFunctions } from '@/_helpers'
import { filtersService } from '@/_services'
import('lodash-es').then((lodashEs) => { window._ = lodashEs.default })

export default {
  name: 'FavoriteFilters',
  props: ['entity'],
  components: {
    ShimmerLoading,
    ModalDeleteFilter
  },
  data () {
    return {
      opened: true,
      loading: false,
      filterToDelete: null,
      showModalDelete: false,
      favoriteFilters: []
    }
  },
  beforeDestroy () {
    this.$root.$off('update-favorite-filters')
  },
  mounted () {
    this.getFilterFavorites()

    this.$root.$on('update-favorite-filters', () => { this.getFilterFavorites() })
  },
  computed: {
  },
  methods: {
    async loadFavFilter (filter) {
      try {
        this.loading = true

        if (!filter._id) { this.loading = false; return }

        const resultQuery = await filterFunctions.loadFavoriteFilters(filter._id, this.entity)

        const updateUrl = (query) => {
          this.$router.push({ name: this.$route.name, query })
            .catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error when updating url with filters', err) })
        }

        if (this.entity === 'insights') {
          const query = { ...this.$route.query, q: resultQuery }
          this.$store.commit('filtersInsight/insightField', query)
          updateUrl(query)
          this.$emit('loaded-filter')
          setTimeout(() => this.$root.$emit('loadFilterAgain'), 600)
        } else if (this.entity === 'customDashboard') {
          this.$emit('loaded-filter-custom', resultQuery)
          this.$emit('loaded-filter')
        } else {
          const query = { q: JSON.stringify(resultQuery) }
          updateUrl(query)
          this.$emit('loaded-filter')
          setTimeout(() => this.$root.$emit('loadFilterAgain'), 600)
        }
      } catch (error) {
        console.error('Error during filter loading:', error)
      } finally {
        this.loading = false
      }
    },
    isToDeleteFilter (filter) {
      this.filterToDelete = filter
      this.showModalDelete = true
    },
    async getFilterFavorites () {
      this.loading = true
      this.favoriteFilters = await filtersService.getCustomFilters(this.entity)
      setTimeout(() => { this.loading = false }, 600)
    },
    openFavorites () {
      this.opened = !this.opened
      if (this.opened) {
        this.loading = true
        setTimeout(() => { this.loading = false }, 600)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
}
.container-fav-filter {
  width: 100%;
  .header-fav-filter {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    margin-top: 0.5rem;
    .title-fav-filter {
      font-size: 1.1rem;
      color: #454548;
      font-weight: bold;
      line-height: 1.7rem;
    }
    .icon-expand-filter {
      color: var(--accent-color);
    }
  }
  .content-fav-filter {
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border: 0.1rem solid #cacaca;
    border-radius: 0.3rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    .card-fav-filter {
      height: 5rem;
      min-width: 18.7rem;
      font-size: 1.3rem;
      border: 0.1rem solid #cacaca;
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.070);
      color: gray;
      padding: 0.5rem;
      border-radius: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .card-fav-info {
        width: 85%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .card-fav-name {
          font-size: 0.8rem;
          .card-fav-title {
            font-size: 0.8rem;
            color: #454548;
          }
        }
      }
      .card-actions-btn {
        width: 15%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .icon-select-filter, .icon-delete-filter {
          border: 0.1rem solid #cacaca;
          box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.070);
          border-radius: 50%;
          color: #cacaca;
        }
        .icon-delete-filter {
          font-size: 1.2rem;
          padding: 0.1rem;
        }
        .icon-select-filter:hover, .icon-delete-filter:hover {
          cursor: pointer;
          color: var(--accent-color);
          border-color: var(--accent-color);
        }
      }
    }
  }
  .alert-no-fav-filter {
    height: 4rem;
    margin-top: 0.5rem;
    padding: 0.5rem;
    border: 0.1rem solid #cacaca;
    border-radius: 0.3rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .title-alert-fav-filter {
      font-size: 0.9rem;
      color: #808080;
    }
    .icon-no-filter {
      color: #cacaca;
      font-size: 1.4rem;
    }
  }
}
</style>
