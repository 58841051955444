<template>
  <div>
    <Menu :showSubMenu="false"></Menu>
      <div class="content-not-found">
      <span class="not-found"> 404</span>
    </div>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'

export default {
  name: 'NotFound',
  components: {
    Menu
  }
}
</script>
<style lang="scss">
.content-not-found{
  text-align: center;
}
.not-found{
    font-size: 200px;
    color: $color-purple-darker;
    text-align: center;
    margin-top: 15%;
    display: block;
  }
</style>
