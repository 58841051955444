<template>
  <div class="container-card-health"
    :style="{'--color': returnColorGraph}"
    @mouseenter="changeHover(true)"
    @mouseleave="changeHover()">
    <div class="section-front-card-health" :ref="`front-health-${uniqueID}`">
      <div class="header-front-card-health">
        <p class="title-front-card-health">{{ $t(`${details.title}`) }}</p>
      </div>
      <div class="content-canvas-card-health">
        <canvas :id="`content-card-health-${uniqueID}`" width="100%" height="100%"></canvas>
         <div class="ball-card-health" :style="{'background': returnColorGraph}">
          <p class="title-card-health" v-if="details.type === 'churn'">{{ setLabel }}</p>
          <p class="title-card-health title-score" v-else>{{ valueChart }}</p>
         </div>
      </div>
      <div class="footer-front-card-health">
        <i class="material-symbols-outlined icon-help-card-health">help</i>
      </div>
    </div>
    <div class="section-back-card-health" :ref="`back-health-${uniqueID}`" :style="{'display': 'none', '--back-background': `${returnColorGraph}20`, '--before-background': `${returnColorGraph}40`}">
      <i class="material-icons icon-back-card-health">{{ details.icon }}</i>
      <p class="title-back-card-health">{{ $t(`${details.title}`) }}</p>
      <p class="tooltip-back-card-health">{{ $t(`${details.tooltip}`) }}</p>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import Chart from 'chart.js'
import mixins from '@/_mixins/health'
window.Chart = Chart

export default {
  name: 'SquareHealthCard',
  props: ['dataCircle', 'details'],
  mixins: [mixins],
  components: {},
  data () {
    return {
      backDisplay: false,
      dataChart: {},
      valueChart: this.dataCircle
    }
  },
  mounted () {
    this.renderData()
  },
  computed: {
    uniqueID () { return Math.floor(10000 + Math.random() * 90000) },
    setLabel () { return this.getLabelChurn(this.valueChart) },
    returnColorGraph () { return this.getColorForHealth(this.valueChart, this.details.type) }
  },
  methods: {
    changeHover (enter) {
      this.backDisplay = enter
      const cardFront = this.$refs[`front-health-${this.uniqueID}`]
      const cardBack = this.$refs[`back-health-${this.uniqueID}`]

      if (this.backDisplay) {
        gsap.to(cardFront, {
          opacity: 0,
          duration: 0.3,
          onComplete: () => {
            gsap.set(cardFront, { display: 'none' })
            gsap.set(cardBack, { display: 'flex', opacity: 0 })
            gsap.to(cardBack, { opacity: 1, duration: 0.3 })
          }
        })
      } else {
        gsap.to(cardBack, {
          opacity: 0,
          duration: 0.3,
          onComplete: () => {
            gsap.set(cardBack, { display: 'none' })
            gsap.set(cardFront, { display: 'flex', opacity: 0 })
            gsap.to(cardFront, { opacity: 1, duration: 0.3 })
          }
        })
      }
    },
    async renderData () {
      const formattedData = this.valueChart
      this.dataChart = {
        labels: ['', ''],
        datasets: [{
          backgroundColor: [this.returnColorGraph, '#f1f1f1'],
          data: [formattedData, 100 - formattedData]
        }]
      }

      const ctx = document.getElementById(`content-card-health-${this.uniqueID}`).getContext('2d')

      this.graph = new window.Chart(ctx, {
        type: 'doughnut',
        data: this.dataChart,
        options: {
          responsive: true,
          legend: { display: false },
          cutoutPercentage: 70,
          title: {
            display: false,
            text: this.$t(`${this.title}`),
            fontSize: 11,
            fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
            fontStyle: '500',
            fontColor: '#454548',
            padding: 0,
            position: 'bottom'
          },
          animation: {
            duration: 3000
          },
          tooltips: {
            enabled: false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-card-health {
  width: 11vw;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  user-select: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  .section-front-card-health {
    padding: 0.5vw;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0.5vw;
    .header-front-card-health {
      width: 100%;
      height: 20%;
      border-bottom: 1px solid #e1e1e1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title-front-card-health {
        font-size: 0.8vw;
        text-align: center;
        font-weight: 400;
        color: #808080;
      }
    }
    .content-canvas-card-health {
      position: relative;
      width: 8vw;
      height: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
      .title-card-health {
        width: 100%;
        text-align: center;
        white-space: normal;
        color: #ffffff;
        font-size: 0.9vw;
        font-weight: 700;
        text-shadow: 2px 2px 4px #b6b6b640;
      }
      .ball-card-health {
        position: absolute;
        background: #f1f1f1;
        width: 4.5vw;
        height: 4.5vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        box-shadow: 0 0 4px #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: center;
        .title-score {
          color: #ffffff;
          font-size: 1.7vw;
          letter-spacing: 1px;
        }
      }
    }
    .footer-front-card-health {
      background: #f1f1f1;
      box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.02);
      width: 100%;
      height: 10%;
      border: 1px solid #e1e1e1;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-help-card-health {
        font-size: 1vw;
        color: #808080;
      }
    }
  }
  .section-back-card-health {
    background: var(--back-background);
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    visibility: none;
    opacity: 0;
    border-radius: 7px;
    padding: 0.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8vw;
    .icon-back-card-health {
      border-radius: 50%;
      font-size: 2.1vw;
      color: var(--color);
      filter: drop-shadow(0px 0px 2px var(--color));
    }
    .title-back-card-health {
      color: #454548;
      font-size: 0.85vw;
      font-weight: 600;
      text-align: center;
    }
    .tooltip-back-card-health {
      font-size: 0.7vw;
      line-height: 1vw;
      font-weight: 500;
      letter-spacing: 0.03vw;
      color: #454548;
      text-align: center;
      z-index: 2;
    }
  }
  .section-back-card-health::before {
    content: '';
    position: absolute;
    right: -2.5vw;
    bottom: -1vw;
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    background: var(--before-background);
  }
}
</style>
