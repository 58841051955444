<template>
  <div class="template-wpp" :class="name" @click="selectTemplate()">
    <div class="header-card-wpp">
      <div class="account-header-card-wpp">
        <i class="material-icons header-icon-card-wpp">chevron_left</i>
        <div class="image-and-account-header-wpp">
          <img class="img-account-wpp" src="https://img.binds.co/4399ff05-53d9-4521-be2b-29deb9d151b1/-/crop/1046x1021/121,145/-/preview/">
          <app-heading level="h7" class="title-header-wpp-card">Binds.co</app-heading>
        </div>
        <div class="check-template">
          <div class="checked-template"></div>
        </div>
      </div>
      <app-heading level="h7" class="title-wpp-card">{{ $t('whatsapp.today') }}</app-heading>
    </div>
    <div class="content-card-wpp">
      <div class="section-msg-wpp">
        <div class="image-wpp-card" v-if="urlImg">
          <img class="img-acc-wpp-card" :src="urlImg">
        </div>
        <app-heading level="h7" class="msg-wpp-card"><span class="text-to-replace" v-html="textFormated"></span> <span class="dynamic-text" v-html="getDynamicText"></span> <i class="material-icons dynamic-hint" v-title="$t('whatsapp.explain_dynamic_text')" title-max-width="315" title-placement="left">help</i> <span class="link-survey-wpp" v-if="showLink"> {{ previewLink }}</span></app-heading>
        <app-heading level="h7" class="msg-footer-wpp-card">{{ $t('whatsapp.text_footer_2') }}</app-heading>
        <app-heading level="h7" class="hour-wpp-card">{{ currentHour }}</app-heading>
        <div class="arrow-wpp-card"></div>
        <div class="button-wpp-card" v-if="btnFooter || optOutButton">
          <app-heading level="h7" class="title-btn-wpp-card" v-if='btnFooter'> <i class="material-icons icon-btn-card-wpp">open_in_new</i> {{ $t('whatsapp.text_button') }}</app-heading>
          <app-heading level="h7" class="title-btn-wpp-card" v-if='btnFooter || optOutButton'> <i class="material-icons icon-btn-card-wpp">open_in_new</i> Parar notificações</app-heading>
        </div>
      </div>
    </div>
    <div class="footer-card-wpp">
      <div class="content-footer-wpp">
        <i class="material-icons icon-card-wpp">add</i>
        <div class="fake-input-wpp">
          <i class="material-icons-outlined icon-card-wpp" style="font-size: 17px">description</i>
        </div>
        <i class="material-icons-outlined icon-card-wpp">photo_camera</i>
        <i class="material-icons icon-card-wpp">mic</i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateSimpleText',
  props: ['textFormated', 'optOutButton', 'urlImg', 'btnFooter', 'showLink', 'name'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
    getDynamicText () {
      const defaultText = this.$t('whatsapp.text_default')
      return this.showLink ? `${defaultText}:` : defaultText
    },
    previewLink () {
      const surveyHashId = this.$store.getters['survey/getSurvey'].hashId
      return 'https://surveyv2.binds.co/#/link/' + surveyHashId
    },
    lang () {
      return this.$store.getters['account/getLang']
    },
    currentHour () {
      this.$moment.locale(this.lang)
      return this.$moment().format('LT')
    }
  },
  methods: {
    selectTemplate () {
      this.$emit('select-template', `.${this.name}`)
    }
  }
}
</script>

<style lang="scss">
.template-wpp {
  width: 22vw;
  height: 100%;
  background-image: url('https://i.pinimg.com/originals/35/f3/e9/35f3e9c4b86568b4919949a9307da2a9.png');
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #cacaca;
  border-radius: 5px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  .header-card-wpp {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    .account-header-card-wpp {
      position: relative;
      width: 100%;
      height: 60px;
      margin-bottom: 10px;
      border-bottom: 1px solid #cacaca;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      .header-icon-card-wpp {
        color: #4ca7ff;
        font-size: 30px;
      }
      .image-and-account-header-wpp {
        width: 95%;
        height: 100%;
        display: flex;
        align-items: center;
        .img-account-wpp {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
        .title-header-wpp-card {
          font-size: 15px;
          font-weight: bold;
          color: #454548;
        }
      }
      .check-template {
        position: absolute;
        right: 5px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        padding: 2px;
        border: 2px solid #454548;
        .checked-template {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }
    }
    .title-wpp-card {
      height: 25%;
      font-size: 0.7vw;
      border-radius: 35px;
      color: #FFFFFF;
      padding: 2px 17px;
      display: flex;
      align-items: center;
      background: #00000059;
    }
  }
  .content-card-wpp {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    user-select: none;
    .section-msg-wpp {
      position: relative;
      background: #ffffff;
      width: 90%;
      padding: 5px;
      border-radius: 10px;
      .image-wpp-card {
        width: 100%;
        height: 9vw;
        margin-bottom: 5px;
        border-radius: 10px;
        overflow: hidden;
        .img-acc-wpp-card {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .msg-wpp-card {
        font-size: 0.75vw;
        color: #454548;
        font-weight: 400 !important;
        overflow-wrap: break-word;
        .link-survey-wpp {
          font-size: 0.7vw;
          color: #4ca7ff;
        }
        .text-to-replace {
          white-space: pre-wrap;
        }
        .dynamic-text {
          background: #e4dcd4;;
          line-height: 25px;
        }
        .dynamic-hint {
          font-size: 17px;
          ;border-radius: 12px;
        }
      }
      .msg-footer-wpp-card {
        font-size: 0.65vw;
        color: #9b9b9b;
        padding: 5px 0;
      }
      .hour-wpp-card {
        color: #454548;
        font-size: 0.5vw;
        text-align: right;
      }
      .arrow-wpp-card {
        position: absolute;
        bottom: 5px;
        left: -3px;
        width: 10px;
        height: 10px;
        background: #FFFFFF;
        transform: rotate(35deg);
      }
      .button-wpp-card {
        width: 100%;
        margin-top: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .title-btn-wpp-card {
          color: #4ca7ff;
          margin: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.75vw;
          padding: 10px;
          width: 100%;
          border-top: 1px solid #cacaca;
          height: 37px;
          .icon-btn-card-wpp {
            font-size: 0.85vw;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .footer-card-wpp {
    background: #FFFFFF;
    width: 100%;
    height: 47px;
    border-top: 1px solid #cacaca;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 19px 0;
    .content-footer-wpp {
      width: 100%;
      height: 27px;
      padding: 0 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon-card-wpp {
        color: #4ca7ff;
        font-size: 23px;
      }
      .fake-input-wpp {
        width: 65%;
        height: 100%;
        border-radius: 35px;
        border: 1px solid #cacaca;
        padding-right: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
</style>
