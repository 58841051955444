<template>
  <div class="container-progress-bar">
    <div class="step-progress-bar-first" v-title="$t('resend.select_surveys')">
      <i class="material-icons icon-progress-bar-first">fact_check</i>
    </div>
    <div class="line-progress">
      <p class="line" :class="{ 'progress': step > 1, 'removeProgress': step === 1}"></p>
    </div>
    <div class="step-progress-bar" :class="{'activeColor': step >= 2, 'inactiveColor': step < 2}" v-title="$t('resend.config_resend')">
      <i class="material-icons icon-progress-bar">settings</i>
    </div>
    <div class="line-progress">
      <p class="line" :class="{'progress': step > 2, 'removeProgress': step <= 2}"></p>
    </div>
    <div class="step-progress-bar" :class="{'activeColor': step >= 3, 'inactiveColor': step < 3}">
      <i class="material-icons icon-progress-bar">check_circle</i>
    </div>
</div>
</template>

<script>
export default {
  name: 'VerticalProgressBar',
  props: ['currentStep'],
  components: {},
  data () {
    return {
      step: 1
    }
  },
  watch: {
    currentStep: {
      immediate: true,
      handler (newVal) {
        if (newVal === 'surveys') { this.step = 1 } else if (newVal === 'settings') { this.step = 2 } else { this.step = 3 }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-progress-bar {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .step-progress-bar-first {
    z-index: 2;
    width: 1.5vw;
    height: 1.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--accent-color);
    color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 0px 5px var(--accent-color);
    .icon-progress-bar-first {
      font-size: 1.1vw;
    }
  }
  .step-progress-bar {
    z-index: 2;
    width: 1.5vw;
    height: 1.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
    .icon-progress-bar {
      font-size: 1.1vw;
    }
  }
  .line-progress {
    background: #e1e1e1;
    width: 4.5px;
    height: 45%;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.075);
    display: flex;
    flex-direction: column;
    align-items: center;
    .line {
      width: 4.5px;
      border-radius: 35px;
    }
  }
}
.activeColor {
  animation: activeColor 2s ease-in-out;
  animation-fill-mode: both;
}
.inactiveColor {
  animation: inactiveColor 0.5s ease-in-out;
  animation-fill-mode: both;
  background: #e1e1e1;
}
.progress {
  background-color: var(--accent-color);
  animation: progressBar 1s ease-in-out;
  animation-fill-mode: both;
}
.removeProgress {
  background-color: #e1e1e1;
  animation: noprogressBar 1s ease-in-out;
  animation-fill-mode: both;
}

@keyframes activeColor {
  0% {
    color: #e1e1e1;
    background: #e1e1e1;
  }
  100% {
    color: #ffffff;
    background: var(--accent-color);
    box-shadow: 0 0px 5px var(--accent-color);
  }
}

@keyframes inactiveColor {
  0% {
    color: var(--accent-color);
  }
  100% {
    color: #b7b7b7;
  }
}

@keyframes defaultProgress {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

@keyframes progressBar {
  0% {
    height: 0%;
  }
  100% {
    height: 99.8%;
  }
}

@keyframes noprogressBar {
  0% {
    height: 99.8%;
    background-color: var(--accent-color);
  }
  100% {
    height: 0%;
    background-color: #e1e1e1;
  }
}

</style>
