<template>
  <div class="center-lg center-md">
    <div class="label-5emo" v-if="response === 100 || response === undefined">
      <old-5emo class="hvr-grow" name="icon-joy-gee" size="40" color="#49BB59"></old-5emo>
      <span v-if="(question.type === 'kpi' && response === undefined) || (question.type === 'kpi' && response === 100 && tickets)">
        <app-heading level="h5" :style="{ 'color': colors.splashColor}">{{t('dashboard.graphics.ex')}}</app-heading>
      </span>
      <span v-if="(question.type === 'csat' && response === undefined) || (question.type === 'csat' && response === 100 && tickets)">
        <app-heading level="h5" :style="{ 'color': colors.splashColor}">{{t('dashboard.graphics.vs')}}</app-heading>
      </span>
    </div>
    <div class="label-5emo" v-if="response === 75 || response === undefined">
      <old-5emo class="hvr-grow" name="icon-happy-gee" size="40" color="#37B0E4"></old-5emo>
      <span v-if="(question.type === 'kpi' && response === undefined) || (question.type === 'kpi' && response === 75 && tickets)">
        <app-heading level="h5" :style="{ 'color': colors.splashColor}">{{t('dashboard.graphics.vg')}}</app-heading>
      </span>
      <span v-if="(question.type === 'csat' && response === undefined) || (question.type === 'csat' && response === 75 && tickets)">
        <app-heading level="h5" :style="{ 'color': colors.splashColor}">{{t('dashboard.graphics.sat')}}</app-heading>
      </span>
    </div>
    <div class="label-5emo" v-if="response === 50 || response === undefined">
      <old-5emo class="hvr-grow" name="icon-neutral-sleep" size="40" color="#9E9E9E"></old-5emo>
      <span v-if="(question.type === 'kpi' && response === undefined) || (question.type === 'kpi' && response === 50 && tickets)">
        <app-heading level="h5" :style="{ 'color': colors.splashColor}">{{t('dashboard.graphics.satisf')}}</app-heading>
      </span>
      <span v-if="(question.type === 'csat' && response === undefined) || (question.type === 'csat' && response === 50 && tickets)">
        <app-heading level="h5" :style="{ 'color': colors.splashColor}">{{t('dashboard.graphics.ind')}}</app-heading>
      </span>
    </div>
    <div class="label-5emo" v-if="response === 25 || response === undefined">
      <old-5emo class="hvr-grow" name="icon-unhappy-sleep" size="40" color="#F26C3C"></old-5emo>
      <span v-if="(question.type === 'kpi' && response === undefined) || (question.type === 'kpi' && response === 25 && tickets)">
        <app-heading level="h5" :style="{ 'color': colors.splashColor}">{{t('dashboard.graphics.bd')}}</app-heading>
      </span>
      <span v-if="(question.type === 'csat' && response === undefined) || (question.type === 'csat' && response === 25 && tickets)">
        <app-heading level="h5" :style="{ 'color': colors.splashColor}">{{t('dashboard.graphics.un')}}</app-heading>
      </span>
    </div>
    <div class="label-5emo" v-if="response === 0 || response === undefined">
      <old-5emo class="hvr-grow" name="icon-wow-open" size="40" color="#EE2E35"></old-5emo>
      <span v-if="(question.type === 'kpi' && response === undefined) || (question.type === 'kpi' && response === 0 && tickets)">
        <app-heading level="h5" :style="{ 'color': colors.splashColor}">{{t('dashboard.graphics.vb')}}</app-heading>
      </span>
      <span v-if="(question.type === 'csat' && response === undefined) || (question.type === 'csat' && response === 0 && tickets)">
        <app-heading level="h5" :style="{ 'color': colors.splashColor}">{{t('dashboard.graphics.vu')}}</app-heading>
      </span>
    </div>
  </div>
</template>

<script>
import { translator } from '@/_helpers'
export default {
  name: 'app5emo',
  props: ['response', 'question', 'colors', 'tickets'],
  components: {
    'old-5emo': () => import('@binds-tech/binds-design-system/src/components/Icon/Old5emo'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  methods: {
    t (text) {
      return translator.translateThisText(this.question.language, text)
    }
  }
}
</script>
<style lang="scss">
.spacing-5emo {
  & i + i {
    margin-left: 30px;
  }
  padding-top: 40px;
}
.purple-light {
  color: #9871dd;
}
.yellow-poker {
  color: #ffb500 !important;
}
.color-pink {
  color: $color-pink-lighter !important;
}
.label-5emo {
    display: block;
    width: 213px;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 28px;
    span {
      display: inline-block;
      vertical-align: top;
      margin: 10px 0 0 20px;
    }
}
</style>
