import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import account from './modules/account'
import survey from './modules/survey'
import alerts from './modules/alerts'
import sendhub from './modules/sendhub'
import seeds from './modules/seeds'
import templates from './modules/templates'
import dashboard from './modules/dashboard'
import filters from './modules/filters'
import questionSettings from './modules/questionSettings'
import widget from './modules/widget'
import approaches from './modules/approaches'
import filtersSocialMedia from './modules/filtersSocialMedia'
import filtersInsight from './modules/filtersInsight'
import overview from './modules/overview'
import providers from './modules/providers'

Vue.use(Vuex)

const store = {
  modules: {
    account,
    survey,
    alerts,
    sendhub,
    seeds,
    templates,
    dashboard,
    questionSettings,
    filters,
    widget,
    approaches,
    filtersSocialMedia,
    filtersInsight,
    overview,
    providers
  },
  plugins: [
    createPersistedState({
      key: 'bindsAccount',
      paths: ['account']
    }),
    createPersistedState({
      key: 'bindsSurvey',
      paths: ['survey'],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: 'bindsSendhub',
      paths: ['sendhub']
    }),
    createPersistedState({
      key: 'bindsTemplates',
      paths: ['templates']
    }),
    createPersistedState({
      key: 'bindsFilters',
      paths: ['filters'],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: 'bindsDashboard',
      paths: ['dashboard'],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: 'bindsApproaches',
      paths: ['approaches'],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: 'bindsFiltersSocialMedia',
      paths: ['filtersSocialMedia'],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: 'bindsFiltersInsight',
      paths: ['filtersInsight'],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: 'bindsOverview',
      paths: ['overview'],
      storage: window.sessionStorage
    })
  ]
}
export default new Vuex.Store(store)
