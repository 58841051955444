<template>
  <div class="content-5num" :class="{ 'stars-widget': isWidget && (question.stars && question.stars.active) }">
    <app-form-rate
      v-model="inputValue"
      :isStar="(question.stars && question.stars.active)"
      :useStarNumbers="(question.stars && question.stars.numbers)"
      :items="items"
      item-value="id"
      item-text="id"
      :isGradient="question.gradient"
      :textColor="colors.actionText"
      :bgColor="colors.actionColor"
      :hoverColor="colors.buttonHover"
      :labelColor="colors.splashColor"
      :max-label="t(false)"
      :min-label="t(true)"
    />
  </div>
</template>

<script>
import { translator, gradientMaker } from '@/_helpers'

export default {
  name: 'app5num',
  props: ['response', 'question', 'colors', 'isWidget'],
  components: {
    'app-form-rate': () => import('@binds-tech/binds-design-system/src/components/Form/Rate')
  },
  data () {
    return {
      inputValue: null,
      items: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 }
      ]
    }
  },
  created () {
    gradientMaker.checkGradientAndReturnTheColors(this.question, this.items)

    this.$root.$on('language-update', (language, id) => {
      if (this.question.id === id) {
        this.question.startLabel = translator.translateThisText(language, 'components.csat_not_satisfied')
        this.question.endLabel = translator.translateThisText(language, 'components.csat_satisfied')
      }
    })

    if (this.question.startLabel === '' && this.question.endLabel === '') {
      this.question.startLabel = translator.translateThisText(this.question.language, 'components.csat_not_satisfied')
      this.question.endLabel = translator.translateThisText(this.question.language, 'components.csat_satisfied')
    }
  },
  methods: {
    t (isStart) {
      // Não remover segunda parte da condição. Foi feita para lidar com pesquisas vindas da v1.
      if ((this.question.stars && !this.question.stars.active) && (!this.question.startLabel && !this.question.endLabel)) {
        this.question.startLabel = translator.translateThisText(this.question.language, 'components.csat_not_satisfied')
        this.question.endLabel = translator.translateThisText(this.question.language, 'components.csat_satisfied')
      }

      if (isStart) {
        return this.question.startLabel
      }

      return this.question.endLabel
    }
  }
}
</script>
<style lang="scss">
.content-5num {
  padding-bottom: 20px;
  & .star-span {
    top: 20.6px !important;
  }
}
.stars-widget {
  margin-left: -32px !important;
  margin-bottom: 32px;
}
</style>
