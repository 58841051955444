<template>
  <div>
    <div class="content-referralDynamic mt30" v-if="question.referral">
        <div  class="row center-lg center-md center-sm comment" v-for="(item, index) in question.referral.dynamicFields" v-bind:key="index">
            <div class="col-lg-8 col-md-8 col-sm-8" v-if="item.active">
                <app-form-input secondary border-solid v-model="item.placeholder" :placeholder="item.placeholder"></app-form-input>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// Options sao tratados no sidebarconfig ReferralDynamicOptions.vue
export default {
  name: 'appreferraldynamic',
  components: {
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input')
  },
  props: ['question']
}
</script>
<style lang="scss">
.content-referralDynamic {
  width: 430px;
  max-height: 350px;
  overflow-y: scroll;
}
</style>
