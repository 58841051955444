<template>
  <div class="container-tag-select">
    <div class="content-tag-select">
      <button class="btn-open-tag-manager" @click="showTagManager = true">
        <i class="material-icons icon-tag-manager">local_offer</i>
        <p class="title-open-manager-tag">{{ $t('tags.edit_tag') }}</p>
      </button>
      <div class="section-tag-selected">
        <div class="tag-selected" v-for="(tag, idx) in dataTag.tags" :key="idx" :style="{'background': `${tag.color}90`}">
          <p class="title-tag-selected">{{ tag.name }}</p>
          <i class="material-icons remove-tag-selected" @click="removeTag(tag)">close</i>
        </div>
      </div>
      <app-select-binds
        :key="keyUpdateTag"
        :options="localTags"
        titleItem="name"
        :id="`select-tab-${dataTag.sendingId}`"
        :value="dataTag.tagInput"
        @input="handleInputTag($event)"
        style="width: 210px; min-height: 1.5vw; height: 1.5vw; border-radius: 6px; padding-top: 0;"
      />
    </div>
    <TagManager v-if="showTagManager" @close-tag-manager="showTagManager = false" @tag-manager-removed="updateTags($event)" />
  </div>
</template>

<script>
import { tagsService } from '@/_services'
import TagManager from './TagManager.vue'

export default {
  name: 'TagSelect',
  props: ['dataTag', 'tags'],
  components: {
    TagManager,
    'app-select-binds': () => import('@binds-tech/binds-design-system/src/components/Select/SelectBinds')
  },
  data () {
    return {
      showTagManager: false,
      localTags: [],
      keyUpdateTag: 0
    }
  },
  created () {
    this.localTags = [...this.tags]
  },
  computed: {},
  methods: {
    async handleInputTag (payload) {
      if (!payload._id) return

      this.$emit('set-loading', true)

      const foundTag = this.dataTag.tags.find(tag => tag._id === payload._id)

      if (foundTag) {
        this.showTagError()
        this.keyUpdateTag++
        this.$emit('set-loading', false)
        return
      }

      const add = await tagsService.updateTagToResponse(this.dataTag.sendingId || this.dataTag.sending, [payload._id])

      if (add === undefined) { return this.showTagError() }

      this.dataTag.tags.push(payload)

      this.keyUpdateTag++
      this.$emit('set-loading', false)
    },
    async removeTag (tag) {
      this.$emit('set-loading', true)
      this.loadTagSelect = true

      const idx = this.dataTag.tags.indexOf(tag)

      if (idx >= 0) {
        this.dataTag.tags.splice(idx, 1)
        await tagsService.updateTagToResponse(this.dataTag.sendingId || this.dataTag.sending, [tag._id], true)
      }

      this.keyUpdateTag++
      this.$emit('set-loading', false)
    },
    showTagError () {
      return this.$store.commit('alerts/alert', {
        message: this.$t('tags.exist_tag'),
        position: 'bottomRight',
        showAlert: true
      })
    },
    updateTags (id) {
      this.dataTag.tags = this.dataTag.tags.filter(e => e._id !== id)
      this.localTags = this.tags.filter(e => e._id !== id)
    }
  }
}
</script>

<style lang="scss" scoped>
.container-tag-select {
  height: 1.6vw;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .content-tag-select {
    width: 98%;
    display: flex;
    align-items: center;
    gap: 5px;
    .btn-open-tag-manager {
      height: 1.5vw;
      background: #f7f7f7;
      color: #5e5e5e;
      border: 1px solid #e1e1e1;
      padding: 0 7px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      .icon-tag-manager {
        font-size: 0.8vw;
      }
      .title-open-manager-tag {
        font-size: 0.7vw;
      }
    }
    .section-tag-selected {
      max-width: 76%;
      padding: 0 2px;
      overflow-x: auto;
      overflow-y: hidden;
      height: 1.5vw;
      display: flex;
      align-items: center;
      gap: 5px;
      .tag-selected {
        max-width: 10vw;
        height: 1.4vw;
        padding: 0 7px;
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        color: #454548;
        display: flex;
        align-items: center;
        gap: 5px;
        .remove-tag-selected {
          font-size: 0.8vw;
          cursor: pointer;
        }
        .title-tag-selected {
          font-size: 0.7vw;
          max-width: 9vw;
          line-height: 0.9vw;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .section-tag-selected::-webkit-scrollbar {
      height: 4px;
    }
  }
}
</style>
