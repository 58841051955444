<template>
  <div class="row center-lg center-md" :class="{ 'op-ynd': !tickets }">
    <app-button v-if="response === 100 || response >= 90 || response === undefined" class="col-lg-6 col-md-6" style="background: #57ad26" variation="primary">{{t('dashboard.y')}}</app-button>
    <app-button v-if="response === 0 || response === 5 || response === undefined"  class="col-lg-6 col-md-6" style="background: #ea484d" variation="secondary">{{t('dashboard.n')}}</app-button>
    <app-button v-if="response === 50 || response === 75 || response === undefined" class="col-lg-6 col-md-6 maybe">{{t('dashboard.mb')}}</app-button>
  </div>
</template>

<script>
import { translator } from '@/_helpers'

export default {
  name: 'appynm',
  props: ['question', 'response', 'tickets'],
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button')
  },
  methods: {
    t (text) {
      return translator.translateThisText(this.question.language, text)
    }
  }
}
</script>
<style lang="scss">
.op-ynd {
  & button {
    width: 200px;
    border-radius: 24px !important;
    box-shadow: 0 5px 15px 0 rgb(215, 215, 215) !important;
  }
  & .maybe {
    background: #f8c43d !important;
    color: #ffffff !important;
  }
}
</style>
