<template>
  <div class="container-info-user-sidebar">
    <div class="content-photo-user-sidebar-sending">
      <img class="image-user-sidebar-sending" :src="dataInfo.metadataImage || account.theme.mainLogo" alt="image-user">
    </div>
    <div class="content-infos-user-sidebar-sending">
      <div class="section-info-user-sidebar-sending">
        <div class="label-infos-user-sidebar-sending">
          <i class="material-icons-outlined icon-infos-user-sidebar-sending">person</i>
          <p class="item-infos-user-sidebar-sending is-name">{{ dataInfo.name }}</p>
        </div>
        <div class="label-infos-user-sidebar-sending">
          <i class="material-icons-outlined icon-infos-user-sidebar-sending">mail</i>
          <p class="item-infos-user-sidebar-sending">{{ dataInfo.email }}</p>
        </div>
        <div class="label-infos-user-sidebar-sending">
          <i class="material-icons-outlined icon-infos-user-sidebar-sending">smartphone</i>
          <p class="item-infos-user-sidebar-sending">{{ dataInfo.phone }}</p>
        </div>
        <div class="label-infos-user-sidebar-sending">
          <i class="material-icons-outlined icon-infos-user-sidebar-sending">tag</i>
          <p class="item-infos-user-sidebar-sending id-sending-sidebar" @click="copyIdSending()" v-title="$t('surveyList.copy_id')" title-placement="right">{{ dataInfo._id }}</p>
        </div>
        <div class="label-infos-user-sidebar-sending">
          <i class="material-icons icon-infos-user-sidebar-sending">send</i>
          <p class="item-infos-user-sidebar-sending">{{ $t('dashboard.send_type') }}: {{ capitalizeFirstLetter(dataInfo.type) }}</p>
        </div>
      </div>
      <div class="section-info-seed-sidebar-sending">
        <div class="label-infos-seed-sidebar-sending" v-for="infoResponse in responsesInfo" :key="infoResponse.title">
          <div class="square-seed-sidebar-sending" :style="{'background': infoResponse.background, 'color': infoResponse.color}">
            <i class="material-icons-outlined icon-infos-user-sidebar-sending">{{ infoResponse.icon }}</i>
            <p class="item-infos-user-sidebar-sending">{{ infoResponse.title }}</p>
          </div>
          <p class="date-infos-user-sidebar-sending">{{ infoResponse.label }}</p>
        </div>
      </div>
      <div class="type-user-sidebar-sending">
        <button class="is-solved-sidebar-sending" :disabled="loadBtnSolved" @click="markAsResolved()">
          <i class="material-symbols-outlined icon-resolved-sidebar" v-if="dataInfo.isSolved">check_box</i>
          <i class="material-symbols-outlined icon-resolved-sidebar" v-else>check_box_outline_blank</i>
          <p class="label-btn-solved-sidebar-sending">{{ $t('approaches.mark_solved') }}</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { dashboardsService } from '@/_services'

export default {
  name: 'InfoUserSidebar',
  props: ['dataInfo'],
  components: {
  },
  data () {
    return {
      loadBtnSolved: false
    }
  },
  computed: {
    account () { return this.$store.getters['account/getAccount'] },
    responsesInfo () {
      const objResponse = [
        { title: this.$t('dashboard.send'), label: this.dataInfo.createdAt, color: '#40a1bf', background: '#e6ecf8', icon: 'forward_to_inbox' },
        { title: this.$t('dashboard.opening'), label: this.dataInfo.openedAt, color: '#bcbf40', background: '#f8f8e6', icon: 'mark_email_read' },
        { title: this.$t('dashboard.answer'), label: this.dataInfo.respondedAt, color: '#40bf9d', background: '#e6f8f2', icon: 'drafts' },
        { title: this.$t('dashboard.last_answer'), label: this.dataInfo.lastResponseAt, color: '#40bf9d', background: '#e6f8f2', icon: 'drafts' },
        { title: this.$t('dashboard.done'), label: this.dataInfo.doneAt, color: '#40bf4b', background: '#e6f8e7', icon: 'check' }
      ]

      if (this.dataInfo.deletedAt) {
        objResponse.push({ title: this.$t('dashboard.survey_deleted'), label: this.dataInfo.deletedAt, color: '#d42300', background: '#e8bdb5', icon: 'warning' })
      }

      return objResponse
    }
  },
  methods: {
    async copyIdSending () {
      try {
        await navigator.clipboard.writeText(this.dataInfo._id)
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('surveyList.id_copied'),
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      } catch (error) {
        console.error('Failed to copy text: ', error)
      }
    },
    capitalizeFirstLetter (type) {
      return type?.charAt(0).toUpperCase() + type?.slice(1)
    },
    async markAsResolved () {
      this.loadBtnSolved = true

      this.dataInfo.isSolved = !this.dataInfo.isSolved
      try {
        const resolved = { _id: this.dataInfo._id, isSolved: this.dataInfo.isSolved }

        await dashboardsService.updateSolvedStatus(resolved)

        this.$root.$emit('individual-is-solved', resolved)
      } catch (error) {
        console.error('error marking as resolved:', error)
      } finally {
        this.loadBtnSolved = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-info-user-sidebar {
  width: 100%;
  height: 100%;
  display: flex;
  .content-photo-user-sidebar-sending {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .image-user-sidebar-sending {
      background: var(--primary-color);
      object-fit: contain;
      padding: 0.1vw;
      width: 5vw;
      height: 5vw;
      border: 1px solid var(--primary-color);
      border-radius: 50%;
      box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
    }
  }
  .content-infos-user-sidebar-sending {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8vw;
    .section-info-user-sidebar-sending {
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .label-infos-user-sidebar-sending {
        display: flex;
        align-items: center;
        gap: 0.3vw;
        color: #454548;
        .icon-infos-user-sidebar-sending {
          font-size: 1vw;
          color: #454548;
        }
        .item-infos-user-sidebar-sending {
          font-size: 0.9vw;
          line-height: 0.9vw;
        }
        .id-sending-sidebar {
          cursor: pointer;
        }
        .is-name {
          font-size: 0.9vw;
          font-weight: 500;
        }
      }
    }
    .section-info-seed-sidebar-sending {
      width: 100%;
      height: fit-content;
      gap: 0.2vw;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .label-infos-seed-sidebar-sending {
        display: flex;
        align-items: center;
        gap: 0.5vw;
        .square-seed-sidebar-sending {
          width: 9.5vw;
          height: fit-content;
          display: flex;
          border-radius: 5px;
          padding: 1.7px 0.1vw;
          align-items: center;
          gap: 0.3vw;
          .item-infos-user-sidebar-sending {
            font-size: 0.9vw;
          }
          .icon-infos-user-sidebar-sending {
            font-size: 0.9vw;
          }
        }
        .date-infos-user-sidebar-sending {
          font-size: 0.9vw;
          color: #5e5e5e;
        }
      }
    }
    .type-user-sidebar-sending {
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;
      .icon-send-type-sidebar {
        color: #454548;
        font-size: 1.5vw;
        cursor: default;
      }
      .icon-wpp-type {
        background: #454548;
        width: 1.3vw;
        height: 1.3vw;
        .type-icon-wpp-sidebar {
          color: #ffffff;
          font-size: 1vw;
          cursor: default;
        }
      }
      .is-solved-sidebar-sending {
        position: relative;
        width: fit-content;
        height: 1.7vw;
        border-radius: 5px;
        padding: 0 0.1vw;
        border: 1px solid #e1e1e1;
        background: #f7f7f7;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        gap: 0.3vw;
        .label-btn-solved-sidebar-sending {
          color: #5e5e5e;
          font-size: 0.9vw;
        }
        .icon-resolved-sidebar {
          font-size: 0.9vw;
          color: #808080;
        }
      }
    }
  }
}
</style>
