<template>
  <div class="container-like"
    :key="render"
    :style="{'padding-top': preview ? '0' : '90px'}">
    <div class="content-like">
      <div class="section-matrix-like" v-for="(item, idx) in question.subjects" :key="idx">
        <div class="section-label" v-if="!preview">
          <app-form-textarea-limited
            :inputMaxHeight="500"
            :inputMinHeight="20"
            v-model="item.label"
            :identify="`matrixlike-${idx}`"
            class="description-area"
            :colorText="colors.splashColor"
            name="matrixlike-description"
            @updateSurveyTitle="updateQuestionDescription($event,idx)"
          ></app-form-textarea-limited>
        </div>
        <div class="section-label" v-else>
          <app-heading level="h6" :style="{'color': colors.splashColor }">{{ item.label }}</app-heading>
        </div>
        <div class="section-rating">
          <div class="content-rating">
              <app-form-rate-active
                v-model="inputValue"
                :items="items"
                item-value="id"
              />
              <i class="material-icons up">thumb_up</i>
              <i class="material-icons down">thumb_down</i>
          </div>
          <div class="section-delete" v-if="!preview">
            <button @click="deleteItem(idx)">
              <i class="material-icons close">close</i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="add-item" v-if="!preview">
      <button @click="addItem()"><i class="material-icons add">add</i></button>
    </div>
    <theme-style>
      .add-item > button {
        background-color: {{user.account.theme.colors.accent  || '#ff607d'}} !important;
      }
      .add-item > button > .add {
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }};
      }
    </theme-style>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'matrixlike',
  props: ['colors', 'preview', 'question'],
  components: {
    'app-form-rate-active': () => import('@binds-tech/binds-design-system/src/components/Form/RateActive'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-textarea-limited': () => import('@binds-tech/binds-design-system/src/components/Form/TextareaLimited')
  },
  data () {
    return {
      render: 0,
      inputValue: null,
      items: [
        { id: 1, value: 1 },
        { id: 2, value: 2 }
      ]
    }
  },
  created () {
    if (!this.question.subjects) {
      const subjects = [
        { label: this.$i18n.t('survey.option_1') },
        { label: this.$i18n.t('survey.option_2') },
        { label: this.$i18n.t('survey.option_3') }
      ]

      const options = [
        { label: 'dislike', rating: 0 },
        { label: 'like', rating: 100 }
      ]

      this.question.subjects = subjects
      this.question.options = options
      this.$store.commit('survey/upsertQuestion', this.question)
    }
  },
  methods: {
    updateQuestionDescription (e, idx) {
      this.question.subjects[idx].label = e
      this.$store.commit('survey/upsertQuestion', this.question)
    },
    addItem () {
      this.render += 1
      const count = this.question.subjects.length + 1
      this.question.subjects.push({ label: this.$i18n.t('survey.option_added') + count })
      this.$store.commit('survey/upsertQuestion', this.question)
      setTimeout(function () { this.render += 1 }.bind(this), 200)
    },
    deleteItem (idx) {
      this.render += 1
      this.question.subjects.splice(idx, 1)
      setTimeout(function () { this.render = true }.bind(this), 200)
      this.$store.commit('survey/upsertQuestion', this.question)
    }
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    })
  }
}
</script>

<style lang="scss">
.container-like {
  width: 100%;
  min-height: 415px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  .content-like {
    width: 100%;
    min-height: 70%;
    margin-bottom: 15px;
    .section-matrix-like {
      min-height: 50px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .section-label {
        width: 65%;
        min-height: 100%;
        padding-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        .textarea {
          width: 90% !important;
          .input {
            border: 1px dashed #cacaca !important;
            padding: 10px;
          }
        }
      }
      .section-rating {
        width: 35%;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .content-rating {
          position: relative;
          height: 80%;
          width: 70%;
          border-radius: 35px;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
          border: 1px solid #cacaca;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          .rating {
            z-index: 10;
            width: 100%;
            height: 100%;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .rating__item:nth-child(2) {
              width: 50%;
              height: 100%;
              .rating__button {
                width: 100%;
                border-radius: 0;
                height: 100%;
                background: transparent !important;
              }
              .rating__button:hover {
                background: #ea492240 !important;
              }
              .active-rate {
                background: #ea492240 !important;
                transform: scale(1) !important;
                border: none !important;
              }
            }
            .rating__item:nth-child(5) {
              width: 50%;
              height: 100%;
              .rating__button {
                border-left: 1px solid #b6b6b6;
                width: 100%;
                border-radius: 0;
                height: 100%;
                background: transparent !important;
              }
              .rating__button:hover {
                background: #51ad5940 !important;
              }
              .active-rate {
                background: #51ad5940 !important;
                transform: scale(1) !important;
                border: none !important;
              }
            }
          }
          .up {
            position: absolute;
            right: 30px;
            color: #51ad59;
          }
          .down {
            position: absolute;
            left: 30px;
            color: #ea4922;
          }
        }
        .section-delete {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 20%;
          .close {
            color: gray;
          }
        }
      }
    }
  }
  .add-item {
    width: 85%;
    padding-top: 10px;
    display: flex;
    justify-content: flex-start;
    button {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .add {
        color: #FFFFFF;
      }
    }
  }
}

</style>
