<template>
  <div class="container-chart-swot" style="flex: 50%">
    <div class="header-chart-swot" style="text-align: center;">
      <p class="title-chart-swot">Análise SWOT</p>
      <i class="material-symbols-outlined icon-chart-swot" v-title="'Visualize sua estratégia com uma análise sucinta. Forças, fraquezas, oportunidades e ameaças em um relance. Tomada de decisão informada.'" title-max-width="250" title-placement="left">info</i>
    </div>
    <div class="container-swot" v-if="opportunities.length">
    <div class="content-chart-swot">
      <div class="content-swot-figure">
        <div class="swotF flower-center"><span class="title-sw">SWOT</span></div>
        <div class="swotF flower-top"><span class="span-s">S</span></div>
        <div class="swotF flower-bottom"><span class="span-o">O</span></div>
        <div class="swotF flower-right"><span class="span-w">W</span></div>
        <div class="swotF flower-left"><span class="span-t">T</span></div>
      </div>
      <div class="swot-top-info">
        <div class="swot-s-card swot-card">
          <span>Forças</span>
          <ul>
            <li v-for="(item, index) in strenght" :key="index">
              <p>{{ item.key }}</p>
            </li>
          </ul>
        </div>
        <div class="swot-w-card swot-card">
          <span>Fraquezas</span>
          <ul>
            <li v-for="(item, index) in weakeness" :key="index">
              <p>{{ item.key }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="swot-bottom-info">
        <div class="swot-t-card swot-card">
          <span>Ameaças</span>
          <ul>
            <li v-for="(item, index) in threats" :key="index">
              <p>{{ item.key }}</p>
            </li>
          </ul>
        </div>
        <div class="swot-o-card swot-card">
          <span>Oportunidades</span>
          <ul>
            <li v-for="(item, index) in opportunities" :key="index">
              <p>{{ item.key }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </div>
    <div v-else style='height: 300px'>
      <NoData/>
    </div>
  </div>
</template>
<script>
import NoData from '../NoData.vue'

export default {
  name: 'GraphSwot',
  props: ['data'],
  components: {
    NoData
  },
  data () {
    return {
      graph: {},
      dataChart: {},
      sChart: 'false',
      opportunities: [],
      strenght: [],
      threats: [],
      weakeness: []
    }
  },
  mounted () {
    if (this.data) {
      this.opportunities = this.data.o
      this.strenght = this.data.s
      this.threats = this.data.t
      this.weakeness = this.data.w
    }
  }
}
</script>
<style lang="scss" scoped>
.container-chart-swot {
  width: 100%;
  border: 0.1rem solid #e1e1e1;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.070);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .header-chart-swot {
    padding: 0.5rem;
    width: 100%;
    height: 17%;
    border-bottom: 0.1rem solid #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    color: var(--accent-color);
    .title-chart-swot {
      width: 100%;
      font-size: 1.3vw;
      font-weight: bold;
    }
  }
  .container-swot {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .content-chart-swot {
      position: relative;
      width: 80%;
      min-height: 500px;
      padding: 0.5rem;
      .content-swot-figure {
        position: absolute;
        width: 600px;
        height: 500px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .swotF {
          width: 150px;
          height: 150px;
          display: block;
          border: 2px solid gray;
          transform: rotate(-45deg);
          position: absolute;
          .title-sw {
            font-size: 2vw;
            color: #454548;
            font-weight: bold;
            width: 100%;
            height: 50px;
            transform: translate(-50%, -50%) rotate(45deg);
            position: absolute;
            left: 56%;
            top: 60%;
          }
        }
        .flower-center {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          z-index: 2000;
          border-radius: 8px;
          background-color: #fff;
          border-top-color: #008000;
          border-bottom-color: #808b00;
          border-left-color: #be2626;
          border-right-color: gray;
          span {
            width: fit-content;
            color: #454548;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(46deg);
            font-weight: bold;
          }
        }
        .flower-top {
          position: relative;
          top: 40px;
          left: 37%;
          border-radius: 0 8px 0 0;
          border-color: #008000;
          background-color: #6dd978;
          display: flex;
          align-items: center;
          justify-content: center;
          .span-s {
            margin-bottom: 15px;
            font-size: 2.4vw;
            font-weight: bold;
            color: #ffffff;
            transform: rotate(45deg);
          }
        }
        .flower-bottom {
          bottom: 40px;
          left: 38%;
          border-radius: 0 0 0 8px;
          border-color: #808b00;
          background-color: #ddf54c;
          display: flex;
          align-items: center;
          justify-content: center;
          .span-o {
            margin-top: 10px;
            font-size: 2.4vw;
            font-weight: bold;
            color: #ffffff;
            transform: rotate(45deg);
          }
        }
        .flower-right {
          right: 17%;
          top: 34%;
          border-radius: 0 0 8px 0;
          border-color: gray;
          background-color: #cacaca;
          display: flex;
          align-items: center;
          justify-content: center;
          .span-w {
            margin-top: 15px;
            font-size: 2.4vw;
            font-weight: bold;
            color: #ffffff;
            transform: rotate(45deg);
          }
        }
        .flower-left {
          left: 17%;
          top: 35%;
          border-radius: 8px 0 0 0;
          border-color: #be2626;
          background-color: #fc7676;
          display: flex;
          align-items: center;
          justify-content: center;
          .span-t {
            margin-right: 15px;
            font-size: 2.4vw;
            font-weight: bold;
            color: #ffffff;
            left: 50%;
            top: 50%;
            transform: rotate(45deg);
          }
        }
      }
      .swot-top-info {
        height: 50%;
        display: flex;
        justify-content: space-between;
      }
      .swot-bottom-info {
        height: 50%;
        display: flex;
        justify-content: space-between;
      }
      .swot-card {
        width: 50%;
        height: fit-content;
        padding-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        span, ul {
          width: 70%;
        }
        span {
          font-size: 1.1vw;
          font-weight: bold;
          color: #454548;
        }
        ul {
          li {
            p {
              font-size: 0.8vw;
              color: #454548;
              font-weight: lighter;
              line-height: 1.2rem;
            }
          }
        }
      }
    }
  }
}
.swot-s-card, .swot-t-card {
  text-align: right;
  padding-right: 11rem;
}

.swot-w-card, .swot-o-card {
  text-align: left;
  padding-left: 11rem;
}

</style>
