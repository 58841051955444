import i18n from '../translations/i18n'

export const referralSettings = {
  getOptions,
  getDynamicFields,
  getmappingType
}

function getOptions () {
  const settings = {
    addLabel: `+ ${i18n.t('survey_store.input_add_label')}`,
    fields: {
      name: {
        active: true,
        required: true,
        placeholder: i18n.t('survey_store.input_name'),
        label: 'name'
      },
      email: {
        active: true,
        required: true,
        placeholder: 'email@binds.co',
        label: 'email'
      },
      phone: {
        active: true,
        required: true,
        placeholder: '+55 xx xxxx-xxxx',
        label: 'phone'
      }
    }
  }
  return settings
}

function getDynamicFields () {
  const settings = {
    addLabel: `+ ${i18n.t('survey_store.input_add_label')}`,
    dynamicFields: [{
      active: true,
      required: true,
      placeholder: i18n.t('survey_store.input_name'),
      type: 'text',
      label: 'name',
      value: 'name',
      mappingType: 'name',
      key: '0'
    }]
  }
  return settings
}

function getmappingType () {
  const settings = [
    { label: i18n.t('survey_store.options.name'), value: 'name' },
    { label: i18n.t('survey_store.options.email'), value: 'email' },
    { label: i18n.t('survey_store.options.phone'), value: 'phone' },
    { label: i18n.t('survey_store.options.other'), value: 'other' }
  ]
  return settings
}
