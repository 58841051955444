<template>
<div>
  <div class="content-upload-setImage">
    <uploadcare
      v-if="imageProps"
      :publicKey="publicKey"
      :secureSignature="signature"
      :secureExpire="expire"
      :context="context"
      :crop="imageProps.crop"
      :imagesOnly="imageProps.imagesOnly"
      :imageShrink="imageProps.imageShrink"
      :cdnBase="'https://img.binds.co'"
      :UPLOADCARE_LOCALE="'pt'"
      @success="onSuccess"
      @error="onError"
    >
      <div class="set-image" v-on:click="getParams">
        <div v-if="!imageProps.success">
          <span>{{title}}</span>
          <i class="material-icons">{{icon}}</i>
          <app-button variation="primary" size="large" floating>
            <i class="material-icons">add</i>
          </app-button>
        </div>
        <div v-else>
          <img class="img-container" :src="returnUrl()"/>
          <app-button class="btn-circle-accent" style="margin-top: 20px;" variation="primary" size="large" floating>
            <i class="material-icons">border_color</i>
          </app-button>
        </div>
      </div>
    </uploadcare>
    <app-button variation="primary"  v-if="imageProps && !imageProps.hideDelete && imageProps.success && imageProps.where !== 'emailSettings' " v-on:click="removeImage" size="medium" floating class="delete-image-upload btn-circle-accent">
      <i class="material-icons">delete_forever</i>
    </app-button>
  </div>
</div>
</template>

<script>
import { uploadcareService } from '@/_services'
export default {
  name: 'SetImg',
  components: {
    uploadcare: () => import('./UploadCare'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button')
  },
  props: ['title', 'icon', 'imageProps', 'context'],
  data () {
    return {
      publicKey: '',
      signature: '',
      expire: 0,
      imageUrl: '',
      uuid: '',
      places: {
        startMessage: { commit: 'survey/updateMessageProps' },
        endMessages: { commit: 'survey/updateEndMessageProps' },
        answeredMessage: { commit: 'survey/updateImagesLogo' },
        emailSettings: { commit: 'sendhub/updateImageProps' },
        imageLogoUrl: { commit: 'account/updateImageLogoUrl' },
        imageMainLogo: { commit: 'account/updateImageMainLogo' },
        imageSmallLogo: { commit: 'account/updateImageSmallLogo' },
        surveySmallLogo: { commit: 'survey/updateImageSmallLogo' },
        userImage: { commit: 'account/updateUserImage' }
      }
    }
  },
  methods: {
    returnUrl () {
      if (this.imageProps.value && typeof this.imageProps.value !== 'object') return this.imageProps.value
      if (this.imageProps.logo) return this.imageProps.logo
      if (this.imageProps.value) return this.imageProps.value.value
      return this.imageUrl
    },
    getParams () {
      const result = uploadcareService.generateHash()
      this.publicKey = result.publicKey
      this.signature = result.signature
      this.expire = result.expire
    },
    onSuccess (event) {
      if (this.imageProps) {
        this.imageUrl = event.cdnUrl
        this.$store.commit('sendhub/cropWidth', event.crop.width)
        if (this.imageProps.where === 'emailSettings' || this.imageProps.where === 'imageLogoUrl') {
          this.imageUrl = `${event.cdnUrl}-/setfill/ffffff/-/format/jpeg/-/resize/260x/`
        }
        this.imageProps.value = this.imageUrl
        this.imageProps.logo = this.imageUrl
        this.imageProps.imageUrl = this.imageUrl
        this.uuid = event.uuid
        this.imageProps.uuid = this.uuid
        this.imageProps.success = true
        const objectToCommit = this.formatCommit()
        this.$store.commit(objectToCommit.commit, objectToCommit.object)
        this.$emit('toEmailImage', true)
        // generic handle for notice when was all success
        this.$emit('doneWithSuccess')
        if (['endMessages', 'answeredMessage', 'startMessage'].indexOf(this.imageProps.where) > -1) {
          this.$root.$emit('details-updated')
        }
        this.$forceUpdate()
      }
    },
    formatCommit () {
      let objectToCommit = {}
      Object
        .keys(this.places)
        .forEach(function (key, index) {
          if (key === this.imageProps.where) {
            objectToCommit = this.places[key]
            objectToCommit.object = {}
            objectToCommit.object.value = this.imageUrl
            objectToCommit.object.logo = this.imageUrl
            objectToCommit.object.alias = 'logo'
            objectToCommit.object.imageUrl = this.imageUrl
            objectToCommit.object.uuid = this.uuid
            objectToCommit.object.where = key
          }
        }, this)
      return objectToCommit
    },
    async removeImage () {
      const obj = {
        cdnUrl: 'https://img.binds.co/0af207a2-df2a-4efb-895a-2b112909a4db/',
        uuid: '0af207a2-df2a-4efb-895a-2b112909a4db',
        crop: { left: 0, top: 0, width: 300, height: 300 }
      }

      this.onSuccess(obj)
    },
    onError (event) {
      if (event && this.imageProps) {
        this.imageProps.success = false
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('global.unknown_error'),
          showAlert: true
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .content-upload-setImage{
    position: relative;
    margin: 20px 0;
}
  .set-image {
    min-height: 130px;
    width: 80%;
    border: 2px dashed $color-gray;
    border-radius: 6px;
    background-color: $color-bg;
    position: relative;
    // height: 200px;
    text-align: center;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
    span {
      padding-top: 40px;
      padding-bottom: 15px;
      margin-bottom: 20px;
      font-size: 20px;
      display: block;
      font-size: 18px;
      color: $color-pink;
      font-weight: 600;
    }
    i {
      font-size: 70px;
    }
    button {
      position: absolute;
      bottom: 10px;
      right: 4%;
      i {
        font-size: 26px;
        margin-top: 7px;
        color: #fff !important;
      }
    }
    &:hover {
      border: 2px dashed $color-purple-dark;
    }
    & button.binds-button.medium.primary.floating {
      margin-top: -30px;
      float: right;
      margin-right: 65px;
    }
    & .binds-button.large.floating {
      width: 50px;
      height: 50px;
    }
  }

  .survey {
    width: 80%;
  }
  .img-container {
    display: block;
    max-width: 66%;
    margin: 0 auto;
  }
</style>
