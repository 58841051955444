<template>
  <div class="animated fadeIn pie-sentimental">
    <div>
      <div class="sentimentals-doughnut">
        <canvas id="sentimentals" width="500" height="300"></canvas>
        <div class="legend-container">
          <div class="legend-container--child">
            <app-iconify name="heart" color="#00aaa7" size="20"/>
            <app-heading level="h6" class="prPercentage">
              {{ prPercentage }} <span>{{ $t('sentimentals.positives') }}</span>
            </app-heading>
          </div>
          <div class="legend-container--child" style="margin-top: 27px;">
            <app-iconify name="heartHalf" color="#ffb500" size="20"/>
            <app-heading level="h6" class="ntPercentage">
              {{ ntPercentage }} <span>{{ $t('sentimentals.neutrals') }}</span>
            </app-heading>
          </div>
          <div class="legend-container--child" style="margin-top: 27px;">
            <app-iconify name="heartDislike" color="#ff607d" size="20"/>
            <app-heading level="h6" class="dtPercentage">
              {{ dtPercentage }} <span>{{ $t('sentimentals.negatives') }}</span>
            </app-heading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import('chart.js').then((chartjs) => {
  window.Chart = chartjs.default
})
export default {
  name: 'DoughnutSentimentals',
  props: ['data'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-iconify': () => import('@binds-tech/binds-design-system/src/components/Icon/Iconify.vue')
  },
  data () {
    return {
      graph: {},
      dataChart: {},
      sentimentData: undefined,
      dtPercentage: undefined,
      ntPercentage: undefined,
      prPercentage: undefined
    }
  },
  mounted () {
    this.sentimentData = this.data
    this.renderData()
  },
  methods: {
    async renderData () {
      if (this.sentimentData && this.sentimentData.total > 0) {
        this.dtPercentage = `${Math.round(this.sentimentData.dtPercentage)}%`
        this.ntPercentage = `${Math.round(this.sentimentData.ntPercentage)}%`
        this.prPercentage = `${Math.round(this.sentimentData.prPercentage)}%`
        const formattedData = this.sentimentData.values.map(item => { return item.doc_count })
        this.dataChart = {
          labels: [this.$t('sentimentals.negative'), this.$t('sentimentals.neutral'), this.$t('sentimentals.positive')],
          datasets: [{
            backgroundColor: [
              '#ff607d',
              '#ffb500',
              '#00aaa7'
            ],
            data: formattedData
          }]
        }
        const ctx = document.getElementById('sentimentals')
        this.graph = await new window.Chart(ctx, {
          type: 'doughnut',
          data: this.dataChart,
          options: {
            responsive: true,
            legend: { display: false }
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.pie-sentimental {
  width: 70%;
}
.sentimentals-doughnut {
  display: flex;
  & canvas {
    margin-top: 45px !important;
    margin-left: -40px;
    padding-bottom: 20px !important;
  }
}
.legend-container {
  display: block;
  margin-top: 57px;
  & .legend-container--child {
    display: flex !important;
    & span {
      font-weight: 100 !important;
      font-size: 16px !important;
    }
  }
}
.prPercentage {
  color: #00aaa7 !important;
  margin-left: 10px !important;
  margin-top: 1px !important;
  white-space: nowrap !important;
}
.dtPercentage {
  color: #ff607d !important;
  margin-left: 10px !important;
  margin-top: 1px !important;
  white-space: nowrap !important;
}
.ntPercentage {
  color: #ffb500 !important;
  margin-left: 10px !important;
  margin-top: 1px !important;
  white-space: nowrap !important;
}
 //tag display
.tag-sentimentals-display {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 30px;
  .card {
    width: 100% !important;
    height: auto;
    .tag-cloud-options {
      margin-top: 10px;
      ul {
        li {
          width: auto;
          margin: 10px;
          display: inline-block;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    .redirect_to_responses {
      margin-top: 40px;
      span {
        display: inline-block;
        vertical-align: middle;
      }
      i {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        color: #fff;
      }
    }
  }
}
.header-tag-sentimentals {
  display: flex;
  justify-content: space-between;
}
</style>
