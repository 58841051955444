<template>
  <div class="content-startend">
    <div class="content-question-title-start" :style="{'background-color': survey.colors.headerBg}" v-if="!survey.startMessage.title.hiddenTitle">
      <div class="start-title text-alignment" v-click-outside="hideOptions">
        <edit-title
          :title="survey.startMessage.title.value"
          @updateSurveyTitle="updateSurveyTitle"
          @openOptions="displayOptions = true"
          :optionsDisplay="displayOptions"
          :editStyle="true"
          :color="survey.colors.headerColor"
          :identify="`start-${survey._id}`"
          :minHeight="50"
          :maxHeight="40000"
          type="textarea"
          :metadata.sync="metadataGroup"
        />
      </div>
    </div>
    <div class="content-main-message" :style="{'background-color': survey.colors.splashBg}">
      <SetImg :title="$t('sendhub.set_image_text')" :imageProps="imageProps" v-if="!survey.startMessage.logo.isHidden" context="messages"/>
    </div>
    <div class="content-footer-answer" :style="{'background-color': survey.colors.footerBg}">
      <div class="actionsButtons">
        <app-button
          variation="secondary"
          app-button
          outline
          :style="{
            'background-color': survey.colors.buttonBg,
            'box-shadow':'none',
            'color':survey.colors.buttonColor
          }"
        >
          {{ survey.nextLabel }}
        </app-button>

      </div>
    </div>
  </div>
</template>

<script>
import SetImg from '../../image/SetImg.vue'
import EditTitle from '../../EditTitle.vue'
import { imgProps } from '@/_helpers'
import { userService } from '@/_services'
export default {
  name: 'StartMessages',
  components: {
    SetImg,
    EditTitle,
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button')
  },
  data () {
    return {
      editTitle: false,
      imageProps: {},
      displayOptions: false,
      metadataGroup: [
        { key: '_name', label: this.$i18n.t('sendhub.name'), type: String },
        { key: '_email', label: this.$i18n.t('sendhub.email'), type: String },
        { key: '_phone', label: this.$i18n.t('sendhub.phone'), type: String }
      ]
    }
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    }
  },
  created () {
    this.imageProps = imgProps.setImgMessage(this.survey.images, 'startMessage')
  },
  async mounted () {
    const user = await userService.getMe()
    if (user.account.fields.length > 0) {
      const data = user.account.fields
      data.map(item => this.metadataGroup.push(item))
    }

    this.$root.$on('update-merged-fields', (fields) => {
      fields.map(item => {
        if (this.survey.mergedFields.length > 0) {
          if (!this.survey.mergedFields.includes(item)) {
            this.survey.mergedFields.push(item)
          }
        } else {
          this.survey.mergedFields.push(item)
        }
      })
    })
  },
  methods: {
    hideOptions () {
      this.displayOptions = false
    },
    updateSurveyTitle (e) {
      const startMessage = this.$store.getters['survey/getStartMessage']
      startMessage.title.value = e
      this.$store.commit('survey/survey', { startMessage: startMessage })
      this.editTitle = false
    },
    updateStartMessageText (e) {
      const startMessage = this.$store.getters['survey/getStartMessage']
      startMessage.text = e
      this.$store.commit('survey/survey', { startMessage: startMessage })
      this.editTitle = false
    }
  }
}
</script>

<style lang="scss">
  .start-title {
    textarea {
      font-weight: 600;
    }
    .content-edit-title {
      width: 90%;
      margin: 0 auto;
    }
  }
  .content-startend {
    .content-footer-answer{
      .actionsButtons {
        text-align: right;
      }
    }
    & .content-question-title-start {
      margin: 0 auto;
      width: 100%;
      padding: 20px 0;
      border-radius: 10px 10px 0 0;
      .heading {
        text-align: center;
      }
    }
  }
  .content-main-message {
    padding: 40px;
  }
</style>
