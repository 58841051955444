<template>
  <div class="container-select-metadata">
    <div class="header-select-metadata">
      <h4 class="title-select-metadata">{{ $t('resend.title_metadata') }}</h4>
    </div>
    <div class="section-select-metadata">
      <div class="header-select-fields">
        <app-select-binds
            :key="key"
            :placeholder="$t('resend.select_field')"
            :options="fieldsAccount"
            :titleItem="'label'"
            id="metadata-add-to-resend"
            @input="addMetadata($event)"
          />
        <i class="material-icons-outlined icon-create-meta" v-title="$t('resend.create')" @click="startCreation = true">add_circle</i>
      </div>
      <div class="section-metadata-selecteds">
        <div class="metadata-selected" v-if="startCreation">
          <InputText
            :placeholder="$t('resend.field')"
            :value="newMeta.key"
            @update-model="newMeta.key = $event"
            :disabled="false"
            :requireValue="true"
            :id="`value-${newMeta.key}`"
            style="width: 19vw; height: 35px;"
          />
          <div class="combine-icon">
            <p>{{ $t('resend.label') }}</p>
            <i class="material-icons icon-combine">subdirectory_arrow_right</i>
          </div>
          <InputText
            :value="newMeta.label.trim()"
            @update-model="newMeta.label = $event"
            :disabled="false"
            :requireValue="true"
            :id="`value-${newMeta.label}`"
            style="width: 19vw; height: 35px;"
          />
          <button class="btn-remove-meta" v-if="newMeta.key.length && newMeta.label.length" @click="createNewMeta()">
            <i class="material-icons-outlined icon-remove-meta" v-title="$t('resend.create')">check</i>
          </button>
          <button class="btn-remove-meta" v-else @click="startCreation = false">
            <i class="material-icons-outlined icon-remove-meta" v-title="$t('resend.create')">close</i>
          </button>
        </div>
        <div class="metadata-selected" v-for="meta in metadatasResend" :key="meta.key">
          <InputText
            :placeholder="$t('resend.field')"
            :value="meta.label"
            :disabled="true"
            style="width: 19vw; height: 35px;"
          />
          <div class="combine-icon">
            <p>{{ $t('resend.value') }}</p>
            <i class="material-icons icon-combine">subdirectory_arrow_right</i>
          </div>
          <InputText
            @update-model="meta.value = $event"
            :disabled="false"
            :requireValue="true"
            :id="`value-${meta.key}`"
            style="width: 19vw; height: 35px;"
          />
          <button class="btn-remove-meta" @click="removeMeta(meta)">
            <i class="material-icons-outlined icon-remove-meta" v-title="$t('resend.remove')">delete</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userService, accountService } from '@/_services'
import InputText from '../inputs/InputText.vue'

export default {
  name: 'CardSelectMetadata',
  props: ['metadatasResend', 'currentStep'],
  components: {
    'app-select-binds': () => import('@binds-tech/binds-design-system/src/components/Select/SelectBinds'),
    InputText
  },
  data () {
    return {
      key: 0,
      newMeta: this.resetCurrentMeta(),
      startCreation: false,
      fieldsAccount: []
    }
  },
  mounted () {
    const cloneAccount = structuredClone(this.account)
    cloneAccount.fields.forEach(e => this.fieldsAccount.push(e))
  },
  watch: {
    currentStep: {
      immediate: true,
      handler (newVal) { if (newVal === 'done') { this.startCreation = false; this.resetCurrentMeta() } }
    }
  },
  computed: {
    account () { return this.$store.getters['account/getAccount'] }
  },
  methods: {
    async createNewMeta () {
      if (this.newMeta.key.length && this.newMeta.label.length) {
        const existField = this.fieldsAccount.find(e => { return e.key === this.newMeta.key || e.label === this.newMeta.label })

        if (existField) {
          return this.$store.commit('alerts/alert', {
            message: this.$t('resend.field_already_exists'),
            position: 'bottomRight',
            showAlert: true
          })
        }

        const user = await userService.getMe()
        const selectedAccount = user.account

        const objNewMeta = {
          key: this.newMeta.key,
          type: 'String',
          label: this.newMeta.label,
          groupable: false,
          enabledInsights: false
        }

        selectedAccount.fields.unshift(objNewMeta)

        const accountUpdated = await accountService.updateAccount(selectedAccount._id, selectedAccount)

        if (accountUpdated) {
          this.metadatasResend.unshift(objNewMeta)
          this.newMeta = this.resetCurrentMeta()

          this.$store.commit('alerts/alert', {
            message: this.$t('resend.created_success'),
            position: 'bottomRight',
            showAlert: true
          })

          this.startCreation = false
        }
      }
    },
    addMetadata (meta) {
      const findMetaAccount = this.fieldsAccount.findIndex(field => field.key === meta.key)

      this.fieldsAccount.splice(findMetaAccount, 1)

      this.metadatasResend.unshift(meta)

      this.key++
    },
    removeMeta (meta) {
      meta.value = ''
      const findMetaSelected = this.metadatasResend.findIndex(field => field.key === meta.key)

      this.metadatasResend.splice(findMetaSelected, 1)

      this.fieldsAccount.unshift(meta)

      this.key++
    },
    resetCurrentMeta () {
      return { key: '', value: '', label: '', type: this.$i18n.t('metadata.text_type'), new: false, groupable: false, enabledInsights: false }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-select-metadata {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 0.3rem;
  padding: 0.2rem;
  .header-select-metadata {
    height: 1.7rem;
    font-size: 0.7vw;
    margin-bottom: 5px;
    color: #454548;
    background: #f5f5f5;
    border-radius: 0.3rem;
    border: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section-select-metadata {
    display: flex;
    flex-direction: column;
    .header-select-fields {
      width: 100%;
      height: 3rem;
      padding: 0.5rem 0.3rem;
      display: flex;
      align-items: center;
      gap: 10px;
      .btn-create-meta {
        width: 4vw;
        height: 3.5vh;
        font-size: 0.75vw;
        font-weight: 600;
        padding: 7px 0;
        border-radius: 0.2rem;
      }
      .icon-create-meta {
        cursor: pointer;
        font-size: 1.5vw;
        color: var(--accent-color);
      }
    }
    .section-metadata-selecteds {
      width: 100%;
      min-height: 3.5rem;
      padding: 0.2rem;
      display: flex;
      flex-direction: column;
      gap: 7px;
      .metadata-selected {
        height: 45px;
        border-radius: 0.3rem;
        border: 1px solid #cacaca;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.060);
        padding: 0 0.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .combine-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #454548;
          p {
            font-size: 0.65vw;
            font-weight: bold;
          }
          .icon-combine {
            font-size: 0.9vw;
          }
        }
        .btn-remove-meta {
          width: 35px;
          height: 35px;
          border-radius: 0.2rem;
          border: 1px solid #cacaca;
          color: #cacaca;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon-remove-meta {
            font-size: 1.3vw;
          }
        }
        .btn-remove-meta:hover {
          color: #df6363;
          border-color: #df6363;
        }
      }
    }
  }
}
</style>
