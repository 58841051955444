<template>
  <div>
    <app-card class="cardSendhub emailCardSendHub">
      <app-card-header class="row headerSendhub">
        <app-form-input
          primary
          border-bottom
          :disabled="checkUserIsAllowedToSend"
          style="text-align:left;"
          v-model="emailHubSettings.emailSettings.from_name"
          v-on:change.native="updateEmailHubSettings()"
          :label="$t('sendhub.from_input')"
          class="col-lg-6 col-md-6 col-sm-6"
        ></app-form-input>
        <app-form-input
          primary
          border-bottom
          :disabled="checkUserIsAllowedToSend"
          style="text-align:left;"
          v-model="emailHubSettings.subject"
          v-on:change.native="updateEmailHubSettings()"
          :label="$t('sendhub.subject_input')"
          class="col-lg-6 col-md-6 col-sm-6"
        ></app-form-input>
        <div class="tools-email-config">
          <button v-on:click="openEmailEdit = !openEmailEdit"><i class="material-icons">code</i></button>
        </div>
      </app-card-header>
      <div class="content-email-html" v-if="showPreview">
        <div v-html="emailHtml" ref="myEditor" id="templateEmail" v-if="showEditor"></div>
        <div>
          <answer v-if="canShowEmbeded" v-bind:is="this.answer" @embedQuestion="embeded" :question="firstQuestion" class="hide"></answer>
        </div>
        <div v-if="code" v-html="code" ref="myEditor" id="templateCode" class="hide"></div>
      </div>
    </app-card>
    <app-modal
      class="modal-default-primary modal-editor-html"
      v-model="openEmailEdit"
      lightbox
      close
    >
      <div>
        <editor
          ref='code'
          v-if="openEmailEdit"
          v-model="code"
          @init="editorInit"
          lang="html"
          theme="chrome"
          width="100%"
          height="84vh"
          class="ace-editor"
        ></editor>
      </div>
    </app-modal>
  </div>
</template>

<script>
import app10num from '../email_components/10num.vue'
import app5nes from '../email_components/5nes.vue'
import appyn from '../email_components/yn.vue'
import appynm from '../email_components/ynm.vue'
import appynd from '../email_components/ynd.vue'
import app5emo from '../email_components/5emo.vue'
import app5num from '../email_components/5num.vue'
import appsingle from '../email_components/single.vue'
import appsingleOther from '../email_components/singleOther.vue'
import app1to7 from '../email_components/1to7.vue'
import app1to7button from '../email_components/1to7button.vue'
import app5radio from '../email_components/nvs.vue'
import { mapGetters } from 'vuex'
import { surveyService } from '@/_services'
import { gradientMaker, embedGenerator } from '@/_helpers'

export default {
  name: 'EmailHub',
  data () {
    return {
      editEmailText: false,
      openEmailEdit: false,
      code: '',
      emailHtml: '',
      emdebedCode: '',
      showEditor: false,
      showPreview: false
    }
  },
  components: {
    app10num,
    app5nes,
    appynm,
    appynd,
    appyn,
    app5emo,
    app5num,
    appsingle,
    appsingleOther,
    app1to7,
    app1to7button,
    app5radio,
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-card-header': () => import('@binds-tech/binds-design-system/src/components/Card/Header'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    editor: require('vue2-ace-editor')
  },
  computed: {
    checkUserIsAllowedToSend () {
      if (this.user.type === 'user' && this.user.features.seedBatches) return true // in this case the user is not allowed to modify the field
      else return false
    },
    canShowEmbeded () {
      let result
      ['5emo', '5num', '10num', 'yn', 'ynm', 'ynd', '1to7button', '1to7', '5radio', 'single', 'singleOther'].indexOf(this.firstQuestion.ui) > -1 ? result = true : result = false
      if (this.firstQuestion.type === 'matrix') {
        result = false
      }
      return result
    },
    emailHubSettings () {
      const settings = this.$store.getters['sendhub/getEmailSettings']
      return settings
    },
    color () {
      const message = this.$store.getters['sendhub/getEmailSettings']
      return message.colors
    },
    embeddedQuestion () {
      const settings = this.$store.getters['sendhub/getEmailSettings']
      return settings.hasEmbeddedQuestion
    },
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    },
    firstQuestion () {
      const questions = this.$store.getters['survey/getQuestions']
      const firstQuestion = questions[0]
      return firstQuestion
    },
    answer () {
      return 'app' + this.firstQuestion.ui
    },
    footerHtml () {
      const footer = this.$store.getters['sendhub/getFooterEmail']
      return footer
    },
    ...mapGetters({
      user: 'account/getUser'
    })
  },
  beforeDestroy () {
    this.$root.$off('colorTemplateEmail')
    this.$root.$off('saveEmail')
    this.$root.$off('EmbededQuestion')
    this.$store.commit('sendhub/cropWidth', undefined)
  },
  created () {
    try {
      const object = this.emailHubSettings
      const fromNameFromReq = this.$store.getters['sendhub/getEmailSettings'].emailSettings.from_name
      const subjectFromReq = this.$store.getters['sendhub/getEmailSettings'].subject
      object.subject = subjectFromReq || object.subject
      object.emailSettings.from_name = fromNameFromReq || this.user.account.name
      this.$store.commit('sendhub/sendhub', { emailSettings: object })
    } catch (error) {
      console.log('erro ao tentar resgatar os headers de remetente/assunto', error)
    }
  },
  mounted () {
    if (this.emailHubSettings.html) {
      this.$root.$emit('loadingSelectedHub', true)
      const htmlEmbeded = this.emailHubSettings.html
      this.code = htmlEmbeded.replace(/>/g, '>')
      const self = this
      setTimeout(function () {
        self.preview(self.color)
        self.$root.$emit('loadingSelectedHub', false)
      }, 1000)

      // Adicionar Botao Avaliar se nao tiver questao embedada
      if (!self.emailHubSettings.hasEmbeddedQuestion) {
        if (self.emailHubSettings.__v === 0) {
          const htmlItem = new DOMParser().parseFromString(this.code, 'text/html')
          const defaultBody = embedGenerator.returnDefaultEmailTemplate()
          if (htmlItem) {
            const item = htmlItem.getElementsByClassName('email-cta')
            if (item.length > 0) {
              htmlItem.getElementsByClassName('email-cta')[0].outerHTML = defaultBody
              const c = htmlItem.childNodes
              let txt = ''
              for (let i = 0; i < c.length; i++) {
                if (c[i].outerHTML) {
                  txt += c[i].outerHTML
                }
              }
              const settingsToCommit = { ...self.emailHubSettings }
              settingsToCommit.html = txt
              self.code = settingsToCommit.html.replace(/>/g, '>')
              self.$store.commit('sendhub/sendhub', {
                emailHubSettings: settingsToCommit
              })
            }
          }
        }
      }

      this.showEditor = true
      // Change Color
      this.$root.$on('colorTemplateEmail', (value) => {
        this.preview(value)
      })
      // Save Email Configs
      this.$root.$on('saveEmail', () => {
        this.sendEmailSettings()
      })
      // Embeded Question
      this.$root.$on('EmbededQuestion', (value) => {
        this.EmbededQuestion(value)
      })
    }
  },
  watch: {
    openEmailEdit (val) {
      if (!val) {
        this.emailHtml = this.code
        this.preview(this.color)
      }
    }
  },
  methods: {
    embeded (e) {
      this.emdebedCode = e
      if (this.emailHubSettings.hasEmbeddedQuestion) {
        this.EmbededQuestion(true)
      }
    },
    saveEmailText (e) {
      this.emailHubSettings.html = e
      this.$store.commit('sendhub/sendhub', { emailSettings: this.emailHubSettings })
    },
    updateEmailHubSettings () {
      this.$store.commit('sendhub/sendhub', { emailSettings: this.emailHubSettings })
    },
    updateEmailTitle (e) {
      this.emailHubSettings.templateParameters.titleEmail = e
      this.$store.commit('sendhub/sendhub', { emailSettings: this.emailHubSettings })
    },
    editorInit () {
      // need refactory
      require('brace/ext/language_tools')
      require('brace/mode/html')
      require('brace/mode/javascript')
      require('brace/mode/less')
      require('brace/theme/chrome')
      require('brace/snippets/javascript')
    },
    transformToGradientColors (isToReplaceAll, obj, imageEmailSettings) {
      const newColors = this.firstQuestion.gradient ? gradientMaker.returnGradientColorsForThisQuestion(this.firstQuestion) : [...Array(11).keys()]
      let emailReplaced = `${this.code}`
      if (isToReplaceAll) {
        emailReplaced = emailReplaced.replace(/{{color.c1}}/g, a => obj.c1)
          .replace(/{{color.c2}}/g, a => obj.c2)
          .replace(/{{color.c3}}/g, a => obj.c3)
          .replace(/{{color.c4}}/g, a => obj.c4)
          .replace(/{{image.logo}}/g, a => imageEmailSettings)
      }
      if (this.firstQuestion.gradient &&
       ['nps', 'csat', 'nes', 'enum', 'ces'].indexOf(this.firstQuestion.type) > -1 &&
       ['0to10', '1to5', '0to5', '10num', '5num', '1to7button', '1to7', ''].indexOf(this.firstQuestion.ui) > -1) {
        newColors.forEach((item, idx) => {
          const defaultColor = this.firstQuestion.gradient ? item : obj.c3
          emailReplaced = emailReplaced
            .replace(new RegExp(`{{color${idx}}}`, 'g'), defaultColor)
        })
      }
      return emailReplaced
    },
    preview (obj) {
      this.showPreview = false
      const cropWidth = this.$store.getters['sendhub/getCropWidth']
      const imgUrl = this.emailHubSettings.images[0].value
      const formattedImgUrl = imgUrl
      const imageEmailSettings = cropWidth > 500 || !cropWidth ? formattedImgUrl : imgUrl

      // Aplicando as cores vindas do parametro no template string e a imagem da conta
      this.emailHtml = this.transformToGradientColors(true, obj, imageEmailSettings)
      var self = this
      // Div Content Editable
      setTimeout(function () {
        var htmlObject = document.createElement('div')
        htmlObject.innerHTML = self.code
        const origin = htmlObject.querySelectorAll('[contenteditable]')
        const editable = document.querySelectorAll('[contenteditable]')
        for (let i = 0; i <= editable.length; i++) {
          if (editable[i]) {
            editable[i].addEventListener('focusout', function () {
              origin[i].innerHTML = editable[i].innerHTML
              self.code = htmlObject.innerHTML
            })
          }
        }
      }, 2000)
      this.showPreview = true
    },
    async sendEmailSettings () {
      if (this.emailHubSettings.emailSettings.from_name === '' || this.emailHubSettings.subject === '') {
        this.$store.commit('alerts/alert', { message: this.$i18n.t('sendhub.emailHub.input_error'), position: 'bottomRight', showAlert: true })
        return false
      } else {
        this.$store.commit('alerts/alert', { message: this.$i18n.t('sendhub.emailHub.input_success'), position: 'bottomRight', showAlert: true })
      }
      const smsMessage = this.$store.getters['survey/getSurvey'].sms // para atualizar o obj com o a mensagem sms mais recente
      const messageSettings = { ...this.emailHubSettings, sms: smsMessage }
      const survey = this.survey._id
      const color = []
      const getColors = this.color
      Object.keys(getColors).forEach(function (item) {
        color.push({ alias: item, value: getColors[item] })
      })
      messageSettings.account = this.$store.getters['account/getUser'].account._id
      messageSettings.survey = survey
      messageSettings.colors = color
      messageSettings.html = this.transformToGradientColors(false)

      const send = await surveyService.saveEmailConfig(survey, messageSettings)
      if (send) {
        send.from_name = this.emailHubSettings.emailSettings.from_name
        const color = send.colors.reduce((acc, cur) => ({ ...acc, [cur.alias]: cur.value }), {})
        send.colors = color
        this.$store.commit('sendhub/sendhub', { emailSettings: send })
      }
      this.preview(this.color)
    },
    EmbededQuestion (value) {
      const htmlItem = document.getElementById('templateCode')
      if (value) {
        if (htmlItem) {
          htmlItem.getElementsByClassName('email-cta')[0].outerHTML = this.emdebedCode
        }
      } else {
        const defaultBody = embedGenerator.returnDefaultEmailTemplate()
        if (htmlItem) {
          htmlItem.getElementsByClassName('email-cta')[0].outerHTML = defaultBody
        }
      }
      if (htmlItem) {
        const c = document.getElementById('templateCode').childNodes
        let txt = ''
        for (let i = 0; i < c.length; i++) {
          if (c[i].outerHTML) {
            txt += c[i].outerHTML
          }
        }
        this.code = txt
      }
      this.preview(this.color)
    }
  }
}
</script>

<style lang="scss">
  .loading-embed {
    display: grid;
    align-items: center;
    justify-content: center;
  }
  .emailCardSendHub {
    min-height: 350px;
  }
  .cardSendhub {
    width: 688px !important;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 80px;
    & .card-header {
      padding: 12px 30px 20px 30px !important;
    }
    & .content-10num {
      padding-top: 30px !important;
    }
    button.delete-image-upload{
      display: none;
    }
    & .set-image{
      height: auto;
    }
  }
  .headerSendhub {
    width: 688px !important;
    height: auto;
    background: var(--secondary-color) !important;
    & .text-field__input {
      background: transparent !important;
      border-bottom: 1px solid #fff !important;
      input {
        background-color: transparent !important;
        bottom: 20px !important;
        font-size: 14px !important;
        color: #ffff !important;
      }
    }
    & .text-field__label {
      color: #ffff !important;
      font-size: 14px !important;
    }
    & .b-icon {
    color: #ffff !important;
    margin-right: 12px;
    }
  }
  .content-email-html {
    margin: 0 auto;
    margin-top: 30px;
    width: 600px;
  }
  .action {
    margin-top: 7% !important;
    width: auto !important;
  }
  .email-text {
    text-align: center;
    width: 90%;
    & .input-wrapper {
      .input {
        height: 141px;
        padding-top: 15px !important;
      }
    }
    & textarea {
      text-align: center;
      font-weight: 600 !important;
      padding: 2px !important;
      font-size: 20px !important;
      color: none  !important;
      background-color: none !important;
      border: 1px solid #fff !important;
      &:hover {
          border: 1.3px dashed $color-purple-darker !important;
      }
    }
  }
  .email-text {
    .start-text {
      margin-top: 30px;
    }
  }
  .tools-email-config {
    text-align: right;
    width: 100%;
    i{
      color:#fff;
    }
  }
  .hide {
    display: none;
  }
  .ace-editor {
    position: absolute;
    z-index: 9;
    left: 0;
  }
  .ace-editor * {
    font: 12px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
  }
  .email-cta a {
    pointer-events:none;
    color:#fff;
  }
  .modal-editor-html{
  .modal__wrapper {
    width: 70% !important;
    height: calc(93vh);
    overflow: hidden;
    padding: 48px !important;
  }
}
</style>
