<template>
  <div>
    <div>
      <div v-if="!item.isDefault" class="template-title" @mouseover="showEditButton = true" @mouseleave="showEditButton = false">
        <app-heading level=h8>{{ item.title }}</app-heading>
        <i class="material-icons" v-if="showEditButton && user.type === 'superadmin'" :v-title="$t('tickets.category.to_edit')" @click="showEditModal = true">edit</i>
      </div>
      <app-card v-if="!item.isDefault" class="templateHover preview-texts" v-on:click.native="showTemplateModal = true">
        <!-- Não vai ter essa opção por enquanto, então deixa comentado -->
        <!-- <div v-if="item.isDefault">
          <app-heading level=h7>{{ item.description }}</app-heading>
          <i class="material-icons addTemplateIcon">add</i>
        </div> -->
        <div>
          <app-heading level=h6>{{ item.survey.questions[0].title }}</app-heading>
          <app-heading level=h8>{{ item.survey.questions[0].question }}</app-heading>
          <component
            class="transform-ui"
            v-bind:is="renderUi(item)"
            :question="item.survey.questions[0]"
            :colors="componentsColors"
            :preview="true"
            :class="{
              't-5emo-temp': item.survey.questions[0].ui.toLowerCase() === '5emo',
              't-stars-temp': item.survey.questions[0].ui.toLowerCase() === '5num' && (item.survey.questions[0].stars && item.survey.questions[0].stars.active),
              't-5num-temp': item.survey.questions[0].ui.toLowerCase() === '5num' && !item.survey.questions[0].stars,
              't-5nes-temp': (item.survey.questions[0].ui.toLowerCase() === '5nes' || item.survey.questions[0].ui.toLowerCase() === '10num') && item.survey.questions[0].type === 'nes',
              't-yn-temp': item.survey.questions[0].ui.toLowerCase() === 'yn',
              't-ynm-temp': item.survey.questions[0].ui === 'ynm',
              't-ynd-temp': item.survey.questions[0].ui.toLowerCase() === 'ynd',
              't-text-temp': item.survey.questions[0].ui.toLowerCase() === 'text',
              't-nvs-temp': item.survey.questions[0].ui.toLowerCase() === '5radio',
              't-inputext-temp': item.survey.questions[0].ui.toLowerCase() === 'inputext',
              't-referral-temp': item.survey.questions[0].ui.toLowerCase() === 'referral',
              't-referraldynamic-temp': item.survey.questions[0].ui.toLowerCase() === 'referraldynamic',
              't-single-temp': item.survey.questions[0].ui.toLowerCase() === 'single' || item.survey.questions[0].ui === 'singleOther',
              't-multiple-temp': item.survey.questions[0].ui.toLowerCase() === 'multiple' || item.survey.questions[0].ui === 'multipleOther',
              't-0to5-temp': item.survey.questions[0].options.length === 6 && item.survey.questions[0].ui === '10num',
              't-1to5-temp': item.survey.questions[0].options.length === 5 && item.survey.questions[0].ui === '10num' && item.survey.questions[0].type !== 'nes',
              't-0to10-temp': ((item.survey.questions[0].options.length === 11 && item.survey.questions[0].ui === '10num') || (item.survey.questions[0].options.length === 14 && item.survey.questions[0].ui === '10num')) && item.survey.questions[0].type !== 'matrix',
              't-nps-temp' : item.survey.questions[0].type === 'nps' && item.survey.questions[0].ui === '10num',
              't-1to10-temp': item.survey.questions[0].options.length === 10 && item.survey.questions[0].ui === '10num',
              't-1to7-temp': item.survey.questions[0].type === 'ces' && item.survey.questions[0].ui === '1to7',
              't-1to7button-temp': item.survey.questions[0].type === 'ces' && item.survey.questions[0].ui === '1to7button',
              't-matrix1to5-temp': item.survey.questions[0].ui.toLowerCase() === 'matrix1to5',
              't-matrix10num-temp': item.survey.questions[0].ui.toLowerCase() === '10num' && item.survey.questions[0].type === 'matrix'
            }"
          >
          </component>
        </div>
      </app-card>
      <app-modal
        class="modal-default-primary confirm-modal-templates"
        v-model="showTemplateModal"
        :title="$t('templates.survey_title_template')"
        lightbox
        close
        :primary-button="$t('templates.use_template')"
        @primaryButtonClick="useTemplate()"
        v-on:keyup.esc="showTemplateModal = false"
      >
        <app-form-input
          v-model="surveyTitle"
          :placeholder="$t('start.survey_title_placeholder')"
          secondary
          autofocus
          v-on:keyup.enter="useTemplate()"
          v-on:keyup.esc="showTemplateModal = false"
        >
        </app-form-input>
        <div class="error-title" v-if="showErrorTitle">
          <app-heading level="h7" style="color: red; margin-top: 20px;">{{ $t('start.template_title_error') }}</app-heading>
        </div>
        <div class="loading-templates-list" v-if="loading">
          <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
        </div>
      </app-modal>
      <app-modal
        class="modal-default-primary edit-modal-templates"
        v-model="showEditModal"
        :title="$t('templates.edit_template_details')"
        lightbox
        close
        :primary-button="$t('profile.save')"
        @primaryButtonClick="updateTemplate()"
        v-on:keyup.esc="showEditModal = false"
      >
        <div class="row mt15">
          <app-heading class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-lg-12 col-xs-12 title-input" level="h6">{{ $t('templates.template_title') }}:</app-heading>
          <app-form-input
            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-lg-12 col-xs-12"
            v-model="item.title"
            :placeholder="$t('start.survey_title_placeholder')"
            secondary
            autofocus
            v-on:keyup.esc="showEditModal = false"
          >
          </app-form-input>
        </div>
        <div class="row mt15">
          <app-heading class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-lg-12 col-xs-12" level="h6">{{ $t('templates.template_description') }}:</app-heading>
          <app-form-textarea-limited
            :inputMaxHeight="110"
            :inputMinHeight="90"
            :identify="template-card"
            class="description-area col-xl-12 col-lg-12 col-md-12 col-sm-12 col-lg-12 col-xs-12"
            colorText="#080808"
            v-model="item.description"
            name="template-description"
            v-on:keyup.esc="showEditModal = false"
          ></app-form-textarea-limited>
        </div>
        <div class="row mt15">
          <div class="col-xs template-headings">
            <app-toggle-input v-model="item.isPopular"></app-toggle-input>
            <app-heading level="h6">{{ $t('templates.popular') }}</app-heading>
          </div>
        </div>
        <div class="row">
          <div class="col-xs template-headings">
            <app-toggle-input v-model="item.isDraft"></app-toggle-input>
            <app-heading level="h6">{{ $t('templates.draft') }}</app-heading>
          </div>
        </div>
        <div class="loading-templates-list" v-if="loading">
          <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
        </div>
      </app-modal>
    </div>
  </div>
</template>

<script>
import { templatesService } from '@/_services'
import { iconFormatter } from '@/_helpers'
import { mapGetters } from 'vuex'
import AppCard from '@binds-tech/binds-design-system/src/components/Card/Card'
import app10num from '../survey/answersType/10num.vue'
import app5num from '../survey/answersType/5num.vue'
import app5nes from '../survey/answersType/5nes.vue'
import appyn from '../survey/answersType/kpi-yn.vue'
import appynm from '../survey/answersType/kpi-ynm.vue'
import appynd from '../survey/answersType/kpi-ynd.vue'
import app5emo from '../survey/answersType/5emo.vue'
import apptext from '../survey/answersType/text.vue'
import appinputext from '../survey/answersType/input-text.vue'
import appreferral from '../survey/answersType/referral.vue'
import appreferraldynamic from '../survey/answersType/referraldynamic.vue'
import appsingle from '../survey/answersType/single.vue'
import appmultiple from '../survey/answersType/multiple.vue'
import app0to5 from '../survey/answersType/0to5.vue'
import app1to5 from '../survey/answersType/1to5.vue'
import app1to7 from '../survey/answersType/1to7.vue'
import app1to7button from '../survey/answersType/1to7button.vue'
import app0to10 from '../survey/answersType/0to10.vue'
import app1to10 from '../survey/answersType/1to10.vue'
import appmatrix1to5 from '../survey/answersType/matrix1to5.vue'
import appmatrix10num from '../survey/answersType/matrix10num.vue'
import app5radio from '../survey/answersType/nvs.vue'
import('lodash-es').then((lodashEs) => {
  window._ = lodashEs.default
})
export default {
  name: 'TemplateCard',
  props: ['item'],
  data () {
    return {
      template: '',
      card: '',
      showErrorTitle: false,
      surveyTitle: '',
      showTemplateModal: false,
      loading: false,
      templates: [],
      showEditButton: false,
      showEditModal: false
    }
  },
  components: {
    app10num,
    app5num,
    app5nes,
    appyn,
    appynm,
    appynd,
    app5emo,
    apptext,
    appinputext,
    appreferral,
    appreferraldynamic,
    appsingle,
    appmultiple,
    app0to5,
    app1to5,
    app1to7,
    app1to7button,
    app0to10,
    app1to10,
    appmatrix1to5,
    appmatrix10num,
    app5radio,
    AppCard,
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput'),
    'app-form-textarea-limited': () => import('@binds-tech/binds-design-system/src/components/Form/TextareaLimited')
  },
  computed: {
    componentsColors () {
      const themeColors = this.$store.getters['survey/getColors']
      const colors = {
        actionColor: themeColors.actionColor,
        actionText: themeColors.actionText,
        splashColor: themeColors.splashColor
      }
      return colors
    },
    ...mapGetters({
      user: 'account/getUser'
    })
  },
  methods: {
    async useTemplate () {
      if (this.surveyTitle.trim().length > 0) {
        this.showErrorTitle = false
        this.loading = true
        const shared = iconFormatter.getSharedIcons()
        const createFromTemplate = await templatesService.createFromTemplate(this.item._id, { title: this.surveyTitle.trim() })

        if (!createFromTemplate._id) {
          this.loading = false
          this.$store.commit('alerts/alert', {
            message: this.$i18n.t('templates.error_using_template'),
            position: 'bottomRight',
            showAlert: true
          })
          return false
        }
        // atualiza os icones da survey que está entrando no state
        createFromTemplate.questions.forEach(async element => {
          const questionModel = shared[await window._.findIndex(shared, { type: element.type })]
          element.icon = questionModel.icon
        })

        // atualiza as cores do tema da survey que vai para o state
        const getTheme = createFromTemplate.colors.reduce((acc, cur) => ({ ...acc, [cur.alias]: cur.value }), {})
        createFromTemplate.colors = getTheme

        // seleciona a primeira questão por padrão
        createFromTemplate.questionSelected = 0
        this.$store.commit('survey/survey', createFromTemplate)
        this.$router.push({ name: 'Surveys', params: { id: createFromTemplate._id } })
        this.loading = false
      } else {
        this.showErrorTitle = true
      }
    },
    async updateTemplate () {
      this.loading = true
      const save = await templatesService.updateTemplate(this.item)
      if (save) {
        this.showEditModal = false
        this.$store.commit('alerts/alert', {
          message: 'Saved!',
          position: 'bottomRight',
          showAlert: true
        })
      } else {
        this.$store.commit('alerts/alert', {
          message: 'Something went wrong',
          position: 'bottomRight',
          showAlert: true
        })
      }
      this.loading = false
    },
    renderUi (template) {
      const ui = template.survey.questions[0].ui
      const type = template.survey.questions[0].type
      const options = template.survey.questions[0].options

      let appUi = 'app' + ui.toLowerCase()

      if (ui.toLowerCase() === '10num' && ['nps', 'csat'].indexOf(type) < 0) {
        if (type === 'nes') {
          appUi = 'app5nes'
        }
        if (options.length === 6) {
          appUi = 'app0to5'
        }
        if (options.length === 5) {
          appUi = 'app1to5'
        }
        if (options.length === 11) {
          appUi = 'app0to10'
        }
        if (options.length === 10) {
          appUi = 'app1to10'
        }
      }

      if (type === 'matrix' && ui === '10num') {
        appUi = 'appmatrix10num'
      }
      if (ui === 'multipleOther') {
        appUi = 'appmultiple'
      }
      if (ui === 'singleOther') {
        appUi = 'appsingle'
      }
      return appUi
    }
  }
}
</script>
<style lang="scss">
.confirm-modal-templates {
  & .modal__overlay {
    background-color: #74737380 !important;
  }
  & .modal__wrapper {
    box-shadow: none !important;
  }
  & .modal__wrapper__content {
    margin: 20px 0px 20px 0px;
  }
  & h4 {
    font-size: 20px !important;
  }
  & .text-field__input {
    margin-top: 15px;
    margin-left: -15px;
  }
  & .binds-button.secondary {
    font-size: 14px;
    float: right;
  }
}
.edit-modal-templates {
  & .modal__overlay {
    background-color: #74737380 !important;
  }
  & .modal__wrapper {
    box-shadow: none !important;
  }
  & .modal__wrapper__content {
    margin: 20px 0px 20px 0px;
  }
  & h4 {
    font-size: 20px !important;
  }
  & h6 {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  & .text-field__input {
    margin-top: -15px;
    margin-left: -30px;
    & input {
      color: #2c2c2c !important;
    }
  }
  & .binds-button.secondary {
    font-size: 14px;
    float: right;
  }
}
.description-area {
  margin-left: -15px;
  &:after {
    display: none !important;
  }
  & textarea {
    width: 90%;
    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: #201f1f94;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #585858 !important;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ddd;
    }
  }
}
.loading-templates-list {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  .data-loading-spinner {
    margin-top: 24% !important;
    margin-left: 48% !important;
    & .spinner-inner {
      background-color: #fff !important;
    }
  }
}
.templateHover:hover {
  cursor: pointer;
}
.preview-texts {
  margin-bottom: 15px;
  text-align: center;
}
.addTemplateIcon {
  font-size: 95px !important;
  margin-top: 55px;
  color: #a8a8a8;
  margin-left: 10px;
}
.template-title {
  display: flex;
  & p {
    font-weight: 600 !important;
    margin: 10px 0px 10px 12px !important;
    max-width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  & i {
    cursor: pointer;
    margin: 8px 0px 0px 10px;
    font-size: 14px !important;
    color: #d650cb;
  }
}
.template-headings {
  padding-left: 10px !important;
  h6 {
    font-size: 14px !important;
    margin-left: 50px !important;
    margin-top: 2px !important;
  }
  .text-field__input {
    & input {
      font-size: 13.5px !important;
    }
  }
}
// Classes para preview de componentes para templates - START
.transform-ui {
  &.content-5num {
    // hack para consertar o 5num
    min-width: 310px;
    transform: scale(0.75);
    -webkit-transform: scale(0.75);
    margin-left: -35px !important;
    padding-top: 56px;
  }
}
.transform-ui-temp {
  width: 100% !important;
  pointer-events:none;
}
.t-5emo-temp {
  transform: scale(0.5);
  -webkit-transform: scale(0.5);
  margin-top: -62px !important;
  margin-left: -10px;
}
.t-10num-temp {
  width: 400% !important;
  transform: scale(0.3);
  -webkit-transform: scale(0.3);
  margin-left: -217px !important;
  margin-top: -55% !important;
  margin-bottom: -30% !important;
}
.t-5num-temp {
  min-width: 310px;
  transform: scale(0.75);
  -webkit-transform: scale(0.75);
  margin-left: -35px !important;
  padding-top: 56px;
}
.t-stars-temp {
  min-width: 310px;
  transform: scale(0.55);
  -webkit-transform: scale(0.55);
  margin-left: -67px !important;
  padding-top: 20px;
}
.t-5nes-temp {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin: 20px 0 0 -113px;
  min-width: 583px ;
}
.t-0to5-temp {
  transform: scale(0.55);
  -webkit-transform: scale(0.55);
  margin: 20px 0 0 -135px;
  min-width: 583px ;
}
.t-1to5-temp {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin: 25px 0 0 -113px;
  min-width: 583px ;
}
.t-1to7-temp {
  transform: scale(0.75);
  -webkit-transform: scale(0.75);
  margin: 55px 0 0 -170px;
  min-width: 583px;
}
.t-1to7button-temp {
  transform: scale(0.5) !important;
  -webkit-transform: scale(0.5) !important;
  margin-left: -5% !important;
  margin-top: -100px;
  width: 110% !important;
}
.t-matrix1to5-temp {
  transform: scale(0.4);
  -webkit-transform: scale(0.4);
  margin-left: -225px;
  min-width: 602px;
  margin-top: -110px;
}
.t-matrix10num-temp {
  margin-left: -247px;
  transform: scale(0.45);
  -webkit-transform: scale(0.45);
  min-width: 672px;
  margin-top: -110px;
}
.t-nps-temp,
.t-0to10-temp {
  width: 661px !important;
  transform: scale(0.5);
  -webkit-transform: scale(0.5);
  margin-left: -208px;
  min-width: 583px !important;
  padding-top: 70px;
}
.t-1to10-temp {
  min-width: 610px;
  transform: scale(0.35);
  -webkit-transform: scale(0.35);
  margin-left: -210px;
  margin-top: 10%;
}
.t-yn-temp {
  min-width: 310px;
  transform: scale(0.45);
  -webkit-transform: scale(0.45);
  margin-left: -65px;
  margin-top: 0px;
}
.t-ynd-temp, .t-ynm-temp {
  min-width: 310px;
  transform: scale(0.5) !important;
  -webkit-transform: scale(0.5) !important;
  margin-left: -50% !important;
  margin-top: -24% !important;
}
.t-text-temp > .input-text {
  transform: scale(0.7) !important;
  -webkit-transform: scale(0.7) !important;
  min-width: 225px !important;
  margin-left: -33px !important;
  margin-top: 42px !important;
}
.t-text-temp > .comment {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin-top: -57%;
  min-width: 310px;
  margin-left: -70px;
}
.t-inputext-temp {
  transform: scale(0.4);
  -webkit-transform: scale(0.4);
  margin-left: -141px !important;
  margin-top: -3% !important;
  margin-bottom: -32% !important;
}
.t-referral-temp {
  transform: scale(0.65);
  -webkit-transform: scale(0.65);
  margin-top: -34%;
  margin-bottom: -30%;
  min-width: 375px;
  margin-left: -110px;
}
.t-referraldynamic-temp {
  transform: scale(0.85);
  -webkit-transform: scale(0.85);
  min-width: 300px;
  margin-left: -65px;
}
.t-single-temp {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin: -12% 0 -20% -35%;
  width: 230% !important;
  height: 190px !important;
  h6 {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.t-single-others-temp {
  transform: scale(0.55);
  -webkit-transform: scale(0.55);
  margin-top: -43% !important;
  margin-bottom: -30% !important;
  width: 200% !important;
  margin-left: -50%;
  height: 180px;
  .nav-input-enum {
    padding-left: 0 !important;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    li {
      display: flex;
      align-items: center;
      justify-content: left;
      width: fit-content;
      .op {
        margin: 0 !important;
        display: flex;
        justify-content: flex-start;
        .subject-options {
          min-height: 30px;
          max-height: 70px;
          overflow-y: auto;
          max-width: 60%;
        }
      }
    }
    .other {
      padding-left: 0px !important;
    }
  }
}
.t-multiple-temp {
  width: 210% !important;
  height: 220px !important;
  transform: scale(0.63);
  -webkit-transform: scale(0.63);
  margin: -28% 0 15% -20% !important;
  h6 {
    max-width: 200px;
    text-overflow: ellipsis;
    padding-top: 23px !important;
    white-space: nowrap;
    overflow: hidden;
  }
}
.t-nvs-temp {
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
}
</style>
