<template>
  <div class="container-page-insight">
    <div class="content-menu">
      <Menu />
    </div>
    <div class="no-data-to-show" v-if="noData">
      <NoData :message="$t('insights.no_insights')" />
    </div>
    <div class="content-insight" v-if="!noData && surveys.length">
      <MenuInsight
        :surveys="surveys"
        @data-analysis="middlewareDataAnalysis"
        @data-reports="getReportData"
        @data-dashboard="getDashboardData"
        @open-filter="openFilter = true"
        @text-high-insight="search = $event"
        :showBtnExport="!loading && !noDataReportsOrInsights"
      />
      <main class="main-insight">
        <article v-if="tabSelected === 'reports'">
          <ReportsInsights :dataBase="dataReports" :key="key" :loading="loading"/>
        </article>
        <article v-if="tabSelected === 'dashboard'">
          <DashboardInsight :data="dataDashboard" :key="key" :loading="loading"/>
        </article>
        <article v-if="tabSelected === 'text-analysis'">
          <AnalysisInsight :dataBase="dataAnalysis" :highText="search" :loading="loading"/>
          <div class="pagination-list-insight" v-show="dataAnalysis.length && !loading">
            <app-paginate :initValue="page" :value="page" :prevText="'<'" :nextText="'>'" @input="changePage" :pageRange="sendingsPerPage" :pageCount="dynamicPageCount"></app-paginate>
          </div>
        </article>
      </main>
    </div>
    <FilterModal v-if="openFilter" @close="openFilter = false" entity="insights" />
  </div>
</template>

<script>
import { insightsService } from '@/_services'
import { orderFormater } from '../_helpers/survey.order'
import Menu from '@/components/Menu.vue'
import NoData from '../components/insights/components/NoData.vue'
import MenuInsight from '@/components/insights/components/MenuInsight.vue'
import ReportsInsights from '@/components/insights/components/ReportsInsights.vue'
import DashboardInsight from '@/components/insights/components/DashboardInsight.vue'
import FilterModal from '@/components/filters/FilterModal.vue'
import AnalysisInsight from '../components/insights/components/AnalysisInsight.vue'

export default {
  name: 'Insights',
  props: [],
  components: {
    Menu,
    NoData,
    MenuInsight,
    AnalysisInsight,
    FilterModal,
    ReportsInsights,
    DashboardInsight,
    'app-paginate': () => import('@binds-tech/binds-design-system/src/components/Paginate/Paginate')
  },
  created () {
    this.$store.commit('filters/updateEntity', 'insights')
  },
  data () {
    return {
      noData: false,
      loading: false,
      key: 0,
      surveyId: '',
      openFilter: false,
      tabSelected: 'reports',
      dataAnalysis: [],
      dataDashboard: [],
      dataReports: null,
      surveys: [],
      sendings: [],
      search: '',
      totalContentRange: null,
      page: 1,
      sendingsPerPage: 20,
      dynamicPageCount: 0,
      noDataReportsOrInsights: false
    }
  },
  mounted () {
    this.getDataSurveys()

    this.$root.$on('tab-changed', payload => { this.tabSelected = payload.value })
  },
  computed: {
    filters () { return this.$store.getters['filtersInsight/getFilters'] },
    account () { return this.$store.getters['account/getAccount'] },
    user () { return this.$store.getters['account/getUser'] }
  },
  methods: {
    middlewareDataAnalysis (e) {
      this.page = 1
      this.getAnalysisData(e)
    },
    async getReportData (params) {
      this.loading = true
      this.noDataReportsOrInsights = false
      this.dataReports = null
      const findSurvey = this.surveys.find(e => e._id === params.surveyId)
      const result = await insightsService.getReports(params.surveyId)

      if (Object.keys(result).length > 0) {
        if (!result.metadatas?._binds_sentiment) {
          this.noDataReportsOrInsights = true
          this.loading = false
          return
        }

        this.dataReports = { ...result, name: findSurvey.title }
        this.key++
        this.loading = false
      }
    },
    async getDashboardData (params) {
      this.loading = true
      const q = this.filters.q
      const period = params.period ? params.period : this.account.dashboardDefaultPeriod
      const formatedParam = { surveyId: params.surveyId, date: params.date, period: period, q }
      const [
        resultSwot,
        resultPerformanceSentiment,
        resultPerformanceClassification,
        resultOfEmotions,
        resultOSentiments,
        resultOfCategories
      ] = await Promise.all([
        insightsService.getSwot(formatedParam),
        insightsService.getGeneralPerformance({ ...formatedParam, metric: 'sentiment' }),
        insightsService.getGeneralPerformance({ ...formatedParam, metric: 'emotion' }),
        insightsService.getMetrics({ ...formatedParam, metric: 'emotion' }),
        insightsService.getMetrics({ ...formatedParam, metric: 'sentiment' }),
        insightsService.getMetrics({ ...formatedParam, metric: 'classification' })
      ])

      this.dataDashboard = {
        swot: resultSwot,
        overtimeSentiment: resultPerformanceSentiment,
        overtimeClasification: resultPerformanceClassification,
        syntheticEmotions: resultOfEmotions,
        syntheticSentiments: resultOSentiments,
        syntheticCategories: resultOfCategories
      }
      this.loading = false
    },
    async getAnalysisData (params) {
      this.loading = true
      this.noDataReportsOrInsights = false
      const result = await insightsService.getInsightsFromQuestion(params)
      if (result) {
        this.dataAnalysis = result.data
        if (result.range) {
          const totalContent = result.range.split('/')[1]
          if (totalContent !== '*') { this.totalContentRange = +(totalContent) }
        }
        if (result?.data.length) {
          this.updatePagination()
        } else {
          this.noDataReportsOrInsights = true
        }
        this.loading = false
      }
    },
    async getDataSurveys () {
      const getSurveys = await insightsService.getSurveysWithQuestionsTypeText()

      if (getSurveys.length) {
        getSurveys.sort((a, b) => (a.title?.toUpperCase() > b.title?.toUpperCase() ? 1 : -1))
        this.surveys = orderFormater.expiredAt(getSurveys)
        this.surveyId = this.surveys[0]._id
        this.setDefaultPeriod()
      } else {
        this.noData = true
      }
      this.updatePagination()
    },
    async changePage (page) {
      this.page = page
      const endRange = this.page * 20
      const startRange = endRange - 20
      const searchText = this.$route.query.textSearch ? this.$route.query.textSearch : ''

      const rangeWithText = {
        startRange,
        endRange: endRange - 1,
        searchText,
        ...this.filters
      }

      this.getAnalysisData(rangeWithText)
    },
    updatePagination () {
      const itemsLength = (this.totalContentRange / 20)
      if (Number.isInteger(itemsLength)) {
        this.dynamicPageCount = itemsLength
      } else {
        this.dynamicPageCount = Math.ceil(itemsLength)
      }
    },
    setDefaultPeriod () {
      const currentLocale = this.$moment.locale()
      const date = {
        startDate: '',
        endDate: this.$moment(new Date(), 'YYYY-MM-DD HH:mm ZZ').utc().format()
      }

      switch (this.account.viewSettings.dashboardDefaultPeriod) {
        case 'day': date.startDate = this.$moment(new Date(), 'YYYY-MM-DD HH:mm ZZ').locale(currentLocale).utc().format()
          break
        case 'week': date.startDate = this.$moment(new Date(), 'YYYY-MM-DD HH:mm ZZ').subtract(1, 'weeks').utc().format()
          break
        case 'month': date.startDate = this.$moment(new Date(), 'YYYY-MM-DD HH:mm ZZ').subtract(1, 'months').utc().format()
          break
        case 'semester': date.startDate = this.$moment(new Date(), 'YYYY-MM-DD HH:mm ZZ').subtract(6, 'months').utc().format()
          break
        case 'year': date.startDate = this.$moment(new Date(), 'YYYY-MM-DD HH:mm ZZ').subtract(1, 'years').utc().format()
          break
        default: date.startDate = this.$moment(new Date(), 'YYYY-MM-DD HH:mm ZZ').locale(currentLocale).utc().format()
          break
      }

      this.$store.commit('filtersInsight/updateDate', date)

      const query = { }

      if (this.filters.surveyId) {
        date.startDate = this.filters.date.startDate
        date.endDate = this.filters.date.endDate
        query.surveyId = this.filters.surveyId
        query.questionId = this.filters.questionId
        query.date = this.filters.date
        query.textSearch = this.filters.textSearch
        query.q = this.filters.q
      }

      this.$store.commit('filtersInsight/insightField', query)

      return this.$router.push({ name: 'Insights', query })
        .catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error when updateing url with filters', err) })
    }
  }
}
</script>

<style lang="scss">
.container-page-insight {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .content-menu {
    width: 100%;
  }
  .no-data-to-show {
    height: 95%;
  }
  .content-insight {
    width: 100%;
    height: 92vh;
    background: #EFF3F8;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .main-insight {
      width: 100%;
      height: 89%;
      background: #ffffff;
      padding: 0.5rem;
      overflow-y: auto;
      border-radius: 0.5rem;
      border: 0.1rem solid #cacaca;
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
      article {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        .pagination-list-insight {
          width: 100%;
          height: 5%;
          display: flex;
          align-items: center;
          justify-content: center;
          .pagination {
            height: 100%;
            margin: 0;
            .page-link-item, .prev-link-item, .next-link-item {
              padding: 0.2rem 0.7rem !important;
              font-size: 0.8rem !important;
            }
            .active {
              .page-link-item {
                border-radius: 0.5rem !important;
              }
            }
            .page-link-item:hover, .prev-link-item:hover, .next-link-item:hover {
              color: #808080 !important;
              border-color: #808080 !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .content-menu {
    min-height: 4.9rem;
  }
}

@page {
  margin: 0;
  size: A4;
}

@media print {
  .section-menu-insight, .content-menu {
    display: none !important;
  }
  .header-report-data {
    background: #ffffff !important;
    height: 147vh !important;
    border: 1px solid #e1e1e1 !important;
    border-radius: 3px;
    padding: 0 !important;
    overflow: hidden;
    align-items: center;
    .to-export-reports {
      width: 100% !important;
      display: flex !important;
    }
    .title-report {
      width: 100% !important;
      text-align: left !important;
      padding-left: 20px !important;
      margin: 15px !important;
      font-size: 42px !important;
    }
    .label-general-resume {
      width: 95% !important;
      font-size: 21px !important;
      font-style: normal !important;
      line-height: 25px !important;
      font-weight: 400 !important;
      background: #fbfbfb !important;
      padding: 15px !important;
      border-radius: 15px !important;
      border: 1px solid #e1e1e1 !important;
    }
    .footer-to-export {
      display: flex !important;
    }
  }

  .container-page-insight {
    width: 100% !important;
  }

  .main-insight, article, .container-page-insight {
    height: fit-content !important;
    overflow: visible !important;
  }

  .main-insight {
    padding: 3px !important;
    box-shadow: none !important;
    border: none !important;
    background: transparent !important;
  }

  .container-card-metadata-insight {
    position: relative !important;
    background: #ffffff !important;
    height: 146.5vh !important;
    padding: 8px !important;
    margin-top: 15px !important;
    .header-card-metadata-insight {
      margin-bottom: 15px !important;
      height: fit-content !important;
      .title-card-metadata-insight {
        font-size: 30px !important;
      }
    }
    .content-values-card-metadata-insight {
      gap: 20px !important;
      .meta-analysis-insight {
        .title-meta-insight {
          font-size: 22px !important;
        }
        .header-meta-insight {
          height: 20px !important;
          margin: 15px 0 !important;
          .title-header {
            .icon-insights {
              height: 15px !important;
            }
            span {
              font-size: 14px !important;
            }
          }
          .label-header {
            font-size: 14px !important;
            .help-info {
              font-size: 14px !important;
            }
          }
        }
        .content-meta-insights {
          .meta-insight {
            padding: 5px !important;
            .icon-circle {
              font-size: 20px !important;
            }
            .emoji-meta-insights {
              font-size: 15px !important;
            }
            .label-meta-insights {
              font-size: 16px !important;
            }
          }
        }
      }
    }
  }

}
</style>
