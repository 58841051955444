import Vue from 'vue'
import App from './App'
import { router } from './router'
import store from './store'
import './../node_modules/@drewbot/sass-flexbox-grid/public/sass-flexbox/main.css'
import i18n from './translations/i18n'
import VueWorker from 'vue-worker'
// comentado pois estamos chamando em cada componente que utiliza ele
// import DesignSystem from '@binds-tech/binds-design-system'
// DesignSystem.install(Vue)

// esse pode ser removido dps, passando ele importado em cada tela q utiliza o mesmo
import 'vue-select/dist/vue-select.css'
// comentado pois estamos chamando em cada componente que utiliza ele
// import vSelect from 'vue-select'

import vueCustomElement from 'vue-custom-element'
import ThemeStyle from './components/theme/ThemeStyle.vue'
import 'v-title/lib/element-ui'
import VTitle from 'v-title'
import Tawk from 'vue-tawk'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'

Vue.use(Donut)
Vue.component('v-date-picker', DatePicker)
Vue.use(Tawk, {
  // tawkSrc: 'https://embed.tawk.to/57a0cbab14e2f0af26fdcf8c/default'
  tawkSrc: 'https://embed.tawk.to/63e13877474251287911ceb7/1gojs95bn'
  // tawkSrc: 'https://embed.tawk.to/63640d7ab0d6371309cd2938/1ggvd99gd' --> para testes
})
Vue.filter('formatNumbers', function (value) {
  return value ? value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.') : ''
})

Vue.use(VueWorker)

Vue.use(vueCustomElement)
Vue.component('theme-style', ThemeStyle)
// tem q importar essa lindeza para carregar as traduções de 'segundos atrás, 1 mÊs atrás etc'
const moment = require('moment')
Vue.use(require('vue-moment'), {
  moment
})
Vue.use(VTitle)

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
