<template>
  <div class="container-wpp-hub">
    <div class="header-wpp-hub">
      <div class="send-icon-whatsapp">
        <div class="ball-icon-wpp">
          <i class="material-icons icon-img-wpp">call</i>
        </div>
      </div>
      <app-heading level="h4" class="title-wpp-hub">{{ $t('whatsapp.title_template') }}</app-heading>
    </div>
    <div class="templates-wpp-hub">
      <CardTemplateWpp
        :name="'_text'"
        :textFormated="returnTextFormated"
        :urlImg="false"
        :btnFooter="false"
        :optOutButton="true"
        :showLink="true"
        @select-template="selectTemplate"
      />
      <CardTemplateWpp
        :name="'_image'"
        :textFormated="returnTextFormated"
        :urlImg="urlImg"
        :btnFooter="true"
        :showLink="false"
        @select-template="selectTemplate"
      />
      <CardTemplateWpp
        :name="'_button'"
        :textFormated="returnTextFormated"
        :urlImg="false"
        :btnFooter="true"
        :showLink="false"
        @select-template="selectTemplate"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CardTemplateWpp from './../../whatsapp/CardTemplateWpp.vue'

export default {
  name: 'whatsappHub',
  components: {
    CardTemplateWpp,
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      templateSelected: '',
      urlImg: '',
      oldText: '',
      metadataGroup: [],
      replacements: {
        _name: 'Tobias',
        _email: 'tobias@binds.co',
        _phone: '4002-8922'
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('change-template')
    this.$root.$off('update-image-wpp')
    this.$root.$off('name-wpp-changed')
    this.$root.$off('save-config-wpp')
    this.$store.commit('sendhub/resetWpp')
  },
  mounted () {
    this.templateSelected = this.emailSettings.whatsapp

    this.urlImg = this.emailSettings.images && this.emailSettings.images[1] ? this.emailSettings.images[1].value : this.account.logoUrl

    if (this.user.account.fields.length > 0) {
      this.user.account.fields.forEach(e => {
        this.replacements[e.key] = e.label
      })
    }

    this.$root.$on('save-config-wpp', () => {
      this.$store.commit('sendhub/updateWpp', this.templateSelected)
    })

    this.$root.$on('update-image-wpp', () => {
      this.urlImg = this.$store.getters['sendhub/getEmailSettings'].images[1].value
    })

    this.$root.$on('name-wpp-changed', payload => {
      this.templateSelected.params[0].value = payload
    })

    this.selectTemplate(`._${this.templateSelected?.type}`)
  },
  computed: {
    checkUserIsAllowedToSend () {
      if (this.user.type === 'user' && this.user.features.seedBatches) return true // in this case the user is not allowed to modify the field
      else return false
    },
    ...mapGetters({
      account: 'account/getAccount',
      user: 'account/getUser',
      linkSettings: 'sendhub/getLinkSettings',
      emailSettings: 'sendhub/getEmailSettings',
      templatesWpp: 'sendhub/getTemplatesWpp'
    }),
    returnTextFormated () {
      if (!this.templateSelected?.params) return ''
      const textFormated = this.templateSelected?.params[0].value
      const textAfterReplaceMeta = this.replaceMetaText(textFormated)
      return textAfterReplaceMeta.replace(/[_](.+?)[_](?![_])/g, '<i>$1</i>').replace(/\*(.+?)\*(?!\*)/g, '<b>$1</b>').replace(/[~](.+?)[~](?![~])/g, '<s>$1</s>')
    }
  },
  methods: {
    replaceMetaText (textToParse) {
      if (!textToParse) return textToParse
      const pattern = /\*\|([^|]+)\|\*/g

      return textToParse.replace(pattern, (match, key) => {
        if (Object.hasOwn(this.replacements, key)) {
          if (!this.replacements[key]) return ''
          return this.replacements[key]
        }
        return ''
      })
    },
    async selectTemplate (selectedClass) {
      const allClassTemplates = document.querySelectorAll('.template-wpp')
      const toSelected = document.querySelector(selectedClass)

      for (const t of allClassTemplates) {
        t.classList.remove('selected')
      }

      toSelected.classList.add('selected')
      this.templateSelected.type = selectedClass.replace('._', '')

      this.$root.$emit('change-template', this.templateSelected)
    }
  }
}
</script>
<style lang="scss">
.container-wpp-hub {
  background: #FFFFFF;
  width: 97%;
  height: 80vh;
  margin-top: 15px;
  border-radius: 15px;
  border: 1px solid #cacaca;
  overflow: hidden;
  .header-wpp-hub {
    background: var(--secondary-color);
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    .send-icon-whatsapp {
      width: 5%;
      height: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
      .ball-icon-wpp {
        width: 45px;
        height: 45px;
        background: var(--default-text-color);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-img-wpp {
          font-size: 30px;
          color: var(--secondary-color);
        }
      }
    }
    .title-wpp-hub {
      color: #FFFFFF;
      margin-left: 10px;
    }
  }
  .templates-wpp-hub {
    width: 100%;
    height: 85%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 0.5rem;
    .selected {
      border: 1px solid #454548;
      -webkit-box-shadow: 0px 0px 3px 0px #454548;
      -moz-box-shadow: 0px 0px 3px 0px #454548;
      box-shadow: 0px 0px 3px 0px #454548;
      .checked-template {
        background: #454548;
      }
    }
  }
}

@media only screen and (max-width: 1068px) {
  .templates-wpp-hub {
    overflow-y: auto;
    padding: 20px 0;
    gap: 20px;
  }
}
</style>
