<template>
  <div>
    <div class="loading-templates-list" style="position: fixed;" v-if="loadingTable">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <div class="row one-by-one" style="width:100%">
      <div class="content-sendind-card ">
        <app-card class="sending-card">
          <app-heading level="h5">{{ $t('sendhub.contacts_select') }}</app-heading>
          <app-heading level="h6" class="select-text">{{ $t('sendhub.explain_select') }}</app-heading>
          <div class="content-contacts">
            <div class="edit-spreadsheet">
              <div class="clear-btn edit-btn">
                <app-tooltip hover bottom>
                  <app-button class="btn-edit-spreadsheet" inline v-on:click="isToClear()">{{$t('sendhub.clean')}}</app-button>
                  <template v-slot:activator>
                    <app-button class="btn-circle-accent"  v-on:click="isToClear()" floating variation="secondary"><i class="material-icons clear-icon">delete_sweep</i></app-button>
                  </template>
                  </app-tooltip>
              </div>
              <div class="edit-btn">
                <app-tooltip hover bottom>
                  <app-button class="btn-edit-spreadsheet" inline v-on:click="insertRow()">{{ $t('sendhub.add_line') }}</app-button>
                  <app-button class="btn-edit-spreadsheet" inline v-on:click="insertColumn()">{{ $t('sendhub.add_column') }}</app-button>
                  <template v-slot:activator>
                    <app-button class="btn-circle-accent"  floating variation="secondary"><i class="material-icons clear-icon">playlist_add</i></app-button>
                  </template>
                  </app-tooltip>
              </div>
            </div>
            <div class="spreadsheet-contacts" id="sendContacts">
              <div id="spreadsheet" ref="spreadsheet"></div>
            </div>
            <div class="rename-column" v-if="renameColumn">
              <button class="close-input-column" v-on:click="renameColumn = false">x</button>
              <form v-on:submit.prevent="columnName()" class="form-select-column">
                <app-form-dropdown
                  class="select-metadata"
                  v-model="newNameColumn"
                  :label="$t('sendhub.column_select')"
                  :items="items"
                  item-value="id"
                  item-text="label"
                />
                <app-button variation="primary" class="submit-input-column">ok</app-button>
              </form>
              <app-button inline class="delete-column" v-on:click="removeColumn()">
                <span>Excluir</span>
                <app-icon name="trash" size="xs" color="white" />
              </app-button>
            </div>
          </div>
        </app-card>
      </div>
      <div class="modal-container-failure" v-if="failureSeeds">
        <div class="modal-content-failure">
          <div class="modal-title-failure">
            <span>
              <i class="material-icons check">done</i>
            </span>
            <i class="material-icons close" @click="fixContacts()">close</i>
            <app-heading level="h4">{{$t('sendhub.sendings_precessed')}}</app-heading>
          </div>
          <div class="modal-details-failure">
            <div class="total-processed">
              <app-heading level="h5">{{$t('sendhub.sendings_total')}}:</app-heading>
              <p class="precessed">{{dataSeed.totalSent}}</p>
            </div>
            <div class="total-failures">
              <app-heading level="h5">{{$t('sendhub.failures_total')}}:</app-heading>
              <p class="failure">{{dataSeed.totalFailed}}</p>
            </div>
            <div class="total-duplicates">
              <app-heading level="h5">{{$t('sendhub.duplicates_total')}}:</app-heading>
              <p class="duplicate">{{dataSeed.totalSkipped}}</p>
            </div>
          </div>
          <div class="duplicates" :style="{'overflow-y': showDuplicates ? 'auto' : 'hidden'}">
            <div class="duplicate-title">
              <p>{{$t('sendhub.name')}}</p>
              <p>{{$t('sendhub.email')}}</p>
              <p>{{$t('sendhub.send_phone')}}</p>
              <p>{{$t('sendhub.status')}}</p>
            </div>
            <div class="item-duplicate" v-for="(item, idx) in dataSeed.failures" :key="idx">
              <p>{{item.from.name}}</p>
              <p>{{item.from.email}}</p>
              <p>{{item.from.phone || '-'}}</p>
              <p>{{item.status === 'INVALID' ? $t('sendhub.invalid') : $t('sendhub.duplicate')}}</p>
            </div>
          </div>
          <button class="view-more" v-if="dataSeed.totalSkipped > 5" @click="showDuplicates = !showDuplicates">
            <app-heading level="h6">{{nameMore}}</app-heading>
            <i class="material-icons drop" v-if="!showDuplicates">arrow_drop_down</i>
            <i class="material-icons drop" v-if="showDuplicates">arrow_drop_up</i>
          </button>
          <div class="modal-buttons-failure">
            <button @click="goDashboard()">{{$t('sendhub.go_to_dashboard')}}</button>
            <button @click="fixContacts()">{{$t('sendhub.fix_contacts')}}</button>
          </div>
        </div>
      </div>
    </div>
    <app-modal
      v-model="cleanAll"
      close
      :title="$t('sendhub.is_to_clean')"
      class="modal-default-primary error-modal-buttons"
      lightbox
      :primary-button="$t('sendhub.yes')"
      @primaryButtonClick="clearData()"
      :secondary-button="$t('sendhub.no')"
      @secondaryButtonClick="cleanAll = false"
    ></app-modal>
    <app-modal
      class="modal-default-primary loadingModal"
      v-model="isLoading"
      :title="$t('sendhub.validating_survey')"
      lightbox
    >
    <DoubleBounce></DoubleBounce>
    </app-modal>
    <app-modal
      v-model="successSeeds"
      :title="$t('sendhub.sent_success')"
      close
      lightbox
      :primary-button="$t('sendhub.go_to_dashboard')"
      @primaryButtonClick="goDashboard()"
      class="modal-default-primary success_modal"
    >
    </app-modal>
    <app-modal
      v-model="noReturnSeeds"
      close
      class="modal-default-primary error-modal-buttons"
      lightbox
    >
    <div class="messages-noreturn">
     <app-heading level="h4" v-if="messageForbidden"> {{$t('surveyList.error_go_to')}}</app-heading>
    </div>
    </app-modal>
    <app-modal
      v-model="successSeeds"
      :title="$t('sendhub.sent_success')"
      close
      lightbox
      :primary-button="$t('sendhub.go_to_dashboard')"
      @primaryButtonClick="goDashboard()"
      class="modal-default-primary success_modal"
    >
    </app-modal>
    <app-modal
      class="modal-default-primary"
      v-model="modalPreSend"
      :title="$t('date_send.when_send')"
      close
      lightbox
    >
      <div class="modal-presend-header">
        <div style="width: 100%; display: flex; justify-content: space-around; align-items: center; margin-top: 10px">
          <app-button class="btn-circle-accent" style="margin: 0;" variation="secondary" v-on:click="seedsData(null)">{{$t('date_send.send_now')}} <app-icon name="email" size="xs"/></app-button>
          <app-button
            :style="{'display': this.$route.query.type !== 'whatsapp' ? 'flex' : 'none'}"
            v-on:click="sendDataSelect = true">{{$t('date_send.select_date')}}
            <app-icon name="timer" size="xs" color="gray-darker"/>
          </app-button>
        </div>
      </div>
      <div class="modal-presend-body" v-if="sendDataSelect">
        <div class="presend-calendar">
          <span style="color:gray">{{ $t('date_send.date_time') }}:</span>
          <v-date-picker
          :min-date='new Date()'
          :locale="$i18n.locale"
          :masks='{inputDateTime24hr: "D MMMM YYYY, H:mm"}'
          v-model="dateToSend"
          color="gray" mode="dateTime" is24hr>
            <template v-slot="{ inputValue, inputEvents }">
              <div class="icon-container">
                <i class="material-icons">event</i>
                <input v-on="inputEvents"
                  class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  inputValue readonly :placeholder="$t('activity.today')" :value="inputValue"
                />
              </div>
            </template>
          </v-date-picker>
        </div>
        <div class="btn-pre-send">
          <app-button class="btn-circle-accent" v-on:click="dataPreSend()" variation="primary" v-if="sendDataSelect">{{$t('date_send.send')}}</app-button>
        </div>
      </div>
    </app-modal>
    <theme-style>
      .modal-title-failure {
        background-color: {{user.account.theme.colors.primary  || '#ed4d7f'}};
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }};
      }
      .modal-title-failure > h4 {
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      }
      .modal-title-failure > .close {
        color: {{user.account.theme.colors.accent  || '#ed4d7f'}} !important;
      }
      .modal-buttons-failure > button {
        background-color: {{user.account.theme.colors.primary  || '#ed4d7f'}} !important;
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      }
      .duplicate-title {
        background-color: {{user.account.theme.colors.primary  || '#ed4d7f'}} !important;
        color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
      }
      .modal-title-failure > span {
        background-color: {{user.account.theme.colors.accent  || '#ed4d7f'}} !important;
      }
  </theme-style>
  </div>
</template>

<script>
import { sendhubService, accountService, surveyService, userService } from '@/_services'
import 'jspreadsheet-ce/dist/jspreadsheet.css'
import 'jsuites/dist/jsuites.css'
import { mapGetters } from 'vuex'
const jspreadsheet = require('jspreadsheet-ce')
export default {
  name: 'OneByOne',
  components: {
    VDatePicker: () => import('v-calendar/lib/components/date-picker.umd'),
    'app-icon': () => import('@binds-tech/binds-design-system/src/components/Icon/Icon'),
    'app-tooltip': () => import('@binds-tech/binds-design-system/src/components/Tooltip/Tooltip'),
    'app-form-dropdown': () => import('@binds-tech/binds-design-system/src/components/Form/Dropdown'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  props: ['activeImport', 'isSocialMedia', 'nameDocument'],
  data () {
    return {
      limitToSend: '',
      dataSends: this.checkPreviousDataSendedOrReturnDefault(),
      showDuplicates: false,
      dataSeed: [],
      sendhubTable: '',
      timePreSend: '',
      newNameColumn: '',
      renameColumn: false,
      columnSelected: '',
      newColumns: [],
      worker: '',
      isLoading: false,
      loadingTable: false,
      successSeeds: false,
      failureSeeds: false,
      modalPreSend: false,
      messageExceed: false,
      messageForbidden: false,
      noReturnSeeds: false,
      sendDataSelect: false,
      dateToSend: '',
      cleanAll: false,
      lastPastedItens: [],
      numberOfEmailsFail: [],
      numberOfPhonesFail: [],
      items: [
        { id: 1, label: this.$i18n.t('sendhub.name'), key: 'Name' },
        { id: 2, label: this.$i18n.t('sendhub.email'), key: 'Email' },
        { id: 3, label: this.$i18n.t('sendhub.phone'), key: 'Phone' }
      ]
    }
  },
  beforeDetroy () {
    if (this.worker) {
      this.worker = ''
    }
  },
  computed: {
    nameMore () {
      const name = this.showDuplicates ? this.$i18n.t('sendhub.view_less') : this.$i18n.t('sendhub.view_more')
      return name
    },
    collection () {
      const cl = this.$store.getters['sendhub/getCollection']
      return cl
    },
    jExcelOptions () {
      return {
        data: this.dataSends,
        onpaste: this.pasteData,
        defaultColWidth: 320,
        onbeforepaste: (instance, newdData, x, y) => {
          this.$emit('before-paste-handle', [newdData, x, y])
          return false
        },
        oninsertcolumn: this.onInsertColumn,
        minDimensions: [4, 20],
        onselection: this.onFocusColumn,
        contextMenu: function () {
          return false
        },
        onbeforechange: this.changed,
        columns: this.checkPreviousColumnsSendedOrReturnDefault()
      }
    },
    ...mapGetters({
      user: 'account/getUser'
    })
  },
  methods: {
    checkPreviousDataSendedOrReturnDefault () {
      const checkIfWasSended = window.localStorage.getItem(`binds-survey-send-table-${this.$route.query.type}-${this.$route.params.id}`)
      if (checkIfWasSended) {
        if (typeof checkIfWasSended === 'string') {
          return JSON.parse(checkIfWasSended)
        }
      }
      return []
    },
    checkPreviousColumnsSendedOrReturnDefault () {
      let columnsInit = [
        { type: 'text', width: '300px', title: 'Nome' },
        { type: 'text', width: '300px', title: 'Coluna 2' },
        { type: 'text', width: '300px', title: 'Coluna 3' },
        { type: 'text', width: '300px', title: 'Coluna 4' },
        { type: 'text', width: '300px', title: 'Coluna 5' }
      ]
      const checkIfWasSended = window.localStorage.getItem(`binds-survey-send-headers-${this.$route.query.type}-${this.$route.params.id}`)
      if (checkIfWasSended) {
        if (typeof checkIfWasSended === 'string') {
          const splited = checkIfWasSended.split(',')
          columnsInit = []
          splited.forEach(column => {
            columnsInit.push({
              type: 'text',
              width: '300px',
              title: column
            })
          })
        }
        return columnsInit
      }

      if (this.$route.query.type === 'sms' || this.$route.query.type === 'whatsapp') {
        // to sendType SMS or WHATSAPP
        columnsInit[1].title = this.$i18n.t('sendhub.phone')
      } else if (this.$route.query.type === 'all') {
        // to sendType All
        columnsInit[1].title = 'Email'
        columnsInit[2].title = this.$i18n.t('sendhub.phone')
      } else {
        // to sendType Email
        columnsInit[1].title = 'Email'
      }
      return columnsInit
    },
    changed (instance, cell, x, y, value) {
      const lengthOfRows = this.sendhubTable.rows.length - 1
      if (lengthOfRows === parseInt(y)) {
        this.insertRow()
      }
      window.localStorage.setItem('binds-table-social-media-file', JSON.stringify(this.dataSends))
    },
    dataPreSend () {
      const dateNow = new Date()
      const sendDate = this.dateToSend
      if (sendDate < dateNow) {
        this.$store.commit('alerts/alert', {
          message: this.$t('date_send.valid_date'),
          position: 'bottomRight',
          showAlert: true
        })
        return
      }

      if (sendDate) {
        this.seedsData(sendDate)
      } else {
        this.$store.commit('alerts/alert', {
          message: this.$t('date_send.date_time'),
          position: 'bottomRight',
          showAlert: true
        })
      }
    },
    isToClear () {
      this.cleanAll = true
    },
    clearData () {
      document.getElementById('spreadsheet').innerHTML = ''
      this.sendhubTable.setData([])
      this.dataSends = []
      this.jExcelOptions.columns = this.checkPreviousColumnsSendedOrReturnDefault()
      this.sendhubTable = jspreadsheet(this.$refs.spreadsheet, this.jExcelOptions)
      window.localStorage.removeItem(`binds-survey-send-table-${this.$route.query.type}-${this.$route.params.id}`)
      window.localStorage.removeItem('binds-table-social-media-file')
      this.cleanAll = false
    },
    fixContacts () {
      this.numberOfEmailsFail = []
      this.failureSeeds = false
      this.showDuplicates = false
    },
    pasteData () {
      this.renameColumn = false
    },
    insertRow () {
      if (this.dataSends.length >= this.limitToSend) {
        return this.$store.commit('alerts/alert', {
          message: this.$t('sendhub.limit'),
          position: 'bottomRight',
          showAlert: true
        })
      }
      this.sendhubTable.insertRow()
      var container = this.$el.querySelector('#sendContacts')
      container.scrollTop = container.scrollHeight + 140
    },
    onInsertColumn (instance, column) {
      if (this.columnSelected) {
        const columnLength = this.dataSends[0].length - 1
        this.sendhubTable.setHeader(columnLength, 'Coluna')
        this.sendhubTable.setWidth(columnLength, 275)
      }
    },
    insertColumn () {
      this.columnSelected = this.dataSends[0].length
      this.renameColumn = true
      this.sendhubTable.insertColumn()
      var container = this.$el.querySelector('#sendContacts')
      container.scrollLeft = container.scrollWidth + 275
    },
    seedsData (dateSend) {
      const sendingType = this.$route.query.type.toLowerCase()
      const header = this.sendhubTable.getHeaders()
      const ColumnsHeader = header.split(',')

      let seeds = []
      const data = this.dataSends
      data.unshift(ColumnsHeader)
      for (let i = 1; i < data.length; i++) {
        const row = data[i]
        const prevSeeds = {}

        for (let u = 0; u < row.length; u++) {
          const column = row[u]
          const label = data[0][u]

          prevSeeds[label] = column
        }

        seeds.push(prevSeeds)
      }

      const notMetadataColumnKeys = [this.$i18n.t('sendhub.name'), this.$i18n.t('sendhub.email'), 'Data', this.$i18n.t('sendhub.phone')]
      seeds = seeds.map(seed => {
        const formatedSeed = {
          from: {
            name: seed[this.$i18n.t('sendhub.name')],
            email: seed.Email,
            phone: seed[this.$i18n.t('sendhub.phone')]
          },
          metadata: {},
          defaultSendingType: sendingType
        }

        Object.keys(seed)
          .filter(k => notMetadataColumnKeys.indexOf(k) === -1)
          .forEach(k => {
            formatedSeed.metadata[k] = seed[k]
          })

        for (var key in formatedSeed.metadata) {
          if (formatedSeed.metadata[key] === '' || formatedSeed.metadata[key] === null) {
            delete formatedSeed.metadata[key]
          }
        }

        return formatedSeed
      })
      seeds = seeds.filter(item => {
        if (this.$route.query.type === 'all') return item.from.email || item.from.phone
        if (this.$route.query.type === 'Email') return item.from.email
        if (this.$route.query.type === 'sms' || this.$route.query.type === 'whatsapp') return item.from.phone
      })

      this.modalPreSend = false
      this.save(seeds, dateSend)
    },
    async save (data, dateSend) {
      this.numberOfEmailsFail = []
      this.numberOfPhonesFail = []
      if (data.length <= 0) {
        this.$store.commit('alerts/alert', {
          message: this.$t('sendhub.empty_table'),
          position: 'bottomLeft',
          showAlert: true
        })
        this.modalPreSend = false
        this.dataSends = []
        return false
      }
      this.isLoading = true
      let sendObjectData = {}
      if (dateSend === null) {
        sendObjectData = { collector: this.collection, seeds: data }
      } else {
        dateSend = dateSend.toString()
        sendObjectData = { collector: this.collection, date: dateSend, seeds: data }
      }
      const sendData = await sendhubService.seeds(sendObjectData)
      this.dataSeed = sendData
      this.isLoading = false
      try {
        window.localStorage.setItem(`binds-survey-send-headers-${this.$route.query.type}-${this.$route.params.id}`, this.sendhubTable.getHeaders())
      } catch (error) {
        console.log('erro ao tentar salvar os headers da tabela', error)
      }
      // sendData - nenhum objeto retornado
      if (!sendData) {
        const acc = await userService.getMe()
        // checando se a conta excedeu on limite de envio
        if (acc.account.sendingSettings.hasExceededLimit === true && acc.account.billing.hasInvoice === false) {
          this.$root.$emit('exceeded')
        } else {
          this.noReturnSeeds = true
          this.messageForbidden = true
        }
        this.clearData()
      } else {
        // failures list
        if (sendData.failures.length > 0) {
          this.failureSeeds = true
          const failures = []
          const previousHeaders = this.dataSends[0]
          sendData.failures.forEach(item => {
            if (item.status === 'INVALID') {
              this.numberOfEmailsFail.push('INVALID_EMAIL')
              this.numberOfPhonesFail.push('INVALID_EMAIL')
            }
            let rowErrors = []
            const indexOfEmail = previousHeaders.indexOf('Email')
            const indexOfPhone = previousHeaders.indexOf(this.$i18n.t('sendhub.phone'))
            if (indexOfEmail >= 0) {
              rowErrors = this.dataSends.filter(send => send[indexOfEmail] === item.from.email)
              return failures.push(...rowErrors)
            }
            if (indexOfPhone >= 0) {
              rowErrors = this.dataSends.filter(send => send[indexOfPhone] === item.from.phone)
              return failures.push(...rowErrors)
            }
          })
          this.sendhubTable.setData(failures)
          this.dataSends = failures
        } else {
          this.successSeeds = true
          this.clearData()
        }
      }
    },
    async goDashboard () {
      const surveyId = this.$route.params.id
      const survey = await surveyService.getSurvey(surveyId)
      if (survey) {
        this.$store.commit('dashboard/updateCurrentSurvey', survey)
        this.$store.commit('dashboard/updateSelectedSurveyId', surveyId)
        this.$router.push({ name: 'RealTime', query: { q: '{"$and":[]}' } })
        this.showDuplicates = false
      } else {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('surveyList.error_go_to'),
          position: 'bottomRight',
          showAlert: true
        })
        this.showDuplicates = false
      }
    },
    onFocusColumn (instance, x1, y1, x2, y2, origin) {
      this.renameColumn = false
      if (y1 === 0 && y2 === this.dataSends.length - 1) {
        this.columnSelected = x1
        this.renameColumn = true
      }
    },
    columnName () {
      if (this.newNameColumn && this.columnSelected) {
        if (!isNaN(this.newNameColumn)) {
          const findId = this.items.filter(item => {
            return item.key && item.id === parseInt(this.newNameColumn)
          })
          if (findId[0]) {
            this.newNameColumn = findId[0].label
          }
        }
        let columnNameValidade = false
        const headers = this.sendhubTable.getHeaders().split(',')

        if (headers.indexOf(this.newNameColumn) === -1) {
          columnNameValidade = true
        }
        if (columnNameValidade) {
          this.sendhubTable.setHeader(this.columnSelected, this.newNameColumn)
          this.renameColumn = false
          this.columnSelected = ''
          this.newNameColumn = ''
        } else {
          this.$store.commit('alerts/alert', {
            message: this.$t('sendhub.existing_column'),
            position: 'bottomLeft',
            showAlert: true
          })
        }
      }
    },
    removeColumn () {
      if (this.columnSelected) {
        this.sendhubTable.deleteColumn(this.columnSelected)
        this.renameColumn = false
      }
    },
    tryToImportJexcel () {
      try {
        if (!this.sendhubTable) {
          this.$refs.spreadsheet.innerHTML = ''
          this.sendhubTable = jspreadsheet(this.$refs.spreadsheet, this.jExcelOptions)
        }
      } catch (error) {
        this.sendhubTable = null
        // eslint-disable-next-line no-undef
        console.log('error when was trying to build de jexcel on one by one ', error.message)
      }
    }
  },
  created () {
    // temos varios gatilhos para tentar carregar a tabela excel
    // como importamos o arquivo da lib, tem tempo de resposta até que tudo esteja ok
    // podemos ver uma melhor forma de importar essa lib, mas atualmente só desse jeito faz com q carregue corretamente
    this.loadingTable = true
  },
  async mounted () {
    this.limitToSend = this.$route.query.type === 'whatsapp' ? 5000 : 20000
    // força esperar a pagina carregar os scripts para então renderizar a tabela
    var self = this
    const accountId = this.$store.getters['account/getUser'].account._id
    const account = await accountService.getAccount(accountId)
    this.$store.commit('account/setAccount', account)

    account.fields.map(function (o) {
      self.items.push({ id: o.key, label: o.label })
    })
    this.$nextTick(() => {
      this.tryToImportJexcel()
    })
    this.$root.$on('toSend', function (e) {
      if (self.activeImport === 'onebyone') {
        self.modalPreSend = true
        self.sendDataSelect = false
      }
    })

    this.$on('before-paste-handle', async (event) => {
      this.loadingTable = true
      const actions = [
        {
          message: 'insertCols',
          func: (biggestColumn, currentHeaders, columnName) => {
            const howManyColmuns = biggestColumn - currentHeaders.length
            const headers = currentHeaders
            for (let index = 0; index < howManyColmuns; index++) {
              headers.push(`${columnName} ${(headers.length + 1)}`)
            }
            return headers.map(item => { return { width: '300px', title: item, type: 'text' } })
          }
        },
        {
          message: 'insertRows',
          func: (howManyRows, dataSends) => {
            const dataToInsertRows = dataSends
            Array.from({ length: howManyRows }, (v, k) => k).forEach(item => {
              dataToInsertRows.push([])
            })
            return dataToInsertRows
          }
        },
        {
          message: 'processProcV',
          func: (data, colIndex, dataSends, rowIndex) => {
            for (let i = 0; i < data.length; i++) {
              let startColIndex = colIndex
              for (let j = 0; j < data[i].length; j++) {
                if (data[i][j] !== null && data[i][j]) {
                  dataSends[rowIndex][startColIndex] = data[i][j]
                }
                startColIndex++
              }
              rowIndex++
            }
            return dataSends
          }
        }
      ]
      this.worker = this.$worker.create(actions)
      const [newData, x, y] = event
      // Split new line
      var data = this.sendhubTable.parseCSV(newData, '\t')
      if (x != null && y != null && data) {
        var colIndex = parseInt(x)
        var rowIndex = parseInt(y)
        // Go through the columns to get the data
        // new way
        const biggestColumn = data.sort((a, b) => {
          return b.length - a.length
        })[0].length

        const currentHeaders = this.sendhubTable.getHeaders(true)
        let columns = currentHeaders.map(item => { return { width: '300px', title: item, type: 'text' } })
        if (biggestColumn > currentHeaders.length && (rowIndex + data.length) >= currentHeaders.length) {
          const howManyRows = rowIndex + data.length - this.dataSends.length
          const [headers, datasends] = await Promise.all(
            [
              this.worker.postMessage('insertCols', [biggestColumn, currentHeaders, this.$t('sendhub.column')]),
              this.worker.postMessage('insertRows', [howManyRows, this.dataSends])
            ]
          )
          this.dataSends = datasends
          columns = headers
        } else {
          if (biggestColumn > currentHeaders.length) {
            columns = await this.worker.postMessage('insertCols', [biggestColumn, currentHeaders, this.$t('sendhub.column')])
          }

          if ((rowIndex + data.length) >= currentHeaders.length) {
            const howManyRows = rowIndex + data.length - this.dataSends.length
            // limite de envio
            if ((howManyRows + this.dataSends.length) < this.limitToSend) {
              const res = await this.worker.postMessage('insertRows', [howManyRows, this.dataSends])
              this.dataSends = res
            } else {
              this.loadingTable = false
              this.$store.commit('alerts/alert', {
                message: this.limitToSend === 20000 ? this.$t('sendhub.limit') : this.$t('sendhub.limit_wpp'),
                position: 'bottomRight',
                showAlert: true
              })
              return false
            }
          }
        }
        const result = await this.worker.postMessage('processProcV', [data, colIndex, this.dataSends, rowIndex])
        this.dataSends = result
        document.getElementById('spreadsheet').innerHTML = ''
        this.jExcelOptions.columns = columns
        this.jExcelOptions.data = this.dataSends
        this.sendhubTable = jspreadsheet(this.$refs.spreadsheet, this.jExcelOptions)
        // end new way
      }
      this.loadingTable = false
      this.sendhubTable.setData(this.dataSends)
    })
    this.loadingTable = false
  }
}
</script>

<style lang="scss">
.modal-container-failure {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(155, 155, 155, 0.418);
  position: fixed;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-content-failure {
    background: #ffffff;
    width: 545px;
    min-height: 345px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
    .modal-title-failure {
      position: relative;
      width: 100%;
      height: 60px;
      border-radius: 10px 10px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 0 15px;
        .check {
          font-size: 25px;
          color: #000000;
        }
      }
      .close {
        color: gray;
        position: absolute;
        right: 10px;
        top: 15px;
        cursor: pointer;
      }
    }
    .modal-details-failure {
      width: 100%;
      min-height: 230px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .total-processed, .total-failures, .total-duplicates {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h5 {
          color: #000000 !important;
        }
        p {
          font-weight: bold;
        }
      }
      .total-processed {
        color: green;
      }
      .total-failures {
        color: red;
      }
      .total-duplicates {
        color: orange;
      }
      .details-duplicates {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          h6 {
            margin: 0 0 0 5px !important;
          }
        }
      }
    }
    .duplicates {
      margin: auto;
      margin-bottom: 15px;
      max-height: 158px;
      width: 95%;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      border: 1px solid #cacaca;
      .duplicate-title {
        padding: 15px 0;
        font-size: 15px;
        font-weight: 600;
        border-bottom: 1px solid rgb(63, 63, 63);
        width: 100%;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
      .item-duplicate {
        padding: 3px 0;
        font-size: 14px;
        color: gray;
        border-bottom: 1px solid rgb(226, 226, 226);
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 20px;
        margin-top: 5px;
        p:nth-child(2) {
          width: 30%;
        }
        p {
          width: 23.3%;
          text-align: center;
        }
      }
    }
    .view-more {
      padding: 15px 0;
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .modal-buttons-failure {
      width: 100%;
      height: 55px;
      border-radius: 0 0 10px 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      button {
        font-weight: bold;
        padding: 10px;
        border-radius: 5px;
      }
    }
  }
}
.modal-presend-header {
  margin: 20px 10px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #b5b5b5;
  button{
    i{
      color: #ffff !important;
    }
  }
}
.modal-presend-body{
  display: flex;
  margin: 0 10px 20px 10px;
  .presend-calendar{
    justify-content: center;
    span {
      font-size: 16px;
    }
    input {
      margin-top: 5px;
      width: 100%;
      height: 100%;
      font-size: 14px;
      text-align: center;
      border-radius: 5px;
      background-color: #fff;
      padding: 16px !important;
      border: 1px solid #c4c4c4;
      box-shadow: inset 0 3px 6px rgba(0,0,0,0.1);
    }
    .icon-container {
      display: flex;
      align-items: center;
    }
    .material-icons {
      padding: 5px;
      position: absolute;
    }
  }
  .btn-pre-send {
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    button {
      width: 80%;
      height: 50px;
    }
  }
}
.select-text {
  margin: 20px  0!important;
  width: 667px;
  height: 66px;
  line-height: 22px !important;
  color: #838383 !important;
}
.content-sendind-card {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  .sending-card {
    padding: 0 !important;
    margin: 30px 0;
    width: 98% !important;
    min-height: 464px !important;
    border-radius: 5px !important;
    & .py-2 {
      padding-bottom: 0px !important;
    }
  }
  .card {
    padding: 30px 15px !important;
  }
  .edit-spreadsheet {
    display: flex;
    position: absolute;
    // top: 135px;
    // left: 905px;
    top: 86px;
    right: 108px;
    z-index: 99;
    & .edit-btn {
      & .tooltip__wrap {
        z-index: 999999999999999 !important;
      }
      & .tooltip__wrap__content {
        width: 130px;
        text-align: left;
        padding: 10px !important;
        background-color: #424242 !important;
      }
      & .btn-edit-spreadsheet {
        font-size: 15px !important;
        padding: 3px !important;
        margin: 0;
        color: #fff !important;
      }
    }
    & .clear-btn {
      margin-right: 20px;
    }
  }
}
.content-send {
  position: relative;
}
.clear-icon {
  margin-top: 6px;
}
.clear-tooltip {
  position: relative;
  display: inline-block;
}
.clear-tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #3f3356;
  color: #fff;
  text-align: center;
  padding: 7px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}
.clear-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 13%;
  left: 20%;
  margin-left: -44px;
  border-width: 5px;
  border-style: solid;
  border-color: #3f3356 transparent transparent transparent;
}
.clear-tooltip:hover .tooltiptext {
  visibility: visible !important;
  opacity: 1;
}
.tooltip-right {
  top: -21px;
  left: 50%;
}
.tooltip-right::after {
  content: "" !important;
  position: absolute !important;
  right: 100% !important;
  margin-top: 6px;
  border-width: 5px !important;
  border-style: solid !important;
  border-color: transparent #3f3356 transparent transparent !important;

}
.spreadsheet-contacts {
  overflow-x: auto;
  overflow-y: auto;
}
.one-by-one {
  .spreadsheet-contacts {
      overflow-y: scroll;
      height: 530px;
  }
}
.spreadsheet-contacts .jexcel_content {
  padding: 0 !important;
  width: 100%;
}
.spreadsheet-contacts .jexcel_container {
  width: 100%;
  padding: 0 !important;
}
.spreadsheet-contacts .jexcel > thead > tr > td {
  background-color: #eff3f8;
  padding: 10px 0;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 2px solid #673ab7 !important;
  font-size: 16px !important;
}
.spreadsheet-contacts .jexcel > tbody > tr > td:first-child {
  background-color: #fff;
}
.spreadsheet-contacts .jexcel > tbody > tr:nth-child(even) {
  background-color: #eff3f8;
}
.jexcel > tbody > tr > td {
    border-top: none !important;
    border-bottom: none !important;
    padding: 7px;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 1em;
}
.jexcel .highlight-bottom {
  border-bottom: 1px solid #673ab7 !important;
  border-radius: 5px;
}
.jexcel .highlight-right {
  border-right: 1px solid #673ab7 !important;

}
.jexcel .highlight-left {
  border-left: 1px solid #673ab7 !important;
}
.jexcel .highlight-top {
  border-top: 1px solid #673ab7 !important;
}
.jexcel {
  width: 100% !important;
  border:none;
}
.jexcel_pagination {
  display: none;
}
.jexcel_contextmenu {
  margin-top: 10px;
}
.rename-column {
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;
  width: 330px;
  display: block;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 10px 64px -31px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 64px -31px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 64px -31px rgba(0, 0, 0, 0.75);

  border: 2px solid $color-gray-light;
  top: 282px;
  position: absolute;
  z-index: 10000;
  right: 350px;
  .text-field__input input {
    font-size: 15px !important;
  }
  .close-input-column {
    font-size: 18px;
    position: absolute;
    right: 10px;
    z-index: 100;
    top: 2px;
    font-weight: bold;
    color: $color-gray-light;
  }
  .delete-column {
    float: right;
    font-size: 15px !important;
    span {
      display: inline-block;
      vertical-align: text-bottom;
    }
    i {
      color: $color-gray !important;
    }
    &:hover {
      span,
      i {
        color: $color-purple-dark;
      }
    }
  }
}
.form-select-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  .select-metadata {
    margin: 20px 0;
    width: 225px;
  }
}
thead.resizable {
  tr {
    td {
      color: $color-gray-dark;
      &:hover {
        position: relative;
        color: $color-purple-dark;
        font-weight: normal !important;
        &:before {
          font-weight: 100;
        }
      }
      &:first-child {
        &:before {
          content: "";
        }
      }
      &:before {
        content: "ˆ";
        font-size: 0px !important;
        position: absolute;
        color: $color-purple-light !important;
        right: 10px;
        width: 20px;
        height: 20px;
        -ms-transform: rotate(180deg); /* IE 9 */
        -webkit-transform: rotate(180deg); /* Safari 3-8 */
        transform: rotate(180deg);
        top: 5px !important;
        bottom: 5px !important;
      }
    }
  }
}
.loadingModal {
  .modal__wrapper {
    text-align: center;
  }
  .spinner--double-bounce {
    margin: 0 auto !important;
    margin-top: 6% !important;
    padding: 3% !important;
  }
  .modal__overlay {
    background: hsla(0, 0%, 0%, 0.58) !important;
  }
}
.content-send .modal__overlay {
  z-index: 10000;
}
.content-send .modal__wrapper {
  z-index: 100000 !important;
}
.failure-message {
  color: $color-red-light;
  display: block;
}
.failure-message-count {
  color: $color-gray;
  display: block;
  margin-top: 10px;
  font-weight: bold;
}
.success_modal {
  & .modal__wrapper {
    padding-bottom: 15px !important;
    & .modal__wrapper__title {
      font-size: 22px !important;
      text-align: center !important;
      margin-top: -10px !important;
    }
    & .modal__wrapper__actions {
      text-align: center !important;
      padding-top: 40px !important;
    }
  }
}
.messages-noreturn {
  padding: 20px 0;
  .heading{
    line-height: 22px !important;
  }
}
#spreadsheet tbody tr td{
  overflow: hidden;
}

</style>
