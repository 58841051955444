<template>
  <div style="margin: 5vw">
    <div class="input-field">
      <div class="linearGradient">
        <input
          class="login-form-input-field"
          @keyup.enter="submitLogin()"
          ref="email"
          v-model="email"
          type="text"
          id="name"
          required
        />
        <label class="login-form-label-field" for="name">E-mail:</label>
      </div>
    </div>
    <div class="input-field">
      <div class="linearGradient">
        <input
          class="login-form-input-field"
          @keyup.enter="submitLogin()"
          v-model="password"
          type="password"
          id="password"
          required
        />
        <label class="login-form-label-field" for="password"
          >{{ $t("login.password") }}:</label
        >
      </div>
    </div>
    <div>
      <span class="loginActionLinks" @click="$root.$emit('update-current-active', 2)">Single Sign-On</span>
      <span class="loginActionLinks" @click="$root.$emit('update-current-active', 3)">{{ $t("login.reset") }}</span>
    </div>
    <button
      id="signUpButton"
      @click="submitLogin()"
    >
      {{$t('login.enter')}}
    </button>
  </div>
</template>
<script>

export default {
  name: 'LoginForm',
  data () {
    return {
      email: '',
      password: '',
      isSignOn: false
    }
  },
  mounted () {
    this.$refs.email.focus()
  },
  methods: {
    async submitLogin () {
      if (!this.email || !this.password) {
        this.$root.$emit('show-message', this.$i18n.t('login.login_error'))
        return false
      }
      this.$root.$emit('do-login', { email: this.email, password: this.password })
    }
  }
}
</script>

<style>
</style>
