<template>
  <div class="container-modal-alert">
    <div class="section-modal-alert">
      <div class="header-modal-alert">
        <p class="background-icon"><span class="exclamation-icon">!</span></p>
        <app-heading level="h6">{{ $t('resend.title_cancel_resend') }}</app-heading>
        <app-heading level="h7" class="subtitle-modal">{{ $t('resend.label_cancel_resend') }}</app-heading>
      </div>
      <div class="content-modal-alert">
        <div class="content-detail-modal-alert">
          <p class="title-item-modal">{{ $t('resend.survey') }}</p>
          <p class="label-item-modal">{{ item.title }}</p>
        </div>
        <div class="content-detail-modal-alert">
          <p class="title-item-modal">{{ $t('resend.created_at') }}</p>
          <p class="label-item-modal">{{ formatDate(item.createdAt) }}</p>
        </div>
        <div class="content-detail-modal-alert">
          <p class="title-item-modal">{{ $t('resend.scheduled_to') }}</p>
          <p class="label-item-modal">{{ formatDate(item.schedulingDate) }}</p>
        </div>
        <div class="content-detail-modal-alert send-types">
          <p class="title-item-modal">{{ $t('resend.count') }}</p>
          <p class="label-item-modal" v-if="emailSum.count">{{ formatNumber.format(emailSum.count) }} {{ $t('resend.send_for') }} <span :class="emailSum.type">{{ returnDefaultType(emailSum.type) }}</span></p>
          <p class="label-item-modal" v-if="smsSum.count">{{ formatNumber.format(smsSum.count) }} {{ $t('resend.send_for') }} <span :class="smsSum.type">{{ returnDefaultType(smsSum.type) }}</span></p>
          <p class="label-item-modal" v-if="whatsappSum.count">{{ formatNumber.format(whatsappSum.count) }} {{ $t('resend.send_for') }} <span :class="whatsappSum.type">{{ returnDefaultType(whatsappSum.type) }}</span></p>
        </div>
      </div>
      <div class="footer-modal-alert">
        <button class="btn-modal-alert" @click="confirmAction()">
          <p v-if="!loading">{{ $t('resend.confirm') }}</p>
          <Circle2 v-else class="spinner-social"/>
        </button>
        <button class="btn-modal-alert cancel" @click="closeModal()">{{ $t('resend.cancel') }}</button>
      </div>
      <div class="section-item-deleted" v-show="deleted">
        <div class="sucess-delete" v-if="!errorDelete">
          <i class="material-icons icon-check-resend-cancel">check_circle</i>
          <p class="title-item-deleted">{{ $t('resend.success_delete') }}</p>
        </div>
        <div class="error-delete" v-else>
          <i class="material-icons icon-error-resend-cancel">warning</i>
          <p class="title-item-deleted">{{ $t('resend.error_delete') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import { resendService } from '@/_services'

export default {
  name: 'ModalAlert',
  props: ['item'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      loading: false,
      deleted: false,
      errorDelete: false
    }
  },
  mounted () {
    gsap.to(document.querySelector('.section-modal-alert'), { opacity: 1, duration: 0.5 })
  },
  computed: {
    formatNumber () { return Intl.NumberFormat('pt-BR', { maximumSignificantDigits: 10 }) },
    emailSum () { return this.item.deliveryTypes.find(type => type.type === 'email') },
    smsSum () { return this.item.deliveryTypes.find(type => type.type === 'sms') },
    whatsappSum () { return this.item.deliveryTypes.find(type => type.type === 'whatsapp') }
  },
  methods: {
    formatDate (date) { return this.$moment(date).format('DD/MM/YYYY, HH:mm') },
    async confirmAction () {
      this.loading = true
      const result = await resendService.deleteScheduledResend(this.item)
      if (result.canceled) {
        this.deleted = true
        gsap.to(document.querySelector('.section-item-deleted'), {
          opacity: 1,
          duration: 0.3,
          onComplete: () => {
            this.$emit('item-canceled')
            setTimeout(() => { this.closeModal() }, 500)
          }
        })
      }
    },
    closeModal () {
      gsap.to(document.querySelector('.section-modal-alert'),
        {
          opacity: 0,
          duration: 0.4,
          onComplete: () => { setTimeout(() => { this.$emit('close-modal') }, 100) }
        }
      )
    },
    returnDefaultType (type) {
      if (type === 'email') { return this.$t('resend.type.email') }
      if (type === 'sms') { return this.$t('resend.type.sms') }
      return this.$t('resend.type.whatsapp')
    }
  }
}
</script>

<style lang='scss'>
.container-modal-alert {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background: #0000004a;
  display: flex;
  align-items: center;
  justify-content: center;
  .section-modal-alert {
    position: relative;
    opacity: 0;
    user-select: none;
    width: 25vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 5px;
    overflow: hidden;
    .header-modal-alert {
      width: 100%;
      height: 30%;
      padding: 5px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .background-icon {
        width: 35px;
        height: 35px;
        background: #ff000021;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .exclamation-icon {
          width: 1.1vw;
          height: 1.1vw;
          border: 1px solid #ef5959;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 0.9vw;
          font-weight: 500;
          color: #ef5959;
        }
      }
      h6 {
        text-align: center;
        font-weight: 500;
        color: #454548;
        font-size: 0.9vw;
        margin: 15px 0 !important;
      }
      .subtitle-modal {
        color: #858585;
        font-size: 0.75vw;
        text-align: center;
      }
    }
    .content-modal-alert {
      width: 100%;
      height: 50%;
      border-bottom: 1px solid #e1e1e1;
      border-top: 1px solid #e1e1e1;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .content-detail-modal-alert {
        width: 100%;
        height: 20%;
        border-bottom: 1px solid #f3f3f3;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .title-item-modal {
          font-size: 0.8vw;
          color: #858585;
        }
        .label-item-modal {
          font-size: 0.85vw;
          color: #454548;
          font-weight: 500;
        }
      }
      .send-types {
        height: fit-content;
        gap: 0.3rem;
      }
    }
    .footer-modal-alert {
      width: 100%;
      height: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F0F2F5;
      .btn-modal-alert {
        width: 90px;
        height: 30px;
        margin: 0 5px;
        padding: 5px 7px;
        border-radius: 3px;
        background: var(--accent-color);
        color: var(--default-text-color);
        font-weight: 500;
        font-size: 0.75vw;
        display: flex;
        align-items: center;
        justify-content: center;
        .spinner-social {
          width: 20px !important;
          height: 20px !important;
          border-width: 2px !important;
          border-color: var(--accent-color) var(--default-text-color) var(--default-text-color) !important;
        }
      }
      .cancel {
        background: #ffffff;
        color: var(--accent-color);
        border: 1px solid #cacaca;
        font-weight: 600;
      }
    }
    .section-item-deleted {
      opacity: 0;
      position: absolute;
      background: #ffffff;
      width: 100%;
      height: 100%;
      .sucess-delete, .error-delete {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .icon-check-resend-cancel {
          font-size: 3vw;
          color: #69db63;
        }
        .icon-error-resend-cancel {
          font-size: 3vw;
          color: #dbc363;
        }
        .title-item-deleted {
          font-size: 0.8vw;
          color: #454548;
        }
      }
    }
  }
}
</style>
