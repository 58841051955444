<template>
  <div>
    <Menu></Menu>
    <ComparativeDash></ComparativeDash>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import ComparativeDash from '../components/dashboard/performance/Grouper.vue'

export default {
  name: 'Performance',
  components: { Menu, ComparativeDash }
}
</script>
