import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

export const annotationsService = {
  getAnnotation,
  deleteAnnotation,
  createAnnotation
}

async function getAnnotation (id) {
  const dashUrl = API_URL + 'sendings/' + id + '/annotations'
  const result = await callApiService.callApi(dashUrl, 'get')
  return result
}

async function deleteAnnotation (sendingId, itemId) {
  const dashUrl = API_URL + 'sendings/' + sendingId + '/annotations/' + itemId
  const result = await callApiService.callApi(dashUrl, 'delete')
  return result
}

async function createAnnotation (sendingId, content) {
  const body = { content: content }
  const dashUrl = API_URL + 'sendings/' + sendingId + '/annotations/'
  const result = await callApiService.callApi(dashUrl, 'post', body)
  return result
}
