<template>
  <div>
    <div class="sidebar-export">
      <div class="loading-approaches" v-show="isLoading">
        <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
      </div>
      <div class="my-profile-header" style="height:147px;">
        <div class="profile-button close-config col-lg-12 col-md-12 col-xs-12 col-sm-12 col-xs-12">
          <button v-on:click="$emit('close-export-sidebar')">
            <app-textstyle color="purple" size="size-xl">×</app-textstyle>
          </button>
        </div>
        <div class="profile-heading" style="margin-top:0;">
          <app-heading level="h5">{{$t('export.title')}}</app-heading>
           <app-heading level="h7" style="line-height: 20px;">{{ $t('export.subtitle') }}</app-heading>
        </div>
      </div>
      <div>
        <div class="row file-types-group">
          <!-- tipo do arquivo -->
          <div class="row-content" style="text-align: center;">
            <div class="file-type-badge" @click="options.format = 'xls'" :class="{'file-type-badge-active': options.format === 'xls'}">
              <i class="material-icons mobileFilter" style="font-size: 1.1em;">text_snippet</i>
              <span style="font-size: 0.6em;color: #a2a2a2;">EXCEL</span>
            </div>
          </div>
          <div class="row-content" style="text-align: center;" v-if="this.pageToExport !== 'ranking'">
            <div class="file-type-badge" @click="options.format = 'xml'" :class="{'file-type-badge-active': options.format === 'xml'}">
              <i class="material-icons mobileFilter" style="font-size: 1.1em;">text_snippet</i>
              <span style="font-size: 0.6em;color: #a2a2a2;">XML</span>
            </div>
          </div>
          <div class="row-content" style="text-align: center;">
            <div class="file-type-badge" @click="options.format = 'csv'" :class="{'file-type-badge-active': options.format === 'csv'}">
              <i class="material-icons mobileFilter" style="font-size: 1.1em;">text_snippet</i>
              <span style="font-size: 0.6em;color: #a2a2a2;">CSV</span>
            </div>
          </div>
          <div class="row-content" style="text-align: center;" v-if="bacen.isBacen">
            <div class="file-type-badge" @click="options.format = 'bacen'" :class="{'file-type-badge-active': options.format === 'bacen'}">
              <i class="material-icons mobileFilter" style="font-size: 1.1em;">text_snippet</i>
              <span style="font-size: 0.6em;color: #a2a2a2;">BACEN</span>
            </div>
          </div>
        </div>
        <div v-if="this.pageToExport !== 'ranking'">
          <!-- selecionar timezone -->
          <div v-if="options.format !== 'bacen'" class="row mt20">
            <app-heading level="h6" style="font-size: 14px !important; padding: 15px !important;">{{ $t('export.time_zone') }}:</app-heading>
            <v-select
              class="col-lg-12 col-md-12 col-sm-6 col-xs-6"
              style="margin-top: -18px !important;"
              label="label"
              :reduce="label => label.value"
              :options="tzOptions"
              v-model="options.tz"
              :clearable="false"
              :searchable="false"
            ></v-select>
          </div>
          <!-- separar colunas por -->
          <div class="row" v-if="options.format === 'csv'">
            <app-heading level="h6" style="font-size: 14px !important; padding: 15px !important;">{{ $t('export.delimiter') }}:</app-heading>
            <v-select
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
              style="margin-top: -18px !important;"
              label="label"
              :options="separators"
              :reduce="label => label.value"
              v-model="options.delimiter"
              :clearable="false"
              :searchable="false"
            ></v-select>
          </div>
          <div class="row mt10" v-if="options.delimiter === 'CUSTOM' && options.format === 'csv'">
            <app-heading level="h6" style="font-size: 14px !important; padding: 15px !important;">{{ $t('export.custom_delimiter') }}:</app-heading>
            <app-form-input class="input-custom" secondary border-solid v-model="options.customDelimiter"></app-form-input>
          </div>
          <!-- customizar metadados a exportar -->
          <div class="row" v-if="options.format !== 'bacen'">
            <div class="col-lg-12 col-md-12 col-sm-12 adjust-firefox" v-if="pageToExport === 'sending-responses'">
              <app-toggle-input v-model="showTicketInfo"></app-toggle-input>
              <app-heading level="h6" style="margin-left: 40px;">{{ $t('export.show_ticket_info') }}</app-heading>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 adjust-firefox">
              <app-toggle-input v-model="exportWithoutFields" @change.native="clearCustom()"></app-toggle-input>
              <app-heading level="h6" style="margin-left: 40px;">{{ $t('export.without_fields') }}</app-heading>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 adjust-firefox" v-if="!exportWithoutFields">
              <app-toggle-input v-model="customizeFieldsToExport"></app-toggle-input>
              <app-heading level="h6" style="margin-left: 40px;">{{ $t('export.choose_fields') }}</app-heading>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 adjust-firefox" v-if="customizeFieldsToExport && !exportWithoutFields">
              <app-heading level="h6" style="font-size: 14px !important; margin-bottom: 5px !important;">{{ $t('export.select_fields') }}:</app-heading>
              <v-select multiple v-model="wantedFields" :options="fieldsOptions"/>
            </div>
          </div>
          <div v-if="options.format === 'bacen'">
            <app-heading level="h6" style="font-size: 14px !important; margin: 25px 15px !important;">{{ $t('export.subtitle_bacen') }}</app-heading>
            <div class="bacen-content">
              <div>
                <app-heading level="h6" style="font-size: 14px !important; margin: 25px 10px !important;">{{ $t('export.mounth') }}</app-heading>
                <v-select
                  class="mounth-selector"
                  label="label"
                  :options="bacen.mounth"
                  v-model="bacen.selectedMounth"
                ></v-select>
              </div>
              <div>
                <app-heading level="h6" style="font-size: 14px !important; margin: 25px 10px !important;">{{ $t('export.year') }}</app-heading>
                <app-form-input
                class="input-custom"
                secondary border-solid
                v-model="bacen.year"
                maxLength="4"></app-form-input>
              </div>
            </div>
            <div class="select-metadata-cpf" style="padding: 0 10px">
              <app-heading level="h6" style="font-size: 14px !important; margin: 15px 0 !important;">{{ $t('export.choose_field_metadata') }}</app-heading>
              <v-select
                class="mounth-selector"
                label="label"
                :placeholder="$t('sendConfig.select')"
                :reduce="name => name.key"
                :options="fieldsOptionsToBacen"
                v-model="keyCpfSelected"
              ></v-select>
            </div>
            <app-button class="save-profile" v-if="!hideExports" style="margin-top: 15px !important;" variation="primary" @click="exportBacen()">{{ $t('export.title') }}</app-button>
          </div>
        </div>
        <app-button v-if="options.format !== 'bacen' && !hideExports" class="save-profile" style="margin-bottom: 20px !important;" variation="primary" @click="exportFile()">{{ $t('export.title') }}</app-button>
      </div>
      <theme-style>
        .my-profile-header {
          background-color: {{ account.account.theme.colors.primary || '#673ab7' }} !important;
        }
        .my-profile-header .heading,
        .my-profile-header .close-config button span {
          color:{{ (account.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
        }
      </theme-style>
    </div>
    <div class="close-sidebar" v-on:click="$emit('close-export-sidebar')"></div>
  </div>
</template>
<script>
import { exportService, userService } from '@/_services'
import { formatters } from '@/_helpers'
import { mapGetters } from 'vuex'
import { writeFile, utils } from 'xlsx'
import metricsHelper from '@/_helpers/metrics'
import mixins from '@/_mixins/ranking'

export default {
  name: 'ExportSending',
  props: ['pageToExport', 'rankingResults'],
  mixins: [mixins],
  components: {
    vSelect: () => import('vue-select'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  data () {
    return {
      showTicketInfo: true,
      isLoading: false,
      separators: [
        { label: this.$i18n.t('export.separators.comma'), value: ',' },
        { label: this.$i18n.t('export.separators.semicolon'), value: ';' },
        { label: this.$i18n.t('export.separators.tab'), value: 'TAB' },
        { label: this.$i18n.t('export.separators.custom'), value: 'CUSTOM' }
      ],
      tzOptions: [
        { label: this.$i18n.t('export.dates_local'), value: 'local' },
        { label: this.$i18n.t('export.dates_utc'), value: 'utc' }
      ],
      options: {
        tz: 'local',
        format: 'xls',
        delimiter: ',',
        customDelimiter: ','
      },
      bacen: {
        isBacen: false,
        year: '',
        selectedMounth: '',
        mounth: [
          { label: this.$i18n.t('export.mounth_selected.jan'), value: '01' },
          { label: this.$i18n.t('export.mounth_selected.feb'), value: '02' },
          { label: this.$i18n.t('export.mounth_selected.mar'), value: '03' },
          { label: this.$i18n.t('export.mounth_selected.apr'), value: '04' },
          { label: this.$i18n.t('export.mounth_selected.may'), value: '05' },
          { label: this.$i18n.t('export.mounth_selected.jun'), value: '06' },
          { label: this.$i18n.t('export.mounth_selected.jul'), value: '07' },
          { label: this.$i18n.t('export.mounth_selected.aug'), value: '08' },
          { label: this.$i18n.t('export.mounth_selected.sep'), value: '09' },
          { label: this.$i18n.t('export.mounth_selected.oct'), value: '10' },
          { label: this.$i18n.t('export.mounth_selected.nov'), value: '11' },
          { label: this.$i18n.t('export.mounth_selected.dec'), value: '12' }
        ]
      },
      wantedFields: [],
      fieldsOptions: [],
      fieldsOptionsToBacen: [],
      customizeFieldsToExport: false,
      exportWithoutFields: false,
      keyCpfSelected: null,
      questionSelected: {}
    }
  },
  async mounted () {
    const date = new Date()
    this.isLoading = true
    this.bacen.year = date.getFullYear()
    const currentMonthIndex = this.bacen.mounth.findIndex(month => Number(month.value) === date.getMonth())
    this.bacen.selectedMounth = this.bacen.mounth[currentMonthIndex]
    const user = await userService.getMe()
    this.bacen.isBacen = this.currentSurvey?.isBacenCompatible
    if (user.account.fields.length > 0) {
      const fields = user.account.fields
      fields.map(item => {
        this.fieldsOptions.push(item.key)
        this.fieldsOptionsToBacen.push(item)
      })
    }
    this.isLoading = false
  },
  computed: {
    currentSurvey () { return this.$store.getters['dashboard/getCurrentSurvey'] },
    account () { return this.$store.getters['account/getUser'] },
    hideExports () { return this.user.features.hideExports },
    ...mapGetters({
      sendingFromNotification: 'providers/getSendingOnQuery',
      user: 'account/getUser'
    })
  },
  methods: {
    /**
     * Create the structure of the header based on the ranking results.
     * @param {Object} data - Item to be formatted.
     * @returns {Object} - Formatted header structure.
    */
    createHeaderStructure (data = {}) {
      const { metadata, type, ui } = this.rankingResults
      this.questionSelected.type = type
      this.questionSelected.ui = ui

      const positionRelative = data?.relativePosition === 0 ? '-' : data.relativePosition
      let labelOfMetric = type.toUpperCase()

      switch (type) {
        case 'enum':
          labelOfMetric = ui === '10num' ? this.$t('ranking.quantity') : this.$t('ranking.average')
          break
        case 'health':
          labelOfMetric = this.$t('health_score.title_card_score')
          break
      }

      const formatData = {
        [this.$i18n.t('ranking.position')]: data?.position,
        [this.$i18n.t('ranking.positionVariation')]: positionRelative,
        [metadata]: data?.metadata,
        [labelOfMetric]: data?.score,
        [`${this.$i18n.t('ranking.scoreVariation')} - ${labelOfMetric}`]: this.formatToExcel(this.formatVariation(data?.scoreVariation))
      }

      this.getMostVotedOptionsAsLabels().forEach(header => {
        formatData[`-- ${header.label} --`] = data?.scoreDetailed?.[header.key] || 0
      })

      formatData[this.$i18n.t('ranking.responses')] = data.responses
      return formatData
    },

    /**
     * Get the most voted options as labels, or if it is a metric, return the top 5 metrics.
     * @returns {Array} - Array of most voted options or top 5 metrics.
    */
    getMostVotedOptionsAsLabels () {
      const { type, ui } = this.rankingResults
      const ranking = this.rankingResults?.ranking

      if (type === 'enum' && ranking) {
        const allOptions = ranking.flatMap(aggregated => Object.keys(aggregated.scoreDetailed)) // get all the options of the enum type, like single or 10num
        return allOptions.map(label => ({ label, key: label })).slice(0, 5) // only return the top 5 most voted options
      }

      const metrics = type && ui ? metricsHelper[type][ui] : []
      return Object.keys(metrics).map(metric => ({ ...metricsHelper[type][ui][metric], key: metric }))
    },
    /**
     * Perform the export process for the ranking page.
     * @returns {Promise} - Promise that resolves when export is completed.
    */
    exportAsRanking () {
      this.isLoading = true
      const { ranking, name } = this.rankingResults
      const headersToSetAsColumns = this.createHeaderStructure()
      const workbook = utils.book_new()
      const worksheet = utils.json_to_sheet([headersToSetAsColumns])
      utils.book_append_sheet(workbook, worksheet, 'Ranking')

      const formattedData = ranking.map(data => this.createHeaderStructure(data))

      const totalColumns = Object.keys(headersToSetAsColumns).length
      const columnWidths = Array(totalColumns).fill({ wch: 20 }) // Set the column widths in characters (e.g., 20 characters)
      worksheet['!cols'] = columnWidths

      utils.sheet_add_json(worksheet, formattedData, { skipHeader: false })

      const regexPattern = /\s+/g
      const fileName = `ranking_${String(name).replace(regexPattern, '_')}_${Date.now()}`

      if (this.options.format === 'csv') writeFile(workbook, `${fileName}.csv`)
      if (this.options.format === 'xls') writeFile(workbook, `${fileName}.xlsx`)

      this.isLoading = false
      const message = this.$i18n.t('export.download')
      return this.$store.commit('alerts/alert', {
        message,
        position: 'bottomRight',
        showAlert: true
      })
    },
    async exportFile () {
      const useNewSheetExport = this.account.account.features.newSheetsExport
      const isCustom = this.options.delimiter === 'CUSTOM'
      const delimiter = isCustom ? this.options.customDelimiter : this.options.delimiter
      const tzOffset = this.options.tz === 'local' ? new Date().getTimezoneOffset().toString() : ''
      let params = ''
      const exportParams = JSON.parse(JSON.stringify(this.$route.query))
      let query = formatters.formatDateByAddTimezone(exportParams.q, 3, 'hours')
      if (this.sendingFromNotification) {
        query = formatters.insertSendingIdOnQuery(query, this.sendingFromNotification)
      }

      if (this.pageToExport === 'sendings') {
        const selectedType = this.$store.getters['approaches/getSelectedType']
        query = formatters.insertArchivedAtOnQueryIfNotExists(query, selectedType)
      }

      exportParams.q = query
      exportParams.delimiter = delimiter
      exportParams.browserDate = (new Date()).toISOString()
      exportParams.tzOffset = tzOffset
      exportParams.extension = this.options.format

      if (this.showTicketInfo) { exportParams.showTicketInfo = this.showTicketInfo }

      if (this.exportWithoutFields) { exportParams.wantedFields = undefined }

      if (this.customizeFieldsToExport && !this.exportWithoutFields) { exportParams.wantedFields = this.wantedFields }

      Object.keys(exportParams).forEach((key, index) => {
        params += `${index === 0 ? '?' : '&'}${key}=${exportParams[key]}`
      })

      if (this.pageToExport === 'ranking') {
        return this.exportAsRanking()
      }

      if (this.pageToExport === 'insights') {
        const paramsToExport = {
          wantedFields: this.wantedFields,
          fileExtension: this.options.format,
          tzOffset,
          delimiter,
          ...this.$route.query
        }

        const result = await exportService.exportTextInsights(paramsToExport)

        if (result) {
          return this.$store.commit('alerts/alert', {
            message: this.$i18n.t('export.download_on_email'),
            position: 'bottomRight',
            showAlert: true
          })
        }
        return
      }

      const url = `${process.env.VUE_APP_URL}${this.pageToExport}.${this.options.format}${params}`
      let message = this.$i18n.t('export.download_on_email')
      let permission = { isToExportOnBrowser: true }
      this.isLoading = true
      if (this.pageToExport === 'sending-responses' && useNewSheetExport) {
        permission = await exportService.isToExportOnBrowser(params)
      }
      if (permission.isToExportOnBrowser) {
        message = this.$i18n.t('export.download')
        const signedUrl = await exportService.signWithRedirectRoute({ url })

        if (signedUrl) { window.location.assign(signedUrl.url) }
      }
      this.isLoading = false
      return this.$store.commit('alerts/alert', {
        message,
        position: 'bottomRight',
        showAlert: true
      })
    },
    async exportBacen () {
      this.isLoading = true
      let url = `${process.env.VUE_APP_URL}surveys/${this.currentSurvey._id}/bacen.xml?month=${this.bacen.selectedMounth.value}&year=${this.bacen.year}`

      if (this.keyCpfSelected) {
        url = `${url}&cpfClienteField=metadata.${this.keyCpfSelected}`
      }

      const bacenUrl = await exportService.signWithRedirectRoute({ url: url })

      this.isLoading = false
      if (bacenUrl) {
        window.location.assign(bacenUrl.url)
      }
    },
    clearCustom () {
      this.customizeFieldsToExport = false
      this.wantedFields = []
    }
  }
}
</script>

<style lang="scss">
.adjust-firefox {
  display: grid;
}

.file-type-badge:hover, .file-type-badge-active  {
  border-color: #ff607d !important;
  cursor: pointer;
  & .material-icons {
    color: #ff607d !important;
  }
  & span {
    color: #ff607d !important;
  }
}
.file-type-badge {
  border: 4px solid transparent;
  display: inline-grid;
  text-align: center;
  padding: 10px 14px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
}
.pass-user-input{
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px 0;
    border: 0;
    outline: 0;
    font-family: Open Sans,Helvetica,Arial,sans-serif;
    font-size: 16px;
    border-bottom: 1px solid #838383;
    font-weight: 600;
    color: #64469d;
    background: transparent;
}
.close-side-bar-export {
  position: absolute;
  right: 7px;
  span {
    font-size: 20px;
    color: #fff
  }
}
.footer-sidebar-export {
  margin-top: 30px;
  display: flex;
  text-align: right;
  justify-content: space-between;
  .binds-button:last-child{
    margin-bottom:  auto;
  }
}

.close-sidebar {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 998;
}
.sidebar-export {
  overflow-y: auto;
  background-color:#fff;
  position: fixed;
  top: 0;
  z-index: 9999;
  right: 0;
  height: 100vh;
  width: 460px;
  box-sizing: border-box;
  box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
  .bacen-content {
    display: flex;
    height: 60px;
    div {
      width: 35%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .mounth-selector {
        width: 70%;
        .vs__dropdown-toggle {
          width: 100%;
          .vs__actions {
            height: 30px;
            color: var(--primary-color) !important;
          }
          .vs__search, .vs__clear {
            display: none;
          }
        }
      }
      .input-custom {
        margin: 0;
        width: 90% !important;
        height: 60%;
        .text-field__input {
          width: 90% !important;
          height: 100%;
        }
      }
    }
  }
}
.file-types-group {
  justify-content: space-around;
  -webkit-box-shadow: 0 5px 7px 0 rgba(48,48,48,.16), 0 5px 4px 0 rgba(0,0,0,.01);
  box-shadow: 0 5px 7px 0 rgba(48,48,48,.16), 0 5px 4px 0 rgba(0,0,0,.01);
  background-color: #3f3356 !important;
  .row-content {
    padding: 0.5rem;
  }
}
.input-custom {
  margin-top: 8px;
  & .text-field__input--border-solid {
    border: 1px solid #b5b5b5 !important;
  }
  .text-field__input {
    width: 245px !important;
    input {
      padding: 5px !important;
      font-size: 14px !important;
      font-weight: 600 !important;
    }
  }
}

</style>
