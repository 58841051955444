const state = emptyTemplate()

function emptyTemplate () {
  return {
    width: 400,
    timeout: 0,
    metric: 'px',
    height: '',
    widgetPosition: 'top-right',
    textButtons: '#ffffff',
    background: '#1d2d44',
    buttons: '#3e5c76',
    texts: '#fff',
    widgetTitle: 'feedback',
    site: 'https://binds.co',
    closeButton: true,
    closePermanently: false,
    responseBackground: '#f0ebd8',
    hiddeAfterAnswer: 'false',
    hiddeWhenClickToClose: 'false',
    actionConfig: '{"background":"transparent", "startOpen":"true", "iconName":"https://binds.co/open-graph.png", "iconType":"externalUrl", "closeActionButton":"true"}',
    responseFontColor: '#0a0a0a',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: 'large',
    textProps: "{'bold':'false','italic':'false','underline':'false'}",
    borderRadius: 0,
    sitePreview: 'https://binds.co/'
  }
}

const mutations = {
  resetToInitialState (state) {
    const s = emptyTemplate()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  add (state, payload) {
    Object
      .keys(payload)
      .forEach(key => {
        const value = payload[key]
        state[key] = value
      })
  },
  widgetObject (stte, payload) {
    payload = state
  }
}

const getters = {
  getWidget: state => state
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
