<template>
  <div>
    <div class="row center-lg center-md center-sm comment mt30" v-if="question.referral">
      <div class="col-lg-8 col-md-8 col-sm-8" v-if="question.referral.fields.name.active">
        <app-form-input secondary border-solid v-model="inputValue" :placeholder="question.referral.fields.name.placeholder"></app-form-input>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-8" v-if="question.referral.fields.email.active">
        <app-form-input secondary border-solid v-model="inputValue" :placeholder="question.referral.fields.email.placeholder"></app-form-input>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-8" v-if="question.referral.fields.phone.active">
        <app-form-input secondary border-solid v-model="inputValue" :placeholder="question.referral.fields.phone.placeholder"></app-form-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'appreferral',
  props: ['question'],
  components: {
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input')
  },
  data () {
    return {
      inputValue: ''
    }
  }
}
</script>
