<template>
  <div class="container-survey-card">
    <div class="loading-card-survey" :ref="`loading-survey-${cardSurvey._id}`" v-show="loadingCardSurvey">
      <Circle2 class="spinner-loading-card-survey"/>
    </div>
    <div class="infos-survey-card" @click="editSurvey(cardSurvey, $event)">
      <p class="title-survey-card title-survey">{{ cardSurvey.title }}</p>
      <p class="title-survey-card">
        {{ $t('surveyList.created_since') }}:
        <span>{{ formatDate(cardSurvey.createdAt, 'lll') }}</span>
      </p>
      <p class="title-survey-card">
        {{ $t('surveyList.last_send') }}:
        <span>{{ cardSurvey.lastSendAt ? formatDate(cardSurvey.lastSendAt, 'lll') : $t('surveyList.not_send') }}</span>
      </p>
      <p class="title-survey-card" v-title="$t('surveyList.copy_id')" title-placement="right">
        <i class="material-icons icon-hash-survey-list">tag</i>
        <span :class="`id-question-${cardSurvey._id}`">{{ cardSurvey._id }}</span>
      </p>
    </div>
    <div class="status-survey-card" @click="editSurvey(cardSurvey, $event)">
      <div class="square-status-survey-card">
        <p class="label-status-survey-card" :ref="`total-sending-${cardSurvey._id}`">{{ totalSending }}</p>
        <p class="title-status-survey-card">{{ $t('analytics.sendings') }}</p>
      </div>
      <div class="square-status-survey-card">
        <p class="label-status-survey-card" :ref="`total-answers-${cardSurvey._id}`">{{ totalAnswers }}</p>
        <p class="title-status-survey-card">{{ $t('analytics.answers') }}</p>
      </div>
      <div class="square-status-survey-card">
        <p class="label-status-survey-card" :ref="`total-response-time-${cardSurvey._id}`">{{ totalAverageTime }} <span>min</span></p>
        <p class="title-status-survey-card">{{ $t('analytics.time') }}</p>
      </div>
      <div class="square-status-survey-card" :style="{'cursor': cardSurvey.stats.health ? 'pointer' : 'not-allowed'}">
        <div class="heart-survey-list" @click="isRedirect('Overview', cardSurvey?.stats?.health)">
          <i class="material-icons icon-heart-survey-list" :class="`icon-health-${cardSurvey._id}`">favorite</i>
          <p class="label-heart-survey-list" :ref="`total-health-${cardSurvey._id}`" v-title="$t('surveyList.health_survey')" title-placement="bottom"></p>
        </div>
      </div>
    </div>
    <div class="buttons-survey-card">
      <div class="square-button-survey-card" :class="{'button-disabled': !hasInsight}" @click="goToAnalysis(cardSurvey._id)">
        <i class="material-symbols-outlined icon-button-survey-list">memory</i>
        <p class="title-button-survey-card">{{ 'Insights' }}</p>
      </div>
      <div class="square-button-survey-card"
          :class="{'button-disabled': isButtonDisabled(canVisualizeDashboards)}"
          @click="isRedirect('Individual', canVisualizeDashboards)">
        <i class="material-icons-outlined icon-button-survey-list">insert_chart</i>
        <p class="title-button-survey-card">{{ $t('dashboard.individual_responses') }}</p>
      </div>
      <div class="square-button-survey-card" :class="{'button-disabled': !allowedProfile || cardSurvey.isExpired}" @click="editSurvey(cardSurvey, $event)">
        <i class="material-symbols-outlined icon-button-survey-list">edit_document</i>
        <p class="title-button-survey-card">{{ $t('dashboard_card.edit') }}</p>
      </div>
      <div class="square-button-survey-card"
          :class="{'button-disabled': isButtonDisabled(canCreateOrCloneSurveys)}"
          @click="canCreateOrCloneSurveys ? modalCloneSurvey = true : ''">
        <i class="material-icons-outlined icon-button-survey-list">file_copy</i>
        <p class="title-button-survey-card">{{ $t('dashboard_card.clone') }}</p>
      </div>
      <div class="square-button-survey-card" :class="{'button-disabled': !allowedProfile}" @click="isRedirect('Overview', allowedProfile)">
        <i class="material-symbols-outlined icon-button-survey-list">smart_display</i>
        <p class="title-button-survey-card">{{ $t('dashboard_card.tv_dash') }}</p>
      </div>
      <div class="square-button-survey-card" :class="{'button-disabled': !allowedProfile}" @click="allowedProfile ? modalDeleteSurvey = true : ''">
        <i class="material-symbols-outlined icon-button-survey-list">delete</i>
        <p class="title-button-survey-card">{{ $t('dashboard_card.delete') }}</p>
      </div>
      <div class="square-button-survey-card" :class="{'button-disabled': !allowedProfile}" v-if="!cardSurvey.isExpired && !cardSurvey.expiredAt" @click="allowedProfile ? modalExpireSurvey = true : ''">
        <i class="material-symbols-outlined icon-button-survey-list">timer</i>
        <p class="title-button-survey-card">{{ $t('surveyList.expire_survey') }}</p>
      </div>
      <div class="square-button-survey-card" v-if="cardSurvey.expiredAt && !cardSurvey.isExpired" @click="allowedProfile ? modalExpireSurvey = true : ''" v-title="$t('surveyList.expire_in')">
        <i class="material-symbols-outlined icon-button-survey-list">timer</i>
        <p class="title-button-survey-card">{{ formatDate(cardSurvey.expiredAt, 'lll', true) }}</p>
      </div>
      <div class="square-button-survey-card" style="cursor: pointer;" :class="{'button-disabled': cardSurvey.isExpired}" v-if="cardSurvey.expiredAt && cardSurvey.isExpired" @click="allowedProfile ? modalExpireSurvey = true : ''" v-title="$t('surveyList.survey_expired')">
        <i class="material-symbols-outlined icon-button-survey-list">timer_off</i>
        <p class="title-button-survey-card">{{ $t('surveyList.survey_expired') }}</p>
      </div>
      <div class="square-button-survey-card" :class="{'button-disabled': cardSurvey.isExpired}" v-title="cardSurvey.isExpired ? $t('surveyList.survey_expired') : ''">
        <i class="material-icons icon-button-survey-list" @click="sendSurvey()">send</i>
        <p class="title-button-survey-card">{{ $t('seeds.send') }}</p>
      </div>
    </div>

    <!-- MODAL DELETE SURVEY -->
    <app-modal
      class="modal-default-primary confirm-modal-survey"
      v-model="modalDeleteSurvey"
      style="z-index: 99;"
      :title="$t('surveyList.delete_survey')"
      lightbox
      close
      :primary-button="$t('notifications.cancel')"
      @primaryButtonClick="modalDeleteSurvey = false"
      :secondary-button="$t('approaches.delete')"
      @secondaryButtonClick="deleteSurvey()"
    >
      {{ $t("surveyList.delete_question") }}

      <div class="loading-card-survey" v-show="loadingModal">
        <Circle2 class="spinner-loading-card-survey"/>
      </div>
    </app-modal>

    <!-- MODAL CLONE SURVEY -->
    <app-modal
      class="modal-default-primary confirm-modal-survey"
      v-model="modalCloneSurvey"
      :title="$t('surveyList.clone_survey')"
      lightbox
      close
      :primary-button="$t('notifications.cancel')"
      @primaryButtonClick="modalCloneSurvey = false"
      :secondary-button="$t('surveyList.clone_survey')"
      @secondaryButtonClick="cloneSurvey()"
    >
      <app-form-input
        v-model="nameSurveyToDuplicate"
        :placeholder="$t('start.survey_title_placeholder')"
        secondary
        autofocus
        v-on:keyup.enter="cloneSurvey()"
        v-on:keyup.esc="modalCloneSurvey = false"
      >
      </app-form-input>
      <div class="loading-card-survey" v-show="loadingModal">
        <Circle2 class="spinner-loading-card-survey"/>
      </div>
    </app-modal>

    <!-- MODAL EXPIRE SURVEY -->
    <app-modal
      :title="cardSurvey.isExpired || cardSurvey.expiredAt ? $t('surveyList.activate_survey') : $t('surveyList.select_date')"
      class="modal-default-primary confirm-modal"
      style="text-align: center;"
      v-model="modalExpireSurvey"
      lightbox
      close
    >
      <div class="row" id="expire-survey-date-picker">
        <div class="col-lg-6 col-md-6 col-sm-6" v-if="!cardSurvey.isExpired && !cardSurvey.expiredAt">
          <v-date-picker
          :min-date='new Date()'
          :locale="$i18n.locale"
          :masks='{inputDateTime24hr: "D MMMM YYYY, H:mm"}'
          v-model="dateToExpireSurvey"
          color="gray" mode="dateTime" is24hr>
            <template v-slot="{ inputValue, inputEvents }">
              <div class="icon-container">
                <i class="material-icons">event</i>
                <input v-on="inputEvents"
                  class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  inputValue readonly :placeholder="dateToExpireSurvey" :value="inputValue"
                />
              </div>
            </template>
          </v-date-picker>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6  btn">
            <app-button style="width: 100%;" class="btn-default-primary" @click="expireOrActivateSurvey()">
              {{ $t('survey.confirm')}}
            </app-button>
        </div>
      </div>
      <div class="loading-card-survey" v-show="loadingModal">
        <Circle2 class="spinner-loading-card-survey"/>
      </div>
    </app-modal>
  </div>
</template>

<script>
import gsap from 'gsap'
import { sendhubService, surveyService } from '@/_services'
import healthMixins from '@/_mixins/health'
import userMixins from '@/_mixins/user'

export default {
  name: 'CardSurvey',
  props: ['dataSurvey'],
  mixins: [healthMixins, userMixins],
  components: {
    Circle2: () => import('vue-loading-spinner/src/components/Circle2'),
    VDatePicker: () => import('v-calendar/lib/components/date-picker.umd'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input')
  },
  data () {
    return {
      loadingCardSurvey: false,
      loadingModal: false,
      cardSurvey: null,
      totalSending: null,
      totalAnswers: null,
      totalAverageTime: null,
      modalDeleteSurvey: false,
      modalCloneSurvey: false,
      modalExpireSurvey: false,
      nameSurveyToDuplicate: '',
      dateToExpireSurvey: this.$i18n.t('activity.today')
    }
  },
  created () {
    this.cardSurvey = this.dataSurvey
    this.totalSending = this.cardSurvey.totalSendings
    this.totalAnswers = this.cardSurvey.totalAnswers
    this.totalAverageTime = this.cardSurvey.responseAverageTime
  },
  mounted () {
    this.animateNumber('total-sending')
    this.animateNumber('total-answers')

    if (this.cardSurvey.stats.health) {
      this.animateNumber('total-health')
    }
  },
  computed: {
    lang () { return this.$store.getters['account/getLang'] },
    user () { return this.$store.getters['account/getUser'] },
    allowedProfile () { return ['superadmin', 'admin'].includes(this.user.type) || this.user.features.surveyEdit },
    hasInsight () { return this.user.features.insights && this.cardSurvey.isAllowedToAIAnalysis && this.user.type !== 'user' }
  },
  methods: {
    isButtonDisabled (boolToValidate) {
      return !boolToValidate
    },
    sendSurvey () {
      if (this.cardSurvey.isExpired) return

      return this.$router.push({ name: 'Send', params: { id: this.cardSurvey._id } })
    },
    formatDate (date, type, add) {
      const hour = add ? 3 : 0
      this.$moment.locale(this.lang)
      return this.$moment(date).add(hour, 'hours').format(type)
    },
    async editSurvey (survey, event) {
      if (!this.allowedProfile) return

      if (event) {
        const clickInId = document.querySelector(`.id-question-${survey._id}`).contains(event.target)

        if (clickInId) {
          const copyText = document.querySelector(`.id-question-${survey._id}`).innerHTML
          await navigator.clipboard.writeText(copyText)

          return this.$store.commit('alerts/alert', {
            message: this.$i18n.t('surveyList.id_copied'),
            position: 'bottomRight',
            showAlert: true,
            persist: false
          })
        }
      }

      if (survey.isExpired) return

      const surveyId = survey._id

      if (this.user.type === 'user' && !this.user.features.surveyEdit) {
        const collector = await sendhubService.collectors()
        const isAgreedSend = collector.find(o => o.survey === surveyId)

        if (isAgreedSend && this.user.features.seedBatches) {
          return this.$router.push({ name: 'Surveys', params: { id: surveyId } })
        }

        return this.$router.push({ name: 'YouCant' })
      }

      return this.$router.push({ name: 'Surveys', params: { id: surveyId } })
    },
    animateNumber (classId) {
      const label = this.$refs[`${classId}-${this.cardSurvey._id}`]

      if (!label) return

      label.innerHTML = 0

      let thisValue = null

      if (classId === 'total-sending') { thisValue = this.totalSending }
      if (classId === 'total-answers') { thisValue = this.totalAnswers }

      if (classId === 'total-health') { thisValue = this.cardSurvey.stats.health }

      gsap.to(label, {
        duration: 1,
        innerHTML: thisValue,
        snap: 'innerHTML',
        ease: 'power1.inOut',
        onUpdate: () => {
          label.innerHTML = Number(label.innerHTML).toLocaleString('de-DE')

          if (classId === 'total-health') { this.checkHealthSurvey() }
        }
      })
    },
    checkHealthSurvey () {
      const iconHeart = document.querySelector(`.icon-health-${this.cardSurvey._id}`)

      if (!iconHeart) return

      if (this.cardSurvey.stats.health <= 15) {
        gsap.to(iconHeart, {
          scale: 1.02,
          duration: 0.4,
          repeat: -1,
          yoyo: true,
          ease: 'power1.inOut'
        })
      }

      const colorDynamic = this.getColorHeartSurvey(this.cardSurvey.stats.health)

      iconHeart.style.color = colorDynamic
      iconHeart.style.filter = `drop-shadow(0 0 2px ${colorDynamic})`
    },
    goToAnalysis (surveyId) {
      if (!this.hasInsight) return

      this.$store.commit('filtersInsight/insightField', { surveyId })
      return this.$router.push({ name: 'Insights' })
    },
    deleteSurvey () {
      this.loadingCardSurvey = true
      this.modalDeleteSurvey = false
      return this.$emit('delete-survey', this.cardSurvey._id)
    },
    isRedirect (toPage, allowed) {
      if (!allowed) return
      return this.$emit('to-redirect', { location: toPage, surveyId: this.cardSurvey._id })
    },
    cloneSurvey () {
      if (!this.nameSurveyToDuplicate.length) {
        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('surveyList.type_title'),
          position: 'bottomRight',
          showAlert: true
        })
      }
      this.loadingModal = true
      this.modalCloneSurvey = false
      return this.$emit('to-duplicate', { surveyId: this.cardSurvey._id, name: this.nameSurveyToDuplicate.trim() })
    },
    async expireOrActivateSurvey () {
      this.loadingModal = true
      this.setLoading()

      try {
        const isToExpire = !this.cardSurvey.isExpired && !this.cardSurvey.expiredAt
        const parsedDateToSend = this.dateToExpireSurvey === this.$i18n.t('activity.today') ? new Date() : this.dateToExpireSurvey

        const result = await surveyService.expireOrActivateSurvey(this.cardSurvey._id, isToExpire ? this.$moment(parsedDateToSend).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '')
        if (result) {
          this.cardSurvey.isExpired = result.isExpired
          this.cardSurvey.expiredAt = result.expiredAt

          return this.$store.commit('alerts/alert', {
            message: isToExpire ? this.$i18n.t('surveyList.success_expire') : this.$i18n.t('surveyList.success_activate'),
            position: 'bottomRight',
            showAlert: true
          })
        }
      } catch (error) {
        console.error('Error', error)
      } finally {
        this.modalExpireSurvey = false
        this.loadingModal = false
        this.setLoading(true)
      }
    },
    setLoading (close) {
      if (!close) { this.loadingCardSurvey = true }

      const divLoading = this.$refs[`loading-survey-${this.cardSurvey._id}`]
      gsap.to(divLoading, { duration: 0.5, onComplete: () => { if (close) { this.loadingCardSurvey = false } } })
    }
  }
}
</script>

<style lang="scss" scoped>
#expire-survey-date-picker {
  justify-content: center;
  input {
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 5px;
    background-color: #fff;
    padding: 16px !important;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0 3px 6px rgba(0,0,0,0.1);
  }
  .icon-container {
    display: flex;
    align-items: center;
  }
  .material-icons {
    padding: 5px;
    position: absolute;
  }
}

.loading-card-survey {
  background: #ffffff;
  position: absolute;
  border-radius: 15px;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner-loading-card-survey {
    width: 2.1vw !important;
    height: 2.1vw !important;
    border-width: 3px !important;
    border-radius: 35px;
    border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
  }
}

.container-survey-card {
  position: relative;
  height: 7.8vw;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 15px;
  box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
  padding: 0.8vw;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  .infos-survey-card {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title-survey-card {
      width: fit-content;
      font-size: 0.75vw;
      font-weight: 500;
      color: #454548;
      display: flex;
      align-items: center;
      gap: 0.3vw;
      .icon-hash-survey-list {
        font-size: 1vw;
        color: #5e5e5e;
      }
      span {
        font-weight: 400;
      }
    }
    .title-survey {
      font-weight: 600;
      font-size: 1.05vw;
      line-height: 1.4vw;
      width: 100%;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .status-survey-card {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .square-status-survey-card {
      color: #454548;
      min-width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1vw;
      .label-status-survey-card {
        font-size: 1.1vw;
        font-weight: 700;
        span {
          font-weight: 500;
          font-size: 0.85vw;
        }
      }
      .title-status-survey-card {
        font-size: 0.75vw;
      }
      .heart-survey-list {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-heart-survey-list {
          font-size: 3.5vw;
          color: #f1f1f1;
          filter: drop-shadow(0 0 2px #f1f1f1);
        }
        .label-heart-survey-list {
          position: absolute;
          color: #ffffff;
          font-weight: 700;
          font-size: 1.2vw;
          top: 1vw;
        }
      }
    }
  }
  .buttons-survey-card {
    user-select: none;
    cursor: default;
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .square-button-survey-card {
      cursor: pointer;
      height: 3.2vw;
      color: #454548;
      min-width: 9%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .icon-button-survey-list {
        font-size: 1.5vw;
      }
      .title-button-survey-card {
        font-size: 0.75vw;
        text-align: center;
      }
    }
    .square-button-survey-card:hover {
      color: var(--accent-color);
      transform: scale(1.01);
    }
    .button-disabled {
      color: #e1e1e1;
      cursor: not-allowed;
    }
    .button-disabled:hover {
      color: #e1e1e1;
      transform: scale(1);
    }
  }
}

</style>
