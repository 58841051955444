<template>
  <div class="container-bar-horiz-health">
    <canvas :id="makeSpecialId" width="250"></canvas>
  </div>
</template>

<script>
import mixins from '@/_mixins/health'
import { canvasMaker, constants } from '@/_helpers'
import('chart.js').then((chartjs) => { window.Chart = chartjs.default })

export default {
  name: 'BarHorizHealth',
  props: ['dataHealth', 'cardData'],
  mixins: [mixins],
  components: {},
  data () {
    return {
      chartHealth: null,
      colorsScore: ['#57AD26', '#9FCD35', '#F8C43D', '#EF874C', '#EA484D'],
      colorsChurn: ['#EA484D', '#EF874C', '#F8C43D', '#9FCD35', '#57AD26'],
      dataFormatted: [],
      labels: [
        constants.health.churn.VERY_HIGH,
        constants.health.churn.HIGH,
        constants.health.churn.MID,
        constants.health.churn.LOW,
        constants.health.churn.VERY_LOW
      ]
    }
  },
  mounted () {
    this.formatDataHealth()
  },
  computed: {
    makeSpecialId () { return canvasMaker.makeCanvasIdWithoutParam() }
  },
  methods: {
    getQueryHealth (payload) { return this.mountQueryToRedirect(payload) },
    formatDataHealth () {
      if (!Object.keys(this.dataHealth.overall).length) return

      Object.keys(this.dataHealth.overall).forEach((key, idx) => {
        const item = this.dataHealth.overall[key]
        this.dataFormatted.push({ name: this.labels[idx], ...item })
      })

      this.renderChartHealth()
    },
    scaleBar (value) { return value * 1.2 },
    returnPercentage (idx) { return this.dataFormatted[idx].percentage.toFixed(1) },
    async renderChartHealth () {
      const fontSize = 18
      const fontFamily = "Avenir', Helvetica, Arial, sans-serif;"
      const backgroundColor = this.dataHealth.type === 'score' ? this.colorsScore : this.colorsChurn
      const data = []
      const self = this

      this.dataFormatted.forEach(item => { data.push(item.doc_count) })

      const planetChartData = {
        type: 'horizontalBar',
        data: {
          labels: this.labels,
          datasets: [
            {
              data: data,
              backgroundColor: backgroundColor,
              borderWidth: 2
            }
          ]
        },
        options: {
          indexAxis: 'y',
          maintainAspectRatio: false,
          responsive: true,
          layout: {
            padding: {
              left: 0,
              right: 20,
              top: 0,
              bottom: 0
            }
          },
          tooltips: {
            titleFontFamily: fontFamily,
            titleFontSize: fontSize,
            position: 'average',
            titleMarginBottom: 1,
            callbacks: {
              title: tooltipItem => {
                let sumTexts = ''
                const indexOfLabel = tooltipItem[0].index
                this.labels[indexOfLabel].split(' ').forEach((split, index) => {
                  if (index % 4 === 0) {
                    sumTexts += `${split}`
                  } else {
                    sumTexts += ` ${split}`
                  }
                })
                return sumTexts
              },
              label: context => {
                return `${context.value} - ${this.returnPercentage(context.index)}%`
              },
              footer: () => {
                return [`${this.$t('real_time.see_at_responses')}`]
              }
            }
          },
          scales: {
            xAxes: [{
              ticks: {
                beginAtZero: true,
                precision: 0,
                suggestedMax: this.scaleBar(Math.max(...data))
              }
            }],
            yAxes: [{
              ticks: {
                fontSize: fontSize,
                fontFamily: fontFamily
              }
            }]
          },
          legend: {
            display: false
          },
          onClick: function (event) {
            const item = this.getElementAtEvent(event)
            const type = self.dataHealth.type

            if (!item) return

            const itemLabel = self.labels[item[0]._index]
            const clickedItem = { label: itemLabel, idx: item[0]._index, type }

            const queryFormatted = self.getQueryHealth(clickedItem)

            self.$root.$emit('redirect-responses-health', queryFormatted)
          }
        }
      }

      const ctx = document.getElementById(this.makeSpecialId)

      this.chartHealth = await new window.Chart(ctx, {
        type: planetChartData.type,
        data: planetChartData.data,
        options: planetChartData.options,
        plugins: [{
          beforeInit: chart => {
            chart.data.labels.forEach((e, i, a) => {
              if (/\n/.test(e)) {
                a[i] = e.split(/\n/)
              }
            })
          },
          afterDatasetsDraw: chart => {
            const ctx = chart.ctx
            ctx.textAlign = 'left'
            ctx.font = '12' + 'px Arial'
            ctx.textBaseline = 'bottom'

            chart.config.data.datasets.forEach(dataset => {
              const data = dataset
              this.labels.forEach((o, i) => {
                const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model
                ctx.fillStyle = model.backgroundColor
                const label = `${data.data[i]} - ${this.returnPercentage(i)}%`
                ctx.fillText(label, model.x + 2, model.y + 6)
              })
            })
          }
        }]
      })
      return this.chartHealth
    }
  }
}
</script>

<style lang="scss" scoped>
.container-bar-horiz-health {
  width: 100%;
  height: 100%;
}
</style>
