import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

export const rankingService = {
  getRanking,
  getRankingOvertime,
  getSurveyStats,
  getRankingHealthOvertime
}

async function getRanking (query) {
  const hasQuestion = query.question ? `&question=${query.question}` : ''
  const rankingUrl = `${API_URL}ranking-best-rated?groupBy=${query.groupBy}&minSample=${query.minSample}&q=${query.q}&rankBy=${query.rankBy}${hasQuestion}`
  const formattedUrl = encodeURI(rankingUrl)
  return callApiService.callApi(formattedUrl, 'get')
}

async function getRankingOvertime (query) {
  const overtimeUrl = `${API_URL}sending-responses-overtime?tzOffset=180&cleanEmptyQuestions=${query.cleanEmptyQuestions}&grid=${query.grid}&q=${query.q}`
  const formattedUrl = encodeURI(overtimeUrl)
  return callApiService.callApi(formattedUrl, 'get')
}

async function getRankingHealthOvertime (query) {
  const overtimeUrl = `${API_URL}health-score/overtime?tzOffset=180&cleanEmptyQuestions=${query.cleanEmptyQuestions}&grid=${query.grid}&q=${query.q}`
  const formattedUrl = encodeURI(overtimeUrl)
  return callApiService.callApi(formattedUrl, 'get')
}

async function getSurveyStats (surveyId, filters) {
  let statsUrl = `${API_URL}survey/${surveyId}/stats`

  if (filters) { statsUrl = `${API_URL}survey/${surveyId}/stats?q=${filters}` }

  return callApiService.callApi(statsUrl, 'get')
}
