<template>
  <div class="container-new-card-sidebar">
    <div class="content-new-card-sidebar">
      <header class="header-new-card-sidebar">
        <i class="material-icons close-sidebar-icon" @click="closeOrOpenSidebar(true)">cancel</i>
        <h5 class="header-title-new-card-sidebar">{{ $t('dashboard.create_new_card') }}</h5>
      </header>
      <div class="section-custom-new-card-sidebar">
        <div class="option-custom-new-card">
          <p class="title-to-options">{{ $t('dashboard.choose_title') }}:</p>
          <div class="content-option-new-card">
            <div class="input-text">
              <InputText :disabled="false" id="title-new-custom-card" :placeholderInput="$t('survey_store.input_textarea')" :value="title" @update-model="title = $event" />
            </div>
          </div>
        </div>
        <div class="option-custom-new-card">
          <p class="title-to-options">{{ $t('dashboard.filter_advanced') }}:</p>
          <button class="btn-add-filter-card" @click="filterIsOpen = !filterIsOpen" v-title="$t('dashboard.add_filter_advanced')" title-placement="left">
              <i class="material-icons icon-open-filter-advanced">filter_alt</i>
              <i class="material-icons icon-check-filter-advanced" v-if="query">check</i>
            </button>
        </div>
        <div class="option-custom-new-card" v-if="!card?.isHealth">
          <p class="title-to-options">{{ $t('dashboard.graphics.change_question') }}</p>
          <app-select-binds
            :options="validQuestions"
            titleItem="question"
            id="current-question"
            :showIndex="true"
            :value="currentQuestion"
            @model="currentQuestion = $event"
            @input="getCharts()"
            style="width: 100%; min-height: 2rem;"
          />
        </div>
        <div class="option-custom-new-card" v-if="currentQuestion.type && currentQuestion.type !== 'text'">
          <p class="title-to-options">{{ $t('dashboard.graphics.options') }}</p>
          <app-select-binds
            :options="charts"
            titleItem="name"
            id="current-chart"
            :value="chart"
            @model="chart = $event"
            @input="createColorPalette()"
            style="width: 100%; min-height: 2rem;"
          />
        </div>
        <div class="option-custom-new-card" v-else>
          <p class="title-to-options">{{ $t('dashboard.graphics.options') }}</p>
          <app-select-binds
            :options="healthCharts"
            titleItem="name"
            id="current-chart"
            :value="chart"
            @model="chart = $event"
            @input="createColorPalette()"
            style="width: 100%; min-height: 2rem;"
          />
        </div>
        <div class="option-custom-new-card" v-if="chart.graphicType === 'horizontalBar' && currentQuestion.type.toLowerCase() === 'nps'">
          <p class="title-to-options" v-if="currentQuestion.type !== 'text'">{{ $t('dashboard.graphics.choose_display_graphics') }}</p>
          <app-select-binds
            v-if="currentQuestion.type !== 'text'"
            :options="npsDisplayingTypes"
            titleItem="label"
            id="current-chart"
            :value="selectedNpsType"
            @model="selectedNpsType = $event"
            @input="createColorPalette()"
            style="width: 100%; min-height: 2rem;"
          />
        </div>
        <div class="option-custom-new-card" v-if="handlerColorOptions">
          <p class="title-to-options">{{ $t('dashboard.graphics.choose_color') }}</p>
          <input class="main-color" type="color" v-model="mainColor" @input="createColorPalette()" />
        </div>
        <div class="option-custom-new-card" v-if="isValidColors">
          <p class="title-to-options">{{ $t('dashboard.graphics.generated_palette') }}</p>
          <div class="palette-container">
            <div class="my-palette" v-for="(color, index) in colorPalettePreview" :key="index">
              <input class="palette" type="color" v-model="colorPalettePreview[index]" />
            </div>
          </div>
        </div>
        <button class="btn-create-new-card" @click="createCard()">{{ $t('dashboard.create_card') }}</button>
      </div>
      <div class="content-loading-new-card-custom" v-show="isLoading">
        <Circle2 class="spinner-social-new-card"/>
      </div>
    </div>
    <FilterModal v-if="filterIsOpen" :queryCard="query" @close="filterIsOpen = false" entity="customDashboard" @query-to-custom-card="myQuery($event)" @remove-filter-dash-card="query = null" />
  </div>
</template>

<script>
import { dashboardsService } from '@/_services'
import { responseOptions } from '@/_helpers'
import InputText from '../../inputs/InputText.vue'
import gsap from 'gsap'
import('color-scheme').then((ColorScheme) => { window.ColorScheme = ColorScheme.default })
import('color').then((Color) => { window.Color = Color.default })

export default {
  name: 'NewCardCustom',
  props: ['card'],
  components: {
    InputText,
    FilterModal: () => import('@/components/filters/FilterModal.vue'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2'),
    'app-select-binds': () => import('@binds-tech/binds-design-system/src/components/Select/SelectBinds')
  },
  data () {
    return {
      isLoading: false,
      currentQuestion: this.$i18n.t('dashboard.choose_question'),
      charts: [],
      healthCharts: [
        { attribute: 'int', graphicType: 'emoteGauge', name: this.$t('dashboard.graphics.gauge'), viewType: 'average' },
        { attribute: 'multiple', graphicType: 'line', name: this.$t('dashboard.graphics.lines'), viewType: 'overtime' },
        { attribute: 'int', graphicType: 'horizontalBar', name: this.$t('dashboard.graphics.horizontal_bar'), viewType: 'overall' }
      ],
      title: '',
      query: null,
      filterIsOpen: false,
      chart: this.$i18n.t('dashboard.graphics.choose'),
      mainColor: '#805ea0',
      textColor: '#fff',
      colorPalettePreview: [
        '#805ea0', '#966fbc', '#ae74e8', '#b97bf7', '#ce9eff',
        '#805ea0', '#966fbc', '#ae74e8', '#b97bf7', '#ce9eff'
      ],
      hugeColorListForLines: [
        '#805ea0', '#966fbc', '#ae74e8', '#b97bf7', '#ce9eff', '#3366cc', '#dc3912',
        '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e',
        '#316395', '#3366cc', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300',
        '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620',
        '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413',
        '#0c5922', '#743411'
      ],
      validQuestions: [],
      npsDisplayingTypes: [
        { label: this.$i18n.t('dashboard.graphics.by_metric'), value: 'metric' },
        { label: this.$i18n.t('dashboard.graphics.by_responses'), value: 'responses' }
      ],
      selectedNpsType: '',
      optionsLength: 0
    }
  },
  beforeDestroy () {
    this.$store.commit('filters/updateEntity', 'dashboard')
  },
  created () {
    this.$store.commit('filters/updateEntity', 'customDashboard')
  },
  mounted () {
    this.closeOrOpenSidebar()

    if (this.card?.isHealth) return

    if (this.card !== undefined) { this.currentQuestion = this.card.question; this.getCharts() }

    if (!this.selectedNpsType) { this.selectedNpsType = this.npsDisplayingTypes[0] }

    this.questions.map(item => {
      if (item.type !== 'referral') { this.validQuestions.push(item) }
    })
  },
  computed: {
    questions () { return this.$store.getters['dashboard/getCurrentSurvey'].questions },
    currentSurveyId () { return this.$store.getters['dashboard/getCurrentSurvey']._id },
    handlerColorOptions () {
      const isUnsupportedGraphicType = ['emoteGauge', 'rangeGauge', 'likeOvertime'].includes(this.chart?.graphicType)
      const isTextOrEnumQuestion = this.currentQuestion.type === 'text' || this.currentQuestion.type === 'enum'
      const isLikeOverallWithUiLike = this.chart?.graphicType === 'likeOverall' && this.currentQuestion.ui === 'like'

      return this.chart?.graphicType &&
        !isUnsupportedGraphicType &&
        !isTextOrEnumQuestion &&
        !this.card?.isHealth &&
        !isLikeOverallWithUiLike
    },
    isValidColors () {
      const isNotTextOrEnumQuestion = this.currentQuestion.type !== 'text' && this.currentQuestion.type !== 'enum'
      const hasColorPalette = this.colorPalettePreview.length > 0
      const isMatrixWithSpecificGraphicType =
        this.currentQuestion.type === 'matrix' &&
        (this.chart?.graphicType === 'likeOverall' || this.chart?.graphicType === 'stackedBar')

      const isGeneralValidCase = this.chart.graphicType && isNotTextOrEnumQuestion && hasColorPalette && !this.card?.isHealth
      const isMatrixValidCase = isMatrixWithSpecificGraphicType && !this.card?.isHealth

      return isGeneralValidCase || isMatrixValidCase
    }
  },
  methods: {
    myQuery (query) {
      if (Array.isArray(query.$and) && query.$and.length > 0) {
        const newQuery = query.$and.map(condition => {
          return Object.keys(condition).length > 0 ? condition : null
        })

        const formattedObject = newQuery.filter(obj => obj !== null)
        this.query = JSON.stringify(formattedObject)
      }
    },
    async createCard () {
      const divLoading = document.querySelector('.content-loading-new-card-custom')
      gsap.to(divLoading, {
        opacity: 1,
        duration: 0.5,
        onComplete: async () => {
          const settings = {
            graphicType: this.currentQuestion.type !== 'text' ? this.chart.graphicType : 'text',
            attribute: this.currentQuestion.type !== 'text' ? this.chart.attribute : 'text',
            viewType: this.currentQuestion.type !== 'text' ? this.chart.viewType : 'average',
            colors: this.chart.graphicType !== 'line' ? this.colorPalettePreview : this.hugeColorListForLines,
            textColor: this.textColor || '#ffffff',
            question: this.currentQuestion._id,
            survey: this.currentSurveyId,
            title: this.title.trim(),
            query: this.query,
            npsType: this.selectedNpsType.value
          }

          if (settings.graphicType && settings.question) {
            this.isLoading = true
            const save = await dashboardsService.createCustomizedCard(settings)
            if (save) {
              this.closeOrOpenSidebar(true)
              this.$emit('update-dashboard-custom')
              this.$store.commit('alerts/alert', {
                message: this.$i18n.t('dashboard.success_save_config'),
                position: 'bottomRight',
                showAlert: true
              })
            } else {
              this.$store.commit('alerts/alert', {
                message: this.$i18n.t('dashboard.error_save_config'),
                position: 'bottomRight',
                showAlert: true
              })
            }
          } else {
            this.$store.commit('alerts/alert', {
              message: this.$i18n.t('dashboard.missing_question_and_graphic'),
              position: 'bottomRight',
              showAlert: true
            })
          }
        }
      })
    },
    closeOrOpenSidebar (close) {
      const container = document.querySelector('.container-new-card-sidebar')
      const sidebar = document.querySelector('.content-new-card-sidebar')
      const duration = 0.5
      const opacity = close ? 0 : 1
      const width = close ? '0vw' : '30vw'
      gsap.to(container, { opacity, duration })
      gsap.to(sidebar, { width, duration, onComplete: () => { if (close) { this.$emit('close-sidebar-edit') } } })
    },
    getCharts () {
      this.charts = []
      this.chart = this.$i18n.t('dashboard.graphics.choose')
      this.optionsLength = responseOptions.getOptions(this.currentQuestion).length
      if (this.currentQuestion.type !== 'text') {
        const questionType = this.currentQuestion.type.toLowerCase()
        const questionUi = this.currentQuestion.ui.toLowerCase()
        const graphicsLists = this.$store.getters['dashboard/getGraphicTypes']
        this.charts = graphicsLists.find(list => list.type === questionType && list.ui === questionUi).charts
        this.selectedNpsType = this.selectedNpsType ? this.selectedNpsType : this.$i18n.t('dashboard.graphics.choose_display')
      }
    },
    async createColorPalette () {
      if (['emoteGauge', 'rangeGauge'].indexOf(this.chart.graphicType) > -1) {
        this.colorPalettePreview = ['#EA484D', '#EF874C', '#F8C43D', '#9FCD35', '#57AD26']
      } else {
        this.colorPalettePreview = []

        const colorsAux = this.generateColors()

        // Limite por index, contando que o array já tem uma cor, que é a escolhida como principal
        const limitByGraphic = {
          line: { limit: this.optionsLength },
          emoteGauge: { limit: 5 },
          rangeGauge: { limit: 4 },
          horizontalBar: { limit: (this.selectedNpsType.value === 'metric' && this.currentQuestion.type.toLowerCase() === 'nps') ? 3 : this.optionsLength },
          verticalBar: { limit: this.optionsLength },
          pie: { limit: this.optionsLength },
          percentageBar: { limit: 5 },
          kpiYnd: { limit: 3 },
          kpiYn: { limit: 2 },
          stackedBar: { limit: 11 },
          likeOverall: { limit: 11 },
          likeOvertime: { limit: 2 }
        }

        const key = this.chart.graphicType

        if (key === 'stackedBar' && this.currentQuestion.ui === 'matrix1to5') { limitByGraphic.stackedBar.limit = 5 }

        colorsAux.map((color, index) => {
          if (index < limitByGraphic[key].limit) {
            this.colorPalettePreview.push(color)
          }
        })

        if (key === 'likeOvertime') {
          this.colorPalettePreview = [colorsAux[0]]
        }

        if (key === 'likeOvertime' && this.currentQuestion.ui === 'like') {
          this.colorPalettePreview = [colorsAux[0], colorsAux[8]]
        }

        if (key === 'likeOverall' && this.currentQuestion.ui === 'like') {
          this.colorPalettePreview = ['#ea4922', '#51ad59']
        }
      }
    },
    generateColors () {
      const baseColorRgb = this.hexToRgb(this.mainColor)
      const step = 11
      const increment = 1 / step
      const generatedColors = []

      for (let i = 0; i < step; i++) {
        const newColor = this.rgbToHex(
          Math.round(baseColorRgb.r + (255 - baseColorRgb.r) * (i * increment)),
          Math.round(baseColorRgb.g + (255 - baseColorRgb.g) * (i * increment)),
          Math.round(baseColorRgb.b + (255 - baseColorRgb.b) * (i * increment))
        )
        generatedColors.push(newColor)
      }

      return generatedColors
    },
    hexToRgb (hex) {
      hex = hex.replace(/^#/, '')

      const bigint = parseInt(hex, 16)
      const r = (bigint >> 16) & 255
      const g = (bigint >> 8) & 255
      const b = bigint & 255

      return { r, g, b }
    },
    rgbToHex (r, g, b) {
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
    }
  }
}
</script>

<style lang="scss" scoped>
.container-new-card-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  background: #00000025;
  width: 100vw;
  height: 100vh;
  z-index: 36;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .content-new-card-sidebar {
    position: relative;
    background: #ffffff;
    width: 0vw;
    height: 100vh;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem 0 0 0.5rem;
    border-left: 1px solid #cacaca;
    overflow: hidden;
    .header-new-card-sidebar {
      position: relative;
      width: 100%;
      height: 10%;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .header-title-new-card-sidebar {
        color: #454548;
        font-size: 1.2vw;
      }
      .close-sidebar-icon {
        position: absolute;
        top: 0.2rem;
        right: 0.3rem;
        color: var(--accent-color);
        font-size: 1.4vw;
        cursor: pointer;
      }
    }
    .section-custom-new-card-sidebar {
      width: 100%;
      height: 90%;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 1rem;
      .option-custom-new-card {
        width: 100%;
        border: 1px solid #cacaca;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
        padding: 0.3rem;
        border-radius: 0.3rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .title-to-options {
          font-size: 0.8vw;
          color: var(--accent-color);
          font-weight: 500;
        }
        .main-color {
          height: 2.1vw;
          width: 2.1vw;
          display: flex;
          border: none;
          background-color: transparent;
          cursor: pointer;
        }
        .palette-container {
          display: flex;
          gap: 5px;
          .my-palette {
            .palette {
              height: 2.1vw;
              width: 2.1vw;
              display: flex;
              border: none;
              background-color: transparent;
              cursor: pointer;
            }
          }
        }
        .content-option-new-card {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.5rem;
          .input-text {
            width: 100%;
          }
        }
        .btn-add-filter-card {
          width: fit-content;
          height: 2rem;
          border: 1px solid var(--accent-color);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          border-radius: 0.3rem;
          padding: 0 0.2rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          .icon-open-filter-advanced {
            font-size: 1.3vw;
            color: var(--accent-color);
          }
          .icon-check-filter-advanced {
            background: #15c315;
            border-radius: 50%;
            color: #ffffff;
            font-size: 0.9vw;
            padding: 0.1rem;
            font-weight: bold;
          }
        }
      }
      .btn-create-new-card {
        background: var(--accent-color);
        width: 5.5vw;
        height: 2vw;
        font-size: 0.75vw;
        border-radius: 0.3rem;
        color: var(--default-text-color);
        font-weight: bold;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      }
      .btn-create-new-card:hover {

      }
    }
    .content-loading-new-card-custom {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      height: 100%;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      .spinner-social-new-card {
        width: 2.1vw !important;
        height: 2.1vw !important;
        border-width: 3px !important;
        border-radius: 35px;
        border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
      }
    }
  }
}
</style>
