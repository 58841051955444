<template>
  <div>
    <Menu></Menu>
    <SubMenu :individual="true"></SubMenu>
    <div v-if="!loadIndividualPage && !isToHideStats" class="row content-individual-stats">
      <app-card class="overview-card" style="padding: 0px !important;overflow:inherit">
        <div class="overview-card-row">
          <div class="dashboard-content-stats">
              <tweenedNumber :label="$t('analytics.answers')" :number="statsUpdated.totalAnswers"></tweenedNumber>
              <tweenedNumber :label="$t('analytics.time')" :number="statsUpdated.responseAverageTime" formula="min"></tweenedNumber>
          </div>
          <div class="dashboard-content-options">
            <div class="options2-dash">
              <div class="button-action-individual" v-if="!hideExports" @click="openSidebarExportSending = !openSidebarExportSending; pageToExport = 'sending-responses'">
                <i class="material-icons icon-action-individual">cloud_download</i>
                  <app-textstyle
                    color="gray"
                    size="size-xxs"
                  >{{ $t('export.title') }}</app-textstyle>
              </div>
              <div class="button-action-individual" v-if="showButtonResend && validateButtonResend" @click="openModalResend = true">
                <i class="material-icons icon-action-individual">send</i>
                  <app-textstyle
                    color="gray"
                    size="size-xxs"
                  >{{ $t('resend.resend') }}</app-textstyle>
              </div>
            </div>
          </div>
        </div>
      </app-card>
    </div>
    <export-sending v-if="openSidebarExportSending" :pageToExport="pageToExport" @close-export-sidebar="openSidebarExportSending = false"></export-sending>
    <IndividualList @show-btn-resend-individual="showButtonResend = true" />
    <NoDataToShow v-if="!selectedSurveyId && canShowThePage" title="dashboard.no_responses" />
    <ModalResend v-if="openModalResend" @close-modal-resend="closeModalResend()" />
    <div class="data-loading-individual-page" v-if="loadIndividualPage">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
  </div>
</template>

<script>

import Store from '@/store/'
import { dashboardsService } from '@/_services'
import { formatters } from '@/_helpers'
import { mapGetters } from 'vuex'
import gsap from 'gsap'

import Menu from '../components/Menu.vue'
import SubMenu from '../components/dashboard/menu/Grouper.vue'
import IndividualList from '../components/dashboard/individual/IndividualList.vue'
import NoDataToShow from '../components/alerts/NoDataToShow.vue'
import ExportSending from '../components/SideBarConfig/ExportSending.vue'
import tweenedNumber from '../components/animations/TweenedNumber.vue'
import ModalResend from '../components/resend/ModalResend.vue'

export default {
  name: 'IndividualPage',
  components: {
    Menu,
    SubMenu,
    IndividualList,
    ModalResend,
    NoDataToShow,
    ExportSending,
    tweenedNumber,
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  data () {
    return {
      loadIndividualPage: false,
      openModalResend: false,
      showButtonResend: false,
      canShowThePage: false,
      openSidebarExportSending: false,
      statsUpdated: {
        totalAnswers: 0,
        responseAverageTime: 0
      },
      isToHideStats: true
    }
  },
  beforeRouteEnter (to, from, next) {
    const user = Store.getters['account/getUser']
    if (!user.features.sendings) {
      return next({ name: 'YouCant' })
    }
    next(vm => {
      if (from.path !== '/') {
        if (from.name !== to.name && (['Performance', 'Approaches', 'Insights'].indexOf(from.name) > -1)) {
          vm.$store.commit('filters/resetAllFilters')
          vm.$router.push({ path: to.path, query: { q: JSON.stringify({ $and: [] }) } }).catch(() => {})
        }
      }
    })
  },
  beforeDestroy () {
    this.$root.$off('filter-updated')
  },
  created () {
    this.$store.commit('providers/UPDATE_PROVIDER', { key: 'sendingIdOnQuery', value: this.$route.query.sending })
  },
  mounted () {
    this.loadIndividualPage = true

    this.$root.$on('search-menu-is-done', () => {
      this.canShowThePage = true
    })
    this.$root.$on('filter-updated', () => { // quando mounted já acontece uma vez esse bloco
      this.updateStats()
      this.showButtonResend = false
    })
    this.$root.$on('close-export-sidebar', () => {
      this.openSidebarExportSending = false
    })
    this.$root.$on('hide-stats', (isToHide) => {
      this.isToHideStats = isToHide
    })
  },
  computed: {
    ...mapGetters({
      currentSurvey: 'dashboard/getCurrentSurvey',
      selectedSurveyId: 'dashboard/getSelectedSurveyId',
      user: 'account/getUser',
      account: 'account/getAccount',
      sendingFromNotification: 'providers/getSendingOnQuery'
    }),
    validateButtonResend () { return this.account.features.allowResendSending && this.user.features.seedBatches },
    hideExports () { return this.user.features.hideExports }
  },
  methods: {
    async updateStats () {
      const surveyId = this.$store.getters['dashboard/getSelectedSurveyId']
      if (surveyId) {
        let query = formatters.formatDateByAddTimezone(this.$route.query.q, 3, 'hours')
        if (this.sendingFromNotification) {
          query = formatters.insertSendingIdOnQuery(query, this.sendingFromNotification)
        }
        const result = await dashboardsService.getSurveyStats(surveyId, query)
        this.statsUpdated = result
      } else {
        this.isToHideStats = true
      }

      this.loadIndividualPage = false
    },
    closeModalResend () {
      const modal = document.querySelector('.container-modal-resend')
      gsap.to(modal, {
        opacity: 0,
        duration: 0.3,
        onComplete: () => { this.openModalResend = false }
      })
    }
  }
}
</script>

<style lang="scss">
.data-loading-individual-page {
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffff50;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-individual-stats {
  .overview-card {
    width: 93% !important;
    .dashboard-content-options {
      display: flex;
      justify-content: flex-end;
      .options2-dash {
        box-sizing: border-box;
        margin-right: 20px;
        display: flex;
        justify-content: flex-end;
        gap: 2rem;
        .button-action-individual {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          .icon-action-individual {
            color: #808080;
            font-size: 1.4vw;
          }
        }
      }
    }
  }
}
</style>
