<template>
  <div class="container-gauge-health">
    <div class="section-gauge-health">
      <GaugeHeart :containerBackground="'f6f4f7'" :id="`${myData._id}`" :dataValue="valueHealth" :color="'ea484d'" />
    </div>
    <div class="content-labels-gauge-health">
      <div class="section-labels-gauge-health">
        <img src="../../../../public//img/pulse.gif" class="gif-pulse-health" :ref="`gif-pulse-${myData._id}`">
        <div class="content-result-gauge-health" :ref="`result-gauge-${myData._id}`">
          <span class="icon-sentiment-health">{{ returnIcon }}</span>
          <p class="text-label-value-health" :ref="`text-value-${myData._id}`"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import GaugeHeart from './GaugeHeart.vue'
import mixins from '@/_mixins/health'

export default {
  name: 'GaugeHealth',
  props: ['card'],
  mixins: [mixins],
  components: {
    GaugeHeart
  },
  data () {
    return {
      valueHealth: null,
      currentRotation: 0,
      renderLabel: true,
      myData: null
    }
  },
  created () {
    this.valueHealth = this.card.average.score

    if (this.card) { this.myData = this.card }
  },
  mounted () {
    this.animatePulse()
  },
  computed: {
    returnIcon () { return this.getIconHealth(this.valueHealth) }
  },
  methods: {
    animateText () {
      const divElementText = this.$refs[`result-gauge-${this.myData._id}`]
      const textElement = this.$refs[`text-value-${this.myData._id}`]

      if (!textElement || !divElementText) return

      const text = this.getLabelScore(this.valueHealth)

      textElement.innerHTML = text.split('').map(char => {
        return `<span class="span-label-health">${char === ' ' ? '&nbsp;' : char}</span>`
      }).join('')

      const chars = textElement.querySelectorAll('.span-label-health')

      gsap.set(divElementText, { display: 'flex' })

      gsap.set(chars, { opacity: 0 })

      gsap.to(chars, {
        opacity: 1,
        duration: 0.1,
        stagger: 0.1,
        ease: 'none'
      })
    },
    animatePulse () {
      const pulseGif = this.$refs[`gif-pulse-${this.myData._id}`]

      gsap.to(pulseGif, {
        display: 'none',
        opacity: 0,
        duration: 0.5,
        delay: 1.5,
        onComplete: () => {
          this.animateText()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container-gauge-health {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .section-gauge-health {
    width: 100%;
    height: 80%;
    padding-bottom: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content-labels-gauge-health {
    width: 100%;
    height: 20%;
    .section-labels-gauge-health {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      .gif-pulse-health {
        height: 2vw;
        width: 25%;
        opacity: 1;
        filter: grayscale(1);
      }
      .content-result-gauge-health {
        position: relative;
        background: #ffffff;
        width: 75%;
        height: 2vw;
        border-radius: 1.5vw 0.4vw 0.4vw 1.5vw;
        border: 1px solid #e1e1e1;
        display: none;
        align-items: center;
        justify-content: center;
        .icon-sentiment-health {
          font-family: icomoon;
          position: absolute;
          left: 0.25vw;
          top: 50%;
          transform: translateY(-50%);
          background: #ffffff;
          color: #808080;
          font-weight: 600;
          border-radius: 50%;
          font-size: 1.2vw;
        }
        .text-label-value-health {
          font-size: 0.9vw;
          font-weight: 500;
          color: #808080;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
