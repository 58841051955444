const moment = require('moment')
const periods = {
  day: { unit: 'hour', qty: 24 },
  week: { unit: 'day', qty: 7 },
  month: { unit: 'day', qty: 30 },
  semester: { unit: 'month', qty: 6 },
  year: { unit: 'month', qty: 12 }
}

export const gridMaker = {
  getGrid,
  getDashboardGrid,
  getDataFromQuery,
  checkIfPeriodIsByDay,
  getGridWithouAddTimeZone
}

function getDataFromQuery (query) {
  let data = []
  typeof query === 'string' ? data = JSON.parse(query).$and : data = query.$and
  const filteredData = data.filter(item => item.respondedAt)
  const timeFromQuery = Object.assign({}, ...filteredData.map(x => x.respondedAt))
  return timeFromQuery
}

function getGrid (start, end) {
  let addedTimezoneStart = moment(start).add(3, 'hours').toISOString()
  let addedTimezoneEnd = moment(end).endOf('day').toISOString()
  let overtimePeriod = getPeriod(addedTimezoneStart, addedTimezoneEnd)

  if (overtimePeriod.period.unit === 'hour') {
    addedTimezoneStart = moment(start).add(3, 'hours').toISOString()
    addedTimezoneEnd = moment(end).add(3, 'hours').toISOString()
    overtimePeriod = getPeriod(addedTimezoneStart, addedTimezoneEnd)
  }

  var unit = overtimePeriod.period.unit
  end = overtimePeriod.end
  start = overtimePeriod.period.unit === 'hour' ? overtimePeriod.start : overtimePeriod.start.startOf(unit)
  var qty = overtimePeriod.period.qty
  var step = start.clone()
  return getDateGrid(unit, qty, start, end, step)
}

function getGridWithouAddTimeZone (start, end) {
  const addedTimezoneStart = moment(start).toISOString()
  const addedTimezoneEnd = moment(end).toISOString()
  const overtimePeriod = getPeriod(addedTimezoneStart, addedTimezoneEnd)

  var unit = overtimePeriod.period.unit
  end = overtimePeriod.end
  start = overtimePeriod.period.unit === 'hour' ? overtimePeriod.start : overtimePeriod.start.startOf(unit)
  var qty = overtimePeriod.period.qty
  var step = start.clone()
  return getDateGrid(unit, qty, start, end, step)
}

function getDateGrid (unit, qty, start, end, step) {
  var grid = []
  while (step < end) {
    grid.push((new Date(step.valueOf())).valueOf())
    step = step.add(1, unit).startOf(unit)
  }
  grid.push((new Date(end)).valueOf())
  return grid
}

function getDashboardGrid (period) {
  var now = moment(new Date())
  var unit = periods[period].unit
  var qty = periods[period].qty
  var end = now.clone()
  var start = moment(end.clone().subtract(qty, unit).valueOf())
  var step = start.clone()
  return getDateGrid(unit, qty, start, end, step)
}

function getPeriod (start, end) {
  var now = moment(new Date())
  start = start ? moment(start) : now.clone().subtract(1, 'year').add(1, 'month')
  end = end ? moment(end) : now.clone()
  var unit = 'year'
  var startUnit = 'month'
  var monthAdded = start.clone().endOf('day').add(1, 'month')
  if (monthAdded >= end) {
    unit = 'month'
    startUnit = 'day'
  }
  var dayAdded = start.clone().endOf('day').add(1, 'day')
  if (dayAdded >= end) {
    unit = 'day'
    startUnit = 'hour'
  }

  start = start.clone().startOf(startUnit)
  end = end.clone().add(1, startUnit).startOf(startUnit)
  var result = {
    start: start,
    end: end,
    period: periods[unit]
  }
  return result
}

function checkIfPeriodIsByDayOnTextDashboards (query) {
  let data = []
  typeof query === 'string' ? data = JSON.parse(query).$and : data = query.$and
  const filteredData = data.filter(item => item.realTimeDate)
  if (filteredData.length > 0) {
    return periods[filteredData[0].realTimeDate].unit === 'hour'
  }
  return false
}

function isByDay (start, end) {
  const now = moment(new Date())
  end = end ? moment(end) : now.clone()
  const newStart = moment(start)
  const dayAdded = newStart.endOf('day').add(2, 'day')
  return dayAdded >= end
}

function checkIfPeriodIsByDay (pageName, query) {
  if (pageName === 'Overview') {
    return isByDay(getDataFromQuery(query).$gte, getDataFromQuery(query).$lte)
  }
  if (pageName === 'Social') {
    return isByDay(query.$gte, query.$lte)
  }
  return checkIfPeriodIsByDayOnTextDashboards(query)
}
