<template>
  <div :class="{ 'referral-parent': !showMessageNoReferrals }">
    <div class="data-loading-referrals" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <NoDataToShow v-if="showMessageNoReferrals" title="dashboard.no_referrals" />
    <div class="referral-container">
      <app-card class="referral-card hvr-float-shadow" v-for="(item, index) in referralsList" v-bind:key="index">
        <div class="row">
          <div class="infos" v-if="item.dynamicFields === 'empty'">
            <app-heading level="h3" class="ellipsis ellipsis-sending dark-text">{{ item.name }}</app-heading>
            <app-heading level="h6" class="ellipsis ellipsis-sending gray-text">{{ item.email }}</app-heading>
            <app-heading level="h7" class="ellipsis ellipsis-sending gray-text">{{ item.phone }}</app-heading>

            <app-heading level="h3" class="ellipsis ellipsis-sending dark-text mt20">{{ $t('dashboard.referred_by') }}:</app-heading>
            <app-heading level="h6" class="ellipsis ellipsis-sending gray-text">{{ item.referredBy }}</app-heading>
          </div>
          <div v-else>
            <div v-for="(dynamic, index) in item.dynamicFields" v-bind:key="index">
              <app-heading level="h6" class="ellipsis ellipsis-sending gray-text">{{ dynamic }}</app-heading>
            </div>
          </div>
          <div class="details-button">
            <app-button variation="primary" @click="openDetails(item.sending)">
              {{ $t('survey.details') }}
            </app-button>
          </div>
        </div>
      </app-card>
    </div>
    <Details
      v-if="showDetails"
      :id="sendingId"
      @closeSendings="closeSidebar"
      :class="{'expand-details': showDetails}"
    ></Details>
    <div class="loading-circle-referrals" v-show="loadingMore">
      <Circle2 class="circle"></Circle2>
    </div>
  </div>
</template>

<script>
import { dashboardsService } from '@/_services'
import { formatters } from '@/_helpers'
import NoDataToShow from '../../alerts/NoDataToShow.vue'

export default {
  name: 'Referrals',
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2'),
    Details: () => import('../../SideBarConfig/SidebarSendings.vue'),
    NoDataToShow
  },
  data () {
    return {
      referralsList: [],
      loading: false,
      loadingMore: false,
      contentLength: '',
      sendingId: '',
      showDetails: false,
      showMessageNoReferrals: false,
      // Set first range's limit. I wanna bring 20 items per page, so the range will be initially [0-19].
      rangeEnd: 19
    }
  },
  beforeDestroy () {
    this.$root.$off('filter-updated')
  },
  async mounted () {
    if (this.currentSurvey) {
      this.getReferralsList(this.rangeEnd)
    }
    this.getReferralData = () => {
      // Turn back to initial range.
      if (this.$route.name === 'Referrals') {
        this.rangeEnd = 19
        this.$nextTick(async function () {
          this.getReferralsList(this.rangeEnd)
        })
      }
    }
    // "Reloads" the page if the current date is changed.
    this.$root.$on('filter-updated', this.getReferralData)
  },
  computed: {
    currentSurvey () {
      const survey = this.$store.getters['dashboard/getCurrentSurvey']
      return survey
    }
  },
  methods: {
    openDetails (id) {
      this.showDetails = false
      this.sendingId = id
      setTimeout(() => { this.showDetails = true }, 100)
    },
    closeSidebar () {
      this.showDetails = false
    },
    async getReferralsList (rangeEnd) {
      this.loading = true
      // To show just the pagination's loading if it's true
      if (rangeEnd !== 19) {
        this.loading = false
        this.loadingMore = true
      }

      const dateFilter = this.$route.query.q
      const query = formatters.formatDateByAddTimezone(dateFilter, 3, 'hours')
      const result = await dashboardsService.getReferralsList(query, rangeEnd)
      // Clear list
      this.referralsList = []

      if (result.length > 0) {
        this.formatReferrals(result)
        this.contentLength = result.length
      }
      this.loadingMore = false
      this.loading = false
      this.showMessageNoReferrals = !(this.referralsList.length > 0)
    },
    formatReferrals (result) {
      result.map((item, index) => {
        if (item.referral !== null) {
          const onlyDynamic = JSON.parse(JSON.stringify(item.referral))
          // Delete referral fields to show only dynamic referral fields
          delete onlyDynamic.name
          delete onlyDynamic.email
          delete onlyDynamic.phone
          delete onlyDynamic.other
          if (Object.keys(onlyDynamic).length === 0) {
            const referralToPush = {
              _id: item._id,
              name: item.referral?.name && item.referral.name.length > 0 ? item.referral.name : this.$t('dashboard.no_name'),
              email: item.referral?.email && item.referral.email.length > 0 ? item.referral.email : this.$t('dashboard.no_email'),
              phone: item.referral?.phone && item.referral.phone.length > 0 ? item.referral.phone : this.$t('dashboard.no_phone'),
              referredBy: item.contact?.name && item.contact.name.length > 0 ? item.contact.name : this.$t('dashboard.no_name'),
              dynamicFields: 'empty',
              sending: item.sending
            }
            this.referralsList.push(referralToPush)
          } else {
            const referralDynamicToPush = {
              dynamicFields: onlyDynamic,
              _id: item._id,
              sending: item.sending,
              referredBy: item.contact?.name && item.contact.name.length > 0 ? item.contact.name : this.$t('dashboard.no_name')
            }
            this.referralsList.push(referralDynamicToPush)
          }
        }
      })
    },
    loadMore () {
      // If there's no more content to bring, it won't request.
      if (this.contentLength >= this.rangeEnd) {
        this.rangeEnd = this.contentLength + 19
        this.getResults(this.rangeEnd)
      }
    },
    onScroll () {
      window.onscroll = () => {
        const windowBottom = Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop
        ) + window.innerHeight === document.documentElement.offsetHeight

        if (windowBottom && !this.loadingMore) {
          this.loadMore()
        }
      }
    }
  }
}
</script>

<style lang="scss">
.referral-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.referral-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  .referral-card {
    margin: 10px;
    height: 210px;
    min-width: 585px;
    border: 1px rgba(121, 116, 126, 0.75) solid ;
    & h3 {
      color: #3f3356;
      font-size: 18px !important;
      height: 22px;
    }
    & .infos {
      padding: 0px !important;
    }
    & .ellipsis {
      width: 87%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    & .ellipsis-sending {
      width: 230px;
      height: 28px;
      overflow: hidden;
      white-space: nowrap;
    }
    & .gray-text {
      color: #6c6c6c !important;
    }
    & .dark-text {
      font-weight: 600;
    }
    .details-button {
      position: absolute;
      right: 5%;
      top: 40%;
      & button {
        border-radius: 100px;
        box-shadow: var(--accent-color);
        background: var(--accent-color);
        color: var(--default-text-color);
      }
    }
  }
}

.data-loading-referrals {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  .data-loading-spinner {
    margin-top: 24% !important;
    margin-left: 48% !important;
    & .spinner-inner {
      background-color: #fff !important;
    }
  }
}
.loading-circle-referrals {
  position: absolute;
  right: 50px;
  margin-bottom: 20px;
  & .circle {
    width: 20px !important;
    height: 20px !important;
    border-color: rgb(77, 1, 90) rgb(161, 106, 206) rgb(161, 106, 206) !important;
  }
}
.share-icon {
  color: #a1a1a1;
  font-size: 40px !important;
}
.by {
  padding: 0px !important;
  margin-top: 22px;
  & h3 {
    font-size: 16px !important;
  }
}
</style>
