import i18n from '../translations/i18n'
export const errorMessagesFormatter = {
  returnQuestionErrorMessage
}

/**
 * Returns an error message when someone tries to save a survey with questions unfilled.
 * @param {Object} errors object returned from req.
 */
function returnQuestionErrorMessage (errors) {
  let message = i18n.t('survey.error_saving')
  const numbers = []
  Object.keys(errors).forEach(key => {
    const item = errors[key]
    if (item.path === 'question' && item.kind === 'required') {
      const number = key.match(/\d+/g).map(Number)
      if (number) {
        numbers.push(parseInt(number) + 1)
      }
    }
  })
  if (numbers.length > 0) {
    const questionNumbers = numbers.toString().replace(/,/g, ', ')
    message = numbers.length > 1 ? `${i18n.t('survey.required_question_plu_1')} ${questionNumbers} ${i18n.t('survey.required_question_plu_2')}` : `${i18n.t('survey.required_question_sing_1')} ${questionNumbers} ${i18n.t('survey.required_question_sing_2')}`
  }
  return message
}
