<template>
  <main class="ranking-main-container">
    <div class="loading-approaches" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <header v-if="orderedRows.length !== 0">
      <app-heading level="h4" class="ranking-main-title">{{ currentSurvey.title }}</app-heading>
      <p>{{ questionSelected.labelToShowOnSelect }}</p>
    </header>

    <Transition appear mode="out-in" v-if="!noResponses">
      <section class="ranking-points" v-if="renderSection === 'overview'" key="points">
        <div class="ranking-point-card">
          <app-heading
            level="h4"
            class="point-title"
          >
            <span>
              {{ questionSelected.type === 'enum' ? $t('ranking.moreAnswered') : $t('ranking.best') }}
              <i class="material-icons info hint" v-title="questionSelected.type === 'enum' ? $t('ranking.hint_enum') : `${$t('ranking.hint_nps')} (${questionSelected.type.toUpperCase()})`" title-placement="right">info</i>
            </span>
            <img src="../../../assets/img/icon_green.svg" alt="icon green up" class="point-icon">
          </app-heading>

          <div class="ranking-point-list">
            <ul>
              <li v-for="(item, index) in bestCard" :key="index">
                <div class="grouper">
                  <span style="padding-right: .5rem;">
                    <img
                      :src="trophyPath(index)"
                      alt="trophy"
                      class="list-trophy"
                      v-if="index < 3"
                    >
                    <em v-else>{{ index + 1 }}</em>
                    <div class="elipsis" @click="gotoIndividualResponses(item.metadata)" v-title="item.metadata" style="max-width: 260px;cursor: pointer;">
                      {{ item.metadata }}
                    </div>
                  </span>
                  <span class="font" :style="{ color: checkNPS(item.score), 'min-width': questionSelected.type === 'enum' ? '6rem': '' }">
                    {{ item.score }}
                    {{ questionSelected.type === 'enum' ? `- ${item.responses}` : '' }}
                  </span>
                </div>
                <hr v-if="index < 4" style="width: 100%; color: rgba(0, 0, 0, 0.3); left: 0;">
              </li>
            </ul>
          </div>
        </div>

        <div class="ranking-point-card">
          <app-heading level="h4" class="point-title">
            <span>
              {{ questionSelected.type === 'enum' ? $t('ranking.lessAnswered') : $t('ranking.worse') }}
              <i class="material-icons info hint" v-title="questionSelected.type === 'enum' ? $t('ranking.hint_enum') : `${$t('ranking.hint_nps')} (${questionSelected.type.toUpperCase()})`" title-placement="right">info</i>
            </span>
            <img src="../../../assets/img/icon_red.svg" alt="icon green up" class="point-icon">
          </app-heading>

          <div class="ranking-point-list">
            <ul>
              <li v-for="(item, index) in worseCard" :key="index">
                <div class="grouper">
                  <span style="padding-right: .5rem;">
                    <em>{{ index + 1 }}</em>
                    <div class="elipsis" @click="gotoIndividualResponses(item.metadata)" v-title="item.metadata" style="max-width: 280px;cursor: pointer;">
                      {{ item.metadata }}
                    </div>
                  </span>
                  <span class="font" :style="{ color: checkNPS(item.score), 'min-width': questionSelected.type === 'enum' ? '6rem': '' }">
                    {{ item.score }}
                    {{ questionSelected.type === 'enum' ? `- ${item.responses}` : '' }}
                  </span>
                </div>
                <hr v-if="index < 4" style="width: 100%; color: rgba(0, 0, 0, 0.3); left: 0;">
              </li>
            </ul>
          </div>
        </div>

        <div class="ranking-point-card">
          <div>
            <app-heading level="h4" class="point-title">
              <span>
                {{ questionSelected.type === 'enum' ? $t('ranking.highlights') : $t('ranking.movimentations') }}
                <i class="material-icons info hint" v-title="questionSelected.type === 'enum' ? $t('ranking.hint_enum') : `${$t('ranking.hint_nps')} (${questionSelected.type.toUpperCase()})`" title-placement="right">info</i>
              </span>
              <img src="../../../assets/img/icon_gray.svg" alt="icon gray movement" class="point-icon">
            </app-heading>
            <app-heading level="h8" style="color: red;">{{ $t('ranking.comparison') }}</app-heading>
          </div>

          <div class="ranking-point-list">
            <ul>
              <li v-for="(item, index) in movimentations" :key="index">
                <div class="grouper">
                  <span>
                    <img
                      :src="trophyPath(index)"
                      alt="trophy"
                      class="list-trophy"
                      v-if="index < 3"
                    >
                    <em v-else>{{ index + 1 }}</em>
                    <span class="relative-position">
                      <img v-if="item.relativePosition < 0" src="../../../assets/img/arrow_down_red.svg" alt="arrow down red">
                      <img v-else-if="item.relativePosition  > 0" src="../../../assets/img/arrow_up_green.svg" alt="arrow up green">
                      <img v-else-if="item.relativePosition === 0 || item.relativePosition === '-'" src="../../../assets/img/no_movement_gray.svg" alt="no movement gray">
                      <b :style="{ color: checkPosition(item.relativePosition) }">{{ typeof item.relativePosition === 'number' && item.relativePosition !== 0 ? item.relativePosition : '' }}</b>
                    </span>
                    <div class="elipsis" @click="gotoIndividualResponses(item.metadata)" v-title="item.metadata" style="max-width: 160px;cursor: pointer;">
                      {{ item.metadata }}
                    </div>
                  </span>
                  <span class="font" :style="{ color: checkNPS(item.score) }">{{ item.score }}
                    <em
                      :style="{ color: checkPosition(item.scoreVariation) }"
                      style="font-size: 0.75;"
                    >
                      {{ formatVariation(item.scoreVariation) }}
                    </em>
                  </span>
                </div>
                <hr v-if="index < 4" style="width: 100%; color: rgba(0, 0, 0, 0.3); left: 0;">
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section class="ranking-graph" v-if="renderSection === 'graphic'" key="graph">
        <ranking-chart :key="graphKey" :rankingData="rankingResponse" :questionSelected="questionSelected"></ranking-chart>
      </section>
    </Transition>

    <hr v-if="!noResponses" style="width: 100%; color: rgba(0, 0, 0, 0.3); left: 0;">

    <section v-if="!noResponses" class="ranking-list">
      <div class="list-container">

        <app-form-input
          secondary
          border-solid
          class="list-search"
          v-model="searchTag"
          :placeholder="$t('ranking.search')"
          v-on:keyup.native="search()"
        ></app-form-input>

        <div class="list-header">
          <span>
            <h4 class="list-column" style="cursor: default;">Pos</h4>
          </span>

          <span>
            <h4 class="list-column elipsis limit-the-width" @click="toggleListOrdenation('metadata')" v-title='getCurrentGroupMetadata'>
              {{ getCurrentGroupMetadata }}
              <i v-if="currentOrdenedList.title === 'metadata'" class="material-icons">unfold_more</i>
            </h4>
          </span>

          <span>
            <h4 class="list-column" @click="toggleListOrdenation('nps')">
              {{ getLabelBasedOnQuestionType }}
              <i v-if="currentOrdenedList.title === 'nps'" class="material-icons">unfold_more</i>
          </h4>
          </span>
          <span class="custom-header-item" v-for='key in headersKeys' :key="key">
            <h4 class="list-column elipsis limit-the-width">{{ getHeaderByKey(key)?.abbreviatedLabel }}</h4>
            <i class="custom-header-info material-icons info hint" v-title='getHeaderByKey(key)?.label' title-placement="right">info</i>
          </span>
          <span>
            <h4 class="list-column" @click="toggleListOrdenation('responses')">
              {{ $t('ranking.responses') }}
              <i v-if="currentOrdenedList.title === 'responses'" class="material-icons">unfold_more</i>
            </h4>
          </span>
        </div>

        <div class="list-content">

        <div class="item" v-for="(item, index) in pages[currentPage-1]" :key="index">
            <div class="content">
              <div class="content-title">
                <Transition appear mode="out-in">
                  <img v-if="item.position < 4 && !currentOrdenedList.order" :src="trophyPath(item.position-1)" alt="trophy" class="list-trophy" key="trophy">
                  <em v-else key="number">{{ item.position }}</em>
                </Transition>
                <span class="relative-list-position">
                  <img v-if="item.relativePosition < 0" src="../../../assets/img/arrow_down_red.svg" alt="arrow down red">
                  <img v-else-if="item.relativePosition  > 0" src="../../../assets/img/arrow_up_green.svg" alt="arrow up green">
                  <img v-else-if="item.relativePosition  === 0" src="../../../assets/img/no_movement_gray.svg" alt="arrow up green">
                  <b :style="{ color: checkPosition(item.relativePosition) }">{{ item.relativePosition !== 0 ? item.relativePosition : '' }}</b>
                </span>
              </div>
              <div
                style="color: #828282; padding-right: 1rem;"
                class='elipsis limit-the-width'
                v-title='item.metadata'
                @click="gotoIndividualResponses(item.metadata)"
              >
                <span @click="gotoIndividualResponses(item.metadata)" style="width: fit-content;cursor: pointer;">
                  {{ item.metadata }}
                </span>
              </div>
              <div style="display: flex;align-items: center;">
                <span :style="{ color: checkNPS(item.score) }"  class='content-score'>{{ item.score }}</span>
                <em v-if="item.scoreVariation !== 0" :style="{ color: checkPosition(item.scoreVariation) }" class='badge-variation'>
                  {{ formatVariation(item.scoreVariation) }}
                </em>
              </div>
              <div class='content-score-detailed' v-for='key in headersKeys' :key="key">
                {{ item?.scoreDetailed?.[key] || 0 }}
              </div>
              <div class='final-column-with-expand'>
                <div>{{ item.responses }}</div>
                <span @click="toggleListGraph(index, item.metadata)" class="overtime-expander">
                  <i class="material-icons" :class="{ 'opened-graph': rankingListToggle === index }">expand_more</i>
                </span>
              </div>
            </div>
            <Transition apeear mode="out-in" name="slide">
              <div class="graph" v-if="rankingListToggle === index">
                <RankingOvertime v-if="overtimeData" :cardData="overtimeConfig" :data="overtimeData" :question="questionSelected" :expanded="true" :rankType="rankType" :indexTable="index"/>
              </div>
            </Transition>
          </div>

        </div>

      </div>

      <app-paginate
        :key="graphKey"
        style="display: flex; align-items: center; justify-content: center;"
        v-if="dynamicPageCount > 1"
        :initValue="currentPage"
        :prevText="'<'"
        :nextText="'>'"
        @input="changePage"
        :pageRange="10"
        :pageCount="dynamicPageCount"
      />
    </section>

    <app-heading v-if="noResponses" level="h4" style="text-align: center;">{{ $t('ranking.nothing_to_show') }}</app-heading>
  </main>
</template>

<script>
import RankingChart from '../charts/BarForRanking.vue'
import RankingOvertime from '../charts/RankingOvertime.vue'
import metricsHelper from '@/_helpers/metrics'
import { mapGetters } from 'vuex'
import { rankingService } from '@/_services'
import { formatters, gridMaker, constants } from '@/_helpers'
import rankingMixins from '@/_mixins/ranking'
import healthMixins from '@/_mixins/health'

export default {
  name: 'Ranking',
  mixins: [rankingMixins, healthMixins],
  components: {
    RankingChart,
    RankingOvertime,
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-paginate': () => import('@binds-tech/binds-design-system/src/components/Paginate/Paginate')
  },
  data () {
    return {
      pages: [],
      dynamicPageCount: 1,
      currentPage: 1,
      currentOrdenedList: {
        order: false,
        title: 'nps'
      },
      rankingListToggle: null,
      renderSection: 'overview',
      loading: true,
      graphKey: 0,
      rankingResponse: [],
      filteredList: [],
      searchTag: '',
      overtimeConfig: {
        colors: [
          '#805ea0', '#966fbc', '#ae74e8', '#b97bf7', '#ce9eff',
          '#805ea0', '#966fbc', '#ae74e8', '#b97bf7', '#ce9eff'
        ],
        graphicType: 'line',
        showTextOnly: false,
        syncQuestion: false,
        textAnalytic: false,
        textColor: 'white'
      },
      overtimeData: [],
      currentMetadata: ''
    }
  },
  mounted () {
    this.currentPage = 1
    this.$parent.$on('render-section', (section) => {
      this.renderSection = section
    })

    if (window._.isEmpty(this.rankingResponse)) this.setInitialRanking()

    this.$nextTick(() => {
      this.$root.$on('filter-ranking', (options) => {
        this.currentPage = 1
        if (options.isToUpdateView) {
          this.setInitialRanking()
          this.rankingListToggle = null
        }
      })

      this.$root.$on('filter-updated', () => {
        this.currentPage = 1
        if (!this.loading) {
          this.setInitialRanking()
          this.rankingListToggle = null
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      currentSurvey: 'dashboard/getCurrentSurvey',
      account: 'account/getAccount'
    }),
    trophy () {
      return require('../../../assets/img/trophy1.svg')
    },
    trophy2 () {
      return require('../../../assets/img/trophy2.svg')
    },
    trophy3 () {
      return require('../../../assets/img/trophy3.svg')
    },
    getCurrentGroupMetadata () {
      const keyToFind = this.$route.query.groupBy.split('.').pop()
      const foundField = this.account.fields.find(f => f.key === keyToFind)
      return foundField?.label ?? null
    },
    /**
     * Get label based on question type.
     * @returns {string} - Label based on question type.
    */
    getLabelBasedOnQuestionType () {
      if (this.questionSelected.type === 'enum') {
        return this.questionSelected.ui === '10num' ? this.$i18n.t('ranking.average') : this.$t('ranking.quantity')
      }

      if (this.questionSelected.type === 'health') {
        return this.$t('health_score.title_card_score').toUpperCase()
      }

      return this.questionSelected.type.toUpperCase()
    },
    /**
     * Get header keys based on question type.
     * @returns {Array} - Array of header keys.
    */
    headersKeys () {
      if (this.questionSelected.type === 'enum' || this.questionSelected.type === 'health') {
        const headers = new Set()
        this.rankingResponse.forEach(aggregated => {
          Object.keys(aggregated.scoreDetailed).forEach(key => {
            headers.add(key)
          })
        })
        return [...headers].slice(0, 5)
      }
      return Object.keys(this.metricsFromHelper).slice(0, 5)
    },
    metricsFromHelper () {
      return metricsHelper?.[this.questionSelected.type]?.[this.questionSelected.ui]
    },
    questionSelected () {
      const elegibleQuestions = []
      let questionFinded = {}
      if (!this.currentSurvey || !this.currentSurvey.questions) {
        return questionFinded
      }
      this.currentSurvey.questions.map(question => {
        if (['nps', 'csat', 'nes', 'ces', 'enum', 'kpi', 'nvs'].indexOf(question.type) > -1) {
          elegibleQuestions.push(question)
        }
      })

      if (elegibleQuestions.length > 0) {
        questionFinded = elegibleQuestions[0]
        if (this.$route.query.question) {
          const finded = elegibleQuestions.filter(question => question._id === this.$route.query.question)
          if (finded.length > 0) {
            questionFinded = finded[0]
          }
        }
        if (this.$route.query.rankBy === constants.ranking.TYPES.HEALTH) {
          questionFinded = {
            labelToShowOnSelect: this.$t('health_score.title_card_score'),
            type: constants.ranking.TYPES.HEALTH,
            ui: constants.ranking.TYPES.HEALTH,
            _id: 'health'
          }
        }
      }
      return questionFinded
    },
    orderedRows () {
      let list = []
      if (this.searchTag.length > 0) list = structuredClone(this.filteredList)
      else list = structuredClone(this.rankingResponse)

      if (!list) {
        list = []
        return
      }

      switch (this.currentOrdenedList.title) {
        case 'metadata':
          list.sort((a, b) => a.metadata > b.metadata)
          break
        case 'nps':
          list.sort((a, b) => a.score < b.score)
          break
        case 'responses':
          list.sort((a, b) => a.responses < b.responses)
          break
        default:
          break
      }

      if (this.currentOrdenedList.order) list.reverse()

      this.createPages(list)

      return list
    },
    noResponses () {
      return this.rankingResponse.length === 0
    },
    bestCard () {
      return this.rankingResponse.slice(0, 5)
    },
    worseCard () {
      return window._.sortBy(this.rankingResponse.slice(-5), (a) => a.score)
    },
    movimentations () {
      return window._.sortBy(this.rankingResponse.slice(), (a) => -a.scoreVariation).slice(0, 5)
    },
    rankType () {
      const rankBy = this.$route.query.rankBy
      const rankTypes = constants.ranking.TYPES
      const selected = Object.keys(rankTypes).find(key => rankTypes[key] === rankBy)
      return selected.toLowerCase()
    }
  },
  methods: {
    gotoIndividualResponses (metaValue) {
      const q = JSON.parse(this.$route.query.q)

      const keyMetadata = `seed.${this.$route.query.groupBy}`

      q.$and.push({ [keyMetadata]: { $eq: metaValue } })

      this.$root.$emit('ranking-redirected')

      this.$router.push({ path: '/dashboard/individual', query: { q: JSON.stringify(q) } }).catch(e => console.error(e))
    },
    getHeaderByKey (key) {
      if (this.questionSelected.type === 'enum') {
        return { abbreviatedLabel: key.toUpperCase(), label: key }
      }

      if (this.questionSelected.type === constants.ranking.TYPES.HEALTH) {
        return this.getLabelsHealth(key)
      }

      return { abbreviatedLabel: key.toUpperCase(), label: key }
    },
    changePage (page) {
      this.currentPage = page
    },
    createPages (array) {
      const pages = []
      const pageSize = 33 // but it shows 35 items
      this.dynamicPageCount = Math.ceil(array.length / pageSize)
      for (let i = 0; i < this.dynamicPageCount; i++) {
        const start = i * pageSize
        const end = start + pageSize
        pages.push(array.slice(start, end))
      }
      this.pages = pages
    },
    async toggleListGraph (id, metadata) {
      this.loading = true
      if (this.rankingListToggle !== id) await this.setRankingOvertime(metadata)
      this.rankingListToggle = id === this.rankingListToggle ? null : id
      this.loading = false
    },
    toggleListOrdenation (title) {
      if (this.currentOrdenedList.title === title) {
        this.currentOrdenedList.order = !this.currentOrdenedList.order
      } else {
        this.currentOrdenedList.title = title
        this.currentOrdenedList.order = false
      }
    },
    search () {
      if (this.searchTag === '') {
        this.filteredList = []
        return
      }
      this.filteredList = this.rankingResponse.filter(obj => obj.metadata.toLowerCase().includes(this.searchTag.toLowerCase()))
      if (this.filteredList.length > 0) this.currentPage = 1
    },
    checkPosition (position) {
      if (position < 0) return 'red'
      if (position > 0) return 'green'
      if (position === '-') return 'gray'
    },
    checkNPS (value) {
      const colorsDefault = ['#EA484D', '#FFCE1F', '#3bc273', '#57ad26']

      if (this.questionSelected._id === 'health') {
        return this.getColorForRanking(value)
      } else {
        switch (true) {
          case (value >= -100 && value < 0):
            return colorsDefault[0]
          case (value >= 0 && value < 30):
            return colorsDefault[1]
          case (value >= 30 && value <= 70):
            return colorsDefault[2]
          case (value >= 70 && value <= 100):
            return colorsDefault[3]
          default: return '#cacaca'
        }
      }
    },
    getQuery ({ overtime = false, metadata = '' } = {}) {
      const query = this.$route.query
      const respondedAt = { ...this.$store.getters['filters/getDashFilter'].query.respondedAt }
      const diff = Math.abs(this.$moment(respondedAt.$gte).diff(this.$moment(respondedAt.$lte), 'hours'))
      const newQuery = JSON.parse(formatters.formatDateByAddTimezone(query.q, diff === 24 ? 0 : 3, 'hours'))

      if (!newQuery.$and.find(item => item.respondedAt)) newQuery.$and.push({ respondedAt: respondedAt })

      if (overtime) {
        const startDate = this.$moment(respondedAt.$gte, 'YYYY-MM-DDTHH:mm:ss')
        const current = this.$moment().endOf('day').add(3, 'hours')
        const asHours = this.$moment.duration(startDate.diff(current)).asHours()
        const beforeStartDate = this.$moment(startDate).subtract((asHours * -1), 'hours')
        const formatedBeforeStartDate = beforeStartDate.endOf('day').add(3, 'hours').format('YYYY-MM-DDTHH:mm:ss')

        newQuery.$and.find(item => item.respondedAt).respondedAt.$gte = formatedBeforeStartDate

        const dateGrid = gridMaker.getGrid(formatedBeforeStartDate, respondedAt.$lte)
        query.grid = dateGrid

        // eslint-disable-next-line no-prototype-builtins
        newQuery.$and = newQuery.$and.filter(item => !item.hasOwnProperty(`seed.${query.groupBy}`))
        newQuery.$and = newQuery.$and.filter(item => !item.question)

        const newObject = {}
        const questionId = { question: { $eq: this.questionSelected._id } }
        newObject[`seed.${query.groupBy}`] = { $like: metadata.trim() }
        if (this.rankType === constants.ranking.TYPES.QUESTION) newQuery.$and.push(questionId)
        newQuery.$and.push(newObject)
        query.q = JSON.stringify(newQuery)
      } else {
        query.q = JSON.stringify(newQuery)
      }
      return query
    },
    async setInitialRanking () {
      this.loading = true
      const query = this.getQuery()
      const ms = this.account?.rankingSettings?.minSample
      if (!query.minSample || query.minSample === '0') query.minSample = ms
      const result = await rankingService.getRanking(query)
      this.$root.$emit('filter-updated', query.q)

      if (!result) {
        this.loading = false
        console.warn('Couldn\'t retrieve current survey')
        this.$root.$emit('ranking-can-export', false)
        return
      } else {
        this.$root.$emit('ranking-can-export', true)

        const metadataKey = this.$route.query?.groupBy?.split('.')?.slice(-1)[0]
        const metadata = this.account?.fields.find(acc => acc.key === metadataKey)?.label || 'unknown'
        const ranking = result?.ranking
        const type = this.questionSelected.type
        const ui = this.questionSelected.ui
        const name = this.currentSurvey.title

        this.$root.$emit('ranking-export-content', { ranking, metadata, type, ui, name })
      }

      this.rankingResponse = result?.ranking

      this.graphKey++
      this.loading = false
    },
    async setRankingOvertime (metadata) {
      const query = this.getQuery({ overtime: true, metadata: metadata })
      let response = {}
      if (this.rankType === constants.ranking.TYPES.QUESTION) response = await rankingService.getRankingOvertime(query)
      if (this.rankType === constants.ranking.TYPES.HEALTH) response = await rankingService.getRankingHealthOvertime(query)

      this.overtimeData = response
    },
    trophyPath (index) {
      switch (index) {
        case 0:
          return this.trophy
        case 1:
          return this.trophy2
        case 2:
          return this.trophy3
        default:
          return null
      }
    }
  }
}
</script>

<style lang="scss">
.loading-approaches {
  background: #EFF3F840;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1850px) {
  main.ranking-main-container .ranking-points .ranking-point-card .ranking-point-list ul > li span {
    &:first-child {
      width: 55%;
    }
    div.elipsis {
      width: 80% !important;
      max-width: 150px;
    }
  }
}

@media only screen and (max-width: 1730px) {
  main.ranking-main-container .ranking-points .ranking-point-card .ranking-point-list ul > li .grouper span:first-child {
    width: 50% !important;
  }

  div.list-content-divider {
    right: 21% !important;
  }
}

@media only screen and (max-width: 1360px) {
  section.ranking-points {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  div.ranking-point-card {
    width: 70% !important;
  }

  main.ranking-main-container .ranking-points .ranking-point-card .ranking-point-list ul > li .grouper span:first-child {
    width: 100% !important;
  }
}

.v-enter-active, .v-leave-active { transition: opacity 500ms ease-in-out; }
.v-enter-from, .v-leave-to { opacity: 0; }

.slide-enter { transform: translateX(50%) }
.slide-enter-to { transform: translateX(0) }
.slide-enter-active { position: relative }
.slide-leave { transform: translateX(0) }
.slide-leave-to { transform: translateX(50%) }
.slide-enter-active, .slide-leave-active { transition: all 200ms ease-in-out }

.elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
  // width: 180px;
}
.list-trophy {
  height: 2rem;
  width: 2rem;
  transition: all .3s ease-in-out;
}
.point-icon {
  height: 2.5rem;
  width: 2.5rem;
  shape-rendering: auto;
  image-rendering: optimizeQuality;
}
main.ranking-main-container {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 90%;
  padding: 2rem 4rem;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
  header {
    width: 100%;
  }
  h4.ranking-main-title {
    margin-bottom: 2rem !important;
  }
  .ranking-points {
    display: flex;
    justify-content: space-between;

    margin: 3rem 0;
    width: 100%;

    .ranking-point-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 30%;

      padding: 1.25rem 1rem;

      box-shadow: 3px 4px 5px rgba($color: #000000, $alpha: .3);
      border: 1px solid rgba($color: #000000, $alpha: .3);
      border-radius: 10px;
      .point-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #3f3356;;

        .hint {
          position: relative;
          top: -12px;
          font-size: 1.25rem;
          color: #b5b5b5;
          cursor: default;
        }
      }
      &:nth-child(2) div.ranking-point-list ul {
        margin-top: 5rem;
        li {
          margin: 1.25rem 0;
        }
      }
      .ranking-point-list {
        margin: 0 1rem 0;

        ul>li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          margin: 1rem 0;
          .grouper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }
          span {
            display: flex;
            align-items: center;

            color: #4E4E4E;
            font-weight: 600;

            .relative-position {
              margin-right: 1rem;
              img {
                margin: 0;
              }
            }
            img {
              margin: 0 1rem 0 0;
            }
            em {
              min-width: max-content;
              margin: 0 1rem 0 1rem;
              font-weight: 600;
            }
          }
          .font {
            font-weight: 700;
          }
        }
      }
    }
  }
  .ranking-graph {
    min-width: 70%;
    margin: 3rem auto;
  }
  .ranking-list {
    margin: 3rem 0;
    width: 100%;
    padding: 2rem 1.5rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    .list-search {
      display: flex;
      margin: 1rem 0 2rem;
      max-width: 40%;
    }
    .list-header,
    .list-content .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 2rem;
      .material-icons {
        transition: all 0.3s ease-in-out;
        &.opened-graph {
          transition: all 0.3s ease-in-out;
          transform: rotate(180deg);
        }
      }
    }
    .list-header {
      height: 75px;
      background: #F1F1F1;
      span {
        min-width: 10%;
      }
      .custom-header-item {
        display: flex;
        cursor: default !important;
      }
      .custom-header-info {
        font-size: 0.9rem;
        color: #b5b5b5;
        padding: 0.1rem;
      }
      .list-column {
        color: #3f3356;;
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        cursor: pointer;
        width: fit-content;
      }
    }
    .list-content {
      .badge-variation {
        font-size: .75rem;
        margin-left: 0.3rem;
      }
      .content-score {
        font-size: 1.5vw;
        color: rgb(78, 78, 78);
        max-width: 5rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
      }
      .content-score-detailed {
        color: #828282;
        padding-right: 1rem;
        span {
          width: fit-content;
          cursor: pointer;
        }
      }
      .final-column-with-expand {
        position: relative;
        display: flex;
        align-items: center;
        .overtime-expander {
          position: absolute;
          cursor: pointer;
          right: 0;
          border-left: 1px solid #dad8d8;
          height: 3rem;
          width: 3rem;
          text-align: right;
          align-content: center;
          color: #828282;
          span {
            font-size: 2.25rem;
          }
        }
        div {
          color: #828282;
        }
      }
      .item .graph {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: 0 auto;
        padding: 2rem 0;

        transition: all 300ms ease-in-out;
        max-width: 30%;
      }
      .item:nth-child(even) .content {
        background: #F1F7FA;
      }
      .content {
        height: 80px;
        font-weight: 700;
        div {
          min-width: 10%;
        }
        .list-content-divider {
          border-right:1px solid rgba(0, 0, 0, 0.3);
          position: absolute;
          height:60px;
          width: 1px;
          right: 16%;
        }
        .content-title {
          display: flex;
          align-items: center;
          .relative-list-position {
            display: flex;
            justify-content: center;
            position: relative;
            left: 3%;
          }
          em {
            margin: 0 .75rem;
            font-weight: 600;
          }
        }
      }
    }
    .limit-the-width {
      display: block;
      max-width: 90px !important;
    }
  }
}
</style>
