<template>
  <div class="container-search-text" :style="{'background': disabled ? '#00000008' : '' }">
    <p class="placeholder-search" v-if="placeholder">{{ placeholder }}</p>
    <div class="content-search-text">
      <input
        type="text"
        class="input-text"
        :disabled="disabled"
        :placeholder="$t('survey_store.input_textarea')"
        v-model="text"
        @keyup.enter="searchText()"
        @input="handleInputTyping"
      >
      <i class="material-icons icon-search" v-if="disabled" @click="resetSearch()">close</i>
      <i class="material-icons icon-search" v-if="insight && !disabled" @click="searchText()">search</i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchText',
  props: ['insight', 'placeholder', 'value'],
  components: {},
  data () {
    return {
      text: '',
      disabled: false,
      typingTimeout: null
    }
  },
  mounted () {},
  computed: {},
  watch: {
    value: {
      immediate: true,
      handler (newVal) {
        if (newVal) { this.text = newVal; this.disabled = true }
      }
    }
  },
  methods: {
    handleInputTyping () {
      if (this.insight) return
      clearTimeout(this.typingTimeout)

      this.typingTimeout = setTimeout(() => {
        this.disabled = true
        this.$emit('search-text-insight', this.text.trim())
      }, 1000)
    },
    resetSearch () {
      this.text = ''
      this.disabled = false
    },
    searchText () {
      const query = { ...this.$route.query }

      if (!this.text.length) {
        delete query.textSearch
        this.disabled = false
      } else {
        query.textSearch = this.text.trim()
        this.disabled = true
      }

      this.$router.push({ name: 'Insights', query })
        .catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error when updating url with filters', err) })

      this.$store.commit('filtersInsight/insightField', { textSearch: this.text.trim() })
      this.$emit('search-text-insight', this.text.trim())
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
}
.container-search-text {
  width: 16rem;
  min-height: 2.5rem;
  border-radius: 0.3rem;
  padding: 0.2rem 0;
  border: 1px solid #cacaca;
  display: flex;
  flex-direction: column;
  color: #808080;
  overflow: hidden;
  .placeholder-search {
    font-size: 1vh;
    padding-left: 0.3rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #454548;
    margin-bottom: 0.2rem;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }
  .content-search-text {
    display: flex;
    justify-content: space-between;
    padding: 0 0.2rem;
    .input-text {
      height: 1.2rem;
      width: 90%;
      background: transparent;
      color: #808080;
      border: none;
      font-size: 0.8vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #808080;
      line-height: 1vw;
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
    }
    .input-text::placeholder {
      color: #808080;
    }
    .input-text:disabled {
      background: transparent;
    }
    .icon-search {
      padding-bottom: 5px;
      padding-right: 0.3rem;
      height: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1vw;
      color: var(--accent-color);
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .container-search-text {
    width: 12rem;
  }
}
</style>
