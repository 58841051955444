import { constants } from '@/_helpers'

export default {
  methods: {
    /**
     * Format the value to remove the special characters.
     * @param {String} value - Value to be formatted.
     * @returns {String} - Formatted value.
    */
    formatToExcel (value) {
      const matches = value.match(/[+-]?\d+(\.\d+)?/)
      return matches ? matches[0] : '0'
    },

    /**
     * Format variation value based on question type.
     * @param {number} value - Variation value.
     * @returns {string} - Formatted variation value.
    */
    formatVariation (value) {
      const quantityLabel = this.$i18n.t('ranking.quantity')
      const pointsLabel = 'pts'
      const averageLabel = this.$i18n.t('ranking.average')

      switch (this.questionSelected.type) {
        case 'enum':
          if (this.questionSelected.ui === '10num') return this.formatEnumVariation(value, averageLabel)
          return this.formatEnumVariation(value, quantityLabel)
        default:
          return this.formatNumericVariation(value, pointsLabel)
      }
    },
    /**
     * Format variation value for enum question type.
     * @param {number} value - Variation value.
     * @param {string} label - Label based on question type.
     * @returns {string} - Formatted variation value.
    */
    formatEnumVariation (value, label) {
      if (value > 0) {
        return `+(${value} ${label})`
      } else if (value < 0) {
        return `-(${Math.abs(value)} ${label})`
      } else {
        return `= (0 ${label})`
      }
    },

    /**
     * Format variation value for numeric question type.
     * @param {number} value - Variation value.
     * @param {string} label - Label based on question type.
     * @returns {string} - Formatted variation value.
    */
    formatNumericVariation (value, label) {
      if (value > 0) {
        return `+(${value.toFixed(2)} ${label})`
      } else if (value < 0) {
        return `-(${Math.abs(value).toFixed(2)} ${label})`
      } else {
        return `= (0 ${label})`
      }
    },
    getLabelsHealth (value) {
      switch (true) {
        case (value === 'veryLow_health'):
          return { abbreviatedLabel: constants.abbreviatedHealth.VERY_LOW, label: constants.health.churn.VERY_LOW }
        case (value === 'low_health'):
          return { abbreviatedLabel: constants.abbreviatedHealth.LOW, label: constants.health.churn.LOW }
        case (value === 'medium_health'):
          return { abbreviatedLabel: constants.abbreviatedHealth.MID, label: constants.health.churn.MID }
        case (value === 'high_health'):
          return { abbreviatedLabel: constants.abbreviatedHealth.HIGH, label: constants.health.churn.HIGH }
        case (value === 'veryHigh_health'):
          return { abbreviatedLabel: constants.abbreviatedHealth.VERY_HIGH, label: constants.health.churn.VERY_HIGH }
        default:
          return { abbreviatedLabel: '-', label: '-' }
      }
    }
  }
}
