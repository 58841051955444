<template>
  <div>
    <canvas v-if="multiple" :id="makeSpecialId()" width="550" height="400"></canvas>
    <canvas v-else :id="makeSpecialId()" width="200" height="200"></canvas>
  </div>
</template>

<script>
import { canvasMaker, formatters } from '@/_helpers'
import Chart from 'chart.js'
export default {
  name: 'OvertimeComparativeDash',
  props: ['overtimeChartData', 'metric', 'multiple', 'indexForColor', 'single', 'period'],
  data () {
    return {
      greater: 0
    }
  },
  methods: {
    makeSpecialId () {
      this.overtimeChartData.specialId = canvasMaker.makeCanvasIdWithoutParam()
      return this.overtimeChartData.specialId
    }
  },
  async mounted () {
    let optionsValues = {}
    const labels = []
    const data = []
    const fontSize = 18
    const fontFamily = "'Avenir', Helvetica, Arial, sans-serif;"
    const colours = [
      '#a87df5', '#fc90a4', '#00aaa7', '#673ab7', '#9f95f5',
      '#FFB500', '#f55bed', '#CA1E54', '#4D5359', '#79C99E',
      '#97DB4F', '#27B573', '#FF7663', '#1B9AAA', '#FFC43D'
    ]

    let formatDate = 'MMM Do' // esse if é para saber se o filtro é por hora ou dias, com isso formatamos melhor os labels de data do gráfico
    if (this.period && this.period === 'day') {
      formatDate = 'HH:mm'
    }

    if (this.single) {
      const colour = colours[this.indexForColor]
      const line = {
        data: [],
        borderColor: colour,
        pointBorderColor: colour,
        pointBackgroundColor: colour,
        backgroundColor: ['rgba(255, 255, 255, .2)'],
        borderWidth: 2
      }

      this.overtimeChartData.overtime.map(point => {
        const from = formatters.formatDateByLang(point.from, this.lang, formatDate)
        if (this.metric.metric === 'sendings') {
          line.data.push(point.total)
          labels.push(from)
        } else if (this.metric.metric === 'responseTime') {
          line.data.push(point.metricTotal)
          labels.push(from)
        } else {
          line.data.push(point.percentage)
          labels.push(from)
        }
      })

      optionsValues = {
        title: {
          display: true,
          text: `${this.overtimeChartData.survey.title} ─ ${this.metric.label}`
        },
        legend: {
          display: false
        },
        pointStyle: 'circle',
        responsive: true,
        lineTension: 1,
        scales: {
          xAxes: [{
            gridLines: { color: 'rgba(0, 0, 0, 0)' },
            ticks: {
              fontFamily: fontFamily,
              fontSize: fontSize,
              fontColor: '#939393'
            }
          }],
          yAxes: [{
            stacked: true,
            gridLines: { color: 'rgba(0, 0, 0, 0)' },
            ticks: {
              beginAtZero: true,
              padding: 25
            }
          }]
        }
      }

      data.push(line)
    }

    if (this.multiple) {
      this.overtimeChartData.map((item, index) => {
        const colour = colours[index]
        const newLine = {
          data: [],
          borderColor: colour,
          pointBorderColor: colour,
          pointBackgroundColor: colour,
          backgroundColor: ['rgba(255, 255, 255, .2)'],
          borderWidth: 2,
          label: item.survey.title
        }

        item.overtime.map(e => {
          let to
          const from = formatters.formatDateByLang(e.from, this.lang, formatDate)
          if (e.to) {
            to = formatters.formatDateByLang(e.to, this.lang, formatDate)
          }

          if (this.metric === 'sendings') {
            newLine.data.push(e.total)
            if (!labels.includes(from)) {
              labels.push(from)
            }
            if (to !== undefined && !labels.includes(to)) {
              labels.push(to)
            }
            if (e.total > this.greater) {
              this.greater = e.total
            }
          } else if (this.metric === 'responseTime') {
            newLine.data.push(e.metricTotal)
            if (!labels.includes(from)) {
              labels.push(from)
            }
            if (to !== undefined && !labels.includes(to)) {
              labels.push(to)
            }
            if (e.metricTotal > this.greater) {
              this.greater = e.metricTotal
            }
          } else {
            newLine.data.push(e.percentage)
            if (!labels.includes(from)) {
              labels.push(from)
            }
            if (to !== undefined && !labels.includes(to)) {
              labels.push(to)
            }
            if (e.percentage > this.greater) {
              this.greater = e.percentage
            }
          }
        })
        data.push(newLine)
      })

      optionsValues = {
        legend: {
          display: true,
          position: 'bottom',
          usePointStyle: true,
          labels: {
            usePointStyle: true,
            padding: 8,
            boxWidth: 9,
            fontFamily: fontFamily,
            fontSize: fontSize,
            fontColor: '#939393'
          }
        },
        pointStyle: 'circle',
        responsive: true,
        lineTension: 1,
        scales: {
          xAxes: [{
            gridLines: { color: 'rgba(0, 0, 0, 0)' },
            ticks: {
              fontFamily: fontFamily,
              fontSize: fontSize,
              fontColor: '#939393'
            }
          }],
          yAxes: [{
            gridLines: { color: 'rgba(0, 0, 0, 0)' },
            ticks: {
              beginAtZero: true,
              padding: 25,
              max: this.greater
            }
          }]
        }
      }
    }

    const planetChartData = {
      type: 'line',
      data: {
        labels: labels,
        datasets: data
      },
      options: optionsValues
    }

    if (planetChartData.data.datasets) {
      const ctx = document.getElementById(this.overtimeChartData.specialId)
      const chart = await new Chart(ctx, {
        type: planetChartData.type,
        data: planetChartData.data,
        options: planetChartData.options
      })

      return chart
    }
  }
}
</script>
