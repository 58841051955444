const moment = require('moment')

function initialState () {
  return {
    filters: {
      startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      sentiment: '',
      search: '',
      text: '',
      terms: '',
      theme: 'light'
    },
    theme: '',
    filterTermOptions: [],
    filterTermSelected: [],
    isUpdating: null
  }
}
const state = initialState()

const mutations = {
  resetToInitialState (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  updateFilters (state, payload) {
    if (payload.filters) {
      state.filters = payload.filters
    }
    if (payload.theme) {
      state.theme = payload.theme
    }
    if (payload.filterTermOptions) {
      state.filterTermOptions = payload.filterTermOptions
    }
    if (payload.filterTermSelected) {
      state.filterTermSelected = payload.filterTermSelected
    }
  },
  updateTermsSelected (state, payload) {
    state.filterTermSelected = payload
  },
  updateTermOptions (state, payload) {
    state.filterTermOptions = payload
  },
  isUpdating (state, payload) {
    state.isUpdating = payload
  }
}

const getters = {
  getFilters: state => state
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
