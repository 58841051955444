// Esse componente não está sendo usado no embed do e-mail.
<template>
    <div v-html="embededCode"></div>
</template>
<script>
import { embedGenerator } from '@/_helpers'
export default {
  props: ['question'],
  data () {
    return {
      embededCode: ['<div class="email-cta" style="width:590px;margin:0 auto;text-align:center;"> \n ',
        '<h2 style="display:block; margin-bottom:20px;">' + this.question.question + '</h2> \n ',
        '<table width="600" cellspacing="0" cellpadding="0" border="0"> \n',
        '<tbody> \n',
        '<tr> \n',
        '<td valign="top" align="left" style="width:9%; display:inline-block"> \n',
        '<a href="*|SRV:10|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;width:100%;display:inline-block"> <table cellspacing="0" cellpadding="0"> \n',
        '<tbody> \n',
        '<td> \n',
        '<span style="width:40px;height:40px;border-radius:50%;background-color:{{color.c3}};color:#fff;display:block;text-align:center;">1</span> \n',
        '</td> \n',
        '<td style="color:#333"> \n',
        '</td> \n',
        '</tr> \n',
        '</tbody> \n',
        '</table> \n',
        '</a> \n',
        '</td> \n',
        '<td valign="top" align="left" style="width:9%; display:inline-block"> \n',
        '<a href="*|SRV:20|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;width:100%;display:inline-block"> \n',
        '<table cellspacing="0" cellpadding="0"> \n',
        '<tbody> \n',
        '<tr> \n',
        '<td> \n',
        '<span style="width:40px;height:40px;border-radius:50%;background-color:{{color.c3}};color:#fff;display:block;text-align:center;">2</span> \n',
        '</td> \n',
        '<td style="color:#333"> \n',
        '</td> \n',
        '</tbody> \n',
        '</table> \n',
        '</a> \n',
        '</td> \n',
        '<td valign="top" align="left" style="width:9%; display:inline-block"> \n',
        '<a href="*|SRV:30|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;width:100%;display:inline-block"> \n',
        '<table cellspacing="0" cellpadding="0"> \n',
        '<tbody> \n',
        '<td> \n',
        '<span style="width:40px;height:40px;border-radius:50%;background-color:{{color.c3}};color:#fff;display:block;text-align:center;">3</span> \n',
        '</td> \n',
        '<td style="color:#333"> \n',
        '</td> \n',
        '</tbody> \n',
        '</table> \n',
        '</a> \n',
        '</td> \n',
        '<td valign="top" align="left" style="width:9%; display:inline-block"> \n',
        '<a href="*|SRV:40|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;width:100%;display:inline-block"> \n',
        '<table cellspacing="0" cellpadding="0"> \n',
        '<tbody> \n',
        '<td> \n',
        '<span style="width:40px;height:40px;border-radius:50%;background-color:{{color.c3}};color:#fff;display:block;text-align:center;">4</span> \n',
        '</td> \n',
        '<td style="color:#333"> \n',
        '</td> \n',
        '</tbody> \n',
        '</table> \n',
        '</a> \n',
        '</td> \n',
        '<td valign="top" align="left" style="width:9%; display:inline-block"> \n',
        '<a href="*|SRV:50|*" style="font-size:20px;font-family:Arial,sans-serif;text-decoration:none;line-height:40px;width:100%;display:inline-block"> \n',
        '<table cellspacing="0" cellpadding="0"> \n',
        '<tbody> \n',
        '<td> \n',
        '<span style="width:40px;height:40px;border-radius:50%;background-color:{{color.c3}};color:#fff;display:block;text-align:center;">5</span> \n',
        '</td> \n',
        '<td style="color:#333"> \n',
        '</td> \n',
        '</tbody> \n',
        '</table> \n',
        '</a> \n',
        '</td> \n',
        '</tr> \n',
        '</tbody> \n',
        '</table> \n',
        '</td> \n',
        '</tbody> \n',
        '</table> \n',
        '</div>'
      ].join('')
    }
  },
  mounted () {
    if (this.question.gradient) {
      this.embededCode = embedGenerator.returnEmbedEnum(this.question)
    }
    this.$emit('embedQuestion', this.embededCode)
  }
}
</script>
