<template>
  <div class="content-10num">
    <app-form-rate
      v-model="inputValue"
      :items="items"
      item-value="id"
      item-text="id"
      :isGradient="question.gradient"
      :textColor="colors.actionText"
      :labelColor="colors.splashColor"
      :bgColor="colors.actionColor"
      :hoverColor="colors.buttonHover"
      :min-label="t(true)"
      :max-label="t(false)"
    />
  </div>
</template>

<script>
import { translator, gradientMaker } from '@/_helpers'
import appFormRate from '@binds-tech/binds-design-system/src/components/Form/Rate'

export default {
  name: 'app10num',
  props: ['question', 'colors'],
  components: {
    appFormRate
  },
  data () {
    return {
      inputValue: null,
      items: [
        { id: 0 },
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
        { id: 9 },
        { id: 10 }
      ]
    }
  },
  created () {
    gradientMaker.checkGradientAndReturnTheColors(this.question, this.items)

    this.$root.$on('language-update', (language, id) => {
      if (this.question.id === id) {
        this.question.startLabel = translator.translateThisText(language, 'components.csat_not_satisfied')
        this.question.endLabel = translator.translateThisText(language, 'components.csat_satisfied')
      }
    })

    if (this.question.startLabel === '' && this.question.endLabel === '') {
      this.question.startLabel = translator.translateThisText(this.question.language, 'components.csat_not_satisfied')
      this.question.endLabel = translator.translateThisText(this.question.language, 'components.csat_satisfied')
    }
  },
  methods: {
    t (isStart) {
      const questionType = this.question.type
      // Não remover segunda parte das condições. Foi feita para lidar com pesquisas vindas da v1.
      if (questionType === 'nps' && (!this.question.startLabel && !this.question.endLabel)) {
        this.question.startLabel = translator.translateThisText(this.question.language, 'components.nps_unlikely')
        this.question.endLabel = translator.translateThisText(this.question.language, 'components.nps_very_likely')
      }
      if (questionType === 'csat' && (!this.question.startLabel && !this.question.endLabel)) {
        this.question.startLabel = translator.translateThisText(this.question.language, 'components.csat_not_satisfied')
        this.question.endLabel = translator.translateThisText(this.question.language, 'components.csat_satisfied')
      }
      if (isStart) {
        return this.question.startLabel
      }
      return this.question.endLabel
    }
  }
}
</script>
<style lang="scss">
// .binds-content-widget {
//   .content-10num {
//     width: 100%;
//   }
// }
</style>
