<template>
    <div v-html="embededCode"></div>
</template>
<script>
import { embedGenerator } from '@/_helpers'
export default {
  props: ['question'],
  data () {
    return {
      embededCode: ''
    }
  },
  mounted () {
    this.embededCode = embedGenerator.returnEmbedEnum(this.question)
    this.$emit('embedQuestion', this.embededCode)
  }
}
</script>
