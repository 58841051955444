<template>
  <div class="container-modal-binds">
    <div class="content-modal-binds">
      <div class="header-modal-binds">
        <p class="background-icon"><span class="exclamation-icon">!</span></p>
        <app-heading level="h6">Realmente deseja remover esse filtro ?</app-heading>
        <app-heading level="h7" class="subtitle-modal">Você está prestes a remover este filtro do seus favoritos.</app-heading>
      </div>
      <div class="footer-brand-remove">
        <button class="btn-brand-remove">
          <p v-if="!loading" @click="confirmAction()">Remover</p>
          <Circle2 v-else class="spinner-social"/>
        </button>
        <button class="btn-brand-remove cancel" @click="cancelAction()">Cancelar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { filtersService } from '@/_services'

export default {
  name: 'ModalBinds',
  props: ['filter'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      loading: false
    }
  },
  mounted () {
    const modal = document.querySelector('.content-modal-binds')
    setTimeout(() => { modal.classList.add('display') }, 100)
  },
  methods: {
    async confirmAction () {
      this.loading = true
      if (this.filter._id) {
        await filtersService.deleteCustomFilter(this.filter._id)
        setTimeout(() => {
          this.$root.$emit('update-favorite-filters')
          this.cancelAction()
        }, 1000)
      } else {
        this.$store.commit('alerts/alert', {
          message: 'Erro ao deletar o filtro',
          position: 'bottomRight',
          showAlert: true
        })
      }
    },
    cancelAction () {
      const modal = document.querySelector('.content-modal-binds')
      modal.classList.remove('display')
      setTimeout(() => { this.$emit('cancel-action') }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
.container-modal-binds {
  position: fixed;
  top: 0;
  left: 0;
  background: #00000030;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-modal-binds {
    opacity: 0;
    transition: opacity 0.3s;
    user-select: none;
    position: relative;
    width: 25%;
    height: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 5px;
    overflow: hidden;
    .header-modal-binds {
      width: 100%;
      height: 80%;
      border-bottom: 1px solid #cacaca;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .background-icon {
        width: 50px;
        height: 50px;
        background: #ff000021;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .exclamation-icon {
          width: 50%;
          height: 50%;
          border: 1px solid #ef5959;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 17px;
          font-weight: 500;
          color: #ef5959;
        }
      }
      h6 {
        text-align: center;
        font-weight: 500;
        color: #454548;
        margin: 15px 0 !important;
      }
      .subtitle-modal {
        color: #858585;
        font-size: 13px;
      }
    }
    .footer-brand-remove {
      width: 100%;
      height: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F0F2F5;
      .btn-brand-remove {
        width: 90px;
        height: 30px;
        margin: 0 5px;
        padding: 5px 7px;
        border-radius: 3px;
        background: var(--accent-color);
        color: var(--default-text-color);
        font-weight: 500;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        .spinner-social {
          width: 20px !important;
          height: 20px !important;
          border-width: 2px !important;
          border-color: var(--accent-color) var(--default-text-color) var(--default-text-color) !important;
        }
      }
      .cancel {
        background: #ffffff;
        color: var(--accent-color);
        border: 1px solid #cacaca;
        font-weight: 600;
      }
    }
  }
  .display {
    opacity: 1;
  }
}
</style>
