import i18n from '../translations/i18n'
import { findIndex } from 'lodash-es'

export const formatQuestionResult = {
  countRating,
  averageBarWithPercentage,
  panelStatsForFirstQuestion
}

function panelStatsForFirstQuestion (allOptionsData) {
  const groups = []
  const question = allOptionsData.firstQuestion
  let flowToFollow = []
  if (['10num', 'ynd'].indexOf(question.ui) > -1 && question.type !== 'enum') {
    flowToFollow = [{ value: 0, key: `${i18n.t('dashboard.graphics.detractors')}`, color: '#d81111' },
      { value: 10, key: `${i18n.t('dashboard.graphics.detractors')}`, color: '#d81111' },
      { value: 20, key: `${i18n.t('dashboard.graphics.detractors')}`, color: '#d81111' },
      { value: 30, key: `${i18n.t('dashboard.graphics.detractors')}`, color: '#d81111' },
      { value: 40, key: `${i18n.t('dashboard.graphics.detractors')}`, color: '#d81111' },
      { value: 50, key: `${i18n.t('dashboard.graphics.detractors')}`, color: '#d81111' },
      { value: 60, key: `${i18n.t('dashboard.graphics.detractors')}`, color: '#d81111' },
      { value: 70, key: `${i18n.t('dashboard.graphics.neutral')}`, color: '#ff9f11' },
      { value: 80, key: `${i18n.t('dashboard.graphics.neutral')}`, color: '#ff9f11' },
      { value: 90, key: `${i18n.t('dashboard.graphics.promoters')}`, color: '#24b34b' },
      { value: 100, key: `${i18n.t('dashboard.graphics.promoters')}`, color: '#24b34b' }]
    if (question.ui === 'ynd') {
      flowToFollow = [{ value: 0, key: `${i18n.t('dashboard.graphics.op2')}`, color: '#d81111' },
        { value: 50, key: `${i18n.t('dashboard.graphics.op4')}`, color: '#ff9f11' },
        { value: 100, key: `${i18n.t('dashboard.graphics.op1')}`, color: '#24b34b' }]
    }
    if (question.ui === 'yn') {
      flowToFollow = [{ value: 0, key: `${i18n.t('dashboard.graphics.op2')}`, color: '#d81111' },
        { value: 100, key: `${i18n.t('dashboard.graphics.op1')}`, color: '#24b34b' }]
    }
  }
  if (question.type === 'csat') {
    flowToFollow = [{ value: 0, key: `${i18n.t('dashboard.graphics.un')}`, color: '#d81111' },
      { value: 10, key: `${i18n.t('dashboard.graphics.un')}`, color: '#d81111' },
      { value: 20, key: `${i18n.t('dashboard.graphics.un')}`, color: '#d81111' },
      { value: 30, key: `${i18n.t('dashboard.graphics.un')}`, color: '#d81111' },
      { value: 40, key: `${i18n.t('dashboard.graphics.un')}`, color: '#d81111' },
      { value: 50, key: `${i18n.t('dashboard.graphics.un')}`, color: '#d81111' },
      { value: 60, key: `${i18n.t('dashboard.graphics.un')}`, color: '#d81111' },
      { value: 70, key: `${i18n.t('dashboard.graphics.un')}`, color: '#d81111' },
      { value: 80, key: `${i18n.t('dashboard.graphics.sat')}`, color: '#24b34b' },
      { value: 90, key: `${i18n.t('dashboard.graphics.sat')}`, color: '#24b34b' },
      { value: 100, key: `${i18n.t('dashboard.graphics.sat')}`, color: '#24b34b' }]
    if (['5emo', '5num'].indexOf(question.ui) > -1) {
      const labelOption = i18n.t('dashboard.graphics.op')
      const labels = question.ui === '5num' ? [labelOption + 1, labelOption + 2, labelOption + 3, labelOption + 4, labelOption + 5] : ['\uE608', '\uE607', '\uE606', '\uE600', '\uE603']
      flowToFollow = [{ value: 0, key: labels[0], color: '#673ab7' },
        { value: 25, key: labels[1], color: '#805ea0' },
        { value: 50, key: labels[2], color: '#966fbc' },
        { value: 75, key: labels[3], color: '#b97bf7' },
        { value: 100, key: labels[4], color: '#ce9eff' }]
    }
  }
  if (allOptionsData.questionData[0].questions[0].total > 0) {
    allOptionsData.questionData[0].questions[0].data.forEach((overtimeHeader, indexOfMe) => {
      if (indexOfMe > 0) {
        const rating = parseInt(overtimeHeader[0])
        flowToFollow.forEach(flow => {
          if (!groups[flow.key]) {
            groups[flow.key] = { doc_count: 0, color: flow.color }
          }
          if (rating === flow.value) {
            groups[flow.key].doc_count += overtimeHeader[1]
          }
        })
      }
    })
  }
  const graphicValues = []
  for (const groupName in groups) {
    graphicValues.push({ group: groupName, ...groups[groupName] })
  }
  return graphicValues ? {
    valueGroup: graphicValues,
    total: allOptionsData.questionData[0].questions[0].total,
    average: allOptionsData.questionData[0].questions[0].average
  } : []
}

// função que era usada para renderizar o gráfico matrixTable, que não será mais usado
// function matrixAverage (allQuestionsAverage) {
//   const result = []
//   if (allQuestionsAverage.data) {
//     allQuestionsAverage.data.forEach((el, i) => {
//       if (i === 0) {
//         return false
//       }
//       const index = findIndex(result, { subject: { label: el[0] } })
//       if (index < 0) {
//         result.push({
//           subject:
//           { label: el[0] },
//           value: [{ key: el[1], doc_count: el[2] }]
//         })
//         return false
//       }
//       result[index].value.push({ key: el[1], doc_count: el[2] })
//     })
//   }
//   return { values: result }
// }

function averageBarWithPercentage (questionAverage) {
  const result = []
  if (questionAverage.data) {
    questionAverage.data.forEach((option, index) => {
      if (index === 0) {
        return false
      }
      result.push({ key: option[0], doc_count: option[1], percentage: (option[1] / questionAverage.total) * 100 })
    })
  }
  return { options: result }
}
// enum/ 10num/ singles -> 2 campos de esforço
// kpi 5emo -> 5 campos de carinha kpi (bravo ate o feliz)
// kpi ynd -> 3 campos de (nao talvez sim)
// kpi yn -> 2 campos de (nao  sim)
// nes -> 3 campos de (muito esforço, neutros, pouco esforço)
// csat 5num -> 2 campos de Muito esforço, POuco esforço
// csat 10num -> 3 (muito esforço, , pouco esforço, neutros)
// nps 10num -> 3 (muito esforço, , pouco esforço, neutros)
// csat 5 emo -> 5 carinhas (brava até feliz)
function countRating (allQuestionsOverall, question) {
  let flowToFollow = [3] // -> o default já pega o type nes
  if (['5emo', '5num'].indexOf(question.ui) > -1) {
    const labelOption = i18n.t('dashboard.graphics.op')
    // const labels = question.ui === '5num' ? [labelOption + 1, labelOption + 2, labelOption + 3, labelOption + 4, labelOption + 5] : ['\uE92F', '\uE930', '\uE931', '\uE932', '\uE933']
    const labels = question.ui === '5num' ? [labelOption + 1, labelOption + 2, labelOption + 3, labelOption + 4, labelOption + 5] : ['\uE608', '\uE607', '\uE606', '\uE600', '\uE603']
    flowToFollow = [{ value: 0, key: labels[0] },
      { value: 25, key: labels[1] },
      { value: 50, key: labels[2] },
      { value: 75, key: labels[3] },
      { value: 100, key: labels[4] }]
  }

  if (['10num', 'ynd'].indexOf(question.ui) > -1 && question.type !== 'enum') {
    flowToFollow = [{ value: 0, key: `${i18n.t('dashboard.graphics.un')}` },
      { value: 10, key: `${i18n.t('dashboard.graphics.un')}` },
      { value: 20, key: `${i18n.t('dashboard.graphics.un')}` },
      { value: 30, key: `${i18n.t('dashboard.graphics.un')}` },
      { value: 40, key: `${i18n.t('dashboard.graphics.un')}` },
      { value: 50, key: `${i18n.t('dashboard.graphics.un')}` },
      { value: 60, key: `${i18n.t('dashboard.graphics.un')}` },
      { value: 70, key: `${i18n.t('dashboard.graphics.un')}` },
      { value: 80, key: `${i18n.t('dashboard.graphics.sat')}` },
      { value: 90, key: `${i18n.t('dashboard.graphics.sat')}` },
      { value: 100, key: `${i18n.t('dashboard.graphics.sat')}` }]
    if (question.ui === 'ynd') {
      flowToFollow = [{ value: 0, key: `${i18n.t('dashboard.graphics.op2')}` },
        { value: 50, key: `${i18n.t('dashboard.graphics.op4')}` },
        { value: 100, key: `${i18n.t('dashboard.graphics.op1')}` }]
    }
  }

  if (['10num'].indexOf(question.ui) > -1 && question.type === 'nes') {
    flowToFollow = [{ value: 1, key: `${i18n.t('dashboard.graphics.detractor')}` },
      { value: 2, key: `${i18n.t('dashboard.graphics.detractor')}` },
      { value: 3, key: `${i18n.t('dashboard.graphics.detractor')}` },
      { value: 4, key: `${i18n.t('dashboard.graphics.promoter')}` },
      { value: 5, key: `${i18n.t('dashboard.graphics.promoter')}` }]
  }

  if (['yn'].indexOf(question.ui) > -1) {
    flowToFollow = [{ value: 0, key: `${i18n.t('dashboard.graphics.op2')}` },
      { value: 100, key: `${i18n.t('dashboard.graphics.op1')}` }]
  }

  if (question.type === 'matrix') {
    flowToFollow = question.subjects.map(el => {
      return { subject: el }
    })
    flowToFollow.forEach(el => {
      const values = [...Array(100).keys()]
      el.value = values.map(el2 => {
        return { key: el2, doc_count: 0 }
      })
    })
  }

  if (question.type === 'enum') {
    flowToFollow = question.options.map(option => {
      return { value: option.label, key: option.label }
    })
  }
  const countValues = {}
  allQuestionsOverall.forEach(el => {
    el.responses.forEach(response => {
      if (response.question._id === question._id) {
        // questions que possuem rating, não abrange o matrix
        if (question.type !== 'matrix') {
          const rating = question.type !== 'enum' ? parseInt(response.rating) : response.value
          flowToFollow.forEach(flow => {
            const checkIfExist = question.type !== 'enum' ? flow.key : rating
            if (!countValues[checkIfExist]) {
              countValues[checkIfExist] = 0
            }
            if (rating === flow.value) {
              countValues[checkIfExist] += 1
            }
          })
          return false
        }
        const values = response.value
        flowToFollow.forEach(flow => {
          values.forEach(value => {
            if (value._id === flow.subject._id) {
              const index = findIndex(flow.value, { key: value.rating })
              if (index > -1) {
                flow.value[index].doc_count += 1
              }
            }
          })
        })
      }
    })
  })
  const result = []
  if (question.type === 'matrix') {
    flowToFollow.forEach(el => {
      el.value = el.value.filter(el2 => {
        return el2.doc_count > 0
      })
    })
    return [flowToFollow]
  }
  Object.keys(countValues).forEach(elementKey => {
    if (elementKey !== 'undefined') {
      result.push({ key: elementKey, doc_count: countValues[elementKey] })
    }
  })
  return { valueGroup: result }
}
