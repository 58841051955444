<template>
  <div class="container-cards-health">
    <div class="content-loading-dashboard-health" v-if="loadingDashboardHealth">
      <Circle2 class="spinner-dashboard-health"/>
    </div>
    <CardDashboardHealth
      v-if="healthCard.average"
      :card="healthCard"
      :doubleWidth="false"
      :syntheticHealth="syntheticHealth"
      :overtimeHealth="overtimeHealth"
      :overallHealth="overallHealth"
      @edit-card="newCardHealthCustom(healthCard)"
      @get-data-health="getOvertimeOrOverallData($event)"
    />
    <CardDashboardHealth
      v-if="healthChurnCard.average"
      :isChurn="true"
      :card="healthChurnCard"
      :doubleWidth="false"
      :syntheticHealth="syntheticChurnHealth"
      :overtimeHealth="overtimeChurnHealth"
      :overallHealth="overallChurnHealth"
      @edit-card="newCardHealthCustom(healthChurnCard)"
      @get-data-health="getOvertimeOrOverallData($event)"
    />
  </div>
</template>

<script>
import { healthService } from '@/_services'
import CardDashboardHealth from '../../inputs/CardDashboardHealth.vue'
import gsap from 'gsap'

export default {
  name: 'DashboardHealth',
  props: ['dataHealth'],
  components: {
    CardDashboardHealth,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      healthCard: { title: this.$t('health_score.title_card_score'), type: 'score' },
      healthChurnCard: { title: this.$t('health_score.title_card_churn'), type: 'churn' },
      loadingDashboardHealth: true,
      syntheticHealth: [],
      overtimeHealth: [],
      overallHealth: [],
      syntheticChurnHealth: [],
      overtimeChurnHealth: [],
      overallChurnHealth: []
    }
  },
  created () {
    this.healthCard = { ...this.healthCard, ...this.cardDefault }
    this.healthChurnCard = { ...this.healthChurnCard, ...this.cardDefault }
  },
  mounted () {
    if (this.healthCard) { this.loadingDone() }
  },
  computed: {
    currentPage () { return this.$store.getters['dashboard/getCurrentPage'] },
    handleQuery () { return this.$route.query.q },
    displayHealth () { return this.$store.getters['overview/displayHealth'] },
    cardDefault () {
      return {
        total: this.dataHealth?.count,
        average: this.dataHealth,
        overtime: [],
        overall: [],
        _id: new Date().getTime(),
        viewType: this.currentPage
      }
    }
  },
  watch: {
    displayHealth: {
      handler (newVal) {
        const container = document.querySelector('.container-cards-health')

        const display = newVal ? 'flex' : 'none'

        gsap.set(container, { display: display })
      }
    }
  },
  methods: {
    async getOvertimeOrOverallData (payload) {
      let whereToGet

      if (payload.isChurn) {
        whereToGet = payload.view === 'overtime' ? 'getOvertimeChurn' : 'getOverallChurn'
        const result = await healthService[whereToGet](this.handleQuery)
        this.healthChurnCard[payload.view] = result
        return
      }

      whereToGet = payload.view === 'overtime' ? 'getOvertimeOverview' : 'getOverallOverview'
      const result = await healthService[whereToGet](this.handleQuery)
      this.healthCard[payload.view] = result
    },
    newCardHealthCustom (card) {
      this.$emit('new-card-custom-health', card)
    },
    loadingDone () {
      const refLoading = document.querySelector('.content-loading-dashboard-health')

      if (!refLoading) return

      gsap.to(refLoading, {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          this.loadingDashboardHealth = false

          return this.$emit('show-button-hide-health')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-cards-health {
  position: relative;
  display: flex;
  gap: 0.5vw;
  .content-loading-dashboard-health {
    position: absolute;
    z-index: 30;
    top: 0;
    left: 0;
    background: #ffffff;
    border-radius: 0.8rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    .spinner-dashboard-health {
      width: 2vw !important;
      height: 2vw !important;
      border-width: 3px !important;
      border-color: var(--accent-color) #cacaca #cacaca !important;
    }
    .title-loading-card-dashboard {
      font-size: 0.65vw;
      color: #cacaca;
      font-weight: 500;
    }
  }
}
</style>
