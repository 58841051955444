<template>
  <div class="container-dashboard-overview">
    <div class="loading-dashboard-overview" v-show="loadingOverview">
      <Circle2 class="spinner-dashboard-overview"/>
    </div>
    <div class="container-auto-scroll" v-if="tvDash">
      <div class="content-toggle-auto-scroll">
        <app-toggle-input v-model="isScrollAllowed" class="toggle-auto-scroll"></app-toggle-input>
        <app-heading class="title-auto-scroll" level="h6">{{ $t('real_time.auto_scroll') }}</app-heading>
      </div>
    </div>

    <div class="container-cards-overview">
      <div class="container-hide-or-show exclude-on-print" v-if="!noDataHealth" @click="handlerContainerHealth()" v-title="!displayHealth ? $t('health_score.tooltip_show_health') : $t('health_score.tooltip_hide_health')" title-placement="right">
        <i class="material-symbols-outlined icon-heart-hide-or-show">favorite</i>
        <i class="material-symbols-outlined icon-pen-hide-or-show" v-if="displayHealth">pen_size_1</i>
      </div>

      <DashboardHealth
        v-if="dataHealth && displayHealth && !noDataHealth"
        :dataHealth="dataHealth"
        @new-card-custom-health="newCardCustom($event, true)"
      />

      <CardDashboard v-for="(card, idx) in dataCard"
        :key="idx"
        :card="card"
        :allQuestionsSynthetic="allQuestionsSynthetic"
        :allQuestionsOvertime="allQuestionsOvertime"
        :allQuestionsOverall="allQuestionsOverall"
        @edit-card="newCardCustom(card)"
      />
    </div>

    <NewCardCustom
      v-if="cardSelected && showSidebarConfig"
      @close-sidebar-edit="showSidebarConfig = false"
      :card="cardSelected"
      @update-dashboard-custom="closeSideBarConfig(true)"
    />
  </div>
</template>

<script>
import gsap from 'gsap'
import { healthService } from '@/_services'
import CardDashboard from '../../inputs/CardDashboard.vue'
import NewCardCustom from '../dashboardCustom/NewCardCustom.vue'
import DashboardHealth from './DashboardHealth.vue'
import mixins from '@/_mixins/pages'

export default {
  name: 'DashboardOverview',
  props: ['dataCard', 'tvDash', 'allQuestionsSynthetic', 'allQuestionsOvertime', 'allQuestionsOverall', 'survey'],
  mixins: [mixins],
  components: {
    CardDashboard,
    NewCardCustom,
    DashboardHealth,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2'),
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      loadingOverview: false,
      isScrollAllowed: false,
      openEditCard: false,
      cardSelected: null,
      showSidebarConfig: false,
      noDataHealth: false,
      dataHealth: null
    }
  },
  mounted () {
    if (this.dataCard.length) { this.getHealthScoreData() }
  },
  computed: {
    currentPage () { return this.$store.getters['dashboard/getCurrentPage'] },
    displayHealth () { return this.$store.getters['overview/displayHealth'] },
    handleQuery () { return this.$route.query.q }
  },
  watch: {
    tvDash: {
      immediate: true,
      handler (newVal) {
        this.toggleAutoScroll(newVal, this.isScrollAllowed)
      }
    },
    isScrollAllowed: {
      immediate: true,
      handler (newVal) {
        this.toggleAutoScroll(this.tvDash, newVal)
      }
    },
    currentPage: {
      handler () {
        this.setLoading()
        setTimeout(() => { this.setLoading(true) }, 200)
      }
    }
  },
  methods: {
    handlerContainerHealth () { return this.$store.commit('overview/setDisplayHealth', !this.displayHealth) },
    showButtonHealth () {
      const divHideOrShow = document.querySelector('.container-hide-or-show')

      gsap.to(divHideOrShow, {
        opacity: 1,
        display: 'flex',
        duration: 0.5
      })
    },
    closeSideBarConfig (event) {
      if (event) { this.$emit('show-sidebar-dash-custom') }
      this.showSidebarConfig = false
    },
    newCardCustom (cardItem, isHealth) {
      this.cardSelected = { ...cardItem, isHealth }
      this.showSidebarConfig = true
    },
    setLoading (close) {
      if (!close) { this.loadingOverview = true }

      const opacity = close ? 0 : 1
      const divLoading = document.querySelector('.loading-dashboard-overview')
      gsap.to(divLoading, {
        opacity,
        duration: 0.5,
        onComplete: () => {
          if (close) {
            this.loadingOverview = false
            setTimeout(() => { this.showButtonHealth() })
          }
        }
      })
    },
    async getHealthScoreData () {
      try {
        const result = await healthService.getSyntheticOverview(this.handleQuery)

        if (!Object.keys(result).length) {
          this.noDataHealth = true
          return this.setLoading(true)
        }

        this.dataHealth = result
      } catch (error) {
        console.error('Error fetching health data:', error)
      } finally {
        this.setLoading(true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-dashboard-overview {
  margin: 1rem 4.3vw;
  padding: 0.1vw;
  .container-cards-overview {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.5vw;
    .container-hide-or-show {
      cursor: pointer;
      user-select: none;
      position: absolute;
      opacity: 0;
      width: 3vw;
      height: 2.5vw;
      background: #ffffff;
      left: -5vw;
      border-radius: 0 1.5vw 1.5vw 0;
      box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.06);
      border: 1px solid #e1e1e1;
      display: none;
      align-items: center;
      justify-content: center;
      color: #454548;
      .icon-heart-hide-or-show {
        font-size: 1.4vw;
      }
      .icon-pen-hide-or-show {
        position: absolute;
        font-size: 1.8vw;
        top: 0.15vw;
        right: 0.5vw;
      }
    }
  }

  .container-auto-scroll {
    background: #ffffff;
    margin: 1rem 0;
    height: 2.5vw;
    padding: 0 1vw;
    border-radius: 8px;
    box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .content-toggle-auto-scroll {
      width: fit-content;
      display: flex;
      gap: 3rem;
      .toggle-auto-scroll {
        position: relative;
      }
      .title-auto-scroll {
        margin: 0;
        color: #808080;
        width: fit-content;
      }
    }
  }
  .loading-dashboard-overview {
    background: #EFF3F890;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 36;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner-dashboard-overview {
      width: 2.1vw !important;
      height: 2.1vw !important;
      border-width: 3px !important;
      border-radius: 35px;
      border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
    }
  }
}
</style>
