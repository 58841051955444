function initialState () {
  return {
    seeds: [],
    headers: {},
    sendingType: null
  }
}

const state = initialState

const mutations = {
  resetToInitialState (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  seedsField (state, seedsField) {
    Object
      .keys(seedsField)
      .forEach(key => {
        const value = seedsField[key]
        state[key] = value
      })
  }
}

const getters = {
  getSeeds: state => state.seeds,
  getHeaders: state => state.headers,
  getSendingType: state => state.sendingType
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
