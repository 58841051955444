<template>
  <div class="purpleCard">
    <carousel
    :perPageCustom="[[2400, 7], [2880, 7], [2100, 6], [1920, 6], [1700, 5], [1500, 4], [1200, 3]]"
      :navigationEnabled="true"
      :paginationEnabled="false"
      navigationNextLabel="<i class='material-icons'>keyboard_arrow_right</i>"
      navigationPrevLabel="<i class='material-icons'>keyboard_arrow_left</i>">
      <slide v-for="item in list" :key="item.index">
        <app-card
          v-title="item.warn"
          style="padding: 20px 20px !important"
          v-on:click.native="selectHub(item.type)"
          v-bind:class="{
            'active-hub': active === item.type,
            'no-click': ((item.type === 'sms' || item.type === 'emailSms') && !user.account.smsEnabled) || (item.type === 'whatsapp' && !user.account.features.whatsApp)
          }">
          <app-icon v-if="item.type !== 'whatsapp'" :name="item.iconName" color="color-purple"></app-icon>
          <div class="send-icon-whatsapp" v-else>
            <div class="ball-icon-wpp">
              <i class="material-icons icon-img-wpp" style="font-family: 'Material Icons'">call</i>
            </div>
          </div>
          <app-heading level="h6">{{ item.text }}</app-heading>
        </app-card>
      </slide>
    </carousel>
  <theme-style>
    .color-purple {
      color: {{user.account.theme.colors.secondary || '#64469d' }} !important;
    }
    .active-hub {
      box-shadow: 0 5px 15px 0 {{user.account.theme.colors.secondary || '#64469d'}} 50% !important;
      background-color: {{user.account.theme.colors.secondary || '#64469d'}} !important;
    }
    .active-hub > h6, .active-hub > i {
      color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
    }
    .VueCarousel-navigation-button i {
      color: {{user.account.theme.colors.secondary || '#64469d' }} !important;
    }
  </theme-style>
  </div>
</template>

<script>
import { sendhubService } from '@/_services'
import { Carousel, Slide } from 'vue-carousel'
import { mapGetters } from 'vuex'

export default {
  name: 'SendhubNav',
  props: ['list'],
  components: {
    Carousel,
    Slide,
    'app-icon': () => import('@binds-tech/binds-design-system/src/components/Icon/Icon'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      active: 'email'
    }
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser',
      survey: 'survey/getSurvey',
      account: 'account/getAccount',
      emailSettings: 'sendhub/getEmailSettings'
    })
  },
  mounted () {
    this.list.map(i => {
      // se o item for sms, emailSms, ou whatsApp e não tiver a opção habilitada na conta, acrescenta o warn para o tooltip
      if (((i.type === 'sms' || i.type === 'emailSms') && !this.user.account.smsEnabled) || (i.type === 'whatsapp' && !this.user.account.features.whatsApp)) {
        i.warn = this.$i18n.t('sendhub.sms_warn')
      }
    })
  },
  methods: {
    async selectHub (type) {
      const messagesId = this.survey.message._id
      const surveyHashId = this.survey.hashId
      const linkSettings = this.$store.getters['sendhub/getLinkSettings']
      const totenSettings = this.$store.getters['sendhub/getTotenSettings']
      this.$root.$emit('loadingSelectedHub', true)
      const totenLink = await sendhubService.mountLink(surveyHashId, totenSettings)
      const link = await sendhubService.mountLink(surveyHashId, linkSettings)

      if (type === 'link') {
        this.$store.commit('sendhub/sendhub', { link: link })
      }

      if (type === 'toten') {
        this.$store.commit('sendhub/sendhub', { totenLink: totenLink })
      }

      if ((type === 'sms' || type === 'emailSms') && this.user.account.smsEnabled) {
        if (this.survey && this.survey.message) {
          const sms = await sendhubService.getSms(messagesId)
          this.$store.commit('sendhub/sendhub', { sms: sms })
        } else {
          this.$store.commit('alerts/alert', {
            message: this.$i18n.t('sendhub.error_messages'),
            position: 'bottomRight',
            showAlert: true
          })
        }
      }

      if (type === 'whatsapp' && this.user.account.features.whatsApp) {
        if (this.survey && this.survey.message) {
          const whats = await sendhubService.getWhats(messagesId) || {}
          const getMessageType = (message) => {
            if (message?.type) return message.type
            if (message?.components && message.components.some(component => component.type === 'HEADER' && component.format === 'IMAGE')) return 'image'
            if (message?.components && message.components.some(component => component.type === 'BUTTONS' && component?.buttons.length >= 2)) return 'button'
            return 'text'
          }
          whats.type = getMessageType(whats)

          // fix para contas com o body antigo sem "params"
          if (!whats?.params) {
            whats.type = 'text'
            whats.params = [
              {
                type: 'body',
                index: 1,
                value: this.$i18n.t('whatsapp.hello') + ' *|_name|*'
              }
            ]
          }

          this.$store.commit('sendhub/updateTemplateWpp', whats)
          this.$store.commit('sendhub/updateWpp', whats)
        } else {
          this.$store.commit('alerts/alert', {
            message: this.$i18n.t('sendhub.error_messages'),
            position: 'bottomRight',
            showAlert: true
          })
        }
      }

      this.$root.$emit('loadingSelectedHub', false)

      // só atualiza a classe active e emit o evento caso: não for sms, ou for e tiver essa opção habilitada na conta
      const otherTypes = !['sms', 'emailSms', 'whatsapp'].includes(type)
      const smsEnabled = (['sms', 'emailSms'].includes(type) && this.user.account.smsEnabled)
      const whatsAppEnabled = (type === 'whatsapp' && this.user.account.features.whatsApp)
      if (otherTypes || smsEnabled || whatsAppEnabled) {
        this.active = type
        this.$emit('selectHub', type)
      }
    }
  }
}
</script>
<style lang="scss">
.send-icon-whatsapp {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  .ball-icon-wpp {
    width: 45px;
    height: 45px;
    background: var(--secondary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-img-wpp {
      font-size: 30px;
      color: var(--default-text-color);
    }
  }
}
  .no-click {
    background-color: #b9b9b961 !important;
    cursor: not-allowed;
  }
  .purpleCard {
    width: 95%;
    padding-top: 20px;
    margin: 0 auto;
    & .card {
      cursor: pointer;
      height: 129px;
      margin: 5px;
    }
    & h6 {
      width: 175px;
      height: 44px;
      margin-top: 12px !important;
      font-weight: 600 !important;
      line-height: 1.38 !important;
    }
    .VueCarousel {
      .VueCarousel-wrapper {
        .VueCarousel-inner {
          .VueCarousel-slide {
            width: 210px !important;
          }
        }
      }
    }
  }
  .active-hub{
    .ball-icon-wpp {
      background: #fff;
      .icon-img-wpp {
        color: var(--secondary-color) !important;
      }
    }
    & h6 {
      color: #fff !important;
    }
  }
  .VueCarousel-navigation{
    button{
      padding: 0px !important;
      margin-left: 0px !important;
      i{
        font-size: 40px;
        color: $color-purple;
      }
      &:active{
        outline: none;
      }
    }
  }
</style>
