import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

export const exportService = {
  signWithRedirectRoute,
  isToExportOnBrowser,
  exportTextInsights
}

async function signWithRedirectRoute (body) {
  const signUrl = `${API_URL}sign`
  const result = await callApiService.callApi(signUrl, 'post', body)
  return result
}

async function exportTextInsights (body) {
  let exportInsightUrl = `${API_URL}insights/text-export?`

  const paramsBody = Object.keys(body)

  const convertedParams = paramsBody.filter(key => body[key].length > 0).map(key => `${key}=${body[key]}`).join('&')

  exportInsightUrl = `${exportInsightUrl}${convertedParams}`

  const result = await callApiService.callApi(exportInsightUrl, 'get', body)
  return result
}

/**
 * check if the user is to export on browser
 * @param {Object} params - contains the query params
 * @returns Error or start the export
 */
function isToExportOnBrowser (params) {
  const exportUrl = `${API_URL}where-to-export${params}`
  return callApiService.callApi(exportUrl, 'get')
}
