<template>
  <div class="content-0to5">
    <app-form-rate
      v-model="inputValue"
      :items="options"
      item-value="value"
      item-text="label"
      :isGradient="question.gradient"
      :min-label="question.startLabel"
      :max-label="question.endLabel"
      :textColor="colors.actionText"
      :labelColor="colors.splashColor"
      :bgColor="colors.actionColor"
      :hoverColor="colors.buttonHover"
    />
  </div>
</template>

<script>
import { gradientMaker } from '@/_helpers'
import appFormRate from '@binds-tech/binds-design-system/src/components/Form/Rate'

export default {
  name: 'app0to5',
  props: ['question', 'colors'],
  components: {
    appFormRate
  },
  data () {
    return {
      inputValue: null,
      options: [
        { label: '0', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 }
      ]
    }
  },
  created () {
    gradientMaker.checkGradientAndReturnTheColors(this.question, this.options)
  }
}
</script>
<style lang="scss">
.content-0to5 {
  padding-bottom: 20px;
}
</style>
