<template>
  <div class="content-5nes">
    <app-form-rate
      v-model="inputValue"
      :items="items"
      item-value="id"
      item-text="id"
      :isGradient="question.gradient"
      :textColor="colors.actionText"
      :labelColor="colors.splashColor"
      :hoverColor="colors.buttonHover"
      :bgColor="colors.actionColor"
      :min-label="t(true)"
      :max-label="t(false)"
    />
  </div>
</template>

<script>
import { translator, gradientMaker } from '@/_helpers'

export default {
  name: 'app5nes',
  props: ['colors', 'question'],
  components: {
    'app-form-rate': () => import('@binds-tech/binds-design-system/src/components/Form/Rate')
  },
  data () {
    return {
      inputValue: null,
      items: [
        { label: '1', value: 1, id: 1 },
        { label: '2', value: 2, id: 2 },
        { label: '3', value: 3, id: 3 },
        { label: '4', value: 4, id: 4 },
        { label: '5', value: 5, id: 5 }
      ]
    }
  },
  created () {
    if (!this.question.options) {
      this.question.options = this.items
    }
    gradientMaker.checkGradientAndReturnTheColors(this.question, this.items)

    this.$root.$on('language-update', (language, id) => {
      if (this.question.id === id) {
        this.question.startLabel = translator.translateThisText(language, 'components.nes_little_effort')
        this.question.endLabel = translator.translateThisText(language, 'components.nes_big_effort')
      }
    })
  },
  methods: {
    t (isStart) {
      // Não remover segunda parte da condição. Foi feita para lidar com pesquisas vindas da v1.
      if (this.question.type === 'nes' && (!this.question.startLabel && !this.question.endLabel)) {
        this.question.startLabel = translator.translateThisText(this.question.language, 'components.nes_little_effort')
        this.question.endLabel = translator.translateThisText(this.question.language, 'components.nes_big_effort')
      }
      if (isStart) {
        return this.question.startLabel
      }
      return this.question.endLabel
    }
  }
}
</script>
<style lang="scss">
.content-5nes {
  padding-bottom: 20px;
}
</style>
