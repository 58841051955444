<template>
  <div class="container-synthetic">
    <app-heading level="h4" class="title-synthetic">{{ $t('bvs.sentiment_synthetic_title') }}</app-heading>
    <div class="section-synthetic" v-if="!dataAlert">
      <div class="item-synthetic" v-for="(item, idx) in data" :key="idx">
        <div class="name-synthetic">
          <p class="name-term">{{ item.brand.type ? item.brand[item.brand.type].name : item.brand.name }}</p>
          <p class="name-screen" v-if="[item.brand.name, item.brand.type ] === 'twitter'">@{{ item.brand[item.brand.type].name }} <i class="material-icons icon-term-verified" v-if="item.brand[item.brand.type].verified">verified</i></p>
        </div>
        <div class="infos-metrics">
          <div class="square-bvs">
            <div class="name-and-count">
              <p class="item-name">{{ $t('bvs.sentiment_bvs') }}</p>
              <p class="item-count">{{ item.bvs.toFixed(1) }}</p>
            </div>
            <i class="material-icons icon-square"
            :style="{'color':
                item.bvs.toFixed(1) <= 19.9 ? '#ea484d':
                item.bvs.toFixed(1) <= 39.9 ? '#EF874C':
                item.bvs.toFixed(1) <= 59.9 ? '#f8c43d':
                item.bvs.toFixed(1) <= 79.9 ? '#9FCD35':
                '#57AD26'}"
            >{{ returnEmotion(item.bvs.toFixed(1)) }}</i>
          </div>
          <div class="square-nps">
            <div class="name-and-count">
              <p class="item-name">{{ $t('bvs.sentiment_nps') }}</p>
              <p class="item-count">{{ item.nps.toFixed(1) }}</p>
            </div>
            <i class="material-icons icon-square"
              :style="{'color':
                item.nps.toFixed(1) <= -49.9 ? '#EA484D':
                item.nps.toFixed(1) <= -1 ? '#EF874C':
                item.nps.toFixed(1) <= 49.9 ? '#F8C43D':
                '#9FCD35'}"
            >speed</i>
          </div>
          <div class="square-mentions">
            <div class="name-and-count">
              <p class="item-name">{{ $t('bvs.sentiment_mentions') }}</p>
              <p class="item-count">{{ item.totalMentions }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-loading" v-if="isLoading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <div class="alert-data" v-if="!isLoading && dataAlert">
      <i class="material-icons" style="margin: 0 10px 2px 0">error</i>
      <app-heading level="h5">{{ $t('bvs.no_data') }}</app-heading>
    </div>
  </div>
</template>

<script>
import { socialMediaService } from '@/_services'
import { gridMaker } from '@/_helpers'

export default {
  name: 'AverageByBrand',
  props: [],
  components: {
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      typeObject: '',
      dataChart: null,
      data: null,
      dataAlert: false,
      isLoading: true
    }
  },
  mounted () {
    this.dataChart = this.$route.query
    this.updateGraphicAverage()
  },
  computed: {
    lang () {
      return this.$store.getters['account/getLang']
    },
    colors () {
      const colors = this.$store.getters['account/getAccount'].theme.colors
      return colors
    }
  },
  methods: {
    async updateGraphicAverage () {
      const gridRange = gridMaker.getGrid(this.dataChart.startDate, this.dataChart.endDate)
      const data = await socialMediaService.searchSyntethicMentions({
        gridRange,
        brandId: this.dataChart.terms || '',
        startDate: this.dataChart.startDate,
        endDate: this.dataChart.endDate,
        text: this.dataChart.text || '',
        sentiment: this.dataChart.sentiment
      })
      if (data.length > 0) {
        this.data = data
        this.isLoading = false
        this.dataAlert = false
      } else {
        this.isLoading = false
        this.dataAlert = true
      }
    },
    returnEmotion (value) {
      let emo
      if (value <= 19.9) {
        emo = 'sentiment_very_dissatisfied'
        return emo
      }
      if (value <= 39.9) {
        emo = 'sentiment_dissatisfied'
        return emo
      }
      if (value <= 59.9) {
        emo = 'sentiment_neutral'
        return emo
      }
      if (value <= 79.9) {
        emo = 'sentiment_satisfied'
        return emo
      }
      if (value <= 100) {
        emo = 'sentiment_very_satisfied'
        return emo
      }
    }
  }
}
</script>
<style lang="scss">
.container-synthetic {
  overflow: hidden;
  width: 49.8%;
  height: 500px;
  padding: 15px;
  border-radius: 10px;
  background: #ffffff70;
  border: 1px solid #dbdbdb;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  .title-synthetic {
    text-align: center;
  }
  .section-synthetic {
    margin-top: 20px;
    overflow-y: auto;
    height: 92%;
    .item-synthetic {
      margin-bottom: 10px;
      height: 50px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      background: #ffffff70;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      .name-synthetic {
        width: 40%;
        height: 50px;
        border-radius: 5px;
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .name-term {
          font-size: 16px;
          color: #424242;
          font-weight: bold;
        }
        .name-screen {
          font-size: 14px;
          margin-top: 3px;
          color: gray;
          display: flex;
          align-items: center;
          .icon-term-verified {
            font-size: 15px;
            margin-left: 5px;
            color: #3a9eea;
          }
        }
      }
      .infos-metrics {
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .square-mentions, .square-nps, .square-bvs {
          width: 30%;
          height: 50px;
          display: flex;
          .name-and-count {
            color: gray;
            width: 70%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            .item-name {
              font-size: 13px;
            }
            .item-count {
              color: #424242;
              font-weight: bold;
            }
          }
          .icon-square {
            width: 30%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  .container-loading, .alert-data {
    background: #ffffff;
    color: #151515;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
