<template>
  <div style="margin: 5vw">
    <div class="input-field">
      <div class="linearGradient">
        <input
          class="login-form-input-field"
          v-model="email"
          type="text"
          id="name"
          required
        />
        <label class="login-form-label-field" for="name">E-mail:</label>
      </div>
    </div>
    <button
      id="signUpButton"
      @click="resetPass()"
    >
      {{$t('login.send')}}
    </button>
    <div>
      <span class="loginActionLinks" @click="$root.$emit('update-current-active', 0)">{{$t('login.back')}}</span>
    </div>
  </div>
</template>
<script>
import { userService } from '@/_services'

export default {
  name: 'ResetPassForm',
  data () {
    return {
      email: ''
    }
  },
  methods: {
    showMessage (message) {
      this.$store.commit('alerts/alert', {
        message: message,
        showAlert: true
      })
    },
    async resetPass (e) {
      if (!this.email) {
        return false
      }
      this.$root.$emit('set-login-loading', true)
      try {
        const result = await userService.resetpass({ email: this.email })
        if (!result.message) {
          this.showMessage(this.$t('resetpass.email_sent'))
        } else {
          this.showMessage(result.message)
        }
        this.$root.$emit('set-login-loading', false)
        return false
      } catch (e) {
        this.$root.$emit('set-login-loading', false)
        console.log('Error on login request', e)
      }
    }
  }
}
</script>
