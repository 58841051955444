export const iconFormatter = {
  getSharedIcons
}

function getSharedIcons (lang = 'pt-BR') {
  const shared = [
    {
      type: 'csat',
      icon: 'insert_emoticon',
      tooltip: 'survey.components.csat',
      ui: [
        {
          name: '5emo',
          image:
            lang === 'pt-BR'
              ? './img/pt/5emo-pt.png'
              : lang === 'es-CL'
                ? './img/es/5emo-es.png'
                : './img/en/5emo-en.png'
        },
        { name: '5num', image: './img/1to5.png', isStar: false },
        { name: '10num', image: './img/10num.png' },
        { name: '5num', image: './img/5num-stars.png', isStar: true }
      ]
    },
    {
      type: 'nps',
      icon: 'dashboard',
      tooltip: 'survey.components.nps',
      ui: [
        { name: '10num', image: './img/10num.png' },
        {
          name: 'ynm',
          image:
            lang === 'pt-BR'
              ? './img/pt/ynm-pt.png'
              : lang === 'es-CL'
                ? './img/es/ynm-es.png'
                : './img/en/ynm.png'
        },
        {
          name: 'text',
          image:
            lang === 'pt-BR'
              ? './img/pt/text-pt.png'
              : lang === 'es-CL'
                ? './img/es/text-es.png'
                : './img/en/text.png',
          imgWidth: '100%'
        }
      ]
    },
    {
      type: 'kpi',
      icon: 'insert_chart',
      tooltip: 'survey.components.kpi',
      ui: [
        {
          name: 'yn',
          image:
            lang === 'pt-BR'
              ? './img/pt/yn-pt.png'
              : lang === 'es-CL'
                ? './img/es/ynm-es.png'
                : './img/en/yn.png'
        },
        {
          name: 'ynd',
          image:
            lang === 'pt-BR'
              ? './img/pt/ynd-pt.png'
              : lang === 'es-CL'
                ? './img/es/ynd-es.png'
                : './img/en/ynd.png'
        },
        {
          name: '5emo',
          image:
            lang === 'pt-BR'
              ? './img/pt/5emokpi-pt.png'
              : lang === 'es-CL'
                ? './img/es/5emokpi-es.png'
                : './img/en/5emokpi-en.png',
          imgWidth: '100%'
        }
      ]
    },
    {
      type: 'nes',
      icon: 'fitness_center',
      tooltip: 'survey.components.ces',
      ui: [
        { name: '5nes', image: './img/1to5.png' },
        {
          name: 'text',
          image:
            lang === 'pt-BR'
              ? './img/pt/text-pt.png'
              : lang === 'es-CL'
                ? './img/es/text-es.png'
                : './img/en/text.png',
          imgWidth: '100%'
        }
      ]
    },
    {
      type: 'ces',
      icon: 'fitness_center',
      tooltip: 'survey.components.ces',
      ui: [
        {
          name: '1to7',
          image:
            lang === 'pt-BR'
              ? './img/pt/ces-1to7.png'
              : lang === 'es-CL'
                ? './img/es/ces-1to7.png'
                : './img/en/ces-1to7.png',
          imgWidth: '100%'
        },
        {
          name: '1to7button',
          image:
            lang === 'pt-BR'
              ? './img/pt/ces-1to7button.png'
              : lang === 'es-CL'
                ? './img/es/ces-1to7button.png'
                : './img/en/ces-1to7button.png',
          imgWidth: '100%'
        }
      ]
    },
    {
      type: 'nvs',
      icon: 'volunteer_activism',
      tooltip: 'survey.components.nvs',
      ui: [
        {
          name: '5radio',
          image:
            lang === 'pt-BR'
              ? './img/pt/5radio-pt.png'
              : lang === 'es-CL'
                ? './img/en/5radio-en.png'
                : './img/es/5radio-es.png'
        }
      ]
    },
    {
      type: 'matrix',
      icon: 'format_list_numbered',
      tooltip: 'survey.components.matrix',
      ui: [
        {
          name: '10num',
          image:
            lang === 'pt-BR'
              ? './img/pt/matrix10num-pt.png'
              : lang === 'es-CL'
                ? './img/es/matrix10num-es.png'
                : './img/en/matrix10num.png',
          imgWidth: '100%'
        },
        {
          name: 'matrix1to5',
          image:
            lang === 'pt-BR'
              ? './img/pt/matrix1to5-pt.png'
              : lang === 'es-CL'
                ? './img/es/matrix1to5-es.png'
                : './img/en/matrix1to5.png'
        },
        {
          name: 'like',
          image:
            lang === 'pt-BR'
              ? './img/pt/matrixlike-pt.png'
              : lang === 'es-CL'
                ? './img/es/matrixlike-es.png'
                : './img/en/matrixlike.png'
        }
      ]
    },
    {
      type: 'text',
      icon: 'text_fields',
      tooltip: 'survey.components.text',
      ui: [
        {
          name: 'text',
          image:
            lang === 'pt-BR'
              ? './img/pt/text-pt.png'
              : lang === 'es-CL'
                ? './img/es/text-es.png'
                : './img/en/text.png',
          imgWidth: '100%',
          mask: null
        },
        { name: 'text', image: './img/input-email.png', mask: 'email' },
        {
          name: 'text',
          image:
            lang === 'pt-BR'
              ? './img/pt/input-text-pt.png'
              : lang === 'es-CL'
                ? './img/es/input-text-es.png'
                : './img/en/input-text.png',
          imgWidth: '100%',
          mask: 'text'
        },
        {
          name: 'text',
          image:
            lang === 'pt-BR'
              ? './img/pt/input-phone-pt.png'
              : lang === 'es-CL'
                ? './img/es/input-phone-es.png'
                : './img/en/input-phone.png',
          imgWidth: '100%',
          mask: 'tel'
        }
      ]
    },
    {
      type: 'referral',
      icon: 'share',
      tooltip: 'survey.components.referral',
      ui: [
        {
          name: 'referral',
          image:
            lang === 'pt-BR'
              ? './img/pt/referral-pt.png'
              : lang === 'es-CL'
                ? './img/es/referral-es.png'
                : './img/en/referral.png',
          imgWidth: '100%'
        },
        { name: 'referralDynamic', image: './img/input-email.png' }
      ]
    },
    {
      type: 'enum',
      icon: 'number',
      tooltip: 'survey.components.enum',
      ui: [
        {
          name: 'single',
          image:
            lang === 'pt-BR'
              ? './img/pt/single-pt.png'
              : lang === 'es-CL'
                ? './img/es/single-es.png'
                : './img/en/single.png'
        },
        {
          name: 'multiple',
          image:
            lang === 'pt-BR'
              ? './img/pt/multiple-pt.png'
              : lang === 'es-CL'
                ? './img/es/multiple-es.png'
                : './img/en/multiple.png'
        },
        { name: '0to5', image: './img/0to5.png' },
        { name: '1to5', image: './img/1to5.png' },
        { name: '0to10', image: './img/10num.png' },
        { name: '1to10', image: '/img/1to10.png' }
      ]
    }
  ]
  return shared
}
