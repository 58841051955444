<template>
  <div class="container-card-info-individual">
    <div class="item-card-info-individual">
      <i class="material-icons-outlined icon-item-card-info-individual">person</i>
      <p class="label-item-card-info-individual is-name">{{ cardInfo.name }}</p>
    </div>
    <div class="item-card-info-individual">
      <i class="material-icons-outlined icon-item-card-info-individual">mail</i>
      <p class="label-item-card-info-individual">{{ cardInfo.email }}</p>
    </div>
    <div class="item-card-info-individual">
      <i class="material-icons-outlined icon-item-card-info-individual">smartphone</i>
      <p class="label-item-card-info-individual">{{ cardInfo.phone }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndividualCardInfo',
  props: ['cardInfo'],
  components: {}
}
</script>

<style lang="scss" scoped>
.container-card-info-individual {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .item-card-info-individual {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    .icon-item-card-info-individual {
      font-size: 1.2vw;
      color: #5e5e5e;
    }
    .label-item-card-info-individual {
      color: #454548;
      font-size: 1vw;
    }
    .is-name {
      font-weight: 500;
      font-size: 0.95vw;
    }
  }
}
</style>
