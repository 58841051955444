<template>
  <div class="container-hashtags">
    <app-heading level="h4" class="title-hashtag">{{ $t('bvs.sentiment_word_cloud_title') }}</app-heading>
    <div class="section-word" v-if="typeWord === 'word'">
      <vue-word-cloud
        :animationDuration="5000"
        :animationEasing="'ease-in-out'"
        :animationOverlap="0.2"
        :enterAnimation="'animate__animated animate__bounceIn'"
        :leaveAnimation="'animate__animated animate__bounceOut'"
        :spacing="0.25"
        :font-size-ratio="5"
        :words="currentWords"
        :color="() => {
            return ['#ffd077', '#3bc4c7', '#3a9eea', '#ff4e69', '#461e47'][
              Math.floor(
                Math.random() *
                  ['#ffd077', '#3bc4c7', '#3a9eea', '#ff4e69', '#461e47'].length
              )
            ];
          }"
        font-family="Finger Paint">
        <template v-slot="props">
          <template>
            <div
              v-title="fulltexts[props.text]"
              @click="$root.$emit('search-by-clicked-text', props.text)"
              title-class-name="social-text-class"
              style="cursor: pointer;text-align: center; font-family: Finger Paint">
              {{props.text}}
            </div>
          </template>
        </template>
      </vue-word-cloud>
    </div>
    <div class="section-list" v-if="typeWord === 'list'">
      <div class="title-list-word">
        <app-heading level="h6" class="title-list">{{ $t('bvs.word_cloud_quote') }}</app-heading>
        <app-heading level="h6" class="title-list">{{ $t('bvs.word_cloud_amount') }}</app-heading>
        <app-heading level="h6" class="title-list">{{ $t('bvs.sentiment_bvs') }}</app-heading>
      </div>
      <div class="list-word">
        <div class="item-list-word" :style="{'background': `${colors.accent}21`}" v-for="(item, idx) in currentWords" :key="idx">
          <p class="name-list">{{ item[0] }}</p>
          <p class="qtd-list">{{ item[1].qty }}</p>
          <i class="material-icons icon-bvs"
            :style="{'color':
                item[1].bvs.toFixed(1) <= 19.9 ? '#ea484d':
                item[1].bvs.toFixed(1) <= 39.9 ? '#EF874C':
                item[1].bvs.toFixed(1) <= 59.9 ? '#f8c43d':
                item[1].bvs.toFixed(1) <= 79.9 ? '#9FCD35':
                '#57AD26'}"
            >{{ returnEmotion(item[1].bvs.toFixed(1)) }}</i>
        </div>
      </div>
    </div>
    <div class="options-word">
      <div class="type-word" :style="{'color': typeWord === 'word' ? colors.accent : 'gray' }" @click="changeType('word')">
        <i class="material-icons icon-word">cloud</i>
        <p>{{ $t('bvs.word_cloud') }}</p>
      </div>
      <div class="type-list" :style="{'color': typeWord === 'list' ? colors.accent : 'gray' }" @click="changeType('list')">
        <i class="material-icons icon-word">list_alt</i>
        <p>{{ $t('bvs.word_cloud_list') }}</p>
      </div>
    </div>
    <div class="container-loading" v-if="isLoading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <div class="alert-data" v-if="!isLoading && dataAlert">
      <i class="material-icons" style="margin: 0 10px 2px 0">error</i>
      <app-heading level="h5">{{ $t('bvs.no_data') }}</app-heading>
    </div>
  </div>
</template>

<script>
import VueWordCloud from 'vuewordcloud'
import { socialMediaService } from '@/_services'

export default {
  name: 'WordCloud',
  props: ['data', 'cardData', 'title'],
  components: {
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    [VueWordCloud.name]: VueWordCloud
  },
  data () {
    return {
      s: [],
      wordsx: [],
      currentWords: [],
      fulltexts: {},
      canvasId: '',
      dataChart: null,
      typeWord: 'word',
      isLoading: false,
      dataAlert: false
    }
  },
  mounted () {
    this.dataChart = this.$route.query
    this.updateHashtag()
  },
  computed: {
    colors () {
      const colors = this.$store.getters['account/getAccount'].theme.colors
      return colors
    }
  },
  methods: {
    async updateHashtag () {
      this.isLoading = true
      this.wordsx = await socialMediaService.searchHastags({
        brandId: this.dataChart.terms || '',
        startDate: this.dataChart.startDate,
        endDate: this.dataChart.endDate,
        text: this.dataChart.text || '',
        sentiment: this.dataChart.sentiment
      })
      const tags = {}
      this.wordsx.forEach(group => {
        for (const word of group.hashtags) {
          const letra = word._id.substring(0, 16)
          this.fulltexts[letra] = word._id
          tags[letra] = { qty: word.totalMentions, bvs: word.bvs }
        }
      })
      const resultTags = Object.entries(tags)
      const shortTags = resultTags.slice(0, 40)
      this.currentWords = shortTags.sort((a, b) => {
        if (a[1].qty < b[1].qty) {
          return 1
        }
        if (a[1].qty > b[1].qty) {
          return -1
        }
        return 0
      })

      this.dataAlert = false

      if (this.currentWords.length <= 0) {
        this.dataAlert = true
      }

      this.isLoading = false
    },
    changeType (type) {
      this.typeWord = type
    },
    returnEmotion (value) {
      let emo
      if (value <= 19.9) {
        emo = 'sentiment_very_dissatisfied'
        return emo
      }
      if (value <= 39.9) {
        emo = 'sentiment_dissatisfied'
        return emo
      }
      if (value <= 59.9) {
        emo = 'sentiment_neutral'
        return emo
      }
      if (value <= 79.9) {
        emo = 'sentiment_satisfied'
        return emo
      }
      if (value <= 100) {
        emo = 'sentiment_very_satisfied'
        return emo
      }
    }
  }
}
</script>
<style lang="scss">
.container-hashtags {
  position: relative;
  overflow: hidden;
  width: 49.8%;
  height: 500px;
  padding: 15px;
  border-radius: 10px;
  background: #ffffff70;
  border: 1px solid #dbdbdb;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  .title-hashtag {
    width: 100%;
    text-align: center;
    padding-bottom: 5px !important;
  }
  .section-word {
    width: 100%;
    height: 85%;
  }
  .section-list {
    width: 100%;
    height: 84%;
    border: 1px solid #cacaca;
    border-radius: 5px;
    margin-bottom: 4px;
    overflow: hidden;
    .title-list-word {
      width: 100%;
      height: 9%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-bottom: 1px solid #cacaca;
      .title-list {
        margin: 0 !important;
        padding: 0 !important;
        font-weight: bold;
        font-size: 17px;
        color: #151515;
      }
    }
    .list-word {
      width: 100%;
      height: 91%;
      overflow-y: auto;
      .item-list-word {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .name-list, .qtd-list {
          width: 33%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .icon-bvs {
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .name-list {
          font-size: 15px;
          color: #151515;
        }
        .qtd-list {
          font-size: 15px;
          color: gray;
        }
      }
      .item-list-word:nth-child(odd) {
        background: #ffffff !important;
      }
    }
  }
  .options-word {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    .icon-word {
      font-size: 30px;
    }
    .type-word, .type-list {
      height: 95%;
      width: 150px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-size: 13px;
        margin-top: 5px;
      }
    }
  }
  .container-loading, .alert-data {
    background: #ffffff;
    color: #151515;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
