import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

export const comparativeDashService = {
  getComparativeBarsChart,
  getComparativeOvertimeChart
}

async function getComparativeBarsChart (body) {
  const comparativeUrl = API_URL + 'metrics-panel'
  const result = await callApiService.callApi(comparativeUrl, 'post', body)
  return result
}

async function getComparativeOvertimeChart (body) {
  const comparativeUrl = API_URL + 'metrics-panel-overtime'
  const result = await callApiService.callApi(comparativeUrl, 'post', body)
  return result
}
