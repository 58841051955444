<template>
  <div class="container-responses-sidebar">
    <div class="content-response-sidebar" v-for="response in responses" :key="response.response._id">
      <div class="type-and-hour-response-sidebar">
        <i class="material-symbols-outlined icon-type-response-sidebar" :style="{'font-size': response.icon === '123' ? '1.5vw' : '1vw'}">{{ response.icon }}</i>
        <p class="hour-response-sidebar">{{ response.createdAt }}</p>
      </div>
      <div class="title-detail-response-sidebar">
        <p class="title-response-sidebar">{{ response.title }}</p>
        <div class="section-detail-response-sidebar">
          <DetailResponse :dataRes="response.response" />
        </div>
      </div>
    </div>
    <div class="content-no-response" v-if="!responses.length">
      <div class="message-no-response">
        <p class="title-no-response">{{ $t('dashboard.msg_no_response') }}</p>
        <i class="material-icons-outlined icon-no-response">sentiment_neutral</i>
      </div>
    </div>
  </div>
</template>

<script>
import DetailResponse from './DetailResponse.vue'

export default {
  name: 'ResponsesSidebar',
  props: ['dataResponses'],
  components: {
    DetailResponse
  },
  data () {
    return {
      responses: null
    }
  },
  created () {
    this.responses = this.formatQuestions(this.dataResponses.survey.questions)
  },
  methods: {
    formatQuestions (questions) {
      const responsesFormatted = []
      questions.map(question => {
        if (!Object.keys(question.response).length) return

        const formattedQuestion = {
          type: question.type,
          ui: question.ui,
          createdAt: question?.response?.createdAt ? this.$moment(question.response.createdAt).format('DD/MM/YYYY HH:mm') : '',
          icon: '',
          title: question.question,
          response: { ...question.response, type: question.type, ui: question.ui, question }
        }

        switch (question.type) {
          case 'kpi':
            formattedQuestion.icon = 'insert_chart'
            break
          case 'csat':
            formattedQuestion.icon = 'insert_emoticon'
            break
          case 'enum':
            formattedQuestion.icon = '123'
            break
          case 'text':
            formattedQuestion.icon = 'text_fields'
            break
          case 'referral':
            formattedQuestion.icon = 'share'
            break
          case 'matrix':
            formattedQuestion.icon = 'format_list_numbered'
            break
          case 'nvs':
            formattedQuestion.icon = 'volunteer_activism'
            break
          case 'ces':
            formattedQuestion.icon = 'fitness_center'
            break
          case 'nes':
            formattedQuestion.icon = 'fitness_center'
            break
          case 'nps':
            formattedQuestion.icon = 'speed'
            break

          default:
            formattedQuestion.icon = 'default_icon'
        }

        responsesFormatted.push(formattedQuestion)
      })

      return responsesFormatted
    }
  }
}
</script>

<style lang="scss" scoped>
.container-responses-sidebar {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.3vw;
  .content-response-sidebar {
    width: 100%;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #e1e1e1;
    display: flex;
    .type-and-hour-response-sidebar {
      width: 20%;
      height: 100%;
      cursor: default;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.7vw;
      color: #454548;
      border-right: 1px dashed #e1e1e1;
      .hour-response-sidebar {
        font-size: 0.9vw;
        text-align: center;
      }
    }
    .title-detail-response-sidebar {
      width: 90%;
      min-height: 4vw;
      padding: 0.5vw;
      display: flex;
      flex-direction: column;
      gap: 0.6vw;
      .title-response-sidebar {
        width: 100%;
        line-height: 1.2vw;
        font-weight: 500;
        background: white;
        font-size: 0.9vw;
        color: #454548;
      }
      .section-detail-response-sidebar {
        background: #fafafa;
        width: 100%;
        min-height: 3vw;
        border-radius: 3px;
        border: 1px solid #f1f1f1;
      }
    }
  }
  .content-no-response {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .message-no-response {
      display: flex;
      align-items: center;
      gap: 0.5vw;
      .title-no-response {
        color: #454548;
        font-size: 0.8vw;
      }
      .icon-no-response {
        font-size: 1.2vw;
        color: #454548;
      }
    }
  }
}
</style>
