<template>
  <div
  id="g-recaptcha"
  class="g-recaptcha"
  :data-sitekey="sitekey">
  </div>
</template>
<script>
export default {
  data () {
    return {
      sitekey: '6LfIYOIZAAAAAM7iOvCClDQzMGI1KKLgzu8ouMKI',
      widgetId: 0
    }
  },
  methods: {
    notify () {
      this.$emit('verify', false)
    },
    render () {
      if (window.grecaptcha && typeof window.grecaptcha.render === 'function') {
        this.widgetId = window.grecaptcha.render('g-recaptcha', {
          sitekey: this.sitekey,
          'expired-callback': this.notify,
          // the callback executed when the user solve the recaptcha
          callback: (response) => {
            console.log('response', response)
            // emit an event called verify with the response as payload
            this.$emit('verify', response)
            // reset the recaptcha widget so you can execute it again
            // this.reset()
          }
        })
        const anchorContainer = document.getElementById('rc-anchor-container')
        if (anchorContainer) {
          anchorContainer.style.backgroundColor = 'red'
        }
      }
    }
  },
  mounted () {
    // render the recaptcha widget when the component is mounted
    try {
      this.render()
    } catch (error) {
      console.log('error', error)
    }
  }
}
</script>
<style >
#parent-recaptcha {
  height: 5vw;
}
.g-recaptcha > div  {
  margin: 0 auto;
}
@media only screen and (max-width: 1190px) {
  .g-recaptcha {
  transform:scale(0.60);
  transform-origin: top center;
  }
  #parent-recaptcha {
  height: 4vw;
  }
}
@media only screen and (max-width: 900px) {
  .g-recaptcha {
  transform:scale(0.57);
  transform-origin: top center;
  }
}

@media only screen and (max-width: 765px) {
  .g-recaptcha {
  transform:scale(0.47);
  }
}

@media only screen and (max-width: 710px) {
  .g-recaptcha {
  transform:scale(0.37);
  transform-origin: center;
  }
}
</style>
