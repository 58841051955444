import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

export const uploadFile = {
  uploadSeeds,
  getCollector,
  isUploadRunning
}

async function uploadSeeds (body, query) {
  let url = `${API_URL}seedBatches-upload/?collector=${query.collector}&headers=${query.headers}&defaultSendingType=${query.defaultSendingType}&delimiter=${query.delimiter}`
  if (query.date) {
    url = `${url}&date=${query.date}`
  }
  const result = await callApiService.callApi(url, 'post', body, undefined, true)
  return result
}

async function isUploadRunning (options) {
  const url = `${API_URL}seedBatches-upload?status=${options.status}&survey=${options.survey}&gteCreatedAt=${options.createdAt}`
  return await callApiService.callApi(url, 'get', {}, 2, false, 0) || []
}

async function getCollector () {
  const url = `${API_URL}collectors`
  const result = await callApiService.callApi(url, 'get')
  return result
}
