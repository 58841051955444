<template>
  <div v-on:mouseover="isActive = true" v-on:mouseleave="isActive = false">
    <div>
      <app-form-input
        @click="isActive = !isActive"
        v-on:mouseover="isActive = true"
        class="filterInputText col-xs-12 col-sm-12 col-md-12 col-lg-12"
        secondary
        :disabled="true"
        v-model="currentOptions"
      ></app-form-input>
    </div>
    <transition v-if="isActive" name="dropdown">
      <div
        class="dropdown__menu row"
        :style="{'left': isMobile ? '3px' : '20px'}"
        style="height: 29.4rem; z-index: 999999 !important; width: 315px; margin-top: -12px; position: absolute;"
        v-bind:class="{ active: isActive }"
      >
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <p class="filterLabelOfDates">{{ $t('date_filter.from') }}</p>
          <div class="submenu_filters submenu_filters_data">
            <app-datepicker
              id="from"
              :key="componentKey"
              v-model="lazyDateFrom"
              ref="input"
              maxlength="10"
              :placeholder="$t('date_filter.from')"
              v-on:input="$emit('onFocus', lazyDateFrom, lazyDateTo, true)"
            ></app-datepicker>
            <div class="firstBadgeDate" style="margin-left: 85%;" v-on:click="focusOnDate('from')">
              <div>
                <app-icon name="calendar" size="xs" color="white" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <p class="filterLabelOfDates">{{ $t('date_filter.to') }}</p>
          <div class="submenu_filters submenu_filters_data">
            <app-datepicker
              id="to"
              :key="componentKey"
              v-model="lazyDateTo"
              ref="input"
              maxlength="10"
              :placeholder="$t('date_filter.to')"
              v-on:input="$emit('onFocus', lazyDateFrom, lazyDateTo, true)"
            ></app-datepicker>
            <div class="secondBadgeDate" style="margin-left: 85%;" v-on:click="focusOnDate('to')">
              <div>
                <app-icon name="calendar" size="xs" color="white" />
              </div>
            </div>
          </div>
        </div>
          <div v-for="(mockDate, index) in rangeOfDates"
          @click="changeDate(mockDate)"
          :key="index"
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 filterDateButton">
            {{ $t(`date_filter.periods.${mockDate}`)}}
          </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FilterDateField',
  props: ['dateFrom', 'dateTo', 'isOpen'],
  components: {
    'app-datepicker': () => import('@binds-tech/binds-design-system/src/components/Form/Datepicker'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-icon': () => import('@binds-tech/binds-design-system/src/components/Icon/Icon')
  },
  data () {
    return {
      isActive: this.isOpen,
      componentKey: 0,
      lazyDateFrom: this.dateFrom,
      lazyDateTo: this.dateTo,
      isMobile: false,
      rangeOfDates: ['day', 'week', 'month', 'semester', 'year']
    }
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  async mounted () {
    // isso aqui evita que o filtro renderize sem ter o state pronto com a data convertida
    // resolveu para a tela de Approaches
    await this.$nextTick()
    if (!this.dateFrom && !this.dateTo) {
      this.changeDate(this.account.dashboardDefaultPeriod)
    }
    // check if is mobile
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  computed: {
    currentOptions: {
      get () {
        let stringToReturn = this.$i18n.t('date_filter.empty')
        if (!this.lazyDateFrom && this.lazyDateTo) {
          stringToReturn = `00-00-0000 ~ ${this.lazyDateTo}`
        } else if (this.lazyDateFrom && this.lazyDateTo) {
          stringToReturn = `${this.lazyDateFrom} ~ ${this.lazyDateTo}`
        } else if (this.lazyDateFrom) {
          stringToReturn = `${this.lazyDateFrom} ~ 00-00-0000`
        }
        return stringToReturn
      }
    },
    ...mapGetters({
      account: 'account/getAccount'
    })
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 648
    },
    changeDate (option) {
      const currentLocale = this.$moment.locale()
      let isDay = false
      switch (option) {
        case 'day':
          this.lazyDateFrom = this.$moment().locale(currentLocale).subtract(21, 'hours').format('DD/MM/YYYY')
          this.lazyDateTo = this.$moment().locale(currentLocale).format('DD/MM/YYYY')
          isDay = true
          break
        case 'week': this.lazyDateFrom = this.$moment().locale(currentLocale).subtract(1, 'weeks').format('DD/MM/YYYY'); this.lazyDateTo = this.$moment().locale(currentLocale).format('DD/MM/YYYY')
          break
        case 'month': this.lazyDateFrom = this.$moment().locale(currentLocale).subtract(1, 'months').format('DD/MM/YYYY'); this.lazyDateTo = this.$moment().locale(currentLocale).format('DD/MM/YYYY')
          break
        case 'semester': this.lazyDateFrom = this.$moment().locale(currentLocale).subtract(6, 'months').format('DD/MM/YYYY'); this.lazyDateTo = this.$moment().locale(currentLocale).format('DD/MM/YYYY')
          break
        case 'year': this.lazyDateFrom = this.$moment().locale(currentLocale).subtract(1, 'years').format('DD/MM/YYYY'); this.lazyDateTo = this.$moment().locale(currentLocale).format('DD/MM/YYYY')
          break
        default: this.lazyDateFrom = this.$moment().locale(currentLocale).format('DD/MM/YYYY'); this.lazyDateTo = this.$moment().locale(currentLocale).format('DD/MM/YYYY')
          break
      }
      this.componentKey += 1
      this.$emit('onFocus', this.lazyDateFrom, this.lazyDateTo, true, isDay)
    },
    focusOnDate (location) {
      document
        .getElementById(location)
        .firstChild.firstChild.lastChild.lastElementChild.focus()
    }
  }
}
</script>

<style>
.datepicker__picker {
  left: 8px;
}
.filterInputText > .text-field__input > input {
  width: 92% !important;
  text-align: center;
}
.filterLabelOfDates {
  font-size: 15px;
  padding: 5px;
  color: #b8b8b8;font-family: Open Sans, Helvetica, Arial, sans-serif;
}
.filterDateButton {
  text-align: center;
  margin: 10px 15px;
  box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.03),
    0 5px 4px 0 rgba(0, 0, 0, 0.01);
  border-radius: 5px;
  background: #fcfcfc;
  color: #b2a7b0;
  font-weight: 600;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #b8b8b878;
  padding-top: 18px !important;
}
.filterDateButton:hover {
  color: #fcfcfc !important;
  background: #b2a7b0 !important;
}
</style>
