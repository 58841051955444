<template>
  <div>
    <div class="col-lg-12 col-md-12" style="padding-left: 30px;">
      <app-heading level="h7" style="text-align: center; font-weight: bold">{{ $t('sendhub.configWidget') }}</app-heading>
      <app-accordion
        :title="'Configuração Geral'"
        :showOnStartup="true"
        :flex="false"
        class="accordion-adjust"
      >
        <app-heading
          level="h7"
          class="mt30"
          v-title="$t('sendhub.hints.widgetPosition')"
        >Site:</app-heading>
        <app-form-input
          v-model="widget.sitePreview"
          class="url-site"
          border-bottom
          @input="setSitePreview(currentSite)"
        ></app-form-input>
        <app-heading
          level="h7"
          class="mt30"
          v-title="$t('sendhub.hints.widgetPosition')"
        >{{ $t('sendhub.widget_position') }}:</app-heading>
        <div class="widget-positions">
          <v-select
            :clearable="false"
            :options="widgetPositions"
            v-model="currentPosition"
            label="label"
            @input="getPosition(currentPosition.value)"
            class="widget-position-selector"
          ></v-select>
        </div>

        <!-- if the position is actionButton -->
        <div v-if="widget.widgetPosition === 'actionButton' || widget.widgetPosition === 'actionButtonB'">
          <div class="ml8 mt10 item-widget-options">
            <app-toggle-input v-model="currentActionConfig.startOpen" v-on:change.native="setActionConfig()"></app-toggle-input>
            <app-heading
              level="h6"
              style="padding-left: 46px; height: 21px; line-height: 20px;"
              v-title="$t('sendhub.hints.startOpen')"
            >{{ $t('sendhub.widget_action_button.startOpen') }}</app-heading>
          </div>
          <div class="ml8 mt10 item-widget-options">
            <app-toggle-input v-model="currentActionConfig.closeActionButton" v-on:change.native="setActionConfig()"></app-toggle-input>
            <app-heading
              level="h6"
              style="padding-left: 46px; height: 21px; line-height: 20px;"
              v-title="$t('sendhub.hints.closeActionButton')"
            >{{ $t('sendhub.widget_action_button.closeActionButton') }}</app-heading>
          </div>
          <div class="ml8 mt10 item-widget-options" style="cursor: not-allowed">
            <app-toggle-input value="true" style="pointer-events: none;"></app-toggle-input>
            <app-heading
              level="h6"
              style="padding-left: 46px; height: 21px; line-height: 20px;"
              v-title="$t('sendhub.hints.customizedImage')"
            >{{ $t('sendhub.widget_action_button.customizedImage') }}</app-heading>
          </div>

          <div class="mt30">
            <app-heading level="h7" v-title="$t('sendhub.hints.customizedImageLink')">
              {{ $t('sendhub.widget_action_button.customizedImage') }}:
            </app-heading>
            <app-form-input
              autofocus
              border-bottom
              placeholder="URL externa"
              v-model="currentActionConfig.iconName"
              @change="setActionConfig()"
            ></app-form-input>
          </div>

          <div class="colors-container">
            <app-heading level="h6">{{ $t('sendhub.widget_colors') }}</app-heading>
              <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
                <div class="col-lg-8 col-md-8 col-sm-8 color-group">
                  <app-heading level="h7" v-title="$t('sendhub.hints.backgroundColorActionButton')">2. {{ $t('sendhub.background_color') }}</app-heading>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                  <div class="color-box">
                    <input type="text" v-model="currentActionConfig.background"  @change="setActionConfig()" data-coloris style="opacity: 0; position: absolute; cursor: pointer; width: 50px;">
                    <div :style="{'background-color': currentActionConfig.background}" style="width: 24px; height: 24px; margin: 5px 4px; border-radius: none !important; border: rgba(0, 0, 0, 0.2) 1px solid"/>
                    <i class="material-icons-outlined">arrow_drop_down</i>
                  </div>
                </div>
              </div>
          </div>

          <hr style="width: 100%; color: rgba(0, 0, 0, 0.3); position: absolute; left: 0;">
        </div>
        <!-- end if -->

        <app-heading level="h7" style="margin-top: 50px">{{ $t('sendhub.configLayout') }}</app-heading>
        <div class="widget-options-sidebar">
          <div class="item-widget-options">
            <app-heading level="h7" v-title="$t('sendhub.hints.width')">{{ $t('sendhub.widget_size') }}</app-heading>
            <app-form-input
            type="number"
              v-model="widget.width"
              v-on:change.native="updateWidget()"
              border-bottom
            ></app-form-input>
          </div>
          <div class="item-widget-options">
            <app-heading level="h7" v-title="$t('sendhub.hints.height')">{{ $t('sendhub.height') }}</app-heading>
            <app-form-input
            type="number"
              v-model="widget.height"
              v-on:change.native="updateWidget()"
              border-bottom
            ></app-form-input>
          </div>
          <div class="item-widget-options">
            <app-heading level="h7" v-title="$t('sendhub.hints.widgetTimeToOpen')">{{ $t('sendhub.widget_time_to_open') }}</app-heading>
            <app-form-input
            type="number"
              v-model="widget.timeout"
              v-on:change.native="updateWidget()"
              border-bottom
            ></app-form-input>
          </div>
          <div class="item-widget-options">
            <app-heading level="h7" v-title="$t('sendhub.hints.borders')">{{ $t('sendhub.borders') }}</app-heading>
            <app-form-input
              type="number"
              v-model="widget.borderRadius"
              v-on:change.native="updateWidget()"
              border-bottom
            ></app-form-input>
          </div>
        </div>

        <div class="ml8 item-widget-options">
          <app-toggle-input v-model="widget.closeButton" v-on:change.native="updateWidget()"></app-toggle-input>
          <app-heading
            level="h7"
            style="padding-left: 46px; height: 21px; line-height: 20px;"
            v-title="$t('sendhub.hints.showCloseButton')"
          >{{ $t('sendhub.show_close_button') }}</app-heading>
        </div>
        <div class="ml8 item-widget-options" style="margin-top: 20px;">
          <app-toggle-input v-model="widget.closePermanently" v-on:change.native="updateWidget()"></app-toggle-input>
          <app-heading
            level="h7"
            style="padding-left: 46px; height: 21px; line-height: 20px;"
            v-title="$t('sendhub.hints.closePermanently')"
          >{{$t('sendhub.widget_closePermanently')}}</app-heading>
        </div>

        <app-heading level="h7" class="mt30">{{$t('sendhub.configTitle')}}</app-heading>
        <app-heading level="h7" class="mt20" v-title="$t('sendhub.hints.font')">{{$t('sendhub.font')}}</app-heading>
        <div class="widget-font-positions">
          <v-select
            :clearable="false"
            :options="fontOptions"
            v-model="currentFontFamily"
            label="label"
            @input="setFontFamily()"
            class="widget-position-selector"
          ></v-select>
          <v-select
            :clearable="false"
            :options="fontSizes"
            v-model="currentFontSize"
            label="label"
            @input="setFontSize()"
            class="widget-position-selector"
          ></v-select>
        </div>

        <div class="ml8 item-widget-text-props" style="margin-top: 20px;">
          <span>
            <app-toggle-input v-model="currentTextProps.bold" v-on:change.native="setTextProps()"></app-toggle-input>
            <app-heading
              level="h7"
              style="padding-left: 46px; height: 21px; line-height: 20px;"
            >{{$t('sendhub.textProps.bold')}}</app-heading>
          </span>
          <span>
            <app-toggle-input v-model="currentTextProps.underline" v-on:change.native="setTextProps()"></app-toggle-input>
            <app-heading
              level="h7"
              style="padding-left: 46px; height: 21px; line-height: 20px;"
            >{{$t('sendhub.textProps.underline')}}</app-heading>
          </span>
          <span>
            <app-toggle-input v-model="currentTextProps.italic" v-on:change.native="setTextProps()"></app-toggle-input>
            <app-heading
              level="h7"
              style="padding-left: 46px; height: 21px; line-height: 20px;"
            >{{$t('sendhub.textProps.italic')}}</app-heading>
          </span>
        </div>

        <hr style="width: 100%; color: rgba(0, 0, 0, 0.3); position: absolute; left: 0;">
        <app-heading level="h7" style="font-weight: bold; margin-top: 50px;">{{ $t('sendhub.sendInfo') }}</app-heading>
        <div class="script-widget">
          <app-heading level="h6" style="line-height: 25px;">{{ $t('sendhub.code_to_embed') }}:</app-heading>
          <button class="copy-code" @click="copyCodeWidget">
            <app-heading level="h7">{{ $t('sendhub.copy_code') }}</app-heading>
            <i class="material-icons copy">content_copy</i>
          </button>
          <div class="script">{{ preview }}</div>
        </div>
      </app-accordion>

      <app-accordion
        :title="`${$t('sendhub.widget_colors')}`"
        :showOnStartup="false"
        :flex="false"
        class="accordion-adjust"
      >
        <app-heading level="h7" class="mt30">{{ $t('sendhub.ofTitle') }}:</app-heading>
          <div class="colors-container">
            <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
              <div class="col-lg-8 col-md-8 col-sm-8 color-group">
                <app-heading level="h7" v-title="$t('sendhub.hints.backgroundColorTitle')">1. {{ $t('sendhub.background_color') }}</app-heading>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="color-box">
                  <input type="color" class="widget-palette" v-model="widget.background"/>
                  <i class="material-icons-outlined">arrow_drop_down</i>
                </div>
              </div>
            </div>

            <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
              <div class="col-lg-8 col-md-8 col-sm-8 color-group">
                <app-heading level="h7" v-title="$t('sendhub.hints.textButtonColor')">2. {{ $t('sendhub.text_buttons_color') }}</app-heading>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="color-box">
                  <input type="color" class="widget-palette" v-model="widget.textButtons" style="text-align: center !important;"/>
                  <i class="material-icons-outlined">arrow_drop_down</i>
                </div>
              </div>
            </div>

            <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
              <div class="col-lg-8 col-md-8 col-sm-8 color-group">
                <app-heading level="h7" v-title="$t('sendhub.hints.buttonColor')">3. {{ $t('sendhub.buttons_color') }}</app-heading>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="color-box">
                  <input type="color" class="widget-palette" v-model="widget.buttons" style="text-align: center !important;"/>
                  <i class="material-icons-outlined">arrow_drop_down</i>
                </div>
              </div>
            </div>

            <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
              <div class="col-lg-8 col-md-8 col-sm-8 color-group">
                <app-heading level="h7" v-title="$t('sendhub.hints.textsColor')">4. {{ $t('sendhub.texts_color') }}</app-heading>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="color-box">
                  <input type="color" class="widget-palette" v-model="widget.texts" style="text-align: center !important;"/>
                  <i class="material-icons-outlined">arrow_drop_down</i>
                </div>
              </div>
            </div>
          </div>

          <app-heading level="h7" class="mt30">{{ $t('sendhub.ofMessage') }}:</app-heading>
          <div class="colors-container">
            <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
              <div class="col-lg-8 col-md-8 col-sm-8 color-group">
                <app-heading level="h7" v-title="$t('sendhub.hints.backgroundColorMessage')">1. {{ $t('sendhub.background_color') }}</app-heading>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="color-box">
                  <input type="color" class="widget-palette" v-model="widget.responseBackground" style="text-align: center !important;"/>
                  <i class="material-icons-outlined">arrow_drop_down</i>
                </div>
              </div>
            </div>

            <div class="row center-lg center-md center-sm middle-lg middle-md middle-sm">
              <div class="col-lg-8 col-md-8 col-sm-8 color-group">
                <app-heading level="h7" v-title="$t('sendhub.hints.textColorMessage')">2. {{ $t('sendhub.texts_color') }}</app-heading>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="color-box">
                  <input type="color" class="widget-palette" v-model="widget.responseFontColor" style="text-align: center !important;"/>
                  <i class="material-icons-outlined">arrow_drop_down</i>
                </div>
              </div>
            </div>
          </div>
      </app-accordion>

    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetConfig',
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput'),
    'app-accordion': () => import('@binds-tech/binds-design-system/src/components/Accordion/Accordion'),
    vSelect: () => import('vue-select')
  },
  data () {
    return {
      typeSeleted: '',
      widgetPositions: [
        {
          label: this.$t('sendhub.widget_top_right'),
          value: 'top-right'
        },
        {
          label: this.$t('sendhub.widget_top_left'),
          value: 'top-left'
        },
        {
          label: this.$t('sendhub.widget_middle_left'),
          value: 'middle-left'
        },
        {
          label: this.$t('sendhub.widget_middle_right'),
          value: 'middle-right'
        },
        {
          label: this.$t('sendhub.widget_bottom_right'),
          value: 'bottom-right'
        },
        {
          label: this.$t('sendhub.widget_bottom_left'),
          value: 'bottom-left'
        },
        {
          label: this.$t('sendhub.widget_bottom'),
          value: 'lower'
        },
        {
          label: this.$t('sendhub.widget_popup'),
          value: 'popup'
        },
        {
          label: this.$t('sendhub.widget_button'),
          value: 'actionButton'
        },
        {
          label: this.$t('sendhub.widget_buttonB'),
          value: 'actionButtonB'
        }
      ],
      fontOptions: [
        {
          label: 'Verdana',
          value: 'Verdana'
        },
        {
          label: 'Times New Roman',
          value: 'Times New Roman'
        },
        {
          label: 'Comic sans MS',
          value: 'Lucida Calligraphy,Comic Sans MS,Lucida Console'
        },
        {
          label: 'Helvetica',
          value: "'Helvetica', 'Arial', sans-serif"
        },
        {
          label: 'Arial',
          value: 'Arial, Helvetica, sans-serif'
        },
        {
          label: 'Baskervville',
          value: "'Baskervville', serif"
        },
        {
          label: 'Libre Baskervville',
          value: "'Libre Baskervville', serif"
        },
        {
          label: 'Loto',
          value: "'Lato', sans-serif"
        },
        {
          label: 'Montserrat',
          value: "'Montserrat', sans-serif"
        },
        {
          label: 'Open Sans',
          value: "'Open Sans', sans-serif"
        },
        {
          label: 'Roboto',
          value: "'Roboto', sans-serif"
        },
        {
          label: 'Calibri',
          value: 'Calibri, sans-serif'
        },
        {
          label: 'Cambria',
          value: 'Cambria Georgia, serif'
        },
        {
          label: 'Nunito',
          value: "'Nunito', sans-serif"
        }
      ],
      fontSizes: [
        {
          label: '9.6px',
          value: 'xx-small'
        },
        {
          label: '12px',
          value: 'x-small'
        },
        {
          label: '14.2px',
          value: 'small'
        },
        {
          label: '16px',
          value: 'medium'
        },
        {
          label: '19.2px',
          value: 'large'
        },
        {
          label: '24px',
          value: 'x-large'
        },
        {
          label: '32px',
          value: 'xx-large'
        },
        {
          label: '48px',
          value: 'xxx-large'
        }
      ],
      iconTypes: [
        {
          label: 'design system',
          value: 'designSystem'
        },
        {
          label: 'google',
          value: 'google'
        },
        {
          label: 'external url',
          value: 'externalUrl'
        }
      ],
      currentPosition: {
        label: this.$t('sendhub.widget_top_right'),
        value: 'top-right'
      },
      currentFontFamily: {
        label: 'Arial',
        value: 'Arial, Helvetica, sans-serif'
      },
      currentFontSize: {
        label: '19.2px',
        value: 'large'
      },
      currentActionConfig: {
        background: 'transparent',
        startOpen: true,
        iconName: 'https://binds.co/open-graph.png',
        iconType: 'externalUrl',
        closeActionButton: true
      },
      currentTextProps: {
        bold: false,
        underline: false,
        italic: false
      }
    }
  },
  created () {
    const colorisScript = document.createElement('script')
    const colorisLink = document.createElement('link')
    colorisScript.setAttribute('src', 'https://cdn.jsdelivr.net/gh/mdbassit/Coloris@latest/dist/coloris.min.js')
    colorisLink.setAttribute('rel', 'stylesheet')
    colorisLink.setAttribute('href', 'https://cdn.jsdelivr.net/gh/mdbassit/Coloris@latest/dist/coloris.min.css')
    document.head.appendChild(colorisScript)
    document.head.appendChild(colorisLink)
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    },
    widget () {
      const get = this.$store.getters['widget/getWidget']
      return get
    },
    preview () {
      const widget = this.$store.getters['widget/getWidget']
      const previewCode = [
        '<div>',
        '<link href="https://widget-v3.web.app/css/app.css" rel=stylesheet>',
        '<div>',
        '<vue-widget',
        ' widget_position="' + widget.widgetPosition + '"',
        ' widget_title="' + widget.widgetTitle + '"',
        ' buttons="' + widget.buttons + '"',
        ' width="' + widget.width + '"',
        ' height="' + widget.height + '"',
        ' text_buttons="' + widget.textButtons + '"',
        ' background="' + widget.background + '"',
        ' texts="' + widget.texts + '"',
        ' timeout="' + widget.timeout + '"',
        ' width_metric="' + widget.metric + '"',
        ' survey_id="' + this.survey._id + '"',
        ' close_button="' + widget.closeButton + '"',
        ' close_permanently="' + widget.closePermanently + '"',
        ' response_background="' + widget.responseBackground + '"',

        ' hidde_after_answer="' + widget.hiddeAfterAnswer + '"',
        ' hidde_when_click_to_close="' + widget.hiddeWhenClickToClose + '"',
        ' response_font_color="' + widget.responseFontColor + '"',
        ' font_family="' + widget.fontFamily + '"',
        ' font_size="' + widget.fontSize + '"',
        widget.widgetPosition.includes('actionButton') ? `action_config='${widget.actionConfig}'` : '',
        ` text_props="${widget.textProps}"`,
        ' border_radius="' + widget.borderRadius + '"',

        ' from=' + "'{" + '"name": "", "email": "", "phone":""}' + "'",
        ' is_to_post="true"',
        ' metadata=' + "'{}'" + '/>',
        '</div>',
        /* eslint-disable */
        '<script src="https://widget-v3.web.app/js/app.js"> <\/script>',
        '</div>'
      ].join('')
      return previewCode
    }
  },
  methods: {
    copyCodeWidget () {
      try {
        navigator.clipboard.writeText(this.preview)

        this.$store.commit('alerts/alert', {
          message: this.$t('sendhub.success_copy'),
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      } catch (error) {
        this.$store.commit('alerts/alert', {
          message: this.$t('sendhub.error_copy'),
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      }
    },
    getPosition (type) {
      this.typeSeleted = type
      this.$store.commit('widget/add', { widgetPosition: this.typeSeleted })
    },
    updateWidget () {
      this.$store.commit('widget/widgetObject', this.widget)
    },
    setFontFamily () {
      this.$store.commit('widget/add', { fontFamily: this.currentFontFamily.value })
    },
    setFontSize () {
      this.$store.commit('widget/add', { fontSize: this.currentFontSize.value })
    },
    setActionConfig () {
      const newObject = {
        ...this.currentActionConfig,
        startOpen: this.currentActionConfig.startOpen ? "true" : "false",
        closeActionButton: this.currentActionConfig.closeActionButton ? "true" : "false"
      }
      this.$store.commit('widget/add', { actionConfig: JSON.stringify(newObject) })
    },
    setTextProps () {
      this.$store.commit('widget/add', { textProps: JSON.stringify(this.currentTextProps) })
    }
  }
}
</script>
<style lang="scss">
.url-site {
  .user-click {
    font-size: 16px !important;
  }
}
.widget-palette {
  height: 34px;
  width: 40px;
  display: flex;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer !important;
  input {
    border-radius: 10px !important;
  }
}
.color-group {
  padding-left: 5px !important;
}
.color-box {
  cursor: pointer !important;
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 64px;
  padding-left: 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
div.accordion-adjust {
  min-height: 50px;
  margin: 10px 0;
}
.binds-prev-question {
  display: none; // hack para retirar o botão de voltar do widget e evitar erros
}
.widget-positions {
  margin: .75rem 0 2rem;
}
.active-position-button {
  border: 1.3px dashed #3f3356 !important;
  border-radius: 6px;
}
.script-widget {
  margin-top: 20px;
  h5 {
    width: 330px;
  }
  .script {
    margin: 20px auto;
    height: 150px;
    overflow: scroll;
    width: 95%;
    background-color: #eff3f8;
    padding: 10px;
    font-size: 15px;
    line-height: 22px;
    color: $color-gray;
    word-break: break-word;
  }
  .copy-code {
    background: var(--secondary-color);
    width: 30%;
    height: 40px;
    border-radius: 5px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: var(--default-text-color);
      font-weight: bold;
    }
    .copy {
      font-size: 20px;
      margin: 0 7px 3px 15px;
      color: var(--default-text-color);
    }
  }
    .copy-code:hover {
      transform: scale(1.01);
    }
}
.widget-font-positions {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 10px;
  div.v-select {
    flex: 5;
  }
  .v-select + div.v-select {
    flex: 2;
    margin-left: 10px;
  }
}
.item-widget-options {
  display: flex;
}
.item-widget-text-props {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  > span {
    display: flex;
    margin: 0 1rem;
  }
}

.widget-options-sidebar {
  margin: 30px 0;
  .item-widget-options {
    display: inline-block;
    margin: 5px 10px;
    width: 45%;
  }
}

.colors-container {
  margin-top: 1rem;
  padding: 0 1.5rem;
  > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .75rem;
  }
  p.h7.heading {
    font-weight: bold;
  }
}

@media screen and (max-width: 1800px) {
  .widget-font-positions {
    flex-wrap: wrap;
    justify-content: flex-end;
    div.v-select {
      flex: none;
      width: 100%;
      margin: 10px auto;
    }
    .v-select + div.v-select {
      flex: none;
    }
  }
  .item-widget-text-props {
    flex-wrap: wrap;
    > span {
      flex: 1;
    }
  }
  button.copy-code {
    width: 110px !important;
  }
}
@media screen and (max-width: 1020px) {
  div.col-sm-3 {
    flex-basis: 33%;
    max-width: 33%;
  }
}
.widget-colors {
  h6 {
    margin-bottom: 10px !important;
    margin-top: 10px;
    text-align: left;
    font-size: 12px !important;
    text-align: center !important;
  }
}
.widget-align-colors {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.widget-group {
  padding-left: 0px !important;
}
</style>
