<template>
  <div>
    <div class="card-question">
      <div class="loading-approaches" v-if="loading">
        <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
      </div>
      <app-card class="card-size">
        <app-heading level="h6" class="card-title mt8">{{ card.survey.title }}</app-heading>
        <app-textstyle color="gray" size="size-xs">{{card.survey.createdAt | moment('DD/MM/YYYY')}}</app-textstyle>
        <div v-if="card.compositionCard && ['5emo', '1to5', '5num', '10num', 'yn', 'ynd'].indexOf(card.firstQuestion.ui) >= 0 && card.compositionCard.valueGroup.length > 0">
          <div clas="row center-lg center-md center-sm center-xs center-xl" style="display: flex; padding-top: 20px;">
            <div class="col-lg-offset-2 col-lg-3 col-md-offset-2 col-md-3 col-sm-offset-2 col-sm-4">
              <app-heading level="h3" style="color: #333333 !important;" :class="{'average-pad': card.compositionCard.average < 0 && card.compositionCard.average <= -100}">{{ card.compositionCard.average.toFixed(0) }}</app-heading>
              <app-heading level="h4" style="color: #333333 !important;">{{ card.firstQuestion.type.toUpperCase() }}</app-heading>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-4">
              <span v-for="data in card.compositionCard.valueGroup" :key="data.group" style="display: flex; margin-bottom: 3px !important;" >
                <div v-if="card.firstQuestion.ui !== '5emo'" style="display: flex;">
                  <div class="roundCircle" :style="{'border-color': data.color}"></div>
                  <p class="lowOpacityText">
                    <span class="focusOnIt">{{ parseInt((data.doc_count / card.compositionCard.total) * 100) }}%</span> {{data.group}}
                  </p>
                </div>
                <div v-else style="display: flex; margin: 0 auto; padding-bottom: 2px">
                  <p style="width: 40px;" class="lowOpacityText"><span class="focusOnIt">{{ parseInt((data.doc_count / card.compositionCard.total) * 100) }}% </span></p>
                  <p style="font-family: icomoon" :style="{'color': data.color}">{{data.group}}</p>
                </div>
              </span>
            </div>
          </div>
          <div class="row" style="text-align: center; margin-top: 30px;">
            <app-heading level="h7" class="infos-panel col-lg-12 col-md-12 col-sm-12 col-xs-12">{{ card.stats.responses }} {{ $tc("dashboard_card.anwsers", 420) }} {{ $tc("dashboard_card.sendings", 666, { count: card.stats.sendings }) }}</app-heading>
            <app-heading level="h7" class="infos-panel col-lg-12 col-md-12 col-sm-12 col-xs-12">{{ $t("dashboard_card.rate")}} {{parseInt((card.stats.responses / card.stats.sendings) * 100) }}%</app-heading>
          </div>
        </div>
        <div v-else style="text-align: center; margin-top: 15px;">
          <app-heading level="h6" style="color: gray !important;">{{ $t("dashboard_card.rate") }}</app-heading>
          <div class="rate-border">
            <app-heading level="h2" style="color: #333333 !important;" class="mt20">{{ parseInt((card.stats.responses / card.stats.sendings) * 100 || 0) }}%</app-heading>
          </div>
          <app-heading level="h6" style="color: gray !important; margin-top: 25px;">
            {{ card.stats.responses }} {{ $tc("dashboard_card.anwsers", 420) }} {{ $tc("dashboard_card.sendings", 666, { count: card.stats.sendings }) }}
          </app-heading>
        </div>
      </app-card>
      <app-card class="actions-card row center-lg center-md center-sm" style="padding-top: 10px !important; display: flex;">
        <app-button class="col-lg-2 col-md-2 col-sm-2 btn-pad" inline icon="answers" @click="goToAnswers(card.survey._id)" icon-color="gray" icon-size="sm">
          <app-textstyle color="gray" size="size-xxs">{{ $t("dashboard_card.anwsers_link") }}</app-textstyle>
        </app-button>
        <app-button class="col-lg-2 col-md-2 col-sm-2 btn-pad" inline icon="edit" @click="editSurvey(card.survey._id)" icon-color="gray" icon-size="sm">
          <app-textstyle color="gray" size="size-xxs">{{ $t("dashboard_card.edit") }}</app-textstyle>
        </app-button>
        <app-button class="col-lg-2 col-md-2 col-sm-2 btn-pad" inline icon="duplicate" @click="cloneSurvey(card.survey._id)" icon-color="gray" icon-size="sm">
            <app-textstyle color="gray" size="size-xxs">{{ $t("dashboard_card.clone") }}</app-textstyle>
          </app-button>
        <app-button class="col-lg-2 col-md-2 col-sm-2 btn-pad" inline icon="chart-line" @click="openDash(card.survey._id)" icon-color="gray" icon-size="sm">
          <app-textstyle color="gray" size="size-xxs">{{ $t("dashboard_card.tv_dash") }}</app-textstyle>
        </app-button>
        <app-button class="col-lg-2 col-md-2 col-sm-2 btn-pad trash-btn" inline icon="trash-alt" @click="deleteSurvey(card.survey._id)" icon-color="gray" icon-size="sm">
          <app-textstyle variation="default" color="gray" size="size-xxs">{{ $t("dashboard_card.delete") }}</app-textstyle>
        </app-button>
      </app-card>
    </div>
    <theme-style>
      .rate-border {
        border-color: {{user.account.theme.colors.accent || '#3f3356'}} !important;
      }
    </theme-style>
  </div>
</template>

<script>
import { surveyService } from '@/_services'
import { mapGetters } from 'vuex'

export default {
  name: 'CompositionCard',
  props: ['card'],
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    })
  },
  methods: {
    async openDash (id) {
      this.redirectTo('Overview', id)
    },
    editSurvey (surveyId) {
      this.$router.push({ name: 'Surveys', params: { id: surveyId } })
    },
    async cloneSurvey (surveyId) {
      const survey = await surveyService.getSurvey(surveyId)
      if (survey) {
        const clone = await surveyService.cloneSurvey(surveyId, { title: survey.title })
        if (clone) {
          this.$store.commit('alerts/alert', {
            message: 'Success cloning survey!',
            position: 'bottomRight',
            showAlert: true
          })
        } else {
          this.$store.commit('alerts/alert', {
            message: 'Error cloning survey.',
            position: 'bottomRight',
            showAlert: true
          })
        }
      } else {
        this.$store.commit('alerts/alert', {
          message: 'Error cloning survey.',
          position: 'bottomRight',
          showAlert: true
        })
      }
    },
    async redirectTo (location, surveyId) {
      this.loading = true
      const query =
        {
          $and: [
            { survey: { $eq: surveyId } }
          ]
        }
      const survey = await surveyService.getSurvey(surveyId)
      if (survey) {
        this.$store.commit('dashboard/updateCurrentSurvey', survey)
        this.$store.commit('dashboard/updateSelectedSurveyId', surveyId)
        this.$store.commit('filters/updateFilterAnd', [{ survey: { $eq: surveyId } }])
        this.$router.push({ name: location, query: { q: JSON.stringify(query) } })
      } else {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('error_go_to'),
          position: 'bottomRight',
          showAlert: true
        })
      }
      this.loading = false
    },
    async goToAnswers (surveyId) {
      this.redirectTo('Individual', surveyId)
    },
    async deleteSurvey (id) {
      const del = await surveyService.deleteSurvey(id)
      if (!del) {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('surveyList.delete_error'),
          position: 'bottomRight',
          showAlert: true
        })
      } else {
        this.$root.$emit('remove-this-composition-card', id)
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('surveyList.delete_success'),
          position: 'bottomRight',
          showAlert: true
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.focusOnIt {
  font-weight: 600;
}
.lowOpacityText {
  color: #838383 !important;
  font-size: 14px;
}
.roundCircle {
  width: 9px;
  height: 9px;
  border-radius: 20px;
  border: 3px solid black;
  margin-right: 5px;
}
.card-question {
  padding: 14px 10px 14px 14px;
}
.card-title {
  font-size: 18px;
  line-height: 22px !important;
  letter-spacing: 0s;
  width: 345px;
  font-size: 16px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  color: #333333 !important;
}
.mt8 {
  margin-top: 8px !important;
}
.card-size {
  height: 350px;
  width: 418px !important;
}
.actions-card {
  margin-top: -20px;
  height: 75px !important;
  width: 418px !important;
  border-top: 0.5px solid #ddd !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.btn-pad {
  padding: 0px !important;
}
.trash-btn {
  margin-bottom: 24px !important;
}
.infos-panel {
  color: #838383 !important;
}
.rate-border {
  border: 4px dotted;
  border-radius: 6px;
  height: 100px;
  margin-top: 20px;
}
.average-pad {
  margin-left: -18px !important;
}
</style>
