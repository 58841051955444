<template>
  <div class="donut-chart-container">
    <div class="donut-chart-header">
      <p class="donut-chart-title">CATEGORIAS</p>
      <i class="material-symbols-outlined" v-title="'Classificação de comentários por temas. Identifique tópicos relevantes.'" title-max-width="250" title-placement="left">info</i>
    </div>
    <div class="donut-chart-list" v-if="sections.length">
      <vc-donut
        class="donut-chart-graph"
        background="white"
        foreground="white"
        :size="210"
        unit="px"
        :thickness="24"
        :sections="sections"
        :total="somaDocCounts"
        @section-mouseenter="itemHover($event)"
        :start-angle="0"
        :auto-adjust-text-size="true"
        ><h1
          style="margin: 0; font-size: 2.2vw"
          :style="{ color: hovered.color }"
        >
        {{ (hovered.value * 100 / somaDocCounts).toFixed(1) }}%
        </h1>
        <span style="text-transform: capitalize">{{hovered.label}}</span>
      </vc-donut
      >
      <div class="donut-chart-emotion">
      <div
        v-for="(emotion, idx) in sections"
        :key="emotion.label + emotion.value"
        :style="itemBackgroundColor(idx)"
        class="emotion-item"
        @mouseover="itemHover(emotion)"
      >
      <div class="emotion-info">
        <div :style="{ backgroundColor: emotion.color }" class="color-indicator"></div>
          <span>{{ emotion.label }}</span>
      </div>
          <span class="doc-count">{{ emotion.value }}</span>
        </div>
      </div>
    </div>
    <div class="alert-data" v-if="!data.length">
      <NoData />
    </div>
  </div>
</template>

<script>
import { generateColors } from './generateColorsInsights'
import NoData from '../NoData.vue'

export default {
  props: ['data'],
  components: { NoData },
  data () {
    return {
      sentiments: [],
      somaDocCounts: 10,
      hovered: {},
      sections: []
    }
  },
  async mounted () {
    if (!this.data.length) return
    const getDocCounts = this.data.map(obj => obj.doc_count)
    this.somaDocCounts = getDocCounts.reduce((a, b) => (a + b), 0)
    const sumItems = {}
    this.data.forEach(doc => {
      if (!sumItems[doc.key]) {
        sumItems[doc.key] = doc.doc_count
      } else {
        sumItems[doc.key] += doc.doc_count
      }
    })
    this.sections = this.data.map((item) => {
      return {
        value: item.doc_count,
        label: item.key,
        color: generateColors.generateColor(item.key)
      }
    })
    this.hovered = {
      value: this.sections[0].value,
      label: this.sections[0].label,
      color: this.sections[0].color
    }
  },
  methods: {
    itemHover (e) {
      this.hovered = e
    },
    itemBackgroundColor (idx) {
      return { backgroundColor: idx % 2 ? 'white' : '#eff3f8' }
    }
  }
}
</script>

<style lang='scss'>
.donut-chart-container {
  height: 500px;
  width: 24.6%;
  border-radius: 12px;
  border: 1px solid #e1e1e1;
  .donut-chart-header {
    width: 100%;
    height: 17%;
    border-bottom: 0.1rem solid #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    color: var(--accent-color);
    .donut-chart-title {
      width: fit-content;
      font-size: 1.3vw;
      font-weight: bold;
    }
  }
  .donut-chart-list {
    width: 100%;
    height: 83%;
    padding: 0.5rem;
    .donut-chart-graph {
      width: 100%;
      height: 55%;
    }
    .donut-chart-emotion {
      height: 45%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 0.2rem;
      padding: 0.5rem;
      overflow-y: auto;
      .emotion-item {
        height: 30px;
        background: #ffffff !important;
        width: fit-content;
        font-size: 0.8vw;
        border-radius: 0.3rem;
        border: 0.1rem solid #e1e1e1;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 0.2rem;
        color: #808080;
        .emotion-info {
          display: flex;
          cursor: pointer;
          .color-indicator {
            height: 17px;
            width: 40px;
            margin-right: 5px;
            border: 1px solid #cacaca;
            border-radius: 0.2rem;
          }
          span {
            text-transform: capitalize;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .doc-count {
          margin-left: auto;
        }
      }
    }
  }
  .alert-data {
    width: 100%;
    height: 83%;
  }
}
</style>
