import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

export const analyticsService = {
  getResponses,
  getWords,
  searchTerms,
  grouperQuestion,
  grouperQuestionResponses,
  getWordsList,
  getSentimentalsDoughnut,
  getSentimentalsOvertime,
  getSentimentalResponses,
  getResponsesWithTerm
}

async function getResponses (body) {
  const url = `${API_URL}text-analytics-responses?q=${body}`
  const result = await callApiService.callApi(url, 'get', null, null, null, null, null, false)
  return result
}
async function getResponsesWithTerm (body, term, questionId) {
  const url = `${API_URL}text-analytics-responses?q=${body}&term=${term}&grouperQuestion=${questionId}`
  const result = await callApiService.callApi(url, 'get', null, null, null, null, null, false)
  return result
}
async function getWords (body) {
  const url = `${API_URL}text-analytics-words?q=${body}`
  const result = await callApiService.callApi(url, 'get', [], 9)
  return result
}
async function getWordsList (body) {
  const url = `${API_URL}text-analytics-words?q=${body}`
  const result = await callApiService.callApi(url, 'get')
  return result
}
async function searchTerms (body, term, questionId) {
  const url = `${API_URL}text-analytics-words?q=${body}&term=${term}&grouperQuestion=${questionId}`
  const result = await callApiService.callApi(url, 'get', [], 10)
  return result
}

async function grouperQuestion (body, id) {
  const url = `${API_URL}text-analytics-words?grouperQuestion=${id}&q=${body}`
  const result = await callApiService.callApi(url, 'get', [], 10)
  return result
}
async function grouperQuestionResponses (body, id) {
  const url = `${API_URL}text-analytics-responses?grouperQuestion=${id}&q=${body}`
  const result = await callApiService.callApi(url, 'get')
  return result
}
async function getSentimentalsDoughnut (body, startDate, endDate) {
  let parsedQuery = body
  if (typeof body === 'object') {
    parsedQuery = JSON.stringify(body)
  }
  const url = `${API_URL}text-sentiments?q=${parsedQuery}&startDate=${startDate}&endDate=${endDate}`
  const result = await callApiService.callApi(url, 'get')
  return result
}
async function getSentimentalsOvertime (query, grid, unanswered) {
  let parsedQuery = query
  if (typeof query === 'object') {
    parsedQuery = JSON.stringify(query)
  }
  const points = grid ? encodeURI(grid) : {}
  const url = `${API_URL}text-sentiments-overtime?cleanEmptyQuestions=${unanswered}&grid=${points}&q=${parsedQuery}&tzOffset=${new Date().getTimezoneOffset()}`
  const result = await callApiService.callApi(url, 'get')
  return result
}
async function getSentimentalResponses (body, rangeEnd) {
  let parsedQuery = body
  if (typeof body === 'object') {
    parsedQuery = JSON.stringify(body)
  }
  const url = `${API_URL}text-sentiments-responses?q=${parsedQuery}`
  const result = await callApiService.callApi(url, 'get', null, rangeEnd, false, 0)
  return result?.data
}
