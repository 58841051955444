<template>
  <div class="container-card-metadata-insight">
    <div class="header-card-metadata-insight">
      <p class="title-card-metadata-insight"><span v-if="data.title !== 'Sentimento'">{{ $t('survey.metadata') }} -</span> {{ data.title }}</p>
    </div>
    <div class="content-values-card-metadata-insight">
      <div class="meta-analysis-insight" v-for="(meta, idx) in data.values" :key="idx">
        <p class="title-meta-insight"><span v-if="data.title !== 'Sentimento'">{{ data.title }}:</span> {{ capitalizeFirstLetter(meta.name) }}</p>
        <div class="header-meta-insight">
          <p class="title-header">
            <img :src="insights_icon" class="icon-insights" alt="insights">
            <span>Insights</span>
          </p>
          <p class="label-header">Emoção <i class="material-symbols-outlined help-info" v-title="'A emoção que se sobressai é aquela que apareceu com maior frequência nos comentários analisados.'" title-max-width="250" title-placement="left">info</i></p>
        </div>
        <div class="content-meta-insights">
          <div class="meta-insight positive">
            <i class="material-symbols-outlined icon-circle" v-title="'Positivo'">add_circle</i>
            <p class="label-meta-insights">{{ meta.positive.insight }}</p>
            <p class="emoji-meta-insights">{{ returnEmoji(meta.positive.emotion) }}</p>
          </div>
          <div class="meta-insight neutral">
            <i class="material-symbols-outlined icon-circle" v-title="'Neutro'">do_not_disturb_on</i>
            <p class="label-meta-insights">{{ meta.neutral.insight }}</p>
            <p class="emoji-meta-insights">{{ returnEmoji(meta.neutral.emotion) }}</p>
          </div>
          <div class="meta-insight negative">
            <i class="material-symbols-outlined icon-circle" v-title="'Negativo'">circle</i>
            <p class="label-meta-insights">{{ meta.negative.insight }}</p>
            <p class="emoji-meta-insights">{{ returnEmoji(meta.negative.emotion) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardMetadataInsight',
  props: ['data'],
  components: {},
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
    insights_icon () { return require('../../../assets/img/insights_icon.svg') }
  },
  methods: {
    capitalizeFirstLetter (label) {
      return label.charAt(0).toUpperCase() + label.slice(1)
    },
    returnEmoji (sentiment) {
      if (!sentiment) return '➖'
      const removedAccentuation = sentiment.normalize('NFD').replace(/\p{Mn}/gu, '')
      switch (removedAccentuation) {
        case 'alegria':
          return '😁'
        case 'aversao':
          return '😫'
        case 'confianca':
          return '😌'
        case 'empatia':
          return '😊'
        case 'frustracao':
          return '😖'
        case 'hostilidade':
          return '😡'
        case 'medo':
          return '😱'
        case 'raiva':
          return '😠'
        case 'surpresa':
          return '😦'
        case 'tristeza':
          return '😢'
        default:
          return '➖'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-card-metadata-insight {
  border-radius: 0.3rem;
  padding: 1rem;
  border: 0.1rem solid #e1e1e1;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.070);
  .header-card-metadata-insight {
    height: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
    .title-card-metadata-insight {
      font-size: 1.5rem;
      font-weight: bold;
      color: #454548;
    }
  }
  .content-values-card-metadata-insight {
    font-size: 1rem;
    font-weight: bold;
    color: #454548;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    .meta-analysis-insight {
      border-radius: 0.3rem;
      .title-meta-insight {
        font-weight: bold;
        font-size: 1.2rem;
        color: #454548;
      }
      .header-meta-insight {
        height: 2rem;
        margin: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title-header, .label-header {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          color: #808080;
          .icon-insights {
            width: 20px;
            height: 20px;
            filter: grayscale(1);
          }
          .help-info {
            font-size: 1.3rem;
            cursor: help;
          }
        }
      }
      .content-meta-insights {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .meta-insight {
          min-height: 2.5rem;
          border: 0.1rem solid #e1e1e1;
          border-radius: 0.3rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.5rem;
          padding: 0.5rem;
          .label-meta-insights {
            width: 100%;
            font-size: 1rem;
            line-height: 1.1rem;
            font-weight: 400;
          }
          .emoji-meta-insights {
            font-size: 1.3rem;
          }
        }
        .positive {
          background: #90ee9066;
          border-color: #90ee90;
        }
        .neutral {
          background: #d3d3d366;
          border-color: #d3d3d3;
        }
        .negative {
          background: #ffa07a66;
          border-color: #ffa07a;
        }
      }
    }
  }
}
</style>
