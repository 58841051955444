<template>
  <div class="container-card-log" v-if="data">
    <div class="image-card-log" :style="{'background': randomColor()}">{{ firstLetterName }}</div>
    <div class="label-card-log">
      <i class="material-icons icon-card-log" v-title="typeUser" :class="{'icon-user-log': typeUser === 'user'}">person</i>
      <p class="label-item-card-log">{{ data.user.name }}</p>
    </div>
    <div class="label-card-log">
      <i class="material-icons icon-card-log mail-icon" v-if="data.user?.email" v-title="$t('signup.email')">alternate_email</i>
      <p class="label-item-card-log">{{ data.user?.email }}</p>
    </div>
    <div class="label-card-log">
      <i class="material-symbols-outlined icon-card-log action-icon" v-title="$t('activity.interface.header.origin')">ads_click</i>
      <p class="label-item-card-log">{{ $t(`activity.${data.entity}_${data.action}`) }}</p>
    </div>
    <div class="action-card-log">
      <i class="material-icons icon-card-status-log" :style="{'color': returnIconAndColor(data.entity, 'color')}">{{ returnIconAndColor(data.entity, 'icon') }}</i>
      <p class="action-label-log">{{ $t(`activity.has_${data.action}`) }}</p>
      <p class="action-label-log">{{ interpolateString(data.entity) }}</p>
      <p class="action-this-log">{{ data.metadata.title }}</p>
    </div>
    <div class="label-card-log log-date">
      <i class="material-icons icon-card-log action-icon-date">calendar_today</i>
      <p class="label-item-card-log">{{ this.$moment(data.createdAt).format('lll') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardActivity',
  props: ['data'],
  components: {},
  data () {
    return {}
  },
  mounted () {
  },
  computed: {
    firstLetterName () { return this.data.user.name.charAt(0) },
    typeUser () { return this.data.user?.type }
  },
  methods: {
    interpolateString (entity) {
      if (entity === 'Scheduled') return this.$t('activity.interpolate.scheduled')
      if (entity === 'ResendSending') return this.$t('activity.interpolate.resend')
      if (entity === 'addTag' || entity === 'removeTag' || entity === 'tag') return this.$t('activity.interpolate.tag')
      if (entity === 'annotation') return this.$t('activity.interpolate.annotation')
      if (entity === 'user') return this.$t('activity.interpolate.user')
      if (entity === 'survey') return this.$t('activity.interpolate.survey')
      if (entity === 'ticket') return this.$t('activity.interpolate.ticket')
      if (entity === 'role') return this.$t('activity.interpolate.role')
      if (entity === 'message') return this.$t('activity.interpolate.message')
      if (entity === 'ticketCategory') return this.$t('activity.interpolate.category')
      if (entity === 'account') return this.$t('activity.interpolate.account')
      if (entity === 'seedBatch') return this.$t('activity.interpolate.seedBatch')
    },
    randomColor () {
      const red = Math.floor(Math.random() * 256)
      const green = Math.floor(Math.random() * 256)
      const blue = Math.floor(Math.random() * 256)
      const color = `rgb(${red}, ${green}, ${blue}, 0.8)`

      return color
    },
    returnIconAndColor (entity, type) {
      let submit = ''
      const listIcons = [
        { entity: 'survey', icon: 'feed', color: '#673ab7' },
        { entity: 'role', icon: 'groups', color: '#838383' },
        { entity: 'seedBatch', icon: 'view_list', color: '#00aaa7' },
        { entity: 'message', icon: 'view_cozy', color: '#ed4d7f' },
        { entity: 'ticket', icon: 'chat', color: '#138ae4' },
        { entity: 'ticketCategory', icon: 'chat', color: '#ba5d1a' },
        { entity: 'user', icon: 'manage_accounts', color: '#808080' },
        { entity: 'annotation', icon: 'sticky_note_2', color: '#af9ad6' },
        { entity: 'account', icon: 'group', color: '#af9ad6' },
        { entity: 'tag', icon: 'sell', color: '#e4a713' },
        { entity: 'addTag', icon: 'sell', color: '#31a35b' },
        { entity: 'removeTag', icon: 'sell', color: '#db6363' },
        { entity: 'BrandOnBindsVoiceScore_added', icon: 'cast_connected', color: '#41ab71' },
        { entity: 'BrandOnBindsVoiceScore_removed', icon: 'cast_connected', color: '#ab4141' },
        { entity: 'BrandOnBindsVoiceScore_created', icon: 'cast_connected', color: '#6094e3' },
        { entity: 'BrandOnBindsVoiceScore', icon: 'cast_connected', color: '#cacaca' },
        { entity: 'ResendSending', icon: 'schedule_send', color: '#31a35b' },
        { entity: 'Scheduled', icon: 'cancel_schedule_send', color: '#db6363' }
      ]
      const selected = listIcons.find(item => item.entity === entity)
      try {
        submit = type === 'icon' ? selected.icon : selected.color
      } catch (err) {
        submit = 'blank'
      }
      return submit
    }
  }
}
</script>

<style lang="scss">
.container-card-log {
  width: 100%;
  height: 2.5vw;
  border-bottom: 1px solid #e1e1e1;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .image-card-log {
    width: 1.8vw;
    height: 1.8vw;
    margin-right: 1rem;
    border-radius: 50%;
    border: 1px solid #f1f1f1;
    font-weight: bold;
    font-size: 0.8vw;
    color: #ffffff;
    box-shadow: 0px 0px 5px #cacaca;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-card-status-log {
    font-size: 1vw;
    margin-right: 0.5rem;
  }
  .label-card-log {
    width: 15%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .icon-card-log {
      font-size: 0.9vw;
      color: #c7c736;
      cursor: help;
    }
    .icon-user-log {
      color: #cacaca;
    }
    .mail-icon, .action-icon-date {
      color: #db6363;
    }
    .action-icon {
      color: #cacaca;
    }
    .label-item-card-log {
      width: 80%;
      color: #808080;
      font-size: 0.9vw;
      line-height: 1vw;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .log-date {
    .label-item-card-log {
      width: fit-content;
    }
  }
  .title-card-log {
    color: #808080;
    font-size: 0.8vw;
    font-weight: 500;
  }
  .action-card-log {
    width: 30%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    .icon-card-log {
      font-size: 0.9vw;
      color: #808080;
      cursor: help;
    }
    .action-label-log {
      color: #808080;
      font-size: 0.9vw;
      line-height: 0.95vw;
      font-weight: 500;
    }
    .action-this-log {
      font-size: 0.9vw;
      line-height: 0.95vw;
      color: #454548;
      max-width: 10rem;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.container-card-log:nth-child(even) {
  background: #f7fbff;
}
</style>
