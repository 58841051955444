import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

export const filtersService = {
  createCustomFilter,
  deleteCustomFilter,
  getCustomFilters,
  getSpecificFilter
}

async function createCustomFilter (body) {
  const result = await callApiService.callApi(`${API_URL}filters`, 'post', body)
  return result
}

async function deleteCustomFilter (id) {
  const result = await callApiService.callApi(`${API_URL}filters/${id}`, 'delete')
  return result
}

async function getCustomFilters (entity) {
  const result = await callApiService.callApi(`${API_URL}filters?entity=${entity}`, 'get')
  return result
}

async function getSpecificFilter (id) {
  const result = await callApiService.callApi(`${API_URL}filters/${id}`, 'get')
  return result
}
