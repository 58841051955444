<template>
  <div style="height: 100vh; width: 100vw; position: fixed; top: 0; left: 0; z-index: 99; background: transparent;" @click="handleClickOutsideSidebar($event)">
    <div class="metadata" ref="container">
      <div>
        <div class="row">
          <div class="my-profile-header">
            <div class="profile-button close-config col-lg-12 col-md-12 col-xs-12 col-sm-12 col-xs-12">
              <button v-on:click="showCloseModal = true">
                <app-textstyle color="purple" size="size-xl">×</app-textstyle>
              </button>
            </div>
            <div class="profile-meta-heading">
              <app-heading level="h5">{{$t('metadata.title')}}</app-heading>
            </div>
            <div class="profile-button close-config col-lg-12 col-md-12 col-xs-12 col-sm-12 col-xs-12">
              <button v-on:click="showCloseModal = true">
                <app-textstyle color="purple" size="size-xl">×</app-textstyle>
              </button>
            </div>
            <div class="profile-meta-heading">
              <app-heading level="h5">{{$t('metadata.title')}}</app-heading>
            </div>
          </div>

          <div style="position: sticky; top: 0; background-color: white; z-index: 9999;">
            <div class="tooltip-meta-info">
              <app-heading level="h5">
                {{$t('metadata.manageFields')}}
                <i
                  class="material-icons"
                  style="color: #a2a2a2;"
                  v-title="$t('metadata.ranking')"
                  title-placement="bottom-end"
                  title-max-width="350"
                >help</i>
              </app-heading>
            </div>
            <div style="display: flex; justify-content: center; align-items: flex-end">
              <div class="metadata-label-btn">
                <app-heading level="h6">{{ $t('metadata.field') }}:</app-heading>
                <app-form-input
                  class="messageLabel"
                  v-model="newField.key"
                  border-bottom
                ></app-form-input>
              </div>
              <div class="metadata-label-btn">
                <app-heading level="h6">{{ $t('metadata.label') }}:</app-heading>
                <app-form-input
                  class="messageLabel"
                  v-model="newField.label"
                  border-bottom
                ></app-form-input>
              </div>
              <div class="add-button" @click="addField">
                <app-button variation="primary" size="small" floating>
                  <i class="material-icons plus-icon">add</i>
                </app-button>
                <app-heading level="h7">
                  {{ $t('metadata.add_field') }}
                </app-heading>
              </div>
            </div>
            <div class="save-metadata">
              <app-button variation="primary" v-on:click="save()">{{ $t('profile.save') }}</app-button>
            </div>

            <hr style="width: 100%; color: rgba(0, 0, 0, 0.3); left: 0;">
          </div>

          <div class="data-loading-metadata" v-if="loading">
            <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
          </div>

          <div v-for="(item, index) in fields" :key="`${index}${key}`" ref="fieldsItems">
            <!-- If the field isn't new, read only. -->
            <hr v-if="index !== 0" style="width: 100%; color: rgba(0, 0, 0, 0.3); left: 0;">

            <div v-if="!item.new" style="display: flex; align-items: center">
              <div style="display: flex; width: 100%;">
                <div class="metadata-label-btn">
                  <app-heading level="h6">{{ $t('metadata.field') }}:</app-heading>
                  <app-form-input
                    class="messageLabel disabled-input"
                    v-model="item.key"
                    border-bottom
                    disabled
                  ></app-form-input>
                </div>
                <div class="metadata-label-btn">
                  <app-heading level="h6">{{ $t('metadata.label') }}:</app-heading>
                  <app-form-input
                    class="messageLabel disabled-input"
                    v-model="item.label"
                    border-bottom
                  ></app-form-input>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 metadata-label-btn metadata-options">
                <div class="switch-rankby">
                  <input type="checkbox" class="groupable-check" v-model="item.groupable" @change="changeGroupable(item)">
                  <app-heading style="padding: 4px 0px;" level="h6">{{$t('metadata.groupable')}}</app-heading>
                </div>
                <div class="switch-rankby">
                  <input type="checkbox" class="groupable-check" v-model="item.enabledInsights" @click="changeInsights(item)">
                  <app-heading style="padding: 4px 0px;" level="h6">{{$t('metadata.enable_insight')}}</app-heading>
                </div>
              </div>
              <div v-on:click="itemMeta = item, showModal = true" v-if="user.type !== 'user'" class="delete-metadata" style="margin-top: 30px">
                <app-button
                  inline
                  icon="trash-alt"
                  icon-color="gray"
                  icon-size="xs"
                  class="col-xs-3 col-xxs-3"
                  title-placement="right"
                  v-title="$t('survey.delete_input')"
                >
                </app-button>
              </div>
            </div>
          </div>
        </div>
        <app-modal
          v-model="showModal"
          :title="this.$t('profile.delete_metadata')"
          lightbox
          close
          class="modal-default-primary"
          :primary-button="this.$t('account_config.delete_confirm.yes')"
          @primaryButtonClick="deleteMeta()"
          :secondary-button="this.$t('account_config.delete_confirm.no')"
          @secondaryButtonClick="showModal = false"
        ></app-modal>
      </div>
    </div>
    <app-modal
      v-model="showCloseModal"
      :title="$t('survey.exit_whithout_save')"
      lightbox
      close
      class="modal-default-primary"
      :primary-button="this.$t('account_config.delete_confirm.yes')"
      @primaryButtonClick="canBeClosed = true; handleCloseSidebar()"
      :secondary-button="this.$t('account_config.delete_confirm.no')"
      @secondaryButtonClick="showCloseModal = false, canBeClosed = false"
    >
      <app-heading level="h7">{{ $t('survey.exit_message') }}</app-heading>
    </app-modal>
  </div>
</template>

<script>
import { accountService, userService } from '@/_services'
import { mapGetters } from 'vuex'
export default {
  name: 'Metadata',
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal')
  },
  data () {
    return {
      key: 0,
      fields: [],
      newField: { key: '', label: '', type: this.$i18n.t('metadata.text_type'), new: true, groupable: false, enabledInsights: false },
      accounts: [],
      selectedAccount: {},
      loading: false,
      itemMeta: '',
      showModal: false,
      showCloseModal: false,
      canBeClosed: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    })
  },
  created () {
    const onEscape = (e) => {
      if (e.keyCode === 27) {
        this.showCloseModal = true
      }
    }

    document.addEventListener('keydown', onEscape)

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape)
    })
  },
  async mounted () {
    this.loading = true
    const user = await userService.getMe()
    this.selectedAccount = user.account
    this.getFields()
  },
  methods: {
    changeGroupable (e) {
      this.fields.forEach((meta, idx) => {
        if (meta.key === e.key) { this.fields.splice(idx, 1, e) }
      })
    },
    changeInsights (e) {
      if (!e.enabledInsights) {
        const selectedCount = this.fields.filter(item => item.enabledInsights).length + 1

        if (selectedCount > 3) {
          e.enabledInsights = false
          this.key++
          return this.$store.commit('alerts/alert', {
            message: this.$i18n.t('metadata.error_enable_insight'),
            position: 'bottomRight',
            showAlert: true
          })
        }
      }

      this.fields.forEach((meta, idx) => {
        if (meta.key === e.key) { this.fields.splice(idx, 1, e) }
      })
    },
    handleCloseSidebar () {
      if (this.canBeClosed && this.showCloseModal) this.$root.$emit('close-metadata-config')
      else return null
    },
    handleClickOutsideSidebar (event) {
      if (event.target._prevClass === 'expand-profile') this.showCloseModal = true
      else return null
    },
    addField () {
      if (this.newField.key.trim().length === 0 || this.newField.label.trim().length === 0) return
      const existMeta = this.fields.find(f => f.key === this.newField.key)

      if (existMeta) {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('metadata.error_add'),
          position: 'bottomRight',
          showAlert: true
        })
      } else {
        this.fields.push({ key: this.newField.key.trim(), label: this.newField.label.trim(), type: this.$i18n.t('metadata.text_type'), new: false, groupable: false, enabledInsights: false })
        this.newField.key = ''
        this.newField.label = ''
        this.newField.enabledInsights = false
        // hack para scrollar para o último elemento
        // não é possível recueprar o elemento no tempo de execução normal
        const lastField = this.fields.length - 1
        setTimeout(() => {
          this.scrollToElement(lastField)
        }, 200)
      }
    },
    scrollToElement (index) {
      const element = this.$refs.fieldsItems[index]
      this.$refs.container.scrollTop = element.offsetTop
    },
    deleteMeta () {
      this.fields.forEach((e, i) => {
        if (e.key === this.itemMeta.key) {
          this.fields.splice(i, 1)
        }
      })
      this.showModal = false
    },
    async getFields () {
      this.loading = true
      const account = await accountService.getAccount(this.selectedAccount._id)
      if (account.fields.length > 0) {
        account.fields.map(item => {
          // We save type String, but we show "Text" translated string on the input.
          item.type = this.$i18n.t('metadata.text_type')
          item.new = false
          this.fields.push(item)
        })
      }
      this.loading = false
    },
    async save () {
      this.loading = true
      const fieldsToSave = []
      this.fields.map(field => {
        // We save type as String, but we show "Text" translated string on the input.
        fieldsToSave.push({ key: field.key, type: 'String', label: field.label, groupable: field.groupable, enabledInsights: field.enabledInsights })
      })

      if (fieldsToSave.length > 0) {
        this.selectedAccount.fields = fieldsToSave
        const updateAccount = await accountService.updateAccount(this.selectedAccount._id, this.selectedAccount)
        if (updateAccount) {
          this.loading = false
          this.$store.commit('alerts/alert', {
            message: this.$i18n.t('profile.success'),
            position: 'bottomRight',
            showAlert: true
          })
          this.$root.$emit('update-fields')
        } else {
          this.loading = false
          this.$store.commit('alerts/alert', {
            message: this.$i18n.t('profile.error'),
            position: 'bottomRight',
            showAlert: true
          })
        }
      }
      this.$root.$emit('close-metadata-config')
    }
  }
}
</script>

<style lang="scss">
.metadata {
  height: 100vh;
  z-index: 9999;
  width: 28rem;
  background-color: #fff;
  position: fixed;
  overflow: auto;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 10px 10px 5px 10px rgba(48, 48, 48, 0.5);
  scroll-behavior: smooth;
}

.my-profile-header {
  background: var(--primary-color);
  .profile-meta-heading {
    h5 {
      color: var(--default-text-color) !important;
    }
  }
  .profile-button {
    button {
      span {
        margin: 0;
        color: var(--default-text-color) !important;
      }
    }
  }
}
.delete-metadata {
  .b-icon {
    color: gray !important;
  }
}
.groupable-check {
  cursor: pointer;
}
.add-button {
  width: 95%;
  margin-right: 15px;
}

.metadata-button {
  top: -20px;
  right: 0;
  position: absolute;
}
.metadata-heading {
  & .heading {
    padding: 15px !important;
  }
  & .h7 {
    margin-top: -20px !important;
    margin-left: 2px !important;
  }
}
.switch-rankby {
  width: 70px;
  display: flex;
  h6.heading {
    margin-left: 10px;
  }
  .ui-switch {
    cursor: pointer;
  }
}
.metadata-options {
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  .switch-rankby {
    accent-color: var(--accent-color);
    input {
      height: 17px;
      width: 17px;
    }
  }
}
.metadata-label-btn {
  padding: 15px 10px;
  width: 80%;
  & h6 {
    font-size: 14px !important;
  }
  & .text-field__input {
    input.user-click {
      font-size: 14px;
      background: #D9D9D933;
    }
  }
  & .disabled-input {
    & .text-field__input {
      input {
        color: #9a9a9a !important;
      }
    }
  }
}
.add-button {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  p.heading {
    padding-bottom: 20px;
  }
  & .binds-button.small.floating {
    width: 27px !important;
    height: 27px !important;
    background: var(--accent-color);
    box-shadow: var(--accent-color);
  }
}
.plus-icon {
  margin-top: 7px;
  font-size: 22px !important;
}
.data-loading-metadata {
  width: 28rem;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 110px;
  right: 0;
  z-index: 99999;
  .data-loading-spinner {
    margin-top: 50% !important;
    margin-left: 48% !important;
    & .spinner-inner {
      background-color: #fff !important;
    }
  }
}
.profile-meta-heading {
  margin-top: 25px;
  h5 {
    padding:15px !important;
    padding-bottom: 5px !important;
  }
  p {
    padding-left:15px !important;
  }
}
.save-metadata {
  margin-top: 15px !important;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  button {
    width: 230px;
    padding: 9px 10px !important;
    margin: 0;
    font-size: 14px !important;
    background: var(--accent-color) !important;
    box-shadow: var(--accent-color) !important;
    &.hover,
    &:hover {
      background: var(--secondary-color) !important;
      box-shadow: var(--secondary-color) !important;
    }
  }
}
.delete-item-meta {
  margin-left: 10px;
  margin-top: 5px;
  .binds-button.medium {
    padding: 0;
    margin-top: 10px;
  }
}
.tooltip-meta-info {
  margin: 30px 16px;
  h5.heading {
    display: flex;
    align-items: center;

    .material-icons {
        font-size: 20px;
        margin-left: 5px;
      }
  }
}
</style>
