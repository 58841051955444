<template>
  <div class="container-card-dashboard" :style="{'height': isCustom ? '25vw' : '29vw'}" :class="{ 'double-width': card.type === 'matrix' || card.type === 'text' }">
    <div class="content-loading-card-dashboard" :ref="`ref-card-${card._id}`" v-if="loading">
      <Circle2 class="spinner-social-card-dashboard"/>
      <p class="title-loading-card-dashboard">{{ $t('dashboard.loading_graphic') }}</p>
    </div>
    <div class="content-not-available" v-if="noDataToShow || !card.total">
      <i class="material-icons icon-config-question" @click="$emit('edit-card')" v-title="$t('dashboard.config_graphic')">settings</i>
      <NotFound />
    </div>
    <div class="header-card-dashboard" v-show="!loading && !noDataToShow">
      <div class="title-card-dashboard">
        <p class="label-card-dashboard">{{ card.title || card.question.title || card.question.question }}</p>
        <i class="material-icons icon-card-dashboard" :class="{'has-filter': card.query}" v-title="card.query ? $t('dashboard.has_filter') : ''">filter_alt</i>
        <i class="material-icons icon-config-question" @click="$emit('edit-card')" v-title="$t('dashboard.config_graphic')">settings</i>
      </div>
      <div class="stats-card-dashboard">
        <div class="count-responses-dashboard">
          <i class="material-icons icon-card-dashboard icon-responses" v-title="$t('dashboard.individual_responses')">ads_click</i>
          <p class="title-responses-card-dashboard" v-if="card.total > 0">{{ formattedNumber }} {{ formattedNumber > 1 ? $t('dashboard.individual_responses') : $t('dashboard.individual_response') }}</p>
          <p class="title-responses-card-dashboard" v-else>{{ $t('dashboard.no_responses_label') }}</p>
        </div>
        <div class="icons-card-dashboard">
          <i class="material-icons icon-card-dashboard"
            v-for="icon in questionTypes"
            :key="icon.type" v-title="icon.type === card.type ? `Tipo: ${icon.name}` : ''"
            :style="{'color': icon.type === card.type ? icon.color : ''}">
            {{ icon.value }}
          </i>
        </div>
      </div>
    </div>
    <div class="content-card-dashboard" v-show="!loading" :style="{'height': handlerHeight }">
      <MiddleChartDefault
        v-if="!isCustom && !health"
        :loading="loading"
        :cardData="card"
        :keyCard="keyCard"
        :id="`chart-${card._id}`"
        :allQuestionsSynthetic="allQuestionsSynthetic"
        :allQuestionsOvertime="allQuestionsOvertime"
        :allQuestionsOverall="allQuestionsOverall"
        @card-data-done="loadingDone(card._id)"
        @no-data-to-show="notAvailable(card._id)"
      />
      <MiddleChartCustom
        v-if="isCustom && !health"
        :loading="loading"
        :cardData="card"
        :keyCard="keyCard"
        :id="`chart-${card._id}`"
        :allQuestionsSynthetic="allQuestionsSynthetic"
        :allQuestionsOvertime="allQuestionsOvertime"
        :allQuestionsOverall="allQuestionsOverall"
        @card-data-done="loadingDone(card._id)"
        @no-data-to-show="notAvailable(card._id)"
      />
    </div>
    <div class="section-buttons-view-dashboard" v-show="!isCustom && !loading && (card.question.type !== 'text' || card.question.type === 'like')">
      <ChangeTab :renderTabs="tabs" @tab-active="selectView($event)"></ChangeTab>
    </div>
  </div>
</template>
<script>
import gsap from 'gsap'
import MiddleChartCustom from '../dashboard/dashboardCustom/MiddleChartCustom.vue'
import MiddleChartDefault from '../dashboard/charts/MiddleChartDefault.vue'
import NotFound from '../dashboard/charts/NotFound.vue'
import ChangeTab from './ChangeTab.vue'
import { gridMaker } from '@/_helpers'
import('lodash-es').then((lodashEs) => { window._ = lodashEs.default })

export default {
  name: 'CardDashboard',
  props: [
    'card',
    'health',
    'isCustom',
    'allQuestionsSynthetic',
    'allQuestionsOvertime',
    'allQuestionsOverall'
  ],
  components: {
    NotFound,
    ChangeTab,
    MiddleChartCustom,
    MiddleChartDefault,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      loading: true,
      keyCard: 0,
      noDataToShow: false,
      questionTypes: [
        { type: 'csat', name: 'CSAT', value: 'mood', color: '#2ed976cc' },
        { type: 'nps', name: 'NPS', value: 'speed', color: '#382ad6cc' },
        { type: 'kpi', name: 'KPI', value: 'insert_chart', color: '#ff4bd7cc' },
        { type: 'nes', name: 'CES', value: 'fitness_center', color: '#20be2bcc' },
        { type: 'nvs', name: 'NVS', value: 'volunteer_activism', color: '#DDD042cc' },
        { type: 'matrix', name: 'MATRIZ', value: 'format_list_numbered', color: '#0014d6cc' },
        { type: 'ces', name: 'CES 2.0', value: 'fitness_center', color: '#eb7024cc' },
        { type: 'text', name: 'TEXTO', value: 'text_fields', color: '#f0062acc' },
        { type: 'referral', name: 'INDICAÇÃO', value: 'share', color: '#adaf41cc' },
        { type: 'enum', name: 'ENUM', value: '123', color: '#454548' }
      ],
      tabs: [
        { render: true, active: false, title: this.$t('dashboard.views.average'), value: 'average', icon: 'speed' },
        { render: true, active: false, title: this.$t('dashboard.views.overtime'), value: 'overtime', icon: 'monitor_heart' },
        { render: true, active: false, title: this.$t('dashboard.views.overall'), value: 'overall', icon: 'analytics' }
      ]
    }
  },
  created () {
    this.tabs.forEach(tab => {
      tab.active = false
      if (this.card.viewType === tab.value) { tab.active = true }
    })

    this.tabs = this.tabs.filter(tab =>
      !(this.card.question.type === 'matrix' && tab.value === 'overall')
    )
  },
  mounted () {
    if (this.isCustom && !this.card.query) {
      if (this.card.viewType === 'overall' && !this.allQuestionsOverall.length) {
        return this.getDataForCard(this.card.viewType)
      }

      if (this.card.viewType === 'overtime' && !this.allQuestionsOvertime.length) {
        return this.getDataForCard(this.card.viewType)
      }
    }
  },
  computed: {
    icon () { return this.questionTypes.find(item => item.type === this.card.type) },
    formattedNumber () { return parseFloat(this.card.total).toLocaleString('pt-BR') },
    handlerHeight () {
      if (this.card.ui === 'text' || this.isCustom) { return '85%' }
      return '75%'
    }
  },
  watch: {
    allQuestionsOverall: {
      handler () {
        if (!this.card.query) { this.keyCard++ }
      }
    },
    allQuestionsOvertime: {
      handler () {
        if (!this.card.query) { this.keyCard++ }
      }
    }
  },
  methods: {
    getDataForCard (view) {
      const query = this.$route.query.q
      const gridRange = gridMaker.getGrid(gridMaker.getDataFromQuery(query).$gte, gridMaker.getDataFromQuery(query).$lte)
      const event = view === 'overtime' ? 'get-overtime-data' : 'get-overall-data'
      return this.$root.$emit(event, query, gridRange)
    },
    selectView (view) {
      this.loading = true
      this.card.viewType = view
      this.query = this.$route.query.q

      if (view === 'overall' && !this.allQuestionsOverall.length && !this.health) {
        return this.getDataForCard(view)
      }

      if (view === 'overtime' && !this.allQuestionsOvertime.length && !this.health) {
        return this.getDataForCard(view)
      }

      this.keyCard++
    },
    notAvailable (cardId) {
      this.noDataToShow = true
      this.loadingDone(cardId)
    },
    loadingDone (refLoad) {
      const refLoading = this.$refs[`ref-card-${refLoad}`]

      if (!refLoading) return

      gsap.to(refLoading, {
        opacity: 0,
        duration: 0.5,
        onComplete: () => { this.loading = false }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-card-dashboard {
  position: relative;
  background: #ffffff;
  width: 22.1vw;
  border-radius: 0.8rem;
  padding: 1rem;
  overflow: hidden;
  border: 1px solid #e1e1e1;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.06);
  .content-loading-card-dashboard {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    background: #ffffff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    .spinner-social-card-dashboard {
      width: 2vw !important;
      height: 2vw !important;
      border-width: 3px !important;
      border-color: var(--accent-color) #cacaca #cacaca !important;
    }
    .title-loading-card-dashboard {
      font-size: 0.65vw;
      color: #cacaca;
      font-weight: 500;
    }
  }
  .content-not-available {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-config-question {
      position: absolute;
      right: 0;
      top: 0;
      background: #9696A0;
      width: 1.4vw;
      height: 1.4vw;
      border-radius: 50%;
      font-size: 1vw;
      color: var(--default-text-color);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .header-card-dashboard {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .title-card-dashboard {
      background: #f9f9f9;
      height: 1.5vw;
      border-radius: 35px;
      border: 1px solid #f1f1f1;
      padding-left: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.05);
      .icon-card-dashboard {
        font-size: 0.9vw;
        color: #e7e7e7;
        cursor: default;
      }
      .has-filter {
        color: var(--accent-color);
      }
      .icon-config-question {
        background: #9696A0;
        width: 1.4vw;
        height: 1.4vw;
        border-radius: 50%;
        font-size: 1vw;
        color: var(--default-text-color);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .label-card-dashboard {
        flex: 1;
        font-size: 0.85vw;
        line-height: 1vw;
        font-weight: 500;
        color: #454548;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .stats-card-dashboard {
      background: #f9f9f9;
      height: 1.5vw;
      border-radius: 35px;
      border: 1px solid #f1f1f1;
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.3rem;
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.05);
      .icon-card-dashboard {
        font-size: 0.8vw;
        color: #e7e7e7;
        cursor: default;
      }
      .count-responses-dashboard {
        display: flex;
        align-items: center;
        gap: 0.1rem;
        .title-responses-card-dashboard {
          font-size: 0.8vw;
          color: #454548;
        }
        .icon-responses {
          color: #db6363;
        }
      }
      .icons-card-dashboard {
        display: flex;
        align-items: center;
        gap: 0.1rem;
      }
    }
  }
  .content-card-dashboard {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section-buttons-view-dashboard {
    height: 10%;
  }
}

.double-width {
  width: calc(50% - 0.39rem);
}
</style>
