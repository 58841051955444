import Vue from 'vue'
import Router from 'vue-router'
import i18n from '../translations/i18n'
import Meta from 'vue-meta'
import Login from '@/pages/Login'
import SingleSignOn from '@/pages/SingleSignOn'
import ChangeVersion from '@/pages/ChangeVersion'
import Panel from '@/pages/Panel'
import AccountsList from '@/pages/AccountsList'
import TwoFactorAuth from '@/pages/TwoFactorAuth'
import Roles from '@/pages/Roles'
import Tickets from '@/pages/Tickets'
import Insights from '@/pages/Insights'
import ResendList from '@/pages/ResendList'

// relatórios
import Overview from '@/pages/Overview'
import IndividualPage from '@/pages/IndividualPage'
import Referrals from '@/pages/Referrals'
import Ranking from '@/pages/Ranking'
import Performance from '@/pages/Performance'
import RealTime from '@/pages/RealTime'

import ConfirmResetPass from '@/pages/ConfirmResetPass'
import Surveys from '@/pages/Surveys'
import SurveysList from '@/pages/SurveyList'
import Start from '@/pages/Start'
import Send from '@/pages/Send'
import Sendhub from '@/pages/Sendhub'
import Templates from '@/pages/Templates'
import Users from '@/pages/Users'
import NotFound from '@/pages/404'
import YouCant from '@/pages/YouCant'
import Approaches from '@/pages/Approaches'
import Analytics from '@/pages/Analytics'
import Sentimentals from '@/pages/Sentimentals'
import Activity from '@/pages/Activity'
import Social from '@/components/socialMedia/Index.vue'
import Store from '@/store/'

Vue.use(Router)
Vue.use(Meta)

export const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: `${i18n.t('pageTitles.login')} - binds.co`
      }
    },
    {
      path: '/login/:lang',
      name: 'LoginFromSite',
      component: Login,
      meta: {
        title: `${i18n.t('pageTitles.login')} - binds.co`
      }
    },
    {
      path: '/signup/:lang',
      name: 'SignUp',
      component: Login,
      meta: {
        title: 'SignUp - binds.co'
      }
    },
    {
      path: '/login/authenticationWithSSO/setToken',
      name: 'SingleSignOn',
      component: SingleSignOn
    },
    {
      path: '/accessV2FromV1/:account/:user/:token',
      name: 'ChangeVersion',
      component: ChangeVersion
    },
    {
      path: '/reset-password/:token',
      name: 'ConfirmResetPass',
      component: ConfirmResetPass,
      query: {
        email: ''
      }
    },
    {
      path: '/twofactorauth',
      name: 'TwoFactor',
      params: {
        twoFactorAuth: false,
        codeSendBy: null,
        sendTo: null,
        loginData: {}
      },
      component: TwoFactorAuth
    },
    {
      path: '/accounts',
      name: 'Accounts',
      component: AccountsList,
      meta: {
        roles: ['superadmin'],
        title: `${i18n.t('pageTitles.account_list')} - binds.co`
      }
    },
    {
      path: '/panel',
      name: 'Panel',
      component: Panel,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: `${i18n.t('pageTitles.panel')} - binds.co`
      }
    },
    {
      path: '/text-analytic/cloud-words',
      name: 'Analytics',
      component: Analytics,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: `${i18n.t('pageTitles.cloud_words')} - binds.co`
      }
    },
    {
      path: '/sentimental',
      name: 'Sentimentals',
      component: Sentimentals,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: `${i18n.t('pageTitles.sentimental')} - binds.co`
      }
    },
    {
      path: '/social',
      name: 'Social',
      component: Social,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: 'Mídia Social - binds.co'
      }
    },
    {
      path: '/dashboard/overview',
      name: 'Overview',
      query: '',
      component: Overview,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: `${i18n.t('pageTitles.overview')} - binds.co`
      }
    },
    {
      path: '/roles',
      name: 'Roles',
      query: '',
      component: Roles,
      meta: {
        roles: ['admin', 'superadmin'],
        title: `${i18n.t('pageTitles.roles')} - binds.co`
      }
    },
    {
      path: '/dashboard/individual',
      name: 'Individual',
      query: '',
      component: IndividualPage,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: `${i18n.t('pageTitles.individual')} - binds.co`
      }
    },
    {
      path: '/dashboard/referrals',
      name: 'Referrals',
      query: '',
      component: Referrals,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: `${i18n.t('pageTitles.referrals')} - binds.co`
      }
    },
    {
      path: '/dashboard/ranking',
      name: 'Ranking',
      query: '',
      component: Ranking,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: `${i18n.t('pageTitles.ranking')} - binds.co`
      }
    },
    {
      path: '/dashboard/comparative-dashboard',
      name: 'Performance',
      query: '',
      component: Performance,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: `${i18n.t('pageTitles.performance')} - binds.co`
      }
    },
    {
      path: '/realtime',
      name: 'RealTime',
      query: '',
      component: RealTime,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: `${i18n.t('pageTitles.realtime')} - binds.co`
      }
    },
    {
      path: '/surveys/:id',
      name: 'Surveys',
      component: Surveys,
      meta: {
        roles: ['admin', 'superadmin', 'editor', 'user']
      }
    },
    {
      path: '/survey-list/',
      name: 'SurveysList',
      component: SurveysList,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: `${i18n.t('pageTitles.surveys')} - binds.co`
      }
    },
    {
      path: '/send/:id',
      name: 'Send',
      component: Send,
      meta: {
        roles: ['admin', 'superadmin', 'user'],
        title: `${i18n.t('pageTitles.send')} - binds.co`
      }
    },
    {
      path: '/sendhub/:id',
      name: 'Sendhub',
      component: Sendhub,
      meta: {
        roles: ['admin', 'superadmin', 'user']
      }
    },
    {
      path: '/templates',
      name: 'Templates',
      component: Templates,
      meta: {
        roles: ['admin', 'superadmin', 'editor'],
        title: `${i18n.t('pageTitles.template')} - binds.co`
      }
    },
    {
      path: '/start',
      name: 'Start',
      component: Start,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: `${i18n.t('pageTitles.dashboard')} - binds.co`
      }
    },
    {
      path: '/users',
      name: 'Users',
      component: Users,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: `${i18n.t('pageTitles.users')} - binds.co`
      }
    },
    {
      path: '/404',
      name: 'NotFound',
      component: NotFound,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor']
      }
    },
    {
      path: '/you-cant',
      name: 'YouCant',
      component: YouCant,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor']
      }
    },
    {
      path: '/approaches',
      name: 'Approaches',
      component: Approaches,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: `${i18n.t('pageTitles.approaches')} - binds.co`
      }
    },
    {
      path: '/tickets',
      name: 'Tickets',
      query: '',
      component: Tickets,
      meta: {
        roles: ['admin', 'superadmin', 'user', 'editor'],
        title: `${i18n.t('pageTitles.ticket')} - binds.co`
      }
    },
    {
      path: '/activity',
      name: 'Activity',
      query: '',
      component: Activity,
      meta: {
        roles: ['admin', 'superadmin'],
        title: `${i18n.t('activity.title')} - binds.co`
      }
    },
    {
      path: '/insights',
      name: 'Insights',
      query: '',
      component: Insights,
      meta: {
        roles: ['admin', 'superadmin', 'user'],
        title: 'Insights - binds.co'
      }
    },
    {
      path: '/resend-list',
      name: 'ResendList',
      query: '',
      component: ResendList,
      meta: {
        roles: ['admin', 'superadmin', 'user'],
        title: `${i18n.t('resend.title_schedule')} - binds.co`
      }
    },
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/',
      redirect: '/realtime'
    }
  ],
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  const publicRedirect = to.path.includes('accessV2FromV1') || to.path.includes('/reset-password/') ? to.path : undefined
  const listOfNoAuthPages = ['/login', '/login/authenticationWithSSO/setToken', publicRedirect]
  if (['SignUp', 'LoginFromSite'].indexOf(to.name) > -1) {
    listOfNoAuthPages.push(to.path)
  }
  const authRequired = !listOfNoAuthPages.includes(to.path)
  let getUser = {}
  window.document.title = to.meta.title ? to.meta.title : 'Binds.co'

  if (Store.state && Store.state.account) {
    getUser = Store.state.account.user
  }

  // move the user to verification code if he has TFA
  if (to.path === '/twofactorauth' && from.path === '/login') {
    return next()
  }
  const loggedIn = (getUser && getUser._id) ? getUser._id : false
  if (authRequired && !loggedIn) {
    localStorage.clear()
    return next('/login')
  }

  if (to.path === '/login' && loggedIn) {
    return next('/')
  }

  if (loggedIn && authRequired) {
    if (getUser.type && to.meta.roles && !to.meta.roles.includes(getUser.type)) {
      return next('/you-cant')
    }
  }

  if (to.name === 'Insights') {
    if (getUser.type === 'user' && !getUser.features.insights) { return next('/you-cant') }
    return next()
  }

  next()
})
