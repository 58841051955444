import { callApiService } from '@/_services'
import { orderFormater } from '../_helpers/survey.order'

const API_URL = process.env.VUE_APP_URL

export const surveyService = {
  createSurvey,
  deleteSurvey,
  cloneSurvey,
  getSurvey,
  getAllSurveys,
  saveSurvey,
  createMessages,
  updateMessages,
  getMessages,
  updateSurvey,
  hasConfirmedEmail,
  resendEmail,
  saveEmailConfig,
  getQuestions,
  setTheme,
  getTheme,
  expireOrActivateSurvey
}

async function getSurvey (id) {
  const questionsUrl = API_URL + 'surveys/' + id
  const result = await callApiService.callApi(questionsUrl, 'get')
  return result
}
async function getAllSurveys () {
  const surveysUrl = API_URL + 'surveys'
  const result = await callApiService.callApi(surveysUrl, 'get')
  result.sort((a, b) => (a.title?.toUpperCase() > b.title?.toUpperCase() ? 1 : -1))
  return orderFormater.expiredAt(result)
}

async function createSurvey (body) {
  const templateId = '5f04c5337c35ff2815e8285a'
  const result = await callApiService.callApi(API_URL + 'templates/' + templateId + '/survey', 'post', body)
  return result
}

async function deleteSurvey (id) {
  const surveysUrl = API_URL + 'surveys/' + id
  const result = await callApiService.callApi(surveysUrl, 'delete')
  return result
}

/**
 * Expires or activates the survey depending of "expire" boolean param
 *
 * @param {String} id contains the survey's id
 * @param {Boolean} expire if it's true, it expires the survey; if it's false, activate .
 */
async function expireOrActivateSurvey (id, expire) {
  const body = {
    expire
  }
  const surveysUrl = API_URL + 'surveys/' + id + '/expire'
  const result = await callApiService.callApi(surveysUrl, 'put', body)
  return result
}

async function cloneSurvey (id, body) {
  const surveysUrl = API_URL + 'surveys/' + id + '/duplicate'
  const result = await callApiService.callApi(surveysUrl, 'post', body)
  return result
}

async function hasConfirmedEmail () {
  const hasConfirmedEmail = await callApiService.callApi(API_URL + 'has-confirmed-email', 'get', '')
  return !(!hasConfirmedEmail.content.confirmed)
}

async function resendEmail () {
  const resendConfirmEmail = await callApiService.callApi(API_URL + 'send-signup-email', 'post', '')
  return !(!resendConfirmEmail.status === 'ok')
}

async function getQuestions (id) {
  const questionsUrl = API_URL + 'surveys/' + id + '/questions'
  const result = await callApiService.callApi(questionsUrl, 'get')
  return result
}

async function saveEmailConfig (id, body) {
  const emailUrl = API_URL + 'messages/' + body._id
  const result = await callApiService.callApi(emailUrl, 'put', body)
  return result
}

async function updateSurvey (body, returnMessage) {
  const surveyUrl = API_URL + 'surveys/' + body._id
  const surveyResult = await callApiService.callApi(surveyUrl, 'put', body, null, null, null, returnMessage)
  return surveyResult
}

async function saveSurvey (body) {
  // update survey inside database
  const surveyResult = await updateSurvey(body)

  surveyResult.message = 'survey.draft_not_saved'
  if (surveyResult.status === 'ok') {
    surveyResult.message = 'survey.draft_saved'
  }
  return surveyResult
}

async function createMessages (id, body) {
  const messages = API_URL + 'surveys/' + id + '/messages'
  const result = await callApiService.callApi(messages, 'post', body)
  return result
}

async function updateMessages (id, body) {
  const messages = API_URL + 'surveys/' + id + '/messages/' + body.id
  const result = await callApiService.callApi(messages, 'put', body)
  return result
}

async function getMessages (id) {
  const messages = API_URL + 'surveys/' + id + '/messages/'
  const result = await callApiService.callApi(messages, 'get')
  return result
}

async function setTheme (id, body) {
  const messages = API_URL + 'surveys/' + id + '/colors/'
  const result = await callApiService.callApi(messages, 'put', body)
  return result
}

async function getTheme (id, body) {
  const messages = API_URL + 'surveys/' + id + '/colors/'
  const result = await callApiService.callApi(messages, 'get', body)
  return result
}
