<template>
 <div class="container-modal-ticket">
  <div class="section-modal-ticket">
    <header class="header-modal-ticket">
      <h3 class="title-header-modal-ticket">{{ $t('tickets.create') }}</h3>
      <i class="material-icons icon-close-modal-ticket" @click="openOrCloseModal(true)" v-title="'Fechar'">close</i>
    </header>
    <section class="content-modal-ticket">
      <div class="question-modal-ticket">
        <p class="title-question-modal-ticket">{{ $t('tickets.questions') }}</p>
        <div class="list-questions-modal-ticket">
          <div class="card-question-ticket"
            v-for="(question, idx) in questions" :key="idx"
            :style="{'display': displayQuestion(question) ? 'flex' : 'none'}"
            :class="{ 'question-selected': questionSelected === idx }"
            @click="selectQuestion(idx)">
            <p class="card-index-ticket">{{ idx + 1 }}</p>
            <p class="title-question-ticket">{{ question.question }}</p>
          </div>
        </div>
      </div>
      <div class="options-modal-ticket">
        <p class="title-options-modal-ticket">{{ $t('tickets.select_answer') }}</p>
        <div class="list-options-modal-ticket" v-if="questions[questionSelected]" :key="key">
          <CardSelectTicket
            v-for="(option, idx) in questions[questionSelected].ticketOptions"
            :key="idx"
            :item="option"
            :color="option?.colors[idx]"
            :attendances="users"
            :categories="categories"
            :messages="autoMessages"
          />
        </div>
        <div class="btn-actions-modal-ticket">
          <button class="btn-action-apply" @click="updateTickets()">
            <p v-if="!btnLoading">{{ $t('tickets.apply') }}</p>
            <Circle2 v-else class="spinner-social"/>
          </button>
          <button class="btn-action-cancel" @click="openOrCloseModal(true)">{{ $t('tickets.cancel') }}</button>
        </div>
      </div>
    </section>
    <div class="content-loading-modal-ticket" v-if="loading">
      <Circle2 class="spinner-social"/>
    </div>
  </div>
 </div>
</template>

<script>
import gsap from 'gsap'
import { accountService, ticketsService, autoMessage, surveyService } from '@/_services'
import { responseOptions, gradientMaker } from '@/_helpers'
import CardSelectTicket from '../tickets/CardSelectTicket.vue'

export default {
  name: 'TicketsModal',
  props: ['survey'],
  components: {
    CardSelectTicket,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      key: 0,
      loading: true,
      btnLoading: false,
      users: [],
      categories: [],
      autoMessages: [],
      questions: [],
      questionSelected: 0
    }
  },
  mounted () {
    this.openOrCloseModal()
  },
  computed: {
    user () { return this.$store.getters['account/getUser'] },
    account () { return this.$store.getters['account/getAccount'] }
  },
  methods: {
    async getDataTicket () {
      this.survey.questions.map(question => { this.questions.push(question) })
      this.questionSelected = this.survey.questionSelected

      const users = await accountService.getUsers()
      this.categories = await ticketsService.getTicketCategories()
      this.autoMessages = await autoMessage.getMessages()

      this.autoMessages.unshift({ title: this.$i18n.t('tickets.no_message'), message: undefined, _id: undefined })

      if (users) {
        this.users = users
        const userIdx = this.users.findIndex(user => user._id === this.user._id)
        this.users.splice(userIdx, 1)
        this.users.unshift(this.user)
        this.users.unshift({ name: this.$i18n.t('tickets.no_attendant'), value: 'no-attendant' })
      }

      this.questions.map(question => {
        if (this.displayQuestion(question)) {
          const options = responseOptions.getOptions(question, true, true)
          const colors = gradientMaker.returnGradientColorsForThisQuestion(question) || ''

          options.map(op => {
            op.selected = false
            op.attendant = this.users[1]
            op.message = this.autoMessages[0]
            op.colors = colors
            op.ui = question.ui
            op.type = question.type
            op.uniqueId = this.generateUniqueID(question._id)
            op.star = question.stars
          })

          question.ticketOptions = options

          if (question.type === 'nps') {
            const newArray = options.slice(3, options.length)
            question.ticketOptions = newArray
          }

          if (this.survey.ticket.triggers.length > 0) {
            const triggers = this.survey.ticket.triggers
            triggers.forEach(trigger => {
              if (trigger.questionId === question._id) {
                const responseIdx = trigger.responseIndex
                const attendantIdx = this.users.findIndex(user => user._id === trigger.attendant)
                const messageIdx = this.autoMessages.findIndex(message => message._id === trigger.message)
                question.ticketOptions[responseIdx].selected = true

                if (trigger.category) { question.ticketOptions[responseIdx].selectedCategory = trigger.category }
                if (attendantIdx >= 0) { question.ticketOptions[responseIdx].attendant = this.users[attendantIdx] }
                if (messageIdx >= 0) { question.ticketOptions[responseIdx].message = this.autoMessages[messageIdx] }
              }
            })
          }
        }
      })

      const contentLoading = document.querySelector('.content-loading-modal-ticket')

      gsap.to(contentLoading, {
        opacity: 0,
        duration: 0.5,
        onComplete: () => { this.loading = false }
      })
    },
    updateTickets () {
      this.btnLoading = true
      this.questions.map(question => {
        if (question.ticketOptions) {
          question.ticketOptions.forEach((op, idx) => {
            const responseContentsFormatted = (question.type === 'text' || question.ui === 'single' || question.ui === 'multiple') ? op.value : (op.value ? parseInt(op.value) : parseInt(op.rating))

            const ticket = {
              attendant: op.attendant ? op.attendant._id : null,
              selected: op.selected,
              responseContents: responseContentsFormatted,
              responseIndex: idx,
              questionId: question._id,
              category: op.selectedCategory,
              message: op.message._id
            }

            const triggerIndex = this.survey.ticket.triggers.findIndex(t => t.responseContents === ticket.responseContents && t.questionId === ticket.questionId)

            if (ticket.selected) {
              if (triggerIndex >= 0) {
                this.survey.ticket.triggers[triggerIndex] = ticket
              } else {
                this.survey.ticket.triggers.push(ticket)
              }
            } else if (triggerIndex >= 0) {
              this.survey.ticket.triggers.splice(triggerIndex, 1)
            }
          })
        }
      })

      this.saveSurvey()
    },
    async saveSurvey () {
      const oldSurvey = structuredClone(this.survey)
      const colorArray = []

      if (!Array.isArray(oldSurvey.colors)) {
        Object.keys(oldSurvey.colors).forEach(item => {
          if (item && oldSurvey.colors[item]) {
            colorArray.push({ alias: item, value: oldSurvey.colors[item] })
          }
        })
        oldSurvey.colors = colorArray
      }
      oldSurvey.message = oldSurvey.message ? oldSurvey.message._id : undefined

      const result = await surveyService.updateSurvey(oldSurvey, true)

      if (result && !result.errors) { this.formatColorsFromReq(result) }
    },
    openOrCloseModal (isClose) {
      const opacity = isClose ? 0 : 1
      const container = document.querySelector('.container-modal-ticket')
      const section = document.querySelector('.section-modal-ticket')
      gsap.to(container, { opacity: opacity, duration: 0.5 })
      gsap.to(section, {
        opacity,
        duration: 0.5,
        onComplete: () => {
          if (isClose) {
            this.$emit('close-modal-ticket')
          } else {
            this.getDataTicket()
          }
        }
      })
    },
    selectQuestion (idx) {
      this.questionSelected = idx
      this.key++
    },
    displayQuestion (question) {
      const nonDisplayTypes = ['referral', 'matrix']
      const nonDisplayUIs = ['singleOther', 'multipleOther']

      const isNonDisplayType = nonDisplayTypes.includes(question.type)
      const isNonDisplayUI = nonDisplayUIs.includes(question.ui)

      return !(isNonDisplayType || isNonDisplayUI)
    },
    generateUniqueID (questionId) {
      const timestamp = new Date().getTime().toString()
      const randomPart = Math.floor(Math.random() * Math.pow(10, 14)).toString().padStart(14, '0')
      const numericID = timestamp + randomPart

      return numericID + questionId
    },
    formatColorsFromReq (survey) {
      const getColors = survey.colors.reduce((acc, cur) => ({ ...acc, [cur.alias]: cur.value }), {})
      survey.colors = getColors
      this.$store.commit('survey/survey', survey)
      this.openOrCloseModal(true)
    }
  }
}
</script>

<style lang="scss">
.question-selected {
  border: 1px solid #454548 !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) !important;
  .title-question-ticket {
    color: #454548 !important;
    text-shadow: 1px 0px 4px #e1e1e1;
  }
  .card-index-ticket {
    color: var(--accent-color) !important;
  }
}
.container-modal-ticket {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background: #00000035;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .section-modal-ticket {
    position: relative;
    opacity: 0;
    width: 70vw;
    height: 80vh;
    background: #F7F8FA;
    border-radius: 0.3rem;
    border: 1px solid #cacaca;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    .header-modal-ticket {
      position: relative;
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;
      .title-header-modal-ticket {
        font-size: 1.2vw;
        color: #454548;
        text-shadow: 1px 0px 4px #e1e1e1;
      }
      .icon-close-modal-ticket {
        position: absolute;
        cursor: pointer;
        top: 0;
        right: 0;
        font-size: 1.2vw;
        border-radius: 50%;
        padding: 1px;
        background: var(--accent-color);
        color: var(--default-text-color);
      }
    }
    .content-modal-ticket {
      width: 100%;
      height: 90%;
      background: #ffffff;
      border: 1px solid #e1e1e1;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.08);
      border-radius: 0.3rem;
      overflow: hidden;
      display: flex;
      .question-modal-ticket {
        width: 30%;
        height: 100%;
        border-right: 1px solid #e1e1e1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title-question-modal-ticket {
          width: 100%;
          height: 5%;
          font-size: 0.85vw;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #808080;
        }
        .list-questions-modal-ticket {
          width: 100%;
          height: 95%;
          padding: 0.3rem;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          .card-question-ticket {
            user-select: none;
            position: relative;
            width: 100%;
            min-height: 4vw;
            border-radius: 0.3rem;
            padding: 0.1rem;
            cursor: pointer;
            border: 1px solid #e1e1e1;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.08);
            display: flex;
            align-items: center;
            .card-index-ticket {
              position: absolute;
              top: 4px;
              right: 4px;
              color: #cacaca;
              font-size: 0.75vw;
              font-weight: bold;
            }
            .title-question-ticket {
              width: 95%;
              padding: 0.2rem;
              font-size: 0.75vw;
              line-height: 0.95vw;
              color: #808080;
              text-align: center;
            }
          }
        }
      }
      .options-modal-ticket {
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .title-options-modal-ticket {
          width: 100%;
          height: 5%;
          font-size: 0.85vw;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #808080;
        }
        .list-options-modal-ticket {
          width: 100%;
          height: 88%;
          padding: 0.2rem;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          gap: 0.7rem;
        }
        .btn-actions-modal-ticket {
          width: 100%;
          height: 7%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          .btn-action-apply {
            width: 7vw;
            height: 1.6vw;
            background: var(--accent-color);
            transition: transform 0.2s ease;
            font-size: 0.75vw;
            padding: 7px 8px;
            font-weight: bold;
            border-radius: 0.3rem;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.08);
            color: var(--default-text-color);
            display: flex;
            align-items: center;
            justify-content: center;
            .spinner-social {
              width: 20px !important;
              height: 20px !important;
              border-width: 2px !important;
              border-color: var(--accent-color) var(--default-text-color) var(--default-text-color) !important;
            }
          }
          .btn-action-cancel {
            width: 7vw;
            height: 1.6vw;
            background: transparent;
            transition: transform 0.2s ease;
            font-size: 0.75vw;
            padding: 7px 8px;
            font-weight: 500;
            border-radius: 0.3rem;
            border: 1px solid var(--accent-color);
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.08);
            color: var(--accent-color);
          }
          .btn-action-apply:hover, .btn-action-cancel:hover {
            transform: scale(1.02);
          }
        }
      }
    }
    .content-loading-modal-ticket {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ffffff;
      border-radius: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .spinner-social {
        width: 2vw !important;
        height: 2vw !important;
        border-width: 3px !important;
        border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
      }
    }
  }
}
</style>
