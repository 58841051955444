import { callApiService } from '@/_services'
import { constants } from '@/_helpers'

const API_URL = process.env.VUE_APP_URL

export const dashboardsService = {
  getAverageDash,
  getTextDash,
  getOvertimeChartData,
  getCurrentSurveyResponses,
  createCustomizedCard,
  updateCustomizedCard,
  deleteCustomizedCard,
  getCustomizedCardsList,
  getIndividualResponses,
  updateSolvedStatus,
  getReferralsList,
  getSendingData,
  getSyntheticData,
  getOvertimeData,
  updateSendingData,
  getSurveyStats
}

async function createCustomizedCard (body) {
  const dashUrl = API_URL + 'dashboard'
  const result = await callApiService.callApi(dashUrl, 'post', body)
  return result
}

async function updateCustomizedCard (cardId, body) {
  const dashUrl = API_URL + 'dashboard/' + cardId
  const result = await callApiService.callApi(dashUrl, 'put', body)
  return result
}

async function deleteCustomizedCard (cardId) {
  const dashUrl = API_URL + 'dashboard/' + cardId
  const result = await callApiService.callApi(dashUrl, 'delete')
  return result
}

async function getCustomizedCardsList (surveyId) {
  const dashUrl = API_URL + 'dashboard/' + surveyId
  const result = await callApiService.callApi(dashUrl, 'get')
  return result
}

async function getSyntheticData (query, unanswered) {
  const averageUrl = `${API_URL}sending-responses-synthetic?cleanEmptyQuestions=${unanswered}&q=${query}`
  const result = await callApiService.callApi(averageUrl, 'get')
  return result
}

async function getOvertimeData (query, grid, unanswered) {
  const points = grid ? encodeURI(grid) : {}
  const overtimeUrl = `${API_URL}sending-responses-overtime?cleanEmptyQuestions=${unanswered}&grid=${points}&q=${query}&tzOffset=${new Date().getTimezoneOffset()}`
  const result = await callApiService.callApi(overtimeUrl, 'get')
  return result
}

async function getAverageDash (query, questionId, surveyId) {
  const averageUrl = `${API_URL}dashboard/synthetic/${surveyId}/${questionId}?q=${query}`
  const result = await callApiService.callApi(averageUrl, 'get')
  return result
}

async function getTextDash (routeQuery, questionId, initialRange, finalRange) {
  const query = (typeof routeQuery.q === 'string') ? routeQuery.q : JSON.stringify(routeQuery.q)
  const textUrl = `${API_URL}questions/${questionId}/responses?q=${query}&cleanEmptyQuestions=${routeQuery.cleanEmptyQuestions}`
  const startRange = initialRange || 0
  const endRange = finalRange || constants.itemsPerPageOnTextList - 1

  const result = await callApiService.callApi(textUrl, 'get', null, endRange, null, startRange, null)

  return { data: result?.data, range: result?.range }
}

async function getOvertimeChartData (query, questionId, surveyId) {
  const overtimeUrl = `${API_URL}dashboard/overtime/${surveyId}/${questionId}?q=${query}`
  const result = await callApiService.callApi(overtimeUrl, 'get', query)
  return result
}

async function getCurrentSurveyResponses (query, surveyId) {
  const responsesUrl = API_URL + 'surveys/' + surveyId + '/responses'
  const result = await callApiService.callApi(responsesUrl, 'get', query)
  return result
}

async function getIndividualResponses (query, range, start) {
  let newRangeEnd = null
  let newRangeStart = null
  const formattedResponsesUrl = `${API_URL}sending-responses/?q=${query}`

  if (range) {
    newRangeEnd = range
  }
  if (start) {
    newRangeStart = start
  }

  const result = await callApiService.callApi(formattedResponsesUrl, 'get', null, newRangeEnd, false, newRangeStart)
  return result
}

async function updateSolvedStatus (body) {
  const solvedUrl = `${API_URL}sendings/${body._id}`
  return await callApiService.callApi(solvedUrl, 'put', body)
}

async function getReferralsList (query, range) {
  let newRangeEnd = null
  const formattedResponsesUrl = `${API_URL}referrals/?q=${query}`

  if (range) {
    newRangeEnd = range
  }

  const result = await callApiService.callApi(formattedResponsesUrl, 'get', null, newRangeEnd, null, null, null, false)
  return result
}

async function getSendingData (sendingId) {
  const sendingUrl = API_URL + 'sendings/' + sendingId
  const result = await callApiService.callApi(sendingUrl, 'get')
  return result
}

async function updateSendingData (id, body) {
  const dashUrl = API_URL + 'sendings/' + id
  const result = await callApiService.callApi(dashUrl, 'put', { seedData: { metadata: body } })
  return result
}

async function getSurveyStats (surveyId, filters) {
  let parsedFilter = filters
  if (typeof filters === 'object') {
    parsedFilter = JSON.stringify(filters)
  }
  const statsUrl = `${API_URL}survey/${surveyId}/stats?q=${parsedFilter}`
  return await callApiService.callApi(statsUrl, 'get')
}
