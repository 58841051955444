<template>
  <div>
    <div class="data-loading-fixed" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
    <Menu :showSubMenu="false"></Menu>
    <SubMenu sentimentals="true"></SubMenu>
    <div v-if="!loading && showGraphs" id="pdf-sentimentals" :key="renderKey">
      <div class="row section-sentimentals">
        <app-card class="overview-card hide-print-sent" style="width: 95%;">
          <div class="overview-card-row">
            <div class="dashboard-content-stats">
              <div>
                <app-heading level="h4">{{currentSurveyStats.totalAnswers.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}}</app-heading>
                <app-heading level="h6">{{$t('analytics.answers')}}</app-heading>
              </div>
              <div>
                <app-heading level="h4">{{currentSurveyStats.responseAverageTime}} min</app-heading>
                <app-heading level="h6">{{$t('analytics.time')}}</app-heading>
              </div>
            </div>
            <div>
              <div class="dashboard-content-options">
                <!-- END change types -->
                <div class="options2-dash">
                  <div style="width:60px; margin:0 10px;">
                    <app-button
                      inline
                      icon="fullscreen"
                      icon-color="gray"
                      icon-size="sm"
                      class="px-0 my-0 mr-1 colored"
                      :class="{tvDashButtomColorActive: !!tvDashIsOpen}"
                      v-on:click="tvDashPage()"
                    >
                      <app-textstyle
                        color="gray"
                        size="size-xxs"
                        :class="{tvDashButtomColorActive: !!tvDashIsOpen}"
                        style="white-space: nowrap;"
                      >TV Dash</app-textstyle>
                    </app-button>
                  </div>
                  <div v-if="!hideExports">
                    <app-button
                      inline
                      icon="download"
                      icon-color="gray"
                      icon-size="sm"
                      class="colored"
                      v-on:click="generatePDF()"
                    >
                      <div class="">
                        <app-textstyle color="gray" size="size-xxs" :class="{tvDashButtomColorActive: !!tvDashIsOpen}">{{ $t('export.title') }}</app-textstyle>
                      </div>
                    </app-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-card>
      </div>
      <div style="width: 100%; padding: 1rem;">
        <div class="row sentimental-cards">
          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 col-xxs-12">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12">
              <app-card
                style="width: 100%; height: 50px; display: flex; white-space: nowrap; background-color: #77dd7e;"
                :style="{ 'background-color': doughnut.commentsGrowth > 0 ? '#77dd7e' : '#ff607d' }"
              >
                <div class="row">
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-9 col-xxs-9" style="display: flex; white-space: nowrap; padding: 0;">
                    <app-icon name="messages" size="sm" style="margin-top: -8px !important; margin-right: 10px; margin-left: -10px; color: #fff;"/>
                    <app-heading level="h6" style="font-size: 24px; margin-top: -7px; color: #ffff;">
                      {{ doughnut.total.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }}
                      <span style="font-size: 20px; font-weight: 100">
                        {{ $t('sentimentals.comments') }}
                      </span>
                    </app-heading>
                  </div>
                  <div v-if="doughnut.commentsGrowth > 0" class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs3" style="display: flex; justify-content: flex-end; white-space: nowrap; padding: 0;">
                    <i class="material-icons" style="color: #048004; margin-top:-7px; margin-right: 10px; margin-left: 80px">arrow_upward</i>
                    <app-heading level="h6" v-title="$t('sentimentals.comments_up')" style="font-size: 14px; margin-top: -1px; color: #048004; font-weight: 600;">{{ Math.round(doughnut.commentsGrowth) }}%</app-heading>
                  </div>
                  <div v-else class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-3" style="display: flex; justify-content: flex-end; white-space: nowrap; padding: 0;">
                    <i class="material-icons" style="color: #8a2500; margin-top:-7px; margin-right: 10px; margin-left: 80px">arrow_downward</i>
                    <app-heading level="h6" v-title="$t('sentimentals.comments_down')" style="font-size: 14px; margin-top: -1px; color: #8a2500; font-weight: 600;">{{ Math.round(doughnut.commentsGrowth) }}%</app-heading>
                  </div>
                </div>
              </app-card>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12">
                <app-card style="width: 100%; height: 100%">
                  <app-heading level="h6" class="chart-heading">{{ $t('sentimentals.gauge_chart_title') }}</app-heading>
                  <GaugeSentimentals :data="doughnut"/>
                </app-card>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12">
                <app-card class="chart-card">
                  <app-heading level="h6" class="chart-heading">{{ $t('sentimentals.doughnut_chart_title') }}</app-heading>
                  <DoughnutSentimentals :data="doughnut"/>
                </app-card>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12">
                <app-card class="chart-card">
                  <app-heading level="h6" class="chart-heading pb20">{{ $t('sentimentals.bar_chart_title') }}</app-heading>
                  <StackedBarWithLine :overtime="overtime" :isAverageLine="false"/>
                </app-card>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12">
                <app-card class="chart-card">
                  <app-heading level="h6" class="chart-heading pb20">{{ $t('sentimentals.line_chart_title') }}</app-heading>
                  <StackedBarWithLine :overtime="overtime" :isAverageLine="true"/>
                </app-card>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 hide-sm hide-xs hide-xxs show-xl show-lg show-md mt15">
            <ResponsesSentimental :data="responses"/>
          </div>
        </div>
      </div>
    </div>
    <NoDataToShow :sentimentals="true" v-if="!loading && !showGraphs" title="approaches.non_existing_data" subtitle="sentimentals.check_date_filters" />
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import SubMenu from '../components/dashboard/menu/Grouper.vue'
import NoDataToShow from '../components/alerts/NoDataToShow.vue'
import DoughnutSentimentals from '../components/sentimentals/DoughnutSentimentals.vue'
import GaugeSentimentals from '../components/sentimentals/GaugeSentimentals.vue'
import StackedBarWithLine from '../components/sentimentals/StackedBarWithLine.vue'
import ResponsesSentimental from '../components/sentimentals/Responses.vue'
import { analyticsService, dashboardsService } from '@/_services'
import { gridMaker, formatters } from '@/_helpers'

export default {
  name: 'Analytics',
  data () {
    return {
      selectedGroupableField: {},
      tvDashIsOpen: false,
      overtime: null,
      doughnut: null,
      responses: null,
      showGraphs: false,
      loading: true,
      renderKey: 0,
      endRange: 19,
      responsesLength: undefined
    }
  },
  components: {
    Menu,
    SubMenu,
    NoDataToShow,
    StackedBarWithLine,
    DoughnutSentimentals,
    GaugeSentimentals,
    ResponsesSentimental,
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-icon': () => import('@binds-tech/binds-design-system/src/components/Icon/Icon'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle')
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      if (from.path !== '/') {
        if (from.name !== to.name && from.name === 'Performance') {
          vm.$store.commit('filters/resetAllFilters')
          vm.$router
            .push({ path: to.path, query: { q: JSON.stringify({ $and: [] }) } })
            .catch(() => {})
        }
      }
    })
  },
  computed: {
    currentSurveyStats () { return this.$store.getters['dashboard/getCurrentSurveyStats'] },
    filter () { return this.$store.getters['filters/getQueryFromDash'] },
    user () { return this.$store.getters['account/getUser'] },
    hideExports () { return this.user.features.hideExports }
  },
  beforeDestroy () {
    this.$root.$off('filter-updated')
  },
  mounted () {
    this.$root.$on('filter-updated', () => {
      this.renderData()
    })

    this.$root.$on('load-more-responses', () => {
      this.loadMoreResponses()
    })
  },
  methods: {
    formatQuery3hrs () {
      return formatters.formatDateByAddTimezone(this.$route.query.q, 3, 'hours')
    },
    async updateStats () {
      const surveyId = this.$store.getters['dashboard/getCurrentSurvey']._id
      const updatedStats = await dashboardsService.getSurveyStats(surveyId, this.formatQuery3hrs())
      this.$store.commit('dashboard/updateCurrentSurveyStats', updatedStats)
    },
    async renderData () {
      this.loading = true
      this.overtime = []
      this.doughnut = []
      this.responses = []

      const currentLocale = this.$moment.locale()
      const surveyId = this.$store.getters['dashboard/getCurrentSurvey']._id
      const startDate = this.$moment(this.filter.query.respondedAt.$gte, 'DD/MM/YYYY').locale(currentLocale).utc().startOf('day').add(3, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      const endDate = this.$moment(this.filter.query.respondedAt.$lte, 'DD/MM/YYYY').locale(currentLocale).utc().endOf('day').add(3, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      const gridRange = gridMaker.getGrid(gridMaker.getDataFromQuery(this.$route.query.q).$gte, gridMaker.getDataFromQuery(this.$route.query.q).$lte)
      const overtimePromise = analyticsService.getSentimentalsOvertime(this.formatQuery3hrs(), gridRange, this.$route.query.cleanEmptyQuestions)
      const doughnutPromise = analyticsService.getSentimentalsDoughnut(this.formatQuery3hrs(), startDate, endDate)
      const responsesPromise = analyticsService.getSentimentalResponses(this.formatQuery3hrs(), this.endRange)
      const statsPromise = dashboardsService.getSurveyStats(surveyId, this.formatQuery3hrs())
      const [overtimeData, doughnutData, responsesData, updatedStats] = await Promise.all([overtimePromise, doughnutPromise, responsesPromise, statsPromise])

      this.$store.commit('dashboard/updateCurrentSurveyStats', updatedStats)
      this.overtime = overtimeData
      this.doughnut = doughnutData
      this.responses = responsesData
      this.responsesLength = this.responses.length

      if (this.doughnut && this.doughnut.total > 0) {
        this.showGraphs = true
      } else {
        this.showGraphs = false
      }

      this.renderKey++
      this.loading = false
    },
    async loadMoreResponses () {
      this.endRange = this.responses.length + 20
      const responses = await analyticsService.getSentimentalResponses(this.formatQuery3hrs(), this.endRange)
      if (responses) {
        this.responses = responses
        this.responsesLength = this.responses.length
        const noMoreItems = this.responsesLength <= this.endRange
        this.$root.$emit('load-responses-done', { responses: responses, noMoreItems: noMoreItems })
      }
    },
    generatePDF () {
      window.print()
    },
    tvDashPage () {
      if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen()
          // mozzila
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        }
        return false
      }

      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }
}
</script>

<style lang='scss'>
.chart-card {
  width: 100% !important;
  height: 100% !important;
  padding: 5px;
}
.chart-heading {
  text-transform: uppercase;
  letter-spacing: 0.1px;
  color: #838383 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.pb20 {
  padding-bottom: 20px !important;
}
.sentimental-cards {
  & .card {
    border-radius: 5px !important;
  }
  & .messages {
    margin-top: -30px !important;
  }
}
.section-sentimentals {
  .dashboard-content-stats {
    padding-top: 10px;
  }

  .dashboard-content-options {
    width: auto;
    padding: 0;
    .options2-dash {
      width: auto;
    }
  }
}
.sentimental-aside {
  .card {
    display: flex !important;
    .list-text-analytics {
      max-height: 690px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
#pdf-sentimentals {
  background-color: #eff3f8;
}
.chart-width > .sentimentals-doughnut > canvas {
  width: 723px !important;
  height: 423px !important;
  margin-top: -30px !important;
}
@media print {
  .hide-print-sent {
    display: none !important;
  }
  @page {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 5px;
    size: a5;
  }
  .chartjs-render-monitor {
    width: 100% !important;
    height: 100% !important;
  }
  .overview-card {
    margin-left: 1.5cm !important;
  }
  #pdf-sentimentals {
    background-color: #fff;
  }
  body  {
    width: 100%;
    padding: 0px;
    background-color: #ffff;
    color-adjust: exact;
    -webkit-print-color-adjust: exact !important;
    // print-color-adjust: exact;
  }
  body, #main-container {
    height: auto;
  }
  .dashboard-content-stats {
    margin-left: 1cm !important;
    display: flex !important;
  }
}
</style>
