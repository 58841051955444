<template>
  <div class="container-categories" v-if="categoryList">
    <div class="title-categories">
      <app-heading class="title-categorie" level="h4">{{ $t("tickets.category.head_title") }}</app-heading>
      <app-heading class="categorie-selected" level="h4" v-if="currentTicket">{{ currentTicket.title }}</app-heading>
      <app-heading class="categorie-selected" level="h4" v-if="!currentTicket">{{ $t('tickets.category.no_category') }}</app-heading>
    </div>
    <div class="add-new-categorie" v-if="account.type !== 'user'">
      <div class="titles-new-categorie">
        <app-heading v-if="showContentNewCategory" class="title-categorie" level="h6">Criar nova categoria:</app-heading>
        <app-heading v-if="showContentNewCategory" class="new-categorie-select-survey" level="h6">Enviar pesquisa ao fechar atendimento:</app-heading>
      </div>
      <div class="items-new-categorie">
        <button class="create-categorie" v-title="$t('tickets.category.add')" @click="showContentNewCategory = !showContentNewCategory">
          <i class="material-icons add-categorie-icon">add</i>
        </button>
        <div class="new-categorie" v-show="showContentNewCategory">
          <input type="text" class="new-categorie-title" v-model="newCategory" :placeholder="$t('tickets.category.new_category')">
          <v-select
            class="new-categorie-survey"
            :placeholder="$t('tickets.select_survey')"
            label="label"
            :options="surveyOptions"
            :reduce="label => label._id"
            v-model="surveyToSend"
          ></v-select>
          <v-select
            class="new-categorie-time"
            placeholder="Quando"
            label="label"
            :options="timeOptions"
            :reduce="label => label.value"
            v-model="timeToSend"
          ></v-select>
          <button class="create-categorie-btn" v-title="$t('start.create')" @click="addNewCategory()">
            <i class="material-icons">check</i>
          </button>
      </div>
      </div>
    </div>
    <div class="all-categories">
      <div class="option-no-categorie">
        <app-heading level="h5" class="label-categorie">{{$t("tickets.category.no_category")}}</app-heading>
        <i class="material-icons selected-categorie-icon" @click="selectNoCategory()">arrow_circle_right</i>
      </div>
      <div class="option-categorie" v-for="(category, index) in categoryList" :key="index">
        <div class="section-option">
          <div class="title-categorie-item">
            <app-heading level="h5" class="label-categorie" v-if="!category.editCategory">{{ category.title }}</app-heading>
            <input class="new-title-categorie" type="text" v-if="category.editCategory" v-model="category.title">
            <i class="material-icons icon-edit-sub" v-if="category.editCategory" v-title="$t('tickets.cancel')" @click="category.editCategory = !category.editCategory">cancel</i>
            <i class="material-icons icon-edit-sub" v-if="category.editCategory" v-title="$t('survey.confirm')" @click="editedCategory(category.title, category._id, index)">check</i>
          </div>
          <div class="options-to-categorie">
            <i class="material-icons config-categorie-icon" v-title="$t('tickets.category.add_sub')" v-if="!category.activeSubCategory" @click="category.activeSubCategory = !category.activeSubCategory">expand_more</i>
            <i class="material-icons config-categorie-icon" v-title="$t('tickets.category.add_sub')" v-if="category.activeSubCategory" @click="category.activeSubCategory = !category.activeSubCategory">expand_less</i>
            <i class="material-icons config-categorie-icon" v-title="$t('dashboard_card.delete')" v-if="account.type !== 'user' && !category.activeSubCategory" @click="deleteCategory(category._id)">delete</i>
            <i class="material-icons config-categorie-icon" v-title="$t('tickets.category.to_edit')" v-if="account.type !== 'user' && !category.activeSubCategory" @click="category.editCategory = !category.editCategory">drive_file_rename_outline</i>
            <i class="material-icons config-categorie-icon" v-title="$t('tickets.category.select')" v-if="!category.activeSubCategory" @click="selectCategory(category.title, category._id)">arrow_circle_right</i>
          </div>
        </div>
        <div class="config-sub-categorie" v-if="category.activeSubCategory">
          <div class="sub-categorie" v-for="(sub, idx) in category.subcategories" :key="idx">
            <div class="label-sub-categorie">
              <i class="material-icons option-sub-categorie-icon">subdirectory_arrow_right</i>
              <app-heading level="h7" class="title-sub-categorie" v-if="!sub.editSubCategory">{{ sub.title }}</app-heading>
              <input class="new-title-sub-categorie" type="text" v-if="sub.editSubCategory" v-model="sub.title">
              <i class="material-icons icon-edit-sub" v-if="sub.editSubCategory" v-title="$t('tickets.cancel')" @click="sub.editSubCategory = !sub.editSubCategory">cancel</i>
              <i class="material-icons icon-edit-sub" v-if="sub.editSubCategory" v-title="$t('survey.confirm')" @click="editedSubCategory(sub.title, category._id, sub._id, category, idx)">check</i>
            </div>
            <div class="config-sub-categorie-options">
              <i class="material-icons option-sub-categorie-icon" v-title="$t('dashboard_card.delete')" v-if="account.type !== 'user'" @click="deleteSubCategory(sub._id, index)">delete</i>
              <i class="material-icons option-sub-categorie-icon" v-title="$t('tickets.category.to_edit')" v-if="account.type !== 'user'" @click="sub.editSubCategory = !sub.editSubCategory">drive_file_rename_outline</i>
              <i class="material-icons option-sub-categorie-icon" v-title="$t('tickets.category.select')" @click="selectCategory(sub.title, sub._id)">arrow_circle_right</i>
            </div>
          </div>
          <div class="sub-categorie new-sub" v-if="account.type !== 'user'">
            <i class="material-icons option-sub-categorie-icon">subdirectory_arrow_right</i>
            <input class="new-sub-categorie" type="text" placeholder="Nova sub-categoria" v-model="newSubCategory">
            <i class="material-icons icon-edit-sub" v-title="$t('start.create')" @click="addNewSubCategory(category._id, index)">check</i>
          </div>
        </div>
      </div>
    </div>
    <div class="data-loading" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
  </div>
</template>
<script>
import { ticketsService, surveyService } from '@/_services'
export default {
  name: 'Categories',
  props: ['ticketId', 'currentTicket'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    vSelect: () => import('vue-select'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  data () {
    return {
      categoryList: [],
      surveyOptions: [],
      timeOptions: [
        { label: this.$i18n.t('tickets.category.send_now'), value: 'now' },
        { label: this.$i18n.t('tickets.category.send_24h'), value: '24h' },
        { label: this.$i18n.t('tickets.category.send_7d'), value: '7d' },
        { label: this.$i18n.t('tickets.category.send_30d'), value: '30d' }
      ],
      newCategory: '',
      newSubCategory: '',
      surveyToSend: '',
      timeToSend: '',
      showContentNewCategory: false,
      loading: false
    }
  },
  computed: {
    account () {
      const user = this.$store.getters['account/getUser']
      return user
    }
  },
  mounted () {
    // init category list
    this.getCategories()
  },
  methods: {
    messagesCategories (message) {
      this.$store.commit('alerts/alert', {
        message: this.$i18n.t(message),
        position: 'bottomRight',
        showAlert: true
      })
    },
    async getCategories () {
      this.loading = true
      const fetchAll = [surveyService.getAllSurveys(), ticketsService.getCategories()]
      const [allSurveys, categories] = await Promise.all(fetchAll)
      this.surveyOptions = allSurveys.map(e => { return { label: e.title, _id: e._id } })
      // add models activeSubCategory and editCategory to show/hide the inputs texts
      categories.map((o) => {
        o.activeSubCategory = false
        o.editCategory = false
        if (o.subcategories) {
          o.subcategories.map(i => {
            i.editSubCategory = false
          })
        }
      })
      this.categoryList = categories
      this.loading = false
    },
    async addNewCategory () {
      if (this.newCategory) {
        const objectWithSurvey = {
          title: this.newCategory,
          sendSurvey: {
            survey: this.surveyToSend,
            time: this.timeToSend === 'now' ? '' : this.timeToSend
          }
        }
        const objectToSend = this.surveyToSend && this.timeToSend ? objectWithSurvey : { title: this.newCategory }
        const setCategory = await ticketsService.newCategory(objectToSend)
        if (setCategory) {
          setCategory.activeSubCategory = false
          setCategory.editCategory = false
          setCategory.subcategories = []
          this.categoryList.unshift(setCategory)
          this.newCategory = ''
          this.showContentNewCategory = false
          this.surveyToSend = ''
          this.timeToSend = ''
          this.messagesCategories('tickets.category.created')
        } else {
          this.messagesCategories('tickets.category.created_error')
        }
      } else {
        this.messagesCategories('tickets.category.new_category')
      }
    },
    async deleteCategory (id) {
      await ticketsService.deleteCategory(id)
      this.messagesCategories('tickets.category.deleted')
      const categoryFiltered = this.categoryList.filter((o) => {
        return o._id !== id
      })
      this.categoryList = categoryFiltered
    },
    async editedCategory (title, id, idx) {
      const edited = await ticketsService.editCategory({ title: title }, id)
      const message = edited ? 'tickets.category.edited_success' : 'tickets.category.created_error'
      this.messagesCategories(message)
      this.categoryList[idx].editCategory = false
    },
    async editedSubCategory (title, catId, subId, category, idx) {
      const edited = await ticketsService.editSubCategory({ title: title, parentCategory: catId }, subId)
      const message = edited ? 'tickets.category.edited_success' : 'tickets.category.created_error'
      this.messagesCategories(message)
      const subCategory = category.subcategories.find(e => e._id === subId)
      subCategory.editSubCategory = false
      console.log('this.categoryList[idx].editCategory', subCategory)
    },
    async deleteSubCategory (id, index) {
      await ticketsService.deleteCategory(id)
      this.messagesCategories('tickets.category.deleted')
      const categoryFiltered = this.categoryList[index].subcategories.filter((o) => {
        return o._id !== id
      })
      this.categoryList[index].subcategories = categoryFiltered
    },
    async addNewSubCategory (id, index) {
      if (this.newSubCategory) {
        const setCategory = await ticketsService.newCategory({ title: this.newSubCategory, parentCategory: id })
        if (setCategory) {
          setCategory.editSubCategory = false
          this.categoryList[index].subcategories.unshift(setCategory)
          this.newSubCategory = ''
          this.messagesCategories('tickets.category.created')
        } else {
          this.messagesCategories('tickets.category.created_error')
        }
      } else {
        this.messagesCategories('tickets.category.new_category')
      }
    },
    selectCategory (title, id) {
      this.$emit('updateCategory', { title: title, id: id })
    },
    selectNoCategory () {
      this.$emit('updateCategory', { title: null, id: null })
    }
  }
}
</script>
<style lang="scss">
.container-categories {
  width: 100%;
  height: 520px;
  border-radius: 5px;
  overflow: hidden;
  .title-categories {
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: var(--primary-color);
    .title-categorie {
      font-size: 30px;
      color: var(--default-text-color);
    }
    .categorie-selected {
      color: var(--accent-color);
    }
  }
  .add-new-categorie {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-bottom: 1px solid #dbdbdb;
    .titles-new-categorie {
      display: flex;
      align-items: center;
      padding: 0 15px;
      margin-top: 3px;
      .new-categorie-select-survey {
        margin-left: 80px;
      }
    }
    .items-new-categorie {
      display: flex;
      align-items: center;
      .create-categorie {
        width: 35px;
        height: 35px;
        margin: 0 10px;
        border-radius: 50%;
        background: var(--accent-color);
        display: flex;
        align-items: center;
        justify-content: center;
        .add-categorie-icon {
          color: var(--default-text-color);
          font-size: 25px;
        }
      }
      .new-categorie {
        width: 95%;
        height: 40px;
        display: flex;
        align-items: center;
        .new-categorie-title {
          height: 100%;
          width: 20%;
          font-size: 16px;
          margin-right: 10px;
          padding-left: 5px;
          border-radius: 5px;
          color: #828282;
          border: none;
          border: 1px solid #d0d0d0;
        }
        .new-categorie-title::placeholder {
          color: #828282;
        }
        .new-categorie-survey {
          width: 60% !important;
          height: 100%;
          margin-right: 10px;
          .vs__dropdown-toggle {
            height: 100%;
            background: #ffffff;
            .vs__selected-options {
              .vs__search {
                cursor: pointer;
              }
              .vs__search::placeholder {
                color: #828282;
              }
            }
            .vs__actions {
              color: var(--primary-color) !important;
              .vs__clear {
                display: none;
              }
            }
          }
          .vs__dropdown-menu {
            width: 540px !important;
            .vs__dropdown-option {
              width: 540px !important;
              overflow-x: hidden;
            }
          }
        }
        .new-categorie-time {
          width: 15% !important;
          height: 100%;
          margin-right: 10px;
          .vs__dropdown-toggle {
            width: 100% !important;
            height: 100%;
            background: #ffffff;
            .vs__selected-options {
              .vs__search {
                cursor: pointer;
              }
              .vs__search::placeholder {
                color: #828282;
              }
            }
            .vs__actions {
              color: var(--primary-color) !important;
              .vs__clear {
                display: none;
              }
            }
          }
        }
        .create-categorie-btn {
          width: 35px;
          height: 35px;
          margin-right: 10px;
          border-radius: 50%;
          background: var(--accent-color);
          color: var(--default-text-color);
          font-size: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .all-categories {
    width: 100%;
    height: 355px;
    padding: 10px;
    border-radius: 0 0 5px 5px;
    overflow-y: auto;
    .option-no-categorie, .option-categorie {
      width: 100%;
      min-height: 60px;
      padding: 0 20px;
      margin-bottom: 5px;
      border-radius: 5px;
      border: 1px solid #dbdbdb;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgba( 255, 255, 255, 0.25 );
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.194);
      backdrop-filter: blur( 4px );
      -webkit-backdrop-filter: blur( 4px );
      border-radius: 5px;
      .label-categorie {
        color: #424242;
      }
      .selected-categorie-icon {
        color: var(--accent-color);
        font-size: 35px;
        cursor: pointer;
      }
      .selected-categorie-icon:hover {
        transform: scale(1.02);
      }
    }
    .option-categorie {
      flex-direction: column;
      justify-content: center;
      .section-option {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title-categorie-item {
          display: flex;
          align-items: center;
          .new-title-categorie {
            height: 35px;
            color: var(--accent-color);
            font-size: 15px;
            border: none;
            border: 1px dashed #dbdbdb;
            background: transparent;
            margin-right: 10px;
            padding-left: 5px;
          }
          .icon-edit-sub {
            margin: 0 8px;
            cursor: pointer;
            color: gray;
            font-size: 22px;
          }
          .icon-edit-sub:last-child {
            background: var(--accent-color);
            color: #ffffff;
            font-size: 20px;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }
        }
        .options-to-categorie {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 25%;
          .config-categorie-icon {
            font-size: 25px;
            color: gray;
            cursor: pointer;
          }
          .config-categorie-icon:last-child {
            color: var(--accent-color);
            font-size: 35px;
          }
        }
      }
      .config-sub-categorie {
        width: 100%;
        .sub-categorie {
          width: 100%;
          height: 40px;
          margin: 5px 0;
          border-radius: 5px;
          padding: 0 5px;
          border: 1px solid #dbdbdb;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .label-sub-categorie {
            height: 40px;
            display: flex;
            align-items: center;
            .option-sub-categorie-icon {
              color: gray;
              margin-right: 10px;
              font-size: 20px;
            }
            .title-sub-categorie {
              color: #424242;
            }
            .new-title-sub-categorie {
              height: 70%;
              color: var(--accent-color);
              font-size: 15px;
              border: none;
              border: 1px dashed #dbdbdb;
              background: transparent;
            }
            .icon-edit-sub {
              cursor: pointer;
              margin: 0 8px;
              color: gray;
              font-size: 22px;
            }
            .icon-edit-sub:last-child {
              background: var(--accent-color);
              color: #ffffff;
              font-size: 20px;
              width: 25px;
              height: 25px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
            }
          }
          .config-sub-categorie-options {
            width: 15%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .option-sub-categorie-icon {
              color: gray;
              margin-right: 10px;
              font-size: 22px;
              cursor: pointer;
            }
            .option-sub-categorie-icon:last-child {
              color: var(--accent-color);
            }
          }
        }
        .new-sub {
          justify-content: flex-start;
          .option-sub-categorie-icon {
            color: gray;
            margin-right: 10px;
            font-size: 20px;
          }
          .new-sub-categorie {
            height: 70%;
            color: var(--accent-color);
            font-size: 15px;
            border: none;
            border: 1px dashed #dbdbdb;
            background: transparent;
            margin-right: 10px;
            padding-left: 5px;
          }
          .new-sub-categorie::placeholder {
            font-size: 14px;
          }
          .icon-edit-sub {
            background: var(--accent-color);
            color: #ffffff;
            font-size: 20px;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
</style>
