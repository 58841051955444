<template>
  <div v-show="!tvDashPage">
    <div class="row bread hide-when-print  editable-text dashboardSubmenu">
      <div class="isToShowMobile" style="width: 100%">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12 content-breadcrumb">
          <search-menu
            v-if="isMobile"
            :mobile="isMobile"
            :individual="individual"
            :ranking="ranking"
            :referrals="referrals"
          ></search-menu>
        </div>
      </div>
      <!-- Menu de textos Desktop -->
      <div class="" style="display: contents;">
        <div class="filter-bar content-breadcrumb">
          <search-menu :mobile="isMobile" :individual="individual" :ranking="ranking" :referrals="referrals" :cloudwords="cloudwords" v-if="!isMobile"></search-menu>
        </div>
      </div>
    </div>
    <theme-style>
      .activeMobileMenu {
        color:{{ user.account.theme.colors.accent || '#a786fd' }} !important;
      }
    </theme-style>
  </div>
</template>

<script>
import SearchMenu from './SearchMenu.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Dashcrumb',
  props: ['ranking', 'referrals', 'individual', 'cloudwords'],
  data () {
    return {
      isMobile: false,
      items: [
        { link: 'dashboard/overview', class: 'col-xs-3', label: this.$i18n.t('dashboard.overview'), icon: 'dashboard', active: false, disabled: true, name: 'Overview', id: 0 },
        { link: 'dashboard/individual', class: 'col-xs-3', label: this.$i18n.t('dashboard.individual_answers'), icon: 'vertical_split', active: false, disabled: true, name: 'Individual', id: 1 },
        { link: 'dashboard/referrals', class: 'col-xs-3', label: this.$i18n.t('dashboard.referrals'), icon: 'share', active: false, disabled: true, name: 'Referrals', id: 2 },
        { link: 'dashboard/ranking', class: 'col-xs-3', label: this.$i18n.t('dashboard.ranking'), icon: 'show_chart', active: false, disabled: true, name: 'Ranking', id: 3 }
      ],
      tvDashPage: false
    }
  },
  components: { SearchMenu },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    })
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 648
    },
    changeSelected (item, index) {
      this.$route.query.page = 1
      if (!this.$route.query.q) {
        this.$route.query.q = JSON.stringify({ $and: this.$store.getters['filters/getDashFilter'].query.$and })
      }
      this.$router.push({ path: '/' + item.link, query: { q: this.$route.query.q } }).catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error when updateing url with filters', err) })
    }
  },
  mounted: function () {
    // check if is mobile
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })

    this.items.map(itemOnLoop => {
      if (this.$route.name === itemOnLoop.name) {
        itemOnLoop.active = true
      }
    })
    if (this.$route.query.from) {
      this.$store.commit('filters/updateCreatedAtFrom', this.$route.query.from)
    }
    if (this.$route.query.to) {
      this.$store.commit('filters/updateCreatedAtTo', this.$route.query.to)
    }
    this.$root.$on('tv-dash-page', () => {
      this.tvDashPage = !this.tvDashPage
    })
  }
}
</script>

<style lang="scss">
.filter-bar {
  width: 100%;
}
.gray {
  color: $color-gray;
}
.inactiveMobileMenu {
  color: #A2A2A2;
}
.secondMenu {
  text-align: center;
  background:#fff;
  border-radius: 28px 28px 0px 0px;
  border-top: 1px solid #eff3f8;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 11;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}
.dashboardSubmenu > .notToShowMobile > .content-breadcrumb > .navigation > .submenu__item_individual > .submenu__items_item {
  // display: block;
  text-align: center;
}
.dashboardSubmenu > .notToShowMobile > .content-breadcrumb > .navigation {
  display: flex;
}
.dashboardSubmenu > .notToShowMobile > .content-breadcrumb {
  margin-left: 3%;
}
@media (max-width: 1045px)
{
  .dashboardSubmenu {
    padding: 0px !important;
  }
  .dashboardSubmenu > .notToShowMobile > .content-breadcrumb {
    margin-left: 0;
  }
  .dashboardSubmenu > .notToShowMobile > .content-breadcrumb > .navigation > .submenu__item_individual {
    display: flex;
  }
  .dashboardSubmenu >  .notToShowMobile > .content-breadcrumb > .navigation {
    display: flex;
    text-align: center;
  }
}
.submenu__items_item {
  font-family: inherit;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #eff3f8;
  padding-bottom: 11px;
  text-decoration: none;
  border-bottom: 2px solid #e4a71300;
}
.mt15neg {
  margin-top: -15px !important;
}
</style>
