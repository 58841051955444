<template>
  <div>
    <Menu></Menu>
    <SubMenu :ranking="true"></SubMenu>
    <div v-if="groupableFields.length > 0 && canShowThePage" class="row content-ranking-stats">
      <app-card class="col-lg-11 col-md-11 col-xs-11 col-sm-11 overview-card" style="padding: 0px !important;overflow:inherit; max-width: 90%;">
        <div class="overview-card-row">
          <div class="dashboard-content-stats">
            <tweenedNumber :label="$t('analytics.answers')" :number="statsUpdated?.totalAnswers"></tweenedNumber>
            <tweenedNumber :label="$t('analytics.time')" :number="statsUpdated?.responseAverageTime" formula="min"></tweenedNumber>
          </div>
          <div class="dashboard-content-options">
            <!-- END change types -->
            <div class="options1-dash">
            <div class="ranking-meta-selector">
            <div class="ranking-refs">
              <div style="margin-bottom:7px;">
                <app-heading level="h7">{{ $t('ranking.metadata') }}:</app-heading>
              </div>
              <div>
              <!-- para manter o select aberto e debuggar, acrescente a prop :dropdown-should-open="() => true" -->
              <v-select
                label="label"
                v-title="$t('ranking.custom_field')"
                :options="groupableFields"
                :clearable="false"
                v-model="selectedGroupableField"
                @input="updateMetadataRanking()"
              ></v-select>
              </div>
            </div>
            <div class="ranking-refs">
              <div style="margin-bottom:7px;">
                <app-heading level="h7">{{$t('ranking.min_sample')}}:</app-heading>
              </div>
              <div>
                <app-form-input
                  v-title="$t('ranking.min_sample')"
                  v-model="minSample"
                  border-bottom
                  type="number"
                  :min="account?.rankingSettings?.minSample || 1"
                  @change="updateCountAnswer()"
                ></app-form-input>
              </div>
            </div>
          </div>
            </div>
            <div class="options2-dash">
              <div class="overview-btn" @click="toggleRanking('overview')">
                <i
                  class="material-symbols-outlined b-icon answers sm gray"
                  style="font-size: 30px;margin-bottom: 6px;transition: all 200ms ease-in-out;"
                  :style="{color: currentSection === 'overview' ? 'var(--accent-color)' : 'gray'}"
                >
                  quick_reference_all
                </i>
                <app-textstyle
                  color="gray"
                  size="size-xxs"
                  style="white-space: nowrap;"
                > {{ $t('dashboard.overview') }}
                </app-textstyle>
              </div>
              <app-button
                inline
                icon="answers"
                icon-color="gray"
                icon-size="sm"
                @click="toggleRanking('graphic')"
                :class="currentSection === 'graphic' ? 'highlight-icon' : ''"
              >
                <app-textstyle
                  color="gray"
                  size="size-xxs"
                  style="white-space: nowrap;"
                > {{ $t('real_time.graphics') }}
                </app-textstyle>
              </app-button>
              <app-button
                inline
                icon="fullscreen"
                icon-color="gray"
                icon-size="sm"
                class="px-0 my-0 mr-1 colored"
                :class="{tvDashButtomColorActive: !!tvDashIsOpen}"
                v-on:click="tvDashPage()"
              >
                <app-textstyle
                  color="gray"
                  size="size-xxs"
                  :class="{tvDashButtomColorActive: !!tvDashIsOpen}"
                >TV Dash</app-textstyle>
              </app-button>
            </div>
          </div>
        </div>
      </app-card>
    </div>
    <div v-if="groupableFields.length <= 0  && canShowThePage">
      <app-heading style="margin: 0 auto;text-align:center;" level="h3">{{$t('ranking.first_metadata1')}}</app-heading>
      <app-heading style="margin: 0 auto;width: 50%;text-align:center;" level="h3">{{$t('ranking.first_metadata2')}}</app-heading>
      <div style="margin: 30px auto;text-align:center;" >
        <app-button  v-on:click="$root.$emit('open-metadata-config')">{{$t('ranking.redirect_to_metadata')}}</app-button>
      </div>
    </div>
    <Ranking v-if="groupableFields.length > 0 && canShowThePage && currentSurvey" style="margin:auto;margin-top:30px;margin-bottom:100px;" class="col-lg-11 col-md-11 col-xs-11 col-sm-11"></Ranking>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import SubMenu from '../components/dashboard/menu/Grouper.vue'
import Ranking from '../components/dashboard/ranking/Grouper.vue'
import { dashboardsService, accountService } from '@/_services'
import tweenedNumber from '../components/animations/TweenedNumber.vue'
import Store from '@/store/'

export default {
  name: 'RankingPage',
  data () {
    return {
      selectedGroupableField: '',
      canShowThePage: false,
      tvDashIsOpen: false,
      groupableFields: [],
      minSample: 1,
      statsUpdated: {
        totalAnswers: 0,
        responseAverageTime: 0
      },
      redirected: false,
      currentSection: 'overview'
    }
  },
  components: {
    Menu,
    SubMenu,
    Ranking,
    tweenedNumber,
    vSelect: () => import('vue-select'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button')
  },
  computed: {
    lang () {
      return this.$store.getters['account/getLang']
    },
    account () {
      return this.$store.getters['account/getAccount']
    },
    currentSurvey () {
      const stats = this.$store.getters['dashboard/getCurrentSurvey']
      return stats
    }
  },
  beforeRouteEnter (to, from, next) {
    const user = Store.getters['account/getUser']
    if (!user.features.ranking) {
      return next({ name: 'YouCant' })
    }
    next(vm => {
      vm.prevRoute = from
      vm.$store.commit('filters/resetAllFilters')
      vm.$router.push({ path: to.path, query: { q: JSON.stringify({ $and: [] }) } }).catch(() => {})
    })
  },
  beforeDestroy () {
    this.$root.$off('filter-updated')
    this.$root.$off('ranking-redirected')
    this.$root.$off('ranking-export-content')

    if (!(this.$route.path.includes('individual') && this.redirected)) {
      this.$store.commit('filters/resetAllFilters')
      this.$router.push({ path: this.$route.path, query: { q: JSON.stringify({ $and: [] }) } }).catch(() => {})
    }
  },
  mounted () {
    this.setMinSample()
    this.updateFields()
    this.$root.$on('ranking-redirected', () => {
      this.redirected = true
    })
    this.$root.$on('update-fields', () => {
      this.updateFields()
    })
    this.$root.$on('search-menu-is-done', () => {
      this.canShowThePage = true
    })
    this.$root.$on('filter-updated', (e) => {
      this.updateStats(e)
    })
  },
  methods: {
    setMinSample () {
      const ms = this.account?.rankingSettings?.minSample
      this.minSample = ms || 1
    },
    async updateFields () {
      const account = await accountService.getAccount(this.account._id)
      this.groupableFields = []
      account.fields.map(item => {
        if (item.groupable) {
          this.groupableFields.push(item)
        }
      })
      this.selectedGroupableField = this.groupableFields[0]
    },
    async updateStats (e) {
      const addPlusThree = (date) => this.$moment(date, 'YYYY-MM-DD HH:mm ZZ').add(3, 'hours').utc().format()

      const surveyId = this.$store.getters['dashboard/getCurrentSurvey']._id

      const parsedQuery = JSON.parse(e || this.$route.query.q)
      const respondedAt = { ...this.$store.getters['filters/getDashFilter'].query.respondedAt }
      const diff = this.$moment(respondedAt.$gte).diff(this.$moment(respondedAt.$lte), 'hours')

      if (diff !== 3) { // verify if the date filter isn't the 24h and then adds 3h
        respondedAt.$gte = addPlusThree(respondedAt.$gte)
        respondedAt.$lte = addPlusThree(respondedAt.$lte)
      }

      const keyMetadata = `seed.metadata.${this.selectedGroupableField.key}`
      const metadata = { [keyMetadata]: { $exists: true } }
      if (!parsedQuery.$and[keyMetadata]) parsedQuery.$and.push(metadata)

      const result = await dashboardsService.getSurveyStats(surveyId, JSON.stringify(parsedQuery))
      this.statsUpdated = result
    },
    tvDashPage () {
      if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen()
          // mozzila
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        }
        return false
      }

      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    },
    toggleRanking (section) {
      this.currentSection = section
      this.$emit('render-section', section)
    },
    updateMetadataRanking () {
      this.$root.$emit('ranking-data', { item: this.selectedGroupableField, where: 'meta' })
      this.$root.$emit('filter-updated')
    },
    updateCountAnswer () {
      const ms = this.account?.rankingSettings?.minSample
      if (this.minSample < ms) return
      this.$root.$emit('ranking-data', { item: this.minSample, where: 'count' })
    }
  }
}
</script>
<style lang="scss">
.highlight-icon {
  div > i {
    color: var(--accent-color) !important;
    transition: all 200ms ease-in-out;
  }
}

.content-ranking-stats {
  .dashboard-content-options {
    width: 80%;
    .options1-dash {
      align-items: baseline;
      width: 60%;
    }
    .options2-dash {
      width: 40%;
      display: flex;
      padding: 0;
      .overview-btn {
        display: flex;
        place-items: center;
        flex-direction: column;
        padding: 8px 16px 9px;
        cursor: pointer;
        transition: all 0.2s ease;
        &:hover {
          transform: translateZ(0) scale(1.03);
        }
      }
      button.binds-button {
        margin: 0;
      }
      .text-field__input {
        width: 100px !important;
      }
    }
  }
}
.ranking-meta-selector {
  display: flex;
  width: 600px;
  .ranking-refs {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 5%;
    input[type=number] {
      padding: 0 !important
    }
  }
  .vs__selected-options {
    min-width: 200px;
  }
  .vs__dropdown-toggle {
    height: 45px;
    overflow: hidden;
    font-size: 14px;
  }
  .vs__dropdown-option {
    white-space: normal !important;
    width: 100% !important;
  }
}
</style>
