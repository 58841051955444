<template>
  <div class="container-card" :key="key">
    <div class="section-card">
      <app-heading class="title-card" level="h5"  v-title="$t(subtitle)" title-max-width="350">
        {{ $t(title) }}
        <i class="material-icons">info</i>
      </app-heading>
      <div class="content-card">
        <div class="search-card">
          <div class="select-items">
            <app-form-input v-model="search" @input="updatePagination()" :placeholder="$t('role.search')"/>
            <v-select
              class="col-lg-6 col-md-6 col-sm-6 col-xs-6"
              label="label"
              :options="optionSelect"
              :placeholder="optionSelect[0].label"
              v-model="optionSelect.active"
              @input="optionSelected(optionSelect.active)"
              :clearable="false"
            ></v-select>
          </div>
        </div>
        <div class="list-items">
          <div class="items" v-for="item in currentItems" :key="item._id">
            <app-form-checkbox-mini
              :label="item.name ? item.name : item.title ? item.title : 'ㅤ'"
              :initialValue="valueGroup(item._id)"
              @valueChanged="itemSelect($event, item, objectsId)"
            />
          </div>
        </div>
      </div>
      <div class="items-paginate">
        <app-paginate
          :class="{'prevActive': page > 1, 'nextActive':  page < dynamicPageCount }"
          :hide-prev-next=true
          :initValue="1"
          :prevText="'<'"
          :nextText="'>'"
          @input="changePage"
          :pageRange="3"
          :pageCount="dynamicPageCount"
        ></app-paginate>
      </div>
    </div>
  </div>
</template>

<script>
import { accountService } from '@/_services'

export default {
  name: 'CardWithPagination',
  props: ['contents'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-form-checkbox-mini': () => import('@binds-tech/binds-design-system/src/components/Form/CheckboxMini'),
    'app-paginate': () => import('@binds-tech/binds-design-system/src/components/Paginate/Paginate'),
    vSelect: () => import('vue-select')
  },
  data () {
    return {
      key: 0,
      items: [],
      objectsId: [],
      group: [],
      user: '',
      type: '',
      search: '',
      title: '',
      subtitle: '',
      page: 1,
      dynamicPageCount: 0,
      itemsPerPage: 9,
      optionSelect: [
        { type: 'all', label: this.$i18n.t('pageUsers.all_groups'), active: false },
        { type: 'marked', label: this.$i18n.t('pageUsers.marked_groups'), active: false },
        { type: 'unchecked', label: this.$i18n.t('pageUsers.unchecked_groups'), active: false }
      ]
    }
  },
  mounted () {
    this.type = this.contents.type
    this.title = this.contents.title
    this.subtitle = this.contents.subtitle
    this.items = this.contents.itemList
    this.group = this.contents.group
    this.objectsId = this.contents.objectsId
    this.user = this.contents.user || this.account
    this.updatePagination()
  },
  computed: {
    filteredItems () {
      let filtered = []
      if (this.type === 'surveys') {
        filtered = this.items.filter(item => {
          return item?.title?.toLowerCase().includes(this.search.toLowerCase())
        })
      } else {
        filtered = this.items.filter(item => {
          return item.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }
      return filtered
    },
    currentItems () {
      let filterIndex = this.page
      if (this.page > this.filteredItems.length) {
        filterIndex = 1
      }
      return this.filteredItems.slice((filterIndex * this.itemsPerPage) - this.itemsPerPage, filterIndex * this.itemsPerPage)
    },
    account () {
      return this.$store.getters['account/getUser']
    }
  },
  methods: {
    valueGroup (itemId) {
      let checked = false
      if (this.type === 'users') {
        this.group?.users?.filter(user => {
          if (user._id === itemId) { checked = true }
        })
      }
      if (this.type === 'collections' && this.objectsId) {
        if (this.objectsId.indexOf(itemId) !== -1) {
          checked = true
        }
      }
      if (this.type === 'surveys' && this.objectsId) {
        if (this.objectsId.indexOf(itemId) !== -1) {
          checked = true
        }
      }

      if (this.type === 'groups') {
        if (this.user.roles && this.user.roles.indexOf(itemId) !== -1) {
          checked = true
        }
      }
      return checked
    },
    async itemSelect (e, item) {
      const groupForUpdate = this.group

      if (this.type === 'users') {
        const user = await accountService.getUser(item._id)
        if (e) {
          user.roles.push(groupForUpdate._id)
          groupForUpdate.users.push(user)
        } else {
          user.roles = user.roles.filter(group => {
            return group !== groupForUpdate._id
          })
          groupForUpdate.users = groupForUpdate.users.filter(u => {
            return u._id !== user._id
          })
        }
        await accountService.updateUser(user)
      }

      if (this.type === 'groups') {
        if (e) {
          this.user.roles.push(item._id)
        } else {
          this.user.roles = this.user.roles.filter(user => {
            return user !== item._id
          })
        }
      }

      if (this.type === 'collections') {
        if (e) {
          this.objectsId.push(item._id)
        } else {
          this.objectsId = this.objectsId.filter(i => {
            return i !== item._id
          })
        }
        const objectsId = { $and: [{ _id: { $in: this.objectsId } }] }
        groupForUpdate.filters.Collector = JSON.stringify(objectsId)
      }

      if (this.type === 'surveys') {
        if (e) {
          this.objectsId.push(item._id)
        } else {
          this.objectsId = this.objectsId.filter(i => {
            return i !== item._id
          })
        }
        const objectsId = { $and: [{ _id: { $in: this.objectsId } }] }
        groupForUpdate.filters.Survey = JSON.stringify(objectsId)
      }
      this.$root.$emit('items-for-update', groupForUpdate)
    },
    updatePagination () {
      const itemsLength = this.filteredItems.length / this.itemsPerPage
      if (Number.isInteger(itemsLength)) {
        this.dynamicPageCount = itemsLength
      } else {
        this.dynamicPageCount = Math.trunc(itemsLength) + 1
      }
      this.valueGroup()
    },
    changePage (page) {
      this.page = page
      window.scrollTo({ top: 200, behavior: 'smooth' })
    },
    optionSelected (e) {
      this.items = this.contents.itemList.slice()

      switch (this.type) {
        case 'users':
          if (e.type === 'marked') {
            this.items = this.contents.itemList.filter(item => this.group.users.some(user => user._id === item._id))
          } else if (e.type === 'unchecked') {
            this.items = this.contents.itemList.filter(item => !this.group.users.some(user => user._id === item._id))
          }
          break
        case 'groups':
          if (e.type === 'marked') {
            this.items = this.contents.itemList.filter(item => this.user.roles.some(groupId => groupId === item._id))
          } else if (e.type === 'unchecked') {
            this.items = this.contents.itemList.filter(item => !this.user.roles.some(groupId => groupId === item._id))
          }
          break
        case 'surveys':
        case 'collections':
          if (e.type === 'marked') {
            this.items = this.contents.itemList.filter(item => this.objectsId.includes(item._id))
          } else if (e.type === 'unchecked') {
            this.items = this.contents.itemList.filter(item => !this.objectsId.includes(item._id))
          }
          break
        default:
          return true
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('items-for-update')
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
.container-card {
  background: #fff;
  width: 100%;
  height: 500px;
  padding-top: 10px;
  border-radius: 5px;
  border: 1px solid #cacaca;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .section-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5px;
    width: 100%;
    height: 100%;
    .title-card {
      padding-bottom: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .material-icons {
        font-size: 15px;
        margin: 3px 0 0 5px;
      }
    }
    .h7 {
      text-align: center;
    }
    .content-card {
      height: 80%;
      .search-card {
        width: 100%;
        height: 40px;
        margin: 0 0 15px 0;
        display: flex;
        .select-items {
          width: 100%;
          padding-left: 8px;
          margin: 7px 0;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          .text-field__input {
            margin: 15px 0px;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.4);
            height: 32px;
            width: 100%;
            input {
              color: rgb(63, 51, 86) !important;
              background: rgba(217, 217, 217, 0.2);
              height: 100%;
              font-size: 17px;
              font-weight: 400;
            }
          }
          .v-select {
            margin: 0 5px;
            background: transparent;
            padding: 0;
            .vs__dropdown-menu {
              margin-top: 10px;
              width: 90%;
            }
            .vs__dropdown-toggle {
              width: 100% !important;
            }
            .vs__search {
              color: rgb(168, 168, 168);
            }
          }
        }
      }
      .list-items {
        width: 100%;
        padding: 5px 5px 5px 10px;
        height: 85%;
        .items {
          width: 100%;
          .input-wrapper {
            margin-bottom: 14px;
            .checkmark {
              border: 2px solid #adadad;
              filter: grayscale(100%);
            }
          }
          .input-wrapper::after {
            display: none;
          }
        }
      }
    }
  }
  .items-paginate {
    margin-bottom: 5px;
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    .pagination {
      max-width: 95%;
      .prev-link-item, .next-link-item {
        font-size: 12px !important;
      }
      .page-link-item {
        font-size: 12px !important;
      }
    }
  }
}
</style>
