<template>
  <div>
    <div :id="'chart-'+ idx" v-on:click="handle()"></div>
    <div v-if="tooltipTag">
      <WordCard :word="word" :tooltipTag="tooltipTag" @closeTooltipTag="closeDisplayTag()"/>
    </div>
  </div>
</template>

<script>
import WordCard from '@/components/analytics/wordCard.vue'
export default {
  name: 'Bubbles',
  props: ['idx', 'word', 'tooltipTag', 'qty'],
  components: { WordCard },
  data () {
    return {
      dataChart: {},
      displayGraph: {},
      size: 0
    }
  },
  mounted () {
    const sortQty = this.qty.sort((a, b) => {
      return b - a
    })

    const maxQty = sortQty[0]
    const calc = (previousValue, currentValue) => previousValue + currentValue
    const sum = this.qty.reduce(calc)
    const media = sum / this.qty.length

    if (this.word.qty > media) {
      this.size = 200
    }

    if (this.word.qty > (media / 2) && this.word.qty < media) {
      this.size = 170
    }

    if (this.word.qty < (media / 2)) {
      this.size = 135
    }

    // seta um valor default para a maior bolha
    if (this.word.qty === maxQty) {
      this.size = 250
    }

    this.renderBubbles()
  },
  methods: {
    handle () {
      this.$emit('openTooltipTag', true)
    },
    closeDisplayTag () {
      this.$emit('closeTooltipTag', true)
    },
    async renderBubbles () {
      // Definindo labels e cores para o doughnut
      let labels = []
      let colors = []
      const question = this.word.question
      const data = this.word.options.map((item) => {
        return item.value
      })

      if (question.type === 'nps') {
        labels = [this.$t('dashboard.graphics.detractor'), this.$t('dashboard.graphics.neutralString'), this.$t('dashboard.graphics.promoter')]
        colors = ['#ff607d', '#ffb500', '#00aaa7']
      }

      if (question.type === 'csat') {
        if (question.ui !== '5emo') {
          labels = [this.$t('dashboard.graphics.un'), this.$t('dashboard.graphics.sat')]
          colors = ['#ff607d', '#00aaa7']
        } else {
          labels = [this.$t('dashboard.graphics.vu'), this.$t('dashboard.graphics.un'), this.$t('dashboard.graphics.ind'), this.$t('dashboard.graphics.sat'), this.$t('dashboard.graphics.vs')]
          colors = ['#ff607d', '#ff607d', '#ffb500', '#00aaa7', '#00aaa7']
        }
      }

      if (question.type === 'kpi') {
        if (question.ui === 'ynd') {
          labels = [this.$t('dashboard.graphics.op2'), this.$t('dashboard.graphics.op4'), this.$t('dashboard.graphics.op1')]
          colors = ['#ff607d', '#ffb500', '#00aaa7']
        }
        if (question.ui === 'yn') {
          labels = [this.$t('dashboard.graphics.op2'), this.$t('dashboard.graphics.op1')]
          colors = ['#ff607d', '#00aaa7']
        }
        if (question.ui === '5emo') {
          labels = [this.$t('dashboard.graphics.vb'), this.$t('dashboard.graphics.bd'), this.$t('dashboard.graphics.st'), this.$t('dashboard.graphics.vg'), this.$t('dashboard.graphics.ex')]
          colors = ['#ff607d', '#ff607d', '#ffb500', '#00aaa7', '#00aaa7']
        }
      }

      if (question.type === 'nes') {
        labels = [this.$t('dashboard.graphics.me'), this.$t('dashboard.graphics.ee'), this.$t('dashboard.graphics.le')]
        colors = ['#ff607d', '#ffb500', '#00aaa7']
      }

      // object doughnut
      this.dataChart = {
        labels: labels,
        datasets: [{
          borderWidth: 0,
          weight: 0.5,
          backgroundColor: colors,
          data: data
        }]
      }

      const options = {
        cutoutPercentage: 85,
        legend: {
          display: false
        },
        elements: {
          center: {
            text: this.word.word,
            textb: this.word.qty,
            color: '#fff', // Default is #000000
            fontStyle: 'Arial', // Default is Arial
            sidePadding: 12, // Default is 20 (as a percentage)
            minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
            lineHeight: 25 // Default is 25 (in px), used for when text wraps
          }
        }
      }

      const ctx = document.getElementById('chart-' + this.idx)
      const canvas = document.createElement('canvas')
      canvas.width = this.size
      canvas.height = this.size
      ctx.appendChild(canvas)

      this.displayGraph = await new window.Chart(canvas, {
        type: 'doughnut',
        data: this.dataChart,
        options: options,
        plugins: [{
          beforeDraw: function (chart) {
            if (chart.config.options.elements.center) {
            // Get ctx from string
              var ctx = chart.chart.ctx

              // Get options from the center object in options
              var centerConfig = chart.config.options.elements.center
              var fontStyle = centerConfig.fontStyle || 'Arial'
              var txt = centerConfig.text
              var txtb = centerConfig.textb
              var color = centerConfig.color || '#000'
              var maxFontSize = centerConfig.maxFontSize || 45
              var sidePadding = centerConfig.sidePadding || 20
              var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
              // Start with a base font of 30px
              ctx.font = '35px ' + fontStyle
              // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
              var stringWidth = ctx.measureText(txt).width
              var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated

              // Find out how much the font can grow in width.
              var widthRatio = elementWidth / stringWidth
              var newFontSize = Math.floor(30 * widthRatio)
              var elementHeight = (chart.innerRadius * 2)

              // Pick a new font size so it will not be larger than the height of label.
              var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize)
              var minFontSize = centerConfig.minFontSize
              var lineHeight = centerConfig.lineHeight || 25
              var wrapText = false

              if (minFontSize === undefined) {
                minFontSize = 20
              }

              if (minFontSize && fontSizeToUse < minFontSize) {
                fontSizeToUse = minFontSize
                wrapText = true
              }

              // Set font settings to draw it correctly.
              ctx.textAlign = 'center'
              ctx.textBaseline = 'middle'
              var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2)
              var centerYb = ((chart.chartArea.top + chart.chartArea.bottom) / 1.5)
              var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2.8)
              ctx.font = fontSizeToUse + 'px ' + fontStyle
              ctx.fillStyle = color
              if (!wrapText) {
                ctx.fillText(txt, centerX, centerY)
                ctx.fillText(txtb, centerX, centerYb)
                return
              }

              var words = txt.split(' ')
              var line = ''
              var lines = []

              // Break words up into multiple lines if necessary
              for (var m = 0; m < words.length; m++) {
                var testLine = line + words[m] + ' '
                var metrics = ctx.measureText(testLine)
                var testWidth = metrics.width
                if (testWidth > elementWidth && m > 0) {
                  lines.push(line)
                  line = words[m] + ' '
                } else {
                  line = testLine
                }
              }

              // Move the center up depending on line height and number of lines
              centerY -= (lines.length / 2) * lineHeight

              for (var n = 0; n < lines.length; n++) {
                ctx.fillText(lines[n], centerX, centerY)
                centerY += lineHeight
              }
              // Draw text in center
              ctx.fillText(line, centerX, centerY)
            }
          }
        }]
      }, { onClick: this.handle })
      ctx.fillStyle = 'red'
    }
  }
}
</script>

<style lang="scss">
.info-total-bubbles {
  position: absolute;
  left: 0;
  bottom: 0;
}
</style>
