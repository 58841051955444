<template>
  <div class="container-select">
    <div class="section-selecteds">
      <div class="two-items">
        <div class="op-selected" v-for="item in itemToShow" :key="item.id">
          <img class="op-selected-image" :src="item.image">
          <app-heading class="op-selected-name" level="h7">{{ item.name }}</app-heading>
        </div>
      </div>
      <app-heading v-if="allItems.length > 2" class="total-items" level="h7">+ {{ allItems.length - 2 }}</app-heading>
      <i class="material-icons icon-close-selected" v-if="!opended">expand_more</i>
      <i class="material-icons icon-close-selected" v-else>expand_less</i>
    </div>
    <div class="section-options">
      <div class="search-option">
        <i class="material-icons icon-search-brand">search</i>
        <input type="text" class="search-brand-input" :placeholder="$t('bvs.search')" v-model="search">
      </div>
      <div class="section-select-all">
        <button class="select-all"
          @click="deselectAll()"
          :class="this.optionsSelecteds.length > 0 ? 'enable-btn' : ''"
          :disabled="this.optionsSelecteds.length === 0"
          >{{ $t('bvs.deselect_all') }}
        </button>
      </div>
      <div class="all-options">
        <div class="option" v-for="op in currentItems" :key="op.id" @click="selectBrand(op)">
          <img class="op-image" :src="op.image">
          <app-heading class="op-name" level="h5">{{ op.name }}</app-heading>
          <input class="op-check" type="checkbox" :checked="op.selected">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectBrands',
  props: ['options', 'selecteds'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      opended: false,
      search: '',
      optionsSelecteds: [],
      itemToShow: [],
      allItems: [],
      hasChange: false
    }
  },
  beforeDestroy () {
    this.$root.$off('close-filter-select')
    this.$root.$off('open-filter-select')
  },
  mounted () {
    if (this.selecteds.length <= 0) {
      this.allItems = this.options
      this.optionsSelecteds = []
    } else {
      this.allItems = this.selecteds
      this.options.forEach(op => {
        const findItem = this.allItems.find(e => { return e.id === op.id })
        if (findItem) { op.selected = true }
      })

      this.optionsSelecteds = this.selecteds.map(e => { return e.id })
    }

    this.$root.$on('open-filter-select', (e) => {
      if (e && !this.opended) {
        this.opended = false
        this.openOptions(true)
        return
      }

      this.opended = !this.opended
      this.openOptions()
    })

    this.displayBrands()
  },
  computed: {
    currentItems () {
      return this.filteredItems
    },
    filteredItems () {
      let filtered = []
      filtered = this.options.filter(item => { return item.name.toLowerCase().includes(this.search.toLowerCase()) })
      return filtered
    }
  },
  methods: {
    deselectAll () {
      if (this.optionsSelecteds.length !== this.options.length) {
        this.allItems = this.options
        this.allItems.forEach(op => { op.selected = false })
        this.optionsSelecteds = []
        this.hasChange = true
      }
    },
    displayBrands () {
      this.itemToShow = []
      if (this.allItems.length >= 2) {
        this.itemToShow.push(this.allItems[0])
        this.itemToShow.push(this.allItems[1])
      } else {
        this.itemToShow.push(this.allItems[0])
      }
    },
    selectBrand (e) {
      e.selected = !e.selected
      if (e.selected) {
        this.optionsSelecteds.push(e.id)
        const findTwo = this.allItems.find(i => { return i.id === e.id })
        if (findTwo && !findTwo.selected) { this.allItems.push(e) }
      } else {
        const find = this.optionsSelecteds.findIndex(i => { return i === e.id })
        if (find >= 0) { this.optionsSelecteds.splice(find, 1) }
      }
      this.hasChange = true
    },
    openOptions (e) {
      const openContainer = document.querySelector('.container-select')
      const openOptions = document.querySelector('.section-options')
      if (this.opended) {
        openContainer.style.height = '285px'
        openContainer.style.transition = 'height 0.4s'

        openOptions.style.height = '247px'
        openOptions.style.transition = 'height 0.4s'
      } else {
        openContainer.style.height = '30px'
        openContainer.style.transition = 'height 0.4s'

        openOptions.style.height = '0px'
        openOptions.style.transition = 'height 0.4s'
        if (!e) { this.setItems() }
        this.search = ''
      }
    },
    setItems () {
      if (!this.hasChange) { return false }
      let ids = ''
      let toUpdate = []
      if (this.optionsSelecteds.length > 0) {
        this.optionsSelecteds.forEach(t => { ids = ids.concat(`${t},`) })
        this.options.forEach(e => { if (e.selected) { toUpdate.push(e) } })
        this.allItems = toUpdate
      } else {
        toUpdate = this.allItems
      }
      this.$store.commit('filtersSocialMedia/updateTermsSelected', toUpdate)
      this.$root.$emit('update-brands-selecteds', ids)
      this.displayBrands()
      this.hasChange = false
    }
  }
}
</script>

<style lang="scss">
.container-select {
  width: 250px;
  height: 30px;
  margin-top: 13px;
  border-radius: 2px;
  overflow: hidden;
  user-select: none;
  .section-selecteds {
    background: #00000004;
    width: 100%;
    height: 30px;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .two-items {
      width: 75%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .op-selected {
        display: flex;
        align-items: center;
        padding-right: 2px;
        margin-right: 5px;
        border: 1px solid #cacaca;
        border-radius: 35px;
        width: 85px;
        height: 20px;
        .op-selected-image {
          border-radius: 50%;
          width: 18px;
          height: 18px;
          margin-right: 2px;
        }
        .op-selected-name {
          font-size: 11px;
          width: 60px;
          white-space: nowrap;
          margin: 0 !important;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .total-items {
      font-size: 13px !important;
      width: 15%;
      color: #454548 !important;
      font-weight: bold;
      margin: 0 !important;
      text-align: center;
    }
    .icon-close-selected {
      width: 10%;
      font-weight: bold;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      color: var(--accent-color);
    }
  }
  .section-options {
    width: 100%;
    background: white;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #cacaca;
    .search-option {
      width: 100%;
      height: 35px;
      background: white;
      border-radius: 5px 5px 0 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #cacaca;
      .icon-search-brand {
        width: 15%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: gray;
      }
      .search-brand-input {
        width: 85%;
        height: 100%;
        border: none;
        padding-left: 5px;
      }
    }
    .section-select-all {
      width: 100%;
      height: 35px;
      border-bottom: 1px solid #cacaca;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .select-all {
        width: 95%;
        background: #0000000a;
        height: 25px;
        border: 1px solid #cacaca;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 3px;
        font-weight: 500 !important;
        font-size: 13px;
      }
      .enable-btn {
        color: var(--accent-color);
        border: 1px solid var(--accent-color);
      }
      .select-all-check {
        width: 17px;
        height: 17px;
        accent-color: var(--accent-color);
      }
    }
    .all-options {
      width: 250px;
      height: 175px;
      overflow-y: auto;
      .option {
        width: 100%;
        height: 35px;
        border-bottom: 1px solid #cacaca;
        padding: 0 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .op-image {
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
        .op-name {
          width: 190px;
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 5px;
          color: #454548 !important;
          font-weight: 500 !important;
          font-size: 14px;
        }
        .op-check {
          width: 17px;
          height: 17px;
          accent-color: var(--accent-color);
        }
      }
      .option:hover {
        background: #f5f5f5;
      }
    }
  }
}
</style>
