var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-modal',{staticClass:"modal-default-primary modal-conditions",attrs:{"close":"","lightbox":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel()}},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"row"},_vm._l((_vm.survey.questions),function(question,index){return _c('div',{key:index},[((index < _vm.survey.questionSelected) && (_vm.forbiddenTypes.indexOf(question.type) < 0 && _vm.forbiddenUis.indexOf(question.ui) < 0))?_c('div',{staticClass:"nav-card-conditions",on:{"click":function($event){return _vm.selectQuestionForCondition(question)}}},[_c('div',[_c('app-heading',{staticClass:"question-order"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"head-questions-prev"},[_c('app-heading',{staticClass:"question-nav-title",attrs:{"level":"h6"}},[_vm._v(" "+_vm._s(question.label || _vm.$t('questionsNav.question_title'))+" ")]),_c('app-heading',{staticClass:"question-nav-text",attrs:{"level":"h6"}},[_vm._v(" "+_vm._s(question.question)+" ")])],1)],1),_c(_vm.renderUi(question.ui, question.type, question.options),{tag:"component",staticClass:"transform-ui-c",class:{
              't-5emo-c': question.ui.toLowerCase() === '5emo',
              't-stars-c': question.ui.toLowerCase() === '5num' && (question.stars && question.stars.active),
              't-5num-c': question.ui.toLowerCase() === '5num' && (!question.stars || !question.stars.active),
              't-5nes-c': (question.ui.toLowerCase() === '5nes' || question.ui.toLowerCase() === '10num') && question.type === 'nes',
              't-yn-c': question.ui.toLowerCase() === 'yn',
              't-ynm-c': question.ui === 'ynm',
              't-ynd-c': question.ui.toLowerCase() === 'ynd',
              't-text-c': question.ui.toLowerCase() === 'text',
              't-inputext-c': question.ui.toLowerCase() === 'inputext',
              't-referral-c': question.ui.toLowerCase() === 'referral',
              't-referraldynamic-c': question.ui.toLowerCase() === 'referraldynamic',
              't-single-c': question.ui.toLowerCase() === 'single' || question.ui === 'singleOther',
              't-multiple-c': question.ui.toLowerCase() === 'multiple' || question.ui === 'multipleOther',
              't-0to5-c': question.options.length === 6 && question.ui === '10num',
              't-1to5-c': question.options.length === 5 && question.ui === '10num' && question.type !== 'nes',
              't-0to10-c': ((question.options.length === 11 && question.ui === '10num') || (question.options.length === 14 && question.ui === '10num')) && question.type !== 'matrix',
              't-nps-c' : question.type === 'nps' && question.ui === '10num',
              't-1to10-c': question.options.length === 10 && question.ui === '10num',
              't-1to7-c': question.type === 'ces' && question.ui === '1to7',
              't-1to7button-c': question.type === 'ces' && question.ui === '1to7button',
              't-matrix1to5-c': question.ui.toLowerCase() === 'matrix1to5',
              't-matrix10num-c': question.ui.toLowerCase() === '10num' && question.type === 'matrix',
              't-5radio-c': question.ui.toLowerCase() === '5radio'
            },attrs:{"question":question,"colors":_vm.componentsColors,"preview":true}})],1):_vm._e()])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }