import { translator } from '@/_helpers'
import { find } from 'lodash-es'

export const responseOptions = {
  getOptions,
  createOptionsToEnumUi,
  mountQueryToRedirect
}

/**
 * retorna as opções da questão para ele em sí
 * @param {Object} question questão atual
 * @param {Boolean} isTicket se for ticket o retorno é modificado
 * @param {Boolean} isToReturnShortOptions se for true devemos GARANTIR que vá enviado os valores default das opções + opções customizáveis, como 10num enviar os números de 0 a 10 e promoter, detractor etc
 * @returns retorna as opções para o tipo da questão, para o ticket ou opções da condicional de pesquisa
 */
function getOptions (question, isTicket = false, isToReturnShortOptions = false, useRating) {
  const type = question.type
  const ui = question.ui
  let options = []
  const promoterNeutralDetractor = [
    { label: translator.translateThisText(question.language, 'dashboard.graphics.promoter'), value: '$promoter' },
    { label: translator.translateThisText(question.language, 'dashboard.graphics.neutralSing'), value: '$neutral' },
    { label: translator.translateThisText(question.language, 'dashboard.graphics.detractor'), value: '$detractor' }
  ]

  if (useRating) {
    promoterNeutralDetractor[0].rating = '90'
    promoterNeutralDetractor[2].rating = '60'
  }

  // CSAT options
  if (type === 'csat') {
    if (ui === '5emo') {
      // Muito satisfeito, satisfeito, indiferente, insatisfeito, muito insatisfeito.
      options = [
        { label: translator.translateThisText(question.language, 'dashboard.graphics.vs'), icon: '\uE603', value: '100' },
        { label: translator.translateThisText(question.language, 'dashboard.graphics.sat'), icon: '\uE600', value: '75' },
        { label: translator.translateThisText(question.language, 'dashboard.graphics.ind'), icon: '\uE606', value: '50' },
        { label: translator.translateThisText(question.language, 'dashboard.graphics.un'), icon: '\uE607', value: '25' },
        { label: translator.translateThisText(question.language, 'dashboard.graphics.vu'), icon: '\uE608', value: '0' }
      ]
    }
    if (ui === '10num') {
      options = [...Array(11).keys()].map(item => {
        return { label: item, rating: (item * 10).toString() }
      })
    }
    if (ui === '5num') {
      options = [...Array(5).keys()].map(item => {
        return { label: item + 1, rating: (item * 25).toString() }
      })
    }
  }
  // CSAT options - END

  // NPS options
  if (type === 'nps') {
    if (ui === 'ynm' || ui === 'ynd') {
      options = [
        { label: translator.translateThisText(question.language, 'dashboard.y'), value: '95' },
        { label: translator.translateThisText(question.language, 'dashboard.n'), value: '5' },
        { label: translator.translateThisText(question.language, 'dashboard.mb'), value: '75' }
      ]
      if (isToReturnShortOptions) {
        options.unshift(...promoterNeutralDetractor)
      }
    }
    if (ui === '10num') {
      options = [...Array(11).keys()].map(item => { return { label: item, rating: (item * 10).toString() } })
      if (isToReturnShortOptions) {
        options.unshift(...promoterNeutralDetractor)
      }
    }
  }
  // NPS options - END

  // NES ou CES options
  if (type === 'nes') {
    if (ui === '10num') {
      options = [...Array(5).keys()].map(item => { return { label: item + 1, rating: (item * 25).toString() } })
    }
  }
  if (type === 'ces') {
    if (ui === '1to7') {
      options = [
        { label: '1', rating: '0' },
        { label: '2', rating: '15' },
        { label: '3', rating: '30' },
        { label: '4', rating: '50' },
        { label: '5', rating: '65' },
        { label: '6', rating: '80' },
        { label: '7', rating: '100' }
      ]
    }
    if (ui === '1to7button') {
      options = [
        { label: translator.translateThisText(question.language, 'components.ces.totally_disagree'), rating: '0' },
        { label: translator.translateThisText(question.language, 'components.ces.disagree'), rating: '15' },
        { label: translator.translateThisText(question.language, 'components.ces.partially_disagree'), rating: '30' },
        { label: translator.translateThisText(question.language, 'components.ces.neutral'), rating: '50' },
        { label: translator.translateThisText(question.language, 'components.ces.partially_agree'), rating: '65' },
        { label: translator.translateThisText(question.language, 'components.ces.agree'), rating: '80' },
        { label: translator.translateThisText(question.language, 'components.ces.totally_agree'), rating: '100' }
      ]
    }
  }
  // NES ou CES options - END

  // NVS options
  if (type === 'nvs') {
    options = [
      { label: translator.translateThisText(question.language, 'dashboard.graphics.nvs_op1'), rating: '100' },
      { label: translator.translateThisText(question.language, 'dashboard.graphics.nvs_op2'), rating: '75' },
      { label: translator.translateThisText(question.language, 'dashboard.graphics.nvs_op3'), rating: '50' },
      { label: translator.translateThisText(question.language, 'dashboard.graphics.nvs_op4'), rating: '25' },
      { label: translator.translateThisText(question.language, 'dashboard.graphics.nvs_op5'), rating: '0' }
    ]
  }
  // NVS options - END

  // TEXT options ***FOR TICKETS***
  if (type === 'text' && isTicket) {
    options = [
      // valor para quando é "preenchido"
      { value: true, label: translator.translateThisText(question.language, 'tickets.filled') },
      // valor para quando é "não preenchido"
      { value: '', label: translator.translateThisText(question.language, 'tickets.unfilled') }
    ]
  }

  // KPI options
  if (type === 'kpi') {
    if (ui === 'yn') {
      options = [
        { label: translator.translateThisText(question.language, 'dashboard.y'), value: '100' },
        { label: translator.translateThisText(question.language, 'dashboard.n'), value: '0' }
      ]
    }
    if (ui === 'ynm') {
      options = [
        { label: translator.translateThisText(question.language, 'dashboard.y'), value: '100' },
        { label: translator.translateThisText(question.language, 'dashboard.n'), value: '0' },
        { label: translator.translateThisText(question.language, 'dashboard.mb'), value: '50' }
      ]
    }
    if (ui === 'ynd') {
      options = [
        { label: translator.translateThisText(question.language, 'dashboard.y'), value: '100' },
        { label: translator.translateThisText(question.language, 'dashboard.n'), value: '0' },
        { label: translator.translateThisText(question.language, 'dashboard.graphics.op3'), value: '50' }
      ]
    }
    if (ui === '5emo') {
      options = [
        { label: translator.translateThisText(question.language, 'dashboard.graphics.ex'), icon: '\uE603', value: '100' },
        { label: translator.translateThisText(question.language, 'dashboard.graphics.vg'), icon: '\uE600', value: '75' },
        { label: translator.translateThisText(question.language, 'dashboard.graphics.satisf'), icon: '\uE606', value: '50' },
        { label: translator.translateThisText(question.language, 'dashboard.graphics.bd'), icon: '\uE607', value: '25' },
        { label: translator.translateThisText(question.language, 'dashboard.graphics.vb'), icon: '\uE608', value: '0' }
      ]
    }
  }
  // KPI options - END
  // ENUM options
  if (type === 'enum') {
    /*
      As questions de tipo enum usam o label como value.
      Mesmo assim, vou deixar as UIs expostas aqui, para que
      no futuro, se UIs diferentes forem adicionadas, fique claro.
    */
    if (['10num', 'single', 'multiple', 'multipleOther', 'singleOther'].indexOf(ui) >= 0) {
      const currentQuestionWithoutRef = { ...question }
      options = currentQuestionWithoutRef.options
      options.forEach(item => {
        item.value = item.label
      })
    }
  }
  // ENUM options - END
  return options
}

function createOptionsToEnumUi (ui) {
  let options = []
  if (ui === '0to5') {
    options = [
      { label: '0', value: '0' },
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' }
    ]
  }

  if (ui === '1to5') {
    options = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' }
    ]
  }

  if (ui === '0to10' || ui === '10num') {
    options = [
      { label: '0', value: '0' },
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '6', value: '6' },
      { label: '7', value: '7' },
      { label: '8', value: '8' },
      { label: '9', value: '9' },
      { label: '10', value: '10' }
    ]
  }

  if (ui === '1to10') {
    options = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '6', value: '6' },
      { label: '7', value: '7' },
      { label: '8', value: '8' },
      { label: '9', value: '9' },
      { label: '10', value: '10' }
    ]
  }

  return options
}

function mountQueryToRedirect (question, clickedOption, query) {
  const parsedQuery = typeof query !== 'object' ? JSON.parse(query) : query
  const options = this.getOptions(question)

  const hasSurveyOnQuery = find(parsedQuery.$and, 'survey')
  const hasGteOnQuery = find(parsedQuery.$and, 'respondedAt.$gte')
  const hasLteOnQuery = find(parsedQuery.$and, 'respondedAt.$lte')

  if (question.type === 'kpi' || question.type === 'enum') {
    return query
  }

  if (question.type === 'nps') {
    // último parâmetro dessa função 'getOptions' é para retornar o rating no objeto.
    const optionsNps = this.getOptions(question, false, true, true)
    const npsOptionIdx = optionsNps.findIndex(i => i.label === clickedOption.label)

    if (npsOptionIdx >= 0) {
      const foundOption = optionsNps[npsOptionIdx]
      const rating = foundOption.rating >= 90 ? '"$gte":90' : foundOption.rating <= 60 ? '"$lte":60' : '"$lte":80,"$gte":70'
      const query = `{"$and":[{"survey":{"$eq":"${parsedQuery.$and[0].survey.$eq}"}},{"respondedAt":{"$gte":"${parsedQuery.$and[1].respondedAt.$gte}"}},{"respondedAt":{"$lte":"${parsedQuery.$and[1].respondedAt.$lte}"}},{"responses":{"$elemMatch":{"question._id":"${question._id}","rating":{${rating}}}}}]}`
      return query
    }
  }

  // CSAT
  if (question.type === 'csat') {
    if (['10num', '5num'].indexOf(question.ui) > -1) {
      const foundIndex = options.findIndex(i => `${translator.translateThisText(question.language, 'dashboard.option')} ${i.label}` === clickedOption.label)
      if (foundIndex >= 0) {
        const foundOption = options[foundIndex]
        const rating = foundOption.rating >= 70 ? '"$gte":70' : '"$lt":70'
        const query = `{"$and":[{"survey":{"$eq":"${parsedQuery.$and[0].survey.$eq}"}},{"respondedAt":{"$gte":"${parsedQuery.$and[1].respondedAt.$gte}"}},{"respondedAt":{"$lte":"${parsedQuery.$and[1].respondedAt.$lte}"}},{"responses":{"$elemMatch":{"question._id":"${question._id}","rating":{${rating}}}}}]}`
        return query
      }
    }

    if (question.ui === '5emo') {
      const foundIndex = options.findIndex(i => i.label === clickedOption.label)
      if (foundIndex >= 0) {
        const foundOption = options[foundIndex]
        const rating = foundOption.value >= 70 ? '"$gte":70' : '"$lt":70'
        const query = `{"$and":[{"survey":{"$eq":"${parsedQuery.$and[0].survey.$eq}"}},{"respondedAt":{"$gte":"${parsedQuery.$and[1].respondedAt.$gte}"}},{"respondedAt":{"$lte":"${parsedQuery.$and[1].respondedAt.$lte}"}},{"responses":{"$elemMatch":{"question._id":"${question._id}","rating":{${rating}}}}}]}`
        return query
      }
    }
  }

  // NES (CES)
  if (question.type === 'nes') {
    const foundIndex = options.findIndex(i => `${translator.translateThisText(question.language, 'dashboard.option')} ${i.label}` === clickedOption.label)
    if (foundIndex >= 0) {
      const foundOption = options[foundIndex]
      const rating = foundOption.rating <= 25 ? '"$lte":25' : foundOption.rating >= 75 ? '"$gte":75' : '"$gt":49, "$lt":51'
      const query = `{"$and":[{"survey":{"$eq":"${parsedQuery.$and[0].survey.$eq}"}},{"respondedAt":{"$gte":"${parsedQuery.$and[1].respondedAt.$gte}"}},{"respondedAt":{"$lte":"${parsedQuery.$and[1].respondedAt.$lte}"}},{"responses":{"$elemMatch":{"question._id":"${question._id}","rating":{${rating}}}}}]}`
      return query
    }
  }

  // CES 2.0
  if (question.type === 'ces') {
    const foundIndex = options.findIndex(i => i.label === clickedOption.label)
    if (foundIndex >= 0) {
      const foundOption = options[foundIndex]
      const rating = foundOption.rating >= 52 ? '"$gte":52' : foundOption.rating <= 48 ? '"$lte":48' : '"$gt":49,"$lt":51'
      const query = `{"$and":[{"survey":{"$eq":"${parsedQuery.$and[0].survey.$eq}"}},{"respondedAt":{"$gte":"${parsedQuery.$and[1].respondedAt.$gte}"}},{"respondedAt":{"$lte":"${parsedQuery.$and[1].respondedAt.$lte}"}},{"responses":{"$elemMatch":{"question._id":"${question._id}","rating":{${rating}}}}}]}`
      return query
    }
  }

  // NVS
  if (question.type === 'nvs') {
    const foundIndex = options.findIndex(i => i.label === clickedOption.label)
    if (foundIndex >= 0) {
      const foundOption = options[foundIndex]
      const foundMe = [
        { rating: '100', query: { $gte: 99 } },
        { rating: '75', query: { $gte: 74, $lte: 76 } },
        { rating: '50', query: { $gte: 49, $lte: 51 } },
        { rating: '25', query: { $gte: 24, $lte: 26 } },
        { rating: '0', query: { $lte: 1 } }].filter(item => item.rating === foundOption.rating)
      const hasRating = foundMe.filter(item => item.rating === foundOption.rating)[0]

      if (hasRating) {
        const query = {
          $and: [
            { responses: { $elemMatch: { 'question._id': question._id, rating: hasRating.query } } }
          ]
        }

        if (hasSurveyOnQuery) {
          query.$and.push({ ...hasSurveyOnQuery })
        }

        if (hasGteOnQuery) {
          query.$and.push({ ...hasGteOnQuery })
        }

        if (hasLteOnQuery) {
          query.$and.push({ ...hasLteOnQuery })
        }
        return query
      }
      return false
    }
  }
}
