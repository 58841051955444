<template>
  <div class="container-notes-sidebar">
    <div class="header-notes-sidebar">
      <p class="title-notes-sidebar">{{ $t('annotation.annotation') }}</p>
      <p class="description-notes-sidebar">{{ $t('annotation.description') }}</p>
    </div>
    <div class="content-notes-sidebar" v-if="dataNotes.sendingId">
      <Annotations :id="dataNotes.sendingId" />
    </div>
  </div>
</template>

<script>
import Annotations from '../../annotations/Annotations.vue'

export default {
  name: 'NotesSidebar',
  props: ['dataNotes'],
  components: {
    Annotations
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.container-notes-sidebar {
  width: 100%;
  height: 100%;
  padding: 1vw;
  .header-notes-sidebar {
    width: 100%;
    height: 10%;
    margin-bottom: 0.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.7vw;
    color: #454548;
    .title-notes-sidebar {
      font-size: 1vw;
      font-weight: 500;
    }
    .description-notes-sidebar {
      font-size: 0.9vw;
    }
  }
  .content-notes-sidebar {
    width: 100%;
    max-height: 90%;
    overflow-y: auto;
  }
}
</style>
