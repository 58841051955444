<template :key="canvasId">
  <div class="ranking-overtime-chart">
    <canvas v-if="data.length > 0 || data.overtime.length > 0" :id="makeSpecialId()" style="width: 1050px;" />
    <app-heading level="h4" style="color: #000;" v-else>Falha ao obter resposta do servidor</app-heading>
  </div>
</template>

<script>
import { canvasMaker, gridMaker, formatters, tooltipMaker } from '@/_helpers'
import i18n from '../../../translations/i18n'

import('chart.js').then((chartjs) => {
  window.Chart = chartjs.default
})

export default {
  name: 'RakingOvertime',
  props: ['data', 'question', 'cardData', 'expanded', 'rankType', 'indexTable'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      currentChart: {},
      hideOrShow: false,
      canvasId: null,
      fontSize: 18,
      fontFamily: "'Avenir', Helvetica, Arial, sans-serif;",
      kpiByAverage: true
    }
  },
  mounted () {
    if (!window._.isEmpty(this.currentChart)) {
      this.currentChart.destroy()
      this.currentChart = {}
    }
    this.renderChart()
  },
  computed: {
    lang () {
      return this.$store.getters['account/getLang']
    }
  },
  methods: {
    makeSpecialId () {
      if (this.rankType === 'question') {
        this.canvasId = canvasMaker.makeCanvasId(this.question)
      }
      if (this.rankType === 'health') {
        this.canvasId = `health-${this.indexTable}`
      }
      return this.canvasId
    },
    async renderChart () {
      if (!this.data) {
        return false
      }

      const groups = []
      let labels = []
      let lines = []
      let optionsValues = []
      let graphicValues = []
      // Returns just items that are not null, and we see it by the "qty" attribute
      let dataTime = {}
      if (this.rankType === 'question') {
        const overtimeByQuestionId = this.data.find(item => this.question._id === item.question)
        dataTime = overtimeByQuestionId.overall.qty ? Object.assign({}, overtimeByQuestionId) : []
      }
      if (this.rankType === 'health') {
        dataTime = this.data
      }
      const gridRange = gridMaker.checkIfPeriodIsByDay(this.$route.name, this.$route.query.q)
      let formatDate = 'MMM Do' // esse if é para saber se o filtro é por hora ou dias, com isso formatamos melhor os labels de data do gráfico
      if (gridRange) {
        formatDate = 'HH:mm'
      }
      // Para gráfico de mudança de average no tempo. Uma só linha.
      const nesOrNps10numm = this.question.ui === '10num' && (this.question.type === 'nps' || this.question.type === 'nes')
      const kpiQuestionAvg = this.question.type === 'kpi' && this.kpiByAverage
      if (nesOrNps10numm || kpiQuestionAvg || this.question.ui === 'ynm') {
        const avgGrades = []
        if (!dataTime.overtime) {
          return false
        }
        dataTime.overtime.forEach((item, i) => {
          // devemos considerar um average vazio sem data certa?
          if (i > 0 && dataTime.overtime[i][2] !== null) {
            const avg = Math.round(dataTime.overtime[i][2] * 100) / 100
            const time = formatters.formatDateByLangAndFixTimeZone(dataTime.overtime[i][0], this.lang, formatDate)
            avgGrades.push(avg)
            labels.push(time)
          }
        })

        lines = [{
          label: i18n.t('dashboard.graphics.average'),
          data: avgGrades,
          borderColor: this.cardData.colors[1],
          pointBorderColor: this.cardData.colors[0],
          pointBackgroundColor: this.cardData.colors[0],
          backgroundColor: ['rgba(255, 255, 255, .2)'],
          borderWidth: 2
        }]

        optionsValues = {
          scales: {
            xAxes: [{
              ticks: {
                display: true // this will remove only the label
              }
            }]
          },
          legend: {
            display: this.hideOrShow,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 20
            }
          }
        }
        if (kpiQuestionAvg) {
          optionsValues.scales.yAxes = [{
            ticks: {
              max: 100,
              beginAtZero: true,
              precision: 0,
              padding: 25
            }
          }]
        }
      } else if ((['ynd', 'yn', 'single', 'singleOther', '1to10', 'multiple', 'multipleOther', '0to5', '1to7', '1to7button', '1to5', '5num', '0to10', '10num', '5emo', '5radio'].indexOf(this.question.ui) > -1) && this.question.type !== 'nes') {
        // Separar por grupos - START
        if (!dataTime.overtime) {
          return false
        }
        //  Gráfico de soma, conta a quantidade de QTY
        dataTime.overtime.forEach((item, i) => {
          // remove empty values
          if (i <= 0 || dataTime.overtime[i][0] === null || item[3] === null) {
            return false
          }
          const time = formatters.formatDateByLangAndFixTimeZone(dataTime.overtime[i][0], this.lang, formatDate)
          labels.push(time)
          // const groupName = ''
          if (this.question.type === 'nes') {
            let totalME = 0
            let totalLE = 0
            let totalEE = 0
            dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
              if (indexOfMe >= 4) {
                if (overtimeHeader < 49) {
                  totalLE += item[indexOfMe]
                  return false
                }
                if (overtimeHeader === 50) {
                  totalEE += item[indexOfMe]
                  return false
                }
                totalME += item[indexOfMe]
              }
            })

            if (!groups[i18n.t('dashboard.graphics.le')]) {
              groups[i18n.t('dashboard.graphics.le')] = []
            }
            if (!groups[i18n.t('dashboard.graphics.ee')]) {
              groups[i18n.t('dashboard.graphics.ee')] = []
            }
            if (!groups[i18n.t('dashboard.graphics.me')]) {
              groups[i18n.t('dashboard.graphics.me')] = []
            }
            groups[i18n.t('dashboard.graphics.le')].push(totalLE)
            groups[i18n.t('dashboard.graphics.ee')].push(totalEE)
            groups[i18n.t('dashboard.graphics.me')].push(totalME)
          }

          if ((['csat', 'ces', 'nvs'].indexOf(this.question.type) >= 0) && ['5emo', '1to5', '5num', '10num', '1to7', '1to7button', '5radio'].indexOf(this.question.ui) >= 0) {
            /*
              CSAT, CES 2.0 e NVS devem mostrar somente a média (average) nas labels de cada ponto,
              tudo numa só linha.
            */
            const csatAverageRounded = Math.round(item[2] * 100) / 100
            if (!groups[i18n.t('dashboard.graphics.average')]) {
              groups[i18n.t('dashboard.graphics.average')] = []
            }
            groups[i18n.t('dashboard.graphics.average')].push(csatAverageRounded)
          }

          if (this.question.type === 'kpi' && this.question.ui === '5emo') {
            let verygood = 0
            let excelent = 0
            let satisfied = 0
            let verybad = 0
            let bad = 0

            dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
              if (indexOfMe >= 4) {
                switch (overtimeHeader) {
                  case '0': {
                    bad += item[indexOfMe]
                    break
                  }
                  case '25': {
                    verybad += item[indexOfMe]
                    break
                  }
                  case '50': {
                    satisfied += item[indexOfMe]
                    break
                  }
                  case '75': {
                    excelent += item[indexOfMe]
                    break
                  }
                  case '100': {
                    verygood += item[indexOfMe]
                  }
                }
              }
            })

            this.fontSize = 24
            this.fontFamily = 'icomoon'
            const labels = ['\uE608', '\uE607', '\uE606', '\uE600', '\uE603']
            const values = [verygood, excelent, satisfied, verybad, bad]

            labels.forEach((el, index) => {
              if (!groups[el]) {
                groups[el] = []
              }
              groups[el].push(values[index])
            })
          }

          if (this.question.ui === 'yn') {
            let opt1 = 0
            let opt2 = 0
            dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
              if (indexOfMe >= 4) {
                if (overtimeHeader < 100) {
                  opt1 += item[indexOfMe]
                  return false
                }
                opt2 += item[indexOfMe]
              }
            })

            if (!groups[i18n.t('dashboard.graphics.op1')]) {
              groups[i18n.t('dashboard.graphics.op1')] = []
            }

            if (!groups[i18n.t('dashboard.graphics.op2')]) {
              groups[i18n.t('dashboard.graphics.op2')] = []
            }

            groups[i18n.t('dashboard.graphics.op1')].push(opt1)
            groups[i18n.t('dashboard.graphics.op2')].push(opt2)
          }

          if (this.question.ui === 'ynd') {
            let opt1 = 0
            let opt2 = 0
            let opt3 = 0
            dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
              if (indexOfMe >= 4) {
                if (overtimeHeader === 0) {
                  opt3 += item[indexOfMe]
                  return false
                }
                if (overtimeHeader === 50) {
                  opt2 += item[indexOfMe]
                  return false
                }
                opt1 += item[indexOfMe]
              }
            })

            if (!groups[i18n.t('dashboard.graphics.op1')]) {
              groups[i18n.t('dashboard.graphics.op1')] = []
            }
            if (!groups[i18n.t('dashboard.graphics.op4')]) {
              groups[i18n.t('dashboard.graphics.op4')] = []
            }
            if (!groups[i18n.t('dashboard.graphics.op2')]) {
              groups[i18n.t('dashboard.graphics.op2')] = []
            }
            groups[i18n.t('dashboard.graphics.op1')].push(opt1)
            groups[i18n.t('dashboard.graphics.op4')].push(opt2)
            groups[i18n.t('dashboard.graphics.op2')].push(opt3)
          }

          if (this.question.type === 'enum') {
            const results = {}
            dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
              if (indexOfMe >= 4) {
                if (!results[overtimeHeader]) {
                  results[overtimeHeader] = { key: overtimeHeader, doc_count: 0 }
                }
                results[overtimeHeader].doc_count = item[indexOfMe] || 0
                return false
              }

              Object.keys(results).forEach(currentKey => {
                if (!groups[currentKey]) {
                  groups[currentKey] = []
                }
                groups[currentKey].push(results[currentKey].doc_count)
              })
            })
            Object.keys(results).forEach(currentKey => {
              if (!groups[currentKey]) {
                groups[currentKey] = []
              }
              groups[currentKey].push(results[currentKey].doc_count)
            })
          }
        })

        graphicValues = []
        for (const groupName in groups) {
          graphicValues.push({ group: groupName, doc_count: groups[groupName] })
        }

        optionsValues = {
          responsive: true,
          tooltips: {
            backgroundColor: '#227799',
            bodyFontFamily: this.fontFamily,
            callbacks: {
              afterBody: function (tooltipItem) {
                const value = parseFloat(tooltipItem.value)
                return value
              }
            }
          },
          legend: {
            display: this.hideOrShow,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 8,
              boxWidth: 9,
              fontFamily: this.fontFamily,
              fontSize: this.fontSize,
              fontColor: '#939393'
            }
          },
          pointStyle: 'circle',
          lineTension: 1,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                precision: 0,
                padding: 25
              }
            }],
            xAxes: [{
              ticks: {
                display: true
              }
            }]
          }
        }
        lines = graphicValues.map((line, index) => {
          const colour = this.cardData.colors[index]
          const newLine = {
            label: line.group,
            data: line.doc_count,
            borderColor: colour,
            pointBorderColor: colour,
            pointBackgroundColor: colour,
            backgroundColor: ['rgba(255, 255, 255, .2)'],
            borderWidth: 2
          }
          return newLine
        })
        // Separar por grupos - END
      } else if (this.question.type === 'matrix') {
        const results = {}
        if (!dataTime.overtime) {
          return false
        }
        dataTime.overtime.forEach((item, i) => {
          if (i <= 0 || dataTime.overtime[i][0] === null || item[3] === null) {
            return false
          }
          const time = formatters.formatDateByLangAndFixTimeZone(dataTime.overtime[i][0], this.lang, formatDate)
          labels.push(time)
          dataTime.overtime[0].forEach(async (overtimeHeader, indexOfMe) => {
            if (indexOfMe >= 3) {
              const findLabel = await window._.find(this.question.subjects, { _id: overtimeHeader })
              if (findLabel) {
                if (!results[findLabel.label]) {
                  results[findLabel.label] = { key: findLabel.label, doc_count: 0 }
                }
                const value = item[indexOfMe] === 0 ? 0 : item[indexOfMe] / 10
                results[findLabel.label].doc_count = value
                return false
              }
            }
          })

          Object.keys(results).forEach(currentKey => {
            if (!groups[currentKey]) {
              groups[currentKey] = []
            }
            groups[currentKey].push(results[currentKey].doc_count)
          })
        })

        graphicValues = []
        for (const groupName in groups) {
          graphicValues.push({ group: groupName, doc_count: groups[groupName] })
        }

        optionsValues = {
          responsive: true,
          tooltips: {
            backgroundColor: '#227799',
            callbacks: {
              afterBody: function (tooltipItem) {
                const value = parseFloat(tooltipItem.value)
                return value
              }
            }
          },
          legend: {
            display: this.hideOrShow,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 20
            }
          },
          pointStyle: 'circle',
          lineTension: 1,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                precision: 0,
                padding: 25
              }
            }],
            xAxes: [{
              ticks: {
                display: true
              }
            }]
          }
        }
        lines = graphicValues.map((line, index) => {
          const colour = this.colours[index]
          const newLine = {
            label: line.group,
            data: line.doc_count,
            borderColor: colour,
            pointBorderColor: colour,
            pointBackgroundColor: colour,
            backgroundColor: ['rgba(255, 255, 255, .2)'],
            borderWidth: 2
          }
          return newLine
        })
      } else {
        // gráfico de média dos valores, somando item.average
        const valueGroup = []
        let customTooltip = {}
        if (this.rankType === 'health') {
          labels = dataTime.overtime.slice(1).map(item => formatters.formatDateByLangAndFixTimeZone(item[0], this.lang, formatDate))
          graphicValues = dataTime.overtime.slice(1).map(item => Math.round(item[2]))
          customTooltip = { enabled: true }
        }
        if (this.rankType === 'question') {
          dataTime.forEach((item, index) => {
            const value = Math.round(item.average * 100) / 100
            const time = formatters.formatDateByLangAndFixTimeZone(item.from, this.lang, formatDate)
            graphicValues.push(value)
            labels.push(time)
            valueGroup[index] = item.valueGroup
          })
          customTooltip = {
            enabled: false,
            custom: function (tooltipModel) {
              var position = this._chart.canvas.getBoundingClientRect()
              tooltipMaker.makeCustomTooltip(tooltipModel, lines, position, lines[0].pointBackgroundColor)
            }
          }
        }
        lines = [{
          label: i18n.t('dashboard.graphics.average'),
          data: graphicValues,
          borderColor: this.cardData.colors[1],
          pointBorderColor: this.cardData.colors[0],
          pointBackgroundColor: this.cardData.colors[0],
          backgroundColor: ['rgba(255, 255, 255, .2)'],
          borderWidth: 2
        }]
        optionsValues = {
          tooltips: customTooltip,
          responsive: true,
          legend: {
            display: this.hideOrShow,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 20
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                precision: 0
              }
            }],
            xAxes: [{
              ticks: {
                display: true
              }
            }]
          }
        }
      }

      if (this.question.type === 'enum') {
        optionsValues.legend.position = 'right'
        optionsValues.legend.rtl = true
      }

      const chartData = {
        type: 'line',
        data: {
          labels: labels,
          datasets: lines
        },
        options: optionsValues
      }
      if (chartData.data.datasets) {
        const ctx = document.getElementById(this.canvasId)
        this.currentChart = await new window.Chart(ctx, {
          type: chartData.type,
          data: chartData.data,
          options: chartData.options,
          plugins: [{
            afterLayout: function (chart) {
              chart.legend.legendItems.map(label => {
                const formattedLabel = label.text.length > 40 ? label.text.slice(0, 50) + '...' : label.text
                label.text = formattedLabel
              })
            }
          }]
        })
        if (this.question.type === 'matrix') {
          this.currentChart.canvas.parentNode.style.margin = '0 auto'
        }
        return this.currentChart
      }
    }
  }
}
</script>

<style lang="sass">

</style>
