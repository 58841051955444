<template>
  <div class="container-card-tweened">
    <app-heading level="h3" class="title-number-card-tweened">{{ formatNumber }}</app-heading>
    <app-heading class="subtitle-card-tweened" level="h6">{{ formatLabel }}</app-heading>
  </div>
</template>
<script>
import appHeading from '@binds-tech/binds-design-system/src/components/Typography/Heading'
import { gsap } from 'gsap'

export default {
  name: 'TweenedNumber',
  props: {
    number: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    formula: {
      type: String,
      default: null
    }
  },
  components: {
    appHeading
  },
  computed: {
    formatLabel () {
      return this.label.charAt(0).toUpperCase() + this.label.slice(1)
    },
    formatNumber () {
      switch (this.formula) {
        case 'min':
          return `${this.tweenedNumber.toFixed(2)} min` // 1.20 min
        case 'percent':
          return `${this.tweenedNumber.toFixed(this.number >= 100 ? 0 : 2)}%` // 100% is a whole number
        default:
          return this.tweenedNumber.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') // 1.000.000
      }
    }
  },
  data () {
    return {
      tweenedNumber: 0
    }
  },
  watch: {
    number () {
      this.tweenNumber()
    }
  },
  mounted () {
    this.tweenNumber()
  },
  methods: {
    tweenNumber () {
      gsap.to(this.$data, {
        tweenedNumber: this.number,
        duration: 2,
        ease: 'power4.out'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.container-card-tweened {
  min-width: 110px;
  height: 70px;
  padding: 5px 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  background: #ffffff;
  border-radius: 5px;
  .title-number-card-tweened {
    font-size: 20px;
    color: #454548 !important;
    margin: 0 !important;
  }
  .subtitle-card-tweened {
    color: #454548 !important;
    margin: 0 !important;
    font-size: 15px;
  }
}
</style>
