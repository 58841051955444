<template>
  <div class="container-count-brands" :style="{'background': `${color}90` }">
    <div class="counter-brands" v-if="!hasCount" :style="{'background': color, 'width': widthCount }"></div>
    <i class="material-icons count-brand-icon" :style="{'color': hasCount ? color : '#ffffff' }">{{ icon }}</i>
    <app-heading level="h5" class="title-count-brand" :style="{'color': hasCount ? `${color} !important` : '#ffffff !important' }">{{ $t(name) }}</app-heading>
    <app-heading level="h5" v-if="hasCount" class="count-brand" :style="{'color': `${color} !important` }">{{ searched }}</app-heading>
    <app-heading level="h5" v-else-if="max" class="count-brand" :style="{'color': '#ffffff !important' }">{{ total }} / {{ max }}</app-heading>
  </div>
</template>

<script>
export default {
  name: 'CountBrands',
  props: ['hasCount', 'max', 'name', 'icon', 'color', 'total', 'searched'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
    }
  },
  mounted () {

  },
  computed: {
    widthCount () {
      return `${this.total / this.max * 100}%`
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
.container-count-brands {
  user-select: none;
  position: relative;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  display: flex;
  margin-bottom: 0.4em;
  align-items: center;
  .counter-brands {
    transition: width 0.5s;
    height: 100%;
    border-radius: 5px;
  }
  .count-brand-icon {
    position: absolute;
    left: 10px;
    top: 15%;
    font-size: 30px;
  }
  .title-count-brand {
    position: absolute;
    left: 45px;
    top: 32%;
    font-size: 16px;
    font-weight: bold !important;
  }
  .count-brand {
    position: absolute;
    right: 40px;
    top: 32%;
    font-size: 16px;
    font-weight: bold !important;
  }
}
</style>
