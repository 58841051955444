 <template>
  <div style="height: 100vh; width: 100vw; position: fixed; top: 0; left: 0; z-index: 99; background: transparent;" @click="handleClickOutsideSidebar($event)">
    <div v-if="openBar && group">
      <div class="container-roles">
        <div class="sidebar-roles">
          <div class="side-roles-header">
            <div class="delete-roles">
              <button v-on:click="showModaldelete = true" v-if="!isEmpty">
                <app-icon name="trash" size="xs" color="white" v-title="$t('role.delete')" title-placement="top" />
            </button>
            </div>
            <div class="details-roles">
              <app-heading level="h4">{{ group.name }}</app-heading>
            </div>
            <div class="sidebar-close">
              <button @click="canBeClosed = true;showCloseModal = true">
                <app-textstyle  size="size-xl">×</app-textstyle>
              </button>
            </div>
          </div>
          <div class="group-config">
            <div class="group-name">
              <app-heading level="h7">{{$t('role.name_group')}}:</app-heading>
              <app-form-input v-model="group.name" border-bottom :placeholder="this.$i18n.t('role.new')"></app-form-input>
            </div>
            <div class="groups-roles">
              <div class="card-list" :key="group.name">
                <CardWithPagination v-if="!isEmpty"
                :contents="{
                  type: 'users',
                  title: 'role.user_title',
                  subtitle: '',
                  itemList: userList,
                  group: group }"
                  />
                <CardWithPagination
                  :contents="{
                  type: 'collections',
                  title: 'role.can_send_title',
                  subtitle: 'role.can_send_description',
                  itemList: collectors,
                  objectsId: collectorsId,
                  group: group }"
                  />
                <CardWithPagination
                  :contents="{
                  type: 'surveys',
                  title: 'role.can_edit_title',
                  subtitle: 'role.can_edit_description',
                  itemList: surveys,
                  objectsId: surveysId,
                  group: group }"
                  />
              </div>
              <CardFilterRole :key="key" :query="filterQuery.$and" @add-field-meta="addFieldRow($event)" />
              <div class="btn-field">
                <app-button v-if="isEmpty" class="btn-create" v-on:click="createRoles()">{{ $t("role.create") }}</app-button>
                <app-button v-if="!isEmpty" class="btn-update" v-on:click="submitRoles()">{{ $t("role.update") }}</app-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-modal
        v-model="showModaldelete"
        :title="this.$t('role.delete_group_confirm')"
        lightbox
        close
        class="close-modal-roles"
        :primary-button="this.$t('account_config.delete_confirm.yes')"
        @primaryButtonClick="groupDelete()"
        :secondary-button="this.$t('account_config.delete_confirm.no')"
        @secondaryButtonClick="showModaldelete = false"
      ></app-modal>
      <theme-style>
        .btn-field > .btn-delete, .btn-create {
          background-color: {{account.account.theme.colors.primary || '#673ab7'}} !important;
          color:{{ (account.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
        }
        .btn-field > .btn-update {
          background-color: {{account.account.theme.colors.accent || '#673ab7'}} !important;
          color:{{ (account.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
        }
      </theme-style>
    </div>
    <app-modal
      v-model="showCloseModal"
      :title="$t('survey.exit_whithout_save')"
      lightbox
      close
      class="modal-default-primary"
      :primary-button="this.$t('account_config.delete_confirm.yes')"
      @primaryButtonClick="closeSideBar()"
      :secondary-button="this.$t('account_config.delete_confirm.no')"
      @secondaryButtonClick="canBeClosed = false; showCloseModal = false"
    >
      <app-heading level="h7">{{ $t('survey.exit_message') }}</app-heading>
    </app-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { roleService } from '@/_services'
import CardWithPagination from '../SideBarConfig/CardWithPagination.vue'
import CardFilterRole from './CardFilterRole.vue'

export default {
  name: 'SideBarRoles',
  props: ['surveys', 'collectors', 'userList', 'currentGroup'],
  components: {
    CardWithPagination,
    CardFilterRole,
    'app-icon': () => import('@binds-tech/binds-design-system/src/components/Icon/Icon'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle')
  },
  data () {
    return {
      key: 0,
      openBar: false,
      showModaldelete: false,
      isEmpty: false,
      collectorsId: [],
      surveysId: [],
      operatorPort: [
        { value: '$eq', label: this.$i18n.t('advanced_filters.fields.is_equal') },
        { value: '$ne', label: this.$i18n.t('advanced_filters.fields.is_different') },
        { value: '$exists', label: this.$i18n.t('advanced_filters.fields.is_define'), boolean: true },
        { value: '$exists', label: this.$i18n.t('advanced_filters.fields.is_not_define'), boolean: false }
      ],
      filterQuery: {},
      group: {},
      emptyFilter: {
        model: {
          value: 'metadata',
          label: 'Metadata'
        },
        operator: {
          value: '$eq',
          label: this.$i18n.t('advanced_filters.fields.is_equal')
        },
        'seed.metadata.metadata': { $eq: '' }
      },
      fieldKey: 1,
      canBeClosed: false,
      showCloseModal: false
    }
  },
  computed: {
    ...mapGetters({
      acc: 'account/getUser'
    }),
    account () {
      const user = this.$store.getters['account/getUser']
      return user
    }
  },
  created () {
    const onEscape = (e) => {
      if (e.keyCode === 27) {
        this.showCloseModal = true
      }
    }

    document.addEventListener('keydown', onEscape)

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape)
    })
  },
  async mounted () {
    this.openBar = false
    this.getDataGroups()

    if (this.currentGroup !== null) {
      this.isEmpty = false

      this.$root.$emit('loading-done')
      this.openBar = true
    } else {
      this.isEmpty = true
      this.group.name = ''
      this.group.filters = {
        Collector: { $and: [{ _id: { $in: [] } }] },
        Survey: { $and: [{ _id: { $in: [] } }] }
      }

      this.filterQuery = { $and: [this.emptyFilter] }
      this.$root.$emit('loading-done')
      this.openBar = true
    }

    this.$root.$on('items-for-update', group => { this.group = group })
  },
  methods: {
    addFieldRow (e) {
      this.filterQuery.$and.push(e)
      this.key++
    },
    async getDataGroups () {
      if (this.currentGroup?._id) {
        const result = await roleService.getGroup(this.currentGroup._id)

        if (result) {
          this.group = result
          this.surveysId = result.filters.Survey ? JSON.parse(result.filters.Survey).$and[0]._id.$in : ''
          this.collectorsId = result.filters.Collector ? JSON.parse(result.filters.Collector).$and[0]._id.$in : ''

          if (result.query?.$and) {
            result.query = this.group.query.$and.map(item => {
              const [key] = Object.keys(item)
              if (!key.includes('seed.metadata')) return
              const metadata = this.account.account.fields.find(field => field.key === key.split('.').pop())
              const operator = Object.keys(item[key])[0]

              const objRole = {
                model: {
                  value: metadata.key,
                  label: metadata.label
                },
                operator: {
                  value: operator,
                  label: this.operatorPort.find(e => e.value === operator).label
                },
                ...item,
                value: item[key][operator]
              }

              if (operator === '$exists') {
                const itemExists = this.operatorPort.find(e => { return e.boolean === item[key][operator] })
                objRole.operator.label = itemExists.label
              }

              return objRole
            })
          }

          if (result.query.$and) this.filterQuery = { ...result.query }
          else if (result.query[0]) this.filterQuery.$and = [...result.query]
          else this.filterQuery = { $and: [this.emptyFilter] }
        } else {
          this.filterQuery = { $and: [this.emptyFilter] }
        }
      }
    },
    handleClickOutsideSidebar (event) {
      if (event.target._prevClass === 'container-roles') this.showCloseModal = true
      else return null
    },
    closeSideBar () {
      this.$emit('close-sidebar-roles')
    },
    formatResponse (filter) {
      return structuredClone(filter).map(filterObj => {
        if (filterObj.model) delete filterObj.model
        if (filterObj.operator) delete filterObj.operator
        delete filterObj.value
        return filterObj
      })
    },
    async submitRoles () {
      const filters = this.filterQuery.$and === undefined ? this.filterQuery.$and = [this.emptyFilter] : this.filterQuery.$and
      let emptyFilter = false

      filters.map(o => {
        delete o.value
        Object.keys(o).forEach(item => {
          if (item === 'seed.metadata.metadata') {
            emptyFilter = true
          }
        })
      })
      if (emptyFilter) {
        this.group.query = {}
      } else {
        this.group.query = { $and: this.formatResponse(filters) }
      }
      const update = await roleService.updateGroup(this.group)
      if (update) {
        this.$root.$emit('groups_update_item', update)
      }
      this.$store.commit('alerts/alert', {
        message: this.$i18n.t('dashboard.success_save_config'),
        position: 'bottomRight',
        showAlert: true
      })
      setTimeout(() => {
        this.$emit('close-sidebar-roles')
      }, 800)
    },
    async createRoles () {
      const filter = this.filterQuery.$and
      let emptyFilter = false
      filter.map(o => {
        Object.keys(o).forEach(item => {
          if (item === 'seed.metadata.metadata') {
            emptyFilter = true
          }
        })
      })
      if (emptyFilter) {
        this.group.query = {}
      } else {
        this.group.query = { $and: this.formatResponse(filter) }
      }

      const collectorQuery = typeof this.group.filters.Collector === 'string' ? this.group.filters.Collector : JSON.stringify(this.group.filters.Collector)
      const surveyQuery = typeof this.group.filters.Survey === 'string' ? this.group.filters.Survey : JSON.stringify(this.group.filters.Survey)
      this.group.filters = {
        Collector: collectorQuery,
        Survey: surveyQuery
      }

      const create = await roleService.createGroup(this.group)
      if (create) {
        this.$root.$emit('groups_create', create)
        this.group = create
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('dashboard.success_save_config'),
          position: 'bottomRight',
          showAlert: true
        })
      }

      setTimeout(() => {
        this.$emit('close-sidebar-roles')
      }, 800)
    },
    async groupDelete () {
      const deleteGroup = await roleService.deleteGroup(this.group)
      if (deleteGroup === undefined) {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('role.error_group'),
          position: 'bottomRight',
          showAlert: true
        })
      } else {
        this.$root.$emit('groups_delete', this.group)
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('role.deleted'),
          position: 'bottomRight',
          showAlert: true
        })
      }
      this.showModaldelete = false

      setTimeout(() => {
        this.$emit('close-sidebar-roles')
      }, 800)
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
.close-modal-roles {
  div.modal__wrapper {
    h4 {
      color: var(--primary-color) !important;
    }
    &::before {
      background-color: var(--primary-color) !important;
    }
  }
}
.container-roles {
  width: 100vw;
  height: 100vh;
  position:fixed;
  top: 0;
  display: flex;
  justify-content: right;
  align-items: center;
  z-index: 999;
  .sidebar-roles {
    width: 450px;
    height: 100vh;
    background: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
    border-radius: 5px 0 0 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .side-roles-header {
      width: 99%;
      min-height: 15%;
      border-bottom: 0.5px solid #cacaca;
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;
      .details-roles {
        height: 100%;
        width: 70%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h4 {
          font-size: 19px;
        }
      }
      .delete-roles {
        width: 15%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding: 0 0 5px 3px;
        .b-icon {
          font-size: 20px;
        }
      }
      .sidebar-close {
        width: 15%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        button {
          position: absolute;
          top: -10px;
          right: 5px;
        }
      }
    }
    .group-config {
      width: 99%;
      margin-top: 10px;
      padding: 0 5px;
      .group-name {
        margin-bottom: 10px;
        .text-field__input {
          margin: 5px 0;
          border: none;
          border: 1px solid #cacaca;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
          border-radius: 5px;
          padding-left: 5px;
          height: 40px;
          input {
            color: rgb(63, 51, 86) !important;
            height: 90%;
            font-size: 17px;
            font-weight: 400;
          }
        }
      }
      .groups-roles {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .card-list {
          width: 100%;
        }
        .btn-field {
          width: 100%;
          margin: 20px 0;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          button {
            margin: 0;
            padding: 5px 10px;
            font-size: 15px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
