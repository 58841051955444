// import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/translations/i18n/lang/'

// Vue.use(VueI18n)

function translateThisText (locale, key) {
  const initial = {
    'pt-br': 'pt-BR',
    'en-us': 'en-US',
    'es-cl': 'es-CL'
  }
  const i18n = new VueI18n({
    locale: initial[locale], // set locale
    fallbackLocale: initial[locale],
    messages // set locale messages
  })
  return i18n.t(key)
}

export const translator = { translateThisText }
