import { callApiService } from '@/_services'
const API_URL = process.env.VUE_APP_URL

export const autoMessage = {
  getMessages,
  deleteMessage,
  createMessage,
  editMessage
}

/**
 * Get all automatics messages from tickets
 * @returns {Promise<Object>} - Uma promessa que resolve em um objeto contendo os dados da consulta.
 */
async function getMessages () {
  const url = `${API_URL}ticket-message`
  const result = await callApiService.callApi(url, 'get')
  return result
}

/**
 * @param {String} id - String contendo o ID da mensagem.
 * @returns {Promise<Object>} - Retorna um objeto vazio ao deletar a mensagem.
 */
async function deleteMessage (id) {
  const url = `${API_URL}ticket-message/${id}`
  const result = await callApiService.callApi(url, 'delete')
  return result
}

/**
 * @param {String} body.title - String contendo o título da mensagem.
 * @param {String} body.message - String contendo a mensagem.
 * @returns {Promise<Object>} - Uma promessa que resolve em um objeto contendo os dados do item criado.
 */
async function createMessage (body) {
  const url = `${API_URL}ticket-message`
  const result = await callApiService.callApi(url, 'post', body)
  return result
}

/**
 * @param {String} body.title - String contendo o título da mensagem.
 * @param {String} body.message - String contendo a mensagem.
 * @returns {Promise<Object>} - Uma promessa que resolve em um objeto contendo os dados do item alterado.
 */
async function editMessage (message) {
  const body = {
    title: message.title,
    message: message.message
  }

  const url = `${API_URL}ticket-message/${message._id}`
  const result = await callApiService.callApi(url, 'put', body)
  return result
}
