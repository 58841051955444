<template>
  <div class="container-report-insights">
    <ShimmerLoading :isHeight="'19vh'" :quantity="4" v-if="loading" />
    <div class="section-report-data" v-if="!loading && data">
      <div class="header-report-data">
        <div class="to-export-reports">
          <img src="../../../../public/img/export-bg-reports.jpg" class="img-bg-export">
          <img class="image-export-reports" :src="account.theme.mainLogo" alt="">
          <p class="title-export-reports">INSIGHTS & REPORTS</p>
        </div>
        <p class="title-report">{{ data.name }}</p>
        <p class="label-general-resume">"{{ data.generalResume }}"</p>
        <div class="footer-to-export">
          <img src="../../../../public/img/binds-small-logo.png" class="logo-binds-export">
        </div>
      </div>
      <div class="content-report-data">
        <cardMetadataInsight v-for="(meta, idx) in metadatasConverted" :key="idx" :data="meta" />
      </div>
    </div>
    <NoData v-if="!data && !loading" />
  </div>
</template>

<script>
import cardMetadataInsight from './cardMetadataInsight.vue'
import ShimmerLoading from '../../customLoading/ShimmerLoading.vue'
import NoData from './NoData.vue'

export default {
  name: 'ReportInsights',
  props: ['dataBase', 'loading'],
  components: {
    cardMetadataInsight,
    ShimmerLoading,
    NoData
  },
  mounted () {
  },
  data () {
    return {
    }
  },
  computed: {
    data () { return this.dataBase },
    filters () { return this.$store.getters['filtersInsight/getFilters'] },
    account () { return this.$store.getters['account/getAccount'] },
    metadatasConverted () {
      const metas = []

      for (const key in this.data.metadatas) {
        let name = key
        const values = []

        if (key === '_binds_sentiment') { name = 'Sentimento' }

        for (const keyValue in this.data.metadatas[key].values) {
          values.push({
            name: keyValue,
            ...this.data.metadatas[key].values[keyValue]
          })
        }

        metas.push({
          title: name,
          summary: this.data.metadatas[key].summary,
          values
        })
      }
      return metas
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
}
.container-report-insights {
  width: 100%;
  height: 100%;
  .section-report-data {
    width: 100%;
    height: 100%;
    .header-report-data {
      position: relative;
      width: 100%;
      min-height: 10%;
      padding: 0.5rem 0 1rem 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      .to-export-reports {
        position: relative;
        height: 150px;
        padding: 0 20px;
        display: none;
        align-items: center;
        justify-content: space-between;
        .img-bg-export {
          top: 0;
          left: 0;
          position: absolute;
          width: 100%;
          height: 100%;
        }
        .image-export-reports {
          height: 100px;
          z-index: 2;
        }
        .title-export-reports {
          font-size: 35px;
          font-weight: bold;
          color: #ffffff;
          z-index: 2;
        }
      }
      .title-report {
        font-size: 2.2rem;
        font-weight: bold;
        color: #454548;
      }
      .label-general-resume {
        font-size: 1.1rem;
        font-weight: 300;
        line-height: 1.3rem;
        padding: 0.5rem;
        border-radius: 0.3rem;
        font-style: italic;
        background: #9ac6ff1a;
      }
      .footer-to-export {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: none;
        align-items: center;
        justify-content: center;
        .logo-binds-export {
          opacity: 0.5;
          width: 60px;
        }
      }
    }
    .content-report-data {
      width: 100%;
      min-height: 65%;
      overflow-y: auto;
      padding: 1rem 0 0.5rem 0;
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
    }
  }
}
</style>
