export const generateColors = {
  generateColor,
  getGrayColors
}

function generateColor (label) {
  const colors = [
    { label: 'positivo', value: '#9FCD35' },
    { label: 'neutro', value: '#F8C43D' },
    { label: 'negativo', value: '#EA484D' },
    { label: 'elogio', value: '#03A700' },
    { label: 'sugestao', value: '#2EDB2A' },
    { label: 'critica', value: '#FF6565' },
    { label: 'alegria', value: '#03A700' },
    { label: 'confianca', value: '#2EDB2A' },
    { label: 'empatia', value: '#6DDF6B' },
    { label: 'gratidao', value: '#9CF49A' },
    { label: 'surpresa', value: '#CDFFCC' },
    { label: 'nenhuma', value: '#E5E5E5' },
    { label: 'tristeza', value: '#CBCBCB' },
    { label: 'medo', value: '#FFCB44' },
    { label: 'raiva', value: '#EF675F' },
    { label: 'hostilidade', value: '#FF6363' },
    { label: 'frustracao', value: '#E64848' },
    { label: 'aversao', value: '#E41C0F' }
  ]

  const findColor = colors.find(e => { return e.label === label })

  if (findColor) {
    return findColor.value
  } else {
    return getGrayColors()
  }
}

function getGrayColors () {
  const grayColors = [
    '#00000095',
    '#00000090',
    '#00000085',
    '#00000080',
    '#00000075',
    '#00000070',
    '#00000065',
    '#00000060',
    '#00000055',
    '#00000050',
    '#00000045',
    '#00000040',
    '#00000035'
  ]

  const randomIndex = Math.floor(Math.random() * grayColors.length)
  return grayColors[randomIndex]
}
