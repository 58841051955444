var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-like-graphic",style:({
    '--firstColor': _vm.cardData?.colors[0] ? _vm.cardData.colors[0] : '#51ad59',
    '--firstColorOpacity': _vm.cardData?.colors[0] ? `${_vm.cardData.colors[0]}50` : '#73E83140',
    '--secondColor': _vm.cardData?.colors[1] ? _vm.cardData.colors[1] : '#ea4922',
    '--secondColorOpacity': _vm.cardData?.colors[1] ? `${_vm.cardData.colors[1]}50` : '#ea492240',
    })},_vm._l((_vm.items),function(item,idx){return _c('div',{key:idx,staticClass:"content-like-graphic"},[_c('div',{staticClass:"content-label"},[_c('app-heading',{directives:[{name:"title",rawName:"v-title",value:(item.label),expression:"item.label"}],style:({'white-space': item.label.length > 134 ? 'nowrap' : 'wrap'}),attrs:{"level":"h6","title-max-width":"320"}},[_vm._v(" "+_vm._s(item.label)+" ")])],1),_c('div',{staticClass:"percentage-bar"},[_c('div',{staticClass:"icons"},[_c('div',{staticClass:"negative"},[_c('i',{staticClass:"material-icons down"},[_vm._v("thumb_down")]),_c('app-heading',{staticClass:"label-down",attrs:{"level":"h6"}},[_vm._v(_vm._s(parseFloat(item.percentageDislike.toFixed(1)))),_c('span',[_vm._v("%")])])],1),_c('div',{staticClass:"content-details"},[_c('div',{staticClass:"details-items"},[_c('app-heading',{staticClass:"dislike",attrs:{"level":"h8"}},[_vm._v("Dislikes")]),_c('app-heading',{staticClass:"dislike",attrs:{"level":"h8"}},[_vm._v(_vm._s(item.qtyDislike))])],1),_c('div',{staticClass:"details-items"},[_c('app-heading',{staticClass:"like",attrs:{"level":"h8"}},[_vm._v("Likes")]),_c('app-heading',{staticClass:"like",attrs:{"level":"h8"}},[_vm._v(_vm._s(item.qtyLike))])],1)]),_c('div',{staticClass:"positive"},[_c('i',{staticClass:"material-icons up"},[_vm._v("thumb_up")]),_c('app-heading',{staticClass:"label-up",attrs:{"level":"h6"}},[_vm._v(_vm._s(parseFloat(item.percentageLike.toFixed(1)))),_c('span',[_vm._v("%")])])],1)]),_c('div',{staticClass:"progressive-bar"},[_c('div',{staticClass:"bar-down",style:({
        'width': item.percentageDislike + '%',
        'border-radius': item.percentageLike == 100 ? '35px' : '35px 0 0 35px',
        'display': item.percentageLike == 100 ? 'none' : 'block'})}),_c('div',{staticClass:"bar-up",style:({
        'width': item.percentageLike + '%',
        'border-radius': item.percentageDislike == 100 ? '35px' : '0 35px 35px 0',
        'display': item.percentageDislike == 100 ? 'none' : 'block'})},[_c('div',{staticClass:"ball"})])])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }