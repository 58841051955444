<template>
  <div class="container">
    <canvas
      :id="question.canvasId"
      class="myCanvas"
      width="100%"
    ></canvas>
    <bar-with-metric
      v-if="['nps', 'nes', 'csat', 'ces', 'nvs'].indexOf(question.type) > -1"
      :data="data"
      :question="cardData.question"
      :cardData="cardData"
    ></bar-with-metric>
    <div class="setLabel" style="margin-top: 4px !important;">
      <div v-if="question.type === 'nps'">
        <span v-if="valueOfGraphic < -50 "> {{ $t('dashboard.gauge_analytics.metrics.NPS.bad2') }} {{ $t('dashboard.gauge_analytics.labels.bad') }}</span>
        <span v-if="valueOfGraphic < 0 && valueOfGraphic >= -50 "> {{ $t('dashboard.gauge_analytics.metrics.NPS.bad1') }} {{ $t('dashboard.gauge_analytics.labels.bad') }}</span>
        <span v-if="valueOfGraphic >= 0 && valueOfGraphic < 50"> {{ $t('dashboard.gauge_analytics.metrics.NPS.good') }} {{ $t('dashboard.gauge_analytics.labels.good') }}</span>
        <span v-if="valueOfGraphic >= 50 && valueOfGraphic < 75"> {{ $t('dashboard.gauge_analytics.metrics.NPS.great') }} {{ $t('dashboard.gauge_analytics.labels.great') }}</span>
        <span v-if="valueOfGraphic >= 75"> {{ $t('dashboard.gauge_analytics.metrics.NPS.excellent') }} {{ $t('dashboard.gauge_analytics.labels.excellent') }}</span>
      </div>
       <div v-if="question.type === 'nes'">
        <span v-if="valueOfGraphic >= -100 && valueOfGraphic <= -50"> {{ $t('advanced_filters.fields.much_effort') }}</span>
        <span v-if="valueOfGraphic > -50 && valueOfGraphic  <= 50"> {{ $t('advanced_filters.fields.expected_effort') }}</span>
        <span v-if="valueOfGraphic > 50 && valueOfGraphic <= 100"> {{ $t('advanced_filters.fields.less_effort') }}</span>
      </div>
      <div v-if="question.type === 'ces'">
        <span v-if="valueOfGraphic >= 0 && valueOfGraphic < 4"> {{ $t('advanced_filters.fields.much_effort') }}</span>
        <span v-if="valueOfGraphic >= 4 && valueOfGraphic  < 6"> {{ $t('advanced_filters.fields.expected_effort') }}</span>
        <span v-if="valueOfGraphic >= 6"> {{ $t('advanced_filters.fields.less_effort') }}</span>
      </div>
      <div v-if="question.type === 'csat'">
        <div v-if="question.ui === '5num'">
          <span v-if="valueOfGraphic < 30 "> {{ $t('dashboard.gauge_analytics.metrics.CSAT.oneTo5.bad') }} {{ $t('dashboard.gauge_analytics.labels.bad') }}</span>
          <span v-if="valueOfGraphic >= 30 && valueOfGraphic < 50"> {{ $t('dashboard.gauge_analytics.metrics.CSAT.oneTo5.good') }} {{ $t('dashboard.gauge_analytics.labels.good') }}</span>
          <span v-if="valueOfGraphic >= 50 && valueOfGraphic < 80"> {{ $t('dashboard.gauge_analytics.metrics.CSAT.oneTo5.great') }} {{ $t('dashboard.gauge_analytics.labels.great') }}</span>
          <span v-if="valueOfGraphic >= 80"> {{ $t('dashboard.gauge_analytics.metrics.CSAT.oneTo5.excellent') }} {{ $t('dashboard.gauge_analytics.labels.excellent') }}</span>
        </div>
        <div v-if="question.ui === '10num' && cardData.type !== 'nes'">
          <span v-if="valueOfGraphic < 30 "> {{ $t('dashboard.gauge_analytics.metrics.CSAT.zeroTo10.bad') }} {{ $t('dashboard.gauge_analytics.labels.bad') }}</span>
          <span v-if="valueOfGraphic >= 30 && valueOfGraphic < 50"> {{ $t('dashboard.gauge_analytics.metrics.CSAT.zeroTo10.good') }} {{ $t('dashboard.gauge_analytics.labels.good') }}</span>
          <span v-if="valueOfGraphic >= 50 && valueOfGraphic < 80"> {{ $t('dashboard.gauge_analytics.metrics.CSAT.zeroTo10.great') }} {{ $t('dashboard.gauge_analytics.labels.great') }}</span>
          <span v-if="valueOfGraphic >= 80"> {{ $t('dashboard.gauge_analytics.metrics.CSAT.zeroTo10.excellent') }} {{ $t('dashboard.gauge_analytics.labels.excellent') }}</span>
        </div>
      </div>
      <div v-if="question.type === 'nvs'">
        <span v-if="valueOfGraphic < -50 "> {{ $t('dashboard.gauge_analytics.metrics.NPS.bad2') }}: {{ $t('dashboard.gauge_analytics.labels_nvs.bad') }}</span>
        <span v-if="valueOfGraphic < 0 && valueOfGraphic >= -50 "> {{ $t('dashboard.gauge_analytics.metrics.NPS.bad1') }}: {{ $t('dashboard.gauge_analytics.labels_nvs.moderate') }}</span>
        <span v-if="valueOfGraphic >= 0 && valueOfGraphic < 50"> {{ $t('dashboard.gauge_analytics.metrics.NPS.good') }}: {{ $t('dashboard.gauge_analytics.labels_nvs.good') }}</span>
        <span v-if="valueOfGraphic >= 50 && valueOfGraphic < 75"> {{ $t('dashboard.gauge_analytics.metrics.NPS.great') }}: {{ $t('dashboard.gauge_analytics.labels_nvs.great') }}</span>
        <span v-if="valueOfGraphic >= 75"> {{ $t('dashboard.gauge_analytics.metrics.NPS.excellent') }}: {{ $t('dashboard.gauge_analytics.labels_nvs.excellent') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { canvasMaker } from '@/_helpers'
import BarWithMetric from './BarWithMetric.vue'

export default {
  name: 'Gauge',
  props: {
    data: {
    },
    question: {
      type: Object
    },
    cardData: {
      type: Object
    }
  },
  components: { BarWithMetric },
  data () {
    return {
      valueOfGraphic: 0
    }
  },
  methods: {
    makeSpecialId () {
      this.question.canvasId = canvasMaker.makeCanvasId(this.question)
    }
  },
  created () {
    this.makeSpecialId()
  },
  mounted () {
    if (!this.data) return

    const myCanvas = document.getElementById(this.question.canvasId)
    // diâmetro do gauge inteiro (quanto de tela ele ocupa)
    myCanvas.width = 350
    myCanvas.height = 350

    // fatias a serem exibidas, sem  label pq serão setados depois
    const piecesOfPie = {
      0: 0,
      1: 0,
      2: 0,
      3: 0
    }

    /**
     * cria os pedaços de pizza do gráfico
     * @param {{ Object }} ctx recebe o contexto atual do que está sendo desenhado em getContext('2d')
     * @param {{ Number }} centerX posição horizontal de onde desejamos realizar o desenho
     * @param {{ Number }} centerY posição vertical de onde desejamos realizar o desenho
     * @param {{ Number }} radius define a proporção do gráfico
     * @param {{ Number }} startAngle define o ponto inicial da fatia num ângulo de 360º
     * @param {{ Number }} endAngle define o ponto final da fatia num ângulo de 360º
     * @param {{ String }} color define a cor da fatia
     */
    function drawPieSlice (ctx, centerX, centerY, radius, startAngle, endAngle, color) {
      ctx.fillStyle = color
      ctx.beginPath()
      ctx.moveTo(centerX, centerY)
      ctx.arc(centerX, centerY, radius, startAngle, endAngle)
      ctx.closePath()
      ctx.fill()
    }

    function drawArrow (fromX, fromy, toX, toY, options, question) {
      // variables to be used when creating the arrow
      const c = document.getElementById(question.canvasId)
      const ctx = c.getContext('2d')
      const headlength = 5
      const angle = Math.atan2(toY - fromy, toX - fromX)
      // starting path of the arrow from the start square to the end square and drawing the stroke
      ctx.beginPath()
      ctx.moveTo(fromX, fromy)
      ctx.lineTo(toX, toY)
      ctx.strokeStyle = '#666666'
      ctx.lineWidth = 10
      ctx.stroke()
      // starting a new path from the head of the arrow to one of the sides of the point
      ctx.beginPath()
      ctx.moveTo(toX, toY)
      ctx.lineTo(toX - headlength * Math.cos(angle - Math.PI / 7), toY - headlength * Math.sin(angle - Math.PI / 7))
      // path from the side point of the arrow, to the other side point
      ctx.lineTo(toX - headlength * Math.cos(angle + Math.PI / 7), toY - headlength * Math.sin(angle + Math.PI / 7))
      // path from the side point back to the tip of the arrow, and then again to the opposite side point
      ctx.lineTo(toX, toY)
      ctx.lineTo(toX - headlength * Math.cos(angle - Math.PI / 7), toY - headlength * Math.sin(angle - Math.PI / 7))
      // draws the paths created above
      ctx.strokeStyle = '#666666'
      ctx.lineWidth = 12
      ctx.stroke()
      ctx.fillStyle = '#666666'
      ctx.fill()
    }

    function drawSpecialArrow (options, question, range) {
      if (options.valueOfGraphic < range[0]) {
        drawArrow(180, 185, 119, 155, options, question)
        if (question.type === 'nes') {
          return 3
        } else {
          return 0
        }
      } else if (options.valueOfGraphic < range[1] && options.valueOfGraphic >= range[0]) {
        drawArrow(170, 160, 150, 120, options, question)
        if (question.type === 'nes') {
          return 2
        } else {
          return 1
        }
      } else if (options.valueOfGraphic >= range[1] && options.valueOfGraphic < range[2]) {
        drawArrow(180, 180, 200, 120, options, question)
        if (question.type === 'nes') {
          return 1
        } else {
          return 2
        }
      } else if (options.valueOfGraphic >= range[2]) {
        drawArrow(160, 180, 231, 155, options, question)
        if (question.type === 'nes') {
          return 0
        } else {
          return 3
        }
      }
    }

    function drawRange (ctx, labelX, labelY, range, positionX, positionY) {
      ctx.fillText(range[0], positionX[0], positionY[0])
      ctx.fillText(range[1], positionX[1], positionY[1])
      ctx.fillText(range[2], positionX[2], positionY[2])
      ctx.fillText(range[3], positionX[3], positionY[3])
      ctx.fillText(range[4], positionX[4], positionY[4])
    }

    /**
     * responsável por desenhar o gráfico
     * @param {{Object}} options contém os parâmetros para rendereziar o gráfico
     * @param {{Object}} question contém a questão atual que o gráfico será renderizado
     */
    const Piechart = function (options, question) {
      this.options = options
      this.canvas = options.canvas
      this.ctx = this.canvas.getContext('2d')
      this.colors = options.colors
      this.draw = function () {
        let colorIndex = 0
        let startAngle = 3.140
        let sliceAngle = 0
        for (let i = 0; i < Object.keys(this.options.data).length; i++) {
          sliceAngle = 1 * Math.PI * 25 / 100
          drawPieSlice(
            this.ctx,
            this.canvas.width / 2,
            this.canvas.height / 2,
            Math.min(this.canvas.width / 2.5, this.canvas.height / 2),
            startAngle,
            startAngle + sliceAngle,
            this.colors[colorIndex % this.colors.length]
          )
          startAngle += sliceAngle
          colorIndex++
        }

        if (this.options.doughnutHoleSize) {
          let centerColor = 0
          // draw a small stroke betwen the center of the circle
          drawPieSlice(
            this.ctx,
            this.canvas.width / 2,
            this.canvas.height / 2,
            this.options.doughnutHoleSize * Math.min(this.canvas.width / 3.5, this.canvas.height / 2),
            0,
            2 * Math.PI,
            'white'
          )
          if (question.type === 'csat') {
            if (question.ui === '5num') {
              centerColor = drawSpecialArrow(this.options, question, [30, 50, 80])
              this.options.valueOfGraphic = this.options.valueOfGraphic + '%'
            }
            if (question.ui === '10num') {
              centerColor = drawSpecialArrow(this.options, question, [30, 50, 80])
              this.options.valueOfGraphic = this.options.valueOfGraphic + '%'
            }
          }

          if (question.type === 'nvs') {
            if (this.options.valueOfGraphic <= -50) {
              centerColor = 0
              drawArrow(180, 185, 119, 155, this.options, question)
            } else if (this.options.valueOfGraphic < 0 && this.options.valueOfGraphic > -50) {
              centerColor = 1
              drawArrow(170, 160, 150, 120, this.options, question)
            } else if (this.options.valueOfGraphic >= 0 && this.options.valueOfGraphic < 50) {
              centerColor = 2
              drawArrow(180, 180, 200, 120, this.options, question)
            } else if (this.options.valueOfGraphic >= 50) {
              centerColor = 3
              drawArrow(160, 180, 230, 155, this.options, question)
            }
          }

          if (question.type === 'nes') {
            if (question.ui === '10num') {
              if (this.options.valueOfGraphic <= -50) {
                centerColor = 0
                drawArrow(180, 185, 119, 155, this.options, question)
              } else if (this.options.valueOfGraphic < 0 && this.options.valueOfGraphic > -50) {
                centerColor = 1
                drawArrow(170, 160, 150, 120, this.options, question)
              } else if (this.options.valueOfGraphic >= 0 && this.options.valueOfGraphic < 50) {
                centerColor = 2
                drawArrow(180, 180, 200, 120, this.options, question)
              } else if (this.options.valueOfGraphic >= 50) {
                centerColor = 3
                drawArrow(160, 180, 230, 155, this.options, question)
              }
            }
          }

          if (question.type === 'nps') {
            if (this.options.valueOfGraphic <= -50) {
              centerColor = 0
              drawArrow(180, 185, 119, 155, this.options, question)
            } else if (this.options.valueOfGraphic < 0 && this.options.valueOfGraphic > -50) {
              centerColor = 1
              drawArrow(170, 160, 150, 120, this.options, question)
            } else if (this.options.valueOfGraphic >= 0 && this.options.valueOfGraphic < 50) {
              centerColor = 2
              drawArrow(180, 180, 200, 120, this.options, question)
            } else if (this.options.valueOfGraphic >= 50) {
              centerColor = 3
              drawArrow(160, 180, 230, 155, this.options, question)
            }
          }

          if (question.type === 'ces') {
            if (this.options.valueOfGraphic <= 3) {
              centerColor = 0
              drawArrow(180, 185, 119, 155, this.options, question)
            } else if (this.options.valueOfGraphic > 3 && this.options.valueOfGraphic < 5) {
              centerColor = 1
              drawArrow(170, 160, 150, 120, this.options, question)
            } else if (this.options.valueOfGraphic >= 5 && this.options.valueOfGraphic < 6) {
              centerColor = 2
              drawArrow(180, 180, 200, 120, this.options, question)
            } else if (this.options.valueOfGraphic >= 6) {
              centerColor = 3
              drawArrow(160, 180, 230, 155, this.options, question)
            }
          }
          // draw the main circle, colored based on the current target
          drawPieSlice(
            this.ctx,
            this.canvas.width / 2,
            this.canvas.height / 2,
            this.options.doughnutHoleSize * Math.min(this.canvas.width / 4, this.canvas.height / 2),
            0,
            2 * Math.PI,
            this.options.cardData.colors[centerColor]
          )

          // start the structure of the circle, inserting the value of then
          this.ctx.beginPath()
          this.ctx.fillStyle = this.options.cardData.textColor
          const lengthOfValue = this.options.valueOfGraphic.toString().length
          let xPosition = (this.canvas.width / 2.17)
          if (lengthOfValue <= 1) {
            xPosition = (this.canvas.width / 2.10)
          }
          if (lengthOfValue >= 3) {
            xPosition = lengthOfValue === 3 ? (this.canvas.width / 2.30) : lengthOfValue >= 5 ? (this.canvas.width / 2.60) : (this.canvas.width / 2.45)
          }
          // the current value received
          this.ctx.font = 'bold 28px Arial'
          this.ctx.fillText(this.options.valueOfGraphic, xPosition, this.canvas.height / 1.90)
          this.ctx.closePath()
          this.ctx.fill()
        }

        // draw an arc with the ranged values of the gauge (-100 to 100)
        startAngle = 2.75
        sliceAngle = 1 * Math.PI * 25 / 100
        const pieRadius = Math.min(this.canvas.width / 3, this.canvas.height / 2)
        const offset = sliceAngle + 90
        const labelX = this.canvas.width / 2.3 + (offset + pieRadius / 2) * Math.cos(startAngle + sliceAngle / 2)
        const labelY = this.canvas.height / 2 + (offset + pieRadius / 2) * Math.sin(startAngle + sliceAngle / 2)
        this.ctx.fillStyle = '#838383'
        this.ctx.font = 'bold 13px Arial'

        if (question.type === 'nes') {
          const positionsX = [labelX + 5, labelX + 57, labelX + 168, labelX + 272, labelX + 315]
          const positionsY = [labelY, labelY - 100, labelY - 145, labelY - 100, labelY]
          if (question.ui === '10num') {
            const range = [-100, -50, 0, 50, 100]
            drawRange(this.ctx, labelX, labelY, range, positionsX, positionsY)
          }
        }

        if (question.type === 'ces') {
          const positionsX = [labelX + 20, labelX + 57, labelX + 168, labelX + 272, labelX + 315]
          const positionsY = [labelY, labelY - 100, labelY - 145, labelY - 100, labelY]
          const range = [1, 3, 5, 6, 7]
          drawRange(this.ctx, labelX, labelY, range, positionsX, positionsY)
        }

        if (question.type === 'csat') {
          const positionsX = [labelX + 20, labelX + 57, labelX + 168, labelX + 272, labelX + 315]
          const positionsY = [labelY, labelY - 100, labelY - 145, labelY - 100, labelY]
          if (question.ui === '10num') {
            const range = [0, 30, 50, 80, 100]
            drawRange(this.ctx, labelX, labelY, range, positionsX, positionsY)
          }
          if (question.ui === '5num') {
            const range = [0, 30, 50, 80, 100]
            drawRange(this.ctx, labelX, labelY, range, positionsX, positionsY)
          }
        }

        if (question.type === 'nps') {
          const positionsX = [labelX + 2, labelX + 53, labelX + 168, labelX + 272, labelX + 315]
          const positionsY = [labelY, labelY - 100, labelY - 145, labelY - 100, labelY]
          const range = [-100, -50, 0, 50, 100]
          drawRange(this.ctx, labelX, labelY, range, positionsX, positionsY)
        }

        if (question.type === 'nvs') {
          const positionsX = [labelX + 2, labelX + 53, labelX + 168, labelX + 272, labelX + 315]
          const positionsY = [labelY, labelY - 100, labelY - 145, labelY - 100, labelY]
          const range = [-100, -50, 0, 50, 100]
          drawRange(this.ctx, labelX, labelY, range, positionsX, positionsY)
        }
      }
    }

    // cores padrões para exibir o gráfico
    // const lightPurple = '#b48be385' // roxo claro
    // const lightGrey = '#8e8d8dad' // roxo escuro
    // const darkPurple = '#c2a9dc' // cinza claro
    // const darkGrey = '#8e8d8ddb' // cinza escuro
    const lightPurple = this.cardData.colors[2] // roxo claro
    const lightGrey = this.cardData.colors[1] // roxo escuro
    const darkPurple = this.cardData.colors[3] // cinza claro
    const darkGrey = this.cardData.colors[0]

    // get the current value and clean then to show at the view
    if (this.data.average) {
      // validando se o avarage eh um numero inteiro
      if (Number.isInteger(this.data.average)) {
        this.valueOfGraphic = this.data.average.toString()
      } else {
        const valueParsed = typeof this.data.average === 'number' ? this.data.average.toFixed(1) : this.data.average.toString()
        this.valueOfGraphic = valueParsed.slice(0, (valueParsed.indexOf('.')) + 2)
      }
    }
    // monta o objeto que a função espera para desenhar o gráfico

    const colorsArray = [darkGrey, lightGrey, lightPurple, darkPurple]
    const objectToMount = {
      canvas: myCanvas,
      data: piecesOfPie,
      colors: colorsArray,
      doughnutHoleSize: 0.5,
      valueOfGraphic: this.valueOfGraphic,
      cardData: this.cardData
    }
    // instancia o objeto que desenha o gráfico em si
    const myDougnutChart = new Piechart(
      objectToMount,
      this.question
    )
    myDougnutChart.draw()
  }
}
</script>
