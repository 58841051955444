<template>
  <div class="container-card-detail" :class="`card-detail-${id}`">
    <div class="header-card-detail">
      <p class="title-card-detail">{{ item.title }}</p>
      <p class="index-card" v-if="indexCard !== 0">{{ indexCard }}</p>
    </div>
    <div class="content-card-detail">
      <div class="item-detail-resend">
        <p class="title-detail-resend">Total:</p>
        <p class="total-detail-resend" :class="`total-card-resend-${id}`">{{ formatNumber.format(totalItems) }}</p>
      </div>
      <div class="select-items-resend">
        <label
          class="item-type"
          :class="{ 'disabled-item': typeSend.count === 0 }"
          :for="`item-${typeSend.key}-resend-${id}`"
          v-for="typeSend in item.deliveryTypes" :key="typeSend.type">
          <input
            type="checkbox"
            :disabled="typeSend.count === 0"
            :id="`item-${typeSend.key}-resend-${id}`"
            v-model="typeSend.enabled"
          >
          <p style="text-transform: capitalize;" :class="{ 'disabled-item': typeSend.count === 0 }">{{ returnType(typeSend.key) }}: <span class="item-total-resend" :class="{ 'disabled-item': typeSend.count === 0 }">{{ formatNumber.format(typeSend.count) }}</span></p>
        </label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardDetailsResend',
  props: ['id', 'item', 'list'],
  components: { },
  data () {
    return {
    }
  },
  mounted () {
    this.activeCard()
  },
  watch: {
    'item.deliveryTypes': {
      immediate: true,
      deep: true,
      handler (newVal) { this.activeCard() }
    }
  },
  computed: {
    formatNumber () { return Intl.NumberFormat('pt-BR', { maximumSignificantDigits: 10 }) },
    totalItems () { return this.item.deliveryTypes.filter(item => item.enabled).reduce((total, item) => total + item.count, 0) },
    indexCard () { return this.list.findIndex(e => this.item.title === e.title) + 1 }
  },
  methods: {
    activeCard () {
      this.item.totalToSend = this.totalItems
      const card = document.querySelector(`.card-detail-${this.id}`)
      const totalCard = document.querySelector(`.total-card-resend-${this.id}`)
      let headerCard
      if (card) { headerCard = card.firstElementChild }

      if (card && this.item.totalToSend <= 0) {
        totalCard.classList.remove('active-text')
        headerCard.classList.remove('active-card')
      } else if (card && this.item.totalToSend > 0) {
        totalCard.classList.add('active-text')
        headerCard.classList.add('active-card')
      }

      this.$emit('update-list-surveys', this.item)
    },
    returnType (type) {
      if (type === 'email') { return this.$t('resend.type.email') }
      if (type === 'sms') { return this.$t('resend.type.sms') }
      return this.$t('resend.type.whatsapp')
    }
  }
}
</script>

<style lang="scss" scoped>
.active-text {
  color: var(--accent-color) !important;
}

.disabled-item {
  background: #f7f7f7 !important;
  color: #cacaca !important;
}

.active-card {
  background: #e1e1e1;
}
.container-card-detail {
  width: 32.5%;
  margin: 3px;
  background: #ffffff;
  user-select: none;
  height: fit-content;
  border-radius: 0.2rem;
  border: 1px solid #cacaca;
  padding: 0.3rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  .header-card-detail {
    width: 100%;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.2rem;
    padding: 0 0.3rem;
    .title-card-detail {
      width: 95%;
      line-height: 1.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.85vw;
      font-weight: 600;
      color: #454548;
    }
    .index-card {
      background: #808080;
      color: #ffffff;
      width: 20px;
      height: 20px;
      padding-bottom: 0.030rem;
      border-radius: 50%;
      font-size: 0.75vw;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .content-card-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    .item-detail-resend {
      width: 100%;
      padding: 0 0.5rem;
      height: 25px;
      border-bottom: 1px solid #cacaca;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .title-detail-resend {
        font-size: 1.3vh;
        font-weight: 500;
        color: #454548;
        margin-right: 0.2rem;
      }
      .total-detail-resend {
        font-size: 1.3vh;
        color: #808080;
      }
    }
    .select-items-resend {
      width: 100%;
      height: 6rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .item-type {
        background: #ffffff;
        padding: 0 0.5rem;
        border: 1px solid #cacaca;
        border-radius: 0.3rem;
        width: 100%;
        height: 27px;
        cursor: pointer;
        display: flex;
        align-items: center;
        input[type="checkbox"] {
          accent-color: var(--accent-color);
          margin-right: 0.5rem;
          width: 15px;
          height: 15px;
        }
        p {
          font-size: 0.65vw;
          color: #454548;
          .item-total-resend {
            color: #808080;
            margin-left: 0.2rem;
          }
        }
      }
    }
    .select-meta-resend {
      width: 100%;
    }
  }
}
</style>
