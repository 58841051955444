<template>
  <div>
    <canvas :id="makeSpecialId()" class="mt50"></canvas>
  </div>
</template>

<script>
import { canvasMaker } from '@/_helpers'
import('chart.js').then((chartjs) => {
  window.Chart = chartjs.default
})
export default {
  name: 'OverallChart',
  props: ['data', 'question', 'cardData'],
  methods: {
    makeSpecialId () {
      this.question.canvasId = canvasMaker.makeCanvasId(this.question)
      return this.question.canvasId
    }
  },
  async mounted () {
    const data = this.data.valueGroup.map(function (item) {
      return item.doc_count
    })

    const planetChartData = {
      type: 'bar',
      data: {
        labels: [this.$i18n.t('dashboard.y'), this.$i18n.t('dashboard.mb'), this.$i18n.t('dashboard.n')],
        datasets: [
          { // one line graph
            data: data,
            backgroundColor: [
              this.cardData.colors[0],
              this.cardData.colors[1],
              this.cardData.colors[2]
            ]
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        pointStyle: 'circle',
        responsive: true,
        lineTension: 1,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              padding: 25
            }
          }]
        }
      }
    }
    const ctx = document.getElementById(this.question.canvasId)
    const chart = await new window.Chart(ctx, {
      type: planetChartData.type,
      data: planetChartData.data,
      options: planetChartData.options
    })
    return chart
  }
}
</script>
