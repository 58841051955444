<template>
    <div>
        <div class="add-fields-content">
          <div style="display:flex;">
            <div class="fields-button">
              <app-button v-title="$t('survey.metadata')" style="margin: 2px;" variation="default" v-on:click="toggleFieldsGroup()">
                <i class="material-icons tag-icon">local_offer</i>
              </app-button>
            </div>
            <div class="fields-button" v-if="view !== 'ModalAutomaticMessage'">
              <app-button v-title="$t('survey.bold')" style="margin: 2px;" variation="default" @click.prevent="insertStyle(styleOne)">
                <i class="material-icons tag-icon">format_bold</i>
              </app-button>
            </div>
            <div class="fields-button" v-if="view !== 'ModalAutomaticMessage'">
              <app-button v-title="$t('survey.italic')" style="margin: 2px;" variation="default" @click.prevent="insertStyle(styleTwo)">
                <i class="material-icons tag-icon">format_italic</i>
              </app-button>
            </div>
            <div class="fields-button"  v-if="view === 'WhatsappConfig'">
              <app-button v-title="$t('survey.underlined')" style="margin: 2px;" variation="default" @click.prevent="insertStyle(styleThree)">
                <i class="material-icons tag-icon">format_clear</i>
              </app-button>
            </div>
            <div class="fields-button"  v-if="survey.questionSelected > 0 && view !== 'ModalAutomaticMessage'">
              <app-button v-title="'Inserir resposta da pergunta anterior'" style="margin: 2px;" variation="default" @click.prevent="openQuestionsModal = true">
                <i class="material-icons tag-icon">format_quote</i>
              </app-button>
            </div>
          </div>
         <app-card class="fields-group" v-if="showFieldsGroup">
            <div v-for="(item, index) in metadata" :key="index">
                <app-heading level="h4" v-on:click.native="selectField(item.key)">
                {{ item.label }}
                </app-heading>
                <hr v-if="index !== metadata.length - 1">
            </div>
          </app-card>
        </div>
        <prevSelectQuestion :showModal="openQuestionsModal" @submitSelectedQuestion="insertAnswered($event)"/>
    </div>
</template>

<script>
import prevSelectQuestion from './prevSelectQuestions.vue'
export default {
  name: 'optionsTitle',
  props: {
    metadata: {
      type: Array,
      required: false
    },
    display: {
      type: Boolean,
      required: false
    },
    identify: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    cursorPosition: {
      required: false
    },
    lastSelection: {
      required: false
    },
    view: {
      required: false
    }
  },
  data () {
    return {
      styleOne: '**',
      styleTwo: '*',
      styleThree: '~',
      showFieldsGroup: false,
      timeout: null,
      showButton: false,
      showFieldsQuestions: false,
      usedFields: [],
      openQuestionsModal: false
    }
  },
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    prevSelectQuestion
  },
  mounted () {
    if (this.view === 'WhatsappConfig') {
      this.styleOne = '*'
      this.styleTwo = '_'
    }
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    }
  },
  methods: {
    toggleFieldsGroup () {
      this.showFieldsGroup = !this.showFieldsGroup
      if (this.showFieldsGroup === true) {
        clearTimeout(this.timeout)
      } else {
        this.showButton = false
      }
    },
    selectField (field) {
      if (!this.usedFields.includes(field)) {
        this.usedFields.push(field)
      }
      this.showFieldsGroup = false
      this.showButton = false
      const inputValue = document.getElementById(this.identify).value
      const newValue = `${inputValue}*|${field}|*`
      this.$emit('updateOptionsTitle', newValue)
      if (this.usedFields.length > 0) {
        this.$root.$emit('update-merged-fields', this.usedFields)
      }
    },
    insertStyle (style) {
      if (this.lastSelection.value) {
        const selection = this.lastSelection
        const str = this.title
        const replacedText = str.replace(new RegExp(`^(.{${this.lastSelection.selectionStart}})(.{${this.lastSelection.value.length}})`), `$1${style + this.lastSelection.value + style}`)
        selection.selectionStart = null
        selection.value = null
        this.$emit('updateOptionsTitle', replacedText)
        this.$emit('lastSelectionUpdate', selection)
        this.closeModal()
      }
    },
    insertAnswered (questionId) {
      this.showFieldsQuestions = true
      const inputValue = document.getElementById(this.identify).value
      const newValue = `${inputValue.slice(0, this.cursorPosition)}*|q:${questionId}|*${inputValue.slice(this.cursorPosition)}`
      this.showFieldsQuestions = false
      this.$emit('updateOptionsTitle', newValue)
      this.closeModal()
    },
    closeModal () {
      this.openQuestionsModal = false
      this.$emit('submitCloseOptions')
    }
  }
}
</script>

<style lang="scss">
.add-fields-content {
  & .fields-button {
    & .binds-button.medium {
      width: 15px !important;
      height: 37px !important;
      font-size: 14px !important;
      box-shadow: none;
    }
  }
  & .fields-group {
    position: absolute !important;
    max-height: 200px;
    overflow-y: auto !important;
    margin-top: 3px;
    z-index: 1;
    h4 {
      cursor: pointer !important;
      font-size: 16px !important;
      font-weight: 400 !important;
    }
  }
  & .tag-icon {
    margin-left: -10.6px !important;
    margin-top: -2px !important;
    color: #fff !important;
  }
  & .card {
    border-radius: 5px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}
</style>
