<template>
  <div>
    <Menu :showSubMenu="false"></Menu>
    <div class="start">
      <div class="heading-start row center-xxs center-xs center-sm center-md center-lg">
        <app-heading level="h1">{{title}}</app-heading>
        <app-heading level="h4">{{subTitle}}</app-heading>
      </div>
      <div class="row center-xxs center-xs center-sm center-md center-lg">
        <div class="btn">
          <app-button class="btn-light-accent" v-on:click="openModal">{{ $t("start.from_stratch") }}</app-button>
          <app-button class="btn-outline-secondary" v-on:click="goToTemplates" >{{ $t("start.from_template") }}</app-button>
        </div>
        <app-modal
          v-model="isModalOpen"
          :title="$t('start.modal_survey_title')"
          lightbox
          close
          class="modal-default-primary"
          :primary-button="$t('start.create')"
          @primaryButtonClick="setSurvey"
          v-on:keyup.esc="closeModal"
          :class="{ 'disabled-btn': loading }"
          >
          <app-form-input
            v-model="surveyTitle"
            :placeholder="$t('start.survey_title_placeholder')"
            secondary
            autofocus
            v-on:keyup.enter="setSurvey"
            v-on:keyup.esc="closeModal"
          >
          </app-form-input>
          <div class="data-loading-start" v-if="loading">
            <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
          </div>
        </app-modal>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import { surveyService } from '@/_services'
import { iconFormatter } from '@/_helpers'
import('lodash-es').then((lodashEs) => {
  window._ = lodashEs.default
})
export default {
  name: 'Start',
  data () {
    return {
      title: this.$i18n.t('start.title'),
      subTitle: this.$i18n.t('start.description'),
      surveyTitle: '',
      isModalOpen: false,
      loading: false
    }
  },
  components: {
    Menu,
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    },
    user () {
      const user = this.$store.getters['account/getUser']
      return user
    }
  },
  mounted () {
    // tawk.to new users
    if (!this.user.account.billing.hasInvoice) {
      window.Tawk_API.maximize()
      window.Tawk_API.setAttributes({
        pagante: this.user.account.billing.hasInvoice ? 'Sim' : 'Não',
        name: this.user.name,
        email: this.user.email,
        hash: this.hashMe(this.user.name + this.user.email + this.user._id),
        empresa: this.user.account.name
      }, function (error) {
        console.log(error)
      })
    }
  },
  methods: {
    hashMe (str) {
      var hash = 0
      var i
      var chr
      if (str.length === 0) return hash
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0 // Convert to 32bit integer
      }
      return hash
    },
    openModal () {
      this.isModalOpen = true
    },
    closeModal () {
      this.isModalOpen = false
    },
    commitError (message, position, resolve) {
      this.$store.commit('alerts/alert', {
        message: message,
        position: position,
        showAlert: true
      })

      return resolve
    },
    async setSurvey () {
      this.loading = true
      const titleSurvey = this.surveyTitle.trim()
      if (!titleSurvey && titleSurvey.length === 0) {
        this.loading = false
        return this.commitError(this.$i18n.t('start.template_title_error'), 'bottomRight', false)
      }
      await this.$store.commit('survey/startEmptySurvey', this.$store.state)
      const survey = await surveyService.createSurvey({ title: titleSurvey, lang: this.$i18n.vm.fallbackLocale.toLowerCase() })
      if (!survey._id) {
        this.loading = false
        return this.commitError(this.$i18n.t('start.error_creating'), 'bottomRight', false)
      } else {
        // set color theme on survey
        const getTheme = survey.colors.reduce((acc, cur) => ({ ...acc, [cur.alias]: cur.value }), {})
        survey.colors = getTheme
        // set icons on navigation menu
        const shared = iconFormatter.getSharedIcons()
        survey.questions.forEach(element => {
          const questionModel = shared[window._.findIndex(shared, { type: element.type })]
          element.icon = questionModel.icon
          // Precisamos fazer isso para pesquisas antigas que não têm a propriedade de estrelas nas questions
          if (!element.stars) {
            element.stars = { active: false, numbers: false }
          }
        })
        // FIRSt QUESTION SELECTED value Default
        survey.questionSelected = 0
        survey.selectedEndMessageIndex = undefined
        survey.answeredMessageDetails = {}

        this.$store.commit('survey/survey', survey)
        this.loading = false
        this.$router.push({ name: 'Surveys', params: { id: survey._id } })
      }
    },
    goToTemplates () {
      this.$router.push({ name: 'Templates' })
    }
  }
}
</script>

<style lang="scss">
.start {
  max-width: 662px;
  display: block;
  margin: 0 auto;
  margin-top: 1.5%;
  text-align: center;
  .heading-start {
    text-align: center;
    h1 {
      letter-spacing: 2px;
      text-align: center;
    }
    h4 {
      margin-top: 30px;
      word-spacing: 2px;
      line-height: 35px;
      width: 514px;
    }
  }
  & .btn {
      margin-top: 60px;
      & button + button {
          margin-left: 10px;
      }
  }
}
.data-loading-start {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  .data-loading-spinner {
    margin-top: 102px !important;
    & .spinner-inner {
      background-color: #fff !important;
    }
  }
}
.disabled-btn {
  .modal__wrapper__actions__button {
    opacity: 0.5 !important;
  }
}
.spinner--gauge{ margin: 0 auto }
.modal__wrapper__title {
  margin-top: 20px !important;
}
</style>
