<template>
  <div class="section-menu-insight">
    <ShimmerLoading :isHeight="'100%'" v-if="loading" />
    <header class="container-menu-insight" v-else>
      <div class="content-select">
        <v-date-picker
          class="container-date-picker"
          v-model="date"
          color="gray"
          locale="pt-br"
          mode="date"
          @input="changeDate"
          is-range
          v-if="currentTab !== 'reports'"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div class="content-date-picker">
              <p class="placeholder-date">Data</p>
              <div class="section-date-picker" v-on="inputEvents.start">
                <div class="start-date">
                  <span>{{ inputValue.start }}</span>
                </div>
                <p>-</p>
                <div class="end-date">
                  <span>{{ inputValue.end }}</span>
                </div>
                <i  class="material-icons icon-calendar">calendar_month</i>
              </div>
            </div>
          </template>
          <template #footer>
            <div style="padding: 0.5em;display: flex;">
              <button @click="changeDate(mockDate)" v-for="(mockDate, index) in rangeOfDates" :key="index" style="height: 2.2em; padding: 0.3em; font-size: 1em;margin: 0.4em;">{{ $t(`date_filter.periods.${mockDate}`)}}</button>
            </div>
          </template>
        </v-date-picker>
        <app-select-binds
          placeholder="Pesquisa"
          :options="surveys"
          :titleItem="'title'"
          id="surveys"
          :showIndex="true"
          :value="currentSurvey"
          @model="currentSurvey = $event"
          @input="changeSurveyAndSetQuestions"
          style="height: 3.2rem; gap: 6px;"
        />
        <app-select-binds
          placeholder="Pergunta"
          :options="questions"
          :titleItem="'question'"
          id="questions"
          :showIndex="true"
          :value="currentQuestion"
          @model="currentQuestion = $event"
          @input="updateData"
          v-if="currentTab === 'text-analysis'"
          style="height: 3.2rem; gap: 6px;"
        />
        <SearchText
          :insight="true"
          placeholder="Buscar texto"
          :value="filters.textSearch"
          @search-text-insight="searchText = $event"
          v-if="currentTab === 'text-analysis'"
        />
        <div class="open-filter-insight" v-title="'Filtros avançados'" v-if="currentTab !== 'reports'">
          <i class="material-icons icon-menu-options" @click="openFilter()">filter_alt</i>
          <span class="count-filters" @click="openFilter()" v-if="countedFilter > 0">{{ countedFilter }}</span>
        </div>
      </div>
      <div class="content-options">
        <i class="material-icons icon-menu-options"
          v-if="currentTab === 'reports' && showBtnExport && !hideExports"
          v-title="$t('export.generate_pdf')"
          title-placement="left"
          @click="toExport()"
        >picture_as_pdf</i>
        <i class="material-icons icon-menu-options"
          v-if="currentTab === 'text-analysis' && showBtnExport && !hideExports"
          v-title="$t('export.title')"
          title-placement="left"
          @click="toExport()"
          >cloud_download</i>
        <TabChange :tabs="tabs" />
      </div>
    </header>
  </div>
</template>

<script>
import { dateFormatter } from '@/_helpers'
import ShimmerLoading from '../../customLoading/ShimmerLoading.vue'
import SearchText from '@/components/insights/components/SearchText.vue'
import TabChange from '@/components/insights/components/TabChange.vue'

export default {
  name: 'MenuInsight',
  props: ['surveys', 'showBtnExport'],
  components: {
    ShimmerLoading,
    SearchText,
    TabChange,
    'app-select-binds': () => import('@binds-tech/binds-design-system/src/components/Select/SelectBinds')
  },
  data () {
    return {
      openExport: false,
      loading: true,
      date: null,
      currentSurvey: null,
      currentQuestion: null,
      optionAll: { question: 'Todas', _id: '' },
      questions: [],
      rangeOfDates: ['day', 'week', 'month', 'semester', 'year'],
      tabs: [
        { title: 'Reports', value: 'reports', icon: 'space_dashboard' },
        { title: 'Dashboard', value: 'dashboard', icon: 'dashboard' },
        { title: 'Insights', value: 'text-analysis', icon: 'format_size' }
      ],
      currentTab: '',
      searchText: ''
    }
  },
  beforeDestroy () {
    this.$root.$off('tab-changed')
    this.$root.$off('loadFilterAgain')
    this.$root.$off('open-export-insights')
    this.$root.$off('filter-updated') // AO USAR O FILTERUPDATED PRECISA MATAR ELE ANTES DE DESTRUIR O COMPONENTE, CASO CONTRARIO A TELA VAI OUVIR O EVENTO QUE NÃO É DELA
  },
  mounted () {
    this.$root.$on('tab-changed', e => { this.currentTab = e.value; this.updateData() })

    this.$root.$on('filter-updated', () => { this.updateData(null, this.$route.query.q) })

    this.$root.$on('loadFilterAgain', () => { this.updateData(null, this.$route.query.q) })

    const hasSurveyInStore = this.surveys.find(e => { return e._id === this.filters.surveyId })

    if (hasSurveyInStore) {
      this.currentSurvey = hasSurveyInStore
      if (this.filters.questionId) {
        const findQuestion = this.currentSurvey.questions.find(e => { return e._id === this.filters.questionId })
        if (findQuestion) { this.currentQuestion = findQuestion }
      }
    } else {
      this.currentSurvey = this.surveys[0]
    }

    this.date = {
      start: this.filters.date.startDate,
      end: this.filters.date.endDate
    }

    if (this.currentSurvey?._id) {
      this.changeSurveyAndSetQuestions(this.currentSurvey, this.currentQuestion)
    }

    this.loading = false
  },
  watch: {
    searchText: {
      immediate: true,
      handler () { if (this.currentSurvey?._id) { this.updateData() } }
    }
  },
  computed: {
    filters () { return this.$store.getters['filtersInsight/getFilters'] },
    countedFilter () { return this.$store.getters['filters/getCountedFilter'] },
    account () { return this.$store.getters['account/getAccount'] },
    user () { return this.$store.getters['account/getUser'] },
    hideExports () { return this.user.features.hideExports }
  },
  methods: {
    toExport () {
      if (this.currentTab === 'reports') { return window.print() }
      this.$root.$emit('open-export-insights')
    },
    updateUrlInsight () {
      const query = {
        surveyId: this.filters.surveyId,
        questionId: this.filters.questionId,
        ...this.filters.date
      }

      if (this.searchText) {
        query.textSearch = this.searchText
        this.$emit('text-high-insight', query.textSearch)
      }

      if (this.$route.query.q) { query.q = this.$route.query.q }

      this.$router.push({ name: 'Insights', query })
        .catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error when updateing url with filters', err) })
    },
    changeSurveyAndSetQuestions (survey, question) {
      this.questions = []
      this.questions.unshift(this.optionAll)

      survey.questions.forEach(e => { this.questions.push(e) })

      question ? this.currentQuestion = question : this.currentQuestion = this.questions[0]

      this.updateData()
    },
    updateData (question, q) {
      const paramsToRequest = {
        surveyId: this.currentSurvey._id,
        questionId: question ? question._id : this.currentQuestion ? this.currentQuestion._id : '',
        startRange: 0,
        endRange: 19,
        date: this.filters.date,
        textSearch: this.searchText,
        q: q || this.$route.query.q || ''
      }

      this.$store.commit('filtersInsight/insightField', {
        surveyId: paramsToRequest.surveyId,
        questionId: paramsToRequest.questionId,
        textSearch: paramsToRequest.textSearch,
        date: paramsToRequest.date,
        q: paramsToRequest.q
      })

      this.updateUrlInsight()

      if (this.currentTab === 'dashboard') { return this.$emit('data-dashboard', paramsToRequest) }
      if (this.currentTab === 'reports') { return this.$emit('data-reports', paramsToRequest) }
      if (this.currentTab === 'text-analysis') { return this.$emit('data-analysis', paramsToRequest) }
    },
    changeDate (dates) {
      let startDate
      let endDate
      if (typeof dates === 'string') {
        startDate = dateFormatter.stringPeriodToPlainDate(dates).formatedStart
        endDate = dateFormatter.stringPeriodToPlainDate(dates).formatedEnd
      } else {
        startDate = this.$moment(dates.start, 'YYYY-MM-DD HH:mm ZZ').startOf('day').utc().format()
        endDate = this.$moment(dates.end, 'YYYY-MM-DD HH:mm ZZ').endOf('day').utc().format()
      }

      this.date = { start: startDate, end: endDate }

      this.$store.commit('filtersInsight/updateDate', { startDate, endDate })
      this.updateUrlInsight()
      this.updateData()
    },
    openFilter () {
      this.$emit('open-filter')
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
}

.section-menu-insight {
  height: 10%;
  z-index: 1;
  min-height: 4rem;
  .container-menu-insight {
    background: #ffffff;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 0.1rem solid #cacaca;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .content-select {
      width: 65%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 1rem;
      .container-date-picker {
        width: 13rem;
        height: 3.2rem;
        .content-date-picker {
          width: 100%;
          border-radius: 0.3rem;
          border: 0.1rem solid #cacaca;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .placeholder-date {
            height: 30%;
            font-size: 1vh;
            padding-left: 0.3rem;
            font-weight: bold;
            text-transform: uppercase;
            color: #454548;
            font-family: "Open Sans", Helvetica, Arial, sans-serif;
            display: flex;
            align-items: flex-end;
          }
          .section-date-picker {
            width: 100%;
            height: 70%;
            display: flex;
            align-items: center;
            padding-left: 0.5rem;
            gap: 0.5rem;
            font-size: 0.9vw;
            color: #808080;
            .start-date, .end-date {
              font-size: 1.1vw;
              display: flex;
              align-items: center;
            }
            .icon-calendar {
              font-size: 1.1vw;
              color: var(--accent-color);
            }
          }
        }
        .vc-popover-content-wrapper {
          .vc-popover-content {
            .vc-container {
              .vc-pane-container {
                div:nth-child(3) {
                  height: 2rem;
                  border-radius: 0.3rem;
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  button {
                    font-size: 0.7rem !important;
                    border-radius: 0.3rem;
                    height: 1.6rem !important;
                  }
                  button:hover {
                    background: #EDF2F7;
                    color: #4C535D;
                  }
                }
              }
            }
          }
        }
      }
      .open-filter-insight {
        position: relative;
        .count-filters {
          position: absolute;
          bottom: -0.2rem;
          right: -0.3rem;
          width: 1.5rem;
          height: 1.5rem;
          font-size: 0.9rem;
          font-weight: bold;
          border-radius: 50%;
          background: var(--accent-color);
          color: var(--default-text-color);
          cursor: pointer;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.070);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .content-options {
      width: fit-content;
      min-height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
    }
    .icon-menu-options {
      width: 3.2rem;
      height: 3.2rem;
      font-size: 1.8rem;
      color: #808080;
      border-radius: 0.3rem;
      border: 0.1rem solid #cacaca;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon-menu-options:hover {
      color: var(--accent-color);
    }
  }
}

@media (max-width: 1300px) {
  .container-date-picker {
    width: 12rem !important;
  }
}
</style>
