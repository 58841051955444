<template>
  <div class="container-card-to-resend">
    <div class="header-card-to-resend">
      <p class="title-card-to-resend">{{ $t('resend.survey') }}:</p>
      <p class="title-survey-to-resend">{{ survey.title }}</p>
      <p class="index-card-selected">{{ indexCard + 1 }}</p>
    </div>
    <div class="count-type-to-resend">
      <p class="title-card-to-resend">{{ $t('resend.type_resend') }}:</p>
      <div class="types-to-resend">
        <div class="count-type" :class="{'active-mail': survey.deliveryTypes[0].enabled}">
          <i class="material-icons-outlined type-icon-send">{{ returnTypeIcon(survey.deliveryTypes[0].key) }}</i>
          <p class="total-count-type">{{ survey.deliveryTypes[0].enabled ? formatNumber.format(survey.deliveryTypes[0].count) : 0 }}</p>
        </div>
        <div class="count-type" :class="{'active-sms': survey.deliveryTypes[1].enabled}">
          <i class="material-icons-outlined type-icon-send">{{ returnTypeIcon(survey.deliveryTypes[1].key) }}</i>
          <p class="total-count-type">{{ survey.deliveryTypes[1].enabled ? formatNumber.format(survey.deliveryTypes[1].count) : 0 }}</p>
        </div>
        <div class="count-type" :class="{'active-whatsapp': survey.deliveryTypes[2].enabled}">
          <i class="material-icons-outlined type-icon-send whatsapp-icon">{{ returnTypeIcon(survey.deliveryTypes[2].key) }}</i>
          <p class="total-count-type">{{ survey.deliveryTypes[2].enabled ? formatNumber.format(survey.deliveryTypes[2].count) : 0 }}</p>
        </div>
      </div>
    </div>
    <div class="count-total-to-resend">
      <p class="title-card-to-resend">{{ $t('resend.total') }}:</p>
      <p class="total-count-resend">{{ formatNumber.format(totalItems) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardSurveyResume',
  props: ['survey', 'indexCard'],
  components: {},
  data () {
    return {
    }
  },
  computed: {
    formatNumber () { return Intl.NumberFormat('pt-BR', { maximumSignificantDigits: 10 }) },
    totalItems () { return this.survey.deliveryTypes.filter(item => item.enabled).reduce((total, item) => total + item.count, 0) }
  },
  methods: {
    returnTypeIcon (type) {
      const iconOptions = [
        { type: 'sms', icon: 'comment' },
        { type: 'email', icon: 'mail' },
        { type: 'whatsapp', icon: 'call' }
      ]
      const findIcon = iconOptions.find(e => e.type === type)
      return findIcon.icon
    }
  }
}
</script>

<style lang="scss">
.active-whatsapp {
  color: #40bf4b !important;
  text-shadow: 1px 0.2px 2px #40bf4bb5;
  .whatsapp-icon {
    background: #40bf4b !important;
    box-shadow: 1px 0.2px 2px #40bf4bb5;
  }
}

.active-sms {
  color: #a7a965 !important;
  text-shadow: 1px 0.2px 2px #bcbf40b5;
}

.active-mail {
  color: #5c8e9d !important;
  text-shadow: 1px 0.2px 2px #40a1bfb5;
}

.container-card-to-resend {
  background: #ffffff;
  width: 100%;
  min-height: 9vh;
  border-radius: 0.2rem;
  border: 1px solid #cacaca;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .title-card-to-resend {
    font-size: 0.78vw;
    font-weight: 500;
    line-height: 0.8vw;
    color: #454548;
  }
  .header-card-to-resend {
    position: relative;
    height: 30%;
    line-height: 0.85vw;
    display: flex;
    align-items: center;
    gap: 5px;
    .title-survey-to-resend {
      width: 75%;
      font-size: 0.75vw;
      line-height: 0.95vw;
      color: #808080;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .index-card-selected {
      position: absolute;
      right: 1px;
      top: 1px;
      color: #808080;
      border-radius: 50%;
      font-size: 0.6vw;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .count-type-to-resend {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .types-to-resend {
      width: 66%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .count-type {
        width: 33%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        color: #e1e1e1;
        line-height: 0.8vw;
        .type-icon-send {
          font-size: 0.85vw;
        }
        .whatsapp-icon {
          font-size: 0.55vw;
          padding: 0.15rem;
          border-radius: 50%;
          color: #ffffff;
          background: #e1e1e1;
        }
        .total-count-type {
          font-size: 0.7vw;
          font-weight: 500;
        }
      }
    }
  }
  .count-total-to-resend {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    gap: 5px;
    .total-count-resend {
      font-size: 0.75vw;
      line-height: 0.8vw;
      color: #808080;
      text-shadow: 1px 1px 4px #cacaca;
    }
  }
}
</style>
