<template>
  <div>
    <app-card class="cardSendhub">
      <app-card-header class="row headerSendhub headerSms">
        <app-heading level="h4" light>
          <app-icon name="link"></app-icon>
          {{ $t("sendhub.link") }}
        </app-heading>
        <div v-title="'Link personalizado'" style="position: absolute;right: 10px !important;" v-if="user.type === 'superadmin'">
          <app-button
          @click="openModal = !openModal"
          style="padding: 0;height: 27px;width: 27px;"
          class="btn-circle-accent"
          variation="primary"
          ><i class="material-icons">add_link</i></app-button>
        </div>
      </app-card-header>
      <div class="content-send-link" v-if="showLink">
        <div class="send-link">
          <app-heading level="h6">{{ $t("sendhub.link_survey") }}:</app-heading>
          <app-form-input
            :value="linkUrl"
            placeholder="link"
            border-bottom
            id="addToClipboard"
            @paste="onPaste"
          />
        </div>
        <app-button
          class="btn-circle-accent"
          style="width: 215px !important"
          v-on:click="copyToClipboard"
          variation="primary"
          >{{ $t("sendhub.copy") }}
        </app-button>
      </div>
      <QrCodeGenerator :key="linkUrl" v-if="showLink" :link="linkUrl" />
    </app-card>
    <CustomLink v-if="openModal" @close="openModal = !openModal"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomLink from './CustomLink'
import QrCodeGenerator from './QrCodeGenerator.vue'

export default {
  name: 'LinkHub',
  components: {
    QrCodeGenerator,
    'app-icon': () => import('@binds-tech/binds-design-system/src/components/Icon/Icon'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-card-header': () => import('@binds-tech/binds-design-system/src/components/Card/Header'),
    CustomLink
  },
  data () {
    return {
      surveyHashId: '',
      openModal: false,
      linkUrl: '',
      showLink: false
    }
  },
  mounted () {
    this.mountLink()
    this.$root.$on('updated-link', () => {
      this.mountLink()
    })
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser',
      linkSettings: 'sendhub/getLinkSettings'
    })
  },
  methods: {
    onPaste (evt) {
      setTimeout(() => {
        this.mountLink(evt.target.value)
      }, 10)
    },
    mountLink (isPaste) {
      if (!isPaste) {
        const surveyHashId = this.$store.getters['survey/getSurvey'].hashId
        this.linkUrl = 'https://surveyv2.binds.co/#/link/' + surveyHashId
        if (this.linkSettings) {
          this.linkUrl = this.linkUrl + '/?'
          if (this.linkSettings.allowMoreThanOne) {
            this.linkUrl = this.linkUrl + 'loop=true&'
          }
          if (this.linkSettings.showEndSurveyButton) {
            this.linkUrl = this.linkUrl + 'close=true&'
          }
          if (this.linkSettings.endSurveyByInactivity) {
            this.linkUrl = this.linkUrl + 'timeout=' + this.linkSettings.seconds + '&'
          }
        }
      } else {
        this.linkUrl = isPaste
      }
      this.showLink = true
    },
    copyToClipboard () {
      const testingCodeToCopy = document.querySelector('#addToClipboard')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        document.execCommand('copy')

        this.$store.commit('alerts/alert', {
          message: 'URL da Pesquisa copiado para a área de transferência.',
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      } catch (err) {
        this.$store.commit('alerts/alert', {
          message: 'Não foi possível copiar',
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      }
    }
  }
}
</script>

<style lang="scss">
.content-send-link {
  margin-top: 130px;
  text-align: center;
}
.send-link {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 80px;
  text-align: left;
  .text-field__input input {
    color: #1d1b22 !important;
  }
}
</style>
