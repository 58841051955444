<template>
  <div class="section-mention" v-if="loading" :key="data._id">
    <div class="details-shared">
      <p><i class="material-icons icon-shared">public</i> <span>{{ data.socialMedia }}</span></p>
      <p><i class="material-icons icon-shared">schedule</i> <span>{{ $moment(data.createdAt).startOf('hour').fromNow() }}</span></p>
      <p><i class="material-icons icon-shared">apartment</i> <span>@{{ data.brandsTracking[data.brandsTracking.type].name }}</span></p>
    </div>
    <div class="info-mention">
      <div class="image-profile">
        <img class="image-user-mention" onerror="this.src='https://res.cloudinary.com/binds-co/image/upload/v1508526527/placeholder_je0010.png'" :src="data.userInfos.imageUrl || 'https://res.cloudinary.com/binds-co/image/upload/v1508526527/placeholder_je0010.png'">
      </div>
      <div class="context-mention">
        <div class="name-info">
          <p class="name-user">{{ data.userInfos.name }}</p>
          <p class="user-name">@{{data.userInfos.username }}</p>
        </div>
        <p class="text-mention" @click="findBy($event)" v-html="returnParsedHashtagText(data.text)"></p>
      </div>
    </div>
    <div class="image-and-share">
      <div class="image-post-mention" v-if="data.imageShared">
        <img :src="data.imageShared">
      </div>
      <div class="shared-mention">
        <div class="options-metrics">
          <div class="reply" v-title="$t('bvs.share.comments')" title-max-width="320">
            <p><i class="material-icons icon-mention">mode_comment</i> {{ data.publicMetrics.quote_count }}</p>
          </div>
          <div class="retweet" v-title="$t('bvs.share.retweets')" title-max-width="320">
            <p><i class="material-icons icon-mention">repeat</i> {{ data.publicMetrics.share_count }}</p>
          </div>
          <div class="like" v-title="$t('bvs.share.likes')" title-max-width="320">
            <p><i class="material-icons icon-mention">favorite</i> {{ data.publicMetrics.like_count }}</p>
          </div>
          <div class="redirect-link" v-title="$t('bvs.share.view_on_page')" title-max-width="320" @click="redirectPage(data.link)">
            <i class="material-icons icon-mention">turn_right</i>
          </div>
        </div>
        <div class="nps-mention">
          <i class="material-icons icon-mention-nps" style="color: #57AD2699" v-if="data.sentiment === 'good'">mood</i>
          <i class="material-icons icon-mention-nps" style="color: #F8C43D" v-if="data.sentiment === 'neutral'">sentiment_neutral</i>
          <i class="material-icons icon-mention-nps" style="color: #EA484D99" v-if="data.sentiment === 'bad'">sentiment_dissatisfied</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardMention',
  props: ['data', 'display'],
  components: {},
  data () {
    return {
      loading: false
    }
  },
  mounted () {
    if (this.data) {
      this.loading = true
    }
  },
  computed: {

  },
  methods: {
    findBy (evt) {
      if (evt.target.classList.contains('clickable-text')) {
        this.$root.$emit('search-by-clicked-text', evt.target.textContent)
      }
    },
    returnParsedHashtagText (text) {
      const spliteds = text.split(' ')
      let regexToUse = /@.*/g
      const parsedStrings = spliteds.map((item) => {
        let color = '#3f3356' // black
        if (item.includes('#')) {
          regexToUse = /#.*/g
          color = '#3a9eea' // blue
        }
        return item.replace(regexToUse, (match, f) => {
          return `<b class="clickable-text" style="color: ${color};">${match.replaceAll(',', ' ')}</b>`
        })
      })
      let newText = ''
      parsedStrings.forEach((element) => {
        newText += ` ${element}`
      })

      return newText
    },
    redirectPage (link) {
      window.open(link)
    }
  }
}
</script>

<style lang="scss">
.section-mention {
  user-select: none;
  width: 100%;
  min-height: 150px;
  padding: 10px 5px;
  border: 1px solid #e1e1e1;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .details-shared {
    width: 100%;
    padding: 0 5px;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 10px;
      color: gray;
      display: flex;
      align-items: center;
      .icon-shared {
        font-size: 15px;
        margin-right: 2px;
      }
      span {
        margin-bottom: 2px;
      }
    }
  }
  .info-mention {
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: flex-start;
    .image-profile {
      width: 50px;
      height: 50px;
      padding: 1px;
      overflow: hidden;
      border-radius: 50%;
      border: 2px solid #cacaca;
      display: flex;
      align-items: center;
      justify-content: center;
      .image-user-mention {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .context-mention {
      width: 85%;
      margin-left: 15px;
      .name-info {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 3px 0;
        .name-user {
          font-size: 15px;
          font-weight: bold;
          margin-right: 5px;
        }
        .user-name {
          color: gray;
          font-size: 13px;
          margin-top: 5px;
        }
        .date-post {
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 10px;
            color: gray;
            margin-right: 5px;
          }
          img {
            width: 11px;
          }
        }
      }
      .text-mention {
        margin-top: 10px;
        font-size: 13px;
        line-height: 15px;
        color: #151515;
        .clickable-text {
          cursor: pointer;
        }
      }
    }
  }
  .image-and-share {
    .shared-mention {
      display: flex;
      border-radius: 2px;
      overflow: hidden;
      margin-top: 20px;
      .options-metrics {
        height: 35px;
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .reply, .retweet, .like, .redirect-link {
          color: #a7a7a7;
          p {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            .icon-mention {
              width: 30px;
              height: 30px;
              font-size: 17px;
              margin-right: 5px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .redirect-link {
          cursor: pointer;
        }
        .reply:hover {
          color: #3a9eea;
          .icon-mention {
            background: #3a9eea1c;
          }
        }
        .retweet:hover {
          color: #3bc4c7;
          .icon-mention {
            background: #3bc4c71c;
          }
        }
        .like:hover {
          color: #ff4e69;
          .icon-mention {
            background: #ff4e691c;
          }
        }
        .redirect-link:hover {
          color: #3a9eea;
          .icon-mention {
            border-radius: 50%;
            background: #3a9eea1c;
          }
        }
      }
      .nps-mention {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-mention-nps {
          font-size: 25px;
        }
      }
    }
  }
}
.section-mention:last-child {
  border-bottom: 1px solid #e1e1e1;
}
</style>
