<template>
  <div class="nav-new">
    <app-modal
      v-model="alertModalTitle"
      :title="$t('survey.condition_title')"
      lightbox
      class="modal-default-primary modal-conditions"
      @secondaryButtonClick="alertModalTitle = false"
      :secondary-button="'Ok'"
      v-on:keyup.esc="alertModalTitle = false"
    >
      <app-heading level="h6">{{ $t('survey.condition_title2') }}</app-heading>
    </app-modal>

    <app-modal
      v-model="showModalConditions"
      :title="$t('survey.condition_position')"
      lightbox
      class="modal-default-primary modal-conditions"
      :primary-button="$t('survey.cancel')"
      @secondaryButtonClick="clearConditions()"
      :secondary-button="$t('survey.continue')"
      @primaryButtonClick="cancel()"
      v-on:keyup.esc="cancel()"
    >
      <app-heading level="h6">{{ $t('survey.condition_position2') }}</app-heading>
    </app-modal>

    <app-modal
      v-model="showModalConditionsToDeleteQuestion"
      :title="$t('survey.conditions_msg')"
      lightbox
      class="modal-default-primary modal-conditions"
      :primary-button="$t('survey.cancel')"
      @secondaryButtonClick="deleteQuestion()"
      :secondary-button="$t('survey.continue')"
      @primaryButtonClick="cancel()"
      v-on:keyup.esc="cancel()"
    >
      <app-heading level="h6">{{ $t('survey.conditions_msg2') }}</app-heading>
    </app-modal>

    <app-modal
      v-model="showModalAutoMessageToDelete"
      :title="$t('survey.auto_messages_msg')"
      lightbox
      class="modal-default-primary modal-conditions"
      :primary-button="$t('survey.cancel')"
      @secondaryButtonClick="deleteQuestion()"
      :secondary-button="$t('survey.continue')"
      @primaryButtonClick="cancel()"
      v-on:keyup.esc="cancel()"
    >
      <app-heading level="h6">{{ $t('survey.auto_messages_title') }}</app-heading>
    </app-modal>

    <div class="row center-lg center-md center-sm mt20" v-if="survey.startMessage.isEnabled">
      <app-heading level="h5">{{ $t('questionsNav.start_message') }}</app-heading>
      <div class="nav-content">
        <div
          class="nav-card"
          :class="{ 'green-border': showingStart }"
          @click="selectMessage('start')"
          :style="{ 'background-color': survey.colors.splashBg }"
          style="height: 215px !important;"
        >
          <div class="row center-lg center-md center-sm" v-if="!survey.startMessage.title.hidden" :style="{ 'background-color': survey.colors.headerBg }" style="padding: 15px;">
            <app-heading level="h6" class="question-nav-title" style="text-align: center !important;" :style="{ 'color': survey.colors.headerColor }">{{ survey.startMessage.title.value }}</app-heading>
          </div>
          <div v-if="!survey.startMessage.logo.isHidden && survey.images[0]" class="content-img-preview-messages">
            <img class="img-preview-default" :src="survey.images[0].value" alt="logo">
          </div>
          <div class="row center-lg center-md center-sm mt30">
            <div :style="{ 'background-color': survey.colors.buttonBg }" class="btn-preview-start">
              <app-heading level="h6" style="font-size: 12px !important;" :style="{ 'color':survey.colors.buttonColor }">
                {{ survey.nextLabel }}
              </app-heading>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="divider" v-if="survey.startMessage.isEnabled">

    <draggable v-model="questions" :move="checkMove" @end="onEndUpdateOrder">
      <div class="nav-content" v-for="(question, index) in questions" :key="index">
        <div
          class="nav-card"
          :class="{ 'green-border': index === survey.questionSelected && !showingEnd && !showingStart && !showingAnswered }"
          @click="selectQuestion(index, question.type)"
          :style="{ 'background-color': survey.colors.splashBg }"
        >
          <div :style="{ 'background-color': survey.colors.headerBg }" style="padding: 20px;">
            <app-heading class="question-order" :style="{ 'color': survey.colors.headerColor }">
              {{ index + 1 }}
            </app-heading>
            <div class="head-questions-prev">
              <app-heading level="h6" v-if="question.label.length > 0" class="question-nav-title" :style="{ 'color': survey.colors.headerColor }">
                {{ question.label }}
              </app-heading>
              <app-heading level="h6" class="question-nav-text" :style="{ 'color': survey.colors.headerColor }">
                {{ question.question }}
              </app-heading>
            </div>
          </div>

          <component
            class="transform-ui"
            :key="wasUpdated"
            v-bind:is="renderUi(question.ui, question.type, question.options)"
            :question="question"
            :colors="componentsColors"
            :preview="true"
            :class="{
              't-5emo': question.ui.toLowerCase() === '5emo',
              't-stars': question.ui.toLowerCase() === '5num' && question.stars.active,
              't-5num': question.ui.toLowerCase() === '5num' && !question.stars.active,
              't-5nes': (question.ui.toLowerCase() === '5nes' || question.ui.toLowerCase() === '10num') && question.type === 'nes',
              't-yn': question.ui.toLowerCase() === 'yn',
              't-ynm': question.ui === 'ynm',
              't-ynd': question.ui.toLowerCase() === 'ynd',
              't-text': question.ui.toLowerCase() === 'text',
              't-inputext': question.ui.toLowerCase() === 'inputext',
              't-referral': question.ui.toLowerCase() === 'referral',
              't-referraldynamic': question.ui.toLowerCase() === 'referraldynamic',
              't-single': question.ui.toLowerCase() === 'single' || question.ui === 'singleOther',
              't-multiple': question.ui.toLowerCase() === 'multiple' || question.ui === 'multipleOther',
              't-0to5': question.options.length === 6 && question.ui === '10num',
              't-1to5': question.options.length === 5 && question.ui === '10num' && question.type !== 'nes',
              't-0to10': ((question.options.length === 11 && question.ui === '10num') || (question.options.length === 14 && question.ui === '10num')) && question.type !== 'matrix',
              't-nps' : question.type === 'nps' && question.ui === '10num',
              't-1to10': question.options.length === 10 && question.ui === '10num',
              't-1to7': question.type === 'ces' && question.ui === '1to7',
              't-1to7button': question.type === 'ces' && question.ui === '1to7button',
              't-matrix1to5': question.ui.toLowerCase() === 'matrix1to5',
              't-matrix10num': question.ui.toLowerCase() === '10num' && question.type === 'matrix',
              't-matrixlike': question.ui.toLowerCase() === 'like' && question.type === 'matrix',
              't-5radio': question.ui.toLowerCase() === '5radio'
            }"
          >
          </component>
        </div>

        <app-card v-if="index === survey.questionSelected && survey.questions.length > 1 && !showingStart && !showingEnd && !showingAnswered"
          class="actions-nav" style="padding-top: 5px !important; display: flex;">
          <app-button inline class="col-lg-6 col-md-6 col-sm-6" v-on:click="handleQuestionDeletion(index, questions, question)">
            <i class="material-icons" style="color: gray !important;">delete</i>
          </app-button>
          <app-button inline class="col-lg-6 col-md-6 col-sm-6" v-on:click="cloneQuestion(index)">
            <i class="material-icons clone-icon" style="color: gray !important;">content_copy</i>
          </app-button>
        </app-card>
        <app-card v-if="survey.questions.length === 1 && index === survey.questionSelected && !showingEnd && !showingStart && !showingAnswered" class="actions-nav" style="padding-top: 5px !important;">
          <div class="row center-lg center-md center-sm">
            <app-button inline v-on:click="cloneQuestion(index)">
              <i class="material-icons fs20" style="color: gray !important;">content_copy</i>
            </app-button>
          </div>
        </app-card>
        <!-- Esse botão de adicionar questions é fixo na úlima pergunta da lista. -->
        <div class="row add-question-btn center-lg center-md center-sm" v-if="index === survey.questionSelected && !showingEnd && !showingStart || index === (questions.length - 1)">
          <app-button
            floating
            variation="primary"
            class="btn-circle-accent"
            title-placement="right"
            v-title="$t('questionsNav.add_question')"
            v-on:click="addQuestion(index)"
          >
            <i class="material-icons">add</i>
          </app-button>
        </div>
      </div>
    </draggable>
    <hr class="divider">
    <div class="row center-lg center-md center-sm mt20">
      <app-heading level="h5">{{ $t('questionsNav.end_message') }}</app-heading>
    </div>
    <draggable v-model="survey.endMessages" :move="checkMoveEndMessages">
      <div class="nav-content" v-for="(endMessage, index) in survey.endMessages" :key="index">
        <div
          class="nav-card end-card" @click="selectEndMessage(index)"
          :class="{ 'green-border': index === survey.selectedEndMessageIndex && showingEnd }"
          :style="{ 'background-color': survey.colors.splashBg }"
        >
          <i class="material-icons-outlined info-first-end-message" v-if="index === 0" v-title="$t('survey.settings.no_condition_end_message')" title-max-width="250">info</i>
          <div class="row center-lg center-md center-sm" :style="{ 'background-color': survey.colors.headerBg }" style="padding: 20px;">
          </div>
          <div v-if="!endMessage.logo.isHidden && endMessage.logo.url" class="content-img-preview-messages">
            <img class="img-preview" :src="endMessage.logo.url" alt="logo">
          </div>
          <div v-if="!endMessage.logo.isHidden && !endMessage.logo.url && survey.images.length > 0" class="content-img-preview-messages">
            <img class="img-preview-default" :src="survey.images[0].value" alt="logo">
          </div>
          <div v-if="endMessage.details">
            <app-heading level="h6" class="end-message-details">{{ endMessage.details.value }}</app-heading>
          </div>
          <div v-if="endMessage.details !== undefined && endMessage.details.link !== undefined">
            <app-heading level="h6" class="end-message-link">{{ endMessage.details.link.label }}</app-heading>
          </div>
          <app-heading
            level="h6"
            class="question-nav-title"
            style="text-align: center !important;"
            :style="{ 'color': survey.colors.splashColor }"
          >{{ endMessage.message }}</app-heading>
        </div>
        <app-card v-if="survey.endMessages.length > 1 && showingEnd && index === survey.selectedEndMessageIndex && index !== 0" class="actions-nav" style="padding-top: 5px !important; display: flex;">
          <app-button inline class="col-lg-6 col-md-6 col-sm-6" v-on:click="deleteEndMessage(index)">
            <i class="material-icons" style="color: gray !important;">delete</i>
          </app-button>
          <app-button inline class="col-lg-6 col-md-6 col-sm-6" v-on:click="cloneEndMessage(endMessage, index)">
            <i class="material-icons clone-icon" style="color: gray !important;">content_copy</i>
          </app-button>
        </app-card>
        <app-card v-if="survey.endMessages.length === 1 && showingEnd && index === survey.selectedEndMessageIndex && index !== 0" class="actions-nav" style="padding-top: 5px !important; display: flex;">
          <div class="row center-lg center-md center-sm">
            <app-button inline v-on:click="cloneEndMessage(endMessage, index)">
              <i class="material-icons fs20" style="color: gray !important;">content_copy</i>
            </app-button>
          </div>
        </app-card>
        <div
          class="row add-question-btn center-lg center-md center-sm"
          v-if="index === survey.selectedEndMessageIndex || index === (survey.endMessages.length - 1)"
        >
          <app-button
            floating
            variation="primary"
            title-placement="right"
            class="btn-circle-accent"
            v-title="$t('questionsNav.add_end_message')"
            v-on:click="addEndMessage(index)"
          >
            <i class="material-icons">add</i>
          </app-button>
        </div>
      </div>
    </draggable>
    <hr class="divider">
    <div class="row center-lg center-md center-sm mt20">
      <app-heading level="h5">{{ $t('questionsNav.answered_message') }}</app-heading>
      <div class="nav-content">
        <div
          class="nav-card end-card"
          :class="{ 'green-border': showingAnswered }"
          @click="selectMessage('answered')"
          :style="{ 'background-color': survey.colors.splashBg }"
        >
          <div class="row center-lg center-md center-sm" v-if="!survey.startMessage.hiddenTitle" :style="{ 'background-color': survey.colors.headerBg }" style="padding: 20px;">
          </div>
          <div v-if="!survey.endMessages[0].logo.isHidden && survey.endMessages[0].logo.url" class="content-img-preview-messages">
            <img class="img-preview" :src="survey.endMessages[0].logo.url" alt="logo">
          </div>
          <div v-if="!survey.endMessages[0].logo.isHidden && !survey.endMessages[0].logo.url && survey.images.length > 0" class="content-img-preview-messages">
            <img class="img-preview-default" :src="survey.images[0].value" alt="logo">
          </div>
          <div v-if="survey.answeredMessageDetails.value">
            <app-heading level="h6" class="end-message-details">{{ survey.answeredMessageDetails.value }}</app-heading>
          </div>
          <div v-if="survey.answeredMessageDetails.link">
            <app-heading level="h6" class="end-message-link">{{ survey.answeredMessageDetails.link.label }}</app-heading>
          </div>
          <app-heading
            level="h6"
            class="question-nav-title"
            style="text-align: center !important;"
            :style="{ 'color': survey.colors.splashColor }"
          >
            {{ survey.answeredMessage }}
          </app-heading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import app10num from './answersType/10num.vue'
import app5num from './answersType/5num.vue'
import app5nes from './answersType/5nes.vue'
import appyn from './answersType/kpi-yn.vue'
import appynm from './answersType/kpi-ynm.vue'
import appynd from './answersType/kpi-ynd.vue'
import app5emo from './answersType/5emo.vue'
import apptext from './answersType/text.vue'
import appinputext from './answersType/input-text.vue'
import appreferral from './answersType/referral.vue'
import appreferraldynamic from './answersType/referraldynamic.vue'
import appsingle from './answersType/single.vue'
import appmultiple from './answersType/multiple.vue'
import app0to5 from './answersType/0to5.vue'
import app1to5 from './answersType/1to5.vue'
import app1to7 from './answersType/1to7.vue'
import app1to7button from './answersType/1to7button.vue'
import app0to10 from './answersType/0to10.vue'
import app1to10 from './answersType/1to10.vue'
import appmatrix1to5 from './answersType/matrix1to5.vue'
import applike from './answersType/matrixlike.vue'
import appmatrix10num from './answersType/matrix10num.vue'
import app5radio from './answersType/nvs.vue'
import { questionsHelper, imgProps, canvasMaker } from '@/_helpers'

import('lodash-es').then((lodashEs) => {
  window._ = lodashEs.default
})

export default {
  name: 'NavigationQuestions',
  components: {
    draggable,
    app10num,
    app5num,
    app5nes,
    appyn,
    appynm,
    appynd,
    app5emo,
    apptext,
    appinputext,
    appreferral,
    appreferraldynamic,
    appsingle,
    appmultiple,
    app0to5,
    app1to5,
    app1to7,
    app1to7button,
    app0to10,
    app1to10,
    appmatrix1to5,
    applike,
    appmatrix10num,
    app5radio,
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal')
  },
  data () {
    return {
      showingEnd: false,
      showingStart: false,
      showingAnswered: false,
      showModalConditions: false,
      showModalConditionsToDeleteQuestion: false,
      showModalAutoMessageToDelete: false,
      answerInTitleCanBeDeleted: false,
      currentDeleteQuestionIndex: null,
      conditionsToDelete: [],
      startMessageProp: {},
      wasUpdated: 0,
      alertModalTitle: false
    }
  },
  mounted () {
    this.startMessageProp = imgProps.setImgMessage(this.survey.images, 'startMessage')
    const questionsWithOrder = questionsHelper.addOrder(this.questions)
    this.questions = questionsWithOrder
    this.$store.commit('survey/survey', {
      questionSelected: 0,
      hiddenEndMessage: true,
      hiddenAnsweredMessage: true,
      hiddenStartMessage: true,
      questions: this.questions
    })

    this.$root.$on('details-updated', () => { this.$forceUpdate() })
  },
  computed: {
    questions: {
      get () {
        return this.$store.getters['survey/getQuestions']
      },
      set (newList) {
        this.$store.commit('survey/survey', { questions: newList })
      }
    },
    survey () { return this.$store.getters['survey/getSurvey'] },
    account () { return this.$store.getters['account/getAccount'] },
    user () { return this.$store.getters['account/getUser'] },
    componentsColors () {
      const themeColors = this.$store.getters['survey/getColors']
      const colors = {
        actionColor: themeColors.actionColor,
        actionText: themeColors.actionText,
        splashColor: themeColors.splashColor
      }
      return colors
    }
  },
  watch: {
    questions: function () {
      this.wasUpdated += 1
    }
  },
  methods: {
    renderUi (ui, type, options) {
      let appUi = 'app' + ui.toLowerCase()

      if (ui.toLowerCase() === '10num' && ['nps', 'csat'].indexOf(type) < 0) {
        if (type === 'nes') {
          appUi = 'app5nes'
        }
        if (options.length === 6) {
          appUi = 'app0to5'
        }
        if (options.length === 5) {
          appUi = 'app1to5'
        }
        if (options.length === 11) {
          appUi = 'app0to10'
        }
        if (options.length === 10) {
          appUi = 'app1to10'
        }
      }

      if (type === 'matrix' && ui === '10num') {
        appUi = 'appmatrix10num'
      }
      if (ui === 'multipleOther') {
        appUi = 'appmultiple'
      }
      if (ui === 'singleOther') {
        appUi = 'appsingle'
      }
      if (ui === '5radio') {
        appUi = 'app5radio'
      }
      return appUi
    },
    selectQuestion (index, questionType) {
      this.showingStart = false
      this.showingEnd = false
      this.showingAnswered = false
      this.$store.commit('survey/survey', {
        questionSelected: index,
        showSelector: false,
        hiddenEndMessage: true,
        hiddenAnsweredMessage: true,
        hiddenStartMessage: true,
        questions: this.questions,
        selectedType: questionType
      })

      this.$root.$emit('showing-question')
    },
    addQuestion (index) {
      if (this.checkIfUserCanAddOrDeleteQuestion()) {
        const newIndex = index + 1
        const newQuestion = {
          label: this.$i18n.t('questionsNav.question_title'),
          question: this.$i18n.t('questionsNav.question_value'),
          type: 'kpi',
          ui: '5emo',
          title: this.$i18n.t('questionsNav.question_title'),
          notificate: [],
          showIfAny: [],
          showIfAll: [],
          ifMetadata: [],
          then: [],
          language: this.user.language.toLowerCase(),
          nextLabel: this.$i18n.t('questionsNav.nextLabel'),
          randomOptions: false,
          options: [],
          minLength: 0,
          maxLength: 0,
          required: true,
          customTitle: false,
          icon: 'insert_chart',
          id: canvasMaker.makeCanvasIdWithoutParam() // cria um ID unico para cada question nova, precisamos disso para validar nas condicionais
        }

        this.questions.splice(newIndex, 0, newQuestion)
        this.selectQuestion(newIndex, newQuestion.type)

        const questionsWithOrder = questionsHelper.addOrder(this.questions)
        this.questions = questionsWithOrder
        this.$store.commit('survey/survey', { questions: this.questions })
        this.$forceUpdate()
      }
    },
    checkAnswersInTitle (questions, currentQuestion) {
      const haveAnswersInTitle = questions.map(element => element.title && element.title.includes('*|q:' + currentQuestion.id))

      if (haveAnswersInTitle.some(el => el === true)) {
        this.answerInTitleCanBeDeleted = true
      }
    },
    checkIfUserCanAddOrDeleteQuestion () {
      if (this.survey.alreadySent && !this.account.features.allowEditSurveyWithResponses) {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('survey.survey_already_sent'),
          position: 'bottomRight',
          showAlert: true
        })
        return false
      }
      return true
    },
    checkConditionsToDelete (currentQuestionIndex) {
      this.conditionsToDelete = []
      this.currentDeleteQuestionIndex = currentQuestionIndex
      this.questions.map((question, questionIndex) => {
        question.showIfAll.map(condition => {
          if (condition.question === this.questions[currentQuestionIndex].id) {
            this.conditionsToDelete.push({
              where: 'questions',
              conditionIndex: condition.question,
              questionIndex: questionIndex,
              type: 'showIfAll'
            })
          }
        })

        question.showIfAny.map(condition => {
          if (condition.question === this.questions[currentQuestionIndex].id) {
            this.conditionsToDelete.push({
              where: 'questions',
              conditionIndex: condition.question,
              questionIndex: questionIndex,
              type: 'showIfAny'
            })
          }
        })
      })
      this.survey.endMessages.map((endMessage, questionIndex) => {
        endMessage.showIfAll.map(condition => {
          if (condition.question === this.questions[currentQuestionIndex].id) {
            this.conditionsToDelete.push({
              where: 'endMessage',
              conditionIndex: condition.question,
              questionIndex: questionIndex,
              type: 'showIfAll'
            })
          }
        })

        endMessage.showIfAny.map(condition => {
          if (condition.question === this.questions[currentQuestionIndex].id) {
            this.conditionsToDelete.push({
              where: 'endMessage',
              conditionIndex: condition.question,
              questionIndex: questionIndex,
              type: 'showIfAny'
            })
          }
        })
      })
    },
    clearTriggersQuestion (questionIdx) {
      const questionToDelete = this.survey.questions[questionIdx]
      this.survey.ticket.triggers = this.survey.ticket.triggers.filter(trigger => trigger.questionId !== questionToDelete._id)
    },
    handleQuestionDeletion (currentQuestionIndex, allQuestions, currentQuestion) {
      this.checkConditionsToDelete(currentQuestionIndex)
      this.checkAnswersInTitle(allQuestions, currentQuestion)

      if (this.conditionsToDelete.length > 0 || this.answerInTitleCanBeDeleted) {
        this.showModalConditionsToDeleteQuestion = true
        return
      }

      const hasTrigger = this.survey.ticket.triggers.length
      const hasTriggerInQuestion = this.survey.ticket.triggers.find(trigger => trigger.questionId === currentQuestion._id)

      if (hasTrigger && hasTriggerInQuestion) {
        this.showModalAutoMessageToDelete = true
        return
      }

      if (this.checkIfUserCanAddOrDeleteQuestion()) {
        this.deleteQuestion()
        this.answerInTitleCanBeDeleted = false
      }
    },
    deleteQuestion () {
      const index = this.currentDeleteQuestionIndex
      if (this.conditionsToDelete.length > 0) {
        this.clearConditions()
      }

      if (this.showModalAutoMessageToDelete) {
        this.clearTriggersQuestion(index)
      }

      if (this.answerInTitleCanBeDeleted) {
        this.clearAnswersInQuestion(index)
      }

      this.questions.splice(index, 1)
      const newIndex = (index) ? index - 1 : index
      const questionsWithOrder = questionsHelper.addOrder(this.questions)
      this.questions = questionsWithOrder
      this.$store.commit('survey/survey', { questions: this.questions, questionSelected: newIndex })
      this.$forceUpdate()
      this.showModalConditionsToDeleteQuestion = false
      this.showModalAutoMessageToDelete = false
    },
    clearAnswersInQuestion (questionIndex) {
      const regex = new RegExp(`\\*\\|q:${this.questions[questionIndex].id}\\|\\*`, 'g')

      const questionsWithoutAnswerInTitle = this.questions

      questionsWithoutAnswerInTitle.forEach(element => {
        const label = element.label
        const title = element.title

        element.title = title.replace(regex, '')
        element.label = label.replace(regex, '')
      })

      this.questions = questionsWithoutAnswerInTitle
    },
    cloneQuestion (index) {
      let questions = this.questions
      const question = JSON.parse(JSON.stringify(this.questions[index]))
      var newIndex = index + 1
      delete question._id
      question.id = canvasMaker.makeCanvasIdWithoutParam() // cria um ID unico para cada question nova, precisamos disso para validar nas condicionais
      question.order = this.survey.questionSelected + 1
      questions.splice(newIndex, 0, question)
      const questionsWithOrder = questionsHelper.addOrder(questions)
      questions = questionsWithOrder
      this.$store.commit('survey/survey', { questions: questions })
      this.selectQuestion(newIndex, question.type)
      this.$forceUpdate()
    },
    selectMessage (messageType) {
      if (messageType === 'start') {
        this.showingEnd = false
        this.showingAnswered = false
        this.showingStart = true
        this.$store.commit('survey/survey', {
          hiddenStartMessage: false,
          hiddenAnsweredMessage: true,
          hiddenEndMessage: true
        })
      } else if (messageType === 'answered') {
        this.showingEnd = false
        this.showingStart = false
        this.showingAnswered = true
        this.$store.commit('survey/survey', {
          hiddenEndMessage: true,
          hiddenAnsweredMessage: false,
          hiddenStartMessage: true
        })
      }

      this.$root.$emit('showing-message')
    },
    selectEndMessage (index) {
      this.showingStart = false
      this.showingAnswered = false
      this.showingEnd = true
      this.$store.commit('survey/survey', {
        selectedEndMessageIndex: index,
        hiddenEndMessage: false,
        hiddenAnsweredMessage: true,
        hiddenStartMessage: true
      })

      this.$root.$emit('end-message-updated', index)
    },
    addEndMessage (index) {
      const newIndex = index + 1
      const newEndMessage = {
        message: this.$i18n.t('questionsNav.message_title'),
        logo: { isHidden: false, url: this.survey.images[0] ? this.survey.images[0].value : '' },
        ifMetadata: [],
        showIfAll: [],
        showIfAny: []
      }

      this.survey.endMessages.splice(newIndex, 0, newEndMessage)
      this.$store.commit('survey/survey', this.survey)
      this.selectEndMessage(newIndex)
    },
    deleteEndMessage (index) {
      this.survey.endMessages.splice(index, 1)
    },
    cloneEndMessage (endMessage, index) {
      const newIndex = index + 1
      const newEndMessage = {
        message: endMessage.message ? endMessage.message : this.$i18n.t('questionsNav.message_title'),
        details: endMessage.details ? endMessage.details : {},
        logo: { isHidden: endMessage.logo.isHidden },
        ifMetadata: endMessage.ifMetadata ? endMessage.ifMetadata : [],
        showIfAll: endMessage.showIfAll ? endMessage.showIfAll : [],
        showIfAny: endMessage.showIfAny ? endMessage.showIfAny : []
      }

      this.survey.endMessages.splice(newIndex, 0, newEndMessage)
      this.$store.commit('survey/survey', this.survey)
    },
    // Selects the end message that is being dragged and dropped as the survey.selectedEndMessageIndex.
    checkMoveEndMessages (e) {
      const index = e.relatedContext.index

      if (e.draggedContext.index === 0 || e.draggedContext.futureIndex === 0) { return false }

      this.selectEndMessage(index)

      return true
    },
    // Selects the question that is being dragged and dropped as the survey.questionSelected.
    checkMove (e) {
      this.conditionsToDelete = []
      const currentIndex = e.draggedContext.index
      const futureIndex = e.draggedContext.futureIndex
      const draggedQuestion = e.draggedContext.element
      /* Checar se existe indicação de resposta no titulo da pergunta */
      const questionDesc = this.questions[currentIndex].question
      const questionTitle = this.questions[currentIndex].label
      if (questionDesc.includes('*|q:') || questionTitle.includes('*|q:')) {
        this.alertModalTitle = true
        return false
      }
      /*
        1. Checar se a questão sendo arrastada tem condições sobre outras do array e se alguma das condições é sobre
        uma question que está num index MAIOR OU IGUAL ao futureIndex da draggedQuestion, o que significaria que
        a condição por resposta é sobre uma question que virá depois da draggedQuestion. Assim sendo, não tem
        sentido ter uma condição de resposta sobre uma question que estará DEPOIS dela.
      */
      if (draggedQuestion.showIfAll.length > 0) {
        draggedQuestion.showIfAll.map(condition => {
          this.questions.map((question, questionIndex) => {
            if (question.id === condition.question && questionIndex >= futureIndex) {
              this.conditionsToDelete.push({
                where: 'questions',
                conditionIndex: condition.question,
                questionIndex: currentIndex,
                type: 'showIfAll'
              })
            }
          })
        })
      } else if (draggedQuestion.showIfAny.length > 0) {
        draggedQuestion.showIfAny.map(condition => {
          this.questions.map((question, questionIndex) => {
            if (question.id === condition.question && questionIndex >= futureIndex) {
              this.conditionsToDelete.push({
                where: 'questions',
                conditionIndex: condition.question,
                questionIndex: currentIndex,
                type: 'showIfAny'
              })
            }
          })
        })
      }
      /*
        2. Checar se alguma question tem condição sobre a resposta da draggedQuestion e se alguma dessas condições
        está num index MENOR OU IGUAL ao futureIndex da draggedQuestion, o que significaria que a condição por
        resposta nessa question é sobre uma question que estará DEPOIS dela.
      */
      this.questions.map((question, questionIndex) => {
        question.showIfAll.map(condition => {
          if (condition.question === draggedQuestion.id && questionIndex <= futureIndex) {
            this.conditionsToDelete.push({
              where: 'questions',
              conditionIndex: condition.question,
              questionIndex: questionIndex,
              type: 'showIfAll'
            })
          }
        })

        question.showIfAny.map(condition => {
          if (condition.question === draggedQuestion.id && questionIndex <= futureIndex) {
            this.conditionsToDelete.push({
              where: 'questions',
              conditionIndex: condition.question,
              questionIndex: questionIndex,
              type: 'showIfAny'
            })
          }
        })
      })

      if (this.conditionsToDelete.length > 0) {
        this.showModalConditions = true
        return false
      } else {
        const index = e.relatedContext.index
        const type = e.draggedContext.element.type
        this.selectQuestion(index, type)
        return true
      }
    },
    // When the drag and drop process ends, the @end event is emitted by the component.
    onEndUpdateOrder () {
      const questionsWithOrder = questionsHelper.addOrder(this.questions)
      this.questions = questionsWithOrder
      this.$store.commit('survey/survey', { questions: this.questions })
      this.$forceUpdate()
    },
    clearConditions () {
      const endMessages = [...new Set(this.survey.endMessages)]
      this.conditionsToDelete.map(item => {
        if (item.type === 'showIfAll') {
          if (item.where === 'questions') {
            this.questions[item.questionIndex].showIfAll = this.questions[item.questionIndex].showIfAll.filter(condition => condition.question !== item.conditionIndex)
          }
          if (item.where === 'endMessage') {
            endMessages[item.questionIndex].showIfAll = endMessages[item.questionIndex].showIfAll.filter(condition => condition.question !== item.conditionIndex)
          }
        }

        if (item.type === 'showIfAny') {
          if (item.where === 'questions') {
            this.questions[item.questionIndex].showIfAny = this.questions[item.questionIndex].showIfAny.filter(condition => condition.question !== item.conditionIndex)
          }
          if (item.where === 'endMessage') {
            endMessages[item.questionIndex].showIfAny = endMessages[item.questionIndex].showIfAny.filter(condition => condition.question !== item.conditionIndex)
          }
        }

        if (item.where === 'questions') {
          this.$store.commit('survey/upsertQuestion', this.questions[item.questionIndex])
        }
        this.$store.commit('survey/survey', { endMessages })
      })

      this.showModalConditions = false
      this.showModalConditionsToDeleteQuestion = false
      this.conditionsToDelete = []
      this.$root.$emit('conditions-updated-drag')
      return true
    },
    cancel () {
      this.answerInTitleCanBeDeleted = false
      this.showModalConditions = false
      this.showModalConditionsToDeleteQuestion = false
      this.showModalAutoMessageToDelete = false
      this.conditionsToDelete = []
      return false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
 .modal-conditions {
    h4.modal__wrapper__title  {
      line-height: 1 !important;
      margin-bottom: 8px !important;
    }
}

.nav-new {
  max-width: 243px !important;
  min-width: 243px !important;
  box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
  max-height: calc(100vh + 320px);
  min-height: 100% !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  background-color: #eff3f8 !important;
  padding: 0px !important;
}
.nav-content {
  padding: 20px;
  box-sizing: border-box !important;
  margin: 0 auto !important;
  width: 100% !important;
  display: -ms-flexbox !important;
  display: -webkit-box !important;
  display: flex !important;
  -ms-flex: 0 1 auto !important;
  -webkit-box-flex: 0 !important;
  flex: 0 1 auto !important;
  -ms-flex-direction: row !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  flex-direction: row !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  -webkit-box-pack: center !important;
  justify-content: center !important;
}
.nav-card {
  cursor: pointer;
  width: 100%;
  min-height: 223px;
  max-height: 223px;
  height: auto;
  overflow: hidden;
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 5px 7px 0 rgba(48,48,48,.16), 0 5px 4px 0 rgba(0,0,0,.01);
  -webkit-box-sizing: border-box;
  -webkit-box-shadow: 0 5px 7px 0 rgba(48,48,48,.16), 0 5px 4px 0 rgba(0,0,0,.01);
}

.end-card {
  position: relative;
  height: 225px;
  .question-nav-title {
    margin: 0 !important;
    margin-top: 5px !important;
  }
  .info-first-end-message {
    position: absolute;
    top: 0.2vw;
    right: 0.2vw;
    color: var(--accent-color);
    font-size: 1.2vw;
    cursor: help;
  }
}

.green-border {
  border: 1px solid #46f21f;
}
.actions-nav {
  @extend %animated-faster;
  @extend %fadeIn;
  margin-top: -7px;
  margin-left: -2px !important;
  height: 50px !important;
  width: 100% !important;
  border-top: 0.5px solid #ddd !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.clone-icon {
  font-size: 20px !important;
  margin-left: 10px;
}
.question-nav-title {
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 15px !important;
}
.question-nav-text {
  margin-top: 5px !important;
  font-size: 12px !important;
}
.add-question-btn {
  margin-top: 15px !important;
  margin-bottom: -20px !important;
  button.binds-button.medium.primary.floating {
    width: 35px !important;
    height: 35px !important;
    margin-left: -3px;
    i {
      font-size: 19px !important;
      margin-top: 7px !important;
      color: #fff !important;
    }
  }
}
.end-message-details {
  font-size: 14px !important;
  text-align: center !important;
  margin-top: 30px !important;
}
.end-message-link {
  font-size: 14px !important;
  text-align: center !important;
  margin-top: 10px !important;
}
.transform-ui {
  width: 100% !important;
  pointer-events:none;
}

.t-matrixlike {
  width: 155% !important;
  position: absolute;
  top: 150px;
  margin-top: -190px;
  margin-left: -55px;
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  .content-like {
    top: 100px;
    position: absolute;
    .content-rating {
      width: 100% !important;
    }
  }

  .section-label {
    height: 35px !important;

    h6 {
      font-size: 18px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .section-rating {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    .up {
      right: 15px !important;
      top: 5px !important;
    }
    .down {
      left: 15px !important;
      top: 7px !important;
    }
  }
}

.t-5emo {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin-top: -44px !important;
}
.t-10num {
  width: 400% !important;
  transform: scale(0.3);
  -webkit-transform: scale(0.3);
  margin-left: -217px !important;
  margin-top: -55% !important;
  margin-bottom: -30% !important;
}
.t-5num {
  min-width: 310px;
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin-left: -37px !important;
  margin-top: -16%;
  padding-top: 88px;
  padding-bottom: 25px;
}
.t-stars {
  min-width: 310px;
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin-top: -45px !important;
  margin-left: -45px !important;
  padding-top: 88px;
  padding-bottom: 25px;
  & .star-span {
    top: 22px !important;
  }
}
.t-5nes {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin: 30px 0 60px -93px;
  min-width: 583px ;
}
.t-0to5 {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin: 40px 0 20px -107px;
  min-width: 583px;
}
.t-1to5 {
  transform: scale(0.65);
  -webkit-transform: scale(0.65);
  margin: 40px 0 30px -83px;
  min-width: 583px;
}
.t-1to7 {
  transform: scale(0.5);
  -webkit-transform: scale(0.5);
  margin: 25px 0 25px -136px;
  min-width: 583px ;
}
.t-1to7button {
  transform: scale(0.5) !important;
  -webkit-transform: scale(0.5) !important;
  padding-top: 20px;
  margin-top: -100px;
  width: 110% !important;
}

.t-5radio {
  transform: scale(0.6);
  min-width: 235px;
  margin: -45px 0 0 -20px;
}

.t-matrix1to5 {
  height: 200px;
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin: -65px 0 0 -175px;
  min-width: 550px;

  .subject-label h6 {
    font-size: 22px;
    max-width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.t-matrix10num {
  height: 200px;
  margin: -73px 0 0 -190px;
  transform: scale(0.45);
  -webkit-transform: scale(0.45);
  min-width: 600px;

  .subject-label h6 {
    font-size: 24px;
    margin-left: 10px;
    max-width: 95%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.t-nps,
.t-0to10 {
  width: 661px !important;
  transform: scale(0.3);
  -webkit-transform: scale(0.33);
  margin-left: -215px;
  min-width: 583px !important;
  padding-top: 45px;
}
.t-1to10 {
  min-width: 610px;
  transform: scale(0.35);
  -webkit-transform: scale(0.35);
  margin: 35px 0 20px -187px;
}
.t-yn {
  min-width: 285px;
  transform: scale(0.5);
  -webkit-transform: scale(0.5);
  margin: -25px 0 15px -44px;
  button {
    width: 65% !important;
  }
}
.t-ynd, .t-ynm {
  min-width: 310px;
  transform: scale(0.6) !important;
  -webkit-transform: scale(0.6) !important;
  margin-left: -53px !important;
  margin-top: -12% !important;
}
.t-text > .input-text {
  transform: scale(0.7) !important;
  -webkit-transform: scale(0.7) !important;
  min-width: 225px !important;
  margin-left: -10px !important;
  margin-top: 20px !important;
  margin-bottom: 20px;
}
.t-text > .comment {
  min-width: 310px;
  margin-left: -50px;
  & div {
    margin: -80px 0 0 -80px;
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
  }
}
.t-inputext {
  transform: scale(0.4);
  -webkit-transform: scale(0.4);
  margin-left: -141px !important;
  margin-top: -3% !important;
  margin-bottom: -32% !important;
}
.t-referral {
  transform: scale(0.65);
  -webkit-transform: scale(0.65);
  margin-top: -35%;
  margin-bottom: -30%;
  min-width: 375px;
  margin-left: -90px;
}
.t-referraldynamic {
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
  min-width: 300px;
  margin-left: -95px;
  margin-top: -15px;
  margin-bottom: 15px;
}
.t-single {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin: -26% 0 -20% -50%;
  width: 230% !important;
  height: 190px !important;
  h6 {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.t-multiple {
  width: 210% !important;
  height: 220px !important;
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  margin: -28% 0 15% -33% !important;
  h6 {
    max-width: 200px;
    text-overflow: ellipsis;
    padding-top: 23px !important;
    white-space: nowrap;
    overflow: hidden;
  }
}
.btn-preview-start {
  padding: 7px 12px 5px;
  font-size: 12px;
  border-radius: 5px;
}
.question-order {
  font-size: 12px !important;
  margin-top: -8px !important;
  float: right !important;
  margin-right: -8px !important;
}
.pt5 {
  padding-top: 5px !important;
}
.img-preview {
  display: block;
  max-width: 90%;
  margin: 0px auto;
}
.img-preview-default {
  max-width: 160px;
  max-height: 100px;
  margin-top: 20px;
}
.content-img-preview-messages {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  margin: 0 auto;
}
.head-questions-prev {
  max-height: 70px;
  text-align: center;
  padding-top: 8px;
  h6 {
    min-height: 15px;
    max-height: 35px;
    overflow-y: hidden;
  }
}
</style>
