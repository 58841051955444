import { constants } from './constants'

const { SUPER_ADMIN, ADMIN } = constants.user.TYPES

function hasPermission (user, fields) {
  // Permissão aprovada para superusuários
  const allowedUserTypes = [SUPER_ADMIN, ADMIN]
  if (allowedUserTypes.includes(user.type)) return true

  // Verifica se todos os campos têm permissões verdadeiras
  return fields.every(field => user.features[field])
}

export const userRoles = { hasPermission }
