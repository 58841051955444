<template>
  <div class="container-churn-health" :style="{'--color': returnColorGraph}">
    <div class="content-canvas-churn-health">
      <canvas id="content-churn-health"></canvas>
       <div class="ball-churn-health">
        <i class="material-icons icon-sentiment-health" :ref="`icon-churn-${card._id}`">autorenew</i>
       </div>
    </div>
    <div class="content-labels-churn-health">
      <div class="content-result-churn-health" :ref="`result-churn-${card._id}`">
        <i class="material-icons icon-sentiment-health">autorenew</i>
        <p class="text-label-value-health" :ref="`text-value-${card._id}`"></p>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import Chart from 'chart.js'
import mixins from '@/_mixins/health'
window.Chart = Chart

export default {
  name: 'ChurnHealth',
  props: ['card'],
  mixins: [mixins],
  components: {},
  data () {
    return {
      dataChart: {},
      valueChurn: this.card.average.churn
    }
  },
  mounted () {
    this.renderData()
  },
  computed: {
    returnColorGraph () { return this.getColorForHealth(this.valueChurn, 'churn') }
  },
  methods: {
    async renderData () {
      const formattedData = this.valueChurn
      this.dataChart = {
        labels: ['Churn', ''],
        datasets: [{
          backgroundColor: [this.returnColorGraph, '#ffffff'],
          data: [formattedData, 100 - formattedData]
        }]
      }

      const ctx = document.getElementById('content-churn-health').getContext('2d')

      this.graph = new window.Chart(ctx, {
        type: 'doughnut',
        data: this.dataChart,
        options: {
          responsive: true,
          legend: { display: false },
          cutoutPercentage: 65,
          animation: {
            duration: 3000
          },
          tooltips: {
            enabled: false
          }
        }
      })

      this.animateValue()
    },
    animateValue () {
      const iconValue = this.$refs[`icon-churn-${this.card._id}`]

      gsap.to(iconValue, {
        duration: 2.6,
        rotation: 360,
        onComplete: () => { this.animateText() }
      })
    },
    animateText () {
      const divElementText = this.$refs[`result-churn-${this.card._id}`]
      const textElement = this.$refs[`text-value-${this.card._id}`]

      if (!textElement || !divElementText) return

      const text = this.getLabelChurn(this.valueChurn)

      textElement.innerHTML = text.split('').map(char => {
        return `<span class="span-label-health">${char === ' ' ? '&nbsp;' : char}</span>`
      }).join('')

      const chars = textElement.querySelectorAll('.span-label-health')

      gsap.set(divElementText, { display: 'flex' })

      gsap.set(chars, { opacity: 0 })

      gsap.to(chars, {
        opacity: 1,
        duration: 0.1,
        stagger: 0.1,
        ease: 'none'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-churn-health {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .content-canvas-churn-health {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    .ball-churn-health {
      position: absolute;
      background: var(--color);
      width: 4.5vw;
      height: 4.5vw;
      border-radius: 50%;
      box-shadow: 0 0 4px var(--color);
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-sentiment-health {
        font-size: 2.1vw;
        color: #ffffff;
      }
    }
  }
  .content-labels-churn-health {
    height: 20%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .content-result-churn-health {
      position: relative;
      background: #ffffff;
      width: 75%;
      height: 2vw;
      border-radius: 1.5vw 0.4vw 0.4vw 1.5vw;
      border: 1px solid #e1e1e1;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-sentiment-health {
        position: absolute;
        left: 0.1vw;
        transform: translateY(-50%);
        color: #808080;
        font-weight: 500;
        border-radius: 50%;
        font-size: 1.5vw;
        animation: spin 2s linear forwards;
      }
      .text-label-value-health {
        font-size: 0.9vw;
        font-weight: 500;
        color: #808080;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@keyframes spin360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
</style>
