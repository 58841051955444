<template>
  <div id="canva-graph">
    <canvas :id="makeSpecialId()" width="600" height="400"></canvas>
  </div>
</template>

<script>
import { canvasMaker, formatters, gridMaker, tooltipMaker } from '@/_helpers'
import { rankingService } from '@/_services'
import i18n from '../../../translations/i18n'

import('chart.js').then((chartjs) => {
  window.Chart = chartjs.default
})
export default {
  name: 'RankingChart',
  props: ['rankingData', 'questionSelected'],
  data  () {
    return {
      chart: null,
      overtimeData: [],
      overtimeConfig: {
        colors: [
          '#805ea0', '#966fbc', '#ae74e8', '#b97bf7', '#ce9eff',
          '#805ea0', '#966fbc', '#ae74e8', '#b97bf7', '#ce9eff'
        ],
        graphicType: 'line',
        showTextOnly: false,
        syncQuestion: false,
        textAnalytic: false,
        textColor: 'white'
      }
    }
  },
  methods: {
    makeSpecialId () {
      this.rankingData.specialId = canvasMaker.makeCanvasIdWithoutParam()
      return this.rankingData.specialId
    },
    /**
     * render a the chart container in screen
     * @param  {[HTMLCanvasElement]} floatingChart [the chart that will be inserted in the container]
     * @param  {[string]} positionY [the position in Y axis]
     * @param  {[string]} positionX [the position in X axis]
     * @return {[HTMLDivElement]} [returns a div element]
     */
    renderChartContainer (floatingChart, positionY, positionX) {
      const container = document.createElement('div')
      const span = document.createElement('span')
      const closeButton = document.createElement('button')
      const expandButton = document.createElement('button')

      expandButton.innerText = 'Expand'
      expandButton.style.width = 'fit-content'
      expandButton.style.background = 'none'
      expandButton.style.fontSize = '18px'
      expandButton.className = 'material-symbols-outlined'
      expandButton.innerText = 'open_in_full'

      closeButton.innerText = 'X'
      closeButton.style.fontSize = '18px'
      closeButton.style.width = 'fit-content'
      closeButton.style.background = 'none'

      span.style.padding = '.3rem .6rem'
      span.style.display = 'flex'
      span.style.justifyContent = 'space-between'
      span.style.backgroundColor = '#fff'
      span.style.borderRadius = '5px 5px 0 0'

      container.style.position = 'absolute'
      container.style.top = positionY
      container.style.left = positionX
      container.style.width = '400px'
      container.style.borderRadius = '0 0 5px 5px'
      container.style.transition = 'all 300ms ease-in-out'
      container.style.boxShadow = '2px 5px 7px 0 rgba(48, 48, 48, 0.5), 2px 5px 4px 0 rgba(0, 0, 0, 0.2)'

      span.appendChild(expandButton)
      span.appendChild(closeButton)
      container.appendChild(span)
      container.appendChild(floatingChart)

      closeButton.onclick = () => container.remove()
      expandButton.onclick = () => {
        container.style.width === '400px' ? container.style.width = '750px' : container.style.width = '400px'
        expandButton.innerText === 'open_in_full' ? expandButton.innerText = 'hide' : expandButton.innerText = 'open_in_full'
      }

      return container
    },
    async handleClick (event, chartElement) {
      document.getElementById(this.rankingData.specialId).style.cursor = 'wait'
      const metadata = this.rankingData[chartElement[0]._index].metadata.trim()
      await this.setRankingOvertime(metadata)

      const activePoints = this.chart.getElementsAtEvent(event)
      if (activePoints.length > 0) {
        const firstPoint = activePoints[0]
        const label = this.chart.data.labels[firstPoint._index]
        // const value = this.chart.data.datasets[firstPoint._datasetIndex].data[firstPoint._index]

        if (document.getElementById(label)) return

        const floatingChart = document.createElement('canvas')
        floatingChart.id = label
        floatingChart.style.backgroundColor = '#fff'
        floatingChart.style.borderRadius = '5px'

        document.getElementById('canva-graph')
          .appendChild(this.renderChartContainer(floatingChart, `${event.layerY - 230}px`, `${event.clientX}px`))

        if (!this.overtimeData) {
          return false
        }

        const groups = []
        const labels = []
        let lines = []
        let optionsValues = []
        let graphicValues = []

        // Returns just items that are not null, and we see it by the "qty" attribute
        const overtimeByQuestionId = this.overtimeData.find(item => this.questionSelected._id === item.question)
        const dataTime = overtimeByQuestionId.overall.qty ? Object.assign({}, overtimeByQuestionId) : []
        const gridRange = gridMaker.checkIfPeriodIsByDay(this.$route.name, this.$route.query.q)
        let formatDate = 'MMM Do' // esse if é para saber se o filtro é por hora ou dias, com isso formatamos melhor os labels de data do gráfico
        if (gridRange) {
          formatDate = 'HH:mm'
        }
        // Para gráfico de mudança de average no tempo. Uma só linha.
        const nesOrNps10numm = this.questionSelected.ui === '10num' && (this.questionSelected.type === 'nps' || this.questionSelected.type === 'nes')
        const kpiQuestionAvg = this.questionSelected.type === 'kpi' && this.kpiByAverage
        if (nesOrNps10numm || kpiQuestionAvg || this.questionSelected.ui === 'ynm') {
          const avgGrades = []
          if (!dataTime.overtime) {
            return false
          }
          dataTime.overtime.forEach((item, i) => {
            // devemos considerar um average vazio sem data certa?
            if (i > 0 && dataTime.overtime[i][2] !== null) {
              const avg = Math.round(dataTime.overtime[i][2] * 100) / 100
              const time = formatters.formatDateByLangAndFixTimeZone(dataTime.overtime[i][0], this.lang, formatDate)
              avgGrades.push(avg)
              labels.push(time)
            }
          })

          lines = [{
            label: i18n.t('dashboard.graphics.average'),
            data: avgGrades,
            borderColor: this.overtimeConfig.colors[1],
            pointBorderColor: this.overtimeConfig.colors[0],
            pointBackgroundColor: this.overtimeConfig.colors[0],
            backgroundColor: ['rgba(255, 255, 255, .2)'],
            borderWidth: 2
          }]

          optionsValues = {
            scales: {
              xAxes: [{
                ticks: {
                  display: true // this will remove only the label
                }
              }]
            },
            legend: {
              display: this.hideOrShow,
              position: 'bottom',
              labels: {
                usePointStyle: true,
                padding: 20
              }
            }
          }
          if (kpiQuestionAvg) {
            optionsValues.scales.yAxes = [{
              ticks: {
                max: 100,
                beginAtZero: true,
                precision: 0,
                padding: 25
              }
            }]
          }
        } else if ((['ynd', 'yn', 'single', 'singleOther', '1to10', 'multiple', 'multipleOther', '0to5', '1to7', '1to7button', '1to5', '5num', '0to10', '10num', '5emo', '5radio'].indexOf(this.questionSelected.ui) > -1) && this.questionSelected.type !== 'nes') {
          // Separar por grupos - START
          if (!dataTime.overtime) {
            return false
          }
          //  Gráfico de soma, conta a quantidade de QTY
          dataTime.overtime.forEach((item, i) => {
            // remove empty values
            if (i <= 0 || dataTime.overtime[i][0] === null || item[3] === null) {
              return false
            }
            const time = formatters.formatDateByLangAndFixTimeZone(dataTime.overtime[i][0], this.lang, formatDate)
            labels.push(time)
            // const groupName = ''
            if (this.questionSelected.type === 'nes') {
              let totalME = 0
              let totalLE = 0
              let totalEE = 0
              dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
                if (indexOfMe >= 4) {
                  if (overtimeHeader < 49) {
                    totalLE += item[indexOfMe]
                    return false
                  }
                  if (overtimeHeader === 50) {
                    totalEE += item[indexOfMe]
                    return false
                  }
                  totalME += item[indexOfMe]
                }
              })

              if (!groups[i18n.t('dashboard.graphics.le')]) {
                groups[i18n.t('dashboard.graphics.le')] = []
              }
              if (!groups[i18n.t('dashboard.graphics.ee')]) {
                groups[i18n.t('dashboard.graphics.ee')] = []
              }
              if (!groups[i18n.t('dashboard.graphics.me')]) {
                groups[i18n.t('dashboard.graphics.me')] = []
              }
              groups[i18n.t('dashboard.graphics.le')].push(totalLE)
              groups[i18n.t('dashboard.graphics.ee')].push(totalEE)
              groups[i18n.t('dashboard.graphics.me')].push(totalME)
            }

            if ((['csat', 'ces', 'nvs'].indexOf(this.questionSelected.type) >= 0) && ['5emo', '1to5', '5num', '10num', '1to7', '1to7button', '5radio'].indexOf(this.question.ui) >= 0) {
              /*
                CSAT, CES 2.0 e NVS devem mostrar somente a média (average) nas labels de cada ponto,
                tudo numa só linha.
              */
              const csatAverageRounded = Math.round(item[2] * 100) / 100
              if (!groups[i18n.t('dashboard.graphics.average')]) {
                groups[i18n.t('dashboard.graphics.average')] = []
              }
              groups[i18n.t('dashboard.graphics.average')].push(csatAverageRounded)
            }

            if (this.questionSelected.type === 'kpi' && this.questionSelected.ui === '5emo') {
              let verygood = 0
              let excelent = 0
              let satisfied = 0
              let verybad = 0
              let bad = 0

              dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
                if (indexOfMe >= 4) {
                  switch (overtimeHeader) {
                    case '0': {
                      bad += item[indexOfMe]
                      break
                    }
                    case '25': {
                      verybad += item[indexOfMe]
                      break
                    }
                    case '50': {
                      satisfied += item[indexOfMe]
                      break
                    }
                    case '75': {
                      excelent += item[indexOfMe]
                      break
                    }
                    case '100': {
                      verygood += item[indexOfMe]
                    }
                  }
                }
              })

              this.fontSize = 24
              this.fontFamily = 'icomoon'
              const labels = ['\uE608', '\uE607', '\uE606', '\uE600', '\uE603']
              const values = [verygood, excelent, satisfied, verybad, bad]

              labels.forEach((el, index) => {
                if (!groups[el]) {
                  groups[el] = []
                }
                groups[el].push(values[index])
              })
            }

            if (this.questionSelected.ui === 'yn') {
              let opt1 = 0
              let opt2 = 0
              dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
                if (indexOfMe >= 4) {
                  if (overtimeHeader < 100) {
                    opt1 += item[indexOfMe]
                    return false
                  }
                  opt2 += item[indexOfMe]
                }
              })

              if (!groups[i18n.t('dashboard.graphics.op1')]) {
                groups[i18n.t('dashboard.graphics.op1')] = []
              }

              if (!groups[i18n.t('dashboard.graphics.op2')]) {
                groups[i18n.t('dashboard.graphics.op2')] = []
              }

              groups[i18n.t('dashboard.graphics.op1')].push(opt1)
              groups[i18n.t('dashboard.graphics.op2')].push(opt2)
            }

            if (this.questionSelected.ui === 'ynd') {
              let opt1 = 0
              let opt2 = 0
              let opt3 = 0
              dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
                if (indexOfMe >= 4) {
                  if (overtimeHeader === 0) {
                    opt3 += item[indexOfMe]
                    return false
                  }
                  if (overtimeHeader === 50) {
                    opt2 += item[indexOfMe]
                    return false
                  }
                  opt1 += item[indexOfMe]
                }
              })

              if (!groups[i18n.t('dashboard.graphics.op1')]) {
                groups[i18n.t('dashboard.graphics.op1')] = []
              }
              if (!groups[i18n.t('dashboard.graphics.op4')]) {
                groups[i18n.t('dashboard.graphics.op4')] = []
              }
              if (!groups[i18n.t('dashboard.graphics.op2')]) {
                groups[i18n.t('dashboard.graphics.op2')] = []
              }
              groups[i18n.t('dashboard.graphics.op1')].push(opt1)
              groups[i18n.t('dashboard.graphics.op4')].push(opt2)
              groups[i18n.t('dashboard.graphics.op2')].push(opt3)
            }

            if (this.questionSelected.type === 'enum') {
              const results = {}
              dataTime.overtime[0].forEach((overtimeHeader, indexOfMe) => {
                if (indexOfMe >= 4) {
                  if (!results[overtimeHeader]) {
                    results[overtimeHeader] = { key: overtimeHeader, doc_count: 0 }
                  }
                  results[overtimeHeader].doc_count = item[indexOfMe] || 0
                  return false
                }

                Object.keys(results).forEach(currentKey => {
                  if (!groups[currentKey]) {
                    groups[currentKey] = []
                  }
                  groups[currentKey].push(results[currentKey].doc_count)
                })
              })
              Object.keys(results).forEach(currentKey => {
                if (!groups[currentKey]) {
                  groups[currentKey] = []
                }
                groups[currentKey].push(results[currentKey].doc_count)
              })
            }
          })

          graphicValues = []
          for (const groupName in groups) {
            graphicValues.push({ group: groupName, doc_count: groups[groupName] })
          }

          optionsValues = {
            responsive: true,
            tooltips: {
              backgroundColor: '#227799',
              bodyFontFamily: this.fontFamily,
              callbacks: {
                afterBody: function (tooltipItem) {
                  const value = parseFloat(tooltipItem.value)
                  return value
                }
              }
            },
            legend: {
              display: this.hideOrShow,
              position: 'bottom',
              labels: {
                usePointStyle: true,
                padding: 8,
                boxWidth: 9,
                fontFamily: this.fontFamily,
                fontSize: this.fontSize,
                fontColor: '#939393'
              }
            },
            pointStyle: 'circle',
            lineTension: 1,
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  precision: 0,
                  padding: 25
                }
              }],
              xAxes: [{
                ticks: {
                  display: true
                }
              }]
            }
          }
          lines = graphicValues.map((line, index) => {
            const colour = this.overtimeConfig.colors[index]
            const newLine = {
              label: line.group,
              data: line.doc_count,
              borderColor: colour,
              pointBorderColor: colour,
              pointBackgroundColor: colour,
              backgroundColor: ['rgba(255, 255, 255, .2)'],
              borderWidth: 2
            }
            return newLine
          })
          // Separar por grupos - END
        } else if (this.questionSelected.type === 'matrix') {
          const results = {}
          if (!dataTime.overtime) {
            return false
          }
          dataTime.overtime.forEach((item, i) => {
            if (i <= 0 || dataTime.overtime[i][0] === null || item[3] === null) {
              return false
            }
            const time = formatters.formatDateByLangAndFixTimeZone(dataTime.overtime[i][0], this.lang, formatDate)
            labels.push(time)
            dataTime.overtime[0].forEach(async (overtimeHeader, indexOfMe) => {
              if (indexOfMe >= 3) {
                const findLabel = await window._.find(this.questionSelected.subjects, { _id: overtimeHeader })
                if (findLabel) {
                  if (!results[findLabel.label]) {
                    results[findLabel.label] = { key: findLabel.label, doc_count: 0 }
                  }
                  const value = item[indexOfMe] === 0 ? 0 : item[indexOfMe] / 10
                  results[findLabel.label].doc_count = value
                  return false
                }
              }
            })

            Object.keys(results).forEach(currentKey => {
              if (!groups[currentKey]) {
                groups[currentKey] = []
              }
              groups[currentKey].push(results[currentKey].doc_count)
            })
          })

          graphicValues = []
          for (const groupName in groups) {
            graphicValues.push({ group: groupName, doc_count: groups[groupName] })
          }

          optionsValues = {
            responsive: true,
            tooltips: {
              backgroundColor: '#227799',
              callbacks: {
                afterBody: function (tooltipItem) {
                  const value = parseFloat(tooltipItem.value)
                  return value
                }
              }
            },
            legend: {
              display: this.hideOrShow,
              position: 'bottom',
              labels: {
                usePointStyle: true,
                padding: 20
              }
            },
            pointStyle: 'circle',
            lineTension: 1,
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  precision: 0,
                  padding: 25
                }
              }],
              xAxes: [{
                ticks: {
                  display: true
                }
              }]
            }
          }
          lines = graphicValues.map((line, index) => {
            const colour = this.overtimeConfig.colors[index]
            const newLine = {
              label: line.group,
              data: line.doc_count,
              borderColor: colour,
              pointBorderColor: colour,
              pointBackgroundColor: colour,
              backgroundColor: ['rgba(255, 255, 255, .2)'],
              borderWidth: 2
            }
            return newLine
          })
        } else {
          // gráfico de média dos valores, somando item.average
          const valueGroup = []
          dataTime.forEach((item, index) => {
            const value = Math.round(item.average * 100) / 100
            const time = formatters.formatDateByLangAndFixTimeZone(item.from, this.lang, formatDate)
            graphicValues.push(value)
            labels.push(time)
            valueGroup[index] = item.valueGroup
          })
          lines = [{
            data: graphicValues,
            borderColor: this.overtimeConfig.colors[1],
            pointBorderColor: this.overtimeConfig.colors[0],
            pointBackgroundColor: this.overtimeConfig.colors[0],
            backgroundColor: ['rgba(255, 255, 255, .2)'],
            borderWidth: 2,
            valueGroup: valueGroup
          }]
          optionsValues = {
            tooltips: {
              enabled: false,
              custom: function (tooltipModel) {
                var position = this._chart.canvas.getBoundingClientRect()
                tooltipMaker.makeCustomTooltip(tooltipModel, lines, position, lines[0].pointBackgroundColor)
              }
            },
            responsive: true,
            legend: {
              display: this.hideOrShow,
              position: 'bottom',
              labels: {
                usePointStyle: true,
                padding: 20
              }
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  precision: 0
                }
              }],
              xAxes: [{
                ticks: {
                  display: true
                }
              }]
            }
          }
        }

        if (this.questionSelected.type === 'enum') {
          optionsValues.legend.position = 'right'
          optionsValues.legend.rtl = true
        }

        const chartData = {
          type: 'line',
          data: {
            labels: labels,
            datasets: lines
          },
          options: optionsValues
        }

        const newChart = await new window.Chart(floatingChart, {
          type: chartData.type,
          data: chartData.data,
          options: chartData.options,
          plugins: [{
            afterLayout: function (chart) {
              chart.legend.legendItems.map(label => {
                const formattedLabel = label.text.length > 40 ? label.text.slice(0, 50) + '...' : label.text
                label.text = formattedLabel
              })
            }
          }]
        })

        document.getElementById(this.rankingData.specialId).style.cursor = 'auto'
        return newChart
      }
    },
    async setRankingOvertime (metadata) {
      const query = this.$route.query
      const newQuery = JSON.parse(formatters.formatDateByAddTimezone(query.q, 0, 'hours'))
      const respondedAt = { ...this.$store.getters['filters/getDashFilter'].query.respondedAt }

      const startDate = this.$moment(respondedAt.$gte, 'YYYY-MM-DDTHH:mm:ss')
      const current = this.$moment().endOf('day').add(3, 'hours')
      const asHours = this.$moment.duration(startDate.diff(current)).asHours()
      const beforeStartDate = this.$moment(startDate).subtract((asHours * -1), 'hours')
      const formatedBeforeStartDate = beforeStartDate.endOf('day').add(3, 'hours').format('YYYY-MM-DDTHH:mm:ss')

      newQuery.$and.find(item => item.respondedAt).respondedAt.$gte = formatedBeforeStartDate

      const dateGrid = gridMaker.getGrid(formatedBeforeStartDate, respondedAt.$lte)
      query.grid = dateGrid

      // eslint-disable-next-line no-prototype-builtins
      newQuery.$and = newQuery.$and.filter(item => !item.hasOwnProperty(`seed.${query.groupBy}`))
      newQuery.$and = newQuery.$and.filter(item => !item.question)

      const newObject = {}
      const questionId = { question: { $eq: this.questionSelected._id } }
      newObject[`seed.${query.groupBy}`] = { $like: metadata.trim() }
      newQuery.$and.push(questionId)
      newQuery.$and.push(newObject)
      query.q = JSON.stringify(newQuery)

      const response = await rankingService.getRankingOvertime(query)

      this.overtimeData = response
    }
  },
  async mounted () {
    const labels = []
    const data = []
    const fontSize = 9
    const fontFamily = "'Avenir', Helvetica, Arial, sans-serif;"

    if (this.rankingData) {
      this.rankingData.map((item, index) => {
        if (index <= 29) {
          const formattedLabel = item.metadata.length > 30 ? item.metadata.slice(0, 30) + '...' : item.metadata

          labels.push(formattedLabel)
          data.push(['csat', 'nps', 'ces', 'nes', 'kpi'].includes(this.questionSelected.type) ? item.score : item.responses)
        }
      })
    }

    const planetChartData = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          { // one line graph
            data: data,
            backgroundColor: [
              '#a87df5', '#fc90a4', '#00aaa7', '#673ab7', '#9f95f5',
              '#FFB500', '#f55bed', '#CA1E54', '#4D5359', '#79C99E',
              '#97DB4F', '#27B573', '#FF7663', '#1B9AAA', '#FFC43D',
              '#006CE8', '#FF1600', '#F2AE2E', '#2E0259', '#000000',
              '#63FF61', '#C28151', '#F22E52', '#421CFC', '#400406',
              '#FEA443', '#F3FEB0', '#A5AAA3', '#6593A6', '#FF0005'
            ]
          }
        ]
      },
      options: {
        onClick: (e, chartElement) => {
          this.handleClick(e, chartElement)
        },
        onHover: function (event, chartElement) {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
        },
        legend: {
          display: false
        },
        pointStyle: 'circle',
        responsive: true,
        lineTension: 1,
        scales: {
          xAxes: [{
            gridLines: {
              color: 'rgba(0, 0, 0, 0)'
            },
            ticks: {
              fontFamily: fontFamily,
              fontSize: fontSize,
              fontColor: '#939393'
            }
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              color: 'rgba(0, 0, 0, 0)'
            },
            ticks: {
              beginAtZero: true,
              padding: 25
            }
          }]
        }
      }
    }

    const ctx = document.getElementById(this.rankingData.specialId)
    const chart = await new window.Chart(ctx, {
      type: planetChartData.type,
      data: planetChartData.data,
      options: planetChartData.options
    })

    this.chart = chart

    return chart
  }
}
</script>
