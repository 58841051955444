import {
  userService
} from '@/_services'
function initialState () {
  return {
    loggedIn: false,
    user: {
      image: '',
      account: {
        theme: initialTheme()
      }
    },
    lang: '',
    exceededLimitMessage: false
  }
}
function initialTheme () {
  return { colors: { accent: '#ed4d7f', primary: '#673ab7', primaryText: 'light', secondary: '#3f3356' }, mainLogo: 'https://img.binds.co/59e4587f-de97-4b5f-ad66-e6965323b244/', smallLogo: 'https://res.cloudinary.com/binds-co/image/upload/v1508526527/placeholder_je0010.png', mixBlendMode: false }
}
const state = initialState

const getters = {
  getUser: state => state.user,
  getAccount: state => state.user.account,
  getLang: state => state.lang,
  getExceededLimitMessage: state => state.exceededLimitMessage
}

const mutations = {
  resetToInitialState (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  loginSuccess (state, payload) {
    state.user = payload
  },
  loginFailure (state) {
    state.status = {}
  },
  /**
   * remove todos os itens do localstorage
   * @param {Object} state current state of account
   */
  logout () {
    localStorage.clear()
    this.$router.go('/login')
  },
  updateProfile (state, payload) {
    Object
      .keys(payload)
      .forEach(key => {
        const value = payload[key]
        state.user[key] = value
      })
  },
  setUser (state, user) {
    state.user = user
    state.loggedIn = !!user
  },
  updateUserImage (state, payload) {
    state.user.image = payload
  },
  updateUserFeatures (state, payload) {
    state.user.features = payload
  },
  setAccount (state, account) {
    if (!account.theme) {
      account.theme = initialTheme()
    }
    state.user.account = account
  },
  setDefaultTheme (state) {
    state.user.account.theme = initialTheme()
  },
  language (state, lang) {
    state.lang = lang
    localStorage.setItem('bindsLang', lang)
  },
  updateImageLogoUrl (state, image) {
    state.user.account.logoUrl = image.imageUrl
  },
  updateImageMainLogo (state, image) {
    state.user.account.theme.mainLogo = image.imageUrl
  },
  updateImageSmallLogo (state, image) {
    state.user.account.theme.smallLogo = image.imageUrl
  },
  setExceededLimitMessage (state, payload) {
    state.exceededLimitMessage = payload
  },
  updatetermsSettingsLimit (state, limit) {
    state.user.account.termsSettings.limit = limit
  },
  updatetermsSettingsEnabled (state, enabled) {
    state.user.account.termsSettings.enabled = enabled
  },
  updateBrandsTracking (state, payload) {
    state.user.account.brandsTracking = payload
  },
  updateBrandById (state, payload) {
    const findBrand = state.user.account.brandsTracking.findIndex(brand => brand._id === payload._id)
    if (findBrand !== -1) state.user.account.brandsTracking[findBrand] = payload
  }
}

const actions = {
  signup (commit, {
    name,
    email,
    phone,
    company
  }) {
    userService.signup(name, email, phone, company)
  },
  resetpass (commit, {
    email
  }) {
    userService.resetpass(email)
  },
  logout ({
    commit
  }) {
    userService.logout()
    commit('logout')
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
