<template>
  <div>
    <Menu :showSubMenu="false"></Menu>
    <div class="row header-template">
      <div class="back-button" v-title="$t('login.back')">
        <i @click="$router.go(-1)" class="material-icons">arrow_back</i>
      </div>
      <app-heading level="h3" class="title-template">{{ $t('start.survey_templates') }}</app-heading>
    </div>

    <div class="col-sm-4 col-md-2 col-lg-2 search-input" id="template-search">
      <app-form-input secondary v-model="search" :placeholder="$t('surveyList.search_by_title')"/>
    </div>

    <div class="row templates-container">
      <div v-for="(template, index) in filteredSurveys" v-bind:key="index">
        <TemplateCard class="template-card" :item="template"/>
      </div>
    </div>
    <div class="loading-templates-list" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
  </div>
</template>
<script>
import Menu from '../components/Menu.vue'
import TemplateCard from '../components/cards/TemplateCard.vue'
import { templatesService } from '@/_services'
import { responseOptions } from '@/_helpers'
import { mapGetters } from 'vuex'

export default {
  name: 'Templates',
  components: {
    Menu,
    TemplateCard,
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')
  },
  data () {
    return {
      list: [],
      fixed: '',
      loading: false,
      search: ''
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.setStaticSidebar)
  },
  async mounted () {
    this.loading = true
    window.addEventListener('scroll', this.setStaticSidebar)
    const list = await templatesService.getAllTemplates()
    if (!list) {
      this.$store.commit('alerts/alert', {
        message: this.$i18n.t('templates.error_getting_templates'),
        position: 'bottomRight',
        showAlert: true
      })
    } else {
      list.map((i, idx) => {
        const question = i.survey.questions[0]
        i.survey.questions[0].options = question.options.length > 0 ? question.options : responseOptions.getOptions(question)
      })
      this.list = list
    }
    this.loading = false
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    }),
    filteredSurveys () {
      const filtered = this.list.filter((template) => {
        return template.title.toLowerCase().includes(this.search.toLowerCase()) || template._id.includes(this.search)
      })
      return filtered
    }
  }
}
</script>
<style lang="scss">
  div.templates-container {
    padding: 0 70px;
    justify-content: space-between;

    // this line removes a empty template card that have isDefault as true
    & > div:first-child {
      display: none;
    }
  }

  div.search-input#template-search {
    margin: 0 0 20px 75% !important;

    @media screen and (max-width: 1750px) {
      margin: 0 0 20px 70% !important;
    }

    @media screen and (max-width: 1380px) {
      margin: 0 0 20px 65% !important;
    }
  }

  .header-template {
    padding: 3rem 0 2rem 2rem;
    & .back-button {
      padding-top: 7px;
      cursor: pointer;
    }
    & h3 {
      padding-left: 10px !important;
    }
  }
  .template-card {
    & .card {
      margin-bottom: 20px;
      width: 300px !important;
      height: 350px !important;
      margin-right: 10px !important;
      & h6 {
        font-size: 14px !important;
        font-weight: 600;
        height: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 250px;
        overflow: hidden;
      }
      & p {
        margin-top: 2px !important;
        font-size: 14px !important;
        line-height: 22px !important;
        color: #838383 !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        height: 48px;
      }
    }
  }
  .loading-templates-list {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    .data-loading-spinner {
      margin-top: 35% !important;
      margin-left: 48% !important;
      & .spinner-inner {
        background-color: #fff !important;
      }
    }
  }
</style>
