<template>
  <div class="container-insight-sidebar">
    <div class="card-header-insight-sidebar">
      <div class="card-tags-insight-sidebar">
        <TagInsight v-for="tag in tags" :key="tag.value" :icon="tag.icon" :title="tag.title" :value="tag.value" />
      </div>
      <div class="card-info-insight-sidebar">
        <p class="card-label-insight-sidebar">{{ user.name }}</p>
        <p class="card-label-insight-sidebar mini-text">{{ user.contact }}</p>
        <p class="card-label-insight-sidebar text-insight">{{ text }}</p>
        <p class="card-label-insight-sidebar mini-text">{{ $moment(dataInsight.analyzedAt).locale(lang).format('LL') }}</p>
      </div>
    </div>
    <div class="content-insight-sidebar">
      <div class="section-insight-sidebar">
        <p class="card-label-insight-sidebar">Insight</p>
        <p class="card-label-insight-sidebar text-insight">{{ capitalizeFirstLetter(dataInsight.insight.artificialAnalysis.insights) }}</p>
      </div>
      <div class="section-insight-sidebar" v-if="dataInsight.insight.artificialAnalysis.actions">
        <p class="card-label-insight-sidebar">Plano de ação</p>
        <p class="card-label-insight-sidebar text-insight">{{ capitalizeFirstLetter(dataInsight.insight.artificialAnalysis.actions) }}</p>
      </div>
      <img src="../../../../public/img/openIA.png" class="icon-insight-sidebar">
    </div>
    <div class="section-swot-insight-sidebar">
      <SwotCard :swot="swot" />
    </div>
  </div>
</template>

<script>
import TagInsight from './TagInsight.vue'
import SwotCard from './SwotCard.vue'

export default {
  name: 'MiniCardInsight',
  props: ['dataInsight'],
  components: {
    TagInsight,
    SwotCard
  },
  data () {
    return {
      text: '',
      swot: null,
      tags: [],
      user: {}
    }
  },
  created () {
    this.text = this.dataInsight.insight.artificialAnalysis.response

    this.swot = {
      s: this.capitalizeFirstLetter(this.dataInsight.insight.artificialAnalysis.s) || 'N/A',
      w: this.capitalizeFirstLetter(this.dataInsight.insight.artificialAnalysis.w) || 'N/A',
      o: this.capitalizeFirstLetter(this.dataInsight.insight.artificialAnalysis.o) || 'N/A',
      t: this.capitalizeFirstLetter(this.dataInsight.insight.artificialAnalysis.t) || 'N/A'
    }

    this.tags = [
      { icon: 'inventory_2', title: 'Produto', value: this.dataInsight.insight.artificialAnalysis.product },
      { icon: 'favorite', title: 'Sentimento', value: this.dataInsight.insight.artificialAnalysis.sentiment },
      { icon: 'sentiment_very_satisfied', title: 'Emoção', value: this.dataInsight.insight.artificialAnalysis.emotion },
      { icon: 'star', title: 'Categoria', value: this.dataInsight.insight.artificialAnalysis.classification }
    ]

    this.user = {
      name: this.dataInsight.seedData.from.name || this.$t('dashboard.no_name'),
      contact: this.contact
    }
  },
  computed: {
    lang () { return this.$store.getters['account/getLang'] },
    contact () {
      let contact = this.dataInsight.seedData.from.email || this.convertPhone(this.dataInsight.seedData.from.phone)

      if (!this.dataInsight.seedData.from.email && !this.dataInsight.seedData.from.phone) { contact = this.$t('dashboard.no_email') }

      return contact
    }
  },
  methods: {
    capitalizeFirstLetter (str) {
      if (str && str.length > 0) {
        return str.charAt(0).toUpperCase() + str.slice(1)
      }
      return str
    },
    convertPhone (e) {
      let number = e.toString()

      if (number.startsWith('+55')) {
        number = number.slice(3)
        const ddd = number.slice(0, 2)
        const parte1 = number.slice(2, 7)
        const parte2 = number.slice(7)
        const numberFormatted = `(${ddd}) ${parte1}-${parte2}`
        return numberFormatted
      } else {
        return number
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-insight-sidebar {
  background: #ffffff;
  border-radius: 3px;
  padding: 1vw;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  overflow-y: auto;
  .card-header-insight-sidebar {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    .card-tags-insight-sidebar {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5vw;
    }
    .card-info-insight-sidebar {
      display: flex;
      flex-direction: column;
      gap: 0.5vw;
    }
  }
  .content-insight-sidebar {
    background: #fbfbfb;
    position: relative;
    width: 100%;
    border-radius: 5px;
    padding: 0.2vw;
    border: 1px solid #e1e1e1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    gap: 0.8vw;
    .section-insight-sidebar {
      width: 90%;
      display: flex;
      flex-direction: column;
      gap: 0.3vw;
    }
    .icon-insight-sidebar {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
      width: 1.65vw;
      height: 1.65vw;
    }
  }
  .section-swot-insight-sidebar {
    width: 100%;
    border-radius: 5px;
    padding: 0.2vw;
    border: 1px solid #e1e1e1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  .card-label-insight-sidebar {
    font-size: 0.9vw;
    font-weight: 600;
    color: #454548;
  }
  .mini-text {
    color: #808080;
    font-weight: 400;
    font-size: 0.7vw;
  }
  .text-insight {
    color: #454548;
    font-weight: 400;
    font-size: 0.75vw;
    color: #565656;
    line-height: 0.85vw;
  }
}
</style>
