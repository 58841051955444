<template>
  <div style="350px;">
    <canvas :id="makeSpecialId()"></canvas>
  </div>
</template>

<script>
import { canvasMaker, formatters } from '@/_helpers'
import('chart.js').then((chartjs) => {
  window.Chart = chartjs.default
})
import('color-scheme').then((ColorScheme) => {
  window.ColorScheme = ColorScheme.default
})
import('color').then((Color) => {
  window.Color = Color.default
})
export default {
  name: 'StackedBarWithLine',
  props: ['overtime', 'isAverageLine'],
  data () {
    return {
      specialId: '',
      overtimeData: null
    }
  },
  mounted () {
    this.overtimeData = this.overtime
    this.renderData()
  },
  computed: {
    lang () {
      return this.$store.getters['account/getLang']
    }
  },
  methods: {
    makeSpecialId () {
      this.specialId = canvasMaker.makeCanvasIdWithoutParam()
      return this.specialId
    },
    async renderData (query) {
      const chartLabels = []
      const dtDataset = {
        type: 'bar',
        label: this.$t('sentimentals.negatives'),
        backgroundColor: '#ff607d',
        data: []
      }
      const prDataset = {
        type: 'bar',
        label: this.$t('sentimentals.positives'),
        backgroundColor: '#00aaa7',
        data: []
      }
      const ntDataset = {
        type: 'bar',
        label: this.$t('sentimentals.neutrals'),
        backgroundColor: '#ffb500',
        data: []
      }
      const lineDataset = {
        type: 'line',
        fill: false,
        backgroundColor: '#6f4ead',
        borderColor: '#6f4ead',
        borderWidth: 1,
        data: [],
        label: !this.isAverageLine ? this.$t('sentimentals.total_comments') : ''
      }
      const fontFamily = "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"

      if (this.overtimeData) {
        this.overtimeData.map(i => {
          if (i.total > 0) {
            const time = formatters.formatDateByLang(i.from, this.lang, 'MMM Do')
            chartLabels.push(time)

            if (this.isAverageLine) {
              lineDataset.data.push(Math.round(i.avg * 100) / 100)
            } else {
              lineDataset.data.push(i.total)
            }

            i.values.map(value => {
              if (value.key === 'dt') {
                dtDataset.data.push(value.doc_count)
              }
              if (value.key === 'pr') {
                prDataset.data.push(value.doc_count)
              }
              if (value.key === 'nt') {
                ntDataset.data.push(value.doc_count)
              }
            })
          }
        })

        const ctx = document.getElementById(this.specialId)
        this.displayGraph = await new window.Chart(ctx, {
          data: {
            labels: chartLabels,
            datasets: !this.isAverageLine ? [lineDataset, dtDataset, ntDataset, prDataset] : [lineDataset]
          },
          options: {
            responsive: true,
            scales: {
              xAxes: [{
                ticks: {
                  beginAtZero: true,
                  fontFamily: fontFamily,
                  fontSize: 12
                },
                scaleLabel: {
                  display: false
                },
                stacked: true
              }],
              yAxes: [{
                gridLines: {
                  display: false,
                  color: '#fff',
                  zeroLineColor: '#fff',
                  zeroLineWidth: 0
                },
                ticks: {
                  fontFamily: fontFamily,
                  fontSize: 12
                },
                stacked: true
              }]
            },
            legend: {
              display: false
            }
          }
        })
      }
    }
  }
}
</script>
