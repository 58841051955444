<template>
  <div class="container-card-select-ticket" :class="{'option-selected': item.selected && !isStar && !isNvs && !isOther && !isText}" :style="{'--color': color || myIcon.color, '--background': color ? `${color}12` : `${myIcon.color}12`}">
    <div class="checkbox-card-select-ticket" @click="openOptions(item)">
      <i class="material-icons-outlined checkbox-icon-select" v-if="item.selected">check_box</i>
      <i class="material-icons-outlined checkbox-icon-select" v-else>check_box_outline_blank</i>
    </div>
    <div class="label-card-select-ticket" v-if="isEmotion">
      <old-5emo class="icon-emo-ticket" :name="myIcon.name" size="28" :color="myIcon.color" />
      <p class="title-card-selected">{{ item.label }}</p>
    </div>
    <div class="label-card-select-ticket-default" v-else-if="isStar">
      <i class="material-icons icon-star-modal-ticket">star</i>
      <p class="title-card-selected-star" v-if="item.star.numbers">{{ item.label }}</p>
    </div>
    <div class="label-card-select-ticket-default" v-else-if="isNpsYn">
      <p class="title-card-selected-nps-yn" :style="{'background': color }">{{ item.label }}</p>
    </div>
    <div class="label-card-select-ticket-default" v-else-if="is7button">
      <p class="title-card-selected-7button" :style="{'background': color }">{{ item.label }}</p>
    </div>
    <div class="label-card-select-ticket-default" v-else-if="isNvs">
      <p class="title-card-selected-nvs">{{ item.label }}</p>
    </div>
    <div class="label-card-select-ticket-default" v-else-if="isOther">
      <p class="title-card-selected-other">{{ item.label }}</p>
    </div>
    <div class="label-card-select-ticket-default" v-else-if="isText">
      <p class="title-card-selected-text">{{ item.label }}</p>
    </div>
    <div class="label-card-select-ticket-default" v-else>
      <p class="title-card-selected-default">{{ item.label }}</p>
    </div>
    <div class="options-attendance-select-ticket" :style="{'display': item.selected ? 'flex' : 'none'}" :class="`op-${item.uniqueId}`">
      <div class="content-input-select-modal-ticket">
        <p class="title-input-select-modal-ticket">Selecione o atendente</p>
        <app-select-binds
          :options="attendances"
          :titleItem="'name'"
          :id="`attendances-${item.uniqueId}`"
          :value="item.attendant"
          @model="item.attendant = $event"
          style="height: 1.8vw; width: 100%; border-color: #e1e1e1;"
        />
      </div>
      <div class="content-input-select-modal-ticket">
        <p class="title-input-select-modal-ticket">Selecione a categoria</p>
        <treeselect
          class="input-select-modal-ticket"
          v-model="item.selectedCategory"
          :options="categories"
          :normalizer="normalizerCategory"
          :placeholder="$t('tickets.category.no_category')"
        />
      </div>
      <div class="content-input-select-modal-ticket">
        <p class="title-input-select-modal-ticket">Selecione a mensagem</p>
        <app-select-binds
          :options="messages"
          :titleItem="'title'"
          :id="`messages-ticket-${item.uniqueId}`"
          :value="item.message"
          @model="item.message = $event"
          style="height: 1.8vw; width: 100%; border-color: #e1e1e1;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import Treeselect from '@riophae/vue-treeselect'

export default {
  name: 'CardSelectTicket',
  props: ['item', 'color', 'categories', 'attendances', 'messages'],
  components: {
    Treeselect,
    'app-select-binds': () => import('@binds-tech/binds-design-system/src/components/Select/SelectBinds'),
    'old-5emo': () => import('@binds-tech/binds-design-system/src/components/Icon/Old5emo')
  },
  data () {
    return {
    }
  },
  mounted () {
    if (this.item.selected) { this.openOptions(this.item, true) }
  },
  computed: {
    isEmotion () { return (this.item.type === 'csat' || this.item.type === 'kpi') && this.item.ui === '5emo' },
    isNpsYn () { return ((this.item.type === 'nps' && this.item.ui === 'ynm') || (this.item.type === 'kpi' && (this.item.ui === 'ynd' || this.item.ui === 'yn'))) },
    isStar () { return this.item.type === 'csat' && this.item.ui === '5num' && this.item.star?.active },
    is7button () { return this.item.ui === '1to7button' },
    isNvs () { return this.item.type === 'nvs' },
    isText () { return this.item.type === 'text' },
    isOther () { return this.item.type === 'enum' && (this.item.ui === 'single' || this.item.ui === 'multiple') },
    myIcon () {
      const value = parseInt(this.item.value, 10)
      const icons = {
        100: { name: 'icon-joy-gee', color: '#49BB59' },
        75: { name: 'icon-happy-gee', color: '#37B0E4' },
        50: { name: 'icon-neutral-sleep', color: '#9E9E9E' },
        25: { name: 'icon-unhappy-sleep', color: '#F26C3C' },
        0: { name: 'icon-wow-open', color: '#EE2E35' }
      }

      return icons[value] || {}
    }
  },
  methods: {
    normalizerCategory (node) {
      return {
        id: node._id,
        label: node.title,
        children: node.subcategories
      }
    },
    openOptions (item, autoOpen) {
      if (!autoOpen) { item.selected = !item.selected }

      const divOptions = document.querySelector(`.op-${item.uniqueId}`)
      const height = item.selected ? '3.7vw' : '0vw'
      const opacity = item.selected ? 1 : 0
      const display = item.selected ? 'flex' : 'none'
      if (item.selected) { this.$forceUpdate() }
      gsap.to(divOptions, {
        height,
        opacity,
        display,
        duration: 0.5,
        onComplete: () => {
          if (!item.selected) { this.$forceUpdate() }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.option-selected {
  border: 1px solid var(--color) !important;
  box-shadow: 0 0 2px var(--color) !important;
  background: var(--background) !important;
  .checkbox-card-select-ticket {
    .checkbox-icon-select {
      color: var(--color) !important;
    }
  }
}
.container-card-select-ticket {
  position: relative;
  width: 85%;
  border: 1px solid #e1e1e1;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.08);
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
  .checkbox-card-select-ticket {
    position: absolute;
    left: 0.5rem;
    top: 0.5vw;
    cursor: pointer;
    .checkbox-icon-select {
      font-size: 1.2vw;
      color: #e1e1e1;
    }
  }
  .label-card-select-ticket {
    min-width: 30%;
    height: 2vw;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    .title-card-selected {
      font-size: 0.85vw;
      font-weight: 500;
      color: #454548;
    }
  }
  .label-card-select-ticket-default {
    min-width: 30%;
    height: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .title-card-selected-default {
      width: 1.5vw;
      height: 1.5vw;
      border-radius: 50%;
      background: var(--color);
      color: #ffffff;
      font-size: 0.85vw;
      font-weight: bold;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title-card-selected-star {
      position: absolute;
      top: 13px;
      font-size: 0.7vw;
      font-weight: bold;
      color: #ffffff;
    }
    .icon-star-modal-ticket {
      position: relative;
      font-size: 2.1vw;
      color: #edc00d;
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.20);
    }
    .title-card-selected-nps-yn {
      width: 60%;
      height: 85%;
      border-radius: 35px;
      font-weight: 500;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      font-size: 0.75vw;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title-card-selected-7button {
      width: 80%;
      height: 80%;
      border-radius: 35px;
      font-size: 0.75vw;
      color: #ffffff;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title-card-selected-nvs {
      background: #e1e1e1;
      width: 90%;
      height: 80%;
      border-radius: 35px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      font-size: 0.75vw;
      color: #454548;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title-card-selected-other {
      width: 90%;
      height: 90%;
      font-size: 0.75vw;
      color: #454548;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title-card-selected-text {
      font-size: 0.8vw;
      color: #454548;

    }
  }
  .options-attendance-select-ticket {
    width: 100%;
    height: 0vw;
    opacity: 0;
    padding: 0 0.3rem;
    padding-bottom: 0.5rem;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.06);
    display: none;
    align-items: center;
    justify-content: space-between;
    .content-input-select-modal-ticket {
      width: 33%;
      height: 3.5vw;
      background: #ffffff;
      border: 1px solid #e1e1e1;
      border-radius: 0.3rem;
      padding: 0.2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.4rem;
      .title-input-select-modal-ticket {
        color: #808080;
        font-size: 0.7vw;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .input-select-modal-ticket {
        width: 100%;
        .vs__dropdown-toggle {
          height: 1.85vw;
          border: 1px solid #e1e1e1;
          .vs__selected-options {
            .vs__selected {
              color: #cacaca !important;
              width: 100% !important;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>
