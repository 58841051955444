import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

export const socialMediaService = {
  searchHastags,
  searchTextMentions,
  searchUserOnTwitter,
  searchOvertimeMentions,
  searchSyntethicMentions,
  addBrandWithObjectId,
  getAllBrands,
  createBrands,
  deleteBrands,
  uploadFileCustomBrand,
  uploadHistory,
  updateCustomBrands
}

async function uploadFileCustomBrand (file, obj) {
  const url = `${API_URL}social-posts/upload?brandTracking=${obj._id}&headers=${obj.headers}&delimiter=${obj.delimiter}`
  return await callApiService.callApi(url, 'post', file, undefined, true)
}

async function uploadHistory (status) {
  let url = `${API_URL}social-posts/list-uploads?`

  if (status) { url = `${url}status=${status}` }

  return await callApiService.callApi(url, 'get')
}

async function addBrandWithObjectId (body) {
  const url = `${API_URL}brands-tracking/add-brands`
  return await callApiService.callApi(url, 'post', body)
}

async function deleteBrands (body) {
  const url = `${API_URL}brands-tracking/remove-brands`
  return await callApiService.callApi(url, 'post', body)
}

async function getAllBrands () {
  const url = `${API_URL}brands-tracking`
  return await callApiService.callApi(url, 'get')
}

async function updateCustomBrands (body) {
  const url = `${API_URL}brands-tracking/update-custom-brands`
  return await callApiService.callApi(url, 'put', body)
}
async function createBrands (body) {
  const url = `${API_URL}brands-tracking`
  return await callApiService.callApi(url, 'post', body, null, null, null, true)
}

async function searchUserOnTwitter (accountName) {
  const url = `${API_URL}brands-tracking/search-user-like?userName=${accountName}`
  return await callApiService.callApi(url, 'get')
}

async function searchSyntethicMentions (options) {
  const url = `${API_URL}social-posts/get-synthetic-data?brandToSearch=${options.brandId}&startDate=${options.startDate || ''}&endDate=${options.endDate || ''}&searchText=${encodeURIComponent(options.text)}&sentimentType=${options.sentiment}`
  return await callApiService.callApi(url, 'get')
}

async function searchOvertimeMentions (options) {
  const url = `${API_URL}social-posts/get-overtime-data?grid=${options.gridRange}&brandToSearch=${options.brandId}&searchText=${encodeURIComponent(options.text)}&sentimentType=${options.sentiment}`
  return await callApiService.callApi(url, 'get')
}

async function searchTextMentions (options) {
  const url = `${API_URL}social-posts?brandToSearch=${options.brandId}&startDate=${options.startDate || ''}&endDate=${options.endDate || ''}&searchText=${encodeURIComponent(options.text)}&sentimentType=${options.sentiment}`
  let newRangeEnd = null
  let newRangeStart = null
  if (options.start) {
    newRangeStart = options.start
  }
  if (options.end) {
    newRangeEnd = options.end
  }
  return await callApiService.callApi(url, 'get', null, newRangeEnd, false, newRangeStart)
}

async function searchHastags (options) {
  const url = `${API_URL}social-posts/get-hashtags?brandToSearch=${options.brandId}&startDate=${options.startDate || ''}&endDate=${options.endDate || ''}&searchText=${encodeURIComponent(options.text)}&sentimentType=${options.sentiment}`
  return await callApiService.callApi(url, 'get')
}
