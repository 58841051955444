import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

export const sendhubService = {
  mountLink,
  getMessages,
  getSms,
  updateSms,
  seeds,
  upload,
  collectors,
  getWhats,
  updateWhats
}

async function mountLink (surveyHashId, linkSettings) {
  let linkUrl = 'https://surveyv2.binds.co/#/link/' + surveyHashId
  if (linkSettings) {
    linkUrl = linkUrl + '/?'
    if (linkSettings.allowMoreThanOne) {
      linkUrl = linkUrl + 'loop=true&'
    }
    if (linkSettings.showEndSurveyButton === true) {
      linkUrl = linkUrl + 'close=true&'
    }
    if (linkSettings.endSurveyByInactivity === true) {
      linkUrl = linkUrl + 'timeout=' + linkSettings.seconds
    }
  }
  return linkUrl
}

async function getMessages (id) {
  const messages = API_URL + 'messages/' + id
  const result = await callApiService.callApi(messages, 'get')
  return result
}

async function getWhats (surveyHashId) {
  const whatsUrl = API_URL + 'messages/' + surveyHashId
  const result = await callApiService.callApi(whatsUrl, 'get')
  return result.whatsapp
}

async function updateWhats (body, id) {
  const whatsUrl = API_URL + 'messages/' + id
  const whatsResult = await callApiService.callApi(whatsUrl, 'put', body)
  return whatsResult
}

async function getSms (surveyHashId) {
  const smsUrl = API_URL + 'messages/' + surveyHashId
  const result = await callApiService.callApi(smsUrl, 'get')
  return result.sms
}

async function updateSms (body, id) {
  const smsUrl = API_URL + 'messages/' + id
  const smsResult = await callApiService.callApi(smsUrl, 'put', body)
  return smsResult
}

async function seeds (body) {
  const seedsUrl = API_URL + 'seedBatches'
  const seedsResult = await callApiService.callApi(seedsUrl, 'post', body)
  // let seedsResult = await callApi(seedsUrl +, 'post', body)
  return seedsResult
}
async function upload (body) {
  const seedsUrl = API_URL + 'seeds-upload'
  const seedsResult = await callApiService.callApi(seedsUrl, 'post', body)
  // let seedsResult = await callApi(seedsUrl +, 'post', body)
  return seedsResult
}

async function collectors () {
  const collectorUrl = API_URL + 'collectors'
  const collectorResult = await callApiService.callApi(collectorUrl, 'get')
  return collectorResult
}
