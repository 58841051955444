<template>
  <div style="width: 100%">
    <div :key="render" style="width: 100%">
      <div class="add-dynamic-field">
        <button v-on:click="addDynamicField()">
          <i class="material-icons hvr-grow">add</i>
          <app-heading level="h5">{{$t('survey.add_input')}}</app-heading>
        </button>
      </div>
      <div v-for="(item, index) in options.dynamicFields" v-bind:key="index" class="referral-dynamic-options">
        <div class="header-rf-options">
            <div>
                <app-toggle-input v-model="item.active"></app-toggle-input>
                <app-heading level="h6" style="line-height: 15px; white-space: nowrap; margin-left: 40px !important;">{{$t('survey.show_input')}}</app-heading>
            </div>
            <div v-if="item.active" style="margin-left: 15px;">
                <app-toggle-input v-model="item.required"></app-toggle-input>
                <app-heading level="h6" style="line-height: 15px; white-space: nowrap; margin-left: 40px !important;">{{$t('survey.required_field')}}</app-heading>
            </div>
        </div>
        <div class="labelButton characters mt30" v-if="item.active">
          <div>
            <app-heading level="h7">{{$t('survey.instruction')}}</app-heading>
            <app-form-input
              class="messageLabel"
              v-model="item.placeholder"
              v-on:change.native="updatePlaceholder()"
              border-bottom
            ></app-form-input>
          </div>
        </div>
        <div class="select-mappingType" v-if="item.active">
          <div>
            <app-heading level="h7">{{$t('survey.mapping_type')}}</app-heading>
            <v-select
                class="col-lg-12 col-md-12 col-sm-12 rule-select"
                label="label"
                :options="mappingtypeOptions"
                v-model="item.mappingType"
                :reduce="item => item.value"
                :clearable="false"
                :searchable="false"
                @input="changeTypeInput(item)"
            ></v-select>
          </div>
          <div class="delete-dynamic-field">
            <app-button
            inline
            icon="trash-alt"
            icon-color="gray"
            icon-size="xs"
            v-on:click="deleteDynamicField(index)"
            class="col-xs-3 col-xxs-3"
            title-placement="right"
            v-title="$t('survey.delete_input')">
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { referralSettings } from '@/_helpers'
export default {
  name: 'ReferralDynamicOptions',
  data () {
    return {
      mappingtypeOptions: [],
      render: 0
    }
  },
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    vSelect: () => import('vue-select')
  },
  computed: {
    options () {
      const survey = this.$store.getters['survey/getSurvey']
      const question = survey.questions[survey.questionSelected]
      return question.referral
    }
  },
  methods: {
    changeTypeInput (item) {
      if (item.mappingType === 'name' || item.mappingType === 'other') {
        item.type = 'text'
        item.label = item.mappingType === 'name' ? 'name' : 'other'
      }
      if (item.mappingType === 'phone') {
        item.type = 'tel'
        item.label = 'phone'
      }
      if (item.mappingType === 'email') {
        item.type = 'email'
        item.label = 'email'
      }
    },
    addDynamicField () {
      const payload = referralSettings.getDynamicFields()
      this.options.dynamicFields.push(payload.dynamicFields[0])
      /*
        Como o `payload.dynamicFields[0]` é usado só para adicionar um novo campo
        sempre com os mesmos valores default, ele adiciona sempre a mesma `key`.
        Passamos corrigindo o array das `this.options.dynamicFields`, com as keys
        equivalentes ao index do field.
        Isso resolve um problema de exportação na tela de indicações!
     */
      this.options.dynamicFields.forEach((field, index) => {
        field.key = index.toString()
      })
      this.render += 1
    },
    deleteDynamicField (idx) {
      this.options.dynamicFields.splice(idx, 1)
      this.render += 1
    }
  },
  mounted () {
    this.mappingtypeOptions = referralSettings.getmappingType()
  }
}
</script>
<style lang="scss">
 .select-mappingType .vs__dropdown-toggle {
  width: 261px;
}
.referral-dynamic-options {
  width: 100%;
  border: 2px dashed $color-gray-light;
  border-radius: 6px;
  margin: 10px 0;
  padding: 10px;
}

@media  screen and (max-width: 1740px) {
  .expander .referral-dynamic-options {
    min-width: 360px;
  }
}

@media  screen and (max-width: 1500px) {
  .expander .referral-dynamic-options {
    min-width: 335px;
  }

  .expander .delete-dynamic-field > button {
    margin-left: 10px;
    right: 0px;
  }
}

.header-rf-options {
  display: flex;
  div {
    width: 129px;
    display: grid;
  }
}
.select-mappingType {
    margin-top: 30px;
    display: flex;
}
.delete-dynamic-field {
  text-align: right;
  padding-top: 20px;
  margin: 0 auto;

  > button {
    position: relative;
    right: 40%;
  }
}
.add-dynamic-field {
  margin: 40px 0px 20px 0px;
  button {
    width: 100%;
    text-align: left;
    h5 {
      display: inline-block;
      vertical-align: text-bottom;
    }
    i {
      background: var(--accent-color) !important;
      color: #fff;
      font-size: 17px;
      font-weight: bold;
      border-radius: 50px;
      text-align: center;
      line-height: 25px;
      width: 25px;
      height: 25px;
      margin-right: 12px;
      background: $color-purple;
    }
  }
}
</style>
