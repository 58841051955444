var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'lock-scroll': _vm.exporting }},[(_vm.loading)?_c('div',{staticClass:"data-loading-fixed"},[_c('DoubleBounce',{staticClass:"data-loading-spinner animated fadeIn"})],1):_vm._e(),_c('section',{staticClass:"section-analytics"},[_c('Menu',{attrs:{"showSubMenu":false}}),_c('SubMenu',{staticStyle:{"height":"130px"},attrs:{"cloudwords":"true"}}),(_vm.canShowThePage)?_c('div',[_c('SearchTerm',{attrs:{"statsUpdated":_vm.statsUpdated},on:{"typeCloud":function($event){return _vm.changeTypeCloud($event)},"export-cloud-word":function($event){return _vm.generatePDF()}}}),(!_vm.loading)?_c('div',{staticClass:"content-text-bubbles",attrs:{"id":"pdf-cloud-word"}},[(_vm.displayType === 'bubbles')?_c('CloudWords',{staticClass:"cloud_col1",class:{
            'row': _vm.exporting,
            'center-lg': _vm.exporting,
            'center-md': _vm.exporting,
            'center-sm': _vm.exporting,
            'bubbles-width': _vm.exporting
          },attrs:{"display":_vm.display,"words":_vm.words,"qty":_vm.qty,"origin":_vm.origin,"renderBubbles":_vm.renderBubbles}}):_vm._e(),(_vm.displayType === 'list')?_c('List',{staticClass:"cloud_col1",attrs:{"list":_vm.words,"origin":_vm.origin,"renderList":_vm.renderList}}):_vm._e(),(_vm.words.length > 0)?_c('TextAnalytics',{staticClass:"cloud_col2",attrs:{"data-html2canvas-ignore":"true","empty":_vm.empty,"responses":_vm.responses,"searchedTerm":_vm.words.length === 1 ? _vm.words[0].word : undefined}}):_vm._e()],1):_vm._e()],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }