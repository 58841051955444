<template>
  <div style="height: 100vh; width: 100vw; position: fixed; top: 0; left: 0; z-index: 99; background: transparent;" @click="handleClickOutsideSidebar($event)">
    <div class="profile">
      <div class="content-container">
        <div class="my-profile-header">
          <div class="profile-button close-config col-lg-12 col-md-12 col-xs-12 col-sm-12 col-xs-12">
            <button @click="showCloseModal = true">
              <app-textstyle color="purple" size="size-xl">×</app-textstyle>
            </button>
          </div>
          <div class="profile-heading">
            <app-heading level="h5">{{$t('profile.title')}}</app-heading>
          </div>
        </div>
        <Expander :title="$t('profile.personalInfo')" :showOnStartup="true">
          <div class="row">
              <SetImg class="col-lg-12 col-md-12 col-sm-12 col-xs-12" :imageProps="userImage" :title="''" context="profile"/>
          </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 profile-label-btn labelInputConfig">
              <div class="edit-name">
                <app-heading level="h6">{{$t('profile.name')}}</app-heading>
                <app-form-input
                  class="messageLabel"
                  v-model="name"
                  border-bottom
                ></app-form-input>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 profile-label-btn labelInputConfig">
              <div class="email">
                <app-heading level="h6">{{$t('profile.email')}}</app-heading>
                <app-form-input
                  class="messageLabel email-disabled"
                  v-model="email"
                  border-bottom
                  disabled
                ></app-form-input>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 profile-label-btn labelInputConfig">
              <div class="edit-phone">
                <app-heading level="h6">{{$t('profile.phone')}}</app-heading>
                <vue-phone-number-input
                  class="mt10"
                  ref="phoneOutput"
                  v-model="phone"
                  :preferred-countries="countries"
                  :show-code-on-list="true"
                  :fetch-country="true"
                  :translations="{
                    countrySelectorLabel: $t('account_config.phone_input.country_code'),
                    countrySelectorError: $t('account_config.phone_input.choose_country'),
                    phoneNumberLabel: $t('account_config.phone_input.phone_number'),
                    example: $t('account_config.phone_input.example')
                  }"
                ></vue-phone-number-input>
              </div>
            </div>
        </Expander>
        <hr style="width: 100%; left: 0; color: rgba(0, 0, 0, 0.4);">
        <Expander :title="this.$i18n.t('profile.change_pass')" :showOnStartup="false">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 labelButton labelInputConfig">
              <div class="pass">
                <app-heading level="h6">{{$t('profile.password')}}</app-heading>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pass-margin">
                    <app-form-input class="messageLabel"
                      v-model="pass"
                      border-bottom
                      :type="visibility"
                    ></app-form-input>
                  </div>
                  <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 mt10 visibility">
                    <i class="material-icons visibility-icon" v-if="this.visibility === 'text'" v-on:click="passwordVisibility()">visibility</i>
                    <i class="material-icons visibility-icon" v-if="this.visibility === 'password'" v-on:click="passwordVisibility()">visibility_off</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 labelButton labelInputConfig">
              <div class="confirm">
                <app-heading level="h6">{{$t('profile.confirm')}}</app-heading>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pass-margin">
                    <app-form-input class="messageLabel"
                      v-model="confirmPass"
                      border-bottom
                      :type="visibility"
                    ></app-form-input>
                    <app-heading class="wrong-pass" level="h8" v-if="!passwordConfirmed">{{ $t('profile.wrong_pass') }}</app-heading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Expander>
        <hr style="width: 100%; left: 0; color: rgba(0, 0, 0, 0.4);">
        <Expander :title="$t('profile.otherInfo')" :showOnStartup="false">
          <div class="profile-permission">
            <!-- <app-toggle-input v-model="allowSms"></app-toggle-input> -->
            <input type="checkbox" class="checkbox" v-model="allowSms">
            <app-heading level="h6">{{ $t('profile.sms') }}</app-heading>
          </div>
          <div class="profile-permission">
            <!-- <app-toggle-input v-model="allowNews"></app-toggle-input> -->
            <input type="checkbox" class="checkbox" v-model="allowNews">
            <app-heading level="h6">{{ $t('profile.news') }}</app-heading>
          </div>
        </Expander>
        <hr style="width: 100%; left: 0; color: rgba(0, 0, 0, 0.4);">
      </div>
      <div class="save-btn-container">
        <app-button class="save-profile" variation="primary" v-on:click="save()">{{ $t('profile.save') }}</app-button>
      </div>
      <div>
      <theme-style>
        .my-profile-header .heading,
        .my-profile-header .close-config button span {
          color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
        }
      </theme-style>
      </div>
    </div>
      <app-modal
        v-model="showCloseModal"
        :title="$t('survey.exit_whithout_save')"
        lightbox
        close
        class="modal-default-primary"
        :primary-button="this.$t('account_config.delete_confirm.yes')"
        @primaryButtonClick="canBeClosed = true; handleCloseSidebar()"
        :secondary-button="this.$t('account_config.delete_confirm.no')"
        @secondaryButtonClick="showCloseModal = false, canBeClosed = false"
      >
        <app-heading level="h7">{{ $t('survey.exit_message') }}</app-heading>
    </app-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { userService } from '@/_services'
import SetImg from '../image/SetImg.vue'

import 'vue-phone-number-input/dist/vue-phone-number-input.css'
export default {
  name: 'MyProfile',
  props: ['isActive'],
  components: {
    SetImg,
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    Expander: () => import('../SideBarConfig/Expander.vue'),
    'vue-phone-number-input': () => import('vue-phone-number-input'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button')
  },
  data () {
    return {
      name: '',
      email: '',
      phone: '',
      pass: '',
      confirmPass: '',
      allowSms: true,
      allowNews: true,
      countries: ['BR', 'ES', 'US'],
      visibility: 'password',
      passwordConfirmed: true,
      showCloseModal: false,
      canBeClosed: false
    }
  },
  created () {
    const onEscape = (e) => {
      if (e.keyCode === 27) {
        this.showCloseModal = true
      }
    }

    document.addEventListener('keydown', onEscape)

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape)
    })
  },
  mounted () {
    this.name = this.user.name
    this.email = this.user.email
    this.phone = this.user.tel
    this.allowSms = this.user.optSMSAlerts
    this.allowNews = this.user.opt
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    }),
    userImage () {
      const result = {}
      result.value = this.user.image ? this.user.image.value : ''
      result.where = 'userImage'
      result.success = (this.user.image && this.user.image.value)
      result.hideDelete = true
      result.crop = '800x600 100%'
      result.imagesOnly = true
      return result
    }
  },
  watch: {
    confirmPass (value) {
      if (this.pass === value) {
        this.passwordConfirmed = true
      } else {
        this.passwordConfirmed = false
      }
    }
  },
  methods: {
    handleCloseSidebar () {
      if (this.canBeClosed && this.showCloseModal) this.$root.$emit('close-profile-config')
      else return null
    },
    handleClickOutsideSidebar (event) {
      if (event.target._prevClass === 'expand-profile') this.showCloseModal = true
      else return null
    },
    async save () {
      const settings = {
        name: this.name,
        tel: this.$refs.phoneOutput.results.formattedNumber,
        optSMSAlerts: this.allowSms,
        image: this.userImage || '',
        opt: this.allowNews
      }
      if (this.pass) {
        settings.password = this.pass
      }

      if (settings.name && this.passwordConfirmed) {
        const update = await userService.updateUser(this.user._id, settings)
        if (update) {
          this.$i18n.locale = update.language
          update.image = this.userImage
          delete update.account
          delete update.metadata
          this.$store.commit('account/updateProfile', update)
          this.$store.commit('account/language', update.language)
          this.$store.commit('alerts/alert', {
            message: this.$i18n.t('profile.success'),
            position: 'bottomRight',
            showAlert: true
          })
          if (settings.optSMSAlerts && !settings.tel) {
            setTimeout(() => {
              this.$store.commit('alerts/alert', {
                message: this.$t('profile.smsAlert'),
                position: 'bottomRight',
                showAlert: true
              })
            }, 1250)
          }
          this.$root.$emit('close-profile-config')
        }
      } else {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('profile.error'),
          position: 'bottomRight',
          showAlert: true
        })
      }
    },
    passwordVisibility () {
      if (this.visibility === 'password') {
        this.visibility = 'text'
      } else {
        this.visibility = 'password'
      }
    }
  }
}
</script>

<style lang="scss">
.profile {
  min-height: 100%;
  z-index: 9999;
  width: 28rem;
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 10px 10px 5px 10px rgba(48, 48, 48, 0.5);
  overflow: auto;
  overflow-x: hidden;
  .content-container {
    display: flex;
    flex-direction: column;
  }
  div.my-profile-header {
    background-color: var(--primary-color);
  }
  .text-field__input input.user-click {
    background: #D9D9D933 !important;
    padding-left: 10px;
  }
  .expander .row.pointer {
    justify-content: space-between;
    .arrow.arrow-bottom, .arrow.arrow-right {
      margin-right: 8px;
    }
  }
}
.profile-button {
  top: -20px;
  right: 0;
  position: absolute;
}
.profile-heading {
  & .heading {
    padding: 15px !important;
  }
}
.profile-permission {
  padding-top: 10px;
  margin-top: 15px;
  margin-left: 20px;
  display: flex;
  & h6.heading {
    font-size: 13.5px ;
    margin-left: 15px !important;
    line-height: 20px !important;
  }
  & input.checkbox {
    accent-color: var(--accent-color);
    height: 17px;
    width: 17px;
  }
}
.profile-label-btn {
  & h6 {
    font-size: 14px !important;
    margin-left: 0 !important;
  }
  & .text-field__input {
    input {
      font-size: 14px !important;
    }
  }
}
.pass {
  margin-left: -15px !important;
  width: 115%;
}
.save-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  button.save-profile {
    font-size: 14px !important;
    margin: 25px 0 !important;
    padding: 9px 10px;
    font-size: 16px;
    width: 230px;
    background: var(--accent-color);
    box-shadow: var(--accent-color);
    &.hover,
    &:hover {
      background: var(--secondary-color) !important;
      box-shadow: var(--secondary-color) !important;
    }
  }
}

.pass-margin {
  margin-left: -15px !important;
}
.visibility {
  margin-left: -25px !important;
  position: relative;
  right: 40px;
  bottom: -15px;
}
.visibility-icon {
  color: $color-gray;
  cursor: pointer;
}
.confirm {
  margin-left: -15px !important;
  margin-top: -25px !important;
  width: 115%;
}
.wrong-pass {
  margin-top: 10px !important;
  float: right;
  color: red !important;
}
.email-disabled {
  & .text-field__input {
    & input {
      color: #928d8d !important;
    }
  }
}
.my-profile-header {
  width: 100%;
  height: 96px;
  .profile-heading,
  .account-heading,
  .close-config button span  {
    margin-top: 25px;
    display: block;
  }
}
</style>
