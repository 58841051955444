<template>
  <div  style="margin: 5vw 3vw;">
    <div class="container-signup">
      <div class="input-field-sign-up">
        <div class="linearGradient">
          <input  v-model="username" class="login-form-input-field" type="text" id="name" required />
          <label class="login-form-label-field" for="name">{{$t('signup.name')}}:</label>
        </div>
      </div>
      <div class="input-field-sign-up">
        <div class="linearGradient">
          <input ref="email" v-model="email" class="login-form-input-field" type="text" id="email" required />
          <label class="login-form-label-field" for="email">{{$t('signup.email')}}:</label>
        </div>
      </div>
      <div class="input-field-sign-up">
        <div class="linearGradient">
          <input  ref="phone" v-model="phone" class="login-form-input-field" type="text" id="phone" required />
          <label class="login-form-label-field" for="phone">{{$t('signup.phone')}}:</label>
        </div>
      </div>
      <div class="input-field-sign-up">
        <div class="linearGradient">
          <input  v-model="company" class="login-form-input-field" type="text" id="company" required />
          <label class="login-form-label-field" for="company">{{$t('signup.company')}}:</label>
        </div>
      </div>
    </div>
    <div id="parent-recaptcha">
      <Captcha ref="recaptcha" @verify="canSubmit"></Captcha>
    </div>
    <button @click="submitSignup()" id="signUpButton">{{$t('signup.create_account')}}</button>
    <div class="agreement">
      {{$t('signup.agreement')}} <a href="https://binds.co/termos-de-uso/" target="_blank" class="loginActionLinks">{{$t('signup.use_terms')}}</a>
    </div>
  </div>
</template>
<script>
import VueCookies from 'vue-cookies'
import Captcha from '@/components/recaptcha/Captcha.vue'
import { mapState } from 'vuex'
import { userService } from '@/_services'
export default {
  name: 'SignupForm',
  components: { Captcha },
  data () {
    return {
      username: '',
      email: '',
      phone: '',
      company: '',
      afterSend: false,
      canSend: false,
      myId: ''
    }
  },
  computed: {
    ...mapState('account', ['status'])
  },
  methods: {
    canSubmit (res) {
      this.canSend = !!res
    },
    showMessage (message) {
      this.$store.commit('alerts/alert', {
        message: message,
        showAlert: true
      })
    },
    async submitSignup () {
      if (!this.username || !this.email || !this.phone || !this.company || !window.grecaptcha.getResponse()) {
        this.showMessage(this.$i18n.t('TWO_FACTOR.dialog.fill_in'))
        return false
      }
      if (!this.email.includes('@') || this.email.length < 5) {
        this.$refs.email.focus()
        this.showMessage(this.$i18n.t('signup.incorrect_field_value'))
        return false
      }

      if (this.phone.length < 6 || isNaN(this.phone)) {
        this.$refs.phone.focus()
        this.showMessage(this.$i18n.t('signup.incorrect_field_value'))
        return false
      }
      const account = {
        owner: {}
      }
      this.$root.$emit('set-login-loading', true)

      const cookie = VueCookies.get('traffic_src')
      if (cookie) {
        /*
          COMO SÃO OS CAMPOS A SALVAR E O QUE VEM OU NÃO NO COOKIE ATUAL:
          account.utm = {
            source: '', // ga_source
            medium: '', // ga_medium
            campaign: '', // ga_campaign
            content: '', // ga_content
            term: '', // ga_keyword
            clid: '', // ga_gclid -- esse vem no cookie, mas ainda não tem no back
            landing_page: '' ga_lading_page -- esse vem no cookie, mas ainda não tem no back
          }
        */
        account.utm = {
          source: cookie.ga_source || null,
          medium: cookie.ga_medium || null,
          campaign: cookie.ga_campaign || null,
          content: cookie.ga_content || null,
          term: cookie.ga_keyword || null
        }
      }
      account.owner.name = this.username
      account.owner.email = this.email
      account.owner.phone = this.phone
      account.smsEnabled = false
      account.name = this.company
      account.v2 = true
      account['g-recaptcha-response'] = window.grecaptcha.getResponse()
      // const password = this.password
      let response = {}
      try {
        response = await userService.signup(account)
      } catch (e) {
        response = e && e.response
      }
      if (response.name === 'BadRequestError') {
        this.canSend = false
        this.showMessage(response.message)
        window.grecaptcha.reset()
      } else {
        this.$store.commit('alerts/alert', {
          message: this.$t('signup.congratulation'),
          position: 'bottomRight',
          showAlert: true
        })
        // window.location.href = 'https://binds.co/pesquisas/sucesso.html'
        setTimeout(() => { this.$emit('go-to-login') }, 1000)
        window.google_trackConversion({
          google_conversion_id: 987077798,
          google_conversion_language: 'en',
          google_conversion_format: '3',
          google_conversion_color: 'ffffff',
          google_conversion_label: 'LYO_CKGQ9HAQprnW1gM',
          google_custom_params: {
            nome: this.username,
            email: this.email
          },
          google_remarketing_only: false
        })
        this.username = ''
        this.email = ''
        this.phone = ''
        this.company = ''
      }
      this.$root.$emit('set-login-loading', false)
    }
  }
}
</script>
<style>
.container-signup {
 display: grid; grid-auto-rows: minmax(3vw, auto); justify-content: center;grid-template-columns: 15vw auto;grid-template-rows: 4vw;
}
.agreement {
  display: grid; font-size: 1vw; color: rgb(159, 159, 159);
}
@media only screen and (max-width: 648px) {
  #form {
    background: white;
  }
  #form > div:nth-child(3) > div:nth-child(1) {
    display: block;
  }
  #form > div:nth-child(1) {
    padding: 4vw;
}
  .g-recaptcha {
    transform: none;
  }
  .agreement {
    font-size: 4vw;
  }

  .input-field-sign-up {
    height: 10.7vw;
    line-height: 8vw;
    margin: 2.5vw;
  }

    #parent-recaptcha {
      height: 13vw;
    }

  #form > div:nth-child(3) > div:nth-child(4) {
    font-size: 3vw;
    text-align: center;
  }
  .login-form-input-field:focus ~ label, .login-form-input-field:valid ~ label {
    top: -4vw;
  }
}

@media only screen and (max-width: 648px) {
  .input-field-sign-up {
    height: 11.7vw;
  }
  #parent-recaptcha {
    height: 17vw;
    padding: 5vw;
  }
  .login-form-input-field {
    padding: 2vw 0;
  }
}
</style>
