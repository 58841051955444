<template>
  <div class="container" :style="{'margin-top': cardData.type === 'csat' ? '10px !important' : '0px !important'}">
    <canvas :id="question.canvasId" class="myCanvas" width="100%" height="100%"></canvas>
    <bar-with-metric
      v-if="['csat'].indexOf(cardData.type) > -1"
      :data="data"
      :question="cardData.question"
      :cardData="cardData"
    ></bar-with-metric>
    <div class="emoteGaugeLabel setLabel" :style="{'margin-top': cardData.type === 'csat' ? '8px !important' : '-50px !important'}">
      <div v-if="cardData.type === 'csat'">
        <div v-if="['5num', '5emo'].indexOf(cardData.ui) > -1">
          <span v-if="valueOfGraphic <= 20"> {{ $t('dashboard.gauge_analytics.labels.terrible') }}</span>
          <span v-else-if="valueOfGraphic <= 40"> {{ $t('dashboard.gauge_analytics.labels.bad') }}</span>
          <span v-else-if="valueOfGraphic <= 60"> {{ $t('dashboard.gauge_analytics.labels.good') }}</span>
          <span v-else-if="valueOfGraphic <= 80"> {{ $t('dashboard.gauge_analytics.labels.great') }}</span>
          <span v-else-if="valueOfGraphic <= 100"> {{ $t('dashboard.gauge_analytics.labels.excellent') }}</span>
        </div>
        <div v-if="['10num'].indexOf(cardData.ui) > -1">
          <span v-if="valueOfGraphic < 2"> {{ $t('dashboard.gauge_analytics.labels.terrible') }}</span>
          <span v-if="valueOfGraphic >= 2 && valueOfGraphic < 3"> {{ $t('dashboard.gauge_analytics.labels.bad') }}</span>
          <span v-if="valueOfGraphic >= 3 && valueOfGraphic < 4"> {{ $t('dashboard.gauge_analytics.labels.good') }}</span>
          <span v-if="valueOfGraphic >= 4 && valueOfGraphic < 5"> {{ $t('dashboard.gauge_analytics.labels.great') }}</span>
          <span v-if="valueOfGraphic >= 5"> {{ $t('dashboard.gauge_analytics.labels.excellent') }}</span>
        </div>
      </div>
      <div v-if="cardData.type === 'kpi' && cardData.ui === '5emo'">
        <span v-if="valueOfGraphic <= 20"> {{ $t('dashboard.gauge_analytics.labels.terrible') }}</span>
        <span v-else-if="valueOfGraphic <= 40"> {{ $t('dashboard.gauge_analytics.labels.bad') }}</span>
        <span v-else-if="valueOfGraphic <= 60"> {{ $t('dashboard.gauge_analytics.labels.good') }}</span>
        <span v-else-if="valueOfGraphic <= 80"> {{ $t('dashboard.gauge_analytics.labels.great') }}</span>
        <span v-else-if="valueOfGraphic <= 100"> {{ $t('dashboard.gauge_analytics.labels.excellent') }}</span>
    </div>
    </div>
  </div>
</template>
<script>
import { canvasMaker } from '@/_helpers'
import BarWithMetric from './BarWithMetric.vue'

export default {
  name: 'EmoteGauge',
  props: {
    data: {
      type: Object
    },
    question: {
      type: Object
    },
    cardData: {
      type: Object
    }
  },
  data () {
    return {
      valueOfGraphic: -59
    }
  },
  components: { BarWithMetric },
  methods: {
    makeSpecialId () {
      this.question.canvasId = canvasMaker.makeCanvasId(this.question)
    }
  },
  created () {
    this.makeSpecialId()
  },
  mounted () {
    const myCanvas = document.getElementById(this.question.canvasId)
    // diâmetro do gauge inteiro (quanto de tela ele ocupa)
    myCanvas.width = 350
    myCanvas.height = 350
    if (this.question.type === 'csat') {
      myCanvas.width = 350
      myCanvas.height = 305
    }

    // fatias a serem exibidas
    const piecesOfPie = {
      0: -100,
      1: -75,
      2: -50,
      3: 75,
      4: 100
    }
    function drawArrow (fromX, fromy, toX, toY, options, question) {
      // variables to be used when creating the arrow
      const c = document.getElementById(question.canvasId)
      const ctx = c.getContext('2d')
      const headlength = 5
      const angle = Math.atan2(toY - fromy, toX - fromX)
      // starting path of the arrow from the start square to the end square and drawing the stroke
      ctx.beginPath()
      ctx.moveTo(fromX, fromy)
      ctx.lineTo(toX, toY)
      ctx.strokeStyle = '#666666'
      ctx.lineWidth = 10
      ctx.stroke()
      // starting a new path from the head of the arrow to one of the sides of the point
      ctx.beginPath()
      ctx.moveTo(toX, toY)
      ctx.lineTo(toX - headlength * Math.cos(angle - Math.PI / 7), toY - headlength * Math.sin(angle - Math.PI / 7))
      // path from the side point of the arrow, to the other side point
      ctx.lineTo(toX - headlength * Math.cos(angle + Math.PI / 7), toY - headlength * Math.sin(angle + Math.PI / 7))
      // path from the side point back to the tip of the arrow, and then again to the opposite side point
      ctx.lineTo(toX, toY)
      ctx.lineTo(toX - headlength * Math.cos(angle - Math.PI / 7), toY - headlength * Math.sin(angle - Math.PI / 7))
      // draws the paths created above
      ctx.strokeStyle = '#666666'
      ctx.lineWidth = 12
      ctx.stroke()
      ctx.fillStyle = '#666666'
      ctx.fill()
    }

    function arrowController (options, question, range) {
      let positions = [180, 180, 235, 180]
      let color = 0
      if (options.valueOfGraphic < range[0]) {
        positions = [150, 150, 115, 155]
        if (question.type === 'kpi') {
          positions = [150, 170, 115, 175]
        }
        // atinge o vermelho
        color = 0
      } else if (options.valueOfGraphic < range[1]) {
        positions = [160, 140, 130, 112]
        if (question.type === 'kpi') {
          positions = [180, 180, 130, 134]
        }
        // laranja
        color = 1
      } else if (options.valueOfGraphic < range[2]) {
        positions = [175, 140, 175, 92]
        if (question.type === 'kpi') {
          positions = [180, 180, 175, 115]
        }
        // amarelo csat
        color = 2
      } else if (options.valueOfGraphic < range[3]) {
        positions = [167, 167, 222, 113]
        if (question.type === 'kpi') {
          positions = [180, 185, 220, 135]
        }
        // verde claro
        color = 3
      } else if (options.valueOfGraphic <= range[4]) {
        positions = [140, 145, 235, 152]
        if (question.type === 'kpi') {
          positions = [180, 180, 235, 180]
        }
        // verde escuro
        color = 4
      }
      drawArrow(positions[0], positions[1], positions[2], positions[3], options, question)
      return color
    }

    function drawRange (ctx, labelX, labelY, range, positionX, positionY) {
      ctx.fillText(range[0], positionX[0], positionY[0])
      ctx.fillText(range[1], positionX[1], positionY[1])
      ctx.fillText(range[2], positionX[2], positionY[2])
      ctx.fillText(range[3], positionX[3], positionY[3])
      ctx.fillText(range[4], positionX[4], positionY[4])
      ctx.fillText(range[5], positionX[5], positionY[5])
    }

    /**
     * cria os pedaços de pizza do gráfico
     * @param {{ Object }} ctx recebe o contexto atual do que está sendo desenhado em getContext('2d')
     * @param {{ Number }} centerX posição horizontal de onde desejamos realizar o desenho
     * @param {{ Number }} centerY posição vertical de onde desejamos realizar o desenho
     * @param {{ Number }} radius define a proporção do gráfico
     * @param {{ Number }} startAngle define o ponto inicial da fatia num ângulo de 360º
     * @param {{ Number }} endAngle define o ponto final da fatia num ângulo de 360º
     * @param {{ String }} color define a cor da fatia
     */
    function drawPieSlice (ctx, centerX, centerY, radius, startAngle, endAngle, color) {
      ctx.fillStyle = color
      ctx.beginPath()
      ctx.moveTo(centerX, centerY)
      ctx.arc(centerX, centerY, radius, startAngle, endAngle)
      ctx.closePath()
      ctx.fill()
    }

    function drawCenterValue (ctx, options, canvas, question) {
      ctx.beginPath()
      ctx.fillStyle = 'white'
      const value = options.valueOfGraphic.toString()
      const lengthOfValue = value.length
      let xPosition = (canvas.width / 2.25)
      if (question.type === 'kpi') {
        xPosition = (canvas.width / 2.10)
        if (lengthOfValue === 2) {
          xPosition = (canvas.width / 2.20)
        }
        if (lengthOfValue >= 3) {
          xPosition = (canvas.width / 2.32)
        }
      }
      if (question.type === 'csat') {
        xPosition = (canvas.width / 2.20)
        if (lengthOfValue === 2) {
          xPosition = (canvas.width / 2.30)
        }
        if (lengthOfValue >= 3) {
          xPosition = (canvas.width / 2.43)
        }
      }
      // the current value received
      ctx.font = 'bold 28px Arial'
      ctx.fillText(value, xPosition, canvas.height / 1.90)
      if (question.type === 'csat') {
        ctx.font = 'bold 21px Arial'
        let percentagePosition = xPosition + ctx.measureText(value).width
        if (lengthOfValue === 2) {
          percentagePosition = xPosition + ctx.measureText(value).width + 3
        }
        if (lengthOfValue > 2) {
          percentagePosition = xPosition + ctx.measureText(value).width + 7
        }
        ctx.fillText(' %', percentagePosition, canvas.height / 1.90)
      }
      ctx.closePath()
      ctx.fill()
    }

    /**
    * responsável por desenhar o gráfico
    * @param {{Object}} options contém os parâmetros para rendereziar o gráfico
    * @param {{Object}} question contém a questão atual que o gráfico será renderizado
    */
    const Piechart = function (options, question) {
      this.options = options
      this.canvas = options.canvas
      this.ctx = this.canvas.getContext('2d')
      this.colors = options.colors
      this.draw = function () {
        let colorIndex = 0
        let startAngle = 2.75
        let sliceAngle = 0
        for (let i = 0; i < Object.keys(this.options.data).length; i++) {
          sliceAngle = 1 * Math.PI * 25 / 100
          drawPieSlice(
            this.ctx,
            this.canvas.width / 2,
            this.canvas.height / 2,
            Math.min(this.canvas.width / 2.5, this.canvas.height / 2),
            startAngle,
            startAngle + sliceAngle,
            this.colors[colorIndex % this.colors.length]
          )
          startAngle += sliceAngle
          colorIndex++
        }

        if (this.options.doughnutHoleSize) {
          let centerColor = 0
          // draw a small stroke betwen the center of the circle
          drawPieSlice(
            this.ctx,
            this.canvas.width / 2,
            this.canvas.height / 2,
            this.options.doughnutHoleSize * Math.min(this.canvas.width / 3.5, this.canvas.height / 2),
            0,
            2 * Math.PI,
            'white'
          )

          // draw the arrow, verify the position of then based on value of the chart
          if (this.options.cardData.type === 'csat') {
            // centerColor = verifyRange(this.options, [20, 40, 60, 80, 100])
            centerColor = arrowController(this.options, question, [20, 40, 60, 80, 100])
          }

          if (['kpi'].indexOf(this.options.cardData.type) > -1) {
            // centerColor = verifyRange(this.options, [20, 40, 60, 80, 100])
            centerColor = arrowController(this.options, question, [20, 40, 60, 80, 100])
          }

          // draw the main circle, colored based on the current target
          drawPieSlice(
            this.ctx,
            this.canvas.width / 2,
            this.canvas.height / 2,
            this.options.doughnutHoleSize * Math.min(this.canvas.width / 4, this.canvas.height / 2),
            0,
            2 * Math.PI,
            this.options.colors[centerColor]
          )
          drawCenterValue(this.ctx, this.options, this.canvas, question)
        }
        // draw an arc with the ranged emotes of the gauge
        startAngle = 2.75
        sliceAngle = 1 * Math.PI * 25 / 100
        const pieRadius = Math.min(this.canvas.width / 3, this.canvas.height / 2)
        const offset = sliceAngle + 90
        const labelX = this.canvas.width / 2.3 + (offset + pieRadius / 2) * Math.cos(startAngle + sliceAngle / 2)
        const labelY = this.canvas.height / 2 + (offset + pieRadius / 2) * Math.sin(startAngle + sliceAngle / 2)

        this.ctx.fillStyle = 'white'
        this.ctx.font = '23px icomoon'
        this.ctx.fillText(this.options.icons[0], labelX + 60, labelY + 10)
        this.ctx.fillText(this.options.icons[1], labelX + 85, labelY - 60)
        this.ctx.fillText(this.options.icons[2], labelX + 160, labelY - 90)
        this.ctx.fillText(this.options.icons[3], labelX + 232, labelY - 60)
        this.ctx.fillText(this.options.icons[4], labelX + 262, labelY + 10)

        // draw an arc with the ranged values of the gauge (-100 to 100)
        startAngle = 2.75
        sliceAngle = 1 * Math.PI * 25 / 100
        const pieR = Math.min(this.canvas.width / 3, this.canvas.height / 2)
        const off2 = sliceAngle + 90
        const calcCanvasWidth = this.canvas.width / 2.3 + (off2 + pieR / 2) * Math.cos(startAngle + sliceAngle / 2)
        const calcCanvasHeight = this.canvas.height / 2 + (off2 + pieR / 2) * Math.sin(startAngle + sliceAngle / 2)
        this.ctx.fillStyle = '#838383'
        this.ctx.font = 'bold 13px Arial'

        const positionsX = [calcCanvasWidth + 26, calcCanvasWidth + 25, calcCanvasWidth + 108, calcCanvasWidth + 222, calcCanvasWidth + 305, calcCanvasWidth + 310]
        const positionsY = [calcCanvasHeight + 50, calcCanvasHeight - 50, calcCanvasHeight - 133, calcCanvasHeight - 132, calcCanvasHeight - 52, calcCanvasHeight + 50]
        if (question.ui === '5emo') {
          const range = [0, 20, 40, 60, 80, 100]
          drawRange(this.ctx, calcCanvasWidth, calcCanvasHeight, range, positionsX, positionsY)
        }
      }
    }
    const lightPurple = this.cardData.average.colors[3] // roxo claro
    const lightGrey = this.cardData.average.colors[1] // roxo escuro
    const darkPurple = this.cardData.average.colors[4] // cinza claro
    const darkGrey = this.cardData.average.colors[0]
    const grey = this.cardData.average.colors[2]

    // cores padrões para exibir no gráfico de esforço
    //  lightYellow = '#f2f461' lightRed = '#f46161' lightGreen = '#c9f461' lightOrange = '#f4ab61' superGreen = '#93f461'
    const availableColors = [darkGrey, lightGrey, grey, lightPurple, darkPurple]
    // let availableIcons = ['\uE92f', '\uE930', '\uE931', '\uE932', '\uE933']
    const availableIcons = ['\uE608', '\uE607', '\uE606', '\uE600', '\uE603']
    // get the current value and clean then to show at the view
    if (this.data.average !== undefined) {
      this.valueOfGraphic = parseInt(this.data.average)
      // this.valueOfGraphic = 5
    }

    // monta o objeto que a função espera para desenhar o gráfico
    const objectToMount = {
      canvas: myCanvas,
      data: piecesOfPie,
      colors: availableColors,
      doughnutHoleSize: 0.5,
      icons: availableIcons,
      cardData: this.cardData,
      valueOfGraphic: this.valueOfGraphic
    }

    // instancia o objeto que desenha o gráfico em si
    const myDougnutChart = new Piechart(
      objectToMount,
      this.question
    )
    myDougnutChart.draw()
  }
}
</script>
<style lang="scss">
.emoteGaugeLabel span {
  font-size: 0.65vw;
}
</style>
