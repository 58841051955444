export const questionsHelper = {
  addOrder
}

function addOrder (questionsArray) {
  const questionsWithOrder = []
  questionsArray.map((q, index) => {
    q.order = index
    questionsWithOrder.push(q)
  })
  return questionsWithOrder
}
