<template>
  <div class="container-list-resend">
    <Menu />
    <ModalAlertResend v-if="showModalDelete" :item="scheduledItemToCancel" @close-modal="showModalDelete = false" @item-canceled="updateList($event)"/>
    <div class="section-list-resend">
      <div class="section-no-data" v-if="noData">
        <NoData :message="$t('resend.no_data_list')" />
      </div>
      <div class="is-loading-list-resend" ref="isLoading" v-if="loading">
        <Circle2 class="spinner-social"/>
      </div>
      <header class="header-list-resend">
        <div class="header-titles-resend">
          <p class="title-list-resend">{{ $t('resend.title_schedule') }}</p>
          <p class="count-list-resend">{{ listResend.length }} {{ $t('resend.label_schedule') }}</p>
        </div>
        <div class="header-search-resend">
          <app-select-binds
            :placeholder="$t('resend.order_by')"
            :options="filterOptions"
            :titleItem="'label'"
            id="filter-option-resend"
            :value="currentFilter"
            @model="currentFilter = $event"
            style="height: 2.2vw; justify-content: center; padding: 0; padding-top: 5px; background: #ffffff;"
          />
          <input type="text" class="input-search-resend" v-model="search" :placeholder="$t('resend.search_by_title')">
        </div>
      </header>
      <section class="section-resend-list">
        <CardScheduleResend v-for="card in currentPageItems" :key="card._id" :item="card" @cancel-scheduled-item="cancelScheduled($event)" />
      </section>
      <footer class="section-pagination-resend">
        <app-paginate
          v-if="currentPageItems.length"
          class="paginate-resend-list"
          :initValue="page"
          :value="page"
          :prevText="'<'"
          :nextText="'>'"
          @input="changePage"
          :pageRange="resendsPerPage"
          :pageCount="dynamicPageCount"
        />
      </footer>
    </div>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import { gsap } from 'gsap'
import { resendService } from '@/_services'
import NoData from '../components/insights/components/NoData.vue'
import CardScheduleResend from '../components/resend/CardScheduleResend.vue'
import ModalAlertResend from '../components/resend/ModalAlertResend.vue'

export default {
  name: 'ResendList',
  props: [],
  components: {
    Menu,
    NoData,
    ModalAlertResend,
    CardScheduleResend,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2'),
    'app-paginate': () => import('@binds-tech/binds-design-system/src/components/Paginate/Paginate'),
    'app-select-binds': () => import('@binds-tech/binds-design-system/src/components/Select/SelectBinds')
  },
  data () {
    return {
      loading: true,
      noData: false,
      showModalDelete: false,
      search: '',
      scheduledItemToCancel: null,
      listResend: [],
      page: 1,
      resendsPerPage: 5,
      dynamicPageCount: 0,
      filterOptions: [
        { label: this.$t('resend.filter_options.no_filter'), value: undefined },
        { label: this.$t('resend.filter_options.title_az'), value: 'title_az' },
        { label: this.$t('resend.filter_options.title_za'), value: 'title_za' },
        { label: this.$t('resend.filter_options.created_gt'), value: 'createdAt_gt' },
        { label: this.$t('resend.filter_options.created_lt'), value: 'createdAt_lt' },
        { label: this.$t('resend.filter_options.schedule_gt'), value: 'schedule_gt' },
        { label: this.$t('resend.filter_options.schedule_lt'), value: 'schedule_lt' }
      ],
      currentFilter: { label: this.$t('resend.filter_options.no_filter'), value: undefined }
    }
  },
  mounted () {
    this.getListResend()
  },
  watch: {
    currentPageItems: {
      immediate: true,
      handler () { this.updatePagination() }
    }
  },
  computed: {
    filteredResend () { return this.listResend.filter(resend => { return resend.title.toLowerCase().includes(this.search.toLowerCase()) }) },
    currentPageItems () {
      let filterIndex = this.page
      if (this.page > this.filteredResend.length) { filterIndex = 1 }
      return this.itemsFiltered.slice((filterIndex * this.resendsPerPage) - this.resendsPerPage, filterIndex * this.resendsPerPage)
    },
    itemsFiltered () {
      const result = this.currentFilter?.value
        ? structuredClone(this.filteredResend).sort((a, b) => {
          const scheduleDateA = new Date(a.schedulingDate)
          const scheduleDateB = new Date(b.schedulingDate)
          const createdDateA = new Date(a.createdAt)
          const createdDateB = new Date(b.createdAt)

          if (this.currentFilter.value === 'title_az') {
            return a.title?.toLowerCase() > b.title?.toLowerCase() ? 1 : -1
          } else if (this.currentFilter.value === 'title_za') {
            return a.title?.toLowerCase() < b.title?.toLowerCase() ? 1 : -1
          } else if (this.currentFilter.value === 'createdAt_lt') {
            return createdDateA - createdDateB
          } else if (this.currentFilter.value === 'createdAt_gt') {
            return createdDateB - createdDateA
          } else if (this.currentFilter.value === 'schedule_lt') {
            return scheduleDateA - scheduleDateB
          } else if (this.currentFilter.value === 'schedule_gt') {
            return scheduleDateB - scheduleDateA
          } else {
            return 0
          }
        })
        : structuredClone(this.filteredResend)

      return result
    }
  },
  methods: {
    updateList () {
      this.loading = true
      this.getListResend()
    },
    cancelScheduled (item) {
      this.scheduledItemToCancel = item
      this.showModalDelete = true
    },
    async getListResend () {
      this.listResend = []
      const result = await resendService.getSchedulesResend()
      if (result.length) {
        this.listResend = result
        gsap.to(this.$refs.isLoading, { opacity: 0, duration: 0.5, onComplete: () => { this.loading = false } })
        if (this.listResend.length <= 5) { this.page = 1 }
        this.updatePagination()
      } else {
        this.noData = true
        gsap.to(this.$refs.isLoading, { opacity: 0, duration: 0.5, onComplete: () => { this.loading = false } })
      }
    },
    changePage (page) {
      this.page = page
    },
    updatePagination () {
      const resendsLength = (this.filteredResend.length / 5)
      if (Number.isInteger(resendsLength)) {
        this.dynamicPageCount = resendsLength
      } else {
        this.dynamicPageCount = Math.trunc(resendsLength) + 1
      }
    }
  }
}
</script>

<style lang="scss">
.container-list-resend {
  background: #EFF3F8;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .section-list-resend {
    position: relative;
    width: 100vw;
    height: 100vh;
    padding: 0 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 5px;
    .is-loading-list-resend {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      background: #EFF3F8;
      width: 100vw;
      height: 100vh;
      padding-bottom: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      .spinner-social {
        width: 40px !important;
        height: 40px !important;
        border-width: 4px !important;
        border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
      }
    }
    .section-no-data {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100vw;
      height: 100vh;
      padding-bottom: 10%;
      background: #EFF3F8;
    }
    .header-list-resend {
      width: 100%;
      height: 13%;
      border-bottom: 1px solid #cacaca;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-titles-resend {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .title-list-resend {
          font-size: 1.8vw;
          font-weight: bold;
          color: #454548;
        }
        .count-list-resend {
          color: #808080;
          font-size: 0.85vw;
        }
      }
      .header-search-resend {
        width: 30%;
        height: 100%;
        padding-bottom: 10px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 10px;
        .input-search-resend {
          width: 16rem;
          height: 2.2vw;
          background: #ffffff;
          border: 1px solid #cacaca;
          border-radius: 0.3rem;
          font-size: 0.75vw;
          padding-left: 5px;
          color: #454548;
        }
      }
    }
    .section-resend-list {
      width: 100%;
      height: 77%;
      padding: 5px 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .section-pagination-resend {
      width: 100%;
      height: 8%;
      display: flex;
      align-items: center;
      justify-content: center;
      .paginate-resend-list {
        display: flex;
        align-items: center;
        justify-content: center;
        .page-item {
          width: 1.8vw;
          height: 1.8vw;
          padding: 0;
          border: 1px solid #afafaf;
          display: flex;
          align-items: center;
          justify-content: center;
          .page-link-item {
            padding: 0;
            margin: 0 -2px;
            font-size: 0.85vw;
            width: 100%;
            height: 100%;
            box-shadow: none;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
          }
        }
        .next-item, .prev-item {
          .next-link-item, .prev-link-item {
            font-size: 0.85vw;
            padding: 0;
            width: 1.8vw;
            height: 1.8vw;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .prev-link-item {
            border-right: none;
          }
        }
        .active {
          border-radius: 7px;
          margin-top: 3px;
          margin: 0 -1px;
          width: 2vw;
          height: 2.2vw;
        }
      }
    }
  }
}
</style>
