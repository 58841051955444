<template>
  <div class="content-prev-next border-footer" :style="{'background-color': survey.colors.footerBg}">
    <div class="content-footer-answer" :style="{'background-color': survey.colors.footerBg}">
      <div class="actionsButtons">
        <app-button v-if="!question.required" :style="{'background-color':survey.colors.buttonBg ,'box-shadow':'none', 'color':survey.colors.buttonColor}" variation="secondary" outline >{{ question.nextLabel }}</app-button>
      </div>
      <div v-if="surveySmallLogo" style="text-align: center;padding: 10px;">
        <img :src="surveySmallLogo" style="max-height: 3.2em;max-width: 90%;margin: 0 .5em;line-height: 1.2em;vertical-align: middle;">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionsPrevNext',
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button')
  },
  computed: {
    surveySmallLogo () {
      let result = window._.find(this.survey.images, o => o.alias === 'logoSmall')
      result = result && result.value ? result.value : this.account.theme.logo
      return result
    },
    question () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey.questions[survey.questionSelected]
    },
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    }
  }
}
</script>
<style lang="scss">
.content-footer-answer{
  width: 100%;
  height: 90px;
  padding: 20px 70px;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
  text-align: right;
  .actionsButtons {
    box-shadow: none !important;
  }
}
.border-footer {
  border-radius: 0 0 10px 10px;
}
.content-prev-next {
  padding: 20px 0 40px 0;
}
</style>
