<template>
  <app-card id="card" class="cardsContainerScroll">
    <Details
      v-if="showDetails"
      :id="sendingId"
      @closeSendings="closeSidebar"
      :class="{'expand-details': showDetails}"
    ></Details>
    <div
      v-for="(response, idx) in responses.slice(0, 50)"
      :key="response.question._id + response._id + idx"
      v-on:click="getIndividualResponses(response)"
      class="cardbox"
      :class="{ animateHere: response.added }"
    >
      <div>
        <p class="iconBox">
          <app-icon
            v-if="response.question.type === 'nps'"
            size="md"
            :name="iconsByType.nps"
            class="contactIcon"
            :class="returnStyleToCurrentResponse(response).class"
          />
          <app-icon
            v-else-if="response.question.type === 'enum'"
            class="contactIcon"
            :class="returnStyleToCurrentResponse(response).class"
            :name="iconsByType.enum"
            size="md"
          />
          <i
            v-else
            class="material-icons contactIcon"
            :class="returnStyleToCurrentResponse(response).class"
          >{{ returnIconByType(response.question.type) }}</i>
        </p>
        <p class="contactName">
          {{ response?.contact?.name || $t("dashboard.no_data_to_show.unnamed") }}
        </p>
        <p class="contactTime">{{ moment(response.createdAt) }}</p>
      </div>
      <div class="questionBox">
        <p class="questionFirstTitle">{{ replaceMeta(response.question.title, response) }}</p>
        <p class="questionSecondTitle">{{ replaceMeta(response.question.question, response) }}</p>
      </div>
      <!-- NES -->
      <div
        v-if="response.question.type === 'nes'"
        :class="returnStyleToCurrentResponse(response).class"
        class="nesBox"
      >
        <p class="textLabel">
          {{ returnStyleToCurrentResponse(response).label }}
        </p>
        <p
          v-if="response.rating !== null"
          :class="returnStyleToCurrentResponse(response).class"
          class="nesIcon"
        >
          <i class="material-icons">speed</i>
        </p>
      </div>
      <!-- CES -->
      <div
        v-if="response.question.type === 'ces'"
        :class="returnStyleToCurrentResponse(response).class"
        class="nesBox"
      >
        <p class="textLabel">
          {{ returnStyleToCurrentResponse(response).label }}
        </p>
        <p
          v-if="response.rating !== null"
          :class="returnStyleToCurrentResponse(response).class"
          class="nesIcon"
        >
          <i class="material-icons">speed</i>
        </p>
      </div>
      <!-- UI 5EMO AND TYPE CSAT -->
      <div
        v-if="
          response.question.ui === '5emo' || response.question.type === 'csat'
        "
        :class="returnStyleToCurrentResponse(response).class"
        class="emoBox"
      >
        <p class="textLabel">
          {{ returnStyleToCurrentResponse(response).label }}
        </p>
        <p
          v-if="returnStyleToCurrentResponse(response).icon"
          :class="returnStyleToCurrentResponse(response).class"
          class="emoIcon"
        >
          {{ returnStyleToCurrentResponse(response).icon }}
        </p>
      </div>
      <!-- NPS -->
      <div
        v-if="
          response.question.type === 'nps' && response.question.ui !== 'ynm'
        "
        :class="returnStyleToCurrentResponse(response).class"
        class="npsYnmBox"
      >
              <p class="textLabel">
          {{ returnStyleToCurrentResponse(response).label }}
        </p>
        <p
          :class="returnStyleToCurrentResponse(response).class"
          class="npsIcon"
        >
          <i
            class="material-icons"
            :class="returnStyleToCurrentResponse(response).class"
            >{{ returnStyleToCurrentResponse(response).icon }}</i
          >
        </p>
      </div>
      <!-- YN, YND -->
      <div
        v-if="['yn', 'ynd', 'ynm'].indexOf(response.question.ui) > -1"
        :class="returnStyleToCurrentResponse(response).class"
        class="npsYnmBox"
      >
        <p class="textLabel">
          {{ returnStyleToCurrentResponse(response).label }}
        </p>
      </div>
      <!-- UI ENUM -->
      <div
        v-if="
          response.question.type === 'enum' &&
          ['multipleOther', 'multiple'].indexOf(response.question.ui) < 0
        "
        class="enumBox"
      >
        <p class="enumOption">{{ $t("dashboard.graphics.selected_option") }}</p>
        <p class="enumValue">{{ response.value }}</p>
      </div>

      <div v-if="['multipleOther', 'multiple'].indexOf(response.question.ui) >= 0" class="multipleMatrixBox">
        <div v-for="(option, idxx) in response.value" :key="option + idxx" class="multipleBox">
          <p :style="{'text-align': option.length > 20 ? 'left' : 'center'}">{{ option }}</p>
        </div>
      </div>
      <!-- MATRIX -->
      <div v-if="response.question.type === 'matrix' && response.question.ui !== 'like'" class="container-matrix">
        <div class="matrix-box" v-show="response.value.length > 0" v-for="item in returnQuestionSubject(response)" :key="item._id">
          <div class="container-matrix-individual">
            <div class="matrix-label" v-title="item.label" title-max-width="280">
              <p class="label" :style="{'white-space': item.label.length > 87 ? 'nowrap' : 'wrap'}">{{ item.label }}</p>
            </div>
            <div class="matrix-option">
              <p class="matrix-option">{{ item.option }}</p>
            </div>
          </div>
        </div>
        <div class="matrixBox">
          <p class="containerMatrixLoop" v-show="response.value.length <= 0">{{ $t("dashboard.no_response") }}</p>
        </div>
      </div>

      <!-- -- MATRIX LIKE -- -->

      <div v-if="response.question.type === 'matrix' && response.question.ui === 'like'" class="container-matrix-like">
        <div class="matrix-like-box" v-show="response.value.length > 0" v-for="item in returnQuestionSubject(response)" :key="item._id">
          <div class="container-individual"
          :style="{'background': item.rating >= 100 ? '#51ad5915' : '#ea492215'}" v-title="item.label" title-max-width="340">
            <div class="matrix-like-label">
              <p class="label" :style="{'white-space': item.label.length > 87 ? 'nowrap' : 'wrap'}">{{ item.label }}</p>
            </div>
            <div class="icon-rating">
              <i class="material-icons up" v-if="item.rating >= 100">thumb_up</i>
              <i class="material-icons down" v-if="item.rating <= 0">thumb_down</i>
            </div>
          </div>
        </div>
        <div class="matrixBox">
          <p class="containerMatrixLoop" v-show="response.value.length <= 0">{{ $t("dashboard.no_response") }}</p>
        </div>
      </div>

      <!-- NVS -->
      <div v-if="response.question.type === 'nvs'"
      :class="returnStyleToCurrentResponse(response).class"
        class="nvsBox">
          <p class="textLabel"> {{ response.rating !== null ? response.value : $t("dashboard.no_response") }} </p>
      </div>

      <!-- tipo TEXTO, isOther ou  (Referral)-->
      <!-- referral nunca vai ter response.text, então ele sempre exibe 'sem resposta' -->
      <div
        v-if="
          response.question.ui === 'text' ||
          response.isOther ||
          response.question.type === 'referral'
        "
        style="padding: 10px"
      >
        <p
          v-if="response.question.type === 'referral'"
          style="text-align: center"
        >
          {{
            response.referralEntriesQty > 1
              ? `${response.referralEntriesQty} ${$t("dashboard.referrals")}`
              : `${response.referralEntriesQty} ${$t("dashboard.referral")}`
          }}
        </p>
        <p v-else class="realTimeText">
          {{ response.text || $t("dashboard.no_response") }}
        </p>
      </div>
    </div>
  </app-card>
</template>
<script>
export default {
  name: 'IndividualResponses',
  props: ['responses', 'realTimeQuestions'],
  components: {
    'app-card': () => import('@binds-tech/binds-design-system/src/components/Card/Card'),
    'app-icon': () => import('@binds-tech/binds-design-system/src/components/Icon/Icon'),
    Details: () => import('../../SideBarConfig/SidebarSendings.vue')

  },
  data () {
    return {
      iconsByType: {
        csat: 'insert_emoticon',
        nps: 'dashboard',
        kpi: 'insert_chart',
        ces: 'fitness_center',
        matrix: 'format_list_numbered',
        text: 'text_fields',
        referral: 'share',
        enum: 'number'
      },
      showDetails: false,
      sendingId: undefined
    }
  },
  computed: {
    lang () {
      return this.$store.getters['account/getLang']
    }
  },
  methods: {
    getIndividualResponses (item) {
      this.showDetails = false
      this.sendingId = item.sending
      setTimeout(() => {
        this.showDetails = true
        this.$root.$emit('show-details-sidebar', this.showDetails)
      }, 100)
    },
    closeSidebar () {
      this.showDetails = false
      this.$root.$emit('show-details-sidebar', this.showDetails)
    },
    returnIconByType (questionType) {
      switch (questionType) {
        case 'csat':
          return this.iconsByType.csat
        case 'nps':
          return this.iconsByType.nps
        case 'kpi':
          return this.iconsByType.kpi
        case 'nes':
          return this.iconsByType.ces
        case 'matrix':
          return this.iconsByType.matrix
        case 'text':
          return this.iconsByType.text
        case 'referral':
          return this.iconsByType.referral
        case 'enum':
          return this.iconsByType.enum
        default:
          return 'face'
      }
    },
    replaceMeta (str, sending) {
      let newString = ''
      if (str) {
        let metadata = sending.metadata || {}
        if (sending.seedData && sending.seedData.metadata) {
          metadata = sending.seedData && sending.seedData.metadata
        }
        newString = str.replace(/\*\|((?:(?!\|\*).)+)\|\*/g, (match, f) => {
          return metadata[f] || match
        })
      }
      return newString
    },
    formatRating (type, rating) {
      const range = [0, 25, 50, 75, 100]
      if (type !== 'matrix1to5') {
        return rating / 10
      }
      return range.indexOf(rating) + 1
    },
    verifyRange (sendingResponse, range) {
      if (sendingResponse.rating < range[1]) {
        return 0
      } else if (
        sendingResponse.rating >= range[1] &&
        sendingResponse.rating < range[2]
      ) {
        return 1
      } else if (
        sendingResponse.rating >= range[2] &&
        sendingResponse.rating < range[3]
      ) {
        return 2
      } else if (
        sendingResponse.rating >= range[3] &&
        sendingResponse.rating < range[4]
      ) {
        return 3
      } else if (sendingResponse.rating >= range[4]) {
        return 4
      }
    },
    returnQuestionSubject (currentQuestionOnLoop) {
      let result = null
      if (this.realTimeQuestions && this.realTimeQuestions[0]) {
        if (this.realTimeQuestions[0].questions) {
          result = this.realTimeQuestions[0].questions.find((current) => {
            return current.question._id === currentQuestionOnLoop.question._id
          })
        } else {
          result = this.realTimeQuestions.find((current) => {
            return current.question === currentQuestionOnLoop.question._id
          })
        }
      }

      if (currentQuestionOnLoop.question.type === 'matrix') {
        const response = []
        if (result && result.question && result.question.subjects) {
          result.question.subjects.forEach(res => {
            currentQuestionOnLoop.value.forEach(val => {
              if (val.rating !== null && (res._id === val._id)) {
                if (currentQuestionOnLoop.question.ui !== 'like') {
                  response.push({
                    label: res.label,
                    option: this.formatRating(currentQuestionOnLoop.question.ui, val.rating)
                  })
                } else {
                  response.push({
                    label: res.label,
                    rating: val.rating
                  })
                }
              }
            })
          })
          return response
        }
      }
      return result && result.question && result.question.subjects ? result.question.subjects : []
    },
    /**
     * retorna um objeto contendo classes, textos e demais formatações para a resposta atual do loop
     * tem o objetivo de evitar muito IF no template,
     * de forma a tentar facilitar o entendimento e implementação de demais features
     * @param {object} sendingResponse resposta atual no v-for
     * @returns {Object} retorna um objeto contendo classe, icone, textos traduzidos etc
     */
    returnStyleToCurrentResponse (sendingResponse) {
      let objectToReturn = {
        class: ['emoteGrey']
      }

      if (sendingResponse.rating === null) {
        objectToReturn.label = this.$t('dashboard.no_response')
        objectToReturn.value = this.$t('dashboard.no_response')
        objectToReturn.icon = ''
        return objectToReturn
      }
      if (sendingResponse.question.type === 'nes') {
        objectToReturn.label =
          sendingResponse.rating >= 75
            ? this.$t('dashboard.graphics.me')
            : sendingResponse.rating === 50
              ? this.$t('dashboard.graphics.ee')
              : this.$t('dashboard.graphics.le')
        objectToReturn.class =
          sendingResponse.rating >= 75
            ? ['emoteRed']
            : sendingResponse.rating === 50
              ? ['emoteGrey']
              : ['emoteGreen']
      }

      if (sendingResponse.question.type === 'nvs') {
        objectToReturn.class =
          sendingResponse.rating >= 50
            ? ['emoteGreen']
            : sendingResponse.rating === 25
              ? ['emoteYellow']
              : ['emoteRed']
      }

      if (sendingResponse.question.type === 'ces') {
        objectToReturn.label =
          sendingResponse.rating >= 80
            ? this.$t('dashboard.graphics.le')
            : sendingResponse.rating >= 50
              ? this.$t('dashboard.graphics.ee')
              : this.$t('dashboard.graphics.me')
        objectToReturn.class =
          sendingResponse.rating >= 80
            ? ['emoteGreen']
            : sendingResponse.rating >= 50
              ? ['emoteGrey']
              : ['emoteRed']
      }

      if (sendingResponse.question.type === 'nps') {
        objectToReturn.label =
          sendingResponse.rating >= 90
            ? this.$t('dashboard.graphics.promoter')
            : sendingResponse.rating >= 70
              ? this.$t('dashboard.graphics.neutralSing')
              : this.$t('dashboard.graphics.detractor')
        objectToReturn.class =
          sendingResponse.rating >= 90
            ? ['icon-color-ex']
            : sendingResponse.rating >= 70
              ? ['icon-color-mb']
              : ['icon-color-vb']
        objectToReturn.icon =
          sendingResponse.rating >= 90
            ? 'trending_up'
            : sendingResponse.rating >= 70
              ? 'remove'
              : 'trending_down'
      }

      const availableIcons = [
        {
          icon: '\uE608',
          label: this.$t('dashboard.graphics.vb'),
          class: ['icon-color-vb']
        },
        {
          icon: '\uE607',
          label: this.$t('dashboard.graphics.bd'),
          class: ['icon-color-bd']
        },
        {
          icon: '\uE606',
          label: this.$t('dashboard.graphics.satisf'),
          class: ['icon-color-mb']
        },
        {
          icon: '\uE600',
          label: this.$t('dashboard.graphics.vg'),
          class: ['icon-color-vg']
        },
        {
          icon: '\uE603',
          label: this.$t('dashboard.graphics.ex'),
          class: ['icon-color-ex']
        }
      ]
      if (sendingResponse.question.type === 'csat') {
        objectToReturn.label = this.$t('dashboard.graphics.un')
        objectToReturn.class = 'icon-color-vb'
        objectToReturn.icon = '\uE608'
        if (sendingResponse.rating >= 70) {
          objectToReturn.label = this.$t('dashboard.graphics.sat')
          objectToReturn.class = 'icon-color-ex'
          objectToReturn.icon = '\uE603'
        }
      }

      // nps ynm
      if (sendingResponse.question.ui === 'ynm') {
        objectToReturn.label =
          sendingResponse.rating === 95
            ? this.$t('dashboard.graphics.op1')
            : sendingResponse.rating === 75
              ? this.$t('dashboard.graphics.op4')
              : this.$t('dashboard.graphics.op2')
      }
      if (['kpi'].indexOf(sendingResponse.question.type) > -1) {
        const index = this.verifyRange(sendingResponse, [0, 25, 50, 75, 100])
        objectToReturn = availableIcons[index]
        if (sendingResponse.question.ui === 'yn') {
          objectToReturn.label =
            sendingResponse.rating === 100
              ? this.$t('dashboard.graphics.op1')
              : this.$t('dashboard.graphics.op2')
        }
        if (sendingResponse.question.ui === 'ynd') {
          objectToReturn.label =
            sendingResponse.rating === 100
              ? this.$t('dashboard.graphics.op1')
              : sendingResponse.rating === 50
                ? this.$t('dashboard.graphics.op3')
                : this.$t('dashboard.graphics.op2')
        }
      }
      return objectToReturn
    },
    moment (date) {
      this.$moment.locale(this.lang)
      return this.$moment(date).fromNow()
    }
  }
}
</script>
<style lang="scss" scoped>
.icon-color-vb {
  background: rgb(250, 96, 96);
  color: #fff !important;
}
.icon-color-bd {
  background: #f26c3c;
  color: #fff !important;
}
.icon-color-mb {
  background: #f8c43d;
  color: #fff !important;
}
.icon-color-vg {
  background: #37b0e4;
  color: #fff !important;
}
.icon-color-ex {
  background: #49bb59;
  color: #fff !important;
}
.realTimeText {
  font-size: 15px;
  font-style: italic;
  text-align: center;
  color: #717171;
}
.textLabel {
  display: flex;
  margin: auto auto;
  font-size: 13px;
  font-weight: bold;
}
.animateHere {
  @extend %animated-faster;
  @extend %fadeIn;
}
.cardbox {
  box-shadow: rgba(48, 48, 48, 0.09) 0px 5px 7px 0px,
    rgba(0, 0, 0, 0.01) 0px 5px 4px 0px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 30px;
  position: inherit;
  cursor: pointer;
}
.iconBox {
  position: absolute;
  top: -4px;
  left: -17px;
}
.contactIcon {
  padding: 7px;
  border-radius: 100%;
}
// Como é scoped para esse arquivo, podemos deixar assim essa classe
.b-icon.md {
  font-size: 24px !important;
  padding: 7px;
}
.contactName {
  font-size: 15px;
  margin-left: 14px;
  margin-top: -1px;
  width: 93%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.contactTime {
  margin-left: 16px;
  margin-top: 4px;
  font-size: 12px;
  color: rgb(123, 123, 123);
}
.questionBox {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px 5px 0px 0px;
  text-align: center;
}
.questionFirstTitle {
  font-size: 16px;
  padding-bottom: 5px;
  width: 167px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 auto;
  min-height: 15px;
  font-weight: 500;
  padding-top: 1px;
}
.questionSecondTitle {
  font-size: 12px;
  color: #080808;
  width: 75%;
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;
  margin: 0 auto;
  min-height: 20px;
  max-height: 47px;
  }
.nesBox {
  justify-content: space-between;
  display: flex;
  padding: 7px;
  border-radius: 0px 0px 7px 7px;
}
.nesIcon {
  border-radius: 100%;
  padding: 6px 9px 6px 7px;
  position: absolute;
  right: 10px;
  top: 125px;
}
.emoBox {
  justify-content: space-between;
  display: flex;
  padding: 7px;
  border-radius: 0px 0px 7px 7px;
}

.nvsBox {
  justify-content: space-between;
  display: flex;
  padding: 7px;
  border-radius: 0px 0px 7px 7px;
}

.emoIcon {
  border-radius: 100%;
  padding: 3px;
  position: absolute;
  right: 10px;
  top: 126px;
  font-family: icomoon;
  font-size: 29px;
}
.npsYnmBox {
  justify-content: space-between;
  display: flex;
  padding: 7px;
  border-radius: 0px 0px 7px 7px;
}
.npsIcon {
  border-radius: 100%;
  padding: 5px;
  position: absolute;
  right: 10px;
  top: 126px;
  & i {
    border: 1px solid #fff;
    border-radius: 100%;
    padding: 2px;
  }
}
.enumBox {
  display: inherit;
  padding: 12px;
  background-color: #e8e7e73d;
  color: #080808;
  font-weight: 500;
  font-size: 14px;
}
.enumBox > .enumOption {
  text-align: center;
  background-color: whitesmoke;
  padding: 6px;
  border-radius: 6px;
}
.enumBox > .enumValue {
  margin-top: 10px;text-align: center;
}

.container-matrix-like {
  padding: 0px;
  width: 100%;
  max-height: 295px;
  overflow-y: auto;
  .matrix-like-box {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    .container-individual {
      border-radius: 5px;
      min-height: 40px;
      width: 100%;
      display: flex;
      padding: 5px;
      margin-bottom: 4px;
      border: 1px solid #e9e9e9;
      .matrix-like-label {
        width: 80%;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        .label {
          color: #131313;
          line-height: 19px;
          font-size: 15px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .icon-rating {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        .up {
          color: #51ad59;
        }
        .down {
          color: #ea4922;
        }
      }
    }
  }
}

.container-matrix {
  width: 100%;
  max-height: 295px;
  overflow-y: auto;
  .matrix-box {
    width: 100%;
    overflow: hidden;
    .container-matrix-individual {
      border-radius: 5px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
      .matrix-label {
        position: relative;
        background: #f7f7f7;
        width: 85%;
        height: 100%;
        padding: 3px 5px;
        border-radius: 3px;
        border: 1px solid #dadada;
        display: flex;
        align-items: center;
        justify-content: center;
        .label {
          color: #424242;
          line-height: 19px;
          font-size: 15px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .matrix-label::before {
        content: '';
        background: #f7f7f7;
        width: 10px;
        height: 10px;
        right: -6px;
        border-left: 1px solid #dadada;
        border-top: 1px solid #dadada;
        transform: rotate(-225deg);
        position: absolute;
      }
      .matrix-option {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          background: #f7f7f7;
          border: 1px solid #dadada;
          font-size: 14px;
          font-weight: 600;
          color: #424242;
          width: 30px;
          border-radius: 50%;
        }
      }
    }
  }
}

.transformToLeft {
  transform: translate(6%, 18%);
}
.realTimeText::before {
  color: #ccc;
  font-size: 2.8em;
  line-height: 0;
  height: 0;
  vertical-align: -0.5em;
  display: inline-block;
  font-family: Georgia, serif;
  content: "“";
  margin-right: 0.09em;
  margin-bottom: -0.5em;
}
.realTimeText::after {
  color: #ccc;
  font-size: 2.8em;
  line-height: 0;
  height: 0;
  vertical-align: -0.5em;
  display: inline-block;
  font-family: Georgia, serif;
  content: "”";
  margin-right: 0.09em;
  margin-bottom: -0.5em;
}
.cardsContainerScroll {
  height: 74.5vh;
  border-radius: 15px 8px 15px 15px !important;
  overflow: hidden scroll !important;
  width: 100% !important;
  padding: 30px !important;
}
.emoteGrey {
  color: #fff !important;
  background-color: #7b7b7b;
}
.emoteRed {
  color: #fff !important;
  background-color: rgb(250, 96, 96);
}
.emoteGreen {
  color: #fff !important;
  background-color: #49bb59;
}

.emoteYellow {
  color: #fff !important;
  background-color: #F8C43D;
}
</style>
