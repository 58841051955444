<template>
  <div class="multiple multiple-create" :class="{ 'widget-preview': isWidget && question.ui === 'multipleOther' }">
    <div v-if="render" class="options-box-multiple">
      <div v-for="(item, index) in question.options" :key="index">
        <div class="row middle-xl middle-lg middle-md middle-sm middle-xs">
          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 p0">
            <app-form-checkbox class="multiple-checkbox"/>
          </div>
          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <edit-title
              :class="{ 'special-pad-multiple': item.label.length > 37 }"
              class="text-space-multiple"
              v-if="!preview"
              :title="item.label"
              :color="colors.splashColor"
              @updateSurveyTitle="updateQuestionDescription($event,index)"
              :identify="`multi-${index}`"
              :minHeight="15"
              :maxHeight="90"
              type="textarea"
            />
            <div class="subject-options preview-widget" v-else>
              <app-heading level="h6" :style="{'color': colors.splashColor }">{{ item.label }}</app-heading>
            </div>
          </div>
          <div v-if="!preview && question.options.length > 1" class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 pad-delete">
            <button v-on:click="deleteItem(index)">
              <i class="material-icons del-multiple-op">delete</i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!preview" :class="{ 'add-other': question.ui === 'multipleOther' }">
      <app-button  v-title="$t('survey.add_input')" class="btn-circle-accent" variation="primary" floating v-on:click="addItem()">
        <i class="material-icons">add</i>
      </app-button>
    </div>
    <div v-if="question.ui === 'multipleOther'" class="row middle-xl middle-gl middle-md middle-sm middle-xs widget">
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 p0">
        <app-form-checkbox class="multiple-checkbox"/>
      </div>
      <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10">
        <!-- edit-title ficará desabilitado quando for a "other", a edição é pra ser feita só na barra lateral de configurações -->
        <edit-title
          style="pointer-events: none;"
          class="text-space-multiple"
          v-if="!preview"
          :title="question.otherLabel"
          :color="colors.splashColor"
          @updateSurveyTitle="updateOtherLabel($event)"
          :identify="`other-${question._id}`"
          :minHeight="15"
          :maxHeight="90"
          type="textarea"
        />
        <div v-else>
          <app-heading level="h6" :style="{'color': colors.splashColor }">{{ question.otherLabel }}</app-heading>
        </div>
      </div>
    </div>
    <div class="row" v-if="question.ui === 'multipleOther' && !preview">
      <app-form-input
        :placeholder="question.placeholder"
        border-bottom
        class="other-input-multiple col-lg-10 col-md-10 col-sm-10"
        value=""
      ></app-form-input>
    </div>
  </div>
</template>

<script>
export default {
  name: 'appmultiple',
  props: ['colors', 'question', 'preview', 'isWidget'],
  components: {
    'edit-title': () => import('../../EditTitle.vue'),
    'app-form-checkbox': () => import('@binds-tech/binds-design-system/src/components/Form/Checkbox'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () { return { render: true } },
  created () {
    if (this.question.options.length === 0) {
      const options = [
        { label: this.$i18n.t('survey.option_1'), value: this.$i18n.t('survey.option_1') },
        { label: this.$i18n.t('survey.option_2'), value: this.$i18n.t('survey.option_2') },
        { label: this.$i18n.t('survey.option_3'), value: this.$i18n.t('survey.option_3') }
      ]
      this.question.options = options
    }
    if ((this.question.required && this.question.limitAnswer) && (this.question.minLength === 0 && this.question.maxLength === 0)) {
      this.question.minLength = 1
      this.question.maxLength = 1
    }
  },
  computed: {
    currentSurvey () { return this.$store.getters['survey/getSurvey'] }
  },
  methods: {
    updateQuestionDescription (e, idx) {
      this.question.options[idx].label = e
      this.question.options[idx].value = e
      this.$store.commit('survey/upsertQuestion', this.question)

      const hasTrigger = this.currentSurvey.ticket.triggers.length
      const hasTriggerInQuestion = this.currentSurvey.ticket.triggers.find(trigger => trigger.questionId === this.question._id && trigger.responseIndex === idx)

      if (hasTrigger && hasTriggerInQuestion) {
        hasTriggerInQuestion.responseContents = e
      }
    },
    addItem () {
      const count = this.question.options.length + 1
      this.question.options.push({ label: this.$i18n.t('survey.option_added') + count, value: this.$i18n.t('survey.option_added') + count })
      this.$store.commit('survey/upsertQuestion', this.question)
    },
    deleteItem (idx) {
      this.render = false
      this.question.options.splice(idx, 1)
      setTimeout(function () { this.render = true }.bind(this), 200)
      this.$store.commit('survey/upsertQuestion', this.question)
    },
    updateOtherLabel (e) {
      this.question.otherLabel = e
      this.$store.commit('survey/upsertQuestion', this.question)
    }
  }
}
</script>
<style lang="scss">
.multiple {
  position: relative;
  padding-top: 35px;
  width: 550px;
  margin: 0 auto;
  button.binds-button.medium.primary.floating {
    position: absolute;
    bottom: -15px;
    left: -5px;
    width: 35px;
    height: 35px;
    line-height: 14px;
    i {
      font-size: 24px !important;
      margin-top: 0px !important;
    }
  }
  .input-wrapper:after { display: none; }
}
.multiple-create {
  padding: 0px 20px 20px 0px !important;
  width: 60%;
}
.multiple-checkbox {
  & .checkmark {
    height: 16px !important;
    width: 16px !important;
  }
}
.del-multiple-op {
  font-size: 25px !important;
  color: gray;
}
.text-space-multiple {
  margin-top: 25px;
  & textarea {
    height: 50px !important;
  }
  & .input-wrapper .input {
    padding-top: 12px !important;
    font-size: 14px !important;
    text-align: left;
  }
}
.special-pad-multiple {
  & .input-wrapper .input {
    padding: 0px 5px 7px 5px !important;
  }
}
.other-input-multiple {
  padding: 0px !important;
  & .text-field__input > input {
    font-size: 14px !important;
  }
}
.options-box-multiple {
  max-height: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.pad-delete {
  padding: 22px 0px 0px 0px !important;
}
.add-other {
  button.binds-button.medium.primary.floating {
    bottom: 145px !important;
  }
}
.widget-preview {
  display: block !important;
  .widget {
    margin: -20px 0px 30px 30px !important;
    h6 {
      padding-top: 23px !important;
      text-align: left !important;
    }
  }
}
// .binds-content-widget {
//   .binds-item-single {
//     .input-wrapper {
//       &:after {
//         display: none;
//       }
//     }
//   }
// }
</style>
