<template>
  <div class="row center-lg center-md center-sm">
    <div class="select-animation col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <v-select
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
        label="label"
        :options="configAnimation"
        :placeholder="configAnimation[0].label"
        v-model="animationSelected"
        :clearable="false"
        @input="changeAnimation(animationSelected)"
      ></v-select>
    </div>
    <div class="content-animation" v-if="animationSelected && animationSelected.url.length > 0">
      <img :src="animationSelected.url">
    </div>
  </div>
</template>
<script>
export default {
  name: 'Animations',
  data () {
    return {
      configAnimation: [
        { label: this.$i18n.t('survey.no_animation'), name: '', isActive: false, url: '' },
        { label: 'Tiles Page', name: 'tilesPage', isActive: true, url: 'img/tiles-page.gif' },
        { label: 'Inside Box', name: 'insideBox', isActive: true, url: '/img/inside-box.gif' }
      ],
      animationSelected: null
    }
  },
  components: {
    vSelect: () => import('vue-select')
  },
  created () {
    const survey = this.$store.getters['survey/getSurvey']
    if (survey.animation && survey.animation.name) {
      this.configAnimation.forEach(item => {
        if (item.name === survey.animation.name) {
          this.animationSelected = item
        }
      })
    }
  },
  methods: {
    changeAnimation (item) {
      if (item) {
        this.$store.commit('survey/updateAnimation', { isActive: item.isActive, name: item.name })
      } else {
        this.$store.commit('survey/updateAnimation', { isActive: false, name: '' })
      }
    }
  }
}
</script>
<style lang="scss">
.select-animation {
  width: 100%;
  display: flex;
  justify-content: center;
  .v-select {
    padding: 0;
  }
}
.content-animation {
    margin-top: 10px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      filter: grayscale(1);
    }
  }
</style>
