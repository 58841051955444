var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-synthetic"},[_c('app-heading',{staticClass:"title-synthetic",attrs:{"level":"h4"}},[_vm._v(_vm._s(_vm.$t('bvs.sentiment_synthetic_title')))]),(!_vm.dataAlert)?_c('div',{staticClass:"section-synthetic"},_vm._l((_vm.data),function(item,idx){return _c('div',{key:idx,staticClass:"item-synthetic"},[_c('div',{staticClass:"name-synthetic"},[_c('p',{staticClass:"name-term"},[_vm._v(_vm._s(item.brand.type ? item.brand[item.brand.type].name : item.brand.name))]),([item.brand.name, item.brand.type ] === 'twitter')?_c('p',{staticClass:"name-screen"},[_vm._v("@"+_vm._s(item.brand[item.brand.type].name)+" "),(item.brand[item.brand.type].verified)?_c('i',{staticClass:"material-icons icon-term-verified"},[_vm._v("verified")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"infos-metrics"},[_c('div',{staticClass:"square-bvs"},[_c('div',{staticClass:"name-and-count"},[_c('p',{staticClass:"item-name"},[_vm._v(_vm._s(_vm.$t('bvs.sentiment_bvs')))]),_c('p',{staticClass:"item-count"},[_vm._v(_vm._s(item.bvs.toFixed(1)))])]),_c('i',{staticClass:"material-icons icon-square",style:({'color':
              item.bvs.toFixed(1) <= 19.9 ? '#ea484d':
              item.bvs.toFixed(1) <= 39.9 ? '#EF874C':
              item.bvs.toFixed(1) <= 59.9 ? '#f8c43d':
              item.bvs.toFixed(1) <= 79.9 ? '#9FCD35':
              '#57AD26'})},[_vm._v(_vm._s(_vm.returnEmotion(item.bvs.toFixed(1))))])]),_c('div',{staticClass:"square-nps"},[_c('div',{staticClass:"name-and-count"},[_c('p',{staticClass:"item-name"},[_vm._v(_vm._s(_vm.$t('bvs.sentiment_nps')))]),_c('p',{staticClass:"item-count"},[_vm._v(_vm._s(item.nps.toFixed(1)))])]),_c('i',{staticClass:"material-icons icon-square",style:({'color':
              item.nps.toFixed(1) <= -49.9 ? '#EA484D':
              item.nps.toFixed(1) <= -1 ? '#EF874C':
              item.nps.toFixed(1) <= 49.9 ? '#F8C43D':
              '#9FCD35'})},[_vm._v("speed")])]),_c('div',{staticClass:"square-mentions"},[_c('div',{staticClass:"name-and-count"},[_c('p',{staticClass:"item-name"},[_vm._v(_vm._s(_vm.$t('bvs.sentiment_mentions')))]),_c('p',{staticClass:"item-count"},[_vm._v(_vm._s(item.totalMentions))])])])])])}),0):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"container-loading"},[_c('DoubleBounce',{staticClass:"data-loading-spinner animated fadeIn"})],1):_vm._e(),(!_vm.isLoading && _vm.dataAlert)?_c('div',{staticClass:"alert-data"},[_c('i',{staticClass:"material-icons",staticStyle:{"margin":"0 10px 2px 0"}},[_vm._v("error")]),_c('app-heading',{attrs:{"level":"h5"}},[_vm._v(_vm._s(_vm.$t('bvs.no_data')))])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }