import i18n from '../translations/i18n'
import { constants } from './constants'
const { SUPER_ADMIN } = constants.user.TYPES

const actionsList = [
  { name: '', label: i18n.t('activity.interface.all') },
  { name: 'created', label: i18n.t('activity.interface.actions.created') },
  { name: 'updated', label: i18n.t('activity.interface.actions.updated') },
  { name: 'deleted', label: i18n.t('activity.interface.actions.deleted') },
  { name: 'sent', label: i18n.t('activity.interface.actions.sent') },
  { name: 'expired', label: i18n.t('activity.interface.actions.expired') },
  { name: 'unexpired', label: i18n.t('activity.interface.actions.unexpired') },
  { name: 'added', label: i18n.t('activity.interface.actions.added') },
  { name: 'removed', label: i18n.t('activity.interface.actions.removed') }
]

const entityList = (userType) => {
  const base = [
    { name: '', label: i18n.t('activity.interface.all') },
    { name: 'annotation', label: i18n.t('activity.interface.entities.annotation') },
    { name: 'user', label: i18n.t('activity.interface.entities.user') },
    { name: 'seedBatch', label: i18n.t('activity.seedBatch') },
    { name: 'survey', label: i18n.t('activity.interface.entities.survey') },
    { name: 'message', label: i18n.t('activity.interface.entities.message') },
    { name: 'Scheduled', label: i18n.t('activity.interface.entities.scheduled') },
    { name: 'ResendSending', label: i18n.t('activity.interface.entities.ResendSending') },
    { name: 'addTag', label: i18n.t('activity.addTag') },
    { name: 'removeTag', label: i18n.t('activity.removeTag') },
    { name: 'collector', label: i18n.t('activity.interface.entities.collector') },
    { name: 'emailTest', label: i18n.t('activity.interface.entities.emailTest') },
    { name: 'role', label: i18n.t('activity.interface.entities.role') },
    { name: 'ticketCategory', label: i18n.t('activity.interface.entities.ticketCategory') },
    { name: 'ticket', label: i18n.t('activity.ticket') }
  ]

  if (userType === SUPER_ADMIN) {
    base.push({ name: 'token', label: i18n.t('activity.interface.entities.token') })
  }

  return base
}

export const activityHelper = {
  actionsList,
  entityList
}
