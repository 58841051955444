import { callApiService } from '@/_services'

const API_URL = process.env.VUE_APP_URL

export const insightsService = {
  getSurveysWithQuestionsTypeText,
  getInsightsFromQuestion,
  getReports,
  getMetrics,
  getGeneralPerformance,
  getSwot
}

async function getSurveysWithQuestionsTypeText () {
  const url = `${API_URL}insights/surveys-available`
  const result = await callApiService.callApi(url, 'get')
  return result
}

/**
 * Get insights from question text.
 *
 * @param {Object} params - Parâmetros para a consulta.
 * @param {string} params.q - Parâmetros do filtro avançado.
 * @param {string} params.date - Data para a consulta.
 * @param {string} params.textSearch - Parâmetro para pesquisa de texto.
 * @param {string} params.questionId - O ID da pergunta relacionada à consulta.
 * @param {string} params.surveyId - O ID da pesquisa relacionada à consulta.
 * @param {string} params.endRange - Range final da paginação.
 * @param {string} params.startRange - Range inicial da paginação.
 * @returns {Promise<Object>} - Uma promessa que resolve em um objeto contendo os dados da consulta.
 */

async function getInsightsFromQuestion (params) {
  let url = `${API_URL}insights/texts?surveyId=${params.surveyId}`

  if (params.questionId) { url = `${url}&questionId=${params.questionId}` }

  if (params.q) { url = `${url}&q=${params.q}` }

  if (params.date) { url = `${url}&startDate=${params.date.startDate}&endDate=${params.date.endDate}` }

  if (params.textSearch) { url = `${url}&textSearch=${params.textSearch}` }

  const result = await callApiService.callApi(url, 'get', null, params.endRange, null, params.startRange, null)

  return { data: await result.data, range: result.range }
}

const generateUrl = (params, url) => {
  Object.keys(params).forEach(key => {
    switch (key) {
      case 'date':
        url = `${url}&startDate=${params.date.startDate}&endDate=${params.date.endDate}`
        break

      default:
        url = `${url}&${key}=${params[key]}`
        break
    }
  })
  return url
}

/**
 * Get general metrics.
 *
 * @param {Object} params - Parâmetros para a consulta.
 * @param {string} params.q - Consulta de pesquisa.
 * @param {string} params.date - Data para a consulta.
 * @param {string} params.periods - O período de tempo para a consulta (um dos seguintes: 'day', 'week', 'month', 'semester', 'year').
 * @param {string} params.metrics - O período de tempo para a consulta (um dos seguintes: 'emotion','classification','sentiment','product').
 * @param {string} params.surveyId - O ID da pesquisa relacionada à consulta.
 * @returns {Promise<Object>} - Uma promessa que resolve em um objeto contendo os dados da consulta.
 */

async function getMetrics (params) {
  const url = `${API_URL}insights/metrics?`

  const result = await callApiService.callApi(generateUrl(params, url), 'get')

  return result
}

/**
 * Get general performance over time.
 *
 * @param {Object} params - Parâmetros para a consulta.
 * @param {string} params.q - Consulta de pesquisa.
 * @param {string} params.date - Data para a consulta.
 * @param {string} params.periods - O período de tempo para a consulta (um dos seguintes: 'day', 'week', 'month', 'semester', 'year').
 * @param {string} params.metrics - O período de tempo para a consulta (um dos seguintes: 'emotion','classification','sentiment','product').
 * @param {string} params.surveyId - O ID da pesquisa relacionada à consulta.
 * @returns {Promise<Object>} - Uma promessa que resolve em um objeto contendo os dados da consulta.
 */

async function getGeneralPerformance (params) {
  const url = `${API_URL}insights/general-performance?`

  const result = await callApiService.callApi(generateUrl(params, url), 'get')

  return result
}

/**
 * Get data SWOT.
 *
 * @param {Object} params - Parâmetros para a consulta.
 * @param {string} params.q - Consulta de pesquisa.
 * @param {string} params.date - Data para a consulta.
 * @param {string} params.periods - O período de tempo para a consulta (um dos seguintes: 'day', 'week', 'month', 'semester', 'year').
 * @param {string} params.surveyId - O ID da pesquisa relacionada à consulta.
 * @returns {Promise<Object>} - Uma promessa que resolve em um objeto contendo os dados da consulta.
 */

async function getSwot (params) {
  const url = `${API_URL}insights/swot?`

  const result = await callApiService.callApi(generateUrl(params, url), 'get')

  return result
}

/**
 * Get reports data.
 *
 * @param {Object} params - Parâmetros para a consulta.
 * @param {string} params.surveyId - O ID da pesquisa relacionada à consulta.
 * @returns {Promise<Object>} - Uma promessa que resolve em um objeto contendo os dados da consulta.
 */

async function getReports (surveyId) {
  const url = `${API_URL}insights/reports?surveyId=${surveyId}`
  return await callApiService.callApi(url, 'get', null, null, null, null)
}
