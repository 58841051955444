<template>
  <div class="menu-social-media">
    <div class="filters-social-media">
      <div class="filter-date-social-media">
        <div class="date-from" style="width: 220px;">
          <app-heading level="h5">{{ $t('bvs.date_from') }}</app-heading>
          <v-date-picker v-model='date' color="gray" locale="pt-br" mode="date" @input="changeDate" is-range>
            <!-- para debugar coloque no v-date-picker popover="false" e no v-slot puxe togglePopover e coloque o @click="() => togglePopover()"   no div -->
            <template v-slot="{ inputValue, inputEvents }">
              <div class="date-picker-input flex justify-center items-center">
                <div :style="{'display': 'flex', 'justify-content': 'space-between', 'padding': '10px', 'border-bottom': '1px solid #e1e1e1', 'background': '#f1f4f7'}" v-on="inputEvents.start">
                  <div>
                    <span>{{inputValue.start}}</span>
                  </div>
                  <span>~</span>
                  <div>
                    <span>{{inputValue.end}}</span>
                  </div>
                </div>
              </div>
            </template>
            <template #footer>
                <div style="padding: 0.5em;display: flex;">
                  <button @click="changeDate(mockDate)" v-for="(mockDate, index) in rangeOfDates" :key="index" style="height: 2.2em; padding: 0.3em; font-size: 1em;margin: 0.4em;">{{ $t(`date_filter.periods.${mockDate}`)}}</button>
                </div>
            </template>
          </v-date-picker>
        </div>
      </div>
      <div class="filter-nps-social-media">
        <app-heading level="h5" class="title-filter-nps">{{ $t('bvs.filter_sentiment') }}</app-heading>
        <v-select
          class="filter-nps"
          label="name"
          :placeholder="$t('bvs.filter_sentiment_placeholder')"
          v-model="filterNpsSelected"
          :reduce="name => name.value"
          :options="filterNpsOptions"
          @input="changeMetric()"
        ></v-select>
      </div>
      <div class="filter-terms-social-media">
        <app-heading level="h5" class="title-term">{{ $t('bvs.search_text') }}</app-heading>
        <div class="search-term-social-media">
          <input type="text"
            class="input-term"
            :placeholder="$t('bvs.search_text_placeholder')"
            @keypress.enter="textSearch()"
            v-model="searchText">
            <button class="add-term"
              @click="textSearch()">
              <i class="material-icons icon-social-media-search">search</i>
            </button>
        </div>
      </div>
      <div class="selection-terms">
        <app-heading level="h5">{{ $t('bvs.filter_user') }}</app-heading>
        <SelectBrands :options="filterTermOptions" :selecteds="filterTermSelected" />
      </div>
    </div>
    <div class="edit-media" :style="{'border-left': `1px solid #e1e1e1`}">
      <button
        v-title="$t('bvs.config_users')" title-max-width="320"
        @click="showModalEditMedia()"
        class="btn-social-media-edit"
        :style="{'background': colors.accent + '0a'}">
        <i class="material-icons icon-social-media-edit">settings</i>
      </button>
      <div class="waiting-terms" v-if="waitingTerms">
        <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
        <p class="waiting-terms-label">{{ $t('bvs.updating_data') }}</p>
      </div>
      <!-- <button
        @click="changeThema()"
        class="btn-theme"
        :style="{'border': `2px solid ${colors.accent + 55}`,
        'background': colors.accent + '0a'}">
        <i class="material-icons icon-social-media-edit" v-if="theme === 'light'">light_mode</i>
        <i class="material-icons icon-social-media-edit" v-if="theme === 'dark'">dark_mode</i>
      </button> -->
    </div>
  </div>
</template>

<script>
import { dateFormatter } from '@/_helpers'
import SelectBrands from '../components/SelectBrands.vue'

export default {
  name: 'MenuSocialMedia',
  props: [],
  components: {
    vSelect: () => import('vue-select'),
    SelectBrands,
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading')
  },
  data () {
    return {
      isLoading: false,
      waitingTerms: false,
      openOpt: false,
      date: null,
      search: '',
      searchText: '',
      gte: '',
      lte: '',
      filterNpsSelected: '',
      filterNpsOptions: [
        { name: this.$i18n.t('bvs.sentiment.all'), value: '' },
        { name: this.$i18n.t('bvs.sentiment.positive'), value: 'good' },
        { name: this.$i18n.t('bvs.sentiment.neutral'), value: 'neutral' },
        { name: this.$i18n.t('bvs.sentiment.negative'), value: 'bad' }
      ],
      filterTermSelected: [],
      filterTermOptions: [],
      rangeOfDates: ['day', 'week', 'month', 'semester', 'year'],
      socialMedia: {
        startDate: '',
        endDate: '',
        sentiment: '',
        search: '',
        text: ''
      },
      theme: 'light'
    }
  },
  async created () {
    this.isLoading = true
    // Verifica se já tem filtros salvos no store
    this.filterTermOptions = this.account.brandsTracking.map(item => {
      return {
        name: item[item.type].name,
        value: item[item.type].name.toLowerCase().replaceAll(' ', ''),
        id: item._id,
        selected: false,
        image: item[item.type].profile_image_url || 'https://w7.pngwing.com/pngs/591/439/png-transparent-cloud-upload-export-upload-upload-to-cloud-cute-tech-icon-set-icon.png'
      }
    })
    let termsIds = ''
    this.filterTermSelected = this.filters.filterTermSelected
    if (this.filterTermSelected.length === this.account.brandsTracking.length) {
      this.filterTermSelected = ''
    } else {
      this.filterTermSelected.forEach(t => { termsIds = termsIds.concat(`${t.id},`) })
    }
    this.filterNpsSelected = this.filters.filters.sentiment
    this.socialMedia.sentiment = this.filters.filters.sentiment
    this.socialMedia.text = this.filters.filters.text
    this.searchText = this.filters.filters.text
    this.gte = this.$moment(this.filters.filters.startDate).format('YYYY-MM-DD')
    this.lte = this.$moment(this.filters.filters.endDate).format('YYYY-MM-DD')
    this.socialMedia.startDate = this.$moment(this.gte, 'YYYY-MM-DD HH:mm ZZ').add(0, 'hours').utc().format()
    this.socialMedia.endDate = this.$moment(this.lte, 'YYYY-MM-DD HH:mm ZZ').add(1, 'days').subtract(1, 'minutes').utc().format()
    this.date = {
      start: this.socialMedia.startDate,
      end: this.socialMedia.endDate
    }
    this.socialMedia.terms = termsIds.slice(0, -1)
    this.$router.push({ name: 'Social', query: this.socialMedia }).catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error', err) })
    this.isLoading = false

    this.$root.$on('search-by-clicked-text', (text) => {
      this.searchText = text
      this.textSearch()
    })
  },
  mounted () {
    setTimeout(() => {
      const hasUpdating = this.$store.getters['filtersSocialMedia/getFilters'].isUpdating
      this.waitingTerms = hasUpdating
    }, 1500)

    this.$root.$on('update-done', () => {
      const hasUpdating = this.$store.getters['filtersSocialMedia/getFilters'].isUpdating
      this.waitingTerms = hasUpdating
    })

    this.$root.$on('update-brands-selecteds', payload => {
      this.socialMedia.terms = payload.slice(0, -1)
      this.$router.push({ name: 'Social', query: this.socialMedia }).catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error', err) })
      this.$store.commit('filtersSocialMedia/updateFilters', { filters: this.socialMedia })
      this.$root.$emit('update-graphics')
    })
  },
  computed: {
    filters () {
      return this.$store.getters['filtersSocialMedia/getFilters']
    },
    colors () {
      const colors = this.$store.getters['account/getAccount'].theme.colors
      return colors
    },
    account () {
      const account = this.$store.getters['account/getAccount']
      return account
    }
  },
  methods: {
    textSearch () {
      this.socialMedia.text = this.searchText
      this.$router.push({ name: 'Social', query: this.socialMedia }).catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error', err) })
      this.$store.commit('filtersSocialMedia/updateFilters', { filters: this.socialMedia })
      this.$root.$emit('update-graphics')
    },
    changeThema () {
      this.theme = this.theme === 'light' ? 'dark' : 'light'
      this.$store.commit('filtersSocialMedia/updateFilters', { theme: this.theme })
    },
    changeMetric () {
      this.socialMedia.sentiment = this.filterNpsSelected
      this.$router.push({ name: 'Social', query: this.socialMedia }).catch(err => { if (err.name !== 'NavigationDuplicated') console.log('error', err) })
      this.$store.commit('filtersSocialMedia/updateFilters', { filters: this.socialMedia })
      this.$root.$emit('update-graphics')
    },
    showModalEditMedia () {
      this.$root.$emit('modal-media', true)
    },
    changeDate (dates) {
      if (typeof dates === 'string') {
        this.socialMedia.startDate = dateFormatter.stringPeriodToPlainDate(dates).formatedStart
        this.socialMedia.endDate = dateFormatter.stringPeriodToPlainDate(dates).formatedEnd
      } else {
        this.socialMedia.startDate = this.$moment(dates.start, 'YYYY-MM-DD HH:mm ZZ').startOf('day').utc().format()
        this.socialMedia.endDate = this.$moment(dates.end, 'YYYY-MM-DD HH:mm ZZ').endOf('day').utc().format()
      }
      this.date = {
        start: this.socialMedia.startDate,
        end: this.socialMedia.endDate
      }
      this.$router.push({ name: 'Social', query: this.socialMedia })
      this.$store.commit('filtersSocialMedia/updateFilters', { filters: this.socialMedia })
      this.$root.$emit('update-graphics')
    }
  }
}
</script>

<style lang="scss">
.menu-social-media {
  z-index: 5;
  width: 98vw;
  height: 120px;
  margin: 20px 0;
  padding: 5px;
  border-radius: 10px;
  background: #ffffff70;
  border: 1px solid #dbdbdb;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  .filters-social-media {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .filter-date-social-media {
      display: flex;
      .date-from {
        margin-right: 20px;
        .date-picker-input {
          div {
            cursor: pointer;
            span {
              font-size: 15px;
              color: gray;
            }
          }
        }
      }
    }
    .selection-terms {
      width: 250px;
      height: 61px;
      border-bottom: 1px solid #e1e1e1;
    }
    .filter-nps-social-media, .filter-terms-social-media {
      height: 61px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-bottom: 1px solid #e1e1e1;
      .filter-nps {
        width: 225px;
        height: 30px;
        .vs__dropdown-toggle {
          cursor: pointer;
          background: #00000005;
          border: none;
          .vs__selected-options {
            .vs__search {
              cursor: pointer;
            }
            .vs__search::placeholder {
              color: gray;
              font-size: 15px;
            }
            .vs__selected {
              margin: 0;
              color: gray !important;
              font-size: 15px;
            }
          }
          .vs__clear {
            display: none;
          }
        }
      }
      .search-term-social-media {
        height: 30px;
        display: flex;
        .input-term {
          border: none;
          background: #00000004;
          font-family: "Open Sans", Helvetica, Arial, sans-serif;
          font-size: 15px;
          color: gray;
          padding-left: 5px;
        }
        .input-term::placeholder {
          color: gray;
        }
        .add-term {
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          background: #00000004;
          .icon-social-media-search {
            color: var(--accent-color);
          }
        }
      }
    }
  }
  .edit-media {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .btn-social-media-edit, .btn-theme {
      border-radius: 5px;
      width: 60px;
      height: 60px;
      border: 1px solid #dbdbdb;
      .icon-social-media-edit {
        color: var(--accent-color);
        font-size: 35px;
      }
    }
    .waiting-terms {
      position: relative;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .spinner {
        width: 20px !important;
        height: 20px !important;
      }
      .waiting-terms-label {
        min-width: 100px;
        color: var(--accent-color);
        font-size: 11px;
        position: absolute;
        bottom: -15px;
        right: -40px;
      }
    }
  }
}
</style>
