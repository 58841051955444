<template>
  <div class="row center-lg center-md center-sm">
    <app-accordion
      class="col-lg-11 col-md-11 col-sm-11 accordion-section"
      :title="$t('survey.settings.start_msg')"
      :showOnStartup="false"
    >
      <div class="col-lg-12 col-md-12 col-sm-12 messages-accordion">
        <div class="start-tog">
          <app-toggle-input v-model="survey.startMessage.isEnabled" v-on:change.native="updateSurvey()"></app-toggle-input>
          <app-heading level="h6">{{$t('survey.toggle_use_start_message')}}</app-heading>
        </div>
        <div v-if="survey.startMessage.isEnabled">
          <div class="start-tog">
            <app-toggle-input v-model="survey.startMessage.logo.isHidden" v-on:change.native="updateSurvey()"></app-toggle-input>
            <app-heading level="h6">{{ $t('survey.hide_logo') }}</app-heading>
          </div>
          <div class="start-tog">
            <app-toggle-input v-model="survey.startMessage.title.hiddenTitle" v-on:change.native="updateSurvey()"></app-toggle-input>
            <app-heading level="h6">{{ $t('survey.hide_title') }}</app-heading>
          </div>
          <div class="field-instruction">
            <app-heading level="h6" style="margin-left: 0px !important; margin-top: 20px;">{{$t('survey.start_button_text')}}</app-heading>
            <app-form-input
              class="messageLabel"
              v-model="survey.nextLabel"
              border-bottom
              v-on:change.native="updateSurvey()"
            ></app-form-input>
          </div>
        </div>
      </div>
    </app-accordion>
    <app-accordion
      class="col-lg-11 col-md-11 col-sm-11 accordion-section"
      :title="$t('survey.settings.end_msg')"
      :showOnStartup="false"
    >
      <div class="row mt15 mb20">
        <div class="end-toggle row">
          <app-toggle-input v-model="endMessageRedirect" @change.native="endMessageToggleRedirect(endMessageRedirect)"/>
          <app-heading level="h6" class="tog-text">{{ $t('redirect.redirect') }}</app-heading>
        </div>
        <div v-if="endMessageRedirect" class="row end-redirect">
          <app-heading level="h6" class="col-lg-10 col-md-10 col-sm-10">{{ $t('redirect.time_Redirect') }}:</app-heading>
          <input
            min="1"
            id="redirectSeconds"
            type="number"
            name="redirectSeconds"
            v-model="currentEndMessage.redirectSec"
            class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
          />
        </div>
        <!-- REDIRECT -->
        <div v-if="endMessageRedirect" class="end-url row mt20">
          <app-heading class="col-lg-12 col-md-12 col-sm-12" level="h6">{{ $t('redirect.url_redirection') }}:</app-heading>
          <app-form-input
            class="col-lg-12 col-md-12 col-sm-12"
            v-model="currentEndMessage.redirectUrl"
            border-bottom
            style="padding: 5px 0px 0px 0px !important;"
            placeholder="https://binds.co"
            @keyup.native="validadeRedirectUrl(currentEndMessage.redirectUrl, 'endMessage')"
          ></app-form-input>
          <div class="error-url-redirect" v-if="errorRedirectUrl"><app-heading level="h7">{{ $t('redirect.erro_redirect') }}</app-heading></div>
        </div>
        <!-- END REDIRECT -->
        <div class="end-toggle row mt20" v-if="currentEndMessage">
          <app-toggle-input v-model="endMessageDetails" @change.native="updateEndDetails()"/>
          <app-heading level="h6" class="tog-text">{{ $t('survey.details') }}</app-heading>
        </div>
        <div class="row end-url" v-if="endMessageDetails && currentEndMessage && currentEndMessage.details !== undefined">
          <app-form-input
            class="col-lg-12 col-md-12 col-sm-12"
            v-model="currentEndMessage.details.value"
            v-on:change.native="updateCurrentEndMessage()"
            style="padding: 5px 0px 0px 0px !important;"
            border-bottom
          ></app-form-input>
        </div>
        <div class="end-toggle row mt20" v-if="endMessageDetails">
          <app-toggle-input v-model="useLinkEndMessage" @change.native="toggleEndLink()"/>
          <app-heading level="h6" class="tog-text">{{$t('survey.embedLink')}}</app-heading>
        </div>
        <div class="row label-link mt20" v-if="endMessageDetails && useLinkEndMessage && currentEndMessage.details !== undefined && currentEndMessage.details.link !== undefined">
          <div class="col-lg-12 col-md-12 col-sm-12" style="padding: 5px 0px 0px 0px !important;">
            <app-heading level="h6">{{$t('survey.linkLabel')}}:</app-heading>
            <app-form-input
              v-model="currentEndMessage.details.link.label"
              border-bottom
              @change.native="updateCurrentEndMessage()"
            />
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mt10" style="padding: 5px 0px 0px 0px !important;">
            <app-heading level="h6">{{$t('survey.linkUrl')}}:</app-heading>
            <app-form-input
              v-model="currentEndMessage.details.link.value"
              border-bottom
              @change.native="updateCurrentEndMessage()"
            />
          </div>
        </div>
      </div>
    </app-accordion>
    <app-accordion
      class="col-lg-11 col-md-11 col-sm-11 accordion-section"
      :title="$t('survey.settings.answered_msg')"
      :showOnStartup="false"
    >
      <div class="row mt20 mb20">
        <div class="end-toggle row">
          <app-toggle-input v-model="useAnsweredDetails" @change.native="showAnsweredDetails()"/>
          <app-heading level="h6" class="tog-text">{{ $t('survey.details') }}</app-heading>
        </div>
        <div class="end-url row" v-if="useAnsweredDetails">
          <app-form-input
            class="col-lg-12 col-md-12 col-sm-12"
            v-model="answeredMessageDetails.value"
            :placeholder="survey.defaultDetails.value"
            @change.native="updateAnsweredDetails()"
            style="padding: 5px 0px 0px 0px !important;"
            border-bottom
          />
        </div>
        <div class="end-toggle row mt20" v-if="useAnsweredDetails">
          <app-toggle-input v-model="useLinkAnswered" @change.native="toggleAnsweredLink()"/>
          <app-heading level="h6" class="tog-text">{{ $t('survey.embedLink') }}</app-heading>
        </div>
        <div class="row label-link mt20" v-if="useAnsweredDetails && useLinkAnswered && answeredMessageDetails.link">
          <div class="col-lg-12 col-md-12 col-sm-12" style="padding: 5px 0px 0px 0px !important;">
            <app-heading level="h6">{{$t('survey.linkLabel')}}:</app-heading>
            <app-form-input
              v-model="answeredMessageDetails.link.label"
              border-bottom
              @change.native="updateAnsweredDetails()"
            />
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mt10" style="padding: 5px 0px 0px 0px !important;">
            <app-heading level="h6">{{$t('survey.linkUrl')}}:</app-heading>
            <app-form-input
              v-model="answeredMessageDetails.link.value"
              border-bottom
              @change.native="updateAnsweredDetails()"
            />
          </div>
        </div>
      </div>
      <div class="row mt15 mb20" v-if="useAnsweredDetails">
        <div class="end-toggle row">
          <app-toggle-input v-model="answeredRedirect" @change.native="updateAnsweredRedirect()"/>
          <app-heading level="h6" class="tog-text">{{ $t('survey.redirect') }}</app-heading>
        </div>
        <div v-if="answeredRedirect" class="row end-redirect">
          <app-heading level="h6" class="col-lg-10 col-md-10 col-sm-10">{{ $t('redirect.time_Redirect') }}:</app-heading>
          <input
            min="1"
            type="number"
            id="answeredRedirectSeconds"
            name="answeredRedirectSeconds"
            @input="updateAnsweredDetails()"
            v-model="answeredMessageDetails.redirectSec"
            class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
          />
        </div>
        <div v-if="answeredRedirect" class="end-url row mt20">
          <app-heading class="col-lg-12 col-md-12 col-sm-12" level="h6">{{ $t('redirect.url_redirection') }}:</app-heading>
          <app-form-input
            class="col-lg-12 col-md-12 col-sm-12"
            v-model="answeredMessageDetails.redirectUrl"
            border-bottom
            style="padding: 5px 0px 0px 0px !important;"
            placeholder="https://binds.co"
            @keyup.native="validadeRedirectUrl(answeredMessageDetails.redirectUrl, 'answeredMessage')"
          ></app-form-input>
          <div class="error-url-redirect" v-if="errorRedirectUrl"><app-heading level="h7">{{ $t('redirect.erro_redirect') }}</app-heading></div>
        </div>
      </div>
    </app-accordion>
    <theme-style>
      .end-toggle > div > input {
        color: {{user.account.theme.colors.accent || '#ff607d'}} !important;
      }
    </theme-style>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { userService } from '@/_services'
import { responseOptions } from '@/_helpers'
export default {
  name: 'Messages',
  components: {
    'app-accordion': () => import('@binds-tech/binds-design-system/src/components/Accordion/Accordion'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput')
  },
  data () {
    return {
      endMessageRedirect: false,
      currentEndMessage: '',
      endMessageIndex: '',
      answeredRedirect: false,
      endMessageDetails: false,
      useLinkAnswered: false,
      useAnsweredDetails: false,
      useLinkEndMessage: false,
      errorRedirectUrl: false,
      answeredMessageDetails: {
        link: {
          label: '',
          value: ''
        },
        value: '',
        redirectUrl: '',
        redirectSec: 5
      },
      metadataGroup: [],
      displayRules: [
        { label: this.$i18n.t('survey.all_rule'), value: 'showIfAll' },
        { label: this.$i18n.t('survey.any_rule'), value: 'showIfAny' }
      ],
      operators: [
        { label: this.$i18n.t('survey.is_op'), value: '$eq' },
        { label: this.$i18n.t('survey.is_not_op'), value: '$ne' }
      ]
    }
  },
  async mounted () {
    const survey = this.$store.getters['survey/getSurvey']
    this.endMessageIndex = survey.selectedEndMessageIndex || 0
    this.currentEndMessage = survey.endMessages[this.endMessageIndex]
    if (this.currentEndMessage && this.currentEndMessage.details !== undefined) {
      this.endMessageDetails = true
      if (this.currentEndMessage.details.link !== undefined) {
        this.useLinkEndMessage = true
      }
    }

    const user = await userService.getMe()
    if (user.account.fields.length > 0) {
      const data = user.account.fields
      data.map(item => this.metadataGroup.push(item))
    }
  },
  created () {
    const survey = this.$store.getters['survey/getSurvey']
    this.$root.$on('end-message-updated', () => {
      this.showingEndMessage = true
      this.showingStartOrAnsweredMessage = false
      this.endMessageDetails = false
      this.useLinkEndMessage = false

      const survey = this.$store.getters['survey/getSurvey']
      this.endMessageIndex = survey.selectedEndMessageIndex
      this.currentEndMessage = survey.endMessages[this.endMessageIndex]

      if (this.currentEndMessage.details !== undefined) {
        this.endMessageDetails = true
        if (this.currentEndMessage.details.link !== undefined) {
          this.useLinkEndMessage = true
        }
      }

      if (this.currentEndMessage && this.currentEndMessage.redirectUrl !== '') {
        this.endMessageRedirect = true
      } else {
        this.endMessageRedirect = false
      }
      this.$forceUpdate()

      // Initializing metadata conditions for current endmessage
      this.thereIsMetadataForEnd = false
      this.metadataConditionForEnd = ''
      this.conditionFieldsForEnd = false
      this.selectedMetadataForEnd = this.metadataGroup[0]
      this.selectedOperatorForEnd = this.operators[0]

      if (this.currentEndMessage.ifMetadata.length > 0) {
        if (this.currentEndMessage.ifMetadata.value !== '') {
          this.thereIsMetadataForEnd = true
          this.metadataConditionForEnd = this.currentEndMessage.ifMetadata[0].value

          const op = this.operators.find(item => item.value === this.currentEndMessage.ifMetadata[0].operator)
          if (op) {
            this.selectedOperatorForEnd = op
          }

          const metadata = this.metadataGroup.find(item => item.label === this.currentEndMessage.ifMetadata[0].label)
          if (metadata) {
            this.selectedMetadataForEnd = metadata
          }
        }
      }
      // Initializing metadata conditions for current endmessage - END
      // Initializing response conditions for current endmessage - START
      this.addingConditionForEnd = false
      this.newConditionForEnd = {}
      this.updatingConditionForEnd = false
      this.createQuestionListConditionForEnd()
      this.mountResponseConditionsListForEnd()
      // Initializing response conditions for currend endmessage - END
    })
    this.$root.$on('showing-message', () => {
      this.showingStartOrAnsweredMessage = true
      this.showingEndMessage = false
      this.$forceUpdate()
    })

    const linkValue = survey.answeredMessageDetails?.link?.value.length
    const linkLabelValue = survey.answeredMessageDetails?.link?.label.length
    const redirectValue = survey.answeredMessageDetails?.redirectUrl?.length
    const messageValue = survey.answeredMessageDetails?.value?.length

    const showDetails = linkValue || redirectValue || messageValue

    if (showDetails) {
      this.answeredMessageDetails = survey.answeredMessageDetails
      this.useAnsweredDetails = true
    }

    if (linkLabelValue || linkValue) { this.useLinkAnswered = true }

    if (redirectValue) { this.answeredRedirect = true }
  },
  computed: {
    survey () { return this.$store.getters['survey/getSurvey'] },
    ...mapGetters({
      user: 'account/getUser'
    }),
    getTotalOfConditions () {
      let where = 'question'
      if (this.showingEndMessage) {
        where = 'currentEndMessage'
      }
      const metadata = this[where].ifMetadata && this[where].ifMetadata.length ? this[where].ifMetadata.length : 0
      const showIfAll = this[where].showIfAll && this[where].showIfAll.length ? this[where].showIfAll.length : 0
      const showIfAny = this[where].showIfAny && this[where].showIfAny.length ? this[where].showIfAny.length : 0
      return metadata + showIfAll + showIfAny
    }
  },
  methods: {
    createQuestionListConditionForEnd () {
      this.questionsListForConditionForEnd = []
      const questions = [...new Set(this.questions)]
      const forbiddenTypes = ['matrix', 'text', 'referral']
      questions.map(q => {
        const question = Object.assign({}, q)
        const isForbidden = forbiddenTypes.includes(question.type)
        const alreadyHave = this.questionsListForConditionForEnd.includes(question)

        if (!isForbidden && !alreadyHave) {
          const questionOptions = [...new Set(question.options)]
          const options = [...new Set(responseOptions.getOptions(question, false, true))]
          if (options.length > 0 && question.type === 'enum' && ['multipleOther', 'singleOther'].indexOf(question.ui) > -1) {
            if (options[options.length - 1].label !== question.otherLabel) {
              options.push({ label: question.otherLabel })
            }
          }
          question.options = options.length > 0 ? options : questionOptions
          this.questionsListForConditionForEnd.push(question)
        }
      })

      // New condition starts with this default values.
      if (this.questionsListForConditionForEnd.length > 0) {
        this.newConditionForEnd = {
          question: this.questionsListForConditionForEnd[0],
          operator: this.operators[0],
          value: this.questionsListForConditionForEnd[0].options[0]
        }
      }
    },
    updateSurvey () {
      this.$store.commit('survey/survey', this.survey)
    },
    updateEndDetails () {
      if (this.endMessageDetails) {
        // Verifica se a mensagem não tem detalhes dentro de si:
        if (this.currentEndMessage.details === undefined) {
          const survey = this.$store.getters['survey/getSurvey']
          const details = { value: survey.defaultDetails.value }
          this.currentEndMessage.details = details
        }
      } else {
        this.useLinkEndMessage = false
        delete this.currentEndMessage.details
      }
      this.updateCurrentEndMessage()
    },
    updateCurrentEndMessage () {
      this.survey.endMessages[this.endMessageIndex] = this.currentEndMessage
      this.$store.commit('survey/survey', this.survey)
      this.$forceUpdate()
      this.$root.$emit('details-updated')
    },
    toggleEndLink () {
      if (this.useLinkEndMessage) {
        const survey = this.$store.getters['survey/getSurvey']
        this.currentEndMessage.details.link = {
          label: survey.defaultDetails.link.label,
          value: survey.defaultDetails.link.value
        }
      } else {
        delete this.currentEndMessage.details.link
      }
      this.updateCurrentEndMessage()
    },
    endMessageToggleRedirect (e) {
      if (!e) {
        this.currentEndMessage.redirectUrl = ''
      }
    },
    showAnsweredDetails () {
      if (this.useAnsweredDetails) {
        // Verifica se a mensagem não tem detalhes dentro de si:\
        const survey = this.$store.getters['survey/getSurvey']
        if (!this.answeredMessageDetails.value) {
          this.answeredMessageDetails = {
            link: {
              label: '',
              value: ''
            },
            value: '',
            redirectSec: survey.answeredMessageDetails.redirectSec,
            redirectUrl: survey.answeredMessageDetails.redirectUrl
          }
        }
      } else {
        this.useLinkAnswered = false
        this.answeredRedirect = false
        this.answeredMessageDetails = {
          link: {
            label: '',
            value: ''
          },
          value: '',
          redirectUrl: '',
          redirectSec: 5
        }
      }
      this.updateAnsweredDetails()
    },
    updateAnsweredDetails () {
      this.$store.commit('survey/survey', { answeredMessageDetails: this.answeredMessageDetails })
      this.$forceUpdate()
    },
    updateAnsweredRedirect () {
      if (!this.answeredRedirect) {
        this.answeredMessageDetails.redirectSec = 5
        this.answeredMessageDetails.redirectUrl = ''
        this.errorRedirectUrl = false
      }
    },
    validadeRedirectUrl (item, where) {
      if (item.length > 0) {
        if (item.indexOf('http://') === 0 || item.indexOf('https://') === 0) {
          this.errorRedirectUrl = false
          this.$store.commit('questionSettings/enableButtonSave', true)
        } else {
          this.errorRedirectUrl = true
          this.$store.commit('questionSettings/enableButtonSave', false)
        }
      } else {
        this.errorRedirectUrl = false
        this.$store.commit('questionSettings/enableButtonSave', true)
      }
      if (where === 'answeredMessage') {
        this.$store.commit('survey/survey', { answeredMessageDetails: this.answeredMessageDetails })
      } else {
        this.updateCurrentEndMessage()
      }
      this.$forceUpdate()
    },
    toggleAnsweredLink () {
      if (this.useLinkAnswered) {
        const survey = this.$store.getters['survey/getSurvey']
        this.answeredMessageDetails.link = {
          label: survey.defaultDetails.link.label,
          value: survey.defaultDetails.link.value
        }
      } else {
        delete this.answeredMessageDetails.link
      }
      this.updateAnsweredDetails()
    },
    // funções sobre condições QUE NÃO FICARÃO NESTE ARQUIVO - START
    changeOperator (isEndMessage) {
      if (isEndMessage) {
        if (!this.thereIsMetadataForEnd) {
          const condition = {
            label: this.selectedMetadataForEnd.key,
            operator: this.selectedOperatorForEnd.value,
            value: this.metadataConditionForEnd
          }
          this.currentEndMessage.ifMetadata.push(condition)
        } else {
          this.currentEndMessage.ifMetadata[0].operator = this.selectedOperatorForEnd.value
        }
        this.updateCurrentEndMessage()
      } else {
        // Caso eu esteja adicionando uma condição a uma question que tinha nenhuma ainda.
        if (!this.thereIsMetadata) {
          this.question.ifMetadata = [{
            label: this.selectedMetadada.key,
            operator: this.selectedOperator.value,
            value: this.metadataCondition
          }]
        } else {
          this.question.ifMetadata[0].operator = this.selectedOperator.value
        }
        // Para garantir que só dê update na question quando o value da condition já estiver preenchido.
        this.updateCurrentQuestion(null, true)
      }
    },
    changeMetadata (isEndMessage) {
      // Se a condição for para uma endMessage
      if (isEndMessage) {
        if (!this.thereIsMetadataForEnd) {
          const condition = {
            label: this.selectedMetadataForEnd.key,
            operator: this.selectedOperatorForEnd.value,
            value: this.metadataConditionForEnd
          }
          this.currentEndMessage.ifMetadata[0] = condition
        } else {
          this.currentEndMessage.ifMetadata[0].label = this.selectedMetadataForEnd.key
        }
        this.updateCurrentEndMessage()
      } else {
        // Caso eu esteja adicionando uma condição a uma question que tinha nenhuma ainda.
        if (!this.thereIsMetadata) {
          const condition = {
            label: this.selectedMetadataForEnd.key,
            operator: this.selectedOperatorForEnd.value,
            value: this.metadataConditionForEnd
          }
          this.currentEndMessage.ifMetadata.push(condition)
        } else {
          this.question.ifMetadata[0].label = this.selectedMetadada.key
        }
        // Para garantir que só dê update na question quando o value da condition já estiver preenchido.
        this.updateCurrentQuestion(null, true)
      }
    },
    changeConditionValue (isEndMessage) {
      if (isEndMessage) {
        if (!this.thereIsMetadataForEnd) {
          this.currentEndMessage.ifMetadata = [{
            label: this.selectedMetadataForEnd.key,
            operator: this.selectedOperatorForEnd.value,
            value: this.metadataConditionForEnd
          }]
        } else {
          this.currentEndMessage.ifMetadata[0].value = this.metadataConditionForEnd
        }
        if (this.metadataConditionForEnd) {
          this.updateCurrentEndMessage()
        }
      } else {
        // Caso eu esteja adicionando uma condição a uma question que tinha nenhuma ainda.
        if (!this.thereIsMetadata) {
          this.question.ifMetadata = [{
            label: this.selectedMetadada.key,
            operator: this.selectedOperator.value,
            value: this.metadataCondition
          }]
        } else {
          this.question.ifMetadata[0].value = this.metadataCondition
        }
        if (this.metadataCondition) {
          this.updateCurrentQuestion()
        }
      }
    },
    clearConditionForMetadata (isEndMessage) {
      if (isEndMessage) {
        this.currentEndMessage.ifMetadata = []
        this.thereIsMetadataForEnd = false
        this.metadataConditionForEnd = ''
        this.conditionFieldsForEnd = false
        this.selectedMetadataForEnd = ''
        this.selectedOperatorForEnd = ''
        this.updateCurrentEndMessage()
      } else {
        this.question.ifMetadata = []
        this.updateCurrentQuestion()
        this.showAddConditionFields = false
        this.setConditionsInitialValues()
      }
    },
    addConditionForMetadata (isEndMessage) {
      if (isEndMessage) {
        this.conditionFieldsForEnd = true
      } else {
        this.showAddConditionFields = true
      }
    },
    mountResponseConditionsListForEnd () {
      this.responseConditionsListForEnd = []
      this.selectedDisplayRuleForEnd = this.displayRules[1]
      let conditionsToMap

      if (this.currentEndMessage.showIfAll.length > 0) {
        this.thereIsResponseConditionForEnd = true
        this.selectedDisplayRuleForEnd = this.displayRules[0]
        conditionsToMap = this.currentEndMessage.showIfAll
      }

      if (this.currentEndMessage.showIfAny.length > 0) {
        this.thereIsResponseConditionForEnd = true
        this.selectedDisplayRuleForEnd = this.displayRules[1]
        conditionsToMap = this.currentEndMessage.showIfAny
      }

      if (conditionsToMap) {
        conditionsToMap.map(condition => {
          const conditionToPush = {}

          // Pega a QUESTION da condição.
          const question = this.questionsListForConditionForEnd.find(item => item.id === condition.question || item._id === condition.question)
          if (question) {
            conditionToPush.question = question
          }

          // Pega o OPERATOR da condição.
          const operator = this.operators.find(item => item.value === condition.operator)
          if (operator) {
            conditionToPush.operator = operator
          }

          // Pega o valor (a opção de resposta) da condição.
          const value = question.options.find(item => item.value === condition.value || item.rating === condition.value || item.label === condition.value)
          if (value) {
            conditionToPush.value = value
          }
          this.responseConditionsListForEnd.push(conditionToPush)
        })
      }
    },
    updateConditionForResponse (condition, index, isEndMessage) {
      const conditionToSave = {
        question: condition.question.id,
        operator: condition.operator.value,
        value: condition.value.value || condition.value.rating || condition.value.label
      }

      if (isEndMessage) {
        if (this.selectedDisplayRuleForEnd.value === 'showIfAll') {
          this.currentEndMessage.showIfAll[index] = conditionToSave
        }

        if (this.selectedDisplayRuleForEnd.value === 'showIfAny') {
          this.currentEndMessage.showIfAny[index] = conditionToSave
        }

        this.updateCurrentEndMessage()
      } else {
        if (this.selectedDisplayRule.value === 'showIfAll') {
          this.question.showIfAll[index] = conditionToSave
        }

        if (this.selectedDisplayRule.value === 'showIfAny') {
          this.question.showIfAny[index] = conditionToSave
        }

        this.updateCurrentQuestion()
      }
    },
    changeDisplayRule (isEndMessage) {
      if (isEndMessage) {
        if (this.selectedDisplayRuleForEnd.value === 'showIfAll') {
          if (this.currentEndMessage.showIfAny.length > 0) {
            this.currentEndMessage.showIfAll = this.currentEndMessage.showIfAny
            this.currentEndMessage.showIfAny = []
            this.updateCurrentEndMessage()
          }
        }
        if (this.selectedDisplayRuleForEnd.value === 'showIfAny') {
          if (this.currentEndMessage.showIfAll.length > 0) {
            this.currentEndMessage.showIfAny = this.currentEndMessage.showIfAll
            this.currentEndMessage.showIfAll = []
            this.updateCurrentEndMessage()
          }
        }
      } else {
        if (this.selectedDisplayRule.value === 'showIfAll') {
          if (this.question.showIfAny.length > 0) {
            this.question.showIfAll = this.question.showIfAny
            this.question.showIfAny = []
            this.updateCurrentQuestion()
          }
        }
        if (this.selectedDisplayRule.value === 'showIfAny') {
          if (this.question.showIfAll.length > 0) {
            this.question.showIfAny = this.question.showIfAll
            this.question.showIfAll = []
            this.updateCurrentQuestion()
          }
        }
      }
    },
    addNewConditionForResponse (isEndMessage) {
      if (isEndMessage) {
        this.updatingConditionForEnd = false
        const conditionToPush = {
          question: this.newConditionForEnd.question.id,
          operator: this.newConditionForEnd.operator.value,
          value: this.newConditionForEnd.value.value || this.newConditionForEnd.value.rating || this.newConditionForEnd.value.label
        }

        if (this.selectedDisplayRuleForEnd.value === 'showIfAll') {
          this.currentEndMessage.showIfAll.push(conditionToPush)
        }
        if (this.selectedDisplayRuleForEnd.value === 'showIfAny') {
          this.currentEndMessage.showIfAny.push(conditionToPush)
        }

        this.updateCurrentEndMessage()
        this.mountResponseConditionsListForEnd()
        this.addingConditionForEnd = false
      } else {
        this.updatingCondition = false

        const conditionToPush = {
          question: this.newCondition.question.id,
          operator: this.newCondition.operator.value,
          value: this.newCondition.value.value || this.newCondition.value.rating || this.newCondition.value.label
        }

        if (this.selectedDisplayRule.value === 'showIfAll') {
          this.question.showIfAll.push(conditionToPush)
        }
        if (this.selectedDisplayRule.value === 'showIfAny') {
          this.question.showIfAny.push(conditionToPush)
        }

        this.updateCurrentQuestion()
        this.mountResponseConditionsList()
        this.addingCondition = false
      }
    },
    deleteConditionForResponse (index, isEndMessage) {
      if (isEndMessage) {
        if (this.responseConditionsListForEnd.length > 0) {
          this.responseConditionsListForEnd.splice(index, 1)
        }
        if (this.selectedDisplayRuleForEnd.value === 'showIfAll') {
          this.currentEndMessage.showIfAll.splice(index, 1)
        }
        if (this.selectedDisplayRuleForEnd.value === 'showIfAny') {
          this.currentEndMessage.showIfAny.splice(index, 1)
        }
        this.updateCurrentEndMessage()
      } else {
        if (this.responseConditionsList.length > 0) {
          this.responseConditionsList.splice(index, 1)
        }
        if (this.selectedDisplayRule.value === 'showIfAll') {
          this.question.showIfAll.splice(index, 1)
        }
        if (this.selectedDisplayRule.value === 'showIfAny') {
          this.question.showIfAny.splice(index, 1)
        }
        this.updateCurrentQuestion()
      }
    },
    selectQuestionForCondition (index) {
      if (!this.showingEndMessage) {
        if (!this.updatingCondition) {
          this.newCondition = {
            question: this.questionsListForCondition[index],
            operator: this.operators[0],
            value: this.questionsListForCondition[index].options[0]
          }
        } else {
          const cUpdated = {
            question: this.questionsListForCondition[index].id,
            operator: this.operators[0],
            value: this.questionsListForCondition[index].options[0]
          }

          this.responseConditionsList[this.conditionToUpdateIndex] = { ...cUpdated }
          cUpdated.question = this.questionsListForCondition[index].id
          cUpdated.operator = this.operators[0].value
          cUpdated.value = this.questionsListForCondition[index].options[0].value || this.questionsListForCondition[index].options[0].rating || this.questionsListForCondition[index].options[0].label

          if (this.selectedDisplayRule.value === 'showIfAll') {
            this.question.showIfAll[this.conditionToUpdateIndex] = cUpdated
          }
          if (this.selectedDisplayRule.value === 'showIfAny') {
            this.question.showIfAny[this.conditionToUpdateIndex] = cUpdated
          }

          this.updateCurrentQuestion()
        }
      } else {
        if (!this.updatingConditionForEnd) {
          this.newConditionForEnd = {
            question: this.questionsListForConditionForEnd[index],
            operator: this.operators[0],
            value: this.questionsListForConditionForEnd[index].options[0]
          }
        } else {
          const cUpdated = {
            question: this.questionsListForConditionForEnd[index],
            operator: this.operators[0],
            value: this.questionsListForConditionForEnd[index].options[0]
          }

          this.responseConditionsListForEnd[this.conditionToUpdateIndexForEnd] = { ...cUpdated }
          cUpdated.question = this.questionsListForConditionForEnd[index].id
          cUpdated.operator = this.operators[0].value
          cUpdated.value = this.questionsListForConditionForEnd[index].options[0].value || this.questionsListForConditionForEnd[index].options[0].rating || this.questionsListForConditionForEnd[index].options[0].label

          if (this.selectedDisplayRuleForEnd.value === 'showIfAll') {
            this.currentEndMessage.showIfAll[this.conditionToUpdateIndexForEnd] = cUpdated
          }
          if (this.selectedDisplayRuleForEnd.value === 'showIfAny') {
            this.currentEndMessage.showIfAny[this.conditionToUpdateIndexForEnd] = cUpdated
          }

          this.updateCurrentEndMessage()
        }
      }
      this.showModal = false
    }
    // funções sobre condições QUE NÃO FICARÃO NESTE ARQUIVO - END
  }
}
</script>
<style lang="scss">
.messages-accordion {
  margin-top: 20px !important;
  padding: 0px 0px 10px 0px !important;
  & h6 {
    font-size: 14px !important;
    margin-left: 50px !important;
    padding-top: 3px !important;
  }
  & h4 {
    margin-left: 0px !important;
    margin-top: 5px !important;
    margin-bottom: 25px !important;
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .start-tog {
    margin-top: 10px;
    display: flex;
    // flex-direction: column;
  }
}
.field-instruction {
  & h6 {
    margin-left: 0px !important;
    font-weight: 500;
  }
  .text-field__input {
    margin-top: 5px;
    & input {
      font-size: 13.5px !important;
    }
  }
}
.end-toggle {
  display: flex;
  margin-top: 15px;
  & .tog-text {
    max-width: 220px;
    font-size: 14px !important;
    margin-left: 45px !important;
    line-height: 20px !important;
  }
  & i {
    font-size: 18px;
    margin-left: 5px;
    margin-top: 1px;
    color: #8E8E8E;
    cursor: pointer;
  }
}
.end-redirect {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  & h6 {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #616161 !important;
    white-space: nowrap;
  }
  & input[type=number] {
    height: 25px !important;
    font-size: 14px;
    padding-left: 10px;
    width: 32%;
    border: 1px solid #cacaca;
    border-radius: 4px;
    text-align: center;
  }
  input[type=number]::-webkit-inner-spin-button {
    opacity: 1;
    margin-right: -10px;
  }
}
.end-url {
  h6 {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #616161 !important;
  }
  input {
    font-size: 12px !important;
    font-weight: normal !important;
    color: rgba(97, 97, 97, 0.4) !important;
  }
}
.label-link {
  h6 {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #616161 !important;
  }
  input {
    font-size: 12px !important;
    font-weight: normal !important;
    color: rgba(97, 97, 97, 0.4) !important;
  }
}
</style>
