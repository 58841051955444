<template>
  <div class="container-send-type-individual">
    <div class="section-send-type-individual">
      <p class="label-send-type-individual">{{ $t('dashboard.send') }}:</p>
      <p class="label-send-type-individual">{{ sendInfo.createdAt }}</p>
    </div>
    <div class="section-send-type-individual">
      <p class="label-send-type-individual">{{ $t('dashboard.answer') }}:</p>
      <p class="label-send-type-individual">{{ sendInfo.respondedAt }}</p>
    </div>
    <div class="section-send-type-individual">
      <p class="label-send-type-individual">{{ $t('dashboard.send_type') }}:</p>
      <p class="label-send-type-individual">{{ capitalizeFirstLetter(sendInfo.type) }}</p>
      <i v-if="sendInfo.type !== 'whatsapp'" class="material-icons icon-send-type-individual">{{ returnTypeIcon(sendInfo.type) }}</i>
      <div v-else class="icon-wpp-type">
        <i class="material-icons type-icon-wpp">{{ returnTypeIcon(sendInfo.type) }}</i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndividualSendType',
  props: ['sendInfo'],
  components: {},
  data () {
    return {

    }
  },
  computed: {
    lang () { return this.$store.getters['account/getLang'] }
  },
  methods: {
    formatDate (eventDate) {
      this.$moment.locale(this.lang)
      return this.$moment(eventDate).format('L')
    },
    returnTypeIcon (type) {
      const iconOptions = [
        { type: 'link', icon: 'link' },
        { type: 'sms', icon: 'phone' },
        { type: 'email', icon: 'mail' },
        { type: 'widget', icon: 'integration_instructions' },
        { type: 'whatsapp', icon: 'call' }
      ]
      const findIcon = iconOptions.find(e => e.type === type)
      return findIcon.icon
    },
    capitalizeFirstLetter (type) {
      return type.charAt(0).toUpperCase() + type.slice(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.container-send-type-individual {
  height: 100%;
  padding: 0.2vw 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5vw;
  .section-send-type-individual {
    background: #ffffff;
    display: flex;
    align-items: center;
    gap: 0.3vw;
    .icon-send-type-individual {
      font-size: 0.85vw;
      color: #5e5e5e;
    }
    .icon-wpp-type {
      width: 0.85vw;
      height: 0.85vw;
      border-radius: 35px;
      background: #5e5e5e;
      display: flex;
      align-items: center;
      justify-content: center;
      .type-icon-wpp {
        margin-top: 1px;
        font-size: 0.6vw;
        color: #ffffff;
      }
    }
    .label-send-type-individual {
      color: #5e5e5e;
      font-size: 0.9vw;
    }
    .label-send-type-individual:nth-child(2) {
      font-weight: 500;
    }
  }
}
</style>
