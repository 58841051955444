<template>
  <div class="container-individual-chart-health" v-if="dataChart">
    <div class="header-individual-chart-health">
      <p class="title-individual-chart-health" :style="{'font-size': from === 'individual' ? '0.7vw' : '0.8vw'}">{{ $t('health_score.title_evolution_health') }}</p>
    </div>
    <div class="content-individual-chart-health">
      <canvas :id="`chart-health-${from}-${dataChart.sendingId}-${uniqueID}`" style="width: 100%; height:100%"></canvas>
    </div>
  </div>
</template>

<script>
import { gridMaker, formatters } from '@/_helpers'
import Chart from 'chart.js'

export default {
  name: 'IndividualChartHealth',
  props: ['chartData', 'from'],
  components: {},
  data () {
    return {
      dataChart: null,
      sendingIds: []
    }
  },
  created () {
    this.dataChart = this.chartData
  },
  mounted () {
    this.formatDataChart()
  },
  computed: {
    heightDynamic () {
      let height = '100'
      if (this.from === 'individual') { height = '85' }

      return height
    },
    lang () { return this.$store.getters['account/getLang'] },
    uniqueID () { return Math.floor(10000 + Math.random() * 90000) },
    account () { return this.$store.getters['account/getAccount'] },
    endPointIcon () {
      const heartIcon = 'https://img.binds.co/7aec1821-028a-457b-b10c-e380a609a2a2/'

      const endpointIcon = new Image()
      endpointIcon.src = heartIcon
      endpointIcon.width = 19
      endpointIcon.height = 19
      return endpointIcon
    }
  },
  methods: {
    async formatDataChart () {
      if (!this.dataChart.health?.overtime) { return false }

      const labels = []
      const avgGrades = []

      const gridRange = gridMaker.checkIfPeriodIsByDay(this.$route.name, this.$route.query.q)
      let formatDate = 'LL'

      if (gridRange) { formatDate = 'LL' }

      const gradientBackground = ctx => {
        const gradient = ctx.chart.ctx.createLinearGradient(0, 0, 0, ctx.chart.height)
        gradient.addColorStop(0, '#cacaca')
        gradient.addColorStop(1, '#ffffff')
        gradient.addColorStop(0.8, '#ffffff')
        return gradient
      }

      const dataChart = [{
        data: avgGrades,
        borderColor: '#454548',
        pointBorderColor: '#454548',
        pointBackgroundColor: '#454548',
        backgroundColor: gradientBackground,
        borderWidth: 1,
        tension: 0,
        pointRadius: 2.5,
        pointStyle: context => {
          const findPoint = context.dataset.pointStyleIds.findIndex(e => e === this.dataChart.sendingId)
          if (findPoint > -1 && findPoint === context.dataIndex) {
            return this.endPointIcon
          } else {
            return 'circle'
          }
        }
      }]

      this.dataChart.health.overtime.forEach((_item, i) => {
        if (i > 0 && this.dataChart.health.overtime[i][2] !== null) {
          const avg = Math.round(this.dataChart.health.overtime[i][2] * 100) / 100
          let title = _item[3] || this.$t('dashboard.survey_deleted')
          const time = formatters.formatDateByLangAndFixTimeZone(this.dataChart.health.overtime[i][0], this.lang, formatDate)
          avgGrades.push(avg)

          if (title?.length > 30) { title = title.slice(0, 30) + '...' }

          labels.push(`${title} - ${time}`)
          this.sendingIds.push(this.dataChart.health.overtime[i][1])

          dataChart[0].pointStyleIds = dataChart[0].pointStyleIds || []
          dataChart[0].pointStyleIds.push(this.dataChart.health.overtime[i][1])
        }
      })

      const canvas = document.getElementById(`chart-health-${this.from}-${this.dataChart.sendingId}-${this.uniqueID}`)
      const ctx = canvas.getContext('2d')

      const myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: dataChart
        },
        options: {
          title: {
            display: false,
            text: this.$t('health_score.title_evolution_health'),
            fontSize: 11,
            fontColor: '#454548',
            padding: 2,
            position: 'top'
          },
          scales: {
            yAxes: [{
              tacked: true,
              ticks: {
                beginAtZero: true,
                min: 0,
                max: 100,
                fontSize: 9,
                fontColor: '#5e5e5e',
                stepSize: 50,
                callback: value => {
                  return value === 0 || value === 50 || value === 100 ? value : ''
                }
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [{
              ticks: {
                display: true,
                fontSize: 12,
                fontColor: '#5e5e5e',
                callback: function (value, index, values) {
                  const year = value.slice(-4)
                  if (index === values.length - 1 || year !== values[index + 1].slice(-4)) {
                    return year
                  }
                  return ''
                }
              },
              gridLines: {
                display: false
              }
            }]
          },
          layout: {
            padding: {
              top: 10,
              right: 10
            }
          },
          legend: {
            display: false
          },
          maintainAspectRatio: false,
          responsive: true,
          tooltips: {
            intersect: true,
            callbacks: {
              label: (tooltipItem, data) => {
                return ` ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]} - ${this.$t('health_score.see_details_in_approaches')}`
              }
            }
          },
          onHover: (event, chartElement) => {
            if (chartElement && chartElement.length) {
              event.target.style.cursor = 'pointer'
            } else {
              event.target.style.cursor = 'default'
            }
          },
          onClick: (event, chartElement) => {
            const eventPointClicked = myChart.getElementsAtEventForMode(event, 'point', { intersect: true }, true)

            if (!eventPointClicked.length) return

            const myPoint = eventPointClicked[0]

            if (chartElement.length > 0 && this.from === 'individual') {
              return this.$emit('open-detail-in-time', this.sendingIds[myPoint._index])
            }

            if (chartElement.length > 0 && this.from === 'sidebar') {
              return this.$root.$emit('open-sending-in-time', this.sendingIds[myPoint._index])
            }
          }
        },
        plugins: [{
          afterDatasetsDraw: (chart) => {
            const ctx = chart.chart.ctx
            const yScale = chart.scales['y-axis-0']

            const avg = avgGrades.reduce((a, b) => a + b, 0) / avgGrades.length

            const yPos = yScale.getPixelForValue(avg)

            ctx.save()
            ctx.beginPath()
            ctx.moveTo(chart.chartArea.left, yPos)
            ctx.lineTo(chart.chartArea.right, yPos)
            ctx.setLineDash([5, 5])
            ctx.strokeStyle = '#808080'
            ctx.stroke()
            ctx.restore()
          }
        }]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-individual-chart-health {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1vw;
  .header-individual-chart-health {
    width: 100%;
    height: 15%;
    // border-bottom: 1px solid #e1e1e1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title-individual-chart-health {
      font-size: 0.75vw;
      text-align: center;
      font-weight: 400;
      color: #808080;
    }
  }
  .content-individual-chart-health {
    width: 100%;
    height: 85%;
    overflow: hidden;
  }
}
</style>
