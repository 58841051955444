<template>
  <DefaultStructure>
        <div class="fadeInIt" id="form">
          <div>
            <span class="loginTabs activeLoginTab">{{$t('resetpass.title')}}</span>
            <p style="font-size: 1.5vw;margin: 1vw;color: #ff5790;">{{$route.query.email.replace(' ', '+') || ''}}</p>
          </div>
          <div style="margin: 5vw">
            <div class="input-field">
              <div class="linearGradient">
                <input
                  class="login-form-input-field"
                  v-model="pass1"
                  type="password"
                  id="first-password"
                  required
                />
                <label class="login-form-label-field" for="first-password">{{$t('resetpass.pass1')}}</label>
              </div>
            </div>
            <div class="input-field">
              <div class="linearGradient">
                <input
                  class="login-form-input-field"
                  v-model="pass2"
                  type="password"
                  id="second-password"
                  required
                />
                <label class="login-form-label-field" for="second-password">{{$t('resetpass.pass2')}}</label>
              </div>
            </div>
            <button id="signUpButton" @click="resetPass()">
              {{ $t("resetpass.save") }}
            </button>
            <div>
              <span class="loginActionLinks" @click="$router.push('/login')">{{$t('login.back')}}</span>
            </div>
          </div>
        </div>
  </DefaultStructure>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { userService } from '@/_services'
import DefaultStructure from '@/components/loginForms/DefaultStructure.vue'

export default {
  name: 'ConfirmResetPass',
  data () {
    return {
      pass1: '',
      pass2: '',
      isLoading: false,
      svgCurve: `<svg  width="100%" class="div" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1698 320">
  <path fill="#5133a5" fill-opacity="1" d="M0,128L60,133.3C120,139,240,149,360,181.3C480,213,600,267,720,272C840,277,960,235,1080,202.7C1200,171,1320,149,1380,138.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
</svg>`
    }
  },
  components: {
    DefaultStructure
  },
  computed: {
    ...mapState('account', ['status'])
  },
  methods: {
    ...mapActions('account', ['login']),
    async resetPass (e) {
      if (!this.pass1 || !this.pass2 || (this.pass1 !== this.pass2)) {
        this.$root.$emit('show-message', this.$t('resetpass.fill_in'))
        return false
      }
      this.$root.$emit('set-login-loading', true)
      try {
        const result = await userService.resetpass({ password: this.pass1 }, this.$route.params.token)
        if (result._id) {
          this.$root.$emit('do-login', { email: result.email, password: this.pass2 })
          return false
        } else {
          this.$root.$emit('show-message', this.$t('resetpass.code_expired'))
        }
        this.$root.$emit('set-login-loading', false)
        return false
      } catch (e) {
        this.$root.$emit('set-login-loading', false)
        console.log('Error on login request', e)
      }
    }
  }
}
</script>
