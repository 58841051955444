import { render, staticRenderFns } from "./NumUi.vue?vue&type=template&id=7fe7867e&scoped=true"
import script from "./NumUi.vue?vue&type=script&lang=js"
export * from "./NumUi.vue?vue&type=script&lang=js"
import style0 from "./NumUi.vue?vue&type=style&index=0&id=7fe7867e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fe7867e",
  null
  
)

export default component.exports