<template>
  <transition name="modal">
    <div class="mask-manual-upload">
      <div class="wrapper-manual-upload">
        <div class="modal-container" style="top: 150px !important; padding: 0px !important; width: 560px; height: 305px;">
          <div class="header-manual-upload">
            <slot name="header">
              <div class="modal-close-button close-config col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button @click="$emit('close-new-metadata')">
                  <app-textstyle size="size-l">×</app-textstyle>
                </button>
              </div>
              <app-heading level="h5" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 modal-title-upload" style="margin-left: 15px !important;">
                {{ $t('metadata.new') }}
              </app-heading>
            </slot>
          </div>
          <div class="body-manual-upload">
            <slot name="body">
              <div class="row center-lg center-md center-sm center-xs" style="margin-top: 20px;">
                <div class="col-lg-6 col-md-6 col-sm-6 upload-label-btn">
                  <app-heading level="h6">{{ $t('metadata.field') }}:</app-heading>
                  <app-form-input class="messageLabel" v-model="metadata.key" border-bottom></app-form-input>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 upload-label-btn">
                  <app-heading level="h6">{{ $t('metadata.label') }}:</app-heading>
                  <app-form-input class="messageLabel" v-model="metadata.label" border-bottom></app-form-input>
                </div>
                <div class="row">
                  <div class="col-xs rank-toggle">
                    <app-toggle-input v-model="metadata.groupable"></app-toggle-input>
                    <app-heading level="h6">{{$t('metadata.groupable')}}</app-heading>
                  </div>
                </div>
              </div>
              <app-button class="save-account btn-default-primary  col-lg-offset-8 col-lg-2 col-md-offset-8 col-md-2 col-sm-offset-8 col-sm-2" variation="primary" @click="createMetadata()">{{ $t('start.create') }}</app-button>
              <app-button class="save-account btn-outline-primary col-lg-offset-8 col-lg-2 col-md-offset-8 col-md-2 col-sm-offset-8 col-sm-2" variation="primary" outline @click="$emit('close-new-metadata')">{{ $t('tickets.cancel') }}</app-button>
            </slot>
          </div>
        </div>
      </div>
      <!-- loading effect -->
      <div class="data-loading" v-if="loading">
        <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
      </div>
    </div>
  </transition>
</template>

<script>
import { accountService, userService } from '@/_services'
export default {
  name: 'NewMetadataModal',
  components: {
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle')
  },
  data () {
    return {
      loading: false,
      account: {},
      metadata: { key: '', label: '', groupable: false }
    }
  },
  async mounted () {
    this.loading = true
    const user = await userService.getMe()
    this.account = user.account
    this.loading = false
  },
  methods: {
    async createMetadata () {
      this.loading = true
      if (this.metadata.key.length > 0 && this.metadata.label.length > 0) {
        this.account.fields.push(this.metadata)
        await accountService.updateAccount(this.account._id, this.account)
        this.$store.commit('account/setAccount', this.account)
        this.$root.$emit('close-new-metadata')
      } else {
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('seeds.fill'),
          position: 'bottomRight',
          showAlert: true
        })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.body-manual-upload {
  height: 200px;
}
.mask-manual-upload {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.17);
  display: table;
  transition: opacity 0.3s ease;
  overflow: hidden !important;
}
.wrapper-manual-upload {
  display: table-cell;
  vertical-align: middle;
  width: 880px !important;
  min-height: 300px !important;
  max-height: 450px !important;
  overflow: hidden !important;
}
.header-manual-upload h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-title-upload {
  margin: 10px 0px 0px 10px !important;
}
.rank-toggle {
  padding-left: 20px !important;
  & h6 {
    margin-left: 50px !important;
    margin-top: 1px !important;
  }
}
</style>
