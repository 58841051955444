<template>
  <div :class="{'op-yn': !tickets, 'center-yn': !tickets}">
    <app-button v-if="response === 100 || response === undefined" variation="primary" style="background: #57ad26">{{t('dashboard.y')}}</app-button>
    <app-button v-if="response === 0 || response === undefined" variation="secondary" style="background: #ea484d">{{t('dashboard.n')}}</app-button>
  </div>
</template>

<script>
import { translator } from '@/_helpers'

export default {
  name: 'appyn',
  props: ['response', 'tickets', 'question'],
  components: {
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button')
  },
  methods: {
    t (text) {
      return translator.translateThisText(this.question.language, text)
    }
  }
}
</script>
<style lang="scss">
.center-yn {
  margin: 0 auto;
}
.op-yn {
  text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  & button {
    width: 309px;
    border-radius: 24px;
    display: block;
    box-shadow: 0 5px 15px 0 rgb(215, 215, 215) !important;
  }

}
</style>
