<template>
  <div class="container-select-alert-item">
    <div class="header-select-alert-item">
      <input type="text" class="input-search-alert-item" v-model="searchAlertItem" :placeholder="$t('bvs.search')" @input="filterItems()">
      <i class="material-icons-outlined icon-search-alert-item">search</i>
    </div>
    <div class="section-select-alert-item">
      <div class="section-item-alert" v-for="item in visibleItems[keyItem]" :key="`${item._id}`">
        <div class="info-item-alert">
          <div class="detail-item-alert">
            <p class="title-item-alert">{{ $t('profile.name') }}</p>
            <p class="label-item-alert">{{ item.name }}</p>
          </div>
          <div class="detail-item-alert">
            <p class="title-item-alert" v-if="keyItem === 'users'">{{ $t('profile.email') }}</p>
            <p class="label-item-alert" v-if="keyItem === 'users'">{{ item.emailUser }}</p>
          </div>
        </div>
        <div class="info-item-alert" @click="updateItem(item, 'email')" :class="{'item-active': item.email }">
          <p class="title-item-alert">{{ $t('profile.email') }}</p>
          <input type="checkbox" class="checkbox-item-alert" v-model="item.email">
        </div>
        <div class="info-item-alert" @click="updateItem(item, 'sms')" :class="{'item-active': item.sms }">
          <p class="title-item-alert">{{ $t('resend.type.sms') }}</p>
          <input type="checkbox" class="checkbox-item-alert" v-model="item.sms">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectAlertItem',
  props: ['dataAlertItems', 'keyItem'],
  components: {},
  data () {
    return {
      searchAlertItem: '',
      visibleItems: []
    }
  },
  mounted () {
    this.visibleItems = structuredClone(this.dataAlertItems)
  },
  watch: {
    keyItem: {
      handler () {
        this.searchAlertItem = ''
        this.visibleItems[this.keyItem] = this.dataAlertItems[this.keyItem]
      }
    }
  },
  methods: {
    filterItems () {
      const text = this.searchAlertItem.toLowerCase().trim()

      this.visibleItems[this.keyItem] = this.dataAlertItems[this.keyItem].filter(item => {
        return item.name?.toLowerCase().includes(text)
      })
    },
    updateItem (item, type) {
      item[type] = !item[type]

      const findItem = this.dataAlertItems[this.keyItem].find(i => i._id === item._id)

      findItem[type] = item[type]

      const calculateTotal = (items) => items.reduce((total, { email, sms }) => total + (email ? 1 : 0) + (sms ? 1 : 0), 0)

      const totalGroups = calculateTotal(this.dataAlertItems.groups)
      const totalUsers = calculateTotal(this.dataAlertItems.users)

      this.dataAlertItems.notifications = totalGroups + totalUsers
    }
  }
}
</script>

<style lang="scss" scoped>
.container-select-alert-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  .header-select-alert-item {
    background: #ffffff;
    width: 100%;
    height: 10%;
    border-radius: 0.3vw;
    border: 1px solid #e1e1e1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    padding: 0.3vw;
    display: flex;
    align-items: center;
    .input-search-alert-item {
      flex: 1;
      height: 100%;
      border: none;
      padding-left: 0.3vw;
      font-size: 0.75vw;
      color: #454548;
      font-weight: 500;
    }
    .icon-search-alert-item {
      width: 5%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #cacaca;
    }
  }
  .section-select-alert-item {
    width: 100%;
    height: 90%;
    overflow: scroll;
    .section-item-alert {
      width: 100%;
      padding: 0.3vw;
      margin-bottom: 0.3vw;
      background: #ffffff;
      border-radius: 0.3vw;
      border: 1px solid #e1e1e1;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.3vw;
      .info-item-alert:first-child {
        height: 2vw;
        display: flex;
        flex: 3;
        justify-content: center;
        align-items: flex-start;
        border: none;
        cursor: default;
        box-shadow: none;
        .detail-item-alert {
          display: flex;
          gap: 0.3vw;
        }
      }
      .info-item-alert {
        flex: 1;
        border: 1px solid #e1e1e1;
        box-shadow: 0 3px #e1e1e1;
        border-radius: 0.2vw;
        padding: 0.2vw 0;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.3vw;
        .title-item-alert {
          font-size: 0.75vw;
          line-height: 0.8vw;
          color: #282828;
          font-weight: 500;
        }
        .label-item-alert {
          font-size: 0.75vw;
          line-height: 0.8vw;
          color: #454548;
        }
        .checkbox-item-alert {
          filter: grayscale(0.8);
        }
      }
      .item-active {
        background: #f1f1f1;
        box-shadow: none;
        transform: translateY(2px);
      }
    }
  }
}
</style>
