<template>
  <div class="container-no-data-to-show">
    <p v-if="title" class="title-no-data">{{ $t(title) }}</p>
    <p v-if="subtitle" class="subtitle-no-data">{{ $t(subtitle) }}</p>
  </div>
</template>

<script>
export default {
  name: 'NoDataToShow',
  props: ['title', 'subtitle'],
  components: {}
}
</script>

<style lang="scss">
.container-no-data-to-show {
  height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  padding-top: 30px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 72px;
  color: #454548;
  .title-no-data {
    color: var(--primary-color);
    max-width: 40%;
    text-align: center;
  }
  .subtitle-no-data {
    max-width: 40%;
    text-align: center;
    font-size: 50px;
    color: var(--secondary-color);
  }
}
</style>
