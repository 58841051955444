export const imgProps = {
  setImgMessage
}

function setImgMessage (images, where) {
  let imageObject = {}
  const position = images.findIndex(item => item.where === where)
  if (position < 0) {
    imageObject = images[0]
  } else {
    imageObject = images[position]
  }
  const result = imageObject || {}
  result.value = result.value || ''
  result.where = where
  result.success = !!result.value
  result.crop = 'free'
  result.imageShrink = '800x600 100%'
  result.imageOnly = true
  return result
}
