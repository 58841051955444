<template>
  <div class="container-resume-resend">
    <div class="content-surveys-selected">
      <p class="title-surveys-selecteds">{{ $t('resend.selected_surveys') }}</p>
      <div class="section-to-surveys-selecteds">
        <CardSurveyResume :survey="item" :indexCard="idx" v-for="(item, idx) in data" :key="`${item.survey}${idx}`" />
      </div>
    </div>
    <div class="section-confirm-resend">
      <div class="count-details-resend">
        <h4 class="total-count-resend">{{ $t('resend.details_resend') }}</h4>
        <div class="total-count-type">
          <div class="count-resend" :class="{'active-mail': emailSum > 0}" v-title="$t('resend.type.email')">
            <i class="material-icons-outlined type-icon-count">mail</i>
            <p class="label-count">{{ formatNumber.format(emailSum) }}</p>
          </div>
          <div class="count-resend" :class="{'active-sms': smsSum > 0}" v-title="$t('resend.type.sms')">
            <i class="material-icons-outlined type-icon-count">comment</i>
            <p class="label-count">{{ formatNumber.format(smsSum) }}</p>
          </div>
          <div class="count-resend" :class="{'active-whatsapp': whatsappSum > 0}" v-title="$t('resend.type.whatsapp')">
            <i class="material-icons-outlined type-icon-count whatsapp-icon">call</i>
            <p class="label-count">{{ formatNumber.format(whatsappSum) }}</p>
          </div>
        </div>
        <div class="total-approaches">
          <h5 class="title-count">{{ $t('resend.total_approaches') }}</h5>
          <p class="label-count">{{ totalCount }}</p>
        </div>
      </div>
      <div class="buttons-to-confirm">
        <div class="step-btn-dynamically" v-if="currentStep === 'surveys'">
          <button class="btn-step" :class="{'disabled': countTotal === 0 || disabeldButton}" @click="changeStep('settings')">{{ $t('resend.advance') }}</button>
          <button class="btn-scheduled" @click="$emit('cancel')">{{ $t('resend.cancel') }}</button>
        </div>
        <div class="step-btn-dynamically" v-if="currentStep === 'settings'">
          <button class="btn-step" :class="{'disabled': disabeldButton}" @click="changeStep('done')">{{ $t('resend.advance') }}</button>
          <button class="btn-scheduled" @click="changeStep('surveys')">{{ $t('resend.back') }}</button>
        </div>
        <div class="step-btn-dynamically" v-if="currentStep === 'done'">
          <ButtonAnimate
            :messages="messagesButtonOne"
            :isLoading="loadingFake"
            @has-click="fakeLoading()"
            :disabled="disabeldButton"
          />
          <button class="btn-scheduled" @click="changeStep('settings')">{{ $t('resend.back') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardSurveyResume from './CardSurveyResume.vue'
import ButtonAnimate from '../inputs/ButtonAnimate.vue'
import { gsap } from 'gsap'

export default {
  name: 'ResumeResend',
  props: ['data', 'metadatas'],
  components: {
    CardSurveyResume,
    ButtonAnimate
  },
  data () {
    return {
      currentStep: 'surveys',
      loadingFake: false,
      disabeldButton: false,
      loadingSend: false,
      countTotal: 0,
      enabledTypes: [],
      messagesButtonOne: {
        initial: this.$t('resend.confirm'),
        loading: this.$t('resend.getting_informations')
      }
    }
  },
  mounted () {
    this.sumTotal()
  },
  computed: {
    totalCount () { return this.countTotal.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') },
    formatNumber () { return Intl.NumberFormat('pt-BR', { maximumSignificantDigits: 10 }) },
    emailSum () {
      return this.data.reduce((sum, item) => {
        if (item.deliveryTypes.find((type) => type.key === 'email' && type.enabled)) {
          return sum + item.deliveryTypes.find((type) => type.key === 'email' && type.enabled).count
        }
        return sum
      }, 0)
    },
    smsSum () {
      return this.data.reduce((sum, item) => {
        if (item.deliveryTypes.find((type) => type.key === 'sms' && type.enabled)) {
          return sum + item.deliveryTypes.find((type) => type.key === 'sms' && type.enabled).count
        }
        return sum
      }, 0)
    },
    whatsappSum () {
      return this.data.reduce((sum, item) => {
        if (item.deliveryTypes.find((type) => type.key === 'whatsapp' && type.enabled)) {
          return sum + item.deliveryTypes.find((type) => type.key === 'whatsapp' && type.enabled).count
        }
        return sum
      }, 0)
    }
  },
  methods: {
    fakeLoading () {
      this.loadingFake = true
      this.$emit('is-to-resend')
    },
    changeStep (step) {
      this.disabeldButton = true
      setTimeout(() => { this.disabeldButton = false }, 1200)
      let proceed = true

      if (!this.data.length) return

      if (step === 'done' && this.metadatas.length) {
        this.metadatas.forEach(meta => { if (!meta.value) { proceed = false } })
      }

      if (proceed) {
        this.currentStep = step
        this.$emit('change-step', this.currentStep)
      }
    },
    sumTotal () {
      const count = this.data.reduce((sum, item) => sum + item.total, 0)
      gsap.to(this.$data, { countTotal: count, duration: 1, ease: 'power4.out' })
    }
  }
}
</script>

<style lang="scss">
.container-resume-resend {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 0.3rem;
  padding: 0.2rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.3rem;
  .content-surveys-selected {
    width: 100%;
    height: 65%;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    .title-surveys-selecteds {
      width: 100%;
      height: 35px;
      background: #f5f5f5;
      color: #454548;
      font-weight: 500;
      font-size: 1.9vh;
      border-radius: 0.2rem;
      border: 1px solid #cacaca;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .section-to-surveys-selecteds {
      width: 100%;
      height: 53vh;
      background: #ffffff;
      padding: 0.2rem 0;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      border-radius: 0.3rem 0.3rem 0 0;
    }
  }
  .section-confirm-resend {
    user-select: none;
    width: 100%;
    height: 30vh;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
    background: #c1c1c1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .count-details-resend {
      width: 100%;
      height: 60%;
      padding: 0.2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #454548;
      .total-count-resend {
        font-size: 1vw;
        width: 100%;
        text-align: center;
      }
      .total-count-type {
        width: 100%;
        height: 50%;
        padding: 0 1.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .count-resend {
          width: 30%;
          height: 6vh;
          border-radius: 0.3rem;
          background: #ffffff;
          color: #e1e1e1;
          box-shadow: 0 2px 1px #ffffffd1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          .type-icon-count {
            font-size: 1.1vw;
          }
          .whatsapp-icon {
            font-size: 0.65vw;
            background: #e1e1e1;
            color: #ffffff;
            border-radius: 50%;
            padding: 0.2rem;
          }
          .label-count {
            font-weight: bold;
            font-size: 0.80vw;
          }
        }
      }
      .total-approaches {
        width: 100%;
        gap: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .title-count {
          font-size: 0.85vw;
          font-weight: 500;
        }
        .label-count {
          font-size: 1.2vw;
          font-weight: bold;
          text-shadow: 1px 1px 4px #808080;
        }
      }
    }
    .buttons-to-confirm {
      width: 100%;
      height: 40%;
      .step-btn-dynamically {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .btn-step {
          width: 28vh;
          height: 1.9vw;
          background: #ffffff;
          border-radius: 0.3rem;
          border: 1px solid #ffffff;
          box-shadow: 0 2px 1px #ffffffd1;
          color: #808080;
          font-size: 1.5vh;
          font-weight: bold;
          transition: opacity 0.8s;
        }
        .btn-step:hover {
          transform: scale(1.010);
        }
        .btn-scheduled {
          width: 28vh;
          height: 1.9vw;
          background: #d56565;
          border-radius: 0.3rem;
          border: 1px solid #d56565;
          color: #ffffff;
          font-size: 1.5vh;
          font-weight: bold;
        }
        .disabled {
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }
  }
}
</style>
