import i18n from '../translations/i18n'

export const constants = {
  itemsPerPageOnTextList: 20,
  user: {
    TYPES: {
      ADMIN: 'admin',
      SUPER_ADMIN: 'superadmin',
      USER: 'user'
    },
    USER_DEFAULT_IMAGE: 'https://ucarecdn.com/66dea92f-e212-4f5f-8dbc-d186714e0d6e/-/setfill/ffffff/-/format/jpeg/-/scale_crop/160x160/center/'
  },
  metadataResend: {
    key: 'RESEND',
    type: 'String',
    label: i18n.t('metadata.title_resend_metadata'),
    groupable: false,
    enabledInsights: false
  },
  ranking: {
    TYPES: {
      HEALTH: 'health',
      QUESTION: 'question'
    }
  },
  health: {
    score: {
      VERY_LOW: i18n.t('health_score.health.health_very_low'),
      LOW: i18n.t('health_score.health.health_low'),
      MID: i18n.t('health_score.health.health_mid'),
      HIGH: i18n.t('health_score.health.health_high'),
      VERY_HIGH: i18n.t('health_score.health.health_very_high')
    },
    churn: {
      VERY_LOW: i18n.t('health_score.churn.churn_very_low'),
      LOW: i18n.t('health_score.churn.churn_low'),
      MID: i18n.t('health_score.churn.churn_mid'),
      HIGH: i18n.t('health_score.churn.churn_high'),
      VERY_HIGH: i18n.t('health_score.churn.churn_very_high')
    }
  },
  abbreviatedHealth: {
    VERY_LOW: i18n.t('health_score.abbreviated.very_low'),
    LOW: i18n.t('health_score.abbreviated.low'),
    MID: i18n.t('health_score.abbreviated.mid'),
    HIGH: i18n.t('health_score.abbreviated.high'),
    VERY_HIGH: i18n.t('health_score.abbreviated.very_high')
  }
}
