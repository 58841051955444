<template>
  <div>
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-8" style="margin-left: -60px; margin-top: -10px;">
        <div class="row center-lg center-md center-sm">
          <iframe
            id="previewId"
            type="text/html"
            :src="link"
            :key="surveyMount"
            class='iframe-survey-preview'
          ></iframe>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 preview-fields-box" style="margin-top: 10px;">
        <div class="row center-lg center-md center-sm" style="margin-bottom: 20px;">
          <app-heading level="h6" style="text-align: center; font-weight: 600 !important;">{{ $t('preview.add_fields') }}</app-heading>
        </div>
        <div class="row center-lg center-md center-sm" style="margin-top: 20px;">
          <v-select
            class="col-lg-5 col-md-5 col-sm-5 col-xs-5 text-color-input"
            v-model="newField"
            label="label"
            :clearable="false"
            :placeholder="$t('preview.select_field')"
            :options="fieldsOptions"
            @input="checkIfItIsAlreadyAdded()"
          ></v-select>
          <app-form-input
            class="col-lg-5 col-md-5 col-sm-5 col-xs-5 field-value-input"
            v-if="newField"
            v-model="newField.value"
            @keyup.enter="addField()"
            @input="updateValueIfAlreadyAdded()"
            :placeholder="$t('preview.field_value')"
            border-bottom
          ></app-form-input>
          <span style="font-size: 12px; color: red;" v-if="isAlreadyAdded">{{ alreadyAddedMessage }}</span>
          <span style="font-size: 12px; color: black;" v-if="valueUpdated">{{ valueUpdatedMessage }}</span>
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-color-input" v-if="!isAlreadyAdded && !valueUpdated">
            <app-button v-title="$t('preview.add_tooltip')" title-placement="right" class="btn-circle-accent add-field-btn" variation="primary" size="small" floating @click="addField()">
              <i class="material-icons plus-icon">add</i>
            </app-button>
          </div>
        </div>
        <div class="row center-lg center-md center-sm" style="margin-top: 20px; max-height: 48vh; overflow: scroll;">
          <div class="row center-lg center-md center-sm" v-for="(field, idx) in customFields" :key="idx">
            <div class="col-lg-5 col-md-5 col-sm-5 preview-metadata-list">
              <app-heading level="h6">{{ $t('preview.field') }}</app-heading>
              <app-form-input
                class="messageLabel"
                v-model="field.label"
                border-bottom
                disabled
              ></app-form-input>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-5 preview-metadata-list">
              <app-heading level="h6">{{ $t('preview.field_value') }}</app-heading>
              <app-form-input
                class="messageLabel"
                v-model="field.value"
                border-bottom
                disabled
              ></app-form-input>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 preview-metadata-list">
              <div class="tooltip-metadata">
                <div class="delete-item-meta">
                  <app-button inline icon="trash-alt" icon-color="gray" icon-size="sm" v-on:click="remove(field, idx)"></app-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt30">
          <div class="col-lg-4 col-md-4 col-sm-4" v-if="customFields.length > 0">
            <app-button btn-outline-primary class="btn-circle-accent clear-fields-btn" @click="clearAllFields()">{{ $t('preview.clear_all') }}</app-button>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <app-button btn-outline-primary class="btn-circle-accent clear-fields-btn" @click="reloadSurvey()">{{ $t('preview.reload_survey') }}</app-button>
          </div>
        </div>
      </div>
    </div>
    <div class="data-loading" style="margin-left: 5%" v-if="loading">
      <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce'),
    vSelect: () => import('vue-select')
  },
  props: ['currentSurvey'],
  data () {
    return {
      newField: '',
      selects: [],
      customFields: [],
      fieldToSending: [{
        type: 'test',
        showThisField: false
      }],
      surveyMount: 0,
      fieldsOptions: [
        { label: this.$i18n.t('preview.name'), type: 'name' },
        { label: this.$i18n.t('preview.email'), type: 'email' },
        { label: this.$i18n.t('preview.phone'), type: 'phone' }
      ],
      alreadyAddedMessage: this.$i18n.t('preview.added_message'),
      isAlreadyAdded: false,
      valueUpdatedMessage: this.$i18n.t('preview.value_changed'),
      valueUpdated: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    }),
    link () {
      const baseLinkUrl = `https://surveyv2.binds.co/#/preview/${this.currentSurvey.hashId}?`
      const fields = this.customFields.length > 0 ? JSON.stringify(this.customFields) : JSON.stringify(this.fieldToSending)
      const linkMounted = `${baseLinkUrl}fields=${fields}&surveyPreview=${true}`
      return linkMounted
    }
  },
  mounted () {
    this.loading = true
    this.user.account.fields.forEach((metadata) => {
      this.fieldsOptions.push({
        type: metadata.key,
        showThisField: false,
        label: metadata.label,
        value: ''
      })
    })
    setTimeout(() => { this.loading = false }, 2000)
  },
  methods: {
    reloadSurvey () {
      this.surveyMount++
    },
    addField () {
      if (!this.newField) {
        return
      }
      const hasAnother = this.customFields.filter(
        (item) => item.type === this.newField.type
      )
      if (hasAnother.length > 0) {
        return
      }
      this.customFields.push(this.newField)
      this.surveyMount++
      this.newField = ''
    },
    remove (field, idx) {
      this.customFields.splice(idx, 1)
      const fieldIdx = this.fieldsOptions.findIndex(i => i.label === field.label)
      if (fieldIdx >= 0) {
        this.fieldsOptions[fieldIdx].value = ''
      }
      this.surveyMount++
    },
    checkIfItIsAlreadyAdded () {
      this.valueUpdated = false
      this.isAlreadyAdded = this.customFields.includes(this.newField)
    },
    clearAllFields () {
      this.isAlreadyAdded = false
      this.newField = ''
      this.customFields = []
      this.fieldsOptions.map(i => {
        i.value = ''
      })
    },
    updateValueIfAlreadyAdded () {
      if (this.isAlreadyAdded) {
        this.isAlreadyAdded = false
        this.valueUpdated = true
      }
    }
  }
}
</script>

<style lang="scss">
.iframe-survey-preview {
  width: 95%;
  height: 95vh;
  margin-top: -32px !important;
  box-shadow: none !important;
  border-radius: 8px;
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
}
.preview-test {
  .modal__wrapper {
    overflow: hidden !important;
    height: 96vh;
    padding: 5px !important;
    overflow-y: auto;
    .modal__wrapper__title {
      text-align: center;
      padding-bottom: 10px;
      font-size: 24px;
    }
    .modal__wrapper__content {
      width: 100%;
      height: 100%;
      .my-preview {
        width: 100%;
        height: 93%;
        display: flex;
        align-items: center;
        justify-content: center;
        .create-field-preview {
          width: 100%;
          height: 95%;
          overflow: hidden;
          .title-preview {
            width: 100%;
            height: 5%;
            display: flex;
            align-items: center;
            justify-content: center;
            h6 {
              font-size: 20px;
              color: #000000 !important;
            }
          }
          .fields-preview {
            width: 100%;
            height: 100%;
            padding: 10px;
            position: relative;
            display: flex;
            justify-content: center;
            .section-fields {
              width: 50%;
              height: 95%;
              overflow-y: auto;
              border: 1px solid #b6b6b6;
              box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.23);
              border-radius: 5px;
              .select-type {
                width: 100%;
                height: 10%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                border-bottom: 1px solid #b6b6b6;
                .field-prev {
                  width: 35%;
                  max-width: 50%;
                  border: 1px solid #b6b6b6;
                  border-radius: 4px;
                  .vs__selected {
                    color: #b6b6b6 !important;
                  }
                  .vs__clear {
                    display: none !important;
                  }
                }
                .add-field {
                  color: #5e5e5e;
                  width: 120px;
                  padding: 8px;
                  font-size: 16px;
                  border-radius: 4px;
                  border: 1px solid #b6b6b6;
                }
                .add-field:hover {
                  transform: scale(1.02);
                }
              }
              .fields-added {
                padding-bottom: 10px;
                width: 100%;
                min-height: 90%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .field-for-add {
                  border: 1px solid #b6b6b6;
                  border-radius: 5px;
                  width: 90%;
                  height: 70px;
                  margin-top: 10px;
                  label {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    h6 {
                      width: 20%;
                      text-align: center;
                    }
                    .input-value {
                      font-size: 15px;
                      width: 60%;
                      height: 35px;
                      border-radius: 5px;
                      outline: none;
                      border: 1px solid #b6b6b6;
                      padding-left: 10px;
                    }
                  }
                }
              }
            }
            .confirm-preview {
              width: 150px;
              height: 40px;
              position: absolute;
              right: 30px;
              bottom: 40px;
              font-size: 16px;
              border-radius: 4px;
              color: #5e5e5e;
              border: 1px solid #b6b6b6;
            }
          }
        }
      }
    }
  }
}
.field-value-input {
  margin-top: -5px;
  & input {
    font-size: 13px !important;
  }
}
.preview-metadata-list {
  & h6 {
    font-size: 14px !important;
  }
  & .text-field__input {
    input {
      font-size: 14px !important;
      color: #9a9a9a !important;
    }
  }
  & .disabled-input {
    & .text-field__input {
      input {
        color: #9a9a9a !important;
      }
    }
  }
}
.clear-fields-btn {
  font-size: 14px !important;
  white-space: nowrap;
}
.preview-fields-box {
  margin-top: 60px;
  border: 1px solid #8080805c;
  border-radius: 15px;
  margin-left: -30px;
  height: 100% !important;
}
</style>
