<template>
  <div class="container-graphics-insights">
    <ShimmerLoading :isHeight="'18.4vh'" :quantity="4" v-if="loading" />
    <div class="card-graph-insight" v-if="!loading">
      <VerticalBar :data="data.syntheticEmotions" />
      <GaugeInsights :data="data.syntheticSentiments" />
      <Donuts :data="data.syntheticCategories" />
      <GraphicSentiment :data="data.overtimeClasification" :title="'EMOÇÃO AO LONGO DO TEMPO'" />
      <GraphicSentiment :data="data.overtimeSentiment" :title="'SENTIMENTO AO LONGO DO TEMPO'" />
      <GraphSwot :data="data.swot"/>
    </div>
  </div>
</template>
<script>
import GraphSwot from '@/components/insights/components/charts/GraphSwot.vue'
import GraphicSentiment from '@/components/insights/components/charts/GraphicSentiment.vue'
import GaugeInsights from '@/components/insights/components/charts/GaugeInsights.vue'
import VerticalBar from '@/components/insights/components/charts/VerticalBar.vue'
import Donuts from '@/components/insights/components/charts/Donuts.vue'
import ShimmerLoading from '../../customLoading/ShimmerLoading.vue'

export default {
  name: 'DashBoardInsight',
  props: ['data', 'loading'],
  components: {
    GraphSwot,
    GraphicSentiment,
    GaugeInsights,
    VerticalBar,
    Donuts,
    ShimmerLoading
  }
}
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
}
.container-graphics-insights {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .card-graph-insight {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
</style>
