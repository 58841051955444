const state = emptyTemplate()

function emptyTemplate () {
  return {
    templates: [
      {
        title: '',
        description: '',
        categories: [],
        selectedCategory: '',
        account: ''

      }
    ]
  }
}

const mutations = {
  resetToInitialState (state) {
    const s = emptyTemplate()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  templates (state, template) {
    Object
      .keys(template)
      .forEach(key => {
        const value = template[key]
        state[key] = value
      })
  },
  startEmptyTemplate (state, params) {
    params.templates = emptyTemplate()
  }
}

const getters = {
  getTemplates: state => state.templates
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
