<template>
<div></div>
</template>
<script>
import { userService, surveyService } from '@/_services'

export default {
  data () {
    return {
    }
  },
  beforeDestroy () {
    document.body.classList.remove('whiteLoginBg')
  },
  mounted () {
    document.body.className = 'whiteLoginBg'
  },
  async created () {
    if (this.$route.query.token) {
      localStorage.setItem('bindsUser', JSON.stringify(this.$route.query.token))
      const findAccount = await userService.getMe()
      if (findAccount && findAccount._id) {
        this.$i18n.locale = findAccount.language
        this.$store.commit('account/setUser', findAccount)
        this.$store.commit('account/language', findAccount.language)
        if (findAccount.account && !findAccount.account.theme) {
          this.$store.commit('account/setDefaultTheme')
        }
        const surveys = await surveyService.getAllSurveys()
        if (surveys.length <= 0) {
          this.$router.push({ name: 'Start' })
          return findAccount
        }
        if (findAccount &&
          findAccount.account &&
          !findAccount.account.billing.hasInvoice
        ) {
          this.$router.push({ name: 'SurveysList' })
          return findAccount
        }
        this.$router.push({
          name: 'RealTime',
          query: { q: JSON.stringify({ $and: [] }) }
        })
      }
    }
  }
}
</script>
