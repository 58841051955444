<template>
  <div class="fadeInIt containerLoginPage">
    <img id="faviconImage" :src="'/img/binds-small-logo.png'" />
    <!-- background roxo com curvas -->
    <div v-if="!isMobile" style="height: 100%; display: flex">
      <div class="background50percent" style="background: #5133a5">
        <div v-html="svgCurve"></div>
      </div>
      <div class="background50percent" style="background: #f7f7f7"></div>
    </div>
    <!-- fim das curvas -->
    <DesktopLayout v-if="!isMobile"><slot></slot></DesktopLayout>
    <MobileLayout v-if="isMobile"><slot></slot></MobileLayout>
    <app-modal
      v-model="modalIsOpen"
      :title="$t('login.alert')"
      lightbox
      close
      :primary-button="$t('login.redirect')"
      @primaryButtonClick="modalRedirect"
      class="content-modal-login"
    >
      {{ $t("login.alert_v1_redirect") }}
    </app-modal>
    <div class="fadeInIt data-loading" v-show="isLoading">
      <DoubleBounce
        id="loginSpinner"
        class="data-loading-spinner animated fadeIn"
      ></DoubleBounce>
    </div>
    <theme-style>
      #loginSpinner > .double-bounce1 { background-color: rgb(254, 224, 241)
      !important; } #loginSpinner > .double-bounce2 { background-color: rgb(210,
      204, 220) !important; }
    </theme-style>
  </div>
</template>
<script>
import { userService, surveyService } from '@/_services'
import DesktopLayout from '@/components/loginForms/DesktopLayout.vue'
import MobileLayout from '@/components/loginForms/MobileLayout.vue'

export default {
  name: 'DefaultStructure',
  data () {
    return {
      isMobile: false,
      isSignOn: false,
      isLoading: false,
      modalIsOpen: false,
      email: '',
      password: '',
      svgCurve: `<svg  width="100%" id="curveBindsBackground" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1698 320">
  <path fill="#5133a5" fill-opacity="1" d="M0,128L60,133.3C120,139,240,149,360,181.3C480,213,600,267,720,272C840,277,960,235,1080,202.7C1200,171,1320,149,1380,138.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
</svg>`,
      currentActive: 0,
      tabs: [{ label: 'welcome' }, { label: 'new_account' }]
    }
  },
  components: {
    'app-modal': () =>
      import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    DoubleBounce: () =>
      import('vue-loading-spinner/src/components/DoubleBounce'),
    DesktopLayout,
    MobileLayout
  },
  beforeDestroy () {
    // remove o fix feito para a tela de login
    if (!this.isMobile) {
      document.body.classList.remove('overflowPage')
      document.documentElement.classList.remove('overflowPage')
      document.getElementById('app').classList.remove('overflowPage')
    }

    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
    this.$root.$off('select-login-language')
    this.$root.$off('set-login-loading')
    this.$root.$off('show-login-modal')
    this.$root.$off('show-message')
    this.$root.$off('do-login')
  },
  mounted () {
    // feito para fixar o tamanho da tela
    if (!this.isMobile) {
      document.body.classList.add('overflowPage')
      document.documentElement.classList.add('overflowPage')
      document.getElementById('app').classList.add('overflowPage')
    }
    this.onResize()
    this.$root.$on('set-login-loading', (value) => {
      this.isLoading = value
    })
    this.$root.$on('show-message', (value) => {
      this.showMessage(value)
    })
    this.$root.$on('show-login-modal', (value) => {
      // this.modalIsOpen = value
    })
    this.$root.$on('select-login-language', (value) => {
      this.selectLanguage()
    })
    this.$root.$on('do-login', (body) => {
      this.email = body.email
      this.password = body.password
      this.submitLogin()
    })
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 648
    },
    showMessage (message) {
      this.$store.commit('alerts/alert', {
        message: message,
        showAlert: true
      })
    },
    selectLanguage () {
      localStorage.setItem('bindsLang', this.$i18n.locale)
      this.$store.commit('account/language', this.$i18n.locale)
    },
    modalRedirect () {
      const token = window.localStorage.getItem('bindsUser')
        ? JSON.parse(window.localStorage.getItem('bindsUser'))
        : ''
      const url = `${process.env.VUE_APP_V1}login/authenticationWithSSO/setToken?token=${token}`
      window.location.href = url
    },
    hashMe (str) {
      let hash = 0
      let i
      let chr
      if (str.length === 0) return hash
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0 // Convert to 32bit integer
      }
      return hash
    },
    async submitLogin () {
      this.$root.$emit('set-login-loading', true)
      let response = {}
      try {
        response = await userService.login({
          email: this.email,
          password: this.password
        })
      } catch (e) {
        this.$root.$emit('set-login-loading', false)
        console.log('Error on login request', e)
      }
      if (!response) {
        this.$root.$emit('set-login-loading', false)
        this.$root.$emit('show-message', this.$i18n.t('login.login_error'))
        return false
      }
      if (response.account && response.user) {
        this.$i18n.locale = response.user.language
        this.$store.commit('account/setUser', response.user)
        this.$store.commit('account/language', response.user.language)
        this.$store.commit('dashboard/resetToInitialState')
        this.$store.commit('filters/resetToInitialState')
        this.$store.commit('survey/resetToInitialState')
        this.$store.commit('sendhub/resetToInitialState')
        this.$store.commit('seeds/resetToInitialState')
        this.$store.commit('templates/resetToInitialState')
        this.$store.commit('widget/resetToInitialState')
        this.$store.commit('questionSettings/resetToInitialState')
        if (
          response.user &&
          response.user.account &&
          !response.user.account.theme
        ) {
          this.$store.commit('account/setDefaultTheme')
        }
        const surveys = await surveyService.getAllSurveys()
        if (surveys.length <= 0) {
          this.$router.push({ name: 'Start' })
          return response
        }
        if (
          response.user.account &&
          !response.user.account.billing.hasInvoice
        ) {
          this.$router.push({ name: 'SurveysList' })
          // TAWKto
          window.Tawk_API.showWidget()
          window.Tawk_API.maximize()
          window.Tawk_API.setAttributes({
            pagante: response.user.account.billing.hasInvoice ? 'Sim' : 'Não',
            name: response.user.name,
            email: response.user.email,
            hash: this.hashMe(response.user.name + response.user.email + response.user._id),
            empresa: response.user.account.name,
            enviou: response.user.account.haveEverSent ? 'Sim' : 'Não',
            limite: response.user.account.sendingSettings.hasExceededLimit ? 'Sim' : 'Não'
          }, function (error) {
            console.log(error)
          })
          return response
        }
        this.$router.push({
          name: 'RealTime',
          query: { q: JSON.stringify({ $and: [] }) }
        })
        this.$root.$emit('attributes-tawkto', response.user)
        // we use this return to success inside unit tests
        return response
      } else if (response.token && !response.v2) {
        this.$root.$emit('set-login-loading', false)
        // this.modalIsOpen = true
        this.modalRedirect()
        return response
      } else if (
        response.twoFactorAuth ||
        response.message === 'Code already send'
      ) {
        response.loginData = { email: this.email, password: this.password }
        this.$router.push({ name: 'TwoFactor', params: response })
      } else {
        this.$root.$emit('show-message', this.$i18n.t('login.login_error'))
      }
      this.$root.$emit('set-login-loading', false)
    }
  }
}
</script>
<style lang="scss">
.solutionText {
  position: absolute;
  top: 6vw;
  width: 18vw;
  text-align: center;
  color: white;
  height: 11vw;
  font-size: 1.7vw;
  left: 7.2vw;
}
.input-field-sign-up {
  position: relative;
  text-align: start;
  height: 4.7vh;
  line-height: 0vw;
  margin: 0.5vw;
  input {
    padding: 0rem 0;
  }
}
.input-field {
  position: relative;
  height: 4.5vw;
  line-height: 1vw;
  text-align: start;
}
.linearGradient {
  position: relative;
  padding-bottom: 1px;
  background: linear-gradient(
    90deg,
    rgb(236, 227, 227) 90%,
    rgba(255, 255, 255, 0.8617646887856705) 100%,
    rgba(255, 255, 255, 0.86) 100%
  );
}
.login-form-label-field {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #8c8585;
  font-size: 1.2vw;
  transition: 0.2s all;
  font-weight: lighter;
}
.login-form-input-field {
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0.2rem 0;
  box-shadow: none;
  color: #111;
  font-size: 1.7vw;
  width: 100%;
  font-weight: 200;
}
.login-form-input-field:invalid {
  outline: 0;
}
.login-form-input-field:focus,
.login-form-input-field:valid {
  border-color: #ff5790;
  transition: border-color 0.5s;
}
.login-form-input-field:focus ~ label,
.login-form-input-field:valid ~ label {
  font-size: 1vw;
  top: -1vw;
  color: #ff5790;
  transition: color 0.5s, top 0.2s, font-size 0.2s;
}

// start animations
.fadeInIt {
  @extend %animated-to-slow;
  @extend %fadeIn;
}

.zoomIt {
  @extend %animated-faster;
  @extend %fadeInUp;
}
// end animations

.languageSelector:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.languageSelector {
  height: 2vw;
  width: 10vw;
  line-height: 1vw;
  font-size: 1.1vw;
  background: none;
  border: none;
  box-shadow: none;
  position: absolute;
  font-weight: lighter;
  right: 0vw;
}
#signUpButton {
  padding: 0.6vw;
  background-color: #5133a5;
  width: 35%;
  margin: 1.4vw 0vw;
  border-radius: 0.4vw;
  font-size: 1.4vw;
  color: white;
  font-weight: bold;
}
.lineInputLogin {
  background: rgba(0, 0, 0, 0)
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.43) 0%,
      #f2ecee 48%,
      rgba(255, 255, 255, 0.44) 100%
    )
    repeat scroll 0% 0%;
  height: 0.08vw;
  width: 18.2vw;
  position: absolute;
  top: 17.7vw;
  left: 46.2vw;
}
lottie-player {
  width: 42%;
  position: absolute;
  top: 31%;
  left: 5%;
}
#lottie-controls {
  display: none !important;
}
.containerLoginPage,
.overflowPage {
  height: 100% !important;
  overflow: hidden;
}
#curveBindsBackground {
  width: 119vw;
  height: 117vw;
}
#curveBindsBackground {
  left: 1%;
  position: absolute;
  transform: rotate(90deg);
  overflow: hidden;
  top: 0px;
}
.background50percent {
  width: 50%;
  height: 100%;
}
#faviconImage {
  height: 6%;
  bottom: 2%;
  position: absolute;
  right: 1%;
}
.containerLogin {
  box-shadow: rgba(0, 0, 0, 0.5) 3px 4px 7px -5px;
  display: flex;
  height: 34vw;
  background: #ff5790 none repeat scroll 0% 0%;
  border-radius: 10px;
  position: absolute;
  top: 5vw;
  width: 80%;
  left: 10%;
}
#bindsContainerImage {
  width: 40%;
  background: #ff5790;
  height: 100%;
  border-radius: 2% 0px 0px 2%;
  img {
    height: 3.9vw;
    top: 1.4vw;
    position: absolute;
    left: 10.3vw;
    padding: 0.7vw;
  }
}

.colorLine {
  background: rgba(0, 0, 0, 0)
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.43) 0%,
      rgb(255, 255, 255) 48%,
      rgba(255, 255, 255, 0.44) 100%
    )
    repeat scroll 0% 0%;
  height: 0.07vw;
  width: 10vw;
  position: absolute;
  top: 5vw;
  left: 11.2vw;
}
.loginContainerInputs {
  width: 60%;
  background: white;
  height: 100%;
  border-radius: 15% 8px 8px 0px;
  #form {
    width: 80%;
    margin: 5vw auto;
    text-align: center;
  }
}
.loginActionLinks {
  margin: 0px 10px;
  font-size: 1vw;
  color: #5133a5;
  cursor: pointer;
  transition: color 0.5s;
  text-decoration: none;
}
.loginActionLinks:hover {
  color: #ff5790;
  transition: color 0.5s;
}
.loginInputFields {
  padding: 0.7vw;
  border: none;
  font-size: 1.2vw;
  width: 100%;
  font-weight: 200;
}
.loginTabs {
  margin: 10px;
  font-weight: 200;
  color: #9f9f9f;
  font-size: 1.6vw;
  cursor: pointer;
}
.activeLoginTab {
  margin: 10px;
  font-weight: bold;
  color: #5133a5;
  border-bottom: 1px solid #ff5790;
  font-size: 1.6vw;
  cursor: pointer;
}
#loginEnter {
  position: absolute;
  bottom: 4%;
  right: 2%;
  border: 1px solid #5133a5;
  padding: 0.6vw;
  border-radius: 0.5vw;
  width: 9%;
  font-size: 1vw;
  color: #5133a5;
  transition: border-color 0.5s, color 0.5s;
}
#signUpButton:hover {
  background-color: #ff5790;
  transition: background-color 0.5s;
}
#loginEnter:hover {
  border-color: #ff5790;
  color: #ff5790;
  transition: border-color 0.5s, color 0.5s;
}

@media screen and (max-width: 648px) {
  .modal__wrapper {
    width: 90%;
  }
  .activeLoginTab {
    font-size: 4.6vw;
  }

  .loginTabs {
    font-size: 4.6vw;
  }

  .login-form-input-field {
    border: none;
    font-size: 4.2vw;
  }

  .login-form-label-field {
    font-size: 4.2vw;
  }
  .login-form-input-field:focus ~ label,
  .login-form-input-field:valid ~ label {
    padding: 0.9rem 0;
    font-size: 3vw;
    top: -5vw;
  }

  .loginActionLinks {
    font-size: 4.7vw;
  }

  #signUpButton {
    padding: 1.7vw;
    width: 92%;
    font-size: 3.4vw;
  }

  #form {
    position: absolute;
    top: 17vw;
    width: 100%;
  }
  #app > div:nth-child(1) {
    background: white;
  }
  #form > div:nth-child(1) {
    text-align: center;
    padding: 6vw;
  }
  img.fadeInIt {
    width: 30%;
  }
  .colorLine {
    height: 0.17vw;
    width: 30vw;
    top: 10vw;
    left: 34.2vw;
  }
  .languageSelector {
    line-height: 3vw;
    height: 5vw;
    padding: 0;
    margin: 0;
    width: 34vw;
    font-size: 4.1vw;
  }
  .input-field {
    height: 18.5vw;
    line-height: 1vw;
    input {
     border-bottom: 1px solid black;
    }
  }
  #signUpButton {
    width: 100%;
  }
  #form > div:nth-child(2) > div:nth-child(3) {
    text-align: center;
    margin: 2vw 0;
  }

  .input-field-sign-up {
    line-height: 0vw !important;
    height: 8.7vw !important;
  }
  .login-form-input-field {
    font-size: 5.3vw;
  }

  .linearGradient {
    padding-bottom: 0.3vw;
  }
  .login-form-input-field:focus ~ label, .login-form-input-field:valid ~ label {
    top: -6vw;
  }
}

@media screen and (max-width: 469px) {
  .login-form-input-field:focus ~ label,
  .login-form-input-field:valid ~ label {
    font-size: 2.9vw;
  }
  .linearGradient {
    background: none;
  }
  .input-field-sign-up {
    input {
     border-bottom: 1px solid black;
    }
  }

  #signUpButton {
    padding: 1.7vw;
    width: 92%;
    font-size: 3.4vw;
  }

  #form > div:nth-child(1) {
    text-align: center;
    padding: 15vw;
  }
  img.fadeInIt {
    width: 30%;
  }
  .colorLine {
    height: 0.17vw;
    width: 30vw;
    top: 10vw;
    left: 34.2vw;
  }
  .input-field {
    height: 18.5vw;
    line-height: 1vw;
  }
  #form > div:nth-child(2) > div:nth-child(3) {
    margin: 10vw 0;
  }
}
</style>
