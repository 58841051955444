<template>
  <div class="container-metadata-sidebar">
    <div class="content-button-metadata-sidebar" v-if="!user.features.hiddenSendingMetadata">
      <button class="button-copy-metadata-sidebar" @click="copyContentMetadata()">
        {{ $t('seeds.copy_data') }}
        <i class="material-icons icon-copy-metadata-sidebar">content_copy</i>
      </button>
    </div>
    <div class="content-list-metadata-sidebar">
      <div class="item-metadata-sidebar" v-for="(meta, idx) in allMetadata" :key="idx">
        <p class="label-metadata-sidebar">{{  meta.label }}</p>
        <div class="input-metadata-label">
          <input
            type="text"
            :id="`meta-${meta.key}`"
            v-model="meta.value"
            class="input-item-metadata-sidebar"
            :disabled="true"
            maxlength="300">
            <button class="button-edit-metadata-sidebar" v-if="user.features.metadataEditing">
              <i class="material-icons icon-edit-metadata-sidebar" :id="`icon-edit-${idx}`" style="display: flex;" @click="editMetaValue(meta, idx)">edit</i>
              <i class="material-icons icon-edit-metadata-sidebar" :id="`icon-done-${idx}`" @click="doneChangeMeta(meta, idx)">done</i>
            </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dashboardsService } from '@/_services'

export default {
  name: 'MetadataSidebar',
  props: ['dataMetadata'],
  components: {},
  data () {
    return {
      seedsToCopy: [],
      allMetadata: [],
      accountMetadata: []
    }
  },
  mounted () {
    this.allMetadata = this.dataMetadata.allMetadata.filter(e => e.hiddenItem)
    this.accountMetadata = structuredClone(this.dataMetadata.accountMetadata)

    this.allMetadata.forEach(seed => {
      if (seed.value) {
        const seedValueCopy = [`${seed.label} ${seed.value} \n`]
        this.seedsToCopy.push(seedValueCopy)
      }
    })
  },
  computed: {
    user () { return this.$store.getters['account/getUser'] }
  },
  methods: {
    doneChangeMeta (meta, idx) {
      const inputMeta = document.getElementById(`meta-${meta.key}`)
      inputMeta.disabled = true
      inputMeta.style.background = '#00000005'

      const btnEdit = document.getElementById(`icon-edit-${idx}`)
      btnEdit.style.display = 'flex'

      const btnDone = document.getElementById(`icon-done-${idx}`)
      btnDone.style.display = 'none'

      this.updateMetadata(meta)
    },
    editMetaValue (meta, idx) {
      const inputMeta = document.getElementById(`meta-${meta.key}`)
      inputMeta.disabled = false
      inputMeta.style.background = '#ffffff'
      inputMeta.focus()

      const btnEdit = document.getElementById(`icon-edit-${idx}`)
      btnEdit.style.display = 'none'

      const btnDone = document.getElementById(`icon-done-${idx}`)
      btnDone.style.display = 'flex'
    },
    async updateMetadata (meta) {
      const keyMetadata = meta.key
      this.accountMetadata[keyMetadata] = meta.value.trim()

      await dashboardsService.updateSendingData(this.dataMetadata.sendingId, this.accountMetadata)
    },
    copyContentMetadata () {
      const toCopy = this.seedsToCopy.join('')
      try {
        navigator.clipboard.writeText(toCopy)

        this.$store.commit('alerts/alert', {
          message: this.$t('seeds.copy_success'),
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      } catch (error) {
        this.$store.commit('alerts/alert', {
          message: this.$t('seeds.copy_fail'),
          position: 'bottomRight',
          showAlert: true,
          persist: false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-metadata-sidebar {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.3vw;
  .content-button-metadata-sidebar {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button-copy-metadata-sidebar {
      width: 6vw;
      height: 2vw;
      font-size:0.9vw;
      border-radius: 8px;
      border: 1px solid #e1e1e1;
      background: #ffffff;
      -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      color: #5e5e5e;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.3vw;
      .icon-copy-metadata-sidebar {
        font-size: 0.85vw;
      }
    }
  }
  .content-list-metadata-sidebar {
    width: 100%;
    height: 90%;
    overflow-y: auto;
    .item-metadata-sidebar {
      width: 100%;
      height: 3vw;
      padding: 0.2vw;
      margin-bottom: 0.3vw;
      background: #ffffff;
      border-radius: 5px;
      border: 1px solid #e1e1e1;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label-metadata-sidebar {
        max-width: 35%;
        color: #454548;
        font-weight: 500;
        font-size: 0.9vw;
        line-height: 0.9vw;
        padding: 1vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .input-metadata-label {
        width: 65%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .input-item-metadata-sidebar {
          width: 80%;
          height: 1.5vw;
          border-radius: 5px;
          font-size: 0.85vw;
          color: #454548;
          padding-left: 0.3vw;
          border: 1px solid #e1e1e1;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        }
        .button-edit-metadata-sidebar {
          height: 1.5vw;
          background: #f7f7f7;
          border-radius: 5px;
          border: 1px solid #e1e1e1;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
          color: #5e5e5e;
          .icon-edit-metadata-sidebar {
            padding: 0.2vw;
            display: none;
            font-size: 1vw;
          }
        }
      }
    }
  }
}
</style>
