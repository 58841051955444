<template>
  <div class="container-automatic-message">
    <div class="section-automatic-message">
      <header class="header-automatic-message">
        <h5 class="header-title-automatic-message">{{ $t('automatic_message.title_config') }}</h5>
        <i class="material-icons header-icon-close" @click="openOrCloseModal(true)" v-title="'Fechar'">close</i>
      </header>
      <div class="section-list-automatic-message">
        <p class="title-list-automatic-message">{{ $t('automatic_message.subtitle') }}</p>
        <div class="items-list-automatic-message">
          <div class="item-automatic-message" v-for="autoMessage in listAutoMessages" :key="autoMessage._id" :class="`message-${autoMessage._id}`">
            <div class="content-message">
              <p class="title-automatic-message" @click="editMessage(autoMessage)">{{ autoMessage.title }}</p>
              <i class="material-icons message-icon-close" :class="`btn-${autoMessage._id}`" v-if="!action" @click="hasAction(false, autoMessage)" v-title="$t('automatic_message.delete_tooltip')">close</i>
            </div>
            <div class="action-to-delete-message" :class="`action-${autoMessage._id}`">
              <p class="title-delete-message">{{ $t('automatic_message.title_delete_message') }}</p>
              <div class="action-buttons-message">
                <button class="btn-action" @click="deleteMessage(autoMessage)">{{ $t('automatic_message.confirm') }}</button>
                <button class="btn-action cancel" @click="hasAction(true, autoMessage)">{{ $t('automatic_message.cancel') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="section-add-new-message">
          <button class="btn-add-automatic-message" @click="newMessage()">+ {{ $t('automatic_message.add') }}</button>
        </div>
      </div>
      <div class="section-new-automatic-message">
        <div class="title-new-automatic-message">
          <input type="text" class="new-title-automatic-message" v-model="currentMessage.title" :placeholder="$t('automatic_message.title_placeholder')">
        </div>
        <div class="content-new-automatic-message" v-click-outside="closeOptionsDisplay">
          <EditTitle
            @updateSurveyTitle="currentMessage.message = $event"
            @openOptions="optionsDisplayTitle = true"
            :editStyle="true"
            :optionsDisplay="optionsDisplayTitle"
            :identify="`new-message-automatic-message`"
            :title="currentMessage.message"
            :metadata.sync="metadataGroup"
            type="textarea"
          />
        </div>
        <div class="btn-save-messages">
          <button class="btn-action-save" v-if="isNew" @click="createNewMessage()">{{ $t('automatic_message.create') }}</button>
          <button class="btn-action-save" v-else @click="createNewMessage(true)">{{ $t('automatic_message.save') }}</button>
          <button class="btn-action-save cancel" @click="openOrCloseModal(true)">{{ $t('automatic_message.cancel') }}</button>
        </div>
        <div class="content-loading-auto-message" v-if="loading">
          <Circle2 class="spinner-social"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import { autoMessage } from '@/_services'
import EditTitle from '../EditTitle.vue'

export default {
  name: 'ModalAutomaticMessage',
  props: [],
  components: {
    EditTitle,
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      optionsDisplayTitle: false,
      loading: false,
      isNew: false,
      action: false,
      listAutoMessages: [],
      currentMessage: { title: '', message: '' },
      metadataGroup: [
        {
          key: '_name',
          label: 'Nome',
          groupable: false,
          type: 'String'
        },
        {
          key: '_email',
          label: 'Email',
          groupable: false,
          type: 'String'
        },
        {
          key: '_phone',
          label: 'Telefone',
          groupable: false,
          type: 'String'
        }
      ]
    }
  },
  mounted () {
    this.openOrCloseModal()
    this.getAllMessages()
  },
  computed: {
    user () { return this.$store.getters['account/getUser'] }
  },
  methods: {
    async getAllMessages () {
      const result = await autoMessage.getMessages()
      if (!result.length) { this.newMessage() }
      this.listAutoMessages = result

      if (this.user.account.fields.length > 0) { this.user.account.fields.forEach(e => this.metadataGroup.push(e)) }
    },
    async createNewMessage (isEdit) {
      const sectionNewMessage = document.querySelector('.section-new-automatic-message')
      const contentMessages = document.querySelectorAll('.item-automatic-message')

      if (!this.currentMessage.title.length || !this.currentMessage.message.length) {
        return this.$store.commit('alerts/alert', {
          message: this.$t('tickets.error_create_auto_message'),
          position: 'bottomRight',
          showAlert: true
        })
      }

      this.loading = true

      if (isEdit) {
        const result = await autoMessage.editMessage(this.currentMessage)
        if (result._id) {
          const messageDeleted = this.listAutoMessages.findIndex(e => e._id === result._id)
          this.listAutoMessages.splice(messageDeleted, 1, result)
        }
      } else {
        const result = await autoMessage.createMessage(this.currentMessage)
        if (result._id) { this.listAutoMessages.unshift(result) }
      }

      gsap.to(sectionNewMessage, {
        height: '0vw',
        duration: 0.5,
        onComplete: () => {
          this.loading = false
          this.currentMessage = { title: '', message: '' }
          for (const item of contentMessages) { item.style.borderColor = '#dddddd' }
        }
      })
    },
    async deleteMessage (message) {
      const result = await autoMessage.deleteMessage(message._id)
      if (Object.keys(result).length === 0) {
        const messageDeleted = this.listAutoMessages.findIndex(e => e._id === message._id)
        this.listAutoMessages.splice(messageDeleted, 1)
        this.currentMessage = { title: '', message: '' }
        const sectionNewMessage = document.querySelector('.section-new-automatic-message')
        gsap.to(sectionNewMessage, { height: '0vw', duration: 0.5 })
      } else {
        return this.$store.commit('alerts/alert', {
          message: this.$t('tickets.error_delete_msg'),
          position: 'bottomRight',
          showAlert: true
        })
      }
    },
    newMessage () {
      this.isNew = true
      const contentMessages = document.querySelectorAll('.item-automatic-message')
      const sectionNewMessage = document.querySelector('.section-new-automatic-message')

      for (const item of contentMessages) { item.style.borderColor = '#dddddd' }

      this.currentMessage = { title: '', message: '' }
      gsap.to(sectionNewMessage, { height: '13vw', duration: 0.5 })
    },
    editMessage (message) {
      this.isNew = false
      const sectionNewMessage = document.querySelector('.section-new-automatic-message')
      gsap.to(sectionNewMessage, { height: '13vw', duration: 0.5 })

      this.currentMessage = message
      const contentMessages = document.querySelectorAll('.item-automatic-message')
      const contentMessage = document.querySelector(`.message-${message._id}`)

      for (const item of contentMessages) { item.style.borderColor = '#dddddd' }

      contentMessage.style.borderColor = '#454548'
    },
    hasAction (isClose, item) {
      const height = isClose ? 0 : '2.2vw'
      const opacity = isClose ? 0 : 1
      const content = document.querySelector(`.action-${item._id}`)
      const btn = document.querySelector(`.btn-${item._id}`)
      gsap.to(content, {
        opacity,
        height,
        duration: 0.5,
        onComplete: () => {
          if (!isClose) {
            btn.style.display = 'none'
          } else {
            btn.style.display = 'flex'
          }
        }
      })
    },
    closeOptionsDisplay () {
      this.optionsDisplayTitle = false
    },
    openOrCloseModal (isClose) {
      const opacity = isClose ? 0 : 1
      const container = document.querySelector('.container-automatic-message')
      const section = document.querySelector('.section-automatic-message')
      gsap.to(container, { opacity: opacity, duration: 0.5 })
      gsap.to(section, {
        opacity,
        duration: 0.5,
        onComplete: () => {
          if (isClose) { this.$emit('close-modal-auto-message') }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.container-automatic-message {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.196);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .section-automatic-message {
    opacity: 0;
    width: 55vw;
    background: #ffffff;
    border-radius: 0.3rem;
    border: 1px solid #cacaca;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .header-automatic-message {
      position: relative;
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;
      .header-title-automatic-message {
        font-size: 1.2vw;
        color: var(--accent-color);
      }
      .header-icon-close {
        position: absolute;
        cursor: pointer;
        top: 0;
        right: 0;
        font-size: 1.2vw;
        border-radius: 50%;
        padding: 1px;
        background: var(--accent-color);
        color: var(--default-text-color);
      }
    }
    .section-list-automatic-message {
      width: 100%;
      min-height: 5vw;
      height: fit-content;
      max-height: 18vw;
      border-bottom: 1px solid #cacaca;
      padding-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 15px;
      .title-list-automatic-message {
        font-size: 0.95vw;
        font-weight: bold;
        color: #454548;
      }
      .items-list-automatic-message {
        width: 100%;
        max-height: 71%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .item-automatic-message {
          cursor: pointer;
          width: 100%;
          border: 1px solid #dddddd;
          border-radius: 0.3rem;
          .content-message {
            width: 100%;
            height: 1.6vw;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.5rem;
            .title-automatic-message {
              width: 98%;
              height: 100%;
              font-size: 0.75vw;
              line-height: 1vw;
              color: #808080;
              font-weight: 500;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: flex;
              align-items: center;
            }
            .message-icon-close {
              cursor: pointer;
              background: #dddddd;
              color: #ffffff;
              font-size: 0.7vw;
              border-radius: 50%;
              padding: 1.5px;
              font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .action-to-delete-message {
            height: 0;
            overflow: hidden;
            padding: 0 0.5rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;
            .title-delete-message {
              font-size: 0.7vw;
              color: #000000;
            }
            .action-buttons-message {
              width: 20%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: space-around;
              .btn-action {
                background: var(--accent-color);
                font-size: 0.7vw;
                padding: 7px 8px;
                font-weight: bold;
                border-radius: 0.3rem;
                color: var(--default-text-color);
              }
              .cancel {
                border: 1px solid var(--accent-color);
                background: transparent;
                color: var(--accent-color);
              }
            }
          }
        }
      }
      .section-add-new-message {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .btn-add-automatic-message {
          background: var(--accent-color);
          font-size: 0.7vw;
          padding: 7px 8px;
          font-weight: bold;
          border-radius: 0.3rem;
          color: var(--default-text-color);
        }
        .btn-add-automatic-message:hover {
          transform: scale(1.02);
        }
      }
    }
    .section-new-automatic-message {
      position: relative;
      width: 100%;
      height: 0vw;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title-new-automatic-message {
        width: 100%;
        height: 1.7vw;
        margin-bottom: 2vw;
        display: flex;
        align-items: center;
        border-radius: 0.2rem;
        border: 2px dashed #e1e1e1;
        .new-title-automatic-message {
          width: 100%;
          height: 100%;
          font-size: 0.7vw;
          color: #808080;
          border: none;
          padding-left: 0.5rem;
        }
      }
      .content-new-automatic-message {
        width: 100%;
        height: 52%;
        border-radius: 0.2rem;
        border: 2px dashed #e1e1e1;
        display: flex;
        align-items: center;
        justify-content: center;
        .content-edit-title {
          width: 100%;
          height: 100%;
          .add-fields-content {
            position: absolute;
            left: 0;
            top: -37%;
            .fields-group {
              padding: 0.7rem !important;
              border: 1px solid var(--accent-color);
              max-height: 8.4vw;
            }
          }
          .active-edit-title {
            width: 100% !important;
            height: 100% !important;
            .edit-label {
              display: none !important;
            }
            .center-text {
              width: 100%;
              height: 100%;
              #new-message-automatic-message {
                height: 100% !important;
                min-height: 100% !important;
                max-height: 100% !important;
                padding: 0.5rem !important;
                text-align: left;
                font-weight: 500 !important;
                font-size: 0.7vw !important;
                color: #808080 !important;
                line-height: 100%;
                word-wrap: break-word;
                border: none !important;
                resize: none !important;
              }
            }
          }
        }
      }
      .btn-save-messages {
        width: 100%;
        height: 2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        .btn-action-save {
          width: 7vw;
          height: 1.6vw;
          background: var(--accent-color);
          font-size: 0.75vw;
          padding: 7px 8px;
          font-weight: bold;
          border-radius: 0.3rem;
          color: var(--default-text-color);
        }
        .cancel {
          background: #e1e1e1;
          border: 1px solid #cacaca;
          color: #808080;
          font-weight: 500;
        }
      }
      .content-loading-auto-message {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ffffff;
        border-radius: 0.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .spinner-social {
          width: 2vw !important;
          height: 2vw !important;
          border-width: 3px !important;
          border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
        }
      }
    }
  }
}
</style>
