<template>
    <div v-html="embededCode"></div>
</template>
<script>
import { embedGenerator, responseOptions } from '@/_helpers'
export default {
  props: ['question'],
  data () {
    return {
      embededCode: ''
    }
  },
  mounted () {
    if (['nps', 'csat'].indexOf(this.question.type) > -1) {
      this.embededCode = embedGenerator.returnEmbedCsatOrNps(this.question)
    }
    if (['nes', 'enum'].indexOf(this.question.type) > -1) {
      if (this.question.options.length === 0) {
        this.question.options = responseOptions.getOptions(this.question)
      }
      this.embededCode = embedGenerator.returnEmbedEnum(this.question)
    }
    this.$emit('embedQuestion', this.embededCode)
  }
}
</script>
