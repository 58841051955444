<template>
  <div class="row center-lg center-md center-sm">
    <!-- Bloqueios -->
    <app-accordion
      id="accordionWidth"
      class="col-lg-11 col-md-11 col-sm-11 accordion-section"
      :title="$t('survey.settings.locks')"
      :showOnStartup="false"
    >
      <!-- Bloqueio de resposta -->
      <div class="row mt30 mb20">
        <div class="responses-toggle row">
          <app-toggle-input v-model="isToBlock" @change.native="blocking()"/>
          <app-heading level="h6" class="tog-text">{{ $t('survey.lock_time') }}</app-heading>
        </div>
        <div class="row middle-lg middle-md middle-sm time-content" v-if="isToBlock">
          <input
            min="1"
            id="lockTime"
            type="number"
            name="lockTime"
            v-model="lockTime"
            @input="updateTime(lockTime)"
            class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
          />
          <v-select
            label="label"
            v-model="interval"
            :clearable="false"
            :options="optionsForBlocking"
            :placeholder="optionsForBlocking[0].label"
            @input="optionSelected(interval, 'time')"
            class="time-select col-lg-8 col-md-8 col-sm-8 col-xs-8"
          ></v-select>
        </div>
        <div class="row unique" v-if="isToBlock">
          <div class="unique--title">
            <app-heading level="h6">{{ $t('survey.unique_key') }}</app-heading>
            <i v-title="$t('survey.unique_key_desc')" class="material-icons">help</i>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding: 10px 0px 0px 0px;">
            <v-select
              label="label"
              :clearable="false"
              v-model="intervalKey"
              :options="fieldsToMetadata"
              @input="optionSelected(intervalKey, 'metadata')"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="row center-lg center-md center-sm">
        <div v-if="dividerWidth" class="lock-divider" :style="{ '--divider': dividerWidth }"></div>
      </div>
      <!-- Bloqueio de resposta - END -->
      <!-- Bloqueio de envio -->
      <div class="row mt30 mb20">
        <div class="responses-toggle row">
          <app-toggle-input v-model="isToBlockSurveySendByInterval" @change.native="updateSendInterval()"/>
          <app-heading level="h6" class="tog-text">{{ $t('survey.send_lock_time') }}</app-heading>
          <i v-title="$t('survey.send_block_description')" title-max-width="260" title-placement="left" class="material-icons">help</i>
        </div>
        <div class="row middle-lg middle-md middle-sm time-content" v-if="isToBlockSurveySendByInterval">
          <input
            min="1"
            id="sendLockTime"
            type="number"
            name="sendLockTime"
            v-model="sendLockTime"
            @input="updateTimeForSendInterval(sendLockTime)"
            class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
          />
          <v-select
            label="label"
            v-model="sendInterval"
            :clearable="false"
            :options="optionsSendBlock"
            :placeholder="optionsSendBlock[0].label"
            @input="optionSelectedForSurveySendInterval(sendInterval, 'time')"
            class="time-select col-lg-8 col-md-8 col-sm-8 col-xs-8"
          ></v-select>
        </div>
      </div>
      <div class="row center-lg center-md center-sm">
        <div v-if="dividerWidth" class="lock-divider" :style="{ '--divider': dividerWidth }"></div>
      </div>
      <!-- Bloqueio de envio - END -->
      <!-- Expirar envio -->
      <div class="row mt30 mb20">
        <div class="responses-toggle row">
          <app-toggle-input v-model="isToBlockSurveyExpireByInterval" @change.native="updateExpireInterval()"/>
          <app-heading level="h6" class="tog-text">{{ $t('survey.expire_lock_time') }}</app-heading>
          <i v-title="$t('survey.expire_block_description')" title-max-width="260" title-placement="" class="material-icons">help</i>
        </div>
        <div class="row middle-lg middle-md middle-sm time-content" v-if="isToBlockSurveyExpireByInterval">
          <input
            min="1"
            id="expireLockTime"
            type="number"
            name="expireLockTime"
            v-model="expireLockTime"
            @input="updateTimeForExpireInterval(expireLockTime)"
            class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
          />
          <v-select
            label="label"
            v-model="expireInterval"
            :clearable="false"
            :options="optionsSendBlock"
            :placeholder="optionsSendBlock[0].label"
            class="time-select col-lg-8 col-md-8 col-sm-8 col-xs-8"
            @input="optionSelectedForSurveyExpireInterval(expireInterval, 'time')"
          ></v-select>
        </div>
      </div>
      <!-- Expirar envio - END -->
    </app-accordion>
    <!-- Anonimato -->
    <app-accordion
      class="col-lg-11 col-md-11 col-sm-11 accordion-section"
      :title="$t('survey.settings.anonymity')"
      :showOnStartup="false"
    >
      <isAnonymous/>
    </app-accordion>
    <theme-style>
      .responses-toggle > div > input {
        color: {{user.account.theme.colors.accent || '#ff607d'}} !important;
      }
    </theme-style>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import isAnonymous from './IsAnonymousConfig.vue'
import { userService } from '@/_services'
export default {
  name: 'Responses',
  components: {
    isAnonymous,
    vSelect: () => import('vue-select'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-accordion': () => import('@binds-tech/binds-design-system/src/components/Accordion/Accordion'),
    'app-toggle-input': () => import('@binds-tech/binds-design-system/src/components/Form/ToggleInput')
  },
  data () {
    return {
      isToBlock: false,
      isToBlockSurveySendByInterval: false,
      isToBlockSurveyExpireByInterval: false,
      sendLockTime: '',
      expireLockTime: '',
      lockTime: 1,
      intervalKey: '',
      interval: { label: this.$i18n.t('survey.hours'), value: 'h' },
      sendInterval: '',
      sendIntervalValue: '',
      expireInterval: '',
      expireIntervalValue: '',
      intervalValue: 'h',
      metadataGroup: [],
      fieldsMetadata: [
        { label: this.$i18n.t('survey.email_or_phone'), value: null }
      ],
      optionsForBlocking: [
        { label: this.$i18n.t('survey.hours'), value: 'h' },
        { label: this.$i18n.t('survey.days'), value: 'd' },
        { label: this.$i18n.t('survey.months'), value: 'M' }
      ],
      optionsSendBlock: [
        { label: this.$i18n.t('survey.seconds'), value: 'seconds' },
        { label: this.$i18n.t('survey.minutes'), value: 'minutes' },
        { label: this.$i18n.t('survey.hours'), value: 'hours' },
        { label: this.$i18n.t('survey.days'), value: 'days' },
        { label: this.$i18n.t('survey.weeks'), value: 'weeks' },
        { label: this.$i18n.t('survey.months'), value: 'months' }
      ],
      dividerWidth: undefined
    }
  },
  computed: {
    ...mapGetters({
      user: 'account/getUser'
    }),
    fieldsToMetadata () {
      const fields = this.fieldsMetadata
      this.metadataGroup.forEach(item => {
        fields.push({ label: item.label, value: item.key })
      })
      return fields
    },
    survey () { return this.$store.getters['survey/getSurvey'] }
  },
  created () {
    if (!this.survey.responseInterval) {
      this.survey.responseInterval = null
    }
    if (!this.survey.sendInterval) {
      this.survey.sendInterval = null
    }
    if (!this.survey.expireInterval) {
      this.survey.expireInterval = null
    }
    if (!this.survey.responseIntervalKey) {
      this.survey.responseIntervalKey = null
    }
  },
  async mounted () {
    const waitABit = setTimeout(() => {
      const width = document.getElementById('accordionWidth')?.offsetWidth
      this.dividerWidth = `${width}px`
      window.addEventListener('resize', this.getDividerWidth)
      clearTimeout(waitABit)
    }, 1000)

    const user = await userService.getMe()
    if (user.account.fields.length > 0) {
      const data = user.account.fields
      data.map(item => this.metadataGroup.push(item))
    }

    if (this.survey.responseInterval) {
      const parseInterval = this.survey.responseInterval.split('')
      const findInterval = parseInterval[parseInterval.length - 1]
      parseInterval.splice(parseInterval.length - 1, 1)
      this.lockTime = parseInterval.join('')
      this.intervalValue = findInterval
      this.optionsForBlocking.find(item => {
        if (item.value === findInterval) {
          this.interval = item.label
        }
      })

      this.isToBlock = true
    }

    if (this.survey.responseIntervalKey) {
      const parseIntervalKey = this.survey.responseIntervalKey.split('.')
      this.metadataGroup.find(item => {
        if (item.key === parseIntervalKey[1] && this.survey.responseIntervalKey !== null) {
          this.intervalKey = item.label
        }
      })
    }

    if (this.survey.sendInterval) {
      const findInterval = this.survey.sendInterval.match(/[a-zA-Z]+/g)
      const hasSendInterval = this.survey.sendInterval.match(/\d+/g)
      if (hasSendInterval) {
        this.sendLockTime = hasSendInterval[0]
      }

      if (findInterval && findInterval[0]) {
        this.sendIntervalValue = findInterval[0]
        this.optionsSendBlock.find(item => {
          if (item.value === findInterval[0]) {
            this.sendInterval = item.label
          }
        })
      }
      this.isToBlockSurveySendByInterval = true
    } else {
      this.sendInterval = this.optionsSendBlock[0]
      this.sendIntervalValue = this.optionsSendBlock[0].value
    }

    if (this.survey.expireInterval) {
      const findInterval = this.survey.expireInterval.match(/[a-zA-Z]+/g)
      const hasExpireInterval = this.survey.expireInterval.match(/\d+/g)
      if (hasExpireInterval) {
        this.expireLockTime = hasExpireInterval[0]
      }

      if (findInterval && findInterval[0]) {
        this.expireIntervalValue = findInterval[0]
        this.optionsSendBlock.find(item => {
          if (item.value === findInterval[0]) {
            this.expireInterval = item.label
          }
        })
      }
      this.isToBlockSurveyExpireByInterval = true
    } else {
      this.expireInterval = this.optionsSendBlock[0]
      this.expireIntervalValue = this.optionsSendBlock[0].value
    }

    if (this.survey.responseIntervalKey === null) {
      this.intervalKey = this.fieldsMetadata[0].label
    }
  },
  methods: {
    blocking () {
      if (!this.isToBlock) {
        return this.$store.commit('survey/survey', { responseIntervalKey: null, responseInterval: null })
      }

      if (!this.survey.responseInterval) {
        this.updateTime(this.lockTime)

        if (this.intervalKey && this.intervalKey !== this.$i18n.t('survey.email_or_phone')) {
          const key = typeof this.intervalKey === 'string' ? this.intervalKey : this.intervalKey.value

          this.$store.commit('survey/survey', { responseIntervalKey: `metadata.${key}` })
        }
      }
    },
    updateSendInterval () {
      if (!this.isToBlockSurveySendByInterval) {
        this.$store.commit('survey/survey', { sendInterval: null })
      }
    },
    updateTimeForSendInterval (time) {
      this.$store.commit('survey/survey', { sendInterval: `${time}${this.sendIntervalValue}` })
    },
    updateExpireInterval () {
      if (!this.isToBlockSurveyExpireByInterval) {
        this.$store.commit('survey/survey', { expireInterval: null })
      }
    },
    updateTimeForExpireInterval (time) {
      this.$store.commit('survey/survey', { expireInterval: `${time}${this.expireIntervalValue}` })
    },
    updateTime (time) {
      this.$store.commit('survey/survey', { responseInterval: `${time}${this.intervalValue}` })
    },
    optionSelected (item, key) {
      if (key === 'time') {
        this.intervalValue = item.value
        this.$store.commit('survey/survey', { responseInterval: `${this.lockTime}${this.intervalValue}` })
      }

      if (key === 'metadata') {
        if (item.value === null) {
          return this.$store.commit('survey/survey', { responseIntervalKey: null })
        }
        this.$store.commit('survey/survey', { responseIntervalKey: `metadata.${item.value}` })
      }
    },
    optionSelectedForSurveySendInterval (item, key) {
      if (key === 'time') {
        this.sendIntervalValue = item.value
        this.$store.commit('survey/survey', { sendInterval: `${this.sendLockTime}${this.sendIntervalValue}` })
      }
    },
    optionSelectedForSurveyExpireInterval (item, key) {
      if (key === 'time') {
        this.expireIntervalValue = item.value
        this.$store.commit('survey/survey', { expireInterval: `${this.expireLockTime}${this.expireIntervalValue}` })
      }
    },
    getDividerWidth () {
      let width
      const accordion = document.getElementById('accordionWidth')
      if (accordion) {
        width = accordion.offsetWidth
      }
      this.dividerWidth = `${width}px`
    }
  }
}
</script>
<style lang="scss">
.responses-toggle {
  display: flex;
  margin-top: 15px;
  & .tog-text {
    max-width: 220px;
    font-size: 14px !important;
    margin-left: 45px !important;
    line-height: 20px !important;
  }
  & i {
    font-size: 18px;
    margin-left: 5px;
    margin-top: 1px;
    color: #8E8E8E;
    cursor: pointer;
  }
}
.time-content {
  margin-top: 10px;
  & input[type=number] {
    height: 28px !important;
    font-size: 16px;
    padding-left: 10px;
    width: 30%;
    border: 1px solid #cacaca;
    border-radius: 4px;
    text-align: center;
  }
  input[type=number]::-webkit-inner-spin-button {
    opacity: 1;
  }
}
.time-select {
  padding-right: 0px !important;
}
.unique {
  padding: 20px 0px;
  h6 {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  & .unique--title {
    display: flex;
    align-items: center;
    flex-direction: row;
    & i {
      font-size: 18px;
      margin-left: 5px;
      color: #8E8E8E;
      cursor: pointer;
    }
  }
}
.lock-divider {
  border-bottom: 1px solid rgba(0,0,0,0.25);
  margin: 0px -10px;
  width: var(--divider);
}
</style>
