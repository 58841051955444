import i18n from '../translations/i18n'

export const responseFormat = {
  responses
}

function responses (survey, responses) {
  const responsesToFormat = responses || []

  if (responsesToFormat) {
    survey.questions.forEach(question => {
      responsesToFormat.sort(a => {
        a.question.gradient = question.gradient
        return a.question._id === question._id
      })
    })
  }
  /*
    Formatação feita porque quando o type é CSAT, por exemplo, a response vem com rating e value.
    Dependendo da UI, precisamos escolher entre rating e value para renderizar a resposta na listagem.
  */
  if (responsesToFormat) {
    responsesToFormat.map(response => {
      response.formattedValue = response.rating >= 0 ? response.rating : response.value ? response.value : i18n.t('dashboard.no_response')
      if ((response.question.type === 'csat' && response.question.ui === '10num') || (response.question.type === 'csat' && response.question.ui === '5num')) {
        response.formattedValue = response.value ? response.value : i18n.t('dashboard.no_response')
      }
      if (response.question.type === 'nps' && response.question.ui === '10num') {
        response.formattedValue = response.rating >= 0 ? response.rating / 10 : i18n.t('dashboard.no_response')
      }
      if (response.question.type === 'ces' && response.question.ui === '1to7') {
        // esse math aqui resolve a vida, transforma o rating de 15, 30 etc nas labels que o usuário vê na pesquisa (1, 2, 3, 4, 5...)
        response.formattedValue = response.rating >= 0 ? Math.round((response.rating / 16)) + 1 : i18n.t('dashboard.no_response')
      }
      if (
        ((response.question.type === 'ces' && response.question.ui === '1to7button') ||
        (response.question.type === 'nps' && response.question.ui === 'ynm') ||
        (response.question.type === 'kpi')) && !response.question.language
      ) {
        response.question.language = survey.language
      }
      if (response.question.type === 'nvs' && response.question.ui === '5radio') {
        response.formattedValue = response.value ? response.value : i18n.t('dashboard.no_response')
      }
      if (['multiple', 'multipleOther'].indexOf(response.question.ui) > -1 && response.enum_multiple) {
        response.formattedValue = response.enum_multiple.toString()
      }
    })
  }
  return responsesToFormat
}
