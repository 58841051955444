<template>
  <div class="content-1to7button">
    <app-form-vertical-button
      v-model="inputValue"
      :items="options"
      item-value="value"
      item-text="label"
      :isToShowResponse="response"
      :isGradient="question.gradient"
      :textColor="colors.actionText"
      :labelColor="colors.splashColor"
      :bgColor="colors.actionColor"
      :hoverColor="colors.buttonHover"
    />
  </div>
</template>

<script>
import { translator, gradientMaker } from '@/_helpers'

export default {
  name: 'app1to7button',
  props: ['question', 'colors', 'response'],
  components: {
    'app-form-vertical-button': () => import('@binds-tech/binds-design-system/src/components/Form/VerticalButton')
  },
  data () {
    return {
      inputValue: null,
      optionsValues: []
    }
  },
  created () {
  },
  computed: {
    options () {
      const options = [
        { label: this.t('components.ces.totally_disagree'), value: 0 },
        { label: this.t('components.ces.disagree'), value: 15 },
        { label: this.t('components.ces.partially_disagree'), value: 30 },
        { label: this.t('components.ces.neutral'), value: 50 },
        { label: this.t('components.ces.partially_agree'), value: 65 },
        { label: this.t('components.ces.agree'), value: 80 },
        { label: this.t('components.ces.totally_agree'), value: 100 }
      ]
      gradientMaker.checkGradientAndReturnTheColors(this.question, options)
      return options
    }
  },
  methods: {
    t (text) {
      return translator.translateThisText(this.question.language, text)
    }
  }
}
</script>
<style lang="scss">
.content-1to7 {
  padding-bottom: 20px;
}
</style>
