<template>
  <div>
    <app-modal class="modal-default-primary" v-model="isModalOpen" lightbox close>
        <div class="content-exceeded">
            <span class="material-icons">star</span>
            <div class="exceeded-title"><app-heading level="h4">{{ $t("exceeded.upgrade") }}</app-heading></div>
            <app-heading level="h6">{{ $t("exceeded.limit") }}</app-heading>
        </div>
        <div class="footer-exceeded">
         <app-button class="btn-circle-accent" v-on:click="send()">{{ $t("exceeded.keep") }}</app-button>
         <div class="exceeded-alert-message">
            <app-form-checkbox-mini
              :label="$t('exceeded.notshow')"
              @valueChanged="updateCheckBox"
            />
            </div>
        </div>
    </app-modal>
    <app-modal class="modal-default-primary" v-model="isModalsuccess" lightbox close>
        <div class="content-exceeded">
            <div class="exceeded-title"><app-heading level="h4">{{ $t("exceeded.thanks") }}</app-heading></div>
            <app-heading level="h6">{{ $t("exceeded.process") }}</app-heading>
        </div>
        <div class="footer-exceeded">
         <app-button class="btn-circle-accent" v-on:click="isModalsuccess = false">Ok</app-button>
        </div>
    </app-modal>
  </div>
</template>

<script>
import { userService, accountService } from '@/_services'
export default {
  name: 'ExceededLimit',
  components: {
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-checkbox-mini': () => import('@binds-tech/binds-design-system/src/components/Form/CheckboxMini')
  },
  data () {
    return {
      isModalOpen: false,
      isModalsuccess: false,
      me: {}
    }
  },
  created () {
    this.display()
  },
  mounted () {
    this.$root.$on('exceeded', () => {
      this.display()
    })
  },
  beforeDestroy () {
    this.$root.$off('exceeded')
  },
  methods: {
    async display () {
      this.me = await userService.getMe()
      const acc = this.me.account
      const flagToShowMessage = this.$store.getters['account/getExceededLimitMessage']
      if (acc.sendingSettings.hasExceededLimit === true && acc.billing.hasInvoice === false) {
        flagToShowMessage ? this.isModalOpen = false : this.isModalOpen = true
      } else {
        this.isModalOpen = false
      }
    },
    updateCheckBox (e) {
      this.$store.commit('account/setExceededLimitMessage', e)
    },
    async send () {
      const attachments = [{
        title: 'QUERO CONTRATAR UM PLANO PARA ENVIAR MAIS!',
        footer: 'Referer: https://adminv2.binds.co/#/',
        color: '#4caf50',
        ts: Math.floor(Date.now() / 1000),
        fields: [
          {
            short: true,
            title: 'Nome',
            value: this.me.name
          },
          {
            short: true,
            title: 'Email',
            value: this.me.email
          },
          {
            short: true,
            title: 'Telefone',
            value: this.me.tel
          },
          {
            short: true,
            title: 'Empresa',
            value: this.me.account.name
          }
        ]
      }]
      await accountService.opportunities({ attachments: attachments })
      this.isModalOpen = false
      this.isModalsuccess = true
      this.$store.commit('account/setExceededLimitMessage', false)
      window.open(`https://seguro.binds.co/checkout.php?account=${this.me.account._id}`, '_blank')
    }
  }
}
</script>

<style lang="scss">
.content-exceeded {
    text-align: center;
    .material-icons {
      font-size: 55px;
      color: #673ab7;
    }
    .exceeded-title {
      margin-bottom: 20px;
    }
    h6 {
      line-height: 21px !important;
    }
}
.footer-exceeded {
    margin-top: 20px;
    text-align: center;
    .input-wrapper{
     padding-top: 0 !important;
     padding-left: 13px !important;
    &:after {display: none;}
    }
    .exceeded-alert-message {
        cursor: pointer;
        width: 200px;
        margin: 0 auto;
    }
}

</style>
