<template>
  <div style="height: 100vh; width: 100vw; position: fixed; top: 0; left: 0; z-index: 99; background: transparent;" @click="handleClickOutsideSidebar($event)">
    <div class="profile">
      <div>
        <div class="data-loading-preferences" v-if="loading">
          <DoubleBounce class="data-loading-spinner animated fadeIn"></DoubleBounce>
        </div>
        <div class="row">
          <div class="my-profile-header">
          <div class="profile-button close-config col-lg-12 col-md-12 col-xs-12 col-sm-12 col-xs-12">
            <button @click="showCloseModal = true">
              <app-textstyle color="purple" size="size-xl">×</app-textstyle>
            </button>
          </div>
          <div class="profile-heading">
            <app-heading level="h5">{{$t('sendConfig.interval_title')}}</app-heading>
          </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 profile-label-btn labelInputConfig">
              <p style="display: flex; margin: 15px 0;">
                <app-heading level="h5" style="padding:4px 4px 4px 0">{{$t('sendConfig.interval_subtitle')}}</app-heading>
                <app-tooltip style="display: contents;" hover :bottom="true">
                  <span>{{$t('sendConfig.description')}}</span>
                  <template v-slot:activator>
                    <i class="material-icons" style="color: #a2a2a2;">help</i>
                  </template>
                </app-tooltip>
              </p>
              <div class="row">
                <div style="margin: 10px 0; width: 100%;">
                  <app-heading level="h6">{{$t('sendConfig.period')}}:</app-heading>
                </div>
                <div style="display: flex; width: 100%;">
                  <v-select
                    style="width: 50%;"
                      label="name"
                      v-model="interval.unit"
                      :placeholder="$t('sendConfig.select')"
                      :reduce="name => name.value"
                      :options="[
                        {name: $t('sendConfig.seconds'), value: 'seconds'},
                        {name: $t('sendConfig.minutes'), value: 'minutes'},
                        {name: $t('sendConfig.hours'), value: 'hours'},
                        {name: $t('sendConfig.days'), value: 'days'},
                        {name: $t('sendConfig.weeks'), value: 'weeks'},
                        {name: $t('sendConfig.months'), value: 'months'}
                      ]"
                    ></v-select>
                    <app-form-input type="number" v-model="interval.value" min="0" :placeholder="$t('sendConfig.interval')" border-bottom ></app-form-input>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 text-color-input" style="display: flex; margin: 15px 0;">
                  <input type="checkbox" v-model="account.features.surveySendingInterval" class="block-checkbox">
                  <app-heading level="h6" style="padding: 4px;">{{$t('sendConfig.block')}}</app-heading>
                  <app-tooltip style="display: contents;" hover :bottom="true">
                    <span>{{$t('sendConfig.block_tooltip')}}</span>
                    <template v-slot:activator>
                      <i class="material-icons" style="color: #a2a2a2;">help</i>
                    </template>
                  </app-tooltip>
                </div>
              </div>

              <hr style="width: 100%; color: rgba(0, 0, 0, 0.3); left: 0; position: absolute;">

              <div class="row" style="margin-top: 20px;">
                <p style="display: flex; margin: 15px 0px;">
                  <app-heading level="h5" style="padding: 4px 4px 4px 0px">{{$t('sendConfig.expiration_title')}}</app-heading>
                  <app-tooltip style="display: contents;" hover :bottom="true">
                    <span>{{$t('sendConfig.expiration_subtitle')}}</span>
                    <template v-slot:activator>
                      <i class="material-icons" style="color: #a2a2a2;">help</i>
                    </template>
                  </app-tooltip>
                </p>
                  <div style="margin: 10px 0; width: 100%;">
                    <app-heading level="h6">{{$t('sendConfig.period')}}:</app-heading>
                  </div>
                <div style="display: flex; width: 100%;">
                  <v-select
                  style="width: 50%;"
                      label="name"
                      v-model="expirationInterval.unit"
                      :placeholder="$t('sendConfig.select')"
                      :reduce="name => name.value"
                      :options="[
                        {name: $t('sendConfig.seconds'), value: 'seconds'},
                        {name: $t('sendConfig.minutes'), value: 'minutes'},
                        {name: $t('sendConfig.hours'), value: 'hours'},
                        {name: $t('sendConfig.days'), value: 'days'},
                        {name: $t('sendConfig.weeks'), value: 'weeks'},
                        {name: $t('sendConfig.months'), value: 'months'}
                      ]"
                    ></v-select>
                    <app-form-input
                      type="number"
                      v-model="expirationInterval.value"
                      min="0"
                      :placeholder="$t('sendConfig.interval')"
                      border-bottom
                    ></app-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-button class="save-profile" variation="primary" v-on:click="save()">{{ $t('profile.save') }}</app-button>
      </div>
      <div>
        <theme-style>
          .my-profile-header {
            background-color: {{ user.account.theme.colors.primary || '#673ab7' }} !important;
          }
          .my-profile-header .heading,
          .my-profile-header .close-config button span {
            color:{{ (user.account.theme.colors.primaryText === 'dark') ? '#302b3c' : '#fdfbff' }} !important;
          }

        </theme-style>
        <app-modal
          v-model="showCloseModal"
          :title="$t('survey.exit_whithout_save')"
          lightbox
          close
          class="modal-default-primary"
          :primary-button="this.$t('account_config.delete_confirm.yes')"
          @primaryButtonClick="canBeClosed = true; handleCloseSidebar()"
          :secondary-button="this.$t('account_config.delete_confirm.no')"
          @secondaryButtonClick="showCloseModal = false, canBeClosed = false"
        >
          <app-heading level="h7">{{ $t('survey.exit_message') }}</app-heading>
        </app-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { accountService } from '@/_services'
import { metadataManipulator } from '@/_helpers'

export default {
  name: 'SendPreferences',
  components: {
    vSelect: () => import('vue-select'),
    'app-tooltip': () => import('@binds-tech/binds-design-system/src/components/Tooltip/Tooltip'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-textstyle': () => import('@binds-tech/binds-design-system/src/components/Typography/TextStyle'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    DoubleBounce: () => import('vue-loading-spinner/src/components/DoubleBounce')

  },
  data () {
    return {
      interval: {},
      expirationInterval: {},
      loading: true,
      canBeClosed: false,
      showCloseModal: false
    }
  },
  computed: {
    ...mapGetters({
      account: 'account/getAccount',
      user: 'account/getUser'
    })
  },
  created () {
    const onEscape = (e) => {
      if (e.keyCode === 27) {
        this.showCloseModal = true
      }
    }

    document.addEventListener('keydown', onEscape)

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape)
    })
  },
  async mounted () {
    const result = await accountService.getAccount(this.account._id)
    if (result._id) {
      this.$store.commit('account/setAccount', result)
    }
    this.interval = this.transformIntervalToUi(this.account.sendingInterval)
    this.expirationInterval = this.transformIntervalToUi(this.account.expirationInterval)
    this.loading = false
  },
  methods: {
    handleCloseSidebar () {
      if (this.canBeClosed && this.showCloseModal) this.$root.$emit('close-send-preferences-config')
      else return null
    },
    handleClickOutsideSidebar (event) {
      if (event.target._prevClass === 'expand-profile') this.showCloseModal = true
      else return null
    },
    transformIntervalToUi (str) {
      return !str
        ? {
          unit: ''
        }
        : {
          value: parseInt(str.match(/\d*/g)[0], 10),
          unit: str.match(/[^\d\W]+/g)[0]
        }
    },
    transformUiToInterval (obj) {
      var value = obj.value
      var unit = obj.unit
      return !value || !unit ? '' : value + unit
    },
    async save () {
      this.account.sendingInterval = this.transformUiToInterval(this.interval)
      this.account.expirationInterval = this.transformUiToInterval(this.expirationInterval)
      const accountToSave = metadataManipulator.removeFieldsFromAccounts(this.account)
      const update = await accountService.updateAccount(this.account._id, accountToSave)
      if (update._id) {
        this.$store.commit('account/setAccount', update)
        this.$store.commit('alerts/alert', {
          message: this.$i18n.t('sendConfig.success'),
          position: 'bottomRight',
          showAlert: true
        })
        this.$root.$emit('close-send-preferences-config')
        return false
      }

      this.$store.commit('alerts/alert', {
        message: this.$i18n.t('sendConfig.error'),
        position: 'bottomRight',
        showAlert: true
      })
    }
  }
}
</script>

<style lang="scss">
.profile {
  min-height: 100%;
  z-index: 9999;
  width: 28rem;
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 10px 10px 5px 10px rgba(48, 48, 48, 0.5);
  overflow: auto;
  overflow-x: hidden;
  div.vs__dropdown-toggle {
    height: 40px;
    margin-right: 10px;
  }
}
.profile-button {
  top: -20px;
  right: 0;
  position: absolute;
}
.profile-heading {
  & .heading {
    padding: 15px !important;
  }
}
.profile-permission {
  padding-top: 10px;
  margin-top: 15px;
  margin-left: 20px;
  & h6 {
    font-size: 13.5px !important;
    margin-left: 40px !important;
    margin-top: -1px !important;
    line-height: 20px !important;
  }
}
.text-color-input {
  input.block-checkbox {
    accent-color: var(--accent-color);
  }
}
.profile-label-btn {
  & h6 {
    font-size: 14px !important;
    margin-left: 0 !important;
  }
  & .text-field__input {
    input {
      font-size: 14px !important;
    }
  }
}
.pass {
  margin-left: -15px !important;
}
button.save-profile.binds-button {
  font-size: 14px;
  margin: 35px 25%;
  padding: 9px 10px;
  width: 230px;
  background: var(--accent-color);
  box-shadow: var(--secondary-color);
  &.hover,
  &:hover {
    background: var(--secondary-color);
    box-shadow: var(--secondary-color);
  }
}
.pass-margin {
  margin-left: -15px !important;
}
.visibility {
  margin-left: -25px !important;
}
.visibility-icon {
  color: $color-gray
}
.confirm {
  margin-left: -15px !important;
  margin-top: -25px !important;
}
.wrong-pass {
  margin-top: 10px !important;
  float: right;
  color: red !important;
}
.email-disabled {
  & .text-field__input {
    & input {
      color: #928d8d !important;
    }
  }
}
.data-loading-preferences {
  width: 28rem;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  .data-loading-spinner {
    margin-top: 50% !important;
    margin-left: 48% !important;
    & .spinner-inner {
      background-color: #fff !important;
    }
  }
}
</style>
